import { faAnglesRight } from "@fortawesome/free-solid-svg-icons";
import "./interhome.css";

import { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FaClock, FaLongArrowAltRight } from "react-icons/fa";
import { MdKeyboardDoubleArrowRight } from "react-icons/md";
import Navbar from "../navbar/Navbar";
import Footer from "../footer/Footer";
import axios from "axios";
import { Link, useLocation } from "react-router-dom";



export const HomeInterList = () => {
  const [places, setPlaces] = useState([]);
  const location = useLocation();
  const itemsPerPage = 4; // Number of cards per page
  const [currentPage, setCurrentPage] = useState(1);
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;

  // Get the current page's items
  const currentItems = places.slice(startIndex, endIndex);

  // Handle page change
  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };
  const getinternational = async () => {
    let res = await axios.get(
      "https://bookingoda.com/api/admin/getinternational"
    );
    if (res.status === 200) {
      //////console.log(res.data);
      setPlaces(res.data.getpackages.filter((item)=>
          item.blockstatus !== true
      ));
    
    }
  };
  useEffect(() => {
    getinternational();
  }, []);
  const [destination, setdestination] = useState(location?.state?.destination !== undefined ? location?.state?.destination : "");
 console.log(destination);
  const [DestinationName, setDestinationName] = useState("");
  const handleDestination = (e) => {
    setDestinationName(e.target.value);
  };
  const [selectedCarModels, setSelectedCarModels] = useState([]);
 

  const [filteredCars, setFilteredCars] = useState([]);

  useEffect(() => {
    // Filter cars based on the selected car models
    const filtered = places.filter((data) =>
      selectedCarModels.includes(data.tripduration)
    );
    setFilteredCars(filtered);
  }, [selectedCarModels, places]);
  
  const handleCheckboxChange = (event) => {
    const { value, checked } = event.target;

    // Update selected car models based on checkbox state
    setSelectedCarModels((prevModels) =>
      checked ? [...prevModels, value] : prevModels.filter((model) => model !== value)
    );
  };
  
  return (
    <>
<Navbar />
      <div>
  
     
      <div className="breadcrumb breadcrumb-style-one">
        <div className="container">
          <div className="col-lg-12 text-center">
            <h2 className="breadcrumb-title">Package Standard</h2>
            <ul className="d-flex justify-content-center breadcrumb-items">
              <li className="breadcrumb-item"><a >Home</a></li>
              <FontAwesomeIcon className="icons" icon={faAnglesRight} style={{margin:"0px"}}/>
              <li className="breadcrumb-item active">Package Standard</li>
            </ul>
          </div>
        </div>
      </div>
      <div className="package-standard-wrapper pt-80">
        <div className="container">
          <div className="row">
            <div className="col-lg-8">
             {destination !== "" 
                  ? places
                      .filter((item) => item.state === destination).map((data) => (    <div className="package-card-gamma">
                      <div className="package-thumb">
                          <Link to={`/HomeInternationalDetails/${data._id}`}><img src={`https://bookingoda.com/image/${data.GalleryImageCulture[0]?.imageculture}`} alt=""  style={{width:"300px",height:"200px"}}/></Link>
                      </div>
                      <div className="package-card-body">
                        <p className="card-lavel">
                          <FaClock /> <span>{data.tripduration}</span>
                        </p>
                        <h1 className="p-card-title">  <Link to={`/HomeInternationalDetails/${data._id}`}>{data.DestinationName}</Link></h1>
                        <div className="p-card-bottom">
                          <div className="book-btnint">
                            <Link to={`/HomeInternationalDetails/${data._id}`}>  Book Now <FaLongArrowAltRight /></Link>
                          </div>
                          <div className="p-card-infos">
                            <span>From</span>
                            <h6>Rs.{data.tripcost.toFixed(2)} <span>/Person</span></h6>
                          </div>
                        </div>
                      </div>
                    </div>)) : filteredCars?.length > 0 ? filteredCars.map((data) => (  <div className="package-card-gamma">
                <div className="package-thumb">
                    <Link to={`/HomeInternationalDetails/${data._id}`}><img src={`https://bookingoda.com/image/${data.GalleryImageCulture[0]?.imageculture}`} alt=""  style={{width:"300px",height:"200px"}}/></Link>
                </div>
                <div className="package-card-body">
                  <p className="card-lavel">
                    <FaClock /> <span>{data.tripduration}</span>
                  </p>
                  <h1 className="p-card-title">  <Link to={`/HomeInternationalDetails/${data._id}`}>{data.DestinationName}</Link></h1>
                  <div className="p-card-bottom">
                    <div className="book-btnint">
                      <Link to={`/HomeInternationalDetails/${data._id}`}>  Book Now <FaLongArrowAltRight /></Link>
                    </div>
                    <div className="p-card-infos">
                      <span>From</span>
                      <h6>Rs.{data.tripcost.toFixed(2)} <span>/Person</span></h6>
                    </div>
                  </div>
                </div>
              </div> )) :
             DestinationName !== ""
                  ? places
                      .filter((item) => item._id === DestinationName).map((data) => (    <div className="package-card-gamma">
                      <div className="package-thumb">
                          <Link to={`/HomeInternationalDetails/${data._id}`}><img src={`https://bookingoda.com/image/${data.GalleryImageCulture[0]?.imageculture}`} alt=""  style={{width:"300px",height:"200px"}}/></Link>
                      </div>
                      <div className="package-card-body">
                        <p className="card-lavel">
                          <FaClock /> <span>{data.tripduration}</span>
                        </p>
                        <h1 className="p-card-title">  <Link to={`/HomeInternationalDetails/${data._id}`}>{data.DestinationName}</Link></h1>
                        <div className="p-card-bottom">
                          <div className="book-btnint">
                            <Link to={`/HomeInternationalDetails/${data._id}`}>  Book Now <FaLongArrowAltRight /></Link>
                          </div>
                          <div className="p-card-infos">
                            <span>From</span>
                            <h6>Rs.{data.tripcost.toFixed(2)} <span>/Person</span></h6>
                          </div>
                        </div>
                      </div>
                    </div>))  : 
              currentItems.map((data) => (  
                <div className="package-card-gamma">
                <div className="package-thumb">
                    <Link to={`/HomeInternationalDetails/${data._id}`}><img src={`https://bookingoda.com/image/${data.GalleryImageCulture[0]?.imageculture}`} alt=""  style={{width:"300px",height:"200px"}}/></Link>
                </div>
                <div className="package-card-body">
                  <p className="card-lavel">
                    <FaClock /> <span>{data.tripduration}</span>
                  </p>
                  <h1 className="p-card-title">  <Link to={`/HomeInternationalDetails/${data._id}`}>{data.DestinationName}</Link></h1>
                  <div className="p-card-bottom">
                    <div className="book-btnint">
                      <Link to={`/HomeInternationalDetails/${data._id}`}>  Book Now <FaLongArrowAltRight /></Link>
                    </div>
                    <div className="p-card-infos">
                      <span>From</span>
                      <h6>Rs.{data.tripcost.toFixed(2)} <span>/Person</span></h6>
                    </div>
                  </div>
                </div>
              </div>))}
              
              <div className="blog-pagination">
                <nav>
                  <ul className="pagination page-item justify-content-center">
                    {Array.from({
                      length: Math.ceil(places.length / itemsPerPage),
                    }).map((_, index) => (
                      <li
                        key={index}
                        className={`previtem ${
                          currentPage === index + 1 ? "active" : ""
                        }`}
                      >
                        <button
                          className="page-link"
                          onClick={() => handlePageChange(index + 1)}
                        >
                          <i className="fas fa-regular fa-arrow-right me-2" />{" "}
                          {index + 1}
                        </button>
                      </li>
                    ))}
                  </ul>
                </nav>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="package-sidebar">
                <aside className="package-widget widget-search">
                  <div className="widget-title">
                    <h4>Search Here</h4>
                  </div>
                  <div className="widget-body">
                    <form action="#" method="post" id="blog-sidebar-search">
                      <div className="form-custom">
                      <select
                        value={DestinationName}
                        onChange={handleDestination}
                        style={{ appearance: "none" }}
                      >
                        <option value="">Select Your Destination</option>
                        {places.map((data) => (
                          <option value={data._id}>{data.DestinationName}</option>
                        ))}
                          </select>
                        {/* <input type="search" placeholder="Your Destination" style={{width:"100%"}}/> */}
                        {/* <button type="submit" style={{height:"100%"}}>SEAECH</button> */}
                      </div>
                    </form>
                  </div>
                </aside>
                <aside className="package-widget widget-tour-categoris mt-30">
                  <div className="widget-title">
                    <h4>Duration</h4>
                  </div>
                  <div className="widget-body">
                    <ul>
                    {[...new Set(places.map((data) => data.tripduration))].map((data) => (   <li className="category-check">
                    <input className="form-check-input" type="checkbox"   value={data}
              onChange={handleCheckboxChange}
              checked={selectedCarModels.includes(data)}/>  <label className="form-check-label" htmlFor="cate">
                          <MdKeyboardDoubleArrowRight /> {data}
                        </label>
                      
                      </li>
                      ))}
                    </ul>
                  </div>
                </aside>
               
                <aside className="package-widget widget-recent-package-entries mt-30">
                  <div className="widget-title">
                    <h4>Choose Your Destination</h4>
                  </div>
                  <div className="widget-body">
                    <ul>
                     { places.map((data) => (    
                       <li className="package-sm">
                    <div className="thumb">
                    <Link to={`/HomeInternationalDetails/${data._id}`}>
                        <img src={`https://bookingoda.com/image/${data.GalleryImageCulture[0]?.imageculture}`} alt=""  />
                      </Link>
                    </div>
                    <div className="infos">
                      <h6>   <Link to={`/HomeInternationalDetails/${data._id}`}>
                        {data.DestinationName}</Link></h6>
                      <div className="price">
                        <span>From</span>
                        <h6>Rs.{data.tripcost.toFixed(2)} <span>Per Person</span></h6>
                      </div>
                    </div>
                  </li>))}
                    </ul>
                  </div>
                </aside>
               
              </div>
            </div>
          </div>
        </div>
      </div>
  
      </div>
      <Footer />
    </>
  );
};
