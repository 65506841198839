import "./list.css";
import Navbar from "../../components/navbar/Navbar";
import Header from "../../components/header/Header";
import React, { useCallback, useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { format } from "date-fns";
import { DateRange } from "react-date-range";
import IndianCities from "indian-cities-json";

import axios from "axios";
import { FaHeart, FaLongArrowAltRight, FaRegHeart } from "react-icons/fa";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import StarRatings from "react-star-ratings";
import { FaMapMarkerAlt } from "react-icons/fa";
import Carousel from "react-bootstrap/Carousel";
const List = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [data, setdata] = useState([]);
  const [data1, setdata1] = useState([]);
  const [data2, setdata2] = useState([]);
  const [data3, setdata3] = useState([]);
  const location = useLocation();
  const [showcat, setShowcat] = useState(null);
  const handleKeyDown = (e) => {
    // Prevent the input of the minus sign
    if (e.key === "-" || e.key === "e" || e.key === "E") {
      e.preventDefault();
    }
  };
  const getapartment = async () => {
    let res = await axios.get(
      "https://bookingoda.com/api/admin/getallApartment"
    );
    if (res.status === 200) {
      ////console.log(res.data);
      setdata(
        res.data.Apartment.filter(
          (item) =>
            item.status === "Approved" &&
            item.Deletestatus !== "Deleted By Admin" &&
            item.blockstatus !== true
        )
      );
    }
  };
  const getalternative = async () => {
    let res = await axios.get(
      "https://bookingoda.com/api/admin/getallalternative"
    );
    if (res.status === 200) {
      ////console.log(res.data);
      setdata2(
        res.data.alternative.filter(
          (item) =>
            item.status === "Approved" &&
            item.Deletestatus !== "Deleted By Admin" &&
            item.blockstatus !== true
        )
      );
    }
  };
  const gethotel = async () => {
    let res = await axios.get("https://bookingoda.com/api/admin/getallhotel");
    if (res.status === 200) {
      ////console.log(res.data);
      setdata1(
        res.data.hotel.filter(
          (item) =>
            item.status === "Approved" &&
            item.Deletestatus !== "Deleted By Admin" &&
            item.blockstatus !== true
        )
      );
    }
  };
  const gethomestay = async () => {
    let res = await axios.get("https://bookingoda.com/api/admin/getallhome");
    if (res.status === 200) {
      ////console.log(res.data);
      setdata3(
        res.data.home.filter(
          (item) =>
            item.status === "Approved" &&
            item.Deletestatus !== "Deleted By Admin" &&
            item.blockstatus !== true
        )
      );
    }
  };

  useEffect(() => {
    getapartment();
    gethotel();
    getalternative();
    gethomestay();
    getreviews();
    AllWishlist();
  }, []);
  const cityOptions = [
    { value: "Mumbai", label: "Mumbai" },
    { value: "Delhi", label: "Delhi" },
    { value: "Bangalore", label: "Bangalore" },
    { value: "Chennai", label: "Chennai" },
    { value: "Kolkata", label: "Kolkata" },
    { value: "Hyderabad", label: "Hyderabad" },
    { value: "Pune", label: "Pune" },
    { value: "Ahmedabad", label: "Ahmedabad" },
    { value: "Jaipur", label: "Jaipur" },
    { value: "Lucknow", label: "Lucknow" },
  ];
  const list = [...data, ...data1, ...data2, ...data3];
  const datasss = list.map((e) => e.address.map((data) => data));
  const cityNames = datasss.map((address, index) => {
    if (Array.isArray(address) && address[0]) {
      const cityArray = address[0].split(","); // Split the address string by commas
      if (cityArray.length >= 4) {
        const cityName = cityArray[cityArray.length - 4]?.trim(); // Use optional chaining to avoid 'undefined' error
        return cityName || null; // Provide a default value if city name is not found
      } else {
        return null;
      }
    } else {
      return null;
    }
  });

  const uniqueAddresses = [...new Set(cityNames.flat())];
  const dataProperty = list.map((e) => e.PropertyName);
  // //console.log(location.state.list, "list");

  const Unique = [...uniqueAddresses, ...dataProperty];

  const [destination, setDestination] = useState(
    location.state.destination ? location.state.destination : ""
  );
  const [filteredOptions, setFilteredOptions] = useState(Unique);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  // const handleDestinationChange = (e) => {
  //   const input = e.target.value;
  //   setDestination(input);

  //   // Filter options based on the input
  //   const filtered = Unique.filter((data) =>
  //     data?.toLowerCase().includes(input.toLowerCase())
  //   );
  //   setFilteredOptions(filtered);
  //   setIsDropdownOpen(true);
  // };
  const user = JSON.parse(sessionStorage.getItem("user"));

  const handleDestinationChange = (e) => {
    const input = e.target.value;
    setDestination(input);

    // Filter options based on the input from both arrays
    const filteredOptions = IndianCities.cities
      .filter((data) => data.name?.toLowerCase().includes(input.toLowerCase()))
      .concat(
        list.filter((data) =>
          data?.PropertyName.toLowerCase().includes(input.toLowerCase())
        )
      );

    setFilteredOptions(filteredOptions);
    setIsDropdownOpen(true);
  };
  const handleOptionSelect = useCallback((selectedOption) => {
    setDestination(selectedOption);
    setIsDropdownOpen(false);
  }, []);
  const [date, setDate] = useState(
    location.state.date
      ? location.state.date
      : [
          {
            startDate: new Date(),
            endDate: new Date(),
            key: "selection",
          },
        ]
  );

  const [openDate, setOpenDate] = useState(false);
  const [options, setOptions] = useState(
    location.state.options
      ? location.state.options
      : {
          adult: 1,
          children: 0,
          room: 1,
          childrenAges: [] 
        }
  );
  const handleAgeChange = (index, newAge) => {
    if (newAge >= 0 && newAge <= 17) {
      setOptions(prev => {
        const newChildrenAges = [...prev.childrenAges];
        newChildrenAges[index] = newAge;
        return {
          ...prev,
          childrenAges: newChildrenAges
        };
      });
    }
  };
  const handleDateChange = (selectedDates) => {
    setDate([selectedDates.selection]);
  };
  const startDate = date[0].startDate.setHours(0, 0, 0, 0);
  const endDate = date[0].endDate.setHours(0, 0, 0, 0);
  const timeDifference = endDate - startDate;

  // Convert the difference to days
  const daysDifference = timeDifference / (1000 * 60 * 60 * 24);

  const handleOption = (name, operation) => {
    setOptions(prev => {
      const newValue = operation === "i" ? prev[name] + 1 : prev[name] - 1;
      let newChildrenAges = [...prev.childrenAges];
  
      if (name === "children") {
        if (operation === "i") {
          newChildrenAges.push(0); // Add a default age
        } else {
          newChildrenAges.pop(); // Remove the last age entry
        }
      }
  
      return {
        ...prev,
        [name]: newValue,
        childrenAges: newChildrenAges
      };
    });
  };
  const [minPrice, setMinPrice] = useState(0);
  const [maxPrice, setMaxPrice] = useState(Infinity);
  const [filteredProducts, setFilteredProducts] = useState(
    location.state.list !== undefined ? location.state.list : []
  );

  const handleCheckboxChange = (event) => {
    const priceRange = event.target.id.split("-").map((str) => parseInt(str.trim(), 10));
    const [min, max] = priceRange;
  
    // If the checkbox is already checked, uncheck it
    if (minPrice === min && maxPrice === max) {
      setMinPrice(0);
      setMaxPrice(0);
      filterProducts(0, 0); // Assuming this will clear the filter
    } else {
      // If unchecked, check
      setMinPrice(min);
      setMaxPrice(max);
      filterProducts(min, max);
    }
  };

  const filterProducts = (min, max) => {
    // Filter products based on the selected price range
    const filtered = list.filter(
      (product) =>
        parseInt(product.Price) >= min && parseInt(product.Price) <= max
    );
    setShowcat(filtered.length);
    // Update the filtered products state
    setFilteredProducts(filtered);
  };

  const [selectedCheckbox, setSelectedCheckbox] = useState(null);
  const [selectedCheckboxFav, setSelectedCheckboxFav] = useState(null);

  // //console.log(selectedCheckbox);
  const [selectedCheckboxstar, setSelectedCheckboxstar] = useState(0);
  const [dataCounts, setDataCounts] = useState({});
  const handleCheckboxChange1 = (checkboxId) => {
    // If the clicked checkbox is already checked, uncheck it
    if (selectedCheckbox === checkboxId) {
      setSelectedCheckbox(""); // Unselecting the checkbox
    } else {
      setSelectedCheckbox(checkboxId); // Selecting the checkbox
    }
  };
  const handleCheckboxChangeFav = (checkboxId) => {
    // If the checkbox is already checked, uncheck it
    if (selectedCheckboxFav === checkboxId) {
      setSelectedCheckboxFav(""); // Unselecting the checkbox
    } else {
      setSelectedCheckboxFav(checkboxId); // Selecting the checkbox
    }
  };
  
  const handleCheckboxChangestar = (star) => {
    if (selectedCheckboxstar === star) {
      // If the same checkbox is clicked again (unchecking), reset the filter and counts
      setSelectedCheckboxstar(""); // Unselect the checkbox
      const initialCounts = {
        1: 0,
        2: 0,
        3: 0,
        4: 0,
        5: 0,
      };
      setDataCounts(initialCounts); // Reset the counts
    } else {
      // If a different checkbox is clicked (checking), update the counts based on the selected star rating
      setSelectedCheckboxstar(star, () => {
        const counts = {
          1: 0,
          2: 0,
          3: 0,
          4: 0,
          5: 0,
        };
  
        list?.forEach((hotel) => {
          const overallAverage = getOverallAverageRating(hotel._id);
          if (Math.floor(overallAverage) === star) {
            counts[Math.floor(overallAverage)] += 1;
          }
        });
  
        setDataCounts(counts); // Update the counts
      });
    }
  };
  
  
  
  
  const [wishlist, setwishlist] = useState([]);

  const AllWishlist = () => {
    // console.log("hgfh", user._id);
    axios
      .get("https://bookingoda.com/api/user/getWishlist/" + user?._id)
      .then(function (response) {
        setwishlist(response.data.WishList);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const RemovefromWishlist = async (item) => {
    try {
      const config = {
        url: "/user/removeFromWishlist",
        method: "put",
        baseURL: "https://bookingoda.com/api",
        hearder: { "content-type": "application/json" },
        data: {
          userId: user?._id,
          hotelId: item._id,
        },
      };
      await axios(config).then(function (res) {
        if (res.status === 200) {
          console.log(res.data);
          // alert("Product Removed");
          AllWishlist();
          // if(wishlist.length === 1){
          // navigation.goBack();
          // }
        } else {
          alert("Something Wrong");
        }
      });
    } catch (error) {
      console.log(error.response);
    }
  };
  const addToWishlist = async (item) => {
    try {
      const config = {
        url: "/user/addToWishlist",
        method: "post",
        baseURL: "https://bookingoda.com/api",
        hearder: { "content-type": "application/json" },

        data: {
          userId: user._id,
          hotelId: item?._id,
        },
      };
      await axios(config).then(function (res) {
        if (res.status === 200) {
          // console.log(res.data);
          alert(res.data.success);
          AllWishlist();
        } else {
          alert("Something Wrong");
        }
      });
    } catch (error) {
      console.log(error.response);
    }
  };
  const handleClick = (datas) => {
    // Check if the checkbox is currently checked
    const isChecked = selectedCheckbox !== '';
  
    if (isChecked) {
      // If the checkbox is checked, filter out the selected checkbox from the datas array
      const updatedData = datas.filter((item) => item !== selectedCheckbox);
      setFilteredProducts(updatedData);
    } else {
      // If the checkbox is unchecked, reset the filtered products list to the original list of products
      setFilteredProducts(list);
    }
  };
  
  const handleClickFav = (datas, isChecked) => {
  if (isChecked) {
    // If the checkbox is checked, filter products based on the provided IDs
    const updatedData = list.filter((data) =>
      datas.map((item) => item).includes(data._id)
    );
    setFilteredProducts(updatedData);
  } else {
    // If the checkbox is unchecked, reset the filtered products list
    setFilteredProducts(list);
  }
};
  const itemsPerPage = 10; // Number of cards per page
  const [currentPage, setCurrentPage] = useState(1);
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;

  // Get the current page's items
  const currentItems = list.slice(startIndex, endIndex);

  // Handle page change
  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };
  const [review, setreview] = useState([]);

  const getreviews = async () => {
    let res = await axios.get("https://bookingoda.com/api/user/getallReview");
    if (res.status === 200) {
      ////console.log(res.data);
      setreview(res.data.success);
      //console.log(res.data.bookedstays, "dasfasd");
    }
  };

  const ReviewPercentage = ({ review, hotelId }) => {
    const selectedCategories = [
      "Pricerating",
      "Staffrating",
      "Cleanlinessrating",
      "Comfortrating",
      "Facilitiesrating",
      "Locationrating",
    ];
    const averageRatings = {};

    // Error-checking: Ensure review is an array
    if (!Array.isArray(review)) {
      console.error("Invalid 'review' data:", review);
      return null; // or handle the error in a way suitable for your application
    }

    selectedCategories?.forEach((category) => {
      const filteredReviews = review.filter(
        (data) => data?.HotelId[0] === hotelId
      );

      // Error-checking: Ensure filteredReviews is an array
      if (!Array.isArray(filteredReviews)) {
        console.error("Invalid 'filteredReviews' data:", filteredReviews);
        return null; // or handle the error
      }

      const sum = filteredReviews.reduce(
        (acc, rating) => acc + rating[category],
        0
      );
      averageRatings[category] =
        filteredReviews.length > 0 ? sum / filteredReviews.length : 0;
    });

    const overallAverage =
      selectedCategories.length > 0
        ? selectedCategories.reduce(
            (acc, category) => acc + averageRatings[category],
            0
          ) / selectedCategories.length
        : 0;

    // Determine the status based on the overall average
    let status;
    if (overallAverage >= 1 && overallAverage < 2) {
      status = "Review score";
    } else if (overallAverage >= 2 && overallAverage < 3) {
      status = "Okay";
    } else if (overallAverage >= 3 && overallAverage < 4) {
      status = "Average";
    } else if (overallAverage >= 4 && overallAverage <= 5) {
      status = "Good";
    } else {
      status = "Unrated";
    }

    return (
      <div style={{ textAlign: "right" }}>
        <div className="siRating" style={{ textAlign: "right" }}>
          {/* <span>Excellent</span> */}

          <span
            style={{ color: "#0b58b4", fontSize: "20px", fontWeight: "bold" }}
          >
            {status}
          </span>
          <button>&nbsp;{overallAverage.toFixed(2)}</button>
        </div>
        <p className="mt-reviews-summary" style={{ textAlign: "right" }}>
          ({" "}
          <span className="mt-reviews-number" itemProp="ratingCount">
            {Array.isArray(review)
              ? review.filter(
                  (data) =>
                    data?.HotelId &&
                    Array.isArray(data.HotelId) &&
                    data.HotelId[0] === hotelId
                ).length
              : 0}
          </span>
          <span className="mt-reviews-label">&nbsp;review</span>)
        </p>
        <StarRatings
          rating={overallAverage}
          starRatedColor="#ffb700"
          numberOfStars={5}
          name="rating"
          isSelectable={false}
          starDimension="18px"
        />
      </div>
    );
  };

  const getOverallAverageRating = (hotelId) => {
    const reviewsForHotel = review.filter(
      (review) => review.HotelId[0] === hotelId
    );

    if (reviewsForHotel.length > 0) {
      const totalRating = reviewsForHotel.reduce(
        (sum, review) => sum + review.Average,
        0
      );
      return totalRating / reviewsForHotel.length;
    } else {
      return 0;
    }
  };

  const handleClickstar = (star) => {
    let filteredHotels = [];
  
    if (selectedCheckboxstar === star) {
      // If the same checkbox is clicked again (unchecking), reset the filter
      filteredHotels = list;
      setSelectedCheckboxstar(""); // Unselect the checkbox
    } else {
      // If a different checkbox is clicked (checking), filter hotels based on the selected star rating
      filteredHotels = list.filter((hotel) => {
        const overallAverage = getOverallAverageRating(hotel._id);
  
        // Filter out unrated hotels if star is 0
        if (star === 0) {
          return overallAverage !== 0;
        }
  
        // Filter for rated hotels based on the selected star
        return Math.floor(overallAverage) === star;
      });
  
      setSelectedCheckboxstar(star); // Select the clicked checkbox
    }
  
    // Update the filtered products state
    setFilteredProducts(filteredHotels);
  
    // Update the counts based on the filtered hotels
    const counts = {
      1: 0,
      2: 0,
      3: 0,
      4: 0,
      5: 0,
    };
  
    filteredHotels?.forEach((hotel) => {
      const overallAverage = getOverallAverageRating(hotel._id);
      counts[Math.floor(overallAverage)] += 1;
    });
  
    // Update the data counts state
    setDataCounts(counts);
  };
  useEffect(() => {
    const initialCounts = { 1: 0, 2: 0, 3: 0, 4: 0, 5: 0 };
    list?.forEach((hotel) => {
      const overallAverage = getOverallAverageRating(hotel._id);
      initialCounts[Math.floor(overallAverage)] += 1;
    });
    setDataCounts(initialCounts);
  }, [list]);

  //Rooms Facilities
  const [selectedFacilities, setSelectedFacilities] = useState({
    "Flat screen TV": false,
    Linens: false,
    "Air conditioning": false,
    Desk: false,
    "Wake-up service": false,
    Towels: false,
    Heating: false,
    Fan: false,
    Safe: false,
    "Towels/Sheets (extra fee)": false,
    Balcony: false,
    Terrace: false,
    View: false,
    "Electric kettle": false,
    "Tea/Coffee maker": false,
    "Dining area": false,
    "Dining table": false,
    Microwave: false,
  });
  const facilitiesList = [
    "Flat screen TV",
    "Linens",
    "Air conditioning",
    "Desk",
    "Wake-up service",
    "Towels",
    "Heating",
    "Fan",
    "Safe",
    "Towels/Sheets (extra fee)",
    "Balcony",
    "Terrace",
    "View",
    "Electric kettle",
    "Tea/Coffee maker",
    "Dining area",
    "Dining table",
    "Microwave",
  ];
  const [dataCounts1, setDataCounts1] = useState({});
  const handleCheckboxChangeroom = (facility) => {
    setSelectedFacilities((prevFacilities) => ({
      ...prevFacilities,
      [facility]: !prevFacilities[facility],
    }));
  };
  const handleClickroom = () => {
    const filteredData = list.filter((item) => {
      return item.RoomDetails.some((roomDetail) => {
        const roomFacilities = roomDetail?.General || {};
        const roomFacilities1 = roomDetail?.Food || {};
        const roomFacilities2 = roomDetail?.Outdoors || {};

        return Object.entries(selectedFacilities).every(
          ([key, value]) =>
            !value ||
            roomFacilities[key] === true ||
            roomFacilities1[key] === true ||
            roomFacilities2[key] === true
        );
      });
    });

    setFilteredProducts(filteredData);
    updateFacilityCounts(filteredData);
};

const updateFacilityCounts = (data) => {
  const counts = {};
  facilitiesList?.forEach((facility) => {
    counts[facility] = 0;
  });

  data?.forEach((item) => {
    item.RoomDetails?.forEach((roomDetail) => {
      const roomFacilities = roomDetail?.General || {};
      const roomFacilities1 = roomDetail?.Food || {};
      const roomFacilities2 = roomDetail?.Outdoors || {};

      facilitiesList?.forEach((facility) => {
        if (
          roomFacilities[facility] === true ||
          roomFacilities1[facility] === true ||
          roomFacilities2[facility] === true
        ) {
          counts[facility]++;
        }
      });
    });
  });

  setDataCounts1(counts);
};

// Update filtered data and counts whenever selectedFacilities change
useEffect(() => {
  handleClickroom();
}, [selectedFacilities]);

// Initial count setup
useEffect(() => {
  updateFacilityCounts(list);
}, [list]);

// Count occurrences of each facility in the data

  const filteredItems = list.filter((item) => {
    const addressString = item.address.map((data) => data).join(", ");
    return (
      addressString.includes(destination) || item.PropertyName === destination
    );
  });
  const [advertise, setadvertise] = useState([]);
  const getadvertise = async () => {
    let res = await axios.get(
      "https://bookingoda.com/api/admin/getalladvertiseList"
    );
    if ((res.status = 200)) {
      //console.log(res);
      setadvertise(res.data?.advertiseList);
    }
  };
  useEffect(() => {
    // getcountry();
    getadvertise();
  }, []);
  const filteredAdverts = advertise.filter((item) => {
    const currentDate = new Date();

    const year = currentDate.getFullYear();
    const month = String(currentDate.getMonth() + 1).padStart(2, "0");
    const day = String(currentDate.getDate()).padStart(2, "0");

    const formattedCurrentDate = `${year}-${month}-${day}`;
    const startingDate = item.startingdate;
    const validityDate = item.validity;
    // //console.log(startingDate,validityDate,"dsf");
    return (
      startingDate <= formattedCurrentDate &&
      formattedCurrentDate <= validityDate
    );
  });

  // //console.log(filteredAdverts);
  const validImageExtensions = [".jpg", ".jpeg", ".png", ".gif"];

  // Filter only items with image file extensions
  const filteredListImage = filteredAdverts.filter((item) => {
    const lowerCaseFileName = item.advertiseList?.toLowerCase();
    return validImageExtensions.some((extension) =>
      lowerCaseFileName?.endsWith(extension)
    );
  });

  return (
    <div>
      <Navbar />
      <div class="header12 listMode">
        <div class="headerContainer12 container12">
          <div class="headerCont12">
            <div>
              <h2
                class="headerTitle12 text-center"
                style={{ marginTop: "50px" }}
              >
                Fresh, quite and peaceful
              </h2>
            </div>
          </div>
        </div>
      </div>
      <h3
        class="mb-2 mar"
        style={{
          fontSize: "1.2rem",
          fontFamily: "Poppins,sans-serif",
        }}
      >
        Choose from wide range of reservations
      </h3>
      <div class="mb-4 available mar" style={{}}>
        <span class="first">Available destinations:</span>{" "}
        {uniqueAddresses.slice(0, 4).map((e) => (
          <span class="second">
            {" "}
            {e}
            {","}
          </span>
        ))}
      </div>

      <div className="listContainer">
        <div className="listWrapper">
          <div
            style={{ display: "flex", flexDirection: "column", width: "300px" }}
          >
            <div className="listSearch">
              <h1 className="lsTitle">Search</h1>
              <div className="lsItem">
                <label>Destination</label>
                {/* <select
                id="cityDropdown"
                className="headerSearchInput"
                value={destination}
                onChange={(e) => setDestination(e.target.value)}
              >
                <option value="" disabled>
                  Where are you going?
                </option>
                {Unique.map((data) => (
                  <option value={data}>{data}</option>
                ))}
              </select> */}
                <input
                  type="text"
                  placeholder="Where are you going?"
                  value={destination}
                  onChange={handleDestinationChange}
                  onFocus={() => setIsDropdownOpen(true)}
                  onBlur={() => setIsDropdownOpen(false)}
                  style={{ color: "#827e9f" }}
                  className="headerSearchInput"
                />

                {destination !== "" && isDropdownOpen && (
                  <div
                    className="dropdown-content"
                    style={{
                      backgroundColor: "white",
                      color: "black",
                      overflowY: "scroll",
                      height: "200px",
                      width: "250px",
                    }}
                  >
                    {/* {filteredOptions.map((data) => (
                    <div
                      key={data}
                      className="dropdown-item"
                      
                      onMouseDown={() => {
                        // //console.log("Clicked on item:", data);
                        handleOptionSelect(data);
                      }}
                    >
                      {data}
                    </div>
                  ))} */}
                    {filteredOptions.map((data) => (
                      <div
                        key={data.name}
                        className="dropdown-item"
                        onMouseDown={() => {
                          // //console.log("Clicked on item:", data);
                          handleOptionSelect(
                            data.name !== undefined
                              ? data.name
                              : data.PropertyName
                          );
                        }}
                      >
                        {data.name ? <>📍 {data.name}</> : null}
                        {data.PropertyName ? <>📍 {data.PropertyName}</> : null}
                      </div>
                    ))}
                  </div>
                )}
              </div>
              <div className="lsItem">
                <label>Check-in Date</label>
                <span onClick={() => setOpenDate(!openDate)}>{`${format(
                  startDate,
                  "MM/dd/yyyy"
                )} to ${format(endDate, "MM/dd/yyyy")}`}</span>
                {openDate && (
                  <DateRange
                    onChange={handleDateChange}
                    minDate={new Date()}
                    ranges={date}
                    style={{width:"290px"}}
                  />
                )}
              </div>
              <div className="lsItem">
                <label>Options</label>
                <div className="lsOptions">
                  {/* <div className="lsOptionItem">
                  <span className="lsOptionText">
                    Min price <small>per night</small>
                  </span>
                  <input type="number" onKeyDown={handleKeyDown} className="lsOptionInput" />
                </div>
                <div className="lsOptionItem">
                  <span className="lsOptionText">
                    Max price <small>per night</small>
                  </span>
                  <input type="number" onKeyDown={handleKeyDown} className="lsOptionInput" />
                </div> */}
                  <div className="lsOptionItem">
                    <span className="lsOptionText">Adult</span>
                    <div className="optionCounter">
                      <button
                        disabled={options.adult <= 1}
                        className="optionCounterButton"
                        onClick={() => handleOption("adult", "d")}
                      >
                        -
                      </button>
                      <span className="optionCounterNumber">
                        {options.adult}
                      </span>
                      <button
                        className="optionCounterButton"
                        onClick={() => handleOption("adult", "i")}
                      >
                        +
                      </button>
                    </div>
                  </div>
                  <div className="lsOptionItem">
                    <span className="lsOptionText">Children</span>
                    <div className="optionCounter">
                      <button
                        disabled={options.children <= 0}
                        className="optionCounterButton"
                        onClick={() => handleOption("children", "d")}
                      >
                        -
                      </button>
                      <span className="optionCounterNumber">
                        {options.children}
                      </span>
                      <button
                        className="optionCounterButton"
                        onClick={() => handleOption("children", "i")}
                      >
                        +
                      </button>
                    </div>
                  </div>
                  {options.children > 0 && (
        <div className="childrenAges">
          {options.childrenAges.map((age, index) => (
            <div key={index} className="optionItem">
              <span className="optionText">Child {index + 1} Age</span>
              <input
  type="number"
  onKeyDown={(event) => {
    if (!/[0-9]/.test(event.key) && event.key !== 'Backspace' && event.key !== 'ArrowLeft' && event.key !== 'ArrowRight' && event.key !== 'Delete') {
      event.preventDefault();
    }
  }}
  value={age === 0 ? "" : age} // conditionally render the value
  onChange={(e) => {
    const value = e.target.value === "" ? 0 : parseInt(e.target.value);
    handleAgeChange(index, value);
  }}
  className="ageInput"
/>
            </div>
          ))}
        </div>
      )}
                  <div className="lsOptionItem">
                    <span className="lsOptionText">Room</span>
                    <div className="optionCounter">
                      <button
                        disabled={options.room <= 1}
                        className="optionCounterButton"
                        onClick={() => handleOption("room", "d")}
                      >
                        -
                      </button>
                      <span className="optionCounterNumber">
                        {options.room}
                      </span>
                      <button
                        className="optionCounterButton"
                        onClick={() => handleOption("room", "i")}
                      >
                        +
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="filterRow " id="MERGE_PROPERTY_TYPE">
                <div className="latoBold font16 blackText appendBottom15 makeFlex hrtlCenter">
                  Favorites
                </div>
                <ul className="filterList">
                  <li className>
                    <span
                      data-testid="checkboxFilter"
                      className=" checkmarkOuter"
                    >
                      <input
                        type="checkbox"
                        aria-label="wishlist"
                        id="wishlist"
                        checked={selectedCheckboxFav === "wishlist"}
                        onChange={() => handleCheckboxChangeFav("wishlist")}
                        onClick={() => handleClickFav(wishlist, selectedCheckboxFav !== "wishlist")}

                      />
                      <label htmlFor="wishlist">
                        <div className="makeFlex column">
                          <span>Saved Stays</span>
                        </div>
                      </label>
                    </span>
                    <span className="font12 grayText">
                      ({/* */}
                      {wishlist?.length}
                      {/* */})
                    </span>
                  </li>
                </ul>
              </div>
              <div className="filterRow " id="PRICE_BUCKET">
                <div className="latoBold font16 blackText appendBottom15 makeFlex hrtlCenter">
                  Price per night
                </div>
                <div className="priceBucketFilter">
                  <ul className="filterList ">
                    <li className>
                      <span className="checkmarkOuter ">
                        <input
                          type="checkbox"
                          id="0 - 1500"
                          onChange={handleCheckboxChange}
                          checked={minPrice === 0 && maxPrice === 1500}
                        />
                        <label htmlFor="0 - 1500">₹ 0 - ₹ 1500</label>
                      </span>
                      <span className="font12 grayText">
                        (
                        {
                          list.filter(
                            (product) =>
                              parseInt(product.Price) >= 0 &&
                              parseInt(product.Price) <= 1500
                          ).length
                        }
                        )
                      </span>
                    </li>
                    <li className="latoBold">
                      <span className="checkmarkOuter">
                        <input
                          type="checkbox"
                          id="1500 - 3000"
                          onChange={handleCheckboxChange}
                          checked={minPrice === 1500 && maxPrice === 3000}
                        />
                        <label htmlFor="1500 - 3000">₹ 1500 - ₹ 3000</label>
                      </span>
                      <span className="font12 grayText">
                        (
                        {
                          list.filter(
                            (product) =>
                              parseInt(product.Price) >= 1500 &&
                              parseInt(product.Price) <= 3000
                          ).length
                        }
                        )
                      </span>
                    </li>
                    <li className>
                      <span className="checkmarkOuter ">
                        <input
                          type="checkbox"
                          id="3000 - 6000"
                          onChange={handleCheckboxChange}
                          checked={minPrice === 3000 && maxPrice === 6000}
                        />
                        <label htmlFor="3000 - 6000">₹ 3000 - ₹ 6000</label>
                      </span>
                      <span className="font12 grayText">
                        (
                        {
                          list.filter(
                            (product) =>
                              parseInt(product.Price) >= 3000 &&
                              parseInt(product.Price) <= 6000
                          ).length
                        }
                        )
                      </span>
                    </li>
                    <li className>
                      <span className="checkmarkOuter ">
                        <input
                          type="checkbox"
                          id="6000 - 9000"
                          onChange={handleCheckboxChange}
                          checked={minPrice === 6000 && maxPrice === 9000}
                        />
                        <label htmlFor="6000 - 9000">₹ 6000 - ₹ 9000</label>
                      </span>
                      <span className="font12 grayText">
                        (
                        {
                          list.filter(
                            (product) =>
                              parseInt(product.Price) >= 6000 &&
                              parseInt(product.Price) <= 9000
                          ).length
                        }
                        )
                      </span>
                    </li>
                    <li className>
                      <span className="checkmarkOuter ">
                        <input
                          type="checkbox"
                          id="9000 - 12000"
                          onChange={handleCheckboxChange}
                          checked={minPrice === 9000 && maxPrice === 12000}
                        />
                        <label htmlFor="9000 - 12000">₹ 9000 - ₹ 12000</label>
                      </span>
                      <span className="font12 grayText">
                        (
                        {
                          list.filter(
                            (product) =>
                              parseInt(product.Price) >= 9000 &&
                              parseInt(product.Price) <= 12000
                          ).length
                        }
                        )
                      </span>
                    </li>
                    <li className>
                      <span className="checkmarkOuter ">
                        <input
                          type="checkbox"
                          id="12000 - 15000"
                          onChange={handleCheckboxChange}
                          checked={minPrice === 12000 && maxPrice === 15000}
                        />
                        <label htmlFor="12000 - 15000">₹ 12000 - ₹ 15000</label>
                      </span>
                      <span className="font12 grayText">
                        (
                        {
                          list.filter(
                            (product) =>
                              parseInt(product.Price) >= 12000 &&
                              parseInt(product.Price) <= 15000
                          ).length
                        }
                        )
                      </span>
                    </li>

                    <li className>
                      <span className="checkmarkOuter ">
                        <input
                          type="checkbox"
                          id="20000+"
                          onChange={handleCheckboxChange}
                          checked={minPrice === 20000}
                        />
                        <label htmlFor="20000+">₹ 20000+</label>
                      </span>
                      <span className="font12 grayText">
                        (
                        {
                          list.filter(
                            (product) =>
                              parseInt(product.Price) >= 20000 &&
                              parseInt(product.Price) <= Infinity
                          ).length
                        }
                        )
                      </span>
                    </li>
                  </ul>
                  {/* <p className="latoBold font14 appendTop15">Your Budget</p>
                <div className="range">
                  <input
                    name="min"
                    className="rangeInput "
                    type="number" onKeyDown={handleKeyDown}
                    placeholder="Min"
                    aria-label="min range input"
                    value={minPrice}
                    onChange={(e) => setMinPrice(parseInt(e.target.value, 10) || null)}
                  />
                  <span className="appendLR10">to</span>
                  <input
                    name="max"
                    className="appendLeft5 rangeInput "
                    type="number" onKeyDown={handleKeyDown}
                    placeholder="Max"
                    aria-label="max range input"
                    value={maxPrice === Infinity ? '' : maxPrice}
                    onChange={(e) => setMaxPrice(parseInt(e.target.value, 10) || Infinity)}
                  />
                  <button
                  onClick={handleRangeFilter}
                    aria-label="Select Range button"
                    className="btnRangeGo appendLeft5 fadeEff"
                  >
                    <FontAwesomeIcon icon={faArrowRight}  style={{ color: "white", fontSize: "16px" }}/>
                    
                  </button>
                </div> */}
                </div>
              </div>

              <div className="filterRow " id="MERGE_PROPERTY_TYPE">
                <div className="latoBold font16 blackText appendBottom15 makeFlex hrtlCenter">
                  Property Type
                </div>
                <ul className="filterList">
                  <li className>
                    <span
                      data-testid="checkboxFilter"
                      className=" checkmarkOuter"
                    >
                      <input
                        type="checkbox"
                        aria-label="Hotel"
                        id="Hotel"
                        checked={selectedCheckbox === "Hotel"}
                        onChange={() => handleCheckboxChange1("Hotel")}
                        onClick={() => handleClick(data1)}
                      />
                      <label htmlFor="Hotel">
                        <div className="makeFlex column">
                          <span>Hotel</span>
                        </div>
                      </label>
                    </span>
                    <span className="font12 grayText">
                      ({/* */}
                      {data1?.length}
                      {/* */})
                    </span>
                  </li>
                  <li className>
                    <span
                      data-testid="checkboxFilter"
                      className=" checkmarkOuter"
                    >
                      <input
                        type="checkbox"
                        aria-label="Homestay"
                        id="Homestay"
                        checked={selectedCheckbox === "Homestay"}
                        onChange={() => handleCheckboxChange1("Homestay")}
                        onClick={() => handleClick(data3)}
                      />
                      <label htmlFor="Homestay">
                        <div className="makeFlex column">
                          <span>Homestay</span>
                        </div>
                      </label>
                    </span>
                    <span className="font12 grayText">
                      ({/* */}
                      {data3?.length}
                      {/* */})
                    </span>
                  </li>
                  <li className>
                    <span
                      data-testid="checkboxFilter"
                      className=" checkmarkOuter"
                    >
                      <input
                        aria-label="Apartment"
                        id="Apartment"
                        type="checkbox"
                        checked={selectedCheckbox === "Apartment"}
                        onChange={() => handleCheckboxChange1("Apartment")}
                        onClick={() => handleClick(data)}
                      />
                      <label htmlFor="Apartment">
                        <div className="makeFlex column">
                          <span>Apartment</span>
                        </div>
                      </label>
                    </span>
                    <span className="font12 grayText">({data?.length})</span>
                  </li>

                  <li className>
                    <span
                      data-testid="checkboxFilter"
                      className=" checkmarkOuter"
                    >
                      <input
                        aria-label="Alternative"
                        id="Alternative"
                        type="checkbox"
                        checked={selectedCheckbox === "Alternative"}
                        onChange={() => handleCheckboxChange1("Alternative")}
                        onClick={() => handleClick(data2)}
                      />
                      <label htmlFor="Alternative">
                        <div className="makeFlex column">
                          <span>Alternative</span>
                        </div>
                      </label>
                    </span>
                    <span className="font12 grayText">({data2?.length})</span>
                  </li>
                </ul>
              </div>
              <div className="filterRow " id="MERGE_PROPERTY_TYPE">
                <div className="latoBold font16 blackText appendBottom15 makeFlex hrtlCenter">
                  Property Ratings
                </div>
                <ul className="filterList">
                  {[1, 2, 3, 4, 5].map((star) => (
                    <li key={star}>
                      <span
                        data-testid="checkboxFilter"
                        className="checkmarkOuter"
                      >
                        <input
                          type="checkbox"
                          aria-label={`${star} Stars`}
                          id={star}
                          checked={selectedCheckboxstar === star}
                          onChange={() => handleCheckboxChangestar(star)}
                          onClick={() => handleClickstar(star)}
                        />
                        <label htmlFor={star}>
                          <div className="makeFlex column">
                            <span>{`${star} Stars`}</span>
                          </div>
                        </label>
                      </span>
                      <span className="font12 grayText">
                        ({dataCounts[star]})
                      </span>
                    </li>
                  ))}
                </ul>
              </div>
              <div className="filterRow " id="MERGE_PROPERTY_TYPE">
                <div className="latoBold font16 blackText appendBottom15 makeFlex hrtlCenter">
                  Room Facilities
                </div>
                <ul className="filterList">
                  {/* {Object.entries(selectedFacilities).map(([facility, isSelected]) => (
          <li key={facility}>
            <span data-testid="checkboxFilter" className="checkmarkOuter">
              <input
                type="checkbox"
                aria-label={facility}
                id={facility}
                checked={isSelected}
                onChange={() => handleCheckboxChangeroom(facility)}
              />
              <label htmlFor={facility}>
                <div className="makeFlex column">
                  <span>{facility}</span>
                </div>
              </label>
            </span>
            <span className="font12 grayText">
              ({dataCounts[facility]})
            </span>
          </li>
        ))} */}
                   {facilitiesList.map((facility) => (
          <li key={facility}>
            <span data-testid="checkboxFilter" className="checkmarkOuter">
              <input
                type="checkbox"
                aria-label={facility}
                id={facility}
                checked={selectedFacilities[facility] || false}
                onChange={() => handleCheckboxChangeroom(facility)}
              />
              <label htmlFor={facility}>
                <div className="makeFlex column">
                  <span>{facility}</span>
                </div>
              </label>
            </span>
            <span className="font12 grayText">({dataCounts1[facility] || 0})</span>
          </li>
        ))}
                </ul>
              </div>
              {/* <button>Search</button> */}
            </div>
            <div style={{ marginTop: "0px" }}>
              {filteredAdverts
                .filter((item) => item.advertiseList.endsWith(".mp4"))
                .map((data) => (
                  <video
                    src={"https://bookingoda.com/image/" + data.advertiseList}
                    alt="video"
                    className="marvideo"
                    controls
                  />
                ))}
            </div>
          </div>
          <div className="listResult">
            <Carousel
              style={{ position: "relative" }}
              interval={advertise[currentIndex]?.Sec || 3000}
              wrap={true}
            >
              {filteredListImage.map((data) => (
                <Carousel.Item>
                  {" "}
                  <a href={data.link} title="">
                    {" "}
                    <img
                      alt=""
                      style={{
                        width: "100%",
                        height: "200px",
                        marginBottom: "20px",
                      }}
                      src={"https://bookingoda.com/image/" + data.advertiseList}
                    />{" "}
                  </a>
                </Carousel.Item>
              ))}
            </Carousel>
            {/* //search */}
            {destination ? (
              <>
                {filteredItems
                  .filter((data) => data.prioritystatus === true)
                  .map((item) => {
                    const originalPrice = parseInt(item?.Price);
                    const discountString = item?.Offerprice || "";
                    let discountedPrice;

                    //console.log(`Discount String: ${discountString}`);

                    if (discountString.endsWith("%")) {
                      const discountPercentage = parseFloat(
                        discountString.replace(/[^0-9.]/g, "")
                      );
                      discountedPrice =
                        originalPrice -
                        (originalPrice * discountPercentage) / 100;
                    } else {
                      const discountValue = parseFloat(
                        discountString.replace(/[^0-9.]/g, "")
                      );
                      discountedPrice = originalPrice - discountValue;
                    }

                    const taxInfo = item?.tax || "";
                    let taxedPrice;

                    if (taxInfo.endsWith("%")) {
                      // If the tax is in percentage
                      const taxPercentage = parseFloat(
                        taxInfo.replace(/[^0-9.]/g, "")
                      );
                      taxedPrice = (originalPrice * taxPercentage) / 100;
                    } else if (taxInfo.endsWith("INR")) {
                      // If the tax is in INR
                      const taxInr = parseFloat(
                        taxInfo.replace(/[^0-9.]/g, "")
                      );
                      taxedPrice = taxInr;
                    } else {
                      // Handle the case where the tax format is not recognized
                      console.warn(
                        `Unrecognized tax format for item with ID ${item._id}`
                      );
                    }
                    return (
                      <div className="searchItem">
                        <Link
                          to={`/hotels/${item._id}`}
                          state={{ date: date, options: options }}
                          key={item._id}
                        >
                          <div
                            style={{ display: "flex", flexDirection: "column" }}
                          >
                           {item?.BreakfastIncluded === "Yes, it's included" || item?.Breakfast === "Yes" ? 
                            <span
                              className="siSubtitle"
                              style={{
                                backgroundColor: "#0B6E0B",
                                color: "white",
                                textAlign: "center",
                                padding: "10px",
                                borderTopLeftRadius: "5px",
                                borderTopRightRadius: "5px",
                              }}
                            >
                              Breakfast Included
                            </span> : ""}

                            <img
                              src={`https://bookingoda.com/image/${item?.Photos[0]?.file}`}
                              alt=""
                              className="siImg"
                            />
                          </div>{" "}
                        </Link>
                        <div className="siDesc">
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "space-between",
                            }}
                          >
                            <div>
                              {item?.Badge?.length > 0 ? (
                                <img
                                  src={`https://bookingoda.com/image/${item?.Badge[0]?.file}`}
                                  style={{
                                    width: "100px",
                                    height: "30px",
                                    marginBottom: "10px",
                                  }}
                                  alt=""
                                />
                              ) : (
                                ""
                              )}
                              <Link
                                to={`/hotels/${item._id}`}
                                state={{ date: date, options: options }}
                                key={item._id}
                              >
                                {" "}
                                <h1 className="siTitle">
                                  {item.PropertyName}
                                </h1>{" "}
                              </Link>{" "}
                            </div>
                            {wishlist.find((data) => data === item?._id) ? (    <div
                              role="none"
                              className="f9039b381b"
                              data-testid="wishlist-icon"
                            >
                              <span className>
                                <span className="f419a93f12">
                                  <button
                                    aria-expanded="false"
                                    data-testid="wishlist-button"
                                    aria-label="Save the property"
                                    type="button"
                                    className="a83ed08757 c21c56c305 bd3a1c7108 d691166b09 ab98298258 deab83296e fe94449254"
                                    onClick={() => RemovefromWishlist(item)}
                                  >
                                    <span className="eedba9e88a">
                                      <span
                                        className="fcd9eec8fb bf9a32efa5"
                                        aria-hidden="true"
                                      >
                                     <FaHeart style={{color:"red"}} />
                                      </span>
                                    </span>
                                  </button>
                                </span>
                              </span>
                            </div>  ) : (<div
                              role="none"
                              className="f9039b381b"
                              data-testid="wishlist-icon"
                            >
                              <span className>
                                <span className="f419a93f12">
                                  <button
                                    aria-expanded="false"
                                    data-testid="wishlist-button"
                                    aria-label="Save the property"
                                    type="button"
                                    className="a83ed08757 c21c56c305 bd3a1c7108 d691166b09 ab98298258 deab83296e fe94449254"
                                    onClick={() => addToWishlist(item)}
                                  >
                                    <span className="eedba9e88a">
                                      <span
                                        className="fcd9eec8fb bf9a32efa5"
                                        aria-hidden="true"
                                      >
                                        <FaRegHeart />
                                      </span>
                                    </span>
                                  </button>
                                </span>
                              </span>
                            </div>)}
                          </div>
                          <span className="siDistance">
                            <FaMapMarkerAlt
                              style={{ color: "black", marginRight: "5px" }}
                            />
                            {item.address.map((item) => item).join(", ")}
                          </span>
                          <span className="siSubtitle">
                            Rooms Available:{" "}
                            {item?.RoomDetails?.map(
                              (item) => item.Roomname[0]?.value
                            ).join(", ")}
                          </span>
                        </div>
                        <div className="siDetails">
                          <ReviewPercentage
                            review={review}
                            hotelId={item._id}
                          />
                          <div className="siDetailTexts">
                            <span style={{ fontSize: "16px", color: "black" }}>
                              Price for 1 Night
                            </span>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "flex-end",
                              }}
                            >
                              <span
                                style={{
                                  color: "red",
                                  fontSize: "16px",
                                  textDecoration: "line-through",
                                  marginRight: "10px",
                                }}
                              >
                                ₹ {parseInt(item?.Price)}
                              </span>

                              <span className="siPrice">
                                ₹{" "}
                                {discountedPrice
                                  ? discountedPrice
                                  : item?.Price}
                              </span>
                            </div>
                            <span className="siTaxOp">
                              +{taxedPrice ? taxedPrice : 0} taxes &amp; fees
                            </span>

                            <Link
                              to={`/hotels/${item._id}`}
                              state={{ date: date, options: options }}
                            >
                              <button className="siCheckButton">
                                See availability
                              </button>
                            </Link>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                {filteredItems
                  .filter((data) => data.prioritystatus !== true)
                  .map((item) => {
                    const originalPrice = parseInt(item?.Price);
                    const discountString = item?.Offerprice || "";
                    let discountedPrice;

                    //console.log(`Discount String: ${discountString}`);

                    if (discountString.endsWith("%")) {
                      const discountPercentage = parseFloat(
                        discountString.replace(/[^0-9.]/g, "")
                      );
                      discountedPrice =
                        originalPrice -
                        (originalPrice * discountPercentage) / 100;
                    } else {
                      const discountValue = parseFloat(
                        discountString.replace(/[^0-9.]/g, "")
                      );
                      discountedPrice = originalPrice - discountValue;
                    }

                    const taxInfo = item?.tax || "";
                    let taxedPrice;

                    if (taxInfo.endsWith("%")) {
                      // If the tax is in percentage
                      const taxPercentage = parseFloat(
                        taxInfo.replace(/[^0-9.]/g, "")
                      );
                      taxedPrice = (originalPrice * taxPercentage) / 100;
                    } else if (taxInfo.endsWith("INR")) {
                      // If the tax is in INR
                      const taxInr = parseFloat(
                        taxInfo.replace(/[^0-9.]/g, "")
                      );
                      taxedPrice = taxInr;
                    } else {
                      // Handle the case where the tax format is not recognized
                      console.warn(
                        `Unrecognized tax format for item with ID ${item._id}`
                      );
                    }
                    return (
                      <div className="searchItem">
                      <Link
                        to={`/hotels/${item._id}`}
                        state={{ date: date, options: options }}
                        key={item._id}
                      >
                        <div
                          style={{ display: "flex", flexDirection: "column" }}
                        >
                          {item?.BreakfastIncluded === "Yes, it's included" || item?.Breakfast === "Yes" ? 
                            <span
                              className="siSubtitle"
                              style={{
                                backgroundColor: "#0B6E0B",
                                color: "white",
                                textAlign: "center",
                                padding: "10px",
                                borderTopLeftRadius: "5px",
                                borderTopRightRadius: "5px",
                              }}
                            >
                              Breakfast Included
                            </span> : ""}

                          <img
                            src={`https://bookingoda.com/image/${item?.Photos[0]?.file}`}
                            alt=""
                            className="siImg"
                          />
                        </div>{" "}
                      </Link>
                      <div className="siDesc">
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                          }}
                        >
                          <div>
                            {item?.Badge?.length > 0 ? (
                              <img
                                src={`https://bookingoda.com/image/${item?.Badge[0]?.file}`}
                                style={{
                                  width: "100px",
                                  height: "30px",
                                  marginBottom: "10px",
                                }}
                                alt=""
                              />
                            ) : (
                              ""
                            )}
                            <Link
                              to={`/hotels/${item._id}`}
                              state={{ date: date, options: options }}
                              key={item._id}
                            >
                              {" "}
                              <h1 className="siTitle">
                                {item.PropertyName}
                              </h1>{" "}
                            </Link>{" "}
                          </div>
                          {wishlist.find((data) => data === item?._id) ? (    <div
                            role="none"
                            className="f9039b381b"
                            data-testid="wishlist-icon"
                          >
                            <span className>
                              <span className="f419a93f12">
                                <button
                                  aria-expanded="false"
                                  data-testid="wishlist-button"
                                  aria-label="Save the property"
                                  type="button"
                                  className="a83ed08757 c21c56c305 bd3a1c7108 d691166b09 ab98298258 deab83296e fe94449254"
                                  onClick={() => RemovefromWishlist(item)}
                                >
                                  <span className="eedba9e88a">
                                    <span
                                      className="fcd9eec8fb bf9a32efa5"
                                      aria-hidden="true"
                                    >
                                   <FaHeart style={{color:"red"}} />
                                    </span>
                                  </span>
                                </button>
                              </span>
                            </span>
                          </div>  ) : (<div
                            role="none"
                            className="f9039b381b"
                            data-testid="wishlist-icon"
                          >
                            <span className>
                              <span className="f419a93f12">
                                <button
                                  aria-expanded="false"
                                  data-testid="wishlist-button"
                                  aria-label="Save the property"
                                  type="button"
                                  className="a83ed08757 c21c56c305 bd3a1c7108 d691166b09 ab98298258 deab83296e fe94449254"
                                  onClick={() => addToWishlist(item)}
                                >
                                  <span className="eedba9e88a">
                                    <span
                                      className="fcd9eec8fb bf9a32efa5"
                                      aria-hidden="true"
                                    >
                                      <FaRegHeart />
                                    </span>
                                  </span>
                                </button>
                              </span>
                            </span>
                          </div>)}
                        </div>
                        <span className="siDistance">
                          <FaMapMarkerAlt
                            style={{ color: "black", marginRight: "5px" }}
                          />
                          {item.address.map((item) => item).join(", ")}
                        </span>
                        <span className="siSubtitle">
                          Rooms Available:{" "}
                          {item?.RoomDetails?.map(
                            (item) => item.Roomname[0]?.value
                          ).join(", ")}
                        </span>
                      </div>
                      <div className="siDetails">
                        <ReviewPercentage
                          review={review}
                          hotelId={item._id}
                        />
                        <div className="siDetailTexts">
                          <span style={{ fontSize: "16px", color: "black" }}>
                            Price for 1 Night
                          </span>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "flex-end",
                            }}
                          >
                            <span
                              style={{
                                color: "red",
                                fontSize: "16px",
                                textDecoration: "line-through",
                                marginRight: "10px",
                              }}
                            >
                              ₹ {parseInt(item?.Price)}
                            </span>

                            <span className="siPrice">
                              ₹{" "}
                              {discountedPrice
                                ? discountedPrice
                                : item?.Price}
                            </span>
                          </div>
                          <span className="siTaxOp">
                            +{taxedPrice ? taxedPrice : 0} taxes &amp; fees
                          </span>

                          <Link
                            to={`/hotels/${item._id}`}
                            state={{ date: date, options: options }}
                          >
                            <button className="siCheckButton">
                              See availability
                            </button>
                          </Link>
                        </div>
                      </div>
                    </div>
                    );
                  })}
              </>
            ) : filteredProducts.length > 0 ? (
              <>
                {filteredProducts
                  .filter((data) => data.prioritystatus === true)
                  ?.map((item) => {
                    const originalPrice = parseInt(item?.Price);
                    const discountString = item?.Offerprice || "";
                    let discountedPrice;

                    //console.log(`Discount String: ${discountString}`);

                    if (discountString.endsWith("%")) {
                      const discountPercentage = parseFloat(
                        discountString.replace(/[^0-9.]/g, "")
                      );
                      discountedPrice =
                        originalPrice -
                        (originalPrice * discountPercentage) / 100;
                    } else {
                      const discountValue = parseFloat(
                        discountString.replace(/[^0-9.]/g, "")
                      );
                      discountedPrice = originalPrice - discountValue;
                    }

                    const taxInfo = item?.tax || "";
                    let taxedPrice;

                    if (taxInfo.endsWith("%")) {
                      // If the tax is in percentage
                      const taxPercentage = parseFloat(
                        taxInfo.replace(/[^0-9.]/g, "")
                      );
                      taxedPrice = (originalPrice * taxPercentage) / 100;
                    } else if (taxInfo.endsWith("INR")) {
                      // If the tax is in INR
                      const taxInr = parseFloat(
                        taxInfo.replace(/[^0-9.]/g, "")
                      );
                      taxedPrice = taxInr;
                    } else {
                      // Handle the case where the tax format is not recognized
                      console.warn(
                        `Unrecognized tax format for item with ID ${item._id}`
                      );
                    }
                    return (
                      <div className="searchItem">
                      <Link
                        to={`/hotels/${item._id}`}
                        state={{ date: date, options: options }}
                        key={item._id}
                      >
                        <div
                          style={{ display: "flex", flexDirection: "column" }}
                        >
                        {item?.BreakfastIncluded === "Yes, it's included" || item?.Breakfast === "Yes" ? 
                            <span
                              className="siSubtitle"
                              style={{
                                backgroundColor: "#0B6E0B",
                                color: "white",
                                textAlign: "center",
                                padding: "10px",
                                borderTopLeftRadius: "5px",
                                borderTopRightRadius: "5px",
                              }}
                            >
                              Breakfast Included
                            </span> : ""}

                          <img
                            src={`https://bookingoda.com/image/${item?.Photos[0]?.file}`}
                            alt=""
                            className="siImg"
                          />
                        </div>{" "}
                      </Link>
                      <div className="siDesc">
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                          }}
                        >
                          <div>
                            {item?.Badge?.length > 0 ? (
                              <img
                                src={`https://bookingoda.com/image/${item?.Badge[0]?.file}`}
                                style={{
                                  width: "100px",
                                  height: "30px",
                                  marginBottom: "10px",
                                }}
                                alt=""
                              />
                            ) : (
                              ""
                            )}
                            <Link
                              to={`/hotels/${item._id}`}
                              state={{ date: date, options: options }}
                              key={item._id}
                            >
                              {" "}
                              <h1 className="siTitle">
                                {item.PropertyName}
                              </h1>{" "}
                            </Link>{" "}
                          </div>
                          {wishlist.find((data) => data === item?._id) ? (    <div
                            role="none"
                            className="f9039b381b"
                            data-testid="wishlist-icon"
                          >
                            <span className>
                              <span className="f419a93f12">
                                <button
                                  aria-expanded="false"
                                  data-testid="wishlist-button"
                                  aria-label="Save the property"
                                  type="button"
                                  className="a83ed08757 c21c56c305 bd3a1c7108 d691166b09 ab98298258 deab83296e fe94449254"
                                  onClick={() => RemovefromWishlist(item)}
                                >
                                  <span className="eedba9e88a">
                                    <span
                                      className="fcd9eec8fb bf9a32efa5"
                                      aria-hidden="true"
                                    >
                                   <FaHeart style={{color:"red"}} />
                                    </span>
                                  </span>
                                </button>
                              </span>
                            </span>
                          </div>  ) : (<div
                            role="none"
                            className="f9039b381b"
                            data-testid="wishlist-icon"
                          >
                            <span className>
                              <span className="f419a93f12">
                                <button
                                  aria-expanded="false"
                                  data-testid="wishlist-button"
                                  aria-label="Save the property"
                                  type="button"
                                  className="a83ed08757 c21c56c305 bd3a1c7108 d691166b09 ab98298258 deab83296e fe94449254"
                                  onClick={() => addToWishlist(item)}
                                >
                                  <span className="eedba9e88a">
                                    <span
                                      className="fcd9eec8fb bf9a32efa5"
                                      aria-hidden="true"
                                    >
                                      <FaRegHeart />
                                    </span>
                                  </span>
                                </button>
                              </span>
                            </span>
                          </div>)}
                        </div>
                        <span className="siDistance">
                          <FaMapMarkerAlt
                            style={{ color: "black", marginRight: "5px" }}
                          />
                          {item.address.map((item) => item).join(", ")}
                        </span>
                        <span className="siSubtitle">
                          Rooms Available:{" "}
                          {item?.RoomDetails?.map(
                            (item) => item.Roomname[0]?.value
                          ).join(", ")}
                        </span>
                      </div>
                      <div className="siDetails">
                        <ReviewPercentage
                          review={review}
                          hotelId={item._id}
                        />
                        <div className="siDetailTexts">
                          <span style={{ fontSize: "16px", color: "black" }}>
                            Price for 1 Night
                          </span>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "flex-end",
                            }}
                          >
                            <span
                              style={{
                                color: "red",
                                fontSize: "16px",
                                textDecoration: "line-through",
                                marginRight: "10px",
                              }}
                            >
                              ₹ {parseInt(item?.Price)}
                            </span>

                            <span className="siPrice">
                              ₹{" "}
                              {discountedPrice
                                ? discountedPrice
                                : item?.Price}
                            </span>
                          </div>
                          <span className="siTaxOp">
                            +{taxedPrice ? taxedPrice : 0} taxes &amp; fees
                          </span>

                          <Link
                            to={`/hotels/${item._id}`}
                            state={{ date: date, options: options }}
                          >
                            <button className="siCheckButton">
                              See availability
                            </button>
                          </Link>
                        </div>
                      </div>
                    </div>
                    );
                  })}{" "}
                {filteredProducts
                  .filter((data) => data.prioritystatus !== true)
                  ?.map((item) => {
                    const originalPrice = parseInt(item?.Price);
                    const discountString = item?.Offerprice || "";
                    let discountedPrice;

                    //console.log(`Discount String: ${discountString}`);

                    if (discountString.endsWith("%")) {
                      const discountPercentage = parseFloat(
                        discountString.replace(/[^0-9.]/g, "")
                      );
                      discountedPrice =
                        originalPrice -
                        (originalPrice * discountPercentage) / 100;
                    } else {
                      const discountValue = parseFloat(
                        discountString.replace(/[^0-9.]/g, "")
                      );
                      discountedPrice = originalPrice - discountValue;
                    }

                    const taxInfo = item?.tax || "";
                    let taxedPrice;

                    if (taxInfo.endsWith("%")) {
                      // If the tax is in percentage
                      const taxPercentage = parseFloat(
                        taxInfo.replace(/[^0-9.]/g, "")
                      );
                      taxedPrice = (originalPrice * taxPercentage) / 100;
                    } else if (taxInfo.endsWith("INR")) {
                      // If the tax is in INR
                      const taxInr = parseFloat(
                        taxInfo.replace(/[^0-9.]/g, "")
                      );
                      taxedPrice = taxInr;
                    } else {
                      // Handle the case where the tax format is not recognized
                      console.warn(
                        `Unrecognized tax format for item with ID ${item._id}`
                      );
                    }
                    return (
                       <div className="searchItem">
                        <Link
                          to={`/hotels/${item._id}`}
                          state={{ date: date, options: options }}
                          key={item._id}
                        >
                          <div
                            style={{ display: "flex", flexDirection: "column" }}
                          >
                           {item?.BreakfastIncluded === "Yes, it's included" || item?.Breakfast === "Yes" ? 
                            <span
                              className="siSubtitle"
                              style={{
                                backgroundColor: "#0B6E0B",
                                color: "white",
                                textAlign: "center",
                                padding: "10px",
                                borderTopLeftRadius: "5px",
                                borderTopRightRadius: "5px",
                              }}
                            >
                              Breakfast Included
                            </span> : ""}

                            <img
                              src={`https://bookingoda.com/image/${item?.Photos[0]?.file}`}
                              alt=""
                              className="siImg"
                            />
                          </div>{" "}
                        </Link>
                        <div className="siDesc">
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "space-between",
                            }}
                          >
                            <div>
                              {item?.Badge?.length > 0 ? (
                                <img
                                  src={`https://bookingoda.com/image/${item?.Badge[0]?.file}`}
                                  style={{
                                    width: "100px",
                                    height: "30px",
                                    marginBottom: "10px",
                                  }}
                                  alt=""
                                />
                              ) : (
                                ""
                              )}
                              <Link
                                to={`/hotels/${item._id}`}
                                state={{ date: date, options: options }}
                                key={item._id}
                              >
                                {" "}
                                <h1 className="siTitle">
                                  {item.PropertyName}
                                </h1>{" "}
                              </Link>{" "}
                            </div>
                            {wishlist.find((data) => data === item?._id) ? (    <div
                              role="none"
                              className="f9039b381b"
                              data-testid="wishlist-icon"
                            >
                              <span className>
                                <span className="f419a93f12">
                                  <button
                                    aria-expanded="false"
                                    data-testid="wishlist-button"
                                    aria-label="Save the property"
                                    type="button"
                                    className="a83ed08757 c21c56c305 bd3a1c7108 d691166b09 ab98298258 deab83296e fe94449254"
                                    onClick={() => RemovefromWishlist(item)}
                                  >
                                    <span className="eedba9e88a">
                                      <span
                                        className="fcd9eec8fb bf9a32efa5"
                                        aria-hidden="true"
                                      >
                                     <FaHeart style={{color:"red"}} />
                                      </span>
                                    </span>
                                  </button>
                                </span>
                              </span>
                            </div>  ) : (<div
                              role="none"
                              className="f9039b381b"
                              data-testid="wishlist-icon"
                            >
                              <span className>
                                <span className="f419a93f12">
                                  <button
                                    aria-expanded="false"
                                    data-testid="wishlist-button"
                                    aria-label="Save the property"
                                    type="button"
                                    className="a83ed08757 c21c56c305 bd3a1c7108 d691166b09 ab98298258 deab83296e fe94449254"
                                    onClick={() => addToWishlist(item)}
                                  >
                                    <span className="eedba9e88a">
                                      <span
                                        className="fcd9eec8fb bf9a32efa5"
                                        aria-hidden="true"
                                      >
                                        <FaRegHeart />
                                      </span>
                                    </span>
                                  </button>
                                </span>
                              </span>
                            </div>)}
                          </div>
                          <span className="siDistance">
                            <FaMapMarkerAlt
                              style={{ color: "black", marginRight: "5px" }}
                            />
                            {item.address.map((item) => item).join(", ")}
                          </span>
                          <span className="siSubtitle">
                            Rooms Available:{" "}
                            {item?.RoomDetails?.map(
                              (item) => item.Roomname[0]?.value
                            ).join(", ")}
                          </span>
                        </div>
                        <div className="siDetails">
                          <ReviewPercentage
                            review={review}
                            hotelId={item._id}
                          />
                          <div className="siDetailTexts">
                            <span style={{ fontSize: "16px", color: "black" }}>
                              Price for 1 Night
                            </span>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "flex-end",
                              }}
                            >
                              <span
                                style={{
                                  color: "red",
                                  fontSize: "16px",
                                  textDecoration: "line-through",
                                  marginRight: "10px",
                                }}
                              >
                                ₹ {parseInt(item?.Price)}
                              </span>

                              <span className="siPrice">
                                ₹{" "}
                                {discountedPrice
                                  ? discountedPrice
                                  : item?.Price}
                              </span>
                            </div>
                            <span className="siTaxOp">
                              +{taxedPrice ? taxedPrice : 0} taxes &amp; fees
                            </span>

                            <Link
                              to={`/hotels/${item._id}`}
                              state={{ date: date, options: options }}
                            >
                              <button className="siCheckButton">
                                See availability
                              </button>
                            </Link>
                          </div>
                        </div>
                      </div>
                    );
                  })}
              </>
            ) : showcat === 0 ? (
              <p>No matching results found.</p>
            ) : (
              <>
                {currentItems
                  .filter((data) => data.prioritystatus === true)
                  ?.map((item) => {
                    const originalPrice = parseInt(item?.Price);
                    const discountString = item?.Offerprice || "";
                    let discountedPrice;

                    //console.log(`Discount String: ${discountString}`);

                    if (discountString.endsWith("%")) {
                      const discountPercentage = parseFloat(
                        discountString.replace(/[^0-9.]/g, "")
                      );
                      discountedPrice =
                        originalPrice -
                        (originalPrice * discountPercentage) / 100;
                    } else {
                      const discountValue = parseFloat(
                        discountString.replace(/[^0-9.]/g, "")
                      );
                      discountedPrice = originalPrice - discountValue;
                    }

                    const taxInfo = item?.tax || "";
                    let taxedPrice;

                    if (taxInfo.endsWith("%")) {
                      // If the tax is in percentage
                      const taxPercentage = parseFloat(
                        taxInfo.replace(/[^0-9.]/g, "")
                      );
                      taxedPrice = (originalPrice * taxPercentage) / 100;
                    } else if (taxInfo.endsWith("INR")) {
                      // If the tax is in INR
                      const taxInr = parseFloat(
                        taxInfo.replace(/[^0-9.]/g, "")
                      );
                      taxedPrice = taxInr;
                    } else {
                      // Handle the case where the tax format is not recognized
                      console.warn(
                        `Unrecognized tax format for item with ID ${item._id}`
                      );
                    }
                    return (
                     <div className="searchItem">
                        <Link
                          to={`/hotels/${item._id}`}
                          state={{ date: date, options: options }}
                          key={item._id}
                        >
                          <div
                            style={{ display: "flex", flexDirection: "column" }}
                          >
                            {item?.BreakfastIncluded === "Yes, it's included" || item?.Breakfast === "Yes" ? 
                            <span
                              className="siSubtitle"
                              style={{
                                backgroundColor: "#0B6E0B",
                                color: "white",
                                textAlign: "center",
                                padding: "10px",
                                borderTopLeftRadius: "5px",
                                borderTopRightRadius: "5px",
                              }}
                            >
                              Breakfast Included
                            </span> : ""}

                            <img
                              src={`https://bookingoda.com/image/${item?.Photos[0]?.file}`}
                              alt=""
                              className="siImg"
                            />
                          </div>{" "}
                        </Link>
                        <div className="siDesc">
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "space-between",
                            }}
                          >
                            <div>
                              {item?.Badge?.length > 0 ? (
                                <img
                                  src={`https://bookingoda.com/image/${item?.Badge[0]?.file}`}
                                  style={{
                                    width: "100px",
                                    height: "30px",
                                    marginBottom: "10px",
                                  }}
                                  alt=""
                                />
                              ) : (
                                ""
                              )}
                              <Link
                                to={`/hotels/${item._id}`}
                                state={{ date: date, options: options }}
                                key={item._id}
                              >
                                {" "}
                                <h1 className="siTitle">
                                  {item.PropertyName}
                                </h1>{" "}
                              </Link>{" "}
                            </div>
                            {wishlist.find((data) => data === item?._id) ? (    <div
                              role="none"
                              className="f9039b381b"
                              data-testid="wishlist-icon"
                            >
                              <span className>
                                <span className="f419a93f12">
                                  <button
                                    aria-expanded="false"
                                    data-testid="wishlist-button"
                                    aria-label="Save the property"
                                    type="button"
                                    className="a83ed08757 c21c56c305 bd3a1c7108 d691166b09 ab98298258 deab83296e fe94449254"
                                    onClick={() => RemovefromWishlist(item)}
                                  >
                                    <span className="eedba9e88a">
                                      <span
                                        className="fcd9eec8fb bf9a32efa5"
                                        aria-hidden="true"
                                      >
                                     <FaHeart style={{color:"red"}} />
                                      </span>
                                    </span>
                                  </button>
                                </span>
                              </span>
                            </div>  ) : (<div
                              role="none"
                              className="f9039b381b"
                              data-testid="wishlist-icon"
                            >
                              <span className>
                                <span className="f419a93f12">
                                  <button
                                    aria-expanded="false"
                                    data-testid="wishlist-button"
                                    aria-label="Save the property"
                                    type="button"
                                    className="a83ed08757 c21c56c305 bd3a1c7108 d691166b09 ab98298258 deab83296e fe94449254"
                                    onClick={() => addToWishlist(item)}
                                  >
                                    <span className="eedba9e88a">
                                      <span
                                        className="fcd9eec8fb bf9a32efa5"
                                        aria-hidden="true"
                                      >
                                        <FaRegHeart />
                                      </span>
                                    </span>
                                  </button>
                                </span>
                              </span>
                            </div>)}
                          </div>
                          <span className="siDistance">
                            <FaMapMarkerAlt
                              style={{ color: "black", marginRight: "5px" }}
                            />
                            {item.address.map((item) => item).join(", ")}
                          </span>
                          <span className="siSubtitle">
                            Rooms Available:{" "}
                            {item?.RoomDetails?.map(
                              (item) => item.Roomname[0]?.value
                            ).join(", ")}
                          </span>
                        </div>
                        <div className="siDetails">
                          <ReviewPercentage
                            review={review}
                            hotelId={item._id}
                          />
                          <div className="siDetailTexts">
                            <span style={{ fontSize: "16px", color: "black" }}>
                              Price for 1 Night
                            </span>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "flex-end",
                              }}
                            >
                              <span
                                style={{
                                  color: "red",
                                  fontSize: "16px",
                                  textDecoration: "line-through",
                                  marginRight: "10px",
                                }}
                              >
                                ₹ {parseInt(item?.Price)}
                              </span>

                              <span className="siPrice">
                                ₹{" "}
                                {discountedPrice
                                  ? discountedPrice
                                  : item?.Price}
                              </span>
                            </div>
                            <span className="siTaxOp">
                              +{taxedPrice ? taxedPrice : 0} taxes &amp; fees
                            </span>

                            <Link
                              to={`/hotels/${item._id}`}
                              state={{ date: date, options: options }}
                            >
                              <button className="siCheckButton">
                                See availability
                              </button>
                            </Link>
                          </div>
                        </div>
                      </div>
                    );
                  })}

                {currentItems
                  .filter((data) => data.prioritystatus !== true)
                  ?.map((item) => {
                    const originalPrice = parseInt(item?.Price);
                    const discountString = item?.Offerprice || "";
                    let discountedPrice;

                    //console.log(`Discount String: ${discountString}`);

                    if (discountString.endsWith("%")) {
                      const discountPercentage = parseFloat(
                        discountString.replace(/[^0-9.]/g, "")
                      );
                      discountedPrice =
                        originalPrice -
                        (originalPrice * discountPercentage) / 100;
                    } else {
                      const discountValue = parseFloat(
                        discountString.replace(/[^0-9.]/g, "")
                      );
                      discountedPrice = originalPrice - discountValue;
                    }

                    const taxInfo = item?.tax || "";
                    let taxedPrice;

                    if (taxInfo.endsWith("%")) {
                      // If the tax is in percentage
                      const taxPercentage = parseFloat(
                        taxInfo.replace(/[^0-9.]/g, "")
                      );
                      taxedPrice = (originalPrice * taxPercentage) / 100;
                    } else if (taxInfo.endsWith("INR")) {
                      // If the tax is in INR
                      const taxInr = parseFloat(
                        taxInfo.replace(/[^0-9.]/g, "")
                      );
                      taxedPrice = taxInr;
                    } else {
                      // Handle the case where the tax format is not recognized
                      console.warn(
                        `Unrecognized tax format for item with ID ${item._id}`
                      );
                    }
                    return (
                      <div className="searchItem">
                        <Link
                          to={`/hotels/${item._id}`}
                          state={{ date: date, options: options }}
                          key={item._id}
                        >
                          <div
                            style={{ display: "flex", flexDirection: "column" }}
                          >
                            {item?.BreakfastIncluded === "Yes, it's included" || item?.Breakfast === "Yes" ? 
                            <span
                              className="siSubtitle"
                              style={{
                                backgroundColor: "#0B6E0B",
                                color: "white",
                                textAlign: "center",
                                padding: "10px",
                                borderTopLeftRadius: "5px",
                                borderTopRightRadius: "5px",
                              }}
                            >
                              Breakfast Included
                            </span> : ""}

                            <img
                              src={`https://bookingoda.com/image/${item?.Photos[0]?.file}`}
                              alt=""
                              className="siImg"
                            />
                          </div>{" "}
                        </Link>
                        <div className="siDesc">
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "space-between",
                            }}
                          >
                            <div>
                              {item?.Badge?.length > 0 ? (
                                <img
                                  src={`https://bookingoda.com/image/${item?.Badge[0]?.file}`}
                                  style={{
                                    width: "100px",
                                    height: "30px",
                                    marginBottom: "10px",
                                  }}
                                  alt=""
                                />
                              ) : (
                                ""
                              )}
                              <Link
                                to={`/hotels/${item._id}`}
                                state={{ date: date, options: options }}
                                key={item._id}
                              >
                                {" "}
                                <h1 className="siTitle">
                                  {item.PropertyName}
                                </h1>{" "}
                              </Link>{" "}
                            </div>
                            {wishlist.find((data) => data === item?._id) ? (    <div
                              role="none"
                              className="f9039b381b"
                              data-testid="wishlist-icon"
                            >
                              <span className>
                                <span className="f419a93f12">
                                  <button
                                    aria-expanded="false"
                                    data-testid="wishlist-button"
                                    aria-label="Save the property"
                                    type="button"
                                    className="a83ed08757 c21c56c305 bd3a1c7108 d691166b09 ab98298258 deab83296e fe94449254"
                                    onClick={() => RemovefromWishlist(item)}
                                  >
                                    <span className="eedba9e88a">
                                      <span
                                        className="fcd9eec8fb bf9a32efa5"
                                        aria-hidden="true"
                                      >
                                     <FaHeart style={{color:"red"}} />
                                      </span>
                                    </span>
                                  </button>
                                </span>
                              </span>
                            </div>  ) : (<div
                              role="none"
                              className="f9039b381b"
                              data-testid="wishlist-icon"
                            >
                              <span className>
                                <span className="f419a93f12">
                                  <button
                                    aria-expanded="false"
                                    data-testid="wishlist-button"
                                    aria-label="Save the property"
                                    type="button"
                                    className="a83ed08757 c21c56c305 bd3a1c7108 d691166b09 ab98298258 deab83296e fe94449254"
                                    onClick={() => addToWishlist(item)}
                                  >
                                    <span className="eedba9e88a">
                                      <span
                                        className="fcd9eec8fb bf9a32efa5"
                                        aria-hidden="true"
                                      >
                                        <FaRegHeart />
                                      </span>
                                    </span>
                                  </button>
                                </span>
                              </span>
                            </div>)}
                          </div>
                          <span className="siDistance">
                            <FaMapMarkerAlt
                              style={{ color: "black", marginRight: "5px" }}
                            />
                            {item.address.map((item) => item).join(", ")}
                          </span>
                          <span className="siSubtitle">
                            Rooms Available:{" "}
                            {item?.RoomDetails?.map(
                              (item) => item.Roomname[0]?.value
                            ).join(", ")}
                          </span>
                        </div>
                        <div className="siDetails">
                          <ReviewPercentage
                            review={review}
                            hotelId={item._id}
                          />
                          <div className="siDetailTexts">
                            <span style={{ fontSize: "16px", color: "black" }}>
                              Price for 1 Night
                            </span>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "flex-end",
                              }}
                            >
                              <span
                                style={{
                                  color: "red",
                                  fontSize: "16px",
                                  textDecoration: "line-through",
                                  marginRight: "10px",
                                }}
                              >
                                ₹ {parseInt(item?.Price)}
                              </span>

                              <span className="siPrice">
                                ₹{" "}
                                {discountedPrice
                                  ? discountedPrice
                                  : item?.Price}
                              </span>
                            </div>
                            <span className="siTaxOp">
                              +{taxedPrice ? taxedPrice : 0} taxes &amp; fees
                            </span>

                            <Link
                              to={`/hotels/${item._id}`}
                              state={{ date: date, options: options }}
                            >
                              <button className="siCheckButton">
                                See availability
                              </button>
                            </Link>
                          </div>
                        </div>
                      </div>
                    );
                  })}
              </>
            )}

            <div className="blog-pagination">
              <nav>
                <ul className="pagination page-item justify-content-center">
                  {Array.from({
                    length: Math.ceil(list.length / itemsPerPage),
                  }).map((_, index) => (
                    <li
                      key={index}
                      className={`previtem ${
                        currentPage === index + 1 ? "active" : ""
                      }`}
                    >
                      <button
                        className="page-link"
                        onClick={() => handlePageChange(index + 1)}
                      >
                        <i className="fas fa-regular fa-arrow-right me-2" />{" "}
                        {index + 1}
                      </button>
                    </li>
                  ))}
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </div>
      <div style={{ marginBottom: "30px" }}></div>
    </div>
  );
};

export default List;
