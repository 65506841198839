import { initializeApp } from "firebase/app";
import { getMessaging, getToken } from "firebase/messaging";


const firebaseConfig = {
  apiKey: "AIzaSyCKxU1uQtQhgOkBddkbe1NKsFSXJ_v4ico",
  authDomain: "mukkatis.firebaseapp.com",
  projectId: "mukkatis",
  storageBucket: "mukkatis.appspot.com",
  messagingSenderId: "255102460015",
  appId: "1:255102460015:web:68af0bb8f73057720ead9a",
  measurementId: "G-EQVQ4N72VJ"
};
const app = initializeApp(firebaseConfig);
const messaging = getMessaging(app);
function requestPermission() {
  console.log("Requesting permission...");
  Notification.requestPermission().then((permission) => {
    if (permission === "granted") {
      console.log("Notification permission granted.");
      getToken(messaging, {
        vapidKey:
          "BGuemogN_43tJcTzXHA03EpfZkZgS0nWo0vGao2tyI302BT4Cx2KcM5DAeYfAho2sEWE8cNV82qupr1_2zi6PGQ",
      }).then((currentToken) => {
        if (currentToken) {
          console.log("currentToken111: ", currentToken);
          
        } else {
          console.log("Can not get token");
        }
      });
    } else {
      console.log("Do not have permission!");
    }
  });
}

requestPermission();
