import {
  faBed,
  faCalendarDays,
  faCar,
  faPerson,
  faPlane,
  faTaxi,
  faHouse,
  faHotel,
  faEarthAmericas,
} from "@fortawesome/free-solid-svg-icons";
import withAutoplay from "react-awesome-slider/dist/autoplay";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { IoMdLogOut } from "react-icons/io";
import Select from "react-select";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./header.css";
import { DateRange } from "react-date-range";
import { useCallback, useEffect, useState } from "react";
import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file
import { format } from "date-fns";
import { useNavigate } from "react-router-dom";
import Logo from "../../assets/booking.jpeg";
import { City, Country, State } from "country-state-city";
import IndianCities from "indian-cities-json";
import axios from "axios";
import AwesomeSlider from "react-awesome-slider";
import "react-awesome-slider/dist/styles.css";
import { useSpring, animated } from "react-spring";
import { Container } from "react-bootstrap";
import { FiMapPin } from "react-icons/fi";
import { Link } from "react-router-dom";

import moment from "moment";
const CityImage = ({ cityName }) => {
  const [imageUrl, setImageUrl] = useState("");

  useEffect(() => {
    const fetchImage = async () => {
      try {
        const response = await fetch(
          `https://api.unsplash.com/photos/random?query=${cityName}&client_id=QFma38LsbVXAwtzwSVLi4kYQVrCQbzGyzHNtJsbWpAg`
        );

        const data = await response.json();

        if (data.urls && data.urls.full) {
          setImageUrl(data.urls.full);
        } else {
          console.error("Image not found");
          setImageUrl(
            "https://cdn.pixabay.com/photo/2021/05/13/07/18/sculpture-6250058_1280.jpg"
          );
        }
      } catch (error) {
        console.error("Error fetching image:", error);
      }
    };

    fetchImage();
  }, [cityName]);

  return (
    <img
      src={imageUrl}
      alt={`City: ${cityName}`}
      style={{ height: "50px", width: "50px" }}
      // class="featuredImg12 img-fluid"
    />
  );
};
const Header = ({ type }) => {
  const user = JSON.parse(sessionStorage.getItem("user"));
  const catresponsive = {
    0: {
      items: 1,
    },
    600: {
      items: 2,
    },
    1000: {
      items: 4,
    },
  };
  const [data, setdata] = useState([]);
  const [data1, setdata1] = useState([]);
  const [data2, setdata2] = useState([]);
  const [data3, setdata3] = useState([]);
  const [banner, setbanner] = useState([]);

  const [currentIndex, setCurrentIndex] = useState(0);
  const getapartment = async () => {
    let res = await axios.get(
      "https://bookingoda.com/api/admin/getallApartment"
    );
    if (res.status === 200) {
      //////console.log(res.data);
      setdata(
        res.data.Apartment.filter(
          (item) =>
            item.status === "Approved" &&
            item.Deletestatus !== "Deleted By Admin" &&
            item.blockstatus !== true
        )
      );
    }
  };
  const getalternative = async () => {
    let res = await axios.get(
      "https://bookingoda.com/api/admin/getallalternative"
    );
    if (res.status === 200) {
      //////console.log(res.data);
      setdata2(
        res.data.alternative.filter(
          (item) =>
            item.status === "Approved" &&
            item.Deletestatus !== "Deleted By Admin" &&
            item.blockstatus !== true
        )
      );
    }
  };
  const gethotel = async () => {
    let res = await axios.get("https://bookingoda.com/api/admin/getallhotel");
    if (res.status === 200) {
      //////console.log(res.data);
      setdata1(
        res.data.hotel.filter(
          (item) =>
            item.status === "Approved" &&
            item.Deletestatus !== "Deleted By Admin" &&
            item.blockstatus !== true
        )
      );
    }
  };
  const gethomestay = async () => {
    let res = await axios.get("https://bookingoda.com/api/admin/getallhome");
    if (res.status === 200) {
      //////console.log(res.data);
      setdata3(
        res.data.home.filter(
          (item) =>
            item.status === "Approved" &&
            item.Deletestatus !== "Deleted By Admin" &&
            item.blockstatus !== true
        )
      );
    }
  };

  useEffect(() => {
    getapartment();
    gethotel();
    getalternative();
    gethomestay();
  }, []);
  const data4 = [...data, ...data1, ...data2, ...data3];
  const datasss = data4.map((e) => e.address.map((data) => data));
  const cityNames = datasss.map((address, index) => {
    if (Array.isArray(address) && address[0]) {
      const cityArray = address[0].split(","); // Split the address string by commas
      if (cityArray.length >= 4) {
        const cityName = cityArray[cityArray.length - 4]?.trim(); // Use optional chaining to avoid 'undefined' error
        return cityName || null; // Provide a default value if city name is not found
      } else {
        //console.log(`Invalid address format at index ${index}: ${address}`);
        return null;
      }
    } else {
      //console.log(`Invalid address format at index ${index}: ${address}`);
      return null;
    }
  });

  const uniqueAddresses = [...new Set(cityNames.flat())];
  ////console.log(datasss);
  const dataProperty = data4.map((e) => e.PropertyName);

  const Unique = [...uniqueAddresses, ...dataProperty];

  const [openDate, setOpenDate] = useState(false);
  const [date, setDate] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: "selection",
    },
  ]);
  const handleDateChange = (selectedDates) => {
    setDate([selectedDates.selection]);
  };
  const startDate = date[0].startDate.setHours(0, 0, 0, 0);
  const endDate = date[0].endDate.setHours(0, 0, 0, 0);
  const [openOptions, setOpenOptions] = useState(false);
  const [options, setOptions] = useState({
    adult: 1,
    children: 0,
    room: 1,
    childrenAges: [], // Initializing childrenAges as an empty array
  });
  ////console.log(date);
  const timeDifference = endDate - startDate;

  // Convert the difference to days
  const daysDifference = timeDifference / (1000 * 60 * 60 * 24);
  ////console.log(daysDifference, "sadfsad");
  const cityOptions = [
    { value: "Mumbai", label: "Mumbai" },
    { value: "Delhi", label: "Delhi" },
    { value: "Bangalore", label: "Bangalore" },
    { value: "Chennai", label: "Chennai" },
    { value: "Kolkata", label: "Kolkata" },
    { value: "Hyderabad", label: "Hyderabad" },
    { value: "Pune", label: "Pune" },
    { value: "Ahmedabad", label: "Ahmedabad" },
    { value: "Jaipur", label: "Jaipur" },
    { value: "Lucknow", label: "Lucknow" },
  ];

  const navigate = useNavigate();

  const handleOption = (name, operation) => {
    setOptions((prev) => {
      const newValue = operation === "i" ? prev[name] + 1 : prev[name] - 1;
      let newChildrenAges = [...prev.childrenAges];

      if (name === "children") {
        if (operation === "i") {
          newChildrenAges.push(0); // Add a default age
        } else {
          newChildrenAges.pop(); // Remove the last age entry
        }
      }

      return {
        ...prev,
        [name]: newValue,
        childrenAges: newChildrenAges,
      };
    });
  };

  const handleSearch = () => {
    if (destination !== "") {
      postRecent();
      navigate("/hotels", { state: { destination, date, options } });
    } else {
      navigate("/hotels", { state: { destination, date, options } });
    }
  };
  const postRecent = async () => {
    try {
      const config = {
        url: "/postRecentSearch",
        method: "post",
        baseURL: "https://bookingoda.com/api/user",

        headers: { "content-type": "application/json" },
        data: {
          userId: user._id,
          place: destination,
          selectedDates: date,
          adults: options.adult,
          children: options.children,
          rooms: options.room,
        },
      };
      await axios(config).then(function (response) {
        if (response.status === 200) {
          ////console.log("success");

          getRecentSearch();
        }
      });
    } catch (error) {
      console.error(error);
    }
  };
  const getbanner = async () => {
    let res = await axios.get("https://bookingoda.com/api/admin/getallbanner");
    if ((res.status = 200)) {
      ////console.log(res);
      setbanner(res.data?.banner);
    }
  };
  useEffect(() => {
    // getcountry();
    getbanner();
  }, []);

  const AutoplaySlider = withAutoplay(AwesomeSlider);

  const images = banner.map(
    (item) => `https://bookingoda.com/image/${item.banner}`
  );
  const autoplayInterval = banner.length > 0 ? banner[currentIndex].Sec : 3000;

  // Handle changing the current index to move to the next banner
  const handleSlideChange = (index) => {
    setCurrentIndex(index);
  };
  //console.log(images);
  const [destination, setDestination] = useState("");
  const [filteredOptions, setFilteredOptions] = useState(Unique);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  // const handleDestinationChange = (e) => {
  //   const input = e.target.value;
  //   setDestination(input);

  //   // Filter options based on the input
  //   const filtered = Unique.filter((data) =>
  //     data?.toLowerCase().includes(input.toLowerCase())
  //   );
  //   setFilteredOptions(filtered);
  //   setIsDropdownOpen(true);
  // };
  const handleOptionSelect = useCallback((selectedOption) => {
    setDestination(selectedOption);
    setIsDropdownOpen(false);
  }, []);
  const handleDestinationChange = (e) => {
    const input = e.target.value;
    setDestination(input);

    // Filter options based on the input from both arrays
    const filteredOptions = IndianCities.cities
      .filter((data) => data.name?.toLowerCase().includes(input.toLowerCase()))
      .concat(
        data4.filter((data) =>
          data?.PropertyName.toLowerCase().includes(input.toLowerCase())
        )
      );

    setFilteredOptions(filteredOptions);
    setIsDropdownOpen(true);
  };
  const [RecentSearch, setRecentSearch] = useState([]);

  const getRecentSearch = async () => {
    let res = await axios.get(
      "https://bookingoda.com/api/user/getallRecentSearch"
    );
    if ((res.status = 200)) {
      setRecentSearch(
        res.data?.RecentSearch.filter((data) => data.userId === user?._id)
      );
    }
  };
  useEffect(() => {
    getRecentSearch();
  }, []);
  const handleAgeChange = (index, newAge) => {
    if (newAge >= 0 && newAge <= 17) {
      setOptions((prev) => {
        const newChildrenAges = [...prev.childrenAges];
        newChildrenAges[index] = newAge;
        return {
          ...prev,
          childrenAges: newChildrenAges,
        };
      });
    }
  };
  return (
    <>
      <AutoplaySlider
        play={true}
        cancelOnInteraction={false} // should stop playing on user interaction
        interval={autoplayInterval}
        organicArrows={false}
        bullets={false}
        buttons={false}
        style={{ height: "500px" }}
      >
        {banner.map((data, index) => (
          <>
            <section
              style={{ position: "relative" }}
              className="hero-section"
              data-scrollax-parent="true"
              id="sec1"
            >
              <div className="hero-parallax">
                <div
                  data-bg={` url('https://bookingoda.com/image/${data.banner}')`}
                  data-scrollax="properties: { translateY: '200px' }"
                  className="header-slide"
                  style={{
                    // background: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(103, 71, 54, 0.4)), url('https://bookingoda.com/image/${data.banner}')`,
                    backgroundImage: ` url('https://bookingoda.com/image/${data.banner}')`,
                    // transform: "translateZ(0px) translateY(107.74px)",
                    backgroundSize: "cover",
                    height: "500px",
                    width: "100%",
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "bottom",
                    alignItems: "center",
                    position: "relative",
                    // color: 'white',
                  }}
                />
                <div className="overlay op7" />
              </div>
              <div className="hero-section-wrap fl-wrap">
                <div className="containers" style={{ marginTop: "30px" }}>
                  <div className="home-intro">
                    <div className="section-title-separator">
                      <span>
                        <i className="fas fa-star" />
                        <i className="fas fa-star" />
                        <i className="fas fa-star" />
                      </span>
                    </div>
                    <h2>Booking-Oda.com Hotel Booking System</h2>
                    <span className="section-separator" />
                    <h3>
                      Let's start exploring the world together with
                      Booking-Oda.com
                    </h3>
                  </div>
                </div>
              </div>
              {/* <div className="header-sec-link">
              <div className="containers">
                <a href="#sec1" className="custom-scroll-link color-bg">
                  <i className="fa fa-angle-double-down" />
                </a>
              </div>
            </div> */}
            </section>
          </>
        ))}
      </AutoplaySlider>
      <Container style={{ marginTop: "30px" }}>
        <div className="headerSearch12">
          <div className="headerSearchItem12">
            <i className="bx bx-location-plus headerIcon12"></i>

            <FontAwesomeIcon
              icon={faBed}
              color="#0f7cba"
              style={{ fontSize: "20px" }}
            />
            <span style={{ color: "#0f7cba", fontWeight: "bold" }}>
              Destination/Property
            </span>
            {/* <select
              id="cityDropdown"
              className="headerSearchInput12"
              value={destination}
              onChange={(e) => setDestination(e.target.value)}
            >
              <option value="" disabled>
                Where are you going?
              </option>
              {uniqueAddresses.map((data) => (
                <option value={data}>{data}</option>
              ))}
            </select> */}

            <input
              type="text"
              placeholder="Where are you going?"
              value={destination}
              onChange={handleDestinationChange}
              onFocus={() => setIsDropdownOpen(true)}
              onBlur={() => setIsDropdownOpen(false)}
              style={{ color: "black" }}
              className="headerSearchInput12"
            />
            {destination !== "" && isDropdownOpen && (
              <div
                className="date"
                style={{
                  backgroundColor: "white",
                  color: "black",
                  overflowY: "scroll",
                  height: "200px",
                  width: "350px",
                }}
              >
                {filteredOptions.map((data) => (
                  <div
                    key={data.name}
                    className="dropdown-item"
                    onMouseDown={() => {
                      //console.log("Clicked on item:", data);
                      handleOptionSelect(
                        data.name !== undefined ? data.name : data.PropertyName
                      );
                    }}
                  >
                    {data.name ? <>📍 {data.name}</> : null}
                    {data.PropertyName ? <>📍 {data.PropertyName}</> : null}
                  </div>
                ))}
              </div>
            )}
          </div>
          <div className="headerSearchItem12">
            <i className="bx bxs-calendar-plus headerIcon12"></i>
            <FontAwesomeIcon
              icon={faCalendarDays}
              color="#0f7cba"
              style={{ fontSize: "20px", marginLeft: "0px" }}
            />
            <span
              onClick={() => setOpenDate(!openDate)}
              className="headerSearchText12"
            >{`${format(startDate, "MM/dd/yyyy")} to ${format(
              endDate,
              "MM/dd/yyyy"
            )}`}</span>
            {openDate && (
              <DateRange
                editableDateInputs={true}
                onChange={handleDateChange}
                moveRangeOnFirstSelection={false}
                ranges={date}
                className="date"
                minDate={new Date()}
              />
            )}
          </div>
          <div className="headerSearchItem12">
            <FontAwesomeIcon
              icon={faPerson}
              color="#0f7cba"
              style={{ fontSize: "20px", marginLeft: "0px" }}
            />
            <span
              onClick={() => setOpenOptions(!openOptions)}
              className="headerSearchText12"
            >{`${options.adult} adult · ${options.children} children · ${options.room} room`}</span>
            {openOptions && (
              <div className="options">
                <div className="optionItem">
                  <span className="optionText">Adult</span>
                  <div className="optionCounter">
                    <button
                      disabled={options.adult <= 1}
                      className="optionCounterButton"
                      onClick={() => handleOption("adult", "d")}
                    >
                      -
                    </button>
                    <span className="optionCounterNumber">{options.adult}</span>
                    <button
                      className="optionCounterButton"
                      onClick={() => handleOption("adult", "i")}
                    >
                      +
                    </button>
                  </div>
                </div>
                <div className="optionItem">
                  <span className="optionText">Children</span>
                  <div className="optionCounter">
                    <button
                      disabled={options.children <= 0}
                      className="optionCounterButton"
                      onClick={() => handleOption("children", "d")}
                    >
                      -
                    </button>
                    <span className="optionCounterNumber">
                      {options.children}
                    </span>
                    <button
                      className="optionCounterButton"
                      onClick={() => handleOption("children", "i")}
                    >
                      +
                    </button>
                  </div>
                </div>
                {options.children > 0 && (
                  <div className="childrenAges">
                    {options.childrenAges.map((age, index) => (
                      <div key={index} className="optionItem">
                        <span className="optionText">
                          Child {index + 1} Age
                        </span>
                        <input
                          type="number"
                          onKeyDown={(event) => {
                            if (
                              !/[0-9]/.test(event.key) &&
                              event.key !== "Backspace" &&
                              event.key !== "ArrowLeft" &&
                              event.key !== "ArrowRight" &&
                              event.key !== "Delete"
                            ) {
                              event.preventDefault();
                            }
                          }}
                          value={age === 0 ? "" : age} // conditionally render the value
                          onChange={(e) => {
                            const value =
                              e.target.value === ""
                                ? 0
                                : parseInt(e.target.value);
                            handleAgeChange(index, value);
                          }}
                          className="ageInput"
                        />
                      </div>
                    ))}
                  </div>
                )}
                <div className="optionItem">
                  <span className="optionText">Room</span>
                  <div className="optionCounter">
                    <button
                      disabled={options.room <= 1}
                      className="optionCounterButton"
                      onClick={() => handleOption("room", "d")}
                    >
                      -
                    </button>
                    <span className="optionCounterNumber">{options.room}</span>
                    <button
                      className="optionCounterButton"
                      onClick={() => handleOption("room", "i")}
                    >
                      +
                    </button>
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className="headerSearchButton12">
            <button
              className="headerBtn12 active"
              onClick={handleSearch}
              style={{ backgroundColor: "#18458b", color: "white" }}
            >
              Search
            </button>
          </div>
        </div>
      </Container>

      {user && RecentSearch.length > 0 ? (
        <>
          {" "}
          <div style={{ margin: 40 }}>
            <h3>Your Recent Searches</h3>
          </div>
          <div style={{ display: "flex", marginLeft: 30 }}>
            <OwlCarousel
              className="topdeals-carousel"
              loop
              margin={10}
              nav={true}
              autoplayTimeout={5000}
              responsive={catresponsive}
              autoplay={true}
              autoHeightClass="owl-height"
            >
              {RecentSearch.map((data, index) => (
                <Link to={`/hotels`} state={{ destination: data.place }}>
                  <div
                    key={index}
                    style={{
                      width: 300,
                      padding: 8,
                      borderRadius: 4,
                      marginRight: 20,
                      marginBottom: 20,
                      backgroundColor: "#ffffff",
                      boxShadow: "0 2px 8px rgba(26, 26, 26, 0.16)", // Adding box shadow
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        padding: 8,
                        borderRadius: 4,
                        backgroundColor: "white",
                      }}
                    >
                      <CityImage cityName={data.place} />
                      <div
                        style={{
                          flex: 1,
                          justifyContent: "center",
                          marginLeft: "20px",
                        }}
                      >
                        <h2
                          style={{
                            fontSize: 16,
                            fontWeight: "bold",
                            marginBottom: 4,
                            color: "black",
                          }}
                        >
                          {data.place}
                        </h2>
                        <p style={{ fontSize: 14, color: "black" }}>
                          {moment(data.selectedDates.startDate).format(
                            "DD MMM"
                          )}{" "}
                          –{" "}
                          {moment(data.selectedDates.endDate).format("DD MMM")},{" "}
                          {data.adults + data.children} people
                        </p>
                      </div>
                    </div>
                  </div>
                </Link>
              ))}
            </OwlCarousel>{" "}
          </div>
        </>
      ) : (
        ""
      )}
    </>
  );
};

export default Header;
const customStyles = {
  control: (provided) => ({
    ...provided,
    border: "1px solid #ccc",
    borderRadius: "4px",
    minHeight: "38px",
  }),
  input: (provided) => ({
    ...provided,
    margin: "0",
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    color: "#555",
  }),
};
