import { useEffect, useState } from "react";
import "./featuredProperties.css";
import axios from "axios";
import { Link, useLocation } from "react-router-dom";
import { GrMapLocation } from "react-icons/gr";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBanSmoking, faSquareParking, faUtensils, faWifi } from "@fortawesome/free-solid-svg-icons";

const FeaturedProperties = () => {
  const [data, setdata] = useState([]);
  const [data1, setdata1] = useState([]);
  const [data2, setdata2] = useState([]);
  const [data3, setdata3] = useState([]);

  const getapartment = async () => {
    let res = await axios.get(
      "https://bookingoda.com/api/admin/getallApartment"
    );
    if (res.status === 200) {
      //////console.log(res.data);
      setdata(
        res.data.Apartment.filter(
          (item) =>
            item.status === "Approved" &&
            item.Deletestatus !== "Deleted By Admin" &&
            item.blockstatus !== true
        )
      );
    }
  };
  const getalternative = async () => {
    let res = await axios.get(
      "https://bookingoda.com/api/admin/getallalternative"
    );
    if (res.status === 200) {
      //////console.log(res.data);
      setdata2(
        res.data.alternative.filter(
          (item) =>
            item.status === "Approved" &&
            item.Deletestatus !== "Deleted By Admin" &&
            item.blockstatus !== true
        )
      );
    }
  };
  const gethotel = async () => {
    let res = await axios.get("https://bookingoda.com/api/admin/getallhotel");
    if (res.status === 200) {
      //////console.log(res.data);
      setdata1(
        res.data.hotel.filter(
          (item) =>
            item.status === "Approved" &&
            item.Deletestatus !== "Deleted By Admin" &&
            item.blockstatus !== true
        )
      );
    }
  };
  const gethomestay = async () => {
    let res = await axios.get("https://bookingoda.com/api/admin/getallhome");
    if (res.status === 200) {
      //////console.log(res.data);
      setdata3(
        res.data.home.filter(
          (item) =>
            item.status === "Approved" &&
            item.Deletestatus !== "Deleted By Admin" &&
            item.blockstatus !== true
        )
      );
    }
  };
  const [date, setDate] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: "selection",
    },
  ]);

  const [options, setOptions] = useState({
    adult: 1,
    children: 0,
    room: 1,
  });
  useEffect(() => {
    getapartment();
    gethotel();
    getalternative();
    gethomestay();
  }, []);
  const data4 = [...data, ...data1, ...data2, ...data3];
  const datasss = data4.map((e) => e.address.map((data) => data));
  const cityNames = datasss.map((address, index) => {
    if (Array.isArray(address) && address[0]) {
      const cityArray = address[0].split(","); // Split the address string by commas
   
      if (cityArray.length >= 3) {
        const cityName = cityArray[cityArray.length - 3]?.trim(); // Use optional chaining to avoid 'undefined' error
        return cityName || null; // Provide a default value if city name is not found
      } else {
        return null;
      }
    } else {
      return null;
    }
  });

  const uniqueAddresses = [...new Set(cityNames.flat())];
  //console.log(uniqueAddresses.reverse()[3],"array");
  //console.log(data4.filter((item)=> item.address[0].includes(uniqueAddresses.reverse()[2])),"data");
  return (
 
    <div className="fp row13 gy-4">
   {data.slice(0,1).map((data)=>( <div className="col-md-6 col-lg-3">
      <div>
     
        <div></div>
      <Link
        to={`/hotels/${data?._id}`}
        state={{ date: date, options: options }}
      >
      
            <div>
              <div
                className="slick-slide-item"
                style={{ width: "100%", display: "inline-block" }}
              >
                {/* listing-item  */}
                <div className="listing-item">
                  <article className="geodir-category-listing fl-wrap">
                    <div className="geodir-category-img">
                      <a  tabIndex={0}>
                        <img  src={`https://bookingoda.com/image/${data?.Photos[0]?.file}`} alt="" style={{height:"200px"}} />
                      </a>
                     
                   
                    </div>
                    <div className="geodir-category-content fl-wrap title-sin_item">
                      <div className="geodir-category-content-title fl-wrap">
                    
                        <div className="geodir-category-content-title-item">
                          <h3 className="title-sin_map">
                            <a  tabIndex={0}>
                            {data?.PropertyName.slice(0,20)}
                            </a>
                          </h3>
                          <div className="geodir-category-location fl-wrap">
                         
                            <a href="#" className="map-item" tabIndex={0} style={{width:"200px"}}>
                              <GrMapLocation /> {data?.address?.map((data) => data.slice(0,40)).join(", ")+"..."}
                            </a>
                          </div>
                        </div>
                      </div>
                     
                      <ul className="facilities-list fl-wrap">
                        <li>
                        <FontAwesomeIcon icon={faWifi} />
                          <span>Free WiFi</span>
                        </li>
                        <li>
                        <FontAwesomeIcon icon={faSquareParking} />
                          <span>Parking</span>
                        </li>
                        <li>
                        <FontAwesomeIcon icon={faBanSmoking} />
                          <span>Non-smoking Rooms</span>
                        </li>
                        <li>
                        <FontAwesomeIcon icon={faUtensils} />
                          <span> Restaurant</span>
                        </li>
                      </ul>
                      <div className="geodir-category-footer fl-wrap">
                        <div className="geodir-opt-link">
                          <div className="geodir-category-price">
                            Awg/Night{" "}
                            <span>
                              ₹
                              {parseInt(data?.Price) }
                            </span>
                          </div>
                        </div>
                     
                      </div>
                    </div>
                  </article>
                </div>
                {/* listing-item end */}
              </div>
            </div>
        </Link>
      </div>
    </div>))}
    {data1.slice(0,1).map((data)=>( <div className="col-md-6 col-lg-3">
      <div>
     
        <div></div>
      <Link
        to={`/hotels/${data?._id}`}
        state={{ date: date, options: options }}
      >
      
            <div>
              <div
                className="slick-slide-item"
                style={{ width: "100%", display: "inline-block" }}
              >
                {/* listing-item  */}
                <div className="listing-item">
                  <article className="geodir-category-listing fl-wrap">
                    <div className="geodir-category-img">
                      <a  tabIndex={0}>
                        <img  src={`https://bookingoda.com/image/${data?.Photos[0]?.file}`} alt="" style={{height:"200px"}} />
                      </a>
                     
                   
                    </div>
                    <div className="geodir-category-content fl-wrap title-sin_item">
                      <div className="geodir-category-content-title fl-wrap">
                    
                        <div className="geodir-category-content-title-item">
                          <h3 className="title-sin_map">
                            <a  tabIndex={0}>
                            {data?.PropertyName.slice(0,20)}
                            </a>
                          </h3>
                          <div className="geodir-category-location fl-wrap">
                         
                            <a href="#" className="map-item" tabIndex={0} style={{width:"200px"}}>
                              <GrMapLocation /> {data?.address?.map((data) => data.slice(0,40)).join(", ")+"..."}
                            </a>
                          </div>
                        </div>
                      </div>
                     
                      <ul className="facilities-list fl-wrap">
                        <li>
                        <FontAwesomeIcon icon={faWifi} />
                          <span>Free WiFi</span>
                        </li>
                        <li>
                        <FontAwesomeIcon icon={faSquareParking} />
                          <span>Parking</span>
                        </li>
                        <li>
                        <FontAwesomeIcon icon={faBanSmoking} />
                          <span>Non-smoking Rooms</span>
                        </li>
                        <li>
                        <FontAwesomeIcon icon={faUtensils} />
                          <span> Restaurant</span>
                        </li>
                      </ul>
                      <div className="geodir-category-footer fl-wrap">
                        <div className="geodir-opt-link">
                          <div className="geodir-category-price">
                            Awg/Night{" "}
                            <span>
                              ₹
                              {parseInt(data?.Price) }
                            </span>
                          </div>
                        </div>
                     
                      </div>
                    </div>
                  </article>
                </div>
                {/* listing-item end */}
              </div>
            </div>
        </Link>
      </div>
    </div>))}
    {data2.slice(0,1).map((data)=>( <div className="col-md-6 col-lg-3">
      <div>
     
        <div></div>
      <Link
        to={`/hotels/${data?._id}`}
        state={{ date: date, options: options }}
      >
      
            <div>
              <div
                className="slick-slide-item"
                style={{ width: "100%", display: "inline-block" }}
              >
                {/* listing-item  */}
                <div className="listing-item">
                  <article className="geodir-category-listing fl-wrap">
                    <div className="geodir-category-img">
                      <a  tabIndex={0}>
                        <img  src={`https://bookingoda.com/image/${data?.Photos[0]?.file}`} alt="" style={{height:"200px"}} />
                      </a>
                     
                   
                    </div>
                    <div className="geodir-category-content fl-wrap title-sin_item">
                      <div className="geodir-category-content-title fl-wrap">
                    
                        <div className="geodir-category-content-title-item">
                          <h3 className="title-sin_map">
                            <a  tabIndex={0}>
                            {data?.PropertyName.slice(0,20)}
                            </a>
                          </h3>
                          <div className="geodir-category-location fl-wrap">
                         
                            <a href="#" className="map-item" tabIndex={0} style={{width:"200px"}}>
                              <GrMapLocation /> {data?.address?.map((data) => data.slice(0,40)).join(", ")+"..."}
                            </a>
                          </div>
                        </div>
                      </div>
                     
                      <ul className="facilities-list fl-wrap">
                        <li>
                        <FontAwesomeIcon icon={faWifi} />
                          <span>Free WiFi</span>
                        </li>
                        <li>
                        <FontAwesomeIcon icon={faSquareParking} />
                          <span>Parking</span>
                        </li>
                        <li>
                        <FontAwesomeIcon icon={faBanSmoking} />
                          <span>Non-smoking Rooms</span>
                        </li>
                        <li>
                        <FontAwesomeIcon icon={faUtensils} />
                          <span> Restaurant</span>
                        </li>
                      </ul>
                      <div className="geodir-category-footer fl-wrap">
                        <div className="geodir-opt-link">
                          <div className="geodir-category-price">
                            Awg/Night{" "}
                            <span>
                              ₹
                              {parseInt(data?.Price) }
                            </span>
                          </div>
                        </div>
                     
                      </div>
                    </div>
                  </article>
                </div>
                {/* listing-item end */}
              </div>
            </div>
        </Link>
      </div>
    </div>))}
    {data3.slice(0,1).map((data)=>( <div className="col-md-6 col-lg-3">
      <div>
     
        <div></div>
      <Link
        to={`/hotels/${data?._id}`}
        state={{ date: date, options: options }}
      >
      
            <div>
              <div
                className="slick-slide-item"
                style={{ width: "100%", display: "inline-block" }}
              >
                {/* listing-item  */}
                <div className="listing-item">
                  <article className="geodir-category-listing fl-wrap">
                    <div className="geodir-category-img">
                      <a  tabIndex={0}>
                        <img  src={`https://bookingoda.com/image/${data?.Photos[0]?.file}`} alt="" style={{height:"200px"}} />
                      </a>
                     
                   
                    </div>
                    <div className="geodir-category-content fl-wrap title-sin_item">
                      <div className="geodir-category-content-title fl-wrap">
                    
                        <div className="geodir-category-content-title-item">
                          <h3 className="title-sin_map">
                            <a  tabIndex={0}>
                            {data?.PropertyName}
                            </a>
                          </h3>
                          <div className="geodir-category-location fl-wrap">
                         
                            <a href="#" className="map-item" tabIndex={0} style={{width:"200px"}}>
                              <GrMapLocation /> {data?.address?.map((data) => data.slice(0,40)).join(", ")+"..."}
                            </a>
                          </div>
                        </div>
                      </div>
                     
                      <ul className="facilities-list fl-wrap">
                        <li>
                        <FontAwesomeIcon icon={faWifi} />
                          <span>Free WiFi</span>
                        </li>
                        <li>
                        <FontAwesomeIcon icon={faSquareParking} />
                          <span>Parking</span>
                        </li>
                        <li>
                        <FontAwesomeIcon icon={faBanSmoking} />
                          <span>Non-smoking Rooms</span>
                        </li>
                        <li>
                        <FontAwesomeIcon icon={faUtensils} />
                          <span> Restaurant</span>
                        </li>
                      </ul>
                      <div className="geodir-category-footer fl-wrap">
                        <div className="geodir-opt-link">
                          <div className="geodir-category-price">
                            Awg/Night{" "}
                            <span>
                              ₹
                              {parseInt(data?.Price) }
                            </span>
                          </div>
                        </div>
                     
                      </div>
                    </div>
                  </article>
                </div>
                {/* listing-item end */}
              </div>
            </div>
        </Link>
      </div>
    </div>))}
    <div class="section-decor"></div>
  </div>
  );
};

export default FeaturedProperties;
