import { React, useEffect, useState } from "react";
import CustomInput from "../components/CustomInput";
import ReactQuill from "react-quill";
import { useNavigate } from "react-router-dom";
import "react-quill/dist/quill.snow.css";
import { toast } from "react-toastify";
import * as yup from "yup";
import { useFormik } from "formik";

import { Select, Table } from "antd";
import Dropzone, { useDropzone } from "react-dropzone";

import Register from "../../pages/register/Register";
import axios from "axios";
import { Modal } from "react-bootstrap";
import Success from "../../assets/success.png";
import Error from "../../assets/close.png";
import { MdDelete } from "react-icons/md";
let schema = yup.object().shape({});

const AddCategory = () => {
  const [category, setcategory] = useState("");
  const [categoryimage, setcategoryimage] = useState();
  const [categorydata, setcategorydata] = useState([]);
  const [categoryadd, setcategoryadd] = useState(true);
  const [categorylist, setcategorylist] = useState(false);
  const handlecategory = () => {
    setcategoryadd(true);
    setcategorylist(false);
  };
  const handlecategorylist = () => {
    setcategoryadd(false);
    setcategorylist(true);
  };

  const formik = useFormik({
    initialValues: {
      images: "",
    },
    validationSchema: schema,
    onSubmit: (values) => {
      ////console.log(values);
    },
  });
  const formdata = new FormData();

  const Register1 = async (e) => {
    e.preventDefault();

    formdata.append("RSAcategoryName", category);
    formdata.append("categoryimage", categoryimage);
    if(!category || ! categoryimage){
      alert("Please add category name and image")
    } else {
      try {
        const config = {
          url: "/addRSACategory",
          method: "post",
          baseURL: "https://bookingoda.com/api/admin",

          // headers: { "content-type": "application/json" },
          data: formdata,
          // data: {banner:uploadedImages},
        };
        await axios(config).then(function (response) {
          if (response.status === 200) {
            ////console.log("success");
            handlesuccess();
            getcategory();
            setcategory("");
            setcategoryimage();
          }
        });
      } catch (error) {
        console.error(error);
        handleerror();
      }
    }
  };
 
  const remove = async (datas) => {
    try {
      const config = {
        url: `/deleteRSACategory/${datas._id}`,
        method: "delete",
        baseURL: "https://bookingoda.com/api/admin",
        headers: { "Content-Type": "application/json" },
      };

      let res = await axios(config);
      if (res.status === 200) {
        alert("Successfully removed");
        getcategory();
        
      } else {
        alert("Something Wrong");
      }
    } catch (error) {
      if (error.response) {
        alert("Something went wrong");
      }
    }
  };
  const [successshow, setsuccessshow] = useState(false);
  const handlesuccessClose = () => setsuccessshow(false);
  const handlesuccess = () => setsuccessshow(true);

  const [errorshow, seterrorshow] = useState(false);
  const handleerrorClose = () => seterrorshow(false);
  const handleerror = () => seterrorshow(true);
  const getcategory = async () => {
    let res = await axios.get(
      "https://bookingoda.com/api/admin/getRSACategory"
    );
    if ((res.status = 200)) {
      ////console.log(res);
      setcategorydata(res.data?.RSAcategory);
    }
  };
  useEffect(() => {
    // getcountry();
    getcategory();
  }, []);
  const columns = [
    {
      title: "Serial No.",
      dataIndex: "Sno",

      // //fixed: "left",
      // width: 50,
    },
    {
      title: "Unique ID",
      dataIndex: "UniqueId",

      // //fixed: "left",
    },
    {
      title: "Category Name",
      dataIndex: "Category",
      width: 550,
    },
    {
      title: "Category Image",
      dataIndex: "Image",
      width: 550,

      render: (cell, row) => {
        ////console.log(row, "row");
        return (
          <div
            style={{
              overflowY: "scroll",
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap",
              justifyContent: "space-between",
            }}
          >
            <img
              src={"https://bookingoda.com/image/" + row.Image}
              alt="image"
              style={{ width: "100px", height: "100px" }}
            />
          </div>
        );
      },
    },
    {
      title: "Action",
      dataIndex: "action1",
      key: "operation",
      //fixed: "right",

      // render: () => <Link className="ms-3 fs-8 text-success">Approve</Link>,
    },
  ];
  const data1 = [];
  for (let i = 0; i < categorydata?.length; i++) {
    data1.push({
      Sno: i + 1,
      UniqueId: "BORSACAT" + categorydata[i]._id.slice(22, 24).toUpperCase(),

      Category:categorydata[i].RSAcategoryName,
      Image:categorydata[i].categoryimage,

      action1: (
        <>
          <div className="d-flex gap-2 fs-4" style={{ cursor: "pointer" }}>
            <MdDelete
              className="text-danger"
              onClick={() => remove(categorydata[i])}
            />
          </div>
        </>
      ),
    });
  }
  return (
    <div>
      <h3 className="mb-4 title">Add RSA Category</h3>
      <button
        className="btn btn-success border-0 rounded-3 my-5 mr-5"
        type="submit"
        onClick={handlecategory}
      >
        Add Category
      </button>
      <button
        className="btn btn-success border-0 rounded-3 my-5"
        type="submit"
        onClick={handlecategorylist}
      >
        Category List
      </button>
      {categoryadd ? (
        <div>
          <form
            onSubmit={formik.handleSubmit}
            className="d-flex gap-3 flex-column"
          >
            <div className="bg-white border-1 p-5">
              <CustomInput
                type="text"
                label="Category Name"
                name="Category"
                onChng={(e) => setcategory(e.target.value)}
                val={category}
              />
                  <label style={{ marginTop:"30px"}}>Upload Category Image</label>
            <div
              className="dropzone-container"
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                padding: "20px",
              }}
            >
              <div
                className="dropzone"
                style={{
                  border: "2px dashed #cccccc",
                  borderRadius: "4px",
                  padding: "20px",
                  textAlign: "center",
                  cursor: "pointer",
                  marginBottom: "20px",
                }}
              >
                <input
                  type="file"
                  id="fileInput"
                  style={{ display: "none" }} // Hide the default file input
                  accept="image/png, image/gif, image/jpeg"
                  onChange={(e) => setcategoryimage(e.target.files[0])}
                />
                <label htmlFor="fileInput">
                  {/* Use a label as a clickable area */}
                  <p>Click to select files</p>
                </label>
              </div>

              <img
                src={
                  categoryimage != undefined
                    ? URL.createObjectURL(categoryimage)
                    : null
                }
                style={{ height: "150px", width: "150px" }}
              />
            </div>
            </div>

            <button
              className="btn btn-success border-0 rounded-3 my-5"
              type="submit"
              onClick={Register1}
            >
              Add Category
            </button>
          </form>
        </div>
      ) : (
        ""
      )}
      {categorylist ? (
        <div>
          <Table
            columns={columns}
            className="custom-table"

            dataSource={data1}
            // scroll={{ x: "calc(700px + 50%)", y: 240 }}
            pagination={{
              pageSize: 5,
              showSizeChanger: false, // Optional: Hide the "Show X entries" dropdown
            }}
            components={{
              header: {
              
                cell: props => (
                  <th {...props} style={{ ...props.style, background:'linear-gradient(#ffa07a,#C98064,#59382B)'  ,color:"white"}} />
                ),
              },
            }}
          />
        </div>
      ) : (
        ""
      )}
      <Modal
        show={successshow}
        onHide={handlesuccess}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton onClick={handlesuccessClose}></Modal.Header>
        <Modal.Body>
          <div className="text-center">
            <img src={Success} style={{ marginTop: "-12px" }} width="100px" />

            <p>Successfully Added</p>
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        show={errorshow}
        onHide={handleerror}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton onClick={handleerrorClose}></Modal.Header>
        <Modal.Body>
          <div className="text-center">
            <img src={Error} style={{ marginTop: "-12px" }} width="100px" />

            <p>Error Occured</p>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default AddCategory;
