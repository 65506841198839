import { useEffect, useState } from "react";
import "./propertyList.css";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";

const PropertyList = () => {
  const navigate = useNavigate();
  const [data, setdata] = useState([]);
  const [data1, setdata1] = useState([]);
  const [data2, setdata2] = useState([]);
  const [data3, setdata3] = useState([]);
  const rsa = () => {
    navigate("/rsa");
  };
  const getapartment = async () => {
    let res = await axios.get(
      "https://bookingoda.com/api/admin/getallApartment"
    );
    if (res.status === 200) {
      //////console.log(res.data);
      setdata(
        res.data.Apartment.filter(
          (item) =>
            item.status === "Approved" &&
            item.Deletestatus !== "Deleted By Admin" && item.blockstatus !== true
        )
      );
    }
  };
  const getalternative = async () => {
    let res = await axios.get(
      "https://bookingoda.com/api/admin/getallalternative"
    );
    if (res.status === 200) {
      //////console.log(res.data);
      setdata2(
        res.data.alternative.filter(
          (item) =>
            item.status === "Approved" &&
            item.Deletestatus !== "Deleted By Admin" && item.blockstatus !== true
        )
      );
    }
  };
  const gethotel = async () => {
    let res = await axios.get("https://bookingoda.com/api/admin/getallhotel");
    if (res.status === 200) {
      //////console.log(res.data);
      setdata1(
        res.data.hotel.filter(
          (item) =>
            item.status === "Approved" &&
            item.Deletestatus !== "Deleted By Admin" && item.blockstatus !== true
        )
      );
    }
  };
  const gethomestay = async () => {
    let res = await axios.get("https://bookingoda.com/api/admin/getallhome");
    if (res.status === 200) {
      //////console.log(res.data);
      setdata3(
        res.data.home.filter(
          (item) =>
            item.status === "Approved" &&
            item.Deletestatus !== "Deleted By Admin" && item.blockstatus !== true
        )
      );
    }
  };
  const itemStyle = { height: '300px', overflow: 'hidden', position: 'relative' };
  const imgStyle = { width: '100%', height: '100%', objectFit: 'cover' };
  useEffect(() => {
    getapartment();
    gethotel();
    getalternative();
    gethomestay();
  }, []);
  return (
    <>
    
    <div style={{ 
      display: 'grid', 
      gap: '5px', 
      position: 'relative', 
      height: 'auto' 
    }}>
      {/* First Row: 2 items */}
      <div style={{ 
        display: 'grid', 
        gridTemplateColumns: 'repeat(2, 1fr)', 
        gap: '5px' 
      }}>
        <div style={itemStyle}>
          <div>
            <Link to={`/hotels`} state={{ list: data1 }}>
              <div className="listing-item-grid" style={itemStyle}>
                <div className="listing-counter"><span>{data1.length}</span> Hotels</div>
                <img src="https://cdn.pixabay.com/photo/2021/12/11/07/59/hotel-6862159_1280.jpg" alt="" style={imgStyle} />
                <div className="listing-item-cat">
                  <h3><a href="listing.html">Hotels</a></h3>
                  <div className="weather-grid" data-grcity="Rome" />
                  <div className="clearfix" />
                </div>
              </div>
            </Link>
          </div>
        </div>

        <div style={itemStyle}>
          <div>
            <Link to={`/hotels`} state={{ list: data1 }}>
              <div className="listing-item-grid" style={itemStyle}>
                <img src="https://cdn.pixabay.com/photo/2019/09/12/15/21/resort-4471852_1280.jpg" alt="" style={imgStyle} />
                <div className="listing-counter"><span>{data1.length}</span> Resorts</div>
                <div className="listing-item-cat">
                  <h3><a href="listing.html">Resorts</a></h3>
                  <div className="weather-grid" data-grcity="Paris" />
                  <div className="clearfix" />
                  {/* <div style={{ position: 'absolute', bottom: '10px', right: '10px', backgroundColor: '#fff', padding: '5px 10px', cursor: 'pointer' }} onClick={rsa}>RSA</div> */}
                </div>
              </div>
            </Link>
          </div>
        </div>
      </div>

      {/* Second Row: 3 items */}
      <div style={{ 
        display: 'grid', 
        gridTemplateColumns: 'repeat(3, 1fr)', 
        gap: '5px' 
      }}>
        <div style={itemStyle}>
          <div>
            <Link to={`/hotels`} state={{ list: data3 }}>
              <div className="listing-item-grid" style={itemStyle}>
                <div className="listing-counter"><span>{data3?.length}</span> Homestay</div>
                <img src="https://cdn.pixabay.com/photo/2017/10/11/14/54/vietnam-2841267_1280.jpg" alt="" style={imgStyle} />
                <div className="listing-item-cat">
                  <h3><a href="listing.html">Homestay</a></h3>
                  <div className="weather-grid" data-grcity="London" />
                  <div className="clearfix" />
                </div>
              </div>
            </Link>
          </div>
        </div>

        <div style={itemStyle}>
          <div>
            <Link to={`/hotels`} state={{ list: data }}>
              <div className="listing-item-grid" style={itemStyle}>
                <div className="listing-counter"><span>{data?.length}</span> Apartments</div>
                <img src="https://cdn.pixabay.com/photo/2022/05/22/17/22/building-7214070_1280.jpg" alt="" style={imgStyle} />
                <div className="listing-item-cat">
                  <h3><a href="listing.html">Apartment</a></h3>
                  <div className="weather-grid" data-grcity="London" />
                  <div className="clearfix" />
                </div>
              </div>
            </Link>
          </div>
        </div>

        <div style={itemStyle}>
          <div>
            <Link to={`/hotels`} state={{ list: data2 }}>
              <div className="listing-item-grid" style={itemStyle}>
                <div className="listing-counter"><span>{data2.length}</span> cabins</div>
                <img src="https://cdn.pixabay.com/photo/2016/02/10/07/12/evening-1191187_1280.jpg" alt="" style={imgStyle} />
                <div className="listing-item-cat">
                  <h3><a href="listing.html">Alternative Locations</a></h3>
                  <div className="weather-grid" data-grcity="Dubai" />
                  <div className="clearfix" />
                </div>
              </div>
            </Link>
          </div>
        </div>
      </div>
    </div>
    </>
  );
};

export default PropertyList;
