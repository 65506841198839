import React, { useEffect, useState } from "react";
import CustomInput from "../components/CustomInput";
import { Link, useNavigate } from "react-router-dom";
import * as yup from "yup";
import { useFormik } from "formik";

import axios from "axios";
import Success from '../../assets/success.png'
import Error from '../../assets/close.png'
import { Container, Form, Modal, Row, Table } from "react-bootstrap";
import { initializeApp } from "firebase/app";
import { getMessaging, getToken } from "firebase/messaging";

const firebaseConfig = {
  apiKey: "AIzaSyCKxU1uQtQhgOkBddkbe1NKsFSXJ_v4ico",
  authDomain: "mukkatis.firebaseapp.com",
  projectId: "mukkatis",
  storageBucket: "mukkatis.appspot.com",
  messagingSenderId: "255102460015",
  appId: "1:255102460015:web:68af0bb8f73057720ead9a",
  measurementId: "G-EQVQ4N72VJ"
};

const AdminLogin = () => {
  const [deviceToken,setdeviceToken] =useState("");
  const app = initializeApp(firebaseConfig);
  const messaging = getMessaging(app);
function requestPermission() {
  console.log("Requesting permission...");
  Notification.requestPermission().then((permission) => {
    if (permission === "granted") {
      console.log("Notification permission granted.");
      getToken(messaging, {
        vapidKey:
          "BGuemogN_43tJcTzXHA03EpfZkZgS0nWo0vGao2tyI302BT4Cx2KcM5DAeYfAho2sEWE8cNV82qupr1_2zi6PGQ",
      }).then((currentToken) => {
        if (currentToken) {
          console.log("currentToken111: ", currentToken);
          setdeviceToken(currentToken)
        } else {
          console.log("Can not get token");
        }
      });
    } else {
      console.log("Do not have permission!");
    }
  });
}
requestPermission();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(true);
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  const [successshow, setsuccessshow] = useState(false);
  const handlesuccessClose = () => setsuccessshow(false);
  const handlesuccess = () => 
    setsuccessshow(true);
   
    const [errorshow, seterrorshow] = useState(false);
    const handleerrorClose = () => seterrorshow(false);
    const handleerror = () => 
      seterrorshow(true);
  const login = async (e) => {
    e.preventDefault();
   
    try {
      const config = {
        url: "/admin/login",
        method: "post",
        baseURL: "https://bookingoda.com/api",
        hearder: { "content-type": "application/json" },

        data: {
          email: email,
          password: password,
          deviceTokens:deviceToken,
        },
      };
      await axios(config).then(function (res) {
        if (res.status === 200) {
          ////console.log(res.data);
          handlesuccess();alert("login success");
          sessionStorage.setItem("admin", JSON.stringify(res.data.success));
          
         window.location.assign('/admindashboard')
          
        } else {
          alert("Something Wrong");
        }
      });
    } catch (error) {
      alert("Invalid email or password");
      ////console.log(error.response.data.error);
    }
  };

  return (
    <div className="py-5" style={{ background: "#2f7ab1", minHeight: "100vh" }}>
      <br />
      <br />
      <br />
      <br />
      <br />
      <div className="my-5  bg-white rounded-3 mx-auto p-4" style={{height:"400px",width:'30%'}}>
        <h3 className="text-center title">Admin Login</h3>
        <p className="text-center">Login to your account to continue.</p>
        
        <form action="">
          <CustomInput
            type="text"
            label="Email Address"
            id="email"
            name="email"
          
            onChng={(e)=>setEmail(e.target.value)}
            val={email}
          />
          <div class="input-container" >
          <CustomInput
            type={!showPassword ? 'text' : 'password'}
            label="Password"
            id="pass"
            name="password"
           
            onChng={(e)=>setPassword(e.target.value)}

            val={password}
          />
            <i class={showPassword ? "fas fa-eye-slash" : "fas fa-eye"} onClick={togglePasswordVisibility} style={{marginRight:"10px",marginTop:"20px"}}></i></div>
          <div className="mb-3 text-end">
            {/* <Link to="forgot-password" className="">
              Forgot Password?
            </Link> */}
          </div>
          <button
            className="border-0 px-3  text-white fw-bold w-100 text-center text-decoration-none fs-5"
            style={{ background: "#2f7ab1" }}
            type="submit"
            onClick={login}
          >
            Login
          </button>
        </form>
      </div>
      <Modal
        show={successshow}
        onHide={handlesuccess}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton onClick={handlesuccessClose}></Modal.Header>
        <Modal.Body>
          <div className="text-center">
           <img src={Success}
              style={{ marginTop: "-12px" }}
              width="100px"/>
           
            <p>Login Successfully</p>
          </div>
        </Modal.Body>
     
      </Modal>
      <Modal
        show={errorshow}
        onHide={handleerror}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton onClick={handleerrorClose}></Modal.Header>
        <Modal.Body>
          <div className="text-center">
           <img src={Error}
              style={{ marginTop: "-12px" }}
              width="100px"/>
           
            <p>Error Occured</p>
          </div>
        </Modal.Body>
     
      </Modal>
    </div>
  );
};

export default AdminLogin;
