import React, { useState } from "react";
import "./myproperty.css";
import Apartment from "../../assets/apartments.png"
import Homestay from "../../assets/homestay.png"
import Hotel from "../../assets/hotel.png"

import Tent from "../../assets/tent.png"

const VendorHeader = () => {
    const [show,setshow]=useState(false)
  const vendor = JSON.parse(sessionStorage.getItem("vendor"));
  if (!vendor) {
    alert("please Login");
    window.location.assign("/signin");
  }
  const showmore = () =>{
    setshow(!show)
  }
  const logOut = () => {
    alert("Successfully logout");
    window.location.assign("/signin");
    window.sessionStorage.removeItem("vendor");
  };
  return (   
          <div class="join-section join-section--header join-section--header--account1" >
            <div class="join-content-layout" style={{borderBottomWidth:"3px",borderBottomColor:"red"}}>
              <div class="join-header" style={{marginTop:"15px",borderBottomWidth:"3px",borderBottomColor:"red"}}>
                <div class="mobile-join-logo join_logo" style={{borderBottomWidth:"3px",borderBottomColor:"red"}}>
                <span style={{ color: "white",fontSize:30,marginTop:"40px"}}>Booking-Oda.com</span>
                </div>
                <div class="my-properties_access  " id="account-menu-access" onClick={showmore}>
    <span class="my-properties_access_icon"></span>
</div>

{show ? <div class="my-properties_menu1" id="account-menu">
    <ul>
        <li style={{lineHeight:"40px",marginTop:"10px",marginLeft:"20px"}}>
            <a onClick={()=>window.location.assign("/vendordashboard")} data-track-ga="sb_account_click_my_properties" class="my-properties_menu_myproperties">
                <span>My properties</span>
            </a>
        </li>
        <li style={{lineHeight:"40px",marginLeft:"20px"}}>
            <a onClick={logOut} class="my-properties_menu_logout">
                <span>Log out</span>
            </a>
        </li>
    </ul>
</div> : ""}
              </div>
            </div>
          </div>
          
    
     
   
  );
};

export default VendorHeader;
