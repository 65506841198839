import { CKEditor } from "@ckeditor/ckeditor5-react";
import React, { useEffect, useState } from "react";
import { Container, Form, Modal } from "react-bootstrap";
import { Button, Table ,DatePicker} from "antd";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepButton from "@mui/material/StepButton";
import { City, Country, State } from "country-state-city";

import Table1 from "react-bootstrap/Table";
import Typography from "@mui/material/Typography";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import {  Input, Space } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { BiSolidEdit } from "react-icons/bi";
import { MdDelete } from "react-icons/md";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { BsSearch } from "react-icons/bs";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCancel } from "@fortawesome/free-solid-svg-icons";
import * as XLSX from "xlsx";
import moment from "moment/moment";

import { AiFillDelete } from "react-icons/ai";
import CustomInput from "../../admin/components/CustomInput";
const steps = [
  "Package Details",
  // "Details and Includes",
  "Gallery Image",
  "About The Tour ",
  "Itinerary"
];
function TourListVendor(props) {
  const vendor = JSON.parse(sessionStorage.getItem("vendor"));
  const [show, setShow] = useState(false);
  const [show1, setShow1] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleShow1 = (id) => {
    setShow1(true);
    setselectedModal(id);
  };
  const handleClose1 = () => setShow1(false);


  const [show2, setShow2] = useState(false);
  const handleClose2 = () => setShow2(false);
  const handleShow2 = () => setShow2(true);
  const [showI, setShowI] = useState(false);
  const handleCloseI = () => setShowI(false);
  const handleShowI = () => setShowI(true);
  const [show4, setShow4] = useState(false);
  const handleClose4 = () => setShow4(false);
  const handleShow4 = () => setShow4(true);
  const [FullView, setFullView] = useState({});

  const [show3, setShow3] = useState(false);
  const handleClose3 = () => setShow3(false);
  const handleShow3 = (item) => {
    setShow3(true);
    setdata(item);
  };

  // Get Tour Packages
  const [ViewSchedule, setViewSchedule] = useState({});
  const [Viewgallery, setViewgallery] = useState({});
  const [Tourpackages, setTourpackages] = useState([]);
  const getTourPackages = async () => {
    try {
      const res = await axios.get(
        "https://bookingoda.com/api/admin/gettrippackages"
      );
      if (res.status === 200) {
        setTourpackages(res.data.getpackages.filter((item)=> item.UserId === vendor._id));
      }
    } catch (error) {
      ////console.log(error);
    }
  };

  // Delete
  const [Data, setdata] = useState("");
  const DeleteTrip = async () => {
    try {
      const config = {
        url: "admin/daletetrippackages/" + Data,
        baseURL: "https://bookingoda.com/api",
        method: "DELETE",
        Headers: { "Content-Type": "application-json" },
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert(res.data.success);
          handleClose3();
          getTourPackages();
        }
      });
    } catch (error) {
      alert(error.response.data.error);
    }
  };

  //Update
  const UpdateProduct = async () => {
    try {
      const config = {
        url: "/admin/Updateproduct",
        method: "put",
        baseURL: "https://bookingoda.com/api",
        headers: { "content-type": "application/json" },
        data: {},
      };
      await axios(config).then(function (res) {
        if (res.status === 200) {
          //   alert(res.data.success);
          alert("successfully Update");
          window.location.assign("/productlist");
          // setPname(Pname)
        }
      });
    } catch (error) {
      // ////console.log(error);
      alert(error.response.data.error);
    }
  };

  //Approved vendor data

  // const [Data1, setData1] = useState("");
  var vendorApprovedDate = moment().format("DD/MM/YYYY HH:mm:ss");
  //Delivered
  const ApprovedDate = async (Data1) => {
    try {
      const config = {
        url: "/admin/approveddata/" + Data1,
        method: "put",
        baseURL: "https://bookingoda.com/api",
        headers: { "content-type": "application/json" },
        data: { approveddate: vendorApprovedDate },
      };
      await axios(config).then(function (res) {
        if ((res.status = 200)) {
          alert("Order is Delivery..!");
          getTourPackages();
        }
      });
    } catch (error) {
      // ////console.log(error);
    }
  };

  useEffect(() => {
    getTourPackages();
  }, []);
  const [data1, setData1] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  
  useEffect(() => {
    // Fetch data asynchronously (replace fetchDataCar with your actual data fetching logic)
    getTourPackages().then((result) => {
      setTourpackages(result);
    });
  }, []);

  useEffect(() => {
    // Ensure datacar is not undefined before transforming it
    if (Tourpackages && Tourpackages.length > 0) {
      const transformedData = transformData(Tourpackages);
      setData1(transformedData);
      setFilteredData(transformedData);
    }
  }, [Tourpackages]);
  const transformData = (Tourpackages) => {
    
    return  Tourpackages.map((item,i) => {
      const PriceDetails = (
        <div>
          <p> Adult Price: + ₹{item.tripcost} ,</p>
          <p> Adult Discount:  + ₹{item.tripdiscount} %,</p>
          <p> Children Price:  + ₹{item.childrencost}  ,</p>
          <p> Children Discount:  + ₹{item.childrendiscount}  %,</p>
          <p> Pet Price:  + ₹{item.petscost} ,</p>
          <p> Pet Discount:  + ₹{item.petdiscount} %</p>
        </div>
      );
  
        return {
        Sno:i+1,
        UniqueId: "BOPIL" + item?._id.slice(22, 24).toUpperCase(),
        TripName: item.tripname,
        // UserId: item.UserId,
        tripdescription: item.tripdescription,
        locations:
          item.city +
          "," +
          item.state +
          "," +
          item.country,
        Price:item.tripcost,
    date:moment(item.createdAt).format("DD/MM/YYYY"),

        action2: (
          <>
            <button
              className="btn"
              style={{ backgroundColor: "#1677ff", color: "white" }}
              onClick={() => {
                handleShow4();
                setFullView(item);
              }}
            >
              Tour Details
            </button>
          </>
        ),
        action1: (
          <div style={{display:'flex',flexDirection:'column'}}>
            <button
              className="pink-btn"
              style={{marginBottom:"20px"}}
              onClick={() => {
                handleShow2();
                setViewSchedule(item);
              }}
            >
              View Schedule
            </button>
            <button
              className="pink-btn"
              onClick={() => {
                handleShowI();
                setViewSchedule(item);
              }}
            >
              View Itinerary
            </button>
          </div>
        ),
        action3: (
          <>
            <button
              className="pink-btn"
              onClick={() => {
                handleShowI();
                setViewSchedule(item);
              }}
            >
              View Itinerary
            </button>
          </>
        ),
        action: (
          <>
            <div className="d-flex gap-4 fs-8" style={{ cursor: "pointer"}}>
             
             
            </div>
            <div style={{display:'flex',flexDirection:'row',justifyContent:'space-between',margin:"10px"}}>
              {/* <BiSolidEdit
              fontSize={24}
                className="text-primary"
                onClick={() => handleShow1(item)}
              />
              <MdDelete
               fontSize={24}
                className="text-danger"
                onClick={() => handleShow3(item?._id)}
              /> */}
                <button
                  className="btn btn-outline-success"
                  onClick={() => handleShow1(item)}
                >
                 Edit
                </button>
                <button
                  className="btn btn-outline-danger"
                  onClick={() => handleShow3(item._id)}
                >
                 Delete
                </button>
              </div>
          </>
          ),
        };
      });
    
     
    };
//   const data1 = [];
//   for (let i = 0; i < Tourpackages.length; i++) {
//     data1.push({
//       key: Tourpackages[i]._id,
//       TripName: Tourpackages[i].tripname,
     
//       tripdescription: Tourpackages[i].tripdescription,
//       locations:Tourpackages[i].city +
//         "," +
//         Tourpackages[i].state +
//         "," +
//         Tourpackages[i].country,
//         Price: 
//         <div>
//  <p> Adult Price: + ₹{Tourpackages[i].tripcost} ,</p>
//  <p> Adult Discount:  + ₹{Tourpackages[i].tripdiscount} %,</p>
//  <p> Children Price:  + ₹{Tourpackages[i].childrencost}  ,</p>
//  <p> Children Discount:  + ₹{Tourpackages[i].childrendiscount}  %,</p>
//  <p> Pet Price:  + ₹{Tourpackages[i].petscost} ,</p>
//  <p> Pet Discount: " + ₹{Tourpackages[i].petdiscount} %</p>
//  </div>,
//  action2: (
//   <>
//     <button
//       className="btn"
//       style={{ backgroundColor: "#1677ff", color: "white" }}
//       onClick={() => {
//         handleShow4();
//         setFullView(Tourpackages[i]);
//       }}
//     >
//       Tour Details
//     </button>
//   </>
// ),
//       action1: (
//         <>
//           <button
//             className="pink-btn"
//             onClick={() => {
//               handleShow2();
//               setViewSchedule(Tourpackages[i]);
//             }}
//           >
//             View Schedule
//           </button>
//         </>
//       ),
//       action: (
//         <>
//           <div className="d-flex gap-4 fs-8" style={{ cursor: "pointer"}}>
//             <BiSolidEdit className="text-primary" onClick={()=>handleShow1(Tourpackages[i])} />
//             <MdDelete
//               className="text-danger"
//               onClick={() => handleShow3(Tourpackages[i]?._id)}
//             />
//           </div>
//         </>
//       ),
//     });
//   }
//   ////console.log(data1,"data1");
  const removeschedule = (val) => {
    const updatedGallery = tourschedule.filter((item, index) => index !== val);
    settourschedule(updatedGallery);
  };

  const { i_class,onBlr} = props;
  const [activeStep, setActiveStep] = React.useState(0);
  const [completed, setCompleted] = React.useState({});

  const totalSteps = () => {
    return steps.length;
  };

  const completedSteps = () => {
    return Object.keys(completed).length;
  };

  const isLastStep = () => {
    return activeStep === totalSteps() - 1;
  };

  const allStepsCompleted = () => {
    return completedSteps() === totalSteps();
  };

  const handleNext = () => {
    const newActiveStep =
      isLastStep() && !allStepsCompleted()
        ? steps.findIndex((step, i) => !(i in completed))
        : activeStep + 1;
    setActiveStep(newActiveStep);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStep = (step) => () => {
    setActiveStep(step);
  };

  const handleReset = () => {
    setActiveStep(0);
    setCompleted({});
  };

  // ////console.log("completed", completed);
  // Tour Package Details
  const [selectedModal, setselectedModal] = useState({});

 
  const [Country1, setCountry1] = useState("");
  const [State1, setState1] = useState({ isoCode: "", name: "" });
  const [City1, setCity1] = useState("");
  console.log(State1);
  const CountryList = Country.getAllCountries();
  const StateList = State.getStatesOfCountry(Country1);
  const CityList = City.getCitiesOfState(Country1, State1?.isoCode);

  useEffect(() => {
    // Reset state and city when country changes
    setState1({ isoCode: "", name: "" });
    setCity1("");
  }, [Country1]);

  useEffect(() => {
    // Reset city when state changes
    setCity1("");
  }, [State1]);
  const [TripName, setTripName] = useState("");
  const [TripDescription, setTripDescription] = useState("");
  const [TripCost, setTripCost] = useState();
  const [TripDiscount, setTripDiscount] = useState();
  const [ChildrenCost, setChildrenCost] = useState();
  const [Childrendiscount, setChildrendiscount] = useState();
  const [Petcost, setPetcost] = useState();
  const [Petdiscount, setPetdiscount] = useState();
  const [TripDuration, setTripDuration] = useState("");
  //Details And Includes
  const [Sightseeing, setSightseeing] = useState("");
  const [Hotels, setHotels] = useState("");
  const [Transport, setTransport] = useState("");
  
  const handleChange = (event) => {
    setCountry1(event.target.value);
  };
  const handleChange1 = (event) => {
    setState1(event.target.value);
  };
  const handleChange2 = (event) => {
    setCity1(event.target.value);
  };
  const handleChange3 = (event) => {
    setSightseeing(event.target.value);
  };
  const handleChange4 = (event) => {
    setHotels(event.target.value);
  };
  const handleChange5 = (event) => {
    setTransport(event.target.value);
  };
  const handleComplete = () => {
    const newCompleted = completed;
    newCompleted[activeStep] = true;
    setCompleted(newCompleted);
    if (activeStep === 0) {
      
      handleNext();
    }

    if (activeStep === 1) {
      
        handleNext();
      }
    
    if (activeStep === 2) {
     
        handleNext();
      }
    

    if (activeStep === 3) {
     handleNext();
      }
      // if (activeStep === 4) {
      //   handleNext();
      //    }
  }
  

  //

  // Add Gallery Image
  const [imageURL2, setimageURL2] = useState(null);
  const [Image, setImage] = useState("");
  const [galleryImages, setGalleryImages] = useState([]);
  const onImageChange2 = (event) => {
    if (event.target.files && event.target.files[0]) {
      setimageURL2(URL.createObjectURL(event.target.files[0]));
      
    }
  };

  const AddGalleryImage = () => {
    if (!Image) {
      return alert("Please, Select Image");
    }

    const imageExists = galleryImages.find((img) => img?.imgUrl === Image);

    if (imageExists) {
      return alert("Image already exists in the gallery.");
    }

    const obj = {
      image: Image,
      imgUrl: imageURL2,
    };

    setGalleryImages([...galleryImages, obj]);
    setImage("")
    setimageURL2(null)
    const fileInput = document.getElementById('fileInput');
    if (fileInput) {
      fileInput.value = null;
    }
  };
  const removeItem2 = (val) => {
    const updatedGallery = galleryImages.filter((item, index) => index !== val);
    setGalleryImages(updatedGallery);
  };
  const [selectedImages, setSelectedImages] = useState([]);

  const toggleImageSelection = (imageId) => {
    setSelectedImages((prevSelected) => {
      if (prevSelected.includes(imageId)) {
        return prevSelected.filter((id) => id !== imageId);
      } else {
        return [...prevSelected, imageId];
      }
    });
  };

  const deleteSelectedImages = async () => {
    try {
     
      await removetourPhotos(FullView._id, selectedImages); 

 
      alert("Successfully removed");
      setSelectedImages([]);
    } catch (error) {
 
      if (error.response) {
        alert(error.response.data.error);
      } else {
        alert("Something went wrong");
      }
    }
  };
  const removetourPhotos = async (id1, ids) => {
    try {
      await Promise.all(
        ids.map(async (id) => {
      const config = {
        url: `/removetrippackagesPhotos/${id1}/${id}`,
        method: "delete",
        baseURL: "https://bookingoda.com/api/admin",
        headers: { "content-type": "application/json" },
      };

      let res = await axios(config);
      if (res.status !== 200) {
        throw new Error("Failed to delete image");
      }
    })
  );
  alert("Successfully removed");
  setShow4(false);
  setShow1(false);
  getTourPackages();
} catch (error) {
  if (error.response) {
    alert(error.response.data.error);
  } else {
    alert("Something went wrong");
  }
}
};
  const removeSchedules = async (id1, id) => {
    try {
      const config = {
        url: `/removeSchedules/${id1}/${id}`,
        method: "delete",
        baseURL: "https://bookingoda.com/api/admin",
        headers: { "content-type": "application/json" },
      };

      let res = await axios(config);
      if (res.status === 200) {
        alert("Successfully removed");
        window.location.reload("")
        getTourPackages();
      } else {
        alert("Something Wrong");
      }
    } catch (error) {
      if (error.response) {
        alert(error.response.data.error);
      }
    }
  };
  const removeItinerarys = async (id1, id) => {
    try {
      const config = {
        url: `/removeItinerary/${id1}/${id}`,
        method: "delete",
        baseURL: "https://bookingoda.com/api/admin",
        headers: { "content-type": "application/json" },
      };

      let res = await axios(config);
      if (res.status === 200) {
        alert("Successfully removed");
        window.location.reload("")
        getTourPackages();
      } else {
        alert("Something Wrong");
      }
    } catch (error) {
      if (error.response) {
        alert(error.response.data.error);
      }
    }
  };
  const [tourschedule, settourschedule] = useState([]);
  const [PlaceName, setPlaceName] = useState("");
  const [Inclusion, setInclusion] = useState("");
  const [Exclusion, setExclusion] = useState("");
  const [EventDate, setEventDate] = useState("");
  ////console.log(tourschedule.length);
  const AddTourSchedule = () => {
    if (!PlaceName) {
      return alert("Enter Place Name");
    }
    if (!Inclusion) {
      return alert("Enter Inclusion");
    }
    if (!Exclusion) {
      return alert("Enter Exclusion");
    }
    if (!EventDate) {
      return alert("Enter EventDate");
    }

    const scheduleExists = tourschedule?.filter(
      (ele) =>
        ele?.placename === PlaceName &&
        ele.inclusion === Inclusion &&
        ele?.exclusion === Exclusion &&
        ele?.eventDate === EventDate
    );

    if (!scheduleExists) {
      return alert("Details is  already exists.");
    }

    const obj = {
      placename: PlaceName,
      inclusion: Inclusion,
      exclusion: Exclusion,
      eventDate: EventDate,
    };

    settourschedule([...tourschedule, obj]);
    setPlaceName("");
    setInclusion("");
    setExclusion("");
 setEventDate("");
  };
  const [Itinerary, setItinerary] = useState([]);
  const [Header, setHeader] = useState("");
  const [ItineraryDesc, setItineraryDesc] = useState("");
 
  ////console.log();
  const AddItinerary = () => {
    if (!Header) {
      return alert("Enter Itinerary title");
    }
    if (!ItineraryDesc) {
      return alert("Enter Description");
    }
    

    const ItineraryExists = Itinerary?.filter(
      (ele) =>
        ele?.Header === Header &&
        ele.ItineraryDesc === ItineraryDesc 
    );

    if (!ItineraryExists) {
      return alert("Details is  already exists.");
    }

    const obj = {
      Header: Header,
      ItineraryDesc: ItineraryDesc,
      
    };

    setItinerary([...Itinerary, obj]);
    setHeader("");
    setItineraryDesc("");
   
  };
  const removeItinerary = (val) => {
    const updatedItinerary = Itinerary.filter((item, index) => index !== val);
    setItinerary(updatedItinerary);
  };
  const tour =tourschedule.concat(selectedModal?.TourSchedule)
  const Itinerarys =Itinerary.concat(selectedModal?.Itinerary)
  

  const TourpackageDetails = async () => {
    let adultofferprice = TripCost - (TripCost * TripDiscount) / 100;
    let childrenofferprice =
      ChildrenCost - (ChildrenCost * Childrendiscount) / 100;
    let petofferprice = Petcost - (Petcost * Petdiscount) / 100;
    try {
      const config = {
        url: "/admin/updatetrippackages",
        method: "put",
        baseURL: "https://bookingoda.com/api",
        Headers: { "content-type": "multipart/form-data" },
        data: {
          // UserId:admin._id,
          techid: selectedModal._id,

          tripname: TripName ? TripName : selectedModal.tripname,
          tripdescription: TripDescription ? TripDescription : selectedModal.tripdescription,
          tripcost: TripCost ? TripCost : selectedModal.tripcost,
          tripdiscount: TripDiscount ?  TripDiscount : selectedModal.tripdiscount,
          offerprice: adultofferprice ? adultofferprice : selectedModal.offerprice,
          country: Country1 ? Country1 : selectedModal.country,
          state: State1?.name ? State1?.name  : selectedModal.state,
          city: City1 ? City1 : selectedModal.city,
          childrencost: ChildrenCost ? ChildrenCost :selectedModal.childrencost,
          childrendiscount: Childrendiscount ?  Childrendiscount : selectedModal.childrendiscount,
          childrenoffer: childrenofferprice ? childrenofferprice : selectedModal.childrenoffer,
          petscost: Petcost ? Petcost : selectedModal.petscost,
          petdiscount: Petdiscount ? Petdiscount : selectedModal.petdiscount,
          petoffer: petofferprice ?  petofferprice : selectedModal.petoffer,
          tripduration: TripDuration ? TripDuration : selectedModal.tripduration,
          signtseeing: Sightseeing ? Sightseeing : selectedModal.signtseeing,
          hotels: Hotels ? Hotels : selectedModal.hotels,
          transport: Transport ?  Transport : selectedModal.transport,
        
          TourSchedule: tour,
          Itinerary: Itinerarys,
        },
      };
      await axios(config).then(async (res) => {
        if (res.status === 200) {
          for (let i = 0; i < galleryImages.length; i++) {
            const config = {
              url: "/admin/galleryimageupload",
              method: "put",
              baseURL: "https://bookingoda.com/api",
              headers: { "content-type": "multipart/form-data" },
              data: {
                productId: selectedModal?._id,
                image: galleryImages[i]?.image,
              },
            };
            await axios(config);
          }
          alert("Package Updated Successfully");
          window.location.reload();
        }
      });
      // ////console.log("config", config);
    } catch (error) {
      // ////console.log(error);
    }
  };
 
  const columns = [
    {
      title: "Serial No.",
      dataIndex: "Sno",

      // //fixed: "left",
      width: 100,
    },
    {
      title: "Unique ID",
      dataIndex: "UniqueId",
       
       // //fixed: "left",
       width: 150,
     
    },
    {
      title: "Trip Name",
      dataIndex: "TripName",
      

      width: 200,
      
      
   
    },
 
    // {
    //   title: "Vendor ID",
    //   dataIndex: "UserId",
    //   width: 150,

    //   // //fixed: "left",
    //   // width: 50,
    // },
    {
      title: "Created Date",
      dataIndex: "date",
      width: 150,
     
    },
    {
      title: "Tour Details",
      dataIndex: "action2",
      key: "operation",
      // //fixed: "right",

      // render: () => <Link className="ms-3 fs-8 text-success">Approve</Link>,
      // width: 50,
      width: 150,
    },
    {
      title: "Details",

      dataIndex: "action1",
      key: "operation",
      // //fixed: "right",
  
      // render: () => <Link className="ms-3 fs-8 text-success">Approve</Link>,
      width: 200,
    },
    // {
    //   title: "Itinerary", 

    //   dataIndex: "action3",
    //   key: "operation",
    //   // //fixed: "right",
  
    //   // render: () => <Link className="ms-3 fs-8 text-success">Approve</Link>,
    //   width: 150,
    // },
    {
      title: "Action",
      dataIndex: "action",
      key: "operation",
      // //fixed: "right",
  
      // render: () => <Link className="ms-3 fs-8 text-success">Approve</Link>,
      width: 190,
    },
  ];
  const ExportToExcelButton = ({ dataSource, columns, filename }) => {
    const exportToExcel = () => {
      const worksheet = XLSX.utils.json_to_sheet(dataSource);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet 1");
      XLSX.writeFile(workbook, `${filename}.xlsx`);
    };

    return (
      <Button
        type="primary"
        onClick={exportToExcel}
        style={{
          margin: "10px",
          alignSelf: "flex-end",
          backgroundColor: "#1677ff",
          color: "white",
        }}
      >
        Export to Excel
      </Button>
    );
  };

  const [searchTerm, setSearchTerm] = useState("");
  // const [filteredData, setFilteredData] = useState(data1);
  ////console.log(filteredData, "vgvg");
  const handles = (value) => {
    setSearchTerm(value);

    // Filter the data based on the search term
    const filteredDataSource = data1.filter((record) => {
      return Object.values(record).some(
        (val) =>
          val && val.toString().toLowerCase().includes(value.toLowerCase())
      );
    });

    setFilteredData(filteredDataSource);
  };

  const onClearSearch = () => {
    setSearchTerm("");
    setFilteredData(data1);
  };

  const searchInput = (
    <Input
      placeholder="Search"
      value={searchTerm}
      style={{width:"50%"}}
      onChange={(e) => handles(e.target.value)}
      suffix={
        searchTerm && (
          <span
            onClick={onClearSearch}
            style={{ cursor: "pointer", color: "grey" }}
          >
            Clear
          </span>
        )
      }
      prefix={<SearchOutlined />}
    />
  );

  const pageSize = 5;
  return (
    <>
      <div>
        <Container fluid>
          <div className="d-flex justify-content-between align-items-center pt-4 pb-2">
          <h3 className="mb-4 title">Pilgrim Vacations List</h3>
            {/* <button className='pink-btn' onClick={handleShow}>Add Package</button> */}
          </div>

          <div>
     
          <div
              style={{
                display: "flex",
                flexDirection: "row",
                // justifyContent: "space-around",
                margin: 10,
              }}
            >
               {searchInput}
      <ExportToExcelButton
        dataSource={filteredData}
        columns={columns}
        filename="Pilgrimlist"
      />
      </div>
      <div>
        <Table
          columns={columns} className="custom-table"
          dataSource={
            filteredData
          }
          scroll={{ x: "calc(700px + 50%)", y: 240 }}
          pagination={{
            pageSize: pageSize,
            showSizeChanger: false, // Optional: Hide the "Show X entries" dropdown
          }}
        />
        </div>
          </div>
        </Container>

        {/* Gallery Image Model */}
        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title className="text-danger">Gallery Images</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Table1 responsive bordered>
              <thead>
                <th>Sl.No</th>
                <th>Image</th>
                <th>Action</th>
              </thead>
              <tbody>
                {Viewgallery?.GalleryImage?.map((item, i) => {
                  return (
                    <tr>
                      <td>{i + 1}</td>
                      <td>
                        <img
                          style={{ width: "80px" }}
                          src={`https://bookingoda.com/Packages/${item?.image}`}
                          alt=""
                        />
                      </td>
                      <td>
                        {" "}
                        <div
                          className="d-flex gap-2 fs-4"
                          style={{ cursor: "pointer" }}
                        >
                          <BiSolidEdit className="text-primary" />
                          <MdDelete className="text-danger" />
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table1>
          </Modal.Body>
          {/* <Modal.Footer>
            <Button variant="warning" onClick={handleClose}>
              Close
            </Button>
            <Button variant="danger" onClick={handleClose}>
              Save
            </Button>
          </Modal.Footer> */}
        </Modal>

        {/* Trip Schedule */}
        <Modal show={show2} onHide={handleClose2} size="lg">
          <Modal.Header closeButton>
            <Modal.Title className="text-danger">Schedule</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Table1 responsive bordered>
              <thead>
                <th>Sl.No</th>
                <th>Places covered</th>
                <th>Inclusions</th>
                <th>Exclusions</th>
                <th>Event Date</th>
                <th>Action</th>
              </thead>
              <tbody>
                {ViewSchedule?.TourSchedule?.map((item, i) => {
                  return (
                    <tr>
                      <td>{i + 1}</td>
                      <td> {item?.placename}</td>
                      <td> {item?.inclusion}</td>
                      <td> {item?.exclusion}</td>
                      <td> {item?.eventDate}</td>
                      <td>
                        {" "}
                        <div
                          className="d-flex gap-2 fs-4"
                          style={{ cursor: "pointer" }}
                        >
                          {/* <BiSolidEdit className="text-primary" /> */}
                          <MdDelete className="text-danger" onClick={()=>removeSchedules(ViewSchedule?._id,item._id)}/>
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table1>
          </Modal.Body>
          {/* <Modal.Footer>
            <Button variant="warning" onClick={handleClose2}>
              Close
            </Button>
            <Button variant="danger" onClick={handleClose2}>
              Save
            </Button>
          </Modal.Footer> */}
        </Modal>
        <Modal show={showI} onHide={handleCloseI} size="lg">
          <Modal.Header closeButton>
            <Modal.Title className="text-danger">Itinerary Details</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Table1 responsive bordered>
              <thead>
                <th>Days</th>
                <th>Title</th>
                <th>Itinerary Description</th>
                
                <th>Action</th>
              </thead>
              <tbody>
                {ViewSchedule?.Itinerary?.map((item, i) => {
                  return (
                    <tr>
                      <td>Day {i + 1}</td>
                      <td> {item?.Header}</td>
                      <td> {item?.ItineraryDesc}</td>
                     
                      <td>
                        {" "}
                        <div
                          className="d-flex gap-2 fs-4"
                          style={{ cursor: "pointer" }}
                        >
                          {/* <BiSolidEdit className="text-primary" /> */}
                          <MdDelete className="text-danger" onClick={()=>removeItinerarys(ViewSchedule?._id,item._id)}/>
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table1>
          </Modal.Body>
          {/* <Modal.Footer>
            <Button variant="warning" onClick={handleClose2}>
              Close
            </Button>
            <Button variant="danger" onClick={handleClose2}>
              Save
            </Button>
          </Modal.Footer> */}
        </Modal>
        {/* Edit Activity */}
        <Modal show={show1} onHide={handleClose1}>
          <Modal.Header closeButton>
            <Modal.Title className="text-danger">Edit Package</Modal.Title>
          </Modal.Header>
          <Modal.Body>
       
          </Modal.Body>
          <Modal.Footer>
            <Button variant="warning" onClick={handleClose1}>
              Close
            </Button>
            <Button variant="danger" onClick={handleClose1}>
              Save
            </Button>
          </Modal.Footer>
        </Modal>
        <Modal
        show={show1}
        onHide={handleClose1}
        dialogClassName="custom-modal"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title className="text-danger">Edit Pilgrim Vacation List</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <div className="Stepper-info" style={{ padding: "20px" }}>
        <Box sx={{ width: "100%" }}>
            <Stepper nonLinear activeStep={activeStep}>
              {steps.map((label, index) => (
                <Step key={label} completed={completed[index]}>
                  <StepButton
                    color="inherit"
                    style={{ marginBottom: "50px" }}
                    onClick={handleStep(index)}
                  >
                    {label}
                  </StepButton>
                </Step>
              ))}
            </Stepper>
            <div>
              {allStepsCompleted() ? (
                <React.Fragment>
                  <Typography
                    sx={{ mt: 2, mb: 1 }}
                    style={{
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      fontSize: "20px",
                      fontWeight: "500",
                    }}
                  >
                    All steps completed successfully
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignSelf: "center",
                        pt: 2,
                      }}
                    >
                      <Button onClick={handleReset}>Back</Button>
                    </Box>
                  </Typography>
                </React.Fragment>
              ) : (
                <React.Fragment>
                  {activeStep == 0 ? (
                    <>
                      <Typography sx={{ mt: 2, mb: 1, py: 1 }}>
                        <div className="container" style={{ padding: "5px" }}>
                          <div className="row ">
                            <div className="col-md-6">
                              <div className="do-sear mt-2">
                                {/* <label>
                                  Trip Name
                                  <span style={{ color: "red" }}>*</span>
                                </label> */}
                                <CustomInput
                                  type="text"
                                  label="Trip Name"
                                  name="name"
                                  onChng={(e) => setTripName(e.target.value)}
                                  val={TripName ? TripName : selectedModal.tripname}
                                />
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="do-sear mt-2">
                                {/* <label>
                                  Trip Description
                                  <span style={{ color: "red" }}>*</span>
                                </label> */}
                                <CustomInput
                                  type="text"
                                  label="Description"
                                  name="name"
                                  onChng={(e) =>
                                    setTripDescription(e.target.value)
                                  }
                                  val={TripDescription ?  TripDescription : selectedModal.tripdescription}
                                />
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="do-sear mt-2">
                                {/* <label>
                                  Adult Price
                                  <span style={{ color: "red" }}>*</span>
                                </label> */}
                                {/* <input
                                  type="text"
                                  placeholder="₹ Price"
                                  className="vi_0"
                                  onChange={(e) => setTripCost(e.target.value)}
                                /> */}
                                <CustomInput
                                  type="number"
                                  label="Adult Price"
                                  max={6}
                                  name="name"
                                  onChng={(e) => setTripCost(e.target.value)}
                                  val={TripCost ?  TripCost : selectedModal.tripcost}
                                />
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="do-sear mt-2">
                                {/* <label>
                                  Adult Discount{" "}
                                <span style={{ color: "red" }}>*</span>
                                </label> */}
                                {/* <input
                                  type="text"
                                  placeholder="%Discount"
                                  className="vi_0"
                                  onChange={(e) =>
                                    setTripDiscount(e.target.value)
                                  }
                                /> */}
                                <CustomInput
                                  type="number"
                                  label="Adult Discount"
                                  name="name"
                                  max={2}
                                  onChng={(e) =>
                                    setTripDiscount(e.target.value)
                                  }
                                  val={TripDiscount ? TripDiscount : selectedModal.tripdiscount}
                                />
                              </div>
                            </div>

                            <div className="col-md-6">
                              <div className="do-sear mt-2">
                                {/* <label>
                                  Children Price
                                  <span style={{ color: "red" }}>*</span>
                                </label> */}
                                {/* <input
                                  type="text"
                                  placeholder="₹ For Children Price"
                                  className="vi_0"
                                  onChange={(e) =>
                                    setChildrenCost(e.target.value)
                                  }
                                /> */}
                                <CustomInput
                                  type="number"
                                  label="Children Price"
                                  max={6}
                                  name="name"
                                  onChng={(e) =>
                                    setChildrenCost(e.target.value)
                                  }
                                  val={ChildrenCost ? ChildrenCost : selectedModal.childrencost}
                                />
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="do-sear mt-2">
                                {/* <label>
                                  Children Discount{" "}
                                  {/* <span style={{ color: "red" }}>*</span> */}
                                {/* </label> */}
                                {/* <input
                                  type="text"
                                  placeholder="For Children % Discount"
                                  className="vi_0"
                                  onChange={(e) =>
                                    setChildrendiscount(e.target.value)
                                  } 
                                 />  */}
                                <CustomInput
                                  type="number"
                                  label="Children Discount"
                                  max={2}
                                  name="name"
                                  onChng={(e) =>
                                    setChildrendiscount(e.target.value)
                                  }
                                  val={Childrendiscount ? Childrendiscount : selectedModal.childrendiscount}
                                />
                              </div>
                            </div>
                            {/* <div className="col-md-6">
                              <div className="do-sear mt-2">
                                
                                <CustomInput
                                  type="text"
                                  label="Pet Price"
                                  name="name"
                                  onChng={(e) => setPetcost(e.target.value)}
                                  val={Petcost ? Petcost : selectedModal.petscost}
                                />
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="do-sear mt-2">
                              
                                <CustomInput
                                  type="text"
                                  label="Pet Discount"
                                  name="name"
                                  onChng={(e) => setPetdiscount(e.target.value)}
                                  val={Petdiscount}
                                />
                              </div>
                            </div> */}

                            <div className="col-md-6">
                              <div className="do-sear mt-3">
                                <label>Country</label>
                                <select
                                  class="ebf4591c8e country-select-for-e2e-tests"
                                  name="cc1"
                                  id="cc1"
                                  className="vi_0"
                                  style={{
                                    width: "100%",
                                    borderColor: "#E6E6E6",
                                    fontSize: "16px",
                                  }}
                                  onChange={(e) => setCountry1(e.target.value)}
                                >
                                  <option value="" style={{ fontSize: "16px" }}>
                                  {selectedModal.country}
                                    <span style={{ color: "red" }}>*</span>
                                  </option>
                                  {CountryList?.map((Country) => (
                                    <option
                                      value={Country?.isoCode}
                                      style={{ fontSize: "16px" }}
                                    >
                                      {Country?.name}
                                    </option>
                                  ))}
                                </select>
                                {/* // <FormControl fullWidth>
                                //   <InputLabel id="demo-simple-select-helper-label">
                                //     Select country/region{" "}
                                //     <span style={{ color: "red" }}>*</span>
                                //   </InputLabel>
                                //   <Select
                                //     labelId="demo-simple-select-helper-label"
                                //     id="demo-simple-select-helper"
                                //     value={Country1}
                                   
                                //     label="Select country/region"
                                //     onChange={handleChange}
                                //   >
                                 
                                //     {CountryList?.map((item) => (
                                //       <MenuItem value={item?.name}>
                                //         {item?.name}
                                //       </MenuItem>
                                //     ))}
                                //   </Select>
                                  
                                // </FormControl> */}
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="do-sear mt-3">
                              <label>State</label>
                                <select
                                  class="ebf4591c8e country-select-for-e2e-tests"
                                  name="cc1"
                                  id="cc1"
                                  className="vi_0"
                                  style={{
                                    width: "100%",
                                    borderColor: "#E6E6E6",
                                    fontSize: "16px",
                                  }}
                                 onChange={(e) => {
                                        const selectedState = StateList.find(
                                          (state) =>
                                            state.isoCode === e.target.value
                                        );
                                        setState1(
                                          selectedState
                                            ? {
                                                isoCode: selectedState.isoCode,
                                                name: selectedState.name,
                                              }
                                            : { isoCode: "", name: "" }
                                        );
                                      }}
                                    >
                                      <option value="">
                                        {selectedModal.state}
                                        <span style={{ color: "red" }}>*</span>
                                      </option>
                                      {StateList?.map((state) => (
                                        <option
                                          key={state.isoCode}
                                          value={state.isoCode}
                                        >
                                          {state.name}
                                        </option>
                                      ))}
                                </select>
                                {/* <FormControl fullWidth>
                                  <InputLabel id="demo-simple-select-label">
                                    State{" "}
                                    <span style={{ color: "red" }}>*</span>
                                  </InputLabel>
                                  <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={State1}
                                    label="State"
                                    onChange={handleChange1}
                                  >
                                    {StateList?.map((item) => (
                                      <MenuItem value={item?.isoCode}>
                                        {item?.name}
                                      </MenuItem>
                                    ))}
                                  </Select>
                                </FormControl> */}
                              </div>
                            </div>

                            <div className="col-md-6">
                              <div className="do-sear mt-3">
                              <label>City</label>
                                <select
                                  className="vi_0"
                                  // class="ebf4591c8e country-select-for-e2e-tests"
                                  style={{
                                    width: "100%",
                                    borderColor: "#E6E6E6",
                                    fontSize: "16px",
                                  }}
                                  name="cc1"
                                  id="cc1"
                                  onChange={(e) => setCity1(e.target.value)}
                                >
                                  <option value="" style={{ fontSize: "16px" }}>
                                    {selectedModal.city}
                                    <span style={{ color: "red" }}>*</span>
                                  </option>
                                  {CityList?.map((city1) => (
                                    <option
                                      value={city1?.name}
                                      style={{ fontSize: "16px" }}
                                    >
                                      {city1?.name}
                                    </option>
                                  ))}
                                </select>
                                {/* <FormControl fullWidth>
                                  <InputLabel id="demo-simple-select-label">
                                    City <span style={{ color: "red" }}>*</span>
                                  </InputLabel>
                                  <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={City1}
                                    label="City"
                                    onChange={handleChange2}
                                  >
                                    {CityList?.map((item) => (
                                      <MenuItem value={item?.name}>
                                        {item?.name}
                                      </MenuItem>
                                    ))}
                                  </Select>
                                </FormControl> */}
                              </div>
                            </div>
                            {/* <div className="col-md-6">
                              <div className="do-sear mt-2">
                                <label>
                                  Package Start Date
                                  <span style={{ color: "red" }}>*</span>
                                </label>
                                <input
                                  type="date"
                                  className="vi_0"
                                  onChange={(e) => setStartDate(e.target.value)}
                                />
                              </div>
                            </div> */}
                            {/* <div className="col-md-6">
                              <div className="do-sear mt-2">
                                <label>
                                  Package End Date
                                  <span style={{ color: "red" }}>*</span>
                                </label>
                                <input
                                  type="date"
                                  className="vi_0"
                                  onChange={(e) => setEndDate(e.target.value)}
                                />
                              </div>
                            </div> */}
                            <div className="col-md-6">
                              <div className="do-sear mt-2">
                                {/* <label>
                                  Duration
                                  <span style={{ color: "red" }}>*</span>
                                </label>
                                <input
                                  type="text"
                                  placeholder="Days"
                                  className="vi_0"
                                  onChange={(e) =>
                                    setTripDuration(e.target.value)
                                  }
                                /> */}
                                <CustomInput
                                  type="text"

                                  label="Duration(eg: 3N/3D)"
                                  name="name"
                                  onChng={(e) =>
                                    setTripDuration(e.target.value)
                                  }
                                  val={TripDuration ?  TripDuration : selectedModal.tripduration}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </Typography>
                    </>
                  ) : 
                    
                      activeStep == 1 ? (
                      //   <>
                      //     <Typography sx={{ mt: 2, mb: 1, py: 1 }}>
                      //       <div
                      //         className="container"
                      //         style={{ padding: "5px" }}
                      //       >
                      //         <div className="row ">
                      //           <div className="col-md-6">
                      //             <div className="do-sear mt-2">
                      //               {/* <label>
                      //                 Sight Seeing{" "}
                      //                 <span style={{ color: "red" }}>*</span>
                      //               </label> */}
                      //               {/* <input
                      //                 type="text"
                      //                 placeholder="Sight"
                      //                 className="vi_0"
                      //               /> */}
                      //               {/* <select
                      //                 className="vi_0"
                      //                 onChange={(e) =>
                      //                   setSightseeing(e.target.value)
                      //                 }
                      //               >
                      //                 <option>Select</option>
                      //                 <option value="Yes">Yes</option>
                      //                 <option value="No">No</option>
                      //               </select> */}
                      //               {/* <FormControl fullWidth>
                      //                 <InputLabel id="demo-simple-select-label">
                      //                   Sight Seeing{" "}
                      //                   <span style={{ color: "red" }}>*</span>
                      //                 </InputLabel>
                      //                 <Select
                      //                   labelId="demo-simple-select-label"
                      //                   id="demo-simple-select"
                      //                   value={Sightseeing}
                      //                   label="Sight Seeing"
                      //                   onChange={handleChange3}
                      //                 >
                      //                   <MenuItem value="Yes">Yes</MenuItem>
                      //                   <MenuItem value="No">No</MenuItem>
                      //                 </Select>
                      //               </FormControl> */}
                      //               <select
                      //                 className="vi_0"
                      //                 // class="ebf4591c8e country-select-for-e2e-tests"
                      //                 style={{
                      //                   width: "100%",
                      //                   borderColor: "#E6E6E6",
                      //                   fontSize: "16px",
                      //                 }}
                      //                 name="cc1"
                      //                 id="cc1"
                      //                 value={Sightseeing ? Sightseeing : selectedModal.signtseeing}
                      //                 label="Sight Seeing"
                      //                 onChange={handleChange3}
                      //               >
                      //                 <option
                      //                   value=""
                      //                   style={{ fontSize: "16px" }}
                      //                 >
                      //                   Sight Seeing{" "}
                      //                   <span style={{ color: "red" }}>*</span>
                      //                 </option>

                      //                 <option
                      //                   value="Yes"
                      //                   style={{ fontSize: "16px" }}
                      //                 >
                      //                   Yes
                      //                 </option>
                      //                 <option
                      //                   value="No"
                      //                   style={{ fontSize: "16px" }}
                      //                 >
                      //                   No
                      //                 </option>
                      //               </select>
                      //             </div>
                      //           </div>

                      //           <div className="col-md-6">
                      //             <div className="do-sear mt-2">
                      //               {/* <label>
                      //                 Hotel
                      //                 <span style={{ color: "red" }}>*</span>
                      //               </label> */}
                      //               {/* <input
                      //                 type="text"
                      //                 placeholder="Hotel"
                      //                 className="vi_0"
                      //               /> */}
                      //               {/* <select
                      //                 className="vi_0"
                      //                 onChange={(e) =>
                      //                   setHotels(e.target.value)
                      //                 }
                      //               >
                      //                 <option>Select</option>
                      //                 <option value="Yes">Yes</option>
                      //                 <option value="No">No</option>
                      //               </select> */}
                      //               {/* <FormControl fullWidth>
                      //                 <InputLabel id="demo-simple-select-label">
                      //                   Hotel{" "}
                      //                   <span style={{ color: "red" }}>*</span>
                      //                 </InputLabel>
                      //                 <Select
                      //                   labelId="demo-simple-select-label"
                      //                   id="demo-simple-select"
                      //                   value={Hotels}
                      //                   label="Hotel"
                      //                   onChange={handleChange4}
                      //                 >
                      //                   <MenuItem value="Yes">Yes</MenuItem>
                      //                   <MenuItem value="No">No</MenuItem>
                      //                 </Select>
                      //               </FormControl> */}
                      //               <select
                      //                 className="vi_0"
                      //                 // class="ebf4591c8e country-select-for-e2e-tests"
                      //                 style={{
                      //                   width: "100%",
                      //                   borderColor: "#E6E6E6",
                      //                   fontSize: "16px",
                      //                 }}
                      //                 name="cc1"
                      //                 id="cc1"
                      //                 value={Hotels ? Hotels : selectedModal.hotels}
                      //                 label="Hotel"
                      //                 onChange={handleChange4}
                      //               >
                      //                 <option
                      //                   value=""
                      //                   style={{ fontSize: "16px" }}
                      //                 >
                      //                   Hotel{" "}
                      //                   <span style={{ color: "red" }}>*</span>
                      //                 </option>

                      //                 <option
                      //                   value="Yes"
                      //                   style={{ fontSize: "16px" }}
                      //                 >
                      //                   Yes
                      //                 </option>
                      //                 <option
                      //                   value="No"
                      //                   style={{ fontSize: "16px" }}
                      //                 >
                      //                   No
                      //                 </option>
                      //               </select>
                      //             </div>
                      //           </div>
                      //           <div className="col-md-6">
                      //             <div className="do-sear mt-2">
                      //               {/* <label>
                      //                 Transpotrs
                      //                 <span style={{ color: "red" }}>*</span>
                      //               </label>
                      //               <select
                      //                 className="vi_0"
                      //                 onChange={(e) =>
                      //                   setTransport(e.target.value)
                      //                 }
                      //               >
                      //                 <option>Select</option>
                      //                 <option value="Yes">Yes</option>
                      //                 <option value="No">No</option>
                      //               </select> */}
                      //               {/* <FormControl fullWidth>
                      //                 <InputLabel id="demo-simple-select-label">
                      //                   Transpotrs{" "}
                      //                   <span style={{ color: "red" }}>*</span>
                      //                 </InputLabel>
                      //                 <Select
                      //                   labelId="demo-simple-select-label"
                      //                   id="demo-simple-select"
                      //                   value={Transport}
                      //                   label="Transpotrs"
                      //                   onChange={handleChange5}
                      //                 >
                      //                   <MenuItem value="Yes">Yes</MenuItem>
                      //                   <MenuItem value="No">No</MenuItem>
                      //                 </Select>
                      //               </FormControl> */}
                      //               <select
                      //                 className="vi_0"
                      //                 // class="ebf4591c8e country-select-for-e2e-tests"
                      //                 style={{
                      //                   width: "100%",
                      //                   borderColor: "#E6E6E6",
                      //                   fontSize: "16px",
                      //                 }}
                      //                 name="cc1"
                      //                 id="cc1"
                      //                 value={Transport ? Transport : selectedModal.transport}
                      //                 label="Transpotrs"
                      //                 onChange={handleChange5}
                      //               >
                      //                 <option
                      //                   value=""
                      //                   style={{ fontSize: "16px" }}
                      //                 >
                      //                   Transports{" "}
                      //                   <span style={{ color: "red" }}>*</span>
                      //                 </option>

                      //                 <option
                      //                   value="Yes"
                      //                   style={{ fontSize: "16px" }}
                      //                 >
                      //                   Yes
                      //                 </option>
                      //                 <option
                      //                   value="No"
                      //                   style={{ fontSize: "16px" }}
                      //                 >
                      //                   No
                      //                 </option>
                      //               </select>
                      //             </div>
                      //           </div>
                      //           {/* <div className="col-md-6">
                      //             <div className="do-sear mt-2">
                      //               <label>
                      //                 Duration
                      //                 <span style={{ color: "red" }}>*</span>
                      //               </label>
                      //               <input
                      //                 type="text"
                      //                 placeholder="Enter Duration"
                      //                 className="vi_0"
                      //               />
                      //             </div>
                      //           </div> */}
                      //           {/* <div className="col-md-6">
                      //             <div className="do-sear mt-2">
                      //               <label>
                      //                 Location
                      //                 <span style={{ color: "red" }}>*</span>
                      //               </label>
                      //               <input
                      //                 type="text"
                      //                 placeholder="Enter Location"
                      //                 className="vi_0"
                      //               />
                      //             </div>
                      //           </div> */}
                      //         </div>
                      //       </div>
                      //     </Typography>
                      //   </>
                      // ) : 
                      //     activeStep == 2 ? (
                            <>
                              <Typography sx={{ mt: 2, mb: 1, py: 1 }}>
                                <div
                                  className="container"
                                  style={{ padding: "5px" }}
                                >
                                  <div className="row ">
                                    <div className="col-md-6">
                                      <div className="do-sear mt-2">
                                        <label>
                                          Gallery
                                          <span style={{ color: "red" }}>
                                            *
                                          </span>
                                        </label>
                                        <div className=" mt-3">
                                          <input
                                            type="file"
                                            id="fileInput"
                                            className={`form-control ${i_class}`}
                                            onBlur={onBlr}
                                            accept="image/*"
                                            onChange={(e) => {
                                              onImageChange2(e);
                                              setImage(e.target.files[0]);
                                            }}
                                          />
                                        </div>
                                        {/* <CustomInput
                                          type="file"
                                          label="Gallary"
                                          name="name"
                                          onChng={(e) =>{
                                            onImageChange2(e);
                                            setImage(e.target.value)
                                          }}
                                          val={Image}
                                        /> */}
                                      </div>

                                      <button
                                        className="pink-btn mt-4 "
                                        style={{
                                          background: "green",
                                          color: "#fff",
                                          padding: 10,
                                        }}
                                        onClick={AddGalleryImage}
                                      >
                                        Add
                                      </button>
                                      <div
            style={{
              overflowY: "scroll",
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap",
              margin: "20px",
              // justifyContent: "space-between",
            }}
          >
            
          {selectedModal.GalleryImage?.map((media) => (
              <div
                key={media._id}
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-around",
                }}
              >
               
                  <img
                    src={"https://bookingoda.com/image/" + media.image}
                    alt="image"
                    style={{ width: "80px", height: "80px" ,marginRight:"20px",marginBottom:"20px"}}
                    className={`image-item ${
                      selectedImages.includes(media._id) ? "selected" : ""
                    }`}
                    onClick={() => toggleImageSelection(media._id)}
                  />
             
                {/* <MdDelete
                  onClick={() => removeHotel(FullView._id, media._id)}
                  style={{ color: "red" }}
                /> */}
              </div>
            ))}
          </div>
          {selectedImages.length > 0 ? <button
              onClick={deleteSelectedImages}
              style={{
                padding: "10px 20px",
                backgroundColor: "red",
                color: "white",
                border: "none",
                borderRadius: "5px",
                cursor: "pointer",
                fontSize: "16px",
                fontWeight: "bold",
              }}
            >
              
              Delete {selectedImages.length} Selected Image
              {selectedImages.length !== 1 ? "s" : ""}
            </button> : ''}
                                    </div>

                                    <div className="col-md-6">
                                      <Table1 responsive bordered>
                                        <thead>
                                          <tr>
                                            <th
                                              style={{
                                                background: "#d81d4a",
                                                color: "#fff",
                                              }}
                                            >
                                              S.No
                                            </th>
                                            <th
                                              style={{
                                                background: "#d81d4a",
                                                color: "#fff",
                                              }}
                                            >
                                              Image
                                            </th>
                                            <th
                                              style={{
                                                background: "#d81d4a",
                                                color: "#fff",
                                              }}
                                            >
                                              Action
                                            </th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                          {galleryImages?.map((item, i) => {
                                            return (
                                              <tr>
                                                <td>{i + 1}</td>
                                                <td>
                                                  <img
                                                    src={item?.imgUrl}
                                                    alt=""
                                                    style={{
                                                      width: "50px",
                                                      height: "50px",
                                                      objectFit: "cover",
                                                    }}
                                                  />
                                                </td>
                                                <td>
                                                  <div
                                                    className="d-flex gap-2 fs-4"
                                                    style={{
                                                      cursor: "pointer",
                                                    }}
                                                  >
                                                    {/* <BiSolidEdit className="text-primary" /> */}
                                                    <MdDelete
                                                      onClick={() =>
                                                        removeItem2(i)
                                                      }
                                                      className="text-danger"
                                                    />
                                                  </div>
                                                </td>
                                              </tr>
                                            );
                                          })}
                                        </tbody>
                                      </Table1>
                                    </div>
                                  </div>
                                </div>
                              </Typography>
                            </>
                          ) : 
                            activeStep == 2 ? (
                            <>
                              <Typography sx={{ mt: 2, mb: 1, py: 1 }}>
                                <div
                                  className="container"
                                  style={{ padding: "5px" }}
                                >
                                  <div className="row ">
                                    <div className="col-md-5">
                                      <div className="do-sear mt-2">
                                        {/* <label>
                                          Place Name
                                          <span style={{ color: "red" }}>
                                            *
                                          </span> */}
                                        {/* </label> */}
                                        {/* <input
                                          type="text"
                                          placeholder="Enter Place Name"
                                          className="vi_0"
                                          onChange={(e) =>
                                            setPlaceName(e.target.value)
                                          }
                                        /> */}
                                        <CustomInput
                                          type="text"
                                          label="Place Name"
                                          name="name"
                                          onChng={(e) =>
                                            setPlaceName(e.target.value)
                                          }
                                          val={PlaceName}
                                        />
                                      </div>
                                      <div className="do-sear mt-2">
                                        {/* <label>
                                          Inclusion
                                          <span style={{ color: "red" }}>
                                            *
                                          </span>
                                        </label>
                                        <input
                                          type="text"
                                          placeholder="Enter Inclusion"
                                          className="vi_0"
                                          onChange={(e) =>
                                            setInclusion(e.target.value)
                                          }
                                        /> */}
                                        <CustomInput
                                          type="text"
                                          label="Inclusion"
                                          name="name"
                                          onChng={(e) =>
                                            setInclusion(e.target.value)
                                          }
                                          val={Inclusion}
                                        />
                                      </div>
                                      <div className="do-sear mt-2">
                                        {/* <label>
                                          Exclusion
                                          <span style={{ color: "red" }}>
                                            *
                                          </span>
                                        </label>
                                        <input
                                          type="text"
                                          placeholder="Enter Exclusion"
                                          className="vi_0"
                                          onChange={(e) =>
                                            setExclusion(e.target.value)
                                          }
                                        /> */}
                                        <CustomInput
                                          type="text"
                                          label="Exclusion"
                                          name="name"
                                          onChng={(e) =>
                                            setExclusion(e.target.value)
                                          }
                                          val={Exclusion}
                                        />
                                      </div>
                                      <div className="do-sear mt-2">
                                        {/* <label>
                                          Event Date
                                          <span style={{ color: "red" }}>
                                            *
                                          </span>
                                        </label>
                                        <input
                                          type="text"
                                          placeholder="Enter Event Date/Time"
                                          className="vi_0"
                                          onChange={(e) =>
                                            setEventDate(e.target.value)
                                          }
                                        /> */}
                                        <CustomInput
                                          type="text"
                                          label="Enter Event Date/Time"
                                          name="name"
                                          onChng={(e) =>
                                            setEventDate(e.target.value)
                                          }
                                          val={EventDate}
                                        />
                                      </div>

                                      <button
                                        onClick={AddTourSchedule}
                                        className="pink-btn mt-4"
                                        style={{
                                          background: "green",
                                          color: "#fff",
                                          padding: 10,
                                        }}
                                      >
                                        Add
                                      </button>
                                    </div>

                                    <div className="col-md-7">
                                      <Table1 responsive bordered>
                                        <thead>
                                          <tr>
                                            {/* <th
                                              style={{
                                                background: "#d81d4a",
                                                color: "#fff",
                                              }}
                                            >
                                              Itinerary
                                            </th> */}
                                            <th
                                              style={{
                                                background: "#d81d4a",
                                                color: "#fff",
                                              }}
                                            >
                                              Place Name
                                            </th>
                                            <th
                                              style={{
                                                background: "#d81d4a",
                                                color: "#fff",
                                              }}
                                            >
                                              Inclusion
                                            </th>
                                            <th
                                              style={{
                                                background: "#d81d4a",
                                                color: "#fff",
                                              }}
                                            >
                                              Exclusion
                                            </th>
                                            <th
                                              style={{
                                                background: "#d81d4a",
                                                color: "#fff",
                                              }}
                                            >
                                              Event Date
                                            </th>
                                            <th
                                              style={{
                                                background: "#d81d4a",
                                                color: "#fff",
                                              }}
                                            >
                                              Action
                                            </th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                          {tour?.map((item, i) => {
                                            return (
                                              <tr>
                                                {/* <td>Day {i + 1}</td> */}
                                                <td>{item?.placename}</td>
                                                <td>{item?.inclusion}</td>
                                                <td>{item?.exclusion}</td>
                                                <td>{item?.eventDate}</td>
                                                <td>
                                                  <div
                                                    className="d-flex gap-2 fs-4"
                                                    style={{
                                                      cursor: "pointer",
                                                    }}
                                                  >
                                                    {/* <BiSolidEdit className="text-primary" /> */}
                                                    <MdDelete
                                                      onClick={() =>
                                                        removeschedule(i)
                                                      }
                                                      className="text-danger"
                                                    />
                                                  </div>
                                                </td>
                                              </tr>
                                            );
                                          })}
                                        </tbody>
                                      </Table1>
                                    </div>
                                  </div>
                                </div>
                              </Typography>
                              </>
                               ) : activeStep == 3 ? (
                            <>
                              <Typography sx={{ mt: 2, mb: 1, py: 1 }}>
                                <div
                                  className="container"
                                  style={{ padding: "5px" }}
                                >
                                  <div className="row ">
                                    <div className="col-md-5">
                                      <div className="do-sear mt-2">
                                        {/* <label>
                                          Place Name
                                          <span style={{ color: "red" }}>
                                            *
                                          </span> */}
                                        {/* </label> */}
                                        {/* <input
                                          type="text"
                                          placeholder="Enter Place Name"
                                          className="vi_0"
                                          onChange={(e) =>
                                            setPlaceName(e.target.value)
                                          }
                                        /> */}
                                        <CustomInput
                                          type="text"
                                          label="Itinerary Title"
                                          name="name"
                                          onChng={(e) =>
                                            setHeader(e.target.value)
                                          }
                                          val={Header}
                                        />
                                      </div>
                                      <div className="do-sear mt-2">
                                      <label>
                                          Description
                                          <span style={{ color: "red" }}>
                                            *
                                          </span> 
                                 </label>
                                        <textarea
                                          type="text"
                                          label="Inclusion"
                                          name="name"
                                          onChange={(e) =>
                                            setItineraryDesc(e.target.value)
                                          }
                                          value={ItineraryDesc}
                                        />
                                      </div>
                                     

                                      <button
                                        onClick={AddItinerary}
                                        className="pink-btn mt-6"
                                        style={{
                                          background: "green",
                                          color: "#fff",
                                          padding: 10,
                                        }}
                                      >
                                        Add
                                      </button>
                                    </div>

                                    <div className="col-md-7">
                                      <Table1 responsive bordered>
                                        <thead>
                                          <tr>
                                            <th
                                              style={{
                                                background: "#d81d4a",
                                                color: "#fff",
                                              }}
                                            >
                                              Itinerary
                                            </th>
                                            <th
                                              style={{
                                                background: "#d81d4a",
                                                color: "#fff",
                                              }}
                                            >
                                              Title
                                            </th>
                                            <th
                                              style={{
                                                background: "#d81d4a",
                                                color: "#fff",
                                              }}
                                            >
                                              Description
                                            </th>
                                            
                                           
                                            <th
                                              style={{
                                                background: "#d81d4a",
                                                color: "#fff",
                                              }}
                                            >
                                              Action
                                            </th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                          {Itinerarys?.map((item, i) => {
                                            return (
                                              <tr>
                                                <td>Day {i + 1}</td>
                                                <td>{item?.Header}</td>
                                                <td>{item?.ItineraryDesc}</td>
                                               
                                                <td>
                                                  <div
                                                    className="d-flex gap-2 fs-4"
                                                    style={{
                                                      cursor: "pointer",
                                                    }}
                                                  >
                                                    {/* <BiSolidEdit className="text-primary" /> */}
                                                    <MdDelete
                                                      onClick={() =>
                                                        removeItinerary(i)
                                                      }
                                                      className="text-danger"
                                                    />
                                                  </div>
                                                </td>
                                              </tr>
                                            );
                                          })}
                                        </tbody>
                                      </Table1>
                                    </div>
                                  </div>
                                </div>
                              </Typography>
                              </>
                              ) : <></>}

                  <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
                    <Button
                      style={{
                        background: "green",
                        color: "#fff",
                        display: activeStep === 0 ? "none" : "block"
                      }}
                      // disabled={activeStep === 0}

                      onClick={handleBack}
                      sx={{ mr: 1 }}
                    >
                      Back
                    </Button>
                    <Box sx={{ flex: "1 1 auto" }} />
                    <Button
                      style={{ background: "#ffc107", color: "#000", display: activeStep === 3 ? "none" : "block",marginRight:"10px" }}
                      onClick={handleNext}
                      sx={{ mr: 1 }}
                    >
                      Next
                    </Button>
                    {/* {activeStep !== steps.length &&
                      (completed[activeStep] ? (
                        <Typography
                          variant="caption"
                          sx={{ display: "inline-block" }}
                        >
                          Step {activeStep + 1} already completed
                        </Typography>
                      ) : (
                        <button
                          className="pink-btn"
                          style={{
                            background: "green",
                            color: "#fff",
                            padding: 10,
                          }}
                          onClick={() => {
                            completedSteps() === totalSteps() - 1
                              ? TourpackageDetails()
                              : handleComplete();
                          }}
                        >
                          {completedSteps() === totalSteps() - 1
                            ? "Submit"
                            : "Complete Step"}
                        </button>
                      ))} */}
                        <button
                          className="pink-btn"
                          style={{
                            background: "green",
                            color: "#fff",
                            padding: 10,
                          }}
                          onClick={TourpackageDetails}
                        >
                          Submit
                        </button>
                  </Box>
                </React.Fragment>
              )}
            </div>
          </Box>
        </div>
        </Modal.Body>
        {/* <Modal.Footer>
            <Button variant="warning" onClick={handleClose1}>
              Close
            </Button>
            <Button variant="danger" onClick={handleClose1}>
              Save
            </Button>
          </Modal.Footer> */}
      </Modal>
      </div>

      <Modal
        show={show3}
        onHide={handleClose3}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <div className="text-center">
           
            <h4 className="fw-bold text-dark mb-2">Are You Sure</h4>
            <p>This event data will be removed permanently</p>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="success" onClick={handleClose3}>
            Cancel
          </Button>
          <Button variant="danger" onClick={() => DeleteTrip()}>
            <FontAwesomeIcon icon={faCancel} className=" me-2" />
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={show4} onHide={handleClose4} size="lg">
        <Modal.Header closeButton>
          <Modal.Title className="text-danger">Pilgrim List</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div
            style={{
              marginTop: "30px",
              marginBottom: "20px",
              fontWeight: "900",
              fontSize: "18px",
              color: "white",
              border: "2px solid #B8BCC7",
              backgroundColor: "#B8BCC7",
              width: "100%",
              boxSizing: "border-box",
              padding: "10px",
            }}
          >
            Photos
          </div>
          <div
            style={{
              overflowY: "scroll",
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap",
              margin: "20px",
              // justifyContent: "space-between",
            }}
          >
              {FullView.GalleryImage?.map((media) => (
              <div
                key={media._id}
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-around",
                }}
              >
               
                  <img
                    src={"https://bookingoda.com/image/" + media.image}
                    alt="image"
                    style={{ width: "80px", height: "80px" ,marginRight:"20px",marginBottom:"20px"}}
                    className={`image-item ${
                      selectedImages.includes(media._id) ? "selected" : ""
                    }`}
                    onClick={() => toggleImageSelection(media._id)}
                  />
             
                {/* <MdDelete
                  onClick={() => removeHotel(FullView._id, media._id)}
                  style={{ color: "red" }}
                /> */}
              </div>
            ))}
          </div>
          {selectedImages.length > 0 ? <button
              onClick={deleteSelectedImages}
              style={{
                padding: "10px 20px",
                backgroundColor: "red",
                color: "white",
                border: "none",
                borderRadius: "5px",
                cursor: "pointer",
                fontSize: "16px",
                fontWeight: "bold",
              }}
            >
              
              Delete {selectedImages.length} Selected Image
              {selectedImages.length !== 1 ? "s" : ""}
            </button> : ''}
          <div
            style={{
              marginTop: "30px",
              marginBottom: "20px",
              fontWeight: "900",
              fontSize: "18px",
              color: "white",
              border: "2px solid #B8BCC7",
              backgroundColor: "#B8BCC7",
              width: "100%",
              boxSizing: "border-box",
              padding: "10px",
            }}
          >
            Description
          </div>
          <div style={{ fontSize: "18px", marginLeft: "10px" }}>
            {FullView.tripdescription}
          </div>
          <div
            style={{
              marginTop: "30px",
              marginBottom: "20px",
              fontWeight: "900",
              fontSize: "18px",
              color: "white",
              border: "2px solid #B8BCC7",
              backgroundColor: "#B8BCC7",
              width: "100%",
              boxSizing: "border-box",
              padding: "10px",
            }}
          >
            Location
          </div>
          <div style={{ fontSize: "18px", marginLeft: "10px" }}>
            {FullView.city +
        "," +
        FullView.state +
        "," +
        FullView.country}
          </div>
          <div
            style={{
              marginTop: "30px",
              marginBottom: "20px",
              fontWeight: "900",
              fontSize: "18px",
              color: "white",
              border: "2px solid #B8BCC7",
              backgroundColor: "#B8BCC7",
              width: "100%",
              boxSizing: "border-box",
              padding: "10px",
            }}
          >
           Price
          </div>
          <div style={{ fontSize: "18px", marginLeft: "10px" }}>
          <div>
 <p> Adult Price: + ₹{FullView.tripcost} ,</p>
 <p> Adult Discount:  + ₹{FullView.tripdiscount} %,</p>
 <p> Children Price:  + ₹{FullView.childrencost}  ,</p>
 <p> Children Discount:  + ₹{FullView.childrendiscount}  %,</p>
 {/* <p> Pet Price:  + ₹{FullView.petscost} ,</p>
 <p> Pet Discount: " + ₹{FullView.petdiscount} %</p> */}
 </div>
          </div>
        </Modal.Body>
        {/* <Modal.Footer>
            <Button variant="warning" onClick={handleClose2}>
              Close
            </Button>
            <Button variant="danger" onClick={handleClose2}>
              Save
            </Button>
          </Modal.Footer> */}
      </Modal>
    </>
  );
}


export default TourListVendor;
