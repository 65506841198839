import React, { useEffect, useState } from "react";
import { Button, Table, DatePicker } from "antd";
import Table1 from "react-bootstrap/Table";
import { Input, Space } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { BiEdit } from "react-icons/bi";
import { AiFillDelete } from "react-icons/ai";
import { FcApproval } from "react-icons/fc";
import { MdDelete } from "react-icons/md";
import { BiSolidEdit } from "react-icons/bi";
import { Container, Form, Modal } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCancel } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import axios from "axios";
import parse from "html-react-parser";

import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepButton from "@mui/material/StepButton";

import Typography from "@mui/material/Typography";
import { City, Country, State } from "country-state-city";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

import { CKEditor } from "@ckeditor/ckeditor5-react";
import CustomInput from "../components/CustomInput";
import * as XLSX from "xlsx";
import moment from "moment/moment";
const steps = [
  "Package Details",
  "Everything user wants to know",
  "Gallery Photos",
  "Tour Details",
  "About The Tour",
  "Itinerary",
];
const InternationalPackages = (props) => {
  const [data, setdata] = useState([]);
  const [show1, setShow1] = useState(false);
  const handleClose1 = () => setShow1(false);
  const [selectedModal, setselectedModal] = useState({});
  const handleKeyDown = (e) => {
    // Prevent the input of the minus sign
    if (e.key === "-" || e.key === "e" || e.key === "E") {
      e.preventDefault();
    }
  };
  const handleShow1 = (id) => {
    setShow1(true);
    setselectedModal(id);
  };

  const [show2, setShow2] = useState(false);
  const handleClose2 = () => setShow2(false);
  const handleShow2 = () => setShow2(true);
  const [FullView, setFullView] = useState({});
  const [show4, setShow4] = useState(false);
  const handleClose4 = () => setShow4(false);
  const handleShow4 = () => setShow4(true);
  const [show3, setShow3] = useState(false);
  const handleClose3 = () => setShow3(false);
  const handleShow3 = (item) => {
    setShow3(true);
    setdata1(item);
  };
  const getinternational = async () => {
    let res = await axios.get(
      "https://bookingoda.com/api/admin/getinternational"
    );
    if (res.status === 200) {
      //////console.log(res.data);
      setdata(res.data.getpackages);
    }
  };
  useEffect(() => {
    getinternational();
  }, []);
  const Approve = async (id) => {
    try {
      const config = {
        url: "/admin/Approveinternational",
        method: "post",
        baseURL: "https://bookingoda.com/api",
        hearder: { "content-type": "application/json" },

        data: {
          internationalid: id,
          status: "Approved",
        },
      };
      await axios(config).then(function (res) {
        if (res.status === 200) {
          ////console.log(res.data);
          alert("Approved");
          window.location.reload();
        } else {
          alert("Something Wrong");
        }
      });
    } catch (error) {
      ////console.log(error.response);
    }
  };

  ////console.log(data);
  const block = async (data) => {
    try {
      axios
        .get(`https://bookingoda.com/api/admin/blockinternational/` + data._id)
        .then(function (response) {
          if (response.status === 200) {
            ////console.log(response.data);
            alert("Blocked successfully");
            window.location.reload();
          }
        })
        .catch(function (error) {
          // setdatacondition(true);

          ////console.log(error.message.data);
          alert("Not able to complete");
        });
    } catch (error) {
      console.warn(error);
      alert("Not able to complete");
      // setdatacondition(true);
      return error;
    }
  };

  const unblock = async (data) => {
    try {
      axios
        .get(
          `https://bookingoda.com/api/admin/unblockinternational/` + data._id
        )
        .then(function (response) {
          if (response.status === 200) {
            ////console.log(response.data);
            alert("Unblocked successfully");
            window.location.reload();
          }
        })
        .catch(function (error) {
          // setdatacondition(true);

          ////console.log(error.message.data);
          alert("Not able to complete");
        });
    } catch (error) {
      console.warn(error);
      alert("Not able to complete");
      // setdatacondition(true);
      return error;
    }
  };
  const [Data1, setdata1] = useState("");
  const DeleteTrip = async () => {
    try {
      const config = {
        url: "admin/daleteinternational/" + Data1,
        baseURL: "https://bookingoda.com/api",
        method: "DELETE",
        Headers: { "Content-Type": "application-json" },
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert(res.data.success);
          handleClose3();
          getinternational();
        }
      });
    } catch (error) {
      alert(error.response.data.error);
    }
  };
  const [data1, setData1] = useState([]);
  const [filteredData, setFilteredData] = useState([]);

  useEffect(() => {
    // Fetch data asynchronously (replace fetchDataCar with your actual data fetching logic)
    getinternational().then((result) => {
      setdata(result);
    });
  }, []);

  useEffect(() => {
    // Ensure datacar is not undefined before transforming it
    if (data && data.length > 0) {
      const transformedData = transformData(data);
      setData1(transformedData);
      setFilteredData(transformedData);
    }
  }, [data]);
  const [tourschedule, settourschedule] = useState([]);
  const [PlaceName, setPlaceName] = useState("");
  const [Inclusion, setInclusion] = useState("");
  const [Exclusion, setExclusion] = useState("");
  const [EventDate, setEventDate] = useState("");
  ////console.log();
  const AddTourSchedule = () => {
    if (!PlaceName) {
      return alert("Enter Place Name");
    }
    if (!Inclusion) {
      return alert("Enter Inclusion");
    }
    if (!Exclusion) {
      return alert("Enter Exclusion");
    }
    // if (!EventDate) {
    //   return alert("Enter EventDate");
    // }

    const scheduleExists = tourschedule?.filter(
      (ele) =>
        ele?.placename === PlaceName &&
        ele.inclusion === Inclusion &&
        ele?.exclusion === Exclusion
    );

    if (!scheduleExists) {
      return alert("Details is  already exists.");
    }

    const obj = {
      placename: PlaceName,
      inclusion: Inclusion,
      exclusion: Exclusion,
    };

    settourschedule([...tourschedule, obj]);
    setPlaceName("");
    setInclusion("");
    setExclusion("");
  };
  const removeschedule = (val) => {
    const updatedGallery = tourschedule.filter((item, index) => index !== val);
    settourschedule(updatedGallery);
  };
  const [Itinerary, setItinerary] = useState([]);
  const [Header, setHeader] = useState("");
  const [ItineraryDesc, setItineraryDesc] = useState("");

  ////console.log();
  const AddItinerary = () => {
    if (!Header) {
      return alert("Enter Itinerary title");
    }
    if (!ItineraryDesc) {
      return alert("Enter Description");
    }

    const ItineraryExists = Itinerary?.filter(
      (ele) => ele?.Header === Header && ele.ItineraryDesc === ItineraryDesc
    );

    if (!ItineraryExists) {
      return alert("Details is  already exists.");
    }

    const obj = {
      Header: Header,
      ItineraryDesc: ItineraryDesc,
    };

    setItinerary([...Itinerary, obj]);
    setHeader("");
    setItineraryDesc("");
  };
  const removeItinerary = (val) => {
    const updatedItinerary = Itinerary.filter((item, index) => index !== val);
    setItinerary(updatedItinerary);
  };
  const removeSchedules = async (id1, id) => {
    try {
      const config = {
        url: `/removeSchedules1/${id1}/${id}`,
        method: "delete",
        baseURL: "https://bookingoda.com/api/admin",
        headers: { "content-type": "application/json" },
      };

      let res = await axios(config);
      if (res.status === 200) {
        alert("Successfully removed");
        window.location.reload("");

        getinternational();
      } else {
        alert("Something Wrong");
      }
    } catch (error) {
      if (error.response) {
        alert(error.response.data.error);
      }
    }
  };
  const removeItinerarys = async (id1, id) => {
    try {
      const config = {
        url: `/removeItinerary1/${id1}/${id}`,
        method: "delete",
        baseURL: "https://bookingoda.com/api/admin",
        headers: { "content-type": "application/json" },
      };

      let res = await axios(config);
      if (res.status === 200) {
        alert("Successfully removed");
        window.location.reload("");
        getinternational();
      } else {
        alert("Something Wrong");
      }
    } catch (error) {
      if (error.response) {
        alert(error.response.data.error);
      }
    }
  };
  const transformData = (data1) => {
    return data1.map((item, i) => {
      return {
        Sno: i + 1,
        UniqueId: "BOINT" + item._id.slice(22, 24).toUpperCase(),
        UserId: "BOVEN" + item.UserId.slice(22, 24).toUpperCase(),
        DestinationName: item.DestinationName,
        Destinationsubtitle: item.Destinationsubtitle,
        PlaceDescription: item.PlaceDescription,
        date: moment(item.createdAt).format("DD/MM/YYYY"),

        // PlaceCulture: item.PlaceCulture,
        // PlaceBeaches: item.PlaceBeaches,
        // PlaceHotels: item.PlaceHotels,
        // PlacePopular: item.PlacePopular,
        // PlaceIncluded: item.PlaceIncluded,
        Country: item.country,
        State: item.state,
        City: item.city,
        Duration: item.tripduration,
        Price: item.tripcost,
        action2: (
          <>
            <button
              className="btn"
              style={{ backgroundColor: "#1677ff", color: "white" }}
              onClick={() => {
                handleShow2();
                setFullView(item);
              }}
            >
              Details
            </button>
          </>
        ),
        action: (
          <>
            {item.status === "Pending" ? (
              <Button
                className="btn btn-outline-success"
                style={{ cursor: "pointer" }}
                onClick={() => Approve(item._id)}
              >
                Approve
              </Button>
            ) : (
              <div className="btn btn-outline-success">
                <FcApproval /> Approved
              </div>
            )}
          </>
        ),
        action1: (
          <>
            <div className="d-flex gap-4" style={{ cursor: "pointer" }}></div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-around",
                margin: "10px",
              }}
            >
              {/* <BiSolidEdit
              fontSize={24}
                className="text-primary"
                onClick={() => handleShow1(item)}
              />
              <MdDelete
               fontSize={24}
                className="text-danger"
                onClick={() => handleShow3(item?._id)}
              /> */}
              {!item.blockstatus ? (
                <button
                  className="btn btn-outline-success"
                  onClick={() => block(item)}
                >
                  Block
                </button>
              ) : (
                <button
                  className="btn btn-outline-danger"
                  onClick={() => unblock(item)}
                >
                  Un-Block
                </button>
              )}
              <button
                className="btn btn-outline-success"
                onClick={() => handleShow1(item)}
              >
                Edit
              </button>
              <button
                className="btn btn-outline-danger"
                onClick={() => handleShow3(item._id)}
              >
                Delete
              </button>
            </div>
          </>
        ),
      };
    });
  };
  // const data1 = [];
  // for (let i = 0; i < data.length; i++) {
  //   data1.push({
  //     key: data[i]._id,
  //     UserId: data[i].UserId,

  //     DestinationName: data[i].DestinationName,
  //     Destinationsubtitle: data[i].Destinationsubtitle,
  //     PlaceDescription: data[i].PlaceDescription,
  //     PlaceCulture: data[i].PlaceCulture,
  //     PlaceBeaches: data[i].PlaceBeaches,
  //     PlaceHotels: data[i].PlaceHotels,
  //     PlacePopular: data[i].PlacePopular,
  //     PlaceIncluded: data[i].PlaceIncluded,
  //     Country: data[i].country,
  //     State: data[i].state,
  //     City: data[i].city,
  //     Duration: data[i].tripduration,
  //     Price: data[i].tripcost,

  //     action2: (
  //       <>
  //         <button
  //           className="btn"
  //           style={{ backgroundColor: "#1677ff", color: "white" }}
  //           onClick={() => {
  //             handleShow2();
  //             setFullView(data[i]);
  //           }}
  //         >
  //           International Details
  //         </button>
  //       </>
  //     ),
  //     action: (
  //       <>
  //         {/* <Link to="/" className=" fs-3 text-danger">
  //             <BiEdit />
  //           </Link> */}
  //         {data[i].status === "Pending" ? (
  //           <Button
  //             className="ms-3 fs-8 text-success"
  //             style={{ cursor: "pointer" }}
  //             onClick={() => Approve(data[i]._id)}
  //           >
  //             Approve
  //           </Button>
  //         ) : (
  //           <div className="ms-3 fs-8 text-success">
  //             <FcApproval /> Approved
  //           </div>
  //         )}
  //       </>
  //     ),
  //     action1: (
  //       <>
  //         <div className="d-flex gap-4 fs-8" style={{ cursor: "pointer"}}>
  //         {!data[i].blockstatus ? (
  //           <button
  //             className="btn btn-outline-success"
  //             onClick={() => block(data[i])}
  //           >
  //             Block
  //           </button>
  //         ) : (
  //           <button
  //             className="btn btn-outline-danger"
  //             onClick={() => unblock(data[i])}
  //           >
  //             Un-Block
  //           </button>
  //         )}
  //           <BiSolidEdit
  //             className="text-primary"
  //             onClick={() => handleShow1(data[i])}
  //           />

  //           <MdDelete
  //             className="text-danger"
  //             onClick={() => handleShow3(data[i]?._id)}
  //           />
  //         </div>
  //       </>
  //     ),
  //   });
  // }
  const { i_class, onBlr } = props;
  const [activeStep, setActiveStep] = React.useState(0);
  const [completed, setCompleted] = React.useState({});

  const totalSteps = () => {
    return steps.length;
  };

  const completedSteps = () => {
    return Object.keys(completed).length;
  };

  const isLastStep = () => {
    return activeStep === totalSteps() - 1;
  };

  const allStepsCompleted = () => {
    return completedSteps() === totalSteps();
  };

  const handleNext = () => {
    const newActiveStep =
      isLastStep() && !allStepsCompleted()
        ? steps.findIndex((step, i) => !(i in completed))
        : activeStep + 1;
    setActiveStep(newActiveStep);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStep = (step) => () => {
    setActiveStep(step);
  };

  const handleReset = () => {
    setActiveStep(0);
    setCompleted({});
  };

  // Tour Package Details

  const [Country1, setCountry1] = useState("");
  const [State1, setState1] = useState({ isoCode: "", name: "" });
  const [City1, setCity1] = useState("");
  console.log(State1);
  const CountryList = Country.getAllCountries();
  const StateList = State.getStatesOfCountry(Country1);
  const CityList = City.getCitiesOfState(Country1, State1?.isoCode);

  useEffect(() => {
    // Reset state and city when country changes
    setState1({ isoCode: "", name: "" });
    setCity1("");
  }, [Country1]);

  useEffect(() => {
    // Reset city when state changes
    setCity1("");
  }, [State1]);

  const [DestinationName, setDestinationName] = useState("");
  const [Destinationsubtitle, setDestinationsubtitle] = useState("");
  const [TripCost, setTripCost] = useState();
  const [PlaceDescription, setPlaceDescription] = useState("");
  const [PlaceCulture, setPlaceCulture] = useState("");
  const [PlaceBeaches, setPlaceBeaches] = useState("");
  const [PlaceHotels, setPlaceHotels] = useState("");
  const [PlacePopular, setPlacePopular] = useState("");
  const [PlaceIncluded, setPlaceIncluded] = useState("");

  const [TripDuration, setTripDuration] = useState("");
  const [TourType, setTourType] = useState("");
  const [GroupSize, setGroupSize] = useState();
  const [TourGuide, setTourGuide] = useState();
  //Details And Includes

  const handlePlace = (e, editor) => {
    const data = editor.getData();
    setPlaceDescription(data);
  };
  const handleCulture = (e, editor) => {
    const data = editor.getData();
    setPlaceCulture(data);
  };
  const handleBeaches = (e, editor) => {
    const data = editor.getData();
    setPlaceBeaches(data);
  };
  const handleHotel = (e, editor) => {
    const data = editor.getData();
    setPlaceHotels(data);
  };
  const handlePopular = (e, editor) => {
    const data = editor.getData();
    setPlacePopular(data);
  };
  const handleIncluded = (e, editor) => {
    const data = editor.getData();
    setPlaceIncluded(data);
  };
  const handleComplete = () => {
    const newCompleted = completed;
    newCompleted[activeStep] = true;
    setCompleted(newCompleted);
    if (activeStep === 0) {
      handleNext();
    }

    if (activeStep === 1) {
      handleNext();
    }
    if (activeStep === 2) {
      handleNext();
    }
    if (activeStep === 3) {
      handleNext();
    }

    if (activeStep === 4) {
      handleNext();
    }

    if (activeStep === 5) {
      handleNext();
    }
  };

  //

  // Add Gallery Image
  const [imageURL2, setimageURL2] = useState(null);
  const [Image, setImage] = useState("");
  const [galleryImages, setGalleryImages] = useState([]);
  const onImageChange2 = (event) => {
    if (event.target.files && event.target.files[0]) {
      setimageURL2(URL.createObjectURL(event.target.files[0]));
    }
  };
  const AddGalleryImage = () => {
    if (!Image) {
      return alert("Please, Select Image");
    }

    const imageExists = galleryImages.find((img) => img?.imgUrl === Image);

    if (imageExists) {
      return alert("Image already exists in the gallery.");
    }

    const obj = {
      image: Image,
      imgUrl: imageURL2,
    };

    setGalleryImages([...galleryImages, obj]);
    setImage("");
    setimageURL2(null);
    const fileInput = document.getElementById("fileInput");
    if (fileInput) {
      fileInput.value = null;
    }
  };
  const removeItem2 = (val) => {
    const updatedGallery = galleryImages.filter((item, index) => index !== val);
    setGalleryImages(updatedGallery);
  };
  // Culture Images
  const [imageURLculture, setimageURLculture] = useState(null);
  const [Imageculture, setImageculture] = useState("");
  const [galleryImagesculture, setGalleryImagesculture] = useState([]);
  const onImageChangeculture = (event) => {
    if (event.target.files && event.target.files[0]) {
      setimageURLculture(URL.createObjectURL(event.target.files[0]));
    }
  };

  const AddGalleryImageculture = () => {
    if (!Imageculture) {
      return alert("Please, Select Image");
    }

    const imageExists = galleryImagesculture.find(
      (img) => img?.imgUrl === Imageculture
    );

    if (imageExists) {
      return alert("Image already exists in the gallery.");
    }

    const obj = {
      image: Imageculture,
      imgUrl: imageURLculture,
    };

    setGalleryImagesculture([...galleryImagesculture, obj]);
    setImageculture("");
    setimageURLculture(null);
    const fileInput = document.getElementById("fileInput");
    if (fileInput) {
      fileInput.value = null;
    }
  };
  const removeItemculture = (val) => {
    const updatedGallery = galleryImagesculture.filter(
      (item, index) => index !== val
    );
    setGalleryImagesculture(updatedGallery);
  };
  // Beaches Images
  const [imageURLbeaches, setimageURLbeaches] = useState(null);
  const [Imagebeaches, setImagebeaches] = useState("");
  const [galleryImagesbeaches, setGalleryImagesbeaches] = useState([]);
  const onImageChangebeaches = (event) => {
    if (event.target.files && event.target.files[0]) {
      setimageURLbeaches(URL.createObjectURL(event.target.files[0]));
    }
  };

  const AddGalleryImagebeaches = () => {
    if (!Imagebeaches) {
      return alert("Please, Select Image");
    }

    const imageExists = galleryImagesbeaches.find(
      (img) => img?.imgUrl === Imagebeaches
    );

    if (imageExists) {
      return alert("Image already exists in the gallery.");
    }

    const obj = {
      image: Imagebeaches,
      imgUrl: imageURLbeaches,
    };

    setGalleryImagesbeaches([...galleryImagesbeaches, obj]);
  };
  const removeItembeaches = (val) => {
    const updatedGallery = galleryImagesbeaches.filter(
      (item, index) => index !== val
    );
    setGalleryImagesbeaches(updatedGallery);
  };
  // Hotels Images
  const [imageURLhotel, setimageURLhotel] = useState(null);
  const [Imagehotel, setImagehotel] = useState("");
  const [galleryImageshotel, setGalleryImageshotel] = useState([]);
  const onImageChangehotel = (event) => {
    if (event.target.files && event.target.files[0]) {
      setimageURLhotel(URL.createObjectURL(event.target.files[0]));
    }
  };

  const AddGalleryImagehotel = () => {
    if (!Imagehotel) {
      return alert("Please, Select Image");
    }

    const imageExists = galleryImageshotel.find(
      (img) => img?.imgUrl === Imagehotel
    );

    if (imageExists) {
      return alert("Image already exists in the gallery.");
    }

    const obj = {
      image: Imagehotel,
      imgUrl: imageURLhotel,
    };

    setGalleryImageshotel([...galleryImageshotel, obj]);
  };
  const removeItemhotel = (val) => {
    const updatedGallery = galleryImageshotel.filter(
      (item, index) => index !== val
    );
    setGalleryImageshotel(updatedGallery);
  };
  // Popular Images
  const [imageURLpopular, setimageURLpopular] = useState(null);
  const [Imagepopular, setImagepopular] = useState("");
  const [galleryImagespopular, setGalleryImagespopular] = useState([]);
  const onImageChangepopular = (event) => {
    if (event.target.files && event.target.files[0]) {
      setimageURLpopular(URL.createObjectURL(event.target.files[0]));
    }
  };

  const AddGalleryImagepopular = () => {
    if (!Imagepopular) {
      return alert("Please, Select Image");
    }

    const imageExists = galleryImagespopular.find(
      (img) => img?.imgUrl === Imagepopular
    );

    if (imageExists) {
      return alert("Image already exists in the gallery.");
    }

    const obj = {
      image: Imagepopular,
      imgUrl: imageURLpopular,
    };

    setGalleryImagespopular([...galleryImagespopular, obj]);
  };
  const removeItempopular = (val) => {
    const updatedGallery = galleryImagespopular.filter(
      (item, index) => index !== val
    );
    setGalleryImagespopular(updatedGallery);
  };

  const PlaceDescription1 = JSON.parse(
    sessionStorage.getItem("PlaceDescription")
  );

  const columns = [
    {
      title: "Serial No.",
      dataIndex: "Sno",

      // //fixed: "left",
      width: 100,
    },
    {
      title: "Unique ID",
      dataIndex: "UniqueId",

      // //fixed: "left",
      // width: 200,
      width: 150,
    },
    {
      title: "Vendor ID",
      dataIndex: "UserId",
      // width: 200,
      // //fixed: "left",
      // width: 50,
      width: 150,
    },
    {
      title: "Destination Name",
      dataIndex: "DestinationName",
      // //fixed: "left",
      width: 150,
      style: { borderRight: "1px solid black" },
    },
    {
      title: "Destination Tagline",
      dataIndex: "Destinationsubtitle",
      width: 150,
      style: { borderRight: "1px solid black" },
    },

    {
      title: "Created Date",
      dataIndex: "date",
      width: 150,

      style: { borderRight: "1px solid black" },
    },
    {
      title: "International Details",
      dataIndex: "action2",
      key: "operation",
      // //fixed: "right",

      // render: () => <Link className="ms-3 fs-8 text-success">Approve</Link>,
      width: 150,
      style: { borderRight: "1px solid black" },
    },
    // {
    //   title: "Approved Status",

    //   dataIndex: "action",
    //   key: "operation",
    //   //fixed: "right",

    //   // render: () => <Link className="ms-3 fs-8 text-success">Approve</Link>,
    //   width: 150,
    // },
    {
      title: "Action",
      dataIndex: "action1",
      key: "operation",
      // //fixed: "right",

      // render: () => <Link className="ms-3 fs-8 text-success">Approve</Link>,
      width: 300,
      style: { borderRight: "1px solid black" },
    },
  ];
  const tour = tourschedule.concat(selectedModal?.TourSchedule);
  const Itinerarys = Itinerary.concat(selectedModal?.Itinerary);
  const TourpackageDetails = async () => {
    try {
      const config = {
        url: "/admin/updateinternational",
        method: "put",
        baseURL: "https://bookingoda.com/api",
        Headers: { "content-type": "multipart/form-data" },
        data: {
          packageid: selectedModal._id,

          UserId: selectedModal.UserId,
          DestinationName: DestinationName
            ? DestinationName
            : selectedModal.DestinationName,
          Destinationsubtitle: Destinationsubtitle
            ? Destinationsubtitle
            : selectedModal.Destinationsubtitle,
          tripcost: TripCost ? TripCost : selectedModal.tripcost,
          PlaceDescription: PlaceDescription1
            ? PlaceDescription1
            : selectedModal.PlaceDescription,

          // PlaceCulture: PlaceCulture1 ? PlaceCulture1 : selectedModal.PlaceCulture,
          // PlaceBeaches: PlaceBeaches1 ? PlaceBeaches1 : selectedModal.PlaceBeaches,
          // PlaceHotels: PlaceHotels1 ? PlaceHotels1 : selectedModal.PlaceHotels,
          // PlaceIncluded: PlaceIncluded ? PlaceIncluded : selectedModal.PlaceIncluded,
          // PlacePopular: PlacePopular1 ? PlacePopular1 : selectedModal.PlacePopular,
          country: Country1 ? Country1 : selectedModal.country,
          state: State1?.name ? State1?.name : selectedModal.state,
          city: City1 ? City1 : selectedModal.city,
          tripduration: TripDuration
            ? TripDuration
            : selectedModal.tripduration,
          status: selectedModal.status,
          TourType: TourType ? TourType : selectedModal.TourType,
          TourGuide: TourGuide ? TourGuide : selectedModal.TourGuide,
          GroupSize: GroupSize ? GroupSize : selectedModal.GroupSize,
          TourSchedule: tour,
          Itinerary: Itinerarys,
        },
      };
      await axios(config).then(async (res) => {
        if (res.status === 200) {
          for (let i = 0; i < galleryImages.length; i++) {
            const config = {
              url: "/admin/galleryimageuploadplace",
              method: "put",
              baseURL: "https://bookingoda.com/api",
              headers: { "content-type": "multipart/form-data" },
              data: {
                productId: selectedModal._id,
                image: galleryImages[i]?.image,
              },
            };
            await axios(config);
          }
          for (let i = 0; i < galleryImagesculture.length; i++) {
            const config = {
              url: "/admin/galleryimageuploadculture",
              method: "put",
              baseURL: "https://bookingoda.com/api",
              headers: { "content-type": "multipart/form-data" },
              data: {
                productId: selectedModal._id,
                imageculture: galleryImagesculture[i]?.image,
              },
            };
            await axios(config);
          }
          for (let i = 0; i < galleryImagesbeaches.length; i++) {
            const config = {
              url: "/admin/galleryimageuploadbeaches",
              method: "put",
              baseURL: "https://bookingoda.com/api",
              headers: { "content-type": "multipart/form-data" },
              data: {
                productId: selectedModal._id,
                imagebeaches: galleryImagesbeaches[i]?.image,
              },
            };
            await axios(config);
          }
          for (let i = 0; i < galleryImageshotel.length; i++) {
            const config = {
              url: "/admin/galleryimageuploadhotel",
              method: "put",
              baseURL: "https://bookingoda.com/api",
              headers: { "content-type": "multipart/form-data" },
              data: {
                productId: selectedModal._id,
                imagehotels: galleryImageshotel[i]?.image,
              },
            };
            await axios(config);
          }
          for (let i = 0; i < galleryImagespopular.length; i++) {
            const config = {
              url: "/admin/galleryimageuploadpopular",
              method: "put",
              baseURL: "https://bookingoda.com/api",
              headers: { "content-type": "multipart/form-data" },
              data: {
                productId: selectedModal._id,
                imagepopular: galleryImagespopular[i]?.image,
              },
            };
            await axios(config);
          }
          window.sessionStorage.removeItem("PlaceDescription1");
          window.sessionStorage.removeItem("PlaceCulture1");
          window.sessionStorage.removeItem("PlaceBeaches1");
          window.sessionStorage.removeItem("PlaceHotels1");
          window.sessionStorage.removeItem("PlacePopular1");

          alert("Package Updated Successfully");
          window.location.reload();
        }
      });
    } catch (error) {
      alert(error.response.data.error);
    }
  };
  const ExportToExcelButton = ({ dataSource, columns, filename }) => {
    const exportToExcel = () => {
      const worksheet = XLSX.utils.json_to_sheet(dataSource);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet 1");
      XLSX.writeFile(workbook, `${filename}.xlsx`);
    };

    return (
      <Button
        type="primary"
        onClick={exportToExcel}
        style={{
          margin: "10px",
          alignSelf: "flex-end",
          backgroundColor: "#1677ff",
          color: "white",
        }}
      >
        Export to Excel
      </Button>
    );
  };

  const [searchTerm, setSearchTerm] = useState("");
  // const [filteredData, setFilteredData] = useState(data1);
  ////console.log(filteredData, "vgvg");
  const handles = (value) => {
    setSearchTerm(value);

    // Filter the data based on the search term
    const filteredDataSource = data1.filter((record) => {
      return Object.values(record).some(
        (val) =>
          val && val.toString().toLowerCase().includes(value.toLowerCase())
      );
    });

    setFilteredData(filteredDataSource);
  };

  const onClearSearch = () => {
    setSearchTerm("");
    setFilteredData(data1);
  };

  const searchInput = (
    <Input
      placeholder="Search"
      style={{ width: "50%" }}
      value={searchTerm}
      onChange={(e) => handles(e.target.value)}
      suffix={
        searchTerm && (
          <span
            onClick={onClearSearch}
            style={{ cursor: "pointer", color: "grey" }}
          >
            Clear
          </span>
        )
      }
      prefix={<SearchOutlined />}
    />
  );

  const pageSize = 5;
  const removetourPlace = async (id1, id) => {
    try {
      const config = {
        url: `/removeGalleryimagesplace/${id1}/${id}`,
        method: "delete",
        baseURL: "https://bookingoda.com/api/admin",
        headers: { "content-type": "application/json" },
      };

      let res = await axios(config);
      if (res.status === 200) {
        alert("Successfully removed");
        getinternational();
        setShow2(false);
      } else {
        alert("Something Wrong");
      }
    } catch (error) {
      if (error.response) {
        alert(error.response.data.error);
      }
    }
  };
  const removetourCulture = async (id1, id) => {
    try {
      const config = {
        url: `/removeGalleryimagesculture/${id1}/${id}`,
        method: "delete",
        baseURL: "https://bookingoda.com/api/admin",
        headers: { "content-type": "application/json" },
      };

      let res = await axios(config);
      if (res.status === 200) {
        alert("Successfully removed");
        getinternational();
        setShow2(false);
        setShow1(false);
      } else {
        alert("Something Wrong");
      }
    } catch (error) {
      if (error.response) {
        alert(error.response.data.error);
      }
    }
  };
  const removetourBeaches = async (id1, id) => {
    try {
      const config = {
        url: `/removeGalleryimagesbeaches/${id1}/${id}`,
        method: "delete",
        baseURL: "https://bookingoda.com/api/admin",
        headers: { "content-type": "application/json" },
      };

      let res = await axios(config);
      if (res.status === 200) {
        alert("Successfully removed");
        getinternational();
        setShow2(false);
      } else {
        alert("Something Wrong");
      }
    } catch (error) {
      if (error.response) {
        alert(error.response.data.error);
      }
    }
  };
  const removetourHotels = async (id1, id) => {
    try {
      const config = {
        url: `/removeGalleryimageshotels/${id1}/${id}`,
        method: "delete",
        baseURL: "https://bookingoda.com/api/admin",
        headers: { "content-type": "application/json" },
      };

      let res = await axios(config);
      if (res.status === 200) {
        alert("Successfully removed");
        getinternational();
        setShow2(false);
      } else {
        alert("Something Wrong");
      }
    } catch (error) {
      if (error.response) {
        alert(error.response.data.error);
      }
    }
  };
  const removetourPopualr = async (id1, id) => {
    try {
      const config = {
        url: `/removeGalleryimagespopular/${id1}/${id}`,
        method: "delete",
        baseURL: "https://bookingoda.com/api/admin",
        headers: { "content-type": "application/json" },
      };

      let res = await axios(config);
      if (res.status === 200) {
        alert("Successfully removed");
        getinternational();
        setShow2(false);
      } else {
        alert("Something Wrong");
      }
    } catch (error) {
      if (error.response) {
        alert(error.response.data.error);
      }
    }
  };
  const [selectedImages, setSelectedImages] = useState([]);

  const toggleImageSelection = (imageId) => {
    setSelectedImages((prevSelected) => {
      if (prevSelected.includes(imageId)) {
        return prevSelected.filter((id) => id !== imageId);
      } else {
        return [...prevSelected, imageId];
      }
    });
  };

  const deleteSelectedImages = async () => {
    try {
      await removeInternationalPhotos(FullView._id, selectedImages);

      alert("Successfully removed");
      setSelectedImages([]);
    } catch (error) {
      if (error.response) {
        alert(error.response.data.error);
      } else {
        alert("Something went wrong");
      }
    }
  };
  const removeInternationalPhotos = async (id1, ids) => {
    try {
      await Promise.all(
        ids.map(async (id) => {
          const config = {
            url: `/removeGalleryimagesculture/${id1}/${id}`,
            method: "delete",
            baseURL: "https://bookingoda.com/api/admin",
            headers: { "content-type": "application/json" },
          };

          let res = await axios(config);
          if (res.status !== 200) {
            throw new Error("Failed to delete image");
          }
        })
      );
      alert("Successfully removed");
      setShow2(false);
      setShow1(false);

      getinternational();
    } catch (error) {
      if (error.response) {
        alert(error.response.data.error);
      } else {
        alert("Something went wrong");
      }
    }
  };
  return (
    <div>
      <h3 className="mb-4 title">International Holiday List</h3>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          // justifyContent: "space-around",
          margin: 10,
        }}
      >
        {searchInput}
        <ExportToExcelButton
          dataSource={filteredData}
          columns={columns}
          filename="Internationallist"
        />
      </div>
      <div>
        <Table
          columns={columns}
          className="custom-table"
          dataSource={filteredData}
          scroll={{ x: "calc(700px + 50%)", y: 240 }}
          pagination={{
            pageSize: pageSize,
            showSizeChanger: false, // Optional: Hide the "Show X entries" dropdown
          }}
          components={{
            header: {
              cell: (props) => (
                <th
                  {...props}
                  style={{
                    ...props.style,
                    background: "linear-gradient(#7A5E05,#705B15,#4B4019)",
                    color: "white",
                  }}
                />
              ),
            },
          }}
        />
      </div>
      <Modal
        show={show3}
        onHide={handleClose3}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <div className="text-center">
            <h4 className="fw-bold text-dark mb-2">Are You Sure</h4>
            <p>This event data will be removed permanently</p>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="success" onClick={handleClose3}>
            Cancel
          </Button>
          <Button variant="danger" onClick={() => DeleteTrip()}>
            <FontAwesomeIcon icon={faCancel} className=" me-2" />
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal
        show={show1}
        onHide={handleClose1}
        dialogClassName="custom-modal"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title className="text-danger">
            Edit International location List
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Container fluid>
            <div className="d-flex justify-content-between align-items-center pt-4 pb-2">
              <p className="text-success fs-4 fw-semibold">
                International location List Edit:
              </p>
            </div>
            <div className="Stepper-info" style={{ padding: "20px" }}>
              <Box sx={{ width: "100%" }}>
                <Stepper nonLinear activeStep={activeStep}>
                  {steps.map((label, index) => (
                    <Step key={label} completed={completed[index]}>
                      <StepButton color="inherit" onClick={handleStep(index)}>
                        {label}
                      </StepButton>
                    </Step>
                  ))}
                </Stepper>
                <div>
                  {allStepsCompleted() ? (
                    <React.Fragment>
                      <Typography
                        sx={{ mt: 2, mb: 1 }}
                        style={{
                          position: "absolute",
                          top: "50%",
                          left: "50%",
                          fontSize: "20px",
                          fontWeight: "500",
                        }}
                      >
                        All steps completed successfully
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            alignSelf: "center",
                            pt: 2,
                          }}
                        >
                          <Button onClick={handleReset}>Back</Button>
                        </Box>
                      </Typography>
                    </React.Fragment>
                  ) : (
                    <React.Fragment>
                      {activeStep == 0 ? (
                        <>
                          <Typography sx={{ mt: 2, mb: 1, py: 1 }}>
                            <div
                              className="container"
                              style={{ padding: "5px" }}
                            >
                              <div className="row ">
                                <div className="col-md-6">
                                  <div className="do-sear mt-2">
                                    <CustomInput
                                      type="text"
                                      label="Destination Name"
                                      name="name"
                                      onChng={(e) =>
                                        setDestinationName(e.target.value)
                                      }
                                      val={
                                        DestinationName
                                          ? DestinationName
                                          : selectedModal.DestinationName
                                      }
                                    />
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="do-sear mt-2">
                                    {/* <label>
                                  Trip Description
                                  <span style={{ color: "red" }}>*</span>
                                </label> */}
                                    <CustomInput
                                      type="text"
                                      label="Destination Sub-title"
                                      name="name"
                                      onChng={(e) =>
                                        setDestinationsubtitle(e.target.value)
                                      }
                                      val={
                                        Destinationsubtitle
                                          ? Destinationsubtitle
                                          : selectedModal.Destinationsubtitle
                                      }
                                    />
                                  </div>
                                </div>

                                <div className="col-md-6">
                                  <div className="do-sear mt-3">
                                    <label>Country</label>

                                    <select
                                      class="ebf4591c8e country-select-for-e2e-tests"
                                      name="cc1"
                                      id="cc1"
                                      className="vi_0"
                                      style={{
                                        width: "100%",
                                        borderColor: "#E6E6E6",
                                      }}
                                      onChange={(e) =>
                                        setCountry1(e.target.value)
                                      }
                                    >
                                      <option value="">
                                        {selectedModal.country}
                                        <span style={{ color: "red" }}>*</span>
                                      </option>
                                      {CountryList?.map((Country) => (
                                        <option value={Country?.isoCode}>
                                          {Country?.name}
                                        </option>
                                      ))}
                                    </select>
                                    {/* // <FormControl fullWidth>
                                //   <InputLabel id="demo-simple-select-helper-label">
                                //     Select country/region{" "}
                                //     <span style={{ color: "red" }}>*</span>
                                //   </InputLabel>
                                //   <Select
                                //     labelId="demo-simple-select-helper-label"
                                //     id="demo-simple-select-helper"
                                //     value={Country1}
                                   
                                //     label="Select country/region"
                                //     onChange={handleChange}
                                //   >
                                 
                                //     {CountryList?.map((item) => (
                                //       <MenuItem value={item?.name}>
                                //         {item?.name}
                                //       </MenuItem>
                                //     ))}
                                //   </Select>
                                  
                                // </FormControl> */}
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="do-sear mt-3">
                                    <label>State</label>

                                    <select
                                      class="ebf4591c8e country-select-for-e2e-tests"
                                      name="cc1"
                                      id="cc1"
                                      className="vi_0"
                                      style={{
                                        width: "100%",
                                        borderColor: "#E6E6E6",
                                      }}
                                      onChange={(e) => {
                                        const selectedState = StateList.find(
                                          (state) =>
                                            state.isoCode === e.target.value
                                        );
                                        setState1(
                                          selectedState
                                            ? {
                                                isoCode: selectedState.isoCode,
                                                name: selectedState.name,
                                              }
                                            : { isoCode: "", name: "" }
                                        );
                                      }}
                                    >
                                      <option value="">
                                        {selectedModal.state}
                                        <span style={{ color: "red" }}>*</span>
                                      </option>
                                      {StateList?.map((state) => (
                                        <option
                                          key={state.isoCode}
                                          value={state.isoCode}
                                        >
                                          {state.name}
                                        </option>
                                      ))}
                                    </select>
                                  </div>
                                </div>

                                <div className="col-md-6">
                                  <div className="do-sear mt-3">
                                    <label>City</label>

                                    <select
                                      className="vi_0"
                                      // class="ebf4591c8e country-select-for-e2e-tests"
                                      style={{
                                        width: "100%",
                                        borderColor: "#E6E6E6",
                                      }}
                                      name="cc1"
                                      id="cc1"
                                      onChange={(e) => setCity1(e.target.value)}
                                    >
                                      <option value="">
                                        {selectedModal.city}
                                        <span style={{ color: "red" }}>*</span>
                                      </option>
                                      {CityList?.map((city1) => (
                                        <option value={city1?.name}>
                                          {city1?.name}
                                        </option>
                                      ))}
                                    </select>
                                  </div>
                                </div>

                                {/* <div className="col-md-6">
                                    <div className="do-sear mt-2">
                                      <CustomInput
                                        type="text"
                                        label="Duration (eg: 3-4 nights)"
                                        name="name"
                                        onChng={(e) =>
                                          setTripDuration(e.target.value)
                                        }
                                        val={TripDuration}
                                      />
                                    </div>
                                  </div> */}
                                <div className="col-md-6">
                                  <div className="do-sear mt-2">
                                    <CustomInput
                                      type="number"
                                      onKeyDown={handleKeyDown}
                                      label="Price as Per Duartion"
                                      name="name"
                                      onChng={(e) =>
                                        setTripCost(e.target.value)
                                      }
                                      val={
                                        TripCost
                                          ? TripCost
                                          : selectedModal.tripcost
                                      }
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </Typography>
                        </>
                      ) : activeStep == 1 ? (
                        <>
                          <Typography sx={{ mt: 2, mb: 1, py: 1 }}>
                            <div
                              className="container"
                              style={{ padding: "5px" }}
                            >
                              <div className="row ">
                                <div className="col-md-6">
                                  <div className="do-sear mt-2">
                                    <label>
                                      Everything user wants to know
                                      <span style={{ color: "red" }}>*</span>
                                    </label>
                                    <CKEditor
                                      editor={ClassicEditor}
                                      data={
                                        PlaceDescription
                                          ? PlaceDescription
                                          : selectedModal.PlaceDescription
                                      }
                                      onChange={handlePlace}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                            {/* <div className="row ">
                                  <div className="col-md-6">
                                    <div className="do-sear mt-2">
                                      <label>
                                        Attractive Place Photos
                                        <span style={{ color: "red" }}>
                                          *
                                        </span>
                                      </label>
                                      <div className=" mt-3">
                                        <input
                                          type="file"

                                          className={`form-control ${i_class}`}
                                          onBlur={onBlr}
                                          accept="image/*"
                                          onChange={(e) => {
                                            onImageChange2(e);
                                            setImage(e.target.files[0]);
                                          }}
                                        />
                                      </div>

                                    </div>

                                    <button
                                      className="pink-btn mt-4 "
                                      style={{ background: "green", color: "#fff", padding: 10 }}
                                      onClick={AddGalleryImage}
                                    >
                                      Add
                                    </button>
                                  </div>

                                  <div className="col-md-6">
                                    <Table responsive bordered>
                                      <thead>
                                        <tr>
                                          <th
                                            style={{
                                              background: "#d81d4a",
                                              color: "#fff",
                                            }}
                                          >
                                            S.No
                                          </th>
                                          <th
                                            style={{
                                              background: "#d81d4a",
                                              color: "#fff",
                                            }}
                                          >
                                            Image
                                          </th>
                                          <th
                                            style={{
                                              background: "#d81d4a",
                                              color: "#fff",
                                            }}
                                          >
                                            Action
                                          </th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        {galleryImages?.map((item, i) => {
                                          return (
                                            <tr>
                                              <td>{i + 1}</td>
                                              <td>
                                                <img
                                                  src={item?.imgUrl}
                                                  alt=""
                                                  style={{
                                                    width: "50px",
                                                    height: "50px",
                                                    objectFit: "cover",
                                                  }}
                                                />
                                              </td>
                                              <td>
                                                <div
                                                  className="d-flex gap-2 fs-4"
                                                  style={{
                                                    cursor: "pointer",
                                                  }}
                                                >
                                                 
                                                  <MdDelete
                                                    onClick={() =>
                                                      removeItem2(i)
                                                    }
                                                    className="text-danger"
                                                  />
                                                </div>
                                              </td>
                                            </tr>
                                          );
                                        })}
                                      </tbody>
                                    </Table>
                                  </div>
                                </div> */}
                          </Typography>
                        </>
                      ) : activeStep == 2 ? (
                        <>
                          <Typography sx={{ mt: 2, mb: 1, py: 1 }}>
                            <div
                              className="container"
                              style={{ padding: "5px" }}
                            >
                              {/* <div className="row ">
                                    <div className="col-md-6">
                                      <div className="do-sear mt-2">
                                        <label>
                                          Best Cultures at your place
                                          <span style={{ color: "red" }}>*</span>
                                        </label>
                                        <CKEditor editor={ClassicEditor}
                                          data={PlaceCulture}
                                          onChange={handleCulture} />


                                      </div>
                                    </div>


                                  </div> */}
                              <div className="row ">
                                <div className="col-md-6">
                                  <div className="do-sear mt-2">
                                    <label>
                                      Attractive Photos
                                      <span style={{ color: "red" }}>*</span>
                                    </label>
                                    <div className=" mt-3">
                                      <input
                                        type="file"
                                        id="fileInput"
                                        className={`form-control ${i_class}`}
                                        onBlur={onBlr}
                                        accept="image/*"
                                        onChange={(e) => {
                                          onImageChangeculture(e);
                                          setImageculture(e.target.files[0]);
                                        }}
                                      />
                                    </div>
                                  </div>

                                  <button
                                    className="pink-btn mt-4 "
                                    style={{
                                      background: "green",
                                      color: "#fff",
                                      padding: 10,
                                    }}
                                    onClick={AddGalleryImageculture}
                                  >
                                    Add
                                  </button>
                                  <div
                                    style={{
                                      overflowY: "scroll",
                                      display: "flex",
                                      flexDirection: "row",
                                      flexWrap: "wrap",
                                      margin: "20px",
                                      // justifyContent: "space-between",
                                    }}
                                  >
                                    {selectedModal.GalleryImageCulture?.map(
                                      (media) => (
                                        <div
                                          key={media._id}
                                          style={{
                                            display: "flex",
                                            flexDirection: "row",
                                            justifyContent: "space-around",
                                          }}
                                        >
                                          <img
                                            src={
                                              "https://bookingoda.com/image/" +
                                              media.imageculture
                                            }
                                            alt="image"
                                            style={{
                                              width: "80px",
                                              height: "80px",
                                              marginRight: "20px",
                                              marginBottom: "20px",
                                            }}
                                            className={`image-item ${
                                              selectedImages.includes(media._id)
                                                ? "selected"
                                                : ""
                                            }`}
                                            onClick={() =>
                                              toggleImageSelection(media._id)
                                            }
                                          />

                                          {/* <MdDelete
                  onClick={() => removeHotel(FullView._id, media._id)}
                  style={{ color: "red" }}
                /> */}
                                        </div>
                                      )
                                    )}
                                  </div>
                                  {selectedImages.length > 0 ? (
                                    <button
                                      onClick={deleteSelectedImages}
                                      style={{
                                        padding: "10px 20px",
                                        backgroundColor: "red",
                                        color: "white",
                                        border: "none",
                                        borderRadius: "5px",
                                        cursor: "pointer",
                                        fontSize: "16px",
                                        fontWeight: "bold",
                                      }}
                                    >
                                      Delete {selectedImages.length} Selected
                                      Image
                                      {selectedImages.length !== 1 ? "s" : ""}
                                    </button>
                                  ) : (
                                    ""
                                  )}
                                </div>

                                <div className="col-md-6">
                                  <Table1 responsive bordered>
                                    <thead>
                                      <tr>
                                        <th
                                          style={{
                                            background: "#d81d4a",
                                            color: "#fff",
                                          }}
                                        >
                                          S.No
                                        </th>
                                        <th
                                          style={{
                                            background: "#d81d4a",
                                            color: "#fff",
                                          }}
                                        >
                                          Image
                                        </th>
                                        <th
                                          style={{
                                            background: "#d81d4a",
                                            color: "#fff",
                                          }}
                                        >
                                          Action
                                        </th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {galleryImagesculture?.map((item, i) => {
                                        return (
                                          <tr>
                                            <td>{i + 1}</td>
                                            <td>
                                              <img
                                                src={item?.imgUrl}
                                                alt=""
                                                style={{
                                                  width: "50px",
                                                  height: "50px",
                                                  objectFit: "cover",
                                                }}
                                              />
                                            </td>
                                            <td>
                                              <div
                                                className="d-flex gap-2 fs-4"
                                                style={{
                                                  cursor: "pointer",
                                                }}
                                              >
                                                {/* <BiSolidEdit className="text-primary" /> */}
                                                <MdDelete
                                                  onClick={() =>
                                                    removeItemculture(i)
                                                  }
                                                  className="text-danger"
                                                />
                                              </div>
                                            </td>
                                          </tr>
                                        );
                                      })}
                                    </tbody>
                                  </Table1>
                                </div>
                              </div>
                            </div>
                          </Typography>
                        </>
                      ) : activeStep == 3 ? (
                        <>
                          <Typography sx={{ mt: 2, mb: 1, py: 1 }}>
                            <div
                              className="container"
                              style={{ padding: "5px" }}
                            >
                              <div className="row ">
                                <div className="col-md-6">
                                  {/* <div className="do-sear mt-2">
                                        <label>
                                          Best Beaches To Visit
                                          <span style={{ color: "red" }}>*</span>
                                        </label>
                                        <CKEditor editor={ClassicEditor}
                                          data={PlaceBeaches}
                                          onChange={handleBeaches} />


                                      </div> */}
                                  <div className="do-sear mt-2">
                                    <CustomInput
                                      type="text"
                                      label="Tour Type"
                                      name="name"
                                      onChng={(e) =>
                                        setTourType(e.target.value)
                                      }
                                      val={
                                        TourType
                                          ? TourType
                                          : selectedModal.TourType
                                      }
                                    />
                                  </div>
                                  <div className="do-sear mt-2">
                                    <CustomInput
                                      type="number"
                                      onKeyDown={handleKeyDown}
                                      label="Group Size"
                                      name="name"
                                      onChng={(e) =>
                                        setGroupSize(e.target.value)
                                      }
                                      val={
                                        GroupSize
                                          ? GroupSize
                                          : selectedModal.GroupSize
                                      }
                                    />
                                  </div>
                                </div>

                                <div className="col-md-6">
                                  <div className="do-sear mt-2">
                                    <CustomInput
                                      type="number"
                                      onKeyDown={handleKeyDown}
                                      label="Number of Tour Guides"
                                      name="name"
                                      onChng={(e) =>
                                        setTourGuide(e.target.value)
                                      }
                                      val={
                                        TourGuide
                                          ? TourGuide
                                          : selectedModal.TourGuide
                                      }
                                    />
                                  </div>
                                  <div className="do-sear mt-2">
                                    <CustomInput
                                      type="text"
                                      label="Duration (eg: 3N-4D)"
                                      name="name"
                                      onChng={(e) =>
                                        setTripDuration(e.target.value)
                                      }
                                      val={
                                        TripDuration
                                          ? TripDuration
                                          : selectedModal.tripduration
                                      }
                                    />
                                  </div>
                                </div>
                              </div>
                              {/* <div className="row ">
                                    <div className="col-md-6">
                                      <div className="do-sear mt-2">
                                        <label>
                                          Attractive Beaches Images
                                          <span style={{ color: "red" }}>
                                            *
                                          </span>
                                        </label>
                                        <div className=" mt-3">
                                          <input
                                            type="file"

                                            className={`form-control ${i_class}`}
                                            onBlur={onBlr}
                                            accept="image/*"
                                            onChange={(e) => {
                                              onImageChangebeaches(e);
                                              setImagebeaches(e.target.files[0]);
                                            }}
                                          />
                                        </div>
                                       
                                      </div>

                                      <button
                                        className="pink-btn mt-4 "
                                        style={{ background: "green", color: "#fff", padding: 10 }}
                                        onClick={AddGalleryImagebeaches}
                                      >
                                        Add
                                      </button>
                                    </div>

                                    <div className="col-md-6">
                                      <Table responsive bordered>
                                        <thead>
                                          <tr>
                                            <th
                                              style={{
                                                background: "#d81d4a",
                                                color: "#fff",
                                              }}
                                            >
                                              S.No
                                            </th>
                                            <th
                                              style={{
                                                background: "#d81d4a",
                                                color: "#fff",
                                              }}
                                            >
                                              Image
                                            </th>
                                            <th
                                              style={{
                                                background: "#d81d4a",
                                                color: "#fff",
                                              }}
                                            >
                                              Action
                                            </th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                          {galleryImagesbeaches?.map((item, i) => {
                                            return (
                                              <tr>
                                                <td>{i + 1}</td>
                                                <td>
                                                  <img
                                                    src={item?.imgUrl}
                                                    alt=""
                                                    style={{
                                                      width: "50px",
                                                      height: "50px",
                                                      objectFit: "cover",
                                                    }}
                                                  />
                                                </td>
                                                <td>
                                                  <div
                                                    className="d-flex gap-2 fs-4"
                                                    style={{
                                                      cursor: "pointer",
                                                    }}
                                                  >
                                                   
                                                    <MdDelete
                                                      onClick={() =>
                                                        removeItembeaches(i)
                                                      }
                                                      className="text-danger"
                                                    />
                                                  </div>
                                                </td>
                                              </tr>
                                            );
                                          })}
                                        </tbody>
                                      </Table>
                                    </div>
                                  </div> */}
                            </div>
                          </Typography>
                        </>
                      ) : activeStep == 4 ? (
                        <>
                          {/* <Typography sx={{ mt: 2, mb: 1, py: 1 }}>
                              <div
                                className="container"
                                style={{ padding: "5px" }}
                              >
                                <div className="row ">
                                  <div className="col-md-6">
                                    <div className="do-sear mt-2">
                                      <label>
                                        Best Hotels To Stay
                                        <span style={{ color: "red" }}>*</span>
                                      </label>
                                      <CKEditor
                                        editor={ClassicEditor}
                                        data={PlaceHotels}
                                        onChange={handleHotel}
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div className="row ">
                                  <div className="col-md-6">
                                    <div className="do-sear mt-2">
                                      <label>
                                        Attractive Hotels Images
                                        <span style={{ color: "red" }}>*</span>
                                      </label>
                                      <div className=" mt-3">
                                        <input
                                          type="file"
                                          className={`form-control ${i_class}`}
                                          onBlur={onBlr}
                                          accept="image/*"
                                          onChange={(e) => {
                                            onImageChangehotel(e);
                                            setImagehotel(e.target.files[0]);
                                          }}
                                        />
                                      </div>
                                     
                                    </div>

                                    <button
                                      className="pink-btn mt-4 "
                                      style={{
                                        background: "green",
                                        color: "#fff",
                                        padding: 10,
                                      }}
                                      onClick={AddGalleryImagehotel}
                                    >
                                      Add
                                    </button>
                                  </div>

                                  <div className="col-md-6">
                                    <Table responsive bordered>
                                      <thead>
                                        <tr>
                                          <th
                                            style={{
                                              background: "#d81d4a",
                                              color: "#fff",
                                            }}
                                          >
                                            S.No
                                          </th>
                                          <th
                                            style={{
                                              background: "#d81d4a",
                                              color: "#fff",
                                            }}
                                          >
                                            Image
                                          </th>
                                          <th
                                            style={{
                                              background: "#d81d4a",
                                              color: "#fff",
                                            }}
                                          >
                                            Action
                                          </th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        {galleryImageshotel?.map((item, i) => {
                                          return (
                                            <tr>
                                              <td>{i + 1}</td>
                                              <td>
                                                <img
                                                  src={item?.imgUrl}
                                                  alt=""
                                                  style={{
                                                    width: "50px",
                                                    height: "50px",
                                                    objectFit: "cover",
                                                  }}
                                                />
                                              </td>
                                              <td>
                                                <div
                                                  className="d-flex gap-2 fs-4"
                                                  style={{
                                                    cursor: "pointer",
                                                  }}
                                                >
                                                
                                                  <MdDelete
                                                    onClick={() =>
                                                      removeItemhotel(i)
                                                    }
                                                    className="text-danger"
                                                  />
                                                </div>
                                              </td>
                                            </tr>
                                          );
                                        })}
                                      </tbody>
                                    </Table>
                                  </div>
                                </div>
                              </div>
                            </Typography> */}
                          <Typography sx={{ mt: 2, mb: 1, py: 1 }}>
                            <div
                              className="container"
                              style={{ padding: "5px" }}
                            >
                              <div className="row ">
                                <div className="col-md-5">
                                  <div className="do-sear mt-2">
                                    {/* <label>
                                          Place Name
                                          <span style={{ color: "red" }}>
                                            *
                                          </span> */}
                                    {/* </label> */}
                                    {/* <input
                                          type="text"
                                          placeholder="Enter Place Name"
                                          className="vi_0"
                                          onChange={(e) =>
                                            setPlaceName(e.target.value)
                                          }
                                        /> */}
                                    <CustomInput
                                      type="text"
                                      label="Place Name"
                                      name="name"
                                      onChng={(e) =>
                                        setPlaceName(e.target.value)
                                      }
                                      val={PlaceName}
                                    />
                                  </div>
                                  <div className="do-sear mt-2">
                                    {/* <label>
                                          Inclusion
                                          <span style={{ color: "red" }}>
                                            *
                                          </span>
                                        </label>
                                        <input
                                          type="text"
                                          placeholder="Enter Inclusion"
                                          className="vi_0"
                                          onChange={(e) =>
                                            setInclusion(e.target.value)
                                          }
                                        /> */}
                                    <CustomInput
                                      type="text"
                                      label="Inclusion"
                                      name="name"
                                      onChng={(e) =>
                                        setInclusion(e.target.value)
                                      }
                                      val={Inclusion}
                                    />
                                  </div>
                                  <div className="do-sear mt-2">
                                    {/* <label>
                                          Exclusion
                                          <span style={{ color: "red" }}>
                                            *
                                          </span>
                                        </label>
                                        <input
                                          type="text"
                                          placeholder="Enter Exclusion"
                                          className="vi_0"
                                          onChange={(e) =>
                                            setExclusion(e.target.value)
                                          }
                                        /> */}
                                    <CustomInput
                                      type="text"
                                      label="Exclusion"
                                      name="name"
                                      onChng={(e) =>
                                        setExclusion(e.target.value)
                                      }
                                      val={Exclusion}
                                    />
                                  </div>
                                  <div className="do-sear mt-2">
                                    {/* <label>
                                          Event Date
                                          <span style={{ color: "red" }}>
                                            *
                                          </span>
                                        </label>
                                        <input
                                          type="text"
                                          placeholder="Enter Event Date/Time"
                                          className="vi_0"
                                          onChange={(e) =>
                                            setEventDate(e.target.value)
                                          }
                                        /> */}
                                    {/* <CustomInput
                                          type="text"
                                          label="Enter Event Date/Time"
                                          name="name"
                                          onChng={(e) =>
                                            setEventDate(e.target.value)
                                          }
                                          val={EventDate}
                                        /> */}
                                  </div>

                                  <button
                                    onClick={AddTourSchedule}
                                    className="pink-btn mt-4"
                                    style={{
                                      background: "green",
                                      color: "#fff",
                                      padding: 10,
                                    }}
                                  >
                                    Add
                                  </button>
                                </div>

                                <div className="col-md-7">
                                  <Table1 responsive bordered>
                                    <thead>
                                      <tr>
                                        {/* <th
                                              style={{
                                                background: "#d81d4a",
                                                color: "#fff",
                                              }}
                                            >
                                              Itinerary
                                            </th> */}
                                        <th
                                          style={{
                                            background: "#d81d4a",
                                            color: "#fff",
                                          }}
                                        >
                                          Place Name
                                        </th>
                                        <th
                                          style={{
                                            background: "#d81d4a",
                                            color: "#fff",
                                          }}
                                        >
                                          Inclusion
                                        </th>
                                        <th
                                          style={{
                                            background: "#d81d4a",
                                            color: "#fff",
                                          }}
                                        >
                                          Exclusion
                                        </th>
                                        {/* <th
                                              style={{
                                                background: "#d81d4a",
                                                color: "#fff",
                                              }}
                                            >
                                              Event Date
                                            </th> */}
                                        <th
                                          style={{
                                            background: "#d81d4a",
                                            color: "#fff",
                                          }}
                                        >
                                          Action
                                        </th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {tour?.map((item, i) => {
                                        return (
                                          <tr>
                                            {/* <td>Day {i + 1}</td> */}
                                            <td>{item?.placename}</td>
                                            <td>{item?.inclusion}</td>
                                            <td>{item?.exclusion}</td>
                                            {/* <td>{item?.eventDate}</td> */}
                                            <td>
                                              <div
                                                className="d-flex gap-2 fs-4"
                                                style={{
                                                  cursor: "pointer",
                                                }}
                                              >
                                                {/* <BiSolidEdit className="text-primary" /> */}
                                                <MdDelete
                                                  onClick={() =>
                                                    removeschedule(i)
                                                  }
                                                  className="text-danger"
                                                />
                                              </div>
                                            </td>
                                          </tr>
                                        );
                                      })}
                                    </tbody>
                                  </Table1>
                                </div>
                              </div>
                            </div>
                          </Typography>
                        </>
                      ) : activeStep == 5 ? (
                        <>
                          {/* <Typography sx={{ mt: 2, mb: 1, py: 1 }}>
                              <div
                                className="container"
                                style={{ padding: "5px" }}
                              >
                                <div className="row ">
                                  <div className="col-md-6">
                                    <div className="do-sear mt-2">
                                      <label>
                                        Popular at your place
                                        <span style={{ color: "red" }}>*</span>
                                      </label>
                                      <CKEditor
                                        editor={ClassicEditor}
                                        data={PlacePopular}
                                        onChange={handlePopular}
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div className="row ">
                                  <div className="col-md-6">
                                    <div className="do-sear mt-2">
                                      <label>
                                        Attractive Popular Images
                                        <span style={{ color: "red" }}>*</span>
                                      </label>
                                      <div className=" mt-3">
                                        <input
                                          type="file"
                                          className={`form-control ${i_class}`}
                                          onBlur={onBlr}
                                          accept="image/*"
                                          onChange={(e) => {
                                            onImageChangepopular(e);
                                            setImagepopular(e.target.files[0]);
                                          }}
                                        />
                                      </div>
                                    
                                    </div>

                                    <button
                                      className="pink-btn mt-4 "
                                      style={{
                                        background: "green",
                                        color: "#fff",
                                        padding: 10,
                                      }}
                                      onClick={AddGalleryImagepopular}
                                    >
                                      Add
                                    </button>
                                  </div>

                                  <div className="col-md-6">
                                    <Table responsive bordered>
                                      <thead>
                                        <tr>
                                          <th
                                            style={{
                                              background: "#d81d4a",
                                              color: "#fff",
                                            }}
                                          >
                                            S.No
                                          </th>
                                          <th
                                            style={{
                                              background: "#d81d4a",
                                              color: "#fff",
                                            }}
                                          >
                                            Image
                                          </th>
                                          <th
                                            style={{
                                              background: "#d81d4a",
                                              color: "#fff",
                                            }}
                                          >
                                            Action
                                          </th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        {galleryImagespopular?.map(
                                          (item, i) => {
                                            return (
                                              <tr>
                                                <td>{i + 1}</td>
                                                <td>
                                                  <img
                                                    src={item?.imgUrl}
                                                    alt=""
                                                    style={{
                                                      width: "50px",
                                                      height: "50px",
                                                      objectFit: "cover",
                                                    }}
                                                  />
                                                </td>
                                                <td>
                                                  <div
                                                    className="d-flex gap-2 fs-4"
                                                    style={{
                                                      cursor: "pointer",
                                                    }}
                                                  >
                                                  
                                                    <MdDelete
                                                      onClick={() =>
                                                        removeItempopular(i)
                                                      }
                                                      className="text-danger"
                                                    />
                                                  </div>
                                                </td>
                                              </tr>
                                            );
                                          }
                                        )}
                                      </tbody>
                                    </Table>
                                  </div>
                                </div>
                              </div>
                            </Typography> */}
                          <Typography sx={{ mt: 2, mb: 1, py: 1 }}>
                            <div
                              className="container"
                              style={{ padding: "5px" }}
                            >
                              <div className="row ">
                                <div className="col-md-5">
                                  <div className="do-sear mt-2">
                                    <CustomInput
                                      type="text"
                                      label="Itinerary Title"
                                      name="name"
                                      onChng={(e) => setHeader(e.target.value)}
                                      val={Header}
                                    />
                                  </div>

                                  <div className="do-sear mt-2">
                                    <label>
                                      Description
                                      <span style={{ color: "red" }}>*</span>
                                    </label>
                                    <textarea
                                      type="text"
                                      label="Inclusion"
                                      name="name"
                                      onChange={(e) =>
                                        setItineraryDesc(e.target.value)
                                      }
                                      value={ItineraryDesc}
                                    />
                                  </div>

                                  <button
                                    onClick={AddItinerary}
                                    className="pink-btn mt-4"
                                    style={{
                                      background: "green",
                                      color: "#fff",
                                      padding: 10,
                                    }}
                                  >
                                    Add
                                  </button>
                                </div>

                                <div className="col-md-7">
                                  <Table1 responsive bordered>
                                    <thead>
                                      <tr>
                                        <th
                                          style={{
                                            background: "#d81d4a",
                                            color: "#fff",
                                          }}
                                        >
                                          Itinerary
                                        </th>
                                        <th
                                          style={{
                                            background: "#d81d4a",
                                            color: "#fff",
                                          }}
                                        >
                                          Title
                                        </th>
                                        <th
                                          style={{
                                            background: "#d81d4a",
                                            color: "#fff",
                                          }}
                                        >
                                          Description
                                        </th>

                                        <th
                                          style={{
                                            background: "#d81d4a",
                                            color: "#fff",
                                          }}
                                        >
                                          Action
                                        </th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {Itinerarys?.map((item, i) => {
                                        return (
                                          <tr>
                                            <td>Day {i + 1}</td>
                                            <td>{item?.Header}</td>
                                            <td>{item?.ItineraryDesc}</td>

                                            <td>
                                              <div
                                                className="d-flex gap-2 fs-4"
                                                style={{
                                                  cursor: "pointer",
                                                }}
                                              >
                                                {/* <BiSolidEdit className="text-primary" /> */}
                                                <MdDelete
                                                  onClick={() =>
                                                    removeItinerary(i)
                                                  }
                                                  className="text-danger"
                                                />
                                              </div>
                                            </td>
                                          </tr>
                                        );
                                      })}
                                    </tbody>
                                  </Table1>
                                </div>
                              </div>
                            </div>
                          </Typography>
                        </>
                      ) : (
                        // activeStep == 6 ? (
                        //   <>
                        //     <Typography sx={{ mt: 2, mb: 1, py: 1 }}>
                        //       <div
                        //         className="container"
                        //         style={{ padding: "5px" }}
                        //       >
                        //         <div className="row ">
                        //           <div className="col-md-6">
                        //             <div className="do-sear mt-2">
                        //               <label>
                        //                 What's Included
                        //                 <span style={{ color: "red" }}>*</span>
                        //               </label>
                        //               <CKEditor
                        //                 editor={ClassicEditor}
                        //                 data={PlaceIncluded}
                        //                 onChange={handleIncluded}
                        //               />
                        //             </div>
                        //           </div>
                        //         </div>
                        //       </div>
                        //     </Typography>
                        //   </>
                        // ) :
                        <></>
                      )}

                      <Box
                        sx={{ display: "flex", flexDirection: "row", pt: 2 }}
                      >
                        <Button
                          style={{ background: "green", color: "#fff" }}
                          disabled={activeStep === 0}
                          onClick={handleBack}
                          sx={{ mr: 1 }}
                        >
                          Back
                        </Button>
                        <Box sx={{ flex: "1 1 auto" }} />

                        <button
                          className="pink-btn"
                          style={{
                            background: "green",
                            color: "#fff",
                            padding: 10,
                          }}
                          onClick={() => {
                            completedSteps() === totalSteps() - 1
                              ? TourpackageDetails()
                              : handleComplete();
                          }}
                        >
                          {completedSteps() === totalSteps() - 1
                            ? "Submit"
                            : "Save"}
                        </button>
                      </Box>
                    </React.Fragment>
                  )}
                </div>
              </Box>
            </div>
          </Container>
        </Modal.Body>
      </Modal>
      <Modal show={show2} onHide={handleClose2} size="lg">
        <Modal.Header closeButton>
          <Modal.Title className="text-danger">International List</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div
            style={{
              marginTop: "30px",
              marginBottom: "20px",
              fontWeight: "900",
              fontSize: "18px",
              color: "white",
              border: "2px solid #B8BCC7",
              backgroundColor: "#B8BCC7",
              width: "100%",
              boxSizing: "border-box",
              padding: "10px",
            }}
          >
            Place Description
          </div>
          <div style={{ fontSize: "18px", marginLeft: "10px" }}>
            {parse(String(FullView?.PlaceDescription))}
          </div>

          <div
            style={{
              marginTop: "30px",
              marginBottom: "20px",
              fontWeight: "900",
              fontSize: "18px",
              color: "white",
              border: "2px solid #B8BCC7",
              backgroundColor: "#B8BCC7",
              width: "100%",
              boxSizing: "border-box",
              padding: "10px",
            }}
          >
            Gallery Photos
          </div>
          <div
            style={{
              overflowY: "scroll",
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap",
              margin: "20px",
              // justifyContent: "space-between",
            }}
          >
            {FullView.GalleryImageCulture?.map((media) => (
              <div
                key={media._id}
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-around",
                }}
              >
                <img
                  src={"https://bookingoda.com/image/" + media.imageculture}
                  alt="image"
                  style={{
                    width: "80px",
                    height: "80px",
                    marginRight: "20px",
                    marginBottom: "20px",
                  }}
                  className={`image-item ${
                    selectedImages.includes(media._id) ? "selected" : ""
                  }`}
                  onClick={() => toggleImageSelection(media._id)}
                />

                {/* <MdDelete
                  onClick={() => removeHotel(FullView._id, media._id)}
                  style={{ color: "red" }}
                /> */}
              </div>
            ))}
          </div>
          {selectedImages.length > 0 ? (
            <button
              onClick={deleteSelectedImages}
              style={{
                padding: "10px 20px",
                backgroundColor: "red",
                color: "white",
                border: "none",
                borderRadius: "5px",
                cursor: "pointer",
                fontSize: "16px",
                fontWeight: "bold",
              }}
            >
              Delete {selectedImages.length} Selected Image
              {selectedImages.length !== 1 ? "s" : ""}
            </button>
          ) : (
            ""
          )}
          <div
            style={{
              marginTop: "30px",
              marginBottom: "20px",
              fontWeight: "900",
              fontSize: "18px",
              color: "white",
              border: "2px solid #B8BCC7",
              backgroundColor: "#B8BCC7",
              width: "100%",
              boxSizing: "border-box",
              padding: "10px",
            }}
          >
            Tour Details
          </div>
          <div style={{ fontSize: "18px", marginLeft: "10px" }}>
            Tour Type: {FullView.TourType} , Tour Guide : {FullView.TourGuide}{" "}
            People, Group Size:{FullView.GroupSize}People
          </div>
          <div
            style={{
              marginTop: "30px",
              marginBottom: "20px",
              fontWeight: "900",
              fontSize: "18px",
              color: "white",
              border: "2px solid #B8BCC7",
              backgroundColor: "#B8BCC7",
              width: "100%",
              boxSizing: "border-box",
              padding: "10px",
            }}
          >
            Tour Schedule
          </div>
          <div style={{ fontSize: "18px", marginLeft: "10px" }}>
            <Table1 responsive bordered>
              <thead>
                <th>Sl.No</th>
                <th>Places covered</th>
                <th>Inclusions</th>
                <th>Exclusions</th>
                {/* <th>Event Date</th> */}
                <th>Action</th>
              </thead>
              <tbody>
                {FullView?.TourSchedule?.map((item, i) => {
                  return (
                    <tr>
                      <td>{i + 1}</td>
                      <td> {item?.placename}</td>
                      <td> {item?.inclusion}</td>
                      <td> {item?.exclusion}</td>
                      {/* <td> {item?.eventDate}</td> */}
                      <td>
                        {" "}
                        <div
                          className="d-flex gap-2 fs-4"
                          style={{ cursor: "pointer" }}
                        >
                          {/* <BiSolidEdit className="text-primary" /> */}
                          <MdDelete
                            className="text-danger"
                            onClick={() =>
                              removeSchedules(FullView?._id, item._id)
                            }
                          />
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table1>
          </div>
          <div
            style={{
              marginTop: "30px",
              marginBottom: "20px",
              fontWeight: "900",
              fontSize: "18px",
              color: "white",
              border: "2px solid #B8BCC7",
              backgroundColor: "#B8BCC7",
              width: "100%",
              boxSizing: "border-box",
              padding: "10px",
            }}
          >
            Itinerary
          </div>
          <div style={{ fontSize: "18px", marginLeft: "10px" }}>
            <Table1 responsive bordered>
              <thead>
                <th>Days</th>
                <th>Title</th>
                <th>Itinerary Description</th>

                <th>Action</th>
              </thead>
              <tbody>
                {FullView?.Itinerary?.map((item, i) => {
                  return (
                    <tr>
                      <td>Day {i + 1}</td>
                      <td> {item?.Header}</td>
                      <td> {item?.ItineraryDesc}</td>

                      <td>
                        {" "}
                        <div
                          className="d-flex gap-2 fs-4"
                          style={{ cursor: "pointer" }}
                        >
                          {/* <BiSolidEdit className="text-primary" /> */}
                          <MdDelete
                            className="text-danger"
                            onClick={() =>
                              removeItinerarys(FullView?._id, item._id)
                            }
                          />
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table1>
          </div>
          <div
            style={{
              marginTop: "30px",
              marginBottom: "20px",
              fontWeight: "900",
              fontSize: "18px",
              color: "white",
              border: "2px solid #B8BCC7",
              backgroundColor: "#B8BCC7",
              width: "100%",
              boxSizing: "border-box",
              padding: "10px",
            }}
          >
            Location
          </div>
          <div style={{ fontSize: "18px", marginLeft: "10px" }}>
            {FullView.city},{FullView.state},{FullView.country}
          </div>
          <div
            style={{
              marginTop: "30px",
              marginBottom: "20px",
              fontWeight: "900",
              fontSize: "18px",
              color: "white",
              border: "2px solid #B8BCC7",
              backgroundColor: "#B8BCC7",
              width: "100%",
              boxSizing: "border-box",
              padding: "10px",
            }}
          >
            Duration
          </div>
          <div style={{ fontSize: "18px", marginLeft: "10px" }}>
            {FullView.tripduration}
          </div>
          <div
            style={{
              marginTop: "30px",
              marginBottom: "20px",
              fontWeight: "900",
              fontSize: "18px",
              color: "white",
              border: "2px solid #B8BCC7",
              backgroundColor: "#B8BCC7",
              width: "100%",
              boxSizing: "border-box",
              padding: "10px",
            }}
          >
            Trip Cost
          </div>
          <div style={{ fontSize: "18px", marginLeft: "10px" }}>
            {FullView.tripcost}
          </div>
        </Modal.Body>
        {/* <Modal.Footer>
            <Button variant="warning" onClick={handleClose2}>
              Close
            </Button>
            <Button variant="danger" onClick={handleClose2}>
              Save
            </Button>
          </Modal.Footer> */}
      </Modal>
    </div>
  );
};

export default InternationalPackages;
