import React, { useEffect, useState } from "react";
import "./property.css";
import Apartment from "../../assets/apartments.png";
import Apartments from "../../assets/buildings.png";

import Homestay from "../../assets/homestay.png";
import Hotel from "../../assets/hotel.png";
import VendorHeader from "./Vendorheader";
import { useLocation, useNavigate } from "react-router-dom";

const Addroom = () => {
  const { state } = useLocation();
  //console.log(state);
  const handleKeyDown = (e) => {
    // Prevent the input of the minus sign
    if (e.key === '-' || e.key === 'e' || e.key === 'E') {
      e.preventDefault();
    }
  };
  const [bedCount, setBedCount] = useState(1);
  const [beds, setBeds] = useState([]);

  const addBed = () => {
    setBedCount(bedCount + 1);
    setBeds([...beds, { name: `Bed ${bedCount + 1}`, quantity: 1 }]);
  };

  const removeBed = (index) => {
    const updatedBeds = beds.filter((_, i) => i !== index);
    setBeds(updatedBeds);
  };
  ////console.log(beds, "sds");
  const updateBedQuantity = (index, newQuantity) => {
    const updatedBeds = [...beds];
    updatedBeds[index].quantity = newQuantity;
    setBeds(updatedBeds);
  };

  const updateBedName = (index, newName) => {
    const updatedBeds = [...beds];
    updatedBeds[index].name = newName;
    setBeds(updatedBeds);
  };
  const [unit, setunit] = useState("");
  const [type, settype] = useState("");
  const [twinbed, settwinbed] = useState(0);
  const [fullbed, setfullbed] = useState(0);
  const [queenbed, setqueenbed] = useState(0);
  const [kingbed, setkingbed] = useState(0);
  const [queststay, setqueststay] = useState(0);
  const [roomsize, setroomsize] = useState(0);
  const [roommeasure, setroommeasure] = useState("");

  const [roomsmoking, setroomsmoking] = useState("");
  const history = useNavigate();
  const increaseRange = () => {
    settwinbed(twinbed + 1);
  };
  const decrement = () => {
    settwinbed(twinbed - 1);
  };
  const increaseRange1 = () => {
    setfullbed(fullbed + 1);
  };
  const decrement1 = () => {
    setfullbed(fullbed - 1);
  };
  const increaseRange2 = () => {
    setqueenbed(queenbed + 1);
  };
  const decrement2 = () => {
    setqueenbed(queenbed - 1);
  };
  const increaseRange3 = () => {
    setkingbed(kingbed + 1);
  };
  const decrement3 = () => {
    setkingbed(kingbed - 1);
  };
  const increaseRange4 = () => {
    setqueststay(queststay + 1);
  };
  const decrement4 = () => {
    setqueststay(queststay - 1);
  };
  const HandleClick = () => {
    if(selectedOptions.length < 1){
      alert("Please select room type")
    }
    else if(type === ""){
      alert("Please enter number of room type selected before")
    } else if(queststay < 1){
      alert("Please enter number of guests can stay in this room")
    }else if(roomsmoking === ""){
      alert("Please select smoking is allowed are not")
    } else{
    history("/bathroomdetails",{
      state: 
       
        state,
      
    },sessionStorage.setItem("RoomUnit", JSON.stringify(selectedOptions)), sessionStorage.setItem("Roomtype", JSON.stringify(type)),sessionStorage.setItem("TwinBed", JSON.stringify(twinbed)),sessionStorage.setItem("Fullbed", JSON.stringify(fullbed)),sessionStorage.setItem("Queenbed", JSON.stringify(queenbed)),sessionStorage.setItem("Kingbed", JSON.stringify(kingbed)),sessionStorage.setItem("QuestStay", JSON.stringify(queststay)),sessionStorage.setItem("Roomsize", JSON.stringify(roomsize)),sessionStorage.setItem("Roommeasure", JSON.stringify(roommeasure)),sessionStorage.setItem("Roomsmoking", JSON.stringify(roomsmoking)),sessionStorage.setItem("addbeds", JSON.stringify(beds)),
    );
  }
  };
  useEffect(() => {
    // Retrieve items from sessionStorage
    const storedRoomUnit = sessionStorage.getItem("RoomUnit");
    const storedRoomtype = sessionStorage.getItem("Roomtype");
    const storedTwinBed = sessionStorage.getItem("TwinBed");
    const storedQueenBed = sessionStorage.getItem("Queenbed");
    const storedKingBed = sessionStorage.getItem("Kingbed");
    const storedQuestStay = sessionStorage.getItem("QuestStay");
    const storedRoomSize = sessionStorage.getItem("Roomsize");
    const storedRoomMeasure = sessionStorage.getItem("Roommeasure");
    const storedRoomSmoking = sessionStorage.getItem("Roomsmoking");
    const storedAddBeds = sessionStorage.getItem("addbeds"); 
    // Check if the items exist in sessionStorage and set state accordingly
    if (storedRoomUnit) {
      setSelectedOptions(JSON.parse(storedRoomUnit));
    }

    if (storedRoomtype) {
      settype(JSON.parse(storedRoomtype));
    }

    if (storedTwinBed) {
      settwinbed(JSON.parse(storedTwinBed));
    }

    if (storedQueenBed) {
      setqueenbed(JSON.parse(storedQueenBed));
    }

    if (storedKingBed) {
      setkingbed(JSON.parse(storedKingBed));
    }

    if (storedQuestStay) {
      setqueststay(JSON.parse(storedQuestStay));
    }

    if (storedRoomSize) {
      setroomsize(JSON.parse(storedRoomSize));
    }

    if (storedRoomMeasure) {
      setroommeasure(JSON.parse(storedRoomMeasure));
    }

    if (storedRoomSmoking) {
      setroomsmoking(JSON.parse(storedRoomSmoking));
    }

    if (storedAddBeds) {
      setBeds(JSON.parse(storedAddBeds));
    }
  }, []); 


  const [options, setOptions] = useState([
    {
      id: 1,
      label: "Single",
      value: "Single",
    },
    {
      id: 2,
      label: "Double",
      value: "Double",
    },
    {
      id: 3,
      label: "Twin",
      value: "Twin",
    },
    {
      id: 4,
      label: "Twin/Double",
      value: "Twin/Double",
    },
    {
      id: 5,
      label: "Triple",
      value: "Triple",
    },
    {
      id: 6,
      label: "Quad",
      value: "Quad",
    },
    {
      id: 7,
      label: "Suite",
      value: "Suite",
    },
    {
      id: 8,
      label: "Family",
      value: "Family",
    },
    
    {
      id: 9,
      label: "Studio",
      value: "Studio",
    },
    {
      id: 10,
      label: "Apartment",
      value: "Apartment",
    },
    {
      id: 11,
      label: "Dorm Room",
      value: "Dorm Room",
    },
    {
      id: 12,
      label: "Bed in Dorm",
      value: "Bed in Dorm",
    },
  
    // Add more objects as needed
  ]);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [showOptions, setShowOptions] = useState(false);
  const [newOption, setNewOption] = useState("");

  // const handleOptionClick = (option) => {
  //   const isSelected = selectedOptions.some(
  //     (selectedOption) => selectedOption.id === option.id
  //   );
  //   if (isSelected) {
  //     setSelectedOptions(
  //       selectedOptions.filter(
  //         (selectedOption) => selectedOption.id !== option.id
  //       )
  //     );
  //   } else {
  //     setSelectedOptions([...selectedOptions, option]);
  //   }
  // };

  // const handleAddOption = () => {
  //   if (newOption.trim() !== "") {
  //     const optionId = options.length + 1;
  //     const newOptionObj = {
  //       id: optionId,
  //       label: newOption,
  //       value: newOption.toLowerCase().replace(/\s/g, ""),
  //       extraData: "Additional Info for " + newOption,
  //     };
  //     setOptions([...options, newOptionObj]);
  //     setNewOption("");
  //   }
  // };
  const handleOptionClick = (option) => {
    // Clear all selected options
    setSelectedOptions([]);
  
    // Add the clicked option
    setSelectedOptions([option]);
    setShowOptions(false)
  };
  
  const handleAddOption = () => {
    if (newOption.trim() !== "") {
      const optionId = options.length + 1;
      const newOptionObj = {
        id: optionId,
        label: newOption,
        value: newOption.toLowerCase().replace(/\s/g, ""),
        extraData: "Additional Info for " + newOption,
      };
      setOptions([...options, newOptionObj]);
      setNewOption("");
    }
  };
  
  const handleOptionChange = (event) => {
    setroomsmoking(event.target.value);
  };
  return (
    <div
      data-capla-component="b-registration-joinapp15min-fe/"
      data-capla-namespace="b-registration-joinapp15min-feaYGUODfA"
    >
      <div id="app-container">
        <div class="error-popup-container"></div>
        <div class="application">
          <VendorHeader />
          <div class="error-popup-container"></div>
          <div className="page-container">
            <div className="screens screens-animation-direction-forward">
              <div
                className="height-100"
                id="automation_id_screen_container_Bedroom"
              >
                <div className="height-100 screens-animation-direction-backward">
                  <div className="ms-container" id="automation_id_bedroom_page">
                    <div className="form-container">
                      <div className="f2cf178bcd screen-main-header d991e1216a">
                        <div className="f6431b446c e6208ee469 d0caee4251 screen-main-header__title">
                          Room details
                        </div>
                      </div>
                      <div
                        data-testid="MupRoomFlowLikeContainer"
                        className="ffb9c3d6a3 wide-container second-container"
                      >
                        <div className="e1eebb6a1e spacing--4x question-title">
                          What type of unit is this?
                        </div>
                        <div className="a53cbfa6de ac9267e216">
                        <div className="e000754250">
                              <input
                                type="text"
                                value={newOption}
                                onChange={(e) => setNewOption(e.target.value)}
                                placeholder="Type to add or search..."
                                class="eb46370fe1"
                                onClick={() => setShowOptions(!showOptions)}
                              />
                            </div>
                            <button
                              style={{
                                marginTop: "10px",
                                padding: "10px 20px",
                                fontSize: "16px",
                                backgroundColor: "#70bbe9",
                                color: "white",
                                border: "none",
                                borderRadius: "5px",
                                cursor: "pointer",
                                transition: "background-color 0.3s",
                              }}
                              onClick={handleAddOption}
                            >
                              Add
                            </button>
                            {showOptions && (
                              <div
                              className="ebf4591c8e"
                              >   {options.map((option) => (     
                               <div className="cabad3c686 aff44befa2">
                            {/* <select
                              className="ebf4591c8e"
                              name="room_type"
                              data-testid="InputRadioWithOptions-inputSelect-room_type"
                              id=":r63:"
                              // value={unit}
                              // onChange={(e) => setunit(e.target.value)}
                              onClick={() => handleOptionClick(option)}
                            > */}
                            <div
                                    
                                    key={option.id}
                                    style={{
                                      padding: "10px",
                                      cursor: "pointer",
                                      color: "black",
                                      backgroundColor: selectedOptions.some(
                                        (selectedOption) =>
                                          selectedOption.id === option.id
                                      )
                                        ? "#ddd"
                                        : "transparent",
                                    }}
                                    onClick={() => handleOptionClick(option)}
                                  >  
                              <option value="Bed in Dorm" data-key={26}>
                              {option.label}
                              </option>
                              </div> 
                         
                          </div>))}</div>)}
                          {selectedOptions && (
                              <div className="selected-option">
                                Selected units:{" "}
                                {selectedOptions
                                  .map((option) => option.label)
                                  .join(", ")}
                              </div>
                            )}
                        </div>
                        <div className="spacing--6x" />
                        <label
                          className="spacing--4x bedroom-label"
                          htmlFor="automation_id_num_units_with_exact_layout"
                        >
                          <div className="e1eebb6a1e">
                            How many rooms of this type do you have?
                          </div>
                        </label>
                        <div className="num-unit-container">
                          <div className="a53cbfa6de ac9267e216 num-unit a20293ec70">
                            <div className="b9b84f4305">
                              <div className="e000754250">
                                <input
                                  type="number" onKeyDown={handleKeyDown}
                                  name="num_units_with_exact_layout"
                                  className="eb46370fe1"
                                  id="automation_id_num_units_with_exact_layout"
                                  value={type}
                                  onChange={(e) => settype(e.target.value)}
                                />
                                <div className="e7e5251f68" />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="ffb9c3d6a31 wide-container second-container">
                        <div className="aca0ade214 ebac6e22e9 f66f916626 c2931f4182 c27e5d305d db150fece4 spacing--6x">
                          <div className="b817090550 b736e9e3f4">
                            <div className="e1eebb6a1e">
                              What beds are available in this room?
                            </div>
                          </div>
                        </div>
                        <div className="aca0ade214 ebac6e22e9 f66f916626 c2931f4182 c27e5d305d db150fece4 spacing--6x addRoom">
                          <div className="roomDesc b817090550 e0ad3ea0c7">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 96 96"
                            >
                              <path d="M78.25 48h-60.5C16.1 48 15 49.1 15 50.77v30.46C15 82.9 16.1 84 17.75 84s2.75-1.1 2.75-2.77V70.15h55v11.08c0 1.66 1.1 2.77 2.75 2.77S81 82.9 81 81.23V50.77C81 49.1 79.9 48 78.25 48zm-22-27.7h-27.5v-5.53c0-1.66-1.1-2.77-2.75-2.77s-2.75 1.1-2.75 2.77v27.7h33V20.3z" />
                              <path d="M72.75 23.08v-8.3c0-1.67-1.1-2.78-2.75-2.78s-2.75 1.1-2.75 2.77v5.54h-27.5v22.16h33V23.08z" />
                            </svg>
                            <div className="f2cf178bcd">
                              <div className="a3332d346a e6208ee469 d0caee4251 bed-type--not-highlighted">
                                Twin bed(s)
                              </div>
                              <div className="abf093bdfe f45d8e4c32 df64fda51b">
                                35–51 inches wide
                              </div>
                            </div>
                          </div>
                          <div className="b817090550 a7cf1a6b1d">
                            <div
                              id="automation_id_add_bedroom_single_bed_input"
                              data-testid="automation_id_add_bedroom_single_bed_input"
                              className="a7a72174b8"
                            >
                              <div className="bfb38641b0">
                                <button
                                  tabIndex={-1}
                                  disabled={twinbed < 1}
                                  aria-hidden="true"
                                  type="button"
                                  onClick={decrement}
                                  className="a83ed08757 c21c56c305 f38b6daa18 d691166b09 ab98298258 deab83296e e91c91fa93"
                                >
                                  <span className="eedba9e88a">
                                    <span
                                      className="fcd9eec8fb bf9a32efa5"
                                      aria-hidden="true"
                                    >
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 24 24"
                                      >
                                        <path d="M20.25 12.75H3.75a.75.75 0 0 1 0-1.5h16.5a.75.75 0 0 1 0 1.5z" />
                                      </svg>
                                    </span>
                                  </span>
                                </button>
                                <span className="d723d73d5f" aria-hidden="true">
                                  {twinbed}
                                </span>
                                <button
                                  tabIndex={-1}
                                  aria-hidden="true"
                                  type="button"
                                  onClick={increaseRange}
                                  className="a83ed08757 c21c56c305 f38b6daa18 d691166b09 ab98298258 deab83296e bb803d8689 f4d78af12a"
                                >
                                  <span className="eedba9e88a">
                                    <span
                                      className="fcd9eec8fb bf9a32efa5"
                                      aria-hidden="true"
                                    >
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 24 24"
                                      >
                                        <path d="M20.25 11.25h-7.5v-7.5a.75.75 0 0 0-1.5 0v7.5h-7.5a.75.75 0 0 0 0 1.5h7.5v7.5a.75.75 0 0 0 1.5 0v-7.5h7.5a.75.75 0 0 0 0-1.5z" />
                                      </svg>
                                    </span>
                                  </span>
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="aca0ade214 ebac6e22e9 f66f916626 c2931f4182 c27e5d305d db150fece4 spacing--6x addRoom">
                          <div className="roomDesc b817090550 e0ad3ea0c7">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 96 96"
                            >
                              <path d="M89.25 48H6.75C5.1 48 4 49.1 4 50.77v30.46C4 82.9 5.1 84 6.75 84s2.75-1.1 2.75-2.77V70.15h77v11.08c0 1.66 1.1 2.77 2.75 2.77S92 82.9 92 81.23V50.77C92 49.1 90.9 48 89.25 48zm-44-27.7h-27.5v-5.53c0-1.66-1.1-2.77-2.75-2.77s-2.75 1.1-2.75 2.77v27.7h33V20.3zm38.5 2.78v-8.3c0-1.67-1.1-2.78-2.75-2.78s-2.75 1.1-2.75 2.77v5.54h-27.5v22.16h33V23.08z" />
                            </svg>
                            <div className="f2cf178bcd">
                              <div className="a3332d346a e6208ee469 d0caee4251">
                                Full bed(s)
                              </div>
                              <div className="abf093bdfe f45d8e4c32 df64fda51b">
                                52–59 inches wide
                              </div>
                            </div>
                          </div>
                          <div className="b817090550 a7cf1a6b1d">
                            <div
                              id="automation_id_add_bedroom_double_bed_input"
                              data-testid="automation_id_add_bedroom_double_bed_input"
                              className="a7a72174b8"
                            >
                              <div className="bfb38641b0">
                                <button
                                  tabIndex={-1}
                                  disabled={fullbed < 1}
                                  aria-hidden="true"
                                  type="button"
                                  className="a83ed08757 c21c56c305 f38b6daa18 d691166b09 ab98298258 deab83296e bb803d8689 e91c91fa93"
                                  onClick={decrement1}
                                >
                                  <span className="eedba9e88a">
                                    <span
                                      className="fcd9eec8fb bf9a32efa5"
                                      aria-hidden="true"
                                    >
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 24 24"
                                      >
                                        <path d="M20.25 12.75H3.75a.75.75 0 0 1 0-1.5h16.5a.75.75 0 0 1 0 1.5z" />
                                      </svg>
                                    </span>
                                  </span>
                                </button>
                                <span className="d723d73d5f" aria-hidden="true">
                                  {fullbed}
                                </span>
                                <button
                                  tabIndex={-1}
                                  aria-hidden="true"
                                  type="button"
                                  onClick={increaseRange1}
                                  className="a83ed08757 c21c56c305 f38b6daa18 d691166b09 ab98298258 deab83296e bb803d8689 f4d78af12a"
                                >
                                  <span className="eedba9e88a">
                                    <span
                                      className="fcd9eec8fb bf9a32efa5"
                                      aria-hidden="true"
                                    >
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 24 24"
                                      >
                                        <path d="M20.25 11.25h-7.5v-7.5a.75.75 0 0 0-1.5 0v7.5h-7.5a.75.75 0 0 0 0 1.5h7.5v7.5a.75.75 0 0 0 1.5 0v-7.5h7.5a.75.75 0 0 0 0-1.5z" />
                                      </svg>
                                    </span>
                                  </span>
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="aca0ade214 ebac6e22e9 f66f916626 c2931f4182 c27e5d305d db150fece4 spacing--6x addRoom">
                          <div className="roomDesc b817090550 e0ad3ea0c7">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 96 96"
                            >
                              <path d="M89.25 48H6.75C5.1 48 4 49.1 4 50.77v30.46C4 82.9 5.1 84 6.75 84s2.75-1.1 2.75-2.77V70.15h77v11.08c0 1.66 1.1 2.77 2.75 2.77S92 82.9 92 81.23V50.77C92 49.1 90.9 48 89.25 48zm-44-27.7h-27.5v-5.53c0-1.66-1.1-2.77-2.75-2.77s-2.75 1.1-2.75 2.77v27.7h33V20.3zm38.5 2.78v-8.3c0-1.67-1.1-2.78-2.75-2.78s-2.75 1.1-2.75 2.77v5.54h-27.5v22.16h33V23.08z" />
                            </svg>
                            <div className="f2cf178bcd">
                              <div className="a3332d346a e6208ee469 d0caee4251 bed-type--not-highlighted">
                                Queen bed(s)
                              </div>
                              <div className="abf093bdfe f45d8e4c32 df64fda51b">
                                60–70 inches wide
                              </div>
                            </div>
                          </div>
                          <div className="b817090550 a7cf1a6b1d">
                            <div
                              id="automation_id_add_bedroom_large_double_bed_input"
                              data-testid="automation_id_add_bedroom_large_double_bed_input"
                              className="a7a72174b8"
                            >
                              <div className="bfb38641b0">
                                <button
                                  tabIndex={-1}
                                  disabled={queenbed < 1}
                                  aria-hidden="true"
                                  type="button"
                                  onClick={decrement2}
                                  className="a83ed08757 c21c56c305 f38b6daa18 d691166b09 ab98298258 deab83296e e91c91fa93"
                                >
                                  <span className="eedba9e88a">
                                    <span
                                      className="fcd9eec8fb bf9a32efa5"
                                      aria-hidden="true"
                                    >
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 24 24"
                                      >
                                        <path d="M20.25 12.75H3.75a.75.75 0 0 1 0-1.5h16.5a.75.75 0 0 1 0 1.5z" />
                                      </svg>
                                    </span>
                                  </span>
                                </button>
                                <span className="d723d73d5f" aria-hidden="true">
                                  {queenbed}
                                </span>
                                <button
                                  tabIndex={-1}
                                  aria-hidden="true"
                                  type="button"
                                  onClick={increaseRange2}
                                  className="a83ed08757 c21c56c305 f38b6daa18 d691166b09 ab98298258 deab83296e bb803d8689 f4d78af12a"
                                >
                                  <span className="eedba9e88a">
                                    <span
                                      className="fcd9eec8fb bf9a32efa5"
                                      aria-hidden="true"
                                    >
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 24 24"
                                      >
                                        <path d="M20.25 11.25h-7.5v-7.5a.75.75 0 0 0-1.5 0v7.5h-7.5a.75.75 0 0 0 0 1.5h7.5v7.5a.75.75 0 0 0 1.5 0v-7.5h7.5a.75.75 0 0 0 0-1.5z" />
                                      </svg>
                                    </span>
                                  </span>
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="aca0ade214 ebac6e22e9 f66f916626 c2931f4182 c27e5d305d db150fece4 spacing--6x addRoom">
                          <div className="roomDesc b817090550 e0ad3ea0c7">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 96 96"
                            >
                              <path d="M89.25 48H6.75C5.1 48 4 49.1 4 50.77v30.46C4 82.9 5.1 84 6.75 84s2.75-1.1 2.75-2.77V70.15h77v11.08c0 1.66 1.1 2.77 2.75 2.77S92 82.9 92 81.23V50.77C92 49.1 90.9 48 89.25 48zm-44-27.7h-27.5v-5.53c0-1.66-1.1-2.77-2.75-2.77s-2.75 1.1-2.75 2.77v27.7h33V20.3zm38.5 2.78v-8.3c0-1.67-1.1-2.78-2.75-2.78s-2.75 1.1-2.75 2.77v5.54h-27.5v22.16h33V23.08z" />
                            </svg>
                            <div className="f2cf178bcd">
                              <div className="a3332d346a e6208ee469 d0caee4251 bed-type--not-highlighted">
                                King bed(s)
                              </div>
                              <div className="abf093bdfe f45d8e4c32 df64fda51b">
                                71–81 inches wide
                              </div>
                            </div>
                          </div>
                          <div className="b817090550 a7cf1a6b1d">
                            <div
                              id="automation_id_add_bedroom_extra_large_double_bed_input"
                              data-testid="automation_id_add_bedroom_extra_large_double_bed_input"
                              className="a7a72174b8"
                            >
                              <div className="bfb38641b0">
                                <button
                                  tabIndex={-1}
                                  disabled={kingbed < 1}
                                  aria-hidden="true"
                                  type="button"
                                  onClick={decrement3}
                                  className="a83ed08757 c21c56c305 f38b6daa18 d691166b09 ab98298258 deab83296e e91c91fa93"
                                >
                                  <span className="eedba9e88a">
                                    <span
                                      className="fcd9eec8fb bf9a32efa5"
                                      aria-hidden="true"
                                    >
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 24 24"
                                      >
                                        <path d="M20.25 12.75H3.75a.75.75 0 0 1 0-1.5h16.5a.75.75 0 0 1 0 1.5z" />
                                      </svg>
                                    </span>
                                  </span>
                                </button>
                                <span className="d723d73d5f" aria-hidden="true">
                                  {kingbed}
                                </span>
                                <button
                                  tabIndex={-1}
                                  aria-hidden="true"
                                  type="button"
                                  onClick={increaseRange3}
                                  className="a83ed08757 c21c56c305 f38b6daa18 d691166b09 ab98298258 deab83296e bb803d8689 f4d78af12a"
                                >
                                  <span className="eedba9e88a">
                                    <span
                                      className="fcd9eec8fb bf9a32efa5"
                                      aria-hidden="true"
                                    >
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 24 24"
                                      >
                                        <path d="M20.25 11.25h-7.5v-7.5a.75.75 0 0 0-1.5 0v7.5h-7.5a.75.75 0 0 0 0 1.5h7.5v7.5a.75.75 0 0 0 1.5 0v-7.5h7.5a.75.75 0 0 0 0-1.5z" />
                                      </svg>
                                    </span>
                                  </span>
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                        {beds.map((bed, index) => (
                          <div key={index} className="aca0ade214 ebac6e22e9 f66f916626 c2931f4182 c27e5d305d db150fece4 spacing--6x addRoom">
                            <div className="roomDesc b817090550 e0ad3ea0c7">
                              
                              <div className="f2cf178bcd">
                                <div className="a3332d346a e6208ee469 d0caee4251 bed-type--not-highlighted">
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 96 96"
                                  >
                                    <path d="M89.25 48H6.75C5.1 48 4 49.1 4 50.77v30.46C4 82.9 5.1 84 6.75 84s2.75-1.1 2.75-2.77V70.15h77v11.08c0 1.66 1.1 2.77 2.75 2.77S92 82.9 92 81.23V50.77C92 49.1 90.9 48 89.25 48zm-44-27.7h-27.5v-5.53c0-1.66-1.1-2.77-2.75-2.77s-2.75 1.1-2.75 2.77v27.7h33V20.3zm38.5 2.78v-8.3c0-1.67-1.1-2.78-2.75-2.78s-2.75 1.1-2.75 2.77v5.54h-27.5v22.16h33V23.08z" />
                                  </svg>
                                  <input
                                    type="text"
                                    value={bed.name}
                                    onChange={(e) => updateBedName(index, e.target.value)}
                                  />
                                </div>
                                <div className="abf093bdfe f45d8e4c32 df64fda51b">
                                  {bed.width}
                                </div>
                              </div>
                            </div>
                            <div className="b817090550 a7cf1a6b1d">
                              <div className="a7a72174b8">
                                <div className="bfb38641b0">
                                  <button
                                    type="button"
                                    disabled={bed.quantity < 1}
                                    onClick={() => updateBedQuantity(index, bed.quantity - 1)}
                                    className="a83ed08757 c21c56c305 f38b6daa18 d691166b09 ab98298258 deab83296e e91c91fa93"
                                  >
                                    <span className="eedba9e88a">
                                    <span
                                      className="fcd9eec8fb bf9a32efa5"
                                      aria-hidden="true"
                                    >
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 24 24"
                                      >
                                        <path d="M20.25 12.75H3.75a.75.75 0 0 1 0-1.5h16.5a.75.75 0 0 1 0 1.5z" />
                                      </svg>
                                    </span>
                                  </span>
                                  </button>
                                  <span className="d723d73d5f" aria-hidden="true">
                                    {bed.quantity}
                                  </span>
                                  <button
                                    type="button"
                                    onClick={() => updateBedQuantity(index, bed.quantity + 1)}
                                    className="a83ed08757 c21c56c305 f38b6daa18 d691166b09 ab98298258 deab83296e bb803d8689 f4d78af12a"
                                  >
                                     <span className="eedba9e88a">
                                    <span
                                      className="fcd9eec8fb bf9a32efa5"
                                      aria-hidden="true"
                                    >
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 24 24"
                                      >
                                        <path d="M20.25 11.25h-7.5v-7.5a.75.75 0 0 0-1.5 0v7.5h-7.5a.75.75 0 0 0 0 1.5h7.5v7.5a.75.75 0 0 0 1.5 0v-7.5h7.5a.75.75 0 0 0 0-1.5z" />
                                      </svg>
                                    </span>
                                  </span>
                                  </button>
                                </div>
                              </div>
                            </div>
                            <button style={{
                              marginTop: "10px",
                              padding: "5px 5px",
                              fontSize: "16px",
                              backgroundColor: "red",
                              color: "white",
                              border: "none",
                              borderRadius: "5px",
                              cursor: "pointer",
                              transition: "background-color 0.3s",
                            }} type="button" onClick={() => removeBed(index)}>Remove Bed</button>
                          </div>
                        ))}
                        <button style={{
                          marginTop: "10px",
                          padding: "10px 20px",
                          fontSize: "16px",
                          backgroundColor: "#70bbe9",
                          color: "white",
                          border: "none",
                          borderRadius: "5px",
                          cursor: "pointer",
                          transition: "background-color 0.3s",
                        }} onClick={addBed}>Add Bed</button>
                        <div
                          id="automation_id_sleeping_arrangement_reassurance"
                          className="page-tooltip__wrap"
                        >
                          <section
                            aria-labelledby=":r68:"
                            className="c82435a4b8 a178069f51 a6ae3c2b40 a18aeea94d d794b7a0f7 f53e278e95"
                            style={{ padding: 4 }}
                          >
                            <div className="aca0ade214 ebac6e22e9 c2931f4182 d79e71457a">
                              <span className="d963552ea0">
                                <span
                                  className="fcd9eec8fb c2cc050fb8"
                                  aria-hidden="true"
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 24 24"
                                  >
                                    <path d="M12.75 2.251v-1.5a.75.75 0 0 0-1.5 0v1.5a.75.75 0 0 0 1.5 0zm6.144 3.167l1.061-1.06a.75.75 0 0 0-1.06-1.061l-1.061 1.06a.75.75 0 0 0 1.06 1.061zM21 12.001h1.5a.75.75 0 0 0 0-1.5H21a.75.75 0 0 0 0 1.5zm-3.166 6.144l1.06 1.061a.75.75 0 1 0 1.061-1.06l-1.06-1.061a.75.75 0 1 0-1.061 1.06zM6.166 4.358l-1.06-1.061a.75.75 0 0 0-1.061 1.06l1.06 1.061a.75.75 0 0 0 1.061-1.06zM3 10.5H1.5a.75.75 0 0 0 0 1.5H3a.75.75 0 0 0 0-1.5zm2.106 6.584l-1.061 1.06a.75.75 0 1 0 1.06 1.061l1.061-1.06a.75.75 0 1 0-1.06-1.061zm3.144-.636v2.3a3.75 3.75 0 1 0 7.5 0v-2.3a.75.75 0 0 0-1.5 0v2.3a2.25 2.25 0 0 1-4.5 0v-2.3a.75.75 0 0 0-1.5 0zm9-5.198a5.25 5.25 0 1 1-10.5 0 5.25 5.25 0 0 1 10.5 0zm1.5 0a6.75 6.75 0 1 0-13.5 0 6.75 6.75 0 0 0 13.5 0zm-7.5 10.5v1.5a.75.75 0 0 0 1.5 0v-1.5a.75.75 0 0 0-1.5 0z" />
                                  </svg>
                                </span>
                              </span>
                              <div className="aaee4e7cd3 e7a57abb1e">
                                <div className="aca0ade214 aaf30230d9 cd2e7d62b0">
                                  <div className="ffd93a9ecb">
                                    <h3
                                      id=":r68:"
                                      className="e1eebb6a1e e0a7dcceb5"
                                    >
                                      Do you offer other sleeping arrangements?
                                    </h3>
                                    {/* <div className="abcc616ec7 cc1b961f14 c180176d40 f11eccb5e8">
                                      <button
                                        aria-label="Close banner"
                                        type="button"
                                        className="a83ed08757 c21c56c305 f38b6daa18 d691166b09 ab98298258 deab83296e f4552b6561"
                                      >
                                        <span className="eedba9e88a">
                                          <span
                                            className="fcd9eec8fb bf9a32efa5"
                                            aria-hidden="true"
                                          >
                                            <svg
                                              xmlns="http://www.w3.org/2000/svg"
                                              viewBox="0 0 24 24"
                                            >
                                              <path d="M13.31 12l6.89-6.89a.93.93 0 1 0-1.31-1.31L12 10.69 5.11 3.8A.93.93 0 0 0 3.8 5.11L10.69 12 3.8 18.89a.93.93 0 0 0 1.31 1.31L12 13.31l6.89 6.89a.93.93 0 1 0 1.31-1.31z" />
                                            </svg>
                                          </span>
                                        </span>
                                      </button>
                                    </div> */}
                                  </div>
                                  <div className="a53cbfa6de">
                                    <div className="a53cbfa6de">
                                      For now, you just need to add your basic
                                      sleeping arrangements.{" "}
                                    </div>
                                    <div className="a53cbfa6de spacing--6x">
                                      Cribs, additional beds, and other sleeping
                                      arrangements can be added on the Extranet,
                                      the platform you’ll use to manage your
                                      property.
                                    </div>
                                    <div className="e1eebb6a1e">
                                      Do you have specific policies for
                                      children?
                                    </div>
                                    <div className="a53cbfa6de">
                                      You can set up your property’s child
                                      policies (e.g. maximum age, price
                                      adjustments) on the Extranet after you
                                      finish registration.
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </section>
                        </div>
                      </div>
                      <div className="ffb9c3d6a31 wide-container second-container">
                        <label
                          className="spacing--4x bedroom-label"
                          htmlFor="automation_id_bedroom_num_guests_input"
                        >
                          <div className="e1eebb6a1e">
                            How many guests can stay in this room?
                          </div>
                        </label>
                        <div
                          data-testid="MupRoomFlowLikeContainer-num-guests-input-stepper"
                          className="a7a72174b8"
                        >
                          <input
                            type="range"
                            className="ebb9f563b4"
                            id="automation_id_bedroom_num_guests_input"
                            min={1}
                            max={100}
                            step={1}
                            aria-valuemin={1}
                            aria-valuemax={100}
                            aria-valuenow={2}
                            value={queststay}
                            onChange={(e) => setqueststay(e.target.value)}
                          />
                          <div className="bfb38641b0">
                            <button
                              tabIndex={-1}
                              disabled={queststay < 1}
                              aria-hidden="true"
                              type="button"
                              onClick={decrement4}
                              className="a83ed08757 c21c56c305 f38b6daa18 d691166b09 ab98298258 deab83296e bb803d8689 e91c91fa93"
                            >
                              <span className="eedba9e88a">
                                <span
                                  className="fcd9eec8fb bf9a32efa5"
                                  aria-hidden="true"
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 24 24"
                                  >
                                    <path d="M20.25 12.75H3.75a.75.75 0 0 1 0-1.5h16.5a.75.75 0 0 1 0 1.5z" />
                                  </svg>
                                </span>
                              </span>
                            </button>
                            <span className="d723d73d5f" aria-hidden="true">
                              {queststay}
                            </span>
                            <button
                              tabIndex={-1}
                              aria-hidden="true"
                              type="button"
                              onClick={increaseRange4}
                              className="a83ed08757 c21c56c305 f38b6daa18 d691166b09 ab98298258 deab83296e bb803d8689 f4d78af12a"
                            >
                              <span className="eedba9e88a">
                                <span
                                  className="fcd9eec8fb bf9a32efa5"
                                  aria-hidden="true"
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 24 24"
                                  >
                                    <path d="M20.25 11.25h-7.5v-7.5a.75.75 0 0 0-1.5 0v7.5h-7.5a.75.75 0 0 0 0 1.5h7.5v7.5a.75.75 0 0 0 1.5 0v-7.5h7.5a.75.75 0 0 0 0-1.5z" />
                                  </svg>
                                </span>
                              </span>
                            </button>
                          </div>
                        </div>
                      </div>
                      <div className="ffb9c3d6a3 wide-container second-container">
                        <div className="e1eebb6a1e spacing--4x">
                          How big is this room?
                        </div>
                        <div className="bedroom-room-size">
                          <div className="a53cbfa6de ac9267e216 bedroom-room-size__room-measurement a20293ec70">
                            <label
                              htmlFor=":r69:"
                              className="a53cbfa6de e6208ee469 f555271986"
                            >
                              <span>
                                <span className="ccb65902b2">
                                  Room size – optional
                                </span>
                              </span>
                            </label>
                            <div className="b9b84f4305">
                              <div className="e000754250">
                                <input
                                  type="number" onKeyDown={handleKeyDown}
                                  name="room_size"
                                  className="eb46370fe1"
                                  min={0}
                                  data-testid="bedroom_roomsize_select"
                                  id=":r69:"
                                  value={roomsize}
                                  onChange={(e) => setroomsize(e.target.value)}
                                />
                                <div className="e7e5251f68" />
                              </div>
                            </div>
                          </div>
                          <div className="a53cbfa6de ac9267e216 bedroom-room-size__measurement-type">
                            <div className="cabad3c686 aff44befa2">
                              <select
                                className="ebf4591c8e"
                                name="measurement_type"
                                data-testid="bedroom_roomsize_measurement_type_select"
                                id=":r6a:"
                                value={roommeasure}
                                onChange={(e) => setroommeasure(e.target.value)}
                              >
                                 <option disabled value="">
                                  Unit
                                </option>
                                <option value="square meters" data-key={1}>
                                  square meters
                                </option>
                                <option value="square feet" data-key={0}>
                                  square feet
                                </option>
                              </select>
                              <span className="ad2c299542">
                                <span
                                  className="fcd9eec8fb cab1c196b5 c2cc050fb8 f64eb5d122"
                                  aria-hidden="true"
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 24 24"
                                  >
                                    <path d="M19.2681 8.91288C19.2687 9.03226 19.2454 9.15054 19.1996 9.26079C19.1539 9.37105 19.0865 9.47105 19.0016 9.55493L12.9445 15.612C12.8211 15.7367 12.6738 15.8353 12.5115 15.9018C12.3491 15.9684 12.1751 16.0017 11.9996 15.9997C11.6491 16.0075 11.3096 15.877 11.0547 15.6362L4.99764 9.57916C4.8275 9.40881 4.73193 9.17788 4.73193 8.93711C4.73193 8.69634 4.8275 8.46542 4.99764 8.29506C5.08117 8.20935 5.18101 8.14122 5.29128 8.09471C5.40155 8.04819 5.52002 8.02423 5.6397 8.02423C5.75937 8.02423 5.87784 8.04819 5.98811 8.09471C6.09838 8.14122 6.19822 8.20935 6.28175 8.29506L11.9996 13.9887L17.7175 8.27084C17.801 8.18512 17.9009 8.11699 18.0111 8.07048C18.1214 8.02396 18.2399 8 18.3596 8C18.4792 8 18.5977 8.02396 18.708 8.07048C18.8182 8.11699 18.9181 8.18512 19.0016 8.27084C19.0871 8.3543 19.1548 8.45422 19.2006 8.56457C19.2464 8.67492 19.2694 8.79341 19.2681 8.91288Z" />
                                  </svg>
                                </span>
                              </span>
                            </div>
                          </div>
                        </div>
                        <div className="spacing--8x" />
                        <fieldset className="a53cbfa6de ac9267e216 smoking-allowed">
                          <legend className="a53cbfa6de e6208ee469 f555271986">
                            <span>
                              <span className="ccb65902b2">
                                <div
                                  className="e1eebb6a1e spacing--4x"
                                  style={{ marginLeft: "20px" }}
                                >
                                  Is smoking allowed in this room?
                                </div>
                              </span>
                            </span>
                          </legend>
                          <div className="aca0ade214 aaf30230d9 c2931f4182">
                            <div className="a53cbfa6de ac9267e216 ad8af0911d">
                              <label
                                htmlFor=":r6c:"
                                className="aca0ade214 aaf30230d9 c2931f4182 d79e71457a f8208e9f83"
                              >
                                <input
                                  className="c907c67d20"
                                  type="radio"
                                  value="Yes"
                                  checked={roomsmoking === "Yes"}
                                  onChange={handleOptionChange}
                                />
                                <span className="aaee4e7cd3 e7a57abb1e">
                                  <div className="a53cbfa6de e6208ee469">
                                    Yes
                                  </div>
                                </span>
                              </label>
                            </div>
                            <div className="a53cbfa6de ac9267e216 ad8af0911d">
                              <label
                                htmlFor=":r6d:"
                                className="aca0ade214 aaf30230d9 c2931f4182 d79e71457a f8208e9f83"
                              >
                                <input
                                  className="c907c67d20"
                                  type="radio"
                                  value="No"
                                  checked={roomsmoking === "No"}
                                  onChange={handleOptionChange}
                                />
                                <span className="aaee4e7cd3 e7a57abb1e">
                                  <div className="a53cbfa6de e6208ee469">
                                    No
                                  </div>
                                </span>{" "}
                              </label>
                            </div>
                          </div>
                        </fieldset>
                      </div>
                    </div>
                    <div className="form-cta">
                      <div>
                        <div className="form-buttons-container">
                          <div
                            className="form-buttons-container--secondary"
                            onClick={() => window.history.back()}
                          >
                            <button
                              aria-label="Back to previous step"
                              data-testid="FormButtonSecondary"
                              type="button"
                              className="a83ed08757 c21c56c305 bf0537ecb5 d691166b09 f671049264 d2529514af af7297d90d"
                            >
                              <span className="eedba9e88a">
                                <span
                                  className="fcd9eec8fb c2cc050fb8"
                                  aria-hidden="true"
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 24 24"
                                    data-rtl-flip="true"
                                  >
                                    <path d="M15.0871 19.2361C14.9677 19.2367 14.8495 19.2134 14.7392 19.1676C14.629 19.1219 14.5289 19.0546 14.4451 18.9696L8.38799 12.9125C8.26332 12.7891 8.16475 12.6418 8.09815 12.4795C8.03156 12.3172 7.99829 12.1431 8.00034 11.9676C7.99246 11.6171 8.12303 11.2776 8.36376 11.0227L14.4208 4.96566C14.5912 4.79552 14.8221 4.69995 15.0629 4.69995C15.3037 4.69995 15.5346 4.79552 15.7049 4.96566C15.7907 5.04918 15.8588 5.14902 15.9053 5.25929C15.9518 5.36957 15.9758 5.48803 15.9758 5.60771C15.9758 5.72739 15.9518 5.84586 15.9053 5.95613C15.8588 6.0664 15.7907 6.16624 15.7049 6.24976L10.0113 11.9676L15.7292 17.6855C15.8149 17.769 15.883 17.8689 15.9295 17.9792C15.976 18.0894 16 18.2079 16 18.3276C16 18.4473 15.976 18.5657 15.9295 18.676C15.883 18.7863 15.8149 18.8861 15.7292 18.9696C15.6457 19.0551 15.5458 19.1228 15.4354 19.1686C15.3251 19.2144 15.2066 19.2374 15.0871 19.2361Z" />
                                  </svg>
                                </span>
                              </span>
                            </button>
                          </div>
                          <div
                            className="form-buttons-container--primary"
                            onClick={HandleClick}
                          >
                            <button
                              aria-label="Continue"
                              data-testid="FormButtonPrimary-enabled"
                              type="button"
                              className="a83ed08757 c21c56c305 a4c1805887 f671049264 d2529514af c082d89982"
                            >
                              <span className="e4adce92df">Continue</span>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            id="automation_id_flow_Segmentation_Flow"
            style={{ display: "none" }}
          ></div>
          <div></div>
        </div>
      </div>
      <img
        alt=""
        height="1"
        width="1"
        style={{ display: "none" }}
        src="https://booking.com/track_join_app_landing?progress_status=2"
      />
    </div>
  );
};

export default Addroom;
