import React from "react";
import "./footer.css";

const Footer = () => {
  const user = JSON.parse(sessionStorage.getItem("user"));
  const redirect = async () => {
    if (!user) {
      return alert("Please login");
    } else {
window.location.assign("/community-home")
    }
  }
  return (
    <footer class="py-3">
      <ul class="nav justify-content-center border-bottom pb-3 mb-3">
        <li class="nav-item">
          <a class="nav-link px-2" href="/">
            Home
          </a>
        </li>
      
          <li class="nav-item">
            <button  class="nav-link px-2" onClick={redirect}>
              Community
            </button>
          </li>
      
        <li class="nav-item">
          <a class="nav-link px-2" href="/">
            Pricing
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-link px-2" href="/">
            FAQs
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-link px-2" href="/">
            About
          </a>
        </li>
      </ul>
      <p class="text-center company-name" style={{ color: "white" }}>
        © 2023 Booking-Oda.com, Inc
      </p>
    </footer>
  );
};

export default Footer;
