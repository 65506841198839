import React from 'react'
import { Container } from 'react-bootstrap'
import { FaAngleRight } from 'react-icons/fa'

export const MotorCycle = () => {
    return (
        <div>
            <div style={{ backgroundColor: '#212121', padding: '10px', }}>
                <p className='text-light text-center fw-bold fs-5 letter-space'>Motorcycles</p>
            </div>
            <div style={{ background: '#000' }}>
                <Container>
                    <div className="row">
                        <div className="col-lg-4 bike">
                            <img src="./img/bike-1.jpg" alt="" />
                            <div>
                                <p className='text-light fw-bold text-center letter-space mb-2'>New Himalaya</p>
                                <div className="d-flex gap-2 justify-content-center mb-2">
                                    <button className='renew-btn'>Explore<FaAngleRight style={{display:'inline-block'}}/></button>
                                    {/* <button className='buy-btn'>Book Test Ride<FaAngleRight style={{display:'inline-block'}}/></button> */}
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 bike">
                            <img src="./img/bike-2.jpg" alt="" />
                            <div>
                                <p className='text-light fw-bold text-center letter-space mb-2'>Bullet 350</p>
                                <div className="d-flex gap-2 justify-content-center mb-2">
                                    <button className='renew-btn'>Explore<FaAngleRight style={{display:'inline-block'}}/></button>
                                    {/* <button className='buy-btn'>Book Test Ride<FaAngleRight style={{display:'inline-block'}}/></button> */}
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 bike">
                            <img src="./img/bike-3.jpg" alt="" />
                            <div>
                                <p className='text-light fw-bold text-center letter-space mb-2'>Super Meteor 650</p>
                                <div className="d-flex gap-2 justify-content-center mb-2">
                                    <button className='renew-btn'>Explore<FaAngleRight style={{display:'inline-block'}}/></button>
                                    {/* <button className='buy-btn'>Book Test Ride<FaAngleRight style={{display:'inline-block'}}/></button> */}
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 bike">
                            <img src="./img/bike-4.jpg" alt="" />
                            <div>
                                <p className='text-light fw-bold text-center letter-space mb-2'>Hunter 350</p>
                                <div className="d-flex gap-2 justify-content-center mb-2">
                                    <button className='renew-btn'>Explore<FaAngleRight style={{display:'inline-block'}}/></button>
                                    {/* <button className='buy-btn'>Book Test Ride<FaAngleRight style={{display:'inline-block'}}/></button> */}
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 bike">
                            <img src="./img/bike-5.jpg" alt="" />
                            <div>
                                <p className='text-light fw-bold text-center letter-space mb-2'>Scram 411</p>
                                <div className="d-flex gap-2 justify-content-center mb-2">
                                    <button className='renew-btn'>Explore<FaAngleRight style={{display:'inline-block'}}/></button>
                                    {/* <button className='buy-btn'>Book Test Ride<FaAngleRight style={{display:'inline-block'}}/></button> */}
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 bike">
                            <img src="./img/bike-6.jpg" alt="" />
                            <div>
                                <p className='text-light fw-bold text-center letter-space mb-2'>Classic 350</p>
                                <div className="d-flex gap-2 justify-content-center mb-2">
                                    <button className='renew-btn'>Explore<FaAngleRight style={{display:'inline-block'}}/></button>
                                    {/* <button className='buy-btn'>Book Test Ride<FaAngleRight style={{display:'inline-block'}}/></button> */}
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 bike">
                            <img src="./img/bike-7.jpg" alt="" />
                            <div>
                                <p className='text-light fw-bold text-center letter-space mb-2'>Meteor 350</p>
                                <div className="d-flex gap-2 justify-content-center mb-2">
                                    <button className='renew-btn'>Explore<FaAngleRight style={{display:'inline-block'}}/></button>
                                    {/* <button className='buy-btn'>Book Test Ride<FaAngleRight style={{display:'inline-block'}}/></button> */}
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 bike">
                            <img src="./img/bike-8.avif" alt="" />
                            <div>
                                <p className='text-light fw-bold text-center letter-space mb-2'>Interceptor</p>
                                <div className="d-flex gap-2 justify-content-center mb-2">
                                    <button className='renew-btn'>Explore<FaAngleRight style={{display:'inline-block'}}/></button>
                                    {/* <button className='buy-btn'>Book Test Ride<FaAngleRight style={{display:'inline-block'}}/></button> */}
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 bike">
                            <img src="./img/bike-9.jpg" alt="" />
                            <div>
                                <p className='text-light fw-bold text-center letter-space mb-2'>Continetal GT</p>
                                <div className="d-flex gap-2 justify-content-center mb-2">
                                    <button className='renew-btn'>Explore<FaAngleRight style={{display:'inline-block'}}/></button>
                                    {/* <button className='buy-btn'>Book Test Ride<FaAngleRight style={{display:'inline-block'}}/></button> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </Container>
            </div>

        </div>
    )
}
