import React, { useEffect, useState } from "react";
import { Button, Table,DatePicker } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { Input, Space } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import axios from "axios";
import { MdDelete } from "react-icons/md";
import { Modal } from "react-bootstrap";
import { faCancel } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as XLSX from "xlsx";
import moment from "moment/moment";
const Vendors = () => {
  const [data1, setdata1] = useState([]);
  const [show3, setShow3] = useState(false);
  const handleClose3 = () => setShow3(false);
  const handleShow3 = (item) => {
    setShow3(true);
    setdata(item);
  };
  const dispatch = useDispatch();
  const getVendor = async () => {
    let res = await axios.get("https://bookingoda.com/api/admin/getallVendor");
    if (res.status === 200) {
      ////console.log(res.data.Vendor, "Vendor");
      setdata1(res.data.Vendor);
    }
  };
  const [Data, setdata] = useState("");
  const DeleteTrip = async () => {
    try {
      const config = {
        url: "admin/deleteVendor/" + Data,
        baseURL: "https://bookingoda.com/api",
        method: "DELETE",
        Headers: { "Content-Type": "application-json" },
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert(res.data.success);
          handleClose3();
          getVendor();
        }
      });
    } catch (error) {
      alert(error.response.data.error);
    }
  };
  useEffect(() => {
    getVendor();
  }, []);

  const approve = async (data) => {
    try {
      axios
        .get(`https://bookingoda.com/api/admin/blockvendor/` + data._id)
        .then(function (response) {
          if (response.status === 200) {
            ////console.log(response.data);
            alert("Blocked successfully");
            window.location.reload();
          }
        })
        .catch(function (error) {
          // setdatacondition(true);

          ////console.log(error.message.data);
          alert("Not able to complete");
        });
    } catch (error) {
      console.warn(error);
      alert("Not able to complete");
      // setdatacondition(true);
      return error;
    }
  };

  const unblock = async (data) => {
    try {
      axios
        .get(`https://bookingoda.com/api/admin/unblockvendor/` + data._id)
        .then(function (response) {
          if (response.status === 200) {
            ////console.log(response.data);
            alert("Unblocked successfully");
            window.location.reload();
          }
        })
        .catch(function (error) {
          // setdatacondition(true);

          ////console.log(error.message.data);
          alert("Not able to complete");
        });
    } catch (error) {
      console.warn(error);
      alert("Not able to complete");
      // setdatacondition(true);
      return error;
    }
  };
 
  const columns = [
    {
      title: "Serial No.",
      dataIndex: "Sno",

      // //fixed: "left",
      width: 100,
    },
    {
      title: "Unique ID",
      dataIndex: "UniqueId",
      width: 150,
      
    },
    {
      title: "First Name",
      dataIndex: "name",
      width: 150,
      
    
    },
    {
      title: "Last Name",
      dataIndex: "Lname",
     
      width: 150,
      
    },
    {
      title: "Email",
      dataIndex: "email",
      width: 200,
     
    },
    {
      title: "Mobile",
      dataIndex: "mobile",
      width: 150,
      
    },
    {
      title: "Registered Date",
      dataIndex: "Date",
      render: (text) => (
        <span>{moment(text).utc().format('DD/MM/YYYY')}</span>
      ),
      width: 150,

    },
    {
      title: "Action",
      dataIndex: "action",
      width: 150,
    },
  ];
  const [data2, setData2] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  
  useEffect(() => {
    // Fetch data asynchronously (replace fetchDataCar with your actual data fetching logic)
    getVendor().then((result) => {
      setdata1(result);
    });
  }, []);

  useEffect(() => {
    // Ensure datacar is not undefined before transforming it
    if (data1 && data1.length > 0) {
      const transformedData = transformData(data1);
      setData2(transformedData);
      setFilteredData(transformedData);
    }
  }, [data1]);
  const transformData = (data1) => {
    return data1.map((item,i) => {
      return {
        Sno:i+1,
        UniqueId: "BOVEN"+item._id.slice(22,24).toUpperCase(),
        name: item.name,
        Lname: item.Lname,
        email: item.email,
        mobile: item.mobile,
        RegisteredDate: moment(item.createdAt).format("DD/MM/YYYY"),
        Date: item.createdAt,
        action: (
          <>
            {!item.blockstatus ? (
              <button
                className="btn btn-outline-success"
                onClick={() => approve(item)}
              >
                Block
              </button>
            ) : (
              <button
                className="btn btn-outline-danger"
                onClick={() => unblock(item)}
              >
                Un-Block
              </button>
            )}
            <MdDelete
              className="text-danger"
              size={24}
              style={{ marginLeft: 20 }}
              onClick={() => handleShow3(item?._id)}
            />
          </>
        ),
      };
  
     
    });
  };
  // const data2 = [];
  // for (let i = 0; i < data1.length; i++) {
  //   data2.push({
  //     _id: data1[i]._id,
  //     name: data1[i].name,
  //     Lname: data1[i].Lname,
  //     email: data1[i].email,
  //     mobile: data1[i].mobile,
  //     RegisteredDate: moment(data1[i].createdAt).format("DD/MM/YYYY"),
  //     Date:data1[i].createdAt,
  //     action: (
  //       <>
  //         {!data1[i].blockstatus ? (
  //           <button
  //             className="btn btn-outline-success"
  //             onClick={() => approve(data1[i])}
  //           >
  //             Block
  //           </button>
  //         ) : (
  //           <button
  //             className="btn btn-outline-danger"
  //             onClick={() => unblock(data1[i])}
  //           >
  //             Un-Block
  //           </button>
  //         )}
  //          <MdDelete
  //             className="text-danger"
  //             size={24}
  //             style={{marginLeft:20}}
  //             onClick={() => handleShow3(data1[i]?._id)}
  //           />
  //       </>
  //     ),
  //   });
  // }
  const ExportToExcelButton = ({ dataSource, columns, filename }) => {
    const exportToExcel = () => {
      const dataToExport = dataSource.map(({ RegisteredDate, ...rest }) => rest);
      const worksheet = XLSX.utils.json_to_sheet(dataToExport);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet 1");
      XLSX.writeFile(workbook, `${filename}.xlsx`);
    };

    return (
      <Button
        type="primary"
        onClick={exportToExcel}
        style={{
          margin: "10px",
          alignSelf: "flex-end",
          backgroundColor: "#1677ff",
          color: "white",
        }}
      >
        Export to Excel
      </Button>
    );
  };

  const [searchTerm, setSearchTerm] = useState("");
  // const [filteredData, setFilteredData] = useState(data2);

  const handles = (value) => {
    setSearchTerm(value);

    // Filter the data based on the search term
    const filteredDataSource = data2.filter((record) => {
      return Object.values(record).some(
        (val) =>
          val && val.toString().toLowerCase().includes(value.toLowerCase())
      );
    });

    setFilteredData(filteredDataSource);
  };

  const onClearSearch = () => {
    setSearchTerm("");
    setFilteredData(data2);
  };

  const searchInput = (
    <Input
      placeholder="Search"
      value={searchTerm}
      style={{width:"50%"}}
      onChange={(e) => handles(e.target.value)}
      suffix={
        searchTerm && (
          <span
            onClick={onClearSearch}
            style={{ cursor: "pointer", color: "grey" }}
          >
            Clear
          </span>
        )
      }
      prefix={<SearchOutlined />}
    />
  );

  const pageSize = 5;
 
  const [dateRange, setDateRange] = useState([null, null]);
  
  const handleDateRangeChange = (dates) => {
    ////console.log('Selected Dates:', dates);
  
    // Filter data based on the selected date range
    const filteredDataSource = data2.filter((record) => {
      const startDate = dates && dates[0] ? new Date(dates[0].startOf('day').toDate()) : null;
      const endDate = dates && dates[1] ? new Date(dates[1].endOf('day').toDate()) : null;
      const recordDate = new Date(record.Date);
  
      const result =
        (!startDate || recordDate >= startDate) &&
        (!endDate || recordDate <= endDate);
  
      return result;
    });
  
    if (filteredDataSource.length === 0) {
      // Display an empty table or show a message
      // For example, setFilteredData([]) to clear the table
      setFilteredData([]);
      
    } else {
      // Set the filtered data and update the date range
      setFilteredData(filteredDataSource);
      setDateRange(dates);
    }
  };
  return (
    <div>
      <h3 className="mb-4 title">Vendors</h3>
      <div style={{display:"flex",flexDirection:"row",justifyContent:'space-between',margin:10}}>
      {searchInput}
      <DatePicker.RangePicker
        value={dateRange}
        onChange={handleDateRangeChange}
        
      />
       <ExportToExcelButton
        dataSource={filteredData}
        columns={columns}
        filename="Vendor"
      /></div>
      <div>
        <Table
          columns={columns} className="custom-table"
          dataSource={filteredData
          }
          scroll={{ x: "calc(700px + 50%)", y: 240 }}
          pagination={{
            pageSize: pageSize,
            showSizeChanger: false, // Optional: Hide the "Show X entries" dropdown
          }}
          components={{
            header: {
            
              cell: props => (
                <th {...props} style={{ ...props.style, background:'linear-gradient(#f39c12,#CA8B26,#614A25)'  ,color:"white"}} />
              ),
            },
          }}
        />
      </div>
      <Modal
        show={show3}
        onHide={handleClose3}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <div className="text-center">
            <h4 className="fw-bold text-dark mb-2">Are You Sure</h4>
            <p>This event data will be removed permanently</p>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="success" onClick={handleClose3}>
            Cancel
          </Button>
          <Button variant="danger" onClick={() => DeleteTrip()}>
            <FontAwesomeIcon icon={faCancel} className=" me-2" />
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default Vendors;
