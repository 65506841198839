import React, { useState, useEffect } from "react";
import Logo from "../../assets/booking.jpeg";

import { ImCross } from "react-icons/im";
import axios from "axios";
import { Link, useLocation } from "react-router-dom";
import { connect } from "react-redux";
// import { getBasketTotal } from "../../../StoreReducer";
import { BsCart4 } from "react-icons/bs";
import { Row, Col } from "reactstrap";
import Table from "react-bootstrap/Table";
import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";
import moment from "moment";
import "./invoice.css";
function NewInvoice(props) {
    const Item = JSON.parse(sessionStorage.getItem("InvoiceItem"));
    const Itemdata = JSON.parse(sessionStorage.getItem("items"));
  const createPDF = async () => {
    // setRotate(360);
    const pdf = new jsPDF("portrait", "pt", "a4");
    const data = await html2canvas(document.querySelector("#pdf"));
    const img = data.toDataURL("image/png");
    const imgProperties = pdf.getImageProperties(img);
    const pdfWidth = pdf.internal.pageSize.getWidth();
    const pdfHeight = (imgProperties.height * pdfWidth) / imgProperties.width;
    pdf.addImage(img, "PNG", 0, 0, pdfWidth, pdfHeight);
    pdf.save("Invoice.pdf");
  };

  const [data5, setdata5] = useState([]);
  const [data6, setdata6] = useState([]);
  const [data2, setdata2] = useState([]);
  const [data3, setdata3] = useState([]);

  const getapartment = async () => {
    let res = await axios.get(
      "https://bookingoda.com/api/admin/getallApartment"
    );
    if (res.status === 200) {
      //////console.log(res.data);
      setdata5(res.data.Apartment.filter((item) => item.status === "Approved"));
    }
  };
  const getalternative = async () => {
    let res = await axios.get(
      "https://bookingoda.com/api/admin/getallalternative"
    );
    if (res.status === 200) {
      //////console.log(res.data);
      setdata2(
        res.data.alternative.filter((item) => item.status === "Approved")
      );
    }
  };
  const gethotel = async () => {
    let res = await axios.get("https://bookingoda.com/api/admin/getallhotel");
    if (res.status === 200) {
      //////console.log(res.data);
      setdata6(res.data.hotel.filter((item) => item.status === "Approved"));
    }
  };
  const gethomestay = async () => {
    let res = await axios.get("https://bookingoda.com/api/admin/getallhome");
    if (res.status === 200) {
      //////console.log(res.data);
      setdata3(res.data.home.filter((item) => item.status === "Approved"));
    }
  };

  useEffect(() => {
    getapartment();
    gethotel();
    getalternative();
    gethomestay();
  }, []);
  const data4 = [...data5, ...data6, ...data2, ...data3];
  const parsedDate = moment(Item.arrivaldate, 'DD/MM/YYYY');

  // Format the date using moment
  const formattedDate = parsedDate.format('DD MMM YYYY');
  const parsedDate1 = moment(Item.departuredate, 'DD/MM/YYYY');

  // Format the date using moment
  const formattedDate1 = parsedDate1.format('DD MMM YYYY');
 

  // Calculate the difference in days
  const diffInDays = parsedDate1.diff(parsedDate, 'days');
  return (
    <div style={{ backgroundColor: "#70bbe9", padding: "5%" }}>
      <div
        id="pdf"
        style={{ margin: "0% 10%", padding: "5%", backgroundColor: "white" }}
      >
        <Row>
          <Col md={"11"}>
            <Row>
              <Col md={"12"}>
                <Row
                  className="header-background"
                  style={{
                    backgroundImage:
                      "linear-gradient(rgba(0, 0, 0, 0.5), rgba(103, 71, 54, 0.4)),url(https://reserva-web-app.netlify.app/static/media/heroimage-1.c485d6a6233f174ef9b7.jpeg)",
                  }}
                >
                  <Col md={"7"}>
                    {" "}
                    <span style={{ fontFamily: "Calibri", fontSize: "18px" }}>
                      INVOICE: {"BOOKODA"+Item._id.slice(22,24)}
                    </span>
                  </Col>
                  <Col md={"5"} style={{ textAlign: "right" }}>
                    <img
                      style={{
                        width: "100px",
                        height: "100px",
                      }}
                      src={Logo}
                    ></img>
                  </Col>

                  <p
                    style={{
                      marginLeft: "3.55px",
                      textAlign: "justify",
                      lineHeight: "15.85px",
                    }}
                  >
                    <span
                      style={{
                        fontFamily: "Calibri",
                        fontSize: "30px",
                        color: "white",
                      }}
                    >
                     {data4.find((e) => e._id === Item?.packageId[0])?.PropertyName}
                    </span>
                  </p>
                  <p
                    style={{
                      textAlign: "right",
                      lineHeight: "12.2px",
                    }}
                  >
                    <span
                      style={{
                        fontFamily: "Calibri",
                        fontSize: "18px",
                        color: "white",
                      }}
                    >
                      Date: {moment(Item.createdAt).format('DD MMM YYYY')}
                    </span>
                  </p>

                  <p
                    style={{
                      marginTop: "2.15px",
                      marginLeft: "3.55px",
                      textAlign: "justify",
                      lineHeight: "10.95px",
                    }}
                  >
                    <span
                      style={{
                        fontFamily: "Calibri",
                        fontSize: "20px",
                        color: "white",
                      }}
                    >
                     {(data4.find((e) => e._id === Item?.packageId[0])?.address)?.join(", ")}
                    </span>
                    <span
                      style={{
                        fontFamily: "Calibri",
                        fontSize: "16px",
                        letterSpacing: "2.95px",
                      }}
                    ></span>
                  
                   
                  </p>
                 
                </Row>
                <div>
                  <p
                    style={{
                      marginTop: "24.15px",
                      marginLeft: "4.95px",
                      textIndent: "391.75px",
                      lineHeight: "16px",
                    }}
                  ></p>

                  <p
                    style={{
                      marginTop: "70.4px",
                      marginLeft: "3.55px",
                      marginBottom: "56.65px",
                      textAlign: "justify",
                      lineHeight: "17.05px",
                    }}
                  >
                    <span style={{ fontFamily: "Calibri", fontSize: "20px" }}>
                      {Item.name}
                    </span>
                   
                  </p>
                  <p
                    style={{
                      marginTop: "10px",
                      marginLeft: "3.55px",
                      marginBottom: "56.65px",
                      textAlign: "justify",
                      lineHeight: "17.05px",
                    }}
                  >
                      <span style={{ fontFamily: "Calibri", fontSize: "20px"}}>
                      {Item.address?.join(", ")}
                    </span>
                   
                  </p>
                  <p
                    style={{
                      marginTop: "10px",
                      marginLeft: "3.55px",
                      marginBottom: "56.65px",
                      textAlign: "justify",
                      lineHeight: "17.05px",
                    }}
                  >
                      <span style={{ fontFamily: "Calibri", fontSize: "20px" }}>
                      Mobile: {Item.mobile}
                    </span>
                   
                  </p>
                  <p
                    style={{
                      marginTop: "10px",
                      marginLeft: "3.55px",
                      marginBottom: "56.65px",
                      textAlign: "justify",
                      lineHeight: "17.05px",
                    }}
                  >
                      <span style={{ fontFamily: "Calibri", fontSize: "20px" }}>
                      Email: {Item.email}
                    </span>
                   
                  </p>
                  
                  
                   
                  <p
                    style={{
                      marginTop: "0.5px",
                      marginLeft: "12.7px",
                      textIndent: "-9.16px",
                      textAlign: "justify",
                      lineHeight: "12.2px",
                      AwImport: "list-item",
                      AwListLevelNumber: 0,
                      AwListNumberFormat: '"«"',
                      AwListNumberStyles: '"bullet"',
                      AwListPaddingSml: "4.78px",
                    }}
                  >
                    <span style={{ AwImport: "ignore" }}>
                      <span
                        style={{
                          width: "4.78px",
                          font: '7pt "Times New Roman"',
                          display: "inline-block",
                          AwImport: "spaces",
                        }}
                      >
                        &nbsp;&nbsp;
                      </span>
                    </span>
                  </p>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-between",
                    }}
                  >
                    <p
                      style={{
                        marginTop: "0.5px",
                        marginRight: "68.16px",
                        marginBottom: "20px",
                        marginLeft: "3.55px",
                       
                      }}
                    >
                      <span
                        style={{
                          fontFamily: "Calibri",
                          fontSize: "18px",
                          letterSpacing: "2.65px",
                        }}
                      ></span>
                      <span style={{ fontFamily: "Calibri", fontSize: "18px" }}>
                        Arrival Date:{" "} {formattedDate}
                      </span>
                    

                    
                    </p>
                    <p
                      style={{
                        marginTop: "10px",
                        marginLeft: "3.55px",
                       
                      }}
                    >
                      <span style={{ AwImport: "ignore" }}></span>
                      <span style={{ fontFamily: "Calibri", fontSize: "18px" }}>
                        Departure Date:{" "} 
                      </span>
                      <span
                        style={{
                          fontFamily: "Calibri",
                          fontSize: "18px",
                          letterSpacing: "4.7px",
                        }}
                      ></span>

                      <span style={{ fontFamily: "Calibri", fontSize: "18px" }}>
                      {formattedDate1}
                      </span>
                    </p>
                  </div>
                </div>
              </Col>
            </Row>

            <Table striped style={{width:"800px",maxWidth:"1200px"}}>
              <thead>
                <tr
                  style={{
                    backgroundColor: "#70bbe9",
                    color: "white",
                    textAlign: "center",
                  }}
                >
                  <th>DESCRIPTION</th>
                  <th>QTY</th>
                  <th>Rooms</th>
                  <th>Rate</th>
                  <th>TOTAL</th>
                </tr>
              </thead>
              <tbody>
                <tr style={{ textAlign: "center" }}>
                  <td>Room Charges</td>
                  <td>{diffInDays} nights</td>
                  <td>
                    {Item.noofpeople.room}
                  </td>
                  <td>
                  {(parseInt(data4.find((e) => e._id === Item?.packageId[0])?.Price) +
      parseInt(data4.find((e) => e._id === Item?.packageId[0])?.Price) *
        (parseInt(data4.find((e) => e._id === Item?.packageId[0])?.PriceCommision) !== null
          ? parseInt(data4.find((e) => e._id === Item?.packageId[0])?.PriceCommision) / 100
          : 1))}
                  </td>
                  <td>
                    {Item.amount}
                  </td>
                </tr>
              </tbody>
            </Table>

            <Row>
              <Col md={"7"} >We Hope You Had A Great Stay!</Col>
              <Col md={"5"}>
                <Row>
                  <Table style={{ width: "96%" }}>
                    <thead></thead>
                    <tbody>
                     
                    
                     
                     
                      {/* <tr>
                        <td style={{ textAlign: "right" }}>
                          SHIPPING/HANDLING
                        </td>
                        <td style={{ textAlign: "right" }}>0.00</td>
                      </tr> */}
                      <tr>
                        <td style={{ textAlign: "right" }}>TOTAL</td>
                        <td style={{ textAlign: "right" }}>
                          {" "}
                          ₹
                          {Item.amount}
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>

        {/* <span>TERMS & INSTRUCTIONS</span>
        <hr></hr>
        <Row>
          <Col md={"6"}>
            <Row>
              <Col md={"6"}>AMOUNT</Col>
              <Col md={"6"}> ₹   {(
                            Item.Product_Price +
                            (Item.Product_CGST / 100) * Item.Product_Price +
                            (Item.Product_SGST / 100) * Item.Product_Price -
                            (Item.Product_productdiscount / 100) *
                              (Item.Product_Price +
                                (Item.Product_CGST / 100) * Item.Product_Price +
                                (Item.Product_SGST / 100) * Item.Product_Price)
                          ).toFixed(0) * Item.Quantity}</Col>
            </Row>
            <Row>
              <Col md={"6"}>PAYMENT-TYPE</Col>
              <Col md={"6"}> {Order?.paymentMethod.toUpperCase()}</Col>
            </Row>
         
          </Col>
        </Row> */}
      </div>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <button
          style={{
            textAlign: "center",
            cursor: "pointer",
            marginTop: "30px",
            backgroundColor: "#70bbe9",
            color: "white",
            border: "none",
            padding: "15px",
            border: "1px solid white",
          }}
          onClick={createPDF}
        >
          PRINT INVOICE
        </button>
      </div>
    </div>
  );
}

export default NewInvoice;
