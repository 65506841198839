import { React, useEffect, useState } from "react";
import "./cart.css";
import Navbar from "../navbar/Navbar";
import { Link, useLocation } from "react-router-dom";
import moment from "moment";
import axios from "axios";
import { Tooltip } from "antd";
import { MdDelete } from "react-icons/md";
import { initializeApp } from "firebase/app";
import { getMessaging, getToken } from "firebase/messaging";
import { useRef } from "react";

const firebaseConfig = {
  apiKey: "AIzaSyCKxU1uQtQhgOkBddkbe1NKsFSXJ_v4ico",
  authDomain: "mukkatis.firebaseapp.com",
  projectId: "mukkatis",
  storageBucket: "mukkatis.appspot.com",
  messagingSenderId: "255102460015",
  appId: "1:255102460015:web:68af0bb8f73057720ead9a",
  measurementId: "G-EQVQ4N72VJ",
};

const Checkoutstay = () => {
  const location = useLocation();
  const { items } = location.state;
  const { noofrooms } = location.state;
  console.log(location.state);
  const [deviceToken, setdeviceToken] = useState("");
  const app = initializeApp(firebaseConfig);
  const messaging = getMessaging(app);
  function requestPermission() {
    console.log("Requesting permission...");
    Notification.requestPermission().then((permission) => {
      if (permission === "granted") {
        console.log("Notification permission granted.");
        getToken(messaging, {
          vapidKey:
            "BGuemogN_43tJcTzXHA03EpfZkZgS0nWo0vGao2tyI302BT4Cx2KcM5DAeYfAho2sEWE8cNV82qupr1_2zi6PGQ",
        }).then((currentToken) => {
          if (currentToken) {
            console.log("currentToken111: ", currentToken);
            setdeviceToken(currentToken);
          } else {
            console.log("Can not get token");
          }
        });
      } else {
        console.log("Do not have permission!");
      }
    });
  }
  requestPermission();
  const handleKeyDown = (e) => {
    // Prevent the input of the minus sign
    if (e.key === "-" || e.key === "e" || e.key === "E") {
      e.preventDefault();
    }
  };
  //console.log(location);
  //console.log(items, "itrm");
  const user = JSON.parse(sessionStorage.getItem("user"));
  const [Checkindate, setCheckindate] = useState();
  const [name, setname] = useState("");
  const [street, setstreet] = useState("");
  const [payment, setpayment] = useState("");

  const [country, setcountry] = useState("");
  const [state, setstate] = useState("");

  const [city, setcity] = useState("");
  const [pincode, setpincode] = useState("");

  const [email, setemail] = useState("");
  const [mobile, setmobile] = useState("");
  const address = [street, city, state, country, pincode];
  ////console.log(item, "item");
  const [Checkoutdate, setCheckoutdate] = useState();
  const [uploadedImage, setUploadedImage] = useState([]);
  const fileInputRef = useRef(null);

  const handleFileChange = (event) => {
    const files = Array.from(event.target.files);
    setUploadedImage([...uploadedImage, ...files]);
    // Reset the file input value to allow re-uploading the same image
    fileInputRef.current.value = null;
  };

  const deleteImage = (index) => {
    const updatedImages = [...uploadedImage];
    updatedImages.splice(index, 1);
    setUploadedImage(updatedImages.length === 0 ? [] : updatedImages);
    if (fileInputRef.current) {
      fileInputRef.current.value = null;
    }
  };

  const addBooking = async () => {
    if (!user) {
      alert("Please login");
      window.location.assign("/login");
    } else {
      try {
        // Define the request body
        const requestBody = {
          roomId: items.RoomDetails._id,
          checkInDate: moment(items.date[0].startDate).format("DD/MM/YYYY"),
          checkOutDate: moment(items.date[0].endDate).format("DD/MM/YYYY"),
          BookedRooms: noofrooms,
        };

        let response;

        if ("ApartmentType" in items.item) {
          response = await axios.post(
            "https://bookingoda.com/api/admin/apartment/booking",
            requestBody
          );
        } else if ("AlternativeType" in items.item) {
          response = await axios.post(
            "https://bookingoda.com/api/admin/alternative/booking",
            requestBody
          );
        } else if ("HomeType" in items.item) {
          response = await axios.post(
            "https://bookingoda.com/api/admin/home/booking",
            requestBody
          );
        } else if ("HotelType" in items.item) {
          response = await axios.post(
            "https://bookingoda.com/api/admin/hotel/booking",
            requestBody
          );
        } else {
          // Handle the case where none of the conditions are met
          console.error("No valid booking type found in items.");
          return;
        }
        if (response.status === 200) {
          PackageBooking();
        } else {
          console.log("Error:", response.data.error);
        }
      } catch (error) {
        console.error("Error:", error);
        alert(error.response.data.error);
      }
    }
  };
  const mobilePattern = /^[0-9]{10}$/;

  const PackageBooking = async () => {
    const namePattern = /^[a-zA-Z\s]+$/;
    const emailPattern = /^[^\s@]+@gmail\.com$/;
    const pincodePattern = /^\d{6}$/; // Assuming a 6-digit pincode format for validation
    const cityPattern = /^[a-zA-Z\s]+$/;
    const statePattern = /^[a-zA-Z\s]+$/;
    const countryPattern = /^[a-zA-Z\s]+$/;
    if (
      !name ||
      !mobile ||
      !email ||
      !pincode ||
      !city ||
      !state ||
      !street ||
      !country
    ) {
      alert("Please fill all the fields");
    } else if (!payment) {
      alert("Please select payment method");
    } else if (!mobilePattern.test(mobile)) {
      alert("Please enter a valid mobile number");
    } else if (!namePattern.test(name)) {
      alert("Please enter a valid name");
    } else if (!emailPattern.test(email)) {
      alert("Please enter a valid Gmail address");
    } else if (!pincodePattern.test(pincode)) {
      alert("Please enter a valid pincode");
    } else if (!cityPattern.test(city)) {
      alert("Please enter a valid city name");
    } else if (!statePattern.test(state)) {
      alert("Please enter a valid state name");
    } else if (!countryPattern.test(country)) {
      alert("Please enter a valid country name");
    } else if (uploadedImage.length === 0) {
      alert("No documents selected");
    } else {
      try {
        const config = {
          url: "admin/bookstay",
          method: "post",
          baseURL: "https://bookingoda.com/api/",
          headers: { "Content-Type": "application/json" },
          data: {
            userId: user?._id,

            name: name ? name : user.name,
            email: email ? email : user.email,
            mobile: mobile ? mobile : user.mobile,
            noofrooms: noofrooms,
            RoomsAvail: items.RoomDetails.RoomType,
            RoomName: items.RoomDetails.Roomname[0]?.label,
            address: address,
            payment: payment,
            noofpeople: items.Room,
            VendorID: items.item.UserId,
            amount: items.amount * noofrooms,
            arrivaldate: moment(items.date[0].startDate).format("DD/MM/YYYY"),
            departuredate: moment(items.date[0].endDate).format("DD/MM/YYYY"),
            packageId: items.item._id,
            PropertyName: items.item.PropertyName,
            deviceToken: deviceToken,
            title: "Booking Confirmation",
            body: "Your stay has been successfully booked.",
          },
        };
        await axios(config).then((res) => {
          if (res.status === 200) {
            // alert(res.data.success);
            // window.location.assign("/bookingoda");
            uploadFiles(res.data.data?._id);
          }
        });
      } catch (error) {
        ////console.log(error);
        alert(error.response.data.error);
      }
    }
  };
  const sendNotification = async (deviceToken, title, body) => {
    try {
      const response = await fetch(
        "https://bookingoda.com/api/admin/send-notification",
        {
          method: "post",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ deviceToken, title, body }),
        }
      );
      const result = await response.text();
      console.log(result); // Notification sent successfully
      // window.location.assign("/bookingoda");
    } catch (error) {
      console.error("Error sending notification:", error);
    }
  };
  const formdata2 = new FormData();
  const uploadFiles = async (id) => {
    try {
      for (let i = 0; i < uploadedImage.length; i++) {
        formdata2.append("id", id);
        formdata2.append("Doc", uploadedImage[i]);
        await fetch("https://bookingoda.com/api/admin/addDocStay/", {
          method: "post",
          body: formdata2,
        });
        alert("Booked Succesfully");
        window.location.assign("/bookingoda");
        // sendNotification(deviceToken, "Booking Confirmation", "Your stay has been successfully booked.");
      }
    } catch (error) {}
  };
  return (
    <div style={{ backgroundColor: "white" }}>
      <Navbar />
      <div class="header12 listMode">
        <div class="headerContainer12 container12">
          <div class="headerCont12">
            <div>
              <h2
                class="headerTitle12 text-center"
                style={{ marginTop: "50px" }}
              >
                Fresh, quite and peaceful
              </h2>
            </div>
          </div>
        </div>
      </div>
      <div className="containercart">
        <div id="primary" className="primary content-area">
          <article className="post-1832 page type-page status-publish hentry">
            <div className="entry-content">
              <div className="woocommerce">
                <div className="woocommerce-notices-wrapper" />
                <div name="checkout" className="checkout woocommerce-checkout">
                  <div className="col2-set" id="customer_details">
                    <div className="col-55">
                      <div className="woocommerce-billing-fields">
                        <h3>Billing details</h3>
                        <div className="woocommerce-billing-fields__field-wrapper">
                          <p
                            className="form-row55 form-row-first validate-required"
                            id="billing_first_name_field"
                            data-priority={10}
                          >
                            <label htmlFor="billing_first_name" className>
                              First name&nbsp;
                              <span
                                className="required"
                                title="required"
                                style={{ color: "red" }}
                              >
                                *
                              </span>
                            </label>
                            <span className="woocommerce-input-wrapper">
                              <input
                                type="text"
                                className="input-text55"
                                id="billing_first_name"
                                placeholder
                                name="name"
                                onChange={(e) => setname(e.target.value)}
                                autoComplete="given-name"
                              />
                            </span>
                          </p>

                          <p
                            className="form-row55 form-row-first validate-required"
                            id="billing_first_name_field"
                            data-priority={10}
                          >
                            <label htmlFor="billing_country" className>
                              Country / Region&nbsp;
                              <span
                                className="required"
                                title="required"
                                style={{ color: "red" }}
                              >
                                *
                              </span>
                            </label>
                            <span className="woocommerce-input-wrapper">
                              <input
                                type="text"
                                className="input-text55"
                                id="billing_first_name"
                                placeholder
                                name="country"
                                onChange={(e) => setcountry(e.target.value)}
                                autoComplete="given-name"
                              />
                            </span>
                          </p>
                          <p
                            className="form-row55 address-field validate-required form-row-wide"
                            id="billing_address_1_field"
                            data-priority={50}
                          >
                            <label htmlFor="billing_address_1" className>
                              Street address&nbsp;
                              <span
                                className="required"
                                title="required"
                                style={{ color: "red" }}
                              >
                                *
                              </span>
                            </label>
                            <span className="woocommerce-input-wrapper">
                              <input
                                type="text"
                                className="input-text55 "
                                id="billing_address_1"
                                placeholder="House number and street name"
                                name="street"
                                onChange={(e) => setstreet(e.target.value)}
                                autoComplete="address-line1"
                                data-placeholder="House number and street name"
                              />
                            </span>
                          </p>

                          <p
                            className="form-row55 address-field validate-required form-row-wide"
                            id="billing_city_field"
                            data-priority={70}
                            data-o_class="form-row55 form-row-wide address-field validate-required"
                          >
                            <label htmlFor="billing_city" className>
                              Town / City&nbsp;
                              <span
                                className="required"
                                title="required"
                                style={{ color: "red" }}
                              >
                                *
                              </span>
                            </label>
                            <span className="woocommerce-input-wrapper">
                              <input
                                type="text"
                                className="input-text55 "
                                id="billing_city"
                                placeholder
                                name="city"
                                onChange={(e) => setcity(e.target.value)}
                                autoComplete="address-level2"
                              />
                            </span>
                          </p>
                          <p
                            className="form-row55 address-field validate-required validate-state form-row-wide"
                            id="billing_state_field"
                            data-priority={80}
                            data-o_class="form-row55 form-row-wide address-field validate-required validate-state"
                          >
                            <label htmlFor="billing_state" className>
                              State&nbsp;
                              <span
                                className="required"
                                title="required"
                                style={{ color: "red" }}
                              >
                                *
                              </span>
                            </label>
                            <span className="woocommerce-input-wrapper">
                              <input
                                type="text"
                                className="input-text55"
                                id="billing_first_name"
                                placeholder
                                name="state"
                                onChange={(e) => setstate(e.target.value)}
                                autoComplete="given-name"
                              />
                            </span>
                          </p>
                          <p
                            className="form-row55 address-field validate-required validate-postcode form-row-wide"
                            id="billing_postcode_field"
                            data-priority={90}
                            data-o_class="form-row55 form-row-wide address-field validate-required validate-postcode"
                          >
                            <label htmlFor="billing_postcode" className>
                              PIN Code&nbsp;
                              <span
                                className="required"
                                title="required"
                                style={{ color: "red" }}
                              >
                                *
                              </span>
                            </label>
                            <span className="woocommerce-input-wrapper">
                              <input
                                type="number"
                                onKeyDown={handleKeyDown}
                                maxLength={6}
                                className="input-text55 "
                                id="billing_postcode"
                                placeholder
                                name="pincode"
                                onChange={(e) => setpincode(e.target.value)}
                                autoComplete="postal-code"
                              />
                            </span>
                          </p>
                          <p
                            className="form-row55 form-row-wide validate-required validate-phone"
                            id="billing_phone_field"
                            data-priority={100}
                          >
                            <label htmlFor="billing_phone" className>
                              Phone&nbsp;
                              <span
                                className="required"
                                title="required"
                                style={{ color: "red" }}
                              >
                                *
                              </span>
                            </label>
                            <span className="woocommerce-input-wrapper">
                              <input
                                type="tel"
                                onKeyDown={handleKeyDown}
                                className="input-text55 "
                                id="billing_phone"
                                placeholder
                                maxLength={10}
                                name="mobile"
                                onChange={(e) => setmobile(e.target.value)}
                                autoComplete="tel"
                              />
                            </span>
                          </p>
                          <p
                            className="form-row55 form-row-wide validate-required validate-email"
                            id="billing_email_field"
                            data-priority={110}
                          >
                            <label htmlFor="billing_email" className>
                              Email address&nbsp;
                              <span
                                className="required"
                                title="required"
                                style={{ color: "red" }}
                              >
                                *
                              </span>
                            </label>
                            <span className="woocommerce-input-wrapper">
                              <input
                                type="email"
                                className="input-text55 "
                                id="billing_email"
                                placeholder
                                name="email"
                                onChange={(e) => setemail(e.target.value)}
                                autoComplete="email username"
                              />
                            </span>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <h3 id="order_review_heading">Your order</h3>
                  <div
                    id="order_review"
                    className="woocommerce-checkout-review-order"
                  >
                    <table className="shop_table woocommerce-checkout-review-order-table">
                      <thead>
                        <tr>
                          <th className="product-name">Stay</th>
                          <th className="product-total">Total</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr className="cart_item">
                          <td className="product-name">
                            {items.item.PropertyName}&nbsp;{" "}
                            <div className="cs-room-order-wrapper">
                              <div className="cs-room-order-date">
                                <strong>Room Name: </strong>{" "}
                                {items.RoomDetails.Roomname[0]?.label}
                              </div>
                              <div className="cs-room-order-date">
                                <strong>Date: </strong>
                                {moment(items.date[0].startDate).format(
                                  "DD/MM/YYYY"
                                )}{" "}
                                -{" "}
                                {moment(items.date[0].endDate).format(
                                  "DD/MM/YYYY"
                                )}{" "}
                              </div>
                              <div className="cs-room-order-details">
                                <strong>Details: </strong>
                                Adults: {items.Room.adult} ,Children:{" "}
                                {items.Room.children} ,Room: {noofrooms}
                              </div>
                            </div>
                          </td>
                          <td className="product-total">
                            <span className="woocommerce-Price-amount amount">
                              <bdi>
                                <span className="woocommerce-Price-currencySymbol">
                                  Rs.
                                </span>
                                {items.amount * noofrooms}
                              </bdi>
                            </span>{" "}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <div className="upload-photos_container">
                      <div className="drag-and-drop__highlight">
                        <div className="wide-container min-height ota-container">
                          <div
                            className="spacing--4x"
                            data-testid="NumberOfPhotosRequiredMessage-container"
                          >
                            <label
                              htmlFor="photoFileInput"
                              className="add-photo-sub-info font_body_2"
                            >
                              <div
                                className="a3332d346a"
                                style={{ margin: "10px", display: "block" }}
                              >
                                Upload documents for verfication (Pan
                                card/Aadhar Card/Any other relevant government
                                document).
                              </div>{" "}
                            </label>
                          </div>
                          <div className="take-photo take-photo--with-drag-and-drop">
                            <div className="drag-and-drop">
                              <div
                                style={{
                                  border: "2px dashed #cccccc",
                                  borderRadius: "4px",
                                  padding: "20px",
                                  textAlign: "center",
                                  display: "flex",
                                  flexDirection: "row",
                                  cursor: "pointer",
                                }}
                              >
                                <input
                                  type="file"
                                  multiple
                                  onChange={handleFileChange}
                                  ref={fileInputRef}
                                />
                                <p>Click to select one or more images</p>
                              </div>
                            </div>
                          </div>
                          <div className="take-photo take-photo--with-drag-and-drop">
                            <div className="drag-and-drop">
                              <div
                                style={{
                                  textAlign: "center",
                                  marginTop: "10px",
                                }}
                              >
                                {uploadedImage &&
                                  uploadedImage.map((item, index) => (
                                    <div
                                      key={index}
                                      style={{
                                        display: "inline-block",
                                        marginRight: "10px",
                                        marginTop: "10px",
                                      }}
                                    >
                                      <img
                                        src={URL.createObjectURL(item)}
                                        alt="Uploaded"
                                        style={{ maxWidth: "50px" }}
                                      />

                                      <Tooltip title="delete">
                                        <MdDelete
                                          color="red"
                                          onClick={() => deleteImage(index)}
                                        />
                                      </Tooltip>
                                    </div>
                                  ))}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div id="payment" className="woocommerce-checkout-payment">
                      <ul className="wc_payment_methods payment_methods methods">
                        <li
                          className="wc_payment_method payment_method_cheque"
                          style={{ display: "flex", flexDirection: "row" }}
                        >
                          <input
                            id="payment_method_cheque"
                            type="radio"
                            className="input-radio"
                            name="payment_method"
                            value="Online payment"
                            onChange={(e) => setpayment(e.target.value)}
                          />
                          <label
                            htmlFor="payment_method_cheque"
                            style={{ marginLeft: "20px", marginTop: "10px" }}
                          >
                            {"   "} Online payment
                          </label>
                        </li>
                        <li
                          className="wc_payment_method payment_method_cod"
                          style={{ display: "flex", flexDirection: "row" }}
                        >
                          <input
                            id="payment_method_cod"
                            type="radio"
                            className="input-radio"
                            name="payment_method"
                            data-order_button_text
                            value="Pay Cash"
                            onChange={(e) => setpayment(e.target.value)}
                          />
                          <label
                            htmlFor="payment_method_cod"
                            style={{ marginLeft: "20px", marginTop: "10px" }}
                          >
                            Pay Cash
                          </label>
                        </li>
                      </ul>
                      <div className="form-row55 place-order">
                        <button
                          className="button alt"
                          id="place_order"
                          onClick={addBooking}
                        >
                          Place order
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </article>
        </div>
      </div>
    </div>
  );
};

export default Checkoutstay;
