import React from "react";
import "./property.css";
import Apartment from "../../assets/apartments.png";
import Apartments from "../../assets/buildings.png";

import Homestay from "../../assets/homestay.png";
import Hotel from "../../assets/hotel.png";
import VendorHeader from "./Vendorheader";
import { useLocation, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
const Alternativeedit = () => {
  const { state } = useLocation();

  ////console.log(state,"gfgf");
  const Roomdetails = JSON.parse(sessionStorage.getItem("Roomdetails"))

 
  const history = useNavigate();
  const AddRoom = () => {
  
    history("/alternativeaddroom", {
         state:
          
           state,
         
       },)
   };
   const removeRoomFromStorage = (roomNameToRemove) => {

    // Retrieve the array from session storage
    const storedRoomdetails = JSON.parse(sessionStorage.getItem('Roomdetails')) || [];
  
    // Use filter to create a new array excluding the item to be removed
    const updatedRoomDetails = storedRoomdetails.filter(
      (room) => room.Roomname[0].value !== roomNameToRemove
    );
  
    // Update session storage with the new array
    sessionStorage.setItem('Roomdetails', JSON.stringify(updatedRoomDetails));
    window.location.reload();
  };
  const HandleClick = () => {
    history("/alternativepayment",{
      state: 
       
        state,
      
    },);
  };
  
 
  return (
    <div
      data-capla-component="b-registration-joinapp15min-fe/"
      data-capla-namespace="b-registration-joinapp15min-feaYGUODfA"
    >
      <div id="app-container">
        <div class="error-popup-container"></div>
        <div class="application">
          <VendorHeader />
          <div class="error-popup-container"></div>
          <div className="page-container">
            <div className="screens screens-animation-direction-forward">
              <div
                className="height-100"
                id="automation_id_screen_container_MupOverviewV2"
              >
                <div className="ms-container screen-overview--room screen">
                  <div className="form-container">
                    <div className="wide-container">
                      <div
                        className="c82435a4b8 a178069f51 a6ae3c2b40 a18aeea94d d794b7a0f7 f53e278e95"
                        style={{ padding: "30px" }}
                      >
                        <div className="aca0ade214 ebac6e22e9 c2931f4182 e7d9f93f4d d79e71457a sectionDetails">
                          <div className>
                            {" "}
                            <span
                              data-testid="CardIcon-completeIcon"
                              className="fcd9eec8fb svg dba56f70c6 a229b4a525"
                              aria-hidden="true"
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 24 24"
                              >
                                <path d="M12 0C5.373 0 0 5.373 0 12s5.373 12 12 12 12-5.373 12-12C23.992 5.376 18.624.008 12 0zm6.927 8.2l-6.845 9.289a1.011 1.011 0 0 1-1.43.188l-4.888-3.908a1 1 0 0 1 1.25-1.562l4.076 3.261 6.227-8.451a1 1 0 1 1 1.61 1.183z" />
                              </svg>
                            </span>
                          </div>
                          <div className="aaee4e7cd3 e7a57abb1e">
                            {" "}
                            <article data-testid="CardContent-container">
                              <div
                                data-testid="CardContent-stepName"
                                className="abf093bdfe f45d8e4c32"
                              >
                                Step 1
                              </div>
                              <div
                                data-testid="CardContent-sectionName"
                                className="e1eebb6a1e"
                              >
                                Property details
                              </div>
                              <div
                                data-testid="CardContent-sectionSummary"
                                className="abf093bdfe f45d8e4c32"
                              >
                                The basics. Add your property name, address,
                                facilities, and more.
                              </div>
                            </article>
                          </div>
                          {/* <div className>
                            <div
                              className="action-btn-right-side-end"
                              onClick={() =>
                                window.location.assign("/property")
                              }
                            >
                              <button
                                id="automation_id_overview_edit-OVERVIEW_MAIN_FLOW_ID"
                                type="button"
                                className="a83ed08757 f88a5204c2 b98133fb50"
                              >
                                <span>Edit</span>
                              </button>
                            </div>
                          </div> */}
                        </div>
                      </div>
                      <div className="spacing--4x" />
                      <div
                        className="c82435a4b8 a178069f51 a6ae3c2b40 a18aeea94d d794b7a0f7 f53e278e95"
                        style={{ padding: "30px" }}
                      >
                        <div className="aca0ade214 ebac6e22e9 c2931f4182 e7d9f93f4d d79e71457a sectionDetails">
                          <div className>
                            {" "}
                            <span
                              data-testid="CardIcon-customIcon-largest"
                              className="fcd9eec8fb svg dba56f70c6"
                              aria-hidden="true"
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 24 24"
                              >
                                <path d="M8.25 12.375H.75l.75.75v-2.25a2.25 2.25 0 0 1 2.25-2.25H18a.75.75 0 0 0 0-1.5H3.75A3.75 3.75 0 0 0 0 10.875v2.25c0 .414.336.75.75.75h7.5a.75.75 0 0 0 0-1.5zm-8.25.75v3a.75.75 0 0 0 1.5 0v-3a.75.75 0 0 0-1.5 0zm18.75-5.25v-5.25A2.25 2.25 0 0 0 16.5.375h-12a2.25 2.25 0 0 0-2.25 2.25V7.97a.75.75 0 0 0 1.5 0V2.625a.75.75 0 0 1 .75-.75h12a.75.75 0 0 1 .75.75v5.25a.75.75 0 0 0 1.5 0zM8.5 5.625h4a.25.25 0 0 1 .25.25v2l.75-.75h-6l.75.75v-2a.25.25 0 0 1 .25-.25zm0-1.5a1.75 1.75 0 0 0-1.75 1.75v2c0 .414.336.75.75.75h6a.75.75 0 0 0 .75-.75v-2a1.75 1.75 0 0 0-1.75-1.75h-4zm14 12.75a5.25 5.25 0 1 1-10.5 0 5.25 5.25 0 0 1 10.5 0zm1.5 0a6.75 6.75 0 1 0-13.5 0 6.75 6.75 0 0 0 13.5 0zm-7.5-3v6a.75.75 0 0 0 1.5 0v-6a.75.75 0 0 0-1.5 0zm-2.25 3.75h6a.75.75 0 0 0 0-1.5h-6a.75.75 0 0 0 0 1.5z" />
                              </svg>
                            </span>
                          </div>
                            <div className="aaee4e7cd3 e7a57abb1e">
                            <article data-testid="CardContent-container">
                              <div
                                data-testid="CardContent-stepName"
                                className="abf093bdfe f45d8e4c32"
                              >
                                Step 2
                              </div>
                              <div
                                data-testid="CardContent-sectionName"
                                className="e1eebb6a1e"
                              >
                                Rooms
                              </div>
                              <div
                                data-testid="CardContent-sectionSummary"
                                className="abf093bdfe f45d8e4c32"
                              >
                                <div
                                  data-testid="Units-sectionSummaryTranslation"
                                  className="abf093bdfe f45d8e4c32"
                                >
                                  Tell us about your room. 
                                </div>
                                {Roomdetails?.map((e) =>(      <div
                                  data-testid="Units-container"
                                  className="c82435a4b8 spacing--4x"
                                  style={{ "--bui_box_padding--s": "0" }}
                                >
                                  <div
                                    data-testid="Units-card-861078787"
                                    className="c82435a4b8 a178069f51 a6ae3c2b40 a18aeea94d d794b7a0f7 f53e278e95 roomsCards"
                                    style={{ "--bui_box_padding--s": "0" }}
                                  >
                                    <div className="aca0ade214 ebac6e22e9 f66f916626 c2931f4182 c27e5d305d db150fece4">
                                      <div className="b817090550 b1e9f8a61d">
                                        <div
                                          className="b795ec59f5"
                                          style={{
                                            "--bui_aspect_ratio_padding-top--s":
                                              "100%",
                                          }}
                                        >
                                          <div className="aea002392a">
                                            <div className="f8e5e86e33">
                                              {/* <img
                                                src="https://q.bstatic.com/static/img/join/illustrated_bedroom.jpg"
                                                alt
                                              /> */}
                                              <img
                                            src={e.RoomPhotos[0].url}
                                            alt="Uploaded"
                                            // style={{ maxWidth: "50px" }}
                                          />
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="b817090550 d4e36dbd77">
                                        <div className="f2cf178bcd">
                                        {e.Roomname.map((option) => (     <div className="e1eebb6a1e e6208ee469 d0caee4251 spacing--2x">
                                             {option.value}
                                          </div> ))}
                                          <div className="a53cbfa6de f45d8e4c32 df64fda51b">
                                            <div
                                              data-testid="Units-subtitle-861078787-desktop"
                                              className="aca0ade214 ebac6e22e9 d79e71457a"
                                            >
                                              <div className="aaee4e7cd3 e7a57abb1e">
                                                <div className="roomsCardUnit">
                                                  <div
                                                    data-testid="Details-numberOfGuests"
                                                    className="f2cf178bcd"
                                                  >
                                                    <div className="a3332d346a e6208ee469 d0caee4251">
                                                      <div className="c8079eaf8c f45d8e4c32">
                                                        Guests
                                                      </div>
                                                    </div>
                                                    <div className="abf093bdfe f45d8e4c32 df64fda51b">
                                                      <div className="c8079eaf8c f45d8e4c32">
                                                        {e.RoomStay}
                                                      </div>
                                                    </div>
                                                  </div>
                                                  <div
                                                    data-testid="Details-numberOfBeds"
                                                    className="f2cf178bcd"
                                                  >
                                                    <div className="a3332d346a e6208ee469 d0caee4251">
                                                      <div className="c8079eaf8c f45d8e4c32">
                                                        Beds
                                                      </div>
                                                    </div>
                                                    <div className="abf093bdfe f45d8e4c32 df64fda51b">
                                                      <div className="c8079eaf8c f45d8e4c32">
                                                     {e.Twinbed}
                                                       
                                                      </div>
                                                    </div>
                                                  </div>
                                                  <div
                                                    data-testid="Details-is_bathroom_private"
                                                    className="f2cf178bcd"
                                                  >
                                                    <div className="a3332d346a e6208ee469 d0caee4251">
                                                      <div className="c8079eaf8c f45d8e4c32">
                                                        Bathroom Private
                                                      </div>
                                                    </div>
                                                    <div className="abf093bdfe f45d8e4c32 df64fda51b">
                                                      <div className="c8079eaf8c f45d8e4c32">
                                                      {e.PrivateBathroom}
                                                      </div>
                                                    </div>
                                                  </div>
                                                  <div
                                                    data-testid="Details-price"
                                                    className="f2cf178bcd"
                                                  >
                                                    <div className="a3332d346a e6208ee469 d0caee4251">
                                                      <div className="c8079eaf8c f45d8e4c32">
                                                        Price
                                                      </div>
                                                    </div>
                                                    <div className="abf093bdfe f45d8e4c32 df64fda51b">
                                                      <div className="c8079eaf8c f45d8e4c32">
                                                        INR {e.RoomPrice}
                                                      </div>
                                                    </div>
                                                  </div>
                                                  <div
                                                    data-testid="Details-num_units_with_exact_layout"
                                                    className="f2cf178bcd"
                                                  >
                                                    <div className="a3332d346a e6208ee469 d0caee4251">
                                                      <div className="c8079eaf8c f45d8e4c32">
                                                        Rooms of this type
                                                      </div>
                                                    </div>
                                                    <div className="abf093bdfe f45d8e4c32 df64fda51b">
                                                      <div className="c8079eaf8c f45d8e4c32">
                                                      {e.RoomType}
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                              <div className>
                                                <span data-testid="Actions-container">
                                                {e.Roomname.map((option) => (  <FontAwesomeIcon icon={faTrash} style={{color:"red"}} onClick={()=>removeRoomFromStorage(option.value)}/>  ))}
                                                  <span className="f419a93f12"></span>
                                                </span>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>))}
                              </div>
                            </article>
                          </div>
                          <div className>
                            <div className="action-btn-right-side-end">
                              <div
                                data-testid="ActionWithDetails-container"
                                className="cardActionContainer"
                                onClick={AddRoom}
                              >
                                <button
                                  data-testid="ActionWithDetails-btn-isMobile:false-variant:primary"
                                  id="automation_id_overview_add-OVERVIEW_SUBFLOW_0_FLOW_ID"
                                  type="button"
                                  className="a83ed08757 c21c56c305 a4c1805887 ab98298258 deab83296e c082d89982"
                                >
                                  <span className="e4adce92df">Add room</span>
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="spacing--4x" />
                      {/* <div
                        className="c82435a4b8 a178069f51 a6ae3c2b40 a18aeea94d d794b7a0f7 f53e278e95"
                        style={{ padding: "30px" }}
                      >
                        <div className="aca0ade214 ebac6e22e9 c2931f4182 e7d9f93f4d d79e71457a sectionDetails">
                          <div className>
                            {" "}
                            <span
                              data-testid="CardIcon-customIcon-largest"
                              className="fcd9eec8fb svg dba56f70c6"
                              aria-hidden="true"
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 24 24"
                              >
                                <path d="M22.5 12v9.75a.75.75 0 0 1-.75.75H2.25a.75.75 0 0 1-.75-.75V2.25a.75.75 0 0 1 .75-.75h19.5a.75.75 0 0 1 .75.75V12zm1.5 0V2.25A2.25 2.25 0 0 0 21.75 0H2.25A2.25 2.25 0 0 0 0 2.25v19.5A2.25 2.25 0 0 0 2.25 24h19.5A2.25 2.25 0 0 0 24 21.75V12zM5.85 17.7l3.462-4.616a.75.75 0 0 1 1.13-.08l1.028 1.026a.75.75 0 0 0 1.13-.08l3.3-4.4a.75.75 0 0 1 1.2 0l2.67 3.56a.75.75 0 1 0 1.2-.9L18.3 8.65a2.248 2.248 0 0 0-3.6 0l-3.3 4.4 1.13-.08-1.027-1.027a2.25 2.25 0 0 0-3.391.242L4.65 16.8a.75.75 0 1 0 1.2.9zM7.5 6.375a1.125 1.125 0 1 1-2.25 0 1.125 1.125 0 0 1 2.25 0zm1.5 0a2.625 2.625 0 1 0-5.25 0 2.625 2.625 0 0 0 5.25 0zM.75 18h22.5a.75.75 0 0 0 0-1.5H.75a.75.75 0 0 0 0 1.5z" />
                              </svg>
                            </span>
                          </div>
                          <div className="aaee4e7cd3 e7a57abb1e">
                            <article data-testid="CardContent-container">
                              <div
                                data-testid="CardContent-stepName"
                                className="abf093bdfe f45d8e4c32"
                              >
                                Step 3
                              </div>
                              <div
                                data-testid="CardContent-sectionName"
                                className="e1eebb6a1e"
                              >
                                Photos
                              </div>
                              <div
                                data-testid="CardContent-sectionSummary"
                                className="abf093bdfe f45d8e4c32"
                              >
                                Share some photos of your property so guests
                                know what to expect.
                              </div>
                            </article>
                          </div>
                          <div className>
                            <div className="action-btn-right-side-end">
                              <div
                                data-testid="ActionWithDetails-container"
                                className="cardActionContainer"
                                onClick={() =>
                                  window.location.assign("/propertyphoto")
                                }
                              >
                                <button
                                  data-testid="ActionWithDetails-btn-isMobile:false-variant:secondary"
                                  id="automation_id_overview_add-OVERVIEW_SUBFLOW_1_FLOW_ID"
                                  type="button"
                                  className="a83ed08757 c21c56c305 bf0537ecb5 ab98298258 deab83296e af7297d90d"
                                >
                                  <span className="e4adce92df">Add photos</span>
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div> */}
                      <div className="spacing--4x" />
                      <div
                        className="c82435a4b8 a178069f51 a6ae3c2b40 a18aeea94d d794b7a0f7 f53e278e95"
                        style={{ padding: "30px" }}
                      >
                        <div className="aca0ade214 ebac6e22e9 c2931f4182 e7d9f93f4d d79e71457a sectionDetails">
                          <div className>
                            {" "}
                            <span
                              data-testid="CardIcon-customIcon-largest"
                              className="fcd9eec8fb svg dba56f70c6"
                              aria-hidden="true"
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 24 24"
                              >
                                <path d="M3.75 18.75H2.571c-.183.006-.316-.119-.321-.277V1.725c.007-.115.136-.232.288-.226l18.891.001c.185-.007.314.11.322.262L21.75 18.5c-.005.131-.138.256-.297.25H20.25a.75.75 0 0 0 0 1.5h1.179c.96.032 1.785-.739 1.82-1.723l.001-16.802C23.2.711 22.372-.042 21.396.001L2.57 0C1.627-.042.799.711.751 1.688L.75 18.5a1.793 1.793 0 0 0 1.845 1.75H3.75a.75.75 0 0 0 0-1.5zm1.5-13.5h13.5a.75.75 0 0 0 0-1.5H5.25a.75.75 0 0 0 0 1.5zM5.217 9H15a.75.75 0 0 0 0-1.5H5.217a.75.75 0 0 0 0 1.5zm11.658 8.625a4.875 4.875 0 1 1-9.75 0 4.875 4.875 0 0 1 9.75 0zm1.5 0a6.375 6.375 0 1 0-12.75 0 6.375 6.375 0 0 0 12.75 0zm-4.716-1.941l-2.438 3.251a.12.12 0 0 1 .18-.014l-1.26-1.26a.75.75 0 1 0-1.06 1.061l1.26 1.261a1.38 1.38 0 0 0 2.08-.147l2.438-3.252a.75.75 0 1 0-1.2-.9z" />
                              </svg>
                            </span>
                          </div>
                          <div className="aaee4e7cd3 e7a57abb1e">
                            {" "}
                            <article data-testid="CardContent-container">
                              <div
                                data-testid="CardContent-stepName"
                                className="abf093bdfe f45d8e4c32"
                              >
                                Step 3
                              </div>
                              <div
                                data-testid="CardContent-sectionName"
                                className="e1eebb6a1e"
                              >
                                Final steps
                              </div>
                              <div
                                data-testid="CardContent-sectionSummary"
                                className="abf093bdfe f45d8e4c32"
                              >
                                Set up payments and invoicing before you open
                                for bookings.
                              </div>
                            </article>
                          </div>
                          <div class="">
                            <div class="action-btn-right-side-end">
                              <div
                                data-testid="ActionWithDetails-container"
                                class="cardActionContainer"
                                onClick={HandleClick
                                }
                              >
                                <button
                                  data-testid="ActionWithDetails-btn-isMobile:false-variant:secondary"
                                  id="automation_id_overview_add-OVERVIEW_SUBFLOW_2_FLOW_ID"
                                  type="button"
                                  class="a83ed08757 c21c56c305 bf0537ecb5 ab98298258 deab83296e af7297d90d"
                                >
                                  <span class="e4adce92df">
                                    Complete registration
                                  </span>
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="spacing--4x" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            id="automation_id_flow_Segmentation_Flow"
            style={{ display: "none" }}
          ></div>
          <div></div>
        </div>
      </div>
      <img
        alt=""
        height="1"
        width="1"
        style={{ display: "none" }}
        src="https://booking.com/track_join_app_landing?progress_status=2"
      />
    </div>
  );
};

export default Alternativeedit;
