import { React, useEffect, useState } from "react";
import CustomInput from "../components/CustomInput";
import ReactQuill from "react-quill";
import { useNavigate } from "react-router-dom";
import "react-quill/dist/quill.snow.css";
import { toast } from "react-toastify";
import * as yup from "yup";
import { useFormik } from "formik";

import { Select, Table } from "antd";
import Dropzone, { useDropzone } from "react-dropzone";

import Register from "../../pages/register/Register";
import axios from "axios";
import { Modal } from "react-bootstrap";
import Success from "../../assets/success.png";
import Error from "../../assets/close.png";
import { MdDelete } from "react-icons/md";
import moment from "moment";
let schema = yup.object().shape({});

const AddAdvertise = () => {
  const [uploadedImages, setUploadedImages] = useState();
  const [advertiseadd, setadvertiseadd] = useState(true);
  const [advertiselist, setadvertiselist] = useState(false);
  const [validity, setvalidity] = useState("");
  const [link, setlink] = useState("");
  const [Sec, setSec] = useState("");
  const [startingdate, setstartingdate] = useState("");
const handleadvertise =()=>{
  setadvertiseadd(true);
  setadvertiselist(false)
}
const handleadvertiselist =()=>{
  setadvertiseadd(false);
  setadvertiselist(true)
}
  const [advertise, setadvertise] = useState([]);

  const formik = useFormik({
    initialValues: {
      images: "",
    },
    validationSchema: schema,
    onSubmit: (values) => {
      ////console.log(values);
    },
  });
  const formdata = new FormData();

  const Register1 = async (e) => {
    e.preventDefault();
    if (!uploadedImages) {
      alert("Please select image");
    } else if(!startingdate ||  !link || !validity || !Sec){
      alert("All fields are required")
    }else {
      formdata.append("advertise", uploadedImages);
      formdata.append("link", link);
      formdata.append("startingdate", startingdate);
      formdata.append("validity", validity);
      formdata.append("Sec", Sec);

      try {
        const config = {
          url: "/addadvertise",
          method: "post",
          baseURL: "https://bookingoda.com/api/admin",

          headers: { "content-type": "multipart/form-data" },
          data: formdata,
          // data: {advertise:uploadedImages},
        };
        await axios(config).then(function (response) {
          if (response.status === 200) {
            ////console.log("success");
            handlesuccess();
            getadvertise();
            setUploadedImages()
            setstartingdate("")
            setvalidity("")
            setlink("")
            setSec("")
          }
        });
      } catch (error) {
        console.error(error);
        handleerror();
      }
    }
  };
  const remove = async (data) => {
    try {
      axios
        .post(`https://bookingoda.com/api/admin/deleteadvertise/` + data)
        .then(function (response) {
          if (response.status === 200) {
            ////console.log(response.data);
            alert("Successfully Deleted");
            getadvertise();
          }
        })
        .catch(function (error) {
          // setdatacondition(true);
          ////console.log(error.message.data);
          alert("Not able to complete");
        });
    } catch (error) {
      console.warn(error);
      alert("Not able to complete");
      // setdatacondition(true);
      return error;
    }
  };
  const [successshow, setsuccessshow] = useState(false);
  const handlesuccessClose = () => setsuccessshow(false);
  const handlesuccess = () => setsuccessshow(true);

  const [errorshow, seterrorshow] = useState(false);
  const handleerrorClose = () => seterrorshow(false);
  const handleerror = () => seterrorshow(true);
  const getadvertise = async () => {
    let res = await axios.get("https://bookingoda.com/api/admin/getalladvertise");
    if ((res.status = 200)) {
      ////console.log(res);
      setadvertise(res.data?.advertise);
    }
  };
  useEffect(() => {
    // getcountry();
    getadvertise();
  }, []);
  const columns = [
    {
      title: "Serial No.",
      dataIndex: "Sno",

      // //fixed: "left",
      width: 150,
    },
    {
      title: "Unique ID",
      dataIndex: "UniqueId",
 width: 150,
      // //fixed: "left",
    },
    {
      title: "Link",
      dataIndex: "link",
      width: 150,

      // //fixed: "left",
    },
    {
      title: "Starting Date",
      dataIndex: "startingdate",
      width: 150,

      // //fixed: "left",
    },
    {
      title: "Ending Date",
      dataIndex: "validity",
      width: 150,

      // //fixed: "left",
    },
    {
      title: "Delay",
      dataIndex: "seconds",
      width: 150,


    },
    {
      title: "Photos",
      dataIndex: "Photos",
      width: 550,

      render: (cell, row) => {
        ////console.log(row, "row");
        return (
          <div
            style={{
              overflowY: "scroll",
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap",
              justifyContent: "space-between",
            }}
          >
            <img
              src={"https://bookingoda.com/image/" + row.Photos}
              alt="image"
              style={{ width: "400px", height: "100px" }}
            />
          </div>
        );
      },
    },

    {
      title: "Action",
      dataIndex: "action1",
      key: "operation",
     
      width: 150,

      // render: () => <Link className="ms-3 fs-8 text-success">Approve</Link>,
    },
  ];
  const data1 = [];
  for (let i = 0; i < advertise.length; i++) {
    data1.push({
      Sno:i+1,
      UniqueId:  "BOMAINADV" + advertise[i]._id.slice(22, 24).toUpperCase(),
      link: advertise[i].link,
      Photos: advertise[i].advertise,
      startingdate: moment(advertise[i].startingdate).format("DD/MM/YYYY"),
      seconds:advertise[i].Sec,
      validity: moment(advertise[i].validity).format('DD/MM/YYYY'),

      action1: (
        <>
          <div className="d-flex gap-2 fs-4" style={{ cursor: "pointer" }}>
            <MdDelete
              className="text-danger"
              onClick={() => remove(advertise[i]?._id)}
            />
          </div>
        </>
      ),
    });
  }
  return (
    <div>
      <h3 className="mb-4 title">Add Main Screen  Advertisement/Hot deals</h3>
      <button
            className="btn btn-success border-0 rounded-3 my-5 mr-5"
            type="submit"
            onClick={handleadvertise}
          >
            Add Advertisement/Hot deals
          </button>
          <button
            className="btn btn-success border-0 rounded-3 my-5"
            type="submit"
            onClick={handleadvertiselist}
          >
            Advertisement/Hot deals List
          </button>
    {advertiseadd ?  <div>
        <form
          onSubmit={formik.handleSubmit}
          className="d-flex gap-3 flex-column"
        >
          <div className="bg-white border-1 p-5">
            <p style={{ color: "black", fontWeight: "900",textAlign:'center' }}>Upload Advertisement/Hot deals</p>
            <CustomInput
            type="text"
            label="Link"
            name="Link"
            onChng={(e)=>setlink(e.target.value)}
            
            val={link}
          />
       
       <CustomInput
            type="date"
            label="Starting Date"
            name="StartingDate"
            onChng={(e)=>setstartingdate(e.target.value)}
          
            val={startingdate}
          />
           <CustomInput
            type="date"
            label="Ending Date"
            name="EndingDate"
            onChng={(e)=>setvalidity(e.target.value)}
            
            val={validity}
          />
            <CustomInput
            type="number"
            label="Delay in miliseconds (eg:3000)"
            name="Delay"
            onChng={(e)=>setSec(e.target.value)}
            
            val={Sec}
          />
            <div
              className="dropzone-container"
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                padding: "20px",
              }}
            >
              <div
                className="dropzone"
                style={{
                  border: "2px dashed #cccccc",
                  borderRadius: "4px",
                  padding: "20px",
                  textAlign: "center",
                  cursor: "pointer",
                  marginBottom: "20px",
                }}
              >
                <input
                  type="file"
                  id="fileInput"
                  style={{ display: "none" }} // Hide the default file input
                  accept="image/png, image/gif, image/jpeg"
                  onChange={(e) => setUploadedImages(e.target.files[0])}
                />
                <label htmlFor="fileInput">
                  {/* Use a label as a clickable area */}
                  <p>Click to select files</p>
                </label>
              </div>

              <img
                src={
                  uploadedImages != undefined
                    ? URL.createObjectURL(uploadedImages)
                    : null
                }
                style={{ height: "150px", width: "100%" }}
              />
            </div>
          </div>

          <button
            className="btn btn-success border-0 rounded-3 my-5"
            type="submit"
            onClick={Register1}
          >
            Add Advertisement/Hot deals
          </button>
        </form>
      </div> : ""}
      {advertiselist ?  <div>
        <Table
          columns={columns} 
          className="custom-table"
          dataSource={data1}
          scroll={{ x: "calc(700px + 50%)", y: 240 }}
          pagination={{
            pageSize: 5,
            showSizeChanger: false, // Optional: Hide the "Show X entries" dropdown
          }}
          components={{
            header: {
            
              cell: props => (
                <th {...props} style={{ ...props.style, background:'linear-gradient(#2440b0,#1E348D,#131F51)'  ,color:"white"}} />
              ),
            },
          }}
        />
      </div> : ""}
      <Modal
        show={successshow}
        onHide={handlesuccess}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton onClick={handlesuccessClose}></Modal.Header>
        <Modal.Body>
          <div className="text-center">
            <img src={Success} style={{ marginTop: "-12px" }} width="100px" />

            <p>Successfully Added</p>
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        show={errorshow}
        onHide={handleerror}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton onClick={handleerrorClose}></Modal.Header>
        <Modal.Body>
          <div className="text-center">
            <img src={Error} style={{ marginTop: "-12px" }} width="100px" />

            <p>Error Occured</p>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default AddAdvertise;
