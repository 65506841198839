import Footer from '../footer/Footer';
import React, { useEffect, useState } from 'react';
import { FaArrowRight, FaArrowUp, FaLongArrowAltUp } from "react-icons/fa";
import { Carousel } from 'react-bootstrap';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { TfiMapAlt } from "react-icons/tfi";
import './homecar.css'
import Navbar from '../navbar/Navbar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';
import BgShape from "../../assets/service-right.svg";
import BgShape1 from "../../assets/choose-left.png";

import HeroCar from "../../assets/car-right.png";
import Icon1 from "../../assets/services-icon-01.svg";
import Icon2 from "../../assets/services-icon-02.svg";
import Icon3 from "../../assets/services-icon-03.svg";
import Icon4 from "../../assets/bx-selection.svg";
import Icon5 from "../../assets/bx-crown.svg";
import Icon6 from "../../assets/bx-user-check.svg";

import Car1 from "../../assets/car-parts-01.svg"
import Car2 from "../../assets/car-parts-02.svg"
import Car3 from "../../assets/car-parts-03.svg"
import Car4 from "../../assets/car-parts-04.svg"
import Car5 from "../../assets/car-parts-05.svg"
import Car6 from "../../assets/car-parts-06.svg"

import IndianCities from "indian-cities-json";
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';
import { SlCalender } from 'react-icons/sl';

function HomeCar1() {
    const options = {
        loop: true,
        margin: 10,
        responsive: {
          0: {
            items: 1,
          },
          600: {
            items: 2,
          },
          1000: {
            items: 3,
          },
        },
      };
      const [car, setcar] = useState([]);
  const getcar = async () => {
    let res = await axios.get("https://bookingoda.com/api/admin/getallCar");
    if (res.status === 200) {
      ////console.log(res.data.Car);
      setcar(res.data.Car.filter(
        (item) =>
          item.blockstatus !== true
      ));
    }
  };
  useEffect(() => {
    getcar();
  }, [car]);
  
      const [carType, setCarType] = useState("");
        const [pickUp, setPickUp] = useState("");
      
        const [dropOff, setDropOff] = useState("");
        const [pickTime, setPickTime] = useState("");
        const [dropTime, setDropTime] = useState("");
        const [pickTime1, setPickTime1] = useState("");
        const [dropTime1, setDropTime1] = useState("");
        const [carImg, setCarImg] = useState("");
      
        // modal infos
        ////console.log(pickTime1,"sadsa");
        const [name, setName] = useState("");
      
        const [phone, setPhone] = useState("");
        const [age, setAge] = useState("");
        const [email, setEmail] = useState("");
        const [address, setAddress] = useState("");
        const [city, setCity] = useState("");
        const [zipcode, setZipCode] = useState("");
        const handleCityChange1 = (event) => {
          setDropOff(event.target.value);
        };
        const handleCityChange = (event) => {
          setPickUp(event.target.value);
        };
        const handlePickTime = (e) => {
          setPickTime(e.target.value);
        };
        const history = useNavigate();  
        const handleDropTime = (e) => {
          setDropTime(e.target.value);
        };
        const search = () => {
          history("/carlist", sessionStorage.setItem("pickuplocation", JSON.stringify(pickUp)),
          sessionStorage.setItem("pickupdate", JSON.stringify(pickTime)),
          sessionStorage.setItem("pickuptime", JSON.stringify(pickTime1)),
          sessionStorage.setItem("dropupdate", JSON.stringify(dropTime)),
          sessionStorage.setItem("dropuptime", JSON.stringify(dropTime1)) );
        };
     
  return (
    <>
<Navbar />
      <div className="main-wrapper" >
        {/* Banner */}
        <div className="banner-section1 banner-slider" id="homecar">		
          <div className="containercars">
            <div className="home-banner">		
              <div className="rowcar align-items-center">					    
                <div className="col-lg-6 aos-init aos-animate" data-aos="fade-down">
                  <p className="explore-text"> <span><i className="fa-solid fa-thumbs-up me-2" /></span>100% Trusted car rental platform</p>
                  <h1>Find Your Best <br />									
                    <span>Dream Car for Rental</span></h1>
                  <p>Rent the car of your dreams. Unbeatable prices, unlimited miles, flexible pick-up options and much more.</p>
                  <div className="view-all">
                    <a href="/carlist" className="btncar btn-viewcar d-inline-flex align-items-center">View all Cars <span> {" "}<FaArrowRight /></span></a>
                  </div>
                </div>
                <div className="col-lg-6 aos-init aos-animate" data-aos="fade-down">
                  <div className="banner-imgs">
                    <img src={HeroCar} className="img-fluid aos" alt="bannerimage" />							
                  </div>
                </div>
              </div>
            </div>	
          </div>
        </div>
        {/* /Banner */}
        {/* Search */}	
        <div className="section-search"> 
          <div className="containercars">	  
            <div className="search-box-banner">
              {/* <form action={search}> */}
                <ul className="align-items-center">
               
                <li className="column-group-main">
                    <div className="input-block">
                      <label>Pickup Location</label>												
                      <div className="group-img">
                      <select
                    id="cities"
                    name="cities"
                    value={pickUp}
                    onChange={handleCityChange}
                  >
                    <option value="">Select pick up location</option>
                    {IndianCities.cities?.map((data) => (
                      <option value={data.name}>{data.name}</option>
                    ))}
                  </select>
                        {/* <input type="text" className="form-control" placeholder="Enter City" /> */}
                        <span><i className="feather-map-pin" /></span>
                      </div>
                    </div>
                  </li>
               
                 
                  <li className="column-group-main">						
                    <div className="input-block">																	
                      <label>Pickup Date</label>
                    </div>
                    <div className="input-block-wrapp">
                      <div className="input-block date-widget">												
                        <div className="group-img">
                          <input type="date" className="form-control datetimepicker" value={pickTime}
                    onChange={handlePickTime}  min={new Date().toISOString().split("T")[0]}
                    />
                          <span><i className="feather-calendar" /></span>
                        </div>
                      </div>
                      <div className="input-block time-widge">											
                        <div className="group-img">
                          <input type="time"  onChange={(e)=>setPickTime1(e.target.value)} className="form-control timepicker"  />
                          <span><i className="feather-clock" /></span>
                        </div>
                      </div>
                    </div>	
                  </li>
                  <li className="column-group-main">						
                    <div className="input-block">																	
                      <label>Return Date</label>
                    </div>
                    <div className="input-block-wrapp">
                      <div className="input-block date-widge">												
                        <div className="group-img">
                          <input  value={dropTime}
                    onChange={handleDropTime}
                    type="date"  min={new Date().toISOString().split("T")[0]} className="form-control datetimepicker" />
                          <span><i className="feather-calendar" /></span>
                        </div>
                      </div>
                      <div className="input-block time-widge">											
                        <div className="group-img">
                          <input type="time" onChange={(e)=>setDropTime1(e.target.value)} className="form-control timepicker"  />
                          <span><i className="feather-clock" /></span>
                        </div>
                      </div>
                    </div>	
                  </li>
                  <li className="column-group-last">
                    <div className="input-block">
                      <div className="search-btn">
                        <button className="btn search-button" onClick={search} type="submit"> <FontAwesomeIcon icon={faMagnifyingGlass} /> {" "}Search</button>
                      </div>
                    </div>
                  </li>
                </ul>
              {/* </form>	 */}
            </div>
          </div>	
        </div>	
        {/* /Search */}
        {/* services */}
        <section className="section services">
          <div className="service-right">
            <img src={BgShape} className="img-fluid" alt="services right" />
          </div>		
          <div className="containercars">	
            {/* Heading title*/}
            <div className="section-headingcar aos-init aos-animate" data-aos="fade-down">
              <h2>How It Works</h2>
              <p>Everything in life is located elsewhere, and you travel there by automobile</p>
            </div>
            {/* /Heading title */}
            <div className="services-work">
              <div className="rowcar">
                <div className="col-lg-4 col-md-4 col-12 aos-init aos-animate" data-aos="fade-down">
                  <div className="services-group">
                    <div className="services-icon border-secondary">
                      <img className="icon-img bg-secondary" src={Icon1} alt="Choose Locations" />
                    </div>
                    <div className="services-content">
                      <h3>1. Choose Locations</h3>
                      <p>A journey of a thousand miles begins with a single step.</p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-4 col-12 aos-init aos-animate" data-aos="fade-down">
                  <div className="services-group">
                    <div className="services-icon border-warning">
                      <img className="icon-img bg-warning" src={Icon2} alt="Choose Locations" />
                    </div>
                    <div className="services-content">
                      <h3>2. Pick-Up Locations</h3>
                      <p>Select the location where your journey starts</p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-4 col-12 aos-init aos-animate" data-aos="fade-down">
                  <div className="services-group">
                    <div className="services-icon border-dark">
                      <img className="icon-img bg-dark" src={Icon3} alt="Choose Locations" />
                    </div>
                    <div className="services-content">
                      <h3>3. Book your Car</h3>
                      <p>Book your comfortable car here </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
       
        <section className="section popular-services popular-explore">		
          <div className="containercars">	
            {/* Heading title*/}
            <div className="section-headingcar aos-init aos-animate" data-aos="fade-down">
              <h2>Explore Most Popular Cars</h2>
              <p>Steer the future of connected driving</p>
            </div>
            {/* /Heading title */}
         
            <div className="rowcar">
              <div className="popular-slider-group" style={{display:"flex",justifyContent:'space-between'}}>
              {/* <OwlCarousel className="owl-theme" {...options}> */}
     
                
                 
             {car.slice(0,3).map((item)=>{ return (
             <div className="rental-car-item">
                          <div className="listing-itemcar">
                            <div className="listing-img">
                            <Link  to="/Carrentaldetails" state={{car:item}}>
                                <img
                                  src={`https://bookingoda.com/image/${item?.Photos[0]?.file}`}
                                  className="img-fluid"
                                  alt="Toyota"
                                  style={{ width: "320px", height: "250px" }}
                                />
                              </Link>
                              <div className="fav-item">
                                <span className="featured-text">
                                  {item.CarModel}
                                </span>
                              </div>
                            </div>
                            <div className="listing-content">
                              <div className="listing-features">
                                <h3 className="listing-title">
                                <Link  to="/Carrentaldetails" state={{car:item}}>{item.CarName}</Link>
                                </h3>
                              </div>
                              <div className="listing-details-group">
                                <ul>
                                  <li>
                                    <span>
                                      <img src={Car1} alt="Auto" />
                                    </span>
                                    <p>{item.TransmissionType}</p>
                                  </li>
                                  <li>
                                    <span>
                                      <img src={Car2} alt="10 KM" />
                                    </span>
                                    <p>{item.Mileage} KM/ltr</p>
                                  </li>
                                  <li>
                                    <span>
                                      <img src={Car3} alt="Petrol" />
                                    </span>
                                    <p>{item.FuelType}</p>
                                  </li>
                                </ul>
                                <ul>
                                  <li>
                                    <span>
                                      <img src={Car4} alt="Power" />
                                    </span>
                                    <p>{item.Enginetype.slice(0,7)+"..."}</p>
                                  </li>
                                  <li>
                                    <span>
                                      <img src={Car5} alt={2018} />
                                    </span>
                                    <p>{item.YearofManufacture}</p>
                                  </li>
                                  <li>
                                    <span>
                                      <img src={Car6} alt="Persons" />
                                    </span>
                                    <p>{item.Seats} Seats</p>
                                  </li>
                                </ul>
                              </div>
                              <div className="listing-location-details">
                                {/* <div className="listing-price">
                          <span>
                            <GrMapLocation />
                          </span>
                          Germany
                        </div> */}
                                <div
                                  className="listing-price"
                                  style={{ alignSelf: "center" }}
                                >
                                  <h6>
                                    &#8377; {item.Price} <span>/ Per km</span>
                                  </h6>
                                </div>
                              </div>
                              <div className="listing-button">
                              <Link  to="/Carrentaldetails" state={{car:item}}>  <a className="btn btn-order"><span>   <span>
                            <SlCalender className='me-2' />
                          </span></span>Rent Now</a></Link>
                              </div>
                            </div>
                          </div>
                        </div>)})}
                          
                      
                      
                        {/* </OwlCarousel> */}
              </div>
            </div>
            {/* View More */}
            <div className="view-all text-center aos-init" data-aos="fade-down" style={{marginTop:"30px"}}>
              <a  href="/carlist "className="btncar btn-viewcar d-inline-flex align-items-center">Go to all Cars <span>{" "}<FaArrowRight /></span></a>
            </div>
          </div>
        </section>
     
        <section className="section why-choose popular-explore">
        <div className="choose-left">
          <img src={BgShape1} className="img-fluid" alt="Why Choose Us" />
        </div>		
        <div className="containercar">	
          {/* Heading title*/}
          <div className="section-headingcar aos-init aos-animate" data-aos="fade-down">
            <h2>Why Choose Us</h2>
           
          </div>
          {/* /Heading title */}
          <div className="why-choose-group">
            <div className="row">
              <div className="col-lg-4 col-md-6 col-12 d-flex aos-init aos-animate" data-aos="fade-down">
                <div className="cardcar flex-fill">
                  <div className="card-body">
                    <div className="choose-img choose-black">
                      <img src={Icon4} alt="Icon" />
                    </div>
                    <div className="choose-content">
                      <h4>Easy &amp; Fast Booking</h4>
                      <p>Fast, simple, secure: book your car online.</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-12 d-flex aos-init aos-animate" data-aos="fade-down">
                <div className="cardcar flex-fill">
                  <div className="card-body">
                    <div className="choose-img choose-secondary">
                      <img src={Icon5} alt="Icon" />
                    </div>
                    <div className="choose-content">
                      <h4>Many Pickup Location</h4>
                      <p>We have many different Pickup Car class pick up locations across the city</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-12 d-flex aos-init aos-animate" data-aos="fade-down">
                <div className="cardcar flex-fill">
                  <div className="card-body">
                    <div className="choose-img choose-primary">
                      <img src={Icon6} alt="Icon" />
                    </div>
                    <div className="choose-content">
                      <h4>Customer Satisfaction</h4>
                      <p>Painfully low customer satisfaction seems to be the new normal among rental car companies.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
 
        <div className="progress-wrap active-progress">
          <a href="#homecar">
        <FaLongArrowAltUp style={{marginLeft:"10px",marginTop:"10px"}} /></a>
      </div>
     
   
      </div>
      <Footer/>
    </>
  );
}

export default HomeCar1;
