import React, { useEffect, useState } from "react";
import "./property.css";
import Apartment from "../../assets/apartments.png";
import Apartments from "../../assets/buildings.png";

import Homestay from "../../assets/homestay.png";
import Hotel from "../../assets/hotel.png";
import VendorHeader from "./Vendorheader";
import { useLocation, useNavigate } from "react-router-dom";

const Alternativerate = () => {
  const { state } = useLocation();
  const handleKeyDown = (e) => {
    // Prevent the input of the minus sign
    if (e.key === '-' || e.key === 'e' || e.key === 'E') {
      e.preventDefault();
    }
  };
  ////console.log(state);
  const history = useNavigate();
  const [Cancel, setCancel] = useState(false);
  const [Group, setGroup] = useState(false);
  const [enable, setenable] = useState(false);
  const [reenable, setreenable] = useState(false);
  const [refamount, setrefamount] = useState(0);
  const [weekly, setweekly] = useState(false);
  const [weekamount, setweekamount] = useState(0);
  const [accidental, setaccidental] = useState(false);
  
  const [weekendamount, setweekendamount] = useState(0);

  const [Refund, setRefund] = useState(false);
  const [Week, setWeek] = useState(false);
  const [days, setdays] = useState("");


const number1 = JSON.parse(sessionStorage.getItem("Stay"))
const Price =JSON.parse(sessionStorage.getItem("Price"))
   const des = Array.from({ length: number1 }, (_, index) => number1 - index)
   ////console.log(des);
   const [discount, setdiscount] = useState({});
////console.log(Object.keys(discount).length);
const HandleClick = () => {
  history("/alternativeedit",{
    state: 
     
      state,
    
  },sessionStorage.setItem("Refunddays", JSON.stringify(days)),sessionStorage.setItem("AccidentalBooking", JSON.stringify(accidental)),sessionStorage.setItem("Discount", JSON.stringify(discount)),sessionStorage.setItem("Stay1", JSON.stringify(des)),sessionStorage.setItem("RefundRate", JSON.stringify(refamount)),sessionStorage.setItem("Weekdayrate", JSON.stringify(weekamount)),sessionStorage.setItem("Weekendrate", JSON.stringify(weekendamount)));
};
useEffect(() => {
  // Retrieve values from sessionStorage when component mounts
  const storedDays = sessionStorage.getItem("Refunddays");
  const storedAccidental = sessionStorage.getItem("AccidentalBooking");
  const storedDiscount = sessionStorage.getItem("Discount");
  const storedStay = sessionStorage.getItem("Stay1");
  const storedRefundRate = sessionStorage.getItem("RefundRate");
  const storedWeekdayRate = sessionStorage.getItem("Weekdayrate");
  const storedWeekendRate = sessionStorage.getItem("Weekendrate");

  setdays(JSON.parse(storedDays));
  setaccidental(JSON.parse(storedAccidental));
  if(storedDiscount){
    setdiscount(JSON.parse(storedDiscount));
    }
  setrefamount(JSON.parse(storedRefundRate));
  setweekamount(JSON.parse(storedWeekdayRate));
  setweekendamount(JSON.parse(storedWeekendRate));
}, []);
  return (
    <div
      data-capla-component="b-registration-joinapp15min-fe/"
      data-capla-namespace="b-registration-joinapp15min-feaYGUODfA"
    >
      <div id="app-container">
        <div class="error-popup-container"></div>
        <div class="application">
        <VendorHeader />
          <div class="error-popup-container"></div>

          <div className="page-container">
            <div className="screens screens-animation-direction-forward">
              <div
                className="height-100 screen-change-enter-done"
                id="automation_id_screen_container_PriceOverview"
              >
                <div className="ms-container">
                  <div className="form-container">
                    <div className="f2cf178bcd screen-main-header d991e1216a">
                      <div className="f6431b446c e6208ee469 d0caee4251 screen-main-header__title">
                        Rate plans
                      </div>
                    </div>
                    <div className="ffb9c3d6a3 wide-container spacing--6x">
                      <div className="aca0ade214 ebac6e22e9 f66f916626 c2931f4182 c27e5d305d db150fece4">
                        <div className="b817090550 b736e9e3f4">
                          <div className="a53cbfa6de">
                            To attract a wider range of guests, we suggest
                            setting up multiple rate plans. The recommended
                            prices and policies for each plan are based on data
                            from properties like yours, but they can be edited
                            now or after you complete registration.
                          </div>
                        </div>
                      </div>
                    </div>
                    <h2 className="f6431b446c screen-main-header spacing--4x">
                      Standard rate plan
                    </h2>
                    <div className="ffb9c3d6a3 wide-container spacing--6x">
                      <div className="c82435a4b8" style={{ padding: 0 }}>
                        <div className="price-overview-heading spacing--4x">
                          <div className="e1eebb6a1e price-overview-heading__text-container">
                            <span className="price-overview-heading__text">
                              Cancellation policy
                            </span>
                            <span className="f419a93f12">
                              <span
                                aria-expanded="false"
                                data-testid="PricePlanHeader-tip-trigger"
                                className="fcd9eec8fb price-overview-info bf9a32efa5"
                                aria-hidden="true"
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 24 24"
                                >
                                  <path d="M14.25 15.75h-.75a.75.75 0 0 1-.75-.75v-3.75a1.5 1.5 0 0 0-1.5-1.5h-.75a.75.75 0 0 0 0 1.5h.75V15a2.25 2.25 0 0 0 2.25 2.25h.75a.75.75 0 0 0 0-1.5zM11.625 6a1.125 1.125 0 1 0 0 2.25 1.125 1.125 0 0 0 0-2.25.75.75 0 0 0 0 1.5.375.375 0 1 1 0-.75.375.375 0 0 1 0 .75.75.75 0 0 0 0-1.5zM22.5 12c0 5.799-4.701 10.5-10.5 10.5S1.5 17.799 1.5 12 6.201 1.5 12 1.5 22.5 6.201 22.5 12zm1.5 0c0-6.627-5.373-12-12-12S0 5.373 0 12s5.373 12 12 12 12-5.373 12-12z" />
                                </svg>
                              </span>
                            </span>
                          </div>
                          <button
                            data-testid="PricePlanHeader-edit-btn"
                            type="button"
                            className="a83ed08757 c21c56c305 bf0537ecb5 ab98298258 deab83296e af7297d90d price-overview-link automation_class_price_overview_policies"
                            onClick={()=>setCancel(!Cancel)}
                          >
                            <span className="e4adce92df">Edit</span>
                          </button>
                        </div>
                        <div className="abf093bdfe spacing--2x">
                          This policy is set at the property level – any changes
                          made will be applied to all units.
                        </div>
                        {/* <div className="abf093bdfe d068504c75 spacing--4x">
                          You’re 91% more likely to get bookings with the
                          pre-selected cancellation policy settings than with a
                          30-day cancellation policy
                        </div> */}
                        <div className="price-overview-checkbox-container spacing--4x">
                          <span
                            className="fcd9eec8fb price-overview-checkbox-icon dbed623b69 f64eb5d122"
                            aria-hidden="true"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 128 128"
                            >
                              <path d="M56.62 93.54a4 4 0 0 1-2.83-1.18L28.4 67a4 4 0 1 1 5.65-5.65l22.13 22.1 33-44a4 4 0 1 1 6.4 4.8L59.82 91.94a4.06 4.06 0 0 1-2.92 1.59zM128 64c0-35.346-28.654-64-64-64C28.654 0 0 28.654 0 64c0 35.346 28.654 64 64 64 35.33-.039 63.961-28.67 64-64zm-8 0c0 30.928-25.072 56-56 56S8 94.928 8 64 33.072 8 64 8c30.914.033 55.967 25.086 56 56z" />
                            </svg>
                          </span>
                          <div className="a53cbfa6de">
                            Guests can cancel their bookings for free up to {days} before their arrival
                          </div>
                        </div>
                        <div className="price-overview-checkbox-container spacing--4x">
                          <span
                            className="fcd9eec8fb price-overview-checkbox-icon dbed623b69 f64eb5d122"
                            aria-hidden="true"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 128 128"
                            >
                              <path d="M56.62 93.54a4 4 0 0 1-2.83-1.18L28.4 67a4 4 0 1 1 5.65-5.65l22.13 22.1 33-44a4 4 0 1 1 6.4 4.8L59.82 91.94a4.06 4.06 0 0 1-2.92 1.59zM128 64c0-35.346-28.654-64-64-64C28.654 0 0 28.654 0 64c0 35.346 28.654 64 64 64 35.33-.039 63.961-28.67 64-64zm-8 0c0 30.928-25.072 56-56 56S8 94.928 8 64 33.072 8 64 8c30.914.033 55.967 25.086 56 56z" />
                            </svg>
                          </span>
                          <div className="a53cbfa6de">
                            Guests who cancel within 24 hours will have their
                            cancellation fee waived
                          </div>
                        </div>
                     {Cancel ?   <div className="ffb9c3d6a31 wide-container min-height">
                      <div className="aca0ade214 ebac6e22e9 f66f916626 c2931f4182 c27e5d305d db150fece4">
                        <div className="b817090550 b736e9e3f4">
                          <div className="form-group">
                            <p
                              className="policies__label"
                              data-testid="FreeCancellation-body"
                            >
                              How many days before their arrival can your guests{" "}
                              <b>cancel their booking for free</b>?
                            </p>
                            <div className="f3942475b4 b7efa77ac6 bd4784f2d6 d6b5cc954e">
                              <div
                                data-testid="FreeCancellation-SegmentedControl"
                                className="spacing--6x policies__free-cancellation-segmented-control--desktop"
                              >
                             <span className="cdadcf1b05">
                                        <input
                                          id="days1"
                                          className="b7cdcf5f57"
                                          type="radio"
                                          name="days"
                                          value="1 day"
                                          onChange={() => setdays("1 day")}
                                          defaultChecked={days === "1 day"}
                                        />
                                        <label
                                          htmlFor="days1"
                                          className="a53cbfa6de a454023a93"
                                        >
                                          1 day
                                        </label>
                                        <input
                                          id="days5"
                                          className="b7cdcf5f57"
                                          type="radio"
                                          name="days"
                                          value="5 days"
                                          onChange={() => setdays("5 days")}
                                          defaultChecked={days === "5 days"}
                                        />
                                        <label
                                          htmlFor="days5"
                                          className="a53cbfa6de a454023a93"
                                        >
                                          5 days
                                        </label>
                                        <input
                                          id="days14"
                                          className="b7cdcf5f57"
                                          type="radio"
                                          name="days"
                                          value="14 days"
                                          onChange={() => setdays("14 days")}
                                          defaultChecked={days === "14 days"}
                                        />
                                        <label
                                          htmlFor="days14"
                                          className="a53cbfa6de a454023a93"
                                        >
                                          14 days
                                        </label>
                                        <input
                                          id="days30"
                                          className="b7cdcf5f57"
                                          type="radio"
                                          name="days"
                                          value="30 days"
                                          onChange={() => setdays("30 days")}
                                          defaultChecked={days === "30 days"}
                                        />
                                        <label
                                          htmlFor="days30"
                                          className="a53cbfa6de a454023a93"
                                        >
                                          30 days
                                        </label>
                                      </span>
                              </div>
                            </div>
                            <div className="a4cbc7b9f5 a312d3fe67 b5156fd448 b88ad0398a">
                              <div
                                data-testid="FreeCancellation-select"
                                className="a53cbfa6de ac9267e216 spacing--6x"
                              >
                                <div className="cabad3c686 aff44befa2">
                                  <select
                                    className="ebf4591c8e"
                                    name="days"
                                    id=":r25:"
                                  >
                                    <option value={1} data-key={1}>
                                      Recommended1 day
                                    </option>
                                    <option value={5} data-key={5}>
                                      5 days
                                    </option>
                                    <option value={14} data-key={14}>
                                      14 days
                                    </option>
                                    <option value={30} data-key={30}>
                                      30 days
                                    </option>
                                  </select>
                                  <span className="ad2c299542">
                                    <span
                                      className="fcd9eec8fb cab1c196b5 c2cc050fb8 f64eb5d122"
                                      aria-hidden="true"
                                    >
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 24 24"
                                      >
                                        <path d="M19.2681 8.91288C19.2687 9.03226 19.2454 9.15054 19.1996 9.26079C19.1539 9.37105 19.0865 9.47105 19.0016 9.55493L12.9445 15.612C12.8211 15.7367 12.6738 15.8353 12.5115 15.9018C12.3491 15.9684 12.1751 16.0017 11.9996 15.9997C11.6491 16.0075 11.3096 15.877 11.0547 15.6362L4.99764 9.57916C4.8275 9.40881 4.73193 9.17788 4.73193 8.93711C4.73193 8.69634 4.8275 8.46542 4.99764 8.29506C5.08117 8.20935 5.18101 8.14122 5.29128 8.09471C5.40155 8.04819 5.52002 8.02423 5.6397 8.02423C5.75937 8.02423 5.87784 8.04819 5.98811 8.09471C6.09838 8.14122 6.19822 8.20935 6.28175 8.29506L11.9996 13.9887L17.7175 8.27084C17.801 8.18512 17.9009 8.11699 18.0111 8.07048C18.1214 8.02396 18.2399 8 18.3596 8C18.4792 8 18.5977 8.02396 18.708 8.07048C18.8182 8.11699 18.9181 8.18512 19.0016 8.27084C19.0871 8.3543 19.1548 8.45422 19.2006 8.56457C19.2464 8.67492 19.2694 8.79341 19.2681 8.91288Z" />
                                      </svg>
                                    </span>
                                  </span>
                                </div>
                              </div>
                            </div>
                            <div className="aca0ade214 aaf30230d9 c2931f4182 d79e71457a">
                              <span
                                className="fcd9eec8fb bf9a32efa5 b3ef400590"
                                aria-hidden="true"
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 24 24"
                                >
                                  <path d="M14.25 15.75h-.75a.75.75 0 0 1-.75-.75v-3.75a1.5 1.5 0 0 0-1.5-1.5h-.75a.75.75 0 0 0 0 1.5h.75V15a2.25 2.25 0 0 0 2.25 2.25h.75a.75.75 0 0 0 0-1.5zM11.625 6a1.125 1.125 0 1 0 0 2.25 1.125 1.125 0 0 0 0-2.25.75.75 0 0 0 0 1.5.375.375 0 1 1 0-.75.375.375 0 0 1 0 .75.75.75 0 0 0 0-1.5zM22.5 12c0 5.799-4.701 10.5-10.5 10.5S1.5 17.799 1.5 12 6.201 1.5 12 1.5 22.5 6.201 22.5 12zm1.5 0c0-6.627-5.373-12-12-12S0 5.373 0 12s5.373 12 12 12 12-5.373 12-12z" />
                                </svg>
                              </span>
                              <div className="aaee4e7cd3 e7a57abb1e">
                                Guests love flexibility – free cancellation
                                rates are generally the most booked rates on our
                                site. Get your first booking sooner by allowing
                                guests to cancel up to five days before
                                check-in.
                              </div>
                            </div>
                            <div className="policies__spacing" />
                            <div>
                              <label
                                className="policies__label spacing--4x"
                                data-testid="GracePeriod-title"
                                htmlFor="automation_id_switch_grace_period"
                              >
                                <div className="a3332d346a">
                                  Protection against accidental bookings
                                </div>
                              </label>
                              <div data-testid="GracePeriod-InputSwitch">
                                <div className="a53cbfa6de d87fd4472a">
                                  <input
                                    type="checkbox"
                                    name="switch"
                                    id="automation_id_switch_grace_period"
                                    className="f43c0d9153"
                                    
                                    role="switch"
                                  onChange={()=>setaccidental(!accidental)}
                                  />
                                  <label
                                    htmlFor="automation_id_switch_grace_period"
                                    className="dbe832aef0"
                                    aria-live="polite"
                                  >
                                    <span className="ade77cee67" />
                                    <span className="c640a7fa88">{!accidental ? "Off" : "On"}</span>
                                    <span className="f9bbc6d198" />
                                  </label>
                                </div>
                              </div>
                              <div
                                className="grace-period__caption"
                                data-testid="GracePeriod-body"
                              >
                                <div className="abf093bdfe f45d8e4c32">
                                  To avoid you having to spend time handling
                                  accidental bookings, we automatically waive
                                  cancellation fees for guests that cancel
                                  within the first 24 hours of making a booking.
                                </div>
                              </div>
                            </div>
                          </div>
                         
                        </div>
                        
                      </div>
                    </div> : ""}
                        <hr className="b9bfeba2b4 b288f61df6 spacing--4x" />
                        <div className="price-overview-heading spacing--4x">
                          <div className="e1eebb6a1e price-overview-heading__text-container">
                            <span className="price-overview-heading__text">
                              Price per group size
                            </span>
                            <span className="f419a93f12">
                              <span
                                aria-expanded="false"
                                data-testid="PricePlanHeader-tip-trigger"
                                className="fcd9eec8fb price-overview-info bf9a32efa5"
                                aria-hidden="true"
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 24 24"
                                >
                                  <path d="M14.25 15.75h-.75a.75.75 0 0 1-.75-.75v-3.75a1.5 1.5 0 0 0-1.5-1.5h-.75a.75.75 0 0 0 0 1.5h.75V15a2.25 2.25 0 0 0 2.25 2.25h.75a.75.75 0 0 0 0-1.5zM11.625 6a1.125 1.125 0 1 0 0 2.25 1.125 1.125 0 0 0 0-2.25.75.75 0 0 0 0 1.5.375.375 0 1 1 0-.75.375.375 0 0 1 0 .75.75.75 0 0 0 0-1.5zM22.5 12c0 5.799-4.701 10.5-10.5 10.5S1.5 17.799 1.5 12 6.201 1.5 12 1.5 22.5 6.201 22.5 12zm1.5 0c0-6.627-5.373-12-12-12S0 5.373 0 12s5.373 12 12 12 12-5.373 12-12z" />
                                </svg>
                              </span>
                            </span>
                          </div>
                          <button
                            data-testid="PricePlanHeader-edit-btn"
                            type="button"
                            className="a83ed08757 c21c56c305 bf0537ecb5 ab98298258 deab83296e af7297d90d price-overview-link automation_class_price_overview_obp"
                            onClick={()=>setGroup(!Group)}
                          >
                            <span className="e4adce92df">Edit</span>
                          </button>
                        </div>
                        {/* <div className="abf093bdfe d068504c75 spacing--4x">
                          You’re 12% more likely to get bookings if you set
                          lower prices for smaller groups of guests
                        </div> */}
                        <div className="spacing--4x">
                          <div className="price-overview-per-person-item spacing--4x">
                            <div className="price-overview-per-person__person">
                              <div className="e1eebb6a1e">Occupancy</div>
                            </div>
                            <div className="price-overview-per-person__price">
                              <div className="e1eebb6a1e">Guests pay</div>
                            </div>
                          </div>
                      {des.map((item)=>    
                          <div
                            className="price-overview-per-person-item spacing--4x"
                            data-testid="OccupancyRatesSection-price-per-person"
                          >
                            <div className="price-overview-per-person__person">
                              <span
                                className="fcd9eec8fb d24fc26e73"
                                aria-hidden="true"
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 24 24"
                                >
                                  <path d="M16.5 6a4.5 4.5 0 1 1-9 0 4.5 4.5 0 0 1 9 0zM18 6A6 6 0 1 0 6 6a6 6 0 0 0 12 0zM3 23.25a9 9 0 1 1 18 0 .75.75 0 0 0 1.5 0c0-5.799-4.701-10.5-10.5-10.5S1.5 17.451 1.5 23.25a.75.75 0 0 0 1.5 0z" />
                                </svg>
                              </span>{" "}
                              ✕ {item}
                            </div>
                            <div className="price-overview-per-person__price">
                              INR {Object.keys(discount ?? {}).length !== 0 && discount[item] !== undefined
  ? (parseInt(Price) - parseInt(Price) * (discount[item] ?? 0) / 100).toFixed(2)
  : parseInt(Price).toFixed(2)}

                            </div>
                          </div>)}
                     
                        </div>
                      </div>
                    </div>
       
                   {Group ? <div className="ffb9c3d6a3 wide-container spacing--6x">
                      <div className="aca0ade214 ebac6e22e9 f66f916626 c2931f4182 c27e5d305d db150fece4">
                        <div className="b817090550 b736e9e3f4">
                          <div className="a53cbfa6de spacing--4x">
                            Offering lower rates for groups of less than 2 makes
                            your property more attractive to potential guests.
                          </div>
                          <div className="a53cbfa6de spacing--4x">
                            The recommended discounts are based on data from
                            properties like yours. These can be updated at any
                            time.
                          </div>
                          <div
                            data-testid="GroupDiscount-input-switch"
                            className="a53cbfa6de d87fd4472a"
                          >
                            <input
                              type="checkbox"
                              name="switch"
                              id=":r88:"
                              className="f43c0d9153"
                             value={enable}
                              onChange={()=>setenable(!enable)}
                            />
                            <label
                              htmlFor=":r88:"
                              className="dbe832aef0"
                              aria-live="polite"
                            >
                              <span className="ade77cee67" />
                              <span className="c640a7fa88">{!enable ? "Disabled" : "Enabled"}</span>
                              <span className="f9bbc6d198" />
                            </label>
                          </div>
                       {enable ?    <div
                            className="obp-discounts-table__container"
                            data-testid="DiscountTable-container"
                          >
                            <div className="obp-discounts-table">
                              <div className="obp-discounts-table__header">
                                <div className="aca0ade214 ebac6e22e9 f66f916626 c2931f4182 c27e5d305d db150fece4">
                                  <div className="b817090550 a7cf1a6b1d">
                                    <div className="a3332d346a">Occupancy</div>
                                  </div>
                                  <div className="obp-discounts-table__header-discount b817090550 c44c37515e">
                                    <div className="a3332d346a">Discount</div>
                                  </div>
                                  <div className="obp-discounts-table__header-price b817090550 b8d011b59c">
                                    <div className="a3332d346a">Guests pay</div>
                                  </div>
                                </div>
                              </div>
                        
                              {des.map((item,index) =>     <div className="obp-discounts-table__discount">
                                <div className="aca0ade214 ebac6e22e9 f66f916626 c2931f4182 c27e5d305d db150fece4">
                                  <div className="obp-discounts-table__num-guests b817090550 a7cf1a6b1d">
                                    {item} guest
                                  </div>
                                  <div className="obp-discounts-table__discount-value b817090550 c44c37515e">
                                    <div className="a53cbfa6de ac9267e216 a20293ec70">
                                      <div className="b9b84f4305">
                                        <div className="e000754250">
                                          <input
                                            type="number" onKeyDown={handleKeyDown}
                                            name="percentage"
                                            className="eb46370fe1"
                                            placeholder={0}
                                            value={discount[item]}
                                            onChange={(e) => {
                                              const newDiscounts = { ...discount, [item]: parseInt(e.target.value, 10 )   || 0};
                                              setdiscount(newDiscounts);
                                            }}
                                          />
                                          <div className="e7e5251f68" />
                                        </div>
                                        <div className="b93ef00bf5">%</div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="obp-discounts-table__price b817090550 b8d011b59c">
                                  INR 
                                  {Object.keys(discount ?? {}).length !== 0 && discount[item] !== undefined
  ? (parseInt(Price) - parseInt(Price) * (discount[item] ?? 0) / 100).toFixed(2)
  : parseInt(Price).toFixed(2)}

                                  </div>
                                </div>
                              </div>)}
                            </div>
                          </div> : ""}
                        </div>
                      </div>
                    </div> : ""}
                    <h2 className="f6431b446c screen-main-header spacing--4x">
                      Non-refundable rate plan
                    </h2>
                    <div className="ffb9c3d6a3 wide-container spacing--6x">
                      <div className="price-overview-heading spacing--4x">
                        <div className="e1eebb6a1e price-overview-heading__text-container">
                          <span className="price-overview-heading__text">
                            Price and cancellation policy
                          </span>
                          <span className="f419a93f12">
                            <span
                              aria-expanded="false"
                              data-testid="PricePlanHeader-tip-trigger"
                              className="fcd9eec8fb price-overview-info bf9a32efa5"
                              aria-hidden="true"
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 24 24"
                              >
                                <path d="M14.25 15.75h-.75a.75.75 0 0 1-.75-.75v-3.75a1.5 1.5 0 0 0-1.5-1.5h-.75a.75.75 0 0 0 0 1.5h.75V15a2.25 2.25 0 0 0 2.25 2.25h.75a.75.75 0 0 0 0-1.5zM11.625 6a1.125 1.125 0 1 0 0 2.25 1.125 1.125 0 0 0 0-2.25.75.75 0 0 0 0 1.5.375.375 0 1 1 0-.75.375.375 0 0 1 0 .75.75.75 0 0 0 0-1.5zM22.5 12c0 5.799-4.701 10.5-10.5 10.5S1.5 17.799 1.5 12 6.201 1.5 12 1.5 22.5 6.201 22.5 12zm1.5 0c0-6.627-5.373-12-12-12S0 5.373 0 12s5.373 12 12 12 12-5.373 12-12z" />
                              </svg>
                            </span>
                          </span>
                        </div>
                        <button
                          data-testid="PricePlanHeader-edit-btn"
                          type="button"
                          className="a83ed08757 c21c56c305 bf0537ecb5 ab98298258 deab83296e af7297d90d price-overview-link automation_class_price_overview_non_refundable"
                          onClick={()=>setRefund(!Refund)}
                        >
                          <span className="e4adce92df">Edit</span>
                        </button>
                      </div>
                      <div className="price-overview-checkbox-container spacing--4x">
                        <span
                          className="fcd9eec8fb price-overview-checkbox-icon dbed623b69 f64eb5d122"
                          aria-hidden="true"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 128 128"
                          >
                            <path d="M56.62 93.54a4 4 0 0 1-2.83-1.18L28.4 67a4 4 0 1 1 5.65-5.65l22.13 22.1 33-44a4 4 0 1 1 6.4 4.8L59.82 91.94a4.06 4.06 0 0 1-2.92 1.59zM128 64c0-35.346-28.654-64-64-64C28.654 0 0 28.654 0 64c0 35.346 28.654 64 64 64 35.33-.039 63.961-28.67 64-64zm-8 0c0 30.928-25.072 56-56 56S8 94.928 8 64 33.072 8 64 8c30.914.033 55.967 25.086 56 56z" />
                          </svg>
                        </span>
                        <div className="a53cbfa6de">
                          Guests will pay {refamount}% less than the standard rate for a
                          non-refundable rate
                        </div>
                      </div>
                      <div className="price-overview-checkbox-container">
                        <span
                          className="fcd9eec8fb price-overview-checkbox-icon dbed623b69 f64eb5d122"
                          aria-hidden="true"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 128 128"
                          >
                            <path d="M56.62 93.54a4 4 0 0 1-2.83-1.18L28.4 67a4 4 0 1 1 5.65-5.65l22.13 22.1 33-44a4 4 0 1 1 6.4 4.8L59.82 91.94a4.06 4.06 0 0 1-2.92 1.59zM128 64c0-35.346-28.654-64-64-64C28.654 0 0 28.654 0 64c0 35.346 28.654 64 64 64 35.33-.039 63.961-28.67 64-64zm-8 0c0 30.928-25.072 56-56 56S8 94.928 8 64 33.072 8 64 8c30.914.033 55.967 25.086 56 56z" />
                          </svg>
                        </span>
                        <div className="a53cbfa6de">
                          Guests cannot cancel their bookings for free at any
                          time
                        </div>
                      </div>
                    </div>
                    {Refund ?  <div className="ffb9c3d6a3 wide-container min-height">
                      <div className="aca0ade214 ebac6e22e9 f66f916626 c2931f4182 c27e5d305d db150fece4">
                        <div className="b817090550 b736e9e3f4">
                          <div className="spacing--4x">
                            In addition to the standard rate plan you've created
                            for your property, you can add a non-refundable rate
                            plan.
                          </div>
                          <div className="spacing--8x">
                            With this, you set a discounted price but your{" "}
                            <b>revenue for these bookings is guaranteed</b> as
                            guests will not receive a refund if they cancel or
                            don’t show up.
                          </div>
                          <div
                            id="automation_id_non_refundable_plan_switch"
                            data-testid="automation_id_non_refundable_plan_switch"
                            className="a53cbfa6de d87fd4472a spacing--8x"
                          >
                            <input
                              type="checkbox"
                              name="switch"
                              id=":r3u:"
                              className="f43c0d9153"
                              
                              role="switch"
                              value={reenable}
                              onChange={()=>setreenable(!reenable)}
                            />
                            <label
                              htmlFor=":r3u:"
                              className="dbe832aef0"
                              aria-live="polite"
                            >
                              <span className="ade77cee67" />
                              <span className="c640a7fa88">
                                Set up a non-refundable rate plan
                              </span>
                              <span className="f9bbc6d198" />
                            </label>
                          </div>
                          {reenable ? 
                          <div
                            id="automation_id_non_refundable_plan_percentage_enabled_container"
                            data-testid="non_refundable_plan_percentage_enabled_container"
                          >
                            <hr className="b9bfeba2b4 b288f61df6 spacing--8x" />
                            <div
                              id="price_non_refundable_input_id"
                              className="a53cbfa6de ac9267e216 a20293ec70"
                            >
                              <label
                                htmlFor=":r3v:"
                                className="a53cbfa6de e6208ee469 f555271986"
                              >
                                <span>
                                  <span className="ccb65902b2">
                                    Discount for guests that book with this rate
                                    plan:
                                  </span>
                                </span>
                              </label>
                              <div className="b9b84f4305">
                                <div className="e000754250">
                                  <input
                                    type="number" onKeyDown={handleKeyDown}
                                    name="discount"
                                    className="eb46370fe1"
                                    id=":r3v:"
                                  value={refamount}
                          onChange={(e)=>setrefamount(e.target.value)}
                                  />
                                  <div className="e7e5251f68" />
                                </div>
                                <div className="b93ef00bf5">%</div>
                              </div>
                            </div>
                            <table className="non-refund-rate-calculation__table spacing--8x">
                              <tbody>
                                <tr>
                                  <td className="non-refund-rate-calculation__value-cell">
                                    INR {parseInt(Price)}
                                  </td>
                                  <td>Base price</td>
                                </tr>
                                <tr>
                                  <td className="non-refund-rate-calculation__value-cell">
                                    {refamount}%
                                  </td>
                                  <td>
                                    Discount when guests book the non-refundable
                                    option
                                  </td>
                                </tr>
                                <tr className="non-refund-rate-calculation__final-price-row">
                                  <td
                                    className="non-refund-rate-calculation__value-cell"
                                    data-testid="non_refund_price"
                                  >
                                    INR {parseInt(Price)- parseInt(Price)*refamount/100}
                                  </td>
                                  <td>Non-refundable price</td>
                                </tr>
                              </tbody>
                            </table>
                            <div className="aca0ade214 aaf30230d9 c2931f4182 d79e71457a">
                              <span
                                className="fcd9eec8fb bf9a32efa5"
                                aria-hidden="true"
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 24 24"
                                >
                                  <path d="M12 15.75A1.125 1.125 0 1 0 12 18a1.125 1.125 0 0 0 0-2.25.75.75 0 0 0 0 1.5.375.375 0 1 1 0-.75.375.375 0 0 1 0 .75.75.75 0 0 0 0-1.5zm.75-2.25V5.25a.75.75 0 0 0-1.5 0v8.25a.75.75 0 0 0 1.5 0zM22.5 12c0 5.799-4.701 10.5-10.5 10.5S1.5 17.799 1.5 12 6.201 1.5 12 1.5 22.5 6.201 22.5 12zm1.5 0c0-6.627-5.373-12-12-12S0 5.373 0 12s5.373 12 12 12 12-5.373 12-12z" />
                                </svg>
                              </span>
                              <div className="aaee4e7cd3 e7a57abb1e">
                                <div className="a53cbfa6de">
                                  Guests who select non-refundable rates are
                                  usually looking for competitive prices. A
                                  discount of at least {refamount}% will attract more
                                  guests by improving your visibility.
                                </div>
                              </div>
                            </div>
                          </div> : ""}
                        </div>
                      </div>
                      
                    </div> : ""}
                    <h2 className="f6431b446c screen-main-header spacing--4x">
                      Weekly rate plan
                    </h2>
                    <div className="ffb9c3d6a3 wide-container">
                      <div className="price-overview-heading spacing--4x">
                        <div className="e1eebb6a1e price-overview-heading__text-container">
                          <span className="price-overview-heading__text">
                            Price and cancellation policy
                          </span>
                          <span className="f419a93f12">
                            <span
                              aria-expanded="false"
                              data-testid="PricePlanHeader-tip-trigger"
                              className="fcd9eec8fb price-overview-info bf9a32efa5"
                              aria-hidden="true"
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 24 24"
                              >
                                <path d="M14.25 15.75h-.75a.75.75 0 0 1-.75-.75v-3.75a1.5 1.5 0 0 0-1.5-1.5h-.75a.75.75 0 0 0 0 1.5h.75V15a2.25 2.25 0 0 0 2.25 2.25h.75a.75.75 0 0 0 0-1.5zM11.625 6a1.125 1.125 0 1 0 0 2.25 1.125 1.125 0 0 0 0-2.25.75.75 0 0 0 0 1.5.375.375 0 1 1 0-.75.375.375 0 0 1 0 .75.75.75 0 0 0 0-1.5zM22.5 12c0 5.799-4.701 10.5-10.5 10.5S1.5 17.799 1.5 12 6.201 1.5 12 1.5 22.5 6.201 22.5 12zm1.5 0c0-6.627-5.373-12-12-12S0 5.373 0 12s5.373 12 12 12 12-5.373 12-12z" />
                              </svg>
                            </span>
                          </span>
                        </div>
                        <button
                          data-testid="PricePlanHeader-edit-btn"
                          type="button"
                          className="a83ed08757 c21c56c305 bf0537ecb5 ab98298258 deab83296e af7297d90d price-overview-link automation_class_price_overview_weekly_rate"
                          onClick={()=>setWeek(!Week)}
                        >
                          <span className="e4adce92df">Edit</span>
                        </button>
                      </div>
                      {/* <div className="abf093bdfe d068504c75 spacing--4x">
                        You’re 16% more likely to get bookings with the 15%
                        pre-selected weekly rate than with none
                      </div> */}
                      <div className="price-overview-checkbox-container spacing--4x">
                        <span
                          className="fcd9eec8fb price-overview-checkbox-icon dbed623b69"
                          aria-hidden="true"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 128 128"
                          >
                            <path d="M56.62 93.54a4 4 0 0 1-2.83-1.18L28.4 67a4 4 0 1 1 5.65-5.65l22.13 22.1 33-44a4 4 0 1 1 6.4 4.8L59.82 91.94a4.06 4.06 0 0 1-2.92 1.59zM128 64c0-35.346-28.654-64-64-64C28.654 0 0 28.654 0 64c0 35.346 28.654 64 64 64 35.33-.039 63.961-28.67 64-64zm-8 0c0 30.928-25.072 56-56 56S8 94.928 8 64 33.072 8 64 8c30.914.033 55.967 25.086 56 56z" />
                          </svg>
                        </span>
                        <div className="a53cbfa6de">
                          Guests will pay {weekamount}% less than the standard rate when
                          they book for at least 7 nights
                        </div>
                      </div>
                      <div className="price-overview-checkbox-container">
                        <span
                          className="fcd9eec8fb price-overview-checkbox-icon dbed623b69"
                          aria-hidden="true"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 128 128"
                          >
                            <path d="M56.62 93.54a4 4 0 0 1-2.83-1.18L28.4 67a4 4 0 1 1 5.65-5.65l22.13 22.1 33-44a4 4 0 1 1 6.4 4.8L59.82 91.94a4.06 4.06 0 0 1-2.92 1.59zM128 64c0-35.346-28.654-64-64-64C28.654 0 0 28.654 0 64c0 35.346 28.654 64 64 64 35.33-.039 63.961-28.67 64-64zm-8 0c0 30.928-25.072 56-56 56S8 94.928 8 64 33.072 8 64 8c30.914.033 55.967 25.086 56 56z" />
                          </svg>
                        </span>
                        <div className="a53cbfa6de">
                          Guests can cancel their bookings for free up to {days} 
                          before their arrival (based on the standard rate
                          cancellation policy)
                        </div>
                      </div>
                    </div>
                    {Week ? 
                    <div className="ffb9c3d6a3 wide-container min-height">
                      <div className="aca0ade214 ebac6e22e9 f66f916626 c2931f4182 c27e5d305d db150fece4">
                        <div className="b817090550 b736e9e3f4">
                          <div className="a53cbfa6de spacing--4x">
                            In addition to the standard rate plan you've created
                            for your property, you can add a weekly rate plan.
                          </div>
                          <div className="a53cbfa6de spacing--8x">
                            With this, you set a discounted price and use the
                            same cancellation policy as the standard rate plan.
                            Guests who stay for at least a week are interested
                            in discounts since they’ll be spending more on their
                            overall booking.
                          </div>
                          <div
                            data-testid="WeeklyRates-input-switch"
                            className="a53cbfa6de d87fd4472a spacing--8x"
                          >
                            <input
                              type="checkbox"
                              name="switch"
                              id=":r8u:"
                              className="f43c0d9153"
                              
                              role="switch"
                              onChange={()=>setweekly(!weekly)}
                            />
                            <label
                              htmlFor=":r8u:"
                              className="dbe832aef0"
                              aria-live="polite"
                            >
                              <span className="ade77cee67" />
                              <span className="c640a7fa88">
                                Set up a weekly rate plan
                              </span>
                              <span className="f9bbc6d198" />
                            </label>
                          </div>
                          <hr className="b9bfeba2b4 b288f61df6 spacing--8x" />
                          {weekly ? <>
                          <div
                            data-testid="WeeklyRates-input-discount"
                            className="a53cbfa6de ac9267e216 spacing--6x non-refund-rate-discount a20293ec70"
                          >
                            <label
                              htmlFor=":r8v:"
                              className="a53cbfa6de e6208ee469 f555271986"
                            >
                              <span>
                                <span className="ccb65902b2">
                                  How much cheaper than the standard rate would
                                  you like to make this rate plan for weekdays?
                                </span>
                              </span>
                            </label>
                            <div className="b9b84f4305">
                              <div className="e000754250">
                                <input
                                  type="number" onKeyDown={handleKeyDown}
                                  name="discount"
                                  className="eb46370fe1"
                                  
                                  value={weekamount}
                                  onChange={(e)=>setweekamount(e.target.value)}
                                />
                                <div className="e7e5251f68" />
                              </div>
                              <div className="b93ef00bf5">%</div>
                            </div>
                          </div>  <div
                            data-testid="WeeklyRates-input-discount"
                            className="a53cbfa6de ac9267e216 spacing--6x non-refund-rate-discount a20293ec70"
                          >
                            <label
                              htmlFor=":r8v:"
                              className="a53cbfa6de e6208ee469 f555271986"
                            >
                              <span>
                                <span className="ccb65902b2">
                                  How much cheaper than the standard rate would
                                  you like to make this rate plan for weekend?
                                </span>
                              </span>
                            </label>
                            <div className="b9b84f4305">
                              <div className="e000754250">
                                <input
                                  type="number" onKeyDown={handleKeyDown}
                                  name="discount"
                                  className="eb46370fe1"
                                  
                                  value={weekendamount}
                                  onChange={(e)=>setweekendamount(e.target.value)}
                                />
                                <div className="e7e5251f68" />
                              </div>
                              <div className="b93ef00bf5">%</div>
                            </div>
                          </div> </> : ""}
                        </div>
                      </div>
                   </div> : "" }
                  </div> <div className="form-cta">
                    <div>
                      <div className="form-buttons-container">
                        <div className="form-buttons-container--secondary" onClick={()=>window.history.back()}>
                          <button
                            aria-label="Back to previous step"
                            data-testid="FormButtonSecondary"
                            type="button"
                            className="a83ed08757 c21c56c305 bf0537ecb5 d691166b09 f671049264 d2529514af af7297d90d"
                          >
                            <span className="eedba9e88a">
                              <span
                                className="fcd9eec8fb c2cc050fb8"
                                aria-hidden="true"
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 24 24"
                                  data-rtl-flip="true"
                                >
                                  <path d="M15.0871 19.2361C14.9677 19.2367 14.8495 19.2134 14.7392 19.1676C14.629 19.1219 14.5289 19.0546 14.4451 18.9696L8.38799 12.9125C8.26332 12.7891 8.16475 12.6418 8.09815 12.4795C8.03156 12.3172 7.99829 12.1431 8.00034 11.9676C7.99246 11.6171 8.12303 11.2776 8.36376 11.0227L14.4208 4.96566C14.5912 4.79552 14.8221 4.69995 15.0629 4.69995C15.3037 4.69995 15.5346 4.79552 15.7049 4.96566C15.7907 5.04918 15.8588 5.14902 15.9053 5.25929C15.9518 5.36957 15.9758 5.48803 15.9758 5.60771C15.9758 5.72739 15.9518 5.84586 15.9053 5.95613C15.8588 6.0664 15.7907 6.16624 15.7049 6.24976L10.0113 11.9676L15.7292 17.6855C15.8149 17.769 15.883 17.8689 15.9295 17.9792C15.976 18.0894 16 18.2079 16 18.3276C16 18.4473 15.976 18.5657 15.9295 18.676C15.883 18.7863 15.8149 18.8861 15.7292 18.9696C15.6457 19.0551 15.5458 19.1228 15.4354 19.1686C15.3251 19.2144 15.2066 19.2374 15.0871 19.2361Z" />
                                </svg>
                              </span>
                            </span>
                          </button>
                        </div>
                        <div className="form-buttons-container--primary" onClick={HandleClick}>
                          <button
                            aria-label="Continue"
                            data-testid="FormButtonPrimary-enabled"
                            type="button"
                            className="a83ed08757 c21c56c305 a4c1805887 f671049264 d2529514af c082d89982"
                          >
                            <span className="e4adce92df">Continue</span>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              id="automation_id_screen_id_price-overview"
              style={{ display: "none" }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Alternativerate;
