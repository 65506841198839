import React, { useEffect, useRef } from "react";
import { Container, Image, Table } from "react-bootstrap";
import { AiOutlineRight } from "react-icons/ai";
import Logo from "../../assets/booking.jpeg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { City, Country, State } from "country-state-city";
import {
  faBed,
  faCalendarDays,
  faCar,
  faPerson,
  faUsersRays,
  faTaxi,
  faHouse,
  faHotel,
  faEarthAmericas,
} from "@fortawesome/free-solid-svg-icons";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import OwlCarousel from "react-owl-carousel";
import { useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import { IoIosArrowDown } from "react-icons/io";
import DatePicker from "react-multi-date-picker";
import axios from "axios";
import Footer from "../footer/Footer";
import Navbar from "../navbar/Navbar";
import { Map, GoogleApiWrapper, Marker } from "google-maps-react";
import { Tooltip } from "antd";
import { MdDelete } from "react-icons/md";

const TripPackageDetails = (props) => {
  const navigate = useNavigate();
  const handleKeyDown = (e) => {
    if (e.key === "-" || e.key === "e" || e.key === "E") {
      e.preventDefault();
    }
  };
  const user = JSON.parse(sessionStorage.getItem("user"));

  const lat = 13.07876;
  const log = 77.53324;

  const apiKey = "AIzaSyCom4BCfTVsw3o22AYg69Y7EovmLfV_Alw";

  const [street, setstreet] = useState("");
  const [payment, setpayment] = useState("");
  const [mobile, setmobile] = useState("");

  const [country, setcountry] = useState("");
  const [state, setstate] = useState("");

  const [city, setcity] = useState("");
  const [pincode, setpincode] = useState("");
  const CountryList = Country.getAllCountries();
  const StateList = State.getStatesOfCountry(country);
  const CityList = City.getCitiesOfState(country, state.isoCode);

  useEffect(() => {
    setstate({ isoCode: "", name: "" });
    setcity("");
  }, [country]);

  useEffect(() => {
    setcity("");
  }, [state]);
  const address = [street, city, state.name, country, pincode];

  const options = {
    items: 6,
    loop: true,
    margin: 10,
    nav: true,
    responsive: {
      navText: ["<", ">"],
      desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 5,
        slidesToSlide: 1,
      },
      tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 2,
        slidesToSlide: 2,
      },
      mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1,
        slidesToSlide: 1,
      },
    },
  };

  const [viewImage, setViewimage] = useState();
  const [acc1, setacc1] = useState(true);
  const [acc2, setacc2] = useState(false);
  const [acc3, setacc3] = useState(false);
  const [acc4, setacc4] = useState(false);

  const [Adult, setAdult] = useState(0);
  const adultinc = () => {
    setAdult(Adult + 1);
  };
  const adultdec = () => {
    if (Adult > 0) {
      setAdult(Adult - 1);
    }
  };

  const [Children, setChildren] = useState(0);
  const childreninc = () => {
    setChildren(Children + 1);
  };
  const childrendec = () => {
    if (Children > 0) {
      setChildren(Children - 1);
    }
  };

  const [Pets, setPets] = useState(0);
  const Petsinc = () => {
    setPets(Pets + 1);
  };
  const Petsdec = () => {
    if (Pets > 0) {
      setPets(Pets - 1);
    }
  };

  const [isOpen, setIsOpen] = useState(false);

  const handleClick = () => {
    setIsOpen(!isOpen);
  };
  const params = useParams();
  const [Tourpackages, setTourpackages] = useState([]);
  const getTourPackages = async () => {
    try {
      const res = await axios.get(
        "https://bookingoda.com/api/admin/gettrippackages"
      );
      if (res.status === 200) {
        setTourpackages(
          res.data.getpackages.filter((item) => item.blockstatus !== true)
        );
      }
    } catch (error) {}
  };
  useEffect(() => {
    getTourPackages();
  }, []);
  const item = Tourpackages.filter((items) => items?._id === params.id)[0];
  //console.log(item);
  // Book Tour Package
  const [Checkindate, setCheckindate] = useState();
  const [uploadedImage, setUploadedImage] = useState([]);
  const fileInputRef = useRef(null);

  const handleFileChange = (event) => {
    const files = Array.from(event.target.files);
    setUploadedImage([...uploadedImage, ...files]);
    // Reset the file input value to allow re-uploading the same image
    fileInputRef.current.value = null;
  };

  const deleteImage = (index) => {
    const updatedImages = [...uploadedImage];
    updatedImages.splice(index, 1);
    setUploadedImage(updatedImages.length === 0 ? [] : updatedImages);
    if (fileInputRef.current) {
      fileInputRef.current.value = null;
    }
  };
  const mobilePattern = /^[0-9]{10}$/;
  const PackageBooking = async () => {
    if (!user) {
      return alert("Please login");
    } else if (!Checkindate) {
      return alert("please select Check In Date");
    } else if (!Adult) {
      return alert("please confirm how many adult...");
    } else if (
      !pincode ||
      !city ||
      !state.name ||
      !street ||
      !country ||
      !mobile
    ) {
      alert("Please fill all the address fields");
    } else if (!payment) {
      alert("Please select payment method");
    } else if (!mobilePattern.test(mobile)) {
      return alert("Please enter a valid mobile number");
    } else if (uploadedImage.length === 0) {
      alert("No documents selected");
    } else {
      try {
        const config = {
          url: "admin/booktourpackage",
          method: "post",
          baseURL: "https://bookingoda.com/api/",
          headers: { "Content-Type": "application/json" },
          data: {
            userId: user?._id,
            packageId: item?._id,
            checkindate: Checkindate,
            adultprice: item?.offerprice * Adult,
            adultquantity: Adult,
            address: address,
            mobile: mobile,
            payment: payment,
            childrenprice: item?.childrenoffer * Children,
            childrenquantity: Children,
            petsprice: item?.petoffer * Pets,
            petsquantity: Pets,
          },
        };
        await axios(config).then((res) => {
          if (res.status === 200) {
            // alert(res.data.success);
            uploadFiles(res.data.data?._id);
            // window.location.assign("/bookingoda");
          }
        });
      } catch (error) {
        ////console.log(error);
        alert(error.response.data.error);
      }
    }
  };
  const formdata2 = new FormData();
  const uploadFiles = async (id) => {
    try {
      for (let i = 0; i < uploadedImage.length; i++) {
        formdata2.append("id", id);
        formdata2.append("Doc", uploadedImage[i]);
        await fetch("https://bookingoda.com/api/admin/addDocTourpackages/", {
          method: "post",
          body: formdata2,
        });
        alert("Booked Succesfully");

        window.location.assign("/Attraction");
      }
    } catch (error) {}
  };
  // Example discount percentage
  const childrenDiscount = (item?.childrendiscount / 100) * item?.childrenoffer;
  const childPrice = item?.childrenoffer - childrenDiscount;
  return (
    <div>
      <Navbar />

      <div className="mb-4">
        <div>
          <div
            // style={{
            //   background: "url(./img/trip-package-bg.jpg)",
            //   backgroundPosition: "initial",
            //   backgroundSize: "cover",
            //   backgroundRepeat: "no-repeat",
            //   backgroundAttachment: "fixed",
            //   height: "350px",
            //   width: "100%",
            // }}
            className="Pil"
          >
            <div className="activity-text">
              <div className="bread">
                <h2
                  className="text-uppercase fw-bold"
                  style={{ color: "white" }}
                >
                  Pilgrim Vacations
                </h2>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Container>
        <div className="row">
          <div className="col-lg-8">
            <div className="mb-5">
              {/* <h3 className='fs-2 fw-semibold mb-3'>The Best of <span className='text-danger'>Brazil & Argentina</span></h3> */}
              <h3 className="fs-2 fw-semibold mb-3">{item?.tripname}</h3>
              <h5 className="fw-semibold mb-3">
                {item?.city},{item?.state},{item?.country}
              </h5>
              <div className="d-flex gap-2 align-items-center mb-3 tour_head1">
                {/* <img src="./img/umbrella.png" alt="" className="umbrella" /> */}
                <h3>Description</h3>
              </div>
              <p className="text-secondary">{item?.tripdescription}</p>
              <br />
              {/* <p className='text-secondary'>Brazil’s view takes you through clouds of mist and the opportunity to see these 275 falls, spanning nearly two miles! Argentina’s side allows you to walk along the boardwalk network and embark on a jungle train through the forest for unforgettable views. Hear the deafening roar and admire the brilliant rainbows created by the clouds of spray, and take in the majesty of this wonder of the world. From vibrant cities to scenic beauty, this vacation to Rio de Janeiro, Iguassu Falls, and Buenos Aires will leave you with vacation memories you’ll cherish for life.</p> */}
            </div>

            <div className="mb-5">
              <div className="d-flex gap-2 align-items-center mb-3 tour_head1">
                {/* <img src="./img/umbrella.png" alt="" className="umbrella" /> */}
                <h3>Photo Gallery</h3>
              </div>

              <div>
                <img
                  src={
                    viewImage
                      ? viewImage
                      : `https://bookingoda.com/image/${item?.GalleryImage[0]?.image}`
                  }
                  alt=""
                  className="trip-gallery"
                />
              </div>

              <div className="small_imgA">
                {item?.GalleryImage?.map((data) => {
                  return (
                    <div
                      onClick={() => {
                        setViewimage(
                          `https://bookingoda.com/image/${data?.image}`
                        );
                        setacc1(true);
                        setacc2(false);
                        setacc3(false);
                        setacc4(false);
                      }}
                    >
                      <Image
                        src={`https://bookingoda.com/image/${data?.image}`}
                      />
                    </div>
                  );
                })}
              </div>
            </div>

            <div>
              <div className="d-flex gap-2 align-items-center mb-3 tour_head1">
                {/* <img src="./img/umbrella.png" alt="" className="umbrella" /> */}
                <h3>About The Tour</h3>
              </div>

              <Table responsive>
                <thead>
                  <tr>
                    {/* <th>Itinerary</th> */}
                    <th
                      width="20%"
                      style={{
                        color: "black",
                        fontWeight: "bold",
                        fontSize: "16px",
                      }}
                    >
                      Places covered
                    </th>
                    <th
                      width="20%"
                      style={{
                        color: "black",
                        fontWeight: "bold",
                        fontSize: "16px",
                      }}
                    >
                      Inclusions
                    </th>
                    <th
                      width="20%"
                      style={{
                        color: "black",
                        fontWeight: "bold",
                        fontSize: "16px",
                      }}
                    >
                      Exclusions
                    </th>
                    <th
                      width="20%"
                      style={{
                        color: "black",
                        fontWeight: "bold",
                        fontSize: "16px",
                      }}
                    >
                      Event Date and Time
                    </th>
                  </tr>
                </thead>
                <tbody className="sjrhg_0">
                  {item?.TourSchedule?.map((item, i) => {
                    return (
                      <tr>
                        {/* <td>Day {i + 1}</td> */}
                        <td style={{ color: "black", fontSize: "16px" }}>
                          {item?.placename}
                        </td>
                        <td style={{ color: "black", fontSize: "16px" }}>
                          {item?.inclusion}
                        </td>
                        <td style={{ color: "black", fontSize: "16px" }}>
                          {item?.exclusion}
                        </td>
                        <td style={{ color: "black", fontSize: "16px" }}>
                          {item?.eventDate}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </div>
            <div className="tour_head1 l-info-pack-days days">
              <h3>Detailed Day Wise Itinerary</h3>
              <ul>
                {item?.Itinerary?.map((items, i) => (
                  <li className="l-info-pack-plac">
                    <i class="fa-solid fa-clock"></i>
                    <h4>
                      <span>
                        Day : {i + 1} {"    "}
                      </span>
                      {"    "}
                      {items.Header}
                    </h4>
                    <p style={{ alignSelf: "center" }}>{items.ItineraryDesc}</p>
                  </li>
                ))}
              </ul>
            </div>
          </div>

          <div className="col-lg-4">
            <div
              style={{
                border: "1px solid rgb(221, 221, 221)",
                borderRadius: "12px",
                padding: "24px",
                boxShadow: "rgba(0, 0, 0, 0.12) 0px 6px 16px",
                marginTop: "120px",
              }}
            >
              <div className="out_0">
                <p>
                  Starting From {"   "}
                  <h6>INR {item?.tripcost}</h6>
                </p>
                <p>
                  ₹{item?.tripcost} <span>/ per Adult</span>
                </p>
                <p>
                  <label>Adult Discount :</label> {item?.tripdiscount}%
                </p>
                <p>
                  ₹{item?.childrenoffer} <span>/ per Children</span>
                </p>
                <p>
                  <label>Children Discount :</label> {item?.childrendiscount}%
                </p>
                <h6>
                  Total: ₹
                  {item?.offerprice * (Adult !== 0 ? Adult : null) +
                    childPrice * (Children !== 0 ? Children : null)}
                  {/* ₹{item?.offerprice} <span>/ per Adult</span> */}
                </h6>
              </div>

              <div>
                <div className="d-flex mb-2">
                  <div className="warner">
                    <label>Check In</label> <br />
                    <DatePicker
                      minDate={new Date()}
                      onChange={(date) =>
                        setCheckindate(date.format("DD/MM/YYYY"))
                      }
                      placeholder="Add Date"
                      style={{ border: "none", padding: "3px", width: "145px" }}
                    />
                  </div>
                  {/* <div className="warner">
                    <label>Check Out</label> <br />
                    <DatePicker
                      placeholder="Add Date"
                      style={{ border: "none", padding: "3px", width: "145px" }}
                    />
                  </div> */}
                </div>

                <div className="warner-out">
                  <label>Guest</label>
                  <div onClick={handleClick}>
                    <span>{Adult} Adult,</span>
                    <span>{Children} Children</span>
                    {/* <span>{Pets} pet</span> */}
                    <span className="float-end">
                      <IoIosArrowDown />
                    </span>
                  </div>
                </div>

                {isOpen && (
                  <>
                    <div className="p-2">
                      <div className="d-flex justify-content-between align-items-center mb-3">
                        <div>
                          <b>Adults</b>
                          <p style={{ fontSize: "12px" }}>Age 13+</p>
                        </div>
                        <div className="d-flex gap-2">
                          <RemoveIcon
                            onClick={() => adultdec()}
                            className="increase-icon"
                          />
                          <span>{Adult}</span>
                          <span>
                            <AddIcon
                              onClick={() => adultinc()}
                              className="increase-icon"
                            />
                          </span>
                        </div>
                      </div>

                      <div className="d-flex justify-content-between align-items-center mb-3">
                        <div>
                          <b>Children</b>
                          <p style={{ fontSize: "12px" }}>Age 2-12</p>
                        </div>
                        <div className="d-flex gap-2">
                          <RemoveIcon
                            onClick={() => childrendec()}
                            className="increase-icon"
                          />
                          <span>{Children}</span>
                          <span>
                            <AddIcon
                              onClick={() => childreninc()}
                              className="increase-icon"
                            />
                          </span>
                        </div>
                      </div>

                      {/* <div className="d-flex justify-content-between align-items-center mb-3">
                        <div>
                          <b>Infants</b>
                          <p style={{ fontSize: "12px" }}>Under 2</p>
                        </div>
                        <div className="d-flex gap-2">
                          <RemoveIcon
                            onClick={() => decrease()}
                            className="increase-icon"
                          />
                          <span>{Adult}</span>
                          <span>
                            
                            <AddIcon
                              onClick={() => increase()}
                              className="increase-icon"
                            />
                          </span>
                        </div>
                      </div> */}

                      {/* <div className="d-flex justify-content-between align-items-center mb-3">
                        <div>
                          <b>Pets</b>
                          <p style={{ fontSize: "12px" }}></p>
                        </div>
                        <div className="d-flex gap-2">
                          <RemoveIcon
                            onClick={() => Petsdec()}
                            className="increase-icon"
                          />
                          <span>{Pets}</span>
                          <span>
                            
                            <AddIcon
                              onClick={() => Petsinc()}
                              className="increase-icon"
                            />
                          </span>
                        </div>
                      </div> */}
                    </div>
                  </>
                )}
                <div className="warner-out">
                  {/* <input
                    type="text"
                    placeholder="Country"
                    required
                    style={{ width: "100%" }}
                    name="country"
                    onChange={(e) => setcountry(e.target.value)}
                  /> */}
                  <select
                    className="vi_0"
                    name="cc1"
                    id="cc1"
                    value={country}
                    onChange={(e) => setcountry(e.target.value)}
                  >
                    <option value="">Select country/region</option>

                    {CountryList?.map((Country) => (
                      <option value={Country?.isoCode} data-key="in">
                        {Country?.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="warner-out">
                  <input
                    type="tel"
                    onKeyDown={handleKeyDown}
                    maxLength={10}
                    placeholder="Mobile Number"
                    required
                    style={{ width: "100%" }}
                    name="Mobile Number"
                    onChange={(e) => setmobile(e.target.value)}
                  />
                </div>
                <div className="warner-out">
                  <input
                    type="text"
                    placeholder="Street address"
                    required
                    style={{ width: "100%" }}
                    name="Street"
                    onChange={(e) => setstreet(e.target.value)}
                  />
                </div>
                <div className="warner-out">
                  {/* <input
                    type="text"
                    placeholder="State"
                    required
                    style={{ width: "100%" }}
                    name="State"
                    onChange={(e) => setstate(e.target.value)}
                  /> */}
                  <select
                    className="vi_0"
                    name="cc1"
                    id="cc1"
                    style={{
                      width: "100%",
                      borderColor: "#E6E6E6",
                      fontSize: "16px",
                    }}
                    onChange={(e) => {
                      const selectedState = StateList.find(
                        (state) => state.isoCode === e.target.value
                      );
                      setstate(
                        selectedState
                          ? {
                              isoCode: selectedState.isoCode,
                              name: selectedState.name,
                            }
                          : { isoCode: "", name: "" }
                      );
                    }}
                  >
                    <option value="">
                      Select State
                      <span style={{ color: "red" }}>*</span>
                    </option>
                    {StateList?.map((state) => (
                      <option key={state.isoCode} value={state.isoCode}>
                        {state.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="warner-out">
                  {/* <input
                    type="text"
                    placeholder="City"
                    required
                    style={{ width: "100%" }}
                    name="City"
                    onChange={(e) => setcity(e.target.value)}
                  /> */}
                  <select
                    className="vi_0"
                    // class="ebf4591c8e country-select-for-e2e-tests"
                    style={{
                      width: "100%",
                      borderColor: "#E6E6E6",
                      fontSize: "16px",
                    }}
                    name="cc1"
                    id="cc1"
                    onChange={(e) => setcity(e.target.value)}
                  >
                    <option value="" style={{ fontSize: "16px" }}>
                      Select City <span style={{ color: "red" }}>*</span>
                    </option>
                    {CityList?.map((city1) => (
                      <option value={city1?.name} style={{ fontSize: "16px" }}>
                        {city1?.name}
                      </option>
                    ))}
                  </select>
                </div>

                <div className="warner-out">
                  <input
                    type="number"
                    onKeyDown={handleKeyDown}
                    maxLength={6}
                    placeholder="Pincode"
                    required
                    style={{ width: "100%" }}
                    name="Pincode"
                    onChange={(e) => setpincode(e.target.value)}
                  />
                </div>
                <div className="upload-photos_container">
                  <div className="drag-and-drop__highlight">
                    <div className="wide-container min-height ota-container">
                      <div
                        className="spacing--4x"
                        data-testid="NumberOfPhotosRequiredMessage-container"
                      >
                        <label
                          htmlFor="photoFileInput"
                          className="add-photo-sub-info font_body_2"
                        >
                          <div
                            className="a3332d346a"
                            style={{ margin: "10px", display: "block" }}
                          >
                            Upload documents for verfication (Pan card/Aadhar
                            Card/Any other relevant government document).
                          </div>{" "}
                        </label>
                      </div>
                      <div className="take-photo take-photo--with-drag-and-drop">
                        <div className="drag-and-drop">
                          <div
                            style={{
                              border: "2px dashed #cccccc",
                              borderRadius: "4px",
                              padding: "20px",
                              textAlign: "center",
                              display: "flex",
                              flexDirection: "row",
                              cursor: "pointer",
                            }}
                          >
                            <input
                              type="file"
                              multiple
                              onChange={handleFileChange}
                              ref={fileInputRef}
                            />
                            <p>Click to select one or more images</p>
                          </div>
                        </div>
                      </div>
                      <div className="take-photo take-photo--with-drag-and-drop">
                        <div className="drag-and-drop">
                          <div
                            style={{
                              textAlign: "center",
                              marginTop: "10px",
                            }}
                          >
                            {uploadedImage &&
                              uploadedImage.map((item, index) => (
                                <div
                                  key={index}
                                  style={{
                                    display: "inline-block",
                                    marginRight: "10px",
                                    marginTop: "10px",
                                  }}
                                >
                                  <img
                                    src={URL.createObjectURL(item)}
                                    alt="Uploaded"
                                    style={{ maxWidth: "50px" }}
                                  />

                                  <Tooltip title="delete">
                                    <MdDelete
                                      color="red"
                                      onClick={() => deleteImage(index)}
                                    />
                                  </Tooltip>
                                </div>
                              ))}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <h3>Payment Method</h3>
                  <div
                    id="payment"
                    className="woocommerce-checkout-payment"
                    style={{}}
                  >
                    <ul
                      className="wc_payment_methods payment_methods methods"
                      style={{ marginTop: "20px" }}
                    >
                      <li
                        className="wc_payment_method payment_method_cheque"
                        style={{ display: "flex", flexDirection: "row" }}
                      >
                        <input
                          id="payment_method_cheque"
                          type="radio"
                          className="input-radio"
                          name="payment_method"
                          value="Online payment"
                          onChange={(e) => setpayment(e.target.value)}
                        />
                        <label
                          htmlFor="payment_method_cheque"
                          style={{ marginLeft: "20px", marginTop: "10px" }}
                        >
                          {"     "} Online payment
                        </label>
                      </li>
                      <li
                        className="wc_payment_method payment_method_cod"
                        style={{ display: "flex", flexDirection: "row" }}
                      >
                        <input
                          id="payment_method_cod"
                          type="radio"
                          className="input-radio"
                          name="payment_method"
                          data-order_button_text
                          value="Pay Cash"
                          onChange={(e) => setpayment(e.target.value)}
                        />
                        <label
                          htmlFor="payment_method_cod"
                          style={{ marginLeft: "20px", marginTop: "10px" }}
                        >
                          Pay Cash
                        </label>
                      </li>
                    </ul>
                  </div>
                </div>
                <div>
                  <button
                    className="header-search1"
                    style={{ width: "100%", height: "44px" }}
                    onClick={() => PackageBooking()}
                  >
                    Book Now
                  </button>
                </div>
              </div>
            </div>
            <div className="list-single-main-item fl-wrap" id="sec4">
              <div className="list-single-main-item-title fl-wrap">
                <h3>Location</h3>
              </div>
              <div
                id="map"
                style={{ position: "relative", overflow: "hidden" }}
              >
                <div
                  style={{
                    height: "100%",
                    width: "100%",
                    position: "absolute",
                    top: 0,
                    left: 0,
                    backgroundColor: "rgb(229, 227, 223)",
                  }}
                >
                  <Map
                    google={props.google}
                    zoom={14}
                    style={{ width: "100%", height: "400px" }}
                    initialCenter={{ lat: lat, lng: log }}
                  >
                    <Marker position={{ lat: lat, lng: log }} />
                  </Map>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Container>

      <Footer />
    </div>
  );
};
export default GoogleApiWrapper({
  apiKey: "AIzaSyCom4BCfTVsw3o22AYg69Y7EovmLfV_Alw",
})(TripPackageDetails);
