import React, { useEffect, useState } from "react";
import "./property.css";
import Apartment from "../../assets/apartments.png";
import Apartments from "../../assets/buildings.png";

import Homestay from "../../assets/homestay.png";
import Hotel from "../../assets/hotel.png";
import VendorHeader from "./Vendorheader";
import { useLocation, useNavigate } from "react-router-dom";

const HotelInvoice = () => {
  const vendor = JSON.parse(sessionStorage.getItem("vendor"));
  const { state } = useLocation();
  const handleKeyDown = (e) => {
    // Prevent the input of the minus sign
    if (e.key === '-' || e.key === 'e' || e.key === 'E') {
      e.preventDefault();
    }
  };
  ////console.log(state,"gfgf");
  const [name, setname] = useState("");
  const [same, setsame] = useState("");

  const [country, setcountry] = useState("");
  const [streetname, setstreetname] = useState("");
  const [states, setstates] = useState("");
  const [pincode, setpincode] = useState();
  const [city, setcity] = useState("");
  const history = useNavigate();
  const handleOptionChange = (event) => {
    setname(event.target.value);


  };
  const handleOptionChange1 = (event) => {
    setsame(event.target.value);


  }; 
  const address =[]
  address.push(streetname,city,states,country,pincode)
   const HandleClick = () => {
    if (name === "") {
      alert("Please enter invoice name");
    } else if (same === "") {
      alert("Please select same address or not");
    } else if (same === "No" && (country === "" || states === "" || city === "" || streetname === "" || pincode === undefined)) {
      alert("Please enter different address");
    } else {
    history("/hotelcompleteregistration",{
      state: 
       
        state,
      
    },sessionStorage.setItem("invoicename", JSON.stringify(name)),sessionStorage.setItem("SameAddress", JSON.stringify(same)),sessionStorage.setItem("differentaddress", JSON.stringify(address)));
  }}
  useEffect(() => {
    // Retrieve data from sessionStorage
    const storedName = sessionStorage.getItem("invoicename");
    const storedSame = sessionStorage.getItem("SameAddress");
    const storedAddress = sessionStorage.getItem("differentaddress");

    // Check if the items exist in sessionStorage and set state accordingly
    if (storedName) {
      setname(JSON.parse(storedName));
    }

    if (storedSame) {
      setsame(JSON.parse(storedSame));
    }

    if (storedAddress) {
      // Parse the storedAddress string into an array
      const parsedAddress = JSON.parse(storedAddress);
  
      // Update state variables with individual values
      setstreetname(parsedAddress[0]);
      setcity(parsedAddress[1]);
      setstates(parsedAddress[2]);
      setcountry(parsedAddress[3]);
      setpincode(parsedAddress[4]);
    }
  }, []); 
  return (
    <div
      data-capla-component="b-registration-joinapp15min-fe/"
      data-capla-namespace="b-registration-joinapp15min-feaYGUODfA"
    >
      <div id="app-container">
        <div class="error-popup-container"></div>
        <div class="application">
        <VendorHeader />
          <div class="error-popup-container"></div>
          <div className="page-container">
            <div className="screens screens-animation-direction-forward">
              <div
                className="height-100 screen-change-enter-done"
                id="automation_id_screen_container_Invoicing"
              >
                <div className="ms-container">
                  <div className="form-container">
                    <div className="f2cf178bcd screen-main-header d991e1216a">
                      <div className="f6431b446c e6208ee469 d0caee4251 screen-main-header__title">
                        Invoicing
                      </div>
                    </div>
                    <div className="ffb9c3d6a3 wide-container min-height">
                      <div className="aca0ade214 ebac6e22e9 f66f916626 c2931f4182 c27e5d305d db150fece4">
                        <div className="b817090550 b736e9e3f4">
                         
                          <div className="e1eebb6a1e spacing--4x question-title">
                            What name should be on the invoice?
                          </div>
                          <fieldset className="a53cbfa6de ac9267e216">
                            <div className="aca0ade214 aaf30230d9 cd2e7d62b0">
                            <div className="a53cbfa6de ac9267e216 ad8af0911d">
                              <label
                                htmlFor=":r6c:"
                                className="aca0ade214 aaf30230d9 c2931f4182 d79e71457a f8208e9f83"
                              >
                                <input
                                  className="c907c67d20"
                                  type="radio"
                                  value={vendor.name}
                                  checked={name === vendor.name}
                                  onChange={handleOptionChange}
                                />
                                <span className="aaee4e7cd3 e7a57abb1e">
                                  <div className="a53cbfa6de e6208ee469">
                                  {vendor.name}
                                  </div>
                                </span>
                              </label>
                            </div>
                           </div>
                          </fieldset>
                          <div className="spacing--6x" />
                          <div className="a53cbfa6de ac9267e216 a20293ec70">
                            <label
                              htmlFor=":ro:"
                              className="a53cbfa6de e6208ee469 f555271986"
                            >
                              <span>
                                <span className="ccb65902b2">
                                  Legal company name (please specify)
                                </span>
                              </span>
                            </label>
                            <div className="b9b84f4305">
                              <div className="e000754250">
                                <input
                                  name="legal_company_name"
                                  className="eb46370fe1"
                                 value={name}
                                 onChange={(e)=>setname(e.target.value)}
                                  
                                />
                                <div className="e7e5251f68" />
                              </div>
                            </div>
                          </div>
                          <div className="spacing--8x" />
                          <hr className="b9bfeba2b4 b288f61df6" />
                          <div className="spacing--8x" />
                          <div className="e1eebb6a1e spacing--4x question-title">
                            Does this recipient have the same address as your
                            property?
                          </div>
                          <fieldset className="a53cbfa6de ac9267e216">
                            <div className="aca0ade214 aaf30230d9 cd2e7d62b0">
                            <div className="a53cbfa6de ac9267e216 ad8af0911d">
                              <label
                                htmlFor=":r6c:"
                                className="aca0ade214 aaf30230d9 c2931f4182 d79e71457a f8208e9f83"
                              >
                                <input
                                  className="c907c67d20"
                                  type="radio"
                                  value="Yes"
                                  checked={same === "Yes"}
                                  onChange={handleOptionChange1}
                                />
                                <span className="aaee4e7cd3 e7a57abb1e">
                                  <div className="a53cbfa6de e6208ee469">
                                  Yes
                                  </div>
                                </span>
                              </label>
                            </div>
                            <div className="a53cbfa6de ac9267e216 ad8af0911d">
                              <label
                                htmlFor=":r6c:"
                                className="aca0ade214 aaf30230d9 c2931f4182 d79e71457a f8208e9f83"
                              >
                                <input
                                  className="c907c67d20"
                                  type="radio"
                                  value="No"
                                  checked={same === "No"}
                                  onChange={handleOptionChange1}
                                />
                                <span className="aaee4e7cd3 e7a57abb1e">
                                  <div className="a53cbfa6de e6208ee469">
                                  No
                                  </div>
                                </span>
                              </label>
                            </div>
                            </div>
                            {same === "No" ?    <div class="b817090550 b736e9e3f4">
                          <div class="a53cbfa6de spacing--4x">
                            We may send a letter to confirm the location of your
                            property, so make sure that the address is correct –
                            it’s difficult to make changes to it later.
                          </div>
                          <div class="a53cbfa6de ac9267e216 form-group">
                            <label
                              for="cc1"
                              class="a53cbfa6de e6208ee469 f555271986"
                            >
                              <span>
                                <span class="ccb65902b2">Country/region</span>
                              </span>
                            </label>
                            <div class="cabad3c686 aff44befa2">
                              <select
                                class="ebf4591c8e country-select-for-e2e-tests"
                                name="cc1"
                                id="cc1"
                                value={country}
                                onChange={(e) => setcountry(e.target.value)}
                              >
                                <option value="">Select country/region</option>

                                <option value="India" data-key="in">
                                  India
                                </option>
                              </select>
                              <span class="ad2c299542">
                                <span
                                  class="fcd9eec8fb cab1c196b5 c2cc050fb8 f64eb5d122"
                                  aria-hidden="true"
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 24 24"
                                  >
                                    <path d="M19.2681 8.91288C19.2687 9.03226 19.2454 9.15054 19.1996 9.26079C19.1539 9.37105 19.0865 9.47105 19.0016 9.55493L12.9445 15.612C12.8211 15.7367 12.6738 15.8353 12.5115 15.9018C12.3491 15.9684 12.1751 16.0017 11.9996 15.9997C11.6491 16.0075 11.3096 15.877 11.0547 15.6362L4.99764 9.57916C4.8275 9.40881 4.73193 9.17788 4.73193 8.93711C4.73193 8.69634 4.8275 8.46542 4.99764 8.29506C5.08117 8.20935 5.18101 8.14122 5.29128 8.09471C5.40155 8.04819 5.52002 8.02423 5.6397 8.02423C5.75937 8.02423 5.87784 8.04819 5.98811 8.09471C6.09838 8.14122 6.19822 8.20935 6.28175 8.29506L11.9996 13.9887L17.7175 8.27084C17.801 8.18512 17.9009 8.11699 18.0111 8.07048C18.1214 8.02396 18.2399 8 18.3596 8C18.4792 8 18.5977 8.02396 18.708 8.07048C18.8182 8.11699 18.9181 8.18512 19.0016 8.27084C19.0871 8.3543 19.1548 8.45422 19.2006 8.56457C19.2464 8.67492 19.2694 8.79341 19.2681 8.91288Z"></path>
                                  </svg>
                                </span>
                              </span>
                            </div>
                          </div>
                         
                          <form autocomplete="off" class="form-group">
                            <div
                              class="autocomplete-suggestions-dropdown"
                              data-testid="autocomplete_container"
                            >
                              <div class="a53cbfa6de ac9267e216 a20293ec70">
                                <label
                                  for="address-autocomplete"
                                  class="a53cbfa6de e6208ee469 f555271986"
                                >
                                  <span>
                                    <span class="ccb65902b2">
                                      Street name and house number
                                    </span>
                                  </span>
                                </label>
                                <div class="b9b84f4305">
                                  <div class="e000754250">
                                    <input
                                      class="eb46370fe1"
                                      placeholder="Start typing your address"
                                      value={streetname}
                                      onChange={(e) => setstreetname(e.target.value.replace(/,/g, ''))}
                                    />
                                    <div class="e7e5251f68"></div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </form>
                          <div class="a53cbfa6de ac9267e216 form-group a20293ec70">
                            <label
                              for=":r1u:"
                              class="a53cbfa6de e6208ee469 f555271986"
                            >
                              <span>
                                <span class="ccb65902b2">City</span>
                              </span>
                            </label>
                            <div class="b9b84f4305">
                              <div class="e000754250">
                                <input
                                  name="city_name"
                                  class="eb46370fe1"
                                  value={city}
                                  onChange={(e) => setcity(e.target.value)}
                                />
                                <div class="e7e5251f68"></div>
                              </div>
                            </div>
                          </div>
                          {/* <div class="spacing--6x">
                            <button
                              data-testid="add_apartment_or_floor_link"
                              type="button"
                              class="a83ed08757 f88a5204c2 b98133fb50"
                            >
                              <span>
                                Add apartment or floor number (optional)
                              </span>
                            </button>
                          </div> */}
                          <div class="a53cbfa6de ac9267e216 form-group a20293ec70">
                            <label
                              for=":r24:"
                              class="a53cbfa6de e6208ee469 f555271986"
                            >
                              <span>
                                <span class="ccb65902b2">
                                  State
                                  {/* <button data-testid="remove_apartment_or_floor_link" type="button" class="a83ed08757 f88a5204c2 b98133fb50"><span>Remove</span></button> */}
                                </span>
                              </span>
                            </label>
                            <div class="b9b84f4305">
                              <div class="e000754250">
                                <input
                                  name="address_supplement"
                                  class="eb46370fe1"
                                  placeholder="State"
                                  value={states}
                                  onChange={(e) => setstates(e.target.value)}
                                />
                                <div class="e7e5251f68"></div>
                              </div>
                            </div>
                          </div>
                          <div class="a53cbfa6de ac9267e216 form-group manual-address-form__postcode a20293ec70">
                            <label
                              for=":r1t:"
                              class="a53cbfa6de e6208ee469 f555271986"
                            >
                              <span>
                                <span class="ccb65902b2">Pin Code</span>
                              </span>
                            </label>
                            <div class="b9b84f4305">
                              <div class="e000754250">
                                <input
                                  name="zipcode"
                                  type="number" onKeyDown={handleKeyDown}
                                  maxLength={6}
                                  class="eb46370fe1"
                                  value={pincode}
                                  onChange={(e) => setpincode(e.target.value)}
                                />
                                <div class="e7e5251f68"></div>
                              </div>
                            </div>
                          </div>

                        
                        </div> : ""}
                          </fieldset>
                          <div className="spacing--6x" />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="form-cta">
                    <div>
                      <div className="form-buttons-container">
                           <div className="form-buttons-container--secondary" onClick={()=>window.history.back()}>
                          <button
                            aria-label="Back to previous step"
                            data-testid="FormButtonSecondary"
                            type="button"
                            className="a83ed08757 c21c56c305 bf0537ecb5 d691166b09 f671049264 d2529514af af7297d90d"
                          >
                            <span className="eedba9e88a">
                              <span
                                className="fcd9eec8fb c2cc050fb8"
                                aria-hidden="true"
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 24 24"
                                  data-rtl-flip="true"
                                >
                                  <path d="M15.0871 19.2361C14.9677 19.2367 14.8495 19.2134 14.7392 19.1676C14.629 19.1219 14.5289 19.0546 14.4451 18.9696L8.38799 12.9125C8.26332 12.7891 8.16475 12.6418 8.09815 12.4795C8.03156 12.3172 7.99829 12.1431 8.00034 11.9676C7.99246 11.6171 8.12303 11.2776 8.36376 11.0227L14.4208 4.96566C14.5912 4.79552 14.8221 4.69995 15.0629 4.69995C15.3037 4.69995 15.5346 4.79552 15.7049 4.96566C15.7907 5.04918 15.8588 5.14902 15.9053 5.25929C15.9518 5.36957 15.9758 5.48803 15.9758 5.60771C15.9758 5.72739 15.9518 5.84586 15.9053 5.95613C15.8588 6.0664 15.7907 6.16624 15.7049 6.24976L10.0113 11.9676L15.7292 17.6855C15.8149 17.769 15.883 17.8689 15.9295 17.9792C15.976 18.0894 16 18.2079 16 18.3276C16 18.4473 15.976 18.5657 15.9295 18.676C15.883 18.7863 15.8149 18.8861 15.7292 18.9696C15.6457 19.0551 15.5458 19.1228 15.4354 19.1686C15.3251 19.2144 15.2066 19.2374 15.0871 19.2361Z" />
                                </svg>
                              </span>
                            </span>
                          </button>
                        </div>
                        <div className="form-buttons-container--primary" onClick={HandleClick
                                  }>
                          <button
                            aria-label="Continue"
                            data-testid="FormButtonPrimary-enabled"
                            type="button"
                            className="a83ed08757 c21c56c305 a4c1805887 f671049264 d2529514af c082d89982"
                          >
                            <span className="e4adce92df">Continue</span>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            id="automation_id_flow_Segmentation_Flow"
            style={{ display: "none" }}
          ></div>
          <div></div>
        </div>
      </div>
      <img
        alt=""
        height="1"
        width="1"
        style={{ display: "none" }}
        src="https://booking.com/track_join_app_landing?progress_status=2"
      />
    </div>
  );
};

export default HotelInvoice;
