import React from "react";
import "./property.css";
import Apartment from "../../assets/apartments.png"
import Homestay from "../../assets/homestay.png"
import Hotel from "../../assets/hotel.png"

import Tent from "../../assets/tent.png"
import Holiday from "../../assets/holiday.png"
import travel from "../../assets/travel.png"

import VendorHeader from "./Vendorheader";

const Property = () => {
  const vendor = JSON.parse(sessionStorage.getItem("vendor"));
  if (!vendor) {
    alert("Please Login");
    window.location.assign("/signin");
  }

  return (
    <div
      data-capla-component="b-registration-joinapp15min-fe/"
      data-capla-namespace="b-registration-joinapp15min-feaYGUODfA"
    >
      <div id="app-container">
        <div class="error-popup-container"></div>
        <div class="application">
          <VendorHeader />
          <div class="error-popup-container"></div>
          <div class="page-container">
            <div class="screens screens-animation-direction-backward">
              <div
                class="height-100 screen-change-enter-done"
                id="automation_id_screen_container_Category"
              >
                <div class="ms-container ms-container--full-width">
                  <div class="form-container">
                    <div class="ffb9c3d6a3 ab0db388cf a8e45fbcef spacing--6x"></div>
                    <div class="f2cf178bcd screen-main-header d991e1216a">
                      <div class="f6431b446c e6208ee469 d0caee4251 screen-main-header__title">
                        <font style={{ verticalAlign: "inherit" }}>
                          <font style={{ verticalAlign: "inherit" }}>
                            List your property on Booking-Oda.com and start
                            welcoming guests as soon as possible!
                          </font>
                        </font>
                      </div>
                    </div>
                    <div class="bcdcb105b3 screen-main-sub-header">
                      <font style={{ verticalAlign: "inherit" }}>
                        <font style={{ verticalAlign: "inherit" }}>
                          To get started, choose the type of property you want
                          to list on Booking-Oda.com
                        </font>
                      </font>
                    </div>
                    <div class="ffb9c3d6a3 category-screen-container wide-container spacing--4x">
                      {/* <div
                        class="category-card-group clearfix category-card-group--quick-start"
                        data-testid="category_card_group_container"
                      >
                        <span
                          class="custom-badge category-card-group__badge custom-badge--constructive"
                          data-testid="badge_with_icon"
                        >
                          <span
                            class="fcd9eec8fb d24fc26e73"
                            aria-hidden="true"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 128 128"
                            >
                              <path d="M93.94 79.48a4 4 0 0 1-2.83-1.17L80.27 67.47a4.002 4.002 0 1 1 5.66-5.66l7.58 7.58L107 51.4a4 4 0 1 1 6.4 4.8L97.14 77.88a4 4 0 0 1-2.92 1.59zM128 63.5C128 46.103 113.897 32 96.5 32S65 46.103 65 63.5 79.103 95 96.5 95c17.39-.016 31.483-14.11 31.5-31.5zm-8 0C120 76.479 109.479 87 96.5 87S73 76.479 73 63.5 83.521 40 96.5 40c12.974.011 23.489 10.526 23.5 23.5zm-60.75-16a4 4 0 0 0 -4-4H4a4 4 0 0 0 0 8h51.25a4 4 0 0 0 4-3.98zm0 17.06a4 4 0 0 0-4-4h-35a4 4 0 0 0 0 8h35a4 4 0 0 0 4-3.98zm0 18.09a4 4 0 0 0-4-4H38a4 4 0 0 0 0 8h17.25a4 4 0 0 0 4-3.98z"></path>
                            </svg>
                          </span>{" "}
                          <font style={{verticalAlign: "inherit"}}>
                            <font style={{verticalAlign: "inherit"}}>
                              Ouick start
                            </font>
                          </font>
                        </span>
                        <div
                          class="category-card"
                          id="automation_id_choose_category_quick_start"
                          role="checkbox"
                          aria-checked="false"
                          tabindex="0"
                          data-testid="category_card_container_quick_start"
                        >
                          <div
                            class="c82435a4b8 a178069f51 a6ae3c2b40 a18aeea94d d794b7a0f7 f53e278e95 card"
                            // style="--bui_box_padding--s: 4;"
                          >
                            <div class="category-card__content">
                              <div class="category-card__icon spacing--2x">
                                <img
                                  src={Apartment}
                                  width="50"
                                  height="50"
                                  alt=""
                                />
                              </div>
                              <div class="category-card__body">
                                <div class="e1eebb6a1e category-card__header">
                                  <font style={{verticalAlign: "inherit"}}>
                                    <font style={{verticalAlign: "inherit"}}>
                                      Service apartment
                                    </font>
                                  </font>
                                </div>
                                <div class="abf093bdfe category-card__description">
                                  <font style={{verticalAlign: "inherit"}}>
                                    <font style={{verticalAlign: "inherit"}}>
                                      Furnished and self-catering property where
                                      guests rent the entire space.
                                    </font>
                                  </font>
                                </div>
                              </div>
                              <div class="category-card__arrow-container">
                                <span
                                  class="fcd9eec8fb category-card__arrow-icon dba56f70c6 b3ef400590"
                                  aria-hidden="true"
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 24 24"
                                    data-rtl-flip="true"
                                  >
                                    <path d="M8.91289 19.2361C9.03226 19.2367 9.15054 19.2134 9.2608 19.1676C9.37105 19.1219 9.47105 19.0546 9.55493 18.9696L15.612 12.9125C15.7367 12.7891 15.8353 12.6418 15.9018 12.4795C15.9684 12.3172 16.0017 12.1431 15.9997 11.9676C16.0075 11.6171 15.877 11.2776 15.6362 11.0227L9.57916 4.96566C9.40881 4.79552 9.17788 4.69995 8.93711 4.69995C8.69634 4.69995 8.46542 4.79552 8.29506 4.96566C8.20935 5.04918 8.14122 5.14902 8.09471 5.25929C8.04819 5.36957 8.02423 5.48803 8.02423 5.60771C8.02423 5.72739 8.04819 5.84586 8.09471 5.95613C8.14122 6.0664 8.20935 6.16624 8.29506 6.24976L13.9887 11.9676L8.27084 17.6855C8.18512 17.769 8.117 17.8689 8.07048 17.9792C8.02396 18.0894 8 18.2079 8 18.3276C8 18.4473 8.02396 18.5657 8.07048 18.676C8.117 18.7863 8.18512 18.8861 8.27084 18.9696C8.3543 19.0551 8.45423 19.1228 8.56458 19.1686C8.67493 19.2144 8.79341 19.2374 8.91289 19.2361Z"></path>
                                  </svg>
                                </span>
                              </div>
                              <div class="category-card__btn" onClick={()=>window.location.assign('/apartmenttype')}>
                                <span
                                  id="automation_id_choose_category_quick_start_btn"
                                  data-testid="category-card-btn"
                                  class="a83ed08757 c21c56c305 a4c1805887 ab98298258 d2529514af c082d89982"
                                >
                                  <span class="e4adce92df">
                                    <font style={{verticalAlign: "inherit"}}>
                                      <font style={{verticalAlign: "inherit"}}>
                                        list your property
                                      </font>
                                    </font>
                                  </span>
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div> */}
                      <div
                        class="category-card-group clearfix"
                        data-testid="category_card_group_container"
                      >
                        <div
                          class=" clearfix category-card-group--quick-start"

                        >
                          <span
                            class="custom-badge category-card-group__badge custom-badge--constructive"
                            data-testid="badge_with_icon"
                          >
                            <span
                              class="fcd9eec8fb d24fc26e73"
                              aria-hidden="true"
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 128 128"
                              >
                                <path d="M93.94 79.48a4 4 0 0 1-2.83-1.17L80.27 67.47a4.002 4.002 0 1 1 5.66-5.66l7.58 7.58L107 51.4a4 4 0 1 1 6.4 4.8L97.14 77.88a4 4 0 0 1-2.92 1.59zM128 63.5C128 46.103 113.897 32 96.5 32S65 46.103 65 63.5 79.103 95 96.5 95c17.39-.016 31.483-14.11 31.5-31.5zm-8 0C120 76.479 109.479 87 96.5 87S73 76.479 73 63.5 83.521 40 96.5 40c12.974.011 23.489 10.526 23.5 23.5zm-60.75-16a4 4 0 0 0 -4-4H4a4 4 0 0 0 0 8h51.25a4 4 0 0 0 4-3.98zm0 17.06a4 4 0 0 0-4-4h-35a4 4 0 0 0 0 8h35a4 4 0 0 0 4-3.98zm0 18.09a4 4 0 0 0-4-4H38a4 4 0 0 0 0 8h17.25a4 4 0 0 0 4-3.98z"></path>
                              </svg>
                            </span>{" "}
                            <font style={{ verticalAlign: "inherit" }}>
                              <font style={{ verticalAlign: "inherit" }}>
                                Ouick start
                              </font>
                            </font>
                          </span>
                          <div
                            class="category-card"
                            id="automation_id_choose_category_apt"
                            role="checkbox"
                            aria-checked="false"
                            tabindex="0"
                            data-testid="category_card_container_apt"
                          >
                            <div
                              class="c82435a4b8 a178069f51 a6ae3c2b40 a18aeea94d d794b7a0f7 f53e278e95 card"
                            // style="--bui_box_padding--s: 4;"
                            >
                              <div class="category-card__content">
                                <div class="category-card__icon spacing--2x">
                                  <img
                                    src={Apartment}

                                    width="50"
                                    height="50"
                                    alt=""
                                  />
                                </div>
                                <div class="category-card__body">
                                  <div class="e1eebb6a1e category-card__header">
                                    <font style={{ verticalAlign: "inherit" }}>
                                      <font style={{ verticalAlign: "inherit" }}>
                                        Service apartment
                                      </font>
                                    </font>
                                  </div>
                                  <div class="abf093bdfe category-card__description">
                                    <font style={{ verticalAlign: "inherit" }}>
                                      <font style={{ verticalAlign: "inherit" }}>
                                        Furnished and self-catering property where
                                        guests rent the entire space.
                                      </font>
                                    </font>
                                  </div>
                                </div>
                                <div class="category-card__arrow-container">
                                  <span
                                    class="fcd9eec8fb category-card__arrow-icon dba56f70c6 b3ef400590"
                                    aria-hidden="true"
                                  >
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      viewBox="0 0 24 24"
                                      data-rtl-flip="true"
                                    >
                                      <path d="M8.91289 19.2361C9.03226 19.2367 9.15054 19.2134 9.2608 19.1676C9.37105 19.1219 9.47105 19.0546 9.55493 18.9696L15.612 12.9125C15.7367 12.7891 15.8353 12.6418 15.9018 12.4795C15.9684 12.3172 16.0017 12.1431 15.9997 11.9676C16.0075 11.6171 15.877 11.2776 15.6362 11.0227L9.57916 4.96566C9.40881 4.79552 9.17788 4.69995 8.93711 4.69995C8.69634 4.69995 8.46542 4.79552 8.29506 4.96566C8.20935 5.04918 8.14122 5.14902 8.09471 5.25929C8.04819 5.36957 8.02423 5.48803 8.02423 5.60771C8.02423 5.72739 8.04819 5.84586 8.09471 5.95613C8.14122 6.0664 8.20935 6.16624 8.29506 6.24976L13.9887 11.9676L8.27084 17.6855C8.18512 17.769 8.117 17.8689 8.07048 17.9792C8.02396 18.0894 8 18.2079 8 18.3276C8 18.4473 8.02396 18.5657 8.07048 18.676C8.117 18.7863 8.18512 18.8861 8.27084 18.9696C8.3543 19.0551 8.45423 19.1228 8.56458 19.1686C8.67493 19.2144 8.79341 19.2374 8.91289 19.2361Z"></path>
                                    </svg>
                                  </span>
                                </div>
                                <div class="category-card__btn" onClick={() => window.location.assign('/apartmenttype')}>
                                  <span
                                    id="automation_id_choose_category_apt_btn"
                                    data-testid="category-card-btn"
                                    class="a83ed08757 c21c56c305 a4c1805887 ab98298258 d2529514af c082d89982"
                                  >
                                    <span class="e4adce92df">
                                      <font style={{ verticalAlign: "inherit" }}>
                                        <font style={{ verticalAlign: "inherit" }}>
                                          list your property
                                        </font>
                                      </font>
                                    </span>
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          class="category-card"
                          id="automation_id_choose_category_apt"
                          role="checkbox"
                          aria-checked="false"
                          tabindex="0"
                          data-testid="category_card_container_apt"
                        >
                          <div
                            class="c82435a4b8 a178069f51 a6ae3c2b40 a18aeea94d d794b7a0f7 f53e278e95 card"
                          // style="--bui_box_padding--s: 4;"
                          >
                            <div class="category-card__content">
                              <div class="category-card__icon spacing--2x">
                                <img
                                  src={Homestay}
                                  width="50"
                                  height="50"
                                  alt=""
                                />
                              </div>
                              <div class="category-card__body">
                                <div class="e1eebb6a1e category-card__header">
                                  <font style={{ verticalAlign: "inherit" }}>
                                    <font style={{ verticalAlign: "inherit" }}>
                                      Home Stays
                                    </font>
                                  </font>
                                </div>
                                <div class="abf093bdfe category-card__description">
                                  <font style={{ verticalAlign: "inherit" }}>
                                    <font style={{ verticalAlign: "inherit" }}>
                                      Properties like Apartments, Holiday Homes,
                                      Villas etc.
                                    </font>
                                  </font>
                                </div>
                              </div>
                              <div class="category-card__arrow-container">
                                <span
                                  class="fcd9eec8fb category-card__arrow-icon dba56f70c6 b3ef400590"
                                  aria-hidden="true"
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 24 24"
                                    data-rtl-flip="true"
                                  >
                                    <path d="M8.91289 19.2361C9.03226 19.2367 9.15054 19.2134 9.2608 19.1676C9.37105 19.1219 9.47105 19.0546 9.55493 18.9696L15.612 12.9125C15.7367 12.7891 15.8353 12.6418 15.9018 12.4795C15.9684 12.3172 16.0017 12.1431 15.9997 11.9676C16.0075 11.6171 15.877 11.2776 15.6362 11.0227L9.57916 4.96566C9.40881 4.79552 9.17788 4.69995 8.93711 4.69995C8.69634 4.69995 8.46542 4.79552 8.29506 4.96566C8.20935 5.04918 8.14122 5.14902 8.09471 5.25929C8.04819 5.36957 8.02423 5.48803 8.02423 5.60771C8.02423 5.72739 8.04819 5.84586 8.09471 5.95613C8.14122 6.0664 8.20935 6.16624 8.29506 6.24976L13.9887 11.9676L8.27084 17.6855C8.18512 17.769 8.117 17.8689 8.07048 17.9792C8.02396 18.0894 8 18.2079 8 18.3276C8 18.4473 8.02396 18.5657 8.07048 18.676C8.117 18.7863 8.18512 18.8861 8.27084 18.9696C8.3543 19.0551 8.45423 19.1228 8.56458 19.1686C8.67493 19.2144 8.79341 19.2374 8.91289 19.2361Z"></path>
                                  </svg>
                                </span>
                              </div>
                              <div class="category-card__btn" onClick={() => window.location.assign('/hometype')}>
                                <span
                                  id="automation_id_choose_category_apt_btn"
                                  data-testid="category-card-btn"
                                  class="a83ed08757 c21c56c305 a4c1805887 ab98298258 d2529514af c082d89982"
                                >
                                  <span class="e4adce92df">
                                    <font style={{ verticalAlign: "inherit" }}>
                                      <font style={{ verticalAlign: "inherit" }}>
                                        list your property
                                      </font>
                                    </font>
                                  </span>
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          class="category-card"
                          id="automation_id_choose_category_hotel"
                          role="checkbox"
                          aria-checked="false"
                          tabindex="0"
                          data-testid="category_card_container_hotel"
                        >
                          <div
                            class="c82435a4b8 a178069f51 a6ae3c2b40 a18aeea94d d794b7a0f7 f53e278e95 card"
                          // style="--bui_box_padding--s: 4;"
                          >
                            <div class="category-card__content">
                              <div class="category-card__icon spacing--2x">
                                <img
                                  src={Hotel}
                                  width="50"
                                  height="50"
                                  alt=""
                                />
                              </div>
                              <div class="category-card__body">
                                <div class="e1eebb6a1e category-card__header">
                                  <font style={{ verticalAlign: "inherit" }}>
                                    <font style={{ verticalAlign: "inherit" }}>
                                      Hotels, Resorts, Guest house and more
                                    </font>
                                  </font>
                                </div>
                                <div class="abf093bdfe category-card__description">
                                  <font style={{ verticalAlign: "inherit" }}>
                                    <font style={{ verticalAlign: "inherit" }}>
                                      Properties such as hotels, B&amp;B, guest
                                      houses, hostels, etc.
                                    </font>
                                  </font>
                                </div>
                              </div>
                              <div class="category-card__arrow-container">
                                <span
                                  class="fcd9eec8fb category-card__arrow-icon dba56f70c6 b3ef400590"
                                  aria-hidden="true"
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 24 24"
                                    data-rtl-flip="true"
                                  >
                                    <path d="M8.91289 19.2361C9.03226 19.2367 9.15054 19.2134 9.2608 19.1676C9.37105 19.1219 9.47105 19.0546 9.55493 18.9696L15.612 12.9125C15.7367 12.7891 15.8353 12.6418 15.9018 12.4795C15.9684 12.3172 16.0017 12.1431 15.9997 11.9676C16.0075 11.6171 15.877 11.2776 15.6362 11.0227L9.57916 4.96566C9.40881 4.79552 9.17788 4.69995 8.93711 4.69995C8.69634 4.69995 8.46542 4.79552 8.29506 4.96566C8.20935 5.04918 8.14122 5.14902 8.09471 5.25929C8.04819 5.36957 8.02423 5.48803 8.02423 5.60771C8.02423 5.72739 8.04819 5.84586 8.09471 5.95613C8.14122 6.0664 8.20935 6.16624 8.29506 6.24976L13.9887 11.9676L8.27084 17.6855C8.18512 17.769 8.117 17.8689 8.07048 17.9792C8.02396 18.0894 8 18.2079 8 18.3276C8 18.4473 8.02396 18.5657 8.07048 18.676C8.117 18.7863 8.18512 18.8861 8.27084 18.9696C8.3543 19.0551 8.45423 19.1228 8.56458 19.1686C8.67493 19.2144 8.79341 19.2374 8.91289 19.2361Z"></path>
                                  </svg>
                                </span>
                              </div>
                              <div class="category-card__btn" onClick={() => window.location.assign('/hoteltypess')}>
                                <span
                                  id="automation_id_choose_category_hotel_btn"
                                  data-testid="category-card-btn"
                                  class="a83ed08757 c21c56c305 a4c1805887 ab98298258 d2529514af c082d89982"
                                >
                                  <span class="e4adce92df">
                                    <font style={{ verticalAlign: "inherit" }}>
                                      <font style={{ verticalAlign: "inherit" }}>
                                        list your property
                                      </font>
                                    </font>
                                  </span>
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          class="category-card"
                          id="automation_id_choose_category_alternative"
                          role="checkbox"
                          aria-checked="false"
                          tabindex="0"
                          data-testid="category_card_container_alternative"
                        >
                          <div
                            class="c82435a4b8 a178069f51 a6ae3c2b40 a18aeea94d d794b7a0f7 f53e278e95 card"
                            style={{ "--bui_box_padding--s": "4" }}
                          >
                            <div class="category-card__content">
                              <div class="category-card__icon spacing--2x">
                                <img
                                  src={Tent}
                                  width="50"
                                  height="50"
                                  alt=""
                                />
                              </div>
                              <div class="category-card__body">
                                <div class="e1eebb6a1e category-card__header">
                                  <font style={{ verticalAlign: "inherit" }}>
                                    <font style={{ verticalAlign: "inherit" }}>
                                      Alternate Property
                                    </font>
                                  </font>
                                </div>
                                <div class="abf093bdfe category-card__description">
                                  <font style={{ verticalAlign: "inherit" }}>
                                    <font style={{ verticalAlign: "inherit" }}>
                                      Properties such as Boat-house, camp-sites,
                                      luxury tents, and more
                                    </font>
                                  </font>
                                </div>
                              </div>
                              <div class="category-card__arrow-container">
                                <span
                                  class="fcd9eec8fb category-card__arrow-icon dba56f70c6 b3ef400590"
                                  aria-hidden="true"
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 24 24"
                                    data-rtl-flip="true"
                                  >
                                    <path d="M8.91289 19.2361C9.03226 19.2367 9.15054 19.2134 9.2608 19.1676C9.37105 19.1219 9.47105 19.0546 9.55493 18.9696L15.612 12.9125C15.7367 12.7891 15.8353 12.6418 15.9018 12.4795C15.9684 12.3172 16.0017 12.1431 15.9997 11.9676C16.0075 11.6171 15.877 11.2776 15.6362 11.0227L9.57916 4.96566C9.40881 4.79552 9.17788 4.69995 8.93711 4.69995C8.69634 4.69995 8.46542 4.79552 8.29506 4.96566C8.20935 5.04918 8.14122 5.14902 8.09471 5.25929C8.04819 5.36957 8.02423 5.48803 8.02423 5.60771C8.02423 5.72739 8.04819 5.84586 8.09471 5.95613C8.14122 6.0664 8.20935 6.16624 8.29506 6.24976L13.9887 11.9676L8.27084 17.6855C8.18512 17.769 8.117 17.8689 8.07048 17.9792C8.02396 18.0894 8 18.2079 8 18.3276C8 18.4473 8.02396 18.5657 8.07048 18.676C8.117 18.7863 8.18512 18.8861 8.27084 18.9696C8.3543 19.0551 8.45423 19.1228 8.56458 19.1686C8.67493 19.2144 8.79341 19.2374 8.91289 19.2361Z"></path>
                                  </svg>
                                </span>
                              </div>
                              <div class="category-card__btn" onClick={() => window.location.assign('/alternative')}>
                                <span
                                  id="automation_id_choose_category_alternative_btn"
                                  data-testid="category-card-btn"
                                  class="a83ed08757 c21c56c305 a4c1805887 ab98298258 d2529514af c082d89982"
                                >
                                  <span class="e4adce92df">
                                    <font style={{ verticalAlign: "inherit" }}>
                                      <font style={{ verticalAlign: "inherit" }}>
                                        list your property
                                      </font>
                                    </font>
                                  </span>
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          class="category-card"
                          id="automation_id_choose_category_alternative"
                          role="checkbox"
                          aria-checked="false"
                          tabindex="0"
                          data-testid="category_card_container_alternative"
                        >
                          <div
                            class="c82435a4b8 a178069f51 a6ae3c2b40 a18aeea94d d794b7a0f7 f53e278e95 card"
                            style={{ "--bui_box_padding--s": "4" }}
                          >
                            <div class="category-card__content">
                              <div class="category-card__icon spacing--2x">
                                <img
                                  src={travel}
                                  width="50"
                                  height="50"
                                  alt=""
                                />
                              </div>
                              <div class="category-card__body">
                                <div class="e1eebb6a1e category-card__header">
                                  <font style={{ verticalAlign: "inherit" }}>
                                    <font style={{ verticalAlign: "inherit" }}>
                                      International Holidays Locations
                                    </font>
                                  </font>
                                </div>
                                <div class="abf093bdfe category-card__description">
                                  <font style={{ verticalAlign: "inherit" }}>
                                    <font style={{ verticalAlign: "inherit" }}>
                                      A vacation at an overseas destination lends you an opportunity to explore
                                    </font>
                                  </font>
                                </div>
                              </div>
                              <div class="category-card__arrow-container">
                                <span
                                  class="fcd9eec8fb category-card__arrow-icon dba56f70c6 b3ef400590"
                                  aria-hidden="true"
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 24 24"
                                    data-rtl-flip="true"
                                  >
                                    <path d="M8.91289 19.2361C9.03226 19.2367 9.15054 19.2134 9.2608 19.1676C9.37105 19.1219 9.47105 19.0546 9.55493 18.9696L15.612 12.9125C15.7367 12.7891 15.8353 12.6418 15.9018 12.4795C15.9684 12.3172 16.0017 12.1431 15.9997 11.9676C16.0075 11.6171 15.877 11.2776 15.6362 11.0227L9.57916 4.96566C9.40881 4.79552 9.17788 4.69995 8.93711 4.69995C8.69634 4.69995 8.46542 4.79552 8.29506 4.96566C8.20935 5.04918 8.14122 5.14902 8.09471 5.25929C8.04819 5.36957 8.02423 5.48803 8.02423 5.60771C8.02423 5.72739 8.04819 5.84586 8.09471 5.95613C8.14122 6.0664 8.20935 6.16624 8.29506 6.24976L13.9887 11.9676L8.27084 17.6855C8.18512 17.769 8.117 17.8689 8.07048 17.9792C8.02396 18.0894 8 18.2079 8 18.3276C8 18.4473 8.02396 18.5657 8.07048 18.676C8.117 18.7863 8.18512 18.8861 8.27084 18.9696C8.3543 19.0551 8.45423 19.1228 8.56458 19.1686C8.67493 19.2144 8.79341 19.2374 8.91289 19.2361Z"></path>
                                  </svg>
                                </span>
                              </div>
                              <div class="category-card__btn" onClick={() => window.location.assign('/internationalvacation')}>
                                <span
                                  id="automation_id_choose_category_alternative_btn"
                                  data-testid="category-card-btn"
                                  class="a83ed08757 c21c56c305 a4c1805887 ab98298258 d2529514af c082d89982"
                                >
                                  <span class="e4adce92df">
                                    <font style={{ verticalAlign: "inherit" }}>
                                      <font style={{ verticalAlign: "inherit" }}>
                                        list your property
                                      </font>
                                    </font>
                                  </span>
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          class="category-card"
                          id="automation_id_choose_category_alternative"
                          role="checkbox"
                          aria-checked="false"
                          tabindex="0"
                          data-testid="category_card_container_alternative"
                        >
                          <div
                            class="c82435a4b8 a178069f51 a6ae3c2b40 a18aeea94d d794b7a0f7 f53e278e95 card"
                            style={{ "--bui_box_padding--s": "4" }}
                          >
                            <div class="category-card__content">
                              <div class="category-card__icon spacing--2x">
                                <img
                                  src={Holiday}
                                  width="50"
                                  height="50"
                                  alt=""
                                />
                              </div>
                              <div class="category-card__body">
                                <div class="e1eebb6a1e category-card__header">
                                  <font style={{ verticalAlign: "inherit" }}>
                                    <font style={{ verticalAlign: "inherit" }}>
                                      Pilgrim Vacations
                                    </font>
                                  </font>
                                </div>
                                <div class="abf093bdfe category-card__description">
                                  <font style={{ verticalAlign: "inherit" }}>
                                    <font style={{ verticalAlign: "inherit" }}>
                                      Best Pilgrimage Tour Destinations in India
                                    </font>
                                  </font>
                                </div>
                              </div>
                              <div class="category-card__arrow-container">
                                <span
                                  class="fcd9eec8fb category-card__arrow-icon dba56f70c6 b3ef400590"
                                  aria-hidden="true"
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 24 24"
                                    data-rtl-flip="true"
                                  >
                                    <path d="M8.91289 19.2361C9.03226 19.2367 9.15054 19.2134 9.2608 19.1676C9.37105 19.1219 9.47105 19.0546 9.55493 18.9696L15.612 12.9125C15.7367 12.7891 15.8353 12.6418 15.9018 12.4795C15.9684 12.3172 16.0017 12.1431 15.9997 11.9676C16.0075 11.6171 15.877 11.2776 15.6362 11.0227L9.57916 4.96566C9.40881 4.79552 9.17788 4.69995 8.93711 4.69995C8.69634 4.69995 8.46542 4.79552 8.29506 4.96566C8.20935 5.04918 8.14122 5.14902 8.09471 5.25929C8.04819 5.36957 8.02423 5.48803 8.02423 5.60771C8.02423 5.72739 8.04819 5.84586 8.09471 5.95613C8.14122 6.0664 8.20935 6.16624 8.29506 6.24976L13.9887 11.9676L8.27084 17.6855C8.18512 17.769 8.117 17.8689 8.07048 17.9792C8.02396 18.0894 8 18.2079 8 18.3276C8 18.4473 8.02396 18.5657 8.07048 18.676C8.117 18.7863 8.18512 18.8861 8.27084 18.9696C8.3543 19.0551 8.45423 19.1228 8.56458 19.1686C8.67493 19.2144 8.79341 19.2374 8.91289 19.2361Z"></path>
                                  </svg>
                                </span>
                              </div>
                              <div class="category-card__btn" onClick={() => window.location.assign('/pilgrimvacation')}>
                                <span
                                  id="automation_id_choose_category_alternative_btn"
                                  data-testid="category-card-btn"
                                  class="a83ed08757 c21c56c305 a4c1805887 ab98298258 d2529514af c082d89982"
                                >
                                  <span class="e4adce92df">
                                    <font style={{ verticalAlign: "inherit" }}>
                                      <font style={{ verticalAlign: "inherit" }}>
                                        list your property
                                      </font>
                                    </font>
                                  </span>
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>

                      </div>
                    </div>
                  </div>
                  <div class="bcdcb105b3 screen-main-sub-header">
                    <font style={{ verticalAlign: "inherit" }}>
                      <font style={{
                        verticalAlign: "inherit", fontSize: "20px",

                        fontWeight: "bolder"
                      }}>
                        Thank you for being our Booking-Oda.com Vendor
                      </font>
                    </font>
                  </div>
                </div>

              </div>
            </div>
            <div
              id="automation_id_screen_id_category"
              style={{ display: "none" }}
            ></div>
          </div>
          <div
            id="automation_id_flow_Segmentation_Flow"
            style={{ display: "none" }}
          ></div>
          <div></div>
        </div>
      </div>
      <img
        alt=""
        height="1"
        width="1"
        style={{ display: "none" }}
        src="https://booking.com/track_join_app_landing?progress_status=2"
      />
    </div>
  );
};

export default Property;
