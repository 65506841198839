import React from "react";
import imageUrl from "../../assets/coming-soon.png";
import Navbar from "../navbar/Navbar";
import Footer from "../footer/Footer";
import { Map, GoogleApiWrapper, Marker } from "google-maps-react";
const API_KEY = "46a9246bebba16d42b36aac3fc3ba8af";
const HoneyMoonDetails = (props) => {
  const lat = 13.07876;
  const log = 77.53324;
  return (
    <>
      <Navbar />

      <div class="header-margin" style={{marginTop:"50px"}}></div>
      <div class="header-margin"></div>

      <section className="py-10 d-flex items-center bg-light-2">
        <div className="containerh">
          <div className="row y-gap-10 items-center justify-between">
            <div className="col-autoh">
              <div className="row x-gap-10 y-gap-5 items-center text-14 text-light-1">
                <div className="col-autoh">
                  <div className>Home</div>
                </div>
                <div className="col-autoh">
                  <div className>&gt;</div>
                </div>
                <div className="col-autoh">
                  <div className>London Hotels</div>
                </div>
                <div className="col-autoh">
                  <div className>&gt;</div>
                </div>
                <div className="col-autoh">
                  <div className="text-dark-1">
                    Great Northern Hotel, a Tribute Portfolio Hotel, London
                  </div>
                </div>
              </div>
            </div>
            <div className="col-autoh">
              <a href="#" className="text-14 text-blue-1 underline">
                All Hotel in London
              </a>
            </div>
          </div>
        </div>
      </section>
      <section className="pt-40">
        <div className="containerh">
          <div className="row y-gap-15 justify-between items-end">
            <div className="col-autoh">
              <h1 className="text-30 fw-600">
                Stonehenge, Windsor Castle, and Bath from London
              </h1>
              <div className="row x-gap-20 y-gap-20 items-center pt-10">
                <div className="col-autoh">
                  <div className="row x-gap-10 items-center">
                    <div className="col-autoh">
                      <div className="d-flex x-gap-5 items-center">
                        {/* <i className="icon-placeholder text-16 text-light-1" /> */}
                        <div className="text-15 text-light-1">
                          Greater London, United Kingdom
                        </div>
                      </div>
                    </div>
                  
                  </div>
                </div>
              </div>
            </div>
           
          </div>
        </div>
      </section>
      <section className="pt-40 js-pin-containerh">
        <div className="containerh">
          <div className="row y-gap-30">
            <div className="col-lg-8">
              <div
                className="relative d-flex justify-center overflow-hidden js-section-slider swiper-initialized swiper-horizontal swiper-pointer-events swiper-autoheight swiper-watch-progress swiper-backface-hidden"
                data-slider-cols="base-1"
                data-nav-prev="js-img-prev"
                data-nav-next="js-img-next"
              >
                <div
                  className="swiper-wrapper"
                  id="swiper-wrapper-93ba39f3d077e6b5"
                  aria-live="polite"
                  style={{
                    height: "430px",
                    transitionDuration: "0ms",
                    transform: "translate3d(-1460px, 0px, 0px)",
                  }}
                >
                  <div
                    className="swiper-slide"
                    role="group"
                    aria-label="1 / 4"
                    style={{ width: "730px" }}
                  >
                    <img
                      src="https://creativelayers.net/themes/gotrip-html/img/lists/tour/single/1.png"
                      alt="image"
                      className="rounded-4 col-12 h-full object-cover"
                    />
                  </div>
                  <div
                    className="swiper-slide swiper-slide-prev"
                    role="group"
                    aria-label="2 / 4"
                    style={{ width: "730px" }}
                  >
                    <img
                      src="https://creativelayers.net/themes/gotrip-html/img/gallery/1/2.png"
                      alt="image"
                      className="rounded-4 col-12 h-full object-cover"
                    />
                  </div>
                  <div
                    className="swiper-slide swiper-slide-visible swiper-slide-active"
                    role="group"
                    aria-label="3 / 4"
                    style={{ width: "730px" }}
                  >
                    <img
                      src="https://creativelayers.net/themes/gotrip-html/img/gallery/1/3.png"
                      alt="image"
                      className="rounded-4 col-12 h-full object-cover"
                    />
                  </div>
                  <div
                    className="swiper-slide swiper-slide-next"
                    role="group"
                    aria-label="4 / 4"
                    style={{ width: "730px" }}
                  >
                    <img
                      src="https://creativelayers.net/themes/gotrip-html/img/gallery/1/4.png"
                      alt="image"
                      className="rounded-4 col-12 h-full object-cover"
                    />
                  </div>
                </div>
              
                <div className="absolute h-full col-12 z-2 px-20 py-20 d-flex justify-end items-end">
                  <a
                    href="img/lists/tour/single/1.png"
                    className="buttonh -blue-1 px-24 py-15 bg-white text-dark-1 js-gallery"
                    data-gallery="gallery2"
                  >
                    See All 90 Photos
                  </a>
                  <a
                    href="img/gallery/1/2.png"
                    className="js-gallery"
                    data-gallery="gallery2"
                  />
                  <a
                    href="img/gallery/1/3.png"
                    className="js-gallery"
                    data-gallery="gallery2"
                  />
                  <a
                    href="img/gallery/1/4.png"
                    className="js-gallery"
                    data-gallery="gallery2"
                  />
                  <a
                    href="img/gallery/1/5.png"
                    className="js-gallery"
                    data-gallery="gallery2"
                  />
                </div>
                <span
                  className="swiper-notification"
                  aria-live="assertive"
                  aria-atomic="true"
                />
              </div>
              <h3 className="text-22 fw-500 mt-30">Tour snapshot</h3>
              <div className="row y-gap-30 justify-between pt-20">
                <div className="col-md-auto col-6">
                  <div className="d-flex">
                    {/* <i className="icon-clock text-22 text-blue-1 mr-10" /> */}
                    <div className="text-15 lh-15">
                      Duration:
                      <br /> 11h
                    </div>
                  </div>
                </div>
                <div className="col-md-auto col-6">
                  <div className="d-flex">
                    {/* <i className="icon-customer text-22 text-blue-1 mr-10" /> */}
                    <div className="text-15 lh-15">
                      Group size:
                      <br /> 52
                    </div>
                  </div>
                </div>
                <div className="col-md-auto col-6">
                  <div className="d-flex">
                    {/* <i className="icon-route text-22 text-blue-1 mr-10" /> */}
                    <div className="text-15 lh-15">
                      Near public
                      <br /> transportation
                    </div>
                  </div>
                </div>
                <div className="col-md-auto col-6">
                  <div className="d-flex">
                    {/* <i className="icon-access-denied text-22 text-blue-1 mr-10" /> */}
                    <div className="text-15 lh-15">
                      Free cancellation <br />
                      
                    </div>
                  </div>
                </div>
              </div>
              <div className="border-top-light mt-40 mb-40" />
              <div className="row x-gap-40 y-gap-40">
                <div className="col-12">
                  <h3 className="text-22 fw-500">Overview</h3>
                  <p className="text-dark-1 text-15 mt-20">
                    Unless you hire a car, visiting Stonehenge, Bath, and
                    Windsor Castle in one day is next to impossible. Designed
                    specifically for travelers with limited time in London, this
                    tour allows you to check off a range of southern England's
                    historical attractions in just one day by eliminating the
                    hassle of traveling between each one independently. Travel
                    by comfortable coach and witness your guide bring each
                    UNESCO World Heritage Site to life with commentary. Plus,
                    all admission tickets are included in the tour price.
                  </p>
                 
                </div>
                <div className="col-md-6">
                  <h5 className="text-16 fw-500">Available languages</h5>
                  <div className="text-15 mt-10">
                    German, Chinese, Portuguese, Japanese, English, Italian,
                    Chinese, French, Spanish
                  </div>
                </div>
                <div className="col-md-6">
                  <h5 className="text-16 fw-500">Cancellation policy</h5>
                  <div className="text-15 mt-10">
                    For a full refund, cancel at least 24 hours in advance of
                    the start date of the experience.
                  </div>
                </div>
                <div className="col-12">
                  <h5 className="text-16 fw-500">Highlights</h5>
                  <ul className="list-disc text-15 mt-10">
                    <li>
                      Travel between the UNESCO World Heritage sites aboard a
                      comfortable coach
                    </li>
                    <li>
                      Explore with a guide to delve deeper into the history
                    </li>
                    <li>
                      Great for history buffs and travelers with limited time
                    </li>
                  </ul>
                </div>
              </div>
              <div className="mt-40 border-top-light">
                <div className="row x-gap-40 y-gap-40 pt-40">
                  <div className="col-12">
                    <h3 className="text-22 fw-500">What's Included</h3>
                    <div className="row x-gap-40 y-gap-40 pt-20">
                      <div className="col-md-6">
                        <div className="text-dark-1 text-15">
                          {/* <i className="icon-check text-10 mr-10" /> Entry */}
                          ticket to Harry Potter Warner Bros Studio Tour London
                        </div>
                        <div className="text-dark-1 text-15">
                          {/* <i className="icon-check text-10 mr-10" /> Return */}
                          transfers in air-conditioned coach
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="text-dark-1 text-15">
                          {/* <i className="icon-close text-green-2 text-10 mr-10" />{" "} */}
                          Food and drinks
                        </div>
                        <div className="text-dark-1 text-15">
                          {/* <i className="icon-close text-green-2 text-10 mr-10" />{" "} */}
                          Gratuities
                        </div>
                        <div className="text-dark-1 text-15">
                          {/* <i className="icon-close text-green-2 text-10 mr-10" />{" "} */}
                          Digital guide available in 10 different languages at
                          additional cost
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4" style={{}}>
              <div
                data-scrollmagic-pin-spacer
                className="scrollmagic-pin-spacer"
                style={{
                  inset: "auto",
                  margin: "0px",
                  display: "flex",
                  position: "relative",
                  boxSizing: "content-box",
                  minHeight: "515px",
                  height: "auto",
                  paddingTop: "938px",
                  paddingBottom: "0px",
                }}
              >
                <div
                  className="d-flex justify-end js-pin-content"
                  style={{
                    position: "relative",
                    margin: "auto",
                    inset: "0px auto auto 0px",
                    boxSizing: "border-box",
                    width: "100%",
                  }}
                >
                  <div className="w-360 lg:w-full d-flex flex-column items-center">
                    <div className="px-30 py-30 rounded-4 border-light bg-white shadow-4">
                      <div className="text-14 text-light-1">
                        From{" "}
                        <span className="text-20 fw-500 text-dark-1 ml-5">
                          US$72
                        </span>
                      </div>
                      <div className="row y-gap-20 pt-30">
                        <div className="col-12">
                          <div className="searchMenu-date px-20 py-10 border-light rounded-4 -right js-form-dd js-calendar js-calendar-el">
                            <div data-x-dd-click="searchMenu-date">
                              <h4 className="text-15 fw-500 ls-2 lh-16">
                                Date
                              </h4>
                              <div className="capitalize text-15 text-light-1 ls-2 lh-16">
                                <span className="js-first-date">Wed 2 Mar</span>
                                -
                                <span className="js-last-date">Fri 11 Apr</span>
                              </div>
                            </div>
                            <div
                              className="searchMenu-date__field shadow-2"
                              data-x-dd="searchMenu-date"
                              data-x-dd-toggle="-is-active"
                            >
                              <div className="bg-white px-30 py-30 rounded-4">
                                <div className="elCalendar js-calendar-el-calendar">
                                  <div className="elCalendar__slider js-calendar-slider swiper-initialized swiper-horizontal swiper-pointer-events swiper-autoheight">
                                    <div
                                      className="swiper-wrapper"
                                      id="swiper-wrapper-a8ae4bf74a637ca9"
                                      aria-live="polite"
                                      style={{ height: "360px" }}
                                    >
                                      <div
                                        className="swiper-slide swiper-slide-active"
                                        role="group"
                                        aria-label="1 / 12"
                                        style={{ width: "420px" }}
                                      >
                                        <div className="capitalize text-28 fw-500 text-center mb-10">
                                          january 2024
                                        </div>
                                        <div className="elCalendar__month">
                                          <div className="elCalendar__header">
                                            <div className="elCalendar__header__sell">
                                              Sun
                                            </div>
                                            <div className="elCalendar__header__sell">
                                              Mon
                                            </div>
                                            <div className="elCalendar__header__sell">
                                              Tue
                                            </div>
                                            <div className="elCalendar__header__sell">
                                              Wed
                                            </div>
                                            <div className="elCalendar__header__sell">
                                              Thu
                                            </div>
                                            <div className="elCalendar__header__sell">
                                              Fri
                                            </div>
                                            <div className="elCalendar__header__sell">
                                              Sat
                                            </div>
                                          </div>
                                          <div className="elCalendar__body">
                                            <div
                                              data-index={1}
                                              data-week="Sun"
                                              data-month="jan"
                                              className="elCalendar__sell -dark"
                                            >
                                              <span className="js-date">
                                                31
                                              </span>
                                            </div>
                                            <div
                                              data-index={2}
                                              data-week="Mon"
                                              data-month="jan"
                                              className="elCalendar__sell"
                                            >
                                              <span className="js-date">1</span>
                                            </div>
                                            <div
                                              data-index={3}
                                              data-week="Tue"
                                              data-month="jan"
                                              className="elCalendar__sell"
                                            >
                                              <span className="js-date">2</span>
                                            </div>
                                            <div
                                              data-index={4}
                                              data-week="Wed"
                                              data-month="jan"
                                              className="elCalendar__sell"
                                            >
                                              <span className="js-date">3</span>
                                            </div>
                                            <div
                                              data-index={5}
                                              data-week="Thu"
                                              data-month="jan"
                                              className="elCalendar__sell"
                                            >
                                              <span className="js-date">4</span>
                                            </div>
                                            <div
                                              data-index={6}
                                              data-week="Fri"
                                              data-month="jan"
                                              className="elCalendar__sell"
                                            >
                                              <span className="js-date">5</span>
                                            </div>
                                            <div
                                              data-index={7}
                                              data-week="Sat"
                                              data-month="jan"
                                              className="elCalendar__sell"
                                            >
                                              <span className="js-date">6</span>
                                            </div>
                                            <div
                                              data-index={8}
                                              data-week="Sun"
                                              data-month="jan"
                                              className="elCalendar__sell"
                                            >
                                              <span className="js-date">7</span>
                                            </div>
                                            <div
                                              data-index={9}
                                              data-week="Mon"
                                              data-month="jan"
                                              className="elCalendar__sell"
                                            >
                                              <span className="js-date">8</span>
                                            </div>
                                            <div
                                              data-index={10}
                                              data-week="Tue"
                                              data-month="jan"
                                              className="elCalendar__sell"
                                            >
                                              <span className="js-date">9</span>
                                            </div>
                                            <div
                                              data-index={11}
                                              data-week="Wed"
                                              data-month="jan"
                                              className="elCalendar__sell"
                                            >
                                              <span className="js-date">
                                                10
                                              </span>
                                            </div>
                                            <div
                                              data-index={12}
                                              data-week="Thu"
                                              data-month="jan"
                                              className="elCalendar__sell"
                                            >
                                              <span className="js-date">
                                                11
                                              </span>
                                            </div>
                                            <div
                                              data-index={13}
                                              data-week="Fri"
                                              data-month="jan"
                                              className="elCalendar__sell"
                                            >
                                              <span className="js-date">
                                                12
                                              </span>
                                            </div>
                                            <div
                                              data-index={14}
                                              data-week="Sat"
                                              data-month="jan"
                                              className="elCalendar__sell"
                                            >
                                              <span className="js-date">
                                                13
                                              </span>
                                            </div>
                                            <div
                                              data-index={15}
                                              data-week="Sun"
                                              data-month="jan"
                                              className="elCalendar__sell"
                                            >
                                              <span className="js-date">
                                                14
                                              </span>
                                            </div>
                                            <div
                                              data-index={16}
                                              data-week="Mon"
                                              data-month="jan"
                                              className="elCalendar__sell"
                                            >
                                              <span className="js-date">
                                                15
                                              </span>
                                            </div>
                                            <div
                                              data-index={17}
                                              data-week="Tue"
                                              data-month="jan"
                                              className="elCalendar__sell"
                                            >
                                              <span className="js-date">
                                                16
                                              </span>
                                            </div>
                                            <div
                                              data-index={18}
                                              data-week="Wed"
                                              data-month="jan"
                                              className="elCalendar__sell"
                                            >
                                              <span className="js-date">
                                                17
                                              </span>
                                            </div>
                                            <div
                                              data-index={19}
                                              data-week="Thu"
                                              data-month="jan"
                                              className="elCalendar__sell"
                                            >
                                              <span className="js-date">
                                                18
                                              </span>
                                            </div>
                                            <div
                                              data-index={20}
                                              data-week="Fri"
                                              data-month="jan"
                                              className="elCalendar__sell"
                                            >
                                              <span className="js-date">
                                                19
                                              </span>
                                            </div>
                                            <div
                                              data-index={21}
                                              data-week="Sat"
                                              data-month="jan"
                                              className="elCalendar__sell"
                                            >
                                              <span className="js-date">
                                                20
                                              </span>
                                            </div>
                                            <div
                                              data-index={22}
                                              data-week="Sun"
                                              data-month="jan"
                                              className="elCalendar__sell"
                                            >
                                              <span className="js-date">
                                                21
                                              </span>
                                            </div>
                                            <div
                                              data-index={23}
                                              data-week="Mon"
                                              data-month="jan"
                                              className="elCalendar__sell"
                                            >
                                              <span className="js-date">
                                                22
                                              </span>
                                            </div>
                                            <div
                                              data-index={24}
                                              data-week="Tue"
                                              data-month="jan"
                                              className="elCalendar__sell"
                                            >
                                              <span className="js-date">
                                                23
                                              </span>
                                            </div>
                                            <div
                                              data-index={25}
                                              data-week="Wed"
                                              data-month="jan"
                                              className="elCalendar__sell"
                                            >
                                              <span className="js-date">
                                                24
                                              </span>
                                            </div>
                                            <div
                                              data-index={26}
                                              data-week="Thu"
                                              data-month="jan"
                                              className="elCalendar__sell"
                                            >
                                              <span className="js-date">
                                                25
                                              </span>
                                            </div>
                                            <div
                                              data-index={27}
                                              data-week="Fri"
                                              data-month="jan"
                                              className="elCalendar__sell"
                                            >
                                              <span className="js-date">
                                                26
                                              </span>
                                            </div>
                                            <div
                                              data-index={28}
                                              data-week="Sat"
                                              data-month="jan"
                                              className="elCalendar__sell"
                                            >
                                              <span className="js-date">
                                                27
                                              </span>
                                            </div>
                                            <div
                                              data-index={29}
                                              data-week="Sun"
                                              data-month="jan"
                                              className="elCalendar__sell"
                                            >
                                              <span className="js-date">
                                                28
                                              </span>
                                            </div>
                                            <div
                                              data-index={30}
                                              data-week="Mon"
                                              data-month="jan"
                                              className="elCalendar__sell"
                                            >
                                              <span className="js-date">
                                                29
                                              </span>
                                            </div>
                                            <div
                                              data-index={31}
                                              data-week="Tue"
                                              data-month="jan"
                                              className="elCalendar__sell"
                                            >
                                              <span className="js-date">
                                                30
                                              </span>
                                            </div>
                                            <div
                                              data-index={32}
                                              data-week="Wed"
                                              data-month="jan"
                                              className="elCalendar__sell"
                                            >
                                              <span className="js-date">
                                                31
                                              </span>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div
                                        className="swiper-slide swiper-slide-next"
                                        role="group"
                                        aria-label="2 / 12"
                                        style={{ width: "420px" }}
                                      >
                                        <div className="capitalize text-28 fw-500 text-center mb-10">
                                          february 2024
                                        </div>
                                        <div className="elCalendar__month">
                                          <div className="elCalendar__header">
                                            <div className="elCalendar__header__sell">
                                              Sun
                                            </div>
                                            <div className="elCalendar__header__sell">
                                              Mon
                                            </div>
                                            <div className="elCalendar__header__sell">
                                              Tue
                                            </div>
                                            <div className="elCalendar__header__sell">
                                              Wed
                                            </div>
                                            <div className="elCalendar__header__sell">
                                              Thu
                                            </div>
                                            <div className="elCalendar__header__sell">
                                              Fri
                                            </div>
                                            <div className="elCalendar__header__sell">
                                              Sat
                                            </div>
                                          </div>
                                          <div className="elCalendar__body">
                                            <div
                                              data-index={33}
                                              data-week="Sun"
                                              data-month="feb"
                                              className="elCalendar__sell -dark"
                                            >
                                              <span className="js-date">
                                                28
                                              </span>
                                            </div>
                                            <div
                                              data-index={34}
                                              data-week="Mon"
                                              data-month="feb"
                                              className="elCalendar__sell -dark"
                                            >
                                              <span className="js-date">
                                                29
                                              </span>
                                            </div>
                                            <div
                                              data-index={35}
                                              data-week="Tue"
                                              data-month="feb"
                                              className="elCalendar__sell -dark"
                                            >
                                              <span className="js-date">
                                                30
                                              </span>
                                            </div>
                                            <div
                                              data-index={36}
                                              data-week="Wed"
                                              data-month="feb"
                                              className="elCalendar__sell -dark"
                                            >
                                              <span className="js-date">
                                                31
                                              </span>
                                            </div>
                                            <div
                                              data-index={37}
                                              data-week="Thu"
                                              data-month="feb"
                                              className="elCalendar__sell"
                                            >
                                              <span className="js-date">1</span>
                                            </div>
                                            <div
                                              data-index={38}
                                              data-week="Fri"
                                              data-month="feb"
                                              className="elCalendar__sell"
                                            >
                                              <span className="js-date">2</span>
                                            </div>
                                            <div
                                              data-index={39}
                                              data-week="Sat"
                                              data-month="feb"
                                              className="elCalendar__sell"
                                            >
                                              <span className="js-date">3</span>
                                            </div>
                                            <div
                                              data-index={40}
                                              data-week="Sun"
                                              data-month="feb"
                                              className="elCalendar__sell"
                                            >
                                              <span className="js-date">4</span>
                                            </div>
                                            <div
                                              data-index={41}
                                              data-week="Mon"
                                              data-month="feb"
                                              className="elCalendar__sell"
                                            >
                                              <span className="js-date">5</span>
                                            </div>
                                            <div
                                              data-index={42}
                                              data-week="Tue"
                                              data-month="feb"
                                              className="elCalendar__sell"
                                            >
                                              <span className="js-date">6</span>
                                            </div>
                                            <div
                                              data-index={43}
                                              data-week="Wed"
                                              data-month="feb"
                                              className="elCalendar__sell"
                                            >
                                              <span className="js-date">7</span>
                                            </div>
                                            <div
                                              data-index={44}
                                              data-week="Thu"
                                              data-month="feb"
                                              className="elCalendar__sell"
                                            >
                                              <span className="js-date">8</span>
                                            </div>
                                            <div
                                              data-index={45}
                                              data-week="Fri"
                                              data-month="feb"
                                              className="elCalendar__sell"
                                            >
                                              <span className="js-date">9</span>
                                            </div>
                                            <div
                                              data-index={46}
                                              data-week="Sat"
                                              data-month="feb"
                                              className="elCalendar__sell"
                                            >
                                              <span className="js-date">
                                                10
                                              </span>
                                            </div>
                                            <div
                                              data-index={47}
                                              data-week="Sun"
                                              data-month="feb"
                                              className="elCalendar__sell"
                                            >
                                              <span className="js-date">
                                                11
                                              </span>
                                            </div>
                                            <div
                                              data-index={48}
                                              data-week="Mon"
                                              data-month="feb"
                                              className="elCalendar__sell"
                                            >
                                              <span className="js-date">
                                                12
                                              </span>
                                            </div>
                                            <div
                                              data-index={49}
                                              data-week="Tue"
                                              data-month="feb"
                                              className="elCalendar__sell"
                                            >
                                              <span className="js-date">
                                                13
                                              </span>
                                            </div>
                                            <div
                                              data-index={50}
                                              data-week="Wed"
                                              data-month="feb"
                                              className="elCalendar__sell"
                                            >
                                              <span className="js-date">
                                                14
                                              </span>
                                            </div>
                                            <div
                                              data-index={51}
                                              data-week="Thu"
                                              data-month="feb"
                                              className="elCalendar__sell"
                                            >
                                              <span className="js-date">
                                                15
                                              </span>
                                            </div>
                                            <div
                                              data-index={52}
                                              data-week="Fri"
                                              data-month="feb"
                                              className="elCalendar__sell"
                                            >
                                              <span className="js-date">
                                                16
                                              </span>
                                            </div>
                                            <div
                                              data-index={53}
                                              data-week="Sat"
                                              data-month="feb"
                                              className="elCalendar__sell"
                                            >
                                              <span className="js-date">
                                                17
                                              </span>
                                            </div>
                                            <div
                                              data-index={54}
                                              data-week="Sun"
                                              data-month="feb"
                                              className="elCalendar__sell"
                                            >
                                              <span className="js-date">
                                                18
                                              </span>
                                            </div>
                                            <div
                                              data-index={55}
                                              data-week="Mon"
                                              data-month="feb"
                                              className="elCalendar__sell"
                                            >
                                              <span className="js-date">
                                                19
                                              </span>
                                            </div>
                                            <div
                                              data-index={56}
                                              data-week="Tue"
                                              data-month="feb"
                                              className="elCalendar__sell"
                                            >
                                              <span className="js-date">
                                                20
                                              </span>
                                            </div>
                                            <div
                                              data-index={57}
                                              data-week="Wed"
                                              data-month="feb"
                                              className="elCalendar__sell"
                                            >
                                              <span className="js-date">
                                                21
                                              </span>
                                            </div>
                                            <div
                                              data-index={58}
                                              data-week="Thu"
                                              data-month="feb"
                                              className="elCalendar__sell"
                                            >
                                              <span className="js-date">
                                                22
                                              </span>
                                            </div>
                                            <div
                                              data-index={59}
                                              data-week="Fri"
                                              data-month="feb"
                                              className="elCalendar__sell"
                                            >
                                              <span className="js-date">
                                                23
                                              </span>
                                            </div>
                                            <div
                                              data-index={60}
                                              data-week="Sat"
                                              data-month="feb"
                                              className="elCalendar__sell"
                                            >
                                              <span className="js-date">
                                                24
                                              </span>
                                            </div>
                                            <div
                                              data-index={61}
                                              data-week="Sun"
                                              data-month="feb"
                                              className="elCalendar__sell"
                                            >
                                              <span className="js-date">
                                                25
                                              </span>
                                            </div>
                                            <div
                                              data-index={62}
                                              data-week="Mon"
                                              data-month="feb"
                                              className="elCalendar__sell"
                                            >
                                              <span className="js-date">
                                                26
                                              </span>
                                            </div>
                                            <div
                                              data-index={63}
                                              data-week="Tue"
                                              data-month="feb"
                                              className="elCalendar__sell"
                                            >
                                              <span className="js-date">
                                                27
                                              </span>
                                            </div>
                                            <div
                                              data-index={64}
                                              data-week="Wed"
                                              data-month="feb"
                                              className="elCalendar__sell"
                                            >
                                              <span className="js-date">
                                                28
                                              </span>
                                            </div>
                                            <div
                                              data-index={65}
                                              data-week="Thu"
                                              data-month="feb"
                                              className="elCalendar__sell"
                                            >
                                              <span className="js-date">
                                                29
                                              </span>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div
                                        className="swiper-slide"
                                        role="group"
                                        aria-label="3 / 12"
                                        style={{ width: "420px" }}
                                      >
                                        <div className="capitalize text-28 fw-500 text-center mb-10">
                                          march 2024
                                        </div>
                                        <div className="elCalendar__month">
                                          <div className="elCalendar__header">
                                            <div className="elCalendar__header__sell">
                                              Sun
                                            </div>
                                            <div className="elCalendar__header__sell">
                                              Mon
                                            </div>
                                            <div className="elCalendar__header__sell">
                                              Tue
                                            </div>
                                            <div className="elCalendar__header__sell">
                                              Wed
                                            </div>
                                            <div className="elCalendar__header__sell">
                                              Thu
                                            </div>
                                            <div className="elCalendar__header__sell">
                                              Fri
                                            </div>
                                            <div className="elCalendar__header__sell">
                                              Sat
                                            </div>
                                          </div>
                                          <div className="elCalendar__body">
                                            <div
                                              data-index={66}
                                              data-week="Sun"
                                              data-month="mar"
                                              className="elCalendar__sell -dark"
                                            >
                                              <span className="js-date">
                                                25
                                              </span>
                                            </div>
                                            <div
                                              data-index={67}
                                              data-week="Mon"
                                              data-month="mar"
                                              className="elCalendar__sell -dark"
                                            >
                                              <span className="js-date">
                                                26
                                              </span>
                                            </div>
                                            <div
                                              data-index={68}
                                              data-week="Tue"
                                              data-month="mar"
                                              className="elCalendar__sell -dark"
                                            >
                                              <span className="js-date">
                                                27
                                              </span>
                                            </div>
                                            <div
                                              data-index={69}
                                              data-week="Wed"
                                              data-month="mar"
                                              className="elCalendar__sell -dark"
                                            >
                                              <span className="js-date">
                                                28
                                              </span>
                                            </div>
                                            <div
                                              data-index={70}
                                              data-week="Thu"
                                              data-month="mar"
                                              className="elCalendar__sell -dark"
                                            >
                                              <span className="js-date">
                                                29
                                              </span>
                                            </div>
                                            <div
                                              data-index={71}
                                              data-week="Fri"
                                              data-month="mar"
                                              className="elCalendar__sell"
                                            >
                                              <span className="js-date">1</span>
                                            </div>
                                            <div
                                              data-index={72}
                                              data-week="Sat"
                                              data-month="mar"
                                              className="elCalendar__sell"
                                            >
                                              <span className="js-date">2</span>
                                            </div>
                                            <div
                                              data-index={73}
                                              data-week="Sun"
                                              data-month="mar"
                                              className="elCalendar__sell"
                                            >
                                              <span className="js-date">3</span>
                                            </div>
                                            <div
                                              data-index={74}
                                              data-week="Mon"
                                              data-month="mar"
                                              className="elCalendar__sell"
                                            >
                                              <span className="js-date">4</span>
                                            </div>
                                            <div
                                              data-index={75}
                                              data-week="Tue"
                                              data-month="mar"
                                              className="elCalendar__sell"
                                            >
                                              <span className="js-date">5</span>
                                            </div>
                                            <div
                                              data-index={76}
                                              data-week="Wed"
                                              data-month="mar"
                                              className="elCalendar__sell"
                                            >
                                              <span className="js-date">6</span>
                                            </div>
                                            <div
                                              data-index={77}
                                              data-week="Thu"
                                              data-month="mar"
                                              className="elCalendar__sell"
                                            >
                                              <span className="js-date">7</span>
                                            </div>
                                            <div
                                              data-index={78}
                                              data-week="Fri"
                                              data-month="mar"
                                              className="elCalendar__sell"
                                            >
                                              <span className="js-date">8</span>
                                            </div>
                                            <div
                                              data-index={79}
                                              data-week="Sat"
                                              data-month="mar"
                                              className="elCalendar__sell"
                                            >
                                              <span className="js-date">9</span>
                                            </div>
                                            <div
                                              data-index={80}
                                              data-week="Sun"
                                              data-month="mar"
                                              className="elCalendar__sell"
                                            >
                                              <span className="js-date">
                                                10
                                              </span>
                                            </div>
                                            <div
                                              data-index={81}
                                              data-week="Mon"
                                              data-month="mar"
                                              className="elCalendar__sell"
                                            >
                                              <span className="js-date">
                                                11
                                              </span>
                                            </div>
                                            <div
                                              data-index={82}
                                              data-week="Tue"
                                              data-month="mar"
                                              className="elCalendar__sell"
                                            >
                                              <span className="js-date">
                                                12
                                              </span>
                                            </div>
                                            <div
                                              data-index={83}
                                              data-week="Wed"
                                              data-month="mar"
                                              className="elCalendar__sell"
                                            >
                                              <span className="js-date">
                                                13
                                              </span>
                                            </div>
                                            <div
                                              data-index={84}
                                              data-week="Thu"
                                              data-month="mar"
                                              className="elCalendar__sell"
                                            >
                                              <span className="js-date">
                                                14
                                              </span>
                                            </div>
                                            <div
                                              data-index={85}
                                              data-week="Fri"
                                              data-month="mar"
                                              className="elCalendar__sell"
                                            >
                                              <span className="js-date">
                                                15
                                              </span>
                                            </div>
                                            <div
                                              data-index={86}
                                              data-week="Sat"
                                              data-month="mar"
                                              className="elCalendar__sell"
                                            >
                                              <span className="js-date">
                                                16
                                              </span>
                                            </div>
                                            <div
                                              data-index={87}
                                              data-week="Sun"
                                              data-month="mar"
                                              className="elCalendar__sell"
                                            >
                                              <span className="js-date">
                                                17
                                              </span>
                                            </div>
                                            <div
                                              data-index={88}
                                              data-week="Mon"
                                              data-month="mar"
                                              className="elCalendar__sell"
                                            >
                                              <span className="js-date">
                                                18
                                              </span>
                                            </div>
                                            <div
                                              data-index={89}
                                              data-week="Tue"
                                              data-month="mar"
                                              className="elCalendar__sell"
                                            >
                                              <span className="js-date">
                                                19
                                              </span>
                                            </div>
                                            <div
                                              data-index={90}
                                              data-week="Wed"
                                              data-month="mar"
                                              className="elCalendar__sell"
                                            >
                                              <span className="js-date">
                                                20
                                              </span>
                                            </div>
                                            <div
                                              data-index={91}
                                              data-week="Thu"
                                              data-month="mar"
                                              className="elCalendar__sell"
                                            >
                                              <span className="js-date">
                                                21
                                              </span>
                                            </div>
                                            <div
                                              data-index={92}
                                              data-week="Fri"
                                              data-month="mar"
                                              className="elCalendar__sell"
                                            >
                                              <span className="js-date">
                                                22
                                              </span>
                                            </div>
                                            <div
                                              data-index={93}
                                              data-week="Sat"
                                              data-month="mar"
                                              className="elCalendar__sell"
                                            >
                                              <span className="js-date">
                                                23
                                              </span>
                                            </div>
                                            <div
                                              data-index={94}
                                              data-week="Sun"
                                              data-month="mar"
                                              className="elCalendar__sell"
                                            >
                                              <span className="js-date">
                                                24
                                              </span>
                                            </div>
                                            <div
                                              data-index={95}
                                              data-week="Mon"
                                              data-month="mar"
                                              className="elCalendar__sell"
                                            >
                                              <span className="js-date">
                                                25
                                              </span>
                                            </div>
                                            <div
                                              data-index={96}
                                              data-week="Tue"
                                              data-month="mar"
                                              className="elCalendar__sell"
                                            >
                                              <span className="js-date">
                                                26
                                              </span>
                                            </div>
                                            <div
                                              data-index={97}
                                              data-week="Wed"
                                              data-month="mar"
                                              className="elCalendar__sell"
                                            >
                                              <span className="js-date">
                                                27
                                              </span>
                                            </div>
                                            <div
                                              data-index={98}
                                              data-week="Thu"
                                              data-month="mar"
                                              className="elCalendar__sell"
                                            >
                                              <span className="js-date">
                                                28
                                              </span>
                                            </div>
                                            <div
                                              data-index={99}
                                              data-week="Fri"
                                              data-month="mar"
                                              className="elCalendar__sell"
                                            >
                                              <span className="js-date">
                                                29
                                              </span>
                                            </div>
                                            <div
                                              data-index={100}
                                              data-week="Sat"
                                              data-month="mar"
                                              className="elCalendar__sell"
                                            >
                                              <span className="js-date">
                                                30
                                              </span>
                                            </div>
                                            <div
                                              data-index={101}
                                              data-week="Sun"
                                              data-month="mar"
                                              className="elCalendar__sell"
                                            >
                                              <span className="js-date">
                                                31
                                              </span>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div
                                        className="swiper-slide"
                                        role="group"
                                        aria-label="4 / 12"
                                        style={{ width: "420px" }}
                                      >
                                        <div className="capitalize text-28 fw-500 text-center mb-10">
                                          april 2024
                                        </div>
                                        <div className="elCalendar__month">
                                          <div className="elCalendar__header">
                                            <div className="elCalendar__header__sell">
                                              Sun
                                            </div>
                                            <div className="elCalendar__header__sell">
                                              Mon
                                            </div>
                                            <div className="elCalendar__header__sell">
                                              Tue
                                            </div>
                                            <div className="elCalendar__header__sell">
                                              Wed
                                            </div>
                                            <div className="elCalendar__header__sell">
                                              Thu
                                            </div>
                                            <div className="elCalendar__header__sell">
                                              Fri
                                            </div>
                                            <div className="elCalendar__header__sell">
                                              Sat
                                            </div>
                                          </div>
                                          <div className="elCalendar__body">
                                            <div
                                              data-index={102}
                                              data-week="Sun"
                                              data-month="apr"
                                              className="elCalendar__sell -dark"
                                            >
                                              <span className="js-date">
                                                31
                                              </span>
                                            </div>
                                            <div
                                              data-index={103}
                                              data-week="Mon"
                                              data-month="apr"
                                              className="elCalendar__sell"
                                            >
                                              <span className="js-date">1</span>
                                            </div>
                                            <div
                                              data-index={104}
                                              data-week="Tue"
                                              data-month="apr"
                                              className="elCalendar__sell"
                                            >
                                              <span className="js-date">2</span>
                                            </div>
                                            <div
                                              data-index={105}
                                              data-week="Wed"
                                              data-month="apr"
                                              className="elCalendar__sell"
                                            >
                                              <span className="js-date">3</span>
                                            </div>
                                            <div
                                              data-index={106}
                                              data-week="Thu"
                                              data-month="apr"
                                              className="elCalendar__sell"
                                            >
                                              <span className="js-date">4</span>
                                            </div>
                                            <div
                                              data-index={107}
                                              data-week="Fri"
                                              data-month="apr"
                                              className="elCalendar__sell"
                                            >
                                              <span className="js-date">5</span>
                                            </div>
                                            <div
                                              data-index={108}
                                              data-week="Sat"
                                              data-month="apr"
                                              className="elCalendar__sell"
                                            >
                                              <span className="js-date">6</span>
                                            </div>
                                            <div
                                              data-index={109}
                                              data-week="Sun"
                                              data-month="apr"
                                              className="elCalendar__sell"
                                            >
                                              <span className="js-date">7</span>
                                            </div>
                                            <div
                                              data-index={110}
                                              data-week="Mon"
                                              data-month="apr"
                                              className="elCalendar__sell"
                                            >
                                              <span className="js-date">8</span>
                                            </div>
                                            <div
                                              data-index={111}
                                              data-week="Tue"
                                              data-month="apr"
                                              className="elCalendar__sell"
                                            >
                                              <span className="js-date">9</span>
                                            </div>
                                            <div
                                              data-index={112}
                                              data-week="Wed"
                                              data-month="apr"
                                              className="elCalendar__sell"
                                            >
                                              <span className="js-date">
                                                10
                                              </span>
                                            </div>
                                            <div
                                              data-index={113}
                                              data-week="Thu"
                                              data-month="apr"
                                              className="elCalendar__sell"
                                            >
                                              <span className="js-date">
                                                11
                                              </span>
                                            </div>
                                            <div
                                              data-index={114}
                                              data-week="Fri"
                                              data-month="apr"
                                              className="elCalendar__sell"
                                            >
                                              <span className="js-date">
                                                12
                                              </span>
                                            </div>
                                            <div
                                              data-index={115}
                                              data-week="Sat"
                                              data-month="apr"
                                              className="elCalendar__sell"
                                            >
                                              <span className="js-date">
                                                13
                                              </span>
                                            </div>
                                            <div
                                              data-index={116}
                                              data-week="Sun"
                                              data-month="apr"
                                              className="elCalendar__sell"
                                            >
                                              <span className="js-date">
                                                14
                                              </span>
                                            </div>
                                            <div
                                              data-index={117}
                                              data-week="Mon"
                                              data-month="apr"
                                              className="elCalendar__sell"
                                            >
                                              <span className="js-date">
                                                15
                                              </span>
                                            </div>
                                            <div
                                              data-index={118}
                                              data-week="Tue"
                                              data-month="apr"
                                              className="elCalendar__sell"
                                            >
                                              <span className="js-date">
                                                16
                                              </span>
                                            </div>
                                            <div
                                              data-index={119}
                                              data-week="Wed"
                                              data-month="apr"
                                              className="elCalendar__sell"
                                            >
                                              <span className="js-date">
                                                17
                                              </span>
                                            </div>
                                            <div
                                              data-index={120}
                                              data-week="Thu"
                                              data-month="apr"
                                              className="elCalendar__sell"
                                            >
                                              <span className="js-date">
                                                18
                                              </span>
                                            </div>
                                            <div
                                              data-index={121}
                                              data-week="Fri"
                                              data-month="apr"
                                              className="elCalendar__sell"
                                            >
                                              <span className="js-date">
                                                19
                                              </span>
                                            </div>
                                            <div
                                              data-index={122}
                                              data-week="Sat"
                                              data-month="apr"
                                              className="elCalendar__sell"
                                            >
                                              <span className="js-date">
                                                20
                                              </span>
                                            </div>
                                            <div
                                              data-index={123}
                                              data-week="Sun"
                                              data-month="apr"
                                              className="elCalendar__sell"
                                            >
                                              <span className="js-date">
                                                21
                                              </span>
                                            </div>
                                            <div
                                              data-index={124}
                                              data-week="Mon"
                                              data-month="apr"
                                              className="elCalendar__sell"
                                            >
                                              <span className="js-date">
                                                22
                                              </span>
                                            </div>
                                            <div
                                              data-index={125}
                                              data-week="Tue"
                                              data-month="apr"
                                              className="elCalendar__sell"
                                            >
                                              <span className="js-date">
                                                23
                                              </span>
                                            </div>
                                            <div
                                              data-index={126}
                                              data-week="Wed"
                                              data-month="apr"
                                              className="elCalendar__sell"
                                            >
                                              <span className="js-date">
                                                24
                                              </span>
                                            </div>
                                            <div
                                              data-index={127}
                                              data-week="Thu"
                                              data-month="apr"
                                              className="elCalendar__sell"
                                            >
                                              <span className="js-date">
                                                25
                                              </span>
                                            </div>
                                            <div
                                              data-index={128}
                                              data-week="Fri"
                                              data-month="apr"
                                              className="elCalendar__sell"
                                            >
                                              <span className="js-date">
                                                26
                                              </span>
                                            </div>
                                            <div
                                              data-index={129}
                                              data-week="Sat"
                                              data-month="apr"
                                              className="elCalendar__sell"
                                            >
                                              <span className="js-date">
                                                27
                                              </span>
                                            </div>
                                            <div
                                              data-index={130}
                                              data-week="Sun"
                                              data-month="apr"
                                              className="elCalendar__sell"
                                            >
                                              <span className="js-date">
                                                28
                                              </span>
                                            </div>
                                            <div
                                              data-index={131}
                                              data-week="Mon"
                                              data-month="apr"
                                              className="elCalendar__sell"
                                            >
                                              <span className="js-date">
                                                29
                                              </span>
                                            </div>
                                            <div
                                              data-index={132}
                                              data-week="Tue"
                                              data-month="apr"
                                              className="elCalendar__sell"
                                            >
                                              <span className="js-date">
                                                30
                                              </span>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div
                                        className="swiper-slide"
                                        role="group"
                                        aria-label="5 / 12"
                                        style={{ width: "420px" }}
                                      >
                                        <div className="capitalize text-28 fw-500 text-center mb-10">
                                          may 2024
                                        </div>
                                        <div className="elCalendar__month">
                                          <div className="elCalendar__header">
                                            <div className="elCalendar__header__sell">
                                              Sun
                                            </div>
                                            <div className="elCalendar__header__sell">
                                              Mon
                                            </div>
                                            <div className="elCalendar__header__sell">
                                              Tue
                                            </div>
                                            <div className="elCalendar__header__sell">
                                              Wed
                                            </div>
                                            <div className="elCalendar__header__sell">
                                              Thu
                                            </div>
                                            <div className="elCalendar__header__sell">
                                              Fri
                                            </div>
                                            <div className="elCalendar__header__sell">
                                              Sat
                                            </div>
                                          </div>
                                          <div className="elCalendar__body">
                                            <div
                                              data-index={133}
                                              data-week="Sun"
                                              data-month="may"
                                              className="elCalendar__sell -dark"
                                            >
                                              <span className="js-date">
                                                28
                                              </span>
                                            </div>
                                            <div
                                              data-index={134}
                                              data-week="Mon"
                                              data-month="may"
                                              className="elCalendar__sell -dark"
                                            >
                                              <span className="js-date">
                                                29
                                              </span>
                                            </div>
                                            <div
                                              data-index={135}
                                              data-week="Tue"
                                              data-month="may"
                                              className="elCalendar__sell -dark"
                                            >
                                              <span className="js-date">
                                                30
                                              </span>
                                            </div>
                                            <div
                                              data-index={136}
                                              data-week="Wed"
                                              data-month="may"
                                              className="elCalendar__sell"
                                            >
                                              <span className="js-date">1</span>
                                            </div>
                                            <div
                                              data-index={137}
                                              data-week="Thu"
                                              data-month="may"
                                              className="elCalendar__sell"
                                            >
                                              <span className="js-date">2</span>
                                            </div>
                                            <div
                                              data-index={138}
                                              data-week="Fri"
                                              data-month="may"
                                              className="elCalendar__sell"
                                            >
                                              <span className="js-date">3</span>
                                            </div>
                                            <div
                                              data-index={139}
                                              data-week="Sat"
                                              data-month="may"
                                              className="elCalendar__sell"
                                            >
                                              <span className="js-date">4</span>
                                            </div>
                                            <div
                                              data-index={140}
                                              data-week="Sun"
                                              data-month="may"
                                              className="elCalendar__sell"
                                            >
                                              <span className="js-date">5</span>
                                            </div>
                                            <div
                                              data-index={141}
                                              data-week="Mon"
                                              data-month="may"
                                              className="elCalendar__sell"
                                            >
                                              <span className="js-date">6</span>
                                            </div>
                                            <div
                                              data-index={142}
                                              data-week="Tue"
                                              data-month="may"
                                              className="elCalendar__sell"
                                            >
                                              <span className="js-date">7</span>
                                            </div>
                                            <div
                                              data-index={143}
                                              data-week="Wed"
                                              data-month="may"
                                              className="elCalendar__sell"
                                            >
                                              <span className="js-date">8</span>
                                            </div>
                                            <div
                                              data-index={144}
                                              data-week="Thu"
                                              data-month="may"
                                              className="elCalendar__sell"
                                            >
                                              <span className="js-date">9</span>
                                            </div>
                                            <div
                                              data-index={145}
                                              data-week="Fri"
                                              data-month="may"
                                              className="elCalendar__sell"
                                            >
                                              <span className="js-date">
                                                10
                                              </span>
                                            </div>
                                            <div
                                              data-index={146}
                                              data-week="Sat"
                                              data-month="may"
                                              className="elCalendar__sell"
                                            >
                                              <span className="js-date">
                                                11
                                              </span>
                                            </div>
                                            <div
                                              data-index={147}
                                              data-week="Sun"
                                              data-month="may"
                                              className="elCalendar__sell"
                                            >
                                              <span className="js-date">
                                                12
                                              </span>
                                            </div>
                                            <div
                                              data-index={148}
                                              data-week="Mon"
                                              data-month="may"
                                              className="elCalendar__sell"
                                            >
                                              <span className="js-date">
                                                13
                                              </span>
                                            </div>
                                            <div
                                              data-index={149}
                                              data-week="Tue"
                                              data-month="may"
                                              className="elCalendar__sell"
                                            >
                                              <span className="js-date">
                                                14
                                              </span>
                                            </div>
                                            <div
                                              data-index={150}
                                              data-week="Wed"
                                              data-month="may"
                                              className="elCalendar__sell"
                                            >
                                              <span className="js-date">
                                                15
                                              </span>
                                            </div>
                                            <div
                                              data-index={151}
                                              data-week="Thu"
                                              data-month="may"
                                              className="elCalendar__sell"
                                            >
                                              <span className="js-date">
                                                16
                                              </span>
                                            </div>
                                            <div
                                              data-index={152}
                                              data-week="Fri"
                                              data-month="may"
                                              className="elCalendar__sell"
                                            >
                                              <span className="js-date">
                                                17
                                              </span>
                                            </div>
                                            <div
                                              data-index={153}
                                              data-week="Sat"
                                              data-month="may"
                                              className="elCalendar__sell"
                                            >
                                              <span className="js-date">
                                                18
                                              </span>
                                            </div>
                                            <div
                                              data-index={154}
                                              data-week="Sun"
                                              data-month="may"
                                              className="elCalendar__sell"
                                            >
                                              <span className="js-date">
                                                19
                                              </span>
                                            </div>
                                            <div
                                              data-index={155}
                                              data-week="Mon"
                                              data-month="may"
                                              className="elCalendar__sell"
                                            >
                                              <span className="js-date">
                                                20
                                              </span>
                                            </div>
                                            <div
                                              data-index={156}
                                              data-week="Tue"
                                              data-month="may"
                                              className="elCalendar__sell"
                                            >
                                              <span className="js-date">
                                                21
                                              </span>
                                            </div>
                                            <div
                                              data-index={157}
                                              data-week="Wed"
                                              data-month="may"
                                              className="elCalendar__sell"
                                            >
                                              <span className="js-date">
                                                22
                                              </span>
                                            </div>
                                            <div
                                              data-index={158}
                                              data-week="Thu"
                                              data-month="may"
                                              className="elCalendar__sell"
                                            >
                                              <span className="js-date">
                                                23
                                              </span>
                                            </div>
                                            <div
                                              data-index={159}
                                              data-week="Fri"
                                              data-month="may"
                                              className="elCalendar__sell"
                                            >
                                              <span className="js-date">
                                                24
                                              </span>
                                            </div>
                                            <div
                                              data-index={160}
                                              data-week="Sat"
                                              data-month="may"
                                              className="elCalendar__sell"
                                            >
                                              <span className="js-date">
                                                25
                                              </span>
                                            </div>
                                            <div
                                              data-index={161}
                                              data-week="Sun"
                                              data-month="may"
                                              className="elCalendar__sell"
                                            >
                                              <span className="js-date">
                                                26
                                              </span>
                                            </div>
                                            <div
                                              data-index={162}
                                              data-week="Mon"
                                              data-month="may"
                                              className="elCalendar__sell"
                                            >
                                              <span className="js-date">
                                                27
                                              </span>
                                            </div>
                                            <div
                                              data-index={163}
                                              data-week="Tue"
                                              data-month="may"
                                              className="elCalendar__sell"
                                            >
                                              <span className="js-date">
                                                28
                                              </span>
                                            </div>
                                            <div
                                              data-index={164}
                                              data-week="Wed"
                                              data-month="may"
                                              className="elCalendar__sell"
                                            >
                                              <span className="js-date">
                                                29
                                              </span>
                                            </div>
                                            <div
                                              data-index={165}
                                              data-week="Thu"
                                              data-month="may"
                                              className="elCalendar__sell"
                                            >
                                              <span className="js-date">
                                                30
                                              </span>
                                            </div>
                                            <div
                                              data-index={166}
                                              data-week="Fri"
                                              data-month="may"
                                              className="elCalendar__sell"
                                            >
                                              <span className="js-date">
                                                31
                                              </span>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div
                                        className="swiper-slide"
                                        role="group"
                                        aria-label="6 / 12"
                                        style={{ width: "420px" }}
                                      >
                                        <div className="capitalize text-28 fw-500 text-center mb-10">
                                          june 2024
                                        </div>
                                        <div className="elCalendar__month">
                                          <div className="elCalendar__header">
                                            <div className="elCalendar__header__sell">
                                              Sun
                                            </div>
                                            <div className="elCalendar__header__sell">
                                              Mon
                                            </div>
                                            <div className="elCalendar__header__sell">
                                              Tue
                                            </div>
                                            <div className="elCalendar__header__sell">
                                              Wed
                                            </div>
                                            <div className="elCalendar__header__sell">
                                              Thu
                                            </div>
                                            <div className="elCalendar__header__sell">
                                              Fri
                                            </div>
                                            <div className="elCalendar__header__sell">
                                              Sat
                                            </div>
                                          </div>
                                          <div className="elCalendar__body">
                                            <div
                                              data-index={167}
                                              data-week="Sun"
                                              data-month="jun"
                                              className="elCalendar__sell -dark"
                                            >
                                              <span className="js-date">
                                                26
                                              </span>
                                            </div>
                                            <div
                                              data-index={168}
                                              data-week="Mon"
                                              data-month="jun"
                                              className="elCalendar__sell -dark"
                                            >
                                              <span className="js-date">
                                                27
                                              </span>
                                            </div>
                                            <div
                                              data-index={169}
                                              data-week="Tue"
                                              data-month="jun"
                                              className="elCalendar__sell -dark"
                                            >
                                              <span className="js-date">
                                                28
                                              </span>
                                            </div>
                                            <div
                                              data-index={170}
                                              data-week="Wed"
                                              data-month="jun"
                                              className="elCalendar__sell -dark"
                                            >
                                              <span className="js-date">
                                                29
                                              </span>
                                            </div>
                                            <div
                                              data-index={171}
                                              data-week="Thu"
                                              data-month="jun"
                                              className="elCalendar__sell -dark"
                                            >
                                              <span className="js-date">
                                                30
                                              </span>
                                            </div>
                                            <div
                                              data-index={172}
                                              data-week="Fri"
                                              data-month="jun"
                                              className="elCalendar__sell -dark"
                                            >
                                              <span className="js-date">
                                                31
                                              </span>
                                            </div>
                                            <div
                                              data-index={173}
                                              data-week="Sat"
                                              data-month="jun"
                                              className="elCalendar__sell"
                                            >
                                              <span className="js-date">1</span>
                                            </div>
                                            <div
                                              data-index={174}
                                              data-week="Sun"
                                              data-month="jun"
                                              className="elCalendar__sell"
                                            >
                                              <span className="js-date">2</span>
                                            </div>
                                            <div
                                              data-index={175}
                                              data-week="Mon"
                                              data-month="jun"
                                              className="elCalendar__sell"
                                            >
                                              <span className="js-date">3</span>
                                            </div>
                                            <div
                                              data-index={176}
                                              data-week="Tue"
                                              data-month="jun"
                                              className="elCalendar__sell"
                                            >
                                              <span className="js-date">4</span>
                                            </div>
                                            <div
                                              data-index={177}
                                              data-week="Wed"
                                              data-month="jun"
                                              className="elCalendar__sell"
                                            >
                                              <span className="js-date">5</span>
                                            </div>
                                            <div
                                              data-index={178}
                                              data-week="Thu"
                                              data-month="jun"
                                              className="elCalendar__sell"
                                            >
                                              <span className="js-date">6</span>
                                            </div>
                                            <div
                                              data-index={179}
                                              data-week="Fri"
                                              data-month="jun"
                                              className="elCalendar__sell"
                                            >
                                              <span className="js-date">7</span>
                                            </div>
                                            <div
                                              data-index={180}
                                              data-week="Sat"
                                              data-month="jun"
                                              className="elCalendar__sell"
                                            >
                                              <span className="js-date">8</span>
                                            </div>
                                            <div
                                              data-index={181}
                                              data-week="Sun"
                                              data-month="jun"
                                              className="elCalendar__sell"
                                            >
                                              <span className="js-date">9</span>
                                            </div>
                                            <div
                                              data-index={182}
                                              data-week="Mon"
                                              data-month="jun"
                                              className="elCalendar__sell"
                                            >
                                              <span className="js-date">
                                                10
                                              </span>
                                            </div>
                                            <div
                                              data-index={183}
                                              data-week="Tue"
                                              data-month="jun"
                                              className="elCalendar__sell"
                                            >
                                              <span className="js-date">
                                                11
                                              </span>
                                            </div>
                                            <div
                                              data-index={184}
                                              data-week="Wed"
                                              data-month="jun"
                                              className="elCalendar__sell"
                                            >
                                              <span className="js-date">
                                                12
                                              </span>
                                            </div>
                                            <div
                                              data-index={185}
                                              data-week="Thu"
                                              data-month="jun"
                                              className="elCalendar__sell"
                                            >
                                              <span className="js-date">
                                                13
                                              </span>
                                            </div>
                                            <div
                                              data-index={186}
                                              data-week="Fri"
                                              data-month="jun"
                                              className="elCalendar__sell"
                                            >
                                              <span className="js-date">
                                                14
                                              </span>
                                            </div>
                                            <div
                                              data-index={187}
                                              data-week="Sat"
                                              data-month="jun"
                                              className="elCalendar__sell"
                                            >
                                              <span className="js-date">
                                                15
                                              </span>
                                            </div>
                                            <div
                                              data-index={188}
                                              data-week="Sun"
                                              data-month="jun"
                                              className="elCalendar__sell"
                                            >
                                              <span className="js-date">
                                                16
                                              </span>
                                            </div>
                                            <div
                                              data-index={189}
                                              data-week="Mon"
                                              data-month="jun"
                                              className="elCalendar__sell"
                                            >
                                              <span className="js-date">
                                                17
                                              </span>
                                            </div>
                                            <div
                                              data-index={190}
                                              data-week="Tue"
                                              data-month="jun"
                                              className="elCalendar__sell"
                                            >
                                              <span className="js-date">
                                                18
                                              </span>
                                            </div>
                                            <div
                                              data-index={191}
                                              data-week="Wed"
                                              data-month="jun"
                                              className="elCalendar__sell"
                                            >
                                              <span className="js-date">
                                                19
                                              </span>
                                            </div>
                                            <div
                                              data-index={192}
                                              data-week="Thu"
                                              data-month="jun"
                                              className="elCalendar__sell"
                                            >
                                              <span className="js-date">
                                                20
                                              </span>
                                            </div>
                                            <div
                                              data-index={193}
                                              data-week="Fri"
                                              data-month="jun"
                                              className="elCalendar__sell"
                                            >
                                              <span className="js-date">
                                                21
                                              </span>
                                            </div>
                                            <div
                                              data-index={194}
                                              data-week="Sat"
                                              data-month="jun"
                                              className="elCalendar__sell"
                                            >
                                              <span className="js-date">
                                                22
                                              </span>
                                            </div>
                                            <div
                                              data-index={195}
                                              data-week="Sun"
                                              data-month="jun"
                                              className="elCalendar__sell"
                                            >
                                              <span className="js-date">
                                                23
                                              </span>
                                            </div>
                                            <div
                                              data-index={196}
                                              data-week="Mon"
                                              data-month="jun"
                                              className="elCalendar__sell"
                                            >
                                              <span className="js-date">
                                                24
                                              </span>
                                            </div>
                                            <div
                                              data-index={197}
                                              data-week="Tue"
                                              data-month="jun"
                                              className="elCalendar__sell"
                                            >
                                              <span className="js-date">
                                                25
                                              </span>
                                            </div>
                                            <div
                                              data-index={198}
                                              data-week="Wed"
                                              data-month="jun"
                                              className="elCalendar__sell"
                                            >
                                              <span className="js-date">
                                                26
                                              </span>
                                            </div>
                                            <div
                                              data-index={199}
                                              data-week="Thu"
                                              data-month="jun"
                                              className="elCalendar__sell"
                                            >
                                              <span className="js-date">
                                                27
                                              </span>
                                            </div>
                                            <div
                                              data-index={200}
                                              data-week="Fri"
                                              data-month="jun"
                                              className="elCalendar__sell"
                                            >
                                              <span className="js-date">
                                                28
                                              </span>
                                            </div>
                                            <div
                                              data-index={201}
                                              data-week="Sat"
                                              data-month="jun"
                                              className="elCalendar__sell"
                                            >
                                              <span className="js-date">
                                                29
                                              </span>
                                            </div>
                                            <div
                                              data-index={202}
                                              data-week="Sun"
                                              data-month="jun"
                                              className="elCalendar__sell"
                                            >
                                              <span className="js-date">
                                                30
                                              </span>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div
                                        className="swiper-slide"
                                        role="group"
                                        aria-label="7 / 12"
                                        style={{ width: "420px" }}
                                      >
                                        <div className="capitalize text-28 fw-500 text-center mb-10">
                                          july 2024
                                        </div>
                                        <div className="elCalendar__month">
                                          <div className="elCalendar__header">
                                            <div className="elCalendar__header__sell">
                                              Sun
                                            </div>
                                            <div className="elCalendar__header__sell">
                                              Mon
                                            </div>
                                            <div className="elCalendar__header__sell">
                                              Tue
                                            </div>
                                            <div className="elCalendar__header__sell">
                                              Wed
                                            </div>
                                            <div className="elCalendar__header__sell">
                                              Thu
                                            </div>
                                            <div className="elCalendar__header__sell">
                                              Fri
                                            </div>
                                            <div className="elCalendar__header__sell">
                                              Sat
                                            </div>
                                          </div>
                                          <div className="elCalendar__body">
                                            <div
                                              data-index={203}
                                              data-week="Sun"
                                              data-month="jul"
                                              className="elCalendar__sell -dark"
                                            >
                                              <span className="js-date">
                                                30
                                              </span>
                                            </div>
                                            <div
                                              data-index={204}
                                              data-week="Mon"
                                              data-month="jul"
                                              className="elCalendar__sell"
                                            >
                                              <span className="js-date">1</span>
                                            </div>
                                            <div
                                              data-index={205}
                                              data-week="Tue"
                                              data-month="jul"
                                              className="elCalendar__sell"
                                            >
                                              <span className="js-date">2</span>
                                            </div>
                                            <div
                                              data-index={206}
                                              data-week="Wed"
                                              data-month="jul"
                                              className="elCalendar__sell"
                                            >
                                              <span className="js-date">3</span>
                                            </div>
                                            <div
                                              data-index={207}
                                              data-week="Thu"
                                              data-month="jul"
                                              className="elCalendar__sell"
                                            >
                                              <span className="js-date">4</span>
                                            </div>
                                            <div
                                              data-index={208}
                                              data-week="Fri"
                                              data-month="jul"
                                              className="elCalendar__sell"
                                            >
                                              <span className="js-date">5</span>
                                            </div>
                                            <div
                                              data-index={209}
                                              data-week="Sat"
                                              data-month="jul"
                                              className="elCalendar__sell"
                                            >
                                              <span className="js-date">6</span>
                                            </div>
                                            <div
                                              data-index={210}
                                              data-week="Sun"
                                              data-month="jul"
                                              className="elCalendar__sell"
                                            >
                                              <span className="js-date">7</span>
                                            </div>
                                            <div
                                              data-index={211}
                                              data-week="Mon"
                                              data-month="jul"
                                              className="elCalendar__sell"
                                            >
                                              <span className="js-date">8</span>
                                            </div>
                                            <div
                                              data-index={212}
                                              data-week="Tue"
                                              data-month="jul"
                                              className="elCalendar__sell"
                                            >
                                              <span className="js-date">9</span>
                                            </div>
                                            <div
                                              data-index={213}
                                              data-week="Wed"
                                              data-month="jul"
                                              className="elCalendar__sell"
                                            >
                                              <span className="js-date">
                                                10
                                              </span>
                                            </div>
                                            <div
                                              data-index={214}
                                              data-week="Thu"
                                              data-month="jul"
                                              className="elCalendar__sell"
                                            >
                                              <span className="js-date">
                                                11
                                              </span>
                                            </div>
                                            <div
                                              data-index={215}
                                              data-week="Fri"
                                              data-month="jul"
                                              className="elCalendar__sell"
                                            >
                                              <span className="js-date">
                                                12
                                              </span>
                                            </div>
                                            <div
                                              data-index={216}
                                              data-week="Sat"
                                              data-month="jul"
                                              className="elCalendar__sell"
                                            >
                                              <span className="js-date">
                                                13
                                              </span>
                                            </div>
                                            <div
                                              data-index={217}
                                              data-week="Sun"
                                              data-month="jul"
                                              className="elCalendar__sell"
                                            >
                                              <span className="js-date">
                                                14
                                              </span>
                                            </div>
                                            <div
                                              data-index={218}
                                              data-week="Mon"
                                              data-month="jul"
                                              className="elCalendar__sell"
                                            >
                                              <span className="js-date">
                                                15
                                              </span>
                                            </div>
                                            <div
                                              data-index={219}
                                              data-week="Tue"
                                              data-month="jul"
                                              className="elCalendar__sell"
                                            >
                                              <span className="js-date">
                                                16
                                              </span>
                                            </div>
                                            <div
                                              data-index={220}
                                              data-week="Wed"
                                              data-month="jul"
                                              className="elCalendar__sell"
                                            >
                                              <span className="js-date">
                                                17
                                              </span>
                                            </div>
                                            <div
                                              data-index={221}
                                              data-week="Thu"
                                              data-month="jul"
                                              className="elCalendar__sell"
                                            >
                                              <span className="js-date">
                                                18
                                              </span>
                                            </div>
                                            <div
                                              data-index={222}
                                              data-week="Fri"
                                              data-month="jul"
                                              className="elCalendar__sell"
                                            >
                                              <span className="js-date">
                                                19
                                              </span>
                                            </div>
                                            <div
                                              data-index={223}
                                              data-week="Sat"
                                              data-month="jul"
                                              className="elCalendar__sell"
                                            >
                                              <span className="js-date">
                                                20
                                              </span>
                                            </div>
                                            <div
                                              data-index={224}
                                              data-week="Sun"
                                              data-month="jul"
                                              className="elCalendar__sell"
                                            >
                                              <span className="js-date">
                                                21
                                              </span>
                                            </div>
                                            <div
                                              data-index={225}
                                              data-week="Mon"
                                              data-month="jul"
                                              className="elCalendar__sell"
                                            >
                                              <span className="js-date">
                                                22
                                              </span>
                                            </div>
                                            <div
                                              data-index={226}
                                              data-week="Tue"
                                              data-month="jul"
                                              className="elCalendar__sell"
                                            >
                                              <span className="js-date">
                                                23
                                              </span>
                                            </div>
                                            <div
                                              data-index={227}
                                              data-week="Wed"
                                              data-month="jul"
                                              className="elCalendar__sell"
                                            >
                                              <span className="js-date">
                                                24
                                              </span>
                                            </div>
                                            <div
                                              data-index={228}
                                              data-week="Thu"
                                              data-month="jul"
                                              className="elCalendar__sell"
                                            >
                                              <span className="js-date">
                                                25
                                              </span>
                                            </div>
                                            <div
                                              data-index={229}
                                              data-week="Fri"
                                              data-month="jul"
                                              className="elCalendar__sell"
                                            >
                                              <span className="js-date">
                                                26
                                              </span>
                                            </div>
                                            <div
                                              data-index={230}
                                              data-week="Sat"
                                              data-month="jul"
                                              className="elCalendar__sell"
                                            >
                                              <span className="js-date">
                                                27
                                              </span>
                                            </div>
                                            <div
                                              data-index={231}
                                              data-week="Sun"
                                              data-month="jul"
                                              className="elCalendar__sell"
                                            >
                                              <span className="js-date">
                                                28
                                              </span>
                                            </div>
                                            <div
                                              data-index={232}
                                              data-week="Mon"
                                              data-month="jul"
                                              className="elCalendar__sell"
                                            >
                                              <span className="js-date">
                                                29
                                              </span>
                                            </div>
                                            <div
                                              data-index={233}
                                              data-week="Tue"
                                              data-month="jul"
                                              className="elCalendar__sell"
                                            >
                                              <span className="js-date">
                                                30
                                              </span>
                                            </div>
                                            <div
                                              data-index={234}
                                              data-week="Wed"
                                              data-month="jul"
                                              className="elCalendar__sell"
                                            >
                                              <span className="js-date">
                                                31
                                              </span>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div
                                        className="swiper-slide"
                                        role="group"
                                        aria-label="8 / 12"
                                        style={{ width: "420px" }}
                                      >
                                        <div className="capitalize text-28 fw-500 text-center mb-10">
                                          august 2024
                                        </div>
                                        <div className="elCalendar__month">
                                          <div className="elCalendar__header">
                                            <div className="elCalendar__header__sell">
                                              Sun
                                            </div>
                                            <div className="elCalendar__header__sell">
                                              Mon
                                            </div>
                                            <div className="elCalendar__header__sell">
                                              Tue
                                            </div>
                                            <div className="elCalendar__header__sell">
                                              Wed
                                            </div>
                                            <div className="elCalendar__header__sell">
                                              Thu
                                            </div>
                                            <div className="elCalendar__header__sell">
                                              Fri
                                            </div>
                                            <div className="elCalendar__header__sell">
                                              Sat
                                            </div>
                                          </div>
                                          <div className="elCalendar__body">
                                            <div
                                              data-index={235}
                                              data-week="Sun"
                                              data-month="aug"
                                              className="elCalendar__sell -dark"
                                            >
                                              <span className="js-date">
                                                28
                                              </span>
                                            </div>
                                            <div
                                              data-index={236}
                                              data-week="Mon"
                                              data-month="aug"
                                              className="elCalendar__sell -dark"
                                            >
                                              <span className="js-date">
                                                29
                                              </span>
                                            </div>
                                            <div
                                              data-index={237}
                                              data-week="Tue"
                                              data-month="aug"
                                              className="elCalendar__sell -dark"
                                            >
                                              <span className="js-date">
                                                30
                                              </span>
                                            </div>
                                            <div
                                              data-index={238}
                                              data-week="Wed"
                                              data-month="aug"
                                              className="elCalendar__sell -dark"
                                            >
                                              <span className="js-date">
                                                31
                                              </span>
                                            </div>
                                            <div
                                              data-index={239}
                                              data-week="Thu"
                                              data-month="aug"
                                              className="elCalendar__sell"
                                            >
                                              <span className="js-date">1</span>
                                            </div>
                                            <div
                                              data-index={240}
                                              data-week="Fri"
                                              data-month="aug"
                                              className="elCalendar__sell"
                                            >
                                              <span className="js-date">2</span>
                                            </div>
                                            <div
                                              data-index={241}
                                              data-week="Sat"
                                              data-month="aug"
                                              className="elCalendar__sell"
                                            >
                                              <span className="js-date">3</span>
                                            </div>
                                            <div
                                              data-index={242}
                                              data-week="Sun"
                                              data-month="aug"
                                              className="elCalendar__sell"
                                            >
                                              <span className="js-date">4</span>
                                            </div>
                                            <div
                                              data-index={243}
                                              data-week="Mon"
                                              data-month="aug"
                                              className="elCalendar__sell"
                                            >
                                              <span className="js-date">5</span>
                                            </div>
                                            <div
                                              data-index={244}
                                              data-week="Tue"
                                              data-month="aug"
                                              className="elCalendar__sell"
                                            >
                                              <span className="js-date">6</span>
                                            </div>
                                            <div
                                              data-index={245}
                                              data-week="Wed"
                                              data-month="aug"
                                              className="elCalendar__sell"
                                            >
                                              <span className="js-date">7</span>
                                            </div>
                                            <div
                                              data-index={246}
                                              data-week="Thu"
                                              data-month="aug"
                                              className="elCalendar__sell"
                                            >
                                              <span className="js-date">8</span>
                                            </div>
                                            <div
                                              data-index={247}
                                              data-week="Fri"
                                              data-month="aug"
                                              className="elCalendar__sell"
                                            >
                                              <span className="js-date">9</span>
                                            </div>
                                            <div
                                              data-index={248}
                                              data-week="Sat"
                                              data-month="aug"
                                              className="elCalendar__sell"
                                            >
                                              <span className="js-date">
                                                10
                                              </span>
                                            </div>
                                            <div
                                              data-index={249}
                                              data-week="Sun"
                                              data-month="aug"
                                              className="elCalendar__sell"
                                            >
                                              <span className="js-date">
                                                11
                                              </span>
                                            </div>
                                            <div
                                              data-index={250}
                                              data-week="Mon"
                                              data-month="aug"
                                              className="elCalendar__sell"
                                            >
                                              <span className="js-date">
                                                12
                                              </span>
                                            </div>
                                            <div
                                              data-index={251}
                                              data-week="Tue"
                                              data-month="aug"
                                              className="elCalendar__sell"
                                            >
                                              <span className="js-date">
                                                13
                                              </span>
                                            </div>
                                            <div
                                              data-index={252}
                                              data-week="Wed"
                                              data-month="aug"
                                              className="elCalendar__sell"
                                            >
                                              <span className="js-date">
                                                14
                                              </span>
                                            </div>
                                            <div
                                              data-index={253}
                                              data-week="Thu"
                                              data-month="aug"
                                              className="elCalendar__sell"
                                            >
                                              <span className="js-date">
                                                15
                                              </span>
                                            </div>
                                            <div
                                              data-index={254}
                                              data-week="Fri"
                                              data-month="aug"
                                              className="elCalendar__sell"
                                            >
                                              <span className="js-date">
                                                16
                                              </span>
                                            </div>
                                            <div
                                              data-index={255}
                                              data-week="Sat"
                                              data-month="aug"
                                              className="elCalendar__sell"
                                            >
                                              <span className="js-date">
                                                17
                                              </span>
                                            </div>
                                            <div
                                              data-index={256}
                                              data-week="Sun"
                                              data-month="aug"
                                              className="elCalendar__sell"
                                            >
                                              <span className="js-date">
                                                18
                                              </span>
                                            </div>
                                            <div
                                              data-index={257}
                                              data-week="Mon"
                                              data-month="aug"
                                              className="elCalendar__sell"
                                            >
                                              <span className="js-date">
                                                19
                                              </span>
                                            </div>
                                            <div
                                              data-index={258}
                                              data-week="Tue"
                                              data-month="aug"
                                              className="elCalendar__sell"
                                            >
                                              <span className="js-date">
                                                20
                                              </span>
                                            </div>
                                            <div
                                              data-index={259}
                                              data-week="Wed"
                                              data-month="aug"
                                              className="elCalendar__sell"
                                            >
                                              <span className="js-date">
                                                21
                                              </span>
                                            </div>
                                            <div
                                              data-index={260}
                                              data-week="Thu"
                                              data-month="aug"
                                              className="elCalendar__sell"
                                            >
                                              <span className="js-date">
                                                22
                                              </span>
                                            </div>
                                            <div
                                              data-index={261}
                                              data-week="Fri"
                                              data-month="aug"
                                              className="elCalendar__sell"
                                            >
                                              <span className="js-date">
                                                23
                                              </span>
                                            </div>
                                            <div
                                              data-index={262}
                                              data-week="Sat"
                                              data-month="aug"
                                              className="elCalendar__sell"
                                            >
                                              <span className="js-date">
                                                24
                                              </span>
                                            </div>
                                            <div
                                              data-index={263}
                                              data-week="Sun"
                                              data-month="aug"
                                              className="elCalendar__sell"
                                            >
                                              <span className="js-date">
                                                25
                                              </span>
                                            </div>
                                            <div
                                              data-index={264}
                                              data-week="Mon"
                                              data-month="aug"
                                              className="elCalendar__sell"
                                            >
                                              <span className="js-date">
                                                26
                                              </span>
                                            </div>
                                            <div
                                              data-index={265}
                                              data-week="Tue"
                                              data-month="aug"
                                              className="elCalendar__sell"
                                            >
                                              <span className="js-date">
                                                27
                                              </span>
                                            </div>
                                            <div
                                              data-index={266}
                                              data-week="Wed"
                                              data-month="aug"
                                              className="elCalendar__sell"
                                            >
                                              <span className="js-date">
                                                28
                                              </span>
                                            </div>
                                            <div
                                              data-index={267}
                                              data-week="Thu"
                                              data-month="aug"
                                              className="elCalendar__sell"
                                            >
                                              <span className="js-date">
                                                29
                                              </span>
                                            </div>
                                            <div
                                              data-index={268}
                                              data-week="Fri"
                                              data-month="aug"
                                              className="elCalendar__sell"
                                            >
                                              <span className="js-date">
                                                30
                                              </span>
                                            </div>
                                            <div
                                              data-index={269}
                                              data-week="Sat"
                                              data-month="aug"
                                              className="elCalendar__sell"
                                            >
                                              <span className="js-date">
                                                31
                                              </span>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div
                                        className="swiper-slide"
                                        role="group"
                                        aria-label="9 / 12"
                                        style={{ width: "420px" }}
                                      >
                                        <div className="capitalize text-28 fw-500 text-center mb-10">
                                          september 2024
                                        </div>
                                        <div className="elCalendar__month">
                                          <div className="elCalendar__header">
                                            <div className="elCalendar__header__sell">
                                              Sun
                                            </div>
                                            <div className="elCalendar__header__sell">
                                              Mon
                                            </div>
                                            <div className="elCalendar__header__sell">
                                              Tue
                                            </div>
                                            <div className="elCalendar__header__sell">
                                              Wed
                                            </div>
                                            <div className="elCalendar__header__sell">
                                              Thu
                                            </div>
                                            <div className="elCalendar__header__sell">
                                              Fri
                                            </div>
                                            <div className="elCalendar__header__sell">
                                              Sat
                                            </div>
                                          </div>
                                          <div className="elCalendar__body">
                                            <div
                                              data-index={270}
                                              data-week="Sun"
                                              data-month="sep"
                                              className="elCalendar__sell"
                                            >
                                              <span className="js-date">1</span>
                                            </div>
                                            <div
                                              data-index={271}
                                              data-week="Mon"
                                              data-month="sep"
                                              className="elCalendar__sell"
                                            >
                                              <span className="js-date">2</span>
                                            </div>
                                            <div
                                              data-index={272}
                                              data-week="Tue"
                                              data-month="sep"
                                              className="elCalendar__sell"
                                            >
                                              <span className="js-date">3</span>
                                            </div>
                                            <div
                                              data-index={273}
                                              data-week="Wed"
                                              data-month="sep"
                                              className="elCalendar__sell"
                                            >
                                              <span className="js-date">4</span>
                                            </div>
                                            <div
                                              data-index={274}
                                              data-week="Thu"
                                              data-month="sep"
                                              className="elCalendar__sell"
                                            >
                                              <span className="js-date">5</span>
                                            </div>
                                            <div
                                              data-index={275}
                                              data-week="Fri"
                                              data-month="sep"
                                              className="elCalendar__sell"
                                            >
                                              <span className="js-date">6</span>
                                            </div>
                                            <div
                                              data-index={276}
                                              data-week="Sat"
                                              data-month="sep"
                                              className="elCalendar__sell"
                                            >
                                              <span className="js-date">7</span>
                                            </div>
                                            <div
                                              data-index={277}
                                              data-week="Sun"
                                              data-month="sep"
                                              className="elCalendar__sell"
                                            >
                                              <span className="js-date">8</span>
                                            </div>
                                            <div
                                              data-index={278}
                                              data-week="Mon"
                                              data-month="sep"
                                              className="elCalendar__sell"
                                            >
                                              <span className="js-date">9</span>
                                            </div>
                                            <div
                                              data-index={279}
                                              data-week="Tue"
                                              data-month="sep"
                                              className="elCalendar__sell"
                                            >
                                              <span className="js-date">
                                                10
                                              </span>
                                            </div>
                                            <div
                                              data-index={280}
                                              data-week="Wed"
                                              data-month="sep"
                                              className="elCalendar__sell"
                                            >
                                              <span className="js-date">
                                                11
                                              </span>
                                            </div>
                                            <div
                                              data-index={281}
                                              data-week="Thu"
                                              data-month="sep"
                                              className="elCalendar__sell"
                                            >
                                              <span className="js-date">
                                                12
                                              </span>
                                            </div>
                                            <div
                                              data-index={282}
                                              data-week="Fri"
                                              data-month="sep"
                                              className="elCalendar__sell"
                                            >
                                              <span className="js-date">
                                                13
                                              </span>
                                            </div>
                                            <div
                                              data-index={283}
                                              data-week="Sat"
                                              data-month="sep"
                                              className="elCalendar__sell"
                                            >
                                              <span className="js-date">
                                                14
                                              </span>
                                            </div>
                                            <div
                                              data-index={284}
                                              data-week="Sun"
                                              data-month="sep"
                                              className="elCalendar__sell"
                                            >
                                              <span className="js-date">
                                                15
                                              </span>
                                            </div>
                                            <div
                                              data-index={285}
                                              data-week="Mon"
                                              data-month="sep"
                                              className="elCalendar__sell"
                                            >
                                              <span className="js-date">
                                                16
                                              </span>
                                            </div>
                                            <div
                                              data-index={286}
                                              data-week="Tue"
                                              data-month="sep"
                                              className="elCalendar__sell"
                                            >
                                              <span className="js-date">
                                                17
                                              </span>
                                            </div>
                                            <div
                                              data-index={287}
                                              data-week="Wed"
                                              data-month="sep"
                                              className="elCalendar__sell"
                                            >
                                              <span className="js-date">
                                                18
                                              </span>
                                            </div>
                                            <div
                                              data-index={288}
                                              data-week="Thu"
                                              data-month="sep"
                                              className="elCalendar__sell"
                                            >
                                              <span className="js-date">
                                                19
                                              </span>
                                            </div>
                                            <div
                                              data-index={289}
                                              data-week="Fri"
                                              data-month="sep"
                                              className="elCalendar__sell"
                                            >
                                              <span className="js-date">
                                                20
                                              </span>
                                            </div>
                                            <div
                                              data-index={290}
                                              data-week="Sat"
                                              data-month="sep"
                                              className="elCalendar__sell"
                                            >
                                              <span className="js-date">
                                                21
                                              </span>
                                            </div>
                                            <div
                                              data-index={291}
                                              data-week="Sun"
                                              data-month="sep"
                                              className="elCalendar__sell"
                                            >
                                              <span className="js-date">
                                                22
                                              </span>
                                            </div>
                                            <div
                                              data-index={292}
                                              data-week="Mon"
                                              data-month="sep"
                                              className="elCalendar__sell"
                                            >
                                              <span className="js-date">
                                                23
                                              </span>
                                            </div>
                                            <div
                                              data-index={293}
                                              data-week="Tue"
                                              data-month="sep"
                                              className="elCalendar__sell"
                                            >
                                              <span className="js-date">
                                                24
                                              </span>
                                            </div>
                                            <div
                                              data-index={294}
                                              data-week="Wed"
                                              data-month="sep"
                                              className="elCalendar__sell"
                                            >
                                              <span className="js-date">
                                                25
                                              </span>
                                            </div>
                                            <div
                                              data-index={295}
                                              data-week="Thu"
                                              data-month="sep"
                                              className="elCalendar__sell"
                                            >
                                              <span className="js-date">
                                                26
                                              </span>
                                            </div>
                                            <div
                                              data-index={296}
                                              data-week="Fri"
                                              data-month="sep"
                                              className="elCalendar__sell"
                                            >
                                              <span className="js-date">
                                                27
                                              </span>
                                            </div>
                                            <div
                                              data-index={297}
                                              data-week="Sat"
                                              data-month="sep"
                                              className="elCalendar__sell"
                                            >
                                              <span className="js-date">
                                                28
                                              </span>
                                            </div>
                                            <div
                                              data-index={298}
                                              data-week="Sun"
                                              data-month="sep"
                                              className="elCalendar__sell"
                                            >
                                              <span className="js-date">
                                                29
                                              </span>
                                            </div>
                                            <div
                                              data-index={299}
                                              data-week="Mon"
                                              data-month="sep"
                                              className="elCalendar__sell"
                                            >
                                              <span className="js-date">
                                                30
                                              </span>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div
                                        className="swiper-slide"
                                        role="group"
                                        aria-label="10 / 12"
                                        style={{ width: "420px" }}
                                      >
                                        <div className="capitalize text-28 fw-500 text-center mb-10">
                                          october 2024
                                        </div>
                                        <div className="elCalendar__month">
                                          <div className="elCalendar__header">
                                            <div className="elCalendar__header__sell">
                                              Sun
                                            </div>
                                            <div className="elCalendar__header__sell">
                                              Mon
                                            </div>
                                            <div className="elCalendar__header__sell">
                                              Tue
                                            </div>
                                            <div className="elCalendar__header__sell">
                                              Wed
                                            </div>
                                            <div className="elCalendar__header__sell">
                                              Thu
                                            </div>
                                            <div className="elCalendar__header__sell">
                                              Fri
                                            </div>
                                            <div className="elCalendar__header__sell">
                                              Sat
                                            </div>
                                          </div>
                                          <div className="elCalendar__body">
                                            <div
                                              data-index={300}
                                              data-week="Sun"
                                              data-month="oct"
                                              className="elCalendar__sell -dark"
                                            >
                                              <span className="js-date">
                                                29
                                              </span>
                                            </div>
                                            <div
                                              data-index={301}
                                              data-week="Mon"
                                              data-month="oct"
                                              className="elCalendar__sell -dark"
                                            >
                                              <span className="js-date">
                                                30
                                              </span>
                                            </div>
                                            <div
                                              data-index={302}
                                              data-week="Tue"
                                              data-month="oct"
                                              className="elCalendar__sell"
                                            >
                                              <span className="js-date">1</span>
                                            </div>
                                            <div
                                              data-index={303}
                                              data-week="Wed"
                                              data-month="oct"
                                              className="elCalendar__sell"
                                            >
                                              <span className="js-date">2</span>
                                            </div>
                                            <div
                                              data-index={304}
                                              data-week="Thu"
                                              data-month="oct"
                                              className="elCalendar__sell"
                                            >
                                              <span className="js-date">3</span>
                                            </div>
                                            <div
                                              data-index={305}
                                              data-week="Fri"
                                              data-month="oct"
                                              className="elCalendar__sell"
                                            >
                                              <span className="js-date">4</span>
                                            </div>
                                            <div
                                              data-index={306}
                                              data-week="Sat"
                                              data-month="oct"
                                              className="elCalendar__sell"
                                            >
                                              <span className="js-date">5</span>
                                            </div>
                                            <div
                                              data-index={307}
                                              data-week="Sun"
                                              data-month="oct"
                                              className="elCalendar__sell"
                                            >
                                              <span className="js-date">6</span>
                                            </div>
                                            <div
                                              data-index={308}
                                              data-week="Mon"
                                              data-month="oct"
                                              className="elCalendar__sell"
                                            >
                                              <span className="js-date">7</span>
                                            </div>
                                            <div
                                              data-index={309}
                                              data-week="Tue"
                                              data-month="oct"
                                              className="elCalendar__sell"
                                            >
                                              <span className="js-date">8</span>
                                            </div>
                                            <div
                                              data-index={310}
                                              data-week="Wed"
                                              data-month="oct"
                                              className="elCalendar__sell"
                                            >
                                              <span className="js-date">9</span>
                                            </div>
                                            <div
                                              data-index={311}
                                              data-week="Thu"
                                              data-month="oct"
                                              className="elCalendar__sell"
                                            >
                                              <span className="js-date">
                                                10
                                              </span>
                                            </div>
                                            <div
                                              data-index={312}
                                              data-week="Fri"
                                              data-month="oct"
                                              className="elCalendar__sell"
                                            >
                                              <span className="js-date">
                                                11
                                              </span>
                                            </div>
                                            <div
                                              data-index={313}
                                              data-week="Sat"
                                              data-month="oct"
                                              className="elCalendar__sell"
                                            >
                                              <span className="js-date">
                                                12
                                              </span>
                                            </div>
                                            <div
                                              data-index={314}
                                              data-week="Sun"
                                              data-month="oct"
                                              className="elCalendar__sell"
                                            >
                                              <span className="js-date">
                                                13
                                              </span>
                                            </div>
                                            <div
                                              data-index={315}
                                              data-week="Mon"
                                              data-month="oct"
                                              className="elCalendar__sell"
                                            >
                                              <span className="js-date">
                                                14
                                              </span>
                                            </div>
                                            <div
                                              data-index={316}
                                              data-week="Tue"
                                              data-month="oct"
                                              className="elCalendar__sell"
                                            >
                                              <span className="js-date">
                                                15
                                              </span>
                                            </div>
                                            <div
                                              data-index={317}
                                              data-week="Wed"
                                              data-month="oct"
                                              className="elCalendar__sell"
                                            >
                                              <span className="js-date">
                                                16
                                              </span>
                                            </div>
                                            <div
                                              data-index={318}
                                              data-week="Thu"
                                              data-month="oct"
                                              className="elCalendar__sell"
                                            >
                                              <span className="js-date">
                                                17
                                              </span>
                                            </div>
                                            <div
                                              data-index={319}
                                              data-week="Fri"
                                              data-month="oct"
                                              className="elCalendar__sell"
                                            >
                                              <span className="js-date">
                                                18
                                              </span>
                                            </div>
                                            <div
                                              data-index={320}
                                              data-week="Sat"
                                              data-month="oct"
                                              className="elCalendar__sell"
                                            >
                                              <span className="js-date">
                                                19
                                              </span>
                                            </div>
                                            <div
                                              data-index={321}
                                              data-week="Sun"
                                              data-month="oct"
                                              className="elCalendar__sell"
                                            >
                                              <span className="js-date">
                                                20
                                              </span>
                                            </div>
                                            <div
                                              data-index={322}
                                              data-week="Mon"
                                              data-month="oct"
                                              className="elCalendar__sell"
                                            >
                                              <span className="js-date">
                                                21
                                              </span>
                                            </div>
                                            <div
                                              data-index={323}
                                              data-week="Tue"
                                              data-month="oct"
                                              className="elCalendar__sell"
                                            >
                                              <span className="js-date">
                                                22
                                              </span>
                                            </div>
                                            <div
                                              data-index={324}
                                              data-week="Wed"
                                              data-month="oct"
                                              className="elCalendar__sell"
                                            >
                                              <span className="js-date">
                                                23
                                              </span>
                                            </div>
                                            <div
                                              data-index={325}
                                              data-week="Thu"
                                              data-month="oct"
                                              className="elCalendar__sell"
                                            >
                                              <span className="js-date">
                                                24
                                              </span>
                                            </div>
                                            <div
                                              data-index={326}
                                              data-week="Fri"
                                              data-month="oct"
                                              className="elCalendar__sell"
                                            >
                                              <span className="js-date">
                                                25
                                              </span>
                                            </div>
                                            <div
                                              data-index={327}
                                              data-week="Sat"
                                              data-month="oct"
                                              className="elCalendar__sell"
                                            >
                                              <span className="js-date">
                                                26
                                              </span>
                                            </div>
                                            <div
                                              data-index={328}
                                              data-week="Sun"
                                              data-month="oct"
                                              className="elCalendar__sell"
                                            >
                                              <span className="js-date">
                                                27
                                              </span>
                                            </div>
                                            <div
                                              data-index={329}
                                              data-week="Mon"
                                              data-month="oct"
                                              className="elCalendar__sell"
                                            >
                                              <span className="js-date">
                                                28
                                              </span>
                                            </div>
                                            <div
                                              data-index={330}
                                              data-week="Tue"
                                              data-month="oct"
                                              className="elCalendar__sell"
                                            >
                                              <span className="js-date">
                                                29
                                              </span>
                                            </div>
                                            <div
                                              data-index={331}
                                              data-week="Wed"
                                              data-month="oct"
                                              className="elCalendar__sell"
                                            >
                                              <span className="js-date">
                                                30
                                              </span>
                                            </div>
                                            <div
                                              data-index={332}
                                              data-week="Thu"
                                              data-month="oct"
                                              className="elCalendar__sell"
                                            >
                                              <span className="js-date">
                                                31
                                              </span>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div
                                        className="swiper-slide"
                                        role="group"
                                        aria-label="11 / 12"
                                        style={{ width: "420px" }}
                                      >
                                        <div className="capitalize text-28 fw-500 text-center mb-10">
                                          november 2024
                                        </div>
                                        <div className="elCalendar__month">
                                          <div className="elCalendar__header">
                                            <div className="elCalendar__header__sell">
                                              Sun
                                            </div>
                                            <div className="elCalendar__header__sell">
                                              Mon
                                            </div>
                                            <div className="elCalendar__header__sell">
                                              Tue
                                            </div>
                                            <div className="elCalendar__header__sell">
                                              Wed
                                            </div>
                                            <div className="elCalendar__header__sell">
                                              Thu
                                            </div>
                                            <div className="elCalendar__header__sell">
                                              Fri
                                            </div>
                                            <div className="elCalendar__header__sell">
                                              Sat
                                            </div>
                                          </div>
                                          <div className="elCalendar__body">
                                            <div
                                              data-index={333}
                                              data-week="Sun"
                                              data-month="nov"
                                              className="elCalendar__sell -dark"
                                            >
                                              <span className="js-date">
                                                27
                                              </span>
                                            </div>
                                            <div
                                              data-index={334}
                                              data-week="Mon"
                                              data-month="nov"
                                              className="elCalendar__sell -dark"
                                            >
                                              <span className="js-date">
                                                28
                                              </span>
                                            </div>
                                            <div
                                              data-index={335}
                                              data-week="Tue"
                                              data-month="nov"
                                              className="elCalendar__sell -dark"
                                            >
                                              <span className="js-date">
                                                29
                                              </span>
                                            </div>
                                            <div
                                              data-index={336}
                                              data-week="Wed"
                                              data-month="nov"
                                              className="elCalendar__sell -dark"
                                            >
                                              <span className="js-date">
                                                30
                                              </span>
                                            </div>
                                            <div
                                              data-index={337}
                                              data-week="Thu"
                                              data-month="nov"
                                              className="elCalendar__sell -dark"
                                            >
                                              <span className="js-date">
                                                31
                                              </span>
                                            </div>
                                            <div
                                              data-index={338}
                                              data-week="Fri"
                                              data-month="nov"
                                              className="elCalendar__sell"
                                            >
                                              <span className="js-date">1</span>
                                            </div>
                                            <div
                                              data-index={339}
                                              data-week="Sat"
                                              data-month="nov"
                                              className="elCalendar__sell"
                                            >
                                              <span className="js-date">2</span>
                                            </div>
                                            <div
                                              data-index={340}
                                              data-week="Sun"
                                              data-month="nov"
                                              className="elCalendar__sell"
                                            >
                                              <span className="js-date">3</span>
                                            </div>
                                            <div
                                              data-index={341}
                                              data-week="Mon"
                                              data-month="nov"
                                              className="elCalendar__sell"
                                            >
                                              <span className="js-date">4</span>
                                            </div>
                                            <div
                                              data-index={342}
                                              data-week="Tue"
                                              data-month="nov"
                                              className="elCalendar__sell"
                                            >
                                              <span className="js-date">5</span>
                                            </div>
                                            <div
                                              data-index={343}
                                              data-week="Wed"
                                              data-month="nov"
                                              className="elCalendar__sell"
                                            >
                                              <span className="js-date">6</span>
                                            </div>
                                            <div
                                              data-index={344}
                                              data-week="Thu"
                                              data-month="nov"
                                              className="elCalendar__sell"
                                            >
                                              <span className="js-date">7</span>
                                            </div>
                                            <div
                                              data-index={345}
                                              data-week="Fri"
                                              data-month="nov"
                                              className="elCalendar__sell"
                                            >
                                              <span className="js-date">8</span>
                                            </div>
                                            <div
                                              data-index={346}
                                              data-week="Sat"
                                              data-month="nov"
                                              className="elCalendar__sell"
                                            >
                                              <span className="js-date">9</span>
                                            </div>
                                            <div
                                              data-index={347}
                                              data-week="Sun"
                                              data-month="nov"
                                              className="elCalendar__sell"
                                            >
                                              <span className="js-date">
                                                10
                                              </span>
                                            </div>
                                            <div
                                              data-index={348}
                                              data-week="Mon"
                                              data-month="nov"
                                              className="elCalendar__sell"
                                            >
                                              <span className="js-date">
                                                11
                                              </span>
                                            </div>
                                            <div
                                              data-index={349}
                                              data-week="Tue"
                                              data-month="nov"
                                              className="elCalendar__sell"
                                            >
                                              <span className="js-date">
                                                12
                                              </span>
                                            </div>
                                            <div
                                              data-index={350}
                                              data-week="Wed"
                                              data-month="nov"
                                              className="elCalendar__sell"
                                            >
                                              <span className="js-date">
                                                13
                                              </span>
                                            </div>
                                            <div
                                              data-index={351}
                                              data-week="Thu"
                                              data-month="nov"
                                              className="elCalendar__sell"
                                            >
                                              <span className="js-date">
                                                14
                                              </span>
                                            </div>
                                            <div
                                              data-index={352}
                                              data-week="Fri"
                                              data-month="nov"
                                              className="elCalendar__sell"
                                            >
                                              <span className="js-date">
                                                15
                                              </span>
                                            </div>
                                            <div
                                              data-index={353}
                                              data-week="Sat"
                                              data-month="nov"
                                              className="elCalendar__sell"
                                            >
                                              <span className="js-date">
                                                16
                                              </span>
                                            </div>
                                            <div
                                              data-index={354}
                                              data-week="Sun"
                                              data-month="nov"
                                              className="elCalendar__sell"
                                            >
                                              <span className="js-date">
                                                17
                                              </span>
                                            </div>
                                            <div
                                              data-index={355}
                                              data-week="Mon"
                                              data-month="nov"
                                              className="elCalendar__sell"
                                            >
                                              <span className="js-date">
                                                18
                                              </span>
                                            </div>
                                            <div
                                              data-index={356}
                                              data-week="Tue"
                                              data-month="nov"
                                              className="elCalendar__sell"
                                            >
                                              <span className="js-date">
                                                19
                                              </span>
                                            </div>
                                            <div
                                              data-index={357}
                                              data-week="Wed"
                                              data-month="nov"
                                              className="elCalendar__sell"
                                            >
                                              <span className="js-date">
                                                20
                                              </span>
                                            </div>
                                            <div
                                              data-index={358}
                                              data-week="Thu"
                                              data-month="nov"
                                              className="elCalendar__sell"
                                            >
                                              <span className="js-date">
                                                21
                                              </span>
                                            </div>
                                            <div
                                              data-index={359}
                                              data-week="Fri"
                                              data-month="nov"
                                              className="elCalendar__sell"
                                            >
                                              <span className="js-date">
                                                22
                                              </span>
                                            </div>
                                            <div
                                              data-index={360}
                                              data-week="Sat"
                                              data-month="nov"
                                              className="elCalendar__sell"
                                            >
                                              <span className="js-date">
                                                23
                                              </span>
                                            </div>
                                            <div
                                              data-index={361}
                                              data-week="Sun"
                                              data-month="nov"
                                              className="elCalendar__sell"
                                            >
                                              <span className="js-date">
                                                24
                                              </span>
                                            </div>
                                            <div
                                              data-index={362}
                                              data-week="Mon"
                                              data-month="nov"
                                              className="elCalendar__sell"
                                            >
                                              <span className="js-date">
                                                25
                                              </span>
                                            </div>
                                            <div
                                              data-index={363}
                                              data-week="Tue"
                                              data-month="nov"
                                              className="elCalendar__sell"
                                            >
                                              <span className="js-date">
                                                26
                                              </span>
                                            </div>
                                            <div
                                              data-index={364}
                                              data-week="Wed"
                                              data-month="nov"
                                              className="elCalendar__sell"
                                            >
                                              <span className="js-date">
                                                27
                                              </span>
                                            </div>
                                            <div
                                              data-index={365}
                                              data-week="Thu"
                                              data-month="nov"
                                              className="elCalendar__sell"
                                            >
                                              <span className="js-date">
                                                28
                                              </span>
                                            </div>
                                            <div
                                              data-index={366}
                                              data-week="Fri"
                                              data-month="nov"
                                              className="elCalendar__sell"
                                            >
                                              <span className="js-date">
                                                29
                                              </span>
                                            </div>
                                            <div
                                              data-index={367}
                                              data-week="Sat"
                                              data-month="nov"
                                              className="elCalendar__sell"
                                            >
                                              <span className="js-date">
                                                30
                                              </span>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div
                                        className="swiper-slide"
                                        role="group"
                                        aria-label="12 / 12"
                                        style={{ width: "420px" }}
                                      >
                                        <div className="capitalize text-28 fw-500 text-center mb-10">
                                          december 2024
                                        </div>
                                        <div className="elCalendar__month">
                                          <div className="elCalendar__header">
                                            <div className="elCalendar__header__sell">
                                              Sun
                                            </div>
                                            <div className="elCalendar__header__sell">
                                              Mon
                                            </div>
                                            <div className="elCalendar__header__sell">
                                              Tue
                                            </div>
                                            <div className="elCalendar__header__sell">
                                              Wed
                                            </div>
                                            <div className="elCalendar__header__sell">
                                              Thu
                                            </div>
                                            <div className="elCalendar__header__sell">
                                              Fri
                                            </div>
                                            <div className="elCalendar__header__sell">
                                              Sat
                                            </div>
                                          </div>
                                          <div className="elCalendar__body">
                                            <div
                                              data-index={368}
                                              data-week="Sun"
                                              data-month="dec"
                                              className="elCalendar__sell"
                                            >
                                              <span className="js-date">1</span>
                                            </div>
                                            <div
                                              data-index={369}
                                              data-week="Mon"
                                              data-month="dec"
                                              className="elCalendar__sell"
                                            >
                                              <span className="js-date">2</span>
                                            </div>
                                            <div
                                              data-index={370}
                                              data-week="Tue"
                                              data-month="dec"
                                              className="elCalendar__sell"
                                            >
                                              <span className="js-date">3</span>
                                            </div>
                                            <div
                                              data-index={371}
                                              data-week="Wed"
                                              data-month="dec"
                                              className="elCalendar__sell"
                                            >
                                              <span className="js-date">4</span>
                                            </div>
                                            <div
                                              data-index={372}
                                              data-week="Thu"
                                              data-month="dec"
                                              className="elCalendar__sell"
                                            >
                                              <span className="js-date">5</span>
                                            </div>
                                            <div
                                              data-index={373}
                                              data-week="Fri"
                                              data-month="dec"
                                              className="elCalendar__sell"
                                            >
                                              <span className="js-date">6</span>
                                            </div>
                                            <div
                                              data-index={374}
                                              data-week="Sat"
                                              data-month="dec"
                                              className="elCalendar__sell"
                                            >
                                              <span className="js-date">7</span>
                                            </div>
                                            <div
                                              data-index={375}
                                              data-week="Sun"
                                              data-month="dec"
                                              className="elCalendar__sell"
                                            >
                                              <span className="js-date">8</span>
                                            </div>
                                            <div
                                              data-index={376}
                                              data-week="Mon"
                                              data-month="dec"
                                              className="elCalendar__sell"
                                            >
                                              <span className="js-date">9</span>
                                            </div>
                                            <div
                                              data-index={377}
                                              data-week="Tue"
                                              data-month="dec"
                                              className="elCalendar__sell"
                                            >
                                              <span className="js-date">
                                                10
                                              </span>
                                            </div>
                                            <div
                                              data-index={378}
                                              data-week="Wed"
                                              data-month="dec"
                                              className="elCalendar__sell"
                                            >
                                              <span className="js-date">
                                                11
                                              </span>
                                            </div>
                                            <div
                                              data-index={379}
                                              data-week="Thu"
                                              data-month="dec"
                                              className="elCalendar__sell"
                                            >
                                              <span className="js-date">
                                                12
                                              </span>
                                            </div>
                                            <div
                                              data-index={380}
                                              data-week="Fri"
                                              data-month="dec"
                                              className="elCalendar__sell"
                                            >
                                              <span className="js-date">
                                                13
                                              </span>
                                            </div>
                                            <div
                                              data-index={381}
                                              data-week="Sat"
                                              data-month="dec"
                                              className="elCalendar__sell"
                                            >
                                              <span className="js-date">
                                                14
                                              </span>
                                            </div>
                                            <div
                                              data-index={382}
                                              data-week="Sun"
                                              data-month="dec"
                                              className="elCalendar__sell"
                                            >
                                              <span className="js-date">
                                                15
                                              </span>
                                            </div>
                                            <div
                                              data-index={383}
                                              data-week="Mon"
                                              data-month="dec"
                                              className="elCalendar__sell"
                                            >
                                              <span className="js-date">
                                                16
                                              </span>
                                            </div>
                                            <div
                                              data-index={384}
                                              data-week="Tue"
                                              data-month="dec"
                                              className="elCalendar__sell"
                                            >
                                              <span className="js-date">
                                                17
                                              </span>
                                            </div>
                                            <div
                                              data-index={385}
                                              data-week="Wed"
                                              data-month="dec"
                                              className="elCalendar__sell"
                                            >
                                              <span className="js-date">
                                                18
                                              </span>
                                            </div>
                                            <div
                                              data-index={386}
                                              data-week="Thu"
                                              data-month="dec"
                                              className="elCalendar__sell"
                                            >
                                              <span className="js-date">
                                                19
                                              </span>
                                            </div>
                                            <div
                                              data-index={387}
                                              data-week="Fri"
                                              data-month="dec"
                                              className="elCalendar__sell"
                                            >
                                              <span className="js-date">
                                                20
                                              </span>
                                            </div>
                                            <div
                                              data-index={388}
                                              data-week="Sat"
                                              data-month="dec"
                                              className="elCalendar__sell"
                                            >
                                              <span className="js-date">
                                                21
                                              </span>
                                            </div>
                                            <div
                                              data-index={389}
                                              data-week="Sun"
                                              data-month="dec"
                                              className="elCalendar__sell"
                                            >
                                              <span className="js-date">
                                                22
                                              </span>
                                            </div>
                                            <div
                                              data-index={390}
                                              data-week="Mon"
                                              data-month="dec"
                                              className="elCalendar__sell"
                                            >
                                              <span className="js-date">
                                                23
                                              </span>
                                            </div>
                                            <div
                                              data-index={391}
                                              data-week="Tue"
                                              data-month="dec"
                                              className="elCalendar__sell"
                                            >
                                              <span className="js-date">
                                                24
                                              </span>
                                            </div>
                                            <div
                                              data-index={392}
                                              data-week="Wed"
                                              data-month="dec"
                                              className="elCalendar__sell"
                                            >
                                              <span className="js-date">
                                                25
                                              </span>
                                            </div>
                                            <div
                                              data-index={393}
                                              data-week="Thu"
                                              data-month="dec"
                                              className="elCalendar__sell"
                                            >
                                              <span className="js-date">
                                                26
                                              </span>
                                            </div>
                                            <div
                                              data-index={394}
                                              data-week="Fri"
                                              data-month="dec"
                                              className="elCalendar__sell"
                                            >
                                              <span className="js-date">
                                                27
                                              </span>
                                            </div>
                                            <div
                                              data-index={395}
                                              data-week="Sat"
                                              data-month="dec"
                                              className="elCalendar__sell"
                                            >
                                              <span className="js-date">
                                                28
                                              </span>
                                            </div>
                                            <div
                                              data-index={396}
                                              data-week="Sun"
                                              data-month="dec"
                                              className="elCalendar__sell"
                                            >
                                              <span className="js-date">
                                                29
                                              </span>
                                            </div>
                                            <div
                                              data-index={397}
                                              data-week="Mon"
                                              data-month="dec"
                                              className="elCalendar__sell"
                                            >
                                              <span className="js-date">
                                                30
                                              </span>
                                            </div>
                                            <div
                                              data-index={398}
                                              data-week="Tue"
                                              data-month="dec"
                                              className="elCalendar__sell"
                                            >
                                              <span className="js-date">
                                                31
                                              </span>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <button
                                      className="elCalendar__sliderNav -prev flex-center js-calendar-slider-prev swiper-buttonh-disabled"
                                      disabled
                                      tabIndex={-1}
                                      aria-label="Previous slide"
                                      aria-controls="swiper-wrapper-a8ae4bf74a637ca9"
                                      aria-disabled="true"
                                    >
                                      {/* <i className="icon icon-chevron-left text-14" /> */}
                                    </button>
                                    <button
                                      className="elCalendar__sliderNav -next flex-center js-calendar-slider-next"
                                      tabIndex={0}
                                      aria-label="Next slide"
                                      aria-controls="swiper-wrapper-a8ae4bf74a637ca9"
                                      aria-disabled="false"
                                    >
                                      {/* <i className="icon icon-chevron-right text-14" /> */}
                                    </button>
                                    <span
                                      className="swiper-notification"
                                      aria-live="assertive"
                                      aria-atomic="true"
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-12">
                          <div className="searchMenu-guests px-20 py-10 border-light rounded-4 js-form-dd js-form-counters">
                            <div data-x-dd-click="searchMenu-guests">
                              <h4 className="text-15 fw-500 ls-2 lh-16">
                                Number of travelers
                              </h4>
                              <div className="text-15 text-light-1 ls-2 lh-16">
                                <span className="js-count-adult">2</span> adults
                                -<span className="js-count-child">1</span>{" "}
                                childeren -
                                <span className="js-count-room">1</span> room
                              </div>
                            </div>
                            <div
                              className="searchMenu-guests__field shadow-2"
                              data-x-dd="searchMenu-guests"
                              data-x-dd-toggle="-is-active"
                            >
                              <div className="bg-white px-30 py-30 rounded-4">
                                <div className="row y-gap-10 justify-between items-center">
                                  <div className="col-autoh">
                                    <div className="text-15 fw-500">Adults</div>
                                  </div>
                                  <div className="col-autoh">
                                    <div
                                      className="d-flex items-center js-counter"
                                      data-value-change=".js-count-adult"
                                    >
                                      <button className="buttonh -outline-blue-1 text-blue-1 size-38 rounded-4 js-down">
                                        {/* <i className="icon-minus text-12" /> */}
                                      </button>
                                      <div className="flex-center size-20 ml-15 mr-15">
                                        <div className="text-15 js-count">
                                          2
                                        </div>
                                      </div>
                                      <button className="buttonh -outline-blue-1 text-blue-1 size-38 rounded-4 js-up">
                                        {/* <i className="icon-plus text-12" /> */}
                                      </button>
                                    </div>
                                  </div>
                                </div>
                                <div className="border-top-light mt-24 mb-24" />
                                <div className="row y-gap-10 justify-between items-center">
                                  <div className="col-autoh">
                                    <div className="text-15 lh-12 fw-500">
                                      Children
                                    </div>
                                    <div className="text-14 lh-12 text-light-1 mt-5">
                                      Ages 0 - 17
                                    </div>
                                  </div>
                                  <div className="col-autoh">
                                    <div
                                      className="d-flex items-center js-counter"
                                      data-value-change=".js-count-child"
                                    >
                                      <button className="buttonh -outline-blue-1 text-blue-1 size-38 rounded-4 js-down">
                                        {/* <i className="icon-minus text-12" /> */}
                                      </button>
                                      <div className="flex-center size-20 ml-15 mr-15">
                                        <div className="text-15 js-count">
                                          1
                                        </div>
                                      </div>
                                      <button className="buttonh -outline-blue-1 text-blue-1 size-38 rounded-4 js-up">
                                        {/* <i className="icon-plus text-12" /> */}
                                      </button>
                                    </div>
                                  </div>
                                </div>
                                <div className="border-top-light mt-24 mb-24" />
                                <div className="row y-gap-10 justify-between items-center">
                                  <div className="col-autoh">
                                    <div className="text-15 fw-500">Rooms</div>
                                  </div>
                                  <div className="col-autoh">
                                    <div
                                      className="d-flex items-center js-counter"
                                      data-value-change=".js-count-room"
                                    >
                                      <button className="buttonh -outline-blue-1 text-blue-1 size-38 rounded-4 js-down">
                                        {/* <i className="icon-minus text-12" /> */}
                                      </button>
                                      <div className="flex-center size-20 ml-15 mr-15">
                                        <div className="text-15 js-count">
                                          1
                                        </div>
                                      </div>
                                      <button className="buttonh -outline-blue-1 text-blue-1 size-38 rounded-4 js-up">
                                        {/* <i className="icon-plus text-12" /> */}
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-12">
                          <button className="buttonh -dark-1 py-15 px-35 h-60 col-12 rounded-4 bg-blue-1 text-white">
                            Book Now
                          </button>
                        </div>
                      </div>
                     
                    </div>
                  
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="pt-40">
        <div className="containerh">
          <div className="pt-40 border-top-light">
            <div className="row x-gap-40 y-gap-40">
              <div className="col-autoh">
                <h3 className="text-22 fw-500">Important information</h3>
              </div>
            </div>
            <div className="row x-gap-40 y-gap-40 justify-between pt-20">
              <div className="col-lg-4 col-md-6">
                <div className="fw-500 mb-10">Inclusions</div>
                <ul className="list-disc">
                  <li>Superior Coach, Wi-Fi and USB Charging On-board</li>
                  <li>Expert guide</li>
                  <li>Admission to Windsor Castle (if option selected)</li>
                  <li>Admission to Stonehenge</li>
                </ul>
              </div>
              <div className="col-lg-4 col-md-6">
                <div className="fw-500 mb-10">Departure details</div>
                <div className="text-15">
                  Departures from 01st April 2022: Tour departs at 8 am
                  (boarding at 7.30 am), Victoria Coach Station Gate 1-5
                </div>
              </div>
              <div className="col-lg-3 col-md-6">
                <div className="fw-500 mb-10">Know before you go</div>
                <ul className="list-disc">
                  <li>Duration: 11h</li>
                  <li>Mobile tickets accepted</li>
                  <li>Instant confirmation</li>
                </ul>
              </div>
              <div className="col-lg-4 col-md-6">
                <div className="fw-500 mb-10">Exclusions</div>
                <ul className="list-disc">
                  <li>Hotel pick-up and drop-off</li>
                  <li>Gratuities</li>
                  <li>Lunch</li>
                </ul>
              </div>
              <div className="col-12">
                <div className="fw-500 mb-10">Additional information</div>
                <ul className="list-disc">
                  <li>Confirmation will be received at time of booking</li>
                  <li>
                    Departs at 8am (boarding at 7.30am), Victoria Coach Station
                    Gate 1-5, 164 Buckingham Palace Road, London, SW1W 9TP
                  </li>
                  <li>
                    As Windsor Castle is a working royal palace, sometimes the
                    entire Castle or the State Apartments within the Castle need
                    to be closed at short notice. (if selected this option)
                  </li>
                  <li>
                    Stonehenge is closed on 21 June due to the Summer Solstice.
                    During this time, we will instead visit the Avebury Stone
                    Circle.
                  </li>
                  <li>Please note: the tour itinerary and order may change</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="containerh mt-40 mb-40">
        <div className="border-top-light" />
      </div>
      <section>
        <div className="containerh">
          <h3 className="text-22 fw-500 mb-20">Itinerary</h3>
          <div className="row y-gap-30">
            <div className="col-lg-4">
              <div className="relative">
                <div className="border-test" />
                <div className="accordion -map row y-gap-20 js-accordion">
                  <div className="col-12">
                    <div className="accordion__item ">
                      <div className="d-flex">
                        <div className="accordion__icon size-40 flex-center bg-blue-2 text-blue-1 rounded-full">
                          <div className="text-14 fw-500">1</div>
                        </div>
                        <div className="ml-20">
                          <div className="text-16 lh-15 fw-500">
                            Windsor Castle
                          </div>
                          <div className="text-14 lh-15 text-light-1 mt-5">
                            Stop: 60 minutes - Admission included
                          </div>
                          <div className="accordion__content">
                            <div className="pt-15 pb-15">
                              <img
                                src="https://creativelayers.net/themes/gotrip-html/img/lists/tour/single/2.png"
                                alt="image"
                                className="rounded-4 mt-15"
                              />
                              <div className="text-14 lh-17 mt-15">
                                Our first stop is Windsor Castle, the ancestral
                                home of the British Royal family for more than
                                900 years and the largest, continuously occupied
                                castle in Europe.
                              </div>
                            </div>
                          </div>
                          <div className="accordion__button">
                            <button
                              data-open-change-title="See less"
                              className="d-block lh-15 text-14 text-blue-1 underline fw-500 mt-5"
                            >
                              See details &amp; photo
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="accordion__item js-accordion-item-active is-active">
                      <div className="d-flex">
                        <div className="accordion__icon size-40 flex-center bg-blue-2 text-blue-1 rounded-full">
                          <div className="text-14 fw-500">2</div>
                        </div>
                        <div className="ml-20">
                          <div className="text-16 lh-15 fw-500">
                            St. George's Chapel
                          </div>
                          <div className="text-14 lh-15 text-light-1 mt-5">
                            Stop: 60 minutes - Admission included
                          </div>
                          <div
                            className="accordion__content"
                            style={{ maxHeight: "100%" }}
                          >
                            <div className="pt-15 pb-15">
                              <img
                                src="https://creativelayers.net/themes/gotrip-html/img/lists/tour/single/2.png"
                                alt="image"
                                className="rounded-4 mt-15"
                              />
                              <div className="text-14 lh-17 mt-15">
                                Our first stop is Windsor Castle, the ancestral
                                home of the British Royal family for more than
                                900 years and the largest, continuously occupied
                                castle in Europe.
                              </div>
                            </div>
                          </div>
                          <div className="accordion__button">
                            <button
                              data-open-change-title="See less"
                              className="d-block lh-15 text-14 text-blue-1 underline fw-500 mt-5"
                            >
                              See details &amp; photo
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="accordion__item ">
                      <div className="d-flex">
                        <div className="accordion__icon size-40 flex-center bg-blue-2 text-blue-1 rounded-full">
                          <div className="text-14 fw-500">3</div>
                        </div>
                        <div className="ml-20">
                          <div className="text-16 lh-15 fw-500">
                            The Roman Baths
                          </div>
                          <div className="text-14 lh-15 text-light-1 mt-5">
                            Stop: 60 minutes - Admission included
                          </div>
                          <div className="accordion__content">
                            <div className="pt-15 pb-15">
                              <img
                                src="https://creativelayers.net/themes/gotrip-html/img/lists/tour/single/2.png"
                                alt="image"
                                className="rounded-4 mt-15"
                              />
                              <div className="text-14 lh-17 mt-15">
                                Our first stop is Windsor Castle, the ancestral
                                home of the British Royal family for more than
                                900 years and the largest, continuously occupied
                                castle in Europe.
                              </div>
                            </div>
                          </div>
                          <div className="accordion__button">
                            <button
                              data-open-change-title="See less"
                              className="d-block lh-15 text-14 text-blue-1 underline fw-500 mt-5"
                            >
                              See details &amp; photo
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="accordion__item ">
                      <div className="d-flex">
                        <div className="accordion__icon size-40 flex-center bg-blue-2 text-blue-1 rounded-full">
                          <div className="text-14 fw-500">4</div>
                        </div>
                        <div className="ml-20">
                          <div className="text-16 lh-15 fw-500">Stonehenge</div>
                          <div className="text-14 lh-15 text-light-1 mt-5">
                            Stop: 60 minutes - Admission included
                          </div>
                          <div className="accordion__content">
                            <div className="pt-15 pb-15">
                              <img
                                src="https://creativelayers.net/themes/gotrip-html/img/lists/tour/single/2.png"
                                alt="image"
                                className="rounded-4 mt-15"
                              />
                              <div className="text-14 lh-17 mt-15">
                                Our first stop is Windsor Castle, the ancestral
                                home of the British Royal family for more than
                                900 years and the largest, continuously occupied
                                castle in Europe.
                              </div>
                            </div>
                          </div>
                          <div className="accordion__button">
                            <button
                              data-open-change-title="See less"
                              className="d-block lh-15 text-14 text-blue-1 underline fw-500 mt-5"
                            >
                              See details &amp; photo
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-8">
              <div
                className="map rounded-4 js-map-places"
                style={{ position: "relative", overflow: "hidden" }}
              >
               
               <Map
                      google={props.google}
                      zoom={14}
                      style={{ width: "100%"}}
                      initialCenter={{ lat: lat, lng: log }}
                    >
                      <Marker position={{ lat: lat, lng: log }} />
                    </Map>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="containerh mt-40 mb-40">
        <div className="border-top-light" />
      </div>

      <div className="containerh">
        <div className="mt-50 border-top-light" />
      </div>
      <section className="layout-pt-lg layout-pb-lg">
        <div className="containerh">
          <div className="row justify-between items-end">
            <div className="col-autoh">
              <div className="sectionTitle -md">
                <h2 className="sectionTitle__title">Similar Experiences</h2>
                <p className=" sectionTitle__text mt-5 sm:mt-0">
                  Interdum et malesuada fames ac ante ipsum
                </p>
              </div>
            </div>
            <div className="col-autoh">
              <a
                href="#"
                className="buttonh h-50 px-24 -blue-1 bg-blue-1-05 text-blue-1"
              >
                See All <div className="icon-arrow-top-right ml-15" />
              </a>
            </div>
          </div>
          <div className="row y-gap-30 pt-40 sm:pt-20">
            <div className="col-xl-3 col-lg-3 col-sm-6">
              <a
                href="/Exclusivepackagedetails"
                className="tourCard -type-1 rounded-4 "
              >
                <div className="tourCard__image">
                  <div className="cardImage ratio ratio-1:1">
                    <div className="cardImage__content">
                      <img
                        className="rounded-4 col-12"
                        src="https://creativelayers.net/themes/gotrip-html/img/tours/1.png"
                        alt="image"
                      />
                    </div>
                   
                   
                  </div>
                </div>
                <div className="tourCard__content mt-10">
                  <div className="d-flex items-center lh-14 mb-5">
                    <div className="text-14 text-light-1">16+ hours</div>
                    <div className="size-3 bg-light-1 rounded-full ml-10 mr-10" />
                    <div className="text-14 text-light-1">Full-day Tours</div>
                  </div>
                  <h4 className="tourCard__title text-dark-1 text-18 lh-16 fw-500">
                    <span>
                      Stonehenge, Windsor Castle and Bath with Pub Lunch in
                      Lacock
                    </span>
                  </h4>
                  <p className="text-light-1 lh-14 text-14 mt-5">
                    Westminster Borough, London
                  </p>
                  <div className="row justify-between items-center pt-15">
                    
                    <div className="col-autoh">
                      <div className="text-14 text-light-1">
                        From
                        <span className="text-16 fw-500 text-dark-1">
                          US$72
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </a>
            </div>
            <div className="col-xl-3 col-lg-3 col-sm-6">
              <a
                href="/Exclusivepackagedetails"
                className="tourCard -type-1 rounded-4 "
              >
                <div className="tourCard__image">
                  <div className="cardImage ratio ratio-1:1">
                    <div className="cardImage__content">
                      <div className="cardImage-slider rounded-4 overflow-hidden js-cardImage-slider swiper-initialized swiper-horizontal swiper-pointer-events swiper-backface-hidden">
                        <div
                          className="swiper-wrapper"
                          id="swiper-wrapper-ca897ea6ca662e7a"
                          aria-live="polite"
                          style={{
                            transitionDuration: "0ms",
                            transform: "translate3d(-255px, 0px, 0px)",
                          }}
                        >
                          <div
                            className="swiper-slide swiper-slide-duplicate swiper-slide-prev"
                            data-swiper-slide-index={2}
                            role="group"
                            aria-label="3 / 3"
                            style={{ width: "255px" }}
                          >
                            <img
                              className="col-12"
                              src="https://creativelayers.net/themes/gotrip-html/img/tours/3.png"
                              alt="image"
                            />
                          </div>
                          <div
                            className="swiper-slide swiper-slide-active"
                            data-swiper-slide-index={0}
                            role="group"
                            aria-label="1 / 3"
                            style={{ width: "255px" }}
                          >
                            <img
                              className="col-12"
                              src="https://creativelayers.net/themes/gotrip-html/img/tours/2.png"
                              alt="image"
                            />
                          </div>
                          <div
                            className="swiper-slide swiper-slide-next"
                            data-swiper-slide-index={1}
                            role="group"
                            aria-label="2 / 3"
                            style={{ width: "255px" }}
                          >
                            <img
                              className="col-12"
                              src="https://creativelayers.net/themes/gotrip-html/img/tours/1.png"
                              alt="image"
                            />
                          </div>
                          <div
                            className="swiper-slide swiper-slide-duplicate-prev"
                            data-swiper-slide-index={2}
                            role="group"
                            aria-label="3 / 3"
                            style={{ width: "255px" }}
                          >
                            <img
                              className="col-12"
                              src="https://creativelayers.net/themes/gotrip-html/img/tours/3.png"
                              alt="image"
                            />
                          </div>
                          <div
                            className="swiper-slide swiper-slide-duplicate swiper-slide-duplicate-active"
                            data-swiper-slide-index={0}
                            role="group"
                            aria-label="1 / 3"
                            style={{ width: "255px" }}
                          >
                            <img
                              className="col-12"
                              src="https://creativelayers.net/themes/gotrip-html/img/tours/2.png"
                              alt="image"
                            />
                          </div>
                        </div>
                        <div className="cardImage-slider__pagination js-pagination swiper-pagination-clickable swiper-pagination-bullets swiper-pagination-horizontal">
                          <div
                            className="pagination__item is-active"
                            tabIndex={0}
                            role="button"
                            aria-label="Go to slide 1"
                            aria-current="true"
                          />
                          <div
                            className="pagination__item"
                            tabIndex={0}
                            role="button"
                            aria-label="Go to slide 2"
                          />
                          <div
                            className="pagination__item"
                            tabIndex={0}
                            role="button"
                            aria-label="Go to slide 3"
                          />
                        </div>
                       
                        <span
                          className="swiper-notification"
                          aria-live="assertive"
                          aria-atomic="true"
                        />
                      </div>
                    </div>
                   
                  </div>
                </div>
                <div className="tourCard__content mt-10">
                  <div className="d-flex items-center lh-14 mb-5">
                    <div className="text-14 text-light-1">9+ hours</div>
                    <div className="size-3 bg-light-1 rounded-full ml-10 mr-10" />
                    <div className="text-14 text-light-1">
                      Attractions &amp; Museums
                    </div>
                  </div>
                  <h4 className="tourCard__title text-dark-1 text-18 lh-16 fw-500">
                    <span>
                      Westminster Walking Tour &amp; Westminster Abbey Entry
                    </span>
                  </h4>
                  <p className="text-light-1 lh-14 text-14 mt-5">
                    Ciutat Vella, Barcelona
                  </p>
                  <div className="row justify-between items-center pt-15">
                    
                    <div className="col-autoh">
                      <div className="text-14 text-light-1">
                        From
                        <span className="text-16 fw-500 text-dark-1">
                          US$72
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </a>
            </div>
            <div className="col-xl-3 col-lg-3 col-sm-6">
              <a
                href="/Exclusivepackagedetails"
                className="tourCard -type-1 rounded-4 "
              >
                <div className="tourCard__image">
                  <div className="cardImage ratio ratio-1:1">
                    <div className="cardImage__content">
                      <img
                        className="rounded-4 col-12"
                        src="https://creativelayers.net/themes/gotrip-html/img/tours/3.png"
                        alt="image"
                      />
                    </div>
                   
                  
                  </div>
                </div>
                <div className="tourCard__content mt-10">
                  <div className="d-flex items-center lh-14 mb-5">
                    <div className="text-14 text-light-1">40–55 minutes</div>
                    <div className="size-3 bg-light-1 rounded-full ml-10 mr-10" />
                    <div className="text-14 text-light-1">
                      Private and Luxury
                    </div>
                  </div>
                  <h4 className="tourCard__title text-dark-1 text-18 lh-16 fw-500">
                    <span>High-Speed Thames River RIB Cruise in London</span>
                  </h4>
                  <p className="text-light-1 lh-14 text-14 mt-5">
                    Manhattan, New York
                  </p>
                  <div className="row justify-between items-center pt-15">
                    
                    <div className="col-autoh">
                      <div className="text-14 text-light-1">
                        From
                        <span className="text-16 fw-500 text-dark-1">
                          US$72
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </a>
            </div>
            <div className="col-xl-3 col-lg-3 col-sm-6">
              <a
                href="/Exclusivepackagedetails"
                className="tourCard -type-1 rounded-4 "
              >
                <div className="tourCard__image">
                  <div className="cardImage ratio ratio-1:1">
                    <div className="cardImage__content">
                      <img
                        className="rounded-4 col-12"
                        src="https://creativelayers.net/themes/gotrip-html/img/tours/4.png"
                        alt="image"
                      />
                    </div>
                   
                   
                  </div>
                </div>
                <div className="tourCard__content mt-10">
                  <div className="d-flex items-center lh-14 mb-5">
                    <div className="text-14 text-light-1">94+ days</div>
                    <div className="size-3 bg-light-1 rounded-full ml-10 mr-10" />
                    <div className="text-14 text-light-1">Bus Tours</div>
                  </div>
                  <h4 className="tourCard__title text-dark-1 text-18 lh-16 fw-500">
                    <span>
                      Edinburgh Darkside Walking Tour: Mysteries, Murder and
                      Legends
                    </span>
                  </h4>
                  <p className="text-light-1 lh-14 text-14 mt-5">
                    Vaticano Prati, Rome
                  </p>
                  <div className="row justify-between items-center pt-15">
                    
                    <div className="col-autoh">
                      <div className="text-14 text-light-1">
                        From
                        <span className="text-16 fw-500 text-dark-1">
                          US$72
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </a>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
};


export default GoogleApiWrapper({
  apiKey: "AIzaSyCom4BCfTVsw3o22AYg69Y7EovmLfV_Alw",
})(HoneyMoonDetails);