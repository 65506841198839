import "./beachSlider.css";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { QuickBeachArray } from "../../../Constant/constant"
import { Link } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import axios from "axios";
function BeachSlider() {
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 6,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    cssEase: "linear",
  };
  const CityImage = ({ cityName }) => {
    const [imageUrl, setImageUrl] = useState("");
  
    useEffect(() => {
      const fetchImage = async () => {
        try {
          const response = await fetch(
            `https://api.unsplash.com/photos/random?query=${cityName}&client_id=-Uo30ajI98n3gMl__2efYbTyrwvYIvRjKo_d-zB7t4c`
          );
  
          const data = await response.json();
  
          if (data.urls && data.urls.full) {
            setImageUrl(data.urls.full);
          } else {
            console.error("Image not found");
            setImageUrl('https://cdn.pixabay.com/photo/2021/05/13/07/18/sculpture-6250058_1280.jpg')
          }
        } catch (error) {
          console.error("Error fetching image:", error);
        }
      };
  
      fetchImage();
    }, [cityName]);
  
    return (
      <img
        src={imageUrl}
        alt={`City: ${cityName}`}
        style={{height:"100px",width:"100px"}}
        // class="featuredImg12 img-fluid"
      />
    );
  };
  const responsive = {
    loop: true,
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 5,
      slidesToSlide: 1,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
      slidesToSlide: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1,
    },
  };
  const [data, setdata] = useState([]);
  const getapartment = async () => {
    let res = await axios.get(
      "https://bookingoda.com/api/admin/getallApartment"
    );
    if (res.status === 200) {
      //////console.log(res.data);
      setdata(
        res.data.Apartment.filter(
          (item) =>
            item.status === "Approved" &&
            item.Deletestatus !== "Deleted By Admin" &&
            item.blockstatus !== true
        )
      );
    }
  };
  useEffect(() => {
    getapartment();
  
  }, []);
  const datasss = data.map((e) => e.address.map((data)=>data));
  const cityNames = datasss.map((address, index) => {
    if (Array.isArray(address) && address[0]) {
      const cityArray = address[0].split(','); // Split the address string by commas
      if (cityArray.length >= 4) {
        const cityName = cityArray[cityArray.length - 4]?.trim(); // Use optional chaining to avoid 'undefined' error
        return cityName || null; // Provide a default value if city name is not found
      } else {
        //console.log(`Invalid address format at index ${index}: ${address}`);
        return null;
      }
    } else {
      //console.log(`Invalid address format at index ${index}: ${address}`);
      return null;
    }
  });

  const uniqueAddresses = [...new Set(cityNames.flat())];


  return (
    <div className="beachSliderContainer">
      <div className="propertySlider" style={{display:"flex",flexWrap:'wrap'}}>
     
       
        
          {uniqueAddresses.slice(0,9).map((val, index) => {
            return (
              <div className="SliderCard" key={index}>
               
                  <div>
                    {/* <img src={val.image} alt="" /> */}
                    <Link to={`/hotels`}  state={{destination : val}}>  <CityImage cityName={val}  /></Link>
                    <div className="sliderCardDestination">{val}</div>
                    <div className="sliderCardProperty">{cityNames.filter((d) => d === val)?.length} Properties</div>
                  </div>
              
              </div>
            );
          })}
       
      </div>
    </div>
  );
}
export default BeachSlider;
