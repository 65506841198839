import React, { useState } from "react";
import "./myproperty.css";
import Apartment from "../../assets/apartments.png"
import Homestay from "../../assets/homestay.png"
import Hotel from "../../assets/hotel.png"

import Tent from "../../assets/tent.png"
import VendorHeader from "./Vendorheader";

const MyProperty = () => {
    const [show,setshow]=useState(false)
  const vendor = JSON.parse(sessionStorage.getItem("vendor"));
  if (!vendor) {
    alert("please Login");
    window.location.assign("/signin");
  }
  const showmore = () =>{
    setshow(!show)
  }
  const logOut = () => {
    alert("Successfully logout");
    window.location.assign("/signin");
    window.sessionStorage.removeItem("vendor");
  };
  return (
    <div
      data-capla-component="b-registration-joinapp15min-fe/"
      data-capla-namespace="b-registration-joinapp15min-feaYGUODfA"
    >
     
      <VendorHeader />
        <div className="row
                             top_progress
                            content--wrapper_adjust
                            " id="content_wrapper">
          <div id="content" className="content min-height-setter
                            
                            
                            
                            content-compact
                           ">
            <div className="container my-properties_listing">
              <div className="row">
                <div className="col-md-12">
                  <h1>My properties</h1>
                  <div className="add-another-property-button_my_properties">
                    <form id="add_property" action="/json/index.json?token=d5b55614f6c23a4d5d349ae3b446e8f942ff8f62" method="POST">
                      <input type="hidden" name="action" defaultValue="get_started" />
                      <input type="hidden" name="origin" defaultValue="my_properties" />
                      <input type="hidden" name="contact_name" defaultValue="Parnets D" />
                      <input type="hidden" name="contact_email" defaultValue="parnetstech10@gmail.com" />
                      <input type="hidden" name="estimated_properties_amount" defaultValue={1} />
                      <input type="hidden" name="save" defaultValue={1} />
                      <input type="Add another property"  defaultValue="Add another property" className="btn btn-primary_consistent btn-primary add-another-property-button" data-track-ga="sb_account_click_add_another_property" onClick={()=>window.location.assign("/property")}/>
                    </form>
                  </div>
                  <div className="row property-card">
                    <div className="col-sm-4 property-card_photo property-card_photo--center" style={{backgroundImage: 'url("https://q.bstatic.com/static/img/join/illustrated_bedroom.jpg")'}}>
                    </div>
                    <div className="col-sm-8 col-xs-12 property-card_content">
                      <h2>
                        New property
                      </h2>
                    
                     
                    </div>
                  </div>
                 
                </div>
              </div>
            </div>
          </div>
        </div>
        
      <img
        alt=""
        height="1"
        width="1"
        style={{display: "none"}}
        src="https://booking.com/track_join_app_landing?progress_status=2"
      />
    </div>
  );
};

export default MyProperty;
