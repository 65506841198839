import React from "react";
import "./property.css";
import Apartment from "../../assets/apartments.png";
import Apartments from "../../assets/buildings.png";
import location from "../../assets/location.png";

import mutilocation from "../../assets/mutilocation.png";
import Hotel from "../../assets/hotel.png";
import VendorHeader from "./Vendorheader";

const Alternative1 = () => {
  return (
    <div id="app-container">
      <div class="error-popup-container"></div>
      <div class="application">
       <VendorHeader />
        <div class="page-container">
          <div class="screens screens-animation-direction-forward">
            <div
              class="height-100 screen-change-enter-done"
              id="automation_id_screen_container_FeedbackLoop"
            >
              <div class="ms-container">
                <div class="form-container">
                  <div class="ffb9c3d6a31 wide-container spacing--4x">
                    <div class="main">
                      <div class="bcdcb105b3 feedback-loop-header">
                        You're listing:
                      </div>
                      <div class="img-container feedback-loop-img-container">
                        <img
                          src={Apartment}
                          alt=""
                        />
                      </div>
                      <div
                        data-testid="FeedbackLoop-description"
                        class="af8fbdf136 feedback-loop-description"
                      >
                        One entire property where guests can book the entire place
                      </div>
                      <div class="bcdcb105b3 feedback-loop-question">
                        Does this sound like your property?
                      </div>
                    </div>
                    <div class="feedbackloop-cta-desktop">
                      <div class="form-cta form-cta--inline">
                        <div>
                          <div class="form-buttons-container">
                            <div class="form-buttons-container--secondary" onClick={()=>window.history.back()
}  > 
                              <button
                                aria-label="Back to previous step"
                                data-testid="FormButtonSecondary"
                                type="button"
                                class="a83ed08757 c21c56c305 bf0537ecb5 f671049264 d2529514af af7297d90d"
                              >
                                <span class="e4adce92df">
                                  No, I need to make a change
                                </span>
                              </button>
                            </div>
                            <div class="form-buttons-container--primary" onClick={()=>window.location.assign("/alternativelocation")}>
                              <button
                                aria-label="Continue"
                                data-testid="FormButtonPrimary-enabled"
                                type="button"
                                class="a83ed08757 c21c56c305 a4c1805887 f671049264 d2529514af c082d89982"
                              >
                                <span class="e4adce92df">Continue</span>
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="feedbackloop-cta-mobile">
                  <div class="form-cta form-cta--inline">
                    <div>
                      <div class="form-buttons-container">
                        <div class="form-buttons-container--secondary" onClick={()=>window.history.back()
}  >  
                          <button
                            aria-label="Back to previous step"
                            data-testid="FormButtonSecondary"
                            type="button"
                           
                            class="a83ed08757 c21c56c305 bf0537ecb5 f671049264 d2529514af af7297d90d"
                          >
                            <span class="e4adce92df">
                              No, I need to make a change
                            </span>
                          </button>
                        </div>
                        <div class="form-buttons-container--primary" onClick={()=>window.location.assign("/alternativelocation")}>
                          <button
                            aria-label="Continue"
                            data-testid="FormButtonPrimary-enabled"
                            type="button"
                            class="a83ed08757 c21c56c305 a4c1805887 f671049264 d2529514af c082d89982"
                          >
                            <span class="e4adce92df">Continue</span>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            id="automation_id_screen_id_feedback-loop"
            style={{ display: "none" }}
          ></div>
        </div>
        <div></div>
      </div>
    </div>
  );
};

export default Alternative1;
