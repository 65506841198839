import React, { useState } from "react";
import "./createaccount.css";
import { useNavigate } from "react-router-dom";

import axios from "axios";
import { BsEyeFill, BsFillEyeSlashFill } from "react-icons/bs";
import Success from '../../assets/success.png'
import Error from '../../assets/close.png'
import { Container, Form, Modal, Row, Table } from "react-bootstrap";
const CreatePassword = () => {
  const [successshow, setsuccessshow] = useState(false);
  const handlesuccessClose = () => setsuccessshow(false);
  const handlesuccess = () => 
    setsuccessshow(true);
   
    const [errorshow, seterrorshow] = useState(false);
    const handleerrorClose = () => seterrorshow(false);
    const handleerror = () => 
      seterrorshow(true);
  const [password, setpassword] = useState("");
  const [cpassword, setcpassword] = useState("");
  const [eye, seteye] = useState(true);
  const [eye1, seteye1] = useState(true);
  const email = JSON.parse(sessionStorage.getItem("email"));
  const name = JSON.parse(sessionStorage.getItem("name"));
  const Lname = JSON.parse(sessionStorage.getItem("Lname"));
  const mobile = JSON.parse(sessionStorage.getItem("mobile"));
  const isPasswordValid = (password) => {
    // Check if the password has at least 10 characters
    if (password.length < 10) {
      return false;
    }
  
    // Check if the password contains at least one uppercase letter
    if (!/[A-Z]/.test(password)) {
      return false;
    }
  
    // Check if the password contains at least one lowercase letter
    if (!/[a-z]/.test(password)) {
      return false;
    }
  
    // Check if the password contains at least one number
    if (!/\d/.test(password)) {
      return false;
    }
  
    // If all conditions are met, the password is considered valid
    return true;
  };
  let history = useNavigate();
  const onSubmit = async (e) => {
    e.preventDefault();
    if (!isPasswordValid(password)) {
      alert("Use a minimum of 10 characters, including uppercase letters, lowercase letters and numbers.");
    } else 
    if (password !== cpassword) {
      alert("Passwords don't match");
    } else {
      try {
        const config = {
          url: "/vendorsignup",
          method: "post",
          baseURL: "https://bookingoda.com/api/admin",
          headers: { "content-type": "application/json" },
          data: {
            email: email,
            name: name,
            Lname: Lname,
            mobile: mobile,
            password: password,
            Cpassword: cpassword,
          },
        };
        let res = await axios(config);
        if (res.status === 200) {
          history("/signin");
          window.sessionStorage.removeItem("email");
          window.sessionStorage.removeItem("name");
          window.sessionStorage.removeItem("Lname");
          window.sessionStorage.removeItem("mobile");

          // alert("successfully created");
          handlesuccess();
          // history.push("/");
        } else {
          // alert("Something Wrong");
          handleerror();
        }
      } catch (error) {
        if (error.response) {
          alert(error.response.data.error);
        }
      }
    }
  };
  return (
    <div>
      <div class="">
        <div class="">
          <div class="partner-header">
            <header class=" aCWKa87CwyJQfbPP_yg6">
              <nav class="yC5_EodFadTqIIY8_YDx">
                <div class="NrR_ZlI8bZYI3w06JKKb">
                  <div class="H2AEzwF6W02wmXtVrp9U">
                  <div class="mobile-join-logo join_logo">
      <span style={{ color: "white",fontSize:30,marginTop:"40px"}}>Booking-Oda.com</span>
      </div>
                  </div>
                </div>
              </nav>
            </header>
          </div>
          <div class="app">
            <div class="access-container bui_font_body">
              <div class="access-panel-container">
                <div class="access-panel nw-access-panel">
                  <div class="transition-container">
                    <div
                      class="animate-height"
                      style={{
                        height: "auto",
                        overflow: "visible",
                      }}
                    >
                      <div class="sliding-panel">
                        <div class="transition bui-panel-body slide-enter-done">
                          <div class="">
                            <div
                              tabindex="-1"
                              class="page-header"
                              aria-labelledby="1aa694f4-22a3-4ad7-8d4d-e32c00419428"
                              aria-describedby="529d35e8-6936-4d9b-a108-62a5b10ec9df"
                            >
                              <h1
                                id="1aa694f4-22a3-4ad7-8d4d-e32c00419428"
                                class="bui_font_display_one bui_font_heading--bold bui-spacer--medium nw-step-header"
                              >
                                Create password
                              </h1>
                              <div class="bui-spacer--largest">
                                <div>
                                  <p
                                    class="nw-step-description"
                                    id="529d35e8-6936-4d9b-a108-62a5b10ec9df"
                                  >
                                    Use a minimum of 10 characters, including
                                    uppercase letters, lowercase letters and
                                    numbers.
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                          <form class="nw-register" novalidate="">
                            <div class="password-input-container bui-spacer--large">
                              <div class="password-input-container bui-spacer--large">
                                <div
                                  aria-live="polite"
                                  class="sOewt4Y1yBOPAIUGcJwH wkTNdQjAfRVbKvFBiR1T nw-password"
                                >
                                  <label
                                    for="new_password"
                                    class="sOewt4Y1yBOPAIUGcJwH hAFaqujHGDhKOcPl6uUa _nwGprfLZfZgMFjsmap7"
                                  >
                                    <span>
                                      <span class="L08id9Rh757AqBBZobKh">
                                        Password
                                      </span>
                                    </span>
                                  </label>
                                  {!eye ? (
                                    <div class="cTkJymCseykCmdCpB4JG">
                                      <div class="KBX_kI7wQ0ziDRM0x5Ig">
                                        <input
                                          type="text"
                                          name="new_password"
                                          class="ZMH9h0HCYH9GGNxHnXGJ"
                                          placeholder="Enter a password"
                                          id="new_password"
                                          onChange={(e) =>
                                            setpassword(e.target.value)
                                          }
                                          value={password}
                                        />
                                        <div class="KlWiFnWD2KyUjaUF1WAE"></div>
                                        <div class="ypuClFu1I0a0wmwCMKWV">
                                          <div class="ods6EhCCFo1GhPrxs58m reVLDpXAXDa8VaCE9Fpe">
                                            <BsEyeFill
                                              onClick={() => seteye(true)}
                                              fontSize={30}
                                              style={{ margin: 5 }}
                                            />
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  ) : (
                                    <div class="cTkJymCseykCmdCpB4JG">
                                      <div class="KBX_kI7wQ0ziDRM0x5Ig">
                                        <input
                                          type="password"
                                          name="new_password"
                                          class="ZMH9h0HCYH9GGNxHnXGJ"
                                          placeholder="Enter a password"
                                          id="new_password"
                                          onChange={(e) =>
                                            setpassword(e.target.value)
                                          }
                                          value={password}
                                        />
                                        <div class="KlWiFnWD2KyUjaUF1WAE"></div>
                                        <div class="ypuClFu1I0a0wmwCMKWV">
                                          <div class="ods6EhCCFo1GhPrxs58m reVLDpXAXDa8VaCE9Fpe">
                                            <BsFillEyeSlashFill
                                              onClick={() => seteye(false)}
                                              fontSize={30}
                                              style={{ margin: 5 }}
                                            />
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                            <div class="password-input-container bui-spacer--large">
                              <div class="password-input-container bui-spacer--large">
                                <div
                                  aria-live="polite"
                                  class="sOewt4Y1yBOPAIUGcJwH wkTNdQjAfRVbKvFBiR1T nw-confirm-password"
                                >
                                  <label
                                    for="confirmed_password"
                                    class="sOewt4Y1yBOPAIUGcJwH hAFaqujHGDhKOcPl6uUa _nwGprfLZfZgMFjsmap7"
                                  >
                                    <span>
                                      <span class="L08id9Rh757AqBBZobKh">
                                        Confirm password
                                      </span>
                                    </span>
                                  </label>
                                  {!eye1 ? (
                                    <div class="cTkJymCseykCmdCpB4JG">
                                      <div class="KBX_kI7wQ0ziDRM0x5Ig">
                                        <input
                                          type="text"
                                          name="new_password"
                                          class="ZMH9h0HCYH9GGNxHnXGJ"
                                          placeholder="Enter a confirm-password"
                                          id="new_password"
                                          onChange={(e) =>
                                            setcpassword(e.target.value)
                                          }
                                          value={cpassword}
                                        />
                                        <div class="KlWiFnWD2KyUjaUF1WAE"></div>
                                        <div class="ypuClFu1I0a0wmwCMKWV">
                                          <div class="ods6EhCCFo1GhPrxs58m reVLDpXAXDa8VaCE9Fpe">
                                            <BsEyeFill
                                              onClick={() => seteye1(true)}
                                              fontSize={30}
                                              style={{ margin: 5 }}
                                            />
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  ) : (
                                    <div class="cTkJymCseykCmdCpB4JG">
                                      <div class="KBX_kI7wQ0ziDRM0x5Ig">
                                        <input
                                          type="password"
                                          name="new_password"
                                          class="ZMH9h0HCYH9GGNxHnXGJ"
                                          placeholder="Enter a confirm-password"
                                          id="new_password"
                                          onChange={(e) =>
                                            setcpassword(e.target.value)
                                          }
                                          value={cpassword}
                                        />
                                        <div class="KlWiFnWD2KyUjaUF1WAE"></div>
                                        <div class="ypuClFu1I0a0wmwCMKWV">
                                          <div class="ods6EhCCFo1GhPrxs58m reVLDpXAXDa8VaCE9Fpe">
                                            <BsFillEyeSlashFill
                                              onClick={() => seteye1(false)}
                                              fontSize={30}
                                              style={{ margin: 5 }}
                                            />
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                            <div
                              data-dv-event-id="13"
                              //   type="submit"
                              onClick={onSubmit}
                              class="Iiab0gVMeWOd4XcyJGA3 wPxWIS_rJCpwAWksE0s3 Ut3prtt_wDsi7NM_83Jc TuDOVH9WFSdot9jLyXlw EJWUAldA4O1mP0SSFXPm "
                              style={{
                                backgroundColor: "#70bbe9",
                                borderColor: "#70bbe9",
                                color: "white",
                              }}
                            >
                              <span class="yfCvx60qsR50VNBG15jF">
                                Create account
                              </span>
                            </div>
                            <div className="account-access__footer">
                <div className="u-text-center bui_font_caption portal_footer">
                  <div className="account_footer_terms footer-block">
                    By signing in or creating an account, you agree with our{" "}
                    <a
                     
                      className="bui_color_action nw-terms"
                      target="_blank"
                      rel="noopener noreferrer"
                      data-ga-action="click"
                      data-ga-label="Terms partner"
                    >
                      Terms &amp; conditions
                    </a>{" "}
                    and{" "}
                    <a
                     
                      className="bui_color_action nw-privacy"
                      target="_blank"
                      rel="noopener noreferrer"
                      data-ga-action="click"
                      data-ga-label="Privacy partner"
                    >
                      Privacy statement
                    </a>
                  </div>
                
                </div>
              </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="account-access__footer">
              <div class="u-text-center bui_font_caption portal_footer">
                {/* <div class="account_footer_terms footer-block">
                  By signing in or creating an account, you agree with our{" "}
                  <a
                    href="https://www.booking.com/general.en-gb.html?tmpl=docs/terms_of_use"
                    class="bui_color_action nw-terms"
                    target="_blank"
                    rel="noopener noreferrer"
                    data-ga-action="click"
                    data-ga-label="Terms partner"
                  >
                    Terms &amp; conditions
                  </a>{" "}
                  and{" "}
                  <a
                    href="https://admin.booking.com/hotel/hoteladmin/privacy.html?lang=en-gb"
                    class="bui_color_action nw-privacy"
                    target="_blank"
                    rel="noopener noreferrer"
                    data-ga-action="click"
                    data-ga-label="Privacy partner"
                  >
                    Privacy statement
                  </a>
                </div> */}
                <div class="access-footer-bottom bui_font_caption footer-block">
                  <div>
                    All rights reserved. <br />
                    Copyright (2023) - Booking-Oda.com™{" "}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        show={successshow}
        onHide={handlesuccess}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton onClick={handlesuccessClose}></Modal.Header>
        <Modal.Body>
          <div className="text-center">
           <img src={Success}
              style={{ marginTop: "-12px" }}
              width="100px"/>
           
            <p>Successfully Added</p>
          </div>
        </Modal.Body>
     
      </Modal>
      <Modal
        show={errorshow}
        onHide={handleerror}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton onClick={handleerrorClose}></Modal.Header>
        <Modal.Body>
          <div className="text-center">
           <img src={Error}
              style={{ marginTop: "-12px" }}
              width="100px"/>
           
            <p>Error Occured</p>
          </div>
        </Modal.Body>
     
      </Modal>
    </div>
  );
};

export default CreatePassword;
