import { FaClock, FaLongArrowAltRight, FaMapMarkerAlt } from "react-icons/fa";
import "./vacation.css";
import DatePicker from "react-multi-date-picker";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import { useEffect, useState } from "react";
import { GrMapLocation } from "react-icons/gr";
import Navbar from "../navbar/Navbar";
import Footer from "../footer/Footer";
import { Carousel } from "react-bootstrap";
import axios from "axios";
import { IoIosArrowDown, IoIosBed } from "react-icons/io";
import parse from "html-react-parser";
import { Link, useLocation } from "react-router-dom";
import { Map, GoogleApiWrapper, Marker } from "google-maps-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAnglesRight,
  faMapMarkerAlt,
} from "@fortawesome/free-solid-svg-icons";
import { GiDoorWatcher } from "react-icons/gi";
import { PiBathtubFill } from "react-icons/pi";

const Vacationlist = (props) => {
  const city = JSON?.parse(sessionStorage.getItem("city"));

  const handleKeyDown = (e) => {
    // Prevent the input of the minus sign
    if (e.key === "-" || e.key === "e" || e.key === "E") {
      e.preventDefault();
    }
  };
  const lat = 13.07876;
  const log = 77.53324;
  ////console.log(typeof lat, log);
  const apiKey = "AIzaSyCom4BCfTVsw3o22AYg69Y7EovmLfV_Alw";
  const [places, setPlaces] = useState([]);

  const getvacation = async () => {
    let res = await axios.get("https://bookingoda.com/api/admin/getvacation");
    if (res.status === 200) {
      //////console.log(res.data);
      setPlaces(
        res.data.getvacation.filter((item) => item.blockstatus !== true)
      );
    }
  };
  useEffect(() => {
    getvacation();
  }, []);
  const itemsPerPage = 8; // Number of cardvs per page
  const [currentPage, setCurrentPage] = useState(1);
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;

  // Get the current page's items
  const currentItems = places.slice(startIndex, endIndex);
  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };
  const [selectedFacilities, setSelectedFacilities] = useState({
    "Air Conditioning": false,
    "Bathrobes": false,
    "Dry cleaning": false,
    "Fitness center": false,
    "Hair dryer":false,
    "High chair":false,
    "Mini bar":false,
    "Restaurant":false,
    "Slippers":false,
    "Telephone":false,
    "Wake-up call":false,
    "Wifi":false,
  });
  const [DestinationName, setDestinationName] = useState(city !== "" ? city : "");
  const facilitiesList = [
    "Air Conditioning",
    "Bathrobes",
    "Dry cleaning",
    "Fitness center",
    "Hair dryer",
    "High chair",
    "Mini bar",
    "Restaurant",
    "Slippers",
    "Telephone",
    "Wake-up call",
    "Wifi",
  ];
  const [filteredProducts, setFilteredProducts] = useState( []
    
  );
  const handleCheckboxChangeroom = (facility) => {
    setSelectedFacilities((prevFacilities) => ({
      ...prevFacilities,
      [facility]: !prevFacilities[facility],
    }));
  };
  const handleDestination = (e) => {
    setDestinationName(e.target.value);
  };
  const dataCounts1 = {};
  facilitiesList.forEach((facility) => {
    dataCounts1[facility] = 0;
  });
  places.forEach((item) => {
    const roomFacilities =
      item.Amenities;
    facilitiesList.forEach((facility) => {
      if (roomFacilities[facility]) {
        dataCounts1[facility]++;
      }
    });
  });
  const handleClickroom = () => {
    const filteredData = places.filter((item) => {
      const roomFacilities = item.Amenities;
     

      return (
        (roomFacilities &&
          Object.entries(selectedFacilities).every(
            ([key, value]) => !value || roomFacilities[key]
          )) 
      );
    });
   
    // Update the filtered products state
    setFilteredProducts(filteredData);
  };
  return (
    <>
      <Navbar />

      <div className="site-content text-break" tabIndex={-1}>
        <div className="breadcrumb breadcrumbv-style-one">
          <div className="container">
            <div className="col-lg-12 text-center">
              <h2 className="breadcrumb-title">Package List</h2>
              <ul className="d-flex justify-content-center breadcrumb-items">
                <li className="breadcrumb-item">
                  <a href="/Vacationhome">Home</a>
                </li>
                <FontAwesomeIcon
                  className="icons"
                  icon={faAnglesRight}
                  style={{ margin: "0px" }}
                />
                <li className="breadcrumb-item active">Package list</li>
              </ul>
            </div>
          </div>
        </div>
        <div className="containerv pt-5 pt-xl-8">
          <div className="row">
            <div className="col-lg-9">
          
             
              <div className="u-slick__tab">
                <div className="tab-content">
                  <div className="tab-pane fade active show" id="grid-view">
                  
                      <div className="mb-0 list-unstyled products row row-cols-md-6 row-cols-lg-6 row-cols-xl-3">
                       {DestinationName !== ""
                  ? currentItems
                      .filter((item) => item._id === DestinationName).map((data) => (  <div className="add-to-wishlist-after_add_to_cart product type-product post-218 status-publish first instock product_cat-coupes product_cat-rental has-post-thumbnail featured shipping-taxable purchasable product-type-simple mb-3 mb-md-4 pb-1">
                          <div className="cardv transition-3d-hover shadow-hover-2 h-100">
                            <div className="cardv-header position-relative p-0">
                            <Link to={`/Vacationdetails/${data._id}`} >
                                <img
                                  loading="lazy"
                                  width={300}
                                  height={225}
                                  src={`https://bookingoda.com/image/${data.GalleryImage[0]?.image}`}
                                  className="min-height-230 bg-img-hero cardv-img-top"
                                  alt=""
                                />{" "}
                              </Link>

                              <div className="price-wrapper position-absolute bottom-0 left-0 right-0">
                                <div className="px-3 pb-2">
                                  <Link to={`/Vacationdetails/${data._id}`} >
                                    <h2 className="woocommerce-loop-product__title font-size-17 text-white font-weight-bold">
                                      {" "}
                                      {data.stayname}
                                    </h2>
                                  </Link>{" "}
                                  <span className="price">
                                    <span className="woocommerce-Price-amount font-weight-bold font-size-19 mb-2 d-inline-block text-white amount">
                                      {" "}
                                      <bdi>
                                        <span className="woocommerce-Price-currencySymbol">
                                          INR
                                        </span>
                                        {data.staycost}
                                      </bdi>
                                    </span>
                                    <span className="wceb-price-format">
                                      {" "}
                                      / night
                                    </span>{" "}
                                  </span>
                                </div>
                              </div>
                            </div>

                            <div
                              className="cardv-body px-4 py-3
					border-bottom"
                            >
                                 <Link to={`/Vacationdetails/${data._id}`} >
                                <div className=" font-size-14 text-gray-1">
                                  <FontAwesomeIcon
                                    icon={faMapMarkerAlt}
                                    className="icon flaticon-pin-1 mr-2 font-size-24" style={{color:"#69a3cf"}}
                                  />
                                  {data?.street} {data.city},{data.state},{data.country}{" "}
                                </div>
                              </Link>
                            </div>
                            <div className="px-4 pt-3 pb-2">
                              <div className="row">
                                <div className="col-6">
                                  {" "}
                                  <div className="media mb-2 text-gray-1 align-items-center">
                                    <small className="mr-2">
                                      <i className="fas fa-chalkboard font-size-24 small" style={{color:"#69a3cf"}}/>
                                    </small>
                                    <div className="media-body font-size-1">
                                      {data.Measurement} sq ft
                                    </div>
                                  </div>
                                </div>
                                <div className="col-6">
                                  {" "}
                                  <div className="media mb-2 text-gray-1 align-items-center">
                                    <small className="mr-2">
                                      <GiDoorWatcher className="fas fa-chalkboard font-size-24 small" style={{color:"#69a3cf"}}/>
                                    </small>
                                    <div className="media-body font-size-1">
                                      {data.Rooms} rooms
                                    </div>
                                  </div>
                                </div>
                                <div className="col-6">
                                  {" "}
                                  <div className="media mb-2 text-gray-1 align-items-center">
                                    <small className="mr-2">
                                      <PiBathtubFill className="fas fa-chalkboard font-size-24 small" style={{color:"#69a3cf"}}/>
                                    </small>
                                    <div className="media-body font-size-1">
                                      {data.Bathrooms} bath
                                    </div>
                                  </div>
                                </div>
                                <div className="col-6">
                                  {" "}
                                  <div className="media mb-2 text-gray-1 align-items-center">
                                    <small className="mr-2">
                                      <IoIosBed className="flaticon-bed-1 font-size-24 small" style={{color:"#69a3cf"}} />
                                    </small>
                                    <div className="media-body font-size-1">
                                      {data.Beds} beds
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div> )) : filteredProducts?.length > 0 ? filteredProducts.map((data) => (  <div className="add-to-wishlist-after_add_to_cart product type-product post-218 status-publish first instock product_cat-coupes product_cat-rental has-post-thumbnail featured shipping-taxable purchasable product-type-simple mb-3 mb-md-4 pb-1">
                          <div className="cardv transition-3d-hover shadow-hover-2 h-100">
                            <div className="cardv-header position-relative p-0">
                              <Link to={`/Vacationdetails/${data._id}`} >
                                <img
                                  loading="lazy"
                                  width={300}
                                  height={225}
                                  src={`https://bookingoda.com/image/${data.GalleryImage[0]?.image}`}
                                  className="min-height-230 bg-img-hero cardv-img-top"
                                  alt=""
                                />{" "}
                              </Link>

                              <div className="price-wrapper position-absolute bottom-0 left-0 right-0">
                                <div className="px-3 pb-2">
                                  <Link to={`/Vacationdetails/${data._id}`} >
                                    <h2 className="woocommerce-loop-product__title font-size-17 text-white font-weight-bold">
                                      {" "}
                                      {data.stayname}
                                    </h2>
                                  </Link>{" "}
                                  <span className="price">
                                    <span className="woocommerce-Price-amount font-weight-bold font-size-19 mb-2 d-inline-block text-white amount">
                                      {" "}
                                      <bdi>
                                        <span className="woocommerce-Price-currencySymbol">
                                          INR
                                        </span>
                                        {data.staycost}
                                      </bdi>
                                    </span>
                                    <span className="wceb-price-format">
                                      {" "}
                                      / night
                                    </span>{" "}
                                  </span>
                                </div>
                              </div>
                            </div>

                            <div
                              className="cardv-body px-4 py-3
					border-bottom"
                            >
                                 <Link to={`/Vacationdetails/${data._id}`} >
                                <div className="d-flex align-items-center font-size-14 text-gray-1">
                                  <FontAwesomeIcon
                                    icon={faMapMarkerAlt}
                                    className="icon flaticon-pin-1 mr-2 font-size-24" style={{color:"#69a3cf"}}
                                  />
                                  {data?.street} {data.city},{data.state},{data.country}{" "}
                                </div>
                              </Link>
                            </div>
                            <div className="px-4 pt-3 pb-2">
                              <div className="row">
                                <div className="col-6">
                                  {" "}
                                  <div className="media mb-2 text-gray-1 align-items-center">
                                    <small className="mr-2">
                                      <i className="fas fa-chalkboard font-size-24 small" style={{color:"#69a3cf"}}/>
                                    </small>
                                    <div className="media-body font-size-1">
                                      {data.Measurement} sq ft
                                    </div>
                                  </div>
                                </div>
                                <div className="col-6">
                                  {" "}
                                  <div className="media mb-2 text-gray-1 align-items-center">
                                    <small className="mr-2">
                                      <GiDoorWatcher className="fas fa-chalkboard font-size-24 small" style={{color:"#69a3cf"}}/>
                                    </small>
                                    <div className="media-body font-size-1">
                                      {data.Rooms} rooms
                                    </div>
                                  </div>
                                </div>
                                <div className="col-6">
                                  {" "}
                                  <div className="media mb-2 text-gray-1 align-items-center">
                                    <small className="mr-2">
                                      <PiBathtubFill className="fas fa-chalkboard font-size-24 small" style={{color:"#69a3cf"}}/>
                                    </small>
                                    <div className="media-body font-size-1">
                                      {data.Bathrooms} bath
                                    </div>
                                  </div>
                                </div>
                                <div className="col-6">
                                  {" "}
                                  <div className="media mb-2 text-gray-1 align-items-center">
                                    <small className="mr-2">
                                      <IoIosBed className="flaticon-bed-1 font-size-24 small" style={{color:"#69a3cf"}} />
                                    </small>
                                    <div className="media-body font-size-1">
                                      {data.Beds} beds
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div> )): currentItems.map((data) => (  <div className="add-to-wishlist-after_add_to_cart product type-product post-218 status-publish first instock product_cat-coupes product_cat-rental has-post-thumbnail featured shipping-taxable purchasable product-type-simple mb-3 mb-md-4 pb-1">
                          <div className="cardv transition-3d-hover shadow-hover-2 h-100">
                            <div className="cardv-header position-relative p-0">
                              <Link to={`/Vacationdetails/${data._id}`} >
                                <img
                                  loading="lazy"
                                  width={300}
                                  height={225}
                                  src={`https://bookingoda.com/image/${data.GalleryImage[0]?.image}`}
                                  className="min-height-230 bg-img-hero cardv-img-top"
                                  alt=""
                                />{" "}
                              </Link>

                              <div className="price-wrapper position-absolute bottom-0 left-0 right-0">
                                <div className="px-3 pb-2">
                                  <Link to={`/Vacationdetails/${data._id}`} >
                                    <h2 className="woocommerce-loop-product__title font-size-17 text-white font-weight-bold">
                                      {" "}
                                      {data.stayname}
                                    </h2>
                                  </Link>{" "}
                                  <span className="price">
                                    <span className="woocommerce-Price-amount font-weight-bold font-size-19 mb-2 d-inline-block text-white amount">
                                      {" "}
                                      <bdi>
                                        <span className="woocommerce-Price-currencySymbol">
                                          INR
                                        </span>
                                        {data.staycost}
                                      </bdi>
                                    </span>
                                    <span className="wceb-price-format">
                                      {" "}
                                      / night
                                    </span>{" "}
                                  </span>
                                </div>
                              </div>
                            </div>

                            <div
                              className="cardv-body px-4 py-3
					border-bottom"
                            >
                                 <Link to={`/Vacationdetails/${data._id}`} >
                                <div className="font-size-14 text-gray-1">
                                  <FontAwesomeIcon
                                    icon={faMapMarkerAlt}
                                    className="icon flaticon-pin-1 mr-2 font-size-24" style={{color:"#69a3cf"}}
                                  />
                                 {data?.street} {data.city},{data.state},{data.country}{" "}
                                </div>
                              </Link>
                            </div>
                            <div className="">
                              <div className="row">
                                <div className="col-6">
                                  {" "}
                                  <div className="media mb-2 text-gray-1 align-items-center">
                                    <small className="mr-2">
                                      <i className="fas fa-chalkboard font-size-24 small" style={{color:"#69a3cf"}}/>
                                    </small>
                                    <div className="media-body font-size-1">
                                      {data.Measurement} sq ft
                                    </div>
                                  </div>
                                </div>
                                <div className="col-6">
                                  {" "}
                                  <div className="media mb-2 text-gray-1 align-items-center">
                                    <small className="mr-2">
                                      <GiDoorWatcher className="fas fa-chalkboard font-size-24 small" style={{color:"#69a3cf"}}/>
                                    </small>
                                    <div className="media-body font-size-1">
                                      {data.Rooms} rooms
                                    </div>
                                  </div>
                                </div>
                                <div className="col-6">
                                  {" "}
                                  <div className="media mb-2 text-gray-1 align-items-center">
                                    <small className="mr-2">
                                      <PiBathtubFill className="fas fa-chalkboard font-size-24 small" style={{color:"#69a3cf"}}/>
                                    </small>
                                    <div className="media-body font-size-1">
                                      {data.Bathrooms} bath
                                    </div>
                                  </div>
                                </div>
                                <div className="col-6">
                                  {" "}
                                  <div className="media mb-2 text-gray-1 align-items-center">
                                    <small className="mr-2">
                                      <IoIosBed className="flaticon-bed-1 font-size-24 small" style={{color:"#69a3cf"}} />
                                    </small>
                                    <div className="media-body font-size-1">
                                      {data.Beds} beds
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div> )) }
                      </div>
                   
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-3">
              <div className="package-sidebar">
                <aside className="package-widget widget-search">
                  <div className="widget-title">
                    <h4>Search Here</h4>
                  </div>
                  <div className="widget-body">
                    <form action="#" method="post" id="blog-sidebar-search">
                      <div className="form-custom">
                        <select
                          value={DestinationName}
                          onChange={handleDestination}
                          style={{ appearance: "none" }}
                        >
                          <option value="">Select Homes</option>
                          {places.map((data) => (
                          <option value={data._id}>{data.stayname}</option>
                        ))}
                        </select>
                      </div>
                    </form>
                  </div>
                </aside>
                <aside className="package-widget widget-tour-categoris mt-30">
                  <div className="widget-title">
                    <h4>Amenities</h4>
                  </div>
                  <div className="widget-body">
                  <ul >
                 
                  {facilitiesList.map((facility) => (
                    <li className="category-check" key={facility}>
                    
                        <input
                          type="checkbox"
                          className="form-check-input"
                         
                          id={facility}
                          checked={selectedFacilities[facility]}
                          onChange={() => handleCheckboxChangeroom(facility)}
                          onClick={() => handleClickroom()}
                        />
                        <label className="form-check-label" htmlFor={facility}>
                          {" "}
                        
                          <i className="bi bi-chevron-double-right" /> <span>{facility}</span>
                         
                        </label>
                    
                     
                    </li>
                  ))}
                </ul>
                    {/* <ul>
                      <li className="category-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="cate"
                        />
                        <label className="form-check-label" htmlFor="cate">
                          <i className="bi bi-chevron-double-right" /> New York
                          City
                        </label>
                      </li>
                      <li className="category-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="cate2"
                        />
                        <label className="form-check-label" htmlFor="cate2">
                          <i className="bi bi-chevron-double-right" />
                          Adventure Tour
                        </label>
                      </li>
                      <li className="category-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="cate3"
                        />
                        <label className="form-check-label" htmlFor="cate3">
                          <i className="bi bi-chevron-double-right" /> Couple
                          Tour
                        </label>
                      </li>
                      <li className="category-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="cate4"
                        />
                        <label className="form-check-label" htmlFor="cate4">
                          <i className="bi bi-chevron-double-right" /> Village
                          Tour
                        </label>
                      </li>
                      <li className="category-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="cate5"
                        />
                        <label className="form-check-label" htmlFor="cate5">
                          <i className="bi bi-chevron-double-right" />
                          Group Tour
                        </label>
                      </li>
                    </ul> */}
                  </div>
                </aside>
             
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
};
export default GoogleApiWrapper({
  apiKey: "AIzaSyCom4BCfTVsw3o22AYg69Y7EovmLfV_Alw",
})(Vacationlist);
