import React, { useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { FaDirections, FaHotel, FaMusic, FaServicestack } from "react-icons/fa";
import { GrSpotify } from "react-icons/gr";
import { SiApplemusic, SiYoutubemusic } from "react-icons/si";
import {
  MdFoodBank,
  MdOutlineDesignServices,
  MdTravelExplore,
} from "react-icons/md";
import { LuHelpingHand } from "react-icons/lu";

const Location = () => {
  //Explore
  const [show1, setShow1] = useState(false);
  const handleClose1 = () => setShow1(false);
  const handleShow1 = () => setShow1(true);

  //Music
  const [show2, setShow2] = useState(false);
  const handleClose2 = () => setShow2(false);
  const handleShow2 = () => setShow2(true);

  //Assisstance
  const [show3, setShow3] = useState(false);
  const handleClose3 = () => setShow3(false);
  const handleShow3 = () => setShow3(true);

   //Accident
   const [show4, setShow4] = useState(false);
   const handleClose4 = () => setShow4(false);
   const handleShow4 = () => setShow4(true);

    //Robbery
    const [show5, setShow5] = useState(false);
    const handleClose5 = () => setShow5(false);
    const handleShow5 = () => setShow5(true);

    //Vehicle
    const [show6, setShow6] = useState(false);
    const handleClose6 = () => setShow6(false);
    const handleShow6 = () => setShow6(true);

     //Medical
     const [show7, setShow7] = useState(false);
     const handleClose7 = () => setShow7(false);
     const handleShow7 = () => setShow7(true);

  return (
    <div>
      <div className="container">
        <div className="row">
          <div className="col-md-6 mt-3">
            <Form.Group className="mb-3 " controlId="formGroupEmail" >
              <Form.Label style={{fontWeight:"bold"}}>Current Location: </Form.Label>
              <Form.Control type="text" placeholder="Enter Current Location" />
            </Form.Group>

            <Form.Group className="mb-3" controlId="formGroupEmail">
              <Form.Label style={{fontWeight:"bold"}}>Destination: </Form.Label>
              <Form.Control type="text" placeholder="Enter Destination" />
            </Form.Group>

            <div className="location d-flex gap-3">
              <button className="renew-btn fw-semibold" >
                <FaDirections />
                &nbsp;Directions
              </button>

              <button className="renew-btn fw-semibold"  onClick={handleShow1}>
                <MdTravelExplore />
                &nbsp;Explore Services
              </button>

              <button className="renew-btn fw-semibold"  onClick={handleShow3}>
                <LuHelpingHand />
                &nbsp;Ask for Assisstance
              </button>
            </div>
          </div>

          <div
            className="col-md-6"
            style={{ paddingLeft: "unset", paddingRight: "unset" }}
          >
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d31090.264129437837!2d77.53480077779398!3d13.081241386031335!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae229f7a8debe1%3A0x4d80f1d259c7e7ac!2sVidyaranyapura%2C%20Bengaluru%2C%20Karnataka!5e0!3m2!1sen!2sin!4v1702035015935!5m2!1sen!2sin"
              style={{
                width: "100%",
                // marginTop: "2rem",
                height: "450px",
              }}
              allowfullscreen=""
              loading="lazy"
              referrerpolicy="no-referrer-when-downgrade"
            ></iframe>
          </div>
        </div>
      </div>

      {/* Explore  */}
      <Modal show={show1} onHide={handleClose1}>
        <Modal.Header closeButton>
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="location d-flex gap-3">
            <a href="\food">
              <button style={{
                backgroundColor: "#DA291C",
                padding:"5px",
                border: "1px solid #DA291C",
                borderRadius: "15px",
                color: "white",
              }}>
                <FaHotel />
                &nbsp;Food & Drinks
              </button>
            </a>

            <a href="\nearby">
              <button style={{
                backgroundColor:"#da291c", border: "1px solid #da291c",
                padding:"5px",
                
                borderRadius: "15px",
                color: "white",
              }}>
                <MdOutlineDesignServices />
                &nbsp;Near By Services
              </button>
            </a>

            <button style={{
               backgroundColor:"#da291c", border: "1px solid #da291c",
                padding:"5px",
              
                borderRadius: "15px",
                color: "white",
              }} onClick={handleShow2}>
              <FaMusic />
              &nbsp;Music
            </button>
          </div>
        </Modal.Body>
        <Modal.Footer>
          {/* <Button variant="primary">Yes</Button> */}
        </Modal.Footer>
      </Modal>

      {/* Music  */}
      <Modal show={show2} onHide={handleClose2}>
        <Modal.Header closeButton>
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <label htmlFor="" style={{ color: "#DA291C", cursor: "pointer",padding:"5px",backgroundColor:"#da291c", border: "1px solid #da291c", }}>
            <GrSpotify />
            &nbsp;Spotify
          </label>
          <br />
          <label htmlFor="" style={{ color: "#DA291C", cursor: "pointer" ,padding:"5px",backgroundColor:"#da291c", border: "1px solid #da291c"}}>
            <SiApplemusic />
            &nbsp;Apple Music
          </label>
          <br />
          <label htmlFor="" style={{ color: "#DA291C", cursor: "pointer" ,padding:"5px",backgroundColor:"#da291c", border: "1px solid #da291c"}}>
            <SiYoutubemusic />
            &nbsp;YouTube Music
          </label>
          <br />
        </Modal.Body>
        <Modal.Footer>
          {/* <Button variant="primary">Yes</Button> */}
        </Modal.Footer>
      </Modal>

      {/* Assisstance  */}
      <Modal show={show3} onHide={handleClose3}>
        <Modal.Header closeButton>
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <div className="location d-flex gap-3">
           
              <button style={{
                
                padding:"5px",
                backgroundColor:"#da291c", border: "1px solid #da291c",
                borderRadius: "15px",
                color: "white",
              }} onClick={handleShow4}>
                <FaHotel />
                &nbsp;Accident 
              </button>
            

            
              <button style={{
               backgroundColor:"#da291c", border: "1px solid #da291c",
                padding:"5px",
                borderRadius: "15px",
                color: "white",
              }} onClick={handleShow5}>
                <MdOutlineDesignServices />
                &nbsp;Robbery 
              </button>
            

            <button style={{
              backgroundColor:"#da291c", border: "1px solid #da291c",
                padding:"5px",
                borderRadius: "15px",
                color: "white",
              }} onClick={handleShow6}>
              <FaMusic />
              &nbsp;Vehicle Problems 
            </button>

            <button style={{
                backgroundColor:"#da291c", border: "1px solid #da291c",
                padding:"5px",
                borderRadius: "15px",
                color: "white",
              }} onClick={handleShow7}>
              <FaMusic />
              &nbsp;Medical Emergency 
            </button>
          </div>

          {/* <Form>
            <Form.Group className="mb-3" controlId="formGroupEmail">
              <Form.Label>Accident</Form.Label>
              <Form.Select aria-label="Default select example">
                <option>Open this select menu</option>
                <option value="1">Two-Wheeler</option>
                <option value="2">Four-Wheeler</option>
                <option value="3">Bus</option>
                <option value="4">Tempo Traveller</option>
              </Form.Select>
            </Form.Group>

            <Form.Group className="mb-3" controlId="formGroupEmail">
              <Form.Label>Robbery</Form.Label>
              <Form.Select aria-label="Default select example">
                <option>Open this select menu</option>
                <option value="1">Theft</option>
                <option value="2">Misbehave</option>
                <option value="3">Gang Attack</option>
              </Form.Select>
            </Form.Group>

            <Form.Group className="mb-3" controlId="formGroupEmail">
              <Form.Label>Vehicle Problems</Form.Label>
              <Form.Select aria-label="Default select example">
                <option>Open this select menu</option>
                <option value="1">Puncher</option>
                <option value="2">Brake Failure</option>
                <option value="3">Fuel Empty</option>
                <option value="3">Vehicle Breakdown</option>
              </Form.Select>
            </Form.Group>

            <Form.Group className="mb-3" controlId="formGroupEmail">
              <Form.Label>Medical Emergency</Form.Label>
              <Form.Select aria-label="Default select example">
                <option>Open this select menu</option>
                <option value="1">Clinic</option>
                <option value="2">Hospital</option>
                <option value="3">Heart Attack</option>
                <option value="4">Breathing Problem</option>
              </Form.Select>
            </Form.Group>
          </Form> */}
        </Modal.Body>
        <Modal.Footer>
          {/* <Button
            variant=""
            style={{ backgroundColor: "#DA291C", color: "white" }}
          >
            Send Query
          </Button> */}
        </Modal.Footer>
      </Modal>

      {/* Accident  */}
      <Modal show={show4} onHide={handleClose4}>
        <Modal.Header closeButton style={{backgroundColor:"#698541",color:"white",display:"flex",alignItems:"center"}}>
          <Modal.Title>Accident</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* <div className=" text-center" style={{color:"#698541"}}>
            <h4>Accident</h4>
            <hr/>
          </div> */}
          <Form>
      {['checkbox'].map((type) => (
        <div key={`inline-${type}`} className="mb-3">
          <Form.Check
            inline
            label="Two-Wheeler"
            name="group1"
            type={type}
            id={`inline-${type}-1`}
          />
          <br/>
          <Form.Check
            inline
            label="Four-Wheeler"
            name="group1"
            type={type}
            id={`inline-${type}-2`}
          />
          <br/>
          <Form.Check
            inline
            label="Bus"
            name="group1"
            type={type}
            id={`inline-${type}-2`}
          />
          <br/>
          <Form.Check
            inline
            label="Tempo-Traveller"
            name="group1"
            type={type}
            id={`inline-${type}-2`}
          />
        </div>
      ))}
    </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary">Send Query</Button>
        </Modal.Footer>
      </Modal>

       {/* Robbery  */}
       <Modal show={show5} onHide={handleClose5}>
        <Modal.Header closeButton style={{backgroundColor:"#698541",color:"white",display:"flex",alignItems:"center"}}>
          <Modal.Title>Robbery</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* <div className=" text-center" style={{color:"#698541"}}>
            <h4>Accident</h4>
            <hr/>
          </div> */}
          <Form>
      {['checkbox'].map((type) => (
        <div key={`inline-${type}`} className="mb-3">
          <Form.Check
            inline
            label="Theft"
            name="group1"
            type={type}
            id={`inline-${type}-1`}
          />
          <br/>
          <Form.Check
            inline
            label="Gang Attack"
            name="group1"
            type={type}
            id={`inline-${type}-2`}
          />
          <br/>
          <Form.Check
            inline
            label="Misbehave"
            name="group1"
            type={type}
            id={`inline-${type}-2`}
          />
          <br/>
        </div>
      ))}
    </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary">Send Query</Button>
        </Modal.Footer>
      </Modal>

       {/* Vehicle Prob  */}
       <Modal show={show6} onHide={handleClose6}>
        <Modal.Header closeButton style={{backgroundColor:"#698541",color:"white",display:"flex",alignItems:"center"}}>
          <Modal.Title>Vehicle Problems</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* <div className=" text-center" style={{color:"#698541"}}>
            <h4>Accident</h4>
            <hr/>
          </div> */}
          <Form>
      {['checkbox'].map((type) => (
        <div key={`inline-${type}`} className="mb-3">
          <Form.Check
            inline
            label="Puncher"
            name="group1"
            type={type}
            id={`inline-${type}-1`}
          />
          <br/>
          <Form.Check
            inline
            label="Brake Failure"
            name="group1"
            type={type}
            id={`inline-${type}-2`}
          />
          <br/>
          <Form.Check
            inline
            label="Fuel Empty"
            name="group1"
            type={type}
            id={`inline-${type}-2`}
          />
          <br/>
          <Form.Check
            inline
            label="Vehicle Breakdown"
            name="group1"
            type={type}
            id={`inline-${type}-2`}
          />
        </div>
      ))}
    </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary">Send Query</Button>
        </Modal.Footer>
      </Modal>

       {/* Medical */}
       <Modal show={show7} onHide={handleClose7}>
        <Modal.Header closeButton style={{backgroundColor:"#698541",color:"white",display:"flex",alignItems:"center"}}>
          <Modal.Title>Medical Emergency</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* <div className=" text-center" style={{color:"#698541"}}>
            <h4>Accident</h4>
            <hr/>
          </div> */}
          <Form>
      {['checkbox'].map((type) => (
        <div key={`inline-${type}`} className="mb-3">
          <Form.Check
            inline
            label="Clinic"
            name="group1"
            type={type}
            id={`inline-${type}-1`}
          />
          <br/>
          <Form.Check
            inline
            label="Hospital"
            name="group1"
            type={type}
            id={`inline-${type}-2`}
          />
          <br/>
          <Form.Check
            inline
            label="Heart Attack"
            name="group1"
            type={type}
            id={`inline-${type}-2`}
          />
          <br/>
          <Form.Check
            inline
            label="Breathing Problem"
            name="group1"
            type={type}
            id={`inline-${type}-2`}
          />
        </div>
      ))}
    </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary">Send Query</Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default Location;
