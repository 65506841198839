import { useState } from "react";
import "./BookformStay.css";
import emailjs from "@emailjs/browser";
import axios from "axios";
import { useLocation } from "react-router-dom";
import moment from "moment";

export const BookFormStay = () => {
  const location = useLocation();
  const { item } = location.state;
  ////console.log(location);
  const user = JSON.parse(sessionStorage.getItem("user"));
  const [Checkindate, setCheckindate] = useState();
  const [name, setname] = useState("");
  const [email, setemail] = useState("");
  const [mobile, setmobile] = useState("");
  const [people, setpeople] = useState("");

  ////console.log(item, "item");
  const [Checkoutdate, setCheckoutdate] = useState();

  const handleOnSubmit = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_ovl2n1s",
        "template_ctlgwz2",
        e.target,
        "QT5ltA7BuoH7IMiRu"
      )
      .then(
        (result) => {
          alert("Booking request placed! We will contact you shortly.");
        },
        (error) => {
          alert("An error occurred! Please try again later.");
        }
      );
    e.target.reset();
  };
  const PackageBooking = async () => {
    if (!user) {
      alert("Please login");
      window.location.assign("/login");
    }

    try {
      const config = {
        url: "admin/bookstay",
        method: "post",
        baseURL: "https://bookingoda.com/api/",
        headers: { "Content-Type": "application/json" },
        data: {
          userId: user?._id,

          name: name ? name : user.name,
          email: email ? email : user.email,
          mobile: mobile ? mobile : user.mobile,
          noofrooms: location.state.Room.room,
          noofpeople: location.state.Room,
          VendorID: item.UserId,
          amount: location.state.amount,
          arrivaldate: moment(location.state.date[0].startDate).format(
            "DD/MM/YYYY"
          ),
          departuredate: moment(location.state.date[0].endDate).format(
            "DD/MM/YYYY"
          ),
          packageId: item._id,
        },
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert(res.data.success);
          window.location.assign("/bookingoda");
        }
      });
    } catch (error) {
      ////console.log(error);
      alert(error.response.data.error);
    }
  };
  return (
    <>
      <form className="form" onSubmit={handleOnSubmit}>
        <div className="inputbox">
          <h3>Your Name*</h3>
          <input
            type="text"
            placeholder="Name"
            required
            name="name"
            onChange={(e) => setname(e.target.value)}
          />
        </div>
        <div className="inputbox">
          <h3>Email Address*</h3>
          <input
            type="email"
            id="email"
            placeholder="abc@gmail.com"
            required
            pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$"
            name="email"
            onChange={(e) => setemail(e.target.value)}
          />
        </div>
        <div className="inputbox">
          <h3>Contact Number*</h3>
          <input
            type="tel"
            placeholder="Number"
            required
            maxlength="10"
            pattern="[0-9]{10}"
            name="contact"
            onChange={(e) => setmobile(e.target.value)}
          />
        </div>

        <div className="sub-btn">
          <button type="submit" className="subbtn" onClick={PackageBooking}>
            Book now
          </button>
        </div>
      </form>
    </>
  );
};
