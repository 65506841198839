import React, { useState, useEffect } from 'react';
import  haze  from '../../assets/haze.jpg';
import  rainy  from '../../assets/rainy.jpg';
import  snow  from '../../assets/snow.jpg';
import  sunny  from '../../assets/sunny.jpg';

const Weather1 = ({ weatherData }) => {
  const [backgroundImage, setBackgroundImage] = useState(null);

  const { weather, name, main: { temp, humidity }, wind: { speed } } = weatherData;
  const [{ main }] = weather;

  useEffect(() => {
    setBackgroundImage(getBackgroundImg(main));
  }, [weatherData]);

  function getBackgroundImg(weather) {
    if (weather === 'Snow') return snow;
    if (weather === 'Clear') return sunny;
    if (weather === 'Rain') return rainy;
    if (weather === 'Haze') return haze;
    return haze;
  }

  let textColor = backgroundImage !== sunny ? 'white' : 'black';

  return (
    <div className="container"  style={{width:"300px"}}>
      <div className="backgroundImg" style={{ backgroundImage: `url(${backgroundImage})` }}>
        <div style={{ alignItems: 'center' }}>
          <p className="headerTextweather" style={{ color: textColor, fontWeight: 'bold', fontSize: 30,lineHeight:"40px",width:"350px" }}>{name}</p>
          <p className="headerTextweather" style={{ color: textColor, fontWeight: 'bold' , fontSize: 30}}>{main}</p>
          <p className="headerTextweather" style={{ color: textColor, fontSize: 30 }}>{temp} °C</p>
        </div>

        <div className="extraInfo">
          <div className="infoweather">
            <p className="headerTextweather" style={{ fontSize: 18, color: 'white' }}>Humidity</p>
            <p className="headerTextweather" style={{ fontSize: 18, color: 'white' }}>{humidity} %</p>
          </div>

          <div className="infoweather">
            <p className="headerTextweather" style={{ fontSize: 18, color: 'white' }}>Wind Speed</p>
            <p className="headerTextweather" style={{ fontSize: 18, color: 'white' }}>{speed} m/s</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Weather1;
