import {
  FaChalkboard,
  FaCheck,
  FaClock,
  FaLongArrowAltRight,
  FaPhotoVideo,
} from "react-icons/fa";
import { City, Country, State } from "country-state-city";

import "./vacation.css";
import { Tooltip } from "antd";
import { MdDelete } from "react-icons/md";
import DatePicker from "react-multi-date-picker";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import { useEffect, useRef, useState } from "react";
import { GrMapLocation } from "react-icons/gr";
import Navbar from "../navbar/Navbar";
import Footer from "../footer/Footer";
import { Carousel, Modal } from "react-bootstrap";
import axios from "axios";
import { IoIosArrowDown, IoIosBed } from "react-icons/io";
import parse from "html-react-parser";
import { Link, useLocation, useParams } from "react-router-dom";
import { Map, GoogleApiWrapper, Marker } from "google-maps-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAnglesRight,
  faCircleArrowLeft,
  faCircleArrowRight,
  faCircleXmark,
  faMapMarkerAlt,
} from "@fortawesome/free-solid-svg-icons";
import { MdOutlineSlowMotionVideo } from "react-icons/md";
import { FaLocationDot } from "react-icons/fa6";
import { PiBathtubFill } from "react-icons/pi";
import { BsDoorOpenFill } from "react-icons/bs";
import { IoBedSharp, IoClose } from "react-icons/io5";
import Weather from "../../pages/hotel/Weather";
import { IoMdCheckmarkCircleOutline } from "react-icons/io";
import { GiDoorWatcher } from "react-icons/gi";
import { Button } from "antd";
import { debounce } from "lodash";
const API_KEY = "46a9246bebba16d42b36aac3fc3ba8af";
const Vacationdetails = (props) => {
  const user = JSON.parse(sessionStorage.getItem("user"));
  // const location = useLocation();
  // const { place } = location.state;
  const [places, setPlaces] = useState([]);
  const [street, setstreet] = useState("");
  const [payment, setpayment] = useState("");
  const [mobile, setmobile] = useState("");
  const [country, setcountry] = useState("");
  const [state, setstate] = useState("");

  const [city, setcity] = useState("");
  const [pincode, setpincode] = useState("");

  const CountryList = Country.getAllCountries();
  const StateList = State.getStatesOfCountry(country);
  const CityList = City.getCitiesOfState(country, state.isoCode);

  useEffect(() => {
    setstate({ isoCode: "", name: "" });
    setcity("");
  }, [country]);

  useEffect(() => {
    setcity("");
  }, [state]);
  const address = [street, city, state.name, country, pincode];
  const params = useParams();
  const place = places.filter((items) => items?._id === params.id)[0];
  const getvacation = async () => {
    let res = await axios.get("https://bookingoda.com/api/admin/getvacation");
    if (res.status === 200) {
      //////console.log(res.data);
      setPlaces(
        res.data.getvacation.filter((item) => item.blockstatus !== true)
      );
    }
  };
  useEffect(() => {
    getvacation();
  }, []);
  const handleKeyDown = (e) => {
    // Prevent the input of the minus sign
    if (e.key === "-" || e.key === "e" || e.key === "E") {
      e.preventDefault();
    }
  };
  const [uploadedImage, setUploadedImage] = useState([]);
  const fileInputRef = useRef(null);

  const handleFileChange = (event) => {
    const files = Array.from(event.target.files);
    setUploadedImage([...uploadedImage, ...files]);
    // Reset the file input value to allow re-uploading the same image
    fileInputRef.current.value = null;
  };

  const deleteImage = (index) => {
    const updatedImages = [...uploadedImage];
    updatedImages.splice(index, 1);
    setUploadedImage(updatedImages.length === 0 ? [] : updatedImages);
    if (fileInputRef.current) {
      fileInputRef.current.value = null;
    }
  };
  const [modalOpen, setModalOpen] = useState(false);
  const closeModal = () => setModalOpen(false);
  // const openModal = () => setModalOpen(true);

  const [photomodal, setPhotoModal] = useState(false);
  const closephotoModal = () => setPhotoModal(false);
  const openphotoModal = () => setPhotoModal(true);

  const [slideNumber, setSlideNumber] = useState(0);
  const [open, setOpen] = useState(false);
  const handleOpen = (i) => {
    setPhotoModal(false);
    setSlideNumber(i);
    setOpen(true);
  };
  const renderMedia = (media, index) => {
    return (
      <div className="hotelImgWrapper" key={index}>
        {media.image?.endsWith(".jpg") ||
        media.image?.endsWith(".webp") ||
        media.image?.endsWith(".jpeg") ||
        media.image?.endsWith(".png") ? (
          <img
            src={"https://bookingoda.com/image/" + media.image}
            alt="image"
            // style={{ width: "80px", height: "80px" }}
            onClick={() => handleOpen(index)}
            className="hotelImg"
          />
        ) : media.image.endsWith(".mp4") ? (
          <video
            src={"https://bookingoda.com/image/" + media.image}
            alt="video"
            className="hotelImg"
            controls
          />
        ) : null}
      </div>
    );
  };
  const handleMove = (direction) => {
    let newSlideNumber;

    if (direction === "l") {
      newSlideNumber = slideNumber === 0 ? 5 : slideNumber - 1;
    } else {
      newSlideNumber = slideNumber === 5 ? 0 : slideNumber + 1;
    }

    setSlideNumber(newSlideNumber);
  };
  const lat = 13.07876;
  const log = 77.53324;
  ////console.log(typeof lat, log);
  const apiKey = "AIzaSyCom4BCfTVsw3o22AYg69Y7EovmLfV_Alw";
  const [Adult, setAdult] = useState(0);
  const adultinc = () => {
    setAdult(Adult + 1);
  };
  const adultdec = () => {
    if (Adult > 0) {
      setAdult(Adult - 1);
    }
  };
  const [Pets, setPets] = useState(0);
  const Petsinc = () => {
    setPets(Pets + 1);
  };
  const Petsdec = () => {
    if (Pets > 0) {
      setPets(Pets - 1);
    }
  };
  const [Children, setChildren] = useState(0);
  const childreninc = () => {
    setChildren(Children + 1);
  };
  const childrendec = () => {
    if (Children > 0) {
      setChildren(Children - 1);
    }
  };
  const [isOpen, setIsOpen] = useState(false);

  const handleClick = () => {
    setIsOpen(!isOpen);
  };
  const [Checkindate, setCheckindate] = useState();
  const [Checkoutdate, setCheckoutdate] = useState();

  const [weatherData, setWeatherData] = useState(null);
  const [loaded, setLoaded] = useState(true);

  async function fetchWeatherData(cityName) {
    setLoaded(false);
    const API = `https://api.openweathermap.org/data/2.5/weather?q=${cityName}&units=metric&appid=${API_KEY}`;

    try {
      const response = await fetch(API);
      if (response.status === 200) {
        const data = await response.json();
        setWeatherData(data);
      } else {
        setWeatherData(null);
      }
      setLoaded(true);
    } catch (error) {
      console.error("Error fetching weather data:", error);
      setLoaded(true);
    }
  }

  const fetchData = async () => {
    try {
      const city = place?.city !== "" ? place?.city : "Bengaluru";
      await fetchWeatherData(city);
      // Other code that depends on the fetched data
    } catch (error) {
      console.error("Error fetching weather data:", error);
    }
  };
  useEffect(() => {
    fetchData();
  }, []); // The empty dependency array ensures that this effect runs only once on mount
  const debouncedFetchData = debounce(fetchData, 300);

  useEffect(() => {
    debouncedFetchData();
  }, [place?.city]); // The empty dependency array ensures that this effect runs only once on mount

  if (!loaded) {
    return (
      <div className="container">
        <div>Loading...</div>
      </div>
    );
  } else if (weatherData === null) {
    return (
      <div className="container">
        {/* Render any additional UI or messages for when weather data is not available */}
      </div>
    );
  }
  const formdata2 = new FormData();
   const mobilePattern = /^[0-9]{10}$/;
  const PackageBooking = async () => {
    if (!user) {
      return alert("Please login");
    } else if (!Checkindate) {
      return alert("please select Check In Date");
    } else if (!Checkoutdate) {
      return alert("please select Check Out Date");
    } else if (!Adult) {
      return alert("please confirm how many adult...");
    } else if (!pincode || !city || !state.name || !street || !country || !mobile) {
      alert("Please fill all the fields");
    } else if (!payment) {
      alert("Please select payment method");
    } else if (!mobilePattern.test(mobile)) {
      return alert("Please enter a valid mobile number");
    } else if (uploadedImage.length === 0) {
      alert("No documents selected");
    } else {
      try {
        const config = {
          url: "admin/bookvacationpackage",
          method: "post",
          baseURL: "https://bookingoda.com/api/",
          headers: { "Content-Type": "application/json" },
          data: {
            userId: user?._id,
            packageId: place?._id,
            checkindate: Checkindate,
            checkoutdate: Checkoutdate,

            adultprice: place?.offerprice * Adult,
            adultquantity: Adult,
            address: address,
            payment: payment,
            childrenprice: place?.childrenoffer * Children,
            childrenquantity: Children,
            petsprice: place?.petoffer * Pets,
            petsquantity: Pets,
          },
        };
        await axios(config).then((res) => {
          if (res.status === 200) {
            uploadFiles(res.data.data?._id);
            // alert(res.data.success);
            // window.location.assign("/bookingoda");
          }
        });
      } catch (error) {
        ////console.log(error);
        alert(error.response.data.error);
      }
    }
  };
  const uploadFiles = async (id) => {
 
      try {
        for (let i = 0; i < uploadedImage.length; i++) {
          formdata2.append("id", id);
          formdata2.append("Doc", uploadedImage[i]);
          await fetch("https://bookingoda.com/api/admin/addDoc/", {
            method: "post",
            body: formdata2,
          });
          alert("Booked Succesfully");
          window.location.assign("/bookingoda");
        }
      } catch (error) {}
    
  };
  const childrenDiscount = (place?.childrendiscount / 100) * place?.childrenoffer;
  const childPrice = place?.childrenoffer - childrenDiscount;
  return (
    <>
      <Navbar />

      <div className="site-content text-break" tabIndex={-1}>
        {open && (
          <div className="slider">
            <FontAwesomeIcon
              icon={faCircleXmark}
              style={{ width: "20px", color: "white", marginTop: "100px" }}
              className="close"
              onClick={() => setOpen(false)}
            />
            <FontAwesomeIcon
              icon={faCircleArrowLeft}
              className="arrow"
              onClick={() => handleMove("l")}
            />
            <div className="sliderWrapper">
              <img
                src={`https://bookingoda.com/image/${place?.GalleryImage[slideNumber]?.image}`}
                alt=""
                style={{ width: "500px", height: "500px" }}
                className="sliderImg"
              />
            </div>
            <FontAwesomeIcon
              icon={faCircleArrowRight}
              className="arrow"
              onClick={() => handleMove("r")}
            />
          </div>
        )}
        <div className="mb-4 mb-lg-8 mt-n7">
          <img
            fetchpriority="high"
            width={1920}
            height={500}
            src={`https://bookingoda.com/image/${place.GalleryImage[0]?.image}`}
            className="img-fluidv"
            alt=""
            decoding="async"
          />{" "}
          <div className="containerv">
            <div className="position-relative">
              <div className="position-absolute right-0 mt-md-n11 mt-n9">
                <div className="flex-horizontal-center">
                  {/* <a
                    className="js-fancybox btn btn-white transition-3d-hover py-2 px-md-5 px-3 shadow-6 mr-1"
                    href=""
                    data-src="https://www.youtube.com/watch?v=Vfk5VuUpJ-o"
                    data-speed={700}
                  >
                    <MdOutlineSlowMotionVideo className="flaticon-movie mr-md-2 font-size-18 text-primary" />
                    <span className="d-none d-md-inline">Video</span>
                  </a> */}
                  <a
                    className="js-fancybox btn btn-white transition-3d-hover ml-2 py-2 px-md-5 px-3 shadow-6 text"
                    onClick={openphotoModal}
                    data-src="https://mytravel.madrasthemes.com/wp-content/uploads/2022/03/Stone11.jpg"
                    data-fancybox="fancebox-159"
                    data-caption
                    data-speed={700}
                  >
                    <FaPhotoVideo className="flaticon-galleryv mr-md-2 font-size-18 text-primary" />
                    <span className="d-none d-md-inline">Gallery</span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="containerv">
          <div className="mb-4">
            <div className="woocommerce-notices-wrapper" />{" "}
          </div>
          <div className="row">
            <div className="col-lg-8 col-xl-8">
              <div
                id="product-476"
                className="product type-product post-476 status-publish first instock product_cat-coupes product_cat-rental has-post-thumbnail sold-individually shipping-taxable purchasable product-type-booking"
              >
                <div className="d-block d-md-flex flex-center-between align-items-start mb-3">
                  <div className="mb-3">
                    <div className="d-block d-md-flex flex-horizontal-center">
                      <div className="mb-2 mb-md-0">
                        <h4 className="font-size-23 font-weight-bold mb-1 mr-3">
                          {place.stayname}
                        </h4>{" "}
                      </div>
                    </div>
                    <div className="font-size-16 text-gray-1" sty>
                      <FaLocationDot
                        className="icon flaticon-placeholder  font-size-20"
                        style={{ color: "blue" }}
                      />
                      {place?.street} {place.city},{place.state},{place.country}{" "}
                      {/* <a
                        href=""
                        data-src="#hotel-map"
                        data-speed={700}
                        className="js-fancybox ml-1 d-block d-md-inline"
                      >
                        {" "}
                        - View on map
                      </a> */}
                    </div>
                  </div>
                </div>
                <div
                  id="hotel-map"
                  style={{ display: "none", width: "80%", height: "80%" }}
                >
                  <div className="acf-map" data-zoom={16}>
                    <div style={{ height: "100%", width: "100%" }}>
                      <div style={{ overflow: "hidden" }} />
                    </div>
                  </div>
                </div>
                <div className="py-4 border-top border-bottom mb-4">
                  <ul className="list-group list-group-borderless list-group-horizontal flex-center-between text-center mx-md-4 flex-wrap">
                    <li className="list-group-item text-lh-sm ">
                      {" "}
                      <FaChalkboard className="flaticon-plans text-primary mb-1 d-block font-size-50" />
                      <div className="text-gray-1">
                        {place.Measurement} sq ft
                      </div>
                    </li>
                    <li className="list-group-item text-lh-sm ">
                      {" "}
                      <BsDoorOpenFill className="flaticon-door text-primary mb-1 d-block font-size-50" />
                      <div className="text-gray-1">{place.Rooms} rooms</div>
                    </li>
                    <li className="list-group-item text-lh-sm ">
                      {" "}
                      <PiBathtubFill className="flaticon-bathtub text-primary mb-1 d-block font-size-50" />
                      <div className="text-gray-1">
                        {place.Bathrooms} bathrooms
                      </div>
                    </li>
                    <li className="list-group-item text-lh-sm ">
                      {" "}
                      <IoBedSharp className="flaticon-bed-1 text-primary mb-1 d-block font-size-50" />
                      <div className="text-gray-1">{place.Beds} beds</div>
                    </li>
                  </ul>
                </div>
                <div
                  id="single-hotel__description"
                  className="border-bottom position-relative"
                >
                  <h5
                    id="scroll-description"
                    className="font-size-21 font-weight-bold text-dark
               mb-3"
                  >
                    Description{" "}
                  </h5>
                  <div>
                    <p>{place.staydescription}</p>
                  </div>
                </div>
                <div
                  id="single-hotel__amenities"
                  class="amenities border-bottom py-4 position-relative"
                >
                  <h3 class="font-size-21 font-weight-bold text-dark mb-4">
                    Amenities{" "}
                  </h3>
                  <ul className="list-group list-group-borderless list-group-horizontal list-group-flush no-gutters row">
                    {place &&
                      place &&
                      place?.Amenities &&
                      Object.entries(place?.Amenities)
                        ?.filter(([key, value]) => value === true)
                        ?.map(([key]) => (
                          <li className="col-md-4 list-group-item mb-3">
                            <div className="d-flex" style={{ padding: "20px" }}>
                              {key}
                            </div>
                          </li>
                        ))}
                  </ul>
                </div>
                <div id="single-hotel__details" className="py-4">
                  <h5 className="font-size-21 font-weight-bold text-dark mb-4">
                    Details{" "}
                  </h5>
                  <ul className="list-group list-group-borderless list-group-horizontal list-group-flush no-gutters row">
                    <li className="col-md-2 mb-5 mb-md-0 list-group-item pt-0 border-bottom pb-3">
                      <div className="font-weight-bold text-dark">
                        Terms &amp; rules{" "}
                      </div>
                    </li>

                    <li className="col-md-10 mb-5 mb-md-0 list-group-item pt-0 border-bottom pb-3">
                      <div className="flex-horizontal-center mb-3 text-gray-1">
                        {place.Children !== "false" ? (
                          <FaCheck className="flaticon-tick mr-3 font-size-16 text-primary" />
                        ) : (
                          <IoClose className="flaticon-close mr-3 font-size-20 text-primary" />
                        )}
                        Children allowed
                      </div>
                      <div className="flex-horizontal-center mb-3 text-gray-1">
                        {place.Smoking !== "false" ? (
                          <FaCheck className="flaticon-tick mr-3 font-size-16 text-primary" />
                        ) : (
                          <IoClose className="flaticon-close mr-3 font-size-20 text-primary" />
                        )}
                        Smoking allowed
                      </div>
                      <div className="flex-horizontal-center mb-3 text-gray-1">
                        {place.Pets !== "false" ? (
                          <FaCheck className="flaticon-tick mr-3 font-size-16 text-primary" />
                        ) : (
                          <IoClose className="flaticon-close mr-3 font-size-20 text-primary" />
                        )}
                        Pets allowed{" "}
                      </div>
                      <div className="flex-horizontal-center mb-3 text-gray-1">
                        {place.Parties !== "false" ? (
                          <FaCheck className="flaticon-tick mr-3 font-size-16 text-primary" />
                        ) : (
                          <IoClose className="flaticon-close mr-3 font-size-20 text-primary" />
                        )}
                        Party allowed
                      </div>
                    </li>
                  </ul>
                </div>

                <div className="list-single-main-item-title fl-wrap">
                  <h3>Location</h3>
                </div>
                <div
                  id="map"
                  style={{ position: "relative", overflow: "hidden" }}
                >
                  <div
                    style={{
                      height: "100%",
                      width: "100%",
                      position: "absolute",
                      top: 0,
                      left: 0,
                      backgroundColor: "rgb(229, 227, 223)",
                    }}
                  >
                    <Map
                      google={props.google}
                      zoom={14}
                      style={{ width: "100%", height: "400px" }}
                      initialCenter={{ lat: lat, lng: log }}
                    >
                      <Marker position={{ lat: lat, lng: log }} />
                    </Map>
                  </div>
                </div>

                <div id="single-hotel__video" className="border-bottom py-4">
                  <h5 className="font-size-21 font-weight-bold text-dark mb-4">
                    Video{" "}
                  </h5>
                  <div
                    id="youTubeVideoPlayerExample1"
                    className="u-video-player rounded-sm"
                  >
                    {place?.GalleryImage.map((data, index) =>
                      data.image.endsWith(".mp4") ? (
                        <video
                          key={index} // Add a key prop when mapping over elements
                          src={"https://bookingoda.com/image/" + data.image}
                          alt="video"
                          className="hotelImg"
                          style={{ width: "900px", height: "450px" }}
                          controls
                        />
                      ) : null
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-xl-4">
              {weatherData && (
                <Weather
                  weatherData={weatherData}
                  fetchWeatherData={fetchWeatherData}
                />
              )}
              <div
                style={{
                  border: "1px solid rgb(221, 221, 221)",
                  borderRadius: "12px",
                  padding: "24px",
                  boxShadow: "rgba(0, 0, 0, 0.12) 0px 6px 16px",
                  marginTop: "20px",
                }}
              >
                   <div className="out_0">
                <p>
                  Starting From {"   "}
                  <h6>INR {place?.staycost}</h6>
                </p>
                <p>
                  ₹{place?.staycost} <span>/ per Adult</span>
                </p>
                <p>
                  <label>Adult Discount :</label> {place?.staydiscount}%
                </p>
                <p>
                  ₹{place?.childrenoffer} <span>/ per Children</span>
                </p>
                <p>
                  <label>Children Discount :</label> {place?.childrendiscount}%
                </p>
                <h6>
                  Total: ₹
                  {place?.offerprice * (Adult !== 0 ? Adult : null) +
                    childPrice * (Children !== 0 ? Children : null)}
                  {/* ₹{item?.offerprice} <span>/ per Adult</span> */}
                </h6>
              </div>

                <div>
                  <div className="d-flex mb-2">
                    <div className="warner">
                      <label>Check In</label> <br />
                      <DatePicker
                        minDate={new Date()}
                        onChange={(date) =>
                          setCheckindate(date.format("DD/MM/YYYY"))
                        }
                        placeholder="Add Date"
                        style={{
                          border: "none",
                          padding: "3px",
                          width: "145px",
                        }}
                      />
                    </div>
                    <div className="warner">
                      <label>Check Out</label> <br />
                      <DatePicker
                       minDate={new Date()}
                        placeholder="Add Date"
                        onChange={(date) =>
                          setCheckoutdate(date.format("DD/MM/YYYY"))
                        }
                        style={{
                          border: "none",
                          padding: "3px",
                          width: "145px",
                        }}
                      />
                    </div>
                  </div>

                  <div className="warner-out">
                    <p>Guest</p>
                    <div onClick={handleClick}>
                      <span>{Adult} Adult,</span>
                      <span>{Children} Children</span>
                      {/* <span>{Pets} pet</span> */}
                      <span className="float-end">
                        <IoIosArrowDown />
                      </span>
                    </div>
                  </div>

                  {isOpen && (
                    <>
                      <div className="p-2">
                        <div className="d-flex justify-content-between align-items-center mb-3">
                          <div>
                            <b>Adults</b>
                            <p style={{ fontSize: "12px" }}>Age 13+</p>
                          </div>
                          <div className="d-flex gap-2">
                            <RemoveIcon
                              onClick={() => adultdec()}
                              className="increase-icon"
                            />
                            <span>{Adult}</span>
                            <span>
                              <AddIcon
                                onClick={() => adultinc()}
                                className="increase-icon"
                              />
                            </span>
                          </div>
                        </div>

                        <div className="d-flex justify-content-between align-items-center mb-3">
                          <div>
                            <b>Children</b>
                            <p style={{ fontSize: "12px" }}>Age 2-12</p>
                          </div>
                          <div className="d-flex gap-2">
                            <RemoveIcon
                              onClick={() => childrendec()}
                              className="increase-icon"
                            />
                            <span>{Children}</span>
                            <span>
                              <AddIcon
                                onClick={() => childreninc()}
                                className="increase-icon"
                              />
                            </span>
                          </div>
                        </div>

                        {/* <div className="d-flex justify-content-between align-items-center mb-3">
                        <div>
                          <b>Infants</b>
                          <p style={{ fontSize: "12px" }}>Under 2</p>
                        </div>
                        <div className="d-flex gap-2">
                          <RemoveIcon
                            onClick={() => decrease()}
                            className="increase-icon"
                          />
                          <span>{Adult}</span>
                          <span>
                            
                            <AddIcon
                              onClick={() => increase()}
                              className="increase-icon"
                            />
                          </span>
                        </div>
                      </div> */}

                        {/* <div className="d-flex justify-content-between align-items-center mb-3">
                        <div>
                          <b>Pets</b>
                          <p style={{ fontSize: "12px" }}></p>
                        </div>
                        <div className="d-flex gap-2">
                          <RemoveIcon
                            onClick={() => Petsdec()}
                            className="increase-icon"
                          />
                          <span>{Pets}</span>
                          <span>
                            
                            <AddIcon
                              onClick={() => Petsinc()}
                              className="increase-icon"
                            />
                          </span>
                        </div>
                      </div> */}
                      </div>
                    </>
                  )}
                  <div className="warner-out">
                  {/* <input
                    type="text"
                    placeholder="Country"
                    required
                    style={{ width: "100%" }}
                    name="country"
                    onChange={(e) => setcountry(e.target.value)}
                  /> */}
                  <select
                  className="vi_0"
                    name="cc1"
                    id="cc1"
                    value={country}
                    onChange={(e) => setcountry(e.target.value)}
                  >
                    <option value="">Select country/region</option>

                    {CountryList?.map((Country) => (
                      <option value={Country?.isoCode} data-key="in">
                        {Country?.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="warner-out">
                  <input
                    type="tel"
                    onKeyDown={handleKeyDown}
                    maxLength={10}
                    placeholder="Mobile Number"
                    required
                    style={{ width: "100%" }}
                    name="Mobile Number"
                    onChange={(e) => setmobile(e.target.value)}
                  />
                </div>
                <div className="warner-out">
                  <input
                    type="text"
                    placeholder="Street address"
                    required
                    style={{ width: "100%" }}
                    name="Street"
                    onChange={(e) => setstreet(e.target.value)}
                  />
                </div>
                   <div className="warner-out">
                  <input
                    type="number"
                    onKeyDown={handleKeyDown}
                    maxLength={10}
                    placeholder="Mobile Number"
                    required
                    style={{ width: "100%" }}
                    name="Mobile Number"
                    onChange={(e) => setmobile(e.target.value)}
                  />
                </div>
                <div className="warner-out">
                  {/* <input
                    type="text"
                    placeholder="State"
                    required
                    style={{ width: "100%" }}
                    name="State"
                    onChange={(e) => setstate(e.target.value)}
                  /> */}
                  <select
                    className="vi_0"
                    name="cc1"
                    id="cc1"
                    style={{
                      width: "100%",
                      borderColor: "#E6E6E6",
                      fontSize: "16px",
                    }}
                    onChange={(e) => {
                      const selectedState = StateList.find(
                        (state) => state.isoCode === e.target.value
                      );
                      setstate(
                        selectedState
                          ? {
                              isoCode: selectedState.isoCode,
                              name: selectedState.name,
                            }
                          : { isoCode: "", name: "" }
                      );
                    }}
                  >
                    <option value="">
                      Select State
                      <span style={{ color: "red" }}>*</span>
                    </option>
                    {StateList?.map((state) => (
                      <option key={state.isoCode} value={state.isoCode}>
                        {state.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="warner-out">
                  {/* <input
                    type="text"
                    placeholder="City"
                    required
                    style={{ width: "100%" }}
                    name="City"
                    onChange={(e) => setcity(e.target.value)}
                  /> */}
                  <select
                    className="vi_0"
                    // class="ebf4591c8e country-select-for-e2e-tests"
                    style={{
                      width: "100%",
                      borderColor: "#E6E6E6",
                      fontSize: "16px",
                    }}
                    name="cc1"
                    id="cc1"
                    onChange={(e) => setcity(e.target.value)}
                  >
                    <option value="" style={{ fontSize: "16px" }}>
                      Select City <span style={{ color: "red" }}>*</span>
                    </option>
                    {CityList?.map((city1) => (
                      <option value={city1?.name} style={{ fontSize: "16px" }}>
                        {city1?.name}
                      </option>
                    ))}
                  </select>
                </div>
                  <div className="warner-out">
                    <input
                      type="number"
                      onKeyDown={handleKeyDown}
                      maxLength={6}
                      placeholder="Pincode"
                      required
                      style={{ width: "100%" }}
                      name="Pincode"
                      onChange={(e) => setpincode(e.target.value)}
                    />
                  </div>

                  <div className="upload-photos_container">
                    <div className="drag-and-drop__highlight">
                      <div className="wide-container min-height ota-container">
                        <div
                          className="spacing--4x"
                          data-testid="NumberOfPhotosRequiredMessage-container"
                        >
                          <label
                            htmlFor="photoFileInput"
                            className="add-photo-sub-info font_body_2"
                          >
                            <div
                              className="a3332d346a"
                              style={{ margin: "10px", display: "block" }}
                            >
                              Upload documents for verfication (Pan card/Aadhar
                              Card/Any other relevant government document).
                            </div>{" "}
                          </label>
                        </div>
                        <div className="take-photo take-photo--with-drag-and-drop">
                          <div className="drag-and-drop">
                            <div
                              style={{
                                border: "2px dashed #cccccc",
                                borderRadius: "4px",
                                padding: "20px",
                                textAlign: "center",
                                display: "flex",
                                flexDirection: "row",
                                cursor: "pointer",
                              }}
                            >
                              <input
                                type="file"
                                multiple
                                onChange={handleFileChange}
                                ref={fileInputRef}
                              />
                              <p>Click to select one or more images</p>
                            </div>
                          </div>
                        </div>
                        <div className="take-photo take-photo--with-drag-and-drop">
                          <div className="drag-and-drop">
                            <div
                              style={{
                                textAlign: "center",
                                marginTop: "10px",
                              }}
                            >
                              {uploadedImage &&
                                uploadedImage.map((item, index) => (
                                  <div
                                    key={index}
                                    style={{
                                      display: "inline-block",
                                      marginRight: "10px",
                                      marginTop: "10px",
                                    }}
                                  >
                                    <img
                                      src={URL.createObjectURL(item)}
                                      alt="Uploaded"
                                      style={{ maxWidth: "50px" }}
                                    />

                                    <Tooltip title="delete">
                                      <MdDelete
                                        color="red"
                                        onClick={() => deleteImage(index)}
                                      />
                                    </Tooltip>
                                  </div>
                                ))}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div>
                    <h3>Payment Method</h3>
                    <div
                      id="payment"
                      className="woocommerce-checkout-payment"
                      style={{}}
                    >
                      <ul
                        className="wc_payment_methods payment_methods methods"
                        style={{ marginTop: "20px" }}
                      >
                        <li
                          className="wc_payment_method payment_method_cheque"
                          style={{ display: "flex", flexDirection: "row" }}
                        >
                          <input
                            id="payment_method_cheque"
                            type="radio"
                            className="input-radio"
                            name="payment_method"
                            value="Online payment"
                            onChange={(e) => setpayment(e.target.value)}
                          />
                          <label
                            htmlFor="payment_method_cheque"
                            style={{ marginLeft: "20px", marginTop: "10px" }}
                          >
                            {"     "} Online payment
                          </label>
                        </li>
                        <li
                          className="wc_payment_method payment_method_cod"
                          style={{ display: "flex", flexDirection: "row" }}
                        >
                          <input
                            id="payment_method_cod"
                            type="radio"
                            className="input-radio"
                            name="payment_method"
                            data-order_button_text
                            value="Pay Cash"
                            onChange={(e) => setpayment(e.target.value)}
                          />
                          <label
                            htmlFor="payment_method_cod"
                            style={{ marginLeft: "20px", marginTop: "10px" }}
                          >
                            Pay Cash
                          </label>
                        </li>
                      </ul>
                    </div>
                  </div>

                  <div>
                    <button
                      type="submit"
                      name="add-to-cart"
                      value="214"
                      class="single_add_to_cart_button button alt btn btn-primary d-flex align-items-center justify-content-center height-60 w-100 mb-xl-0 mb-lg-1 transition-3d-hover font-weight-bold w-100 date-selection-needed"
                      onClick={() => PackageBooking()}
                    >
                      Book Now
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="border-bottom border-gray-33 related product-cardv-block product-cardv-v3 
      "
        >
          <div className="space-1">
            <div className="containerv">
              <div className="w-md-80 w-lg-50 text-center mx-md-auto pb-4">
                <h2 className="section-title text-black font-size-30 font-weight-bold mb-0">
                  You might also like...
                </h2>
              </div>
              <div
                className="elementor-element elementor-element-0b240f7 mt-lg-n1 mb-4 elementor-widget elementor-widget-myt-product-carousel"
                data-id="0b240f7"
                data-element_type="widget"
                data-settings='{"autoplay":"no","center_slides":"no","infinite":"no","fade":"no"}'
                data-widget_type="myt-product-carousel.rental"
              >
                <div className="elementor-widget-containerv">
                  <div className="myt-carousel rental-slide-wrap">
                    <div
                      className="js-slick-carousel u-slick u-slick--equal-height u-slick--gutters-3 mytravel-65d8783e2b581 slick-initialized slick-slider slick-dotted"
                      data-slides-show={4}
                      data-ride="slick"
                      data-responsive='[{"breakpoint":1025,"settings":{"slidesToShow":3}},{"breakpoint":992,"settings":{"slidesToShow":2}},{"breakpoint":768,"settings":{"slidesToShow":1}}]'
                      data-center-mode={0}
                      data-autoplay
                      data-autoplay-speed={3000}
                      data-pagi-classes="text-center u-slick__pagination mt-4 d-lg-none"
                      data-infinite
                      data-fade
                      data-rows={1}
                      data-arrows-classes="elementor-slick-arrow d-none d-lg-inline-block u-slick__arrow-classic u-slick__arrow-centered--y rounded-circle  u-slick__arrow-classic--v1 shadow-5"
                      data-arrow-left-classes="fas fa-chevron-left u-slick__arrow-classic-inner u-slick__arrow-classic-inner--left"
                      data-arrow-right-classes="fas fa-chevron-right u-slick__arrow-classic-inner u-slick__arrow-classic-inner--right"
                    >
                      <div className="slick-list draggable">
                        <div
                          className="slick-track"
                          style={{
                            opacity: 1,
                            width: "1500px",
                            transform: "translate3d(0px, 0px, 0px)",
                          }}
                        >
                          {places.slice(0, 4).map((data) => (
                            <div
                              className="js-slide slick-slide slick-current slick-active"
                              data-slick-index={0}
                              aria-hidden="false"
                              tabIndex={0}
                              role="tabpanel"
                              id="slick-slide00"
                              aria-describedby="slick-slide-control00"
                              style={{ maxWidth: "300px", height: "auto" }}
                            >
                              <div className="add-to-wishlist-after_add_to_cart product type-product post-218 status-publish first instock product_cat-coupes product_cat-rental has-post-thumbnail featured shipping-taxable purchasable product-type-simple mb-3 mb-md-4 pb-1">
                                <div className="cardv transition-3d-hover shadow-hover-2 h-100">
                                  <div className="cardv-header position-relative p-0">
                                    <Link to={`/Vacationdetails/${data._id}`}>
                                      <img
                                        fetchpriority="high"
                                        decoding="async"
                                        width={300}
                                        height={225}
                                        src={`https://bookingoda.com/image/${data.GalleryImage[0]?.image}`}
                                        className="min-height-230 bg-img-hero cardv-img-top"
                                        alt=""
                                      />{" "}
                                    </Link>

                                    <div className="price-wrapper position-absolute bottom-0 left-0 right-0">
                                      <div className="px-3 pb-2">
                                        <a
                                          className="stretched-link cardv-title text-dark"
                                          tabIndex={0}
                                        >
                                          <h2 className="woocommerce-loop-product__title font-size-17 text-white font-weight-bold">
                                            {data.stayname}
                                          </h2>
                                        </a>{" "}
                                        <span className="price">
                                          <span className="woocommerce-Price-amount font-weight-bold font-size-19 mb-2 d-inline-block text-white amount">
                                            <bdi>
                                              <span className="woocommerce-Price-currencySymbol">
                                                INR
                                              </span>
                                              {data.staycost}
                                            </bdi>
                                          </span>
                                          <span className="wceb-price-format">
                                            {" "}
                                            / night
                                          </span>{" "}
                                        </span>
                                      </div>
                                    </div>
                                  </div>

                                  <div
                                    className="cardv-body
					border-bottom"
                                  >
                                    <Link to={`/Vacationdetails/${data._id}`}>
                                      <div className="font-size-14 text-gray-1">
                                        <FontAwesomeIcon
                                          icon={faMapMarkerAlt}
                                          style={{ color: "#69a3cf" }}
                                          className="icon flaticon-pin-1 mr-2 font-size-24"
                                        />
                                        {data?.street} {data.city},{data.state},
                                        {data.country}{" "}
                                      </div>
                                    </Link>
                                  </div>
                                  <div className="">
                                    <div className="row">
                                      <div className="col-6">
                                        {" "}
                                        <div className="media mb-2 text-gray-1 align-items-center">
                                          <small className="mr-2">
                                            <i
                                              className="fas fa-chalkboard font-size-24 small"
                                              style={{ color: "#69a3cf" }}
                                            />
                                          </small>
                                          <div className="media-body font-size-1">
                                            {data.Measurement} sq ft
                                          </div>
                                        </div>
                                      </div>
                                      <div className="col-6">
                                        {" "}
                                        <div className="media mb-2 text-gray-1 align-items-center">
                                          <small className="mr-2">
                                            <GiDoorWatcher
                                              className="fas fa-chalkboard font-size-24 small"
                                              style={{ color: "#69a3cf" }}
                                            />
                                          </small>
                                          <div className="media-body font-size-1">
                                            {data.Rooms} rooms
                                          </div>
                                        </div>
                                      </div>
                                      <div className="col-6">
                                        {" "}
                                        <div className="media mb-2 text-gray-1 align-items-center">
                                          <small className="mr-2">
                                            <PiBathtubFill
                                              className="fas fa-chalkboard font-size-24 small"
                                              style={{ color: "#69a3cf" }}
                                            />
                                          </small>
                                          <div className="media-body font-size-1">
                                            {data.Bathrooms} bathrooms
                                          </div>
                                        </div>
                                      </div>
                                      <div className="col-6">
                                        {" "}
                                        <div className="media mb-2 text-gray-1 align-items-center">
                                          <small className="mr-2">
                                            <IoIosBed
                                              className="flaticon-bed-1 font-size-24 small"
                                              style={{ color: "#69a3cf" }}
                                            />
                                          </small>
                                          <div className="media-body font-size-1">
                                            {data.Beds} beds
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          ))}
                          <Modal
                            show={photomodal}
                            onHide={closephotoModal}
                            backdrop="static"
                            keyboard={false}
                            size="lg"
                          >
                            <Modal.Body>
                              <Button
                                className="ajax-modal-close"
                                onClick={closephotoModal}
                              >
                                <i
                                  className="fa-solid fa-circle-xmark"
                                  style={{ alignSelf: "center" }}
                                />
                              </Button>
                              <div className="hotelImages">
                                {place.GalleryImage?.map((media, index) =>
                                  renderMedia(media, index)
                                )}
                              </div>
                            </Modal.Body>
                          </Modal>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};
export default GoogleApiWrapper({
  apiKey: "AIzaSyCom4BCfTVsw3o22AYg69Y7EovmLfV_Alw",
})(Vacationdetails);
