import React from "react";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import { FaAngleRight, FaDirections } from "react-icons/fa";
import Modal from "react-bootstrap/Modal";
import { useState } from "react";
import { IoCall } from "react-icons/io5";

const Navigation = () => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  //GetDirections
  const [show1, setShow1] = useState(false);
  const handleClose1 = () => setShow1(false);
  const handleShow1 = () => setShow1(true);

  //Next
  const [show2, setShow2] = useState(false);
  const handleClose2 = () => setShow2(false);
  const handleShow2 = () => setShow2(true);

  //Allow
  const [show3, setShow3] = useState(false);
  const handleClose3 = () => setShow3(false);
  const handleShow3 = () => setShow3(true);

  return (
    <div>
      <header>
        <nav>
          <div className="Contaniner-fluid navbar-color">
            <Navbar expand="lg" className=" ">
              <Container fluid>
                <Navbar.Brand className="fs-1 text-light fw-bold" href="#">
                  {/* <img src="../img/logo.svg" alt="" style={{ width: '250px', height: '50px' }} /> */}
                  <p style={{ color: "#da291c", fontSize: "28px" }}>
                    Booking-Oda.com
                  </p>
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="navbarScroll" />
                <div style={{ display: "flex" }}>
                  <div className="jh">
                    <Button type="button" class="btn btn-primary px-3" style={{backgroundColor:"#da291c", border: "1px solid #da291c",}}>
                      <IoCall />
                      <span style={{ fontWeight: "bold" }}>
                        {" "}
                        24*7 Customer care service{" "}
                      </span>
                      <p
                        style={{
                          textDecoration: "underline",
                          fontWeight: "bold",
                        }}
                      >
                        1234567899
                      </p>
                    </Button>
                  </div>
                  <div style={{ margin: "auto" }}>
                    <button
                      className="text-light fw-bold renew-btn"
                      onClick={handleShow1}
                    >
                      {" "}
                      Road Side Assisstance
                      <FaAngleRight style={{display:'inline-block'}}/>
                    </button>
                  </div>
                </div>
                {/* <Navbar.Collapse id="navbarScroll">
                  <Nav
                    className="m-auto my-2 my-lg-0"
                    style={{ maxHeight: '100px' }}
                    navbarScroll
                  >
                    <Nav.Link className="fs-6 fw-bold  text-light letter-space" href="/">Home</Nav.Link>
                    <Nav.Link className="fs-6 fw-bold text-light letter-space" href="/MotorCycle">Offers</Nav.Link>
                    <Nav.Link className="fs-6 fw-bold text-light letter-space" href="/Service" >Service</Nav.Link>
                    <Nav.Link className="fs-6 fw-bold text-light letter-space" href="/Shop" >Shop</Nav.Link>
                    <Nav.Link className="fs-6 fw-bold text-light letter-space" href="/Contact" >Contact Us</Nav.Link>
                  </Nav>
                  <Form className="d-flex">
                    <Form.Control
                      type="search"
                      placeholder="Search"
                      className="me-2"
                      aria-label="Search"
                    />
                    <button className='renew-btn' >Search</button>
                  </Form>
                </Navbar.Collapse> */}
              </Container>
            </Navbar>
          </div>
          <div className="navbar-color">
            <Container fluid>
              <div className="d-flex justify-content-between pb-2">
                {/* <p className='text-light fs-5 fw-bold'> Road Side Assistance</p> */}
                <div className="d-flex gap-2">
                  {/* <button className='buy-btn'>Buy RSA <FaAngleRight /></button>
                  <button className='renew-btn'>Renew RSA <FaAngleRight /></button> */}
                </div>
              </div>
            </Container>
          </div>
        </nav>
      </header>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Modal heading</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Select
            aria-label="Default select example"
            className="service-input"
          >
            <option>Select Vehcle Type</option>
            <option value="1">Two Wheeler</option>
            <option value="2">Four Wheeler</option>
          </Form.Select>
          <input
            type="text"
            className="form-control"
            placeholder="Vehicle Number"
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleClose}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>

      {/* GetDirection  */}
      <Modal show={show1} onHide={handleClose1}>
        <Modal.Header closeButton>
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3" controlId="formGroupEmail">
              <Form.Label>Enter Vehicle Type</Form.Label>
              <Form.Select aria-label="Default select example">
                <option>Open this select menu</option>
                <option value="1">Car</option>
                <option value="2">Two-Wheeler</option>
                <option value="3">Bus</option>
              </Form.Select>
            </Form.Group>
            <Form.Group className="mb-3" controlId="formGroupEmail">
              <Form.Label>Vehicle Number</Form.Label>
              <Form.Control type="text" placeholder="Enter Vehicle Number" />
            </Form.Group>

            <Form>
              {["checkbox"].map((type) => (
                <div key={`inline-${type}`} className="mb-3">
                  <Form.Check
                    inline
                    label="I agree to the terms and conditions."
                    name="group1"
                    type={type}
                    id={`inline-${type}-1`}
                  />
                </div>
              ))}
            </Form>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant=""
            style={{ backgroundColor: "#DA291C", color: "white" }}
            onClick={handleShow2}
          >
            Next
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Next  */}
      <Modal show={show2} onHide={handleClose2}>
        <Modal.Header closeButton>
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h6>Are you on road?</h6>
          <Form>
            {["checkbox"].map((type) => (
              <div key={`inline-${type}`} className="mb-3">
                <Form.Check
                  inline
                  label="Yes"
                  name="group1"
                  type={type}
                  id={`inline-${type}-1`}
                />
                <Form.Check
                  inline
                  label="No"
                  name="group1"
                  type={type}
                  id={`inline-${type}-2`}
                />
              </div>
            ))}
          </Form>

          <h6>Are you on Highway?</h6>
          <Form>
            {["checkbox"].map((type) => (
              <div key={`inline-${type}`} className="mb-3">
                <Form.Check
                  inline
                  label="Yes"
                  name="group1"
                  type={type}
                  id={`inline-${type}-1`}
                />
                <Form.Check
                  inline
                  label="No"
                  name="group1"
                  type={type}
                  id={`inline-${type}-2`}
                />
              </div>
            ))}
          </Form>

          <h6>In which direction are you riding?</h6>
          <div
            className="pre-0"
            style={{ display: "flex", alignItems: "center" }}
          >
            {/* <label>Search Address</label> */}
            <input
              style={{ width: "100%" }}
              class="form-controls"
              required=""
              placeholder="Enter Location"
              name="name"
              type="text"
            ></input>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant=""
            style={{ backgroundColor: "#DA291C", color: "white" }}
            onClick={handleShow3}
          >
            Next
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Allow  */}
      <Modal show={show3} onHide={handleClose3}>
        <Modal.Header closeButton>
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h6>Allow your current location</h6>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose3}>
            No
          </Button>
          <a href="\location">
            <Button
              variant=""
              style={{ backgroundColor: "#DA291C", color: "white" }}
              // onClick={handleShow4}
            >
              Yes
            </Button>
          </a>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default Navigation;

// <div className='col-6'>
// <ul className='d-flex gap-3 text-decoration-none text-center' style={{ listStyle: "none" }}>
//   <li className='btn-group'>
//     <button type="button" className="btn  dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
//       Action
//     </button>
//    <ul style={{ listStyle: "none" }} >
//       <li ><a className='text-decoration-none text-light' href="#/home-v2">Home 02</a></li>
//       <li><a className='text-decoration-none text-light' href="#/home-v3">Home 03</a></li>
//       <li><a className='text-decoration-none text-light' href="#/home-v4">Home 04</a></li>
//     </ul>
//   </li>
//   <li><a className='text-decoration-none text-light' href='#'>About </a></li>
//   <li>
//     <li className="dropdown"><a className='text-decoration-none text-light' href="#/">Services<button className=""><i className=""></i></button></a>
//       <ul style={{ listStyle: "none" }}>
//         <li><a className='text-decoration-none text-light' href="#/home-v2">Service 02</a></li>
//         <li><a className='text-decoration-none text-light' href="#/home-v3">Service 03</a></li>
//         <li><a className='text-decoration-none text-light' href="#/home-v4">Service 04</a></li>
//       </ul>
//     </li>
//   </li>
//   <li>
//     <li className="dropdown"><a className='text-decoration-none text-light' href="#/">Work<button className=""><i className=""></i></button></a>
//       <ul style={{ listStyle: "none" }}>
//         <li><a className='text-decoration-none text-light' href="#/home-v2">Work 02</a></li>
//         <li><a className='text-decoration-none text-light' href="#/home-v3">Work 03</a></li>
//         <li><a className='text-decoration-none text-light' href="#/home-v4">Work 04</a></li>
//       </ul>
//     </li>
//   </li>
//   <li>
//     <li className="dropdown"><a className='text-decoration-none text-light' href="#/">Page<button className=""><i className=""></i></button></a>
//       <ul style={{ listStyle: "none" }}>
//         <li><a className='text-decoration-none text-light' href="#/home-v2">Page 02</a></li>
//         <li><a className='text-decoration-none text-light' href="#/home-v3">Page 03</a></li>
//         <li><a className='text-decoration-none text-light' href="#/home-v4">Page 04</a></li>
//       </ul>
//     </li>
//   </li>
//   <li><a className='text-decoration-none text-light' href='#'>Contact </a></li>
// </ul>
// </div>
