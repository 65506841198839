import React, { useState } from "react";
import "./property.css";
import Apartment from "../../assets/apartments.png";
import Apartments from "../../assets/buildings.png";

import Homestay from "../../assets/homestay.png";
import Hotel from "../../assets/hotel.png";
import VendorHeader from "./Vendorheader";
import { useLocation, useNavigate } from "react-router-dom";

const Bedroom = () => {
  const { state } = useLocation();
  ////console.log(state);
  const [Singlebed, setSinglebed] = useState(0);
  const [Doublebed, setDoublebed] = useState(0);
  const [Largebed, setLargebed] = useState(0);

  const [XLargebed, setXLargebed] = useState(0);
  const increaseRange = () => {
    setSinglebed(Singlebed + 1);
  };
  const decrement = () => {
    setSinglebed(Singlebed - 1);
  };
  const increaseRange1 = () => {
    setDoublebed(Doublebed + 1);
  };
  const decrement1 = () => {
    setDoublebed(Doublebed - 1);

  };
  const increaseRange2 = () => {
    setLargebed(Largebed + 1);
  };
  const decrement2 = () => {
    setLargebed(Largebed - 1);

  };
  const increaseRange3 = () => {
    setXLargebed(XLargebed + 1);
  };
  const decrement3 = () => {
    setXLargebed(XLargebed - 1);

  };
  const history = useNavigate();

  const HandleClick = () => {
    history("/propertysetup", {
      state: {
         Singlebed: Singlebed ,
         Doublebed : Doublebed ,
         Largebed : Largebed ,
         XLargebed : XLargebed,
         Property: state
      },
    });
  };
  return (
    <div
      data-capla-component="b-registration-joinapp15min-fe/"
      data-capla-namespace="b-registration-joinapp15min-feaYGUODfA"
    >
      <div id="app-container">
        <div class="error-popup-container"></div>
        <div class="application">
        <VendorHeader />
          <div class="error-popup-container"></div>

          <div className="page-container">
    <div className="screens screens-animation-direction-forward">
      <div
        className="height-100 screen-change-enter-done"
        id="automation_id_screen_container_Bedroom"
      >
        <div className="height-100 screens-animation-direction-forward">
          <div
            className="ms-container screen-change-enter-done"
            id="automation_id_add_bedroom_page"
          >
            <div className="form-container">
              <div className="f2cf178bcd screen-main-header d991e1216a">
                <div className="f6431b446c e6208ee469 d0caee4251 screen-main-header__title">
                  Bedroom 1
                </div>
              </div>
              <div className="ffb9c3d6a3 wide-container">
                <div className="aca0ade214 ebac6e22e9 f66f916626 c2931f4182 c27e5d305d db150fece4 spacing--6x">
                  <div className="b817090550 b736e9e3f4">
                    <div className="bcdcb105b3">
                      Which beds are available in this room?
                    </div>
                  </div>
                </div>
                <div className="aca0ade214 ebac6e22e9 f66f916626 c2931f4182 c27e5d305d db150fece4 spacing--6x addRoom">
                  <div className="roomDesc b817090550 e0ad3ea0c7">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 96 96">
                      <path d="M78.25 48h-60.5C16.1 48 15 49.1 15 50.77v30.46C15 82.9 16.1 84 17.75 84s2.75-1.1 2.75-2.77V70.15h55v11.08c0 1.66 1.1 2.77 2.75 2.77S81 82.9 81 81.23V50.77C81 49.1 79.9 48 78.25 48zm-22-27.7h-27.5v-5.53c0-1.66-1.1-2.77-2.75-2.77s-2.75 1.1-2.75 2.77v27.7h33V20.3z" />
                      <path d="M72.75 23.08v-8.3c0-1.67-1.1-2.78-2.75-2.78s-2.75 1.1-2.75 2.77v5.54h-27.5v22.16h33V23.08z" />
                    </svg>
                    <div className="f2cf178bcd">
                      <div className="a3332d346a e6208ee469 d0caee4251">
                        Single bed
                      </div>
                      <div className="abf093bdfe f45d8e4c32 df64fda51b">
                        90 - 130 cm wide
                      </div>
                    </div>
                  </div>
                  <div className="b817090550 a7cf1a6b1d">
                    <div
                      id="automation_id_add_bedroom_single_bed_input"
                      data-testid="automation_id_add_bedroom_single_bed_input"
                      className="a7a72174b8"
                    >
                     
                      <div className="bfb38641b0">
                        <button
                          tabIndex={-1}
                          disabled={Singlebed < 1}

                          aria-hidden="true"
                          type="button"
                          className="a83ed08757 c21c56c305 f38b6daa18 d691166b09 ab98298258 deab83296e bb803d8689 e91c91fa93"
                          onClick={decrement}
                        >
                          <span className="eedba9e88a">
                            <span
                              className="fcd9eec8fb bf9a32efa5"
                              aria-hidden="true"
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 24 24"
                              >
                                <path d="M20.25 12.75H3.75a.75.75 0 0 1 0-1.5h16.5a.75.75 0 0 1 0 1.5z" />
                              </svg>
                            </span>
                          </span>
                        </button>
                        <span className="d723d73d5f" aria-hidden="true">
                        {Singlebed}
                        </span>
                        <button
                          tabIndex={-1}
                          
                          aria-hidden="true"
                          type="button"
                          className="a83ed08757 c21c56c305 f38b6daa18 d691166b09 ab98298258 deab83296e bb803d8689 f4d78af12a"
                          onClick={increaseRange}>
                          <span className="eedba9e88a">
                            <span
                              className="fcd9eec8fb bf9a32efa5"
                              aria-hidden="true"
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 24 24"
                              >
                                <path d="M20.25 11.25h-7.5v-7.5a.75.75 0 0 0-1.5 0v7.5h-7.5a.75.75 0 0 0 0 1.5h7.5v7.5a.75.75 0 0 0 1.5 0v-7.5h7.5a.75.75 0 0 0 0-1.5z" />
                              </svg>
                            </span>
                          </span>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="aca0ade214 ebac6e22e9 f66f916626 c2931f4182 c27e5d305d db150fece4 spacing--6x addRoom">
                  <div className="roomDesc b817090550 e0ad3ea0c7">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 96 96">
                      <path d="M89.25 48H6.75C5.1 48 4 49.1 4 50.77v30.46C4 82.9 5.1 84 6.75 84s2.75-1.1 2.75-2.77V70.15h77v11.08c0 1.66 1.1 2.77 2.75 2.77S92 82.9 92 81.23V50.77C92 49.1 90.9 48 89.25 48zm-44-27.7h-27.5v-5.53c0-1.66-1.1-2.77-2.75-2.77s-2.75 1.1-2.75 2.77v27.7h33V20.3zm38.5 2.78v-8.3c0-1.67-1.1-2.78-2.75-2.78s-2.75 1.1-2.75 2.77v5.54h-27.5v22.16h33V23.08z" />
                    </svg>
                    <div className="f2cf178bcd">
                      <div className="a3332d346a e6208ee469 d0caee4251">
                        Double bed
                      </div>
                      <div className="abf093bdfe f45d8e4c32 df64fda51b">
                        131 - 150 cm wide
                      </div>
                    </div>
                  </div>
                  <div className="b817090550 a7cf1a6b1d">
                    <div
                      id="automation_id_add_bedroom_double_bed_input"
                      data-testid="automation_id_add_bedroom_double_bed_input"
                      className="a7a72174b8"
                    >
                     
                      <div className="bfb38641b0">
                        <button
                          tabIndex={-1}
                          disabled={Doublebed < 1}

                          aria-hidden="true"
                          type="button"
                          className="a83ed08757 c21c56c305 f38b6daa18 d691166b09 ab98298258 deab83296e bb803d8689 e91c91fa93"
                       onClick={decrement1}
                       >
                          <span className="eedba9e88a">
                            <span
                              className="fcd9eec8fb bf9a32efa5"
                              aria-hidden="true"
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 24 24"
                              >
                                <path d="M20.25 12.75H3.75a.75.75 0 0 1 0-1.5h16.5a.75.75 0 0 1 0 1.5z" />
                              </svg>
                            </span>
                          </span>
                        </button>
                        <span className="d723d73d5f" aria-hidden="true">
                          {Doublebed}
                        </span>
                        <button
                          tabIndex={-1}
                          aria-hidden="true"
                          type="button"
                          className="a83ed08757 c21c56c305 f38b6daa18 d691166b09 ab98298258 deab83296e bb803d8689 f4d78af12a"
                        onClick={increaseRange1}
                        >
                          <span className="eedba9e88a">
                            <span
                              className="fcd9eec8fb bf9a32efa5"
                              aria-hidden="true"
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 24 24"
                              >
                                <path d="M20.25 11.25h-7.5v-7.5a.75.75 0 0 0-1.5 0v7.5h-7.5a.75.75 0 0 0 0 1.5h7.5v7.5a.75.75 0 0 0 1.5 0v-7.5h7.5a.75.75 0 0 0 0-1.5z" />
                              </svg>
                            </span>
                          </span>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="aca0ade214 ebac6e22e9 f66f916626 c2931f4182 c27e5d305d db150fece4 spacing--6x addRoom">
                  <div className="roomDesc b817090550 e0ad3ea0c7">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 96 96">
                      <path d="M89.25 48H6.75C5.1 48 4 49.1 4 50.77v30.46C4 82.9 5.1 84 6.75 84s2.75-1.1 2.75-2.77V70.15h77v11.08c0 1.66 1.1 2.77 2.75 2.77S92 82.9 92 81.23V50.77C92 49.1 90.9 48 89.25 48zm-44-27.7h-27.5v-5.53c0-1.66-1.1-2.77-2.75-2.77s-2.75 1.1-2.75 2.77v27.7h33V20.3zm38.5 2.78v-8.3c0-1.67-1.1-2.78-2.75-2.78s-2.75 1.1-2.75 2.77v5.54h-27.5v22.16h33V23.08z" />
                    </svg>
                    <div className="f2cf178bcd">
                      <div className="a3332d346a e6208ee469 d0caee4251">
                        Large bed (King size)
                      </div>
                      <div className="abf093bdfe f45d8e4c32 df64fda51b">
                        151 - 180 cm wide
                      </div>
                    </div>
                  </div>
                  <div className="b817090550 a7cf1a6b1d">
                    <div
                      id="automation_id_add_bedroom_large_double_bed_input"
                      data-testid="automation_id_add_bedroom_large_double_bed_input"
                      className="a7a72174b8"
                    >
                      
                      <div className="bfb38641b0">
                        <button
                          tabIndex={-1}
                          disabled={Largebed < 1}

                          aria-hidden="true"
                          type="button"
                          className="a83ed08757 c21c56c305 f38b6daa18 d691166b09 ab98298258 deab83296e bb803d8689 e91c91fa93"
                        onClick={decrement2}
                        >
                          <span className="eedba9e88a">
                            <span
                              className="fcd9eec8fb bf9a32efa5"
                              aria-hidden="true"
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 24 24"
                              >
                                <path d="M20.25 12.75H3.75a.75.75 0 0 1 0-1.5h16.5a.75.75 0 0 1 0 1.5z" />
                              </svg>
                            </span>
                          </span>
                        </button>
                        <span className="d723d73d5f" aria-hidden="true">
                         {Largebed}
                        </span>
                        <button
                          tabIndex={-1}
                          aria-hidden="true"
                          type="button"
                          className="a83ed08757 c21c56c305 f38b6daa18 d691166b09 ab98298258 deab83296e bb803d8689 f4d78af12a"
                        onClick={increaseRange2}
                        >
                          <span className="eedba9e88a">
                            <span
                              className="fcd9eec8fb bf9a32efa5"
                              aria-hidden="true"
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 24 24"
                              >
                                <path d="M20.25 11.25h-7.5v-7.5a.75.75 0 0 0-1.5 0v7.5h-7.5a.75.75 0 0 0 0 1.5h7.5v7.5a.75.75 0 0 0 1.5 0v-7.5h7.5a.75.75 0 0 0 0-1.5z" />
                              </svg>
                            </span>
                          </span>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="aca0ade214 ebac6e22e9 f66f916626 c2931f4182 c27e5d305d db150fece4 spacing--6x addRoom">
                  <div className="roomDesc b817090550 e0ad3ea0c7">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 96 96">
                      <path d="M89.25 48H6.75C5.1 48 4 49.1 4 50.77v30.46C4 82.9 5.1 84 6.75 84s2.75-1.1 2.75-2.77V70.15h77v11.08c0 1.66 1.1 2.77 2.75 2.77S92 82.9 92 81.23V50.77C92 49.1 90.9 48 89.25 48zm-44-27.7h-27.5v-5.53c0-1.66-1.1-2.77-2.75-2.77s-2.75 1.1-2.75 2.77v27.7h33V20.3zm38.5 2.78v-8.3c0-1.67-1.1-2.78-2.75-2.78s-2.75 1.1-2.75 2.77v5.54h-27.5v22.16h33V23.08z" />
                    </svg>
                    <div className="f2cf178bcd">
                      <div className="a3332d346a e6208ee469 d0caee4251">
                        Extra-large double bed (Super-king size)
                      </div>
                      <div className="abf093bdfe f45d8e4c32 df64fda51b">
                        181 - 210 cm wide
                      </div>
                    </div>
                  </div>
                  <div className="b817090550 a7cf1a6b1d">
                    <div
                      id="automation_id_add_bedroom_extra_large_double_bed_input"
                      data-testid="automation_id_add_bedroom_extra_large_double_bed_input"
                      className="a7a72174b8"
                    >
                     
                      <div className="bfb38641b0">
                        <button
                          tabIndex={-1}
                          disabled={XLargebed < 1}

                          aria-hidden="true"
                          type="button"
                          className="a83ed08757 c21c56c305 f38b6daa18 d691166b09 ab98298258 deab83296e bb803d8689 e91c91fa93"
                        onClick={decrement3}
                        >
                          <span className="eedba9e88a">
                            <span
                              className="fcd9eec8fb bf9a32efa5"
                              aria-hidden="true"
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 24 24"
                              >
                                <path d="M20.25 12.75H3.75a.75.75 0 0 1 0-1.5h16.5a.75.75 0 0 1 0 1.5z" />
                              </svg>
                            </span>
                          </span>
                        </button>
                        <span className="d723d73d5f" aria-hidden="true">
                         {XLargebed}
                        </span>
                        <button
                          tabIndex={-1}
                          aria-hidden="true"
                          type="button"
                          className="a83ed08757 c21c56c305 f38b6daa18 d691166b09 ab98298258 deab83296e bb803d8689 f4d78af12a"
                        onClick={increaseRange3}
                        >
                          <span className="eedba9e88a">
                            <span
                              className="fcd9eec8fb bf9a32efa5"
                              aria-hidden="true"
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 24 24"
                              >
                                <path d="M20.25 11.25h-7.5v-7.5a.75.75 0 0 0-1.5 0v7.5h-7.5a.75.75 0 0 0 0 1.5h7.5v7.5a.75.75 0 0 0 1.5 0v-7.5h7.5a.75.75 0 0 0 0-1.5z" />
                              </svg>
                            </span>
                          </span>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                
              </div>
            </div>
            <div className="form-cta">
              <div>
                <div className="form-buttons-container">
                  <div className="form-buttons-container--secondary">
                    <button
                      aria-label="Back to previous step"
                      data-testid="FormButtonSecondary"
                      type="button"
                      className="a83ed08757 c21c56c305 bf0537ecb5 d691166b09 f671049264 d2529514af af7297d90d"
                      onClick={() => window.history.back()}
                    >
                      <span className="eedba9e88a a29f44e7c4">
                        <span
                          className="fcd9eec8fb c2cc050fb8"
                          aria-hidden="true"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            data-rtl-flip="true"
                          >
                            <path d="M15.0871 19.2361C14.9677 19.2367 14.8495 19.2134 14.7392 19.1676C14.629 19.1219 14.5289 19.0546 14.4451 18.9696L8.38799 12.9125C8.26332 12.7891 8.16475 12.6418 8.09815 12.4795C8.03156 12.3172 7.99829 12.1431 8.00034 11.9676C7.99246 11.6171 8.12303 11.2776 8.36376 11.0227L14.4208 4.96566C14.5912 4.79552 14.8221 4.69995 15.0629 4.69995C15.3037 4.69995 15.5346 4.79552 15.7049 4.96566C15.7907 5.04918 15.8588 5.14902 15.9053 5.25929C15.9518 5.36957 15.9758 5.48803 15.9758 5.60771C15.9758 5.72739 15.9518 5.84586 15.9053 5.95613C15.8588 6.0664 15.7907 6.16624 15.7049 6.24976L10.0113 11.9676L15.7292 17.6855C15.8149 17.769 15.883 17.8689 15.9295 17.9792C15.976 18.0894 16 18.2079 16 18.3276C16 18.4473 15.976 18.5657 15.9295 18.676C15.883 18.7863 15.8149 18.8861 15.7292 18.9696C15.6457 19.0551 15.5458 19.1228 15.4354 19.1686C15.3251 19.2144 15.2066 19.2374 15.0871 19.2361Z" />
                          </svg>
                        </span>
                      </span>
                      <span className="e4adce92df">Cancel</span>
                    </button>
                  </div>
                  <div className="form-buttons-container--primary" onClick={HandleClick}>
                    <button
                      aria-label="Continue"
                      data-testid="FormButtonPrimary-enabled"
                      type="button"
                      className="a83ed08757 c21c56c305 a4c1805887 f671049264 d2529514af c082d89982"
                    >
                      <span className="e4adce92df">Save</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div id="automation_id_screen_id_bedroom" style={{ display: "none" }} />
  </div>

          <div></div>
        </div>
      </div>
      <img
        alt=""
        height="1"
        width="1"
        style={{ display: "none" }}
        src="https://booking.com/track_join_app_landing?progress_status=2"
      />
    </div>
  );
};

export default Bedroom;
