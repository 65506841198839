import React from "react";
import "./property.css";
import Apartment from "../../assets/apartments.png";
import Apartments from "../../assets/buildings.png";

import Homestay from "../../assets/homestay.png";
import Hotel from "../../assets/hotel.png";
import VendorHeader from "./Vendorheader";

const Weeklyrate = () => {
  const handleKeyDown = (e) => {
    // Prevent the input of the minus sign
    if (e.key === '-' || e.key === 'e' || e.key === 'E') {
      e.preventDefault();
    }
  };
  return (
    <div
      data-capla-component="b-registration-joinapp15min-fe/"
      data-capla-namespace="b-registration-joinapp15min-feaYGUODfA"
    >
      <div id="app-container">
        <div class="error-popup-container"></div>
        <div class="application">
        <VendorHeader />
          <div class="error-popup-container"></div>

          <div className="page-container">
            <div className="screens screens-animation-direction-forward">
              <div
                className="height-100"
                id="automation_id_screen_container_WeeklyRate"
              >
                <div className="ms-container">
                  <div className="form-container form-container--map">
                    <div className="f2cf178bcd screen-main-header d991e1216a">
                      <div className="f6431b446c e6208ee469 d0caee4251 screen-main-header__title">
                        Set up a weekly rate
                      </div>
                    </div>
                    <div className="ffb9c3d6a31 wide-container min-height">
                      <div className="aca0ade214 ebac6e22e9 f66f916626 c2931f4182 c27e5d305d db150fece4">
                        <div className="b817090550 b736e9e3f4">
                          <div className="a53cbfa6de spacing--4x">
                            In addition to the standard rate plan you've created
                            for your property, you can add a weekly rate plan.
                          </div>
                          <div className="a53cbfa6de spacing--8x">
                            With this, you set a discounted price and use the
                            same cancellation policy as the standard rate plan.
                            Guests who stay for at least a week are interested
                            in discounts since they’ll be spending more on their
                            overall booking.
                          </div>
                          <div
                            data-testid="WeeklyRates-input-switch"
                            className="a53cbfa6de d87fd4472a spacing--8x"
                          >
                            <input
                              type="checkbox"
                              name="switch"
                              id=":r8u:"
                              className="f43c0d9153"
                              aria-checked="true"
                              role="switch"
                              defaultChecked
                            />
                            <label
                              htmlFor=":r8u:"
                              className="dbe832aef0"
                              aria-live="polite"
                            >
                              <span className="ade77cee67" />
                              <span className="c640a7fa88">
                                Set up a weekly rate plan
                              </span>
                              <span className="f9bbc6d198" />
                            </label>
                          </div>
                          <hr className="b9bfeba2b4 b288f61df6 spacing--8x" />
                          <div
                            data-testid="WeeklyRates-input-discount"
                            className="a53cbfa6de ac9267e216 spacing--6x non-refund-rate-discount a20293ec70"
                          >
                            <label
                              htmlFor=":r8v:"
                              className="a53cbfa6de e6208ee469 f555271986"
                            >
                              <span>
                                <span className="ccb65902b2">
                                  How much cheaper than the standard rate would
                                  you like to make this rate plan?
                                </span>
                              </span>
                            </label>
                            <div className="b9b84f4305">
                              <div className="e000754250">
                                <input
                                  type="number" onKeyDown={handleKeyDown}
                                  name="discount"
                                  className="eb46370fe1"
                                  
                                  defaultValue={15}
                                />
                                <div className="e7e5251f68" />
                              </div>
                              <div className="b93ef00bf5">%</div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="page-tooltip__wrap">
                        <section
                          aria-labelledby=":r90:"
                          className="c82435a4b8 a178069f51 a6ae3c2b40 a18aeea94d d794b7a0f7 f53e278e95"
                          style={{ padding: 4 }}
                        >
                          <div className="aca0ade214 ebac6e22e9 c2931f4182 d79e71457a">
                            <span className="d963552ea0">
                              <span
                                className="fcd9eec8fb c2cc050fb8"
                                aria-hidden="true"
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 24 24"
                                >
                                  <path d="M12.75 2.251v-1.5a.75.75 0 0 0-1.5 0v1.5a.75.75 0 0 0 1.5 0zm6.144 3.167l1.061-1.06a.75.75 0 0 0-1.06-1.061l-1.061 1.06a.75.75 0 0 0 1.06 1.061zM21 12.001h1.5a.75.75 0 0 0 0-1.5H21a.75.75 0 0 0 0 1.5zm-3.166 6.144l1.06 1.061a.75.75 0 1 0 1.061-1.06l-1.06-1.061a.75.75 0 1 0-1.061 1.06zM6.166 4.358l-1.06-1.061a.75.75 0 0 0-1.061 1.06l1.06 1.061a.75.75 0 0 0 1.061-1.06zM3 10.5H1.5a.75.75 0 0 0 0 1.5H3a.75.75 0 0 0 0-1.5zm2.106 6.584l-1.061 1.06a.75.75 0 1 0 1.06 1.061l1.061-1.06a.75.75 0 1 0-1.06-1.061zm3.144-.636v2.3a3.75 3.75 0 1 0 7.5 0v-2.3a.75.75 0 0 0-1.5 0v2.3a2.25 2.25 0 0 1-4.5 0v-2.3a.75.75 0 0 0-1.5 0zm9-5.198a5.25 5.25 0 1 1-10.5 0 5.25 5.25 0 0 1 10.5 0zm1.5 0a6.75 6.75 0 1 0-13.5 0 6.75 6.75 0 0 0 13.5 0zm-7.5 10.5v1.5a.75.75 0 0 0 1.5 0v-1.5a.75.75 0 0 0-1.5 0z" />
                                </svg>
                              </span>
                            </span>
                            <div className="aaee4e7cd3 e7a57abb1e">
                              <div className="aca0ade214 aaf30230d9 cd2e7d62b0">
                                <div className="ffd93a9ecb">
                                  <h3
                                    id=":r90:"
                                    className="e1eebb6a1e e0a7dcceb5"
                                  >
                                    Why should I add a weekly rate plan?
                                  </h3>
                                  {/* <div className="abcc616ec7 cc1b961f14 c180176d40 f11eccb5e8">
                                    <button
                                      aria-label="Close banner"
                                      type="button"
                                      className="a83ed08757 c21c56c305 f38b6daa18 d691166b09 ab98298258 deab83296e f4552b6561"
                                    >
                                      <span className="eedba9e88a">
                                        <span
                                          className="fcd9eec8fb bf9a32efa5"
                                          aria-hidden="true"
                                        >
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 24 24"
                                          >
                                            <path d="M13.31 12l6.89-6.89a.93.93 0 1 0-1.31-1.31L12 10.69 5.11 3.8A.93.93 0 0 0 3.8 5.11L10.69 12 3.8 18.89a.93.93 0 0 0 1.31 1.31L12 13.31l6.89 6.89a.93.93 0 1 0 1.31-1.31z" />
                                          </svg>
                                        </span>
                                      </span>
                                    </button>
                                  </div> */}
                                </div>
                                <div className="a53cbfa6de">
                                  <div className="spacing--4x">
                                    A weekly rate plan can help you stand out to
                                    a range of guests, from business people to
                                    families, looking for competitive prices.
                                  </div>
                                  <div className="spacing--4x">
                                    Properties with weekly rates receive on
                                    average:
                                  </div>
                                  <ul>
                                    <li>5% more views </li>
                                    <li>15% more bookings</li>
                                    <li>
                                      37% more guests booking for a week or
                                      longer
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </div>
                        </section>
                      </div>
                    </div>
                  </div>
                  <div className="form-cta">
                    <div>
                      <div className="form-buttons-container">
                        <div className="form-buttons-container--secondary" onClick={()=>window.history.back()}>
                           <button
                            aria-label="Back to previous step"
                            data-testid="FormButtonSecondary"
                            type="button"
                            className="a83ed08757 c21c56c305 bf0537ecb5 d691166b09 f671049264 d2529514af af7297d90d"
                          >
                            <span className="eedba9e88a">
                              <span
                                className="fcd9eec8fb c2cc050fb8"
                                aria-hidden="true"
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 24 24"
                                  data-rtl-flip="true"
                                >
                                  <path d="M15.0871 19.2361C14.9677 19.2367 14.8495 19.2134 14.7392 19.1676C14.629 19.1219 14.5289 19.0546 14.4451 18.9696L8.38799 12.9125C8.26332 12.7891 8.16475 12.6418 8.09815 12.4795C8.03156 12.3172 7.99829 12.1431 8.00034 11.9676C7.99246 11.6171 8.12303 11.2776 8.36376 11.0227L14.4208 4.96566C14.5912 4.79552 14.8221 4.69995 15.0629 4.69995C15.3037 4.69995 15.5346 4.79552 15.7049 4.96566C15.7907 5.04918 15.8588 5.14902 15.9053 5.25929C15.9518 5.36957 15.9758 5.48803 15.9758 5.60771C15.9758 5.72739 15.9518 5.84586 15.9053 5.95613C15.8588 6.0664 15.7907 6.16624 15.7049 6.24976L10.0113 11.9676L15.7292 17.6855C15.8149 17.769 15.883 17.8689 15.9295 17.9792C15.976 18.0894 16 18.2079 16 18.3276C16 18.4473 15.976 18.5657 15.9295 18.676C15.883 18.7863 15.8149 18.8861 15.7292 18.9696C15.6457 19.0551 15.5458 19.1228 15.4354 19.1686C15.3251 19.2144 15.2066 19.2374 15.0871 19.2361Z" />
                                </svg>
                              </span>
                            </span>
                          </button>
                        </div>
                        <div className="form-buttons-container--primary" onClick={()=>window.location.assign("/propertyrate")}>
                          <button
                            aria-label="Continue"
                            data-testid="FormButtonPrimary-enabled"
                            type="button"
                            className="a83ed08757 c21c56c305 a4c1805887 f671049264 d2529514af c082d89982"
                          >
                            <span className="e4adce92df">Continue</span>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Weeklyrate;
