import React, { useEffect, useState } from "react";
import { Button, Table ,DatePicker} from "antd";
import Table1 from "react-bootstrap/Table";
import {  Input, Space } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { BiEdit } from "react-icons/bi";
import { AiFillDelete } from "react-icons/ai";
import { FcApproval } from "react-icons/fc";
import { MdDelete } from "react-icons/md";
import { BiSolidEdit } from "react-icons/bi";
import { Container, Form, Modal } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCancel } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import axios from "axios";
import parse from "html-react-parser"

import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepButton from "@mui/material/StepButton";

import Typography from "@mui/material/Typography";
import { City, Country, State } from "country-state-city";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

import { CKEditor } from "@ckeditor/ckeditor5-react";
import CustomInput from "../components/CustomInput";
import * as XLSX from "xlsx";
import moment from "moment/moment";
const BookInternationalPackages = (props) => {
  const [data, setdata] = useState([]);
  
  const getinternational = async () => {
    let res = await axios.get(
      "https://bookingoda.com/api/admin/getbookinternationalpackages"
    );
    if (res.status === 200) {
      //////console.log(res.data);
      setdata(res.data.bookedtourpackages);
      ////console.log(res.data.bookedtourpackages,"dasfasd");
    }
  };
  useEffect(() => {
    getinternational();
  }, []);
  const PrintInvoice = (Item) => {
    sessionStorage.setItem("InvoiceItem", JSON.stringify(Item));
   
    window.location.assign("/internationalinvoice");
  };
  const Approve = async (id) => {
    try {
      const config = {
        url: "/admin/Approveinternational",
        method: "post",
        baseURL: "https://bookingoda.com/api",
        hearder: { "content-type": "application/json" },

        data: {
          internationalid: id,
          status: "Approved",
        },
      };
      await axios(config).then(function (res) {
        if (res.status === 200) {
          ////console.log(res.data);
          alert("Approved");
          window.location.reload();
        } else {
          alert("Something Wrong");
        }
      });
    } catch (error) {
      ////console.log(error.response);
    }
  };

  ////console.log(data);
  const makeConfirm = async (id) => {
    try {
      const config = {
        url: "/makeInternationalStatusChange",
        method: "post",
        baseURL: "https://bookingoda.com/api/admin",
        headers: { "content-type": "application/json" },
        data: {
          bookedId: id,
          status: "Confirmed",
        },
      };
      let res = await axios(config);
      if (res.status === 200) {
        getinternational();
      } else {
        alert("Something Wrong");
      }
    } catch (error) {
      ////console.log(error);
    }
  };

  const makeCancel = async (id) => {
    try {
      const config = {
        url: "/makeInternationalStatusChange",
        method: "post",
        baseURL: "https://bookingoda.com/api/admin",
        headers: { "content-type": "application/json" },
        data: {
          bookedId: id,
          status: "Cancelled",
        },
      };
      let res = await axios(config);
      if (res.status === 200) {
        getinternational();
      } else {
        alert("Something Wrong");
      }
    } catch (error) {
      ////console.log(error);
    }
  };
  const makeComplete = async (id) => {
    try {
      const config = {
        url: "/makeInternationalStatusChange",
        method: "post",
        baseURL: "https://bookingoda.com/api/admin",
        headers: { "content-type": "application/json" },
        data: {
          bookedId: id,
          status: "Completed",
        },
      };
      let res = await axios(config);
      if (res.status === 200) {
        getinternational();
      } else {
        alert("Something Wrong");
      }
    } catch (error) {
      ////console.log(error);
    }
  };
  const [data1, setData1] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  
  useEffect(() => {
    // Fetch data asynchronously (replace fetchDataCar with your actual data fetching logic)
    getinternational().then((result) => {
      setdata(result);
    });
  }, []);

  useEffect(() => {
    // Ensure datacar is not undefined before transforming it
    if (data && data.length > 0) {
      const transformedData = transformData(data);
      setData1(transformedData);
      setFilteredData(transformedData);
    }
  }, [data]);

  // Assuming your transformation logic
  const transformData = (data) => {
    return data.map((item,i) => ({
       id:i+1,
       key: "BOI" + item._id.slice(22, 24).toUpperCase(),
      UserID: "BOCUS"+item.userId?._id.slice(22,24).toUpperCase(),
      VendorId: "BOVEN"+item.packageId?.UserId.slice(22,24).toUpperCase(),
Photos:item.Doc,
      Name: item.name,
      Email: item.email,
      Mobile: item.mobile,
      packageId: "BOINT"+item.packageId?._id.slice(22,24).toUpperCase(),
      packageName: item.packageId?.DestinationName,
      noofpeople: item.noofpeople,
      address: item.address?.join(", "),
      payment:item.payment,
      bookdate: moment(item.createdAt).format("DD/MM/YYYY"),
      arrivaldate1: item.createdAt,
      arrivaldate: `${new Date(item.arrivaldate).getDate()}/${
        new Date(item.arrivaldate).getMonth() + 1
      }/${new Date(item.arrivaldate).getFullYear()}`,
      // departuredate: `${new Date(item.departuredate).getDate()}/${
      //   new Date(item.departuredate).getMonth() + 1
      // }/${new Date(item.departuredate).getFullYear()}`,
     
     
      status: (item.status === "Confirmed" || item?.status === "Completed" ? <span style={{color:"green",fontWeight:"900"}}>{item.status}</span> :<span style={{color:"red",fontWeight:"900"}}>{item.status}</span>),

    action: (
      <>
        {item?.status === "Completed" || item?.status === "Cancelled" ? (
          <></>
        ) : (
          <>
            {item?.status === "Confirmed" ? (
              <>
                <Button
                  variant="success"
                  onClick={() => {
                    makeComplete(item?._id);
                  }}
                  style={{ backgroundColor: "green", color: "white" }}
                >
                  Complete
                </Button>
              </>
            ) : (
              <>
                <Button
                  variant="warning"
                  onClick={() => {
                    makeConfirm(item?._id);
                  }}
                  style={{
                    backgroundColor: "green",
                    marginRight: "20px",
                    marginBottom:"10px",
                    color: "white",
                  }}
                >
                  Confirm
                </Button>
                <Button
                  variant="danger"
                  onClick={() => {
                    makeCancel(item?._id);
                  }}
                  style={{ backgroundColor: "red", color: "white" }}
                >
                  Cancel
                </Button>
              </>
            )}
          </>
        )}
      </>
    ),
      action1: (
        <>
          <div className="d-flex gap-4 fs-8" style={{ cursor: "pointer"}}>
            {/* Your additional action logic goes here */}
          </div>
        </>
      ),
      action3: (
        <>
          <div className="d-flex gap-2 fs-4" style={{ cursor: 'pointer' }}>
          <Button
                    variant="warning"
                    onClick={()=> {PrintInvoice(item)}}
                    style={{backgroundColor:'#1677ff',marginRight:"20px",color:"white"}}
                  >
                    Invoice
                  </Button>
         
          </div>
        </>
      )
    }));
  };
  

  // const data1 = [];
  // for (let i = 0; i < data.length; i++) {
  //   data1.push({
  //     key: data[i]._id,
  //     UserID: data[i].userId?._id,
  //     Name: data[i].name,
  //     Email: data[i].email,
  //     Mobile: data[i].mobile,

  //     noofpeople:data[i].noofpeople,
  //     arrivaldate:data[i].arrivaldate,
  //     departuredate:data[i].departuredate,
  //     packageId:data[i].packageId?._id,
  //     packageName:data[i].packageId?.DestinationName,

  //     action: (
  //       <>
  //         {/* <Link to="/" className=" fs-3 text-danger">
  //             <BiEdit />
  //           </Link> */}
  //         {data[i].status === "Pending" ? <Button className="ms-3 fs-8 text-success" style={{ cursor: 'pointer' }} onClick={() => Approve(data[i]._id)}>
  //           Approve
  //         </Button> : <div className="ms-3 fs-8 text-success" >
  //           <FcApproval /> Approved
  //         </div>}
  //       </>
  //     ),
  //     action1: (
  //       <>
  //         <div className="d-flex gap-4 fs-8" style={{ cursor: "pointer"}}>
  //           {/* <BiSolidEdit className="text-primary" onClick={()=>handleShow1(data[i])} />

  //           <MdDelete
  //             className="text-danger"
  //             onClick={() => handleShow3(data[i]?._id)}
  //           /> */}
  //         </div>
  //       </>
  //     ),
  //   });
  // }
 
  const columns = [
    {
      title: "Serial No.",
      dataIndex: "id",

      // //fixed: "left",
      width: 150,
    },
    {
      title: "Booked ID",
      dataIndex: "key",
      
       // //fixed: "left",
      width:150,
    },
    {
      title: "User ID",
      dataIndex: "UserID",
   
     
     
      width: 150,
    },
    {
      title: "Vendor ID",
      dataIndex: "VendorId",
     

      width: 150,
    },
    {
      title: "PackageID",
      dataIndex: "packageId",
   

      width: 150,
    },
    {
      title: "Package Name",
      dataIndex: "packageName",
   

      width: 150,
    },
    {
      title: "Name",
      dataIndex: "Name",
  

      
     
      width: 200,
    },
    {
      title: "Address",
      dataIndex: "address",
 
      width: 150,
    },
    {
      title: "Email",
      dataIndex: "Email",
      width: 200,
    },
    {
      title: "Mobile",
      dataIndex: "Mobile",
      width: 150,
    
    },
    {
      title: "Booked Date",
      dataIndex: "bookdate",
      width: 150,
    },
    {
      title: "Check-In Date",
      dataIndex: "arrivaldate",
      width: 150,
    },
    // {
    //   title: "Check-Out Date",
    //   dataIndex: "departuredate",
    //   width: 150,
    // },
    {
      title: "Number of people",
      dataIndex: "noofpeople",
      width: 150,
    },
    {
      title: "Payment",
      dataIndex: "payment",
 
      width: 150,
    },
    {
      title: "Documents",
      dataIndex: "Photos",
      width: 550,

      render: (cell, row) => {
        // console.log(row);
        return (
          <div
            style={{
              overflowY: "scroll",
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap",
              // justifyContent: "space-between",
            }}
          >
            {row.Photos?.map((item) => (
               <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around' }}>
                <a href={"https://bookingoda.com/image/" + item.file}  target="_blank">
              <img
                src={"https://bookingoda.com/image/" + item.file}
                alt="image"
                style={{ width: "100px", height: "100px" }}
              />
              </a>  

              </div>
            ))}
          </div>
        );
      },
    },
    {
      title: "Status",
      dataIndex: "status",

      width: 150,
    },
   
    {
      title: "Action",
      dataIndex: "action",
      key: "operation",
      // //fixed: "right",

      // render: () => <Link className="ms-3 fs-8 text-success">Approve</Link>,
      width: 150,
    },
    {
      title: "Invoice",
      dataIndex: "action3",
      key: "operation",
      // //fixed: "right",
  
      // render: () => <Link className="ms-3 fs-8 text-success">Approve</Link>,
      width: 150,
    },
    
  ];
 
  const ExportToExcelButton = ({ dataSource, columns, filename }) => {
    const exportToExcel = () => {
      const dataToExport = dataSource.map(({arrivaldate1, ...rest }) => rest);
      const worksheet = XLSX.utils.json_to_sheet(dataToExport);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet 1");
      XLSX.writeFile(workbook, `${filename}.xlsx`);
    };

    return (
      <Button
        type="primary"
        onClick={exportToExcel}
        style={{
          margin: "10px",
          alignSelf: "flex-end",
          backgroundColor: "#1677ff",
          color: "white",
        }}
      >
        Export to Excel
      </Button>
    );
  };

  const [searchTerm, setSearchTerm] = useState("");
  // const [filteredData, setFilteredData] = useState(data1);

  const handles = (value) => {
    setSearchTerm(value);

    // Filter the data based on the search term
    const filteredDataSource = data1.filter((record) => {
      return Object.values(record).some(
        (val) =>
          val && val.toString().toLowerCase().includes(value.toLowerCase())
      );
    });

    setFilteredData(filteredDataSource);
  };

  const onClearSearch = () => {
    setSearchTerm("");
    setFilteredData(data1);
  };

  const searchInput = (
    <Input
      placeholder="Search"
      value={searchTerm}
      style={{width:"50%"}}
      onChange={(e) => handles(e.target.value)}
      suffix={
        searchTerm && (
          <span
            onClick={onClearSearch}
            style={{ cursor: "pointer", color: "grey" }}
          >
            Clear
          </span>
        )
      }
      prefix={<SearchOutlined />}
    />
  );

  const pageSize = 5;
 
  const [dateRange, setDateRange] = useState([null, null]);
  
  const handleDateRangeChange = (dates) => {
    ////console.log('Selected Dates:', dates);
  
    // Filter data based on the selected date range
    const filteredDataSource = data1.filter((record) => {
      const startDate = dates && dates[0] ? new Date(dates[0].startOf('day')) : null;
      const endDate = dates && dates[1] ? new Date(dates[1].endOf('day')) : null;
      const recordDate = moment(record.arrivaldate1, 'YYYY-MM-DD').toDate();
  
      const result =
        (!startDate || recordDate >= startDate) &&
        (!endDate || recordDate <= endDate);
  
      return result;
    });
  
    setFilteredData(filteredDataSource);
    setDateRange(dates);
  };
  
  return (
    <div>
      <h3 className="mb-4 title">Booked International Holiday List</h3>
      <div style={{display:"flex",flexDirection:"row",justifyContent:'space-between',margin:10}}>
      {searchInput}
      <DatePicker.RangePicker
        value={dateRange}
        onChange={handleDateRangeChange}
        
      />
       <ExportToExcelButton
        dataSource={filteredData}
        columns={columns}
        filename="InternationalOrderList"
      /></div>
      <div>
        <Table
          columns={columns} className="custom-table"
          dataSource={filteredData}
          scroll={{ x: "calc(700px + 50%)", y: 240 }}
          pagination={{
            pageSize: pageSize,
            showSizeChanger: false, // Optional: Hide the "Show X entries" dropdown
          }}
          components={{
            header: {
            
              cell: props => (
                <th {...props} style={{ ...props.style, background:'linear-gradient(#7A5E05,#705B15,#4B4019)'  ,color:"white"}} />
              ),
            },
          }}
        />
      </div>
    
    
    </div>
  );
};

export default BookInternationalPackages;
