import { FaAngleRight, FaUmbrellaBeach, FaUser } from "react-icons/fa";
import "./navbar.css";
import { Link, useNavigate } from "react-router-dom";
import Logo from "../../assets/booking.jpeg";
import { IoMdLogOut } from "react-icons/io";
import { MdLibraryAdd } from "react-icons/md";
import { IoListCircleSharp } from "react-icons/io5";
import { FaBuildingUser } from "react-icons/fa6";
import { useEffect, useState } from "react";
import { IoPeopleCircle } from "react-icons/io5";
import { BiSolidOffer } from "react-icons/bi";
import {
  faBed,
  faCalendarDays,
  faCar,
  faPerson,
  faPlane,
  faTaxi,
  faHouse,
  faHotel,
  faEarthAmericas,
  faGroupArrowsRotate
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
const Navbar = () => {
  const user = JSON.parse(sessionStorage.getItem("user"));
  const [isNavbarActive, setNavbarActive] = useState(false);
  const [isNavbarActive1, setIsNavbarActive1] = useState(null);


  // Function to handle scroll events
  const handleScroll = () => {
    // Add the 'active' class to the navbar when scrolling down
    if (window.scrollY > 0) {
      setNavbarActive(true);
    } else {
      setNavbarActive(false);
    }
  };
  const handleShow1 = () => navigate("/rsa");
  // Effect to add event listener when component mounts
  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    // Cleanup: remove event listener when component unmounts
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  let timeoutId;

  const handleItemClick = (item) => {
    setIsNavbarActive1(item);
  };

  const handleClick = (item) => {
    handleItemClick(item);
  };

  const handleDoubleClick = (item) => {
    clearTimeout(timeoutId);
    // Handle the double-click action here
    //console.log(`Double click on item ${item}`);
  };

  const handleMouseDown = (item) => {
    timeoutId = setTimeout(() => {
      handleClick(item);
    }, 300); // Adjust the timeout as needed
  };

  const handleSearch = () => {
   
    handleMouseDown("Stays");
    navigate("/bookingoda");
    
  };
  const navigate = useNavigate();

  const handleSearch1 = () => {
   
    handleMouseDown("Car rentals");

    navigate("/carrentals");
  

  };
 
  const handleSearch2 = () => {
    
    handleMouseDown("Pilgrim Vacations");
 
    navigate("/Attraction");
   

  };
  const handleSearch5 = () => {
   
    handleMouseDown("International Holiday Locations");
  
 navigate("/HomeInternational");
  };
  const handleSearch6 = () => {
   
    handleMouseDown("Vacation Homes");
  
 navigate("/Vacationhome");
  };
  const handleSearch7 = () => {
   
    handleMouseDown("Exclusive Packages");
  
 navigate("/ExclusivepackageHome");
  };
  const handleSearch8 = () => {
   
    handleMouseDown("Offers");
  
 navigate("/Offers");
  };
  const handleSearch9 = async () => {
    if (!user) {
      return alert("Please login");
    } else {
window.location.assign("/community-home")
    }
  }
  return (
  
    <nav className= 'navbar12 active fixed-top navbar-expand-lg12 navbar-light12 bg-light12'>
      {/* <nav className={` ${isNavbarActive ? 'navbar12 active fixed-top navbar-expand-lg12 navbar-light12 bg-light12' : 'navbar12 fixed-top navbar-expand-lg12 navbar-light12 bg-light12'}`}> */}
    <div class="Toastify"></div>
    <div class="container12">
      <a class="navbar-brand12" href="/">
      <img className="logo" src={Logo} alt="" style={{height:"80px",width:"80px",marginLeft:"30px"}}/>
      </a>
      <div className="headerList">
          <div className={`${isNavbarActive1 === "Stays" ? 'headerListItem active' : 'headerListItem'}`} style={{ color: "white", cursor: 'pointer' }} onClick={handleSearch} onDoubleClick={() => handleDoubleClick("Stays")}>
            <FontAwesomeIcon icon={faHouse} />
            <span style={{marginTop:"5px"}}>Stays</span>
          </div>

          <div className={`${isNavbarActive1 === "Car rentals" ? 'headerListItem active' : 'headerListItem'}`} style={{ color: "white", cursor: 'pointer', cursor: 'pointer' }} onClick={handleSearch1} onDoubleClick={() => handleDoubleClick("Car rentals")}>
            <FontAwesomeIcon icon={faCar} />
            <span style={{marginTop:"5px"}}>Car rentals</span>
          </div>

          <div className={`${isNavbarActive1 === "Pilgrim Vacations" ? 'headerListItem active' : 'headerListItem'}`} style={{ color: "white", cursor: 'pointer' }} onClick={handleSearch2} onDoubleClick={() => handleDoubleClick("Pilgrim Vacations")}>
          <FontAwesomeIcon icon={faGroupArrowsRotate} />
            <span style={{marginTop:"5px"}}>Pilgrim Vacations</span>
          </div>

          <div className={`${isNavbarActive1 === "International Holiday Locations" ? 'headerListItem active' : 'headerListItem'}`} style={{ color: "white", cursor: 'pointer' }} onClick={handleSearch5} onDoubleClick={() => handleDoubleClick("International Holiday Locations")}>
            <FontAwesomeIcon icon={faEarthAmericas} />
            <span style={{marginTop:"5px"}}>International Holiday Locations</span>
          </div>
          <div className={`${isNavbarActive1 === "Vacation Homes" ? 'headerListItem active' : 'headerListItem'}`} style={{ color: "white", cursor: 'pointer' }} onClick={handleSearch6} onDoubleClick={() => handleDoubleClick("Vacation Homes")}>
          <FaUmbrellaBeach  />
            <span style={{marginTop:"5px"}}>Vacation Homes</span>
          </div>
          {/* <div className={`${isNavbarActive1 === "Exclusive Package" ? 'headerListItem active' : 'headerListItem'}`} style={{ color: "white", cursor: 'pointer' }} onClick={handleSearch7} onDoubleClick={() => handleDoubleClick("Exclusive Package")}>
          <IoPeopleCircle    />
            <span style={{marginTop:"5px"}}>Exclusive Package</span>
          </div> */}
          <div className={`${isNavbarActive1 === "Offers" ? 'headerListItem active' : 'headerListItem'}`} style={{ color: "white", cursor: 'pointer' }} onClick={handleSearch8} onDoubleClick={() => handleDoubleClick("Offers")}>
          <BiSolidOffer     />
            <span style={{marginTop:"5px"}}>Offers</span>
          </div>
          <div className={`${isNavbarActive1 === "Community" ? 'headerListItem active' : 'headerListItem'}`} style={{ color: "white", cursor: 'pointer' }} onClick={handleSearch9} onDoubleClick={() => handleDoubleClick("Community")}>
          <IoPeopleCircle    />
            <span style={{marginTop:"5px"}}>Community</span>
          </div>
        </div>
      <button
        class="navbar-toggler12 d-none"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#navbarTogglerDemo01"
        aria-controls="navbarTogglerDemo01"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-icon12"></span>
      </button>
      <div
        class="collapse navbar-collapse"
        id="navbarTogglerDemo01"
      > </div>
      <ul class="navbar-nav12 ms-auto mb-2 mb-lg-0">
        
      <a class="nav-item12 me-3" href="/createaccount" >
          <a class="nav-link12 btn px-2 btn-md login-btn12" href="/createProperty" style={{marginBottom:"5px"}}>
            List Your Property <IoListCircleSharp   />
          </a>
        </a>
        {user ? <a class="nav-item12 me-3" href="/profile">
          <a class="nav-link12 btn px-2 btn-md login-btn12" href="/profile">
          {user.name} <FaBuildingUser   /> 
          </a>
        </a>: ""}
        {!user ? <><a class="nav-item12 me-3" href="/register">
          <a class="nav-link12 btn px-2 btn-md login-btn12" href="/register">
            Register <MdLibraryAdd  />
          </a>
        </a>
        <a class="nav-item12 me-3" href="/login">
          <a class="nav-link12 btn px-2 btn-md login-btn12" href="/login">
            Login <IoMdLogOut />
          </a>
        </a></> : ""}
        <div style={{marginTop:"10px",textAlign:"right" }}>
                    <button
                      className="text-light fw-bold renew-btn fontColor"
                      onClick={handleShow1}
                      style={{backgroundColor:"#FCCB43",color:'black'}}
                    >
                      <span style={{color:'black',fontWeight:'400',padding:'5px'}}>Road Side Assisstance</span>
                      <FaAngleRight style={{display:'inline-block',color:'black'}}/>
                    </button>
                  </div>
      
      </ul>
      
    </div>
    
  </nav>
  );
};

export default Navbar;
