import { CKEditor } from "@ckeditor/ckeditor5-react";
import React, { useEffect, useState } from "react";
import { Container, Form, Modal } from "react-bootstrap";
import { Button, Table, DatePicker } from "antd";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepButton from "@mui/material/StepButton";
import { City, Country, State } from "country-state-city";
import { Input, Space } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import Table1 from "react-bootstrap/Table";
import Typography from "@mui/material/Typography";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";

import { BiSolidEdit } from "react-icons/bi";
import { MdDelete } from "react-icons/md";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { BsSearch } from "react-icons/bs";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCancel } from "@fortawesome/free-solid-svg-icons";
import moment from "moment/moment";
import CustomInput from "../components/CustomInput";
import { AiFillDelete } from "react-icons/ai";
import * as XLSX from "xlsx";

function Bookedcar() {
  const [datacar, setdatacar] = useState([]);
  const getcar = async () => {
    try {
      const res = await axios.get(
        "https://bookingoda.com/api/admin/getbookedcars"
      );
      if (res.status === 200) {
        setdatacar(res.data.bookedcars);
        ////console.log(res.data.bookedcars);
      }
    } catch (error) {
      ////console.log(error);
    }
  };

  useEffect(() => {
    getcar();
  }, []);
  const [data1, setData1] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const makeConfirm = async (id) => {
    try {
      const config = {
        url: "/makeCarStatusChange",
        method: "post",
        baseURL: "https://bookingoda.com/api/admin",
        headers: { "content-type": "application/json" },
        data: {
          bookedId: id,
          status: "Confirmed",
        },
      };
      let res = await axios(config);
      if (res.status === 200) {
        getcar();
      } else {
        alert("Something Wrong");
      }
    } catch (error) {
      ////console.log(error);
    }
  };

  const makeCancel = async (id) => {
    try {
      const config = {
        url: "/makeCarStatusChange",
        method: "post",
        baseURL: "https://bookingoda.com/api/admin",
        headers: { "content-type": "application/json" },
        data: {
          bookedId: id,
          status: "Cancelled",
        },
      };
      let res = await axios(config);
      if (res.status === 200) {
        getcar();
      } else {
        alert("Something Wrong");
      }
    } catch (error) {
      ////console.log(error);
    }
  };
  const makeComplete = async (id) => {
    try {
      const config = {
        url: "/makeCarStatusChange",
        method: "post",
        baseURL: "https://bookingoda.com/api/admin",
        headers: { "content-type": "application/json" },
        data: {
          bookedId: id,
          status: "Completed",
        },
      };
      let res = await axios(config);
      if (res.status === 200) {
        getcar();
      } else {
        alert("Something Wrong");
      }
    } catch (error) {
      ////console.log(error);
    }
  };
  useEffect(() => {
    // Fetch data asynchronously (replace fetchDataCar with your actual data fetching logic)
    getcar().then((result) => {
      setdatacar(result);
    });
  }, []);

  useEffect(() => {
    // Ensure datacar is not undefined before transforming it
    if (datacar && datacar.length > 0) {
      const transformedData = transformData(datacar);
      setData1(transformedData);
      setFilteredData(transformedData);
    }
  }, [datacar]);

  // Assuming your transformation logic
  const transformData = (datacar) => {
    const transformedData = datacar.map((item,i) => ({
      id:i+1,
      key: "BOBC" + item?._id.slice(22, 24).toUpperCase(),
      UserID: "BOCUS"+item.userId?._id.slice(22,24).toUpperCase(),
      UserName: item.userId?.name,
      CarId: "BOCAR"+item.CarId?._id.slice(22,24).toUpperCase(),
      Price: "Rs."+item.CarId?.Price,

      CarName: item.CarId?.CarName,
      name: item.name,
      email: item.email,
      mobile: item.mobile,
      age: item.age,
      Photos: item.Doc,
      pickUp1: item.createdAt,
      pickUp: `${new Date(item.pickUp).getDate()}/${
        new Date(item.pickUp).getMonth() + 1
      }/${new Date(item.pickUp).getFullYear()}`,
      dropOff: `${new Date(item.dropOff).getDate()}/${
        new Date(item.dropOff).getMonth() + 1
      }/${new Date(item.dropOff).getFullYear()}`,
      bookdate: moment(item.createdAt).format("DD/MM/YYYY"),
      pickTime: moment(item.pickTime, 'HH:mm').format('hh:mm A'),
      dropTime: moment(item.dropTime, 'HH:mm').format('hh:mm A'),
      dropOffLocation: item.dropOffLocation,
      pickUpLocation: item.pickUpLocation,
      address: item.address,
      city: item.city,
      zipcode: item.zipcode,
      Driver: item.driver === "false" ? "Self Drive" : "Driver Required",
      status: (item.status === "Confirmed" || item?.status === "Completed" ? <span style={{color:"green",fontWeight:"900"}}>{item.status}</span> :<span style={{color:"red",fontWeight:"900"}}>{item.status}</span>),

      action: (
        <>
          {item?.status === "Completed" || item?.status === "Cancelled" ? (
            <></>
          ) : (
            <>
              {item?.status === "Confirmed" ? (
                <>
                  <Button
                    variant="success"
                    onClick={() => {
                      makeComplete(item?._id);
                    }}
                    style={{ backgroundColor: "green", color: "white" }}
                  >
                    Complete
                  </Button>
                </>
              ) : (
                <>
                  <Button
                    variant="warning"
                    onClick={() => {
                      makeConfirm(item?._id);
                    }}
                    style={{
                      backgroundColor: "green",
                      marginRight: "20px",
                    marginBottom:"10px",
                      color: "white",
                    }}
                  >
                    Confirm
                  </Button>
                  <Button
                    variant="danger"
                    onClick={() => {
                      makeCancel(item?._id);
                    }}
                    style={{ backgroundColor: "red", color: "white" }}
                  >
                    Cancel
                  </Button>
                </>
              )}
            </>
          )}
        </>
      ),
    }));

    return transformedData;
  };
  const columns = [
    {
      title: "Serial No.",
      dataIndex: "id",

      // //fixed: "left",
      width: 100,
    },
    {
      title: "Booked ID",
      dataIndex: "key",

      // //fixed: "left",
      width: 150,
    },
    {
      title: "User ID",
      dataIndex: "UserID",

      width: 150,
    },
    {
      title: "User Name",
      dataIndex: "UserName",

      width: 150,
    },
    {
      title: "Car ID",
      dataIndex: "CarId",

      width: 150,
    },
    {
      title: "Car Name",
      dataIndex: "CarName",

      width: 150,
    },
    {
      title: "Name",
      dataIndex: "name",

      width: 150,
    },

    {
      title: "Email",
      dataIndex: "email",

      width: 200,
    },
    {
      title: "Mobile",
      dataIndex: "mobile",

      width: 150,
    },
    {
      title: "Age",
      dataIndex: "age",
      width: 150,
    },
    {
      title: "Pick Up Location",
      dataIndex: "pickUpLocation",
      width: 150,
    },
    {
      title: "Drop Off Location",
      dataIndex: "dropOffLocation",
      width: 150,
    },
    {
      title: "Booked Date",
      dataIndex: "bookdate",
      width: 150,
    },
    {
      title: "Pick Up",
      dataIndex: "pickUp",
      width: 150,
    },
    {
      title: "Drop Off",
      dataIndex: "dropOff",
      width: 150,
    },
    {
      title: "Pick Up Time",
      dataIndex: "pickTime",
      width: 150,
    },
    {
      title: "Drop Off Time",
      dataIndex: "dropTime",
      width: 150,
    },
    {
      title: "Address",
      dataIndex: "address",
      width: 150,
    },
    {
      title: "City",
      dataIndex: "city",
      width: 150,
    },
    {
      title: "Zipcode",
      dataIndex: "zipcode",
      width: 150,
    },
    {
      title: "Driver Required",
      dataIndex: "Driver",
      width: 150,
    },
  
    {
      title: "Price/km",
      dataIndex: "Price",

      width: 150,
    },
    {
      title: "Documents",
      dataIndex: "Photos",
      width: 550,

      render: (cell, row) => {
        // console.log(row);
        return (
          <div
            style={{
              overflowY: "scroll",
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap",
              // justifyContent: "space-between",
            }}
          >
            {row.Photos?.map((item) => (
               <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around' }}>
                <a href={"https://bookingoda.com/image/" + item.file}  target="_blank">
              <img
                src={"https://bookingoda.com/image/" + item.file}
                alt="image"
                style={{ width: "100px", height: "100px" }}
              />
              </a>  

              </div>
            ))}
          </div>
        );
      },
    },
    {
      title: "Status",
      dataIndex: "status",

      width: 150,
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "operation",
      // //fixed: "right",

      // render: () => <Link className="ms-3 fs-8 text-success">Approve</Link>,
      width: 150,
    },
    // {
    //   title: "Action",
    //   dataIndex: "action",
    //   key: "operation",
    //   //fixed: "right",

    //   // render: () => <Link className="ms-3 fs-8 text-success">Approve</Link>,
    //   width: 150,
    // },
  ];
  const ExportToExcelButton = ({ dataSource, columns, filename }) => {
    const exportToExcel = () => {
      const dataToExport = dataSource.map(({ pickUp1, ...rest }) => rest);
      const worksheet = XLSX.utils.json_to_sheet(dataToExport);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet 1");
      XLSX.writeFile(workbook, `${filename}.xlsx`);
    };

    return (
      <Button
        type="primary"
        onClick={exportToExcel}
        style={{
          margin: "10px",
          alignSelf: "flex-end",
          backgroundColor: "#1677ff",
          color: "white",
        }}
      >
        Export to Excel
      </Button>
    );
  };

  const [searchTerm, setSearchTerm] = useState("");
  // const [filteredData, setFilteredData] = useState(data1);

  const handles = (value) => {
    setSearchTerm(value);

    // Filter the data based on the search term
    const filteredDataSource = data1.filter((record) => {
      return Object.values(record).some(
        (val) =>
          val && val.toString().toLowerCase().includes(value.toLowerCase())
      );
    });

    setFilteredData(filteredDataSource);
  };

  const onClearSearch = () => {
    setSearchTerm("");
    setFilteredData(data1);
  };

  const searchInput = (
    <Input
      placeholder="Search"
      value={searchTerm}
      style={{ width: "50%" }}
      onChange={(e) => handles(e.target.value)}
      suffix={
        searchTerm && (
          <span
            onClick={onClearSearch}
            style={{ cursor: "pointer", color: "grey" }}
          >
            Clear
          </span>
        )
      }
      prefix={<SearchOutlined />}
    />
  );

  const pageSize = 5;

  const [dateRange, setDateRange] = useState([null, null]);

  const handleDateRangeChange = (dates) => {
    ////console.log("Selected Dates:", dates);

    // Filter data based on the selected date range
    const filteredDataSource = data1.filter((record) => {
      const startDate =
        dates && dates[0] ? new Date(dates[0].startOf("day").toDate()) : null;
      const endDate =
        dates && dates[1] ? new Date(dates[1].endOf("day").toDate()) : null;
      const recordDate = new Date(record.pickUp1);

      const result =
        (!startDate || recordDate >= startDate) &&
        (!endDate || recordDate <= endDate);

      return result;
    });

    // Check if the filtered data is empty, and handle accordingly
    if (filteredDataSource.length === 0) {
      // Display an empty table or show a message
      // For example, setFilteredData([]) to clear the table
      setFilteredData([]);
    } else {
      // Set the filtered data and update the date range
      setFilteredData(filteredDataSource);
      setDateRange(dates);
    }
  };
  
  return (
    <>
      <div>
        <Container fluid>
          <div className="d-flex justify-content-between align-items-center pt-4 pb-2">
            <h3 className="mb-4 title">Booked Car List</h3>
            {/* <button className='pink-btn' onClick={handleShow}>Add Package</button> */}
          </div>

          <div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                margin: 10,
              }}
            >
              {searchInput}
              <DatePicker.RangePicker
                value={dateRange}
                onChange={handleDateRangeChange}
              />
              <ExportToExcelButton
                dataSource={filteredData}
                columns={columns}
                filename="BookedCars"
              />
            </div>
            <div>
              <Table
                columns={columns}
                className="custom-table"
                dataSource={filteredData}
                scroll={{ x: "calc(700px + 50%)", y: 240 }}
                pagination={{
                  pageSize: pageSize,
                  showSizeChanger: false, // Optional: Hide the "Show X entries" dropdown
                }}
                components={{
                  header: {
                  
                    cell: props => (
                      <th {...props} style={{ ...props.style, background:'linear-gradient(#9B59B6,#8F51A8,#3E2349)'  ,color:"white"}} />
                    ),
                  },
                }}
              />
            </div>
          </div>
        </Container>
      </div>
    </>
  );
}

export default Bookedcar;
