import React, { useState } from "react";
import { MenuFoldOutlined, MenuUnfoldOutlined } from "@ant-design/icons";
import {
  AiOutlineDashboard,
  AiOutlineShoppingCart,
  AiOutlineUser,
  AiOutlineBgColors,
} from "react-icons/ai";
import { BiSolidCategoryAlt   } from "react-icons/bi";
import { RiCouponLine, RiHomeSmile2Fill } from "react-icons/ri";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link } from "react-router-dom";
import { Outlet } from "react-router-dom";
import { ImBlog } from "react-icons/im";
import { IoIosNotifications } from "react-icons/io";
import {
  FaClipboardList,
  FaBloggerB,
  FaHotel,
  FaPlaceOfWorship,
  FaImage,
  FaComments,
  FaAdversal,
} from "react-icons/fa";
import { SiBrandfolder } from "react-icons/si";
import { BiCategoryAlt } from "react-icons/bi";
import { Layout, Menu, theme } from "antd";
import { useNavigate } from "react-router-dom";
import Logo from "../../assets/logo2.jpg";
import Admin from "../../assets/admin.png";
import { MdApartment, MdBedroomParent, MdOutlineQuestionAnswer,MdBookmarkBorder  } from "react-icons/md";
import { HiOutlineHomeModern } from "react-icons/hi2";
import { GiBarracksTent } from "react-icons/gi";
import { FaPowerOff } from "react-icons/fa";

import { IoCarSport } from "react-icons/io5";
import { BsGlobeCentralSouthAsia } from "react-icons/bs";
import { FaPersonWalkingDashedLineArrowRight } from "react-icons/fa6";
import '../../firebase';
import { getMessaging, onMessage } from "firebase/messaging";
import { useEffect } from "react";
import { initializeApp } from "firebase/app";
const { Header, Sider, Content } = Layout;
const MainLayout = () => {
  const admin = JSON.parse(sessionStorage.getItem("admin"));
  console.log(admin
    );
  const firebaseConfig = {
    apiKey: "AIzaSyCKxU1uQtQhgOkBddkbe1NKsFSXJ_v4ico",
    authDomain: "mukkatis.firebaseapp.com",
    projectId: "mukkatis",
    storageBucket: "mukkatis.appspot.com",
    messagingSenderId: "255102460015",
    appId: "1:255102460015:web:68af0bb8f73057720ead9a",
    measurementId: "G-EQVQ4N72VJ"
  };

  const app = initializeApp(firebaseConfig);
  const messaging = getMessaging(app);
  Notification.requestPermission().then((permission) => {
    if (permission === 'granted') {
      console.log('Notification permission granted.');
      // Subscribe to the topic
      onMessage(messaging,'admin')
        .then(() => console.log('Subscribed to admin topic'))
        .catch(error => console.error('Error subscribing to admin topic:', error));
    } else {
      console.log('Unable to get permission to notify.');
    }
  });
  useEffect(() => {
  
    onMessage(messaging,(payload) => {
      console.log('Message received:', payload);

      // Extract notification data
      const { title, body } = payload.notification;

      // Display notification
      new Notification(title, { body });
    });
  }, []);
  if (!admin) {
    alert("Please Login");
    window.location.assign("/admin");
  }
  const [collapsed, setCollapsed] = useState(false);
  const [Expanded, setExpanded] = useState(false);

  const {
    token: { colorBgContainer },
  } = theme.useToken();
  const navigate = useNavigate();

  const logOut = () => {
    window.location.assign("/admin");
    window.sessionStorage.removeItem("admin");
    alert("Successfully logout");
  };
  const sidebarItemColors = {
    dashboard: "#3498db",
    staysbanner: "#2ecc71",
    customers: "#e74c3c",
    vendors: "#f39c12",
    apartmentlist:"#6D4AC7",
    PriorityList:"#6D4AC7",

    homelist:"#6D4AC7",
    hotellist:"#6D4AC7",
    alternativelist:"#6D4AC7",
    bookstays:"#6D4AC7",
    UnbookedList:"#6D4AC7",
    addcars:"#9B59B6",
    carlist:"#9B59B6",
    bookcar:"#9B59B6",
    addtourpackage:"#FFC107",
    tourlist:"#FFC107",
    pilgrimorder:"#FFC107",
    addvacation:"#00c9a7",
    vacationlist:"#00c9a7",
    vacationorder:"#00c9a7",
    international:"#7A5E05",
    bookinternational:"#7A5E05",
   
rsabanner:"#ffa07aff",
rsacategory:"#ffa07aff",
rsasubcategory:"#ffa07aff",
rsaslider:"#ffa07aff",
rsafaq:"#ffa07aff",
rsatestimonial:"#ffa07aff",
advertise:"#2440B0",
offers:"#2440B0",

advertiseList:"#2440B0",

nearby:"#8B4246",
    
  };
  const items=[
    {
      key: "",
      icon: <AiOutlineDashboard className="fs-4" />,
      label: "Dashboard",
    },
    {
      key: "staysbanner",
      icon: <FaImage  className="fs-4" />,
      label: "Stays Banner",
    },
    {
      key: "customers",
      icon: <AiOutlineUser className="fs-4" />,
      label: "Customers",
    },
    {
      key: "vendors",
      icon: <AiOutlineUser className="fs-4" />,
      label: "Vendors",
    },
    {
      key: "apartmentlist",
      icon: <MdApartment className="fs-4" />,
      label: "Apartments",
    },
    {
      key: "homelist",
      icon: <HiOutlineHomeModern className="fs-4" />,
      label: "Homestay",
    },
    {
      key: "hotellist",
      icon: <FaHotel className="fs-4" />,
      label: "Hotels/Resorts",
    },
    {
      key: "alternativelist",
      icon: <GiBarracksTent className="fs-4" />,
      label: "Alternative Property",
    },
    {
      key: "bookstays",
      icon: <MdBedroomParent className="fs-4" />,
      label: "Booked Stays",
    },
    {
      key: "UnbookedList",
      icon: <MdBedroomParent className="fs-4" />,
      label: "Un-Booked Stays",
    },
    {
      key: "PriorityList",
      icon: <MdBookmarkBorder  className="fs-4" />,
      label: "Priority List",
    },
    {
      key: "addcars",
      icon: <IoCarSport className="fs-4" />,
      label: "Add Cars for car rental",
    },
    {
      key: "carlist",
      icon: <IoCarSport className="fs-4" />,
      label: "Car Rental List",
    },
    {
      key: "bookcar",
      icon: <IoCarSport className="fs-4" />,
      label: "Booked Car List",
    },
    {
      key: "addtourpackage",
      icon: <FaPlaceOfWorship className="fs-4" />,
      label: "Add Pilgrim Vacations",
    },
    {
      key: "tourlist",
      icon: <FaPlaceOfWorship className="fs-4" />,
      label: "Pilgrim Vacations List",
    },
    {
      key: "pilgrimorder",
      icon: <FaPlaceOfWorship className="fs-4" />,
      label: "Pilgrim Vacations Orders",
    },
    {
      key: "addvacation",
      icon: <RiHomeSmile2Fill  className="fs-4" />,
      label: "Add Vacation Home",
    },
    {
      key: "vacationlist",
      icon: <RiHomeSmile2Fill  className="fs-4" />,
      label: "Vacation Home List",
    },
    {
      key: "vacationorder",
      icon: <RiHomeSmile2Fill  className="fs-4" />,
      label: "Vacation Orders",
    },
    {
      key: "international",
      icon: <BsGlobeCentralSouthAsia className="fs-4" />,
      label: "International Packages",
    },
    {
      key: "bookinternational",
      icon: <BsGlobeCentralSouthAsia className="fs-4" />,
      label: "International Customer Booking Packages",
    },
    {
      key: "rsabanner",
      icon: <FaImage  className="fs-4" />,
      label: "RSA Banner",
    },
    {
      key: "rsacategory",
      icon: <BiSolidCategoryAlt  className="fs-4" />,
      label: "RSA Category",
    },
    {
      key: "rsasubcategory",
      icon: <BiSolidCategoryAlt  className="fs-4" />,
      label: "RSA Sub-Category",
    },
    {
      key: "rsaslider",
      icon: <FaImage  className="fs-4" />,
      label: "RSA Slider",
    },
    {
      key: "rsafaq",
      icon: <MdOutlineQuestionAnswer   className="fs-4" />,
      label: "RSA FAQ",
    },
    {
      key: "rsatestimonial",
      icon: <FaComments    className="fs-4" />,
      label: "RSA Testimonial",
    },
    {
      key: "advertise",
      icon: <FaAdversal    className="fs-4" />,
      label: "Main Advertisement/Hot deals",
    },
    {
      key: "advertiseList",
      icon: <FaAdversal    className="fs-4" />,
      label: "Advertisement/Hot deals",
    },
    {
      key: "offers",
      icon: <FaAdversal    className="fs-4" />,
      label: "Offers/Hot deals",
    },
    {
      key: "nearby",
      icon: <FaPersonWalkingDashedLineArrowRight  className="fs-4" />,
      label: "Nearby Services",
    },
  ]
  return (
    <Layout hasSider>
      <div   >
      <Sider
        trigger={null}
        collapsible
        collapsed={collapsed}
      
        style={{
          overflow: 'auto',
          height: '100vh',
        position:'fixed',
      
          left: 0,
          top: 0,
          bottom: 0,
        }}
      
      >
        <div className="logo">
          <h2 className="text-white fs-5 text-center py-3 mb-0">
            <span className="sm-logo">MK</span>
            {/* <span className="lg-logo">Mukkatis</span> */}
            <img
              src={Logo}
              style={{ marginTop: "-12px" }}
              width="130px"
              className="lg-logo"
              // style={{ height: "50px", borderRadius: "8px" }}
            />
          </h2>
        </div>
        <Menu
          theme="dark"
          mode="inline"
          defaultSelectedKeys={[""]}
          onClick={({ key }) => {
            if (key == "signout") {
            } else {
              navigate(key);
            }
          }}
          >
          {items.map((item) => (
            <Menu.Item
              key={item.key}
              icon={item.icon}
              style={{ background: sidebarItemColors[item.key] || "#333" ,color:'white'}}
            >
              {item.label}
            </Menu.Item>
          ))}
        </Menu>
      </Sider>
      </div>
      <Layout className="site-layout" style={{
          marginLeft: 250,
        }}>
        <Header
          className="d-flex justify-content-between ps-1 pe-5"
          style={{
            padding: 0,
            background: colorBgContainer,
          }}
        >
          {React.createElement(
            collapsed ? MenuUnfoldOutlined : MenuFoldOutlined,
            {
              className: "trigger",
              onClick: () => setCollapsed(!collapsed),
            }
          )}
          <div className="d-flex gap-4 align-items-center">
            <div className="d-flex gap-3 align-items-center dropdown">
              <div>
                <img width={32} height={32} src={Admin} alt="" />
              </div>
              <div
                role="button"
                id="dropdownMenuLink"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <h5 className="mb-0">Admin</h5>
                <p className="mb-0">{admin.email}</p>
              </div>

              <li>
                <FaPowerOff
                  onClick={logOut}
                  style={{
                    height: "auto",
                    lineHeight: "20px",
                    color: "red",
                    cursor: "pointer",
                  }}
                />
              </li>
            </div>
          </div>
        </Header>
        <Content
           style={{
            margin: '24px 16px 0',
            overflow: 'initial',
          }}
        >
          <ToastContainer
            position="top-right"
            autoClose={250}
            hideProgressBar={false}
            newestOnTop={true}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            theme="light"
          />
          <Outlet />
        </Content>
      </Layout>
    </Layout>
  );
};
export default MainLayout;
