import React from "react";
import "./property.css";
import Apartment from "../../assets/apartments.png";
import Apartments from "../../assets/buildings.png";

import Homestay from "../../assets/homestay.png";
import Hotel from "../../assets/hotel.png";
import VendorHeader from "./Vendorheader";

const Refundable = () => {
  const handleKeyDown = (e) => {
    // Prevent the input of the minus sign
    if (e.key === '-' || e.key === 'e' || e.key === 'E') {
      e.preventDefault();
    }
  };
  return (
    <div
      data-capla-component="b-registration-joinapp15min-fe/"
      data-capla-namespace="b-registration-joinapp15min-feaYGUODfA"
    >
      <div id="app-container">
        <div class="error-popup-container"></div>
        <div class="application">
        <VendorHeader />
          <div class="error-popup-container"></div>

          <div className="page-container">
            <div className="screens screens-animation-direction-forward">
              <div
                className="height-100"
                id="automation_id_screen_container_NonRefundableRatePlan"
              >
                <div className="ms-container">
                  <div className="form-container form-container--map">
                    <div className="f2cf178bcd screen-main-header d991e1216a">
                      <div className="f6431b446c e6208ee469 d0caee4251 screen-main-header__title">
                        Set up a non-refundable rate plan
                      </div>
                    </div>
                    <div className="ffb9c3d6a31 wide-container min-height">
                      <div className="aca0ade214 ebac6e22e9 f66f916626 c2931f4182 c27e5d305d db150fece4">
                        <div className="b817090550 b736e9e3f4">
                          <div className="spacing--4x">
                            In addition to the standard rate plan you've created
                            for your property, you can add a non-refundable rate
                            plan.
                          </div>
                          <div className="spacing--8x">
                            With this, you set a discounted price but your{" "}
                            <b>revenue for these bookings is guaranteed</b> as
                            guests will not receive a refund if they cancel or
                            don’t show up.
                          </div>
                          <div
                            id="automation_id_non_refundable_plan_switch"
                            data-testid="automation_id_non_refundable_plan_switch"
                            className="a53cbfa6de d87fd4472a spacing--8x"
                          >
                            <input
                              type="checkbox"
                              name="switch"
                              id=":r3u:"
                              className="f43c0d9153"
                              aria-checked="true"
                              role="switch"
                              defaultChecked
                            />
                            <label
                              htmlFor=":r3u:"
                              className="dbe832aef0"
                              aria-live="polite"
                            >
                              <span className="ade77cee67" />
                              <span className="c640a7fa88">
                                Set up a non-refundable rate plan
                              </span>
                              <span className="f9bbc6d198" />
                            </label>
                          </div>
                          <div
                            id="automation_id_non_refundable_plan_percentage_enabled_container"
                            data-testid="non_refundable_plan_percentage_enabled_container"
                          >
                            <hr className="b9bfeba2b4 b288f61df6 spacing--8x" />
                            <div
                              id="price_non_refundable_input_id"
                              className="a53cbfa6de ac9267e216 a20293ec70"
                            >
                              <label
                                htmlFor=":r3v:"
                                className="a53cbfa6de e6208ee469 f555271986"
                              >
                                <span>
                                  <span className="ccb65902b2">
                                    Discount for guests that book with this rate
                                    plan:
                                  </span>
                                </span>
                              </label>
                              <div className="b9b84f4305">
                                <div className="e000754250">
                                  <input
                                    type="number" onKeyDown={handleKeyDown}
                                    name="discount"
                                    className="eb46370fe1"
                                    id=":r3v:"
                                    defaultValue={10}
                                  />
                                  <div className="e7e5251f68" />
                                </div>
                                <div className="b93ef00bf5">%</div>
                              </div>
                            </div>
                            <table className="non-refund-rate-calculation__table spacing--8x">
                              <tbody>
                                <tr>
                                  <td className="non-refund-rate-calculation__value-cell">
                                    INR 2,000.00
                                  </td>
                                  <td>Base price</td>
                                </tr>
                                <tr>
                                  <td className="non-refund-rate-calculation__value-cell">
                                    10%
                                  </td>
                                  <td>
                                    Discount when guests book the non-refundable
                                    option
                                  </td>
                                </tr>
                                <tr className="non-refund-rate-calculation__final-price-row">
                                  <td
                                    className="non-refund-rate-calculation__value-cell"
                                    data-testid="non_refund_price"
                                  >
                                    INR 1,800.00
                                  </td>
                                  <td>Non-refundable price</td>
                                </tr>
                              </tbody>
                            </table>
                            <div className="aca0ade214 aaf30230d9 c2931f4182 d79e71457a">
                              <span
                                className="fcd9eec8fb bf9a32efa5"
                                aria-hidden="true"
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 24 24"
                                >
                                  <path d="M12 15.75A1.125 1.125 0 1 0 12 18a1.125 1.125 0 0 0 0-2.25.75.75 0 0 0 0 1.5.375.375 0 1 1 0-.75.375.375 0 0 1 0 .75.75.75 0 0 0 0-1.5zm.75-2.25V5.25a.75.75 0 0 0-1.5 0v8.25a.75.75 0 0 0 1.5 0zM22.5 12c0 5.799-4.701 10.5-10.5 10.5S1.5 17.799 1.5 12 6.201 1.5 12 1.5 22.5 6.201 22.5 12zm1.5 0c0-6.627-5.373-12-12-12S0 5.373 0 12s5.373 12 12 12 12-5.373 12-12z" />
                                </svg>
                              </span>
                              <div className="aaee4e7cd3 e7a57abb1e">
                                <div className="a53cbfa6de">
                                  Guests who select non-refundable rates are
                                  usually looking for competitive prices. A
                                  discount of at least 10% will attract more
                                  guests by improving your visibility.
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="page-tooltip__wrap">
                        <section
                          aria-labelledby=":r40:"
                          className="c82435a4b8 a178069f51 a6ae3c2b40 a18aeea94d d794b7a0f7 f53e278e95"
                          style={{ padding: 4 }}
                        >
                          <div className="aca0ade214 ebac6e22e9 c2931f4182 d79e71457a">
                            <span className="d963552ea0">
                              <span
                                className="fcd9eec8fb c2cc050fb8"
                                aria-hidden="true"
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 24 24"
                                  data-rtl-flip="true"
                                >
                                  <path d="M12.751 0C6.546-.009 1.51 5.014 1.5 11.219a11.235 11.235 0 0 0 1.696 5.952l-.04-.718-3.083 6.475c-.304.637.362 1.303 1 1l6.473-3.084-.718-.04c5.276 3.28 12.213 1.663 15.494-3.614 3.28-5.276 1.663-12.213-3.614-15.494A11.25 11.25 0 0 0 12.748 0zm0 1.5A9.75 9.75 0 1 1 7.62 19.53a.75.75 0 0 0-.718-.04L.428 22.573l1 1 3.082-6.476a.75.75 0 0 0-.04-.718A9.735 9.735 0 0 1 3 11.22c.008-5.377 4.372-9.729 9.749-9.721z" />
                                </svg>
                              </span>
                            </span>
                            <div className="aaee4e7cd3 e7a57abb1e">
                              <div className="aca0ade214 aaf30230d9 cd2e7d62b0">
                                <div className="ffd93a9ecb">
                                  <h3
                                    id=":r40:"
                                    className="e1eebb6a1e e0a7dcceb5"
                                  >
                                    Why should I add a non-refundable rate plan?
                                  </h3>
                                  {/* <div className="abcc616ec7 cc1b961f14 c180176d40 f11eccb5e8">
                                    <button
                                      aria-label="Close banner"
                                      type="button"
                                      className="a83ed08757 c21c56c305 f38b6daa18 d691166b09 ab98298258 deab83296e f4552b6561"
                                    >
                                      <span className="eedba9e88a">
                                        <span
                                          className="fcd9eec8fb bf9a32efa5"
                                          aria-hidden="true"
                                        >
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 24 24"
                                          >
                                            <path d="M13.31 12l6.89-6.89a.93.93 0 1 0-1.31-1.31L12 10.69 5.11 3.8A.93.93 0 0 0 3.8 5.11L10.69 12 3.8 18.89a.93.93 0 0 0 1.31 1.31L12 13.31l6.89 6.89a.93.93 0 1 0 1.31-1.31z" />
                                          </svg>
                                        </span>
                                      </span>
                                    </button>
                                  </div> */}
                                </div>
                                <div className="a53cbfa6de">
                                  <div className="spacing--4x">
                                    A non-refundable rate plan can help attract
                                    guests who are sure of their dates and
                                    prefer to not pay extra for flexibility they
                                    don’t need.{" "}
                                  </div>
                                  <div className="spacing--4x">
                                    Properties with non-refundable rates receive
                                    on average:
                                  </div>
                                  <ul>
                                    <li>11% more views</li>
                                    <li>5% more bookings</li>
                                    <li>9% fewer cancellations</li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </div>
                        </section>
                      </div>
                    </div>
                  </div>
                  <div className="form-cta">
                    <div>
                      <div className="form-buttons-container">
                        <div className="form-buttons-container--secondary" onClick={()=>window.history.back()}>
                          <button
                            aria-label="Back to previous step"
                            data-testid="FormButtonSecondary"
                            type="button"
                            className="a83ed08757 c21c56c305 bf0537ecb5 d691166b09 f671049264 d2529514af af7297d90d"
                          >
                            <span className="eedba9e88a">
                              <span
                                className="fcd9eec8fb c2cc050fb8"
                                aria-hidden="true"
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 24 24"
                                  data-rtl-flip="true"
                                >
                                  <path d="M15.0871 19.2361C14.9677 19.2367 14.8495 19.2134 14.7392 19.1676C14.629 19.1219 14.5289 19.0546 14.4451 18.9696L8.38799 12.9125C8.26332 12.7891 8.16475 12.6418 8.09815 12.4795C8.03156 12.3172 7.99829 12.1431 8.00034 11.9676C7.99246 11.6171 8.12303 11.2776 8.36376 11.0227L14.4208 4.96566C14.5912 4.79552 14.8221 4.69995 15.0629 4.69995C15.3037 4.69995 15.5346 4.79552 15.7049 4.96566C15.7907 5.04918 15.8588 5.14902 15.9053 5.25929C15.9518 5.36957 15.9758 5.48803 15.9758 5.60771C15.9758 5.72739 15.9518 5.84586 15.9053 5.95613C15.8588 6.0664 15.7907 6.16624 15.7049 6.24976L10.0113 11.9676L15.7292 17.6855C15.8149 17.769 15.883 17.8689 15.9295 17.9792C15.976 18.0894 16 18.2079 16 18.3276C16 18.4473 15.976 18.5657 15.9295 18.676C15.883 18.7863 15.8149 18.8861 15.7292 18.9696C15.6457 19.0551 15.5458 19.1228 15.4354 19.1686C15.3251 19.2144 15.2066 19.2374 15.0871 19.2361Z" />
                                </svg>
                              </span>
                            </span>
                          </button>
                        </div>
                        <div className="form-buttons-container--primary" onClick={()=>window.location.assign("/propertyrate")}>
                          <button
                            aria-label="Continue"
                            data-testid="FormButtonPrimary-enabled"
                            type="button"
                            className="a83ed08757 c21c56c305 a4c1805887 f671049264 d2529514af c082d89982"
                          >
                            <span className="e4adce92df">Continue</span>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Refundable;
