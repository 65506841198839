import "../pages/list/list.css";

import React, { useCallback, useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { format } from "date-fns";
import { DateRange } from "react-date-range";
import IndianCities from "indian-cities-json";
import { MdKeyboardDoubleArrowRight } from "react-icons/md";
import axios from "axios";
import { FaHeart, FaLongArrowAltRight, FaRegHeart } from "react-icons/fa";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import StarRatings from "react-star-ratings";
import { FaMapMarkerAlt } from "react-icons/fa";
import Carousel from "react-bootstrap/Carousel";
import Navbar from "./navbar/Navbar";
import Footer from "./footer/Footer";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
const CityImage = ({ cityName }) => {
  const [imageUrl, setImageUrl] = useState("");

  useEffect(() => {
    const fetchImage = async () => {
      try {
        const response = await fetch(
          `https://api.unsplash.com/photos/random?query=${cityName}&client_id=cSSwA-73tDd1tFdcmFgqQYv3IwS_oRlndXmFYfdG3hs`
        );

        const data = await response.json();

        if (data.urls && data.urls.full) {
          setImageUrl(data.urls.full);
        } else {
          console.error("Image not found");
          setImageUrl(
            "https://cdn.pixabay.com/photo/2021/05/13/07/18/sculpture-6250058_1280.jpg"
          );
        }
      } catch (error) {
        console.error("Error fetching image:", error);
      }
    };

    fetchImage();
  }, [cityName]);

  return (
    <img
      src={imageUrl}
      alt={`City: ${cityName}`}
      class="featuredImg12 img-fluid"
    />
  );
};

const Offers1 = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [Tourpackages, setTourpackages] = useState([]);
  const getTourPackages = async () => {
    try {
      const res = await axios.get(
        "https://bookingoda.com/api/admin/gettrippackages"
      );
      if (res.status === 200) {
        setTourpackages(
          res.data.getpackages.filter((item) => item.blockstatus !== true)
        );
      }
    } catch (error) {}
  };
  useEffect(() => {
    getTourPackages();
  }, []);
  const user = JSON.parse(sessionStorage.getItem("user"));

  const itemsPerPage = 5; // Number of cards per page
  const [currentPage, setCurrentPage] = useState(1);
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;

  // Get the current page's items
  const currentItems = Tourpackages.slice(startIndex, endIndex);

  // Handle page change
  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };
  const [destination, setDestination] = useState("");
  console.log(destination);
  const filteredItems1 =
    destination !== ""
      ? Tourpackages.filter((item) => item.state === destination)
      : Tourpackages;
  console.log(filteredItems1);
  const [filteredItems, setFilteredItems] = useState([]);
  const filterByOfferPrice = (min, max) => {
    // Filter the tour packages based on the offer price range
    const filteredData = filteredItems1.filter((item) => {
      const offerPrice = parseInt(item.tripdiscount); // Parse trip cost to integer
      return offerPrice >= min && offerPrice <= max;
    });

    // Update the filtered items state
    setFilteredItems(filteredData);
  };
  const calculateFilteredItemsLength = (minPrice, maxPrice) => {
    // Filter the list based on the offer price range
    const filteredList = filteredItems1.filter((product) => {
      const offerPrice = parseInt(product.tripdiscount);
      return offerPrice >= minPrice && offerPrice <= maxPrice;
    });

    // Return the length of the filtered list
    return filteredList.length;
  };

  // Use the function to calculate the length for each offer price range
  const length1to4 = calculateFilteredItemsLength(1, 4);

  const length5to9 = calculateFilteredItemsLength(5, 9);
  const length10to19 = calculateFilteredItemsLength(10, 19);
  const length20above = calculateFilteredItemsLength(20, 100);

  const citys1 = Tourpackages.map((e) => e.state);
  const uniqueAddresses1 = [...new Set(citys1.flat())];
  const catresponsive = {
    0: {
      items: 1,
    },
    600: {
      items: 2,
    },
    1000: {
      items: 4,
    },
  };
  return (
    <div>
      <Navbar />
      <div class="header12 listMode">
        <div class="headerContainer12 container12">
          <div class="headerCont12">
            <div>
              <h2
                class="headerTitle12 text-center"
                style={{ marginTop: "50px" }}
              >
                Pilgrim Offers
              </h2>
            </div>
          </div>
        </div>
      </div>
      <OwlCarousel
        className="topdeals-carousel"
        loop
        margin={10}
        nav={true}
        autoplayTimeout={5000}
        responsive={catresponsive}
        autoplay={true}
        autoHeightClass="owl-height"
      >
        {uniqueAddresses1?.map((e) => (
          <div class="col-md-6 col-lg-3 col-6">
            <div class="featuredItem12" style={{height:"200px"}}>
              <div onClick={() => setDestination(e)}>
              
                <CityImage cityName={e} />
             
              <div class="featuredTitles12">
                <h1>{e}</h1>
                <h2>{citys1.filter((d) => d === e)?.length} properties</h2>
              </div> </div>
            </div>
          </div>
        ))}
      </OwlCarousel>
      <div className="row" style={{ marginTop: "50px",marginLeft:"10px"}}>
        <div className="col-lg-3">
          <div className="listSearch">
            <h1 className="lsTitle">Filter</h1>
            <div className="filterRow " id="PRICE_BUCKET">
              <div className="latoBold font16 blackText appendBottom15 makeFlex hrtlCenter">
                Discounts
              </div>
              <div className="priceBucketFilter">
                <ul className="filterList">
                  <li>
                    <button onClick={() => filterByOfferPrice(1, 4)}>
                      Below 5%
                    </button>
                    <span className="font12 grayText">({length1to4})</span>
                  </li>
                  <li>
                    <button onClick={() => filterByOfferPrice(5, 9)}>
                      5% - 9%
                    </button>
                    <span className="font12 grayText">({length5to9})</span>
                  </li>
                  <li>
                    <button onClick={() => filterByOfferPrice(10, 19)}>
                      10% - 19%
                    </button>
                    <span className="font12 grayText">({length10to19})</span>
                  </li>
                  <li>
                    <button onClick={() => filterByOfferPrice(20, 100)}>
                      20% and above
                    </button>
                    <span className="font12 grayText">({length20above})</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        <div className="col-lg-9">
          <div
            class="containerpil inn-page-con-bg tb-space pad-bot-redu-5"
            id="inner-page-title"
          >
            {filteredItems.length > 0
              ? filteredItems.map((item) => (
                  <div className="rows p2_2">
                    <div className="col-md-6 col-sm-6 col-xs-12 p2_1">
                      <div className="band">
                        <div className="trip-offer">
                          {item?.tripdiscount}%off
                        </div>
                      </div>
                      <div className="to-ho-hotel-con pack-new-box">
                        <div className="to-ho-hotel-con-1">
                          <img
                            src={`https://bookingoda.com/image/${item?.GalleryImage[0]?.image}`}
                            alt=""
                          />
                          <div className="hom-pack-deta">
                            <h2>{item?.tripname}</h2>
                            <h4>
                              <span>
                                ₹ {item?.offerprice} /{" "}
                                <span
                                  style={{ color: "black", fontSize: "18px" }}
                                >
                                  <s>₹ {item?.tripcost}</s>
                                </span>{" "}
                                <br />
                              </span>{" "}
                              {item?.tripduration}
                            </h4>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6 col-sm-6 col-xs-12 p2">
                      <h3>{item?.tripname}</h3>
                      <p>{item?.tripdescription.slice(0, 200)}...</p>

                      <div className="featur">
                        <h4>Package Locations</h4>
                        <ul>
                          <li>
                            {item?.city},{item?.state},{item?.country}
                          </li>
                        </ul>
                      </div>
                      <div className="p2_book">
                        <Link
                          to={`/trippackagedetails/${item._id}`}
                          // state={{ item: item }}
                          className="text-decoration-none"
                        >
                          <button
                            className="header-search1"
                            style={{ width: "auto", height: "45px" }}
                          >
                            View Package
                          </button>
                        </Link>
                      </div>
                    </div>
                  </div>
                ))
              : filteredItems1.length !== Tourpackages.length  ? filteredItems1.map((item) => (
                <div className="rows p2_2">
                  <div className="col-md-6 col-sm-6 col-xs-12 p2_1">
                    <div className="band">
                      <div className="trip-offer">
                        {item?.tripdiscount}%off
                      </div>
                    </div>
                    <div className="to-ho-hotel-con pack-new-box">
                      <div className="to-ho-hotel-con-1">
                        <img
                          src={`https://bookingoda.com/image/${item?.GalleryImage[0]?.image}`}
                          alt=""
                        />
                        <div className="hom-pack-deta">
                          <h2>{item?.tripname}</h2>
                          <h4>
                            <span>
                              ₹ {item?.offerprice} /{" "}
                              <span
                                style={{ color: "black", fontSize: "18px" }}
                              >
                                <s>₹ {item?.tripcost}</s>
                              </span>{" "}
                              <br />
                            </span>{" "}
                            {item?.tripduration}
                          </h4>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 col-sm-6 col-xs-12 p2">
                    <h3>{item?.tripname}</h3>
                    <p>{item?.tripdescription.slice(0, 200)}...</p>

                    <div className="featur">
                      <h4>Package Locations</h4>
                      <ul>
                        <li>
                          {item?.city},{item?.state},{item?.country}
                        </li>
                      </ul>
                    </div>
                    <div className="p2_book">
                      <Link
                        to={`/trippackagedetails/${item._id}`}
                        // state={{ item: item }}
                        className="text-decoration-none"
                      >
                        <button
                          className="header-search1"
                          style={{ width: "auto", height: "45px" }}
                        >
                          View Package
                        </button>
                      </Link>
                    </div>
                  </div>
                </div>
              )):  currentItems.map((item) => (
                  <div className="rows p2_2">
                    <div className="col-md-6 col-sm-6 col-xs-12 p2_1">
                      <div className="band">
                        <div className="trip-offer">
                          {item?.tripdiscount}%off
                        </div>
                      </div>
                      <div className="to-ho-hotel-con pack-new-box">
                        <div className="to-ho-hotel-con-1">
                          <img
                            src={`https://bookingoda.com/image/${item?.GalleryImage[0]?.image}`}
                            alt=""
                          />
                          <div className="hom-pack-deta">
                            <h2>{item?.tripname}</h2>
                            <h4>
                              <span>
                                ₹ {item?.offerprice} /{" "}
                                <span
                                  style={{ color: "black", fontSize: "18px" }}
                                >
                                  <s>₹ {item?.tripcost}</s>
                                </span>{" "}
                                <br />
                              </span>{" "}
                              {item?.tripduration}
                            </h4>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6 col-sm-6 col-xs-12 p2">
                      <h3>{item?.tripname}</h3>
                      <p>{item?.tripdescription.slice(0, 200)}...</p>

                      <div className="featur">
                        <h4>Package Locations</h4>
                        <ul>
                          <li>
                            {item?.city},{item?.state},{item?.country}
                          </li>
                        </ul>
                      </div>
                      <div className="p2_book">
                        <Link
                          to={`/trippackagedetails/${item._id}`}
                          // state={{ item: item }}
                          className="text-decoration-none"
                        >
                          <button
                            className="header-search1"
                            style={{ width: "auto", height: "45px" }}
                          >
                            View Package
                          </button>
                        </Link>
                      </div>
                    </div>
                  </div>
                ))}
          </div>
          <div className="blog-pagination">
            <nav>
              <ul className="pagination page-item justify-content-center">
                {Array.from({
                  length: Math.ceil(filteredItems1.length / itemsPerPage),
                }).map((_, index) => (
                  <li
                    key={index}
                    className={`previtem ${
                      currentPage === index + 1 ? "active" : ""
                    }`}
                  >
                    <button
                      className="page-link"
                      onClick={() => handlePageChange(index + 1)}
                    >
                      <i className="fas fa-regular fa-arrow-right me-2" />{" "}
                      {index + 1}
                    </button>
                  </li>
                ))}
              </ul>
            </nav>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default Offers1;
