import React from "react";
import imageUrl from "../../assets/coming-soon.png";
import "./honeymoon.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Navbar from "../navbar/Navbar";
import Footer from "../footer/Footer";
import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";
const HoneymoonHome = () => {
  return (
    <>
      <Navbar />
      
        <section data-anim-wrap className="masthead -type-5 animated">
          <div data-anim-child="fade" className="masthead__bg is-in-view">
            <img src="https://creativelayers.net/themes/gotrip-html/img/masthead/5/bg.svg" alt="image" />
          </div>
          <div className="containerh">
            <div className="rowh">
              <div className="col-xl-9">
                <h1 data-anim-child="slide-up delay-4" className="text-60 lg:text-40 md:text-30 is-in-view">Best Travel <span className="text-blue-1 relative">Experience <span className="-line"><img src="https://creativelayers.net/themes/gotrip-html/img/general/line.png" alt="image" /></span></span></h1>
                <p data-anim-child="slide-up delay-5" className="mt-20 is-in-view">Experience the various exciting tour and travel packages and Make hotel reservations, find<br className="lg:d-none" /> vacation packages, search cheap hotels and events</p>
                <div data-anim-child="slide-up delay-6" className="mainSearch bg-white pr-20 py-20 lg:px-20 lg:pt-5 lg:pb-20 rounded-4 shadow-1 mt-35 is-in-view">
                  <div className="buttonh-grid items-center">
                    <div className="searchMenu-loc px-30 lg:py-20 lg:px-0 js-form-dd js-liverSearch">
                      <div data-x-dd-click="searchMenu-loc">
                        <h4 className="text-15 fw-500 ls-2 lh-16">Location</h4>
                        <div className="text-15 text-light-1 ls-2 lh-16">
                          <input autoComplete="off" type="search" placeholder="Where are you going?" className="js-search js-dd-focus" />
                        </div>
                      </div>
                      <div className="searchMenu-loc__field shadow-2 js-popup-window" data-x-dd="searchMenu-loc" data-x-dd-toggle="-is-active">
                        <div className="bg-white px-30 py-30 sm:px-0 sm:py-15 rounded-4">
                          <div className="y-gap-5 js-results">
                            <div>
                              <button className="-link d-block col-12 text-left rounded-4 px-20 py-15 js-search-option">
                                <div className="d-flex">
                                  <div className="icon-location-2 text-light-1 text-20 pt-4" />
                                  <div className="ml-10">
                                    <div className="text-15 lh-12 fw-500 js-search-option-target">London</div>
                                    <div className="text-14 lh-12 text-light-1 mt-5">Greater London, United Kingdom</div>
                                  </div>
                                </div>
                              </button>
                            </div>
                            <div>
                              <button className="-link d-block col-12 text-left rounded-4 px-20 py-15 js-search-option">
                                <div className="d-flex">
                                  <div className="icon-location-2 text-light-1 text-20 pt-4" />
                                  <div className="ml-10">
                                    <div className="text-15 lh-12 fw-500 js-search-option-target">New York</div>
                                    <div className="text-14 lh-12 text-light-1 mt-5">New York State, United States</div>
                                  </div>
                                </div>
                              </button>
                            </div>
                            <div>
                              <button className="-link d-block col-12 text-left rounded-4 px-20 py-15 js-search-option">
                                <div className="d-flex">
                                  <div className="icon-location-2 text-light-1 text-20 pt-4" />
                                  <div className="ml-10">
                                    <div className="text-15 lh-12 fw-500 js-search-option-target">Paris</div>
                                    <div className="text-14 lh-12 text-light-1 mt-5">France</div>
                                  </div>
                                </div>
                              </button>
                            </div>
                            <div>
                              <button className="-link d-block col-12 text-left rounded-4 px-20 py-15 js-search-option">
                                <div className="d-flex">
                                  <div className="icon-location-2 text-light-1 text-20 pt-4" />
                                  <div className="ml-10">
                                    <div className="text-15 lh-12 fw-500 js-search-option-target">Madrid</div>
                                    <div className="text-14 lh-12 text-light-1 mt-5">Spain</div>
                                  </div>
                                </div>
                              </button>
                            </div>
                            <div>
                              <button className="-link d-block col-12 text-left rounded-4 px-20 py-15 js-search-option">
                                <div className="d-flex">
                                  <div className="icon-location-2 text-light-1 text-20 pt-4" />
                                  <div className="ml-10">
                                    <div className="text-15 lh-12 fw-500 js-search-option-target">Santorini</div>
                                    <div className="text-14 lh-12 text-light-1 mt-5">Greece</div>
                                  </div>
                                </div>
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="searchMenu-date px-30 lg:py-20 lg:px-0 js-form-dd js-calendar js-calendar-el">
        <div data-x-dd-click="searchMenu-date">
          <h4 className="text-15 fw-500 ls-2 lh-16">Check in - Check out</h4>
          <div className="capitalize text-15 text-light-1 ls-2 lh-16">
            <span className="js-first-date">Wed 2 Mar</span>
            -
            <span className="js-last-date">Fri 11 Apr</span>
          </div>
</div></div>
                  
                    <div className="buttonh-item" style={{width:"140px"}}>
                      <button className="mainSearch__submit buttonh -dark-1 py-15 px-35 h-60 col-12 rounded-4 bg-blue-1 text-white" >
                      <FontAwesomeIcon icon={faMagnifyingGlass} className="icon-search text-20 mr-10" />
                        Search
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div data-anim-child="fade" className="masthead__image is-in-view">
            <img src="https://creativelayers.net/themes/gotrip-html/img/masthead/5/1.png" alt="image" />
          </div>
        </section>
        <section className="layout-pt-lg layout-pb-md">
          <div data-anim-wrap className="containerh animated">
            <div data-anim-child="slide-up delay-1" className="rowh y-gap-20 justify-between items-end is-in-view">
              <div className="col-autoh">
                <div className="sectionTitle -md">
                  <h2 className="sectionTitle__title">Most Popular Tours</h2>
                  <p className=" sectionTitle__text mt-5 sm:mt-0">Interdum et malesuada fames ac ante ipsum</p>
                </div>
              </div>
              <div className="col-autoh">
                <a href="/Exclusivepackagelist" className="buttonh -md -blue-1 bg-blue-1-05 text-blue-1">
                  More 
                  {/* <div className="icon-arrow-top-right ml-15" /> */}
                </a>
              </div>
            </div>
            <div className="relative overflow-hidden pt-40 sm:pt-20 js-section-slider swiper-initialized swiper-horizontal swiper-pointer-events swiper-autoheight swiper-watch-progress swiper-backface-hidden" data-gap={30} data-scrollbar data-slider-cols="xl-4 lg-3 md-2 sm-2 base-1" data-nav-prev="js-hotels-prev" data-pagination="js-hotels-pag" data-nav-next="js-hotels-next">
              <div className="swiper-wrapper" id="swiper-wrapper-fb2b43377d9d10857" aria-live="polite" style={{height: '472px'}}>
                <div data-anim-child="slide-up delay-2" className="swiper-slide swiper-slide-visible swiper-slide-active is-in-view" role="group" aria-label="1 / 4" style={{width: '255px', marginRight: '30px'}}>
                  <a href="/Exclusivepackagedetails" className="tourCard -type-1 rounded-4 ">
                    <div className="tourCard__image">
                      <div className="cardImage ratio ratio-1:1">
                        <div className="cardImage__content">
                          <img className="rounded-4 col-12" src="https://creativelayers.net/themes/gotrip-html/img/tours/1.png" alt="image" />
                        </div>
                     
                       
                      </div>
                    </div>
                    <div className="tourCard__content mt-10">
                      <div className="d-flex items-center lh-14 mb-5">
                        <div className="text-14 text-light-1">16+ hours</div>
                        <div className="size-3 bg-light-1 rounded-full ml-10 mr-10" />
                        <div className="text-14 text-light-1">Full-day Tours</div>
                      </div>
                      <h4 className="tourCard__title text-dark-1 text-18 lh-16 fw-500">
                        <span>Stonehenge, Windsor Castle and Bath with Pub Lunch in Lacock</span>
                      </h4>
                      <p className="text-light-1 lh-14 text-14 mt-5">Westminster Borough, London</p>
                      <div className="rowh justify-between items-center pt-15">
                        
                        <div className="col-autoh">
                          <div className="text-14 text-light-1">
                            From
                            <span className="text-16 fw-500 text-dark-1">US$72</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </a>
                </div>
                <div data-anim-child="slide-up delay-3" className="swiper-slide swiper-slide-visible swiper-slide-next is-in-view" role="group" aria-label="2 / 4" style={{width: '255px', marginRight: '30px'}}>
                  <a href="/Exclusivepackagedetails" className="tourCard -type-1 rounded-4 ">
                    <div className="tourCard__image">
                      <div className="cardImage ratio ratio-1:1">
                        <div className="cardImage__content">
                          <div className="cardImage-slider rounded-4 overflow-hidden js-cardImage-slider swiper-initialized swiper-horizontal swiper-pointer-events swiper-backface-hidden">
                            <div className="swiper-wrapper" id="swiper-wrapper-703ba6d64a6ece6f" aria-live="polite" style={{transitionDuration: '0ms', transform: 'translate3d(-255px, 0px, 0px)'}}><div className="swiper-slide swiper-slide-duplicate swiper-slide-prev" data-swiper-slide-index={2} role="group" aria-label="3 / 3" style={{width: '255px'}}>
                                <img className="col-12" src="https://creativelayers.net/themes/gotrip-html/img/tours/3.png" alt="image" />
                              </div>
                              <div className="swiper-slide swiper-slide-active" data-swiper-slide-index={0} role="group" aria-label="1 / 3" style={{width: '255px'}}>
                                <img className="col-12" src="https://creativelayers.net/themes/gotrip-html/img/tours/2.png" alt="image" />
                              </div>
                              <div className="swiper-slide swiper-slide-next" data-swiper-slide-index={1} role="group" aria-label="2 / 3" style={{width: '255px'}}>
                                <img className="col-12" src="https://creativelayers.net/themes/gotrip-html/img/tours/1.png" alt="image" />
                              </div>
                              <div className="swiper-slide swiper-slide-duplicate-prev" data-swiper-slide-index={2} role="group" aria-label="3 / 3" style={{width: '255px'}}>
                                <img className="col-12" src="https://creativelayers.net/themes/gotrip-html/img/tours/3.png" alt="image" />
                              </div>
                              <div className="swiper-slide swiper-slide-duplicate swiper-slide-duplicate-active" data-swiper-slide-index={0} role="group" aria-label="1 / 3" style={{width: '255px'}}>
                                <img className="col-12" src="https://creativelayers.net/themes/gotrip-html/img/tours/2.png" alt="image" />
                              </div></div>
                            <div className="cardImage-slider__pagination js-pagination swiper-pagination-clickable swiper-pagination-bullets swiper-pagination-horizontal"><div className="pagination__item is-active" tabIndex={0} role="buttonh" aria-label="Go to slide 1" aria-current="true" /><div className="pagination__item" tabIndex={0} role="buttonh" aria-label="Go to slide 2" /><div className="pagination__item" tabIndex={0} role="buttonh" aria-label="Go to slide 3" /></div>
                            <div className="cardImage-slider__nav -prev">
                              <button className="buttonh -blue-1 bg-white size-30 rounded-full shadow-2 js-prev" tabIndex={0} aria-label="Previous slide" aria-controls="swiper-wrapper-703ba6d64a6ece6f">
                                {/* <i className="icon-chevron-left text-10" /> */}
                              </button>
                            </div>
                            <div className="cardImage-slider__nav -next">
                              <button className="buttonh -blue-1 bg-white size-30 rounded-full shadow-2 js-next" tabIndex={0} aria-label="Next slide" aria-controls="swiper-wrapper-703ba6d64a6ece6f">
                                {/* <i className="icon-chevron-right text-10" /> */}
                              </button>
                            </div>
                            <span className="swiper-notification" aria-live="assertive" aria-atomic="true" /></div>
                        </div>
                     
                      </div>
                    </div>
                    <div className="tourCard__content mt-10">
                      <div className="d-flex items-center lh-14 mb-5">
                        <div className="text-14 text-light-1">9+ hours</div>
                        <div className="size-3 bg-light-1 rounded-full ml-10 mr-10" />
                        <div className="text-14 text-light-1">Attractions &amp; Museums</div>
                      </div>
                      <h4 className="tourCard__title text-dark-1 text-18 lh-16 fw-500">
                        <span>Westminster Walking Tour &amp; Westminster Abbey Entry</span>
                      </h4>
                      <p className="text-light-1 lh-14 text-14 mt-5">Ciutat Vella, Barcelona</p>
                      <div className="rowh justify-between items-center pt-15">
                       
                        <div className="col-autoh">
                          <div className="text-14 text-light-1">
                            From
                            <span className="text-16 fw-500 text-dark-1">US$72</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </a>
                </div>
                <div data-anim-child="slide-up delay-4" className="swiper-slide swiper-slide-visible is-in-view" role="group" aria-label="3 / 4" style={{width: '255px', marginRight: '30px'}}>
                  <a href="/Exclusivepackagedetails" className="tourCard -type-1 rounded-4 ">
                    <div className="tourCard__image">
                      <div className="cardImage ratio ratio-1:1">
                        <div className="cardImage__content">
                          <img className="rounded-4 col-12" src="https://creativelayers.net/themes/gotrip-html/img/tours/3.png" alt="image" />
                        </div>
                     
                      </div>
                    </div>
                    <div className="tourCard__content mt-10">
                      <div className="d-flex items-center lh-14 mb-5">
                        <div className="text-14 text-light-1">40–55 minutes</div>
                        <div className="size-3 bg-light-1 rounded-full ml-10 mr-10" />
                        <div className="text-14 text-light-1">Private and Luxury</div>
                      </div>
                      <h4 className="tourCard__title text-dark-1 text-18 lh-16 fw-500">
                        <span>High-Speed Thames River RIB Cruise in London</span>
                      </h4>
                      <p className="text-light-1 lh-14 text-14 mt-5">Manhattan, New York</p>
                      <div className="rowh justify-between items-center pt-15">
                        
                        <div className="col-autoh">
                          <div className="text-14 text-light-1">
                            From
                            <span className="text-16 fw-500 text-dark-1">US$72</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </a>
                </div>
                <div data-anim-child="slide-up delay-5" className="swiper-slide swiper-slide-visible is-in-view" role="group" aria-label="4 / 4" style={{width: '255px', marginRight: '30px'}}>
                  <a href="/Exclusivepackagedetails" className="tourCard -type-1 rounded-4 ">
                    <div className="tourCard__image">
                      <div className="cardImage ratio ratio-1:1">
                        <div className="cardImage__content">
                          <img className="rounded-4 col-12" src="https://creativelayers.net/themes/gotrip-html/img/tours/4.png" alt="image" />
                        </div>
                     
                      </div>
                    </div>
                    <div className="tourCard__content mt-10">
                      <div className="d-flex items-center lh-14 mb-5">
                        <div className="text-14 text-light-1">94+ days</div>
                        <div className="size-3 bg-light-1 rounded-full ml-10 mr-10" />
                        <div className="text-14 text-light-1">Bus Tours</div>
                      </div>
                      <h4 className="tourCard__title text-dark-1 text-18 lh-16 fw-500">
                        <span>Edinburgh Darkside Walking Tour: Mysteries, Murder and Legends</span>
                      </h4>
                      <p className="text-light-1 lh-14 text-14 mt-5">Vaticano Prati, Rome</p>
                      <div className="rowh justify-between items-center pt-15">
                       
                       
                        <div className="col-autoh">
                          <div className="text-14 text-light-1">
                            From
                            <span className="text-16 fw-500 text-dark-1">US$72</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </a>
                </div>
              </div>
              <div className="d-flex x-gap-15 items-center justify-center pt-40 sm:pt-20">
                <div className="col-autoh">
                  <button className="d-flex items-center text-24 arrow-left-hover js-hotels-prev swiper-buttonh-disabled swiper-buttonh-lock" disabled tabIndex={-1} aria-label="Previous slide" aria-controls="swiper-wrapper-fb2b43377d9d10857" aria-disabled="true">
                    {/* <i className="icon icon-arrow-left" /> */}
                  </button>
                </div>
                <div className="col-autoh">
                  <div className="pagination -dots text-border js-hotels-pag swiper-pagination-clickable swiper-pagination-bullets swiper-pagination-horizontal swiper-pagination-lock"><div className="pagination__item is-active" tabIndex={0} role="buttonh" aria-label="Go to slide 1" aria-current="true" /></div>
                </div>
                <div className="col-autoh">
                  <button className="d-flex items-center text-24 arrow-right-hover js-hotels-next swiper-buttonh-disabled swiper-buttonh-lock" disabled tabIndex={-1} aria-label="Next slide" aria-controls="swiper-wrapper-fb2b43377d9d10857" aria-disabled="true">
                    {/* <i className="icon icon-arrow-right" /> */}
                  </button>
                </div>
              </div>
              <span className="swiper-notification" aria-live="assertive" aria-atomic="true" /></div>
          </div>
        </section>
      
        <section className="section-bg layout-pt-lg md:pt-0 md:pb-60 sm:pb-40 layout-pb-lg bg-blue-1-05">
          <div className="section-bg__item -right -image col-5 md:mb-60 sm:mb-40">
            <img src="https://creativelayers.net/themes/gotrip-html/img/backgrounds/5.png" alt="image" />
          </div>
          <div className="containerh">
            <div className="rowh">
              <div className="col-xl-4 col-md-7">
                <h2 className="text-30 fw-600">Why Choose Us</h2>
                <p className="mt-5">These popular destinations have a lot to offer</p>
                <div className="rowh y-gap-30 pt-60 md:pt-40">
                  <div className="col-12">
                    <div className="d-flex pr-30">
                      <img className="size-50" src="https://creativelayers.net/themes/gotrip-html/img/featureIcons/1/1.svg" alt="image" />
                      <div className="ml-15">
                        <h4 className="text-18 fw-500">Best Price Guarantee</h4>
                        <p className="text-15 mt-10">Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="d-flex pr-30">
                      <img className="size-50" src="https://creativelayers.net/themes/gotrip-html/img/featureIcons/1/2.svg" alt="image" />
                      <div className="ml-15">
                        <h4 className="text-18 fw-500">Easy &amp; Quick Booking</h4>
                        <p className="text-15 mt-10">Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="d-flex pr-30">
                      <img className="size-50" src="https://creativelayers.net/themes/gotrip-html/img/featureIcons/1/3.svg" alt="image" />
                      <div className="ml-15">
                        <h4 className="text-18 fw-500">Customer Care 24/7</h4>
                        <p className="text-15 mt-10">Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
       
       
       
      
      
     <Footer/>
 
    </>
  );
};

export default HoneymoonHome;
