import React, { useEffect, useState } from "react";
import "./featured.css";
import axios from "axios";
import { Link } from "react-router-dom";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
const CityImage = ({ cityName }) => {
  const [imageUrl, setImageUrl] = useState("");

  useEffect(() => {
    const fetchImage = async () => {
      try {
        const response = await fetch(
          `https://api.unsplash.com/photos/random?query=${cityName}&client_id=q4rfxYL4y3OUOx3xiJ0xtjbQMB1TTBa0asLP-txOeFQ`
        );

        const data = await response.json();

        if (data.urls && data.urls.full) {
          setImageUrl(data.urls.full);
        } else {
          console.error("Image not found");
          setImageUrl(
            "https://cdn.pixabay.com/photo/2021/05/13/07/18/sculpture-6250058_1280.jpg"
          );
        }
      } catch (error) {
        console.error("Error fetching image:", error);
      }
    };

    fetchImage();
  }, [cityName]);

  return (
    <img
      src={imageUrl}
      alt={`City: ${cityName}`}
      class="featuredImg12 img-fluid"
    />
  );
};

const Featured = () => {
  const handleClick = () => {
    window.location.assign("/hotels");
  };
  const [data, setdata] = useState([]);
  const [data1, setdata1] = useState([]);
  const [data2, setdata2] = useState([]);
  const [data3, setdata3] = useState([]);

  const getapartment = async () => {
    let res = await axios.get(
      "https://bookingoda.com/api/admin/getallApartment"
    );
    if (res.status === 200) {
      //////console.log(res.data);
      setdata(
        res.data.Apartment.filter(
          (item) =>
            item.status === "Approved" &&
            item.Deletestatus !== "Deleted By Admin" &&
            item.blockstatus !== true
        )
      );
    }
  };
  const getalternative = async () => {
    let res = await axios.get(
      "https://bookingoda.com/api/admin/getallalternative"
    );
    if (res.status === 200) {
      //////console.log(res.data);
      setdata2(
        res.data.alternative.filter(
          (item) =>
            item.status === "Approved" &&
            item.Deletestatus !== "Deleted By Admin" &&
            item.blockstatus !== true
        )
      );
    }
  };
  const gethotel = async () => {
    let res = await axios.get("https://bookingoda.com/api/admin/getallhotel");
    if (res.status === 200) {
      //////console.log(res.data);
      setdata1(
        res.data.hotel.filter(
          (item) =>
            item.status === "Approved" &&
            item.Deletestatus !== "Deleted By Admin" &&
            item.blockstatus !== true
        )
      );
    }
  };
  const gethomestay = async () => {
    let res = await axios.get("https://bookingoda.com/api/admin/getallhome");
    if (res.status === 200) {
      //////console.log(res.data);
      setdata3(
        res.data.home.filter(
          (item) =>
            item.status === "Approved" &&
            item.Deletestatus !== "Deleted By Admin" &&
            item.blockstatus !== true
        )
      );
    }
  };

  useEffect(() => {
    getapartment();
    gethotel();
    getalternative();
    gethomestay();
  }, []);
  const data4 = [...data, ...data1, ...data2, ...data3];
  const datasss = data4.map((e) => e.address.map((data) => data));
  const cityNames = datasss.map((address, index) => {
    if (Array.isArray(address) && address[0]) {
      const cityArray = address[0].split(","); // Split the address string by commas
      //console.log(cityArray,"array");
      if (cityArray.length >= 3) {
        const cityName = cityArray[cityArray.length - 3]?.trim(); // Use optional chaining to avoid 'undefined' error
        return cityName || null; // Provide a default value if city name is not found
      } else {
        return null;
      }
    } else {
      return null;
    }
  });

  const uniqueAddresses = [...new Set(cityNames.flat())];
  const [places, setPlaces] = useState([]);
  const getinternational = async () => {
    let res = await axios.get(
      "https://bookingoda.com/api/admin/getinternational"
    );
    if (res.status === 200) {
      //////console.log(res.data);
      setPlaces(
        res.data.getpackages.filter((item) => item.blockstatus !== true)
      );
    }
  };
  useEffect(() => {
    getinternational();
  }, []);
  const [Tourpackages, setTourpackages] = useState([]);
  const getTourPackages = async () => {
    try {
      const res = await axios.get(
        "https://bookingoda.com/api/admin/gettrippackages"
      );
      if (res.status === 200) {
        setTourpackages(
          res.data.getpackages.filter((item) => item.blockstatus !== true)
        );
      }
    } catch (error) {}
  };
  useEffect(() => {
    getTourPackages();
  }, []);
  const citys = places.map((e) => e.state);
  const citys1 = Tourpackages.map((e) => e.state);

  const uniqueAddresses1 = [...new Set(citys.flat())];
  const uniqueAddresses2 = [...new Set(citys1.flat())];

  // const uniqueAddresses = [...new Set(datasss.flat())];
  const catresponsive = {
    0: {
      items: 1,
    },
    600: {
      items: 2,
    },
    1000: {
      items: 4,
    },
  };
  return (
    <>
      <div className="featured12">
        <div className="exploreIndiaSection">
          <div>
            <h3>Explore India</h3>
            <p>These popular destinations have a lot to offer</p>
          </div>

          <div class="featured12 row13 gy-3 gx-md-3 gx-3 justify-content-space-around mb-1">
            <OwlCarousel
              className="topdeals-carousel"
              loop
              margin={10}
              nav={true}
              autoplayTimeout={5000}
              responsive={catresponsive}
              autoplay={true}
              autoHeightClass="owl-height"
            >
              {uniqueAddresses.reverse()?.map((e) => (
                <div class="col-md-6 col-lg-3 col-6">
                  <div class="featuredItem12">
                    <Link to={`/hotels`} state={{ destination: e }}>
                      {" "}
                      <CityImage cityName={e} />
                    </Link>
                    <div class="featuredTitles12">
                      <h1>{e}</h1>
                      <h2>
                        {cityNames.filter((d) => d === e)?.length} properties
                      </h2>
                    </div>
                  </div>
                </div>
              ))}
              {uniqueAddresses1.reverse()?.map((e) => (
                <div class="col-md-6 col-lg-3 col-6">
                  <div class="featuredItem12">
                    <Link
                      to={`/HomeInternationalList`}
                      state={{ destination: e }}
                    >
                      {" "}
                      <CityImage cityName={e} />
                    </Link>
                    <div class="featuredTitles12">
                      <h1>{e}</h1>
                      <h2>{citys.filter((d) => d === e)?.length} properties</h2>
                    </div>
                  </div>
                </div>
              ))}
              {uniqueAddresses2.reverse()?.map((e) => (
                <div class="col-md-6 col-lg-3 col-6">
                  <div class="featuredItem12">
                    <Link to={`/Attraction`} state={{ destination: e }}>
                      {" "}
                      <CityImage cityName={e} />
                    </Link>
                    <div class="featuredTitles12">
                      <h1>{e}</h1>
                      <h2>
                        {citys1.filter((d) => d === e)?.length} properties
                      </h2>
                    </div>
                  </div>
                </div>
              ))}
            </OwlCarousel>
          </div>
        </div>
      </div>
    </>
  );
};

export default Featured;
