import React, { useEffect, useState } from "react";
import { Button, Table,DatePicker } from "antd";
import Table1 from "react-bootstrap/Table";
import { Input, Space } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { BiEdit } from "react-icons/bi";
import { AiFillDelete } from "react-icons/ai";
import { FcApproval } from "react-icons/fc";
import { MdDelete } from "react-icons/md";
import { BiSolidEdit } from "react-icons/bi";
import { Container, Form, Modal } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCancel } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import axios from "axios";
import parse from "html-react-parser";

import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepButton from "@mui/material/StepButton";

import Typography from "@mui/material/Typography";
import { City, Country, State } from "country-state-city";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

import { CKEditor } from "@ckeditor/ckeditor5-react";
import * as XLSX from "xlsx";
import moment from "moment/moment";
const PilgrimOrderVendor = (props) => {
  const vendor = JSON.parse(sessionStorage.getItem("vendor"));

  const [data, setdata] = useState([]);

  const getstays = async () => {
    let res = await axios.get(
      "https://bookingoda.com/api/admin/getbookedtourpackages"
    );
    if (res.status === 200) {
      //////console.log(res.data);
      setdata(
        res.data.bookedtourpackages.filter(
          (item) => item.packageId?.UserId === vendor._id
        )
      );
      ////console.log(res.data.bookedtourpackages, "dasfasd");
    }
  };
  useEffect(() => {
    getstays();
  }, []);
  const Approve = async (id) => {
    try {
      const config = {
        url: "/admin/Approvestay",
        method: "post",
        baseURL: "https://bookingoda.com/api",
        hearder: { "content-type": "application/json" },

        data: {
          internationalid: id,
          status: "Approved",
        },
      };
      await axios(config).then(function (res) {
        if (res.status === 200) {
          ////console.log(res.data);
          alert("Approved");
          window.location.reload();
        } else {
          alert("Something Wrong");
        }
      });
    } catch (error) {
      ////console.log(error.response);
    }
  };

  ////console.log(data);
  const [Data1, setdata1] = useState("");
  const [data1, setData1] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const PrintInvoice = (Item) => {
    sessionStorage.setItem("InvoiceItem", JSON.stringify(Item));
   
    window.location.assign("/pilgriminvoice");
  };
  useEffect(() => {
    // Fetch data asynchronously (replace fetchDataCar with your actual data fetching logic)
    getstays().then((result) => {
      setdata(result);
    });
  }, []);

  useEffect(() => {
    // Ensure datacar is not undefined before transforming it
    if (data && data.length > 0) {
      const transformedData = transformData(data);
      setData1(transformedData);
      setFilteredData(transformedData);
    }
  }, [data]);
 
  const transformData = (data) => {
    return data.map((item,i) => ({
      id:i+1,
      key: "BOBP" + item._id.slice(22, 24).toUpperCase(),
      UserID: "BOCUS"+item.userId?._id.slice(22,24).toUpperCase(),
      UserName: item.userId?.name,
      packageId: "BOPIL" + item.packageId?._id.slice(22, 24).toUpperCase(),
      packageName: item.packageId?.tripname,
      Photos:item.Doc,
      AdultPrice: item.adultprice,
      AdultDiscount: item.adultquantity,
      address: item.address?.join(", "),
      payment:item.payment,
      ChildrenPrice: item.childrenprice,
      Amount: item.adultprice + item.childrenprice + item.petsprice,
      ChildrenQuantity: item.childrenquantity,
      bookdate1: item.createdAt,
      bookdate: moment(item.createdAt).format("DD/MM/YYYY"),
      arrivaldate: item.checkindate,
      // PetsPrice: item.petsprice,
     
      // petsquantity: item.petsquantity,
      status: item.status,
     
      action1: (
        <>
          <div className="d-flex gap-4 fs-8" style={{ cursor: "pointer"}}>
            {/* Your additional action logic goes here */}
          </div>
        </>
      ),
      action3: (
        <>
          <div className="d-flex gap-2 fs-4" style={{ cursor: 'pointer' }}>
          <Button
                    variant="warning"
                    onClick={()=> {PrintInvoice(item)}}
                    style={{backgroundColor:'#1677ff',marginRight:"20px",color:"white"}}
                  >
                    Invoice
                  </Button>
         
          </div>
        </>
      )
    }));
  };
  // const data1 = [];
  // for (let i = 0; i < data.length; i++) {
  //   data1.push({
  //     key: data[i]._id,
  //     UserID: data[i].userId?._id,
  //     UserName: data[i].userId?.name,

  //     AdultPrice: data[i].adultprice,
  //     AdultDiscount: data[i].adultquantity,
  //     ChildrenPrice: data[i].childrenprice,
  //     Amount:
  //       data[i].adultprice  +
  //       data[i].childrenprice +
  //       data[i].petsprice ,
  //     ChildrenQuantity: data[i].childrenquantity,
  //     arrivaldate: data[i].checkindate,
  //     PetsPrice: data[i].petsprice,
  //     packageId: data[i].packageId?._id,
  //     packageName: data[i].packageId?.tripname,

  //     petsquantity: data[i].petsquantity,
  //     action: (
  //       <>
  //         {/* <Link to="/" className=" fs-3 text-danger">
  //             <BiEdit />
  //           </Link> */}
  //         {data[i].status === "Pending" ? (
  //           <Button
  //             className="ms-3 fs-8 text-success"
  //             style={{ cursor: "pointer" }}
  //             onClick={() => Approve(data[i]._id)}
  //           >
  //             Approve
  //           </Button>
  //         ) : (
  //           <div className="ms-3 fs-8 text-success">
  //             <FcApproval /> Approved
  //           </div>
  //         )}
  //       </>
  //     ),
  //     action1: (
  //       <>
  //         <div className="d-flex gap-4 fs-8" style={{ cursor: "pointer"}}>
  //           {/* <BiSolidEdit className="text-primary" onClick={()=>handleShow1(data[i])} />

  //           <MdDelete
  //             className="text-danger"
  //             onClick={() => handleShow3(data[i]?._id)}
  //           /> */}
  //         </div>
  //       </>
  //     ),
  //   });
  // }
 
  const columns = [
    {
      title: "Serial No.",
      dataIndex: "id",

      // //fixed: "left",
      width: 150,
    },
    {
      title: "Booked ID",
      dataIndex: "key",

      // //fixed: "left",
      width: 150,
    },
    {
      title: "User ID",
      dataIndex: "UserID",
     

      width: 150,
    },
    {
      title: "User Name",
      dataIndex: "UserName",
    

      width: 150,
    },
    {
      title: "Address",
      dataIndex: "address",
 
      width: 150,
    },
    {
      title: "PackageID",
      dataIndex: "packageId",
    

      width: 150,
    },
    {
      title: "Package Name",
      dataIndex: "packageName",
      

      width: 150,
    },
    {
      title: "Total Amount",
      dataIndex: "Amount",

      width: 150,
    },
    {
      title: "Adult Price",
      dataIndex: "AdultPrice",

      width: 150,
    },

    {
      title: "Adult Quantity",
      dataIndex: "AdultDiscount",
      width: 150,
    },
    {
      title: "Children Price",
      dataIndex: "ChildrenPrice",
      width: 150,
    },
    {
      title: "Children Quantity",
      dataIndex: "ChildrenQuantity",
      width: 150,
    },
    // {
    //   title: "Pets Price",
    //   dataIndex: "PetsPrice",
    //   width: 150,
    // },
    // {
    //   title: "Pets Quantity",
    //   dataIndex: "petsquantity",
    //   width: 150,
    // },
    {
      title: "Booked Date",
      dataIndex: "bookdate",
      width: 150,
    },
    
   
   
    {
      title: "CheckIn date",
      dataIndex: "arrivaldate",
      width: 150,
    },
    {
      title: "Payment",
      dataIndex: "payment",
 
      width: 150,
    },
    {
      title: "Documents",
      dataIndex: "Photos",
      width: 550,

      render: (cell, row) => {
        // console.log(row);
        return (
          <div
            style={{
              overflowY: "scroll",
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap",
              // justifyContent: "space-between",
            }}
          >
            {row.Photos?.map((item) => (
               <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around' }}>
                <a href={"https://bookingoda.com/image/" + item.file}  target="_blank">
              <img
                src={"https://bookingoda.com/image/" + item.file}
                alt="image"
                style={{ width: "100px", height: "100px" }}
              />
              </a>  

              </div>
            ))}
          </div>
        );
      },
    },
    {
      title: "Status",
      dataIndex: "status",

      width: 150,
    },
    // {
    //   title: "Action",
    //   dataIndex: "action",
    //   key: "operation",
    //   // //fixed: "right",

    //   // render: () => <Link className="ms-3 fs-8 text-success">Approve</Link>,
    //   width: 350,
    // },
    {
      title: "Invoice",
      dataIndex: "action3",
      key: "operation",
      // //fixed: "right",
  
      // render: () => <Link className="ms-3 fs-8 text-success">Approve</Link>,
      width: 350,
    },
  ];
  const ExportToExcelButton = ({ dataSource, columns, filename }) => {
    const exportToExcel = () => {
      const dataToExport = dataSource.map(({bookdate1, ...rest }) => rest);
      const worksheet = XLSX.utils.json_to_sheet(dataToExport);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet 1");
      XLSX.writeFile(workbook, `${filename}.xlsx`);
    };

    return (
      <Button
        type="primary"
        onClick={exportToExcel}
        style={{
          margin: "10px",
          alignSelf: "flex-end",
          backgroundColor: "#1677ff",
          color: "white",
        }}
      >
        Export to Excel
      </Button>
    );
  };

  const [searchTerm, setSearchTerm] = useState("");
  // const [filteredData, setFilteredData] = useState(data1);

  const handles = (value) => {
    setSearchTerm(value);

    // Filter the data based on the search term
    const filteredDataSource = data1.filter((record) => {
      return Object.values(record).some(
        (val) =>
          val && val.toString().toLowerCase().includes(value.toLowerCase())
      );
    });

    setFilteredData(filteredDataSource);
  };

  const onClearSearch = () => {
    setSearchTerm("");
    setFilteredData(data1);
  };

  const searchInput = (
    <Input
      placeholder="Search"
      value={searchTerm}
      style={{width:"50%"}}
      onChange={(e) => handles(e.target.value)}
      suffix={
        searchTerm && (
          <span
            onClick={onClearSearch}
            style={{ cursor: "pointer", color: "grey" }}
          >
            Clear
          </span>
        )
      }
      prefix={<SearchOutlined />}
    />
  );

  const pageSize = 5;
 
  const [dateRange, setDateRange] = useState([null, null]);
  
  const handleDateRangeChange = (dates) => {
    ////console.log('Selected Dates:', dates);
  
    // Filter data based on the selected date range
    const filteredDataSource = data1.filter((record) => {
      const startDate = dates && dates[0] ? new Date(dates[0].startOf('day')) : null;
      const endDate = dates && dates[1] ? new Date(dates[1].endOf('day')) : null;
      const recordDate = new Date(record.bookdate1);
  
      const result =
        (!startDate || recordDate >= startDate) &&
        (!endDate || recordDate <= endDate);
  
      return result;
    });
  
    setFilteredData(filteredDataSource);
    setDateRange(dates);
  };
  
  return (
    <div>
      <h3 className="mb-4 title">Booked Pilgrim vaction List</h3>
      <div style={{display:"flex",flexDirection:"row",justifyContent:'space-between',margin:10}}>
      {searchInput}
      <DatePicker.RangePicker
        value={dateRange}
        onChange={handleDateRangeChange}
        
      />
       <ExportToExcelButton
        dataSource={filteredData}
        columns={columns}
        filename="PilgrimOrder"
      /></div>
      <div>
        <Table
          columns={columns} className="custom-table"
          dataSource={filteredData}
          scroll={{ x: "calc(700px + 50%)", y: 240 }}
          pagination={{
            pageSize: pageSize,
            showSizeChanger: false, // Optional: Hide the "Show X entries" dropdown
          }}
        />
      </div>
    </div>
  );
};

export default PilgrimOrderVendor;
