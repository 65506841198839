import { display } from "@mui/system";
import axios from "axios";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import "./community.scoped.css";
import Profile_Dashboard from "./Profile_Dashboard";

import pics from "../../assets/user.png";
function FriendReq() {
  const user = JSON.parse(sessionStorage.getItem("user"));
  const [loading, setLoading] = useState(false);
  const [refreshing, setRefreshing] = useState(false);

  const [users, setUsers] = useState([]);
  const [friendReq, setFriendReq] = useState([]);

  useEffect(() => {
  
    getAllUsers();
  }, []);

  useEffect(() => {
    if (Object.keys(user).length > 0) {
      getFriendRequest();
    }
  }, [user]);

  

  const getAllUsers = () => {
    axios
      .get('https://bookingoda.com/api/user/getAllUser')
      .then((response) => {
        setUsers(response.data.success);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getFriendRequest = () => {
    setLoading(true);
    axios
      .get('https://bookingoda.com/api/user/getfriendreq/' + user._id)
      .then((response) => {
        setLoading(false);
        setFriendReq(response.data.receivedRequests);
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
      });
  };

  const friendReqAccepted = async (data) => {
    try {
      const config = {
        url: `/friendreqaccepted/${data}`,
        method: 'post',
        baseURL: 'https://bookingoda.com/api/user',
        data: { status: 'Accepted' },
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          getFriendRequest();
          console.log('success');
        }
      });
    } catch (error) {
      console.log(error);
      alert('Unable to send request');
    }
  };

  const friendReqRejected = async (id) => {
    axios({
      method: 'post',
      url: `https://bookingoda.com/api/user/friendreqrejected/${id}`,
    })
      .then((response) => {
        if (response.status === 200) {
          getFriendRequest();
          console.log('success');
        }
      })
      .catch((error) => {
        console.log(error.response.data);
      });
  };


  return (
    <>
      <div className="communityhomestyle">
      
        <div>
          <div className="gap2 gray-bg">
            <div
              className="container fluid mobile-correct-0"
              style={{ paddingTop: "25px" }}
            >
              <div className="row">
                <div className="col-lg-12">
                  <div className="row merged20" id="page-contents">
                    {/* profile Dashboard start ========================================= */}
                    <div className="user-profile">
                      <Profile_Dashboard />
                    </div>
                    {/* <!-- user profile banner  --> */}
                    <div class="col-lg-12">
                    
                      <div class="central-meta">
                        <div class="title-block">
                        <div className="friend-request-container">
      <div className="headerreq">
       
        <h2>Friend Requests</h2>
      </div>
      <div className="friend-requests">
        {friendReq.map((friend) => (
          <div key={friend._id} className="friend-request">
            <div className="friend-info">
              <div className="friend-avatar">
                {friend?.senderId?.profileImage ? (
                  <img
                    src={`https://bookingoda.com/admin/${friend?.senderId?.profileImage}`}
                    alt={friend?.senderId?.name}
                  />
                ) : (
                  <img src={pics} alt="Avatar" />
                )}
              </div>
              <div className="friend-details">
                <h3>{friend?.senderId?.name}</h3>
                <p>{friend?.senderId?.email}</p>
              </div>
            </div>
            <div className="friend-actions">
              {friend.status === 'Pending' ? (
                <>
                  <button
                    onClick={() => friendReqAccepted(friend._id)}
                    className="accept-btn"
                  >
                    Accept
                  </button>
                  <button
                    onClick={() => friendReqRejected(friend._id)}
                    className="reject-btn"
                  >
                    Reject
                  </button>
                </>
              ) : friend.status === 'Accepted' ? (
                <div className="accepted">Accepted</div>
              ) : (
                <div className="rejected">Rejected</div>
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
                        </div>
                      </div>
                      {/* <!-- title block --> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default FriendReq;
