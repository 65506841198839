import React, { useState } from 'react';

const SortedList = () => {
  const initialData = [
    { id: 1, name: 'John', age: 25 },
    { id: 2, name: 'Alice', age: 30 },
    { id: 3, name: 'Bob', age: 22 },
    // ... add more data as needed
  ];

  const [data, setData] = useState(initialData);
  const [sortBy, setSortBy] = useState('order'); // Default sorting criteria

  const handleSort = (criteria) => {
    const sortedData = [...data].sort((a, b) => {
      if (a[criteria] < b[criteria]) return -1;
      if (a[criteria] > b[criteria]) return 1;
      return 0;
    });

    setData(sortedData);
    setSortBy(criteria);
  };

  return (
    <div>
      <div>
        <label>Sort by:</label>
        <select onChange={(e) => handleSort(e.target.value)} value={sortBy}>
          <option value="name">Name</option>
          <option value="age">Age</option>
          {/* Add more options for other criteria */}
        </select>
      </div>
      <ul>
        {data.map((item, index) => (
          <li key={item.id}>{`${index + 1}. ${item.name} - ${item.age} years old`}</li>
        ))}
      </ul>
    </div>
  );
};

export default SortedList;

