import React, { useEffect, useState } from "react";
import { BsArrowDownRight, BsArrowUpRight } from "react-icons/bs";
import { Column } from "@ant-design/plots";
import {  Card, Space, Table } from "antd";
import axios from "axios";
import {
  DollarCircleOutlined,
  ShoppingCartOutlined,
  ShoppingOutlined,
  UserOutlined,
  UserSwitchOutlined,
  UsergroupAddOutlined
} from "@ant-design/icons";
import { ApartmentOutlined, LocalHotelOutlined, LocationCityOutlined } from "@material-ui/icons";
import { BedroomParentOutlined, DirectionsCarFilledOutlined, HolidayVillageOutlined, HouseboatOutlined, NightShelterOutlined } from "@mui/icons-material";
import { Link } from "react-router-dom";

const data1 = [];
for (let i = 0; i < 46; i++) {
  data1.push({
    key: i,
    name: `Edward King ${i}`,
    product: 32,
    staus: `London, Park Lane no. ${i}`,
  });
}
const Dashboard = () => {
  const [user, setuser] = useState([]);

  const getuser = async () => {
    let res = await axios.get("https://bookingoda.com/api/user/getAllUser");
    if (res.status === 200) {
      ////console.log(res.data.success,"customer");
      setuser(res.data.success);
    }
  };
  const [vendor, setvendor] = useState([]);

  const getvendor = async () => {
    let res = await axios.get("https://bookingoda.com/api/admin/getallVendor");
    if (res.status === 200) {
      ////console.log(res.data.Vendor,"customer");
      setvendor(res.data.Vendor);
    }
  };
  const [hotel, sethotel] = useState([]);

  const gethotel = async () => {
    let res = await axios.get("https://bookingoda.com/api/admin/getallhotel");
    if (res.status === 200) {
      //////console.log(res.data);
      sethotel(res.data.hotel.filter((e) => e.Deletestatus !== "Deleted By Admin"));
    }
  };
  const [home, sethome] = useState([]);

  const gethome = async () => {
    let res = await axios.get("https://bookingoda.com/api/admin/getallhome");
    if (res.status === 200) {
      //////console.log(res.data);
      sethome(res.data.home.filter((e) => e.Deletestatus !== "Deleted By Admin"));
    }
  };
  const [apartment, setapartment] = useState([]);

  const getapartment = async () => {
    let res = await axios.get(
      "https://bookingoda.com/api/admin/getallApartment"
    );
    if (res.status === 200) {
      //////console.log(res.data);
      setapartment(res.data.Apartment.filter((e) => e.Deletestatus !== "Deleted By Admin"));
    }
  };
  const [alternative, setalternative] = useState([]);

  const getalternative = async () => {
    let res = await axios.get(
      "https://bookingoda.com/api/admin/getallalternative"
    );
    if (res.status === 200) {
      //////console.log(res.data);
      setalternative(res.data.alternative.filter((e) => e.Deletestatus !== "Deleted By Admin"));
    }
  };
  const [car, setcar] = useState([]);

  const getcar = async () => {
    let res = await axios.get(
      "https://bookingoda.com/api/admin/getallCar"
    );
    if (res.status === 200) {
      //////console.log(res.data);
      setcar(res.data.Car);
    }
  };
  const [pil, setpil] = useState([]);
  
  const getpil = async () => {
    let res = await axios.get(
      "https://bookingoda.com/api/admin/getbookedtourpackages"
    );
    if (res.status === 200) {
      //////console.log(res.data);
      setpil(res.data.bookedtourpackages);
      ////console.log(res.data.bookedtourpackages,"dasfasd");
    }
  };
  const [international, setinternational] = useState([]);

  const getinternational = async () => {
    let res = await axios.get(
      "https://bookingoda.com/api/admin/getinternational"
    );
    if (res.status === 200) {
      //////console.log(res.data);
      setinternational(res.data.getpackages);
    }
  };
  const [stays, setstays] = useState([]);

  const getstays = async () => {
    let res = await axios.get(
      "https://bookingoda.com/api/admin/gettrippackages"
    );
    if (res.status === 200) {
      //////console.log(res.data);
      setstays(res.data.getpackages);
      ////console.log(res.data.bookedstays,"dasfasd");
    }
  };
  const [carbooked, setcarbooked] = useState([]);

  const getcarbooked = async () => {
    try {
      const res = await axios.get(
        "https://bookingoda.com/api/admin/getbookedcars"
      );
      if (res.status === 200) {
        setcarbooked(res.data.bookedcars);
        ////console.log(res.data.bookedcars);

      }
    } catch (error) {
      ////console.log(error);
    }
  };
  const [internationalbooked, setinternationalbooked] = useState([]);

  const getinternationalbooked = async () => {
    let res = await axios.get(
      "https://bookingoda.com/api/admin/getbookinternationalpackages"
    );
    if (res.status === 200) {
      //////console.log(res.data);
      setinternationalbooked(res.data.bookedtourpackages);
      ////console.log(res.data.bookedtourpackages,"dasfasd");
    }
  };
  const [staysbooked, setstaysbooked] = useState([]);

  const getstaysbooked = async () => {
    let res = await axios.get(
      "https://bookingoda.com/api/admin/getbookstays"
    );
    if (res.status === 200) {
      //////console.log(res.data);
      setstaysbooked(res.data.bookedstays);
      ////console.log(res.data.bookedstays,"dasfasd");
    }
  };
  useEffect(() => {
    getuser();
    gethotel();
    getapartment();
    getalternative();
    getpil();
    gethome();
    getcar();
    getinternational();
    getstays();
    getcarbooked();
    getinternationalbooked();
 getvendor();
    getstaysbooked();

  }, []);
  const data = [
    {
      type: "Jan",
      sales: 38,
    },
    {
      type: "Feb",
      sales: 52,
    },
    {
      type: "Mar",
      sales: 61,
    },
    {
      type: "Apr",
      sales: 145,
    },
    {
      type: "May",
      sales: 48,
    },
    {
      type: "Jun",
      sales: 38,
    },
    {
      type: "July",
      sales: 38,
    },
    {
      type: "Aug",
      sales: 38,
    },
    {
      type: "Sept",
      sales: 38,
    },
    {
      type: "Oct",
      sales: 38,
    },
    {
      type: "Nov",
      sales: 38,
    },
    {
      type: "Dec",
      sales: 38,
    },
  ];
  const config = {
    data,
    xField: "type",
    yField: "sales",
    color: ({ type }) => {
      return "#2f7ab1";
    },
    label: {
      position: "middle",
      style: {
        fill: "#FFFFFF",
        opacity: 1,
      },
    },
    xAxis: {
      label: {
        autoHide: true,
        autoRotate: false,
      },
    },
    meta: {
      type: {
        alias: "Month",
      },
      sales: {
        alias: "Income",
      },
    },
  };
  const staysofall = [...apartment,...home,...hotel,...alternative]
  const unbooked = staysofall.filter((data) => {
    return !staysbooked.some((item) => item.packageId[0] === data._id);
  });
 //console.log("unbooked ",unbooked)
 
  return (
    <div style={{backgroundColor:'white'}}>
      <h3 className="mt-4 title" style={{marginTop:"40px",}}>Dashboard</h3>
      <div className="d-flex justify-content-around align-items-center gap-3" style={{flexWrap:"wrap"}}>
    
    <Link to="/admindashboard/customers">    <div className="dashboard-card12">
      <div className="card-header12">
        <span className="card-icon12"> <UsergroupAddOutlined
              style={{
                color: "white",
                background: `linear-gradient(to right, #3498db, #5dade2, #85c1e9)`,
                borderRadius: 20,
                fontSize: 24,
                padding: 8,
              }}
            /></span>
        <h3 className="card-title12">Users</h3>
      </div>
      <div className="card-content12">
        <p className="card-value12">{user.length}</p>
      </div>
    </div></Link>
     
    <Link to="/admindashboard/vendors"> 
    <div className="dashboard-card12">
      <div className="card-header12">
        <span className="card-icon12"> <UserSwitchOutlined
              style={{
                color: "white",
                background: `linear-gradient(to right, #3498db, #5dade2, #85c1e9)`,
                borderRadius: 20,
                fontSize: 24,
                padding: 8,
              }}
            /></span>
        <h3 className="card-title12">Vendors</h3>
      </div>
      <div className="card-content12">
        <p className="card-value12">{vendor.length}</p>
      </div>
    </div></Link>
    <Link to="/admindashboard/hotellist"> 
     <div className="dashboard-card12">
      <div className="card-header12">
        <span className="card-icon12"> <BedroomParentOutlined
              style={{
                color: "white",
                background: `linear-gradient(to right, #3498db, #5dade2, #85c1e9)`,
                borderRadius: 20,
                fontSize: 40,
                padding: 8,
              }}
            /></span>
        <h3 className="card-title12">Hotels</h3>
      </div>
      <div className="card-content12">
        <p className="card-value12">{hotel.length}</p>
      </div>
    </div></Link>
    <Link to="/admindashboard/apartmentlist"> 
     <div className="dashboard-card12">
      <div className="card-header12">
        <span className="card-icon12"> <ApartmentOutlined
              style={{
                color: "white",
                background: `linear-gradient(to right, #3498db, #5dade2, #85c1e9)`,
                borderRadius: 20,
                fontSize: 40,
                padding: 8,
              }}
            /></span>
        <h3 className="card-title12">Apartments</h3>
      </div>
      <div className="card-content12">
        <p className="card-value12">{apartment.length}</p>
      </div>
    </div></Link>
    <Link to="/admindashboard/homelist">
    <div className="dashboard-card12">
      <div className="card-header12">
        <span className="card-icon12"> <NightShelterOutlined
              style={{
                color: "white",
                background: `linear-gradient(to right, #3498db, #5dade2, #85c1e9)`,
                borderRadius: 20,
                fontSize: 40,
                padding: 8,
              }}
            /></span>
        <h3 className="card-title12">Homestay</h3>
      </div>
      <div className="card-content12">
        <p className="card-value12">{home.length}</p>
      </div>
    </div>  </Link>
    <Link to="/admindashboard/alternativelist">
    <div className="dashboard-card12">
      <div className="card-header12">
        <span className="card-icon12"> <LocationCityOutlined
              style={{
                color: "white",
                background: `linear-gradient(to right, #3498db, #5dade2, #85c1e9)`,
                borderRadius: 20,
                fontSize: 40,
                padding: 8,
              }}
            /></span>
        <h3 className="card-title12">Alternative Property</h3>
      </div>
      <div className="card-content12">
        <p className="card-value12">{alternative.length}</p>
      </div>
    </div>  
    </Link> 
    <Link to="/admindashboard/carlist">

    <div className="dashboard-card12">
      <div className="card-header12">
        <span className="card-icon12"> <DirectionsCarFilledOutlined
              style={{
                color: "white",
                background: `linear-gradient(to right, #3498db, #5dade2, #85c1e9)`,
                borderRadius: 20,
                fontSize: 40,
                padding: 8,
              }}
            /></span>
        <h3 className="card-title12">Car List</h3>
      </div>
      <div className="card-content12">
        <p className="card-value12">{car.length}</p>
      </div>
    </div>    </Link>
    <Link to="/admindashboard/tourlist">
    <div className="dashboard-card12">
      <div className="card-header12">
        <span className="card-icon12"> <HouseboatOutlined
              style={{
                color: "white",
                background: `linear-gradient(to right, #3498db, #5dade2, #85c1e9)`,
                borderRadius: 20,
                fontSize: 40,
                padding: 8,
              }}
            /></span>
        <h3 className="card-title12">Pilgrim Vacations List</h3>
      </div>
      <div className="card-content12">
        <p className="card-value12">{stays.length}</p>
      </div>
    </div>    </Link>
    <Link to="/admindashboard/international">
    <div className="dashboard-card12">
      <div className="card-header12">
        <span className="card-icon12"> <HolidayVillageOutlined
              style={{
                color: "white",
                background: `linear-gradient(to right, #3498db, #5dade2, #85c1e9)`,
                borderRadius: 20,
                fontSize: 40,
                padding: 8,
              }}
            /></span>
        <h3 className="card-title12">International Holiday List</h3>
      </div>
      <div className="card-content12">
        <p className="card-value12">{international.length}</p>
      </div>
    </div>   </Link>
    <Link to="/admindashboard/pilgrimorder">

    <div className="dashboard-card12">
      <div className="card-header12">
        <span className="card-icon12"> <HouseboatOutlined
              style={{
                color: "white",
                background: `linear-gradient(to right, #3498db, #5dade2, #85c1e9)`,
                borderRadius: 20,
                fontSize: 40,
                padding: 8,
              }}
            /></span>
        <h3 className="card-title12">Booked Pilgrim Vacations List</h3>
      </div>
      <div className="card-content12">
        <p className="card-value12">{pil.length}</p>
      </div>
    </div>   </Link>
    <Link to="/admindashboard/bookinternational">

    <div className="dashboard-card12">
      <div className="card-header12">
        <span className="card-icon12"> <HolidayVillageOutlined
              style={{
                color: "white",
                background: `linear-gradient(to right, #3498db, #5dade2, #85c1e9)`,
                borderRadius: 20,
                fontSize: 40,
                padding: 8,
              }}
            /></span>
        <h3 className="card-title12">Booked International Holiday List</h3>
      </div>
      <div className="card-content12">
        <p className="card-value12">{internationalbooked.length}</p>
      </div>
    </div>   </Link>
    <Link to="/admindashboard/bookcar">
    <div className="dashboard-card12">
      <div className="card-header12">
        <span className="card-icon12"> <DirectionsCarFilledOutlined
              style={{
                color: "white",
                background: `linear-gradient(to right, #3498db, #5dade2, #85c1e9)`,
                borderRadius: 20,
                fontSize: 40,
                padding: 8,
              }}
            /></span>
        <h3 className="card-title12">Booked Car List</h3>
      </div>
      <div className="card-content12">
        <p className="card-value12">{carbooked.length}</p>
      </div>
    </div>    </Link>
    <Link to="/admindashboard/bookstays">

    <div className="dashboard-card12">
      <div className="card-header12">
        <span className="card-icon12"> <LocalHotelOutlined
              style={{
                color: "white",
                background: `linear-gradient(to right, #3498db, #5dade2, #85c1e9)`,
                borderRadius: 20,
                fontSize: 40,
                padding: 8,
              }}
            /></span>
        <h3 className="card-title12">Booked Stays</h3>
      </div>
      
      <div className="card-content12">
        <p className="card-value12">{staysbooked.length}</p>
      </div>
    </div> 
   
    </Link> 
    <Link to="/admindashboard/UnbookedList">  
    <div className="dashboard-card12">
      <div className="card-header12">
        <span className="card-icon12"> <LocalHotelOutlined
              style={{
                color: "white",
                background: `linear-gradient(to right, #3498db, #5dade2, #85c1e9)`,
                borderRadius: 20,
                fontSize: 40,
                padding: 8,
              }}
            /></span>
        <h3 className="card-title12">Un-Booked Stays</h3>
      </div>
      <div className="card-content12">
        <p className="card-value12">{unbooked.length}</p>
      </div>
    </div> </Link>
      </div>
    
      {/* <div className="mt-4">
        <h3 className="mb-5 title">Income Statics</h3>
        <div>
          <Column {...config} />
        </div>
      </div>
      <div className="mt-4">
        <h3 className="mb-5 title">Recent Orders</h3>
        <div>
          <Table columns={columns} dataSource={data1} />
        </div>
      </div> */}
    </div>
  );
};

export default Dashboard;
