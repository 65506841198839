import React from "react";
import "./property.css";
import Apartment from "../../assets/apartments.png";
import Apartments from "../../assets/buildings.png";

import Homestay from "../../assets/homestay.png";
import Hotel from "../../assets/hotel.png";
import VendorHeader from "./Vendorheader";

const SourceLink = () => {
  return (
    <div
      data-capla-component="b-registration-joinapp15min-fe/"
      data-capla-namespace="b-registration-joinapp15min-feaYGUODfA"
    >
      <div id="app-container">
        <div class="error-popup-container"></div>
        <div class="application">
        <VendorHeader />
          <div class="error-popup-container"></div>
          <div class="page-container">
            <div class="screens screens-animation-direction-forward">
              <div
                class="height-100"
                id="automation_id_screen_container_OtaQuestion"
              >
                <div class="ms-container">
                  <div class="form-container">
                    <div class="f2cf178bcd screen-main-header d991e1216a">
                      <div class="f6431b446c e6208ee469 d0caee4251 screen-main-header__title">
                        Where else is your property listed?
                      </div>
                    </div>
                    <div class="ffb9c3d6a3 wide-container spacing--4x">
                      <div class="a53cbfa6de ota-supported-header">
                        If your property is listed on Airbnb, you can speed up
                        registration by importing it directly to Booking-Oda.com.
                      </div>
                      <div class="aca0ade214 ebac6e22e9 cd2e7d62b0">
                        <div class="a53cbfa6de ac9267e216 d8eb520c4e automation_class_otaq_airbnb_input">
                          <input
                            id=":r0:"
                            class="f0b6754c38"
                            type="checkbox"
                            name="airbnb"
                            value=""
                          />
                          <label
                            for=":r0:"
                            class="aca0ade214 aaf30230d9 c2931f4182 d79e71457a bd597ff2d8"
                          >
                            <span class="ff5328eb35"></span>
                            <span class="ef785aa7f4">
                              <span
                                class="fcd9eec8fb b27b51da7f bf9a32efa5"
                                aria-hidden="true"
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 128 128"
                                >
                                  <path d="M56.33 102a6 6 0 0 1-4.24-1.75L19.27 67.54A6.014 6.014 0 1 1 27.74 59l27.94 27.88 44-58.49a6 6 0 1 1 9.58 7.22l-48.17 64a5.998 5.998 0 0 1-4.34 2.39z"></path>
                                </svg>
                              </span>
                            </span>
                            <span class="aaee4e7cd3 e7a57abb1e">
                              <div class="a53cbfa6de e6208ee469">Airbnb</div>
                            </span>
                          </label>
                        </div>
                        <div class="a53cbfa6de ac9267e216 d8eb520c4e automation_class_otaq_tripadvisor_input">
                          <input
                            id=":r1:"
                            class="f0b6754c38"
                            type="checkbox"
                            name="tripadvisor"
                            value=""
                          />
                          <label
                            for=":r1:"
                            class="aca0ade214 aaf30230d9 c2931f4182 d79e71457a bd597ff2d8"
                          >
                            <span class="ff5328eb35"></span>
                            <span class="ef785aa7f4">
                              <span
                                class="fcd9eec8fb b27b51da7f bf9a32efa5"
                                aria-hidden="true"
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 128 128"
                                >
                                  <path d="M56.33 102a6 6 0 0 1-4.24-1.75L19.27 67.54A6.014 6.014 0 1 1 27.74 59l27.94 27.88 44-58.49a6 6 0 1 1 9.58 7.22l-48.17 64a5.998 5.998 0 0 1-4.34 2.39z"></path>
                                </svg>
                              </span>
                            </span>
                            <span class="aaee4e7cd3 e7a57abb1e">
                              <div class="a53cbfa6de e6208ee469">
                                TripAdvisor
                              </div>
                            </span>
                          </label>
                        </div>
                        <div class="a53cbfa6de ac9267e216 d8eb520c4e automation_class_otaq_vrbo_input">
                          <input
                            id=":r2:"
                            class="f0b6754c38"
                            type="checkbox"
                            name="vrbo"
                            value=""
                          />
                          <label
                            for=":r2:"
                            class="aca0ade214 aaf30230d9 c2931f4182 d79e71457a bd597ff2d8"
                          >
                            <span class="ff5328eb35"></span>
                            <span class="ef785aa7f4">
                              <span
                                class="fcd9eec8fb b27b51da7f bf9a32efa5"
                                aria-hidden="true"
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 128 128"
                                >
                                  <path d="M56.33 102a6 6 0 0 1-4.24-1.75L19.27 67.54A6.014 6.014 0 1 1 27.74 59l27.94 27.88 44-58.49a6 6 0 1 1 9.58 7.22l-48.17 64a5.998 5.998 0 0 1-4.34 2.39z"></path>
                                </svg>
                              </span>
                            </span>
                            <span class="aaee4e7cd3 e7a57abb1e">
                              <div class="a53cbfa6de e6208ee469">Vrbo</div>
                            </span>
                          </label>
                        </div>
                        <div class="a53cbfa6de ac9267e216 d8eb520c4e automation_class_otaq_other_input">
                          <input
                            id=":r3:"
                            class="f0b6754c38"
                            type="checkbox"
                            name="other"
                            value=""
                          />
                          <label
                            for=":r3:"
                            class="aca0ade214 aaf30230d9 c2931f4182 d79e71457a bd597ff2d8"
                          >
                            <span class="ff5328eb35"></span>
                            <span class="ef785aa7f4">
                              <span
                                class="fcd9eec8fb b27b51da7f bf9a32efa5"
                                aria-hidden="true"
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 128 128"
                                >
                                  <path d="M56.33 102a6 6 0 0 1-4.24-1.75L19.27 67.54A6.014 6.014 0 1 1 27.74 59l27.94 27.88 44-58.49a6 6 0 1 1 9.58 7.22l-48.17 64a5.998 5.998 0 0 1-4.34 2.39z"></path>
                                </svg>
                              </span>
                            </span>
                            <span class="aaee4e7cd3 e7a57abb1e">
                              <div class="a53cbfa6de e6208ee469">
                                Another website
                              </div>
                            </span>
                          </label>
                        </div>
                        <div class="a53cbfa6de ac9267e216 d8eb520c4e automation_class_otaq_none_input otaq_none_input">
                          <input
                            id=":r4:"
                            class="f0b6754c38"
                            type="checkbox"
                            name="none"
                            value=""
                          />
                          <label
                            for=":r4:"
                            class="aca0ade214 aaf30230d9 c2931f4182 d79e71457a bd597ff2d8"
                          >
                            <span class="ff5328eb35"></span>
                            <span class="ef785aa7f4">
                              <span
                                class="fcd9eec8fb b27b51da7f bf9a32efa5"
                                aria-hidden="true"
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 128 128"
                                >
                                  <path d="M56.33 102a6 6 0 0 1-4.24-1.75L19.27 67.54A6.014 6.014 0 1 1 27.74 59l27.94 27.88 44-58.49a6 6 0 1 1 9.58 7.22l-48.17 64a5.998 5.998 0 0 1-4.34 2.39z"></path>
                                </svg>
                              </span>
                            </span>
                            <span class="aaee4e7cd3 e7a57abb1e">
                              <div class="a53cbfa6de e6208ee469">
                                My property isn't listed on any other websites
                              </div>
                            </span>
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="form-cta">
                    <div>
                      <div class="form-buttons-container">
                        <div class="form-buttons-container--secondary" onClick={()=>window.history.back()
}  >
                          <button
                            aria-label="Back to previous step"
                            data-testid="FormButtonSecondary"
                            type="button"
                            class="a83ed08757 c21c56c305 bf0537ecb5 d691166b09 f671049264 d2529514af af7297d90d"
                          >
                            <span class="eedba9e88a">
                              <span
                                class="fcd9eec8fb c2cc050fb8"
                                aria-hidden="true"
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 24 24"
                                  data-rtl-flip="true"
                                >
                                  <path d="M15.0871 19.2361C14.9677 19.2367 14.8495 19.2134 14.7392 19.1676C14.629 19.1219 14.5289 19.0546 14.4451 18.9696L8.38799 12.9125C8.26332 12.7891 8.16475 12.6418 8.09815 12.4795C8.03156 12.3172 7.99829 12.1431 8.00034 11.9676C7.99246 11.6171 8.12303 11.2776 8.36376 11.0227L14.4208 4.96566C14.5912 4.79552 14.8221 4.69995 15.0629 4.69995C15.3037 4.69995 15.5346 4.79552 15.7049 4.96566C15.7907 5.04918 15.8588 5.14902 15.9053 5.25929C15.9518 5.36957 15.9758 5.48803 15.9758 5.60771C15.9758 5.72739 15.9518 5.84586 15.9053 5.95613C15.8588 6.0664 15.7907 6.16624 15.7049 6.24976L10.0113 11.9676L15.7292 17.6855C15.8149 17.769 15.883 17.8689 15.9295 17.9792C15.976 18.0894 16 18.2079 16 18.3276C16 18.4473 15.976 18.5657 15.9295 18.676C15.883 18.7863 15.8149 18.8861 15.7292 18.9696C15.6457 19.0551 15.5458 19.1228 15.4354 19.1686C15.3251 19.2144 15.2066 19.2374 15.0871 19.2361Z"></path>
                                </svg>
                              </span>
                            </span>
                          </button>
                        </div>
                        <div class="form-buttons-container--primary" onClick={()=>window.location.assign("/propertyname")}>
                        <button
                            aria-label="continue"
                            data-testid="FormButtonPrimary-enabled"
                            type="button"
                            class="a83ed08757 c21c56c305 a4c1805887 f671049264 d2529514af c082d89982"
                          >
                            <span class="e4adce92df">
                              <font style={{verticalAlign: "inherit"}}>
                                <font style={{verticalAlign: "inherit"}}>
                                  continue
                                </font>
                              </font>
                            </span>
                          </button>
                       
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            id="automation_id_flow_Segmentation_Flow"
            style={{ display: "none" }}
          ></div>
          <div></div>
        </div>
      </div>
      <img
        alt=""
        height="1"
        width="1"
        style={{ display: "none" }}
        src="https://booking.com/track_join_app_landing?progress_status=2"
      />
    </div>
  );
};

export default SourceLink;
