import Footer from "../footer/Footer";
import React, { useEffect, useState } from "react";
import { FaArrowRight, FaArrowUp, FaLongArrowAltUp } from "react-icons/fa";
import { Carousel } from "react-bootstrap";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import "./homecar.css";
import { SlCalender } from "react-icons/sl";
import { GrMapLocation } from "react-icons/gr";
import Navbar from "../navbar/Navbar";
import axios from "axios";
import Icon1 from "../../assets/car-parts-01.svg";
import Icon2 from "../../assets/car-parts-02.svg";
import Icon3 from "../../assets/car-parts-03.svg";
import Icon4 from "../../assets/car-parts-04.svg";
import Icon5 from "../../assets/car-parts-05.svg";
import Icon6 from "../../assets/car-parts-06.svg";
import { Link } from "react-router-dom";
function Listofcars() {
  const options = {
    loop: true,
    margin: 10,
    responsive: {
      0: {
        items: 1,
      },
      600: {
        items: 2,
      },
      1000: {
        items: 3,
      },
    },
  };
  const [car, setcar] = useState([]);
  const getcar = async () => {
    let res = await axios.get("https://bookingoda.com/api/admin/getallCar");
    if (res.status === 200) {
      ////console.log(res.data.Car);
      setcar(res.data.Car.filter((item) => item.blockstatus !== true));
    }
  };
  useEffect(() => {
    getcar();
  }, []);
  const itemsPerPage = 4; // Number of cards per page
  const [currentPage, setCurrentPage] = useState(1);
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;

  // Get the current page's items
  const currentItems = car.slice(startIndex, endIndex);

  // Handle page change
  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };
  const [carType, setCarType] = useState("");
  const handleCar = (e) => {
    setCarType(e.target.value);
  };
  const [selectedCarModels, setSelectedCarModels] = useState([]);
  const [selectedYear, setSelectedYear] = useState([]);

  const [filteredCars, setFilteredCars] = useState([]);

  useEffect(() => {
    // Filter cars based on the selected car models and selected year
    const filtered = car.filter((data) =>
      selectedCarModels.includes(data.CarModel) || selectedYear.includes(data.FuelType)
    );
    setFilteredCars(filtered);
  }, [selectedCarModels, selectedYear, car]);
  
  const handleCheckboxChange = (event) => {
    const { value, checked } = event.target;

    // Update selected car models based on checkbox state
    setSelectedCarModels((prevModels) =>
      checked ? [...prevModels, value] : prevModels.filter((model) => model !== value)
    );
  };
  const handleCheckboxChange1 = (event) => {
    const { value, checked } = event.target;

    // Update selected car models based on checkbox state
    setSelectedYear((prevModels) =>
      checked ? [...prevModels, value] : prevModels.filter((model) => model !== value)
    );
  };
  return (
    <>
      <Navbar />
      <div className="breadcrumb-barcar">
        <div className="containercar">
          <div className="rowcar align-items-center text-center">
            <div className="col-md-12 col-12">
              <h2 className="breadcrumb-title">Car Listings</h2>
              <nav aria-label="breadcrumbcar" className="page-breadcrumbcar">
                <ol className="breadcrumbcar">
                  <li className="breadcrumb-itemcar">
                    <a href="/carrentals">Home</a>
                  </li>
                 
                  <li className="breadcrumb-itemcar active" aria-current="page">
                    Car Listings
                  </li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
      </div>
      <section className="section car-listing" style={{ transform: "none" }}>
        <div className="container" style={{ transform: "none" }}>
          <div className="rowcar" style={{ transform: "none" }}>
            <div
              className="col-lg-3 col-12 theiaStickySidebar"
              style={{
                position: "relative",
                overflow: "visible",
                boxSizing: "border-box",
                minHeight: "1px",
              }}
            >
              <div
                className="theiaStickySidebar"
                style={{
                  paddingTop: "0px",
                  paddingBottom: "1px",
                  position: "static",
                  transform: "none",
                  top: "0px",
                  left: "73.6667px",
                }}
              >
                <form action="#" autoComplete="off" className="sidebar-form">
                  {/* Customer */}
                  <div className="sidebar-heading">
                    <h3>What Are You Looking For</h3>
                  </div>
                  <div className="product-search">
                    <div className="form-custom">
                      {/* <input
                        type="text"
                        className="form-control"
                        id="member_search1"
                        placeholder="Car name"
                      /> */}
                      <select
                        value={carType}
                        onChange={handleCar}
                        style={{ appearance: "none" }}
                      >
                        <option value="">Select your car type</option>
                        {car.map((data) => (
                          <option value={data._id}>{data.CarName}</option>
                        ))}
                      </select>
                      <span>
                        <img
                          src="https://dreamsrent.dreamstechnologies.com/html/assets/img/icons/search.svg"
                          alt="img"
                        />
                      </span>
                    </div>
                  </div>
                  <div className="accordion" id="accordionMain1">
                    <div className="card-header-new" id="headingOne">
                      <h6 className="filter-title">
                        <a
                          href="javascript:void(0);"
                          className="w-100"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseOne"
                          aria-expanded="true"
                          aria-controls="collapseOne"
                        >
                          Car Model
                          {/* <span className="float-end">
                            <i className="fa-solid fa-chevron-down" />
                          </span> */}
                        </a>
                      </h6>
                    </div>
                    <div
                      id="collapseOne"
                      className="collapse show"
                      aria-labelledby="headingOne"
                      data-bs-parent="#accordionExample1"
                    >
                      <div className="card-body-chat">
                        <div className="rowcar">
                          <div className="col-md-12">
                            <div id="checkBoxes1">
                            {[...new Set(car.map((data) => data.CarModel))].map((model) => (
        <div className="selectBox-cont" key={model}>
          <label className="custom_check w-100">
            <input
              type="checkbox"
              name="carModelCheckbox"
              value={model}
              onChange={handleCheckboxChange}
              checked={selectedCarModels.includes(model)}
            />
            <span className="checkmark" /> {model}
          </label>
        </div>
      ))}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* /Customer */}
             
                    <div className="accordion" id="accordionMain2">
                    <div className="card-header-new" id="headingTwo">
                      <h6 className="filter-title">
                        <a
                          href="javascript:void(0);"
                          className="w-100 collapsed"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseTwo"
                          aria-expanded="true"
                          aria-controls="collapseTwo"
                        >
                         Fuel Type
                          <span className="float-end">
                            <i className="fa-solid fa-chevron-down" />
                          </span>
                        </a>
                      </h6>
                    </div>
                    <div
                      id="collapseTwo"
                      className="collapse show"
                      aria-labelledby="headingTwo"
                      data-bs-parent="#accordionExample2"
                    >
                      <div className="card-body-chat">
                        <div id="checkBoxes2">
                        {[...new Set(car.map((data) => data.FuelType))].map((model) => (
        <div className="selectBox-cont" key={model}>
          <label className="custom_check w-100">
            <input
              type="checkbox"
              name="carModelCheckbox"
              value={model}
              onChange={handleCheckboxChange1}
              checked={selectedYear.includes(model)}
            />
            <span className="checkmark" /> {model}
          </label>
        </div>
      ))}
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* By Status */}
                </form>
                <div
                  className="resize-sensor"
                  style={{
                    position: "absolute",
                    inset: "0px",
                    overflow: "hidden",
                    zIndex: -1,
                    visibility: "hidden",
                  }}
                >
                  <div
                    className="resize-sensor-expand"
                    style={{
                      position: "absolute",
                      left: 0,
                      top: 0,
                      right: 0,
                      bottom: 0,
                      overflow: "hidden",
                      zIndex: -1,
                      visibility: "hidden",
                    }}
                  >
                    <div
                      style={{
                        position: "absolute",
                        left: "0px",
                        top: "0px",
                        transition: "all 0s ease 0s",
                        width: "295px",
                        height: "3066px",
                      }}
                    />
                  </div>
                  <div
                    className="resize-sensor-shrink"
                    style={{
                      position: "absolute",
                      left: 0,
                      top: 0,
                      right: 0,
                      bottom: 0,
                      overflow: "hidden",
                      zIndex: -1,
                      visibility: "hidden",
                    }}
                  >
                    <div
                      style={{
                        position: "absolute",
                        left: 0,
                        top: 0,
                        transition: "0s",
                        width: "200%",
                        height: "200%",
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-9">
              <div className="rowcar">
                {/* col */}
                {carType !== ""
                  ? currentItems
                      .filter((data) => data._id === carType)
                      .map((item) => (
                        <div className="col-xl-6 col-lg-6 col-md-6 col-12">
                          <div className="listing-itemcar">
                            <div className="listing-img">
                            <Link  to="/Carrentaldetails" state={{car:item}}>
                                <img
                                  src={`https://bookingoda.com/image/${item?.Photos[0]?.file}`}
                                  className="img-fluid"
                                  alt="Toyota"
                                  style={{ width: "400px", height: "250px" }}
                                />
                              </Link>
                              <div className="fav-item">
                                <span className="featured-text">
                                  {item.CarModel}
                                </span>
                              </div>
                            </div>
                            <div className="listing-content">
                              <div className="listing-features">
                                <h3 className="listing-title">
                                <Link  to="/Carrentaldetails" state={{car:item}}>{item.CarName}</Link>
                                </h3>
                              </div>
                              <div className="listing-details-group">
                                <ul>
                                  <li>
                                    <span>
                                      <img src={Icon1} alt="Auto" />
                                    </span>
                                    <p>{item.TransmissionType}</p>
                                  </li>
                                  <li>
                                    <span>
                                      <img src={Icon2} alt="10 KM" />
                                    </span>
                                    <p>{item.Mileage} KM/ltr</p>
                                  </li>
                                  <li>
                                    <span>
                                      <img src={Icon3} alt="Petrol" />
                                    </span>
                                    <p>{item.FuelType}</p>
                                  </li>
                                </ul>
                                <ul>
                                  <li>
                                    <span>
                                      <img src={Icon4} alt="Power" />
                                    </span>
                                    <p>{item.Enginetype.slice(0,7)+"..."}</p>
                                  </li>
                                  <li>
                                    <span>
                                      <img src={Icon5} alt={2018} />
                                    </span>
                                    <p>{item.YearofManufacture}</p>
                                  </li>
                                  <li>
                                    <span>
                                      <img src={Icon6} alt="Persons" />
                                    </span>
                                    <p>{item.Seats} Seats</p>
                                  </li>
                                </ul>
                              </div>
                              <div className="listing-location-details">
                                {/* <div className="listing-price">
                          <span>
                            <GrMapLocation />
                          </span>
                          Germany
                        </div> */}
                                <div
                                  className="listing-price"
                                  style={{ alignSelf: "center" }}
                                >
                                  <h6>
                                    &#8377; {item.Price} <span>/ Per km</span>
                                  </h6>
                                </div>
                              </div>
                              <div className="listing-button">
                              <Link  to="/Carrentaldetails" state={{car:item}}>  <a className="btn btn-order"><span>   <span>
                            <SlCalender className='me-2' />
                          </span></span>Rent Now</a></Link>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))
                  :filteredCars.length > 0 ? filteredCars.map((item) => (
                      <div className="col-xl-6 col-lg-6 col-md-6 col-12">
                        <div className="listing-itemcar">
                          <div className="listing-img">
                          <Link  to="/Carrentaldetails" state={{car:item}}>
                              <img
                                src={`https://bookingoda.com/image/${item?.Photos[0]?.file}`}
                                className="img-fluid"
                                alt="Toyota"
                                style={{ width: "400px", height: "250px" }}
                              />
                            </Link>
                            <div className="fav-item">
                              <span className="featured-text">
                                {item.CarModel}
                              </span>
                            </div>
                          </div>
                          <div className="listing-content">
                            <div className="listing-features">
                              <h3 className="listing-title">
                              <Link  to="/Carrentaldetails" state={{car:item}}>{item.CarName}</Link>
                              </h3>
                            </div>
                            <div className="listing-details-group">
                              <ul>
                                <li>
                                  <span>
                                    <img src={Icon1} alt="Auto" />
                                  </span>
                                  <p>{item.TransmissionType}</p>
                                </li>
                                <li>
                                  <span>
                                    <img src={Icon2} alt="10 KM" />
                                  </span>
                                  <p>{item.Mileage} KM/ltr</p>
                                </li>
                                <li>
                                  <span>
                                    <img src={Icon3} alt="Petrol" />
                                  </span>
                                  <p>{item.FuelType}</p>
                                </li>
                              </ul>
                              <ul>
                                <li>
                                  <span>
                                    <img src={Icon4} alt="Power" />
                                  </span>
                                  <p>{item.Enginetype.slice(0,7)+"..."}</p>
                                </li>
                                <li>
                                  <span>
                                    <img src={Icon5} alt={2018} />
                                  </span>
                                  <p>{item.YearofManufacture}</p>
                                </li>
                                <li>
                                  <span>
                                    <img src={Icon6} alt="Persons" />
                                  </span>
                                  <p>{item.Seats} Seats</p>
                                </li>
                              </ul>
                            </div>
                            <div className="listing-location-details">
                              {/* <div className="listing-price">
                          <span>
                            <GrMapLocation />
                          </span>
                          Germany
                        </div> */}
                              <div
                                className="listing-price"
                                style={{ alignSelf: "center" }}
                              >
                                <h6>
                                  &#8377; {item.Price} <span>/ Per km</span>
                                </h6>
                              </div>
                            </div>
                            <div className="listing-button">
                            <Link  to="/Carrentaldetails" state={{car:item}}>  <a className="btn btn-order"><span>   <span>
                            <SlCalender className='me-2' />
                          </span></span>Rent Now</a></Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    )) : currentItems.map((item) => (
                      <div className="col-xl-6 col-lg-6 col-md-6 col-12">
                        <div className="listing-itemcar">
                          <div className="listing-img">
                          <Link  to="/Carrentaldetails" state={{car:item}}>
                              <img
                                src={`https://bookingoda.com/image/${item?.Photos[0]?.file}`}
                                className="img-fluid"
                                alt="Toyota"
                                style={{ width: "400px", height: "250px" }}
                              />
                            </Link>
                            <div className="fav-item">
                              <span className="featured-text">
                                {item.CarModel}
                              </span>
                            </div>
                          </div>
                          <div className="listing-content">
                            <div className="listing-features">
                              <h3 className="listing-title">
                              <Link  to="/Carrentaldetails" state={{car:item}}>{item.CarName}</Link>
                              </h3>
                            </div>
                            <div className="listing-details-group">
                              <ul>
                                <li>
                                  <span>
                                    <img src={Icon1} alt="Auto" />
                                  </span>
                                  <p>{item.TransmissionType}</p>
                                </li>
                                <li>
                                  <span>
                                    <img src={Icon2} alt="10 KM" />
                                  </span>
                                  <p>{item.Mileage} KM/ltr</p>
                                </li>
                                <li>
                                  <span>
                                    <img src={Icon3} alt="Petrol" />
                                  </span>
                                  <p>{item.FuelType}</p>
                                </li>
                              </ul>
                              <ul>
                                <li>
                                  <span>
                                    <img src={Icon4} alt="Power" />
                                  </span>
                                  <p>{item.Enginetype.slice(0,7)+"..."}</p>
                                </li>
                                <li>
                                  <span>
                                    <img src={Icon5} alt={2018} />
                                  </span>
                                  <p>{item.YearofManufacture}</p>
                                </li>
                                <li>
                                  <span>
                                    <img src={Icon6} alt="Persons" />
                                  </span>
                                  <p>{item.Seats} Seats</p>
                                </li>
                              </ul>
                            </div>
                            <div className="listing-location-details">
                              {/* <div className="listing-price">
                          <span>
                            <GrMapLocation />
                          </span>
                          Germany
                        </div> */}
                              <div
                                className="listing-price"
                                style={{ alignSelf: "center" }}
                              >
                                <h6>
                                  &#8377; {item.Price} <span>/ Per km</span>
                                </h6>
                              </div>
                            </div>
                            <div className="listing-button">
                            <Link  to="/Carrentaldetails" state={{car:item}}>  <a className="btn btn-order"><span>   <span>
                            <SlCalender className='me-2' />
                          </span></span>Rent Now</a></Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
              </div>
              {/*Pagination*/}
              <div className="blog-pagination">
                <nav>
                  <ul className="pagination page-item justify-content-center">
                    {Array.from({
                      length: Math.ceil(car.length / itemsPerPage),
                    }).map((_, index) => (
                      <li
                        key={index}
                        className={`previtem ${
                          currentPage === index + 1 ? "active" : ""
                        }`}
                      >
                        <button
                          className="page-link"
                          onClick={() => handlePageChange(index + 1)}
                        >
                          <i className="fas fa-regular fa-arrow-right me-2" />{" "}
                          {index + 1}
                        </button>
                      </li>
                    ))}
                  </ul>
                </nav>
              </div>

              {/* /Pagination */}
              {/* <nav>
        <ul className="pagination">
          {Array.from({ length: Math.ceil(car.length / itemsPerPage) }).map((_, index) => (
            <li key={index} className={`page-item ${currentPage === index + 1 ? 'active' : ''}`}>
              <button className="page-link" onClick={() => handlePageChange(index + 1)}>
                {index + 1}
              </button>
            </li>
          ))}
        </ul>
      </nav> */}
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}

export default Listofcars;
