import React, { useEffect, useState } from "react";
import "./property.css";
import Apartment from "../../assets/apartments.png";
import Apartments from "../../assets/buildings.png";

import Homestay from "../../assets/homestay.png";
import Hotel from "../../assets/hotel.png";
import VendorHeader from "./Vendorheader";
import { useLocation, useNavigate } from "react-router-dom";

const Propertyname = () => {
  const {state}=useLocation();
  ////console.log(state);

  const [propertyname, setpropertyname] = useState("");

  const history=useNavigate()
  const HandleClick = () =>{
    if (propertyname === "") {
      alert("Please enter property name")
    } else {
    history("/propertylocation",sessionStorage.setItem("propertyname",  JSON.stringify(propertyname)))
  }
}
useEffect(() => {
  const storedPropertyName = sessionStorage.getItem('propertyname');
  if (storedPropertyName) {
    setpropertyname(JSON.parse(storedPropertyName));
  }
}, []);
  return (
    <div
      data-capla-component="b-registration-joinapp15min-fe/"
      data-capla-namespace="b-registration-joinapp15min-feaYGUODfA"
    >
      <div id="app-container">
        <div class="error-popup-container"></div>
        <div class="application">
        <VendorHeader />
          <div class="error-popup-container"></div>
          <div class="page-container">
            <div class="screens screens-animation-direction-forward">
              <div class="height-100" id="automation_id_screen_container_Name">
                <div class="ms-container">
                  <div class="form-container form-container--mobile-flex">
                    <div class="f2cf178bcd screen-main-header d991e1216a">
                      <div class="f6431b446c e6208ee469 d0caee4251 screen-main-header__title">
                        What's the name of your place?
                      </div>
                    </div>
                    <div class="page-tooltip__container page-tooltip__container-main page-tooltip__container--last">
                      <div class="spacing--4x">
                        <section
                          aria-labelledby=":rl:"
                          class="c82435a4b8 a178069f51 a6ae3c2b40 a18aeea94d d794b7a0f7 f53e278e95"
                          style={{ padding: "4px" }}
                        >
                          <div class="aca0ade214 ebac6e22e9 c2931f4182 d79e71457a">
                            <span class="d963552ea0">
                              <span
                                class="fcd9eec8fb c2cc050fb8"
                                aria-hidden="true"
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 24 24"
                                >
                                  <path d="M5.239 22.5c.461.008.92.076 1.363.202l3.634 1.038c.602.172 1.226.26 1.853.26h4.885a6.75 6.75 0 0 0 6.716-6.075l.303-5.131a5.252 5.252 0 0 0-3.967-5.365l-1.477-.324A.745.745 0 0 1 18 6.382V3a3 3 0 1 0-6 0v1.554a6.75 6.75 0 0 1-6.75 6.75.75.75 0 0 0-.75.75v9.697c0 .41.329.744.739.75zm.022-1.499l-.011.75H6v-9.697l-.75.75a8.25 8.25 0 0 0 8.25-8.25V3a1.5 1.5 0 0 1 3 0v3.382a2.25 2.25 0 0 0 1.709 2.184l1.475.323a3.745 3.745 0 0 1 2.811 3.823l-.3 5.094c-.267 2.652-2.525 4.694-5.221 4.694h-4.885a5.25 5.25 0 0 1-1.44-.202L7.013 21.26A6.747 6.747 0 0 0 5.261 21zM.75 10.5h4.5l-.75-.75v13.5l.75-.75H.75l.75.75V9.75l-.75.75zm0-1.5a.75.75 0 0 0-.75.75v13.5c0 .414.336.75.75.75h4.5a.75.75 0 0 0 .75-.75V9.75A.75.75 0 0 0 5.25 9H.75z"></path>
                                </svg>
                              </span>
                            </span>
                            <div class="aaee4e7cd3 e7a57abb1e">
                              <div class="aca0ade214 aaf30230d9 cd2e7d62b0">
                                <div class="ffd93a9ecb">
                                  <h3 id=":rl:" class="e1eebb6a1e e0a7dcceb5">
                                    What should I consider when choosing a name?
                                  </h3>
                                  {/* <div class="abcc616ec7 cc1b961f14 c180176d40 f11eccb5e8">
                                    <button
                                      aria-label="Close banner"
                                      type="button"
                                      class="a83ed08757 c21c56c305 f38b6daa18 d691166b09 ab98298258 deab83296e f4552b6561"
                                    >
                                      <span class="eedba9e88a">
                                        <span
                                          class="fcd9eec8fb bf9a32efa5"
                                          aria-hidden="true"
                                        >
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 24 24"
                                          >
                                            <path d="M13.31 12l6.89-6.89a.93.93 0 1 0-1.31-1.31L12 10.69 5.11 3.8A.93.93 0 0 0 3.8 5.11L10.69 12 3.8 18.89a.93.93 0 0 0 1.31 1.31L12 13.31l6.89 6.89a.93.93 0 1 0 1.31-1.31z"></path>
                                          </svg>
                                        </span>
                                      </span>
                                    </button>
                                  </div> */}
                                </div>
                                <div class="a53cbfa6de">
                                  <ul class="property-name-tooltip-list">
                                    <li>Keep it short and catchy</li>
                                    <li>Avoid abbreviations</li>
                                    <li>Stick to the facts</li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </div>
                        </section>
                      </div>
                      <div class="spacing--4x">
                        <section
                          aria-labelledby=":rm:"
                          class="c82435a4b8 a178069f51 a6ae3c2b40 a18aeea94d d794b7a0f7 f53e278e95"
                          style={{ padding: "4px" }}
                        >
                          <div class="aca0ade214 ebac6e22e9 c2931f4182 d79e71457a">
                            <span class="d963552ea0">
                              <span
                                class="fcd9eec8fb c2cc050fb8"
                                aria-hidden="true"
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 24 24"
                                >
                                  <path d="M12.75 2.251v-1.5a.75.75 0 0 0-1.5 0v1.5a.75.75 0 0 0 1.5 0zm6.144 3.167l1.061-1.06a.75.75 0 0 0-1.06-1.061l-1.061 1.06a.75.75 0 0 0 1.06 1.061zM21 12.001h1.5a.75.75 0 0 0 0-1.5H21a.75.75 0 0 0 0 1.5zm-3.166 6.144l1.06 1.061a.75.75 0 1 0 1.061-1.06l-1.06-1.061a.75.75 0 1 0-1.061 1.06zM6.166 4.358l-1.06-1.061a.75.75 0 0 0-1.061 1.06l1.06 1.061a.75.75 0 0 0 1.061-1.06zM3 10.5H1.5a.75.75 0 0 0 0 1.5H3a.75.75 0 0 0 0-1.5zm2.106 6.584l-1.061 1.06a.75.75 0 1 0 1.06 1.061l1.061-1.06a.75.75 0 1 0-1.06-1.061zm3.144-.636v2.3a3.75 3.75 0 1 0 7.5 0v-2.3a.75.75 0 0 0-1.5 0v2.3a2.25 2.25 0 0 1-4.5 0v-2.3a.75.75 0 0 0-1.5 0zm9-5.198a5.25 5.25 0 1 1-10.5 0 5.25 5.25 0 0 1 10.5 0zm1.5 0a6.75 6.75 0 1 0-13.5 0 6.75 6.75 0 0 0 13.5 0zm-7.5 10.5v1.5a.75.75 0 0 0 1.5 0v-1.5a.75.75 0 0 0-1.5 0z"></path>
                                </svg>
                              </span>
                            </span>
                            <div class="aaee4e7cd3 e7a57abb1e">
                              <div class="aca0ade214 aaf30230d9 cd2e7d62b0">
                                <div class="ffd93a9ecb">
                                  <h3 id=":rm:" class="e1eebb6a1e e0a7dcceb5">
                                    Why do I need to name my property?
                                  </h3>
                                  {/* <div class="abcc616ec7 cc1b961f14 c180176d40 f11eccb5e8">
                                    <button
                                      aria-label="Close banner"
                                      type="button"
                                      class="a83ed08757 c21c56c305 f38b6daa18 d691166b09 ab98298258 deab83296e f4552b6561"
                                    >
                                      <span class="eedba9e88a">
                                        <span
                                          class="fcd9eec8fb bf9a32efa5"
                                          aria-hidden="true"
                                        >
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 24 24"
                                          >
                                            <path d="M13.31 12l6.89-6.89a.93.93 0 1 0-1.31-1.31L12 10.69 5.11 3.8A.93.93 0 0 0 3.8 5.11L10.69 12 3.8 18.89a.93.93 0 0 0 1.31 1.31L12 13.31l6.89 6.89a.93.93 0 1 0 1.31-1.31z"></path>
                                          </svg>
                                        </span>
                                      </span>
                                    </button>
                                  </div> */}
                                </div>
                                <div class="a53cbfa6de">
                                  This is the name that will appear as the title
                                  of your listing on our site. It should tell
                                  guests something specific about your place,
                                  where it is or what you offer.This will be
                                  visible to anyone visiting our site, so don't
                                  include your address in the name.
                                </div>
                              </div>
                            </div>
                          </div>
                        </section>
                      </div>
                    </div>
                    <div class="ffb9c3d6a31 wide-container min-height">
                      <div class="aca0ade214 ebac6e22e9 f66f916626 c2931f4182 c27e5d305d db150fece4">
                        <div class="b817090550 b736e9e3f4">
                          <div class="form-group">
                            <div class="a53cbfa6de ac9267e216 a20293ec70">
                              <label
                                for="idproperty_name"
                                class="a53cbfa6de e6208ee469 f555271986"
                              >
                                <span>
                                  <span class="ccb65902b2">Property name</span>
                                </span>
                              </label>
                              <div class="b9b84f4305">
                                <div class="e000754250">
                                  <input
                                    name="property_name"
                                    class="eb46370fe1"
                                    data-testid="PropertyName-"
                                    id="idproperty_name"
                                    value={propertyname}
                                    onChange={(e)=>setpropertyname(e.target.value)}
                                  />
                                  <div class="e7e5251f68"></div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="form-cta">
                    <div>
                      <div class="form-buttons-container">
                        <div class="form-buttons-container--secondary" onClick={()=>window.history.back()
}  >
                          <button
                            aria-label="Back to previous step"
                            data-testid="FormButtonSecondary"
                            type="button"
                            class="a83ed08757 c21c56c305 bf0537ecb5 d691166b09 f671049264 d2529514af af7297d90d"
                          >
                            <span class="eedba9e88a">
                              <span
                                class="fcd9eec8fb c2cc050fb8"
                                aria-hidden="true"
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 24 24"
                                  data-rtl-flip="true"
                                >
                                  <path d="M15.0871 19.2361C14.9677 19.2367 14.8495 19.2134 14.7392 19.1676C14.629 19.1219 14.5289 19.0546 14.4451 18.9696L8.38799 12.9125C8.26332 12.7891 8.16475 12.6418 8.09815 12.4795C8.03156 12.3172 7.99829 12.1431 8.00034 11.9676C7.99246 11.6171 8.12303 11.2776 8.36376 11.0227L14.4208 4.96566C14.5912 4.79552 14.8221 4.69995 15.0629 4.69995C15.3037 4.69995 15.5346 4.79552 15.7049 4.96566C15.7907 5.04918 15.8588 5.14902 15.9053 5.25929C15.9518 5.36957 15.9758 5.48803 15.9758 5.60771C15.9758 5.72739 15.9518 5.84586 15.9053 5.95613C15.8588 6.0664 15.7907 6.16624 15.7049 6.24976L10.0113 11.9676L15.7292 17.6855C15.8149 17.769 15.883 17.8689 15.9295 17.9792C15.976 18.0894 16 18.2079 16 18.3276C16 18.4473 15.976 18.5657 15.9295 18.676C15.883 18.7863 15.8149 18.8861 15.7292 18.9696C15.6457 19.0551 15.5458 19.1228 15.4354 19.1686C15.3251 19.2144 15.2066 19.2374 15.0871 19.2361Z"></path>
                                </svg>
                              </span>
                            </span>
                          </button>
                        </div>
                        <div class="form-buttons-container--primary" onClick={HandleClick}>
                        <button
                            aria-label="continue"
                            data-testid="FormButtonPrimary-enabled"
                            type="button"
                            class="a83ed08757 c21c56c305 a4c1805887 f671049264 d2529514af c082d89982"
                          >
                            <span class="e4adce92df">
                              <font style={{verticalAlign: "inherit"}}>
                                <font style={{verticalAlign: "inherit"}}>
                                  continue
                                </font>
                              </font>
                            </span>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            id="automation_id_flow_Segmentation_Flow"
            style={{ display: "none" }}
          ></div>
          <div></div>
        </div>
      </div>
      <img
        alt=""
        height="1"
        width="1"
        style={{ display: "none" }}
        src="https://booking.com/track_join_app_landing?progress_status=2"
      />
    </div>
  );
};

export default Propertyname;
