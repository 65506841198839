import Header from "../../components/header/Header";
import Navbar from "../../components/navbar/Navbar";
import Featured from "../../components/featured/Featured";
import "./home.css";
import PropertyList from "../../components/propertyList/PropertyList";
import FeaturedProperties from "../../components/featuredProperties/FeaturedProperties";
import MailList from "../../components/mailList/MailList";
import Footer from "../../components/footer/Footer";
import axios from "axios";
import { useEffect, useState } from "react";
import Carousel from "react-bootstrap/Carousel";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { CiDiscount1 } from "react-icons/ci";

import {
  faBed,
  faCalendarDays,
  faCar,
  faPerson,
  faPlane,
  faTaxi,
  faHouse,
  faHotel,
  faEarthAmericas,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import Logo from "../../assets/booking.jpeg";
import logos from "../../assets/addProperty.jpg";
import { Link, useNavigate } from "react-router-dom";
import QuickAndEasyTripPlan from "../../components/QuickAndEasyTripPlan";
const Home = () => {
  const navigate = useNavigate();

  const [currentIndex, setCurrentIndex] = useState(0);

  const catresponsive = {
    0: {
      items: 1,
    },
    600: {
      items: 1,
    },
    1000: {
      items: 2,
    },
  };

  const rsa = () => {
    navigate("/rsa");
  };
  const handleSearch4 = () => {
    navigate("/resort");
  };
  const handleSearch2 = () => {
    navigate("/Attraction");
  };
  const handleSearch5 = () => {
    navigate("/international");
  };
  const [advertise, setadvertise] = useState([]);
  const getadvertise = async () => {
    let res = await axios.get(
      "https://bookingoda.com/api/admin/getalladvertise"
    );
    if ((res.status = 200)) {
      ////console.log(res);
      setadvertise(res.data?.advertise);
    }
  };
  useEffect(() => {
    // getcountry();
    getadvertise();
  }, []);

  const filteredAdverts = advertise.filter((item) => {
    const currentDate = new Date(); // Assuming date is a JavaScript Date object
    const startingDate = new Date(item.startingdate);
    const validityDate = new Date(item.validity);

    return startingDate <= currentDate && currentDate <= validityDate;
  });

  //console.log(filteredAdverts);
  const carouselInterval = 3000; // Set the delay in milliseconds

  // Handle changing the current index to move to the next item
  const handleSlideChange = (index) => {
    setCurrentIndex(index);
  };
  const [advertiseList, setadvertiseList] = useState([]);
  const getadvertiseList = async () => {
    let res = await axios.get(
      "https://bookingoda.com/api/admin/getalladvertiseList"
    );
    if ((res.status = 200)) {
      //console.log(res);
      setadvertiseList(res.data?.advertiseList);
    }
  };
  const [offers, setoffers] = useState([]);
  const getoffers = async () => {
    let res = await axios.get("https://bookingoda.com/api/admin/getalloffers");
    if ((res.status = 200)) {
      //console.log(res);
      setoffers(res.data?.offers);
    }
  };
  useEffect(() => {
    // getcountry();
    getadvertise();
    getadvertiseList();
    getoffers();
  }, []);
  const filteredAdvertsList = advertiseList.filter((item) => {
    const currentDate = new Date();

    const year = currentDate.getFullYear();
    const month = String(currentDate.getMonth() + 1).padStart(2, "0");
    const day = String(currentDate.getDate()).padStart(2, "0");

    const formattedCurrentDate = `${year}-${month}-${day}`;
    const startingDate = item.startingdate;
    const validityDate = item.validity;
    // //console.log(startingDate,validityDate,"dsf");
    return (
      startingDate <= formattedCurrentDate &&
      formattedCurrentDate <= validityDate
    );
  });

  const containerStyle = {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    padding: "20px",
  };

  const offerStyle = {
    marginBottom: "20px",
    borderRadius: "10px",

    boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
    width: "600px",
    height: "200px",
  };

  const imageStyle = {
    width: "300px",
    height: "auto",
  };

  const headingStyle = {
    fontSize: "24px",
    margin: "10px 0",
  };

  const paragraphStyle = {
    fontSize: "16px",
    lineHeight: "1.5",
  };
  const travel = [
    {
      title: "Genuius",
      des: "Enjoy discounts on your favorite stays",
      link:"/Offers"
    },
    {
          title: "5% and more discounts",
      des: "Enjoy discounts on your vacation",
      link:"/PilgrimOffers"
    },
    {
      title: "10% and more discounts",
      des: "Enjoy discounts on your homes",
      link:"/VacationOffers"
    },
  ];
  return (
    <div>
      <Navbar />

      <Header />
      <div className="homeContainer">
        <Featured />

        <div class="section-title sectionnone">
          <div class="section-title-separator">
            <span>
              <i class="fas fa-star"></i>
              <i class="fas fa-star"></i>
              <i class="fas fa-star"></i>
            </span>
          </div>
          <h2>Offers</h2>
          <span class="section-separator"></span>
          <p>Promotions, deals and special offers for you</p>
        </div>

        <div style={containerStyle} className="offer-card-group">
          <OwlCarousel
          
            loop
            margin={10}
            nav={true}
            responsive={catresponsive}
            autoplayTimeout={5000}
            autoplay={true}
            autoHeightClass="owl-height"
          >
            {" "}
            {offers.map((offer, index) => (
              <div
                key={index}
                style={{
                  ...offerStyle,
                  background: `url('https://bookingoda.com/image/${offer.offers}')`,
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                }}
              >
                <a href={offer.link} title="">
                  <div
                    style={{
                      padding: "20px",
                      backgroundColor: "rgba(0, 0, 0, 0.5)",
                    }}
                  >
                    <h1 style={{ ...headingStyle, color: "#fff" }}>
                      {offer.Heading}
                    </h1>
                    <h1 style={{ ...paragraphStyle, color: "#fff" }}>
                      {offer.Description}
                    </h1>
                  </div>
                </a>
              </div>
            ))}
          </OwlCarousel>
        </div>
        <section className="parallax-section" data-scrollax-parent="true">
          <div
            className="bg"
            style={{
              backgroundImage: `url(${logos})`,
              transform: "translateZ(0px) translateY(-56.4511px)",
            }}
          />
          <div className="overlay" />
          {/*container*/}
          <div className="containers1">
            <div className="row">
              <div className="col-md-8">
                {/* column text*/}
                <div className="colomn-text fl-wrap">
                  <div className="colomn-text-title">
                    <h3>The owner of the property or business ?</h3>

                    {/* <a href="/createProperty" className="btn3  color-bg float-btn">List Your Property<i className="fas fa-plus" /></a> */}
                  </div>
                </div>
                <div className="sticky-button" onClick={rsa}>
                  RSA
                </div>
                {/*column text   end*/}
              </div>
            </div>
          </div>
        </section>
        {/* <h1 className="homeTitle">Browse by property type</h1> */}
        <div className="quickAndEasyTripPlanContainer">
          <div className="quicAndEasyBoldText">Travel more, spend less</div>
          <div className="slide-container">
            {travel.map((travel, index) => (
              <Link to={`${travel.link}`}>
              <div
                className="each-slide"
                key={index}
                style={{
                  backgroundColor: "#003b95",
                  borderColor: "#003b95",
                  borderWidth: "1px",
                  boxShadow: "0px 2px 8px 0px rgba(26, 26, 26, .16)",
                  color: "white",
                  borderRadius: "8px",
                  boxSizing: "border-box",
                  borderStyle: "solid",
                  display: "block",
                  height: "100%",
                }}
              >
                <div
                  style={{
                    height: "150px",
                    width: "300px",
                    padding: "30px",
                    marginRight: "15px",
                    marginLeft: "15px",
                    // boxShadow: "rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px",
                    // border:"1px solid rgb(217,217,217)",
                  }}
                >
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <h5 style={{ fontWeight: "bold", color: "white" }}>
                      {travel.title}
                    </h5>
                    <CiDiscount1
                      style={{
                        fontSize: "24px",
                        marginRight: "10px",
                        marginTop: "5px",
                      }}
                    />
                  </div>
                  <h5 style={{ fontWeight: "500", color: "white" }}>
                    {travel.des}
                  </h5>
                  {/* <h3 style={{ fontWeight: "400" }}>{slideImage.property}</h3> */}
                </div>
              </div></Link>
            ))}
          </div>
        </div>

        <div>
          <div class="section-title">
            <div class="section-title-separator">
              <span>
                <i class="fas fa-star"></i>
                <i class="fas fa-star"></i>
                <i class="fas fa-star"></i>
              </span>
            </div>
            <h2>Browse by property type</h2>
            <span class="section-separator"></span>
            <p>
              Explore some of the best tips from around the city from our
              partners and friends.
            </p>
          </div>
        </div>
        <PropertyList />
        <QuickAndEasyTripPlan />
        <div class="adb" style={{ display: "inline-block" }}>
          <div class="adv-baner">
            <Carousel
              style={{ position: "relative" }}
              interval={advertise[currentIndex]?.Sec || 3000}
              wrap={true}
            >
              {filteredAdverts.map((data) => (
                <Carousel.Item>
                  {" "}
                  <a href={data.link} title="">
                    {" "}
                    <img
                      alt=""
                      style={{ width: "100%", height: "300px" }}
                      src={"https://bookingoda.com/image/" + data.advertise}
                    />{" "}
                  </a>
                </Carousel.Item>
              ))}
            </Carousel>
          </div>
        </div>
        {/* <h1 className="homeTitle">Home Guests Love</h1> */}
        <div className="sticky-button" onClick={rsa}>
          RSA
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-evenly",
            flexWrap: "wrap",
          }}
        >
          {filteredAdvertsList
            .filter((item) => item.advertiseList.endsWith(".mp4"))
            .slice(0, 1)
            .map((data) => (
              <video
                src={"https://bookingoda.com/image/" + data.advertiseList}
                alt="video"
                style={{
                  width: "300px",
                  height: "200px",
                  marginRight: "20px",
                  marginTop: "-50px",
                }}
                controls
              />
            ))}

          <div class="section-title">
            <h2>Newly Added Stays</h2>
            <span class="section-separator"></span>
            <p>
              Explore some of the best tips from around the city from our
              partners and friends.
            </p>
          </div>
          {filteredAdvertsList
            .filter((item) => item.advertiseList.endsWith(".mp4"))
            .slice(1, 2)
            .map((data) => (
              <video
                src={"https://bookingoda.com/image/" + data.advertiseList}
                alt="video"
                style={{
                  width: "300px",
                  height: "200px",
                 
                  marginTop: "-50px",
                }}
                className="adv"
                controls
              />
            ))}
        </div>
        <FeaturedProperties />
        {/* <MailList /> */}

        <Footer />
      </div>
    </div>
  );
};

export default Home;
