import { useState } from "react";
import "./register.css";
import axios from "axios";
import Success from "../../assets/success.png";
import Error from "../../assets/close.png";
import { Container, Form, Modal, Row, Table } from "react-bootstrap";
const Register = () => {
  const [successshow, setsuccessshow] = useState(false);
  const handlesuccessClose = () => setsuccessshow(false);
  const handlesuccess = () => setsuccessshow(true);

  const [errorshow, seterrorshow] = useState(false);
  const handleerrorClose = () => seterrorshow(false);
  const handleerror = () => seterrorshow(true);
  const [email, setemail] = useState("");
  const [mobile, setmobile] = useState("");
  const [Username, setUsername] = useState("");
  const [password, setpassword] = useState("");
  const [cpassword, setcpassword] = useState("");
  const [showPassword, setShowPassword] = useState(true);
  const [showPassword1, setShowPassword1] = useState(true);
  const handleKeyDown = (e) => {
    // Prevent the input of the minus sign
    if (e.key === "-" || e.key === "e" || e.key === "E") {
      e.preventDefault();
    }
  };
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  const togglePasswordVisibility1 = () => {
    setShowPassword1(!showPassword1);
  };
  const isPasswordValid = (password) => {
    if (password.length < 8) return false;
    if (!/[A-Z]/.test(password)) return false;
    if (!/[a-z]/.test(password)) return false;
    if (!/\d/.test(password)) return false;
    return true;
  };

  const isEmailValid = (email) => {
    const emailRegex = /^[^\s@]+@gmail\.com$/;
    return emailRegex.test(email);
  };
  const handleClick = async () => {
    if (!isEmailValid(email)) {
      alert("Please enter a valid email address (e.g., user@gmail.com).");
    } else if (!isPasswordValid(password)) {
      alert(
        "Use a minimum of 8 characters, including uppercase letters, lowercase letters, and numbers."
      );
    } else if (password !== cpassword) {
      alert("Passwords don't match");
    } else {
      try {
        const config = {
          url: "/registerUser",
          method: "post",
          baseURL: "https://bookingoda.com/api/user",
          headers: { "content-type": "application/json" },
          data: {
            mobile: mobile,
            email: email,
            password: password,
            name: Username,
            cpassword: cpassword,
          },
        };
        await axios(config).then((res) => {
          if (res.status === 200) {
            // alert("Registered Successfully");
            handlesuccess();
            window.location.assign("/login");
          }
        });
      } catch (error) {
        //////console.log(error);
        alert(error.response.data.error);
      }
    }
  };

  return (
    <>
      <div class="partner-header">
        <header class=" aCWKa87CwyJQfbPP_yg6">
          <nav class="yC5_EodFadTqIIY8_YDx">
            <div class="NrR_ZlI8bZYI3w06JKKb">
              <div class="H2AEzwF6W02wmXtVrp9U">
                <div class="mobile-join-logo join_logo">
                  <span
                    style={{ color: "white", fontSize: 30, marginTop: "40px" }}
                  >
                    Booking-Oda.com
                  </span>
                </div>
              </div>
            </div>
          </nav>
        </header>
      </div>
      <div className="f054b6e97f" id="automation_id_hero_container">
        <div className="ffb9c3d6a3 dbd3b41eae">
          <div className="aca0ade214 b23c3f480a c2931f4182 c27e5d305d db150fece4">
            <div className="b817090550 b736e9e3f4">
              <div className="c277eab6bf">
                <div className="c0d0e448a8" style={{ paddingRight: "70px" }}>
                  <h1 className="be54f7e491">
                    <div className="ab64ff081b">
                      <div
                        className="a1a525e0a9 d62ced7487"
                        style={{ marginTop: "-250px" }}
                      >
                        Register <div>to</div> Booking-Oda.com
                      </div>
                    </div>
                  </h1>
                  <h3 className="b14e7782c1">
                    Hospitality is about trying to support multiple functions in
                    one space
                  </h3>
                </div>
                <div className="f33f32dcf3 ab307327fc">
                  <div className="ef6753444c">
                    <div className="eb11e57202">
                      <div className="a9cf2ea607 fa1e8d74e8">
                        <div>
                          <div>
                            <form>
                              <div class="d-flex align-items-center mb-3 pb-1">
                                <span class="h1 fw-bold mb-0">
                                  Booking-Oda.com
                                </span>
                              </div>
                              <h5 class="fw-normal headersign mb-3 pb-3">
                                Register your account
                              </h5>
                              <div class="mb-3">
                                <label for="username" class="form-label">
                                  Username
                                </label>
                                <input
                                  type="text"
                                  class="form-control form-control-lg"
                                  id="username"
                                  placeholder="Username"
                                  onChange={(e) => setUsername(e.target.value)}
                                  value={Username}
                                  required
                                />
                              </div>

                              <div class="mb-3">
                                <label for="username" class="form-label">
                                  Email
                                </label>
                                <input
                                  type="text"
                                  class="form-control form-control-lg"
                                  id="Email"
                                  placeholder="Email"
                                  onChange={(e) => setemail(e.target.value)}
                                  value={email}
                                  required
                                />
                              </div>
                              <div class="mb-3">
                                <label for="username" class="form-label">
                                  Mobile Number
                                </label>
                                <input
                                  type="text"
                                  class="form-control form-control-lg"
                                  id="mobile"
                                  placeholder="Mobile Number"
                                  onChange={(e) => setmobile(e.target.value)}
                                  value={mobile}
                                  required
                                />
                              </div>
                              <div class="mb-3">
                                <label for="passowrd" class="form-label">
                                  Password
                                </label>
                                <div class="input-container">
                                  <input
                                    type={!showPassword ? "text" : "password"}
                                    class="form-control form-control-lg"
                                    id="password"
                                    onChange={(e) =>
                                      setpassword(e.target.value)
                                    }
                                    value={password}
                                    required
                                  />
                                  <i
                                    class={
                                      showPassword
                                        ? "fas fa-eye-slash"
                                        : "fas fa-eye"
                                    }
                                    onClick={togglePasswordVisibility}
                                    style={{ marginRight: "10px" }}
                                  ></i>
                                </div>
                              </div>
                              <div class="mb-3">
                                <label for="passowrd" class="form-label">
                                  Confirm Password
                                </label>
                                <div class="input-container">
                                  <input
                                    type={!showPassword1 ? "text" : "password"}
                                    class="form-control form-control-lg"
                                    id="password"
                                    onChange={(e) =>
                                      setcpassword(e.target.value)
                                    }
                                    value={cpassword}
                                    required
                                  />
                                  <i
                                    class={
                                      showPassword1
                                        ? "fas fa-eye-slash"
                                        : "fas fa-eye"
                                    }
                                    onClick={togglePasswordVisibility1}
                                    style={{ marginRight: "10px" }}
                                  ></i>
                                </div>
                              </div>
                              <div class="pt-1 mb-4">
                                <button
                                  class="btn btn-dark btn-lg btn-block"
                                  type="button"
                                  onClick={handleClick}
                                >
                                  Register
                                </button>
                              </div>
                              <br />
                              <a href="#!" class="small text-muted">
                                Terms of use.
                              </a>
                              <a href="#!" class="small text-muted">
                                {" "}
                                Privacy policy
                              </a>
                            </form>
                            <a
                              href="/login"
                              class="large"
                              style={{
                                color: "#297cbb",
                                fontWeight: "900",
                                marginLeft: "auto",
                                marginRight: "auto",
                              }}
                            >
                              Already have an account? Sign In!
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        show={successshow}
        onHide={handlesuccess}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton onClick={handlesuccessClose}></Modal.Header>
        <Modal.Body>
          <div className="text-center">
            <img src={Success} style={{ marginTop: "-12px" }} width="100px" />

            <p>Login Successfully</p>
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        show={errorshow}
        onHide={handleerror}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton onClick={handleerrorClose}></Modal.Header>
        <Modal.Body>
          <div className="text-center">
            <img src={Error} style={{ marginTop: "-12px" }} width="100px" />

            <p>Error Occured</p>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Register;
