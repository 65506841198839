import React from "react";
import { BsArrowDownRight, BsArrowUpRight } from "react-icons/bs";
import { Column } from "@ant-design/plots";
import { Table } from "antd";
import { useState } from "react";
import axios from "axios";
import { useEffect } from "react";
import {
  DollarCircleOutlined,
  ShoppingCartOutlined,
  ShoppingOutlined,
  UserOutlined,
  UserSwitchOutlined,
  UsergroupAddOutlined,
} from "@ant-design/icons";
import {
  ApartmentOutlined,
  LocalHotelOutlined,
  LocationCityOutlined,
} from "@material-ui/icons";
import {
  BedroomParentOutlined,
  DirectionsCarFilledOutlined,
  HolidayVillageOutlined,
  HouseboatOutlined,
  NightShelterOutlined,
} from "@mui/icons-material";
import { Link } from "react-router-dom";
const data1 = [];
for (let i = 0; i < 46; i++) {
  data1.push({
    key: i,
    name: `Edward King ${i}`,
    product: 32,
    staus: `London, Park Lane no. ${i}`,
  });
}
const DashboardVendor = () => {
  const vendor = JSON.parse(sessionStorage.getItem("vendor"));

  const data = [
    {
      type: "Jan",
      sales: 38,
    },
    {
      type: "Feb",
      sales: 52,
    },
    {
      type: "Mar",
      sales: 61,
    },
    {
      type: "Apr",
      sales: 145,
    },
    {
      type: "May",
      sales: 48,
    },
    {
      type: "Jun",
      sales: 38,
    },
    {
      type: "July",
      sales: 38,
    },
    {
      type: "Aug",
      sales: 38,
    },
    {
      type: "Sept",
      sales: 38,
    },
    {
      type: "Oct",
      sales: 38,
    },
    {
      type: "Nov",
      sales: 38,
    },
    {
      type: "Dec",
      sales: 38,
    },
  ];

  const [hotel, sethotel] = useState([]);

  const gethotel = async () => {
    let res = await axios.get("https://bookingoda.com/api/admin/getallhotel");
    if (res.status === 200) {
      //////console.log(res.data);
      sethotel(res.data.hotel.filter((item) => item?.UserId === vendor?._id));
    }
  };
  const [apartment, setapartment] = useState([]);

  const getapartment = async () => {
    let res = await axios.get(
      "https://bookingoda.com/api/admin/getallApartment"
    );
    if (res.status === 200) {
      //////console.log(res.data);
      setapartment(
        res.data.Apartment.filter((item) => item?.UserId === vendor._id)
      );
    }
  };
  const [alternative, setalternative] = useState([]);

  const getalternative = async () => {
    let res = await axios.get(
      "https://bookingoda.com/api/admin/getallalternative"
    );
    if (res.status === 200) {
      //////console.log(res.data);
      setalternative(
        res.data.alternative.filter((item) => item?.UserId === vendor._id)
      );
    }
  };

  const [pil, setpil] = useState([]);

  const getpil = async () => {
    let res = await axios.get(
      "https://bookingoda.com/api/admin/getbookedtourpackages"
    );
    if (res.status === 200) {
      //////console.log(res.data);
      setpil(
        res.data.bookedtourpackages.filter(
          (item) => item.packageId?.UserId === vendor._id
        )
      );
      ////console.log(res.data.bookedtourpackages, "dasfasd");
    }
  };
  const [international, setinternational] = useState([]);

  const getinternational = async () => {
    let res = await axios.get(
      "https://bookingoda.com/api/admin/getinternational"
    );
    if (res.status === 200) {
      //////console.log(res.data);
      setinternational(
        res.data.getpackages.filter((item) => item.UserId === vendor._id)
      );
    }
  };
  const [stays, setstays] = useState([]);

  const getstays = async () => {
    let res = await axios.get("https://bookingoda.com/api/admin/getbookstays");
    if (res.status === 200) {
      //////console.log(res.data);
      setstays(
        res.data.bookedstays.filter((item) => item.VendorID?._id === vendor?._id)
      );
    }
  };

  const [internationalbooked, setinternationalbooked] = useState([]);

  const getinternationalbooked = async () => {
    let res = await axios.get(
      "https://bookingoda.com/api/admin/getbookinternationalpackages"
    );
    if (res.status === 200) {
      //////console.log(res.data);
      setinternationalbooked(
        res.data.bookedtourpackages.filter(
          (data) => data.packageId?.UserId === vendor._id
        )
      );
      ////console.log(res.data.bookedtourpackages, "dasfasd");
    }
  };
  const [home, sethome] = useState([]);

  const gethome = async () => {
    let res = await axios.get("https://bookingoda.com/api/admin/getallhome");
    if (res.status === 200) {
      //////console.log(res.data);
      sethome(res.data.home.filter((item) => item?.UserId === vendor._id));
    }
  };
  const [staysbooked, setstaysbooked] = useState([]);

  const getstaysbooked = async () => {
    let res = await axios.get("https://bookingoda.com/api/admin/getbookstays");
    if (res.status === 200) {
      //////console.log(res.data);
      setstaysbooked(
        res.data.bookedstays.filter((item) => item.VendorID._id === vendor._id)
      );
      ////console.log(res.data.bookedstays, "dasfasd");
    }
  };
  const [Tourpackages, setTourpackages] = useState([]);
  const getTourPackages = async () => {
    try {
      const res = await axios.get(
        "https://bookingoda.com/api/admin/gettrippackages"
      );
      if (res.status === 200) {
        setTourpackages(
          res.data.getpackages.filter((item) => item.UserId === vendor._id)
        );
      }
    } catch (error) {
      ////console.log(error);
    }
  };
  useEffect(() => {
    gethotel();
    gethome();
    getapartment();
    getalternative();
    getpil();
    getTourPackages();
    getinternational();
    getstays();

    getinternationalbooked();

    getstaysbooked();
  }, []);
  return (
    <div style={{ backgroundColor: "white"}}>
      <h3 className="title" style={{ marginTop: "40px" }}>
        Dashboard
      </h3>
      <div
        className="d-flex justify-content-around align-items-center gap-3"
        style={{ flexWrap: "wrap" }}
      >
        <Link to="/vendordashboard/hotellistvendor">
          <div className="dashboard-card12">
            <div className="card-header12">
              <span className="card-icon12">
                {" "}
                <BedroomParentOutlined
                  style={{
                    color: "white",
                    background: `linear-gradient(to right, #3498db, #5dade2, #85c1e9)`,
                    borderRadius: 20,
                    fontSize: 40,
                    padding: 8,
                  }}
                />
              </span>
              <h3 className="card-title12">Hotels</h3>
            </div>
            <div className="card-content12">
              <p className="card-value12">{hotel.length}</p>
            </div>
          </div>
        </Link>
        <Link to="/vendordashboard/apartmentlistvendor">
          <div className="dashboard-card12">
            <div className="card-header12">
              <span className="card-icon12">
                {" "}
                <ApartmentOutlined
                  style={{
                    color: "white",
                    background: `linear-gradient(to right, #3498db, #5dade2, #85c1e9)`,
                    borderRadius: 20,
                    fontSize: 40,
                    padding: 8,
                  }}
                />
              </span>
              <h3 className="card-title12">Apartments</h3>
            </div>
            <div className="card-content12">
              <p className="card-value12">{apartment.length}</p>
            </div>
          </div>
        </Link>
        <Link to="/vendordashboard/homelistvendor">
          <div className="dashboard-card12">
            <div className="card-header12">
              <span className="card-icon12">
                {" "}
                <NightShelterOutlined
                  style={{
                    color: "white",
                    background: `linear-gradient(to right, #3498db, #5dade2, #85c1e9)`,
                    borderRadius: 20,
                    fontSize: 40,
                    padding: 8,
                  }}
                />
              </span>
              <h3 className="card-title12">Homestay</h3>
            </div>
            <div className="card-content12">
              <p className="card-value12">{home.length}</p>
            </div>
          </div>{" "}
        </Link>
        <Link to="/vendordashboard/alternativelistvendor">
          <div className="dashboard-card12">
            <div className="card-header12">
              <span className="card-icon12">
                {" "}
                <LocationCityOutlined
                  style={{
                    color: "white",
                    background: `linear-gradient(to right, #3498db, #5dade2, #85c1e9)`,
                    borderRadius: 20,
                    fontSize: 40,
                    padding: 8,
                  }}
                />
              </span>
              <h3 className="card-title12">Alternative Property</h3>
            </div>
            <div className="card-content12">
              <p className="card-value12">{alternative.length}</p>
            </div>
          </div>{" "}
        </Link>
        <Link to="/vendordashboard/tourlistvendor">
          <div className="dashboard-card12">
            <div className="card-header12">
              <span className="card-icon12">
                {" "}
                <HouseboatOutlined
                  style={{
                    color: "white",
                    background: `linear-gradient(to right, #3498db, #5dade2, #85c1e9)`,
                    borderRadius: 20,
                    fontSize: 40,
                    padding: 8,
                  }}
                />
              </span>
              <h3 className="card-title12">Pilgrim Vacations List</h3>
            </div>
            <div className="card-content12">
              <p className="card-value12">{Tourpackages.length}</p>
            </div>
          </div>{" "}
        </Link>
        <Link to="/vendordashboard/internationalvendor">
          <div className="dashboard-card12">
            <div className="card-header12">
              <span className="card-icon12">
                {" "}
                <HolidayVillageOutlined
                  style={{
                    color: "white",
                    background: `linear-gradient(to right, #3498db, #5dade2, #85c1e9)`,
                    borderRadius: 20,
                    fontSize: 40,
                    padding: 8,
                  }}
                />
              </span>
              <h3 className="card-title12">International Holiday List</h3>
            </div>
            <div className="card-content12">
              <p className="card-value12">{international.length}</p>
            </div>
          </div>{" "}
        </Link>
        <Link to="/vendordashboard/bookstaysvendor">
          <div className="dashboard-card12">
            <div className="card-header12">
              <span className="card-icon12">
                {" "}
                <LocalHotelOutlined
                  style={{
                    color: "white",
                    background: `linear-gradient(to right, #3498db, #5dade2, #85c1e9)`,
                    borderRadius: 20,
                    fontSize: 40,
                    padding: 8,
                  }}
                />
              </span>
              <h3 className="card-title12">Booked Stays</h3>
            </div>
            <div className="card-content12">
              <p className="card-value12">{stays?.length}</p>
            </div>
          </div>{" "}
        </Link>
        <Link to="/vendordashboard/pilgrimordervendor">
          <div className="dashboard-card12">
            <div className="card-header12">
              <span className="card-icon12">
                {" "}
                <HouseboatOutlined
                  style={{
                    color: "white",
                    background: `linear-gradient(to right, #3498db, #5dade2, #85c1e9)`,
                    borderRadius: 20,
                    fontSize: 40,
                    padding: 8,
                  }}
                />
              </span>
              <h3 className="card-title12">Booked Pilgrim Vacations List</h3>
            </div>
            <div className="card-content12">
              <p className="card-value12">{pil.length}</p>
            </div>
          </div>{" "}
        </Link>
        <Link to="/vendordashboard/bookinternationalvendor">
          <div className="dashboard-card12">
            <div className="card-header12">
              <span className="card-icon12">
                {" "}
                <HolidayVillageOutlined
                  style={{
                    color: "white",
                    background: `linear-gradient(to right, #3498db, #5dade2, #85c1e9)`,
                    borderRadius: 20,
                    fontSize: 40,
                    padding: 8,
                  }}
                />
              </span>
              <h3 className="card-title12">
                Booked International Holiday List
              </h3>
            </div>
            <div className="card-content12">
              <p className="card-value12">{internationalbooked.length}</p>
            </div>
          </div>
        </Link>
      </div>

      {/* <div className="mt-4">
        <h3 className="mb-5 title">Income Statics</h3>
        <div>
          <Column {...config} />
        </div>
      </div>
      <div className="mt-4">
        <h3 className="mb-5 title">Recent Orders</h3>
        <div>
          <Table columns={columns} dataSource={data1} />
        </div>
      </div> */}
    </div>
  );
};

export default DashboardVendor;
