import "../pages/list/list.css";

import React, { useCallback, useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { format } from "date-fns";
import { DateRange } from "react-date-range";
import IndianCities from "indian-cities-json";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import axios from "axios";
import { FaHeart, FaLongArrowAltRight, FaRegHeart } from "react-icons/fa";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import StarRatings from "react-star-ratings";
import { FaMapMarkerAlt } from "react-icons/fa";
import Carousel from "react-bootstrap/Carousel";
import Navbar from "./navbar/Navbar";
import Footer from "./footer/Footer";
import { MdKeyboardDoubleArrowRight } from "react-icons/md";
const CityImage = ({ cityName }) => {
  const [imageUrl, setImageUrl] = useState("");

  useEffect(() => {
    const fetchImage = async () => {
      try {
        const response = await fetch(
          `https://api.unsplash.com/photos/random?query=${cityName}&client_id=cSSwA-73tDd1tFdcmFgqQYv3IwS_oRlndXmFYfdG3hs`
        );

        const data = await response.json();

        if (data.urls && data.urls.full) {
          setImageUrl(data.urls.full);
        } else {
          console.error("Image not found");
          setImageUrl(
            "https://cdn.pixabay.com/photo/2021/05/13/07/18/sculpture-6250058_1280.jpg"
          );
        }
      } catch (error) {
        console.error("Error fetching image:", error);
      }
    };

    fetchImage();
  }, [cityName]);

  return (
    <img
      src={imageUrl}
      
      alt={`City: ${cityName}`}
      class="featuredImg12 img-fluid"
    />
  );
};

const Offers = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const catresponsive = {
    0: {
      items: 1,
    },
    600: {
      items: 2,
    },
    1000: {
      items: 4,
    },
  };
  const [data, setdata] = useState([]);
  const [data1, setdata1] = useState([]);
  const [data2, setdata2] = useState([]);
  const [data3, setdata3] = useState([]);
  const user = JSON.parse(sessionStorage.getItem("user"));


  const getapartment = async () => {
    let res = await axios.get(
      "https://bookingoda.com/api/admin/getallApartment"
    );
    if (res.status === 200) {
      ////console.log(res.data);
      setdata(
        res.data.Apartment.filter(
          (item) =>
            item.status === "Approved" &&
            item.Deletestatus !== "Deleted By Admin" &&
            item.blockstatus !== true
        )
      );
    }
  };
  const getalternative = async () => {
    let res = await axios.get(
      "https://bookingoda.com/api/admin/getallalternative"
    );
    if (res.status === 200) {
      ////console.log(res.data);
      setdata2(
        res.data.alternative.filter(
          (item) =>
            item.status === "Approved" &&
            item.Deletestatus !== "Deleted By Admin" &&
            item.blockstatus !== true
        )
      );
    }
  };
  const gethotel = async () => {
    let res = await axios.get("https://bookingoda.com/api/admin/getallhotel");
    if (res.status === 200) {
      ////console.log(res.data);
      setdata1(
        res.data.hotel.filter(
          (item) =>
            item.status === "Approved" &&
            item.Deletestatus !== "Deleted By Admin" &&
            item.blockstatus !== true
        )
      );
    }
  };
  const gethomestay = async () => {
    let res = await axios.get("https://bookingoda.com/api/admin/getallhome");
    if (res.status === 200) {
      ////console.log(res.data);
      setdata3(
        res.data.home.filter(
          (item) =>
            item.status === "Approved" &&
            item.Deletestatus !== "Deleted By Admin" &&
            item.blockstatus !== true
        )
      );
    }
  };

  useEffect(() => {
    getapartment();
    gethotel();
    getalternative();
    gethomestay();
    getreviews();
    AllWishlist();
  }, []);

  const list = [...data,...data1,...data2,...data3];

  const [wishlist, setwishlist] = useState([]);

  const AllWishlist = () => {
    // console.log("hgfh", user._id);
    axios
      .get("https://bookingoda.com/api/user/getWishlist/" + user?._id)
      .then(function (response) {
        setwishlist(response.data.WishList);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const RemovefromWishlist = async (item) => {
    try {
      const config = {
        url: "/user/removeFromWishlist",
        method: "put",
        baseURL: "https://bookingoda.com/api",
        hearder: { "content-type": "application/json" },
        data: {
          userId: user?._id,
          hotelId: item._id,
        },
      };
      await axios(config).then(function (res) {
        if (res.status === 200) {
          console.log(res.data);
          // alert("Product Removed");
          AllWishlist();
          // if(wishlist.length === 1){
          // navigation.goBack();
          // }
        } else {
          alert("Something Wrong");
        }
      });
    } catch (error) {
      console.log(error.response);
    }
  };
  const addToWishlist = async (item) => {
    try {
      const config = {
        url: "/user/addToWishlist",
        method: "post",
        baseURL: "https://bookingoda.com/api",
        hearder: { "content-type": "application/json" },

        data: {
          userId: user._id,
          hotelId: item?._id,
        },
      };
      await axios(config).then(function (res) {
        if (res.status === 200) {
          // console.log(res.data);
          alert(res.data.success);
          AllWishlist();
        } else {
          alert("Something Wrong");
        }
      });
    } catch (error) {
      console.log(error.response);
    }
  };

 

  // Handle page change
  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };
  const [review, setreview] = useState([]);
  const [date, setDate] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: "selection",
    },
  ]);
  const [options, setOptions] = useState({
    adult: 1,
    children: 0,
    room: 1,
  });
  const getreviews = async () => {
    let res = await axios.get("https://bookingoda.com/api/user/getallReview");
    if (res.status === 200) {
      ////console.log(res.data);
      setreview(res.data.success);
      //console.log(res.data.bookedstays, "dasfasd");
    }
  };

  const ReviewPercentage = ({ review, hotelId }) => {
    const selectedCategories = [
      "Pricerating",
      "Staffrating",
      "Cleanlinessrating",
      "Comfortrating",
      "Facilitiesrating",
      "Locationrating",
    ];
    const averageRatings = {};

    // Error-checking: Ensure review is an array
    if (!Array.isArray(review)) {
      console.error("Invalid 'review' data:", review);
      return null; // or handle the error in a way suitable for your application
    }

    selectedCategories.forEach((category) => {
      const filteredReviews = review.filter(
        (data) => data?.HotelId[0] === hotelId
      );

      // Error-checking: Ensure filteredReviews is an array
      if (!Array.isArray(filteredReviews)) {
        console.error("Invalid 'filteredReviews' data:", filteredReviews);
        return null; // or handle the error
      }

      const sum = filteredReviews.reduce(
        (acc, rating) => acc + rating[category],
        0
      );
      averageRatings[category] =
        filteredReviews.length > 0 ? sum / filteredReviews.length : 0;
    });

    const overallAverage =
      selectedCategories.length > 0
        ? selectedCategories.reduce(
            (acc, category) => acc + averageRatings[category],
            0
          ) / selectedCategories.length
        : 0;

    // Determine the status based on the overall average
    let status;
    if (overallAverage >= 1 && overallAverage < 2) {
      status = "Review score";
    } else if (overallAverage >= 2 && overallAverage < 3) {
      status = "Okay";
    } else if (overallAverage >= 3 && overallAverage < 4) {
      status = "Average";
    } else if (overallAverage >= 4 && overallAverage <= 5) {
      status = "Good";
    } else {
      status = "Unrated";
    }

    return (
      <div style={{ textAlign: "right" }}>
        <div className="siRating" style={{ textAlign: "right" }}>
          {/* <span>Excellent</span> */}

          <span
            style={{ color: "#0b58b4", fontSize: "20px", fontWeight: "bold" }}
          >
            {status}
          </span>
          <button>&nbsp;{overallAverage.toFixed(2)}</button>
        </div>
        <p className="mt-reviews-summary" style={{ textAlign: "right" }}>
          ({" "}
          <span className="mt-reviews-number" itemProp="ratingCount">
            {Array.isArray(review)
              ? review.filter(
                  (data) =>
                    data?.HotelId &&
                    Array.isArray(data.HotelId) &&
                    data.HotelId[0] === hotelId
                ).length
              : 0}
          </span>
          <span className="mt-reviews-label">&nbsp;review</span>)
        </p>
        <StarRatings
          rating={overallAverage}
          starRatedColor="#ffb700"
          numberOfStars={5}
          name="rating"
          isSelectable={false}
          starDimension="18px"
        />
      </div>
    );
  };

  //Rooms Facilities

  // Count occurrences of each facility in the data

  const itemsWithNonEmptyOfferPrice = list.filter((item) => {
    if ("Offerprice" in item) {
      const offerPrice = item.Offerprice;
      return (
        offerPrice !== "undefined" &&
        (offerPrice.endsWith("%") || offerPrice.includes("INR"))
      );
    }
    return false;
  });
  const datasss = itemsWithNonEmptyOfferPrice.map((e) =>
    e.address.map((data) => data)
  );
  const cityNames = datasss.map((address, index) => {
    if (Array.isArray(address) && address[0]) {
      const cityArray = address[0].split(","); // Split the address string by commas
      //console.log(cityArray,"array");
      if (cityArray.length >= 3) {
        const cityName = cityArray[cityArray.length - 3]?.trim(); // Use optional chaining to avoid 'undefined' error
        return cityName || null; // Provide a default value if city name is not found
      } else {
        return null;
      }
    } else {
      return null;
    }
  });

  const uniqueAddresses = [...new Set(cityNames.flat())];
  const [destination, setDestination] = useState("");
console.log(destination);
  const filteredItems1 = itemsWithNonEmptyOfferPrice.filter((item) => {
    const addressString = item.address.map((data) => data).join(", ");
    return (
      addressString.includes(destination) || item.PropertyName === destination
    );
  });
  const [filteredItems, setFilteredItems] = useState([]);
  const itemsPerPage = 5; // Number of cards per page
  const [currentPage, setCurrentPage] = useState(1);
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;

  // Get the current page's items
  const currentItems = filteredItems1.slice(startIndex, endIndex);
  console.log(filteredItems1, "filteredItems");
  const filterByOfferPrice = (min, max) => {
    const filteredData = filteredItems1.filter((item) => {
      if ("Offerprice" in item) {
        const offerPrice = item.Offerprice.toUpperCase();
        if (offerPrice.endsWith("%")) {
          const percentage = parseFloat(offerPrice.replace("%", ""));
          return percentage >= min && percentage <= max;
        } else if (offerPrice.endsWith("INR")) {
          const inr = parseFloat(offerPrice.replace("INR", ""));

          const percentage = (inr / item.Price) * 100;
          return percentage >= min && percentage <= max;
        }
      }
      return false;
    });
    setFilteredItems(filteredData); // Update filtered items state
  };
  const calculateFilteredItemsLength = (minPrice, maxPrice) => {
    // Filter the list based on the offer price range
    const filteredList = itemsWithNonEmptyOfferPrice.filter((product) => {
      const offerPrice = parseInt(product.Offerprice);
      return offerPrice >= minPrice && offerPrice <= maxPrice;
    });
  
    // Return the length of the filtered list
    return filteredList.length;
  };
  
  // Use the function to calculate the length for each offer price range
  const length1to4 = calculateFilteredItemsLength(1, 4);

  const length5to9 = calculateFilteredItemsLength(5, 9);
  const length10to19 = calculateFilteredItemsLength(10, 19);
  const length20above = calculateFilteredItemsLength(20, 100);
  return (
    <div>
      <Navbar />
      <div class="header12 listMode">
        <div class="headerContainer12 container12">
          <div class="headerCont12">
            <div>
              <h2
                class="headerTitle12 text-center"
                style={{ marginTop: "50px" }}
              >
                Stay Offers
              </h2>
            </div>
          </div>
        </div>
      </div>

      <OwlCarousel
        className="topdeals-carousel"
        loop
        margin={10}
        nav={true}
        autoplayTimeout={5000}
        responsive={catresponsive}
        autoplay={true}
        autoHeightClass="owl-height"
      >
        {uniqueAddresses.reverse()?.map((item) => {
          const originalPrice = parseInt(item?.Price);
          const discountString = item?.Offerprice || "";
          let discountedPrice;

          //console.log(`Discount String: ${discountString}`);

          if (discountString.endsWith("%")) {
            const discountPercentage = parseFloat(
              discountString.replace(/[^0-9.]/g, "")
            );
            discountedPrice =
              originalPrice - (originalPrice * discountPercentage) / 100;
          } else {
            const discountValue = parseFloat(
              discountString.replace(/[^0-9.]/g, "")
            );
            discountedPrice = originalPrice - discountValue;
          }

          const taxInfo = item?.tax || "";
          let taxedPrice;

          if (taxInfo.endsWith("%")) {
            // If the tax is in percentage
            const taxPercentage = parseFloat(taxInfo.replace(/[^0-9.]/g, ""));
            taxedPrice = (originalPrice * taxPercentage) / 100;
          } else if (taxInfo.endsWith("INR")) {
            // If the tax is in INR
            const taxInr = parseFloat(taxInfo.replace(/[^0-9.]/g, ""));
            taxedPrice = taxInr;
          } else {
            // Handle the case where the tax format is not recognized
            console.warn(
              `Unrecognized tax format for item with ID ${item._id}`
            );
          }
          return (
            <div class="col-md-6 col-lg-3 col-6">
              <div class="featuredItem12" style={{height:"200px"}}>
                <div onClick={() => setDestination(item)}>
                  <CityImage cityName={item} />
                
                <div class="featuredTitles12">
                  <h1>{item}</h1>
                  <h2>
                    {cityNames.filter((d) => d === item)?.length} properties
                  </h2>
                </div></div>
              </div>
            </div>
          );
        })}
      </OwlCarousel>
      <div className="listContainer" style={{ marginTop: "50px" }}>
        <div className="listWrapper">
          <div
            style={{ display: "flex", flexDirection: "column", width: "300px" }}
          >
            <div className="listSearch">
              <h1 className="lsTitle">Filter</h1>
              <div className="filterRow " id="PRICE_BUCKET">
                <div className="latoBold font16 blackText appendBottom15 makeFlex hrtlCenter">
                 Discounts
                </div>
                <div className="priceBucketFilter">
                  <ul className="filterList">
                  <li>
                      <button onClick={() => filterByOfferPrice(1, 4)}>
                        Below 5% 
                      </button>
                      <span className="font12 grayText">({length1to4})</span>
                    </li>
                    <li>
                      <button onClick={() => filterByOfferPrice(5, 9)}>
                        5% - 9%
                      </button>
                      <span className="font12 grayText">({length5to9})</span>
                    </li>
                    <li>
                      <button onClick={() => filterByOfferPrice(10, 19)}>
                        10% - 19%
                      </button>
                      <span className="font12 grayText">({length10to19})</span>
                    </li>
                    <li>
                      <button onClick={() => filterByOfferPrice(20, 100)}>
                        20% and above
                      </button>
                      <span className="font12 grayText">({length20above})</span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="listResult">
            {filteredItems.length > 0 ? filteredItems.map((item) => {
              const originalPrice = parseInt(item?.Price);
              const discountString = item?.Offerprice || "";
              let discountedPrice;

              //console.log(`Discount String: ${discountString}`);

              if (discountString.endsWith("%")) {
                const discountPercentage = parseFloat(
                  discountString.replace(/[^0-9.]/g, "")
                );
                discountedPrice =
                  originalPrice - (originalPrice * discountPercentage) / 100;
              } else {
                const discountValue = parseFloat(
                  discountString.replace(/[^0-9.]/g, "")
                );
                discountedPrice = originalPrice - discountValue;
              }

              const taxInfo = item?.tax || "";
              let taxedPrice;

              if (taxInfo.endsWith("%")) {
                // If the tax is in percentage
                const taxPercentage = parseFloat(
                  taxInfo.replace(/[^0-9.]/g, "")
                );
                taxedPrice = (originalPrice * taxPercentage) / 100;
              } else if (taxInfo.endsWith("INR")) {
                // If the tax is in INR
                const taxInr = parseFloat(taxInfo.replace(/[^0-9.]/g, ""));
                taxedPrice = taxInr;
              } else {
                // Handle the case where the tax format is not recognized
                console.warn(
                  `Unrecognized tax format for item with ID ${item._id}`
                );
              }
              return (
                <div className="searchItem">
                  <Link
                    to={`/hotels/${item._id}`}
                    state={{ date: date, options: options }}
                    key={item._id}
                  >
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      {item?.BreakfastIncluded === "Yes, it's included" ? (
                        <span
                          className="siSubtitle"
                          style={{
                            backgroundColor: "#0B6E0B",
                            color: "white",
                            textAlign: "center",
                            padding: "10px",
                            borderTopLeftRadius: "5px",
                            borderTopRightRadius: "5px",
                          }}
                        >
                          Breakfast Included
                        </span>
                      ) : (
                        ""
                      )}

                      <img
                        src={`https://bookingoda.com/image/${item?.Photos[0]?.file}`}
                        alt=""
                        className="siImg"
                      />
                    </div>{" "}
                  </Link>
                  <div className="siDesc">
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                      }}
                    >
                      <div>
                        {item?.Badge?.length > 0 ? (
                          <img
                            src={`https://bookingoda.com/image/${item?.Badge[0]?.file}`}
                            style={{
                              width: "100px",
                              height: "30px",
                              marginBottom: "10px",
                            }}
                            alt=""
                          />
                        ) : (
                          ""
                        )}
                        <Link
                          to={`/hotels/${item._id}`}
                          state={{ date: date, options: options }}
                          key={item._id}
                        >
                          {" "}
                          <h1 className="siTitle">{item.PropertyName}</h1>{" "}
                        </Link>{" "}
                      </div>
                      {wishlist.find((data) => data === item?._id) ? (
                        <div
                          role="none"
                          className="f9039b381b"
                          data-testid="wishlist-icon"
                        >
                          <span className>
                            <span className="f419a93f12">
                              <button
                                aria-expanded="false"
                                data-testid="wishlist-button"
                                aria-label="Save the property"
                                type="button"
                                className="a83ed08757 c21c56c305 bd3a1c7108 d691166b09 ab98298258 deab83296e fe94449254"
                                onClick={() => RemovefromWishlist(item)}
                              >
                                <span className="eedba9e88a">
                                  <span
                                    className="fcd9eec8fb bf9a32efa5"
                                    aria-hidden="true"
                                  >
                                    <FaHeart style={{ color: "red" }} />
                                  </span>
                                </span>
                              </button>
                            </span>
                          </span>
                        </div>
                      ) : (
                        <div
                          role="none"
                          className="f9039b381b"
                          data-testid="wishlist-icon"
                        >
                          <span className>
                            <span className="f419a93f12">
                              <button
                                aria-expanded="false"
                                data-testid="wishlist-button"
                                aria-label="Save the property"
                                type="button"
                                className="a83ed08757 c21c56c305 bd3a1c7108 d691166b09 ab98298258 deab83296e fe94449254"
                                onClick={() => addToWishlist(item)}
                              >
                                <span className="eedba9e88a">
                                  <span
                                    className="fcd9eec8fb bf9a32efa5"
                                    aria-hidden="true"
                                  >
                                    <FaRegHeart />
                                  </span>
                                </span>
                              </button>
                            </span>
                          </span>
                        </div>
                      )}
                    </div>
                    <span className="siDistance">
                      <FaMapMarkerAlt
                        style={{ color: "black", marginRight: "5px" }}
                      />
                      {item.address.map((item) => item).join(", ")}
                    </span>
                    <span className="siSubtitle">
                      Rooms Available:{" "}
                      {item?.RoomDetails.map(
                        (item) => item.Roomname[0]?.value
                      ).join(", ")}
                    </span>
                  </div>
                  <div className="siDetails">
                    <ReviewPercentage review={review} hotelId={item._id} />
                    <div className="siDetailTexts">
                      <span style={{ fontSize: "16px", color: "black" }}>
                        Price for 1 Night
                      </span>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "flex-end",
                        }}
                      >
                        <span
                          style={{
                            color: "red",
                            fontSize: "16px",
                            textDecoration: "line-through",
                            marginRight: "10px",
                          }}
                        >
                          ₹ {parseInt(item?.Price)}
                        </span>

                        <span className="siPrice">
                          ₹ {discountedPrice ? discountedPrice : item?.Price}
                        </span>
                      </div>
                      <span className="siTaxOp">
                        +{taxedPrice ? taxedPrice : 0} taxes &amp; fees
                      </span>

                      <Link
                        to={`/hotels/${item._id}`}
                        state={{ date: date, options: options }}
                      >
                        <button className="siCheckButton">
                          See availability
                        </button>
                      </Link>
                    </div>
                  </div>
                </div>
              );
            }) :filteredItems1.length !== itemsWithNonEmptyOfferPrice.length  ? filteredItems1.map((item) => {
              const originalPrice = parseInt(item?.Price);
              const discountString = item?.Offerprice || "";
              let discountedPrice;

              //console.log(`Discount String: ${discountString}`);

              if (discountString.endsWith("%")) {
                const discountPercentage = parseFloat(
                  discountString.replace(/[^0-9.]/g, "")
                );
                discountedPrice =
                  originalPrice - (originalPrice * discountPercentage) / 100;
              } else {
                const discountValue = parseFloat(
                  discountString.replace(/[^0-9.]/g, "")
                );
                discountedPrice = originalPrice - discountValue;
              }

              const taxInfo = item?.tax || "";
              let taxedPrice;

              if (taxInfo.endsWith("%")) {
                // If the tax is in percentage
                const taxPercentage = parseFloat(
                  taxInfo.replace(/[^0-9.]/g, "")
                );
                taxedPrice = (originalPrice * taxPercentage) / 100;
              } else if (taxInfo.endsWith("INR")) {
                // If the tax is in INR
                const taxInr = parseFloat(taxInfo.replace(/[^0-9.]/g, ""));
                taxedPrice = taxInr;
              } else {
                // Handle the case where the tax format is not recognized
                console.warn(
                  `Unrecognized tax format for item with ID ${item._id}`
                );
              }
              return (
                <div className="searchItem">
                  <Link
                    to={`/hotels/${item._id}`}
                    state={{ date: date, options: options }}
                    key={item._id}
                  >
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      {item?.BreakfastIncluded === "Yes, it's included" ? (
                        <span
                          className="siSubtitle"
                          style={{
                            backgroundColor: "#0B6E0B",
                            color: "white",
                            textAlign: "center",
                            padding: "10px",
                            borderTopLeftRadius: "5px",
                            borderTopRightRadius: "5px",
                          }}
                        >
                          Breakfast Included
                        </span>
                      ) : (
                        ""
                      )}

                      <img
                        src={`https://bookingoda.com/image/${item?.Photos[0]?.file}`}
                        alt=""
                        className="siImg"
                      />
                    </div>{" "}
                  </Link>
                  <div className="siDesc">
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                      }}
                    >
                      <div>
                        {item?.Badge?.length > 0 ? (
                          <img
                            src={`https://bookingoda.com/image/${item?.Badge[0]?.file}`}
                            style={{
                              width: "100px",
                              height: "30px",
                              marginBottom: "10px",
                            }}
                            alt=""
                          />
                        ) : (
                          ""
                        )}
                        <Link
                          to={`/hotels/${item._id}`}
                          state={{ date: date, options: options }}
                          key={item._id}
                        >
                          {" "}
                          <h1 className="siTitle">{item.PropertyName}</h1>{" "}
                        </Link>{" "}
                      </div>
                      {wishlist.find((data) => data === item?._id) ? (
                        <div
                          role="none"
                          className="f9039b381b"
                          data-testid="wishlist-icon"
                        >
                          <span className>
                            <span className="f419a93f12">
                              <button
                                aria-expanded="false"
                                data-testid="wishlist-button"
                                aria-label="Save the property"
                                type="button"
                                className="a83ed08757 c21c56c305 bd3a1c7108 d691166b09 ab98298258 deab83296e fe94449254"
                                onClick={() => RemovefromWishlist(item)}
                              >
                                <span className="eedba9e88a">
                                  <span
                                    className="fcd9eec8fb bf9a32efa5"
                                    aria-hidden="true"
                                  >
                                    <FaHeart style={{ color: "red" }} />
                                  </span>
                                </span>
                              </button>
                            </span>
                          </span>
                        </div>
                      ) : (
                        <div
                          role="none"
                          className="f9039b381b"
                          data-testid="wishlist-icon"
                        >
                          <span className>
                            <span className="f419a93f12">
                              <button
                                aria-expanded="false"
                                data-testid="wishlist-button"
                                aria-label="Save the property"
                                type="button"
                                className="a83ed08757 c21c56c305 bd3a1c7108 d691166b09 ab98298258 deab83296e fe94449254"
                                onClick={() => addToWishlist(item)}
                              >
                                <span className="eedba9e88a">
                                  <span
                                    className="fcd9eec8fb bf9a32efa5"
                                    aria-hidden="true"
                                  >
                                    <FaRegHeart />
                                  </span>
                                </span>
                              </button>
                            </span>
                          </span>
                        </div>
                      )}
                    </div>
                    <span className="siDistance">
                      <FaMapMarkerAlt
                        style={{ color: "black", marginRight: "5px" }}
                      />
                      {item.address.map((item) => item).join(", ")}
                    </span>
                    <span className="siSubtitle">
                      Rooms Available:{" "}
                      {item?.RoomDetails.map(
                        (item) => item.Roomname[0]?.value
                      ).join(", ")}
                    </span>
                  </div>
                  <div className="siDetails">
                    <ReviewPercentage review={review} hotelId={item._id} />
                    <div className="siDetailTexts">
                      <span style={{ fontSize: "16px", color: "black" }}>
                        Price for 1 Night
                      </span>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "flex-end",
                        }}
                      >
                        <span
                          style={{
                            color: "red",
                            fontSize: "16px",
                            textDecoration: "line-through",
                            marginRight: "10px",
                          }}
                        >
                          ₹ {parseInt(item?.Price)}
                        </span>

                        <span className="siPrice">
                          ₹ {discountedPrice ? discountedPrice : item?.Price}
                        </span>
                      </div>
                      <span className="siTaxOp">
                        +{taxedPrice ? taxedPrice : 0} taxes &amp; fees
                      </span>

                      <Link
                        to={`/hotels/${item._id}`}
                        state={{ date: date, options: options }}
                      >
                        <button className="siCheckButton">
                          See availability
                        </button>
                      </Link>
                    </div>
                  </div>
                </div>
              );
            }): currentItems.map((item) => {
              const originalPrice = parseInt(item?.Price);
              const discountString = item?.Offerprice || "";
              let discountedPrice;

              //console.log(`Discount String: ${discountString}`);

              if (discountString.endsWith("%")) {
                const discountPercentage = parseFloat(
                  discountString.replace(/[^0-9.]/g, "")
                );
                discountedPrice =
                  originalPrice - (originalPrice * discountPercentage) / 100;
              } else {
                const discountValue = parseFloat(
                  discountString.replace(/[^0-9.]/g, "")
                );
                discountedPrice = originalPrice - discountValue;
              }

              const taxInfo = item?.tax || "";
              let taxedPrice;

              if (taxInfo.endsWith("%")) {
                // If the tax is in percentage
                const taxPercentage = parseFloat(
                  taxInfo.replace(/[^0-9.]/g, "")
                );
                taxedPrice = (originalPrice * taxPercentage) / 100;
              } else if (taxInfo.endsWith("INR")) {
                // If the tax is in INR
                const taxInr = parseFloat(taxInfo.replace(/[^0-9.]/g, ""));
                taxedPrice = taxInr;
              } else {
                // Handle the case where the tax format is not recognized
                console.warn(
                  `Unrecognized tax format for item with ID ${item._id}`
                );
              }
              return (
                <div className="searchItem">
                  <Link
                    to={`/hotels/${item._id}`}
                    state={{ date: date, options: options }}
                    key={item._id}
                  >
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      {item?.BreakfastIncluded === "Yes, it's included" ? (
                        <span
                          className="siSubtitle"
                          style={{
                            backgroundColor: "#0B6E0B",
                            color: "white",
                            textAlign: "center",
                            padding: "10px",
                            borderTopLeftRadius: "5px",
                            borderTopRightRadius: "5px",
                          }}
                        >
                          Breakfast Included
                        </span>
                      ) : (
                        ""
                      )}

                      <img
                        src={`https://bookingoda.com/image/${item?.Photos[0]?.file}`}
                        alt=""
                        className="siImg"
                      />
                    </div>{" "}
                  </Link>
                  <div className="siDesc">
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                      }}
                    >
                      <div>
                        {item?.Badge?.length > 0 ? (
                          <img
                            src={`https://bookingoda.com/image/${item?.Badge[0]?.file}`}
                            style={{
                              width: "100px",
                              height: "30px",
                              marginBottom: "10px",
                            }}
                            alt=""
                          />
                        ) : (
                          ""
                        )}
                        <Link
                          to={`/hotels/${item._id}`}
                          state={{ date: date, options: options }}
                          key={item._id}
                        >
                          {" "}
                          <h1 className="siTitle">{item.PropertyName}</h1>{" "}
                        </Link>{" "}
                      </div>
                      {wishlist.find((data) => data === item?._id) ? (
                        <div
                          role="none"
                          className="f9039b381b"
                          data-testid="wishlist-icon"
                        >
                          <span className>
                            <span className="f419a93f12">
                              <button
                                aria-expanded="false"
                                data-testid="wishlist-button"
                                aria-label="Save the property"
                                type="button"
                                className="a83ed08757 c21c56c305 bd3a1c7108 d691166b09 ab98298258 deab83296e fe94449254"
                                onClick={() => RemovefromWishlist(item)}
                              >
                                <span className="eedba9e88a">
                                  <span
                                    className="fcd9eec8fb bf9a32efa5"
                                    aria-hidden="true"
                                  >
                                    <FaHeart style={{ color: "red" }} />
                                  </span>
                                </span>
                              </button>
                            </span>
                          </span>
                        </div>
                      ) : (
                        <div
                          role="none"
                          className="f9039b381b"
                          data-testid="wishlist-icon"
                        >
                          <span className>
                            <span className="f419a93f12">
                              <button
                                aria-expanded="false"
                                data-testid="wishlist-button"
                                aria-label="Save the property"
                                type="button"
                                className="a83ed08757 c21c56c305 bd3a1c7108 d691166b09 ab98298258 deab83296e fe94449254"
                                onClick={() => addToWishlist(item)}
                              >
                                <span className="eedba9e88a">
                                  <span
                                    className="fcd9eec8fb bf9a32efa5"
                                    aria-hidden="true"
                                  >
                                    <FaRegHeart />
                                  </span>
                                </span>
                              </button>
                            </span>
                          </span>
                        </div>
                      )}
                    </div>
                    <span className="siDistance">
                      <FaMapMarkerAlt
                        style={{ color: "black", marginRight: "5px" }}
                      />
                      {item.address.map((item) => item).join(", ")}
                    </span>
                    <span className="siSubtitle">
                      Rooms Available:{" "}
                      {item?.RoomDetails.map(
                        (item) => item.Roomname[0]?.value
                      ).join(", ")}
                    </span>
                  </div>
                  <div className="siDetails">
                    <ReviewPercentage review={review} hotelId={item._id} />
                    <div className="siDetailTexts">
                      <span style={{ fontSize: "16px", color: "black" }}>
                        Price for 1 Night
                      </span>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "flex-end",
                        }}
                      >
                        <span
                          style={{
                            color: "red",
                            fontSize: "16px",
                            textDecoration: "line-through",
                            marginRight: "10px",
                          }}
                        >
                          ₹ {parseInt(item?.Price)}
                        </span>

                        <span className="siPrice">
                          ₹ {discountedPrice ? discountedPrice : item?.Price}
                        </span>
                      </div>
                      <span className="siTaxOp">
                        +{taxedPrice ? taxedPrice : 0} taxes &amp; fees
                      </span>

                      <Link
                        to={`/hotels/${item._id}`}
                        state={{ date: date, options: options }}
                      >
                        <button className="siCheckButton">
                          See availability
                        </button>
                      </Link>
                    </div>
                  </div>
                </div>
              );
            })}

            <div className="blog-pagination">
              <nav>
                <ul className="pagination page-item justify-content-center">
                  {Array.from({
                    length: Math.ceil(
                      filteredItems1.length / itemsPerPage
                    ),
                  }).map((_, index) => (
                    <li
                      key={index}
                      className={`previtem ${
                        currentPage === index + 1 ? "active" : ""
                      }`}
                    >
                      <button
                        className="page-link"
                        onClick={() => handlePageChange(index + 1)}
                      >
                        <i className="fas fa-regular fa-arrow-right me-2" />{" "}
                        {index + 1}
                      </button>
                    </li>
                  ))}
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default Offers;
