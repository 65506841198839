import "../pages/list/list.css";

import React, { useCallback, useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { format } from "date-fns";
import { DateRange } from "react-date-range";
import IndianCities from "indian-cities-json";
import { GiDoorWatcher } from "react-icons/gi";
import { PiBathtubFill } from "react-icons/pi";
import { IoIosBed } from "react-icons/io";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import {
  faAnglesRight,
  faMapMarkerAlt,
} from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import { FaHeart, FaLongArrowAltRight, FaRegHeart } from "react-icons/fa";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import StarRatings from "react-star-ratings";
import { FaMapMarkerAlt } from "react-icons/fa";
import Carousel from "react-bootstrap/Carousel";
import Navbar from "./navbar/Navbar";
import Footer from "./footer/Footer";
const CityImage = ({ cityName }) => {
  const [imageUrl, setImageUrl] = useState("");

  useEffect(() => {
    const fetchImage = async () => {
      try {
        const response = await fetch(
          `https://api.unsplash.com/photos/random?query=${cityName}&client_id=cSSwA-73tDd1tFdcmFgqQYv3IwS_oRlndXmFYfdG3hs`
        );

        const data = await response.json();

        if (data.urls && data.urls.full) {
          setImageUrl(data.urls.full);
        } else {
          console.error("Image not found");
          setImageUrl(
            "https://cdn.pixabay.com/photo/2021/05/13/07/18/sculpture-6250058_1280.jpg"
          );
        }
      } catch (error) {
        console.error("Error fetching image:", error);
      }
    };

    fetchImage();
  }, [cityName]);

  return (
    <img
      src={imageUrl}
      alt={`City: ${cityName}`}
      class="featuredImg12 img-fluid"
    />
  );
};

const Offers2 = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [places, setPlaces] = useState([]);

  const getvacation = async () => {
    let res = await axios.get("https://bookingoda.com/api/admin/getvacation");
    if (res.status === 200) {
      //////console.log(res.data);
      setPlaces(
        res.data.getvacation.filter((item) => item.blockstatus !== true)
      );
    }
  };
  useEffect(() => {
    getvacation();
  }, []);
  const user = JSON.parse(sessionStorage.getItem("user"));

  const itemsPerPage = 10; // Number of cards per page
  const [currentPage, setCurrentPage] = useState(1);
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;

  // Get the current page's items
  const currentItems = places.slice(startIndex, endIndex);

  // Handle page change
  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };
  const [destination, setDestination] = useState("");
  console.log(destination);
  const filteredItems1 =
    destination !== ""
      ? places.filter((item) => item.state === destination)
      : places;
  console.log(filteredItems1);
  const [filteredItems, setFilteredItems] = useState([]);
  const filterByOfferPrice = (min, max) => {
    // Filter the tour packages based on the offer price range
    const filteredData = filteredItems1.filter((item) => {
      const offerPrice = parseInt(item.staydiscount); // Parse trip cost to integer
      return offerPrice >= min && offerPrice <= max;
    });

    // Update the filtered items state
    setFilteredItems(filteredData);
  };
  const calculateFilteredItemsLength = (minPrice, maxPrice) => {
    // Filter the list based on the offer price range
    const filteredList = filteredItems1.filter((product) => {
      const offerPrice = parseInt(product.staydiscount);
      return offerPrice >= minPrice && offerPrice <= maxPrice;
    });

    // Return the length of the filtered list
    return filteredList.length;
  };

  // Use the function to calculate the length for each offer price range
  const length1to4 = calculateFilteredItemsLength(1, 4);

  const length5to9 = calculateFilteredItemsLength(5, 9);
  const length10to19 = calculateFilteredItemsLength(10, 19);
  const length20above = calculateFilteredItemsLength(20, 100);

  const citys1 = places.map((e) => e.state);
  const uniqueAddresses1 = [...new Set(citys1.flat())];
  const catresponsive = {
    0: {
      items: 1,
    },
    600: {
      items: 2,
    },
    1000: {
      items: 4,
    },
  };
  return (
    <div>
      <Navbar />
      <div class="header12 listMode">
        <div class="headerContainer12 container12">
          <div class="headerCont12">
            <div>
              <h2
                class="headerTitle12 text-center"
                style={{ marginTop: "50px" }}
              >
                Vacations Offers
              </h2>
            </div>
          </div>
        </div>
      </div>
      <OwlCarousel
        className="topdeals-carousel"
        loop
        margin={10}
        nav={true}
        autoplayTimeout={5000}
        responsive={catresponsive}
        autoplay={true}
        autoHeightClass="owl-height"
      >
        {uniqueAddresses1?.map((e) => (
          <div class="col-md-6 col-lg-3 col-6">
            <div class="featuredItem12" style={{height:"200px"}}>
              <div onClick={() => setDestination(e)}>
                <CityImage cityName={e} />
                <div class="featuredTitles12">
                  <h1>{e}</h1>
                  <h2>{citys1.filter((d) => d === e)?.length} properties</h2>
                </div>{" "}
              </div>
            </div>
          </div>
        ))}
      </OwlCarousel>
      <div className="row" style={{ marginTop: "50px" }}>
        <div className="col-lg-3">
          <div className="listSearch">
            <h1 className="lsTitle">Filter</h1>
            <div className="filterRow " id="PRICE_BUCKET">
              <div className="latoBold font16 blackText appendBottom15 makeFlex hrtlCenter">
                Discounts
              </div>
              <div className="priceBucketFilter">
                <ul className="filterList">
                  <li>
                    <button onClick={() => filterByOfferPrice(1, 4)}>
                      Below 5%
                    </button>
                    <span className="font12 grayText">({length1to4})</span>
                  </li>
                  <li>
                    <button onClick={() => filterByOfferPrice(5, 9)}>
                      5% - 9%
                    </button>
                    <span className="font12 grayText">({length5to9})</span>
                  </li>
                  <li>
                    <button onClick={() => filterByOfferPrice(10, 19)}>
                      10% - 19%
                    </button>
                    <span className="font12 grayText">({length10to19})</span>
                  </li>
                  <li>
                    <button onClick={() => filterByOfferPrice(20, 100)}>
                      20% and above
                    </button>
                    <span className="font12 grayText">({length20above})</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-9">
          <div
            class="containerpil inn-page-con-bg tb-space pad-bot-redu-5"
            id="inner-page-title"
          >
           
              <div className="elementor-widget-containerv"  style={{
                         
                         display:'flex',
                         flexDirection:'row',
                         width: "900px",
                         flexWrap:'wrap',
                        
                       }}>
            
                  <div
                    className="js-slick-carousel u-slick u-slick--equal-height u-slick--gutters-3 mytravel-65d8783e2b581 slick-initialized slick-slider slick-dotted"
                    style={{ display:'flex',flexDirection:'row', flexWrap:'wrap' }}
                  >
                  
                     
                        {filteredItems.length > 0
                          ? filteredItems.map((data) => (
                              <div
                                className="js-slide slick-slide1 slick-current slick-active1"
                               
                                style={{ maxWidth: "300px", height: "auto" }}
                              >
                                <div className="add-to-wishlist-after_add_to_cart product type-product post-218 status-publish first instock product_cat-coupes product_cat-rental has-post-thumbnail featured shipping-taxable purchasable product-type-simple mb-3 mb-md-4 pb-1">
                                  <div className="cardv transition-3d-hover shadow-hover-2 h-100">
                                    <div className="cardv-header position-relative p-0">
                                      <Link to={`/Vacationdetails/${data._id}`}>
                                        <img
                                          fetchpriority="high"
                                          decoding="async"
                                          width={300}
                                          height={225}
                                          src={`https://bookingoda.com/image/${data.GalleryImage[0]?.image}`}
                                          className="min-height-230 bg-img-hero cardv-img-top"
                                          alt=""
                                        />{" "}
                                      </Link>

                                      <div className="price-wrapper position-absolute bottom-0 left-0 right-0">
                                        <div className="px-3 pb-2">
                                          <a
                                            className="stretched-link cardv-title text-dark"
                                            tabIndex={0}
                                          >
                                            <h2 className="woocommerce-loop-product__title font-size-17 text-white font-weight-bold">
                                              {data.stayname}
                                            </h2>
                                          </a>{" "}
                                          <span className="price">
                                            <span className="woocommerce-Price-amount font-weight-bold font-size-19 mb-2 d-inline-block text-white amount">
                                              <bdi>
                                                <span className="woocommerce-Price-currencySymbol">
                                                  INR
                                                </span>
                                                {data.staycost}
                                              </bdi>
                                            </span>
                                            <span className="wceb-price-format">
                                              {" "}
                                              / night
                                            </span>{" "}
                                          </span>
                                        </div>
                                      </div>
                                    </div>

                                    <div
                                      className="cardv-body
            border-bottom"
                                    >
                                      <Link to={`/Vacationdetails/${data._id}`}>
                                        <div className="font-size-14 text-gray-1">
                                          <FontAwesomeIcon
                                            icon={faMapMarkerAlt}
                                            style={{ color: "#69a3cf" }}
                                            className="icon flaticon-pin-1 mr-2 font-size-24"
                                          />{data?.street}
                                          {data.city},{data.state},
                                          {data.country}{" "}
                                        </div>
                                      </Link>
                                    </div>
                                    <div className="">
                                      <div className="row">
                                        <div className="col-6">
                                          {" "}
                                          <div className="media text-gray-1 align-items-center">
                                            <small className="mr-2">
                                              <i
                                                className="fas fa-chalkboard font-size-24 small"
                                                style={{ color: "#69a3cf" }}
                                              />
                                            </small>
                                            <div className="media-body font-size-1">
                                              {data.Measurement} sq ft
                                            </div>
                                          </div>
                                        </div>
                                        <div className="col-6">
                                          {" "}
                                          <div className="media mb-2 text-gray-1 align-items-center">
                                            <small className="mr-2">
                                              <GiDoorWatcher
                                                className="fas fa-chalkboard font-size-24 small"
                                                style={{ color: "#69a3cf" }}
                                              />
                                            </small>
                                            <div className="media-body font-size-1">
                                              {data.Rooms} rooms
                                            </div>
                                          </div>
                                        </div>
                                        <div className="col-6">
                                          {" "}
                                          <div className="media mb-2 text-gray-1 align-items-center">
                                            <small className="mr-2">
                                              <PiBathtubFill
                                                className="fas fa-chalkboard font-size-24 small"
                                                style={{ color: "#69a3cf" }}
                                              />
                                            </small>
                                            <div className="media-body font-size-1">
                                              {data.Bathrooms} bathrooms
                                            </div>
                                          </div>
                                        </div>
                                        <div className="col-6">
                                          {" "}
                                          <div className="media mb-2 text-gray-1 align-items-center">
                                            <small className="mr-2">
                                              <IoIosBed
                                                className="flaticon-bed-1 font-size-24 small"
                                                style={{ color: "#69a3cf" }}
                                              />
                                            </small>
                                            <div className="media-body font-size-1">
                                              {data.Beds} beds
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ))
                          : filteredItems1.length !== places.length
                          ? filteredItems1.map((data) => (
                              <div
                                className="js-slide slick-slide1 slick-current slick-active1"
                                
                                style={{ maxWidth: "300px", height: "auto" }}
                              >
                                <div className="add-to-wishlist-after_add_to_cart product type-product post-218 status-publish first instock product_cat-coupes product_cat-rental has-post-thumbnail featured shipping-taxable purchasable product-type-simple mb-3 mb-md-4 pb-1">
                                  <div className="cardv transition-3d-hover shadow-hover-2 h-100">
                                    <div className="cardv-header position-relative p-0">
                                      <Link to={`/Vacationdetails/${data._id}`}>
                                        <img
                                          fetchpriority="high"
                                          decoding="async"
                                          width={300}
                                          height={225}
                                          src={`https://bookingoda.com/image/${data.GalleryImage[0]?.image}`}
                                          className="min-height-230 bg-img-hero cardv-img-top"
                                          alt=""
                                        />{" "}
                                      </Link>

                                      <div className="price-wrapper position-absolute bottom-0 left-0 right-0">
                                        <div className="px-3 pb-2">
                                          <a
                                            className="stretched-link cardv-title text-dark"
                                            tabIndex={0}
                                          >
                                            <h2 className="woocommerce-loop-product__title font-size-17 text-white font-weight-bold">
                                              {data.stayname}
                                            </h2>
                                          </a>{" "}
                                          <span className="price">
                                            <span className="woocommerce-Price-amount font-weight-bold font-size-19 mb-2 d-inline-block text-white amount">
                                              <bdi>
                                                <span className="woocommerce-Price-currencySymbol">
                                                  INR
                                                </span>
                                                {data.staycost}
                                              </bdi>
                                            </span>
                                            <span className="wceb-price-format">
                                              {" "}
                                              / night
                                            </span>{" "}
                                          </span>
                                        </div>
                                      </div>
                                    </div>

                                    <div
                                      className="cardv-body
            border-bottom"
                                    >
                                      <Link to={`/Vacationdetails/${data._id}`}>
                                        <div className="font-size-14 text-gray-1">
                                          <FontAwesomeIcon
                                            icon={faMapMarkerAlt}
                                            style={{ color: "#69a3cf" }}
                                            className="icon flaticon-pin-1 mr-2 font-size-24"
                                          />
                                          {data?.street}
                                          {data.city},{data.state},
                                          {data.country}{" "}
                                        </div>
                                      </Link>
                                    </div>
                                    <div className="">
                                      <div className="row">
                                        <div className="col-6">
                                          {" "}
                                          <div className="media text-gray-1 align-items-center">
                                            <small className="mr-2">
                                              <i
                                                className="fas fa-chalkboard font-size-24 small"
                                                style={{ color: "#69a3cf" }}
                                              />
                                            </small>
                                            <div className="media-body font-size-1">
                                              {data.Measurement} sq ft
                                            </div>
                                          </div>
                                        </div>
                                        <div className="col-6">
                                          {" "}
                                          <div className="media mb-2 text-gray-1 align-items-center">
                                            <small className="mr-2">
                                              <GiDoorWatcher
                                                className="fas fa-chalkboard font-size-24 small"
                                                style={{ color: "#69a3cf" }}
                                              />
                                            </small>
                                            <div className="media-body font-size-1">
                                              {data.Rooms} rooms
                                            </div>
                                          </div>
                                        </div>
                                        <div className="col-6">
                                          {" "}
                                          <div className="media mb-2 text-gray-1 align-items-center">
                                            <small className="mr-2">
                                              <PiBathtubFill
                                                className="fas fa-chalkboard font-size-24 small"
                                                style={{ color: "#69a3cf" }}
                                              />
                                            </small>
                                            <div className="media-body font-size-1">
                                              {data.Bathrooms} bathrooms
                                            </div>
                                          </div>
                                        </div>
                                        <div className="col-6">
                                          {" "}
                                          <div className="media mb-2 text-gray-1 align-items-center">
                                            <small className="mr-2">
                                              <IoIosBed
                                                className="flaticon-bed-1 font-size-24 small"
                                                style={{ color: "#69a3cf" }}
                                              />
                                            </small>
                                            <div className="media-body font-size-1">
                                              {data.Beds} beds
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ))
                          : currentItems.map((data) => (
                              <div
                                className="js-slide slick-slide1 slick-current slick-active1"
                               
                                style={{ maxWidth: "300px", height: "auto" }}
                              >
                                <div className="add-to-wishlist-after_add_to_cart product type-product post-218 status-publish first instock product_cat-coupes product_cat-rental has-post-thumbnail featured shipping-taxable purchasable product-type-simple mb-3 mb-md-4 pb-1">
                                  <div className="cardv transition-3d-hover shadow-hover-2 h-100">
                                    <div className="cardv-header position-relative p-0">
                                      <Link to={`/Vacationdetails/${data._id}`}>
                                        <img
                                          fetchpriority="high"
                                          decoding="async"
                                          width={300}
                                          height={225}
                                          src={`https://bookingoda.com/image/${data.GalleryImage[0]?.image}`}
                                          className="min-height-230 bg-img-hero cardv-img-top"
                                          alt=""
                                        />{" "}
                                      </Link>

                                      <div className="price-wrapper position-absolute bottom-0 left-0 right-0">
                                        <div className="px-3 pb-2">
                                          <a
                                            className="stretched-link cardv-title text-dark"
                                            tabIndex={0}
                                          >
                                            <h2 className="woocommerce-loop-product__title font-size-17 text-white font-weight-bold">
                                              {data.stayname}
                                            </h2>
                                          </a>{" "}
                                          <span className="price">
                                            <span className="woocommerce-Price-amount font-weight-bold font-size-19 mb-2 d-inline-block text-white amount">
                                              <bdi>
                                                <span className="woocommerce-Price-currencySymbol">
                                                  INR
                                                </span>
                                                {data.staycost}
                                              </bdi>
                                            </span>
                                            <span className="wceb-price-format">
                                              {" "}
                                              / night
                                            </span>{" "}
                                          </span>
                                        </div>
                                      </div>
                                    </div>

                                    <div
                                      className="cardv-body
					border-bottom"
                                    >
                                      <Link to={`/Vacationdetails/${data._id}`}>
                                        <div className="font-size-14 text-gray-1">
                                          <FontAwesomeIcon
                                            icon={faMapMarkerAlt}
                                            style={{ color: "#69a3cf" }}
                                            className="icon flaticon-pin-1 mr-2 font-size-24"
                                          />
                                          {data?.street}
                                          {data.city},{data.state},
                                          {data.country}{" "}
                                        </div>
                                      </Link>
                                    </div>
                                    <div className="">
                                      <div className="row">
                                        <div className="col-6">
                                          {" "}
                                          <div className="media text-gray-1 align-items-center">
                                            <small className="mr-2">
                                              <i
                                                className="fas fa-chalkboard font-size-24 small"
                                                style={{ color: "#69a3cf" }}
                                              />
                                            </small>
                                            <div className="media-body font-size-1">
                                              {data.Measurement} sq ft
                                            </div>
                                          </div>
                                        </div>
                                        <div className="col-6">
                                          {" "}
                                          <div className="media mb-2 text-gray-1 align-items-center">
                                            <small className="mr-2">
                                              <GiDoorWatcher
                                                className="fas fa-chalkboard font-size-24 small"
                                                style={{ color: "#69a3cf" }}
                                              />
                                            </small>
                                            <div className="media-body font-size-1">
                                              {data.Rooms} rooms
                                            </div>
                                          </div>
                                        </div>
                                        <div className="col-6">
                                          {" "}
                                          <div className="media mb-2 text-gray-1 align-items-center">
                                            <small className="mr-2">
                                              <PiBathtubFill
                                                className="fas fa-chalkboard font-size-24 small"
                                                style={{ color: "#69a3cf" }}
                                              />
                                            </small>
                                            <div className="media-body font-size-1">
                                              {data.Bathrooms} bathrooms
                                            </div>
                                          </div>
                                        </div>
                                        <div className="col-6">
                                          {" "}
                                          <div className="media mb-2 text-gray-1 align-items-center">
                                            <small className="mr-2">
                                              <IoIosBed
                                                className="flaticon-bed-1 font-size-24 small"
                                                style={{ color: "#69a3cf" }}
                                              />
                                            </small>
                                            <div className="media-body font-size-1">
                                              {data.Beds} beds
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ))}
                      </div>
                   
                
              
              </div>
          
          </div>
          <div className="blog-pagination">
            <nav>
              <ul className="pagination page-item justify-content-center">
                {Array.from({
                  length: Math.ceil(filteredItems1.length / itemsPerPage),
                }).map((_, index) => (
                  <li
                    key={index}
                    className={`previtem ${
                      currentPage === index + 1 ? "active" : ""
                    }`}
                  >
                    <button
                      className="page-link"
                      onClick={() => handlePageChange(index + 1)}
                    >
                      <i className="fas fa-regular fa-arrow-right me-2" />{" "}
                      {index + 1}
                    </button>
                  </li>
                ))}
              </ul>
            </nav>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default Offers2;
