import React, { useState } from 'react'
import { FaDirections } from 'react-icons/fa';

const Services = () => {
    const [GasStation, setGasStation] = useState(true);
    const [ATM, setATM] = useState(false);
    const [AutoStand, setAutoStand] = useState(false);
    const [Airport, setAirport] = useState(false);
    const [ElectricCharging, setElectricCharging] = useState(false);
    const [BusStand, setBusStand] = useState(false);
  return (
    <div>
        <div className="container">
            <div className="row mt-4">
                <div className="col-md-12">
                <button
              className=" btn"
              style={{ backgroundColor: "#DA291C", color: "White" }}
              onClick={() => {
                setGasStation(true);
                setATM(false);
                setAutoStand(false);
                setAirport(false);
                setElectricCharging(false);
                setBusStand(false);
              }}
            >
             Gas Station
            </button>{" "}
            &nbsp;
            <button
              className=" btn"
              style={{ backgroundColor: "#DA291C", color: "White" }}
              onClick={() => {
                setGasStation(false);
                setATM(true);
                setAutoStand(false);
                setAirport(false);
                setElectricCharging(false);
                setBusStand(false);
              }}
            >
              ATM 
            </button>{" "}
            &nbsp;
            <button
              className=" btn"
              style={{ backgroundColor: "#DA291C", color: "White" }}
              onClick={() => {
                setGasStation(false);
                setATM(false);
                setAutoStand(true);
                setAirport(false);
                setElectricCharging(false);
                setBusStand(false);
              }}
            >
              AutoStand 
            </button>{" "}
            &nbsp;
            <button
              className=" btn"
              style={{ backgroundColor: "#DA291C", color: "White" }}
              onClick={() => {
                setGasStation(false);
                setATM(false);
                setAutoStand(false);
                setAirport(true);
                setElectricCharging(false);
                setBusStand(false);
              }}
            >
              Airport 
            </button>{" "}
            &nbsp;
            <button
              className=" btn"
              style={{ backgroundColor: "#DA291C", color: "White" }}
              onClick={() => {
                setGasStation(false);
                setATM(false);
                setAutoStand(false);
                setAirport(false);
                setElectricCharging(true);
                setBusStand(false);
              }}
            >
              Electric Charging 
            </button>{" "}
            &nbsp;
            <button
              className=" btn"
              style={{ backgroundColor: "#DA291C", color: "White" }}
              onClick={() => {
                setGasStation(false);
                setATM(false);
                setAutoStand(false);
                setAirport(false);
                setElectricCharging(false);
                setBusStand(true);
              }}
            >
              Bus Stand 
            </button>{" "}
            &nbsp;
                </div>

            </div>
        </div>

        <div className="container">
            <div className="row">
                <div className="col-md-12">
                    {GasStation?(<>
                        <div className="row">
                  <div className="col-md-6">
                    <div
                      className="row"
                      style={{
                        border: "1px solid #DDDDDD",
                        borderRadius: "10px",
                        padding: "20px",
                      }}
                    >
                      <div className="col-md-8">
                        <h5>Bharat Petroleum</h5>
                        <p>
                        Petrol Pump <br />
                          4th cross Rd, Tirumala Delights, Tirumala Nagar Rd
                        </p>
                        <p>Open-Closes at 11pm</p>
                        <br />
                        {/* <p>Dinein-Takeaway-Delivery</p> */}
                      </div>
                      <div className="col-md-4">
                        <img
                          src="/img/white.jpg"
                          alt=""
                          style={{
                            width: "auto",
                            height: "150px",
                            borderRadius: "15px",
                          }}
                        />
                        <div
                          className="direction text-center"
                          style={{ padding: "10px",color:"#DA291C",marginLeft:"-2rem" }}
                        >
                          <p>
                            <FaDirections />
                          </p>
                          <h6>Directions</h6>
                        </div>
                      </div>

                      <div className="col-md-12">
                        <hr />
                      </div>

                      <div className="col-md-8">
                        <h5>Bharat Petroleum</h5>
                        <p>
                        Petrol Pump<br />
                          4th cross Rd, Tirumala Delights, Tirumala Nagar Rd
                        </p>
                        <p>Open-Closes at 11pm</p>
                        <br />
                        {/* <p>Dinein-Takeaway-Delivery</p> */}
                      </div>
                      <div className="col-md-4">
                        <img
                          src="/img/white.jpg"
                          alt=""
                          style={{
                            width: "auto",
                            height: "150px",
                            borderRadius: "15px",
                          }}
                        />
                        <div
                          className="direction text-center"
                          style={{ padding: "10px",color:"#DA291C",marginLeft:"-2rem" }}
                        >
                          <p>
                            <FaDirections />
                          </p>
                          <h6>Directions</h6>
                        </div>
                      </div>

                      <div className="col-md-12">
                        <hr />
                      </div>

                      <div className="col-md-8">
                        <h5>Bharat Petroleum</h5>
                        <p>
                        Petrol Pump <br />
                          4th cross Rd, Tirumala Delights, Tirumala Nagar Rd
                        </p>
                        <p>Open-Closes at 11pm</p>
                        <br />
                        {/* <p>Dinein-Takeaway-Delivery</p> */}
                      </div>
                      <div className="col-md-4">
                        <img
                          src="/img/white.jpg"
                          alt=""
                          style={{
                            width: "auto",
                            height: "150px",
                            borderRadius: "15px",
                          }}
                        />
                        <div
                          className="direction text-center"
                          style={{ padding: "10px",color:"#DA291C",marginLeft:"-2rem"}}
                        >
                          <p>
                            <FaDirections />
                          </p>
                          <h6>Directions</h6>
                        </div>
                      </div>

                      <div className="col-md-12">
                        <hr />
                      </div>

                      <div className="col-md-8">
                        <h5>Bharat Petroleum</h5>
                        <p>
                          Petrol Pump <br />
                          4th cross Rd, Tirumala Delights, Tirumala Nagar Rd
                        </p>
                        <p>Open-Closes at 11pm</p>
                        <br />
                        {/* <p>Dinein-Takeaway-Delivery</p> */}
                      </div>
                      <div className="col-md-4">
                        <img
                          src="/img/white.jpg"
                          alt=""
                          style={{
                            width: "auto",
                            height: "150px",
                            borderRadius: "15px",
                          }}
                        />
                        <div
                          className="direction text-center"
                          style={{ padding: "10px",color:"#DA291C",marginLeft:"-2rem" }}
                        >
                          <p>
                            <FaDirections />
                          </p>
                          <h6>Directions</h6>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="col-md-6"
                    style={{ paddingLeft: "unset", paddingRight: "unset" }}
                  >
                    <iframe
                      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d31090.264129437837!2d77.53480077779398!3d13.081241386031335!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae229f7a8debe1%3A0x4d80f1d259c7e7ac!2sVidyaranyapura%2C%20Bengaluru%2C%20Karnataka!5e0!3m2!1sen!2sin!4v1702035015935!5m2!1sen!2sin"
                      style={{
                        width: "100%",
                        // marginTop: "2rem",
                        height: "450px",
                      }}
                      allowfullscreen=""
                      loading="lazy"
                      referrerpolicy="no-referrer-when-downgrade"
                    ></iframe>
                  </div>
                </div>
                    </>):(<>
                    {ATM?(<>
                        <div className="row">
                  <div className="col-md-6">
                    <div
                      className="row"
                      style={{
                        border: "1px solid #DDDDDD",
                        borderRadius: "10px",
                        padding: "20px",
                      }}
                    >
                      <div className="col-md-8">
                        <h5>Canara Bank ATM</h5>
                        <p>
                        ATM<br />
                          4th cross Rd, Tirumala Delights, Tirumala Nagar Rd
                        </p>
                        <p>Open-Closes at 11pm</p>
                        <br />
                        {/* <p>Dinein-Takeaway-Delivery</p> */}
                      </div>
                      <div className="col-md-4">
                        <img
                          src="/img/white.jpg"
                          alt=""
                          style={{
                            width: "auto",
                            height: "150px",
                            borderRadius: "15px",
                          }}
                        />
                        <div
                          className="direction text-center"
                          style={{ padding: "10px",color:"#DA291C",marginLeft:"-2rem" }}
                        >
                          <p>
                            <FaDirections />
                          </p>
                          <h6>Directions</h6>
                        </div>
                      </div>

                      <div className="col-md-12">
                        <hr />
                      </div>

                      <div className="col-md-8">
                        <h5>Canara Bank ATM</h5>
                        <p>
                        ATM<br />
                          4th cross Rd, Tirumala Delights, Tirumala Nagar Rd
                        </p>
                        <p>Open-Closes at 11pm</p>
                        <br />
                        {/* <p>Dinein-Takeaway-Delivery</p> */}
                      </div>
                      <div className="col-md-4">
                        <img
                          src="/img/white.jpg"
                          alt=""
                          style={{
                            width: "auto",
                            height: "150px",
                            borderRadius: "15px",
                          }}
                        />
                        <div
                          className="direction text-center"
                          style={{ padding: "10px",color:"#DA291C",marginLeft:"-2rem" }}
                        >
                          <p>
                            <FaDirections />
                          </p>
                          <h6>Directions</h6>
                        </div>
                      </div>

                      <div className="col-md-12">
                        <hr />
                      </div>

                      <div className="col-md-8">
                        <h5>Canara Bank ATM</h5>
                        <p>
                        ATM <br />
                          4th cross Rd, Tirumala Delights, Tirumala Nagar Rd
                        </p>
                        <p>Open-Closes at 11pm</p>
                        <br />
                        {/* <p>Dinein-Takeaway-Delivery</p> */}
                      </div>
                      <div className="col-md-4">
                        <img
                          src="/img/white.jpg"
                          alt=""
                          style={{
                            width: "auto",
                            height: "150px",
                            borderRadius: "15px",
                          }}
                        />
                        <div
                          className="direction text-center"
                          style={{ padding: "10px",color:"#DA291C",marginLeft:"-2rem" }}
                        >
                          <p>
                            <FaDirections />
                          </p>
                          <h6>Directions</h6>
                        </div>
                      </div>

                      <div className="col-md-12">
                        <hr />
                      </div>

                      <div className="col-md-8">
                        <h5>Canara Bank ATM</h5>
                        <p>
                          ATM <br />
                          4th cross Rd, Tirumala Delights, Tirumala Nagar Rd
                        </p>
                        <p>Open-Closes at 11pm</p>
                        <br />
                        {/* <p>Dinein-Takeaway-Delivery</p> */}
                      </div>
                      <div className="col-md-4">
                        <img
                          src="/img/white.jpg"
                          alt=""
                          style={{
                            width: "auto",
                            height: "150px",
                            borderRadius: "15px",
                          }}
                        />
                        <div
                          className="direction text-center"
                          style={{ padding: "10px",color:"#DA291C" ,marginLeft:"-2rem"}}
                        >
                          <p>
                            <FaDirections />
                          </p>
                          <h6>Directions</h6>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="col-md-6"
                    style={{ paddingLeft: "unset", paddingRight: "unset" }}
                  >
                    <iframe
                      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d31090.264129437837!2d77.53480077779398!3d13.081241386031335!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae229f7a8debe1%3A0x4d80f1d259c7e7ac!2sVidyaranyapura%2C%20Bengaluru%2C%20Karnataka!5e0!3m2!1sen!2sin!4v1702035015935!5m2!1sen!2sin"
                      style={{
                        width: "100%",
                        // marginTop: "2rem",
                        height: "450px",
                      }}
                      allowfullscreen=""
                      loading="lazy"
                      referrerpolicy="no-referrer-when-downgrade"
                    ></iframe>
                  </div>
                </div>
                    </>):(<>
                    {AutoStand?(<>
                        <div className="row">
                  <div className="col-md-6">
                    <div
                      className="row"
                      style={{
                        border: "1px solid #DDDDDD",
                        borderRadius: "10px",
                        padding: "20px",
                      }}
                    >
                      <div className="col-md-8">
                        <h5>Auto Stand</h5>
                        <p>
                        Auto Stand<br />
                          4th cross Rd, Tirumala Delights, Tirumala Nagar Rd
                        </p>
                        <p>Open-Closes at 11pm</p>
                        <br />
                        {/* <p>Dinein-Takeaway-Delivery</p> */}
                      </div>
                      <div className="col-md-4">
                        <img
                          src="/img/white.jpg"
                          alt=""
                          style={{
                            width: "auto",
                            height: "150px",
                            borderRadius: "15px",
                          }}
                        />
                        <div
                          className="direction text-center"
                          style={{ padding: "10px",color:"#DA291C",marginLeft:"-2rem" }}
                        >
                          <p>
                            <FaDirections />
                          </p>
                          <h6>Directions</h6>
                        </div>
                      </div>

                      <div className="col-md-12">
                        <hr />
                      </div>

                      <div className="col-md-8">
                        <h5>Auto Stand</h5>
                        <p>
                        Auto Stand<br />
                          4th cross Rd, Tirumala Delights, Tirumala Nagar Rd
                        </p>
                        <p>Open-Closes at 11pm</p>
                        <br />
                        {/* <p>Dinein-Takeaway-Delivery</p> */}
                      </div>
                      <div className="col-md-4">
                        <img
                          src="/img/white.jpg"
                          alt=""
                          style={{
                            width: "auto",
                            height: "150px",
                            borderRadius: "15px",
                          }}
                        />
                        <div
                          className="direction text-center"
                          style={{ padding: "10px",color:"#DA291C",marginLeft:"-2rem" }}
                        >
                          <p>
                            <FaDirections />
                          </p>
                          <h6>Directions</h6>
                        </div>
                      </div>

                      <div className="col-md-12">
                        <hr />
                      </div>

                      <div className="col-md-8">
                        <h5>Auto Stand</h5>
                        <p>
                        Auto Stand <br />
                          4th cross Rd, Tirumala Delights, Tirumala Nagar Rd
                        </p>
                        <p>Open-Closes at 11pm</p>
                        <br />
                        {/* <p>Dinein-Takeaway-Delivery</p> */}
                      </div>
                      <div className="col-md-4">
                        <img
                          src="/img/white.jpg"
                          alt=""
                          style={{
                            width: "auto",
                            height: "150px",
                            borderRadius: "15px",
                          }}
                        />
                        <div
                          className="direction text-center"
                          style={{ padding: "10px",color:"#DA291C",marginLeft:"-2rem" }}
                        >
                          <p>
                            <FaDirections />
                          </p>
                          <h6>Directions</h6>
                        </div>
                      </div>

                      <div className="col-md-12">
                        <hr />
                      </div>

                      <div className="col-md-8">
                        <h5>Auto Stand</h5>
                        <p>
                        Auto Stand <br />
                          4th cross Rd, Tirumala Delights, Tirumala Nagar Rd
                        </p>
                        <p>Open-Closes at 11pm</p>
                        <br />
                        {/* <p>Dinein-Takeaway-Delivery</p> */}
                      </div>
                      <div className="col-md-4">
                        <img
                          src="/img/white.jpg"
                          alt=""
                          style={{
                            width: "auto",
                            height: "150px",
                            borderRadius: "15px",
                          }}
                        />
                        <div
                          className="direction text-center"
                          style={{ padding: "10px",color:"#DA291C",marginLeft:"-2rem" }}
                        >
                          <p>
                            <FaDirections />
                          </p>
                          <h6>Directions</h6>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="col-md-6"
                    style={{ paddingLeft: "unset", paddingRight: "unset" }}
                  >
                    <iframe
                      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d31090.264129437837!2d77.53480077779398!3d13.081241386031335!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae229f7a8debe1%3A0x4d80f1d259c7e7ac!2sVidyaranyapura%2C%20Bengaluru%2C%20Karnataka!5e0!3m2!1sen!2sin!4v1702035015935!5m2!1sen!2sin"
                      style={{
                        width: "100%",
                        // marginTop: "2rem",
                        height: "450px",
                      }}
                      allowfullscreen=""
                      loading="lazy"
                      referrerpolicy="no-referrer-when-downgrade"
                    ></iframe>
                  </div>
                </div>
                    </>):(<>
                    {Airport?(<>
                        <div className="row">
                  <div className="col-md-6">
                    <div
                      className="row"
                      style={{
                        border: "1px solid #DDDDDD",
                        borderRadius: "10px",
                        padding: "20px",
                      }}
                    >
                      <div className="col-md-8">
                        <h5>Kempegowda International Airport</h5>
                        <p>
                        Airport<br />
                          4th cross Rd, Tirumala Delights, Tirumala Nagar Rd
                        </p>
                        <p>Open-Closes at 11pm</p>
                        <br />
                        {/* <p>Dinein-Takeaway-Delivery</p> */}
                      </div>
                      <div className="col-md-4">
                        <img
                          src="/img/white.jpg"
                          alt=""
                          style={{
                            width: "auto",
                            height: "150px",
                            borderRadius: "15px",
                          }}
                        />
                        <div
                          className="direction text-center"
                          style={{ padding: "10px",color:"#DA291C",marginLeft:"-2rem" }}
                        >
                          <p>
                            <FaDirections />
                          </p>
                          <h6>Directions</h6>
                        </div>
                      </div>

                      <div className="col-md-12">
                        <hr />
                      </div>

                      <div className="col-md-8">
                        <h5>HAL Airport</h5>
                        <p>
                        Airport<br />
                          4th cross Rd, Tirumala Delights, Tirumala Nagar Rd
                        </p>
                        <p>Open-Closes at 11pm</p>
                        <br />
                        {/* <p>Dinein-Takeaway-Delivery</p> */}
                      </div>
                      <div className="col-md-4">
                        <img
                          src="/img/white.jpg"
                          alt=""
                          style={{
                            width: "auto",
                            height: "150px",
                            borderRadius: "15px",
                          }}
                        />
                        <div
                          className="direction text-center"
                          style={{ padding: "10px",color:"#DA291C",marginLeft:"-2rem" }}
                        >
                          <p>
                            <FaDirections />
                          </p>
                          <h6>Directions</h6>
                        </div>
                      </div>

                      <div className="col-md-12">
                        <hr />
                      </div>

                     
                    </div>
                  </div>
                  <div
                    className="col-md-6"
                    style={{ paddingLeft: "unset", paddingRight: "unset" }}
                  >
                    <iframe
                      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d31090.264129437837!2d77.53480077779398!3d13.081241386031335!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae229f7a8debe1%3A0x4d80f1d259c7e7ac!2sVidyaranyapura%2C%20Bengaluru%2C%20Karnataka!5e0!3m2!1sen!2sin!4v1702035015935!5m2!1sen!2sin"
                      style={{
                        width: "100%",
                        // marginTop: "2rem",
                        height: "450px",
                      }}
                      allowfullscreen=""
                      loading="lazy"
                      referrerpolicy="no-referrer-when-downgrade"
                    ></iframe>
                  </div>
                </div>
                    </>):(<>
                    {ElectricCharging?(<>
                        <div className="row">
                  <div className="col-md-6">
                    <div
                      className="row"
                      style={{
                        border: "1px solid #DDDDDD",
                        borderRadius: "10px",
                        padding: "20px",
                      }}
                    >
                      <div className="col-md-8">
                        <h5>Zeon Charging Station </h5>
                        <p>
                        Charging Station<br />
                          4th cross Rd, Tirumala Delights, Tirumala Nagar Rd
                        </p>
                        <p>Open-Closes at 11pm</p>
                        <br />
                        {/* <p>Dinein-Takeaway-Delivery</p> */}
                      </div>
                      <div className="col-md-4">
                        <img
                          src="/img/white.jpg"
                          alt=""
                          style={{
                            width: "auto",
                            height: "150px",
                            borderRadius: "15px",
                          }}
                        />
                        <div
                          className="direction text-center"
                          style={{ padding: "10px",color:"#DA291C",marginLeft:"-2rem" }}
                        >
                          <p>
                            <FaDirections />
                          </p>
                          <h6>Directions</h6>
                        </div>
                      </div>

                      <div className="col-md-12">
                        <hr />
                      </div>

                      <div className="col-md-8">
                        <h5>Zeon Charging Station</h5>
                        <p>
                        Charging Station<br />
                          4th cross Rd, Tirumala Delights, Tirumala Nagar Rd
                        </p>
                        <p>Open-Closes at 11pm</p>
                        <br />
                        {/* <p>Dinein-Takeaway-Delivery</p> */}
                      </div>
                      <div className="col-md-4">
                        <img
                          src="/img/white.jpg"
                          alt=""
                          style={{
                            width: "auto",
                            height: "150px",
                            borderRadius: "15px",
                          }}
                        />
                        <div
                          className="direction text-center"
                          style={{ padding: "10px",color:"#DA291C" ,marginLeft:"-2rem"}}
                        >
                          <p>
                            <FaDirections />
                          </p>
                          <h6>Directions</h6>
                        </div>
                      </div>

                      <div className="col-md-12">
                        <hr />
                      </div>

                      <div className="col-md-8">
                        <h5>Zeon Charging Station</h5>
                        <p>
                        Charging Station<br />
                          4th cross Rd, Tirumala Delights, Tirumala Nagar Rd
                        </p>
                        <p>Open-Closes at 11pm</p>
                        <br />
                        {/* <p>Dinein-Takeaway-Delivery</p> */}
                      </div>
                      <div className="col-md-4">
                        <img
                          src="/img/white.jpg"
                          alt=""
                          style={{
                            width: "auto",
                            height: "150px",
                            borderRadius: "15px",
                          }}
                        />
                        <div
                          className="direction text-center"
                          style={{ padding: "10px",color:"#DA291C",marginLeft:"-2rem" }}
                        >
                          <p>
                            <FaDirections />
                          </p>
                          <h6>Directions</h6>
                        </div>
                      </div>

                      <div className="col-md-12">
                        <hr />
                      </div>

                      <div className="col-md-8">
                        <h5>Zeon Charging Station</h5>
                        <p>
                        Charging Station <br />
                          4th cross Rd, Tirumala Delights, Tirumala Nagar Rd
                        </p>
                        <p>Open-Closes at 11pm</p>
                        <br />
                        {/* <p>Dinein-Takeaway-Delivery</p> */}
                      </div>
                      <div className="col-md-4">
                        <img
                          src="/img/white.jpg"
                          alt=""
                          style={{
                            width: "auto",
                            height: "150px",
                            borderRadius: "15px",
                          }}
                        />
                        <div
                          className="direction text-center"
                          style={{ padding: "10px",color:"#DA291C",marginLeft:"-2rem" }}
                        >
                          <p>
                            <FaDirections />
                          </p>
                          <h6>Directions</h6>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="col-md-6"
                    style={{ paddingLeft: "unset", paddingRight: "unset" }}
                  >
                    <iframe
                      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d31090.264129437837!2d77.53480077779398!3d13.081241386031335!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae229f7a8debe1%3A0x4d80f1d259c7e7ac!2sVidyaranyapura%2C%20Bengaluru%2C%20Karnataka!5e0!3m2!1sen!2sin!4v1702035015935!5m2!1sen!2sin"
                      style={{
                        width: "100%",
                        // marginTop: "2rem",
                        height: "450px",
                      }}
                      allowfullscreen=""
                      loading="lazy"
                      referrerpolicy="no-referrer-when-downgrade"
                    ></iframe>
                  </div>
                </div>
                    </>):(<>
                    {BusStand?(<>
                        <div className="row">
                  <div className="col-md-6">
                    <div
                      className="row"
                      style={{
                        border: "1px solid #DDDDDD",
                        borderRadius: "10px",
                        padding: "20px",
                      }}
                    >
                      <div className="col-md-8">
                        <h5>9th Main Bus Stop   </h5>
                        <p>
                        Bus Stop<br />
                          4th cross Rd, Tirumala Delights, Tirumala Nagar Rd
                        </p>
                        <p>Open-Closes at 11pm</p>
                        <br />
                        {/* <p>Dinein-Takeaway-Delivery</p> */}
                      </div>
                      <div className="col-md-4">
                        <img
                          src="/img/white.jpg"
                          alt=""
                          style={{
                            width: "auto",
                            height: "150px",
                            borderRadius: "15px",
                          }}
                        />
                        <div
                          className="direction text-center"
                          style={{ padding: "10px",color:"#DA291C",marginLeft:"-2rem" }}
                        >
                          <p>
                            <FaDirections />
                          </p>
                          <h6>Directions</h6>
                        </div>
                      </div>

                      <div className="col-md-12">
                        <hr />
                      </div>

                      <div className="col-md-8">
                        <h5>9th Main Bus Stop</h5>
                        <p>
                        Bus Stop<br />
                          4th cross Rd, Tirumala Delights, Tirumala Nagar Rd
                        </p>
                        <p>Open-Closes at 11pm</p>
                        <br />
                        {/* <p>Dinein-Takeaway-Delivery</p> */}
                      </div>
                      <div className="col-md-4">
                        <img
                          src="/img/white.jpg"
                          alt=""
                          style={{
                            width: "auto",
                            height: "150px",
                            borderRadius: "15px",
                          }}
                        />
                        <div
                          className="direction text-center"
                          style={{ padding: "10px",color:"#DA291C",marginLeft:"-2rem" }}
                        >
                          <p>
                            <FaDirections />
                          </p>
                          <h6>Directions</h6>
                        </div>
                      </div>

                      <div className="col-md-12">
                        <hr />
                      </div>

                      <div className="col-md-8">
                        <h5>9th Main Bus Stop</h5>
                        <p>
                        Bus Stop<br />
                          4th cross Rd, Tirumala Delights, Tirumala Nagar Rd
                        </p>
                        <p>Open-Closes at 11pm</p>
                        <br />
                        {/* <p>Dinein-Takeaway-Delivery</p> */}
                      </div>
                      <div className="col-md-4">
                        <img
                          src="/img/white.jpg"
                          alt=""
                          style={{
                            width: "auto",
                            height: "150px",
                            borderRadius: "15px",
                          }}
                        />
                        <div
                          className="direction text-center"
                          style={{ padding: "10px",color:"#DA291C",marginLeft:"-2rem" }}
                        >
                          <p>
                            <FaDirections />
                          </p>
                          <h6>Directions</h6>
                        </div>
                      </div>

                      <div className="col-md-12">
                        <hr />
                      </div>

                      <div className="col-md-8">
                        <h5>9th Main Bus Stop</h5>
                        <p>
                        Bus Stop <br />
                          4th cross Rd, Tirumala Delights, Tirumala Nagar Rd
                        </p>
                        <p>Open-Closes at 11pm</p>
                        <br />
                        {/* <p>Dinein-Takeaway-Delivery</p> */}
                      </div>
                      <div className="col-md-4">
                        <img
                          src="/img/white.jpg"
                          alt=""
                          style={{
                            width: "auto",
                            height: "150px",
                            borderRadius: "15px",
                          }}
                        />
                        <div
                          className="direction text-center"
                          style={{ padding: "10px",color:"#DA291C",marginLeft:"-2rem" }}
                        >
                          <p>
                            <FaDirections />
                          </p>
                          <h6>Directions</h6>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="col-md-6"
                    style={{ paddingLeft: "unset", paddingRight: "unset" }}
                  >
                    <iframe
                      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d31090.264129437837!2d77.53480077779398!3d13.081241386031335!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae229f7a8debe1%3A0x4d80f1d259c7e7ac!2sVidyaranyapura%2C%20Bengaluru%2C%20Karnataka!5e0!3m2!1sen!2sin!4v1702035015935!5m2!1sen!2sin"
                      style={{
                        width: "100%",
                        // marginTop: "2rem",
                        height: "450px",
                      }}
                      allowfullscreen=""
                      loading="lazy"
                      referrerpolicy="no-referrer-when-downgrade"
                    ></iframe>
                  </div>
                </div>
                    </>):(<>
                    </>)}
                    </>)}
                    </>)}
                    </>)}
                    </>)}
                    </>)}
                </div>
            </div>
        </div>

    </div>
  )
}

export default Services