import React from 'react'
import { Container, Form } from 'react-bootstrap';
import { FaAngleRight, FaPhoneAlt } from 'react-icons/fa'
import { MdEmail } from "react-icons/md";

export const Contact = () => {
    return (
        <div>
            <div
                style={{
                    backgroundImage: 'url("./img/login-banner.webp")',
                    backgroundPosition: 'center',
                    backgroundSize: 'cover',
                    backgroundRepeat: 'no-repeat',
                    height: '500px',
                    width: '100%',
                    padding: '30px'
                }}
            >
                <Container>
                    <div className="row justify-content-between align-items-center">
                        <div className="col-lg-4 ">
                            <h1 className='text-light'>CONTACT US</h1>
                            <p className='text-light mb-5'>A HELPLINE FOR ALL THINGS</p>
                            <p className='text-light fw-semibold mb-3'>For assistance on all things Enfield, contact us at: </p>
                            <div className="d-flex gap-3 mb-2">
                                <p className='text-light'><FaPhoneAlt /></p>
                                <div>
                                    <p style={{ color: 'red', fontWeight: 'bold' }}>1800-5441-4412</p>
                                    <p style={{ fontSize: '10px',color:'#fff' }}>(For Roadside Assistance (RSA) 24X7)</p>
                                </div>
                            </div>
                            <div className="d-flex gap-3 mb-2">
                                <p className='text-light'><FaPhoneAlt /></p>
                                <div>
                                    <p style={{ color: 'red', fontWeight: 'bold' }}>1800-5441-4412</p>
                                    <p style={{ fontSize: '10px',color:'#fff' }}>(For Customer Support ( 9 am - 9 pm ))</p>
                                </div>
                            </div>
                            <div className="d-flex gap-3 mb-2">
                                <p className='text-light'><MdEmail /></p>
                                <div>
                                    <p style={{ color: 'red', fontWeight: 'bold' }}>Gk@gmail.com</p>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-6">
                            <p className='text-light mb-3'>Please fill in your details</p>
                            <input type="text" className='service-input' placeholder='Full Name' />
                            <input type="text" className='service-input' placeholder='Email Address' />
                            <input type="text" className='service-input' placeholder='Number' />
                            <textarea type="text" className='service-input' placeholder='Enter Your Massage' />
                            <button className='renew-btn'>Submit<FaAngleRight style={{display:'inline-block'}}/></button>
                        </div>
                    </div>
                </Container>
            </div>
        </div>
    )
}
