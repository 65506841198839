import React from 'react'
import { Button, Container } from 'react-bootstrap'
import { FiFacebook } from "react-icons/fi";
import { FcGoogle } from "react-icons/fc";
import { BsTwitter } from 'react-icons/bs';
import { TfiYoutube } from 'react-icons/tfi';
import { FaAngleRight } from 'react-icons/fa';

import { IoCall } from "react-icons/io5";

export const FooterRSA = () => {
    return (

        <>
            <div className="footter-up">
                <Container>

                    <div className="d-flex justify-content-between ">
                        <div>
                            <img src="./img/service.jpg" alt="" />
                            <p className='fw-bold text-light'>Book Service<FaAngleRight /></p>
                        </div>
                        <div>
                            <img src="./img/rsa.jpg" alt="" />
                            <p className='fw-bold text-light'>Get RSA Free<FaAngleRight /></p>
                        </div>
                        <div>
                            <img src="./img/explore.jpg" alt="" />
                            <p className='fw-bold text-light'>Explore Service<FaAngleRight /></p>
                        </div>
                    </div>
                </Container>
            </div>

            <div className='footer-1'>
                <Container>

                    <div className='row'>
                        <div className="col-lg-3">
                            <h5 className='mb-4' style={{ color: '#000' }}>
                                Motorcycles
                            </h5>
                            <p style={{ color: 'rgb(0 0 0 / 95%)' }}>Street bikes include cruisers, sportbikes, scooters and mopeds, and many other types. Off-road motorcycles include many types designed for dirt-oriented racing ...</p>
                        </div>

                        <div className="col-lg-3">
                            <h5 className='mb-4' style={{ color: '#000' }}>
                                Rides & Events
                            </h5>
                            <p style={{ color: 'rgb(0 0 0 / 98%)' }}>28800 Orchard Lake Road, Suite 180 Farmington Hills, U.S.A. Landmark : Next To Airport</p>
                            <h5 style={{ color: 'rgb(0 0 0 / 98%)' }}>PHONE: +91 9876543210</h5>
                        </div>

                        <div className="col-lg-3">
                            <h5 className='mb-4' style={{ color: '#000' }}>
                                SUPPORT & HELP
                            </h5>
                            <ul className='footer-nav'>
                                <li>
                                    <a href="" >
                                        About Us
                                    </a>
                                </li>
                                <li>
                                    <a href="">
                                        Feedbacks
                                    </a>
                                </li>
                                <li>
                                    <a href="">
                                        Use Cases
                                    </a>
                                </li>
                                <li>
                                    <a href="">
                                        Discount
                                    </a>
                                </li>
                            </ul>
                        </div>

                        <div className="col-lg-3">
                            <h5 className='mb-4' style={{ color: '#000' }}>
                                FOLLOW WITH US
                            </h5>
                            <p style={{ color: 'rgb(0 0 0)' }}>Join the thousands of other There are many variations of passages of Lorem Ipsum available</p>
                            {/* <ul className='d-flex gap-3' style={{ listStyle: 'none', padding: '5px' }}>
                                <li>
                                    <a href="" className='fs-5'><FiFacebook /></a>
                                </li>
                                <li>
                                    <a href="" className='fs-5'><FcGoogle /></a>
                                </li>
                                <li>
                                    <a href="" className='fs-5'><BsTwitter /></a>
                                </li>
                                <li>
                                    <a href="" className='text-danger fs-5'><TfiYoutube /></a>
                                </li>
                            </ul> */}
                        </div>

                    </div>

                </Container>

               
            </div>



        </>

    )

}
