export function register() {
    if ('serviceWorker' in navigator) {
      window.addEventListener('load', () => {
        navigator.serviceWorker.register('/firebase-messaging-sw.js').then(registration => {
          console.log('Service Worker registered: ', registration);
        }).catch(error => {
          console.error('Service Worker registration failed: ', error);
        });
      });
    }
  };