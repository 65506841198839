import React, { useEffect, useState } from "react";

import "./community.scoped.css";
import axios from "axios";
import pics from "../../assets/user.png"
function Profile_Dashboard() {
  const user = JSON.parse(sessionStorage.getItem("user"));
  const [userdetails, setuserdetails] = useState({});
  const [backgroundImage, setbackgroundImage] = useState();
  const [profileImage, setprofileImage] = useState();
  const formdata = new FormData();

  useEffect(() => {
    getuser();
  }, []);

  const getuser = async () => {
    let res = await axios.get(
      "https://bookingoda.com/api/user/getmyprofile/" + user._id
    );
    if (res.status === 200) {
      //console.log("getuser===>", res.data);
      setuserdetails(res.data.user);
    }
  };

  useEffect(() => {
    if (profileImage) {
      postprofileImage();
    }
  }, [profileImage]);

  const postprofileImage = async () => {
    try {
      formdata.append("userid", user._id);
      formdata.append(
        "profileImage",
        profileImage !== undefined ? profileImage : userdetails?.profileImage
      );
      const config = {
        url: "/postprofileImage",
        method: "post",
        baseURL: "https://bookingoda.com/api/user",
        header: { "Content-Type": "multipart/formdata" },
        data: formdata,
      };

      let res = await axios(config);

      if (res.status === 200) {
        //console.log(res.data);
        setprofileImage();
        getuser();
      }
    } catch (error) {
      console.warn(error);

      return error;
    }
  };

  useEffect(() => {
    if (backgroundImage) {
      postbackgroundImage();
    }
  }, [backgroundImage]);

  const postbackgroundImage = async () => {
    try {
      formdata.append("userid", user._id);
      formdata.append(
        "backgroundImage",
        backgroundImage !== undefined
          ? backgroundImage
          : userdetails?.backgroundImage
      );
      const config = {
        url: "/postbackgroundImage",
        method: "post",
        baseURL: "https://bookingoda.com/api/user",
        header: { "Content-Type": "multipart/formdata" },
        data: formdata,
      };

      let res = await axios(config);

      if (res.status === 200) {
        //console.log(res.data);
        setbackgroundImage();
        getuser();
      }
    } catch (error) {
      console.warn(error);

      return error;
    }
  };
  return (
    <div>
      
      <div
        className="resource-ims"
        style={{
          backgroundImage: userdetails?.backgroundImage
            ? "url(" +
              `https://bookingoda.com/admin/${userdetails?.backgroundImage}` +
              ")"
            : "url('img/trip-package-bg.jpg')",
          height: "380px",
          // padding: "5%",
          backgroundPosition: "center",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
        }}
      >
        <div className="edit-pp">
          <label className="fileContainer">
            <i className="fas fa-camera"></i>
            <input
              type="file"
              onChange={(e) => setbackgroundImage(e.target.files[0])}
            />
          </label>
        </div>
     
        <div className="d-flex pro-auth-posit cart-mobile">
          <div className="profile-author-thumb">
           {userdetails?.profileImage ? <img
              className="aouther-mobile"
              alt="author"
              src={`https://bookingoda.com/admin/${userdetails?.profileImage}`}
              style={{ height: "120px", width: "60px" }}
            /> : <img
            className="aouther-mobile"
            alt="author"
            src={pics}
            style={{ height: "120px", width: "60px" }}
          /> } 
            <div className="edit-dp">
              <label className="fileContainer">
                <i className="fas fa-camera">
                  {" "}
                  {/* <BsFillCameraFill
                                  style={{
                                    fontSize: "30px",
                                    paddingTop: "5px",
                                  }}
                                /> */}
                </i>
                <input
                  type="file"
                  onChange={(e) => setprofileImage(e.target.files[0])}
                />
              </label>
            </div>
          </div>
          <div className="author-content ms-3">
            <a
              className="h4 author-name"
              href="#"
              style={{
                backgroundColor: "#ff7d26",
                padding: "5px",
                borderRadius: "5px 5px 0px 0px",
                color: "white",
              }}
            >
              {user?.name}
            </a>
            <div
              className="country"
              style={{
                backgroundColor: "#ff7d26",
                padding: "5px",
                borderRadius: "5px 5px 5px 5px",
                color: "white",
              }}
            >
              {user?.city} | {user?.state}
            </div>
          </div>
        </div>
      </div>
      
    </div>
  );
}

export default Profile_Dashboard;
