// import "./about.css";
import React from "react";
import "./community.scoped.css";
import Profile_Dashboard from "./Profile_Dashboard";

import { MdDescription } from "react-icons/md";
import { ImMusic } from "react-icons/im";
import { GiHeartInside } from "react-icons/gi";
import { SiBandsintown } from "react-icons/si";
import { MdLocalMovies } from "react-icons/md";
import { MdOutlineLocalMovies } from "react-icons/md";
import { IoIosBook } from "react-icons/io";
import { CgGames } from "react-icons/cg";
import { RiMovieFill } from "react-icons/ri";
import { BiCameraMovie } from "react-icons/bi";
import { MdFolderSpecial } from "react-icons/md";
import { MdWork } from "react-icons/md";
import { SiTalend } from "react-icons/si";
import { AiFillSkin } from "react-icons/ai";
import { SiNamecheap } from "react-icons/si";
import { MdPlace } from "react-icons/md";
import { CgWebsite } from "react-icons/cg";
import { FaHandshake } from "react-icons/fa";
import { GiThreeFriends } from "react-icons/gi";
import { BiRepost } from "react-icons/bi";
import { GoComment } from "react-icons/go";
import { useState } from "react";
import axios from "axios";
import { padding } from "@mui/system";
import { useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebook, faGooglePlus, faInstagram, faTwitter, faVk } from "@fortawesome/free-brands-svg-icons";

// how to make post api call in react js using axios?
{
  /* <Button onClick={AddContactInfo}>
  Add Contact
</Button> */
}
function Add_profile() {
  const user = JSON.parse(sessionStorage.getItem("user"));

  const [mySelf, setmySelf] = useState("");
  const [nickName, setnickName] = useState("");
  const [birthDate, setbirthDate] = useState("");
  const [mobileNumber, setmobileNumber] = useState("");
  const [bloodGroup, setbloodGroup] = useState("");
  const [selectGender, setselectGender] = useState("");
  const [relationshipStatus, setrelationshipStatus] = useState("");
  const [birthPlace, setBirthPlace] = useState("");
  const [country, setCountry] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");

  const [occupation, setOccupation] = useState("");
  const [website, setWebsite] = useState("");
  const [description, setDescription] = useState("");
  const [hobbies, setHobbies] = useState("");
  const [favouriteSongs, setfavouriteSongs] = useState("");
  const [latestSongs, setlatestSongs] = useState("");
  const [favArtists, setfavArtists] = useState("");
  const [latestBands, setlatestBands] = useState("");
  const [favouriteMovies, setfavouriteMovies] = useState("");
  const [latestMovies, setlatestMovies] = useState("");
  const [favouriteBooks, setfavouriteBooks] = useState("");
  const [favouriteGames, setfavouriteGames] = useState("");
  const [latestGame, setlatestGame] = useState("");
  const [favouriteSeries, setfavouriteSeries] = useState("");
  const [latestSeries, setlatestSeries] = useState("");
  const [othersInterests, setothersInterests] = useState("");
  const [educationinsitute, setEducationinsitute] = useState("");
  const [educationcourse, setEducationcourse] = useState("");
  const [educationlocation, setEducationlocation] = useState("");
  const [educationperiod, setEducationperiod] = useState("");
  const [specialization, setspecialization] = useState("");
  const [employmentcompany, setEmploymentcompany] = useState("");
  const [employmentdesignation, setEmploymentdesignation] = useState("");
  const [employmentlocation, setEmploymentlocation] = useState("");
  const [employmentperiod, setEmploymentperiod] = useState("");
  const [workExperience, setworkExperience] = useState("");
  const [talentinsitute, setTalentinsitute] = useState("");
  const [talentcourse, setTalentcourse] = useState("");
  const [talentlocation, setTalentlocation] = useState("");
  const [talentperiod, setTalentperiod] = useState("");
  const [skillSet, setskillSet] = useState("");
  const [facebook, setFacebook] = useState("");
  const [google, setGoogle] = useState("");
  const [twitter, setTwitter] = useState("");
  const [vk, setVk] = useState("");
  const [instagram, setInstagram] = useState("");
  const [userdetails, setuserdetails] = useState({});
  const [userPost, setuserPost] = useState([]);

  useEffect(() => {
    getuser();
    getPost();
  }, []);

  const getuser = async () => {
    let res = await axios.get(
      "https://bookingoda.com/api/user/getmyprofile/" + user._id
    );
    if (res.status === 200) {
      //console.log(res.data);
      setuserdetails(res.data.user);
    }
  };

  const getPost = async () => {
    let res = await axios.get(
      "https://bookingoda.com/api/user/getPostByUser/" + user._id
    );
    if (res.status === 200) {
      //console.log(res.data);
      setuserPost(res.data.data);
    }
  };
  const [following, setFollowing] = useState([]);

  useEffect(() => {
   
    getAllFollowing();
  }, []);
  const getAllFollowing = async () => {
    let res = await axios.get(
      `https://bookingoda.com/api/user/getFririndlistByuserId/${user._id}`
    );
    if (res.status === 200) {
      //console.log("getAllFollowing==>", res.data);
      setFollowing(res.data.success);
    }
  };
  const AddDetails = async (e) => {
    e.preventDefault();
    try {
      const config = {
        url: "/user/editProfile",
      method: "post",
      baseURL: "https://bookingoda.com/api",
     
        data: {
          userId: user._id,
          aboutMe: mySelf ? mySelf : userdetails?.aboutMe,
          nickName: nickName ? nickName : userdetails?.nickName,
          DOB: birthDate ? birthDate : userdetails?.DOB,
          mobile:  userdetails?.mobile,
          bloodGroup: bloodGroup ? bloodGroup : userdetails?.bloodGroup,
          gender: selectGender ? selectGender : userdetails?.gender,
          relationshipStatus: relationshipStatus
            ? relationshipStatus
            : userdetails?.relationshipStatus,
          DOBPlace: birthPlace ? birthPlace : userdetails?.DOBPlace,
          country: country ? country : userdetails?.country,
          city: city ? city : userdetails?.city,
          state: state ? state : userdetails?.state,


          occupation: occupation ? occupation : userdetails?.occupation,
          webSite: website ? website : userdetails?.webSite,
          Description: description ? description : userdetails?.Description,
          hobbies: hobbies ? hobbies : userdetails?.hobbies,
          education: {
            Institue: educationinsitute
              ? educationinsitute
              : userdetails?.education?.Institue,
            Course: educationcourse
              ? educationcourse
              : userdetails?.education?.Course,
            Location: educationlocation
              ? educationlocation
              : userdetails?.education?.Location,
            Period: educationperiod
              ? educationperiod
              : userdetails?.education?.Period,
          },
          SocialNetworks: {
            facebook: facebook
              ? facebook
              : userdetails?.SocialNetworks?.facebook,
            instagram: instagram
              ? instagram
              : userdetails?.SocialNetworks?.instagram,
            vk: vk ? vk : userdetails?.SocialNetworks?.vk,
            twitter: twitter ? twitter : userdetails?.SocialNetworks?.twitter,
            googlePlus: google
              ? google
              : userdetails?.SocialNetworks?.googlePlus,
          },
          talent: {
            Institue: talentinsitute
              ? talentinsitute
              : userdetails?.talent?.Institue,
            Course: talentcourse ? talentcourse : userdetails?.talent?.Course,
            Location: talentlocation
              ? talentlocation
              : userdetails?.talent?.Location,
            Period: talentperiod ? talentperiod : userdetails?.talent?.Period,
          },
          Employment: {
            Company: employmentcompany
              ? employmentcompany
              : userdetails?.Employment?.Company,
            Designation: employmentdesignation
              ? employmentdesignation
              : userdetails?.Employment?.Designation,
            Location: employmentlocation
              ? employmentlocation
              : userdetails?.Employment?.Location,
            Period: employmentperiod
              ? employmentperiod
              : userdetails?.Employment?.Period,
          },
          FavouriteSongs: favouriteSongs
            ? favouriteSongs
            : userdetails?.FavouriteSongs,
          LatestSongs: latestSongs ? latestSongs : userdetails?.LatestSongs,
          FavouriteBands: favArtists ? favArtists : userdetails?.FavouriteBands,
          LatestBands: latestBands ? latestBands : userdetails?.LatestBands,
          favouriteMovies: favouriteMovies
            ? favouriteMovies
            : userdetails?.favouriteMovies,
          latestMovies: latestMovies ? latestMovies : userdetails?.latestMovies,
          favouriteBooks: favouriteBooks
            ? favouriteBooks
            : userdetails?.favouriteBooks,
          favouriteGames: favouriteGames
            ? favouriteGames
            : userdetails?.favouriteGames,
          latestGame: latestGame ? latestGame : userdetails?.latestGame,
          favouriteSeries: favouriteSeries
            ? favouriteSeries
            : userdetails?.favouriteSeries,
          latestSeries: latestSeries ? latestSeries : userdetails?.latestSeries,
          OthersInterests: othersInterests
            ? othersInterests
            : userdetails?.OthersInterests,
          Specialization: specialization
            ? specialization
            : userdetails?.Specialization,
          workAndExperience: workExperience
            ? workExperience
            : userdetails?.workAndExperience,
          SkillSet: skillSet ? skillSet : userdetails?.SkillSet,
        },
      };
      await axios(config).then(function (res) {
        if (res.status === 200) {
         
          window.location.assign("/community-about-us");
        }
      });
    } catch (error) {
      //console.log(error.res);
      alert("Something went wrong!!! try again");
    }
  };

  return (
    <div className="communityhomestyle">
    
      <div className="gap2 gray-bg">
        <div
          className="container fluid mobile-correct-0"
          style={{ paddingTop: "30px" }}
        >
          <div className="row">
            <div className="col-lg-12">
              <div className="row merged20" id="page-contents">
                {/* profile Dashboard start ========================================= */}
                <div className="user-profile">
                  <Profile_Dashboard />
                </div>
                {/* profile Dashboard end =================================================== */}
                {/* middle page start =================================================== */}
                <div className="col-lg-4 col-md-4">
                  <aside className="sidebar">
                    <div className="central-meta stick-widget">
                      <span className="create-post">Statistics</span>
                      <div className="personal-head">
                        <span className="f-title1">
                          <i className="fas fa-user1">
                            <GiThreeFriends />
                          </i>{" "}
                          Friends : {following.length}
                        </span> <br />
                        <span className="f-title1">
                          <i className="fas fa-user1">
                            <BiRepost />
                          </i>{" "}
                          Posts : {userPost?.length}
                        </span>
                        {/* <span className="f-title1">
                          <i className="fas fa-user1">
                            <GoComment />
                          </i>{" "}
                          Comments : 0
                        </span> */}
                      </div>
                    </div>
                    <div className="central-meta stick-widget">
                      <span className="create-post">Personal Info</span>
                      <div className="personal-head">
                        <span className="f-title1">
                          <i className="fas fa-user"></i> About Me:
                        </span>
                        <textarea
                        className="add-profile-inpt-field"
                          placeholder={
                            mySelf
                              ? mySelf
                              : userdetails?.aboutMe
                              ? userdetails?.aboutMe
                              : "Write about yourself..."
                          }
                          style={{
                            resize: "vertical",
                            border: "1px solid #f4f4f4",
                            borderRadius: "5px",
                            fontWeight:"bolder"
                          }}
                          onChange={(e) => setmySelf(e.target.value)}
                        />
                        <span className="f-title1">
                          <i className="fas fa-user1">
                            <SiNamecheap style={{ marginTop: "5px" }} />
                          </i>{" "}
                          Nick name:
                        </span>
                        <input
                          className="add-profile-inpt-field"
                          placeholder={
                            nickName
                              ? nickName
                              : userdetails?.nickName
                              ? userdetails?.nickName
                              : "Enter your nickname"
                          }
                          type="text"
                          onChange={(e) => setnickName(e.target.value)}
                        />
                        <span className="f-title1">
                          <i className="fas fa-birthday-cake"></i> Birthday:
                        </span>
                        <input
                          className="add-profile-inpt-field"
                          type="text"
                          placeholder={birthDate
                            ? birthDate
                            : userdetails?.DOB
                            ? userdetails?.DOB
                            : "Enter your birthdate"}
                       
                          onChange={(e) => setbirthDate(e.target.value)}
                        />
                        <span className="f-title1">
                          <i className="fas fa-phone"></i> Phone Number:
                        </span>
                        <input
                          className="add-profile-inpt-field"
                          placeholder="Enter you Mobile number"
                          type="tel"
                          value={userdetails?.mobile}
                        />
                        <span className="f-title1">
                          <i className="fas fa-medkit"></i> Blood group:
                        </span>
                        <input
                          className="add-profile-inpt-field"
                          placeholder={
                            bloodGroup
                              ? bloodGroup
                              : userdetails?.bloodGroup
                              ? userdetails?.bloodGroup
                              : "Enter your bloodgroup"
                          }
                          type="text"
                          onChange={(e) => setbloodGroup(e.target.value)}
                        />
                        <span className="f-title1">
                          <i className="fas fa-male"></i> Gender:
                        </span>
                        <select
                          className="add-profile-inpt-field"
                          onChange={(e) => setselectGender(e.target.value)}
                          value={
                            selectGender
                              ? selectGender
                              : userdetails?.gender
                              ? userdetails?.gender
                              : ""
                          }
                        >
                          <option type="">Select</option>
                          <option type="Male">Male</option>
                          <option type="Female">Female</option>
                          <option type="Custom">Custom</option>
                        </select>
                        <span className="f-title1">
                          <i className="fas fa-globe"></i> Relationship Status:
                        </span>
                        <select
                          className="add-profile-inpt-field"
                          onChange={(e) =>
                            setrelationshipStatus(e.target.value)
                          }
                          value={
                            relationshipStatus
                              ? relationshipStatus
                              : userdetails?.relationshipStatus
                              ? userdetails?.relationshipStatus
                              : ""
                          }
                        >
                          <option type="">Select</option>
                          <option type="Single">Single</option>
                          <option type="In a relationship">
                            In a relationship
                          </option>
                          <option type="Engaged">Engaged</option>
                          <option type="In a civil union">
                            In a civil union
                          </option>
                          <option type="Seperated">Seperated</option>
                          <option type="Divorced">Divorced</option>
                          <option type="Widowed">Widowed</option>
                        </select>
                        <span className="f-title1">
                          <i className="fas fa-globe1">
                            <MdPlace />
                          </i>{" "}
                          Birthplace:
                        </span>
                        <input
                          className="add-profile-inpt-field"
                          placeholder={
                            birthPlace
                              ? birthPlace
                              : userdetails?.DOBPlace
                              ? userdetails?.DOBPlace
                              : "Enter your birthplace"
                          }
                          type="text"
                          onChange={(e) => setBirthPlace(e.target.value)}
                        />
                        <span className="f-title1">
                          <i className="fas fa-globe"></i> City:
                        </span>
                        <input
                          className="add-profile-inpt-field"
                          placeholder={
                            city
                              ? city
                              : userdetails?.city
                              ? userdetails?.city
                              : "Enter your city"
                          }
                          type="text"
                          onChange={(e) => setCity(e.target.value)}
                        />
                        <span className="f-title1">
                          <i className="fas fa-globe"></i> State:
                        </span>
                        <input
                          className="add-profile-inpt-field"
                          placeholder={
                            state
                              ? state
                              : userdetails?.state
                              ? userdetails?.state
                              : "Enter your state"
                          }
                          type="text"
                          onChange={(e) => setState(e.target.value)}
                        />
                        
                        <span className="f-title1">
                          <i className="fas fa-globe"></i> Country:
                        </span>
                        <input
                          className="add-profile-inpt-field"
                          placeholder={
                            country
                              ? country
                              : userdetails?.country
                              ? userdetails?.country
                              : "Enter your country"
                          }
                          type="text"
                          onChange={(e) => setCountry(e.target.value)}
                        />
                        <span className="f-title1">
                          <i className="fas fa-briefcase"></i> Occupation:
                        </span>
                        <input
                          className="add-profile-inpt-field"
                          placeholder={
                            occupation
                              ? occupation
                              : userdetails?.occupation
                              ? userdetails?.occupation
                              : "Enter your occupation"
                          }
                          type="text"
                          onChange={(e) => setOccupation(e.target.value)}
                        />
                        {/* <span className="f-title1">
                          <i className="fas fa-handshake-o1">
                            <FaHandshake />
                          </i>{" "}
                          Joined:
                        </span>
                        <input
                          className="add-profile-inpt-field"
                          type="date"
                          onChange={(e) => setJoined(e.target.value)}// ithuku edit theva illa yapo join pannirukom th ok puriyuthi
                        /> */}
                        {/* <span className="f-title1">
                          <i className="fas fa-envelope"></i> Email :
                        </span>
                        <input
                          className="add-profile-inpt-field"
                          placeholder="Enter your email"
                          type="email"
                          onChange={(e) => setBirthPlace(e.target.value)}// email login a illa mobile number login a mobile numver with otp email unqine field a sari na email ah  comment pannituren ipothikku aprama keta add pannikiren ok 
                        /> */}

                        <span className="f-title1">
                          <i className="fas fa-envelope1">
                            <CgWebsite  />
                          </i>{" "}
                          Website :
                        </span>
                        <input
                          className="add-profile-inpt-field"
                          placeholder={
                            website
                              ? website
                              : userdetails?.webSite
                              ? userdetails?.webSite
                              : "Enter your website link"
                          }
                          type="url"
                          onChange={(e) => setWebsite(e.target.value)}
                        />
                      </div>
                    </div>

                    {/* <div className="central-meta stick-widget">
                      <span className="create-post"> Badges</span>
                      <div className="personal-head">
                        <ul className="badged">
                          <li>
                            <img src="Images/resources/badge2.png" alt="" />
                          </li>
                          <li>
                            <img src="Images/resources/badge19.png" alt="" />
                          </li>
                          <li>
                            <img src="Images/resources/badge21.png" alt="" />
                          </li>
                          <li>
                            <img src="Images/resources/badge3.png" alt="" />
                          </li>
                          <li>
                            <img src="Images/resources/badge4.png" alt="" />
                          </li>
                        </ul>
                      </div>
                    </div> */}

                    <div className="central-meta stick-widget">
                      <span className="create-post"> Social Networks</span>
                      <div className="personal-head">
                        <span className="facebook">
                        <FontAwesomeIcon icon={faFacebook}
                            style={{
                              borderRadius: "100%",
                             
                              display: "inline-block",
                              fontSize: "15px",
                              fontWeight: "500",
                              height: "30px",
                              lineHeight: "30px",
                              textAlign: "center",
                              transition: "all 0.2s linear 0s",
                              width: "30px",
                            }}
                          />{" "}
                          Facebook:
                        </span>
                        <input
                          className="add-profile-inpt-field"
                          placeholder={
                            facebook
                              ? facebook
                              : userdetails?.SocialNetworks?.facebook
                              ? userdetails?.SocialNetworks?.facebook
                              : "Enter your facebook"
                          }
                          type="text"
                          onChange={(e) => setFacebook(e.target.value)}
                        />
                        <span className="facebook">
                         
                            <FontAwesomeIcon icon={faGooglePlus} 
                            style={{
                              borderRadius: "100%",
                              // color: "#fa6342",
                              display: "inline-block",
                              fontSize: "15px",
                              fontWeight: "500",
                              height: "30px",
                              lineHeight: "30px",
                              textAlign: "center",
                              transition: "all 0.2s linear 0s",
                              width: "30px",
                            }}
                          />{" "}
                          Google:
                        </span>
                        <input
                          className="add-profile-inpt-field"
                          placeholder={
                            google
                              ? google
                              : userdetails?.SocialNetworks?.googlePlus
                              ? userdetails?.SocialNetworks?.googlePlus
                              : "Enter your google"
                          }
                          type="text"
                          onChange={(e) => setGoogle(e.target.value)}
                        />
                        <span className="facebook">
                        <FontAwesomeIcon icon={faTwitter} 
                            style={{
                              borderRadius: "100%",
                              // color: "#fa6342",
                              display: "inline-block",
                              fontSize: "15px",
                              fontWeight: "500",
                              height: "30px",
                              lineHeight: "30px",
                              textAlign: "center",
                              transition: "all 0.2s linear 0s",
                              width: "30px",
                            }}
                          />{" "}
                          Twitter:
                        </span>
                        <input
                          className="add-profile-inpt-field"
                          placeholder={
                            twitter
                              ? twitter
                              : userdetails?.SocialNetworks?.twitter
                              ? userdetails?.SocialNetworks?.twitter
                              : "Enter your twitter"
                          }
                          type="text"
                          onChange={(e) => setTwitter(e.target.value)}
                        />
                        <span className="facebook">
                        <FontAwesomeIcon icon={faVk} 
                            style={{
                              borderRadius: "100%",
                              // color: "#fa6342",
                              display: "inline-block",
                              fontSize: "15px",
                              fontWeight: "500",
                              height: "30px",
                              lineHeight: "30px",
                              textAlign: "center",
                              transition: "all 0.2s linear 0s",
                              width: "30px",
                            }}
                         / >{" "}
                          VK:
                        </span>
                        <input
                          className="add-profile-inpt-field"
                          placeholder={
                            vk
                              ? vk
                              : userdetails?.SocialNetworks?.vk
                              ? userdetails?.SocialNetworks?.vk
                              : "Enter your vk"
                          }
                          type="text"
                          onChange={(e) => setVk(e.target.value)}
                        />
                        <span className="facebook">
                        <FontAwesomeIcon icon={faInstagram}
                            style={{
                              borderRadius: "100%",
                              // color: "#fa6342",
                              display: "inline-block",
                              fontSize: "15px",
                              fontWeight: "500",
                              height: "30px",
                              lineHeight: "30px",
                              textAlign: "center",
                              transition: "all 0.2s linear 0s",
                              width: "30px",
                            }}
                         / >{" "}
                          Instagram:
                        </span>
                        <input
                          className="add-profile-inpt-field"
                          placeholder={
                            instagram
                              ? instagram
                              : userdetails?.SocialNetworks?.instagram
                              ? userdetails?.SocialNetworks?.instagram
                              : "Enter your instagram"
                          }
                          type="text"
                          onChange={(e) => setInstagram(e.target.value)}
                        />
                      </div>
                    </div>
                  </aside>
                </div>
                <div className="col-lg-8 col-md-8">
                  <div className="central-meta">
                    <span className="create-post">General Info</span>

                    <div className="gen-metabox1">
                      <span className="f-title1">
                        <i class="fa-duotone fa-file-audi2o">
                          <MdDescription />
                        </i>{" "}
                        Description
                      </span>
                      <textarea
                        placeholder={
                          description
                            ? description
                            : userdetails?.Description
                            ? userdetails?.Description
                            : ""
                        }
                        style={{
                          resize: "vertical",
                          border: "1px solid #f4f4f4",
                          borderRadius: "5px",
                          fontWeight:"bolder"
                        }}
                        className="add-profile-inpt-field"
                        onChange={(e) => setDescription(e.target.value)}
                      />
                    </div>
                    <div className="row">
                      <div className="col-lg-6">
                        <div className="gen-metabox1">
                          <span className="f-title1">
                            <i className="fas fa-puzzle-piece"></i> Hobbies
                          </span>
                          <textarea
                            placeholder={
                              hobbies
                                ? hobbies
                                : userdetails?.hobbies
                                ? userdetails?.hobbies
                                : ""
                            }
                            className="add-profile-inpt-field"
                            style={{
                              resize: "vertical",
                              border: "1px solid #f4f4f4",
                              borderRadius: "5px",
                              fontWeight:"bolder"
                            }}
                            onChange={(e) => setHobbies(e.target.value)}
                          />
                        </div>

                        <div className="gen-metabox11">
                          <span className="f-title1">
                            <i class="fa-solid fa-headphones"></i> Favourite
                            Songs
                          </span>
                          <textarea
                            placeholder={
                              favouriteSongs
                                ? favouriteSongs
                                : userdetails?.FavouriteSongs
                                ? userdetails?.FavouriteSongs
                                : ""
                            }
                            style={{
                              resize: "vertical",
                              border: "1px solid #f4f4f4",
                              borderRadius: "5px",
                              fontWeight:"bolder"
                            }}
                            className="add-profile-inpt-field"
                            onChange={(e) => setfavouriteSongs(e.target.value)}
                          />
                        </div>

                        <div className="gen-metabox1">
                          <span className="f-title1">
                            <i class="fa-solid fa-headphone1s">
                              <ImMusic />
                            </i>{" "}
                            Latest Songs
                          </span>
                          <textarea
                            placeholder={
                              latestSongs
                                ? latestSongs
                                : userdetails?.LatestSongs
                                ? userdetails?.LatestSongs
                                : ""
                            }
                            style={{
                              resize: "vertical",
                              border: "1px solid #f4f4f4",
                              borderRadius: "5px",
                              fontWeight:"bolder"
                            }}
                            className="add-profile-inpt-field"
                            onChange={(e) => setlatestSongs(e.target.value)}
                          />
                        </div>

                        <div className="gen-metabox1">
                          <span className="f-title1">
                            <i class="fa-solid fa-headphone1s">
                              <GiHeartInside />
                            </i>{" "}
                            Favourite Bands / Artists
                          </span>
                          <textarea
                            placeholder={
                              favArtists
                                ? favArtists
                                : userdetails?.FavouriteBands
                                ? userdetails?.FavouriteBands
                                : ""
                            }
                            style={{
                              resize: "vertical",
                              border: "1px solid #f4f4f4",
                              borderRadius: "5px",
                              fontWeight:"bolder"
                            }}
                            className="add-profile-inpt-field"
                            onChange={(e) => setfavArtists(e.target.value)}
                          />
                        </div>

                        <div className="gen-metabox1">
                          <span className="f-title1">
                            <i class="fa-solid fa-closed-captioning1">
                              <SiBandsintown />
                            </i>{" "}
                            Latest Bands
                          </span>
                          <textarea
                            placeholder={
                              latestBands
                                ? latestBands
                                : userdetails?.LatestBands
                                ? userdetails?.LatestBands
                                : ""
                            }
                            className="add-profile-inpt-field"
                            style={{
                              resize: "vertical",
                              border: "1px solid #f4f4f4",
                              borderRadius: "5px",
                              fontWeight:"bolder"
                            }}
                            onChange={(e) => setlatestBands(e.target.value)}
                          />
                        </div>
                        <div className="gen-metabox1">
                          <span className="f-title1">
                            <i class="fa-regular fa-camera-movie1">
                              <MdLocalMovies />
                            </i>
                            Favourite Movies
                          </span>
                          <textarea
                          className="add-profile-inpt-field"
                            placeholder={
                              favouriteMovies
                                ? favouriteMovies
                                : userdetails?.favouriteMovies
                                ? userdetails?.favouriteMovies
                                : ""
                            }
                            style={{
                              resize: "vertical",
                              border: "1px solid #f4f4f4",
                              borderRadius: "5px",
                              fontWeight:"bolder"
                            }}
                            onChange={(e) => setfavouriteMovies(e.target.value)}
                          />
                        </div>
                        <div className="gen-metabox1">
                          <span className="f-title1">
                            <i className="fas fa-puzzle-piece1">
                              <MdOutlineLocalMovies />
                            </i>
                            Latest Movies
                          </span>
                          <textarea
                          className="add-profile-inpt-field"
                            placeholder={
                              latestMovies
                                ? latestMovies
                                : userdetails?.latestMovies
                                ? userdetails?.latestMovies
                                : ""
                            }
                            style={{
                              resize: "vertical",
                              border: "1px solid #f4f4f4",
                              borderRadius: "5px",
                              fontWeight:"bolder"
                            }}
                            onChange={(e) => setlatestMovies(e.target.value)}
                          />
                        </div>

                        <div className="gen-metabox1">
                          <span className="f-title1">
                            <i className="fas fa-puzzle-piece12">
                              <IoIosBook style={{ paddingTop: "1px" }} />
                            </i>
                            Favourite Books
                          </span>
                          <textarea
                          className="add-profile-inpt-field"
                            placeholder={
                              favouriteBooks
                                ? favouriteBooks
                                : userdetails?.favouriteBooks
                                ? userdetails?.favouriteBooks
                                : ""
                            }
                            style={{
                              resize: "vertical",
                              border: "1px solid #f4f4f4",
                              borderRadius: "5px",
                              fontWeight:"bolder"
                            }}
                            onChange={(e) => setfavouriteBooks(e.target.value)}
                          />
                        </div>

                        <div className="gen-metabox1">
                          <span className="f-title1">
                            <i className="fas fa-puzzle-piece12">
                              <CgGames />
                            </i>
                            Favourite Games
                          </span>
                          <textarea
                          className="add-profile-inpt-field"
                            placeholder={
                              favouriteGames
                                ? favouriteGames
                                : userdetails?.favouriteGames
                                ? userdetails?.favouriteGames
                                : ""
                            }
                            style={{
                              resize: "vertical",
                              border: "1px solid #f4f4f4",
                              borderRadius: "5px",
                              fontWeight:"bolder"
                            }}
                            onChange={(e) => setfavouriteGames(e.target.value)}
                          />
                        </div>

                        <div className="gen-metabox1">
                          <span className="f-title1">
                            <i className="fas fa-puzzle-piece12">
                              {" "}
                              <CgGames />
                            </i>
                            Latest Game
                          </span>
                          <textarea
                          className="add-profile-inpt-field"
                            placeholder={
                              latestGame
                                ? latestGame
                                : userdetails?.latestGame
                                ? userdetails?.latestGame
                                : ""
                            }
                            style={{
                              resize: "vertical",
                              border: "1px solid #f4f4f4",
                              borderRadius: "5px",
                              fontWeight:"bolder"
                            }}
                            onChange={(e) => setlatestGame(e.target.value)}
                          />
                        </div>

                        <div className="gen-metabox1">
                          <span className="f-title1">
                            <i className="fas fa-puzzle-piece12">
                              <RiMovieFill />
                            </i>
                            Favourite Series
                          </span>
                          <textarea
                          className="add-profile-inpt-field"
                            placeholder={
                              favouriteSeries
                                ? favouriteSeries
                                : userdetails?.favouriteSeries
                                ? userdetails?.favouriteSeries
                                : ""
                            }
                            style={{
                              resize: "vertical",
                              border: "1px solid #f4f4f4",
                              borderRadius: "5px",
                              fontWeight:"bolder"
                            }}
                            onChange={(e) => setfavouriteSeries(e.target.value)}
                          />
                        </div>

                        <div className="gen-metabox1">
                          <span className="f-title1">
                            <i className="fas fa-puzzle-piece12">
                              <BiCameraMovie />
                            </i>
                            Latest Series
                          </span>
                          <textarea
                          className="add-profile-inpt-field"
                            placeholder={
                              latestSeries
                                ? latestSeries
                                : userdetails?.latestSeries
                                ? userdetails?.latestSeries
                                : ""
                            }
                            style={{
                              resize: "vertical",
                              border: "1px solid #f4f4f4",
                              borderRadius: "5px",
                              fontWeight:"bolder"
                            }}
                            onChange={(e) => setlatestSeries(e.target.value)}
                          />
                        </div>
                        <div className="gen-metabox1">
                          <span className="f-title1">
                            <i className="fas fa-plus"></i> Others Interests
                          </span>
                          <textarea
                          className="add-profile-inpt-field"
                            placeholder={
                              othersInterests
                                ? othersInterests
                                : userdetails?.OthersInterests
                                ? userdetails?.OthersInterests
                                : ""
                            }
                            style={{
                              resize: "vertical",
                              border: "1px solid #f4f4f4",
                              borderRadius: "5px",
                              fontWeight:"bolder"
                            }}
                            onChange={(e) => setothersInterests(e.target.value)}
                          />
                        </div>

                        <div className="gen-metabox1">
                          <button
                            style={{
                              backgroundColor: "#fa6342",
                              color: "white",
                              border: 0,
                              borderRadius: "6px",
                              boxShadow: "0px 1px 2px 1px rgb(76 76 76 / 18%)",
                              marginBottom: "10px",
                              padding: "5px 10px",
                            }}
                            onClick={AddDetails}
                          >
                            Save
                          </button>
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="gen-metabox1">
                          <span className="f-title1">
                            <i className="fas fa-mortar-board"></i> Education
                          </span>
                          <p>
                          <span style={{fontWeight:"bolder"}}> Insitution&nbsp;:&nbsp;</span>
                            <input
                              className="add-profile-inpt-field"
                              placeholder={
                                userdetails?.education?.Institue
                                  ? userdetails?.education?.Institue
                                  : "Enter Insitution"
                              }
                              type="text"
                              onChange={(e) =>
                                setEducationinsitute(e.target.value)
                              }
                            />
                          </p>
                          <p>
                          <span style={{fontWeight:"bolder"}}> Course&nbsp;:&nbsp;</span>
                            <input
                              className="add-profile-inpt-field"
                              placeholder={
                                userdetails?.education?.Course
                                  ? userdetails?.education?.Course
                                  : "Enter Course"
                              }
                              type="text"
                              onChange={(e) =>
                                setEducationcourse(e.target.value)
                              }
                            />
                          </p>
                          <p>
                          <span style={{fontWeight:"bolder"}}>Location&nbsp;:&nbsp;</span>
                            <input
                              className="add-profile-inpt-field"
                              placeholder={
                                userdetails?.education?.Location
                                  ? userdetails?.education?.Location
                                  : "Enter Location"
                              }
                              type="text"
                              onChange={(e) =>
                                setEducationlocation(e.target.value)
                              }
                            />
                          </p>
                          <p>
                          <span style={{fontWeight:"bolder"}}>Period&nbsp;:&nbsp;</span>
                            <input
                              className="add-profile-inpt-field"
                              placeholder={
                                userdetails?.education?.Period
                                  ? userdetails?.education?.Period
                                  : "Enter the Period"
                              }
                              type="text"
                              onChange={(e) =>
                                setEducationperiod(e.target.value)
                              }
                            />
                          </p>
                        </div>
                        <div className="gen-metabox1">
                          <span className="f-title1">
                            <i className="fas fa-certificate1">
                              <MdFolderSpecial />
                            </i>{" "}
                            Specialization
                          </span>
                          <input
                            className="add-profile-inpt-field" //continue
                            placeholder={
                              specialization
                                ? specialization
                                : userdetails?.Specialization
                                ? userdetails?.Specialization
                                : "Enter your Specialization"
                            }
                            type="text"
                            onChange={(e) => setspecialization(e.target.value)}
                          />
                        </div>
                        {/* hello sollunga what are you doing */}
                        <div className="gen-metabox1">
                          <span className="f-title1">
                            <i className="fas fa-certificate1">
                              <MdWork />
                            </i>{" "}
                            Employment
                          </span>
                          <p>
                           <span style={{fontWeight:"bolder"}}> Company&nbsp;:&nbsp;</span>
                            <input
                              className="add-profile-inpt-field"
                              placeholder={
                                userdetails?.Employment?.Company
                                  ? userdetails?.Employment?.Company
                                  : "Enter Company Name"
                              }
                              type="text"
                              onChange={(e) =>
                                setEmploymentcompany(e.target.value)
                              }
                            />
                          </p>
                          <p>
                          <span style={{fontWeight:"bolder"}}>Designation&nbsp;:&nbsp;</span>
                            <input
                              className="add-profile-inpt-field"
                              placeholder={
                                userdetails?.Employment?.Company
                                  ? userdetails?.Employment?.Company
                                  : "Enter your designation"
                              }
                              type="text"
                              onChange={(e) =>
                                setEmploymentdesignation(e.target.value)
                              }
                            />
                          </p>
                          {/* //na poitu saptu vare  */}
                          <p>
                          <span style={{fontWeight:"bolder"}}>Location&nbsp;:&nbsp;</span>
                            <input
                              className="add-profile-inpt-field"
                              placeholder={
                                userdetails?.Employment?.Company
                                  ? userdetails?.Employment?.Company
                                  : "Enter Location"
                              }
                              type="text"
                              onChange={(e) =>
                                setEmploymentlocation(e.target.value)
                              }
                            />
                          </p>
                          <p>
                          <span style={{fontWeight:"bolder"}}> Period&nbsp;:&nbsp;</span>
                            <input
                              className="add-profile-inpt-field"
                              placeholder={
                                userdetails?.Employment?.Company
                                  ? userdetails?.Employment?.Company
                                  : "Enter Period"
                              }
                              type="text"
                              onChange={(e) =>
                                setEmploymentperiod(e.target.value)
                              }
                            />
                          </p>
                        </div>
                        <div className="gen-metabox1">
                          <span className="f-title1">
                            <i className="fas fa-certificate1">
                              <MdWork />
                            </i>{" "}
                            Work and experience
                          </span>
                          <input
                            className="add-profile-inpt-field"
                            placeholder={
                              workExperience
                                ? workExperience
                                : userdetails?.workAndExperience
                                ? userdetails?.workAndExperience
                                : "Enter your work experience"
                            }
                            type="text"
                            onChange={(e) => setworkExperience(e.target.value)}
                          />
                        </div>
                        <div className="gen-metabox1">
                          <span className="f-title1">
                            <i className="fas fa-certificate1">
                              <SiTalend />
                            </i>{" "}
                            Talent
                          </span>
                          <p>
                          <span style={{fontWeight:"bolder"}}> Insitution&nbsp;:&nbsp;</span>
                            <input
                              className="add-profile-inpt-field"
                              placeholder={
                                userdetails?.talent?.Institue
                                  ? userdetails?.talent?.Institue
                                  : "Enter Institution"
                              }
                              type="text"
                              onChange={(e) =>
                                setTalentinsitute(e.target.value)
                              }
                            />
                          </p>
                          <p>
                          <span style={{fontWeight:"bolder"}}> Course&nbsp;:&nbsp;</span>
                            <input
                              className="add-profile-inpt-field"
                              placeholder={
                                userdetails?.talent?.Course
                                  ? userdetails?.talent?.Course
                                  : "Enter Course"
                              }
                              type="text"
                              onChange={(e) => setTalentcourse(e.target.value)}
                            />
                          </p>
                          <p>
                          <span style={{fontWeight:"bolder"}}>  Location&nbsp;:&nbsp;</span>
                            <input
                              className="add-profile-inpt-field"
                              placeholder={
                                userdetails?.talent?.Location
                                  ? userdetails?.talent?.Location
                                  : "Enter Location"
                              }
                              type="text"
                              onChange={(e) =>
                                setTalentlocation(e.target.value)
                              }
                            />
                          </p>
                          <p>
                          <span style={{fontWeight:"bolder"}}>  Period&nbsp;:&nbsp;</span>
                            <input
                              className="add-profile-inpt-field"
                              placeholder={
                                userdetails?.talent?.Period
                                  ? userdetails?.talent?.Period
                                  : "Enter Period"
                              }
                              type="text"
                              onChange={(e) => setTalentperiod(e.target.value)}
                            />
                          </p>
                        </div>
                        <div className="gen-metabox1">
                          <span className="f-title1">
                            <i className="fas fa-certificate1">
                              <AiFillSkin />
                            </i>{" "}
                            Skill Set
                          </span>
                          <input
                            className="add-profile-inpt-field"
                            placeholder={
                              skillSet
                                ? skillSet
                                : userdetails?.SkillSet
                                ? userdetails?.SkillSet
                                : "Enter your skills"
                            }
                            type="text"
                            onChange={(e) => setskillSet(e.target.value)}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {/* middle page end =================================================== */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Add_profile;
