import "./BookformStay.css";
import bookImg from './booking.png'
import { BookFormStay } from "./BookFormStay";


export const BookStay = () => {
  return (
    <>
      <section className="contact" id="contact">
        <h2>
          Book your Stay
          <hr />
        </h2>
        <div className="book-info">
          <p>( Fill out the form below and we will get back to you with a confirmation as soon as possible. )</p>  
        </div>
        <div className="rowbook">
          <div className="img">
            <img src={bookImg} id="contactimg" alt={""}/>
          </div>
           <BookFormStay />
        </div>
      </section>
    </>
  );
};
