import React, { useEffect, useState } from "react";
import { Button, Table, DatePicker } from "antd";
import { BiEdit } from "react-icons/bi";
import { AiFillDelete } from "react-icons/ai";
import { FcApproval } from "react-icons/fc";
import { MdDelete } from "react-icons/md";
import { BiSolidEdit } from "react-icons/bi";
import { Container, Form, Modal } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCancel } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import axios from "axios";
import CustomInput from "../components/CustomInput";
import { useDropzone } from "react-dropzone";
import { useFormik } from "formik";
import { Input, Space } from "antd";
import { SearchOutlined } from "@ant-design/icons";

import * as XLSX from "xlsx";
import moment from "moment/moment";
const CarList = () => {
  const [color, setColor] = useState([]);
  const [images, setImages] = useState([]);

  const [uploadedImages, setUploadedImages] = useState([]);

  const onDrop = (acceptedFiles) => {
    // Update the state with the new uploaded files
    setUploadedImages([...uploadedImages, ...acceptedFiles]);
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: "image/*", // You can specify the accepted file types here
  });

  const formik = useFormik({
    initialValues: {
      title: "",
      description: "",
      Price: "",
      brand: "",
      category: "",
      tags: "",
      color: "",
      quantity: "",
      images: "",
    },

    onSubmit: (values) => {
      ////console.log(values);
    },
  });
  const formdata = new FormData();

  const uploadFiles = async (id) => {
    try {
      for (let i = 0; i < uploadedImages.length; i++) {
        formdata.append("id", id);
        formdata.append("Photos", uploadedImages[i]);
        await fetch("https://bookingoda.com/api/admin/addcarPhotos/", {
          method: "post",
          body: formdata,
        });
      }

      window.location.reload("");
    } catch (error) {
      ////console.log(error);
    }
  };
  const Update = async () => {
    try {
      const config = {
        url: "/updateCar",
        method: "post",
        baseURL: "https://bookingoda.com/api/admin",
        headers: { "content-type": "application/json" },
        data: {
          techid: selectedModal._id,
          CarName: formik.values.name
            ? formik.values.name
            : selectedModal.CarName,
          CarModel: formik.values.model
            ? formik.values.model
            : selectedModal.CarModel,
          YearofManufacture: formik.values.manufacture
            ? formik.values.manufacture
            : selectedModal.YearofManufacture,
          License: formik.values.License
            ? formik.values.License
            : selectedModal.License,
          Mileage: formik.values.Mileage
            ? formik.values.Mileage
            : selectedModal.Mileage,
          FuelType: formik.values.FuelType
            ? formik.values.FuelType
            : selectedModal.FuelType,
          Price: formik.values.Price
            ? formik.values.Price
            : selectedModal.Price,

          TransmissionType: formik.values.TransmissionType
            ? formik.values.TransmissionType
            : selectedModal.TransmissionType,
          Color: formik.values.Color
            ? formik.values.Color
            : selectedModal.Color,
          Seats: formik.values.Seats
            ? formik.values.Seats
            : selectedModal.Seats,

          Enginetype: formik.values.EngineType
            ? formik.values.EngineType
            : selectedModal.Enginetype,
          InsuranceDetails: formik.values.InsuranceDetails
            ? formik.values.InsuranceDetails
            : selectedModal.InsuranceDetails,
          // RegistrationDetails: formik.values.RegistrationDetails
          //   ? formik.values.RegistrationDetails
          //   : selectedModal.RegistrationDetails,
          DriverDetails: formik.values.DriverDetails
            ? formik.values.DriverDetails
            : selectedModal.DriverDetails,

          status: "Pending",
        },
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          if (uploadedImages.length === 0) {
            alert("Successfully Updated");

            getcar();

            window.location.reload();
          } else {
            uploadFiles(selectedModal._id);
          }
        }
      });
    } catch (error) {
      //////console.log(error);
      alert(error.response.data.error);
    }
  };

  const [data, setdata] = useState([]);
  const [show1, setShow1] = useState(false);
  const handleClose1 = () => setShow1(false);
  const [selectedModal, setselectedModal] = useState({});

  const handleShow1 = (id) => {
    setShow1(true);
    setselectedModal(id);
  };

  const [show2, setShow2] = useState(false);
  const handleClose2 = () => setShow2(false);
  const handleShow2 = () => setShow2(true);
  const [FullView, setFullView] = useState({});

  const [show3, setShow3] = useState(false);
  const handleClose3 = () => setShow3(false);
  const [Data1, setdata1] = useState("");

  const handleShow3 = (item) => {
    setShow3(true);
    setdata1(item);
  };
  const getcar = async () => {
    let res = await axios.get("https://bookingoda.com/api/admin/getallCar");
    if (res.status === 200) {
      //////console.log(res.data);
      setdata(res.data.Car);
    }
  };
  useEffect(() => {
    getcar();
  }, []);
  const Approve = async (id) => {
    try {
      const config = {
        url: "/admin/ApproveCar",
        method: "post",
        baseURL: "https://bookingoda.com/api",
        hearder: { "content-type": "application/json" },

        data: {
          Apartmentid: id,
          status: "Approved",
        },
      };
      await axios(config).then(function (res) {
        if (res.status === 200) {
          ////console.log(res.data);
          alert("Approved");
          window.location.reload();
        } else {
          alert("Something Wrong");
        }
      });
    } catch (error) {
      ////console.log(error.response);
    }
  };

  ////console.log(data);
  const block = async (data) => {
    try {
      axios
        .get(`https://bookingoda.com/api/admin/blockcar/` + data._id)
        .then(function (response) {
          if (response.status === 200) {
            ////console.log(response.data);
            alert("Blocked successfully");
            window.location.reload();
          }
        })
        .catch(function (error) {
          // setdatacondition(true);

          ////console.log(error.message.data);
          alert("Not able to complete");
        });
    } catch (error) {
      console.warn(error);
      alert("Not able to complete");
      // setdatacondition(true);
      return error;
    }
  };

  const unblock = async (data) => {
    try {
      axios
        .get(`https://bookingoda.com/api/admin/unblockcar/` + data._id)
        .then(function (response) {
          if (response.status === 200) {
            ////console.log(response.data);
            alert("Unblocked successfully");
            window.location.reload();
          }
        })
        .catch(function (error) {
          // setdatacondition(true);

          ////console.log(error.message.data);
          alert("Not able to complete");
        });
    } catch (error) {
      console.warn(error);
      alert("Not able to complete");
      // setdatacondition(true);
      return error;
    }
  };
  const DeleteTrip = async () => {
    try {
      const config = {
        url: "admin/daletecar/" + Data1,
        baseURL: "https://bookingoda.com/api",
        method: "DELETE",
        Headers: { "Content-Type": "application-json" },
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert(res.data.success);
          handleClose3();
          getcar();
        }
      });
    } catch (error) {
      alert(error.response.data.error);
    }
  };
  const [selectedImages, setSelectedImages] = useState([]);

  const toggleImageSelection = (imageId) => {
    setSelectedImages((prevSelected) => {
      if (prevSelected.includes(imageId)) {
        return prevSelected.filter((id) => id !== imageId);
      } else {
        return [...prevSelected, imageId];
      }
    });
  };

  const deleteSelectedImages = async () => {
    try {
     
      await removecarPhotos(FullView._id, selectedImages); 

 
      alert("Successfully removed");
      setSelectedImages([]);
    } catch (error) {
 
      if (error.response) {
        alert(error.response.data.error);
      } else {
        alert("Something went wrong");
      }
    }
  };
  const removecarPhotos = async (id1, ids) => {
    try {
      await Promise.all(
        ids.map(async (id) => {
      const config = {
        url: `/removecarPhotos/${id1}/${id}`,
        method: "delete",
        baseURL: "https://bookingoda.com/api/admin",
        headers: { "content-type": "application/json" },
      };

      let res = await axios(config);
      if (res.status !== 200) {
        throw new Error("Failed to delete image");
      }
    })
  );
  alert("Successfully removed");
  setShow2(false);
  getcar();
} catch (error) {
  if (error.response) {
    alert(error.response.data.error);
  } else {
    alert("Something went wrong");
  }
}
};
  const [data1, setData1] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  
  useEffect(() => {
    // Fetch data asynchronously (replace fetchDataCar with your actual data fetching logic)
    getcar().then((result) => {
      setdata(result);
    });
  }, []);

  useEffect(() => {
    // Ensure datacar is not undefined before transforming it
    if (data && data.length > 0) {
      const transformedData = transformData(data);
      setData1(transformedData);
      setFilteredData(transformedData);
    }
  }, [data]);
  const transformData = (data) => {
    return data.map((item,i) => {
      return {
        Sno:i+1,
        UniqueId: "BOCAR"+item._id.slice(22,24).toUpperCase(),
        CarName: item.CarName,
        Photos: item.Photos,
        CarModel: item.CarModel,
        YearofManufacture: item.YearofManufacture,
    date:moment(item.createdAt).format("DD/MM/YYYY"),

        License: item.License,
        Mileage: item.Mileage,
        FuelType: item.FuelType,
        Price: item.Price,
        TransmissionType: item.TransmissionType,
        Color: item.Color,
        Seats: item.Seats,
        Enginetype: item.Enginetype,
        InsuranceDetails: item.InsuranceDetails,
        // RegistrationDetails: item.RegistrationDetails,
        DriverDetails: item.DriverDetails,
        action2: (
          <>
            <button
              className="btn"
              style={{ backgroundColor: "#1677ff", color: "white" }}
              onClick={() => {
                handleShow2();
                setFullView(item);
              }}
            >
              Car Details
            </button>
          </>
        ),
        action: (
          <>
            {item.status === "Pending" ? (
              <Button
                className="btn btn-outline-success"
                style={{ cursor: "pointer" }}
                onClick={() => Approve(item._id)}
              >
                Approve
              </Button>
            ) : (
              <div className="btn btn-outline-success">
                <FcApproval /> Approved
              </div>
            )}
          </>
        ),
        action1: (
          <>
            <div className="d-flex gap-3 fs-8" style={{ cursor: "pointer"}}>
              {!item.blockstatus ? (
                <button
                  className="btn btn-outline-success"
                  onClick={() => block(item)}
                >
                  Block
                </button>
              ) : (
                <button
                  className="btn btn-outline-danger"
                  onClick={() => unblock(item)}
                >
                  Un-Block
                </button>
              )}
              {/* <BiSolidEdit
                className="text-primary"
                onClick={() => handleShow1(item)}
              />
              <MdDelete
                className="text-danger"
                onClick={() => handleShow3(item?._id)}
              /> */}
              <button
                  className="btn btn-outline-success"
                  onClick={() => handleShow1(item)}
                >
                 Edit
                </button>
                <button
                  className="btn btn-outline-danger"
                  onClick={() => handleShow3(item._id)}
                >
                 Delete
                </button>
            </div>
          </>
        
         ),
       };
     });
   };
  // const data1 = [];
  // for (let i = 0; i < data.length; i++) {
  //   data1.push({
  //     key: data[i]._id,
  //     CarName: data[i].CarName,
  //     Photos: data[i].Photos,

  //     CarModel: data[i].CarModel,
  //     YearofManufacture: data[i].YearofManufacture,
  //     License: data[i].License,
  //     Mileage: data[i].Mileage,
  //     FuelType: data[i].FuelType,
  //     Price: data[i].Price,

  //     TransmissionType: data[i].TransmissionType,
  //     Color: data[i].Color,

  //     Seats: data[i].Seats,
  //     Enginetype: data[i].Enginetype,

  //     InsuranceDetails: data[i].InsuranceDetails,
  //     RegistrationDetails: data[i].RegistrationDetails,
  //     DriverDetails: data[i].DriverDetails,

  //     action2: (
  //       <>
  //         <button
  //           className="btn"
  //           style={{ backgroundColor: "#1677ff", color: "white" }}
  //           onClick={() => {
  //             handleShow2();
  //             setFullView(data[i]);
  //           }}
  //         >
  //           Car Details
  //         </button>
  //       </>
  //     ),
  //     action: (
  //       <>
  //         {/* <Link to="/" className=" fs-3 text-danger">
  //             <BiEdit />
  //           </Link> */}
  //         {data[i].status === "Pending" ? (
  //           <Button
  //             className="ms-3 fs-8 text-success"
  //             style={{ cursor: "pointer" }}
  //             onClick={() => Approve(data[i]._id)}
  //           >
  //             Approve
  //           </Button>
  //         ) : (
  //           <div className="ms-3 fs-8 text-success">
  //             <FcApproval /> Approved
  //           </div>
  //         )}
  //       </>
  //     ),
  //     action1: (
  //       <>
  //          <div className="d-flex gap-4 fs-8" style={{ cursor: "pointer"}}>
  //         {!data[i].blockstatus ? (
  //           <button
  //             className="btn btn-outline-success"
  //             onClick={() => block(data[i])}
  //           >
  //             Block
  //           </button>
  //         ) : (
  //           <button
  //             className="btn btn-outline-danger"
  //             onClick={() => unblock(data[i])}
  //           >
  //             Un-Block
  //           </button>
  //         )}
  //           <BiSolidEdit
  //             className="text-primary"
  //             onClick={() => handleShow1(data[i])}
  //           />
  //           <MdDelete
  //             className="text-danger"
  //             onClick={() => handleShow3(data[i]?._id)}
  //           />
  //         </div>
  //       </>
  //     ),
  //   });
  // }

  const columns = [
    {
      title: "Serial No.",
      dataIndex: "Sno",

      // //fixed: "left",
      width: 100,
    },
    {
      title: "Unique ID",
      dataIndex: "UniqueId",

      // //fixed: "left",
      width: 150,
    
    },
    {
      title: "Car Name",
      dataIndex: "CarName",
      width: 150,

      // //fixed: "left",
   
    },
   
    {
      title: "Car Model",
      dataIndex: "CarModel",
      width: 100,
    
    },
    {
      title: "Created Date",
      dataIndex: "date",
      width: 150,
    },
    {
      title: "Car Details",
      dataIndex: "action2",
      key: "operation",
      // //fixed: "right",

      // render: () => <Link className="ms-3 fs-8 text-success">Approve</Link>,
      // width: 50,
      width: 150,
    },
    {
      title: "Action",
      dataIndex: "action1",
      key: "operation",
    
      width: 150,
      // render: () => <Link className="ms-3 fs-8 text-success">Approve</Link>,
     
    },
  ];
  const ExportToExcelButton = ({ dataSource, columns, filename }) => {
    const exportToExcel = () => {
      const worksheet = XLSX.utils.json_to_sheet(dataSource);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet 1");
      XLSX.writeFile(workbook, `${filename}.xlsx`);
    };

    return (
      <Button
        type="primary"
        onClick={exportToExcel}
        style={{
          margin: "10px",
          alignSelf: "flex-end",
          backgroundColor: "#1677ff",
          color: "white",
        }}
      >
        Export to Excel
      </Button>
    );
  };

  const [searchTerm, setSearchTerm] = useState("");
  // const [filteredData, setFilteredData] = useState(data1);
  ////console.log(filteredData, "vgvg");
  const handles = (value) => {
    setSearchTerm(value);

    // Filter the data based on the search term
    const filteredDataSource = data1.filter((record) => {
      return Object.values(record).some(
        (val) =>
          val && val.toString().toLowerCase().includes(value.toLowerCase())
      );
    });

    setFilteredData(filteredDataSource);
  };

  const onClearSearch = () => {
    setSearchTerm("");
    setFilteredData(data1);
  };

  const searchInput = (
    <Input
      placeholder="Search"
      value={searchTerm}
      style={{width:"50%"}}
      onChange={(e) => handles(e.target.value)}
      suffix={
        searchTerm && (
          <span
            onClick={onClearSearch}
            style={{ cursor: "pointer", color: "grey" }}
          >
            Clear
          </span>
        )
      }
      prefix={<SearchOutlined />}
    />
  );

  const pageSize = 5;
  return (
    <div>
      <h3 className="mb-4 title">Car Rental List</h3>
      <div
              style={{
                display: "flex",
                flexDirection: "row",
                // justifyContent: "space-around",
                margin: 10,
              }}
            >
               {searchInput}
      <ExportToExcelButton
        dataSource={filteredData}
        columns={columns}
        filename="Carlist"
      />
      </div>
      <div>
        <Table
          columns={columns} className="custom-table"
          dataSource={
            filteredData
          }
          scroll={{ x: "calc(700px + 50%)", y: 240 }}
          pagination={{
            pageSize: pageSize,
            showSizeChanger: false, // Optional: Hide the "Show X entries" dropdown
          }}
          components={{
            header: {
            
              cell: props => (
                <th {...props} style={{ ...props.style, background:'linear-gradient(#9B59B6,#8F51A8,#3E2349)'  ,color:"white"}} />
              ),
            },
          }}
        />
      </div>
      <Modal
        show={show3}
        onHide={handleClose3}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <div className="text-center">
            <h4 className="fw-bold text-dark mb-2">Are You Sure</h4>
            <p>This event data will be removed permanently</p>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="success" onClick={handleClose3}>
            Cancel
          </Button>
          <Button variant="danger" onClick={() => DeleteTrip()}>
            <FontAwesomeIcon icon={faCancel} className=" me-2" />
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal
        show={show1}
        onHide={handleClose1}
        dialogClassName="custom-modal"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title className="text-danger">Edit Car List</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Container fluid>
            <form
              onSubmit={formik.handleSubmit}
              className="d-flex gap-3 flex-column"
            >
              <CustomInput
                type="text"
                label="Enter Car Name"
                name="name"
                onChng={formik.handleChange("name")}
                onBlr={formik.handleBlur("name")}
                val={formik.values.name ? formik.values.name : selectedModal.CarName}
              />
              <div className="error">
                {formik.touched.name && formik.errors.name}
              </div>

              <CustomInput
                type="text"
                label="Enter Car Model"
                name="model"
                onChng={formik.handleChange("model")}
                onBlr={formik.handleBlur("model")}
                val={formik.values.model ? formik.values.model : selectedModal.CarModel}
              />
              <div className="error">
                {formik.touched.model && formik.errors.model}
              </div>

              <CustomInput
                type="text"
                label="Enter Year of Manufacture"
                name="manufacture"
                onChng={formik.handleChange("manufacture")}
                onBlr={formik.handleBlur("manufacture")}
                val={formik.values.manufacture ? formik.values.manufacture : selectedModal.YearofManufacture}
              />
              <div className="error">
                {formik.touched.manufacture && formik.errors.manufacture}
              </div>
              <CustomInput
                type="text"
                label="Registered Plate Number"
                name="License"
                onChng={formik.handleChange("License")}
                onBlr={formik.handleBlur("License")}
                val={formik.values.License ? formik.values.License : selectedModal.License}
              />
              <div className="error">
                {formik.touched.License && formik.errors.License}
              </div>
              <CustomInput
                type="text"
                label="Mileage"
                name="Mileage"
                onChng={formik.handleChange("Mileage")}
                onBlr={formik.handleBlur("Mileage")}
                val={formik.values.Mileage ? formik.values.Mileage : selectedModal.Mileage}
              />
              <div className="error">
                {formik.touched.Mileage && formik.errors.Mileage}
              </div>
              <CustomInput
                type="text"
                label="Fuel Type"
                name="FuelType"
                onChng={formik.handleChange("FuelType")}
                onBlr={formik.handleBlur("FuelType")}
                val={formik.values.FuelType ? formik.values.FuelType : selectedModal.FuelType}
              />
              <div className="error">
                {formik.touched.FuelType && formik.errors.FuelType}
              </div>
              <CustomInput
                type="text"
                label="Price/ Per KM"
                name="Price"
                onChng={formik.handleChange("Price")}
                onBlr={formik.handleBlur("Price")}
                val={formik.values.Price ? formik.values.Price : selectedModal.Price}
              />
              <div className="error">
                {formik.touched.Price && formik.errors.Price}
              </div>
              <CustomInput
                type="text"
                label="Transmission Type"
                name="TransmissionType"
                onChng={formik.handleChange("TransmissionType")}
                onBlr={formik.handleBlur("TransmissionType")}
                val={formik.values.TransmissionType ?  formik.values.TransmissionType : selectedModal.TransmissionType}
              />
              <div className="error">
                {formik.touched.TransmissionType &&
                  formik.errors.TransmissionType}
              </div>
              <CustomInput
                type="text"
                label="Color"
                name="Color"
                onChng={formik.handleChange("Color")}
                onBlr={formik.handleBlur("Color")}
                val={formik.values.Color ? formik.values.Color : selectedModal.Color}
              />
              <div className="error">
                {formik.touched.Color && formik.errors.Color}
              </div>
              <CustomInput
                type="text"
                label="Number of Seats"
                name="Seats"
                onChng={formik.handleChange("Seats")}
                onBlr={formik.handleBlur("Seats")}
                val={formik.values.Seats ? formik.values.Seats : selectedModal.Seats}
              />
              <div className="error">
                {formik.touched.Seats && formik.errors.Seats}
              </div>
              <CustomInput
                type="text"
                label="Engine Type"
                name="EngineType"
                onChng={formik.handleChange("EngineType")}
                onBlr={formik.handleBlur("EngineType")}
                val={formik.values.EngineType ? formik.values.EngineType : selectedModal.Enginetype}
              />
              <div className="error">
                {formik.touched.EngineType && formik.errors.EngineType}
              </div>
              <CustomInput
                type="text"
                label="Insurance Details"
                name="InsuranceDetails"
                onChng={formik.handleChange("InsuranceDetails")}
                onBlr={formik.handleBlur("InsuranceDetails")}
                val={formik.values.InsuranceDetails ? formik.values.InsuranceDetails : selectedModal.InsuranceDetails}
              />
              <div className="error">
                {formik.touched.InsuranceDetails &&
                  formik.errors.InsuranceDetails}
              </div>
              {/* <CustomInput
                type="text"
                label="Registration Details"
                name="RegistrationDetails"
                onChng={formik.handleChange("RegistrationDetails")}
                onBlr={formik.handleBlur("RegistrationDetails")}
                val={formik.values.RegistrationDetails ?  formik.values.RegistrationDetails : selectedModal.RegistrationDetails}
              />

              <div className="error">
                {formik.touched.RegistrationDetails &&
                  formik.errors.RegistrationDetails}
              </div>
              <div className="error">
                {formik.touched.EngineType && formik.errors.EngineType}
              </div> */}
              <CustomInput
                type="text"
                label="Driver Details"
                name="DriverDetails"
                onChng={formik.handleChange("DriverDetails")}
                onBlr={formik.handleBlur("DriverDetails")}
                val={formik.values.DriverDetails ?  formik.values.DriverDetails : selectedModal.DriverDetails}
              />
              <div className="error">
                {formik.touched.DriverDetails && formik.errors.DriverDetails}
              </div>
              <p style={{ color: "black", fontWeight: "600" }}>Upload Car Photos</p>
              <div className="bg-white border-1 p-5 text-center">
                <div
                  className="dropzone-container"
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    padding: "20px",
                  }}
                >
                  <div
                    className="dropzone"
                    style={{
                      border: "2px dashed #cccccc",
                      borderRadius: "4px",
                      padding: "20px",
                      textAlign: "center",
                      cursor: "pointer",
                      marginBottom: "20px",
                    }}
                    {...getRootProps()}
                  >
                    <input {...getInputProps()} />
                    <p>Drag & drop files here, or click to select files</p>
                  </div>
                  {uploadedImages.length > 0 && (
                    <div className="uploaded-images">
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          flexWrap: "wrap",
                        }}
                      >
                        {uploadedImages.map((file, index) => (
                          <img
                            key={index}
                            src={URL.createObjectURL(file)}
                            alt={`Uploaded ${index}`}
                            className="uploaded-image"
                            style={{
                              width: "130px",
                              height: "130px",
                              marginRight: "20px",
                            }}
                          />
                        ))}
                      </div>
                    </div>
                  )}
                </div>
              </div>

              <button
                className="btn btn-success border-0 rounded-3 my-5"
                type="submit"
                onClick={Update}
              >
                Update Car
              </button>
            </form>
          </Container>
        </Modal.Body>
        {/* <Modal.Footer>
            <Button variant="warning" onClick={handleClose1}>
              Close
            </Button>
            <Button variant="danger" onClick={handleClose1}>
              Save
            </Button>
          </Modal.Footer> */}
      </Modal>
      <Modal show={show2} onHide={handleClose2} size="lg">
        <Modal.Header closeButton>
          <Modal.Title className="text-danger">Car List</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div
            style={{
              marginTop: "30px",
              marginBottom: "20px",
              fontWeight: "900",
              fontSize: "18px",
              color: "white",
              border: "2px solid #B8BCC7",
              backgroundColor: "#B8BCC7",
              width: "100%",
              boxSizing: "border-box",
              padding: "10px",
            }}
          >
            Photos
          </div>
          <div
            style={{
              overflowY: "scroll",
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap",
              margin: "20px",
              // justifyContent: "space-between",
            }}
          >
           {FullView.Photos?.map((media) => (
              <div
                key={media._id}
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-around",
                }}
              >
               
                  <img
                    src={"https://bookingoda.com/image/" + media.file}
                    alt="image"
                    style={{ width: "80px", height: "80px" ,marginRight:"20px",marginBottom:"20px"}}
                    className={`image-item ${
                      selectedImages.includes(media._id) ? "selected" : ""
                    }`}
                    onClick={() => toggleImageSelection(media._id)}
                  />
             
                {/* <MdDelete
                  onClick={() => removeHotel(FullView._id, media._id)}
                  style={{ color: "red" }}
                /> */}
              </div>
            ))}
          </div>
          {selectedImages.length > 0 ? <button
              onClick={deleteSelectedImages}
              style={{
                padding: "10px 20px",
                backgroundColor: "red",
                color: "white",
                border: "none",
                borderRadius: "5px",
                cursor: "pointer",
                fontSize: "16px",
                fontWeight: "bold",
              }}
            >
              
              Delete {selectedImages.length} Selected Image
              {selectedImages.length !== 1 ? "s" : ""}
            </button> : ''}
          <div
            style={{
              marginTop: "30px",
              marginBottom: "20px",
              fontWeight: "900",
              fontSize: "18px",
              color: "white",
              border: "2px solid #B8BCC7",
              backgroundColor: "#B8BCC7",
              width: "100%",
              boxSizing: "border-box",
              padding: "10px",
            }}
          >
            Car Model
          </div>
          <div style={{ fontSize: "18px", marginLeft: "10px" }}>
            {FullView.CarModel}
          </div>
          <div
            style={{
              marginTop: "30px",
              marginBottom: "20px",
              fontWeight: "900",
              fontSize: "18px",
              color: "white",
              border: "2px solid #B8BCC7",
              backgroundColor: "#B8BCC7",
              width: "100%",
              boxSizing: "border-box",
              padding: "10px",
            }}
          >
            Year of Manufacture
          </div>
          <div style={{ fontSize: "18px", marginLeft: "10px" }}>
            {FullView.YearofManufacture}
          </div>
          <div
            style={{
              marginTop: "30px",
              marginBottom: "20px",
              fontWeight: "900",
              fontSize: "18px",
              color: "white",
              border: "2px solid #B8BCC7",
              backgroundColor: "#B8BCC7",
              width: "100%",
              boxSizing: "border-box",
              padding: "10px",
            }}
          >
           Registered Plate Number
          </div>
          <div style={{ fontSize: "18px", marginLeft: "10px" }}>
            {FullView.License}
          </div>
          <div
            style={{
              marginTop: "30px",
              marginBottom: "20px",
              fontWeight: "900",
              fontSize: "18px",
              color: "white",
              border: "2px solid #B8BCC7",
              backgroundColor: "#B8BCC7",
              width: "100%",
              boxSizing: "border-box",
              padding: "10px",
            }}
          >
           Mileage
          </div>
          <div style={{ fontSize: "18px", marginLeft: "10px" }}>
            {FullView.Mileage} 
          </div>
          <div
            style={{
              marginTop: "30px",
              marginBottom: "20px",
              fontWeight: "900",
              fontSize: "18px",
              color: "white",
              border: "2px solid #B8BCC7",
              backgroundColor: "#B8BCC7",
              width: "100%",
              boxSizing: "border-box",
              padding: "10px",
            }}
          >
           Fuel Type
          </div>
          <div style={{ fontSize: "18px", marginLeft: "10px" }}>
            {FullView.FuelType} 
          </div>
          <div
            style={{
              marginTop: "30px",
              marginBottom: "20px",
              fontWeight: "900",
              fontSize: "18px",
              color: "white",
              border: "2px solid #B8BCC7",
              backgroundColor: "#B8BCC7",
              width: "100%",
              boxSizing: "border-box",
              padding: "10px",
            }}
          >
          Price / Per KM
          </div>
          <div style={{ fontSize: "18px", marginLeft: "10px" }}>
            {FullView.Price} 
          </div>
          <div
            style={{
              marginTop: "30px",
              marginBottom: "20px",
              fontWeight: "900",
              fontSize: "18px",
              color: "white",
              border: "2px solid #B8BCC7",
              backgroundColor: "#B8BCC7",
              width: "100%",
              boxSizing: "border-box",
              padding: "10px",
            }}
          >
         Transmission Type
          </div>
          <div style={{ fontSize: "18px", marginLeft: "10px" }}>
            {FullView.TransmissionType} 
          </div>
          <div
            style={{
              marginTop: "30px",
              marginBottom: "20px",
              fontWeight: "900",
              fontSize: "18px",
              color: "white",
              border: "2px solid #B8BCC7",
              backgroundColor: "#B8BCC7",
              width: "100%",
              boxSizing: "border-box",
              padding: "10px",
            }}
          >
        Color
          </div>
          <div style={{ fontSize: "18px", marginLeft: "10px" }}>
            {FullView.Color} 
          </div>
          <div
            style={{
              marginTop: "30px",
              marginBottom: "20px",
              fontWeight: "900",
              fontSize: "18px",
              color: "white",
              border: "2px solid #B8BCC7",
              backgroundColor: "#B8BCC7",
              width: "100%",
              boxSizing: "border-box",
              padding: "10px",
            }}
          >
        Number Of Seats
          </div>
          <div style={{ fontSize: "18px", marginLeft: "10px" }}>
            {FullView.Seats} 
          </div>
          <div
            style={{
              marginTop: "30px",
              marginBottom: "20px",
              fontWeight: "900",
              fontSize: "18px",
              color: "white",
              border: "2px solid #B8BCC7",
              backgroundColor: "#B8BCC7",
              width: "100%",
              boxSizing: "border-box",
              padding: "10px",
            }}
          >
        Engine Type
          </div>
          <div style={{ fontSize: "18px", marginLeft: "10px" }}>
            {FullView.Enginetype} 
          </div>
          <div
            style={{
              marginTop: "30px",
              marginBottom: "20px",
              fontWeight: "900",
              fontSize: "18px",
              color: "white",
              border: "2px solid #B8BCC7",
              backgroundColor: "#B8BCC7",
              width: "100%",
              boxSizing: "border-box",
              padding: "10px",
            }}
          >
       Insurance Details
          </div>
          <div style={{ fontSize: "18px", marginLeft: "10px" }}>
            {FullView.InsuranceDetails} 
          </div>
          {/* <div
            style={{
              marginTop: "30px",
              marginBottom: "20px",
              fontWeight: "900",
              fontSize: "18px",
              color: "white",
              border: "2px solid #B8BCC7",
              backgroundColor: "#B8BCC7",
              width: "100%",
              boxSizing: "border-box",
              padding: "10px",
            }}
          >
       Registration Details
          </div>
          <div style={{ fontSize: "18px", marginLeft: "10px" }}>
            {FullView.RegistrationDetails} 
          </div> */}
          <div
            style={{
              marginTop: "30px",
              marginBottom: "20px",
              fontWeight: "900",
              fontSize: "18px",
              color: "white",
              border: "2px solid #B8BCC7",
              backgroundColor: "#B8BCC7",
              width: "100%",
              boxSizing: "border-box",
              padding: "10px",
            }}
          >
      Driver Details
          </div>
          <div style={{ fontSize: "18px", marginLeft: "10px" }}>
            {FullView.DriverDetails} 
          </div>
        </Modal.Body>
        {/* <Modal.Footer>
            <Button variant="warning" onClick={handleClose2}>
              Close
            </Button>
            <Button variant="danger" onClick={handleClose2}>
              Save
            </Button>
          </Modal.Footer> */}
      </Modal>
    </div>
  );
};

export default CarList;
