import React, { useEffect, useState } from "react";
import { Table } from "antd";
import { BiEdit } from "react-icons/bi";
import { AiFillDelete } from "react-icons/ai";
import { FcApproval } from "react-icons/fc";
import Switch from "react-switch";
import { City, Country, State } from "country-state-city";
import { Input, Space } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { MdDelete } from "react-icons/md";
import { BiSolidEdit } from "react-icons/bi";
import { Container, Form, Modal } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCancel } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";

import CustomInput from "../components/CustomInput";
import Apartment from "../../assets/apartments.png";
import Apartments from "../../assets/buildings.png";
import mutilocation from "../../assets/mutilocation.png";
import location from "../../assets/location.png";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepButton from "@mui/material/StepButton";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { Tooltip } from "antd";
import { DeleteOutlined } from "@material-ui/icons";
import axios from "axios";
import * as XLSX from "xlsx";
import moment from "moment";

const steps = [
  "Alternative Property Type",
  "Alternative Property Name",
  "Alternative Property Location",
  "Alternative Property Setup",
  "Alternative Property Guest Use",

  "Alternative Property Service",
  "Alternative Property Language",
  "Alternative Property HouseRules",
  "Alternative Property Photo",

  "Alternative Property Price",
  "Alternative Property Rate Plan",
  "Payment",
  "Invoice",
];
const stepsR = [
  "Add Rooms",
  "Bathroom Details",
  "Amenities",
  "Room Photos",
  "Room Name",
  "Room Price",
  "Submit",
];

const AlternativeList = () => {
  const [data, setdata] = useState([]);
  const [show1, setShow1] = useState(false);
  const handleClose1 = () => setShow1(false);
  const handleShow1 = (id) => {
    setShow1(true);
    setselectedModal(id);
  };
  const [showR, setShowR] = useState(false);
  const handleCloseR = () => setShowR(false);
  const handleShowR = (id) => {
    setShowR(true);
    setselectedModal(id);
  };
  const [showB, setShowB] = useState(false);
  const handleCloseB = () => setShowB(false);
  const handleShowB = (id) => {
    setShowB(true);
    setselectedModal(id);
  };
  const [showD, setShowD] = useState(false);

  const handleCloseD = () => setShowD(false);
  const handleShowD = (id) => {
    setShowD(true);
    setselectedModal(id);
  };
  const [discountType, setDiscountType] = useState("%");
  const [discountTypetax, setDiscountTypetax] = useState("%");

  const [discountValue, setDiscountValue] = useState("");
  const [tax, settax] = useState("");
  const handleKeyDown = (e) => {
    // Prevent the input of the minus sign
    if (e.key === "-" || e.key === "e" || e.key === "E") {
      e.preventDefault();
    }
  };
  const [RoombedCount, setRoomBedCount] = useState(1);
  const [Roombeds, setRoomBeds] = useState([]);

  const addRoomBed = () => {
    setRoomBedCount(RoombedCount + 1);
    setRoomBeds([
      ...Roombeds,
      { name: `RoomBed ${RoombedCount + 1}`, quantity: 1 },
    ]);
  };

  const removeRoomBed = (index) => {
    const updatedRoomBeds = Roombeds.filter((_, i) => i !== index);
    setRoomBeds(updatedRoomBeds);
  };
  ////console.log(Roombeds, "sds");
  const updateRoomBedQuantity = (index, newQuantity) => {
    const updatedRoomBeds = [...Roombeds];
    updatedRoomBeds[index].quantity = newQuantity;
    setRoomBeds(updatedRoomBeds);
  };

  const updateRoomBedName = (index, newName) => {
    const updatedRoomBeds = [...Roombeds];
    updatedRoomBeds[index].name = newName;
    setRoomBeds(updatedRoomBeds);
  };
  const [LRoombedCount, setLRoomBedCount] = useState(1);
  const [LRoombeds, setLRoomBeds] = useState([]);

  const addLRoomBed = () => {
    setLRoomBedCount(LRoombedCount + 1);
    setLRoomBeds([
      ...LRoombeds,
      { name: `LRoomBed ${LRoombedCount + 1}`, quantity: 1 },
    ]);
  };

  const removeLRoomBed = (index) => {
    const updatedLRoomBeds = LRoombeds.filter((_, i) => i !== index);
    setLRoomBeds(updatedLRoomBeds);
  };
  ////console.log(LRoombeds, "sds");
  const updateLRoomBedQuantity = (index, newQuantity) => {
    const updatedLRoomBeds = [...LRoombeds];
    updatedLRoomBeds[index].quantity = newQuantity;
    setLRoomBeds(updatedLRoomBeds);
  };

  const updateLRoomBedName = (index, newName) => {
    const updatedLRoomBeds = [...LRoombeds];
    updatedLRoomBeds[index].name = newName;
    setLRoomBeds(updatedLRoomBeds);
  };
  const [selected, setSelected] = useState(false);
  const [selected1, setSelected1] = useState(false);
  const [selectedYes, setSelectedYes] = useState(false);

  const [price, setprice] = useState();
  const [pricecommission, setpricecommission] = useState();
  const [selectedModal, setselectedModal] = useState({});
  ////console.log(selectedModal, "selectedModal");
  const [selectedNo, setSelectedNo] = useState(false);
  const [selectedtext, setselectedtext] = useState("");
  const [selectedtext1, setselectedtext1] = useState("");
  const [propertyname, setpropertyname] = useState("");
  const [country, setcountry] = useState("");
  const [streetname, setstreetname] = useState("");
  const [states, setstates] = useState({ isoCode: "", name: "" });
  console.log(states);
  const [pincode, setpincode] = useState();
  const [city, setcity] = useState("");
  const CountryList = Country.getAllCountries();
  const StateList = State.getStatesOfCountry(country);
  const CityList = City.getCitiesOfState(country, states?.isoCode);
  const formdata2 = new FormData();
  const formdata3 = new FormData();
  useEffect(() => {
    setstates({ isoCode: "", name: "" });
    setcity("");
  }, [country]);

  useEffect(() => {
    setcity("");
  }, [states]);
  const [optionsLocation, setOptionsLocation] = useState([
    {
      id: 1,
      label: "Police Station",
      value: "Police Station",
    },
    {
      id: 2,
      label: "Petrol bunk",
      value: "Petrol bunk",
    },
    {
      id: 3,
      label: "Hospital/Clinic",
      value: "Hospital/Clinic",
    },
    {
      id: 4,
      label: "Punture Shop",
      value: "Punture Shop",
    },
    {
      id: 5,
      label: "Food hotel",
      value: "Food hotel",
    },
    // Add more objects as needed
  ]);
  const [selectedOptionsLocation, setSelectedOptionsLocation] = useState([]);
  const [showOptionsLocation, setShowOptionsLocation] = useState(false);
  const [newOptionLocation, setNewOptionLocation] = useState("");

  const handleOptionClickLocation = (option) => {
    const isSelected = selectedOptionsLocation.some(
      (selectedOptionLocation) => selectedOptionLocation.id === option.id
    );
    if (isSelected) {
      setSelectedOptionsLocation(
        selectedOptionsLocation.filter(
          (selectedOptionLocation) => selectedOptionLocation.id !== option.id
        )
      );
    } else {
      setSelectedOptionsLocation([...selectedOptionsLocation, option]);
    }
  };

  const handleAddLocationOption = () => {
    if (newOptionLocation.trim() !== "") {
      const optionId = optionsLocation.length + 1;
      const newOptionLocationObj = {
        id: optionId,
        label: newOptionLocation,
        value: newOptionLocation.toLowerCase().replace(/\s/g, ""),
        extraData: "Additional Info for " + newOptionLocation,
      };
      setOptionsLocation([...optionsLocation, newOptionLocationObj]);
      setNewOptionLocation("");
    }
  };
  const [Singlebed, setSinglebed] = useState(0);
  const [Open, setOpen] = useState(false);
  const [Open1, setOpen1] = useState(false);

  const [Doublebed, setDoublebed] = useState(0);
  const [Largebed, setLargebed] = useState(0);

  const [XLargebed, setXLargebed] = useState(0);
  const [Sofabed, setSofabed] = useState(0);
  const [Stay, setStay] = useState(0);
  const [Bathroom, setBathroom] = useState(0);
  const [ApartmentSize, setApartmentSize] = useState(0);
  const [ApartmentType, setApartmentType] = useState("");
  const [selectedOptionBreakfast, setSelectedOptionBreakfast] = useState(""); // State to manage the selected option
  const [checkedItemsBreakfast, setCheckedItemsBreakfast] = useState({}); // State to manage checked items

  const handleCheckboxChangeBreakfast = (event) => {
    const { name, checked } = event.target;
    setCheckedItemsBreakfast({ ...checkedItemsBreakfast, [name]: checked });
  };
  const handleOptionChangeBreakfast = (event) => {
    setSelectedOptionBreakfast(event.target.value);
  };
  const [selectedOptionPrice, setSelectedOptionPrice] = useState(""); // State to manage the selected option

  const handleOptionChangePrice = (event) => {
    setSelectedOptionPrice(event.target.value);
  };
  const [BreakFastPrice, setBreakFastPrice] = useState("");
  const [checkboxesUse, setCheckboxesUse] = useState({
    Bar: false,
    Sauna: false,
    Garden: false,
    Terrace: false,
    "Hot tub/Jacuzzi": false,
    Heating: false,
    "Free WiFi": false,
    "Air conditioning": false,
    "Swimming pool": false,
  });

  const [newCheckboxLabelUse, setNewCheckboxLabelUse] = useState("");

  const handleCheckboxChangeUse = (option) => {
    setCheckboxesUse((prevCheckboxes) => ({
      ...prevCheckboxes,
      [option]: !prevCheckboxes[option],
    }));
  };
  ////console.log(checkboxesUse);
  const handleInputChangeUse = (event) => {
    setNewCheckboxLabelUse(event.target.value);
  };

  const handleAddCheckboxUse = () => {
    if (newCheckboxLabelUse) {
      setCheckboxesUse((prevCheckboxes) => ({
        ...prevCheckboxes,
        [newCheckboxLabelUse]: false,
      }));
      setNewCheckboxLabelUse("");
    }
  };
  const [checkboxesLanguage, setCheckboxesLanguage] = useState({
    English: false,
    Hindi: false,
    Kannada: false,
    Tamil: false,
    Telugu: false,
    Malayanam: false,
  });

  const [newCheckboxLabelLanguage, setNewCheckboxLabelLanguage] = useState("");

  const handleCheckboxChangeLanguage = (option) => {
    setCheckboxesLanguage((prevCheckboxesLanguage) => ({
      ...prevCheckboxesLanguage,
      [option]: !prevCheckboxesLanguage[option],
    }));
  };

  const handleInputChangeLanguage = (event) => {
    setNewCheckboxLabelLanguage(event.target.value);
  };

  const handleAddCheckboxLanguage = () => {
    if (newCheckboxLabelLanguage) {
      setCheckboxesLanguage((prevCheckboxesLanguage) => ({
        ...prevCheckboxesLanguage,
        [newCheckboxLabelLanguage]: false,
      }));
      setNewCheckboxLabelLanguage("");
    }
  };
  const [isCheckedSmoking, setIsCheckedSmoking] = useState(false);

  const [isCheckedPets, setIsCheckedPets] = useState(false);
  const [isCheckedParties, setIsCheckedParties] = useState(false);
  const [isCheckedChildren, setIsCheckedChildren] = useState(false);

  const handleToggleSmoking = () => {
    setIsCheckedSmoking(!isCheckedSmoking);
  };
  const handleTogglePets = () => {
    setIsCheckedPets(!isCheckedPets);
  };
  const handleToggleParties = () => {
    setIsCheckedParties(!isCheckedParties);
  };
  const handleToggleChildren = () => {
    setIsCheckedChildren(!isCheckedChildren);
  };
  const [selectedTime, setSelectedTime] = useState(""); // State to manage selected check-in time
  const [selectedTime1, setSelectedTime1] = useState(""); // State to manage selected check-in time
  const [selectedTime2, setSelectedTime2] = useState(""); // State to manage selected check-in time
  const [selectedTime3, setSelectedTime3] = useState(""); // State to manage selected check-in time

  const handleTimeChange = (event) => {
    setSelectedTime(event.target.value);
  };
  const handleTimeChange1 = (event) => {
    setSelectedTime1(event.target.value);
  };
  const handleTimeChange2 = (event) => {
    setSelectedTime2(event.target.value);
  };
  const handleTimeChange3 = (event) => {
    setSelectedTime3(event.target.value);
  };
  function generateTimeOptions() {
    const timeOptions = [];
    const periods = ["AM", "PM"];
  
    for (let period of periods) {
      for (let hour = 0; hour < 12; hour++) {
        const displayHour = hour === 0 ? 12 : hour;
        const timeBase = displayHour.toString().padStart(2, '0') + ":";
        
        timeOptions.push(timeBase + "00 " + period);
        timeOptions.push(timeBase + "15 " + period);
        timeOptions.push(timeBase + "30 " + period);
        timeOptions.push(timeBase + "45 " + period);
      }
    }
  
    return timeOptions;
  }
  
  const timeOptions = generateTimeOptions();
  const [uploadedImage, setUploadedImage] = useState([]);
  const handleFileChange = (event) => {
    const files = event.target.files;
    const newSelectedImages = [...uploadedImage, ...files];
    setUploadedImage(newSelectedImages);
  };

  const deleteImage = (index) => {
    const updatedImages = [...uploadedImage];
    updatedImages.splice(index, 1);
    setUploadedImage(updatedImages);
  };
  const [uploadedVideos, setUploadedVideos] = useState([]);

  const handleVideoChange = (event) => {
    const files = event.target.files;
    const newSelectedVideos = [...uploadedVideos, ...files];
    setUploadedVideos(newSelectedVideos);
  };
  const handleDeleteVideo = (index) => {
    const updatedVideos = [...uploadedVideos];
    updatedVideos.splice(index, 1);
    setUploadedVideos(updatedVideos);
  };
  const photoFileInput = uploadedImage.concat(uploadedVideos);

  const onSelected = () => {
    setSelected(true);
    setSelected1(false);

    setselectedtext(
      "Single Room: Guests rent a room within the property. There are common areas that are shared with either the host or other guests."
    );
  };
  const onSelectedYes = () => {
    setSelectedYes(true);
    setSelectedNo(false);
  };
  const onSelectedNo = () => {
    setSelectedYes(false);
    setSelectedNo(true);
  };
  const onSelected1 = () => {
    setSelected1(true);
    setSelected(false);

    setselectedtext(
      "Entire property: Guests have access to the entire place and don't have to share it with the host or other guests."
    );
  };

  const increaseRangeSinglebed = () => {
    setSinglebed(Singlebed + 1);
  };
  const decrementSinglebed = () => {
    setSinglebed(Singlebed - 1);
  };
  const increaseRangeDoublebed = () => {
    setDoublebed(Doublebed + 1);
  };
  const decrementDoublebed = () => {
    setDoublebed(Doublebed - 1);
  };
  const increaseRangeLargebed = () => {
    setLargebed(Largebed + 1);
  };
  const decrementLargebed = () => {
    setLargebed(Largebed - 1);
  };
  const increaseRangeXLargebed = () => {
    setXLargebed(XLargebed + 1);
  };
  const decrementXLargebed = () => {
    setXLargebed(XLargebed - 1);
  };
  const increaseRangeSofabed = () => {
    setSofabed(Sofabed + 1);
  };
  const decrementSofabed = () => {
    setSofabed(Sofabed - 1);
  };

  const increaseRangeStay = () => {
    setStay(Stay + 1);
  };
  const decrementStay = () => {
    setStay(Stay - 1);
  };
  const increaseRangeBathroom = () => {
    setBathroom(Bathroom + 1);
  };
  const decrementBathroom = () => {
    setBathroom(Bathroom - 1);
  };
  const HandleClickBedroom = () => {
    setOpen(!Open);
  };
  const HandleClickLivingRoom = () => {
    setOpen1(!Open1);
  };

  const [activeStep, setActiveStep] = React.useState(0);
  const [completed, setCompleted] = React.useState({});

  const totalSteps = () => {
    return steps.length;
  };

  const completedSteps = () => {
    return Object.keys(completed).length;
  };

  const isLastStep = () => {
    return activeStep === totalSteps() - 1;
  };

  const allStepsCompleted = () => {
    return completedSteps() === totalSteps();
  };

  const handleNext = () => {
    const newActiveStep =
      isLastStep() && !allStepsCompleted()
        ? steps.findIndex((step, i) => !(i in completed))
        : activeStep + 1;
    setActiveStep(newActiveStep);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStep = (step) => () => {
    setActiveStep(step);
  };

  const handleReset = () => {
    setActiveStep(0);
    setCompleted({});
  };
  const [activeStepR, setActiveStepR] = React.useState(0);
  const [completedR, setCompletedR] = React.useState({});

  const totalStepsR = () => {
    return stepsR.length;
  };

  const completedStepsR = () => {
    return Object.keys(completedR).length;
  };

  const isLastStepR = () => {
    return activeStepR === totalStepsR() - 1;
  };

  const allStepsCompletedR = () => {
    return completedStepsR() === totalStepsR();
  };

  const handleNextR = () => {
    const newActiveStepR =
      isLastStepR() && !allStepsCompletedR()
        ? stepsR.findIndex((step, i) => !(i in completedR))
        : activeStepR + 1;
    setActiveStepR(newActiveStepR);
  };

  const handleBackR = () => {
    setActiveStepR((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStepR = (step) => () => {
    setActiveStepR(step);
  };

  const handleResetR = () => {
    setActiveStepR(0);
    setCompletedR({});
  };
  const [show2, setShow2] = useState(false);
  const handleClose2 = () => setShow2(false);
  const handleShow2 = () => setShow2(true);
  const [FullView, setFullView] = useState({});

  const [show3, setShow3] = useState(false);
  const handleClose3 = () => setShow3(false);
  const handleShow3 = (item) => {
    setShow3(true);
    setdata1(item);
  };
  const handleComplete = () => {
    const newCompleted = completed;
    newCompleted[activeStep] = true;
    setCompleted(newCompleted);

    if (activeStep === 0) {
      handleNext();
    }

    if (activeStep === 1) {
      handleNext();
    }
    if (activeStep === 2) {
      handleNext();
    }
    if (activeStep === 3) {
      handleNext();
    }

    if (activeStep === 4) {
      handleNext();
    }

    if (activeStep === 5) {
      handleNext();
    }
    if (activeStep === 6) {
      handleNext();
    }
    if (activeStep === 7) {
      handleNext();
    }

    if (activeStep === 8) {
      handleNext();
    }
    if (activeStep === 9) {
      handleNext();
    }
    if (activeStep === 10) {
      handleNext();
    }

    if (activeStep === 11) {
      handleNext();
    }
  };
  const [RoomDetails, setRoomdetails] = useState([]);
  const AddRoom = () => {
    const obj = {
      Roomname: selectedOptionsRoomname,
      PrivateBathroom: privtebathroom,
      RoomStay: queststay,
      BathroomItems: checkboxesBathroomItems,
      Twinbed: twinbed,
      Queenbed: queenbed,
      Kingbed: kingbed,
      Fullbed: fullbed,
      beds: beds,
      RoomPrice: roomprice,
      RoomPhotos: uploadedImageroom?.map((file) => ({
        name: file.name,
        lastModified: file.lastModified,
        size: file.size,
        type: file.type,
        file: file,
        url: URL.createObjectURL(file),
        // ... other properties you want to include
      })),

      RoomType: type,
      RoomUnit: selectedOptionsRoom,
      RoomSize: roomsize,
      RoomMeasure: roommeasure,
      RoomSmoking: roomsmoking,
      General: checkboxesGeneral,
      Outdoors: checkboxesOutdoors,
      Food: checkboxesFood,
    };

    // Use the setRoomdetails to update the state with the new room details
    setRoomdetails((prevRoomDetails) => [...prevRoomDetails, obj]);

    handleNextR();
    // Log the updated state to verify
    //console.log("Roomdetails updated:", RoomDetails);
  };
  //console.log(RoomDetails);
  const postimage = async () => {
    const formData = new FormData();

    Array.from(uploadedImageroom).forEach((photo) => {
      formData.append("photo", photo);
    });
    if (uploadedImageroom) {
      try {
        const config = {
          url: "/admin/addRooms",
          method: "post",
          baseURL: "https://bookingoda.com/api",

          data: formData,
        };
        let res = await axios(config);
        if (res.status === 200) {
          //console.log(res.data);
          // alert("Image/Video Uploaded Successfully");
          handleNextR();
          // window.location.reload()
          return res;
        }
      } catch (error) {
        //console.log(error);
      }
    } else {
      alert("Please select a image/video");
    }
  };
  const handleCompleteR = () => {
    const newCompletedR = completedR;
    newCompletedR[activeStepR] = true;
    setCompletedR(newCompletedR);

    if (activeStepR === 0) {
      if (selectedOptionsRoom?.length < 1) {
        alert("Please select room type");
      } else if (type === "") {
        alert("Please enter number of room type selected before");
      } else if (queststay < 1) {
        alert("Please enter number of guests can stay in this room");
      } else if (roomsmoking === "") {
        alert("Please select smoking is allowed are not");
      } else {
        handleNextR();
      }
    }

    if (activeStepR === 1) {
      if (
        Object.values(checkboxesBathroomItems).every(
          (value) => value === false
        ) === true
      ) {
        alert("Please select bathroom Items");
      } else if (privtebathroom === "") {
        alert("Please select if bathroom is shared or private");
      } else {
        handleNextR();
      }
    }
    if (activeStepR === 2) {
      if (
        Object.values(checkboxesGeneral).every((value) => value === false) ===
        true
      ) {
        alert("Please select general Items");
      } else if (
        Object.values(checkboxesOutdoors).every((value) => value === false) ===
        true
      ) {
        alert("Please select views");
      } else if (
        Object.values(checkboxesFood).every((value) => value === false) === true
      ) {
        alert("Please select kitchen items");
      } else {
        handleNextR();
      }
    }
    if (activeStepR === 3) {
      if (uploadedImageroom?.length < 3) {
        alert("Please add atleast 3 photos");
      } else {
        postimage();
      }
    }

    if (activeStepR === 4) {
      if (selectedOptionsRoomname.length < 1) {
        alert("Please select room name");
      } else {
        handleNextR();
      }
    }

    if (activeStepR === 5) {
      if (roomprice === undefined) {
        alert("Please enter price");
      } else {
        AddRoom();
      }
    }

    if (activeStepR === 6) {
    }
  };
  const getalternative = async () => {
    let res = await axios.get(
      "https://bookingoda.com/api/admin/getallalternative"
    );
    if (res.status === 200) {
      //////console.log(res.data);
      setdata(
        res.data.alternative.filter(
          (e) => e.Deletestatus !== "Deleted By Admin"
        )
      );
    }
  };
  useEffect(() => {
    getalternative();
  }, []);
  const Approve = async (id) => {
    try {
      const config = {
        url: "/admin/Approvealternative",
        method: "post",
        baseURL: "https://bookingoda.com/api",
        hearder: { "content-type": "application/json" },

        data: {
          alternativeid: id,
          status: "Approved",
        },
      };
      await axios(config).then(function (res) {
        if (res.status === 200) {
          ////console.log(res.data);
          alert("Approved");
          window.location.reload();
        } else {
          alert("Something Wrong");
        }
      });
    } catch (error) {
      ////console.log(error.response);
    }
  };

  ////console.log(data);
  const [Data1, setdata1] = useState("");
  const DeleteForAdmin = async () => {
    try {
      const config = {
        url: "/admin/DeleteforAdminalternative",
        method: "post",
        baseURL: "https://bookingoda.com/api",
        hearder: { "content-type": "application/json" },

        data: {
          alternativeid: Data1,
          Deletestatus: "Deleted By Admin",
        },
      };
      await axios(config).then(function (res) {
        if (res.status === 200) {
          ////console.log(res.data);
          alert("Deleted SuccessFully");
          window.location.reload();
        } else {
          alert("Something Wrong");
        }
      });
    } catch (error) {
      ////console.log(error.response);
    }
  };
  const block = async (data) => {
    try {
      axios
        .get(`https://bookingoda.com/api/admin/blockalternative/` + data._id)
        .then(function (response) {
          if (response.status === 200) {
            ////console.log(response.data);
            alert("Blocked successfully");
            window.location.reload();
          }
        })
        .catch(function (error) {
          // setdatacondition(true);

          ////console.log(error.message.data);
          alert("Not able to complete");
        });
    } catch (error) {
      console.warn(error);
      alert("Not able to complete");
      // setdatacondition(true);
      return error;
    }
  };

  const unblock = async (data) => {
    try {
      axios
        .get(`https://bookingoda.com/api/admin/unblockalternative/` + data._id)
        .then(function (response) {
          if (response.status === 200) {
            ////console.log(response.data);
            alert("Unblocked successfully");
            window.location.reload();
          }
        })
        .catch(function (error) {
          // setdatacondition(true);

          ////console.log(error.message.data);
          alert("Not able to complete");
        });
    } catch (error) {
      console.warn(error);
      alert("Not able to complete");
      // setdatacondition(true);
      return error;
    }
  };
  const block1 = async (data) => {
    try {
      axios
        .get(
          `https://bookingoda.com/api/admin/addPriorityalternative/` + data._id
        )
        .then(function (response) {
          if (response.status === 200) {
            ////console.log(response.data);
            alert("Added to priority list successfully");
            window.location.reload();
          }
        })
        .catch(function (error) {
          // setdatacondition(true);

          ////console.log(error.message.data);
          alert("Not able to complete");
        });
    } catch (error) {
      console.warn(error);
      alert("Not able to complete");
      // setdatacondition(true);
      return error;
    }
  };
  const unblock1 = async (data) => {
    try {
      axios
        .get(
          `https://bookingoda.com/api/admin/unPriorityalternative/` + data._id
        )
        .then(function (response) {
          if (response.status === 200) {
            ////console.log(response.data);
            alert("Removed from priority list successfully");
            window.location.reload();
          }
        })
        .catch(function (error) {
          // setdatacondition(true);

          ////console.log(error.message.data);
          alert("Not able to complete");
        });
    } catch (error) {
      console.warn(error);
      alert("Not able to complete");
      // setdatacondition(true);
      return error;
    }
  };
  const DeleteTrip = async () => {
    try {
      const config = {
        url: "admin/daletealternative/" + Data1,
        baseURL: "https://bookingoda.com/api",
        method: "DELETE",
        Headers: { "Content-Type": "application-json" },
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert(res.data.success);
          handleClose3();
          getalternative();
        }
      });
    } catch (error) {
      alert(error.response.data.error);
    }
  };
  const [Cancel, setCancel] = useState(false);
  const [Group, setGroup] = useState(false);
  const [enable, setenable] = useState(false);
  const [reenable, setreenable] = useState(false);
  const [refamount, setrefamount] = useState(0);
  const [weekly, setweekly] = useState(false);
  const [weekamount, setweekamount] = useState(0);
  const [accidental, setaccidental] = useState(false);

  const [weekendamount, setweekendamount] = useState(0);

  const [Refund, setRefund] = useState(false);
  const [Week, setWeek] = useState(false);
  const [days, setdays] = useState("");

  const des = Array.from(
    { length: Stay ? Stay : selectedModal.QuestStayAlternative },
    (_, index) => (Stay ? Stay : selectedModal.QuestStayAlternative - index)
  );
  ////console.log(des);
  const [bedCount, setBedCount] = useState(1);
  const [beds, setBeds] = useState([]);

  const addBed = () => {
    setBedCount(bedCount + 1);
    setBeds([...beds, { name: `Bed ${bedCount + 1}`, quantity: 1 }]);
  };

  const removeBed = (index) => {
    const updatedBeds = beds.filter((_, i) => i !== index);
    setBeds(updatedBeds);
  };
  ////console.log(beds, "sds");
  const updateBedQuantity = (index, newQuantity) => {
    const updatedBeds = [...beds];
    updatedBeds[index].quantity = newQuantity;
    setBeds(updatedBeds);
  };

  const updateBedName = (index, newName) => {
    const updatedBeds = [...beds];
    updatedBeds[index].name = newName;
    setBeds(updatedBeds);
  };
  const [discount, setdiscount] = useState({});
  const [unit, setunit] = useState("");
  const [type, settype] = useState("");
  const [twinbed, settwinbed] = useState(0);
  const [fullbed, setfullbed] = useState(0);
  const [queenbed, setqueenbed] = useState(0);
  const [kingbed, setkingbed] = useState(0);
  const [queststay, setqueststay] = useState(0);
  const [roomsize, setroomsize] = useState(0);
  const [roommeasure, setroommeasure] = useState("");

  const [roomsmoking, setroomsmoking] = useState("");

  const increaseRangetwinbed = () => {
    settwinbed(twinbed + 1);
  };
  const decrementtwinbed = () => {
    settwinbed(twinbed - 1);
  };
  const increaseRangefullbed = () => {
    setfullbed(fullbed + 1);
  };
  const decrementfullbed = () => {
    setfullbed(fullbed - 1);
  };
  const increaseRangequeenbed = () => {
    setqueenbed(queenbed + 1);
  };
  const decrementqueenbed = () => {
    setqueenbed(queenbed - 1);
  };
  const increaseRangekingbed = () => {
    setkingbed(kingbed + 1);
  };
  const decrementkingbed = () => {
    setkingbed(kingbed - 1);
  };
  const increaseRangequeststay = () => {
    setqueststay(queststay + 1);
  };
  const decrementqueststay = () => {
    setqueststay(queststay - 1);
  };

  const [optionsRoom, setOptionsRoom] = useState([
    {
      id: 1,
      label: "Single",
      value: "Single",
    },
    {
      id: 2,
      label: "Double",
      value: "Double",
    },
    {
      id: 3,
      label: "Twin",
      value: "Twin",
    },
    {
      id: 4,
      label: "Twin/Double",
      value: "Twin/Double",
    },
    {
      id: 5,
      label: "Triple",
      value: "Triple",
    },
    {
      id: 6,
      label: "Quad",
      value: "Quad",
    },
    {
      id: 7,
      label: "Suite",
      value: "Suite",
    },
    {
      id: 8,
      label: "Family",
      value: "Family",
    },

    {
      id: 9,
      label: "Studio",
      value: "Studio",
    },
    {
      id: 10,
      label: "Apartment",
      value: "Apartment",
    },
    {
      id: 11,
      label: "Dorm Room",
      value: "Dorm Room",
    },
    {
      id: 12,
      label: "Bed in Dorm",
      value: "Bed in Dorm",
    },

    // Add more objects as needed
  ]);
  const [selectedOptionsRoom, setSelectedOptionsRoom] = useState([]);
  const [showOptionsRoom, setShowOptionsRoom] = useState(false);
  const [newOptionRoom, setNewOptionRoom] = useState("");
  const handleOptionClickRoom = (option) => {
    // Clear all selected options
    setSelectedOptionsRoom([]);

    // Add the clicked option
    setSelectedOptionsRoom([option]);
    setShowOptionsRoom(false);
  };

  const handleAddOptionRoom = () => {
    if (newOptionRoom?.trim() !== "") {
      const optionId = optionsRoom?.length + 1;
      const newOptionObj = {
        id: optionId,
        label: newOptionRoom,
        value: newOptionRoom?.toLowerCase().replace(/\s/g, ""),
        extraData: "Additional Info for " + newOptionRoom,
      };
      setOptionsRoom([...optionsRoom, newOptionObj]);
      setNewOptionRoom("");
    }
  };
  const handleOptionChangeRoomsmoking = (event) => {
    setroomsmoking(event.target.value);
  };
  const [checkboxesBathroomItems, setCheckboxesBathroomitems] = useState({
    "Toilet Paper": false,
    Shower: false,
    Toilet: false,

    "Hair dryer": false,
    Bathtub: false,
    "Free Toileteries": false,
    Bidet: false,
    Slippers: false,
    Bathrobe: false,
    "Spa tub": false,
  });

  const [newCheckboxLabelBathroom, setNewCheckboxLabelBathroom] = useState("");

  const handleCheckboxChangeBathroom = (option) => {
    setCheckboxesBathroomitems((prevCheckboxes) => ({
      ...prevCheckboxes,
      [option]: !prevCheckboxes[option],
    }));
  };
  ////console.log(checkboxesBathroomItems);
  const handleInputChangeBathroom = (event) => {
    setNewCheckboxLabelBathroom(event.target.value);
  };

  const handleAddCheckboxBathroom = () => {
    if (newCheckboxLabelBathroom) {
      setCheckboxesBathroomitems((prevCheckboxes) => ({
        ...prevCheckboxes,
        [newCheckboxLabelBathroom]: false,
      }));
      setNewCheckboxLabelBathroom("");
    }
  };
  const [privtebathroom, setprivtebathroom] = useState("");

  const handleOptionChangePrivatebathroom = (event) => {
    setprivtebathroom(event.target.value);
  };
  const [checkboxesGeneral, setCheckboxesGeneral] = useState({
    "Clothes rack": false,
    "Flat screen TV": false,
    Linens: false,
    "Air conditioning": false,
    Desk: false,
    "Wake-up service": false,
    Towels: false,
    Heating: false,
    Fan: false,
    Safe: false,
    "Towels/Sheets (extra fee)": false,
  });

  const [newCheckboxLabelGeneral, setNewCheckboxLabelGeneral] = useState("");

  const handleCheckboxChangeGeneral = (option) => {
    setCheckboxesGeneral((prevCheckboxes) => ({
      ...prevCheckboxes,
      [option]: !prevCheckboxes[option],
    }));
  };

  const handleInputChangeGeneral = (event) => {
    setNewCheckboxLabelGeneral(event.target.value);
  };

  const handleAddCheckboxGeneral = () => {
    if (newCheckboxLabelGeneral) {
      setCheckboxesGeneral((prevCheckboxes) => ({
        ...prevCheckboxes,
        [newCheckboxLabelGeneral]: false,
      }));
      setNewCheckboxLabelGeneral("");
    }
  };
  const [checkboxesOutdoors, setCheckboxesOutdoors] = useState({
    Balcony: false,
    Terrace: false,
    View: false,
  });

  const [newCheckboxLabelOutdoors, setNewCheckboxLabelOutdoors] = useState("");

  const handleCheckboxChangeOutdoors = (option) => {
    setCheckboxesOutdoors((prevCheckboxes1) => ({
      ...prevCheckboxes1,
      [option]: !prevCheckboxes1[option],
    }));
  };
  ////console.log(checkboxesOutdoors);
  const handleInputChangeOutdoors = (event) => {
    setNewCheckboxLabelOutdoors(event.target.value);
  };

  const handleAddCheckboxOutdoors = () => {
    if (newCheckboxLabelOutdoors) {
      setCheckboxesOutdoors((prevCheckboxes1) => ({
        ...prevCheckboxes1,
        [newCheckboxLabelOutdoors]: false,
      }));
      setNewCheckboxLabelOutdoors("");
    }
  };
  const [checkboxesFood, setCheckboxesFood] = useState({
    "Electric kettle": false,
    "Tea/Coffee maker": false,
    "Dining area": false,
    "Dining table": false,
    Microwave: false,
  });

  const [newCheckboxLabelFood, setNewCheckboxLabelFood] = useState("");

  const handleCheckboxChangeFood = (option) => {
    setCheckboxesFood((prevCheckboxes2) => ({
      ...prevCheckboxes2,
      [option]: !prevCheckboxes2[option],
    }));
  };
  ////console.log(checkboxesFood);
  const handleInputChangeFood = (event) => {
    setNewCheckboxLabelFood(event.target.value);
  };

  const handleAddCheckboxFood = () => {
    if (newCheckboxLabelFood) {
      setCheckboxesFood((prevCheckboxes2) => ({
        ...prevCheckboxes2,
        [newCheckboxLabelFood]: false,
      }));
      setNewCheckboxLabelFood("");
    }
  };

  const [optionsRoomname, setOptionsRoomname] = useState([
    {
      id: 1,
      label: "Double Room",
      value: "Double Room",
    },
    {
      id: 2,
      label: "Double Room with Private Bathroom",
      value: "Double Room with Private Bathroom",
    },

    // Add more objects as needed
  ]);
  const [selectedOptionsRoomname, setSelectedOptionsRoomname] = useState([]);
  const [showOptionsRoomname, setShowOptionsRoomname] = useState(false);
  const [newOptionRoomname, setNewOptionRoomname] = useState("");

  const handleOptionClickRoomname = (option) => {
    // Clear all selected options
    setSelectedOptionsRoomname([]);

    // Add the clicked option
    setSelectedOptionsRoomname([option]);
    setShowOptionsRoomname(false);
  };

  const handleAddOption = () => {
    if (newOptionRoomname.trim() !== "") {
      // Clear all selected options
      setSelectedOptionsRoomname([]);

      const optionId = optionsRoomname.length + 1;
      const newOptionObj = {
        id: optionId,
        label: newOptionRoomname,
        value: newOptionRoomname.toLowerCase().replace(/\s/g, ""),
        extraData: "Additional Info for " + newOptionRoomname,
      };
      setOptionsRoomname([...optionsRoomname, newOptionObj]);
      setNewOptionRoomname("");
    }
  };
  const [roomprice, setroomprice] = useState();
  const [credit, setcredit] = useState("");

  const handleOptionChangePayment = (event) => {
    setcredit(event.target.value);
  };
  const [name1, setname1] = useState("");
  const [same, setsame] = useState("");
  const [country1, setcountry1] = useState("");
  const [streetname1, setstreetname1] = useState("");
  const [states1, setstates1] = useState("");
  const [pincode1, setpincode1] = useState();
  const [city1, setcity1] = useState("");

  const handleOptionChangename = (event) => {
    setname1(event.target.value);
  };
  const handleOptionChangesame = (event) => {
    setsame(event.target.value);
  };
  const address = [];
  address.push(streetname, city, states?.name, country, pincode);
  const address1 = [];
  address1.push(streetname1, city1, states1, country1, pincode1);
  const languagesdata = Object.values(checkboxesLanguage).every(
    (value) => value === false
  );
  ////console.log(languagesdata, "vvv");
  // const updateAlternative = async () => {
  //   try {
  //     const config = {
  //       url: "/admin/updatealternative",
  //       method: "post",
  //       baseURL: "https://bookingoda.com/api",
  //       header: { "content-type": "application/json" },
  //       data: {
  //         techid: selectedModal._id,
  //         UserId: selectedModal.UserId,
  //         AlternativeType: selectedtext
  //           ? selectedtext
  //           : selectedModal.AlternativeType,

  //         Weekendrate: weekendamount
  //           ? weekendamount
  //           : selectedModal.Weekendrate,

  //         PropertyName: propertyname
  //           ? propertyname
  //           : selectedModal.propertyname,

  //         address:
  //           streetname !== "" &&
  //           city !== "" &&
  //           states !== "" &&
  //           country !== "" &&
  //           pincode !== ""
  //             ? address
  //             : selectedModal.address,
  //         Locationservices:
  //           selectedOptionsLocation.length !== 0
  //             ? selectedOptionsLocation
  //             : selectedModal.Locationservices,
  //         differentaddress:
  //           streetname1 !== "" &&
  //           city1 !== "" &&
  //           states1 !== "" &&
  //           country1 !== "" &&
  //           pincode1 !== ""
  //             ? address1
  //             : selectedModal.differentaddress,
  //         Singlebed: Singlebed ? Singlebed : selectedModal.Singlebed,
  //         Doublebed: Doublebed ? Doublebed : selectedModal.Doublebed,
  //         Largebed: Largebed ? Largebed : selectedModal.Largebed,
  //         XLargebed: XLargebed ? XLargebed : selectedModal.XLargebed,
  //         Sofabed: Sofabed ? Sofabed : selectedModal.Sofabed,
  //         QuestStayAlternative: Stay
  //           ? Stay
  //           : selectedModal.QuestStayAlternative,
  //         AlternativeStaySize: ApartmentSize
  //           ? ApartmentSize
  //           : selectedModal.AlternativeStaySize,

  //         AlternativeStayMeasure: ApartmentType
  //           ? ApartmentType
  //           : selectedModal.AlternativeStayMeasure,
  //         BathroomsAlternativeStay: Bathroom
  //           ? Bathroom
  //           : selectedModal.BathroomsAlternativeStay,
  //         AlternativeUse:
  //           Object.values(checkboxesUse).every((value) => value === false) ===
  //           false
  //             ? checkboxesUse
  //             : selectedModal.AlternativeUse,

  //         BreakfastIncluded: selectedOptionPrice
  //           ? selectedOptionPrice
  //           : selectedModal.BreakfastIncluded,

  //         Breakfast: selectedOptionBreakfast
  //           ? selectedOptionBreakfast
  //           : selectedModal.Breakfast,
  //         BreakfastOffers:
  //           Object.values(checkedItemsBreakfast).every(
  //             (value) => value === false
  //           ) === false
  //             ? checkedItemsBreakfast
  //             : selectedModal.BreakfastOffers,

  //         BreakfastPrice: BreakFastPrice
  //           ? BreakFastPrice
  //           : selectedModal.BreakfastPrice,

  //         Language:
  //           Object.values(checkboxesLanguage).every(
  //             (value) => value === false
  //           ) === false
  //             ? checkboxesLanguage
  //             : selectedModal.Language,
  //         Smoking: isCheckedSmoking ? isCheckedSmoking : selectedModal.Smoking,
  //         Pets: isCheckedPets ? isCheckedPets : selectedModal.Pets,
  //         Children: isCheckedChildren ? isCheckedChildren : selectedModal.Children,

  //         Parties: isCheckedParties ? isCheckedParties : selectedModal.Parties,
  //         CheckinFrom: selectedTime ? selectedTime : selectedModal.CheckinFrom,
  //         CheckinUntil: selectedTime1
  //           ? selectedTime1
  //           : selectedModal.CheckinUntil,
  //         CheckOutFrom: selectedTime2
  //           ? selectedTime2
  //           : selectedModal.CheckOutFrom,
  //         CheckOutUntil: selectedTime3
  //           ? selectedTime3
  //           : selectedModal.CheckOutUntil,

  //         Price: price ? price : selectedModal.Price,
  //         PriceCommision: pricecommission
  //           ? pricecommission
  //           : selectedModal.PriceCommision,

  //         Cancellationdays: days ? days : selectedModal.Cancellationdays,
  //         AccidentalBooking: accidental
  //           ? accidental
  //           : selectedModal.AccidentalBooking,
  //         Discount:
  //           Object.keys(discount).length !== 0 &&
  //           discount.constructor !== Object
  //             ? discount
  //             : selectedModal.Discount,
  //         DiscountRate: refamount ? refamount : selectedModal.DiscountRate,
  //         Weekdayrate: weekamount ? weekamount : selectedModal.Weekdayrate,
  //         RoomUnit:
  //           selectedOptionsRoom?.length !== 0
  //             ? selectedOptionsRoom
  //             : selectedModal.RoomUnit,
  //         RoomType: type ? type : selectedModal.RoomType,
  //         Twinbed: twinbed ? twinbed : selectedModal.Twinbed,
  //         Fullbed: fullbed ? fullbed : selectedModal.Fullbed,
  //         Queenbed: queenbed ? queenbed : selectedModal.Queenbed,
  //         Kingbed: kingbed ? kingbed : selectedModal.Kingbed,
  //         beds: beds ? beds : selectedModal.beds,
  //         Roombeds: Roombeds ? Roombeds : selectedModal.Roombeds,
  //         LRoombeds: LRoombeds ? LRoombeds : selectedModal.LRoombeds,

  //         RoomStay: queststay ? queststay : selectedModal.RoomStay,
  //         RoomSize: roomsize ? roomsize : selectedModal.RoomSize,
  //         RoomMeasure: roommeasure ? roommeasure : selectedModal.RoomMeasure,
  //         RoomSmoking: roomsmoking ? roomsmoking : selectedModal.RoomSmoking,
  //         PrivateBathroom: privtebathroom
  //           ? privtebathroom
  //           : selectedModal.PrivateBathroom,
  //         BathroomItems:
  //           Object.values(checkboxesBathroomItems).every(
  //             (value) => value === false
  //           ) === false
  //             ? checkboxesBathroomItems
  //             : selectedModal.BathroomItems,
  //         General:
  //           Object.values(checkboxesGeneral).every(
  //             (value) => value === false
  //           ) === false
  //             ? checkboxesGeneral
  //             : selectedModal.General,
  //         Outdoors:
  //           Object.values(checkboxesOutdoors).every(
  //             (value) => value === false
  //           ) === false
  //             ? checkboxesOutdoors
  //             : selectedModal.Outdoors,
  //         Food:
  //           Object.values(checkboxesFood).every((value) => value === false) ===
  //           false
  //             ? checkboxesFood
  //             : selectedModal.Food,
  //         Roomname:
  //           selectedOptionsRoomname.length !== 0
  //             ? selectedOptionsRoomname
  //             : selectedModal.Roomname,
  //         CreditCharges: credit ? credit : selectedModal.CreditCharges,
  //         InvoiceName: name1 ? name1 : selectedModal.InvoiceName,
  //         Sameaddress: same ? same : selectedModal.Sameaddress,
  //         status: selectedModal.status,
  //       },
  //     };
  //     await axios(config).then(function (res) {
  //       if (res.status === 200) {
  //         if (photoFileInput.length === 0) {
  //           alert("Alternative Property Updated");

  //           getalternative();

  //           window.location.reload();
  //         } else {
  //           uploadFiles(selectedModal._id);
  //         }
  //       } else {
  //         alert("Something Wrong");
  //       }
  //     });
  //   } catch (error) {
  //     alert(error.response.data.error);
  //   }
  // };
  const Rooms = RoomDetails.concat(selectedModal?.RoomDetails);
  const updateAlternative = async () => {
    try {
      const formData1 = new FormData();

      formData1.append("techid", selectedModal._id);

      formData1.append("UserId", selectedModal.UserId);
      formData1.append(
        "AlternativeType",
        selectedtext ? selectedtext : selectedModal.AlternativeType
      );

      formData1.append(
        "Weekendrate",
        weekendamount ? weekendamount : selectedModal.Weekendrate
      );
      formData1.append(
        "PropertyName",
        propertyname ? propertyname : selectedModal.PropertyName
      );
      formData1.append(
        "address",
        streetname !== "" &&
          city !== "" &&
          states?.name !== "" &&
          country !== "" &&
          pincode !== ""
          ? address
          : selectedModal.address
      );
      formData1.append(
        "Locationservices",
        selectedOptionsLocation.length !== 0
          ? JSON.stringify(selectedOptionsLocation)
          : JSON.stringify(selectedModal.Locationservices)
      );
      formData1.append(
        "differentaddress",
        streetname1 !== "" &&
          city1 !== "" &&
          states1 !== "" &&
          country1 !== "" &&
          pincode1 !== ""
          ? address1
          : selectedModal.differentaddress
      );
      formData1.append(
        "Singlebed",
        Singlebed ? Singlebed : selectedModal.Singlebed
      );
      formData1.append(
        "Doublebed",
        Doublebed ? Doublebed : selectedModal.Doublebed
      );
      formData1.append(
        "Largebed",
        Largebed ? Largebed : selectedModal.Largebed
      );
      formData1.append(
        "XLargebed",
        XLargebed ? XLargebed : selectedModal.XLargebed
      );
      formData1.append("Sofabed", Sofabed ? Sofabed : selectedModal.Sofabed);
      formData1.append(
        "QuestStayAlternative",
        Stay ? Stay : selectedModal.QuestStayAlternative
      );
      formData1.append(
        "AlternativeStaySize",
        ApartmentSize ? ApartmentSize : selectedModal.AlternativeStaySize
      );
      formData1.append(
        "AlternativeStayMeasure",
        ApartmentType ? ApartmentType : selectedModal.AlternativeStayMeasure
      );
      formData1.append(
        "BathroomsAlternativeStay",
        Bathroom ? Bathroom : selectedModal.BathroomsAlternativeStay
      );
      formData1.append(
        "Breakfast",
        selectedOptionBreakfast
          ? selectedOptionBreakfast
          : selectedModal.Breakfast
      );
      formData1.append(
        "BreakfastIncluded",
        selectedOptionPrice
          ? selectedOptionPrice
          : selectedModal.BreakfastIncluded
      );
      formData1.append(
        "BreakfastOffers",
        Object.values(checkedItemsBreakfast).every(
          (value) => value === false
        ) === false
          ? JSON.stringify(checkedItemsBreakfast)
          : JSON.stringify(selectedModal.BreakfastOffers)
      );
      formData1.append(
        "BreakfastPrice",
        BreakFastPrice ? BreakFastPrice : selectedModal.BreakfastPrice
      );

      formData1.append(
        "Language",
        Object.values(checkboxesLanguage).every((value) => value === false) ===
          false
          ? JSON.stringify(checkboxesLanguage)
          : JSON.stringify(selectedModal.Language)
      );
      formData1.append(
        "Smoking",
        isCheckedSmoking ? isCheckedSmoking : selectedModal.Smoking
      );
      formData1.append(
        "Pets",
        isCheckedPets ? isCheckedPets : selectedModal.Pets
      );
      formData1.append(
        "Children",
        isCheckedChildren ? isCheckedChildren : selectedModal.Children
      );
      formData1.append(
        "Parties",
        isCheckedParties ? isCheckedParties : selectedModal.Parties
      );
      formData1.append(
        "AlternativeUse",
        Object.values(checkboxesUse).every((value) => value === false) === false
          ? JSON.stringify(checkboxesUse)
          : JSON.stringify(selectedModal.AlternativeUse)
      );
      formData1.append(
        "CheckinFrom",
        selectedTime ? selectedTime : selectedModal.CheckinFrom
      );
      formData1.append(
        "CheckinUntil",
        selectedTime1 ? selectedTime1 : selectedModal.CheckinUntil
      );
      formData1.append(
        "CheckOutFrom",
        selectedTime2 ? selectedTime2 : selectedModal.CheckOutFrom
      );
      formData1.append(
        "CheckOutUntil",
        selectedTime3 ? selectedTime3 : selectedModal.CheckOutUntil
      );
      formData1.append("Price", price ? price : selectedModal.Price);
      formData1.append(
        "Cancellationdays",
        days ? days : selectedModal.Cancellationdays
      );
      formData1.append(
        "AccidentalBooking",
        accidental ? accidental : selectedModal.AccidentalBooking
      );
      formData1.append(
        "Discount",
        discount.length !== 0 && discount.constructor !== Object
          ? JSON.stringify(discount)
          : JSON.stringify(selectedModal.Discount)
      );
      formData1.append(
        "DiscountRate",
        refamount ? refamount : selectedModal.DiscountRate
      );
      formData1.append(
        "Weekdayrate",
        weekamount ? weekamount : selectedModal.Weekdayrate
      );
      formData1.append(
        "Roombeds",
        Roombeds
          ? JSON.stringify(Roombeds)
          : JSON.stringify(selectedModal.Roombeds)
      );
      formData1.append(
        "LRoombeds",
        LRoombeds
          ? JSON.stringify(LRoombeds)
          : JSON.stringify(selectedModal.LRoombeds)
      );
      formData1.append(
        "CreditCharges",
        credit ? credit : selectedModal.CreditCharges
      );
      formData1.append(
        "InvoiceName",
        name1 ? name1 : selectedModal.InvoiceName
      );
      formData1.append("Sameaddress", same ? same : selectedModal.Sameaddress);
      formData1.append("status", selectedModal.status);
      formData1.append(
        "PriceCommision",
        pricecommission ? pricecommission : selectedModal.PriceCommision
      );

      formData1.append(
        "Offerprice",
        discountValue
          ? discountValue + "" + discountType
          : selectedModal.Offerprice
      );
      formData1.append(
        "tax",
        tax ? tax + "" + discountTypetax : selectedModal.tax
      );

      formData1.append("RoomDetails", JSON.stringify(Rooms));

      const config = {
        url: "/updatealternative",
        method: "post",
        baseURL: "https://bookingoda.com/api/admin",
        headers: { "content-type": "multipart/form-data" },
        data: formData1,
      };

      const response = await axios(config);

      if (response.status === 200) {
        if (photoFileInput.length === 0) {
          alert("Successfully added");

          getalternative();

          window.location.reload();
        } else {
          uploadFiles(selectedModal._id);
        }
      } else {
        alert("Something Wrong");
      }
    } catch (error) {
      // Handle error
      console.error(error);
      alert(error.response.data.error);
    }
  };
  const removeRooms = async (id1, id) => {
    try {
      const config = {
        url: `/removeRoomsAlternative/${id1}/${id}`,
        method: "delete",
        baseURL: "https://bookingoda.com/api/admin",
        headers: { "content-type": "application/json" },
      };

      let res = await axios(config);
      if (res.status === 200) {
        alert("Successfully removed");
        setShow2(false);
        getalternative();
      } else {
        alert("Something Wrong");
      }
    } catch (error) {
      if (error.response) {
        alert(error.response.data.error);
      }
    }
  };
  const [uploadedImageroom, setUploadedImageroom] = useState([]);
  const [uploadedImageBadge, setUploadedImageBadge] = useState();

  const handleFileChangeRoom = (event) => {
    const files = event.target.files;
    const newSelectedImagesRoom = [...uploadedImageroom, ...files];
    setUploadedImageroom(newSelectedImagesRoom);
  };
  // const { getRootProps, getInputProps } = useDropzone({ onDrop });
  // const deleteImage = (index) => {
  //   const updatedImages = uploadedImageroom?.filter((_, i) => i !== index);
  //   setUploadedImageroom(updatedImages);
  // };
  const deleteImageRoom = (index) => {
    const updatedImages = [...uploadedImageroom];
    updatedImages.splice(index, 1);
    setUploadedImageroom(updatedImages);
  };
  const uploadFiles = async (id) => {
    try {
      for (let i = 0; i < photoFileInput.length; i++) {
        formdata2.append("id", id);
        formdata2.append("Photos", photoFileInput[i]);
        await fetch("https://bookingoda.com/api/admin/addalternativePhotos/", {
          method: "post",
          body: formdata2,
        });
        alert("Alternative Property Updated");

        getalternative();

        window.location.reload();
      }
    } catch (error) {
      ////console.log(error);
    }
  };
  const uploadBadge = async () => {
    try {
      formdata3.append("id", selectedModal._id);
      formdata3.append("Badge", uploadedImageBadge);
      await fetch("https://bookingoda.com/api/admin/addalternativeBadge/", {
        method: "post",
        body: formdata3,
      });
      alert("Badge added");

      getalternative();
      setUploadedImageBadge();
      setShowB(false);
    } catch (error) {}
  };
  const deleteImageBadge = () => {
    setUploadedImageBadge();
  };
  const [selectedImages, setSelectedImages] = useState([]);

  const toggleImageSelection = (imageId) => {
    setSelectedImages((prevSelected) => {
      if (prevSelected.includes(imageId)) {
        return prevSelected.filter((id) => id !== imageId);
      } else {
        return [...prevSelected, imageId];
      }
    });
  };

  const deleteSelectedImages = async () => {
    try {
      await removeAlternative(FullView._id, selectedImages);

      alert("Successfully removed");
      setSelectedImages([]);
    } catch (error) {
      if (error.response) {
        alert(error.response.data.error);
      } else {
        alert("Something went wrong");
      }
    }
  };
  const removeAlternative = async (id1, ids) => {
    try {
      await Promise.all(
        ids.map(async (id) => {
          const config = {
            url: `/removealternativePhotos/${id1}/${id}`,
            method: "delete",
            baseURL: "https://bookingoda.com/api/admin",
            headers: { "content-type": "application/json" },
          };

          let res = await axios(config);
          if (res.status !== 200) {
            throw new Error("Failed to delete image");
          }
        })
      );
      alert("Successfully removed");
      setShow2(false);
      getalternative();
    } catch (error) {
      if (error.response) {
        alert(error.response.data.error);
      } else {
        alert("Something went wrong");
      }
    }
  };
  const removeBadge = async (id1, id) => {
    try {
      const config = {
        url: `/removealternativeBadge/${id1}/${id}`,
        method: "delete",
        baseURL: "https://bookingoda.com/api/admin",
        headers: { "content-type": "application/json" },
      };

      let res = await axios(config);
      if (res.status === 200) {
        alert("Successfully removed");
        setShow2(false);
        getalternative();
      } else {
        alert("Something Wrong");
      }
    } catch (error) {
      if (error.response) {
        alert(error.response.data.error);
      }
    }
  };
  const [data1, setData1] = useState([]);
  const [filteredData, setFilteredData] = useState([]);

  useEffect(() => {
    // Fetch data asynchronously (replace fetchDataCar with your actual data fetching logic)
    getalternative().then((result) => {
      setdata(result);
    });
  }, []);

  useEffect(() => {
    // Ensure datacar is not undefined before transforming it
    if (data && data.length > 0) {
      const transformedData = transformData(data);
      setData1(transformedData);
      setFilteredData(transformedData);
    }
  }, [data]);
  const transformData = (data) => {
    return data.map((item, i) => {
      const combinedInfo = `${
        item.PrivateBathroom
      }, Bathroom items: ${JSON.stringify(item.BathroomItems)}`;
      const bedrooms = `Bedrooms Available: Single Bedroom- ${
        item.Singlebed
      }, Double Bedroom- ${item.Doublebed}, Large Bedroom- ${
        item.Largebed
      }, ExtraLarge Double Bedroom(Super King Size)- ${
        item.XLargebed
      }, Bedrooms- ${item.Roombeds?.map(
        (data) => `${data.name}:${data.quantity}`
      ).join(", ")}`;
      const livingRoom = `Living Room Beds: ${item.LRoombeds?.map(
        (data) => `${data.name}:${data.quantity}`
      ).join(", ")}, Sofa Bed: ${item.Sofabed}`;
      const refundDays = `Cancel their booking for free before: ${item.Cancellationdays}, Protection against accidental bookings: ${item.AccidentalBooking}, Non-refundable rate plan: ${item.DiscountRate}%, Week Day rate plan: ${item.Weekdayrate}%, Weekend rate plan: ${item.Weekendrate}%`;
      const roomType = `Number of room types: ${
        item.RoomType
      }, Beds: Twin bed(s)- ${item.Twinbed}, Full bed(s)- ${
        item.Fullbed
      }, Queen bed(s)- ${item.Queenbed}, King bed(s)- ${
        item.Kingbed
      }, Beds(s): ${item.beds
        ?.map((data) => `${data.name}:${data.quantity}`)
        .join(", ")}, Number of Guests can stay in this room: ${
        item.QuestStayApartment
      }, Room size: ${item.RoomSize}(${
        item.RoomMeasure
      }), Is smoking allowed in this room: ${item.RoomSmoking}`;
      return {
        Sno: i + 1,
        key: item._id,
        UniqueId: "BOSTAY" + item._id.slice(22, 24).toUpperCase(),
        VendorId: "BOVEN" + item.UserId.slice(22, 24).toUpperCase(),
        // UserName: item.userId?.name,
        PropertyName: item.PropertyName,
        Badge: item.Badge,
        AlternativeType: item.AlternativeType,
        address: item.address.join(","),
        Locationservices: JSON.stringify(
          item.Locationservices?.map((obj) => obj.label).join(", ")
        ),
        bedroom: bedrooms,
        LivingRoom: livingRoom,
        GuestStay: item.QuestStayAlternative,
        AlternativeUse: JSON.stringify(item.AlternativeUse),
        Bathrooms: item.BathroomsAlternativeStay,
        ApartmentSize: `${item.AlternativeStaySize} ${item.AlternativeStayMeasure}`,
        Breakfast:
          item.Breakfast === "No"
            ? "No Breakfast provided"
            : "Yes Breakfast provided",
        BreakfastIncluded: item.BreakfastIncluded,
        BreakfastPrice: item.BreakfastPrice,
        BreakfastOffers: JSON.stringify(item.BreakfastOffers),
        Language: JSON.stringify(item.Language),
        Refunddays: refundDays,
        Discountss: JSON.stringify(item.Discount),
        RoomUnit: JSON.stringify(
          item.RoomUnit?.map((item) => item.value).join(", ")
        ),
        HouseRules: `${item.Smoking}, ${item.Pets}, ${item.Parties} ,${item.Children}, Check-In: ${item.CheckinFrom}-${item.CheckinUntil}, Check-Out: ${item.CheckOutFrom}-${item.CheckOutUntil}`,
        Roomtype: roomType,
        combinedInfo: combinedInfo,
        amenities: JSON.stringify(item.General),
        Outdoors: JSON.stringify(item.Outdoors),
        Food: JSON.stringify(item.Food),
        RoomName: JSON.stringify(
          item.Roomname?.map((item) => item.label).join(", ")
        ),
        credit: item.CreditCharges,
        Invoice: `Invoice Name: ${
          item.InvoiceName
        }, Differentaddress: ${item.differentaddress?.join(", ")}`,
        price: `${item.Price}`,
        PriceCommision: `${item.PriceCommision}`,
        date: moment(item.createdAt).format("DD/MM/YYYY"),
        action2: (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
            }}
          >
            <button
              className="btn"
              style={{
                backgroundColor: "#1677ff",
                color: "white",
                marginBottom: "20px",
              }}
              onClick={() => {
                handleShow2();
                setFullView(item);
              }}
            >
              Property Details
            </button>
            <button
              className="btn"
              style={{
                backgroundColor: "#1677ff",
                color: "white",
                marginBottom: "20px",
              }}
              onClick={() => handleShowR(item)}
            >
              Add Rooms
            </button>
            <button
              className="btn"
              style={{
                backgroundColor: "#1677ff",
                color: "white",
                marginBottom: "20px",
              }}
              onClick={() => handleShowB(item)}
            >
              Add Badge
            </button>
            <button
              className="btn"
              style={{ backgroundColor: "#1677ff", color: "white" }}
              onClick={() => handleShowD(item)}
            >
              Add Discount
            </button>
          </div>
        ),
        action: (
          <>
            {item.status === "Pending" ? (
              <Button
                className="btn btn-outline-success"
                style={{ cursor: "pointer" }}
                onClick={() => Approve(item._id)}
              >
                Approve
              </Button>
            ) : (
              <div className="btn btn-outline-success">
                <FcApproval /> Approved
              </div>
            )}
          </>
        ),
        action1: (
          <>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                flexWrap: "wrap",
                justifyContent: "space-around",
                margin: "10px",
                cursor: "pointer",
              }}
            >
              {!item.blockstatus ? (
                <button
                  className="btn btn-outline-success"
                  onClick={() => block(item)}
                >
                  Block
                </button>
              ) : (
                <button
                  className="btn btn-outline-danger"
                  onClick={() => unblock(item)}
                >
                  Un-Block
                </button>
              )}

              <button
                className="btn btn-outline-success"
                onClick={() => handleShow1(item)}
              >
                Edit
              </button>
              <button
                className="btn btn-outline-danger"
                onClick={() => handleShow3(item._id)}
              >
                Delete
              </button>
              {!item.prioritystatus ? (
                <button
                  className="btn btn-outline-success"
                  style={{ margin: "10px" }}
                  onClick={() => block1(item)}
                >
                  Add Priority
                </button>
              ) : (
                <button
                  className="btn btn-outline-danger"
                  style={{ margin: "10px" }}
                  onClick={() => unblock1(item)}
                >
                  Remove Priority
                </button>
              )}
            </div>
          </>
        ),
      };
    });
  };
  // const data1 = [];
  // for (let i = 0; i < data.length; i++) {
  //   data1.push({
  //     key: data[i]._id,
  //     PropertyName: data[i].PropertyName,
  //     UserId: data[i].UserId,
  //     // Photos: data[i].Photos,

  //     AlternativeType: data[i].AlternativeType,

  //     address: data[i].address.join(","),
  //     Locationservices: JSON.stringify(data[i].Locationservices?.map((obj) => obj.value).join(", ")),
  //     bedroom:
  //     "Bedrooms Available: " +
  //     "Single Bedroom- " +
  //     data[i].Singlebed +
  //     "," +
  //     "Double Bedroom- " +
  //     data[i].Doublebed +
  //     "," +
  //     "Large Bedroom- " +
  //     data[i].Largebed +
  //     "," +
  //     "ExtraLarge Double Bedroom(Super King Size)- " +
  //     data[i].XLargebed +
  //     "," +
  //     "Bedrooms- " +
  //     data[i].Roombeds?.map((data) => data.name + ":" + data.quantity),
  //     LivingRoom:"Living Room Beds: " +
  //     data[i].LRoombeds?.map((data)=> data.name +":" + data.quantity)+ "Sofa Bed: " + data[i].Sofabed ,
  //     GuestStay: data[i].QuestStayAlternative,

  //     AlternativeUse: JSON.stringify(data[i].AlternativeUse),

  //     Bathrooms: data[i].BathroomsAlternativeStay,
  //     ApartmentSize:
  //       data[i].AlternativeStaySize + "  " + data[i].AlternativeStayMeasure,
  //     Breakfast:
  //       data[i].Breakfast === "No"
  //         ? "No Breakfast provided"
  //         : "Yes Breakfast provided",

  //     BreakfastIncluded: data[i].BreakfastIncluded,
  //     BreakfastPrice: data[i].BreakfastPrice,
  //     BreakfastOffers: JSON.stringify(data[i].BreakfastOffers),
  //     Language: JSON.stringify(data[i].Language),
  //     Refunddays:
  //     (" Cancel their booking for free before:" + data[i].Cancellationdays,
  //     "Protection against accidental bookings:" + data[i].AccidentalBooking,
  //     "Non-refundable rate plan:" + data[i].DiscountRate + "%",
  //     "Week Day rate plan:" + data[i].Weekdayrate + "%",
  //     "Weekend rate plan:" + data[i].Weekendrate + "%"),

  //     Discountss: JSON.stringify(data[i].Discount),
  //     RoomUnit: JSON.stringify(data[i].RoomUnit?.map((item) => item.value)?.join(" , ")),

  //     HouseRules:
  //       data[i].Smoking +
  //       "," +
  //       data[i].Pets +
  //       "," +
  //       data[i].Parties +
  //       ",   Check-In:  " +
  //       data[i].CheckinFrom +
  //       "-" +
  //       data[i].CheckinUntil +
  //       "   Check-Out:  " +
  //       data[i].CheckOutFrom +
  //       "-" +
  //       data[i].CheckOutUntil,

  //     Roomtype:
  //       "Number of room types: " +
  //       data[i].RoomType +
  //       ", " +
  //       "Beds: " +
  //       "," +
  //       "Twin bed(s): " +
  //       data[i].Twinbed +
  //       ",  Full bed(s): " +
  //       data[i].Fullbed +
  //       ",  Queen bed(s): " +
  //       data[i].Queenbed +
  //       ",  King bed(s): " +
  //       data[i].Kingbed +
  //       ",  Beds(s): " +
  //       data[i].beds?.map((data) => data.name + ":" + data.quantity) +
  //       ",  Number of Guests can stay in this room: " +
  //       data[i].QuestStayApartment +
  //       ",  Room size: " +
  //       data[i].RoomSize +
  //       "(" +
  //       data[i].RoomMeasure +
  //       ")" +
  //       ",  Is smoking allowed in this room: " +
  //       data[i].RoomSmoking,

  //    combinedInfo : `${data[i].PrivateBathroom}, Bathroom items: ${JSON.stringify(data[i].BathroomItems)}`,

  //    amenities: JSON.stringify(data[i].General),
  //    Outdoors: JSON.stringify(data[i].Outdoors),
  //    Food: JSON.stringify(data[i].Food),
  //    RoomName: JSON.stringify(data[i].Roomname?.map((item) => item.value)?.join(", ")),
  //     credit: data[i].CreditCharges,
  //     Invoice:
  //       "Invoice Name: " +
  //       data[i].InvoiceName +
  //       // ",  " + data[i].Sameaddress + " this recipient have the same address listed before" +

  //       ", " +
  //       "Differentaddress: " +
  //       data[i].differentaddress?.join(","),

  //     price: `${data[i].Price}`,
  //     PriceCommision: `${data[i].PriceCommision}`,
  //     action2: (
  //       <>
  //         <button
  //           className="btn"
  //           style={{backgroundColor:"#1677ff",color:'white'}}
  //           onClick={() => {
  //             handleShow2();
  //             setFullView(data[i]);
  //           }}
  //         >
  //           Property Details
  //         </button>
  //       </>
  //     ),
  //     action: (
  //       <>
  //         {/* <Link to="/" className=" fs-3 text-danger">
  //             <BiEdit />
  //           </Link> */}
  //         {data[i].status === "Pending" ? (
  //           <Button
  //             className="ms-3 fs-8 text-success"
  //             style={{ cursor: "pointer" }}
  //             onClick={() => Approve(data[i]._id)}
  //           >
  //             Approve
  //           </Button>
  //         ) : (
  //           <div className="ms-3 fs-8 text-success">
  //             <FcApproval /> Approved
  //           </div>
  //         )}
  //       </>
  //     ),
  //     action1: (
  //       <>
  //         <div className="d-flex gap-4 fs-8" style={{ cursor: "pointer"}}>
  //         {!data[i].blockstatus ? (
  //           <button
  //             className="btn btn-outline-success"
  //             onClick={() => block(data[i])}
  //           >
  //             Block
  //           </button>
  //         ) : (
  //           <button
  //             className="btn btn-outline-danger"
  //             onClick={() => unblock(data[i])}
  //           >
  //             Un-Block
  //           </button>
  //         )}
  //           <BiSolidEdit
  //             className="text-primary"
  //             onClick={() => handleShow1(data[i])}
  //           />
  //           <MdDelete
  //             className="text-danger"
  //             onClick={() => handleShow3(data[i]?._id)}
  //           />
  //         </div>
  //       </>
  //     ),
  //   });
  // }

  const columns = [
    {
      title: "Serial No.",
      dataIndex: "Sno",

      // //fixed: "left",
      width: 100,
    },
    {
      title: "Unique ID",
      dataIndex: "UniqueId",

      // //fixed: "left",
      width: 150,
    },
    {
      title: "Property Name",
      dataIndex: "PropertyName",

      // //fixed: "left",
      width: 150,
    },
    {
      title: "Vendor ID",
      dataIndex: "VendorId",
      width: 150,

      // //fixed: "left",
      // width: 50,
    },
    {
      title: "Created Date",
      dataIndex: "date",
      width: 150,
    },
    {
      title: "Property Details",
      dataIndex: "action2",
      key: "operation",
      width: 150,

      // //fixed: "right",

      // render: () => <Link className="ms-3 fs-8 text-success">Approve</Link>,
      width: 165,
    },
    {
      title: "Badge",
      dataIndex: "Badge",
      width: 150,
      render: (badges, row) => (
        <div
          style={{
            overflowY: "scroll",
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
            justifyContent: "space-between",
          }}
        >
          {badges?.map((badge) => (
            <div key={badge._id}>
              <img
                src={`https://bookingoda.com/image/${badge.file}`}
                alt="image"
                style={{ width: "100px", height: "100px", marginBottom: "5px" }}
              />
              <MdDelete
                onClick={() => removeBadge(row.key, badge._id)} // Adjust your removeBadge function
                style={{ color: "red" }}
              />
            </div>
          ))}
        </div>
      ),
    },
    {
      title: "Approved Status",
      dataIndex: "action",
      key: "operation",
      // //fixed: "right",

      // render: () => <Link className="ms-3 fs-8 text-success">Approve</Link>,
      width: 150,
    },
    {
      title: "Action",
      dataIndex: "action1",
      key: "operation",
      // //fixed: "right",

      // render: () => <Link className="ms-3 fs-8 text-success">Approve</Link>,
      width: 300,
    },
  ];
  const ExportToExcelButton = ({ dataSource, columns, filename }) => {
    const exportToExcel = () => {
      const dataToExport = dataSource.map(({ key, ...rest }) => rest);
      const worksheet = XLSX.utils.json_to_sheet(dataToExport);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet 1");
      XLSX.writeFile(workbook, `${filename}.xlsx`);
    };

    return (
      <Button
        type="primary"
        onClick={exportToExcel}
        style={{
          margin: "10px",
          alignSelf: "flex-end",
          backgroundColor: "#1677ff",
          color: "white",
        }}
      >
        Export to Excel
      </Button>
    );
  };

  const [searchTerm, setSearchTerm] = useState("");
  // const [filteredData, setFilteredData] = useState(data1);
  ////console.log(filteredData,"vgvg");
  const handles = (value) => {
    setSearchTerm(value);

    // Filter the data based on the search term
    const filteredDataSource = data1.filter((record) => {
      return Object.values(record).some(
        (val) =>
          val && val.toString().toLowerCase().includes(value.toLowerCase())
      );
    });

    setFilteredData(filteredDataSource);
  };

  const onClearSearch = () => {
    setSearchTerm("");
    setFilteredData(data1);
  };

  const searchInput = (
    <Input
      placeholder="Search"
      value={searchTerm}
      style={{ width: "50%" }}
      onChange={(e) => handles(e.target.value)}
      suffix={
        searchTerm && (
          <span
            onClick={onClearSearch}
            style={{ cursor: "pointer", color: "grey" }}
          >
            Clear
          </span>
        )
      }
      prefix={<SearchOutlined />}
    />
  );

  const pageSize = 5;
  useEffect(() => {
    if (
      selectedModal.AlternativeType ===
      "Single Room: Guests rent a room within the property. There are common areas that are shared with either the host or other guests."
    ) {
      setSelected(true);
      setSelected1(false);
    } else {
      setSelected1(true);
      setSelected(false);
    }
    setpropertyname(selectedModal.PropertyName);
    if (selectedModal.address) {
      const addr = selectedModal.address[0]?.split(",");
      setstreetname(addr[0]);
      setcity(addr[1]);
      setstates(addr[2]);
      setcountry(addr[3]);
      setpincode(addr[4]);
    }

    if (selectedModal.Locationservices) {
      setSelectedOptionsLocation(selectedModal.Locationservices);
    }
    if (selectedModal.Breakfast) {
      setSelectedOptionBreakfast(selectedModal.Breakfast);
    }

    if (selectedModal.BreakfastIncluded) {
      setSelectedOptionPrice(selectedModal.BreakfastIncluded);
    }

    if (selectedModal.BreakfastPrice) {
      setBreakFastPrice(JSON.parse(selectedModal.BreakfastPrice));
    }

    if (selectedModal.BreakfastOffers) {
      setCheckedItemsBreakfast(selectedModal.BreakfastOffers);
    }
    setIsCheckedSmoking(selectedModal.Smoking === "Smoking Allowed");
    setIsCheckedPets(selectedModal.Pets === "Pets Allowed");
    setIsCheckedParties(selectedModal.Parties === "Parties/events allowed");
    setIsCheckedChildren(selectedModal.Children === "Children Allowed");
    setSelectedTime(selectedModal.CheckinFrom);
    setSelectedTime1(selectedModal.CheckinUntil);
    setSelectedTime2(selectedModal.CheckOutFrom);
    setSelectedTime3(selectedModal.CheckOutUntil);
    setdays(selectedModal.Cancellationdays);
    setaccidental(selectedModal.AccidentalBooking !== "false" ? true : false);

    setdiscount(selectedModal.Discount);

    setrefamount(selectedModal.DiscountRate);
    setweekamount(selectedModal.Weekdayrate);
    setweekendamount(selectedModal.Weekendrate);
    setcredit(selectedModal.CreditCharges);
    setprice(selectedModal.Price);
    setpricecommission(selectedModal.PriceCommision);
    setname1(selectedModal.InvoiceName);
    setsame(selectedModal.Sameaddress);
    if (selectedModal.differentaddress) {
      const addr1 = selectedModal.differentaddress[0]?.split(",");
      setstreetname1(addr1[0]);
      setcity1(addr1[1]);
      setstates1(addr1[2]);
      setcountry1(addr1[3]);
      setpincode1(addr1[4]);
    }
  }, [selectedModal]);
  useEffect(() => {
    if (selectedModal && selectedModal.Language) {
      setCheckboxesLanguage((prev) => ({
        ...prev,
        ...selectedModal.Language,
      }));
    }
  }, [selectedModal]);
  useEffect(() => {
    if (selectedModal && selectedModal.AlternativeUse) {
      setCheckboxesUse((prev) => ({
        ...prev,
        ...selectedModal.AlternativeUse,
      }));
    }
  }, [selectedModal]);
  return (
    <div>
      <h3 className="mb-4 title">Alternative List</h3>

      <div
        style={{
          display: "flex",
          flexDirection: "row",
          // justifyContent: "space-around",
          margin: 10,
        }}
      >
        {searchInput}
        <ExportToExcelButton
          dataSource={filteredData}
          columns={columns}
          filename="Alternativelist"
        />
      </div>
      <div>
        <Table
          columns={columns}
          className="custom-table"
          dataSource={filteredData}
          scroll={{ x: "calc(700px + 50%)", y: 240 }}
          pagination={{
            pageSize: pageSize,
            showSizeChanger: false, // Optional: Hide the "Show X entries" dropdown
          }}
          components={{
            header: {
              cell: (props) => (
                <th
                  {...props}
                  style={{
                    ...props.style,
                    background: "linear-gradient(#6D4AC7,#493283,#2B1F49)",
                    color: "white",
                  }}
                />
              ),
            },
          }}
        />
      </div>
      <Modal
        show={show3}
        onHide={handleClose3}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <div className="text-center">
            <h4 className="fw-bold text-dark mb-2">Are You Sure</h4>
            <p>This event data will be removed permanently</p>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="success" onClick={handleClose3}>
            Cancel
          </Button>
          <Button variant="danger" onClick={() => DeleteForAdmin()}>
            <FontAwesomeIcon icon={faCancel} className=" me-2" />
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal
        show={show1}
        onHide={handleClose1}
        dialogClassName="custom-modal"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title className="text-danger">
            Edit Alternative location List
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Container fluid>
            {/* <div className="d-flex justify-content-between align-items-center pt-4 pb-2">
              <p className="text-success fs-4 fw-semibold">
                Alternative location List Edit:
              </p>
            </div> */}

            <div className="Stepper-info" style={{ padding: "20px" }}>
              <Box sx={{ width: "100%" }}>
                <Stepper
                  nonLinear
                  activeStep={activeStep}
                  style={{
                    display: "flex",
                    flexWrap: "wrap",
                    marginBottom: "20px",
                  }}
                >
                  {steps?.map((label, index) => (
                    <Step
                      key={label}
                      completed={completed[index]}
                      style={{ margin: "30px" }}
                    >
                      <StepButton color="inherit" onClick={handleStep(index)}>
                        {label}
                      </StepButton>
                    </Step>
                  ))}
                </Stepper>
                <div>
                  {allStepsCompleted() ? (
                    <React.Fragment>
                      <Typography
                        sx={{ mt: 2, mb: 1 }}
                        style={{
                          position: "absolute",
                          top: "50%",
                          left: "50%",
                          fontSize: "20px",
                          fontWeight: "500",
                        }}
                      >
                        All steps completed successfully
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            alignSelf: "center",
                            pt: 2,
                          }}
                        >
                          <Button onClick={handleReset}>Back</Button>
                        </Box>
                      </Typography>
                    </React.Fragment>
                  ) : (
                    <React.Fragment>
                      {activeStep == 0 ? (
                        <>
                          <div class="form-container">
                            <div class="f2cf178bcd screen-main-header d991e1216a">
                              <div class="f6431b446c e6208ee469 d0caee4251 screen-main-header__title">
                                <font style={{ verticalAlign: "inherit" }}>
                                  <font style={{ verticalAlign: "inherit" }}>
                                    What can guests book?
                                  </font>
                                </font>
                              </div>
                            </div>
                            <div class="ffb9c3d6a3 wide-container spacing--4x">
                              <div class="selectable-box-group">
                                <div
                                  class={
                                    !selected
                                      ? "selectable-box selectable-box--wide"
                                      : "selectable-box selectable-box--wide selectable-box--selected"
                                  }
                                  id="automation_id_choose_owner_type_more_than_one"
                                  role="checkbox"
                                  aria-checked="false"
                                  tabindex="0"
                                  data-testid="automation_id_choose_owner_type_more_than_one"
                                  // onClick={()=>window.location.assign("/apartment1")}
                                  onClick={onSelected}
                                >
                                  <div
                                    class="c82435a4b8 a6ae3c2b40 a18aeea94d selectable-box-card"
                                    style={{ padding: "30px" }}
                                  >
                                    <div class="aca0ade214 ebac6e22e9 f66f916626 c2931f4182 c27e5d305d db150fece4">
                                      <div class="b817090550 b1e9f8a61d">
                                        <div
                                          class="b795ec59f5"
                                          style={{ padding: "10%" }}
                                        >
                                          <div class="aea002392a">
                                            <div class="f8e5e86e33">
                                              <picture
                                                data-testid="SelectableBox-image"
                                                class="e5a3812a75 selectable-box-card__img"
                                                style={{
                                                  width: "50px",
                                                  height: "50px",
                                                  marginTop: "-20px",
                                                  marginLeft: "-20px",
                                                }}
                                              >
                                                <img
                                                  class="e3fa9175ee d354f8f44f ba6d792fd4 b1a5e281e7"
                                                  src={Apartment}
                                                  alt=""
                                                  role="presentation"
                                                  loading="lazy"
                                                />
                                              </picture>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="b817090550 d4e36dbd77">
                                        <div className="selectable-box-card__text-container">
                                          <div
                                            data-testid="SelectableBox-title"
                                            className="f2cf178bcd selectable-box-card__text"
                                          >
                                            <div className="e1eebb6a1e e6208ee469 d0caee4251">
                                              Single room
                                            </div>
                                          </div>
                                          <div
                                            data-testid="SelectableBox-text-description"
                                            className="abf093bdfe selectable-box-card__text"
                                          >
                                            Guests rent a room within the
                                            property. There are common areas
                                            that are shared with either the host
                                            or other guests.
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div
                                  class={
                                    !selected1
                                      ? "selectable-box selectable-box--wide"
                                      : "selectable-box selectable-box--wide selectable-box--selected"
                                  }
                                  id="automation_id_choose_owner_type_more_than_one"
                                  role="checkbox"
                                  aria-checked="false"
                                  tabindex="0"
                                  data-testid="automation_id_choose_owner_type_more_than_one"
                                  // onClick={()=>window.location.assign("/multiapartmenttype")}
                                  onClick={onSelected1}
                                >
                                  <div
                                    class="c82435a4b8 a6ae3c2b40 a18aeea94d selectable-box-card"
                                    style={{ padding: "30px" }}
                                  >
                                    <div class="aca0ade214 ebac6e22e9 f66f916626 c2931f4182 c27e5d305d db150fece4">
                                      <div class="b817090550 b1e9f8a61d">
                                        <div
                                          class="b795ec59f5"
                                          style={{ padding: "10%" }}
                                        >
                                          <div class="aea002392a">
                                            <div class="f8e5e86e33">
                                              <picture
                                                data-testid="SelectableBox-image"
                                                class="e5a3812a75 selectable-box-card__img"
                                                style={{
                                                  width: "50px",
                                                  height: "50px",
                                                  marginTop: "-20px",
                                                  marginLeft: "-20px",
                                                }}
                                              >
                                                <img
                                                  class="e3fa9175ee d354f8f44f ba6d792fd4 b1a5e281e7"
                                                  src={Apartments}
                                                  alt=""
                                                  role="presentation"
                                                  loading="lazy"
                                                />
                                              </picture>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="b817090550 d4e36dbd77">
                                        <div className="selectable-box-card__text-container">
                                          <div
                                            data-testid="SelectableBox-title"
                                            className="f2cf178bcd selectable-box-card__text"
                                          >
                                            <div className="e1eebb6a1e e6208ee469 d0caee4251">
                                              Entire property
                                            </div>
                                          </div>
                                          <div
                                            data-testid="SelectableBox-text-description"
                                            className="abf093bdfe selectable-box-card__text"
                                          >
                                            Guests have access to the entire
                                            place and don't have to share it
                                            with the host or other guests.
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </>
                      ) : activeStep == 1 ? (
                        <>
                          <div class="ffb9c3d6a31 wide-container min-height">
                            <div class="aca0ade214 ebac6e22e9 f66f916626 c2931f4182 c27e5d305d db150fece4">
                              <div class="b817090550 b736e9e3f4">
                                <div class="form-group">
                                  <div class="a53cbfa6de ac9267e216 a20293ec70">
                                    <label
                                      for="idproperty_name"
                                      class="a53cbfa6de e6208ee469 f555271986"
                                    >
                                      <span>
                                        <span class="ccb65902b2">
                                          Property name
                                        </span>
                                      </span>
                                    </label>
                                    <div class="b9b84f4305">
                                      <div class="e000754250">
                                        <input
                                          name="property_name"
                                          class="eb46370fe1"
                                          data-testid="PropertyName-"
                                          id="idproperty_name"
                                          value={propertyname}
                                          onChange={(e) =>
                                            setpropertyname(e.target.value)
                                          }
                                        />
                                        <div class="e7e5251f68"></div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </>
                      ) : activeStep == 2 ? (
                        <>
                          <div class="b817090550 b736e9e3f4">
                            <div class="a53cbfa6de ac9267e216 form-group">
                              <label
                                for="cc1"
                                class="a53cbfa6de e6208ee469 f555271986"
                              >
                                <span>
                                  <span class="ccb65902b2">Country/region</span>
                                </span>
                              </label>
                              <div class="cabad3c686 aff44befa2">
                                <select
                                  class="ebf4591c8e country-select-for-e2e-tests"
                                  name="cc1"
                                  id="cc1"
                                  value={country}
                                  onChange={(e) => setcountry(e.target.value)}
                                >
                                  <option value="">
                                    Select country/region
                                  </option>

                                  {CountryList?.map((Country) => (
                                    <option
                                      value={Country?.isoCode}
                                      data-key="in"
                                    >
                                      {Country?.name}
                                    </option>
                                  ))}
                                </select>
                                <span class="ad2c299542">
                                  <span
                                    class="fcd9eec8fb cab1c196b5 c2cc050fb8 f64eb5d122"
                                    aria-hidden="true"
                                  >
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      viewBox="0 0 24 24"
                                    >
                                      <path d="M19.2681 8.91288C19.2687 9.03226 19.2454 9.15054 19.1996 9.26079C19.1539 9.37105 19.0865 9.47105 19.0016 9.55493L12.9445 15.612C12.8211 15.7367 12.6738 15.8353 12.5115 15.9018C12.3491 15.9684 12.1751 16.0017 11.9996 15.9997C11.6491 16.0075 11.3096 15.877 11.0547 15.6362L4.99764 9.57916C4.8275 9.40881 4.73193 9.17788 4.73193 8.93711C4.73193 8.69634 4.8275 8.46542 4.99764 8.29506C5.08117 8.20935 5.18101 8.14122 5.29128 8.09471C5.40155 8.04819 5.52002 8.02423 5.6397 8.02423C5.75937 8.02423 5.87784 8.04819 5.98811 8.09471C6.09838 8.14122 6.19822 8.20935 6.28175 8.29506L11.9996 13.9887L17.7175 8.27084C17.801 8.18512 17.9009 8.11699 18.0111 8.07048C18.1214 8.02396 18.2399 8 18.3596 8C18.4792 8 18.5977 8.02396 18.708 8.07048C18.8182 8.11699 18.9181 8.18512 19.0016 8.27084C19.0871 8.3543 19.1548 8.45422 19.2006 8.56457C19.2464 8.67492 19.2694 8.79341 19.2681 8.91288Z"></path>
                                    </svg>
                                  </span>
                                </span>
                              </div>
                            </div>
                            <div class="a53cbfa6de ac9267e216 form-group manual-address-form__postcode a20293ec70">
                              <label
                                for=":r1t:"
                                class="a53cbfa6de e6208ee469 f555271986"
                              >
                                <span>
                                  <span class="ccb65902b2">
                                    Near by services with contact number
                                  </span>
                                </span>
                              </label>
                              <div className="e000754250">
                                <input
                                  type="text"
                                  value={newOptionLocation}
                                  onChange={(e) =>
                                    setNewOptionLocation(e.target.value)
                                  }
                                  placeholder="Type to add or search..."
                                  class="eb46370fe1"
                                  onClick={() =>
                                    setShowOptionsLocation(!showOptionsLocation)
                                  }
                                />
                              </div>
                              <button
                                style={{
                                  marginTop: "10px",
                                  padding: "10px 20px",
                                  fontSize: "16px",
                                  backgroundColor: "#70bbe9",
                                  color: "white",
                                  border: "none",
                                  borderRadius: "5px",
                                  cursor: "pointer",
                                  transition: "background-color 0.3s",
                                }}
                                onClick={handleAddLocationOption}
                              >
                                Add
                              </button>
                              {showOptionsLocation && (
                                <div className="ebf4591c8e">
                                  {optionsLocation?.map((option) => (
                                    <div
                                      // style={{ padding: "10px",
                                      //   cursor: "pointer",
                                      //   transition: "background-color 0.3s"}}
                                      key={option.id}
                                      style={{
                                        padding: "10px",
                                        cursor: "pointer",
                                        color: "black",
                                        backgroundColor:
                                          selectedOptionsLocation.some(
                                            (selectedOptionLocation) =>
                                              selectedOptionLocation.id ===
                                              option.id
                                          )
                                            ? "#ddd"
                                            : "transparent",
                                      }}
                                      onClick={() =>
                                        handleOptionClickLocation(option)
                                      }
                                    >
                                      {option.label}
                                    </div>
                                  ))}
                                </div>
                              )}

                              {selectedOptionsLocation && (
                                <div className="selected-option">
                                  Selected Location Services:{" "}
                                  {selectedOptionsLocation
                                    ?.map((option) => option.label)
                                    .join(", ")}
                                </div>
                              )}
                            </div>
                            <form autocomplete="off" class="form-group">
                              <div
                                class="autocomplete-suggestions-dropdown"
                                data-testid="autocomplete_container"
                              >
                                <div class="a53cbfa6de ac9267e216 a20293ec70">
                                  <label
                                    for="address-autocomplete"
                                    class="a53cbfa6de e6208ee469 f555271986"
                                  >
                                    <span>
                                      <span class="ccb65902b2">
                                        Street name and house number
                                      </span>
                                    </span>
                                  </label>
                                  <div class="b9b84f4305">
                                    <div class="e000754250">
                                      <input
                                        class="eb46370fe1"
                                        placeholder="Start typing your address"
                                        value={streetname}
                                        onChange={(e) =>
                                          setstreetname(
                                            e.target.value.replace(/,/g, "")
                                          )
                                        }
                                      />
                                      <div class="e7e5251f68"></div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </form>
                            <label
                              for="cc1"
                              class="a53cbfa6de e6208ee469 f555271986"
                            >
                              <span>
                                <span class="ccb65902b2">State</span>
                              </span>
                            </label>
                            <div class="cabad3c686 aff44befa2">
                              <select
                                class="ebf4591c8e country-select-for-e2e-tests"
                                name="cc1"
                                id="cc1"
                                value={states?.isoCode} // Use states?.isoCode here
                                onChange={(e) => {
                                  const selectedState = StateList.find(
                                    (state) => state.isoCode === e.target.value
                                  );
                                  setstates(
                                    selectedState
                                      ? {
                                          isoCode: selectedState.isoCode,
                                          name: selectedState.name,
                                        }
                                      : { isoCode: "", name: "" }
                                  );
                                }}
                              >
                                <option value="" style={{ fontSize: "16px" }}>
                                  Select state/region{" "}
                                  <span style={{ color: "red" }}>*</span>
                                </option>
                                {StateList?.map((state) => (
                                  <option
                                    key={state.isoCode}
                                    value={state.isoCode}
                                  >
                                    {state.name}
                                  </option>
                                ))}
                              </select>
                              <span class="ad2c299542">
                                <span
                                  class="fcd9eec8fb cab1c196b5 c2cc050fb8 f64eb5d122"
                                  aria-hidden="true"
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 24 24"
                                  >
                                    <path d="M19.2681 8.91288C19.2687 9.03226 19.2454 9.15054 19.1996 9.26079C19.1539 9.37105 19.0865 9.47105 19.0016 9.55493L12.9445 15.612C12.8211 15.7367 12.6738 15.8353 12.5115 15.9018C12.3491 15.9684 12.1751 16.0017 11.9996 15.9997C11.6491 16.0075 11.3096 15.877 11.0547 15.6362L4.99764 9.57916C4.8275 9.40881 4.73193 9.17788 4.73193 8.93711C4.73193 8.69634 4.8275 8.46542 4.99764 8.29506C5.08117 8.20935 5.18101 8.14122 5.29128 8.09471C5.40155 8.04819 5.52002 8.02423 5.6397 8.02423C5.75937 8.02423 5.87784 8.04819 5.98811 8.09471C6.09838 8.14122 6.19822 8.20935 6.28175 8.29506L11.9996 13.9887L17.7175 8.27084C17.801 8.18512 17.9009 8.11699 18.0111 8.07048C18.1214 8.02396 18.2399 8 18.3596 8C18.4792 8 18.5977 8.02396 18.708 8.07048C18.8182 8.11699 18.9181 8.18512 19.0016 8.27084C19.0871 8.3543 19.1548 8.45422 19.2006 8.56457C19.2464 8.67492 19.2694 8.79341 19.2681 8.91288Z"></path>
                                  </svg>
                                </span>
                              </span>
                            </div>
                            <label
                              for="cc1"
                              class="a53cbfa6de e6208ee469 f555271986"
                            >
                              <span>
                                <span class="ccb65902b2">City</span>
                              </span>
                            </label>
                            <div class="cabad3c686 aff44befa2">
                              <select
                                class="ebf4591c8e country-select-for-e2e-tests"
                                name="cc1"
                                id="cc1"
                                value={city}
                                onChange={(e) => setcity(e.target.value)}
                              >
                                <option value="">Select City</option>

                                {CityList?.map((city1) => (
                                  <option value={city1?.isoCode} data-key="in">
                                    {city1?.name}
                                  </option>
                                ))}
                              </select>
                              <span class="ad2c299542">
                                <span
                                  class="fcd9eec8fb cab1c196b5 c2cc050fb8 f64eb5d122"
                                  aria-hidden="true"
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 24 24"
                                  >
                                    <path d="M19.2681 8.91288C19.2687 9.03226 19.2454 9.15054 19.1996 9.26079C19.1539 9.37105 19.0865 9.47105 19.0016 9.55493L12.9445 15.612C12.8211 15.7367 12.6738 15.8353 12.5115 15.9018C12.3491 15.9684 12.1751 16.0017 11.9996 15.9997C11.6491 16.0075 11.3096 15.877 11.0547 15.6362L4.99764 9.57916C4.8275 9.40881 4.73193 9.17788 4.73193 8.93711C4.73193 8.69634 4.8275 8.46542 4.99764 8.29506C5.08117 8.20935 5.18101 8.14122 5.29128 8.09471C5.40155 8.04819 5.52002 8.02423 5.6397 8.02423C5.75937 8.02423 5.87784 8.04819 5.98811 8.09471C6.09838 8.14122 6.19822 8.20935 6.28175 8.29506L11.9996 13.9887L17.7175 8.27084C17.801 8.18512 17.9009 8.11699 18.0111 8.07048C18.1214 8.02396 18.2399 8 18.3596 8C18.4792 8 18.5977 8.02396 18.708 8.07048C18.8182 8.11699 18.9181 8.18512 19.0016 8.27084C19.0871 8.3543 19.1548 8.45422 19.2006 8.56457C19.2464 8.67492 19.2694 8.79341 19.2681 8.91288Z"></path>
                                  </svg>
                                </span>
                              </span>
                            </div>
                            <div class="a53cbfa6de ac9267e216 form-group manual-address-form__postcode a20293ec70">
                              <label
                                for=":r1t:"
                                class="a53cbfa6de e6208ee469 f555271986"
                              >
                                <span>
                                  <span class="ccb65902b2">Pin Code</span>
                                </span>
                              </label>
                              <div class="b9b84f4305">
                                <div class="e000754250">
                                  <input
                                    name="zipcode"
                                    type="number"
                                    onKeyDown={handleKeyDown}
                                    maxLength={6}
                                    class="eb46370fe1"
                                    value={pincode}
                                    onChange={(e) => setpincode(e.target.value)}
                                  />
                                  <div class="e7e5251f68"></div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </>
                      ) : activeStep === 3 ? (
                        <>
                          <div className="form-container">
                            <div
                              data-testid="SupMupEpContainer"
                              className="ffb9c3d6a3 wide-container"
                            >
                              <div className="aca0ade214 ebac6e22e9 f66f916626 c2931f4182 c27e5d305d db150fece4">
                                <div className="b817090550 b736e9e3f4">
                                  <div className="bcdcb105b3">
                                    Where can people sleep?
                                  </div>
                                  <div className="room-rows-container">
                                    <div className="aca0ade214 ebac6e22e9 f66f916626 c2931f4182 c27e5d305d db150fece4 count-input add-room room-row">
                                      <div className="b817090550 d4e36dbd77">
                                        <button
                                          type="button"
                                          id="automation_id_bedroom_edit_1"
                                          data-testid="bedroomitem_edit_0"
                                          className="edit-room bedroom-setup--list"
                                          onClick={HandleClickBedroom}
                                        >
                                          <span className="room-row-text">
                                            Bedroom
                                          </span>
                                          <small>
                                            {Singlebed
                                              ? Singlebed
                                              : selectedModal.Singlebed === 0
                                              ? ""
                                              : `${
                                                  Singlebed
                                                    ? Singlebed
                                                    : selectedModal.Singlebed
                                                } Single bed`}{" "}
                                            {Doublebed
                                              ? Doublebed
                                              : selectedModal.Doublebed === 0
                                              ? ""
                                              : `${
                                                  Doublebed
                                                    ? Doublebed
                                                    : selectedModal.Doublebed
                                                } Double bed`}{" "}
                                            {Largebed
                                              ? Largebed
                                              : selectedModal.Largebed === 0
                                              ? ""
                                              : `${
                                                  Largebed
                                                    ? Largebed
                                                    : selectedModal.Largebed
                                                } Large bed`}{" "}
                                            {XLargebed
                                              ? XLargebed
                                              : selectedModal.XLargebed === 0
                                              ? ""
                                              : `${
                                                  XLargebed
                                                    ? XLargebed
                                                    : selectedModal.XLargebed
                                                } Xtra-Large bed`}
                                            {"  "}
                                            {Roombeds.length
                                              ? Roombeds.length
                                              : selectedModal.Roombeds < 0
                                              ? ""
                                              : Roombeds
                                              ? Roombeds.map((data, index) => (
                                                  <>
                                                    {data.quantity} {data.name}
                                                  </>
                                                ))
                                              : selectedModal.Roombeds.map(
                                                  (data, index) => (
                                                    <>
                                                      {data.quantity}{" "}
                                                      {data.name}
                                                    </>
                                                  )
                                                )}
                                          </small>
                                        </button>
                                      </div>
                                      {Open ? (
                                        <div className="ffb9c3d6a3">
                                          <div className="aca0ade214 ebac6e22e9 f66f916626 c2931f4182 c27e5d305d db150fece4 spacing--6x">
                                            <div className="b817090550 b736e9e3f4">
                                              <div className="bcdcb105b3">
                                                Which beds are available in this
                                                room?
                                              </div>
                                            </div>
                                          </div>
                                          <div className="aca0ade214 ebac6e22e9 f66f916626 c2931f4182 c27e5d305d db150fece4 spacing--6x addRoom">
                                            <div className="roomDesc b817090550 e0ad3ea0c7">
                                              <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                viewBox="0 0 96 96"
                                              >
                                                <path d="M78.25 48h-60.5C16.1 48 15 49.1 15 50.77v30.46C15 82.9 16.1 84 17.75 84s2.75-1.1 2.75-2.77V70.15h55v11.08c0 1.66 1.1 2.77 2.75 2.77S81 82.9 81 81.23V50.77C81 49.1 79.9 48 78.25 48zm-22-27.7h-27.5v-5.53c0-1.66-1.1-2.77-2.75-2.77s-2.75 1.1-2.75 2.77v27.7h33V20.3z" />
                                                <path d="M72.75 23.08v-8.3c0-1.67-1.1-2.78-2.75-2.78s-2.75 1.1-2.75 2.77v5.54h-27.5v22.16h33V23.08z" />
                                              </svg>
                                              <div className="f2cf178bcd">
                                                <div className="a3332d346a e6208ee469 d0caee4251">
                                                  Single bed
                                                </div>
                                                <div className="abf093bdfe f45d8e4c32 df64fda51b">
                                                  90 - 130 cm wide
                                                </div>
                                              </div>
                                            </div>
                                            <div className="b817090550 a7cf1a6b1d">
                                              <div
                                                id="automation_id_add_bedroom_single_bed_input"
                                                data-testid="automation_id_add_bedroom_single_bed_input"
                                                className="a7a72174b8"
                                              >
                                                <div className="bfb38641b0">
                                                  <button
                                                    tabIndex={-1}
                                                    disabled={Singlebed < 1}
                                                    aria-hidden="true"
                                                    type="button"
                                                    className="a83ed08757 c21c56c305 f38b6daa18 d691166b09 ab98298258 deab83296e bb803d8689 e91c91fa93"
                                                    onClick={decrementSinglebed}
                                                  >
                                                    <span className="eedba9e88a">
                                                      <span
                                                        className="fcd9eec8fb bf9a32efa5"
                                                        aria-hidden="true"
                                                      >
                                                        <svg
                                                          xmlns="http://www.w3.org/2000/svg"
                                                          viewBox="0 0 24 24"
                                                        >
                                                          <path d="M20.25 12.75H3.75a.75.75 0 0 1 0-1.5h16.5a.75.75 0 0 1 0 1.5z" />
                                                        </svg>
                                                      </span>
                                                    </span>
                                                  </button>
                                                  <span
                                                    className="d723d73d5f"
                                                    aria-hidden="true"
                                                  >
                                                    {Singlebed
                                                      ? Singlebed
                                                      : selectedModal.Singlebed}
                                                  </span>
                                                  <button
                                                    tabIndex={-1}
                                                    aria-hidden="true"
                                                    type="button"
                                                    className="a83ed08757 c21c56c305 f38b6daa18 d691166b09 ab98298258 deab83296e bb803d8689 f4d78af12a"
                                                    onClick={
                                                      increaseRangeSinglebed
                                                    }
                                                  >
                                                    <span className="eedba9e88a">
                                                      <span
                                                        className="fcd9eec8fb bf9a32efa5"
                                                        aria-hidden="true"
                                                      >
                                                        <svg
                                                          xmlns="http://www.w3.org/2000/svg"
                                                          viewBox="0 0 24 24"
                                                        >
                                                          <path d="M20.25 11.25h-7.5v-7.5a.75.75 0 0 0-1.5 0v7.5h-7.5a.75.75 0 0 0 0 1.5h7.5v7.5a.75.75 0 0 0 1.5 0v-7.5h7.5a.75.75 0 0 0 0-1.5z" />
                                                        </svg>
                                                      </span>
                                                    </span>
                                                  </button>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          <div className="aca0ade214 ebac6e22e9 f66f916626 c2931f4182 c27e5d305d db150fece4 spacing--6x addRoom">
                                            <div className="roomDesc b817090550 e0ad3ea0c7">
                                              <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                viewBox="0 0 96 96"
                                              >
                                                <path d="M89.25 48H6.75C5.1 48 4 49.1 4 50.77v30.46C4 82.9 5.1 84 6.75 84s2.75-1.1 2.75-2.77V70.15h77v11.08c0 1.66 1.1 2.77 2.75 2.77S92 82.9 92 81.23V50.77C92 49.1 90.9 48 89.25 48zm-44-27.7h-27.5v-5.53c0-1.66-1.1-2.77-2.75-2.77s-2.75 1.1-2.75 2.77v27.7h33V20.3zm38.5 2.78v-8.3c0-1.67-1.1-2.78-2.75-2.78s-2.75 1.1-2.75 2.77v5.54h-27.5v22.16h33V23.08z" />
                                              </svg>
                                              <div className="f2cf178bcd">
                                                <div className="a3332d346a e6208ee469 d0caee4251">
                                                  Double bed
                                                </div>
                                                <div className="abf093bdfe f45d8e4c32 df64fda51b">
                                                  131 - 150 cm wide
                                                </div>
                                              </div>
                                            </div>
                                            <div className="b817090550 a7cf1a6b1d">
                                              <div
                                                id="automation_id_add_bedroom_double_bed_input"
                                                data-testid="automation_id_add_bedroom_double_bed_input"
                                                className="a7a72174b8"
                                              >
                                                <div className="bfb38641b0">
                                                  <button
                                                    tabIndex={-1}
                                                    disabled={Doublebed < 1}
                                                    aria-hidden="true"
                                                    type="button"
                                                    className="a83ed08757 c21c56c305 f38b6daa18 d691166b09 ab98298258 deab83296e bb803d8689 e91c91fa93"
                                                    onClick={decrementDoublebed}
                                                  >
                                                    <span className="eedba9e88a">
                                                      <span
                                                        className="fcd9eec8fb bf9a32efa5"
                                                        aria-hidden="true"
                                                      >
                                                        <svg
                                                          xmlns="http://www.w3.org/2000/svg"
                                                          viewBox="0 0 24 24"
                                                        >
                                                          <path d="M20.25 12.75H3.75a.75.75 0 0 1 0-1.5h16.5a.75.75 0 0 1 0 1.5z" />
                                                        </svg>
                                                      </span>
                                                    </span>
                                                  </button>
                                                  <span
                                                    className="d723d73d5f"
                                                    aria-hidden="true"
                                                  >
                                                    {Doublebed
                                                      ? Doublebed
                                                      : selectedModal.Doublebed}
                                                  </span>
                                                  <button
                                                    tabIndex={-1}
                                                    aria-hidden="true"
                                                    type="button"
                                                    className="a83ed08757 c21c56c305 f38b6daa18 d691166b09 ab98298258 deab83296e bb803d8689 f4d78af12a"
                                                    onClick={
                                                      increaseRangeDoublebed
                                                    }
                                                  >
                                                    <span className="eedba9e88a">
                                                      <span
                                                        className="fcd9eec8fb bf9a32efa5"
                                                        aria-hidden="true"
                                                      >
                                                        <svg
                                                          xmlns="http://www.w3.org/2000/svg"
                                                          viewBox="0 0 24 24"
                                                        >
                                                          <path d="M20.25 11.25h-7.5v-7.5a.75.75 0 0 0-1.5 0v7.5h-7.5a.75.75 0 0 0 0 1.5h7.5v7.5a.75.75 0 0 0 1.5 0v-7.5h7.5a.75.75 0 0 0 0-1.5z" />
                                                        </svg>
                                                      </span>
                                                    </span>
                                                  </button>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          <div className="aca0ade214 ebac6e22e9 f66f916626 c2931f4182 c27e5d305d db150fece4 spacing--6x addRoom">
                                            <div className="roomDesc b817090550 e0ad3ea0c7">
                                              <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                viewBox="0 0 96 96"
                                              >
                                                <path d="M89.25 48H6.75C5.1 48 4 49.1 4 50.77v30.46C4 82.9 5.1 84 6.75 84s2.75-1.1 2.75-2.77V70.15h77v11.08c0 1.66 1.1 2.77 2.75 2.77S92 82.9 92 81.23V50.77C92 49.1 90.9 48 89.25 48zm-44-27.7h-27.5v-5.53c0-1.66-1.1-2.77-2.75-2.77s-2.75 1.1-2.75 2.77v27.7h33V20.3zm38.5 2.78v-8.3c0-1.67-1.1-2.78-2.75-2.78s-2.75 1.1-2.75 2.77v5.54h-27.5v22.16h33V23.08z" />
                                              </svg>
                                              <div className="f2cf178bcd">
                                                <div className="a3332d346a e6208ee469 d0caee4251">
                                                  Large bed (King size)
                                                </div>
                                                <div className="abf093bdfe f45d8e4c32 df64fda51b">
                                                  151 - 180 cm wide
                                                </div>
                                              </div>
                                            </div>
                                            <div className="b817090550 a7cf1a6b1d">
                                              <div
                                                id="automation_id_add_bedroom_large_double_bed_input"
                                                data-testid="automation_id_add_bedroom_large_double_bed_input"
                                                className="a7a72174b8"
                                              >
                                                <div className="bfb38641b0">
                                                  <button
                                                    tabIndex={-1}
                                                    disabled={Largebed < 1}
                                                    aria-hidden="true"
                                                    type="button"
                                                    className="a83ed08757 c21c56c305 f38b6daa18 d691166b09 ab98298258 deab83296e bb803d8689 e91c91fa93"
                                                    onClick={decrementLargebed}
                                                  >
                                                    <span className="eedba9e88a">
                                                      <span
                                                        className="fcd9eec8fb bf9a32efa5"
                                                        aria-hidden="true"
                                                      >
                                                        <svg
                                                          xmlns="http://www.w3.org/2000/svg"
                                                          viewBox="0 0 24 24"
                                                        >
                                                          <path d="M20.25 12.75H3.75a.75.75 0 0 1 0-1.5h16.5a.75.75 0 0 1 0 1.5z" />
                                                        </svg>
                                                      </span>
                                                    </span>
                                                  </button>
                                                  <span
                                                    className="d723d73d5f"
                                                    aria-hidden="true"
                                                  >
                                                    {Largebed
                                                      ? Largebed
                                                      : selectedModal.Largebed}
                                                  </span>
                                                  <button
                                                    tabIndex={-1}
                                                    aria-hidden="true"
                                                    type="button"
                                                    className="a83ed08757 c21c56c305 f38b6daa18 d691166b09 ab98298258 deab83296e bb803d8689 f4d78af12a"
                                                    onClick={
                                                      increaseRangeLargebed
                                                    }
                                                  >
                                                    <span className="eedba9e88a">
                                                      <span
                                                        className="fcd9eec8fb bf9a32efa5"
                                                        aria-hidden="true"
                                                      >
                                                        <svg
                                                          xmlns="http://www.w3.org/2000/svg"
                                                          viewBox="0 0 24 24"
                                                        >
                                                          <path d="M20.25 11.25h-7.5v-7.5a.75.75 0 0 0-1.5 0v7.5h-7.5a.75.75 0 0 0 0 1.5h7.5v7.5a.75.75 0 0 0 1.5 0v-7.5h7.5a.75.75 0 0 0 0-1.5z" />
                                                        </svg>
                                                      </span>
                                                    </span>
                                                  </button>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          <div className="aca0ade214 ebac6e22e9 f66f916626 c2931f4182 c27e5d305d db150fece4 spacing--6x addRoom">
                                            <div className="roomDesc b817090550 e0ad3ea0c7">
                                              <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                viewBox="0 0 96 96"
                                              >
                                                <path d="M89.25 48H6.75C5.1 48 4 49.1 4 50.77v30.46C4 82.9 5.1 84 6.75 84s2.75-1.1 2.75-2.77V70.15h77v11.08c0 1.66 1.1 2.77 2.75 2.77S92 82.9 92 81.23V50.77C92 49.1 90.9 48 89.25 48zm-44-27.7h-27.5v-5.53c0-1.66-1.1-2.77-2.75-2.77s-2.75 1.1-2.75 2.77v27.7h33V20.3zm38.5 2.78v-8.3c0-1.67-1.1-2.78-2.75-2.78s-2.75 1.1-2.75 2.77v5.54h-27.5v22.16h33V23.08z" />
                                              </svg>
                                              <div className="f2cf178bcd">
                                                <div className="a3332d346a e6208ee469 d0caee4251">
                                                  Extra-large double bed
                                                  (Super-king size)
                                                </div>
                                                <div className="abf093bdfe f45d8e4c32 df64fda51b">
                                                  181 - 210 cm wide
                                                </div>
                                              </div>
                                            </div>
                                            <div className="b817090550 a7cf1a6b1d">
                                              <div
                                                id="automation_id_add_bedroom_extra_large_double_bed_input"
                                                data-testid="automation_id_add_bedroom_extra_large_double_bed_input"
                                                className="a7a72174b8"
                                              >
                                                <div className="bfb38641b0">
                                                  <button
                                                    tabIndex={-1}
                                                    disabled={XLargebed < 1}
                                                    aria-hidden="true"
                                                    type="button"
                                                    className="a83ed08757 c21c56c305 f38b6daa18 d691166b09 ab98298258 deab83296e bb803d8689 e91c91fa93"
                                                    onClick={decrementXLargebed}
                                                  >
                                                    <span className="eedba9e88a">
                                                      <span
                                                        className="fcd9eec8fb bf9a32efa5"
                                                        aria-hidden="true"
                                                      >
                                                        <svg
                                                          xmlns="http://www.w3.org/2000/svg"
                                                          viewBox="0 0 24 24"
                                                        >
                                                          <path d="M20.25 12.75H3.75a.75.75 0 0 1 0-1.5h16.5a.75.75 0 0 1 0 1.5z" />
                                                        </svg>
                                                      </span>
                                                    </span>
                                                  </button>
                                                  <span
                                                    className="d723d73d5f"
                                                    aria-hidden="true"
                                                  >
                                                    {XLargebed
                                                      ? XLargebed
                                                      : selectedModal.XLargebed}
                                                  </span>
                                                  <button
                                                    tabIndex={-1}
                                                    aria-hidden="true"
                                                    type="button"
                                                    className="a83ed08757 c21c56c305 f38b6daa18 d691166b09 ab98298258 deab83296e bb803d8689 f4d78af12a"
                                                    onClick={
                                                      increaseRangeXLargebed
                                                    }
                                                  >
                                                    <span className="eedba9e88a">
                                                      <span
                                                        className="fcd9eec8fb bf9a32efa5"
                                                        aria-hidden="true"
                                                      >
                                                        <svg
                                                          xmlns="http://www.w3.org/2000/svg"
                                                          viewBox="0 0 24 24"
                                                        >
                                                          <path d="M20.25 11.25h-7.5v-7.5a.75.75 0 0 0-1.5 0v7.5h-7.5a.75.75 0 0 0 0 1.5h7.5v7.5a.75.75 0 0 0 1.5 0v-7.5h7.5a.75.75 0 0 0 0-1.5z" />
                                                        </svg>
                                                      </span>
                                                    </span>
                                                  </button>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          {Roombeds
                                            ? Roombeds?.map((bed, index) => (
                                                <div
                                                  key={index}
                                                  className="aca0ade214 ebac6e22e9 f66f916626 c2931f4182 c27e5d305d db150fece4 spacing--6x addRoom"
                                                >
                                                  <div className="roomDesc b817090550 e0ad3ea0c7">
                                                    <div className="f2cf178bcd">
                                                      <div className="a3332d346a e6208ee469 d0caee4251 bed-type--not-highlighted">
                                                        <svg
                                                          xmlns="http://www.w3.org/2000/svg"
                                                          viewBox="0 0 96 96"
                                                        >
                                                          <path d="M89.25 48H6.75C5.1 48 4 49.1 4 50.77v30.46C4 82.9 5.1 84 6.75 84s2.75-1.1 2.75-2.77V70.15h77v11.08c0 1.66 1.1 2.77 2.75 2.77S92 82.9 92 81.23V50.77C92 49.1 90.9 48 89.25 48zm-44-27.7h-27.5v-5.53c0-1.66-1.1-2.77-2.75-2.77s-2.75 1.1-2.75 2.77v27.7h33V20.3zm38.5 2.78v-8.3c0-1.67-1.1-2.78-2.75-2.78s-2.75 1.1-2.75 2.77v5.54h-27.5v22.16h33V23.08z" />
                                                        </svg>
                                                        <input
                                                          type="text"
                                                          value={bed.name}
                                                          onChange={(e) =>
                                                            updateRoomBedName(
                                                              index,
                                                              e.target.value
                                                            )
                                                          }
                                                        />
                                                      </div>
                                                      <div className="abf093bdfe f45d8e4c32 df64fda51b">
                                                        {bed.width}
                                                      </div>
                                                    </div>
                                                  </div>
                                                  <div className="b817090550 a7cf1a6b1d">
                                                    <div className="a7a72174b8">
                                                      <div className="bfb38641b0">
                                                        <button
                                                          type="button"
                                                          disabled={
                                                            bed.quantity < 1
                                                          }
                                                          onClick={() =>
                                                            updateRoomBedQuantity(
                                                              index,
                                                              bed.quantity - 1
                                                            )
                                                          }
                                                          className="a83ed08757 c21c56c305 f38b6daa18 d691166b09 ab98298258 deab83296e e91c91fa93"
                                                        >
                                                          <span className="eedba9e88a">
                                                            <span
                                                              className="fcd9eec8fb bf9a32efa5"
                                                              aria-hidden="true"
                                                            >
                                                              <svg
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                viewBox="0 0 24 24"
                                                              >
                                                                <path d="M20.25 12.75H3.75a.75.75 0 0 1 0-1.5h16.5a.75.75 0 0 1 0 1.5z" />
                                                              </svg>
                                                            </span>
                                                          </span>
                                                        </button>
                                                        <span
                                                          className="d723d73d5f"
                                                          aria-hidden="true"
                                                        >
                                                          {bed.quantity}
                                                        </span>
                                                        <button
                                                          type="button"
                                                          onClick={() =>
                                                            updateRoomBedQuantity(
                                                              index,
                                                              bed.quantity + 1
                                                            )
                                                          }
                                                          className="a83ed08757 c21c56c305 f38b6daa18 d691166b09 ab98298258 deab83296e bb803d8689 f4d78af12a"
                                                        >
                                                          <span className="eedba9e88a">
                                                            <span
                                                              className="fcd9eec8fb bf9a32efa5"
                                                              aria-hidden="true"
                                                            >
                                                              <svg
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                viewBox="0 0 24 24"
                                                              >
                                                                <path d="M20.25 11.25h-7.5v-7.5a.75.75 0 0 0-1.5 0v7.5h-7.5a.75.75 0 0 0 0 1.5h7.5v7.5a.75.75 0 0 0 1.5 0v-7.5h7.5a.75.75 0 0 0 0-1.5z" />
                                                              </svg>
                                                            </span>
                                                          </span>
                                                        </button>
                                                      </div>
                                                    </div>
                                                  </div>
                                                  <button
                                                    style={{
                                                      marginTop: "10px",
                                                      padding: "5px 5px",
                                                      fontSize: "16px",
                                                      backgroundColor: "red",
                                                      color: "white",
                                                      border: "none",
                                                      borderRadius: "5px",
                                                      cursor: "pointer",
                                                      transition:
                                                        "background-color 0.3s",
                                                    }}
                                                    type="button"
                                                    onClick={() =>
                                                      removeRoomBed(index)
                                                    }
                                                  >
                                                    Remove Room Bed
                                                  </button>
                                                </div>
                                              ))
                                            : selectedModal.Roombeds.map(
                                                (bed, index) => (
                                                  <div
                                                    key={index}
                                                    className="aca0ade214 ebac6e22e9 f66f916626 c2931f4182 c27e5d305d db150fece4 spacing--6x addRoom"
                                                  >
                                                    <div className="roomDesc b817090550 e0ad3ea0c7">
                                                      <div className="f2cf178bcd">
                                                        <div className="a3332d346a e6208ee469 d0caee4251 bed-type--not-highlighted">
                                                          <svg
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            viewBox="0 0 96 96"
                                                          >
                                                            <path d="M89.25 48H6.75C5.1 48 4 49.1 4 50.77v30.46C4 82.9 5.1 84 6.75 84s2.75-1.1 2.75-2.77V70.15h77v11.08c0 1.66 1.1 2.77 2.75 2.77S92 82.9 92 81.23V50.77C92 49.1 90.9 48 89.25 48zm-44-27.7h-27.5v-5.53c0-1.66-1.1-2.77-2.75-2.77s-2.75 1.1-2.75 2.77v27.7h33V20.3zm38.5 2.78v-8.3c0-1.67-1.1-2.78-2.75-2.78s-2.75 1.1-2.75 2.77v5.54h-27.5v22.16h33V23.08z" />
                                                          </svg>
                                                          <input
                                                            type="text"
                                                            value={bed.name}
                                                            onChange={(e) =>
                                                              updateRoomBedName(
                                                                index,
                                                                e.target.value
                                                              )
                                                            }
                                                          />
                                                        </div>
                                                        <div className="abf093bdfe f45d8e4c32 df64fda51b">
                                                          {bed.width}
                                                        </div>
                                                      </div>
                                                    </div>
                                                    <div className="b817090550 a7cf1a6b1d">
                                                      <div className="a7a72174b8">
                                                        <div className="bfb38641b0">
                                                          <button
                                                            type="button"
                                                            disabled={
                                                              bed.quantity < 1
                                                            }
                                                            onClick={() =>
                                                              updateRoomBedQuantity(
                                                                index,
                                                                bed.quantity - 1
                                                              )
                                                            }
                                                            className="a83ed08757 c21c56c305 f38b6daa18 d691166b09 ab98298258 deab83296e e91c91fa93"
                                                          >
                                                            <span className="eedba9e88a">
                                                              <span
                                                                className="fcd9eec8fb bf9a32efa5"
                                                                aria-hidden="true"
                                                              >
                                                                <svg
                                                                  xmlns="http://www.w3.org/2000/svg"
                                                                  viewBox="0 0 24 24"
                                                                >
                                                                  <path d="M20.25 12.75H3.75a.75.75 0 0 1 0-1.5h16.5a.75.75 0 0 1 0 1.5z" />
                                                                </svg>
                                                              </span>
                                                            </span>
                                                          </button>
                                                          <span
                                                            className="d723d73d5f"
                                                            aria-hidden="true"
                                                          >
                                                            {bed.quantity}
                                                          </span>
                                                          <button
                                                            type="button"
                                                            onClick={() =>
                                                              updateRoomBedQuantity(
                                                                index,
                                                                bed.quantity + 1
                                                              )
                                                            }
                                                            className="a83ed08757 c21c56c305 f38b6daa18 d691166b09 ab98298258 deab83296e bb803d8689 f4d78af12a"
                                                          >
                                                            <span className="eedba9e88a">
                                                              <span
                                                                className="fcd9eec8fb bf9a32efa5"
                                                                aria-hidden="true"
                                                              >
                                                                <svg
                                                                  xmlns="http://www.w3.org/2000/svg"
                                                                  viewBox="0 0 24 24"
                                                                >
                                                                  <path d="M20.25 11.25h-7.5v-7.5a.75.75 0 0 0-1.5 0v7.5h-7.5a.75.75 0 0 0 0 1.5h7.5v7.5a.75.75 0 0 0 1.5 0v-7.5h7.5a.75.75 0 0 0 0-1.5z" />
                                                                </svg>
                                                              </span>
                                                            </span>
                                                          </button>
                                                        </div>
                                                      </div>
                                                    </div>
                                                    <button
                                                      style={{
                                                        marginTop: "10px",
                                                        padding: "5px 5px",
                                                        fontSize: "16px",
                                                        backgroundColor: "red",
                                                        color: "white",
                                                        border: "none",
                                                        borderRadius: "5px",
                                                        cursor: "pointer",
                                                        transition:
                                                          "background-color 0.3s",
                                                      }}
                                                      type="button"
                                                      onClick={() =>
                                                        removeRoomBed(index)
                                                      }
                                                    >
                                                      Remove Room Bed
                                                    </button>
                                                  </div>
                                                )
                                              )}
                                          <button
                                            style={{
                                              marginTop: "10px",
                                              padding: "10px 20px",
                                              fontSize: "16px",
                                              backgroundColor: "#70bbe9",
                                              color: "white",
                                              border: "none",
                                              borderRadius: "5px",
                                              cursor: "pointer",
                                              transition:
                                                "background-color 0.3s",
                                            }}
                                            onClick={addRoomBed}
                                          >
                                            Add Bed
                                          </button>
                                        </div>
                                      ) : (
                                        ""
                                      )}
                                    </div>
                                    <div className="aca0ade214 ebac6e22e9 f66f916626 c2931f4182 c27e5d305d db150fece4 count-input add-room room-row">
                                      <div className="b817090550 d4e36dbd77">
                                        <button
                                          type="button"
                                          id="automation_id_bedroom_edit_2"
                                          data-testid="bedroomitem_edit_1"
                                          className="edit-room bedroom-setup--list"
                                          onClick={HandleClickLivingRoom}
                                        >
                                          <span className="room-row-text">
                                            Living room
                                          </span>
                                          <small className="room-row-beds-count">
                                            {Sofabed
                                              ? Sofabed
                                              : selectedModal.Sofabed === 0
                                              ? "0 Sofa bed"
                                              : `${
                                                  Sofabed
                                                    ? Sofabed
                                                    : selectedModal.Sofabed
                                                } Sofa bed`}

                                            {"  "}
                                            {LRoombeds.length
                                              ? LRoombeds.length
                                              : selectedModal.LRoombeds < 0
                                              ? ""
                                              : LRoombeds
                                              ? LRoombeds.map((data, index) => (
                                                  <>
                                                    {data.quantity} {data.name}
                                                  </>
                                                ))
                                              : selectedModal.LRoombeds.map(
                                                  (data, index) => (
                                                    <>
                                                      {data.quantity}{" "}
                                                      {data.name}
                                                    </>
                                                  )
                                                )}
                                          </small>
                                        </button>
                                      </div>
                                    </div>
                                    {Open1 ? (
                                      <div className="ffb9c3d6a3">
                                        <div className="aca0ade214 ebac6e22e9 f66f916626 c2931f4182 c27e5d305d db150fece4 spacing--6x">
                                          <div className="b817090550 b736e9e3f4">
                                            <div className="bcdcb105b3">
                                              Living Room
                                            </div>
                                          </div>
                                        </div>
                                        <div className="aca0ade214 ebac6e22e9 f66f916626 c2931f4182 c27e5d305d db150fece4 addRoom">
                                          <div className="roomDesc b817090550 e0ad3ea0c7">
                                            <svg
                                              xmlns="http://www.w3.org/2000/svg"
                                              viewBox="0 0 96 96"
                                            >
                                              <path d="M78.25 45.75h-5.5c-1.65 0-2.75 1.1-2.75 2.75v8.25H26V48.5c0-1.65-1.1-2.75-2.75-2.75h-5.5c-1.65 0-2.75 1.1-2.75 2.75v19.25c0 1.65 1.1 2.75 2.75 2.75h2.75v2.75c0 1.65 1.1 2.75 2.75 2.75S26 74.9 26 73.25V70.5h44v2.75C70 74.9 71.1 76 72.75 76s2.75-1.1 2.75-2.75V70.5h2.75c1.65 0 2.75-1.1 2.75-2.75V48.5c0-1.65-1.1-2.75-2.75-2.75z" />
                                              <path d="M45.25 51.25V21h-22c-1.65 0-2.75 1.1-2.75 2.75v16.5h8.25c1.65 0 2.75 1.1 2.75 2.75v8.25h13.75zm30.25-27.5c0-1.65-1.1-2.75-2.75-2.75h-22v30.25H64.5V43c0-1.65 1.1-2.75 2.75-2.75h8.25v-16.5z" />
                                            </svg>
                                            Sofa bed
                                          </div>
                                          <div className="b817090550 a7cf1a6b1d">
                                            <div
                                              id="automation_id_add_bedroom_sofa_bed_input"
                                              className="a7a72174b8 input-stepper"
                                            >
                                              <div className="bfb38641b0">
                                                <button
                                                  tabIndex={-1}
                                                  disabled={Sofabed < 1}
                                                  aria-hidden="true"
                                                  type="button"
                                                  className="a83ed08757 c21c56c305 f38b6daa18 d691166b09 ab98298258 deab83296e bb803d8689 e91c91fa93"
                                                  onClick={decrementSofabed}
                                                >
                                                  <span className="eedba9e88a">
                                                    <span
                                                      className="fcd9eec8fb bf9a32efa5"
                                                      aria-hidden="true"
                                                    >
                                                      <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        viewBox="0 0 24 24"
                                                      >
                                                        <path d="M20.25 12.75H3.75a.75.75 0 0 1 0-1.5h16.5a.75.75 0 0 1 0 1.5z" />
                                                      </svg>
                                                    </span>
                                                  </span>
                                                </button>
                                                <span
                                                  className="d723d73d5f"
                                                  aria-hidden="true"
                                                >
                                                  {Sofabed
                                                    ? Sofabed
                                                    : selectedModal.Sofabed}
                                                </span>
                                                <button
                                                  tabIndex={-1}
                                                  aria-hidden="true"
                                                  type="button"
                                                  className="a83ed08757 c21c56c305 f38b6daa18 d691166b09 ab98298258 deab83296e bb803d8689 f4d78af12a"
                                                  onClick={increaseRangeSofabed}
                                                >
                                                  <span className="eedba9e88a">
                                                    <span
                                                      className="fcd9eec8fb bf9a32efa5"
                                                      aria-hidden="true"
                                                    >
                                                      <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        viewBox="0 0 24 24"
                                                      >
                                                        <path d="M20.25 11.25h-7.5v-7.5a.75.75 0 0 0-1.5 0v7.5h-7.5a.75.75 0 0 0 0 1.5h7.5v7.5a.75.75 0 0 0 1.5 0v-7.5h7.5a.75.75 0 0 0 0-1.5z" />
                                                      </svg>
                                                    </span>
                                                  </span>
                                                </button>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        {LRoombeds
                                          ? LRoombeds?.map((bed, index) => (
                                              <div
                                                key={index}
                                                className="aca0ade214 ebac6e22e9 f66f916626 c2931f4182 c27e5d305d db150fece4 spacing--6x addRoom"
                                              >
                                                <div className="roomDesc b817090550 e0ad3ea0c7">
                                                  <div className="f2cf178bcd">
                                                    <div className="a3332d346a e6208ee469 d0caee4251 bed-type--not-highlighted">
                                                      <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        viewBox="0 0 96 96"
                                                      >
                                                        <path d="M89.25 48H6.75C5.1 48 4 49.1 4 50.77v30.46C4 82.9 5.1 84 6.75 84s2.75-1.1 2.75-2.77V70.15h77v11.08c0 1.66 1.1 2.77 2.75 2.77S92 82.9 92 81.23V50.77C92 49.1 90.9 48 89.25 48zm-44-27.7h-27.5v-5.53c0-1.66-1.1-2.77-2.75-2.77s-2.75 1.1-2.75 2.77v27.7h33V20.3zm38.5 2.78v-8.3c0-1.67-1.1-2.78-2.75-2.78s-2.75 1.1-2.75 2.77v5.54h-27.5v22.16h33V23.08z" />
                                                      </svg>
                                                      <input
                                                        type="text"
                                                        value={bed.name}
                                                        onChange={(e) =>
                                                          updateLRoomBedName(
                                                            index,
                                                            e.target.value
                                                          )
                                                        }
                                                      />
                                                    </div>
                                                    <div className="abf093bdfe f45d8e4c32 df64fda51b">
                                                      {bed.width}
                                                    </div>
                                                  </div>
                                                </div>
                                                <div className="b817090550 a7cf1a6b1d">
                                                  <div className="a7a72174b8">
                                                    <div className="bfb38641b0">
                                                      <button
                                                        type="button"
                                                        disabled={
                                                          bed.quantity < 1
                                                        }
                                                        onClick={() =>
                                                          updateLRoomBedQuantity(
                                                            index,
                                                            bed.quantity - 1
                                                          )
                                                        }
                                                        className="a83ed08757 c21c56c305 f38b6daa18 d691166b09 ab98298258 deab83296e e91c91fa93"
                                                      >
                                                        <span className="eedba9e88a">
                                                          <span
                                                            className="fcd9eec8fb bf9a32efa5"
                                                            aria-hidden="true"
                                                          >
                                                            <svg
                                                              xmlns="http://www.w3.org/2000/svg"
                                                              viewBox="0 0 24 24"
                                                            >
                                                              <path d="M20.25 12.75H3.75a.75.75 0 0 1 0-1.5h16.5a.75.75 0 0 1 0 1.5z" />
                                                            </svg>
                                                          </span>
                                                        </span>
                                                      </button>
                                                      <span
                                                        className="d723d73d5f"
                                                        aria-hidden="true"
                                                      >
                                                        {bed.quantity}
                                                      </span>
                                                      <button
                                                        type="button"
                                                        onClick={() =>
                                                          updateLRoomBedQuantity(
                                                            index,
                                                            bed.quantity + 1
                                                          )
                                                        }
                                                        className="a83ed08757 c21c56c305 f38b6daa18 d691166b09 ab98298258 deab83296e bb803d8689 f4d78af12a"
                                                      >
                                                        <span className="eedba9e88a">
                                                          <span
                                                            className="fcd9eec8fb bf9a32efa5"
                                                            aria-hidden="true"
                                                          >
                                                            <svg
                                                              xmlns="http://www.w3.org/2000/svg"
                                                              viewBox="0 0 24 24"
                                                            >
                                                              <path d="M20.25 11.25h-7.5v-7.5a.75.75 0 0 0-1.5 0v7.5h-7.5a.75.75 0 0 0 0 1.5h7.5v7.5a.75.75 0 0 0 1.5 0v-7.5h7.5a.75.75 0 0 0 0-1.5z" />
                                                            </svg>
                                                          </span>
                                                        </span>
                                                      </button>
                                                    </div>
                                                  </div>
                                                </div>
                                                <button
                                                  style={{
                                                    marginTop: "10px",
                                                    padding: "5px 5px",
                                                    fontSize: "16px",
                                                    backgroundColor: "red",
                                                    color: "white",
                                                    border: "none",
                                                    borderRadius: "5px",
                                                    cursor: "pointer",
                                                    transition:
                                                      "background-color 0.3s",
                                                  }}
                                                  type="button"
                                                  onClick={() =>
                                                    removeLRoomBed(index)
                                                  }
                                                >
                                                  Remove Living Room Bed
                                                </button>
                                              </div>
                                            ))
                                          : selected.LRoombeds?.map(
                                              (bed, index) => (
                                                <div
                                                  key={index}
                                                  className="aca0ade214 ebac6e22e9 f66f916626 c2931f4182 c27e5d305d db150fece4 spacing--6x addRoom"
                                                >
                                                  <div className="roomDesc b817090550 e0ad3ea0c7">
                                                    <div className="f2cf178bcd">
                                                      <div className="a3332d346a e6208ee469 d0caee4251 bed-type--not-highlighted">
                                                        <svg
                                                          xmlns="http://www.w3.org/2000/svg"
                                                          viewBox="0 0 96 96"
                                                        >
                                                          <path d="M89.25 48H6.75C5.1 48 4 49.1 4 50.77v30.46C4 82.9 5.1 84 6.75 84s2.75-1.1 2.75-2.77V70.15h77v11.08c0 1.66 1.1 2.77 2.75 2.77S92 82.9 92 81.23V50.77C92 49.1 90.9 48 89.25 48zm-44-27.7h-27.5v-5.53c0-1.66-1.1-2.77-2.75-2.77s-2.75 1.1-2.75 2.77v27.7h33V20.3zm38.5 2.78v-8.3c0-1.67-1.1-2.78-2.75-2.78s-2.75 1.1-2.75 2.77v5.54h-27.5v22.16h33V23.08z" />
                                                        </svg>
                                                        <input
                                                          type="text"
                                                          value={bed.name}
                                                          onChange={(e) =>
                                                            updateLRoomBedName(
                                                              index,
                                                              e.target.value
                                                            )
                                                          }
                                                        />
                                                      </div>
                                                      <div className="abf093bdfe f45d8e4c32 df64fda51b">
                                                        {bed.width}
                                                      </div>
                                                    </div>
                                                  </div>
                                                  <div className="b817090550 a7cf1a6b1d">
                                                    <div className="a7a72174b8">
                                                      <div className="bfb38641b0">
                                                        <button
                                                          type="button"
                                                          disabled={
                                                            bed.quantity < 1
                                                          }
                                                          onClick={() =>
                                                            updateLRoomBedQuantity(
                                                              index,
                                                              bed.quantity - 1
                                                            )
                                                          }
                                                          className="a83ed08757 c21c56c305 f38b6daa18 d691166b09 ab98298258 deab83296e e91c91fa93"
                                                        >
                                                          <span className="eedba9e88a">
                                                            <span
                                                              className="fcd9eec8fb bf9a32efa5"
                                                              aria-hidden="true"
                                                            >
                                                              <svg
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                viewBox="0 0 24 24"
                                                              >
                                                                <path d="M20.25 12.75H3.75a.75.75 0 0 1 0-1.5h16.5a.75.75 0 0 1 0 1.5z" />
                                                              </svg>
                                                            </span>
                                                          </span>
                                                        </button>
                                                        <span
                                                          className="d723d73d5f"
                                                          aria-hidden="true"
                                                        >
                                                          {bed.quantity}
                                                        </span>
                                                        <button
                                                          type="button"
                                                          onClick={() =>
                                                            updateLRoomBedQuantity(
                                                              index,
                                                              bed.quantity + 1
                                                            )
                                                          }
                                                          className="a83ed08757 c21c56c305 f38b6daa18 d691166b09 ab98298258 deab83296e bb803d8689 f4d78af12a"
                                                        >
                                                          <span className="eedba9e88a">
                                                            <span
                                                              className="fcd9eec8fb bf9a32efa5"
                                                              aria-hidden="true"
                                                            >
                                                              <svg
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                viewBox="0 0 24 24"
                                                              >
                                                                <path d="M20.25 11.25h-7.5v-7.5a.75.75 0 0 0-1.5 0v7.5h-7.5a.75.75 0 0 0 0 1.5h7.5v7.5a.75.75 0 0 0 1.5 0v-7.5h7.5a.75.75 0 0 0 0-1.5z" />
                                                              </svg>
                                                            </span>
                                                          </span>
                                                        </button>
                                                      </div>
                                                    </div>
                                                  </div>
                                                  <button
                                                    style={{
                                                      marginTop: "10px",
                                                      padding: "5px 5px",
                                                      fontSize: "16px",
                                                      backgroundColor: "red",
                                                      color: "white",
                                                      border: "none",
                                                      borderRadius: "5px",
                                                      cursor: "pointer",
                                                      transition:
                                                        "background-color 0.3s",
                                                    }}
                                                    type="button"
                                                    onClick={() =>
                                                      removeLRoomBed(index)
                                                    }
                                                  >
                                                    Remove Living Room Bed
                                                  </button>
                                                </div>
                                              )
                                            )}
                                        <button
                                          style={{
                                            marginTop: "10px",
                                            padding: "10px 20px",
                                            fontSize: "16px",
                                            backgroundColor: "#70bbe9",
                                            color: "white",
                                            border: "none",
                                            borderRadius: "5px",
                                            cursor: "pointer",
                                            transition: "background-color 0.3s",
                                          }}
                                          onClick={addLRoomBed}
                                        >
                                          Add Living Room Bed
                                        </button>
                                      </div>
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div
                              data-testid="SupMupEpContainer"
                              className="ffb9c3d6a3 wide-container"
                            >
                              <div className="form-group b817090550 b736e9e3f4">
                                <label
                                  className="spacing--2x bedroom-label"
                                  htmlFor="automation_id_bedroom_num_guests_input"
                                >
                                  <div className="bcdcb105b3">
                                    How many guests can stay?
                                  </div>
                                </label>
                                <div
                                  data-testid="automation_id_bedroom_num_guests_input_stepper"
                                  className="a7a72174b8 input-stepper"
                                >
                                  <div className="bfb38641b0">
                                    <button
                                      tabIndex={-1}
                                      disabled={Stay < 1}
                                      aria-hidden="true"
                                      type="button"
                                      className="a83ed08757 c21c56c305 f38b6daa18 d691166b09 ab98298258 deab83296e bb803d8689 e91c91fa93"
                                      onClick={decrementStay}
                                    >
                                      <span className="eedba9e88a">
                                        <span
                                          className="fcd9eec8fb bf9a32efa5"
                                          aria-hidden="true"
                                        >
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 24 24"
                                          >
                                            <path d="M20.25 12.75H3.75a.75.75 0 0 1 0-1.5h16.5a.75.75 0 0 1 0 1.5z" />
                                          </svg>
                                        </span>
                                      </span>
                                    </button>
                                    <span
                                      className="d723d73d5f"
                                      aria-hidden="true"
                                    >
                                      {Stay
                                        ? Stay
                                        : selectedModal?.QuestStayAlternative}
                                    </span>
                                    <button
                                      tabIndex={-1}
                                      aria-hidden="true"
                                      type="button"
                                      className="a83ed08757 c21c56c305 f38b6daa18 d691166b09 ab98298258 deab83296e bb803d8689 f4d78af12a"
                                      onClick={increaseRangeStay}
                                    >
                                      <span className="eedba9e88a">
                                        <span
                                          className="fcd9eec8fb bf9a32efa5"
                                          aria-hidden="true"
                                        >
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 24 24"
                                          >
                                            <path d="M20.25 11.25h-7.5v-7.5a.75.75 0 0 0-1.5 0v7.5h-7.5a.75.75 0 0 0 0 1.5h7.5v7.5a.75.75 0 0 0 1.5 0v-7.5h7.5a.75.75 0 0 0 0-1.5z" />
                                          </svg>
                                        </span>
                                      </span>
                                    </button>
                                  </div>
                                </div>

                                <div className="form-group b817090550 b736e9e3f4">
                                  <label
                                    className="spacing--2x bedroom-label"
                                    htmlFor="automation_id_bedroom_num_guests_bathrooms_input"
                                  >
                                    <div className="bcdcb105b3">
                                      How many bathrooms are there?
                                    </div>
                                  </label>
                                  <div
                                    data-testid="automation_id_bedroom_num_bathrooms_input_stepper"
                                    className="a7a72174b8 input-stepper"
                                  >
                                    <div className="bfb38641b0">
                                      <button
                                        tabIndex={-1}
                                        disabled={Bathroom < 1}
                                        aria-hidden="true"
                                        type="button"
                                        className="a83ed08757 c21c56c305 f38b6daa18 d691166b09 ab98298258 deab83296e bb803d8689 e91c91fa93"
                                        onClick={decrementBathroom}
                                      >
                                        <span className="eedba9e88a">
                                          <span
                                            className="fcd9eec8fb bf9a32efa5"
                                            aria-hidden="true"
                                          >
                                            <svg
                                              xmlns="http://www.w3.org/2000/svg"
                                              viewBox="0 0 24 24"
                                            >
                                              <path d="M20.25 12.75H3.75a.75.75 0 0 1 0-1.5h16.5a.75.75 0 0 1 0 1.5z" />
                                            </svg>
                                          </span>
                                        </span>
                                      </button>
                                      <span
                                        className="d723d73d5f"
                                        aria-hidden="true"
                                      >
                                        {Bathroom
                                          ? Bathroom
                                          : selectedModal.BathroomsAlternativeStay}
                                      </span>
                                      <button
                                        tabIndex={-1}
                                        aria-hidden="true"
                                        type="button"
                                        className="a83ed08757 c21c56c305 f38b6daa18 d691166b09 ab98298258 deab83296e bb803d8689 f4d78af12a"
                                        onClick={increaseRangeBathroom}
                                      >
                                        <span className="eedba9e88a">
                                          <span
                                            className="fcd9eec8fb bf9a32efa5"
                                            aria-hidden="true"
                                          >
                                            <svg
                                              xmlns="http://www.w3.org/2000/svg"
                                              viewBox="0 0 24 24"
                                            >
                                              <path d="M20.25 11.25h-7.5v-7.5a.75.75 0 0 0-1.5 0v7.5h-7.5a.75.75 0 0 0 0 1.5h7.5v7.5a.75.75 0 0 0 1.5 0v-7.5h7.5a.75.75 0 0 0 0-1.5z" />
                                            </svg>
                                          </span>
                                        </span>
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="ffb9c3d6a3 wide-container second-container">
                              <div className="bcdcb105b3 spacing--4x">
                                How big is this Alternative location?
                              </div>
                              <div className="bedroom-room-size">
                                <div className="a53cbfa6de ac9267e216 bedroom-room-size__room-measurement a20293ec70">
                                  <label
                                    htmlFor=":r1g:"
                                    className="a53cbfa6de e6208ee469 f555271986"
                                  >
                                    <span>
                                      <span className="ccb65902b2">
                                        Alternative location size - optional
                                      </span>
                                    </span>
                                  </label>
                                  <div className="b9b84f4305">
                                    <div className="e000754250">
                                      <input
                                        type="number"
                                        onKeyDown={handleKeyDown}
                                        name="room_size"
                                        className="eb46370fe1"
                                        value={
                                          ApartmentSize
                                            ? ApartmentSize
                                            : selectedModal.AlternativeStaySize
                                        }
                                        onChange={(e) =>
                                          setApartmentSize(e.target.value)
                                        }
                                      />
                                      <div className="e7e5251f68" />
                                    </div>
                                  </div>
                                </div>
                                <div className="a53cbfa6de ac9267e216 bedroom-room-size__measurement-type">
                                  <div className="cabad3c686 aff44befa2">
                                    <select
                                      className="ebf4591c8e"
                                      name="measurement_type"
                                      onChange={(e) =>
                                        setApartmentType(e.target.value)
                                      }
                                      value={
                                        ApartmentType
                                          ? ApartmentType
                                          : selectedModal.AlternativeStayMeasure
                                      }
                                    >
                                      <option value="" disabled>
                                        Unit
                                      </option>
                                      <option value="square metres">
                                        square metres
                                      </option>
                                      <option value="square feet">
                                        square feet
                                      </option>
                                    </select>
                                    <span className="ad2c299542">
                                      <span
                                        className="fcd9eec8fb cab1c196b5 c2cc050fb8 f64eb5d122"
                                        aria-hidden="true"
                                      >
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          viewBox="0 0 24 24"
                                        >
                                          <path d="M19.2681 8.91288C19.2687 9.03226 19.2454 9.15054 19.1996 9.26079C19.1539 9.37105 19.0865 9.47105 19.0016 9.55493L12.9445 15.612C12.8211 15.7367 12.6738 15.8353 12.5115 15.9018C12.3491 15.9684 12.1751 16.0017 11.9996 15.9997C11.6491 16.0075 11.3096 15.877 11.0547 15.6362L4.99764 9.57916C4.8275 9.40881 4.73193 9.17788 4.73193 8.93711C4.73193 8.69634 4.8275 8.46542 4.99764 8.29506C5.08117 8.20935 5.18101 8.14122 5.29128 8.09471C5.40155 8.04819 5.52002 8.02423 5.6397 8.02423C5.75937 8.02423 5.87784 8.04819 5.98811 8.09471C6.09838 8.14122 6.19822 8.20935 6.28175 8.29506L11.9996 13.9887L17.7175 8.27084C17.801 8.18512 17.9009 8.11699 18.0111 8.07048C18.1214 8.02396 18.2399 8 18.3596 8C18.4792 8 18.5977 8.02396 18.708 8.07048C18.8182 8.11699 18.9181 8.18512 19.0016 8.27084C19.0871 8.3543 19.1548 8.45422 19.2006 8.56457C19.2464 8.67492 19.2694 8.79341 19.2681 8.91288Z" />
                                        </svg>
                                      </span>
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </>
                      ) : activeStep === 4 ? (
                        <>
                          {" "}
                          <div className="form-container">
                            <div className="f2cf178bcd screen-main-header d991e1216a">
                              <div className="f6431b446c e6208ee469 d0caee4251 screen-main-header__title">
                                What can guests use at your Alternatice
                                location?
                              </div>
                            </div>
                            <div className="ffb9c3d6a3 wide-container min-height automation_class_facilities_list">
                              <div className="aca0ade214 ebac6e22e9 f66f916626 c2931f4182 c27e5d305d db150fece4">
                                <div className="b817090550 b736e9e3f4">
                                  <div className="aca0ade214 aaf30230d9 cd2e7d62b0">
                                    <div className="a53cbfa6de ac9267e216 d8eb520c4e">
                                      {Object.keys(checkboxesUse)?.map(
                                        (option) => (
                                          <div key={option}>
                                            <label
                                              htmlFor=":rf:"
                                              className="aca0ade214 aaf30230d9 c2931f4182 d79e71457a bd597ff2d8"
                                            >
                                              <input
                                                type="checkbox"
                                                id={`checkbox${option}`}
                                                className="ef785aa7f4"
                                                checked={checkboxesUse[option]}
                                                onChange={() =>
                                                  handleCheckboxChangeUse(
                                                    option
                                                  )
                                                }
                                              />
                                              <div className="a53cbfa6de e6208ee469">
                                                {" "}
                                                {option}
                                              </div>
                                            </label>{" "}
                                          </div>
                                        )
                                      )}
                                    </div>
                                    <input
                                      type="text"
                                      placeholder="Enter Guest Use"
                                      value={newCheckboxLabelUse}
                                      class="eb46370fe1"
                                      onChange={handleInputChangeUse}
                                    />
                                    <button
                                      onClick={handleAddCheckboxUse}
                                      style={{
                                        marginTop: "10px",
                                        padding: "10px 20px",
                                        fontSize: "16px",
                                        backgroundColor: "#70bbe9",
                                        color: "white",
                                        border: "none",
                                        borderRadius: "5px",
                                        cursor: "pointer",
                                        transition: "background-color 0.3s",
                                      }}
                                    >
                                      Add Guest Use
                                    </button>
                                  </div>
                                </div>
                              </div>
                              <div className="spacing--8x" />
                            </div>
                          </div>
                        </>
                      ) : activeStep === 5 ? (
                        <>
                          <div className="form-container">
                            <div className="f2cf178bcd screen-main-header d991e1216a">
                              <div className="f6431b446c e6208ee469 d0caee4251 screen-main-header__title">
                                Services at your property
                              </div>
                            </div>
                            <div className="ffb9c3d6a3 wide-container spacing--8x">
                              <div className="f6431b446c spacing--6x">
                                Breakfast
                              </div>
                              <hr className="b9bfeba2b4 b288f61df6 spacing--6x" />
                              <fieldset className="a53cbfa6de ac9267e216 breakfast-offered-question">
                                <legend className="a53cbfa6de e6208ee469 f555271986">
                                  <span>
                                    <span className="ccb65902b2">
                                      <div className="e1eebb6a1e spacing--4x">
                                        Do you serve guests breakfast?
                                      </div>
                                    </span>
                                  </span>
                                </legend>

                                <div className="aca0ade214 aaf30230d9 cd2e7d62b0">
                                  <div
                                    id="automation_id_breakfast_is_offered_yes"
                                    className="a53cbfa6de ac9267e216 ad8af0911d"
                                  >
                                    <label
                                      htmlFor=":r2c:"
                                      className="aca0ade214 aaf30230d9 c2931f4182 d79e71457a f8208e9f83"
                                    >
                                      <span className="ada5be5921" />

                                      <input
                                        type="radio"
                                        value="Yes"
                                        className="c907c67d20"
                                        checked={
                                          selectedOptionBreakfast === "Yes"
                                        }
                                        onChange={handleOptionChangeBreakfast}
                                      />

                                      <div className="a53cbfa6de e6208ee469">
                                        Yes
                                      </div>
                                    </label>
                                  </div>
                                  <div className="a53cbfa6de ac9267e216 ad8af0911d">
                                    <label
                                      htmlFor=":r2d:"
                                      className="aca0ade214 aaf30230d9 c2931f4182 d79e71457a f8208e9f83"
                                    >
                                      <span className="ada5be5921" />

                                      <input
                                        type="radio"
                                        value="No"
                                        className="c907c67d20"
                                        checked={
                                          selectedOptionBreakfast === "No"
                                        }
                                        onChange={handleOptionChangeBreakfast}
                                      />

                                      <div className="a53cbfa6de e6208ee469">
                                        No
                                      </div>
                                    </label>
                                  </div>
                                </div>
                              </fieldset>
                              <div className="spacing--8x" />
                              {selectedOptionBreakfast === "Yes" ? (
                                <>
                                  {" "}
                                  <div className="e1eebb6a1e spacing--4x question-title">
                                    Is breakfast included in the price guests
                                    pay?
                                  </div>
                                  <fieldset className="a53cbfa6de ac9267e216">
                                    <div className="aca0ade214 aaf30230d9 cd2e7d62b0">
                                      <div className="a53cbfa6de ac9267e216 ad8af0911d">
                                        {/* <label
                              htmlFor=":r12:"
                              className="aca0ade214 aaf30230d9 c2931f4182 d79e71457a f8208e9f83"
                            >
                              <span className="ada5be5921" />
                              <span className="c907c67d20" />
                              <span className="aaee4e7cd3 e7a57abb1e">
                                <div className="a53cbfa6de e6208ee469">
                                  Yes, it's included
                                </div>
                              </span>
                            </label> */}
                                        <div
                                          id="automation_id_parking_free"
                                          className="a53cbfa6de ac9267e216 ad8af0911d"
                                        >
                                          <label
                                            htmlFor=":r2f:"
                                            className="aca0ade214 aaf30230d9 c2931f4182 d79e71457a f8208e9f83"
                                          >
                                            <span className="ada5be5921" />

                                            <input
                                              type="radio"
                                              value="Yes, it's included"
                                              className="c907c67d20"
                                              checked={
                                                selectedOptionPrice ===
                                                "Yes, it's included"
                                              }
                                              onChange={handleOptionChangePrice}
                                            />
                                            <div className="a53cbfa6de e6208ee469">
                                              Yes, it's included
                                            </div>
                                          </label>
                                        </div>
                                      </div>
                                      <div
                                        id="automation_id_breakfast_is_included_in_price_no"
                                        className="a53cbfa6de ac9267e216 ad8af0911d"
                                      >
                                        <label
                                          htmlFor=":r2f:"
                                          className="aca0ade214 aaf30230d9 c2931f4182 d79e71457a f8208e9f83"
                                        >
                                          <span className="ada5be5921" />

                                          <input
                                            type="radio"
                                            value="No, it costs extra"
                                            className="c907c67d20"
                                            checked={
                                              selectedOptionPrice ===
                                              "No, it costs extra"
                                            }
                                            onChange={handleOptionChangePrice}
                                          />
                                          <div className="a53cbfa6de e6208ee469">
                                            No, it costs extra
                                          </div>
                                        </label>
                                      </div>
                                    </div>
                                  </fieldset>
                                  <div className="spacing--6x" />
                                  {selectedOptionPrice ===
                                  "No, it costs extra" ? (
                                    <>
                                      {" "}
                                      <div
                                        id="automation_id_breakfast_price_input"
                                        className="a53cbfa6de ac9267e216 a20293ec70"
                                      >
                                        <label
                                          htmlFor=":r17:"
                                          className="a53cbfa6de e6208ee469 f555271986"
                                        >
                                          <span>
                                            <span className="ccb65902b2">
                                              Breakfast price per person, per
                                              day
                                            </span>
                                          </span>
                                        </label>
                                        <div className="b9b84f4305">
                                          <div className="b93ef00bf5">INR</div>
                                          <div className="e000754250">
                                            <input
                                              type="number"
                                              onKeyDown={handleKeyDown}
                                              name="price"
                                              className="eb46370fe1"
                                              value={BreakFastPrice}
                                              onChange={(e) =>
                                                setBreakFastPrice(
                                                  e.target.value
                                                )
                                              }
                                            />
                                            <div className="e7e5251f68" />
                                          </div>
                                        </div>
                                      </div>
                                      <span className="bui-title__subtitle">
                                        Including all taxes and fees
                                      </span>
                                    </>
                                  ) : (
                                    ""
                                  )}
                                  <hr className="b9bfeba2b4 b288f61df6" />
                                  <div className="spacing--8x" />
                                  <div className="f2cf178bcd">
                                    <div className="e1eebb6a1e e6208ee469 d0caee4251">
                                      What type of breakfast do you offer?
                                    </div>
                                    <div className="a53cbfa6de f45d8e4c32 df64fda51b">
                                      Select all that apply
                                    </div>
                                  </div>
                                  <div className="spacing--8x" />
                                  <div className="aca0ade214 aaf30230d9 c2931f4182">
                                    <label className="faefc93c6f">
                                      <input
                                        data-testid="breakfast_type_12"
                                        className={
                                          checkedItemsBreakfast.Nonveg
                                            ? "dfb08330381"
                                            : "ac4a7896c7 bdfe71a871"
                                        }
                                        name="Nonveg"
                                        type="checkbox"
                                        checked={
                                          checkedItemsBreakfast.Nonveg || false
                                        }
                                        onChange={handleCheckboxChangeBreakfast}
                                      />
                                      <span className="dfb0833038">
                                        <span className="ccb65902b2">
                                          Non veg
                                        </span>
                                      </span>
                                    </label>
                                    <label className="faefc93c6f">
                                      <input
                                        data-testid="breakfast_type_10"
                                        className={
                                          checkedItemsBreakfast.American
                                            ? "dfb08330381"
                                            : "ac4a7896c7 bdfe71a871"
                                        }
                                        name="American"
                                        type="checkbox"
                                        checked={
                                          checkedItemsBreakfast.American ||
                                          false
                                        }
                                        onChange={handleCheckboxChangeBreakfast}
                                      />
                                      <span className="dfb0833038">
                                        <span className="ccb65902b2">
                                          American
                                        </span>
                                      </span>
                                    </label>
                                    <label className="faefc93c6f">
                                      <input
                                        data-testid="breakfast_type_9"
                                        className={
                                          checkedItemsBreakfast.SouthIndian
                                            ? "dfb08330381"
                                            : "ac4a7896c7 bdfe71a871"
                                        }
                                        name="SouthIndian"
                                        type="checkbox"
                                        checked={
                                          checkedItemsBreakfast.SouthIndian ||
                                          false
                                        }
                                        onChange={handleCheckboxChangeBreakfast}
                                      />
                                      <span className="dfb0833038">
                                        <span className="ccb65902b2">
                                          South Indian
                                        </span>
                                      </span>
                                    </label>
                                    <label className="faefc93c6f">
                                      <input
                                        data-testid="breakfast_type_13"
                                        className={
                                          checkedItemsBreakfast.Breakfasttogo
                                            ? "dfb08330381"
                                            : "ac4a7896c7 bdfe71a871"
                                        }
                                        name="Breakfasttogo"
                                        type="checkbox"
                                        checked={
                                          checkedItemsBreakfast.Breakfasttogo ||
                                          false
                                        }
                                        onChange={handleCheckboxChangeBreakfast}
                                      />
                                      <span className="dfb0833038">
                                        <span className="ccb65902b2">
                                          Breakfast to go
                                        </span>
                                      </span>
                                    </label>
                                    <label className="faefc93c6f">
                                      <input
                                        data-testid="breakfast_type_11"
                                        className={
                                          checkedItemsBreakfast.Buffet
                                            ? "dfb08330381"
                                            : "ac4a7896c7 bdfe71a871"
                                        }
                                        name="Buffet"
                                        type="checkbox"
                                        checked={
                                          checkedItemsBreakfast.Buffet || false
                                        }
                                        onChange={handleCheckboxChangeBreakfast}
                                      />
                                      <span className="dfb0833038">
                                        <span className="ccb65902b2">
                                          Buffet
                                        </span>
                                      </span>
                                    </label>
                                    <label className="faefc93c6f">
                                      <input
                                        data-testid="breakfast_type_1"
                                        className={
                                          checkedItemsBreakfast.Continental
                                            ? "dfb08330381"
                                            : "ac4a7896c7 bdfe71a871"
                                        }
                                        name="Continental"
                                        type="checkbox"
                                        checked={
                                          checkedItemsBreakfast.Continental ||
                                          false
                                        }
                                        onChange={handleCheckboxChangeBreakfast}
                                      />
                                      <span className="dfb0833038">
                                        <span className="ccb65902b2">
                                          Continental
                                        </span>
                                      </span>
                                    </label>
                                    <label className="faefc93c6f">
                                      <input
                                        data-testid="breakfast_type_3"
                                        className={
                                          checkedItemsBreakfast.FullEnglishIrish
                                            ? "dfb08330381"
                                            : "ac4a7896c7 bdfe71a871"
                                        }
                                        name="FullEnglishIrish"
                                        type="checkbox"
                                        checked={
                                          checkedItemsBreakfast.FullEnglishIrish ||
                                          false
                                        }
                                        onChange={handleCheckboxChangeBreakfast}
                                      />
                                      <span className="dfb0833038">
                                        <span className="ccb65902b2">
                                          Full English/Irish
                                        </span>
                                      </span>
                                    </label>
                                    <label className="faefc93c6f">
                                      <input
                                        data-testid="breakfast_type_7"
                                        className={
                                          checkedItemsBreakfast.NorthIndian
                                            ? "dfb08330381"
                                            : "ac4a7896c7 bdfe71a871"
                                        }
                                        name="NorthIndian"
                                        type="checkbox"
                                        checked={
                                          checkedItemsBreakfast.NorthIndian ||
                                          false
                                        }
                                        onChange={handleCheckboxChangeBreakfast}
                                      />
                                      <span className="dfb0833038">
                                        <span className="ccb65902b2">
                                          North Indian
                                        </span>
                                      </span>
                                    </label>
                                    <label className="faefc93c6f">
                                      <input
                                        data-testid="breakfast_type_6"
                                        className={
                                          checkedItemsBreakfast.Halal
                                            ? "dfb08330381"
                                            : "ac4a7896c7 bdfe71a871"
                                        }
                                        name="Halal"
                                        type="checkbox"
                                        checked={
                                          checkedItemsBreakfast.Halal || false
                                        }
                                        onChange={handleCheckboxChangeBreakfast}
                                      />
                                      <span className="dfb0833038">
                                        <span className="ccb65902b2">
                                          Halal
                                        </span>
                                      </span>
                                    </label>
                                    <label className="faefc93c6f">
                                      <input
                                        data-testid="breakfast_type_2"
                                        className={
                                          checkedItemsBreakfast.Italian
                                            ? "dfb08330381"
                                            : "ac4a7896c7 bdfe71a871"
                                        }
                                        name="Italian"
                                        type="checkbox"
                                        checked={
                                          checkedItemsBreakfast.Italian || false
                                        }
                                        onChange={handleCheckboxChangeBreakfast}
                                      />
                                      <span className="dfb0833038">
                                        <span className="ccb65902b2">
                                          Italian
                                        </span>
                                      </span>
                                    </label>

                                    <label className="faefc93c6f">
                                      <input
                                        data-testid="breakfast_type_4"
                                        className={
                                          checkedItemsBreakfast.Vegetarian
                                            ? "dfb08330381"
                                            : "ac4a7896c7 bdfe71a871"
                                        }
                                        name="Vegetarian"
                                        type="checkbox"
                                        checked={
                                          checkedItemsBreakfast.Vegetarian ||
                                          false
                                        }
                                        onChange={handleCheckboxChangeBreakfast}
                                      />
                                      <span className="dfb0833038">
                                        <span className="ccb65902b2">
                                          Vegetarian
                                        </span>
                                      </span>
                                    </label>
                                  </div>{" "}
                                </>
                              ) : (
                                ""
                              )}
                            </div>
                          </div>
                        </>
                      ) : activeStep === 6 ? (
                        <>
                          <div className="form-container">
                            <div className="f2cf178bcd screen-main-header d991e1216a">
                              <div className="f6431b446c e6208ee469 d0caee4251 screen-main-header__title">
                                What languages do you or your staff speak?
                              </div>
                            </div>
                            <div className="ffb9c3d6a3 wide-container languages__container--fixedHeight">
                              <div className="form-group">
                                <div data-testid="Languages-content">
                                  <div className="f2cf178bcd spacing--4x">
                                    <div className="a3332d346a e6208ee469 d0caee4251">
                                      Select languages
                                    </div>
                                  </div>
                                  <fieldset
                                    data-testid="InputCheckboxGroup-recommended"
                                    className="a53cbfa6de ac9267e216 spacing--6x"
                                  >
                                    <div className="aca0ade214 aaf30230d9 cd2e7d62b0">
                                      <div className="a53cbfa6de ac9267e216 d8eb520c4e">
                                        {Object.keys(checkboxesLanguage)?.map(
                                          (option) => (
                                            <div key={option}>
                                              <label
                                                htmlFor=":rf:"
                                                className="aca0ade214 aaf30230d9 c2931f4182 d79e71457a bd597ff2d8"
                                              >
                                                <input
                                                  type="checkbox"
                                                  id={`checkbox${option}`}
                                                  className="ef785aa7f4"
                                                  checked={
                                                    checkboxesLanguage[option]
                                                  }
                                                  onChange={() =>
                                                    handleCheckboxChangeLanguage(
                                                      option
                                                    )
                                                  }
                                                />
                                                <div className="a53cbfa6de e6208ee469">
                                                  {" "}
                                                  {option}
                                                </div>
                                              </label>{" "}
                                            </div>
                                          )
                                        )}
                                      </div>
                                      <input
                                        type="text"
                                        placeholder="Enter language"
                                        value={newCheckboxLabelLanguage}
                                        class="eb46370fe1"
                                        onChange={handleInputChangeLanguage}
                                      />
                                      <button
                                        onClick={handleAddCheckboxLanguage}
                                        style={{
                                          marginTop: "10px",
                                          padding: "10px 20px",
                                          fontSize: "16px",
                                          backgroundColor: "#70bbe9",
                                          color: "white",
                                          border: "none",
                                          borderRadius: "5px",
                                          cursor: "pointer",
                                          transition: "background-color 0.3s",
                                        }}
                                      >
                                        Add Language
                                      </button>
                                    </div>
                                  </fieldset>
                                </div>
                              </div>
                            </div>
                          </div>
                        </>
                      ) : activeStep === 7 ? (
                        <>
                          <div className="form-container">
                            <div className="f2cf178bcd screen-main-header d991e1216a">
                              <div className="f6431b446c e6208ee469 d0caee4251 screen-main-header__title">
                                House rules
                              </div>
                            </div>
                            <div className="ffb9c3d6a31 wide-container min-height">
                              <div className="aca0ade214 ebac6e22e9 f66f916626 c2931f4182 c27e5d305d db150fece4 spacing--6x">
                                <div className="b817090550 d4e36dbd77">
                                  <label htmlFor="automation_checkbox_id_smoking_allowed">
                                    <div className="a53cbfa6de">
                                      Smoking in shared areas allowed
                                    </div>
                                  </label>
                                </div>
                                <Switch
                                  onChange={handleToggleSmoking}
                                  checked={isCheckedSmoking}
                                />
                              </div>
                              <div className="aca0ade214 ebac6e22e9 f66f916626 c2931f4182 c27e5d305d db150fece4 spacing--6x">
                                <div className="b817090550 d4e36dbd77">
                                  <label htmlFor="automation_checkbox_id_pets_allowed">
                                    <div className="a53cbfa6de">
                                      Pets allowed
                                    </div>
                                  </label>
                                </div>
                                <Switch
                                  onChange={handleTogglePets}
                                  checked={isCheckedPets}
                                />
                              </div>
                              <div className="aca0ade214 ebac6e22e9 f66f916626 c2931f4182 c27e5d305d db150fece4 spacing--6x">
                                <div className="b817090550 d4e36dbd77">
                                  <label htmlFor="automation_checkbox_id_pets_allowed">
                                    <div className="a53cbfa6de">
                                      Children allowed
                                    </div>
                                  </label>
                                </div>
                                <Switch
                                  onChange={handleToggleChildren}
                                  checked={isCheckedChildren}
                                />
                              </div>
                              <div className="aca0ade214 ebac6e22e9 f66f916626 c2931f4182 c27e5d305d db150fece4 spacing--6x">
                                <div className="b817090550 d4e36dbd77">
                                  <label htmlFor="automation_checkbox_id_parties_allowed">
                                    <div className="a53cbfa6de">
                                      Parties/events allowed
                                    </div>
                                  </label>
                                </div>
                                <Switch
                                  onChange={handleToggleParties}
                                  checked={isCheckedParties}
                                />
                              </div>
                              <hr className="b9bfeba2b4 b288f61df6 spacing--6x" />
                              <div className="a3332d346a">Check in</div>
                              <div className="aca0ade214 ebac6e22e9 f66f916626 c2931f4182 c27e5d305d db150fece4 spacing--6x">
                                <div className="b817090550 b268b61489">
                                  <div className="a53cbfa6de ac9267e216">
                                    <label
                                      htmlFor=":r32:"
                                      className="a53cbfa6de e6208ee469 f555271986"
                                    >
                                      From
                                    </label>
                                    <div className="cabad3c686 aff44befa2">
                                      <select
                                        value={selectedTime}
                                        className="ebf4591c8e"
                                        onChange={handleTimeChange}
                                      >
                                        <option value="">Select Time</option>
                                        {timeOptions?.map((time, index) => (
                                          <option key={index} value={time}>
                                            {time}
                                          </option>
                                        ))}
                                      </select>
                                      <span className="ad2c299542">
                                        <span
                                          className="fcd9eec8fb cab1c196b5 c2cc050fb8 f64eb5d122"
                                          aria-hidden="true"
                                        >
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 24 24"
                                          >
                                            <path d="M19.2681 8.91288C19.2687 9.03226 19.2454 9.15054 19.1996 9.26079C19.1539 9.37105 19.0865 9.47105 19.0016 9.55493L12.9445 15.612C12.8211 15.7367 12.6738 15.8353 12.5115 15.9018C12.3491 15.9684 12.1751 16.0017 11.9996 15.9997C11.6491 16.0075 11.3096 15.877 11.0547 15.6362L4.99764 9.57916C4.8275 9.40881 4.73193 9.17788 4.73193 8.93711C4.73193 8.69634 4.8275 8.46542 4.99764 8.29506C5.08117 8.20935 5.18101 8.14122 5.29128 8.09471C5.40155 8.04819 5.52002 8.02423 5.6397 8.02423C5.75937 8.02423 5.87784 8.04819 5.98811 8.09471C6.09838 8.14122 6.19822 8.20935 6.28175 8.29506L11.9996 13.9887L17.7175 8.27084C17.801 8.18512 17.9009 8.11699 18.0111 8.07048C18.1214 8.02396 18.2399 8 18.3596 8C18.4792 8 18.5977 8.02396 18.708 8.07048C18.8182 8.11699 18.9181 8.18512 19.0016 8.27084C19.0871 8.3543 19.1548 8.45422 19.2006 8.56457C19.2464 8.67492 19.2694 8.79341 19.2681 8.91288Z" />
                                          </svg>
                                        </span>
                                      </span>
                                    </div>
                                  </div>
                                </div>
                                <div className="b817090550 b268b61489">
                                  <div className="a53cbfa6de ac9267e216">
                                    <label
                                      htmlFor=":r33:"
                                      className="a53cbfa6de e6208ee469 f555271986"
                                    >
                                      <span>
                                        <span className="ccb65902b2">
                                          Until
                                        </span>
                                      </span>
                                    </label>
                                    <div className="cabad3c686 aff44befa2">
                                      <select
                                        value={selectedTime1}
                                        className="ebf4591c8e"
                                        onChange={handleTimeChange1}
                                      >
                                        <option value="">Select Time</option>
                                        {timeOptions?.map((time, index) => (
                                          <option key={index} value={time}>
                                            {time}
                                          </option>
                                        ))}
                                      </select>
                                      <span className="ad2c299542">
                                        <span
                                          className="fcd9eec8fb cab1c196b5 c2cc050fb8 f64eb5d122"
                                          aria-hidden="true"
                                        >
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 24 24"
                                          >
                                            <path d="M19.2681 8.91288C19.2687 9.03226 19.2454 9.15054 19.1996 9.26079C19.1539 9.37105 19.0865 9.47105 19.0016 9.55493L12.9445 15.612C12.8211 15.7367 12.6738 15.8353 12.5115 15.9018C12.3491 15.9684 12.1751 16.0017 11.9996 15.9997C11.6491 16.0075 11.3096 15.877 11.0547 15.6362L4.99764 9.57916C4.8275 9.40881 4.73193 9.17788 4.73193 8.93711C4.73193 8.69634 4.8275 8.46542 4.99764 8.29506C5.08117 8.20935 5.18101 8.14122 5.29128 8.09471C5.40155 8.04819 5.52002 8.02423 5.6397 8.02423C5.75937 8.02423 5.87784 8.04819 5.98811 8.09471C6.09838 8.14122 6.19822 8.20935 6.28175 8.29506L11.9996 13.9887L17.7175 8.27084C17.801 8.18512 17.9009 8.11699 18.0111 8.07048C18.1214 8.02396 18.2399 8 18.3596 8C18.4792 8 18.5977 8.02396 18.708 8.07048C18.8182 8.11699 18.9181 8.18512 19.0016 8.27084C19.0871 8.3543 19.1548 8.45422 19.2006 8.56457C19.2464 8.67492 19.2694 8.79341 19.2681 8.91288Z" />
                                          </svg>
                                        </span>
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="a3332d346a">Check out</div>
                              <div className="aca0ade214 ebac6e22e9 f66f916626 c2931f4182 c27e5d305d db150fece4 spacing--6x">
                                <div className="b817090550 b268b61489">
                                  <div className="a53cbfa6de ac9267e216">
                                    <label
                                      htmlFor=":r34:"
                                      className="a53cbfa6de e6208ee469 f555271986"
                                    >
                                      <span>
                                        <span className="ccb65902b2">From</span>
                                      </span>
                                    </label>
                                    <div className="cabad3c686 aff44befa2">
                                      <select
                                        value={selectedTime2}
                                        className="ebf4591c8e"
                                        onChange={handleTimeChange2}
                                      >
                                        <option value="">Select Time</option>
                                        {timeOptions?.map((time, index) => (
                                          <option key={index} value={time}>
                                            {time}
                                          </option>
                                        ))}
                                      </select>
                                      <span className="ad2c299542">
                                        <span
                                          className="fcd9eec8fb cab1c196b5 c2cc050fb8 f64eb5d122"
                                          aria-hidden="true"
                                        >
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 24 24"
                                          >
                                            <path d="M19.2681 8.91288C19.2687 9.03226 19.2454 9.15054 19.1996 9.26079C19.1539 9.37105 19.0865 9.47105 19.0016 9.55493L12.9445 15.612C12.8211 15.7367 12.6738 15.8353 12.5115 15.9018C12.3491 15.9684 12.1751 16.0017 11.9996 15.9997C11.6491 16.0075 11.3096 15.877 11.0547 15.6362L4.99764 9.57916C4.8275 9.40881 4.73193 9.17788 4.73193 8.93711C4.73193 8.69634 4.8275 8.46542 4.99764 8.29506C5.08117 8.20935 5.18101 8.14122 5.29128 8.09471C5.40155 8.04819 5.52002 8.02423 5.6397 8.02423C5.75937 8.02423 5.87784 8.04819 5.98811 8.09471C6.09838 8.14122 6.19822 8.20935 6.28175 8.29506L11.9996 13.9887L17.7175 8.27084C17.801 8.18512 17.9009 8.11699 18.0111 8.07048C18.1214 8.02396 18.2399 8 18.3596 8C18.4792 8 18.5977 8.02396 18.708 8.07048C18.8182 8.11699 18.9181 8.18512 19.0016 8.27084C19.0871 8.3543 19.1548 8.45422 19.2006 8.56457C19.2464 8.67492 19.2694 8.79341 19.2681 8.91288Z" />
                                          </svg>
                                        </span>
                                      </span>
                                    </div>
                                  </div>
                                </div>
                                <div className="b817090550 b268b61489">
                                  <div className="a53cbfa6de ac9267e216">
                                    <label
                                      htmlFor=":r35:"
                                      className="a53cbfa6de e6208ee469 f555271986"
                                    >
                                      <span>
                                        <span className="ccb65902b2">
                                          Until
                                        </span>
                                      </span>
                                    </label>
                                    <div className="cabad3c686 aff44befa2">
                                      <select
                                        value={selectedTime3}
                                        className="ebf4591c8e"
                                        onChange={handleTimeChange3}
                                      >
                                        <option value="">Select Time</option>
                                        {timeOptions?.map((time, index) => (
                                          <option key={index} value={time}>
                                            {time}
                                          </option>
                                        ))}
                                      </select>
                                      <span className="ad2c299542">
                                        <span
                                          className="fcd9eec8fb cab1c196b5 c2cc050fb8 f64eb5d122"
                                          aria-hidden="true"
                                        >
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 24 24"
                                          >
                                            <path d="M19.2681 8.91288C19.2687 9.03226 19.2454 9.15054 19.1996 9.26079C19.1539 9.37105 19.0865 9.47105 19.0016 9.55493L12.9445 15.612C12.8211 15.7367 12.6738 15.8353 12.5115 15.9018C12.3491 15.9684 12.1751 16.0017 11.9996 15.9997C11.6491 16.0075 11.3096 15.877 11.0547 15.6362L4.99764 9.57916C4.8275 9.40881 4.73193 9.17788 4.73193 8.93711C4.73193 8.69634 4.8275 8.46542 4.99764 8.29506C5.08117 8.20935 5.18101 8.14122 5.29128 8.09471C5.40155 8.04819 5.52002 8.02423 5.6397 8.02423C5.75937 8.02423 5.87784 8.04819 5.98811 8.09471C6.09838 8.14122 6.19822 8.20935 6.28175 8.29506L11.9996 13.9887L17.7175 8.27084C17.801 8.18512 17.9009 8.11699 18.0111 8.07048C18.1214 8.02396 18.2399 8 18.3596 8C18.4792 8 18.5977 8.02396 18.708 8.07048C18.8182 8.11699 18.9181 8.18512 19.0016 8.27084C19.0871 8.3543 19.1548 8.45422 19.2006 8.56457C19.2464 8.67492 19.2694 8.79341 19.2681 8.91288Z" />
                                          </svg>
                                        </span>
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </>
                      ) : activeStep === 8 ? (
                        <>
                          <div className="form-container">
                            <div className="f2cf178bcd screen-main-header d991e1216a">
                              <div className="f6431b446c e6208ee469 d0caee4251 screen-main-header__title">
                                What does your place look like?
                              </div>
                            </div>
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "space-between",
                              }}
                            >
                              <div className="upload-photos_container">
                                <div className="drag-and-drop__highlight">
                                  <div className="ffb9c3d6a33 wide-container min-height ota-container">
                                    <div
                                      className="spacing--4x"
                                      data-testid="NumberOfPhotosRequiredMessage-container"
                                    >
                                      <label
                                        htmlFor="photoFileInput"
                                        className="add-photo-sub-info font_body_2"
                                      >
                                        <div className="a3332d346a">
                                          Upload at least 5 photos of your
                                          property.{" "}
                                        </div>{" "}
                                        <div className="a53cbfa6de">
                                          The more you upload, the more likely
                                          you are to get bookings. You can add
                                          more later.{" "}
                                        </div>
                                      </label>
                                    </div>
                                    <div className="take-photo take-photo--with-drag-and-drop">
                                      <div className="drag-and-drop">
                                        <div
                                          style={{
                                            border: "2px dashed #cccccc",
                                            borderRadius: "4px",
                                            padding: "20px",
                                            textAlign: "center",
                                            display: "flex",
                                            flexDirection: "row",
                                            cursor: "pointer",
                                          }}
                                        >
                                          <input
                                            type="file"
                                            multiple
                                            onChange={(e) =>
                                              handleFileChange(e)
                                            }
                                          />
                                          <p>
                                            Click to select one or more images
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="take-photo take-photo--with-drag-and-drop">
                                      <div className="drag-and-drop">
                                        <div
                                          style={{
                                            textAlign: "center",
                                            marginTop: "10px",
                                          }}
                                        >
                                          <div
                                            style={{
                                              overflowY: "scroll",
                                              display: "flex",
                                              flexDirection: "row",
                                              flexWrap: "wrap",
                                              margin: "20px",
                                              // justifyContent: "space-between",
                                            }}
                                          >
                                            {selectedModal.Photos?.map(
                                              (media) => (
                                                <div
                                                  key={media._id}
                                                  style={{
                                                    display: "flex",
                                                    flexDirection: "row",
                                                    justifyContent:
                                                      "space-between",
                                                    flexWrap: "wrap",
                                                  }}
                                                  className={`image-item ${
                                                    selectedImages.includes(
                                                      media._id
                                                    )
                                                      ? "selected"
                                                      : ""
                                                  }`}
                                                >
                                                  {media.file.endsWith(
                                                    ".jpg"
                                                  ) ||
                                                  media.file.endsWith(
                                                    ".webp"
                                                  ) ||
                                                  media.file.endsWith(
                                                    ".jpeg"
                                                  ) ||
                                                  media.file.endsWith(
                                                    ".png"
                                                  ) ? (
                                                    <img
                                                      src={
                                                        "https://bookingoda.com/image/" +
                                                        media.file
                                                      }
                                                      alt="image"
                                                      style={{
                                                        width: "80px",
                                                        height: "80px",
                                                        marginRight: "20px",
                                                        marginBottom: "20px",
                                                      }}
                                                      onClick={() =>
                                                        toggleImageSelection(
                                                          media._id
                                                        )
                                                      }
                                                    />
                                                  ) : null}

                                                  {/* <MdDelete
                  onClick={() => removeApartment(FullView._id, media._id)}
                  style={{ color: "red" }}
                /> */}
                                                </div>
                                              )
                                            )}
                                          </div>
                                          {selectedImages.length > 0 ? (
                                            <button
                                              onClick={deleteSelectedImages}
                                              style={{
                                                padding: "10px 20px",
                                                backgroundColor: "red",
                                                color: "white",
                                                border: "none",
                                                borderRadius: "5px",
                                                cursor: "pointer",
                                                fontSize: "16px",
                                                fontWeight: "bold",
                                              }}
                                            >
                                              Delete {selectedImages.length}{" "}
                                              Selected Image
                                              {selectedImages.length !== 1
                                                ? "s"
                                                : ""}
                                            </button>
                                          ) : (
                                            ""
                                          )}
                                          {uploadedImage &&
                                            uploadedImage.map((item, index) => (
                                              <div
                                                key={index}
                                                style={{
                                                  display: "inline-block",
                                                  marginRight: "10px",
                                                  marginTop: "10px",
                                                }}
                                              >
                                                <img
                                                  src={URL.createObjectURL(
                                                    item
                                                  )}
                                                  alt="Uploaded"
                                                  style={{ maxWidth: "50px" }}
                                                />

                                                <Tooltip title="delete">
                                                  <MdDelete
                                                    color="red"
                                                    onClick={() =>
                                                      deleteImage(index)
                                                    }
                                                  />
                                                </Tooltip>
                                              </div>
                                            ))}
                                        </div>
                                      </div>
                                    </div>
                                    <div>
                                      <div className="spacing--4x" />
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="upload-photos_container">
                                <div className="drag-and-drop__highlight">
                                  <div className="ffb9c3d6a33 wide-container min-height ota-container">
                                    <div
                                      className="spacing--4x"
                                      data-testid="NumberOfPhotosRequiredMessage-container"
                                    >
                                      <label
                                        htmlFor="photoFileInput"
                                        className="add-photo-sub-info font_body_2"
                                      >
                                        <div className="a3332d346a">
                                          Upload videos of your property.{" "}
                                        </div>{" "}
                                      </label>
                                    </div>
                                    <div className="take-photo take-photo--with-drag-and-drop">
                                      <div className="drag-and-drop">
                                        <div
                                          style={{
                                            border: "2px dashed #cccccc",
                                            borderRadius: "4px",
                                            padding: "20px",
                                            textAlign: "center",
                                            display: "flex",
                                            flexDirection: "row",
                                            cursor: "pointer",
                                          }}
                                        >
                                          <input
                                            type="file"
                                            accept="video/*"
                                            multiple
                                            onChange={handleVideoChange}
                                          />
                                          <p>Click to select video</p>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="take-photo take-photo--with-drag-and-drop">
                                      <div className="drag-and-drop">
                                        <div
                                          style={{
                                            textAlign: "center",
                                            marginTop: "10px",
                                          }}
                                        >
                                          {selectedModal.Photos?.map(
                                            (media) => (
                                              <div
                                                key={media._id}
                                                style={{
                                                  display: "flex",
                                                  flexDirection: "row",
                                                  justifyContent:
                                                    "space-between",
                                                  flexWrap: "wrap",
                                                }}
                                                className={`image-item ${
                                                  selectedImages.includes(
                                                    media._id
                                                  )
                                                    ? "selected"
                                                    : ""
                                                }`}
                                              >
                                                {media.file.endsWith(".mp4") ? (
                                                  <video
                                                    src={
                                                      "https://bookingoda.com/image/" +
                                                      media.file
                                                    }
                                                    alt="video"
                                                    style={{
                                                      width: "100px",
                                                      height: "100px",
                                                    }}
                                                    controls
                                                    onClick={() =>
                                                      toggleImageSelection(
                                                        media._id
                                                      )
                                                    }
                                                  />
                                                ) : null}
                                              </div>
                                            )
                                          )}

                                          {selectedImages.length > 0 ? (
                                            <button
                                              onClick={deleteSelectedImages}
                                              style={{
                                                padding: "10px 20px",
                                                backgroundColor: "red",
                                                color: "white",
                                                border: "none",
                                                borderRadius: "5px",
                                                cursor: "pointer",
                                                fontSize: "16px",
                                                fontWeight: "bold",
                                              }}
                                            >
                                              Delete {selectedImages.length}{" "}
                                              Selected Image
                                              {selectedImages.length !== 1
                                                ? "s"
                                                : ""}
                                            </button>
                                          ) : (
                                            ""
                                          )}
                                          {uploadedVideos.map(
                                            (video, index) => (
                                              <div key={index}>
                                                <p>Video {index + 1}</p>
                                                <video
                                                  width="200"
                                                  height="200"
                                                  controls
                                                >
                                                  <source
                                                    src={URL.createObjectURL(
                                                      video
                                                    )}
                                                    type="video/mp4"
                                                  />
                                                  Your browser does not support
                                                  the video tag.
                                                </video>

                                                <Tooltip title="delete">
                                                  <MdDelete
                                                    color="red"
                                                    onClick={() =>
                                                      handleDeleteVideo(index)
                                                    }
                                                  />
                                                </Tooltip>
                                              </div>
                                            )
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                    <div>
                                      <div className="spacing--4x" />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </>
                      ) : activeStep === 9 ? (
                        <>
                          <div className="form-container">
                            <div className="ffb9c3d6a31 wide-container">
                              <div className="aca0ade214 ebac6e22e9 f66f916626 c2931f4182 c27e5d305d db150fece4">
                                <div className="b817090550 b736e9e3f4">
                                  <div className="e1eebb6a1e spacing--4x">
                                    How much do you want to charge per night?
                                  </div>
                                  <div>
                                    <div className="form-group">
                                      <div className="a53cbfa6de ac9267e216 a20293ec70">
                                        <label
                                          htmlFor="desired_price"
                                          className="a53cbfa6de e6208ee469 f555271986"
                                        >
                                          <span>
                                            <span className="ccb65902b2">
                                              Price guests pay
                                            </span>
                                          </span>
                                        </label>
                                        <div className="b9b84f4305">
                                          <div className="b93ef00bf5">INR</div>
                                          <div className="e000754250">
                                            <input
                                              className="eb46370fe1"
                                              id="desired_price"
                                              value={price}
                                              onChange={(e) =>
                                                setprice(e.target.value)
                                              }
                                            />
                                            <div className="e7e5251f68" />
                                          </div>
                                        </div>
                                      </div>
                                      <div className="price-help-text spacing--2x">
                                        Including taxes, commission and charges
                                      </div>
                                    </div>
                                  </div>
                                  <label
                                    htmlFor="desired_price"
                                    className="a53cbfa6de e6208ee469 f555271986"
                                  >
                                    <span>
                                      <span className="ccb65902b2">
                                        Price commission
                                      </span>
                                    </span>
                                  </label>
                                  <div className="b9b84f4305">
                                    <div className="b93ef00bf5">%</div>
                                    <div className="e000754250">
                                      <input
                                        className="eb46370fe1"
                                        id="desired_price"
                                        value={pricecommission}
                                        onChange={(e) =>
                                          setpricecommission(e.target.value)
                                        }
                                      />
                                      <div className="e7e5251f68" />
                                    </div>
                                  </div>
                                  <div
                                    className="form-group usps"
                                    data-testid="non-empty-price-content"
                                  >
                                    <p>
                                      <strong
                                        className="prices"
                                        data-testid="total-commission-rate"
                                      >
                                        {pricecommission}%
                                      </strong>
                                      Booking-Oda.com commission
                                    </p>
                                    <ul className="usp-list">
                                      <li>
                                        <span
                                          className="fcd9eec8fb usp-list__checkmark-icon d24fc26e73 a229b4a525"
                                          aria-hidden="true"
                                        >
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 128 128"
                                          >
                                            <path d="M56.33 102a6 6 0 0 1-4.24-1.75L19.27 67.54A6.014 6.014 0 1 1 27.74 59l27.94 27.88 44-58.49a6 6 0 1 1 9.58 7.22l-48.17 64a5.998 5.998 0 0 1-4.34 2.39z" />
                                          </svg>
                                        </span>
                                        24/7 help in your language
                                      </li>
                                      <li>
                                        <span
                                          className="fcd9eec8fb usp-list__checkmark-icon d24fc26e73 a229b4a525"
                                          aria-hidden="true"
                                        >
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 128 128"
                                          >
                                            <path d="M56.33 102a6 6 0 0 1-4.24-1.75L19.27 67.54A6.014 6.014 0 1 1 27.74 59l27.94 27.88 44-58.49a6 6 0 1 1 9.58 7.22l-48.17 64a5.998 5.998 0 0 1-4.34 2.39z" />
                                          </svg>
                                        </span>
                                        Save time with automatically confirmed
                                        bookings
                                      </li>
                                      <li>
                                        <span
                                          className="fcd9eec8fb usp-list__checkmark-icon d24fc26e73 a229b4a525"
                                          aria-hidden="true"
                                        >
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 128 128"
                                          >
                                            <path d="M56.33 102a6 6 0 0 1-4.24-1.75L19.27 67.54A6.014 6.014 0 1 1 27.74 59l27.94 27.88 44-58.49a6 6 0 1 1 9.58 7.22l-48.17 64a5.998 5.998 0 0 1-4.34 2.39z" />
                                          </svg>
                                        </span>
                                        We promote your place on Google
                                      </li>
                                    </ul>
                                    <hr className="b9bfeba2b4 b288f61df6" />
                                    <p>
                                      <strong
                                        className="prices"
                                        data-testid="earnings"
                                      >
                                        INR{" "}
                                        {Number(
                                          price
                                            ? price
                                            : selectedModal.Price -
                                                (price
                                                  ? price
                                                  : selectedModal.Price *
                                                    parseInt(
                                                      pricecommission
                                                        ? pricecommission
                                                        : selectedModal.PriceCommision
                                                    )) /
                                                  100
                                        ).toFixed(2)}
                                      </strong>
                                      Your earnings (including taxes)
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </>
                      ) : activeStep === 10 ? (
                        <>
                          <div className="form-container">
                            <div className="f2cf178bcd screen-main-header d991e1216a">
                              <div className="f6431b446c e6208ee469 d0caee4251 screen-main-header__title">
                                Rate plans
                              </div>
                            </div>
                            <div className="ffb9c3d6a3 wide-container spacing--6x">
                              <div className="aca0ade214 ebac6e22e9 f66f916626 c2931f4182 c27e5d305d db150fece4">
                                <div className="b817090550 b736e9e3f4">
                                  <div className="a53cbfa6de">
                                    To attract a wider range of guests, we
                                    suggest setting up multiple rate plans. The
                                    recommended prices and policies for each
                                    plan are based on data from properties like
                                    yours, but they can be edited now or after
                                    you complete registration.
                                  </div>
                                </div>
                              </div>
                            </div>
                            <h2 className="f6431b446c screen-main-header spacing--4x">
                              Standard rate plan
                            </h2>
                            <div className="ffb9c3d6a3 wide-container spacing--6x">
                              <div
                                className="c82435a4b8"
                                style={{ padding: 0 }}
                              >
                                <div className="price-overview-heading spacing--4x">
                                  <div className="e1eebb6a1e price-overview-heading__text-container">
                                    <span className="price-overview-heading__text">
                                      Cancellation policy
                                    </span>
                                    <span className="f419a93f12">
                                      <span
                                        aria-expanded="false"
                                        data-testid="PricePlanHeader-tip-trigger"
                                        className="fcd9eec8fb price-overview-info bf9a32efa5"
                                        aria-hidden="true"
                                      >
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          viewBox="0 0 24 24"
                                        >
                                          <path d="M14.25 15.75h-.75a.75.75 0 0 1-.75-.75v-3.75a1.5 1.5 0 0 0-1.5-1.5h-.75a.75.75 0 0 0 0 1.5h.75V15a2.25 2.25 0 0 0 2.25 2.25h.75a.75.75 0 0 0 0-1.5zM11.625 6a1.125 1.125 0 1 0 0 2.25 1.125 1.125 0 0 0 0-2.25.75.75 0 0 0 0 1.5.375.375 0 1 1 0-.75.375.375 0 0 1 0 .75.75.75 0 0 0 0-1.5zM22.5 12c0 5.799-4.701 10.5-10.5 10.5S1.5 17.799 1.5 12 6.201 1.5 12 1.5 22.5 6.201 22.5 12zm1.5 0c0-6.627-5.373-12-12-12S0 5.373 0 12s5.373 12 12 12 12-5.373 12-12z" />
                                        </svg>
                                      </span>
                                    </span>
                                  </div>
                                  <button
                                    data-testid="PricePlanHeader-edit-btn"
                                    type="button"
                                    className="a83ed08757 c21c56c305 bf0537ecb5 ab98298258 deab83296e af7297d90d price-overview-link automation_class_price_overview_policies"
                                    onClick={() => setCancel(!Cancel)}
                                  >
                                    <span className="e4adce92df">Edit</span>
                                  </button>
                                </div>
                                <div className="abf093bdfe spacing--2x">
                                  This policy is set at the property level – any
                                  changes made will be applied to all units.
                                </div>
                                {/* <div className="abf093bdfe d068504c75 spacing--4x">
                          You’re 91% more likely to get bookings with the
                          pre-selected cancellation policy settings than with a
                          30-day cancellation policy
                        </div> */}
                                <div className="price-overview-checkbox-container spacing--4x">
                                  <span
                                    className="fcd9eec8fb price-overview-checkbox-icon dbed623b69 f64eb5d122"
                                    aria-hidden="true"
                                  >
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      viewBox="0 0 128 128"
                                    >
                                      <path d="M56.62 93.54a4 4 0 0 1-2.83-1.18L28.4 67a4 4 0 1 1 5.65-5.65l22.13 22.1 33-44a4 4 0 1 1 6.4 4.8L59.82 91.94a4.06 4.06 0 0 1-2.92 1.59zM128 64c0-35.346-28.654-64-64-64C28.654 0 0 28.654 0 64c0 35.346 28.654 64 64 64 35.33-.039 63.961-28.67 64-64zm-8 0c0 30.928-25.072 56-56 56S8 94.928 8 64 33.072 8 64 8c30.914.033 55.967 25.086 56 56z" />
                                    </svg>
                                  </span>
                                  <div className="a53cbfa6de">
                                    Guests can cancel their bookings for free up
                                    to {days} before their arrival
                                  </div>
                                </div>
                                <div className="price-overview-checkbox-container spacing--4x">
                                  <span
                                    className="fcd9eec8fb price-overview-checkbox-icon dbed623b69 f64eb5d122"
                                    aria-hidden="true"
                                  >
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      viewBox="0 0 128 128"
                                    >
                                      <path d="M56.62 93.54a4 4 0 0 1-2.83-1.18L28.4 67a4 4 0 1 1 5.65-5.65l22.13 22.1 33-44a4 4 0 1 1 6.4 4.8L59.82 91.94a4.06 4.06 0 0 1-2.92 1.59zM128 64c0-35.346-28.654-64-64-64C28.654 0 0 28.654 0 64c0 35.346 28.654 64 64 64 35.33-.039 63.961-28.67 64-64zm-8 0c0 30.928-25.072 56-56 56S8 94.928 8 64 33.072 8 64 8c30.914.033 55.967 25.086 56 56z" />
                                    </svg>
                                  </span>
                                  <div className="a53cbfa6de">
                                    Guests who cancel within 24 hours will have
                                    their cancellation fee waived
                                  </div>
                                </div>
                                {Cancel ? (
                                  <div className="ffb9c3d6a31 wide-container min-height">
                                    <div className="aca0ade214 ebac6e22e9 f66f916626 c2931f4182 c27e5d305d db150fece4">
                                      <div className="b817090550 b736e9e3f4">
                                        <div className="form-group">
                                          <p
                                            className="policies__label"
                                            data-testid="FreeCancellation-body"
                                          >
                                            How many days before their arrival
                                            can your guests{" "}
                                            <b>cancel their booking for free</b>
                                            ?
                                          </p>
                                          <div className="f3942475b4 b7efa77ac6 bd4784f2d6 d6b5cc954e">
                                            <div
                                              data-testid="FreeCancellation-SegmentedControl"
                                              className="spacing--6x policies__free-cancellation-segmented-control--desktop"
                                            >
                                              <span className="cdadcf1b05">
                                                <input
                                                  id=":r24:-1"
                                                  className="b7cdcf5f57"
                                                  type="radio"
                                                  name="days"
                                                  value={days}
                                                  onChange={() =>
                                                    setdays("1 day")
                                                  }
                                                  defaultChecked
                                                />
                                                <label
                                                  htmlFor=":r24:-1"
                                                  className="a53cbfa6de a454023a93"
                                                >
                                                  {/* <span className="abf093bdfe c147fc6dd1 policies__free-cancellation-recommended d18b4a6026">
                                      <span className="b30f8eb2d6">
                                        Recommended
                                      </span>
                                    </span> */}
                                                  1 day
                                                </label>
                                                <input
                                                  id=":r24:-5"
                                                  className="b7cdcf5f57"
                                                  type="radio"
                                                  name="days"
                                                  value={days}
                                                  onChange={() =>
                                                    setdays("5 days")
                                                  }
                                                />
                                                <label
                                                  htmlFor=":r24:-5"
                                                  className="a53cbfa6de a454023a93"
                                                >
                                                  5 days
                                                </label>
                                                <input
                                                  id=":r24:-14"
                                                  className="b7cdcf5f57"
                                                  type="radio"
                                                  name="days"
                                                  value={days}
                                                  onChange={() =>
                                                    setdays("14 days")
                                                  }
                                                />
                                                <label
                                                  htmlFor=":r24:-14"
                                                  className="a53cbfa6de a454023a93"
                                                >
                                                  14 days
                                                </label>
                                                <input
                                                  id=":r24:-30"
                                                  className="b7cdcf5f57"
                                                  type="radio"
                                                  name="days"
                                                  value={days}
                                                  onChange={() =>
                                                    setdays("30 days")
                                                  }
                                                />
                                                <label
                                                  htmlFor=":r24:-30"
                                                  className="a53cbfa6de a454023a93"
                                                >
                                                  30 days
                                                </label>
                                              </span>
                                            </div>
                                          </div>
                                          <div className="a4cbc7b9f5 a312d3fe67 b5156fd448 b88ad0398a">
                                            <div
                                              data-testid="FreeCancellation-select"
                                              className="a53cbfa6de ac9267e216 spacing--6x"
                                            >
                                              <div className="cabad3c686 aff44befa2">
                                                <select
                                                  className="ebf4591c8e"
                                                  name="days"
                                                  id=":r25:"
                                                >
                                                  <option
                                                    value={1}
                                                    data-key={1}
                                                  >
                                                    Recommended1 day
                                                  </option>
                                                  <option
                                                    value={5}
                                                    data-key={5}
                                                  >
                                                    5 days
                                                  </option>
                                                  <option
                                                    value={14}
                                                    data-key={14}
                                                  >
                                                    14 days
                                                  </option>
                                                  <option
                                                    value={30}
                                                    data-key={30}
                                                  >
                                                    30 days
                                                  </option>
                                                </select>
                                                <span className="ad2c299542">
                                                  <span
                                                    className="fcd9eec8fb cab1c196b5 c2cc050fb8 f64eb5d122"
                                                    aria-hidden="true"
                                                  >
                                                    <svg
                                                      xmlns="http://www.w3.org/2000/svg"
                                                      viewBox="0 0 24 24"
                                                    >
                                                      <path d="M19.2681 8.91288C19.2687 9.03226 19.2454 9.15054 19.1996 9.26079C19.1539 9.37105 19.0865 9.47105 19.0016 9.55493L12.9445 15.612C12.8211 15.7367 12.6738 15.8353 12.5115 15.9018C12.3491 15.9684 12.1751 16.0017 11.9996 15.9997C11.6491 16.0075 11.3096 15.877 11.0547 15.6362L4.99764 9.57916C4.8275 9.40881 4.73193 9.17788 4.73193 8.93711C4.73193 8.69634 4.8275 8.46542 4.99764 8.29506C5.08117 8.20935 5.18101 8.14122 5.29128 8.09471C5.40155 8.04819 5.52002 8.02423 5.6397 8.02423C5.75937 8.02423 5.87784 8.04819 5.98811 8.09471C6.09838 8.14122 6.19822 8.20935 6.28175 8.29506L11.9996 13.9887L17.7175 8.27084C17.801 8.18512 17.9009 8.11699 18.0111 8.07048C18.1214 8.02396 18.2399 8 18.3596 8C18.4792 8 18.5977 8.02396 18.708 8.07048C18.8182 8.11699 18.9181 8.18512 19.0016 8.27084C19.0871 8.3543 19.1548 8.45422 19.2006 8.56457C19.2464 8.67492 19.2694 8.79341 19.2681 8.91288Z" />
                                                    </svg>
                                                  </span>
                                                </span>
                                              </div>
                                            </div>
                                          </div>
                                          <div className="aca0ade214 aaf30230d9 c2931f4182 d79e71457a">
                                            <span
                                              className="fcd9eec8fb bf9a32efa5 b3ef400590"
                                              aria-hidden="true"
                                            >
                                              <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                viewBox="0 0 24 24"
                                              >
                                                <path d="M14.25 15.75h-.75a.75.75 0 0 1-.75-.75v-3.75a1.5 1.5 0 0 0-1.5-1.5h-.75a.75.75 0 0 0 0 1.5h.75V15a2.25 2.25 0 0 0 2.25 2.25h.75a.75.75 0 0 0 0-1.5zM11.625 6a1.125 1.125 0 1 0 0 2.25 1.125 1.125 0 0 0 0-2.25.75.75 0 0 0 0 1.5.375.375 0 1 1 0-.75.375.375 0 0 1 0 .75.75.75 0 0 0 0-1.5zM22.5 12c0 5.799-4.701 10.5-10.5 10.5S1.5 17.799 1.5 12 6.201 1.5 12 1.5 22.5 6.201 22.5 12zm1.5 0c0-6.627-5.373-12-12-12S0 5.373 0 12s5.373 12 12 12 12-5.373 12-12z" />
                                              </svg>
                                            </span>
                                            <div className="aaee4e7cd3 e7a57abb1e">
                                              Guests love flexibility – free
                                              cancellation rates are generally
                                              the most booked rates on our site.
                                              Get your first booking sooner by
                                              allowing guests to cancel up to
                                              five days before check-in.
                                            </div>
                                          </div>
                                          <div className="policies__spacing" />
                                          <div>
                                            <label
                                              className="policies__label spacing--4x"
                                              data-testid="GracePeriod-title"
                                              htmlFor="automation_id_switch_grace_period"
                                            >
                                              <div className="a3332d346a">
                                                Protection against accidental
                                                bookings
                                              </div>
                                            </label>
                                            <div data-testid="GracePeriod-InputSwitch">
                                              <div className="a53cbfa6de d87fd4472a">
                                                <input
                                                  type="checkbox"
                                                  name="switch"
                                                  id="automation_id_switch_grace_period"
                                                  className="f43c0d9153"
                                                  role="switch"
                                                  onChange={() =>
                                                    setaccidental(!accidental)
                                                  }
                                                />
                                                <label
                                                  htmlFor="automation_id_switch_grace_period"
                                                  className="dbe832aef0"
                                                  aria-live="polite"
                                                >
                                                  <span className="ade77cee67" />
                                                  <span className="c640a7fa88">
                                                    {!accidental ? "Off" : "On"}
                                                  </span>
                                                  <span className="f9bbc6d198" />
                                                </label>
                                              </div>
                                            </div>
                                            <div
                                              className="grace-period__caption"
                                              data-testid="GracePeriod-body"
                                            >
                                              <div className="abf093bdfe f45d8e4c32">
                                                To avoid you having to spend
                                                time handling accidental
                                                bookings, we automatically waive
                                                cancellation fees for guests
                                                that cancel within the first 24
                                                hours of making a booking.
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                ) : (
                                  ""
                                )}
                                <hr className="b9bfeba2b4 b288f61df6 spacing--4x" />
                                <div className="price-overview-heading spacing--4x">
                                  <div className="e1eebb6a1e price-overview-heading__text-container">
                                    <span className="price-overview-heading__text">
                                      Price per group size
                                    </span>
                                    <span className="f419a93f12">
                                      <span
                                        aria-expanded="false"
                                        data-testid="PricePlanHeader-tip-trigger"
                                        className="fcd9eec8fb price-overview-info bf9a32efa5"
                                        aria-hidden="true"
                                      >
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          viewBox="0 0 24 24"
                                        >
                                          <path d="M14.25 15.75h-.75a.75.75 0 0 1-.75-.75v-3.75a1.5 1.5 0 0 0-1.5-1.5h-.75a.75.75 0 0 0 0 1.5h.75V15a2.25 2.25 0 0 0 2.25 2.25h.75a.75.75 0 0 0 0-1.5zM11.625 6a1.125 1.125 0 1 0 0 2.25 1.125 1.125 0 0 0 0-2.25.75.75 0 0 0 0 1.5.375.375 0 1 1 0-.75.375.375 0 0 1 0 .75.75.75 0 0 0 0-1.5zM22.5 12c0 5.799-4.701 10.5-10.5 10.5S1.5 17.799 1.5 12 6.201 1.5 12 1.5 22.5 6.201 22.5 12zm1.5 0c0-6.627-5.373-12-12-12S0 5.373 0 12s5.373 12 12 12 12-5.373 12-12z" />
                                        </svg>
                                      </span>
                                    </span>
                                  </div>
                                  <button
                                    data-testid="PricePlanHeader-edit-btn"
                                    type="button"
                                    className="a83ed08757 c21c56c305 bf0537ecb5 ab98298258 deab83296e af7297d90d price-overview-link automation_class_price_overview_obp"
                                    onClick={() => setGroup(!Group)}
                                  >
                                    <span className="e4adce92df">Edit</span>
                                  </button>
                                </div>
                                {/* <div className="abf093bdfe d068504c75 spacing--4x">
                          You’re 12% more likely to get bookings if you set
                          lower prices for smaller groups of guests
                        </div> */}
                                <div className="spacing--4x">
                                  <div className="price-overview-per-person-item spacing--4x">
                                    <div className="price-overview-per-person__person">
                                      <div className="e1eebb6a1e">
                                        Occupancy
                                      </div>
                                    </div>
                                    <div className="price-overview-per-person__price">
                                      <div className="e1eebb6a1e">
                                        Guests pay
                                      </div>
                                    </div>
                                  </div>
                                  {des?.map((item) => (
                                    <div
                                      className="price-overview-per-person-item spacing--4x"
                                      data-testid="OccupancyRatesSection-price-per-person"
                                    >
                                      <div className="price-overview-per-person__person">
                                        <span
                                          className="fcd9eec8fb d24fc26e73"
                                          aria-hidden="true"
                                        >
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 24 24"
                                          >
                                            <path d="M16.5 6a4.5 4.5 0 1 1-9 0 4.5 4.5 0 0 1 9 0zM18 6A6 6 0 1 0 6 6a6 6 0 0 0 12 0zM3 23.25a9 9 0 1 1 18 0 .75.75 0 0 0 1.5 0c0-5.799-4.701-10.5-10.5-10.5S1.5 17.451 1.5 23.25a.75.75 0 0 0 1.5 0z" />
                                          </svg>
                                        </span>{" "}
                                        ✕ {item}
                                      </div>
                                      <div className="price-overview-per-person__price">
                                        INR{" "}
                                        {Object.keys(discount ?? {}).length !== 0 && discount[item] !== undefined
  ? (parseInt(price) - parseInt(price) * (discount[item] ?? 0) / 100).toFixed(2)
  : parseInt(price).toFixed(2)}
                                      </div>
                                    </div>
                                  ))}
                                </div>
                              </div>
                            </div>

                            {Group ? (
                              <div className="ffb9c3d6a3 wide-container spacing--6x">
                                <div className="aca0ade214 ebac6e22e9 f66f916626 c2931f4182 c27e5d305d db150fece4">
                                  <div className="b817090550 b736e9e3f4">
                                    <div className="a53cbfa6de spacing--4x">
                                      Offering lower rates for groups of less
                                      than 2 makes your property more attractive
                                      to potential guests.
                                    </div>
                                    <div className="a53cbfa6de spacing--4x">
                                      The recommended discounts are based on
                                      data from properties like yours. These can
                                      be updated at any time.
                                    </div>
                                    <div
                                      data-testid="GroupDiscount-input-switch"
                                      className="a53cbfa6de d87fd4472a"
                                    >
                                      <input
                                        type="checkbox"
                                        name="switch"
                                        id=":r88:"
                                        className="f43c0d9153"
                                        value={enable}
                                        onChange={() => setenable(!enable)}
                                      />
                                      <label
                                        htmlFor=":r88:"
                                        className="dbe832aef0"
                                        aria-live="polite"
                                      >
                                        <span className="ade77cee67" />
                                        <span className="c640a7fa88">
                                          {!enable ? "Disabled" : "Enabled"}
                                        </span>
                                        <span className="f9bbc6d198" />
                                      </label>
                                    </div>
                                    {enable ? (
                                      <div
                                        className="obp-discounts-table__container"
                                        data-testid="DiscountTable-container"
                                      >
                                        <div className="obp-discounts-table">
                                          <div className="obp-discounts-table__header">
                                            <div className="aca0ade214 ebac6e22e9 f66f916626 c2931f4182 c27e5d305d db150fece4">
                                              <div className="b817090550 a7cf1a6b1d">
                                                <div className="a3332d346a">
                                                  Occupancy
                                                </div>
                                              </div>
                                              <div className="obp-discounts-table__header-discount b817090550 c44c37515e">
                                                <div className="a3332d346a">
                                                  Discount
                                                </div>
                                              </div>
                                              <div className="obp-discounts-table__header-price b817090550 b8d011b59c">
                                                <div className="a3332d346a">
                                                  Guests pay
                                                </div>
                                              </div>
                                            </div>
                                          </div>

                                          {des?.map((item, index) => (
                                            <div className="obp-discounts-table__discount">
                                              <div className="aca0ade214 ebac6e22e9 f66f916626 c2931f4182 c27e5d305d db150fece4">
                                                <div className="obp-discounts-table__num-guests b817090550 a7cf1a6b1d">
                                                  {item} guest
                                                </div>
                                                <div className="obp-discounts-table__discount-value b817090550 c44c37515e">
                                                  <div className="a53cbfa6de ac9267e216 a20293ec70">
                                                    <div className="b9b84f4305">
                                                      <div className="e000754250">
                                                        <input
                                                          type="number"
                                                          onKeyDown={
                                                            handleKeyDown
                                                          }
                                                          name="percentage"
                                                          className="eb46370fe1"
                                                          placeholder={0}
                                                          value={discount[item]}
                                                          onChange={(e) => {
                                                            const newDiscounts =
                                                              {
                                                                ...discount,
                                                                [item]:
                                                                  parseInt(
                                                                    e.target
                                                                      .value,
                                                                    10
                                                                  ) || 0,
                                                              };
                                                            setdiscount(
                                                              newDiscounts
                                                            );
                                                          }}
                                                        />
                                                        <div className="e7e5251f68" />
                                                      </div>
                                                      <div className="b93ef00bf5">
                                                        %
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                                <div className="obp-discounts-table__price b817090550 b8d011b59c">
                                                  INR{" "}
                                                  {Object.keys(discount ?? {}).length !== 0 && discount[item] !== undefined
  ? (parseInt(price) - parseInt(price) * (discount[item] ?? 0) / 100).toFixed(2)
  : parseInt(price).toFixed(2)}
                                                </div>
                                              </div>
                                            </div>
                                          ))}
                                        </div>
                                      </div>
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                </div>
                              </div>
                            ) : (
                              ""
                            )}
                            <h2 className="f6431b446c screen-main-header spacing--4x">
                              Non-refundable rate plan
                            </h2>
                            <div className="ffb9c3d6a3 wide-container spacing--6x">
                              <div className="price-overview-heading spacing--4x">
                                <div className="e1eebb6a1e price-overview-heading__text-container">
                                  <span className="price-overview-heading__text">
                                    Price and cancellation policy
                                  </span>
                                  <span className="f419a93f12">
                                    <span
                                      aria-expanded="false"
                                      data-testid="PricePlanHeader-tip-trigger"
                                      className="fcd9eec8fb price-overview-info bf9a32efa5"
                                      aria-hidden="true"
                                    >
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 24 24"
                                      >
                                        <path d="M14.25 15.75h-.75a.75.75 0 0 1-.75-.75v-3.75a1.5 1.5 0 0 0-1.5-1.5h-.75a.75.75 0 0 0 0 1.5h.75V15a2.25 2.25 0 0 0 2.25 2.25h.75a.75.75 0 0 0 0-1.5zM11.625 6a1.125 1.125 0 1 0 0 2.25 1.125 1.125 0 0 0 0-2.25.75.75 0 0 0 0 1.5.375.375 0 1 1 0-.75.375.375 0 0 1 0 .75.75.75 0 0 0 0-1.5zM22.5 12c0 5.799-4.701 10.5-10.5 10.5S1.5 17.799 1.5 12 6.201 1.5 12 1.5 22.5 6.201 22.5 12zm1.5 0c0-6.627-5.373-12-12-12S0 5.373 0 12s5.373 12 12 12 12-5.373 12-12z" />
                                      </svg>
                                    </span>
                                  </span>
                                </div>
                                <button
                                  data-testid="PricePlanHeader-edit-btn"
                                  type="button"
                                  className="a83ed08757 c21c56c305 bf0537ecb5 ab98298258 deab83296e af7297d90d price-overview-link automation_class_price_overview_non_refundable"
                                  onClick={() => setRefund(!Refund)}
                                >
                                  <span className="e4adce92df">Edit</span>
                                </button>
                              </div>
                              <div className="price-overview-checkbox-container spacing--4x">
                                <span
                                  className="fcd9eec8fb price-overview-checkbox-icon dbed623b69 f64eb5d122"
                                  aria-hidden="true"
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 128 128"
                                  >
                                    <path d="M56.62 93.54a4 4 0 0 1-2.83-1.18L28.4 67a4 4 0 1 1 5.65-5.65l22.13 22.1 33-44a4 4 0 1 1 6.4 4.8L59.82 91.94a4.06 4.06 0 0 1-2.92 1.59zM128 64c0-35.346-28.654-64-64-64C28.654 0 0 28.654 0 64c0 35.346 28.654 64 64 64 35.33-.039 63.961-28.67 64-64zm-8 0c0 30.928-25.072 56-56 56S8 94.928 8 64 33.072 8 64 8c30.914.033 55.967 25.086 56 56z" />
                                  </svg>
                                </span>
                                <div className="a53cbfa6de">
                                  Guests will pay {refamount}% less than the
                                  standard rate for a non-refundable rate
                                </div>
                              </div>
                              <div className="price-overview-checkbox-container">
                                <span
                                  className="fcd9eec8fb price-overview-checkbox-icon dbed623b69 f64eb5d122"
                                  aria-hidden="true"
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 128 128"
                                  >
                                    <path d="M56.62 93.54a4 4 0 0 1-2.83-1.18L28.4 67a4 4 0 1 1 5.65-5.65l22.13 22.1 33-44a4 4 0 1 1 6.4 4.8L59.82 91.94a4.06 4.06 0 0 1-2.92 1.59zM128 64c0-35.346-28.654-64-64-64C28.654 0 0 28.654 0 64c0 35.346 28.654 64 64 64 35.33-.039 63.961-28.67 64-64zm-8 0c0 30.928-25.072 56-56 56S8 94.928 8 64 33.072 8 64 8c30.914.033 55.967 25.086 56 56z" />
                                  </svg>
                                </span>
                                <div className="a53cbfa6de">
                                  Guests cannot cancel their bookings for free
                                  at any time
                                </div>
                              </div>
                            </div>
                            {Refund ? (
                              <div className="ffb9c3d6a3 wide-container min-height">
                                <div className="aca0ade214 ebac6e22e9 f66f916626 c2931f4182 c27e5d305d db150fece4">
                                  <div className="b817090550 b736e9e3f4">
                                    <div className="spacing--4x">
                                      In addition to the standard rate plan
                                      you've created for your property, you can
                                      add a non-refundable rate plan.
                                    </div>
                                    <div className="spacing--8x">
                                      With this, you set a discounted price but
                                      your{" "}
                                      <b>
                                        revenue for these bookings is guaranteed
                                      </b>{" "}
                                      as guests will not receive a refund if
                                      they cancel or don’t show up.
                                    </div>
                                    <div
                                      id="automation_id_non_refundable_plan_switch"
                                      data-testid="automation_id_non_refundable_plan_switch"
                                      className="a53cbfa6de d87fd4472a spacing--8x"
                                    >
                                      <input
                                        type="checkbox"
                                        name="switch"
                                        id=":r3u:"
                                        className="f43c0d9153"
                                        role="switch"
                                        value={reenable}
                                        onChange={() => setreenable(!reenable)}
                                      />
                                      <label
                                        htmlFor=":r3u:"
                                        className="dbe832aef0"
                                        aria-live="polite"
                                      >
                                        <span className="ade77cee67" />
                                        <span className="c640a7fa88">
                                          Set up a non-refundable rate plan
                                        </span>
                                        <span className="f9bbc6d198" />
                                      </label>
                                    </div>
                                    {reenable ? (
                                      <div
                                        id="automation_id_non_refundable_plan_percentage_enabled_container"
                                        data-testid="non_refundable_plan_percentage_enabled_container"
                                      >
                                        <hr className="b9bfeba2b4 b288f61df6 spacing--8x" />
                                        <div
                                          id="price_non_refundable_input_id"
                                          className="a53cbfa6de ac9267e216 a20293ec70"
                                        >
                                          <label
                                            htmlFor=":r3v:"
                                            className="a53cbfa6de e6208ee469 f555271986"
                                          >
                                            <span>
                                              <span className="ccb65902b2">
                                                Discount for guests that book
                                                with this rate plan:
                                              </span>
                                            </span>
                                          </label>
                                          <div className="b9b84f4305">
                                            <div className="e000754250">
                                              <input
                                                type="number"
                                                onKeyDown={handleKeyDown}
                                                name="discount"
                                                className="eb46370fe1"
                                                id=":r3v:"
                                                value={refamount}
                                                onChange={(e) =>
                                                  setrefamount(e.target.value)
                                                }
                                              />
                                              <div className="e7e5251f68" />
                                            </div>
                                            <div className="b93ef00bf5">%</div>
                                          </div>
                                        </div>
                                        <table className="non-refund-rate-calculation__table spacing--8x">
                                          <tbody>
                                            <tr>
                                              <td className="non-refund-rate-calculation__value-cell">
                                                INR {parseInt(price)}
                                              </td>
                                              <td>Base price</td>
                                            </tr>
                                            <tr>
                                              <td className="non-refund-rate-calculation__value-cell">
                                                {refamount}%
                                              </td>
                                              <td>
                                                Discount when guests book the
                                                non-refundable option
                                              </td>
                                            </tr>
                                            <tr className="non-refund-rate-calculation__final-price-row">
                                              <td
                                                className="non-refund-rate-calculation__value-cell"
                                                data-testid="non_refund_price"
                                              >
                                                INR{" "}
                                                {parseInt(price) -
                                                  (parseInt(price) *
                                                    refamount) /
                                                    100}
                                              </td>
                                              <td>Non-refundable price</td>
                                            </tr>
                                          </tbody>
                                        </table>
                                        <div className="aca0ade214 aaf30230d9 c2931f4182 d79e71457a">
                                          <span
                                            className="fcd9eec8fb bf9a32efa5"
                                            aria-hidden="true"
                                          >
                                            <svg
                                              xmlns="http://www.w3.org/2000/svg"
                                              viewBox="0 0 24 24"
                                            >
                                              <path d="M12 15.75A1.125 1.125 0 1 0 12 18a1.125 1.125 0 0 0 0-2.25.75.75 0 0 0 0 1.5.375.375 0 1 1 0-.75.375.375 0 0 1 0 .75.75.75 0 0 0 0-1.5zm.75-2.25V5.25a.75.75 0 0 0-1.5 0v8.25a.75.75 0 0 0 1.5 0zM22.5 12c0 5.799-4.701 10.5-10.5 10.5S1.5 17.799 1.5 12 6.201 1.5 12 1.5 22.5 6.201 22.5 12zm1.5 0c0-6.627-5.373-12-12-12S0 5.373 0 12s5.373 12 12 12 12-5.373 12-12z" />
                                            </svg>
                                          </span>
                                          <div className="aaee4e7cd3 e7a57abb1e">
                                            <div className="a53cbfa6de">
                                              Guests who select non-refundable
                                              rates are usually looking for
                                              competitive prices. A discount of
                                              at least {refamount}% will attract
                                              more guests by improving your
                                              visibility.
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                </div>
                              </div>
                            ) : (
                              ""
                            )}
                            <h2 className="f6431b446c screen-main-header spacing--4x">
                              Weekly rate plan
                            </h2>
                            <div className="ffb9c3d6a3 wide-container">
                              <div className="price-overview-heading spacing--4x">
                                <div className="e1eebb6a1e price-overview-heading__text-container">
                                  <span className="price-overview-heading__text">
                                    Price and cancellation policy
                                  </span>
                                  <span className="f419a93f12">
                                    <span
                                      aria-expanded="false"
                                      data-testid="PricePlanHeader-tip-trigger"
                                      className="fcd9eec8fb price-overview-info bf9a32efa5"
                                      aria-hidden="true"
                                    >
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 24 24"
                                      >
                                        <path d="M14.25 15.75h-.75a.75.75 0 0 1-.75-.75v-3.75a1.5 1.5 0 0 0-1.5-1.5h-.75a.75.75 0 0 0 0 1.5h.75V15a2.25 2.25 0 0 0 2.25 2.25h.75a.75.75 0 0 0 0-1.5zM11.625 6a1.125 1.125 0 1 0 0 2.25 1.125 1.125 0 0 0 0-2.25.75.75 0 0 0 0 1.5.375.375 0 1 1 0-.75.375.375 0 0 1 0 .75.75.75 0 0 0 0-1.5zM22.5 12c0 5.799-4.701 10.5-10.5 10.5S1.5 17.799 1.5 12 6.201 1.5 12 1.5 22.5 6.201 22.5 12zm1.5 0c0-6.627-5.373-12-12-12S0 5.373 0 12s5.373 12 12 12 12-5.373 12-12z" />
                                      </svg>
                                    </span>
                                  </span>
                                </div>
                                <button
                                  data-testid="PricePlanHeader-edit-btn"
                                  type="button"
                                  className="a83ed08757 c21c56c305 bf0537ecb5 ab98298258 deab83296e af7297d90d price-overview-link automation_class_price_overview_weekly_rate"
                                  onClick={() => setWeek(!Week)}
                                >
                                  <span className="e4adce92df">Edit</span>
                                </button>
                              </div>
                              {/* <div className="abf093bdfe d068504c75 spacing--4x">
                        You’re 16% more likely to get bookings with the 15%
                        pre-selected weekly rate than with none
                      </div> */}
                              <div className="price-overview-checkbox-container spacing--4x">
                                <span
                                  className="fcd9eec8fb price-overview-checkbox-icon dbed623b69"
                                  aria-hidden="true"
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 128 128"
                                  >
                                    <path d="M56.62 93.54a4 4 0 0 1-2.83-1.18L28.4 67a4 4 0 1 1 5.65-5.65l22.13 22.1 33-44a4 4 0 1 1 6.4 4.8L59.82 91.94a4.06 4.06 0 0 1-2.92 1.59zM128 64c0-35.346-28.654-64-64-64C28.654 0 0 28.654 0 64c0 35.346 28.654 64 64 64 35.33-.039 63.961-28.67 64-64zm-8 0c0 30.928-25.072 56-56 56S8 94.928 8 64 33.072 8 64 8c30.914.033 55.967 25.086 56 56z" />
                                  </svg>
                                </span>
                                <div className="a53cbfa6de">
                                  Guests will pay {weekamount}% less than the
                                  standard rate when they book for at least 7
                                  nights
                                </div>
                              </div>
                              <div className="price-overview-checkbox-container">
                                <span
                                  className="fcd9eec8fb price-overview-checkbox-icon dbed623b69"
                                  aria-hidden="true"
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 128 128"
                                  >
                                    <path d="M56.62 93.54a4 4 0 0 1-2.83-1.18L28.4 67a4 4 0 1 1 5.65-5.65l22.13 22.1 33-44a4 4 0 1 1 6.4 4.8L59.82 91.94a4.06 4.06 0 0 1-2.92 1.59zM128 64c0-35.346-28.654-64-64-64C28.654 0 0 28.654 0 64c0 35.346 28.654 64 64 64 35.33-.039 63.961-28.67 64-64zm-8 0c0 30.928-25.072 56-56 56S8 94.928 8 64 33.072 8 64 8c30.914.033 55.967 25.086 56 56z" />
                                  </svg>
                                </span>
                                <div className="a53cbfa6de">
                                  Guests can cancel their bookings for free up
                                  to {days}
                                  before their arrival (based on the standard
                                  rate cancellation policy)
                                </div>
                              </div>
                            </div>
                            {Week ? (
                              <div className="ffb9c3d6a3 wide-container min-height">
                                <div className="aca0ade214 ebac6e22e9 f66f916626 c2931f4182 c27e5d305d db150fece4">
                                  <div className="b817090550 b736e9e3f4">
                                    <div className="a53cbfa6de spacing--4x">
                                      In addition to the standard rate plan
                                      you've created for your property, you can
                                      add a weekly rate plan.
                                    </div>
                                    <div className="a53cbfa6de spacing--8x">
                                      With this, you set a discounted price and
                                      use the same cancellation policy as the
                                      standard rate plan. Guests who stay for at
                                      least a week are interested in discounts
                                      since they’ll be spending more on their
                                      overall booking.
                                    </div>
                                    <div
                                      data-testid="WeeklyRates-input-switch"
                                      className="a53cbfa6de d87fd4472a spacing--8x"
                                    >
                                      <input
                                        type="checkbox"
                                        name="switch"
                                        id=":r8u:"
                                        className="f43c0d9153"
                                        role="switch"
                                        onChange={() => setweekly(!weekly)}
                                      />
                                      <label
                                        htmlFor=":r8u:"
                                        className="dbe832aef0"
                                        aria-live="polite"
                                      >
                                        <span className="ade77cee67" />
                                        <span className="c640a7fa88">
                                          Set up a weekly rate plan
                                        </span>
                                        <span className="f9bbc6d198" />
                                      </label>
                                    </div>
                                    <hr className="b9bfeba2b4 b288f61df6 spacing--8x" />
                                    {weekly ? (
                                      <>
                                        <div
                                          data-testid="WeeklyRates-input-discount"
                                          className="a53cbfa6de ac9267e216 spacing--6x non-refund-rate-discount a20293ec70"
                                        >
                                          <label
                                            htmlFor=":r8v:"
                                            className="a53cbfa6de e6208ee469 f555271986"
                                          >
                                            <span>
                                              <span className="ccb65902b2">
                                                How much cheaper than the
                                                standard rate would you like to
                                                make this rate plan for
                                                weekdays?
                                              </span>
                                            </span>
                                          </label>
                                          <div className="b9b84f4305">
                                            <div className="e000754250">
                                              <input
                                                type="number"
                                                onKeyDown={handleKeyDown}
                                                name="discount"
                                                className="eb46370fe1"
                                                value={weekamount}
                                                onChange={(e) =>
                                                  setweekamount(e.target.value)
                                                }
                                              />
                                              <div className="e7e5251f68" />
                                            </div>
                                            <div className="b93ef00bf5">%</div>
                                          </div>
                                        </div>{" "}
                                        <div
                                          data-testid="WeeklyRates-input-discount"
                                          className="a53cbfa6de ac9267e216 spacing--6x non-refund-rate-discount a20293ec70"
                                        >
                                          <label
                                            htmlFor=":r8v:"
                                            className="a53cbfa6de e6208ee469 f555271986"
                                          >
                                            <span>
                                              <span className="ccb65902b2">
                                                How much cheaper than the
                                                standard rate would you like to
                                                make this rate plan for weekend?
                                              </span>
                                            </span>
                                          </label>
                                          <div className="b9b84f4305">
                                            <div className="e000754250">
                                              <input
                                                type="number"
                                                onKeyDown={handleKeyDown}
                                                name="discount"
                                                className="eb46370fe1"
                                                value={weekendamount}
                                                onChange={(e) =>
                                                  setweekendamount(
                                                    e.target.value
                                                  )
                                                }
                                              />
                                              <div className="e7e5251f68" />
                                            </div>
                                            <div className="b93ef00bf5">%</div>
                                          </div>
                                        </div>{" "}
                                      </>
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                </div>
                              </div>
                            ) : (
                              ""
                            )}
                          </div>{" "}
                        </>
                      ) : activeStep === 11 ? (
                        <>
                          <div className="form-container">
                            <div className="f2cf178bcd screen-main-header d991e1216a">
                              <div className="f6431b446c e6208ee469 d0caee4251 screen-main-header__title">
                                Guest payment options
                              </div>
                            </div>
                            <div className="ffb9c3d6a3 wide-container">
                              <div className="b817090550 b736e9e3f4">
                                <div className="a3332d346a guest-payment--header spacing--4x">
                                  Can you charge credit cards at your property?
                                </div>
                                <div className="spacing--8x">
                                  <fieldset className="a53cbfa6de ac9267e216">
                                    <div className="aca0ade214 aaf30230d9 cd2e7d62b0">
                                      <div className="a53cbfa6de ac9267e216 ad8af0911d">
                                        <label
                                          htmlFor=":r6c:"
                                          className="aca0ade214 aaf30230d9 c2931f4182 d79e71457a f8208e9f83"
                                        >
                                          <input
                                            className="c907c67d20"
                                            type="radio"
                                            value="Yes"
                                            checked={credit === "Yes"}
                                            onChange={handleOptionChangePayment}
                                          />
                                          <span className="aaee4e7cd3 e7a57abb1e">
                                            <div className="a53cbfa6de e6208ee469">
                                              Yes
                                            </div>
                                          </span>
                                        </label>
                                      </div>
                                      <div className="a53cbfa6de ac9267e216 ad8af0911d">
                                        <label
                                          htmlFor=":r6c:"
                                          className="aca0ade214 aaf30230d9 c2931f4182 d79e71457a f8208e9f83"
                                        >
                                          <input
                                            className="c907c67d20"
                                            type="radio"
                                            value="No"
                                            checked={credit === "No"}
                                            onChange={handleOptionChangePayment}
                                          />
                                          <span className="aaee4e7cd3 e7a57abb1e">
                                            <div className="a53cbfa6de e6208ee469">
                                              No
                                            </div>
                                          </span>
                                        </label>
                                      </div>
                                    </div>
                                  </fieldset>
                                </div>
                              </div>
                              <div className="spacing--6x">
                                <div className="b817090550 b736e9e3f4" />
                              </div>
                            </div>
                          </div>
                        </>
                      ) : activeStep === 12 ? (
                        <>
                          <div className="form-container">
                            <div className="f2cf178bcd screen-main-header d991e1216a">
                              <div className="f6431b446c e6208ee469 d0caee4251 screen-main-header__title">
                                Invoicing
                              </div>
                            </div>
                            <div className="ffb9c3d6a3 wide-container min-height">
                              <div className="aca0ade214 ebac6e22e9 f66f916626 c2931f4182 c27e5d305d db150fece4">
                                <div className="b817090550 b736e9e3f4">
                                  <div className="e1eebb6a1e spacing--4x question-title">
                                    What name should be on the invoice?
                                  </div>

                                  <div className="spacing--6x" />
                                  <div className="a53cbfa6de ac9267e216 a20293ec70">
                                    <label
                                      htmlFor=":ro:"
                                      className="a53cbfa6de e6208ee469 f555271986"
                                    >
                                      <span>
                                        <span className="ccb65902b2">
                                          Legal company name (please specify)
                                        </span>
                                      </span>
                                    </label>
                                    <div className="b9b84f4305">
                                      <div className="e000754250">
                                        <input
                                          name="legal_company_name"
                                          className="eb46370fe1"
                                          value={name1}
                                          onChange={(e) =>
                                            setname1(e.target.value)
                                          }
                                        />
                                        <div className="e7e5251f68" />
                                      </div>
                                    </div>
                                  </div>
                                  <div className="spacing--8x" />
                                  <hr className="b9bfeba2b4 b288f61df6" />
                                  <div className="spacing--8x" />
                                  <div className="e1eebb6a1e spacing--4x question-title">
                                    Does this recipient have the same address as
                                    your property?
                                  </div>
                                  <fieldset className="a53cbfa6de ac9267e216">
                                    <div className="aca0ade214 aaf30230d9 cd2e7d62b0">
                                      <div className="a53cbfa6de ac9267e216 ad8af0911d">
                                        <label
                                          htmlFor=":r6c:"
                                          className="aca0ade214 aaf30230d9 c2931f4182 d79e71457a f8208e9f83"
                                        >
                                          <input
                                            className="c907c67d20"
                                            type="radio"
                                            value="Yes"
                                            checked={same === "Yes"}
                                            onChange={handleOptionChangesame}
                                          />
                                          <span className="aaee4e7cd3 e7a57abb1e">
                                            <div className="a53cbfa6de e6208ee469">
                                              Yes
                                            </div>
                                          </span>
                                        </label>
                                      </div>
                                      <div className="a53cbfa6de ac9267e216 ad8af0911d">
                                        <label
                                          htmlFor=":r6c:"
                                          className="aca0ade214 aaf30230d9 c2931f4182 d79e71457a f8208e9f83"
                                        >
                                          <input
                                            className="c907c67d20"
                                            type="radio"
                                            value="No"
                                            checked={same === "No"}
                                            onChange={handleOptionChangesame}
                                          />
                                          <span className="aaee4e7cd3 e7a57abb1e">
                                            <div className="a53cbfa6de e6208ee469">
                                              No
                                            </div>
                                          </span>
                                        </label>
                                      </div>
                                    </div>
                                    {same === "No" ? (
                                      <div class="b817090550 b736e9e3f4">
                                        <div class="a53cbfa6de spacing--4x">
                                          We may send a letter to confirm the
                                          location of your property, so make
                                          sure that the address is correct –
                                          it’s difficult to make changes to it
                                          later.
                                        </div>
                                        <div class="a53cbfa6de ac9267e216 form-group">
                                          <label
                                            for="cc1"
                                            class="a53cbfa6de e6208ee469 f555271986"
                                          >
                                            <span>
                                              <span class="ccb65902b2">
                                                Country/region
                                              </span>
                                            </span>
                                          </label>
                                          <div class="cabad3c686 aff44befa2">
                                            <select
                                              class="ebf4591c8e country-select-for-e2e-tests"
                                              name="cc1"
                                              id="cc1"
                                              value={country1}
                                              onChange={(e) =>
                                                setcountry1(e.target.value)
                                              }
                                            >
                                              <option value="">
                                                Select country/region
                                              </option>

                                              <option
                                                value="India"
                                                data-key="in"
                                              >
                                                India
                                              </option>
                                            </select>
                                            <span class="ad2c299542">
                                              <span
                                                class="fcd9eec8fb cab1c196b5 c2cc050fb8 f64eb5d122"
                                                aria-hidden="true"
                                              >
                                                <svg
                                                  xmlns="http://www.w3.org/2000/svg"
                                                  viewBox="0 0 24 24"
                                                >
                                                  <path d="M19.2681 8.91288C19.2687 9.03226 19.2454 9.15054 19.1996 9.26079C19.1539 9.37105 19.0865 9.47105 19.0016 9.55493L12.9445 15.612C12.8211 15.7367 12.6738 15.8353 12.5115 15.9018C12.3491 15.9684 12.1751 16.0017 11.9996 15.9997C11.6491 16.0075 11.3096 15.877 11.0547 15.6362L4.99764 9.57916C4.8275 9.40881 4.73193 9.17788 4.73193 8.93711C4.73193 8.69634 4.8275 8.46542 4.99764 8.29506C5.08117 8.20935 5.18101 8.14122 5.29128 8.09471C5.40155 8.04819 5.52002 8.02423 5.6397 8.02423C5.75937 8.02423 5.87784 8.04819 5.98811 8.09471C6.09838 8.14122 6.19822 8.20935 6.28175 8.29506L11.9996 13.9887L17.7175 8.27084C17.801 8.18512 17.9009 8.11699 18.0111 8.07048C18.1214 8.02396 18.2399 8 18.3596 8C18.4792 8 18.5977 8.02396 18.708 8.07048C18.8182 8.11699 18.9181 8.18512 19.0016 8.27084C19.0871 8.3543 19.1548 8.45422 19.2006 8.56457C19.2464 8.67492 19.2694 8.79341 19.2681 8.91288Z"></path>
                                                </svg>
                                              </span>
                                            </span>
                                          </div>
                                        </div>

                                        <form
                                          autocomplete="off"
                                          class="form-group"
                                        >
                                          <div
                                            class="autocomplete-suggestions-dropdown"
                                            data-testid="autocomplete_container"
                                          >
                                            <div class="a53cbfa6de ac9267e216 a20293ec70">
                                              <label
                                                for="address-autocomplete"
                                                class="a53cbfa6de e6208ee469 f555271986"
                                              >
                                                <span>
                                                  <span class="ccb65902b2">
                                                    Street name and house number
                                                  </span>
                                                </span>
                                              </label>
                                              <div class="b9b84f4305">
                                                <div class="e000754250">
                                                  <input
                                                    class="eb46370fe1"
                                                    placeholder="Start typing your address"
                                                    value={streetname1}
                                                    onChange={(e) =>
                                                      setstreetname1(
                                                        e.target.value.replace(
                                                          /,/g,
                                                          ""
                                                        )
                                                      )
                                                    }
                                                  />
                                                  <div class="e7e5251f68"></div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </form>
                                        <div class="a53cbfa6de ac9267e216 form-group a20293ec70">
                                          <label
                                            for=":r1u:"
                                            class="a53cbfa6de e6208ee469 f555271986"
                                          >
                                            <span>
                                              <span class="ccb65902b2">
                                                City
                                              </span>
                                            </span>
                                          </label>
                                          <div class="b9b84f4305">
                                            <div class="e000754250">
                                              <input
                                                name="city_name"
                                                class="eb46370fe1"
                                                value={city1}
                                                onChange={(e) =>
                                                  setcity1(e.target.value)
                                                }
                                              />
                                              <div class="e7e5251f68"></div>
                                            </div>
                                          </div>
                                        </div>
                                        {/* <div class="spacing--6x">
                            <button
                              data-testid="add_apartment_or_floor_link"
                              type="button"
                              class="a83ed08757 f88a5204c2 b98133fb50"
                            >
                              <span>
                                Add apartment or floor number (optional)
                              </span>
                            </button>
                          </div> */}
                                        <div class="a53cbfa6de ac9267e216 form-group a20293ec70">
                                          <label
                                            for=":r24:"
                                            class="a53cbfa6de e6208ee469 f555271986"
                                          >
                                            <span>
                                              <span class="ccb65902b2">
                                                State
                                                {/* <button data-testid="remove_apartment_or_floor_link" type="button" class="a83ed08757 f88a5204c2 b98133fb50"><span>Remove</span></button> */}
                                              </span>
                                            </span>
                                          </label>
                                          <div class="b9b84f4305">
                                            <div class="e000754250">
                                              <input
                                                name="address_supplement"
                                                class="eb46370fe1"
                                                placeholder="State"
                                                value={states1}
                                                onChange={(e) =>
                                                  setstates1(e.target.value)
                                                }
                                              />
                                              <div class="e7e5251f68"></div>
                                            </div>
                                          </div>
                                        </div>
                                        <div class="a53cbfa6de ac9267e216 form-group manual-address-form__postcode a20293ec70">
                                          <label
                                            for=":r1t:"
                                            class="a53cbfa6de e6208ee469 f555271986"
                                          >
                                            <span>
                                              <span class="ccb65902b2">
                                                Pin Code
                                              </span>
                                            </span>
                                          </label>
                                          <div class="b9b84f4305">
                                            <div class="e000754250">
                                              <input
                                                name="zipcode"
                                                class="eb46370fe1"
                                                value={pincode1}
                                                onChange={(e) =>
                                                  setpincode1(e.target.value)
                                                }
                                              />
                                              <div class="e7e5251f68"></div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    ) : (
                                      ""
                                    )}
                                  </fieldset>
                                  <div className="spacing--6x" />
                                </div>
                              </div>
                            </div>
                          </div>
                        </>
                      ) : (
                        <></>
                      )}

                      <Box
                        sx={{ display: "flex", flexDirection: "row", pt: 2 }}
                      >
                        <Button
                          style={{
                            background: "green",
                            color: "#fff",
                            display: activeStep === 0 ? "none" : "block",
                          }}
                          disabled={activeStep === 0}
                          onClick={handleBack}
                          sx={{ mr: 1 }}
                        >
                          Back
                        </Button>
                        <Box sx={{ flex: "1 1 auto" }} />
                        {/* <Button
                          style={{ background: "#ffc107", color: "#000" }}
                          onClick={handleNext}
                          sx={{ mr: 1 }}
                        >
                          Next
                        </Button> */}

                        <button
                          className="pink-btn"
                          style={{
                            background: "green",
                            color: "#fff",
                            padding: 10,
                            marginRight: "30px",
                            display: activeStep === 12 ? "none" : "block",
                          }}
                          onClick={handleComplete}
                        >
                          Next
                        </button>

                        <button
                          className="pink-btn"
                          style={{
                            background: "green",
                            color: "#fff",
                            padding: 10,
                          }}
                          onClick={updateAlternative}
                        >
                          Submit
                        </button>
                      </Box>
                    </React.Fragment>
                  )}
                </div>
              </Box>
            </div>
          </Container>
        </Modal.Body>
        {/* <Modal.Footer>
            <Button variant="warning" onClick={handleClose1}>
              Close
            </Button>
            <Button variant="danger" onClick={handleClose1}>
              Save
            </Button>
          </Modal.Footer> */}
      </Modal>
      <Modal show={show2} onHide={handleClose2} size="lg">
        <Modal.Header closeButton>
          <Modal.Title className="text-danger">Alternative Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div
            style={{
              marginTop: "30px",
              marginBottom: "20px",
              fontWeight: "900",
              fontSize: "18px",
              color: "white",
              border: "2px solid #B8BCC7",
              backgroundColor: "#B8BCC7",
              width: "100%",
              boxSizing: "border-box",
              padding: "10px",
            }}
          >
            Photos
          </div>
          <div
            style={{
              overflowY: "scroll",
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap",
              margin: "20px",
              // justifyContent: "space-between",
            }}
          >
            {FullView.Photos?.map((media) => (
              <div
                key={media._id}
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-around",
                }}
              >
                {media.file.endsWith(".jpg") ||
                media.file.endsWith(".webp") ||
                media.file.endsWith(".jpeg") ||
                media.file.endsWith(".png") ? (
                  <img
                    src={"https://bookingoda.com/image/" + media.file}
                    alt="image"
                    style={{
                      width: "80px",
                      height: "80px",
                      marginRight: "20px",
                      marginBottom: "20px",
                    }}
                    className={`image-item ${
                      selectedImages.includes(media._id) ? "selected" : ""
                    }`}
                    onClick={() => toggleImageSelection(media._id)}
                  />
                ) : media.file.endsWith(".mp4") ? (
                  <video
                    src={"https://bookingoda.com/image/" + media.file}
                    alt="video"
                    style={{ width: "100px", height: "100px" }}
                    controls
                    className={`image-item ${
                      selectedImages.includes(media._id) ? "selected" : ""
                    }`}
                    onClick={() => toggleImageSelection(media._id)}
                  />
                ) : null}
                {/* <MdDelete
                  onClick={() => removeHotel(FullView._id, media._id)}
                  style={{ color: "red" }}
                /> */}
              </div>
            ))}
          </div>
          {selectedImages.length > 0 ? (
            <button
              onClick={deleteSelectedImages}
              style={{
                padding: "10px 20px",
                backgroundColor: "red",
                color: "white",
                border: "none",
                borderRadius: "5px",
                cursor: "pointer",
                fontSize: "16px",
                fontWeight: "bold",
              }}
            >
              Delete {selectedImages.length} Selected Image
              {selectedImages.length !== 1 ? "s" : ""}
            </button>
          ) : (
            ""
          )}
          <div
            style={{
              marginTop: "30px",
              marginBottom: "20px",
              fontWeight: "900",
              fontSize: "18px",
              color: "white",
              border: "2px solid #B8BCC7",
              backgroundColor: "#B8BCC7",
              width: "100%",
              boxSizing: "border-box",
              padding: "10px",
            }}
          >
            Alternative Type
          </div>
          <div style={{ fontSize: "18px", marginLeft: "10px" }}>
            {FullView.AlternativeType}
          </div>

          <div
            style={{
              marginTop: "30px",
              marginBottom: "20px",
              fontWeight: "900",
              fontSize: "18px",
              color: "white",
              border: "2px solid #B8BCC7",
              backgroundColor: "#B8BCC7",
              width: "100%",
              boxSizing: "border-box",
              padding: "10px",
            }}
          >
            Number of Property
          </div>
          <div style={{ fontSize: "18px", marginLeft: "10px" }}>
            {FullView.NumberManyAlternativeType !== "null" &&
            FullView.NumberManyAlternativeType !== null &&
            FullView.NumberManyAlternativeType !== ""
              ? FullView.NumberManyAlternativeType
              : 0}
          </div>
          <div
            style={{
              marginTop: "30px",
              marginBottom: "20px",
              fontWeight: "900",
              fontSize: "18px",
              color: "white",
              border: "2px solid #B8BCC7",
              backgroundColor: "#B8BCC7",
              width: "100%",
              boxSizing: "border-box",
              padding: "10px",
            }}
          >
            Address
          </div>
          <div style={{ fontSize: "18px", marginLeft: "10px" }}>
            {FullView.address}
          </div>
          <div
            style={{
              marginTop: "30px",
              marginBottom: "20px",
              fontWeight: "900",
              fontSize: "18px",
              color: "white",
              border: "2px solid #B8BCC7",
              backgroundColor: "#B8BCC7",
              width: "100%",
              boxSizing: "border-box",
              padding: "10px",
            }}
          >
            Location services
          </div>
          <div style={{ fontSize: "18px", marginLeft: "10px" }}>
            {FullView.Locationservices?.map((obj) => obj.label).join(", ")}
          </div>
          <div
            style={{
              marginTop: "30px",
              marginBottom: "20px",
              fontWeight: "900",
              fontSize: "18px",
              color: "white",
              border: "2px solid #B8BCC7",
              backgroundColor: "#B8BCC7",
              width: "100%",
              boxSizing: "border-box",
              padding: "10px",
            }}
          >
            Bedroom
          </div>
          <div style={{ fontSize: "18px", marginLeft: "10px" }}>
            <div>
              {(FullView.Singlebed !== 0 && FullView.Singlebed !== undefined
                ? `Single Bed- ${FullView.Singlebed}, `
                : "") +
                (FullView.Doublebed !== 0 && FullView.Doublebed !== undefined
                  ? `Double Bed- ${FullView.Doublebed}, `
                  : "") +
                (FullView.Largebed !== 0 && FullView.Largebed !== undefined
                  ? `Large Bed- ${FullView.Largebed}, `
                  : "") +
                (FullView.XLargebed !== 0 && FullView.XLargebed !== undefined
                  ? `ExtraLarge Double Bed(Super King Size)- ${FullView.XLargebed}, `
                  : "")}

              {FullView?.Roombeds?.length > 0
                ? FullView.Roombeds.filter(
                    (data) => data.quantity !== 0 && data.quantity !== undefined
                  )
                    .map((data) => `${data.name}: ${data.quantity}`)
                    .join(", ")
                : ""}
            </div>
            <div
              style={{
                marginTop: "30px",
                marginBottom: "20px",
                fontWeight: "900",
                fontSize: "18px",
                color: "white",
                border: "2px solid #B8BCC7",
                backgroundColor: "#B8BCC7",
                width: "100%",
                boxSizing: "border-box",
                padding: "10px",
              }}
            >
              Living Room
            </div>
            <div style={{ fontSize: "18px", marginLeft: "10px" }}>
              {(FullView.Sofabed !== 0 && FullView.Sofabed !== undefined
                ? `Sofa Bed: ${FullView.Sofabed}`
                : "") +
                (FullView.LRoombeds?.length > 0
                  ? FullView.LRoombeds.map(
                      (bed) => `${bed.name}: ${bed.quantity}`
                    ).join(", ")
                  : "")}
            </div>
            <div
              style={{
                marginTop: "30px",
                marginBottom: "20px",
                fontWeight: "900",
                fontSize: "18px",
                color: "white",
                border: "2px solid #B8BCC7",
                backgroundColor: "#B8BCC7",
                width: "100%",
                boxSizing: "border-box",
                padding: "10px",
              }}
            >
              Number Of Guest can Stay in Alternative
            </div>
            <div style={{ fontSize: "18px", marginLeft: "10px" }}>
              {FullView.QuestStayAlternative}
            </div>
            <div
              style={{
                marginTop: "30px",
                marginBottom: "20px",
                fontWeight: "900",
                fontSize: "18px",
                color: "white",
                border: "2px solid #B8BCC7",
                backgroundColor: "#B8BCC7",
                width: "100%",
                boxSizing: "border-box",
                padding: "10px",
              }}
            >
              Number Of Bathrooms
            </div>
            <div style={{ fontSize: "18px", marginLeft: "10px" }}>
              {FullView.BathroomsAlternativeStay}
            </div>
            <div
              style={{
                marginTop: "30px",
                marginBottom: "20px",
                fontWeight: "900",
                fontSize: "18px",
                color: "white",
                border: "2px solid #B8BCC7",
                backgroundColor: "#B8BCC7",
                width: "100%",
                boxSizing: "border-box",
                padding: "10px",
              }}
            >
              Alternative Size
            </div>
            <div style={{ fontSize: "18px", marginLeft: "10px" }}>
              {FullView.AlternativeStaySize}
              {"  "}
              {FullView.AlternativeStayMeasure}
            </div>
            <div
              style={{
                marginTop: "30px",
                marginBottom: "20px",
                fontWeight: "900",
                fontSize: "18px",
                color: "white",
                border: "2px solid #B8BCC7",
                backgroundColor: "#B8BCC7",
                width: "100%",
                boxSizing: "border-box",
                padding: "10px",
              }}
            >
              Alternative Use
            </div>
            <div style={{ fontSize: "18px", marginLeft: "10px" }}>
              {!FullView?.AlternativeUse ||
              Object.keys(FullView.AlternativeUse).length === 0 ? (
                <div>
                  <span>No Alternative Use selected</span>
                </div>
              ) : (
                <ul>
                  {Object.entries(FullView.AlternativeUse)
                    .filter(([key, value]) => value === true)
                    .map(([key], index) => (
                      <li key={index}>
                        {index + 1}){key}
                      </li>
                    ))}
                </ul>
              )}
            </div>
            <div
              style={{
                marginTop: "30px",
                marginBottom: "20px",
                fontWeight: "900",
                fontSize: "18px",
                color: "white",
                border: "2px solid #B8BCC7",
                backgroundColor: "#B8BCC7",
                width: "100%",
                boxSizing: "border-box",
                padding: "10px",
              }}
            >
              Breakfast
            </div>
            <div style={{ fontSize: "18px", marginLeft: "10px" }}>
              {FullView.Breakfast === "No"
                ? "No Breakfast provided"
                : "Yes Breakfast provided"}
            </div>
            <div
              style={{
                marginTop: "30px",
                marginBottom: "20px",
                fontWeight: "900",
                fontSize: "18px",
                color: "white",
                border: "2px solid #B8BCC7",
                backgroundColor: "#B8BCC7",
                width: "100%",
                boxSizing: "border-box",
                padding: "10px",
              }}
            >
              Breakfast Included
            </div>
            <div style={{ fontSize: "18px", marginLeft: "10px" }}>
              {FullView.BreakfastIncluded}
            </div>
            {FullView.BreakfastIncluded === "No, it costs extra" ? (
              <>
                {" "}
                <div
                  style={{
                    marginTop: "30px",
                    marginBottom: "20px",
                    fontWeight: "900",
                    fontSize: "18px",
                    color: "white",
                    border: "2px solid #B8BCC7",
                    backgroundColor: "#B8BCC7",
                    width: "100%",
                    boxSizing: "border-box",
                    padding: "10px",
                  }}
                >
                  Breakfast Price
                </div>
                <div style={{ fontSize: "18px", marginLeft: "10px" }}>
                  Rs.{FullView.BreakfastPrice}
                </div>
              </>
            ) : (
              ""
            )}
            <div
              style={{
                marginTop: "30px",
                marginBottom: "20px",
                fontWeight: "900",
                fontSize: "18px",
                color: "white",
                border: "2px solid #B8BCC7",
                backgroundColor: "#B8BCC7",
                width: "100%",
                boxSizing: "border-box",
                padding: "10px",
              }}
            >
              Breakfast Offers
            </div>
            <div style={{ fontSize: "18px", marginLeft: "10px" }}>
              {!FullView?.BreakfastOffers ||
              Object.keys(FullView.BreakfastOffers).length === 0 ? (
                <div>
                  <span>No Breakfast selected</span>
                </div>
              ) : (
                <ul>
                  {Object.entries(FullView.BreakfastOffers)
                    .filter(([key, value]) => value === true)
                    .map(([key], index) => (
                      <li key={index}>
                        {index + 1}){key}
                      </li>
                    ))}
                </ul>
              )}
            </div>

            <div
              style={{
                marginTop: "30px",
                marginBottom: "20px",
                fontWeight: "900",
                fontSize: "18px",
                color: "white",
                border: "2px solid #B8BCC7",
                backgroundColor: "#B8BCC7",
                width: "100%",
                boxSizing: "border-box",
                padding: "10px",
              }}
            >
              Staffs Language
            </div>
            <div style={{ fontSize: "18px", marginLeft: "10px" }}>
              {!FullView?.Language ||
              Object.keys(FullView.Language).length === 0 ? (
                <div>
                  <span>No language selected</span>
                </div>
              ) : (
                <ul>
                  {Object.entries(FullView.Language)
                    .filter(([key, value]) => value === true)
                    .map(([key], index) => (
                      <li key={index}>
                        {index + 1}){key}
                      </li>
                    ))}
                </ul>
              )}
            </div>
            <div
              style={{
                marginTop: "30px",
                marginBottom: "20px",
                fontWeight: "900",
                fontSize: "18px",
                color: "white",
                border: "2px solid #B8BCC7",
                backgroundColor: "#B8BCC7",
                width: "100%",
                boxSizing: "border-box",
                padding: "10px",
              }}
            >
              House Rules
            </div>
            <div style={{ fontSize: "18px", marginLeft: "10px" }}>
              {FullView.Smoking && <div>{`Smoking: ${FullView.Smoking},`}</div>}
              {FullView.Pets && <div>{`Pets: ${FullView.Pets},`}</div>}
              {FullView.Parties && <div>{`Parties: ${FullView.Parties},`}</div>}
              {FullView.Children && (
                <div>{`Children: ${FullView.Children},`}</div>
              )}
              <div>{`Check-In: ${FullView.CheckinFrom}-${FullView.CheckinUntil}`}</div>
              <div>{`Check-Out: ${FullView.CheckOutFrom}-${FullView.CheckOutUntil}`}</div>
            </div>
            <div
              style={{
                marginTop: "30px",
                marginBottom: "20px",
                fontWeight: "900",
                fontSize: "18px",
                color: "white",
                border: "2px solid #B8BCC7",
                backgroundColor: "#B8BCC7",
                width: "100%",
                boxSizing: "border-box",
                padding: "10px",
              }}
            >
              Rate Plan
            </div>
            <div style={{ fontSize: "18px", marginLeft: "10px" }}>
              <div>
                <p>
                  Cancel their booking for free before:{" "}
                  {FullView.Cancellationdays}
                </p>
                <p>
                  Protection against accidental bookings:{" "}
                  {FullView.AccidentalBooking}
                </p>
                <p>Non-refundable rate plan: {FullView.DiscountRate}%</p>
                <p>Week Day rate plan: {FullView.Weekdayrate}%</p>
                <p>Weekend rate plan: {FullView.Weekendrate}%</p>
              </div>
            </div>
            <div
              style={{
                marginTop: "30px",
                marginBottom: "20px",
                fontWeight: "900",
                fontSize: "18px",
                color: "white",
                border: "2px solid #B8BCC7",
                backgroundColor: "#B8BCC7",
                width: "100%",
                boxSizing: "border-box",
                padding: "10px",
              }}
            >
              Discount
            </div>
            <div style={{ fontSize: "18px", marginLeft: "10px" }}>
              {!FullView.Discount ||
              Object.keys(FullView.Discount).length === 0 ? (
                <span>No Discount selected</span>
              ) : (
                <>
                  {(() => {
                    const discountArray = Object.entries(
                      FullView.Discount
                    )?.map(([key, value]) => ({
                      id: key,
                      proficiency: value,
                    }));

                    return (
                      <ul>
                        {discountArray?.map(({ id, proficiency }) => (
                          <li key={id}>
                            {id} : Guest, Discount: {proficiency}%
                          </li>
                        ))}
                      </ul>
                    );
                  })()}
                </>
              )}
            </div>

            <div
              style={{
                marginTop: "30px",
                marginBottom: "20px",
                fontWeight: "900",
                fontSize: "18px",
                color: "white",
                border: "2px solid #B8BCC7",
                backgroundColor: "#B8BCC7",
                width: "100%",
                boxSizing: "border-box",
                padding: "10px",
              }}
            >
              Room Details
            </div>
            <div style={{ fontSize: "18px", marginLeft: "10px" }}>
              <div>
                {Array.isArray(FullView?.RoomDetails) &&
                  FullView.RoomDetails.map((room, index) => (
                    <div key={index}>
                      {index + 1}){" "}
                      <MdDelete
                        onClick={() => removeRooms(FullView._id, room?._id)}
                        style={{ color: "red" }}
                      />
                      {[
                        <div key="roomsname">
                          Room Name: {room?.Roomname?.map((item) => item.label)}
                        </div>,
                        <div key="price">Room Price: {room?.RoomPrice}</div>,
                        <div key="rooms">
                          Room: {room?.RoomUnit?.map((item) => item.value)}
                        </div>,
                        <div key="roomType">
                          Number of room types: {room?.RoomType}
                        </div>,
                        room?.Twinbed !== null &&
                        room?.Twinbed !== undefined &&
                        room?.Twinbed !== "0" ? (
                          <div key="twinBed">Twin bed(s): {room?.Twinbed}</div>
                        ) : null,
                        room?.Fullbed !== null &&
                        room?.Fullbed !== undefined &&
                        room?.Fullbed !== "0" ? (
                          <div key="fullBed">Full bed(s): {room?.Fullbed}</div>
                        ) : null,
                        room?.Queenbed !== null &&
                        room?.Queenbed !== undefined &&
                        room?.Queenbed !== "0" ? (
                          <div key="queenBed">
                            Queen bed(s): {room?.Queenbed}
                          </div>
                        ) : null,
                        room?.Kingbed !== null &&
                        room?.Kingbed !== undefined &&
                        room?.Kingbed !== "0" ? (
                          <div key="kingBed">King bed(s): {room?.Kingbed}</div>
                        ) : null,
                        room?.beds?.length > 0
                          ? room?.beds.map((data, index) => (
                              <div key={`bed${index + 1}`}>
                                Beds(s): {data.name}:{data.quantity}
                              </div>
                            ))
                          : null,
                        <div key="guests">
                          Number of Guests can stay in this room:{" "}
                          {room?.RoomStay}
                        </div>,
                        room?.RoomSize !== "0" && (
                          <div key="roomSize">
                            Room size: {room?.RoomSize} {room?.RoomMeasure}
                          </div>
                        ),
                        <div key="smoking">
                          Is smoking allowed in this room: {room?.RoomSmoking}
                        </div>,
                        <div key="privateBathroom">
                          Private Bathroom:{" "}
                          {room?.PrivateBathroom !== ""
                            ? room?.PrivateBathroom
                            : "No"}
                        </div>,
                        <div key="bathroomItems">
                          Bathroom Items:
                          <ul>
                            {Object.entries(room?.BathroomItems)
                              .filter(([key, value]) => value === true)
                              .map(([bathroom], index) => (
                                <li key={index + 1}>
                                  {index + 1}) {bathroom}
                                </li>
                              ))}
                          </ul>
                        </div>,
                        <div key="General">
                          General:
                          <ul>
                            {Object.entries(room?.General)
                              .filter(([key, value]) => value === true)
                              .map(([general], index) => (
                                <li key={index + 1}>
                                  {index + 1}) {general}
                                </li>
                              ))}
                          </ul>
                        </div>,
                        <div key="Outdoors">
                          Outdoors:
                          <ul>
                            {Object.entries(room?.Outdoors)
                              .filter(([key, value]) => value === true)
                              .map(([outdoors], index) => (
                                <li key={index + 1}>
                                  {index + 1}) {outdoors}
                                </li>
                              ))}
                          </ul>
                        </div>,
                        <div key="Food">
                          Kitchen Accessibility:
                          <ul>
                            {Object.entries(room?.Food)
                              .filter(([key, value]) => value === true)
                              .map(([food], index) => (
                                <li key={index + 1}>
                                  {index + 1}) {food}
                                </li>
                              ))}
                          </ul>
                        </div>,
                      ]}
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-around",
                          flexWrap: "wrap",
                        }}
                      >
                        {room?.RoomPhotos.map((photo, photoIndex) => (
                          <img
                            key={photoIndex}
                            src={`https://bookingoda.com/image/${photo.name}`}
                            alt={`Room ${index + 1} Photo ${photoIndex + 1}`}
                            style={{
                              width: "80px",
                              height: "80px",
                              marginRight: "20px",
                            }}
                          />
                        ))}
                      </div>
                    </div>
                  ))}
              </div>
            </div>

            <div
              style={{
                marginTop: "30px",
                marginBottom: "20px",
                fontWeight: "900",
                fontSize: "18px",
                color: "white",
                border: "2px solid #B8BCC7",
                backgroundColor: "#B8BCC7",
                width: "100%",
                boxSizing: "border-box",
                padding: "10px",
              }}
            >
              Can you charge credit cards at your property
            </div>
            <div style={{ fontSize: "18px", marginLeft: "10px" }}>
              {FullView.CreditCharges}
            </div>
            <div
              style={{
                marginTop: "30px",
                marginBottom: "20px",
                fontWeight: "900",
                fontSize: "18px",
                color: "white",
                border: "2px solid #B8BCC7",
                backgroundColor: "#B8BCC7",
                width: "100%",
                boxSizing: "border-box",
                padding: "10px",
              }}
            >
              Invoice Details
            </div>
            <div style={{ fontSize: "18px", marginLeft: "10px" }}>
              {"Invoice Name: " +
                FullView.InvoiceName +
                (FullView.differentaddress?.some(
                  (value) => value !== "" && value !== null
                )
                  ? ", Different Address: " +
                    FullView.differentaddress.join(", ")
                  : "")}
            </div>
            <div
              style={{
                marginTop: "30px",
                marginBottom: "20px",
                fontWeight: "900",
                fontSize: "18px",
                color: "white",
                border: "2px solid #B8BCC7",
                backgroundColor: "#B8BCC7",
                width: "100%",
                boxSizing: "border-box",
                padding: "10px",
              }}
            >
              Price
            </div>
            <div style={{ fontSize: "18px", marginLeft: "10px" }}>
              Rs.{FullView.Price}
            </div>
            <div
              style={{
                marginTop: "30px",
                marginBottom: "20px",
                fontWeight: "900",
                fontSize: "18px",
                color: "white",
                border: "2px solid #B8BCC7",
                backgroundColor: "#B8BCC7",
                width: "100%",
                boxSizing: "border-box",
                padding: "10px",
              }}
            >
              Price Commission
            </div>
            <div style={{ fontSize: "18px", marginLeft: "10px" }}>
              {FullView.PriceCommision}%
            </div>
            <div
              style={{
                marginTop: "30px",
                marginBottom: "20px",
                fontWeight: "900",
                fontSize: "18px",
                color: "white",
                border: "2px solid #B8BCC7",
                backgroundColor: "#B8BCC7",
                width: "100%",
                boxSizing: "border-box",
                padding: "10px",
              }}
            >
              Offerprice
            </div>
            <div style={{ fontSize: "18px", marginLeft: "10px" }}>
              {FullView.Offerprice}
            </div>
            <div
              style={{
                marginTop: "30px",
                marginBottom: "20px",
                fontWeight: "900",
                fontSize: "18px",
                color: "white",
                border: "2px solid #B8BCC7",
                backgroundColor: "#B8BCC7",
                width: "100%",
                boxSizing: "border-box",
                padding: "10px",
              }}
            >
              Tax
            </div>
            <div style={{ fontSize: "18px", marginLeft: "10px" }}>
              {FullView.tax}
            </div>
          </div>
        </Modal.Body>
        {/* <Modal.Footer>
            <Button variant="warning" onClick={handleClose2}>
              Close
            </Button>
            <Button variant="danger" onClick={handleClose2}>
              Save
            </Button>
          </Modal.Footer> */}
      </Modal>
      <Modal
        show={showR}
        onHide={handleCloseR}
        dialogClassName="custom-modal"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title className="text-danger">Add Room Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Container fluid>
            {/* <div className="d-flex justify-content-between align-items-center pt-4 pb-2">
              <p className="text-success fs-4 fw-semibold">
                Apartment List Edit:
              </p>
            </div> */}

            <div className="Stepper-info" style={{ padding: "20px" }}>
              <Box sx={{ width: "100%" }}>
                <Stepper
                  nonLinear
                  activeStep={activeStepR}
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    flexWrap: "wrap",
                    marginBottom: "40px",
                  }}
                >
                  {stepsR?.map((label, index) => (
                    <Step
                      key={label}
                      completed={completedR[index]}
                      style={{ margin: "30px" }}
                    >
                      <StepButton color="inherit" onClick={handleStepR(index)}>
                        {label}
                      </StepButton>
                    </Step>
                  ))}
                </Stepper>
                <div>
                  {allStepsCompletedR() ? (
                    <React.Fragment>
                      <Typography
                        sx={{ mt: 2, mb: 1 }}
                        style={{
                          position: "absolute",
                          top: "50%",
                          left: "50%",
                          fontSize: "20px",
                          fontWeight: "500",
                        }}
                      >
                        All steps completed successfully
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            alignSelf: "center",
                            pt: 2,
                          }}
                        >
                          <Button onClick={handleResetR}>Back</Button>
                        </Box>
                      </Typography>
                    </React.Fragment>
                  ) : (
                    <React.Fragment>
                      {activeStepR == 0 ? (
                        <>
                          <div className="form-container">
                            <div className="f2cf178bcd screen-main-header d991e1216a">
                              <div className="f6431b446c e6208ee469 d0caee4251 screen-main-header__title">
                                Room details
                              </div>
                            </div>
                            <div
                              data-testid="MupRoomFlowLikeContainer"
                              className="ffb9c3d6a3 wide-container second-container"
                            >
                              <div className="e1eebb6a1e spacing--4x question-title">
                                What type of unit is this?
                              </div>
                              <div className="a53cbfa6de ac9267e216">
                                <div className="e000754250">
                                  <input
                                    type="text"
                                    value={newOptionRoom}
                                    onChange={(e) =>
                                      setNewOptionRoom(e.target.value)
                                    }
                                    placeholder="Type to add or search..."
                                    class="eb46370fe1"
                                    onClick={() =>
                                      setShowOptionsRoom(!showOptionsRoom)
                                    }
                                  />
                                </div>
                                <button
                                  style={{
                                    marginTop: "10px",
                                    padding: "10px 20px",
                                    fontSize: "16px",
                                    backgroundColor: "#70bbe9",
                                    color: "white",
                                    border: "none",
                                    borderRadius: "5px",
                                    cursor: "pointer",
                                    transition: "background-color 0.3s",
                                  }}
                                  onClick={handleAddOptionRoom}
                                >
                                  Add
                                </button>
                                {showOptionsRoom && (
                                  <div className="ebf4591c8e">
                                    {" "}
                                    {optionsRoom?.map((option) => (
                                      <div className="cabad3c686 aff44befa2">
                                        {/* <select
                              className="ebf4591c8e"
                              name="room_type"
                              data-testid="InputRadioWithOptions-inputSelect-room_type"
                              id=":r63:"
                              // value={unit}
                              // onChange={(e) => setunit(e.target.value)}
                              onClick={() => handleOptionClick(option)}
                            > */}
                                        <div
                                          key={option.id}
                                          style={{
                                            padding: "10px",
                                            cursor: "pointer",
                                            color: "black",
                                            backgroundColor:
                                              selectedOptionsRoom?.some(
                                                (selectedOption) =>
                                                  selectedOption.id ===
                                                  option.id
                                              )
                                                ? "#ddd"
                                                : "transparent",
                                          }}
                                          onClick={() =>
                                            handleOptionClickRoom(option)
                                          }
                                        >
                                          <option
                                            value="Bed in Dorm"
                                            data-key={26}
                                          >
                                            {option.label}
                                          </option>
                                        </div>
                                      </div>
                                    ))}
                                  </div>
                                )}
                                {selectedOptionsRoom && (
                                  <div className="selected-option">
                                    Selected units:{" "}
                                    {selectedOptionsRoom
                                      .map((option) => option.label)
                                      .join(", ")}
                                  </div>
                                )}
                              </div>
                              <div className="spacing--6x" />
                              <label
                                className="spacing--4x bedroom-label"
                                htmlFor="automation_id_num_units_with_exact_layout"
                              >
                                <div className="e1eebb6a1e">
                                  How many rooms of this type do you have?
                                </div>
                              </label>
                              <div className="num-unit-container">
                                <div className="a53cbfa6de ac9267e216 num-unit a20293ec70">
                                  <div className="b9b84f4305">
                                    <div className="e000754250">
                                      <input
                                        type="number"
                                        onKeyDown={handleKeyDown}
                                        name="num_units_with_exact_layout"
                                        className="eb46370fe1"
                                        id="automation_id_num_units_with_exact_layout"
                                        value={type}
                                        onChange={(e) =>
                                          settype(e.target.value)
                                        }
                                      />
                                      <div className="e7e5251f68" />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="ffb9c3d6a31 wide-container second-container">
                              <div className="aca0ade214 ebac6e22e9 f66f916626 c2931f4182 c27e5d305d db150fece4 spacing--6x">
                                <div className="b817090550 b736e9e3f4">
                                  <div className="e1eebb6a1e">
                                    What beds are available in this room?
                                  </div>
                                </div>
                              </div>
                              <div className="aca0ade214 ebac6e22e9 f66f916626 c2931f4182 c27e5d305d db150fece4 spacing--6x addRoom">
                                <div className="roomDesc b817090550 e0ad3ea0c7">
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 96 96"
                                  >
                                    <path d="M78.25 48h-60.5C16.1 48 15 49.1 15 50.77v30.46C15 82.9 16.1 84 17.75 84s2.75-1.1 2.75-2.77V70.15h55v11.08c0 1.66 1.1 2.77 2.75 2.77S81 82.9 81 81.23V50.77C81 49.1 79.9 48 78.25 48zm-22-27.7h-27.5v-5.53c0-1.66-1.1-2.77-2.75-2.77s-2.75 1.1-2.75 2.77v27.7h33V20.3z" />
                                    <path d="M72.75 23.08v-8.3c0-1.67-1.1-2.78-2.75-2.78s-2.75 1.1-2.75 2.77v5.54h-27.5v22.16h33V23.08z" />
                                  </svg>
                                  <div className="f2cf178bcd">
                                    <div className="a3332d346a e6208ee469 d0caee4251 bed-type--not-highlighted">
                                      Twin bed(s)
                                    </div>
                                    <div className="abf093bdfe f45d8e4c32 df64fda51b">
                                      35–51 inches wide
                                    </div>
                                  </div>
                                </div>
                                <div className="b817090550 a7cf1a6b1d">
                                  <div
                                    id="automation_id_add_bedroom_single_bed_input"
                                    data-testid="automation_id_add_bedroom_single_bed_input"
                                    className="a7a72174b8"
                                  >
                                    <div className="bfb38641b0">
                                      <button
                                        tabIndex={-1}
                                        disabled={twinbed < 1}
                                        aria-hidden="true"
                                        type="button"
                                        onClick={decrementtwinbed}
                                        className="a83ed08757 c21c56c305 f38b6daa18 d691166b09 ab98298258 deab83296e e91c91fa93"
                                      >
                                        <span className="eedba9e88a">
                                          <span
                                            className="fcd9eec8fb bf9a32efa5"
                                            aria-hidden="true"
                                          >
                                            <svg
                                              xmlns="http://www.w3.org/2000/svg"
                                              viewBox="0 0 24 24"
                                            >
                                              <path d="M20.25 12.75H3.75a.75.75 0 0 1 0-1.5h16.5a.75.75 0 0 1 0 1.5z" />
                                            </svg>
                                          </span>
                                        </span>
                                      </button>
                                      <span
                                        className="d723d73d5f"
                                        aria-hidden="true"
                                      >
                                        {twinbed}
                                      </span>
                                      <button
                                        tabIndex={-1}
                                        aria-hidden="true"
                                        type="button"
                                        onClick={increaseRangetwinbed}
                                        className="a83ed08757 c21c56c305 f38b6daa18 d691166b09 ab98298258 deab83296e bb803d8689 f4d78af12a"
                                      >
                                        <span className="eedba9e88a">
                                          <span
                                            className="fcd9eec8fb bf9a32efa5"
                                            aria-hidden="true"
                                          >
                                            <svg
                                              xmlns="http://www.w3.org/2000/svg"
                                              viewBox="0 0 24 24"
                                            >
                                              <path d="M20.25 11.25h-7.5v-7.5a.75.75 0 0 0-1.5 0v7.5h-7.5a.75.75 0 0 0 0 1.5h7.5v7.5a.75.75 0 0 0 1.5 0v-7.5h7.5a.75.75 0 0 0 0-1.5z" />
                                            </svg>
                                          </span>
                                        </span>
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="aca0ade214 ebac6e22e9 f66f916626 c2931f4182 c27e5d305d db150fece4 spacing--6x addRoom">
                                <div className="roomDesc b817090550 e0ad3ea0c7">
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 96 96"
                                  >
                                    <path d="M89.25 48H6.75C5.1 48 4 49.1 4 50.77v30.46C4 82.9 5.1 84 6.75 84s2.75-1.1 2.75-2.77V70.15h77v11.08c0 1.66 1.1 2.77 2.75 2.77S92 82.9 92 81.23V50.77C92 49.1 90.9 48 89.25 48zm-44-27.7h-27.5v-5.53c0-1.66-1.1-2.77-2.75-2.77s-2.75 1.1-2.75 2.77v27.7h33V20.3zm38.5 2.78v-8.3c0-1.67-1.1-2.78-2.75-2.78s-2.75 1.1-2.75 2.77v5.54h-27.5v22.16h33V23.08z" />
                                  </svg>
                                  <div className="f2cf178bcd">
                                    <div className="a3332d346a e6208ee469 d0caee4251">
                                      Full bed(s)
                                    </div>
                                    <div className="abf093bdfe f45d8e4c32 df64fda51b">
                                      52–59 inches wide
                                    </div>
                                  </div>
                                </div>
                                <div className="b817090550 a7cf1a6b1d">
                                  <div
                                    id="automation_id_add_bedroom_double_bed_input"
                                    data-testid="automation_id_add_bedroom_double_bed_input"
                                    className="a7a72174b8"
                                  >
                                    <div className="bfb38641b0">
                                      <button
                                        tabIndex={-1}
                                        disabled={fullbed < 1}
                                        aria-hidden="true"
                                        type="button"
                                        className="a83ed08757 c21c56c305 f38b6daa18 d691166b09 ab98298258 deab83296e bb803d8689 e91c91fa93"
                                        onClick={decrementfullbed}
                                      >
                                        <span className="eedba9e88a">
                                          <span
                                            className="fcd9eec8fb bf9a32efa5"
                                            aria-hidden="true"
                                          >
                                            <svg
                                              xmlns="http://www.w3.org/2000/svg"
                                              viewBox="0 0 24 24"
                                            >
                                              <path d="M20.25 12.75H3.75a.75.75 0 0 1 0-1.5h16.5a.75.75 0 0 1 0 1.5z" />
                                            </svg>
                                          </span>
                                        </span>
                                      </button>
                                      <span
                                        className="d723d73d5f"
                                        aria-hidden="true"
                                      >
                                        {fullbed}
                                      </span>
                                      <button
                                        tabIndex={-1}
                                        aria-hidden="true"
                                        type="button"
                                        onClick={increaseRangefullbed}
                                        className="a83ed08757 c21c56c305 f38b6daa18 d691166b09 ab98298258 deab83296e bb803d8689 f4d78af12a"
                                      >
                                        <span className="eedba9e88a">
                                          <span
                                            className="fcd9eec8fb bf9a32efa5"
                                            aria-hidden="true"
                                          >
                                            <svg
                                              xmlns="http://www.w3.org/2000/svg"
                                              viewBox="0 0 24 24"
                                            >
                                              <path d="M20.25 11.25h-7.5v-7.5a.75.75 0 0 0-1.5 0v7.5h-7.5a.75.75 0 0 0 0 1.5h7.5v7.5a.75.75 0 0 0 1.5 0v-7.5h7.5a.75.75 0 0 0 0-1.5z" />
                                            </svg>
                                          </span>
                                        </span>
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="aca0ade214 ebac6e22e9 f66f916626 c2931f4182 c27e5d305d db150fece4 spacing--6x addRoom">
                                <div className="roomDesc b817090550 e0ad3ea0c7">
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 96 96"
                                  >
                                    <path d="M89.25 48H6.75C5.1 48 4 49.1 4 50.77v30.46C4 82.9 5.1 84 6.75 84s2.75-1.1 2.75-2.77V70.15h77v11.08c0 1.66 1.1 2.77 2.75 2.77S92 82.9 92 81.23V50.77C92 49.1 90.9 48 89.25 48zm-44-27.7h-27.5v-5.53c0-1.66-1.1-2.77-2.75-2.77s-2.75 1.1-2.75 2.77v27.7h33V20.3zm38.5 2.78v-8.3c0-1.67-1.1-2.78-2.75-2.78s-2.75 1.1-2.75 2.77v5.54h-27.5v22.16h33V23.08z" />
                                  </svg>
                                  <div className="f2cf178bcd">
                                    <div className="a3332d346a e6208ee469 d0caee4251 bed-type--not-highlighted">
                                      Queen bed(s)
                                    </div>
                                    <div className="abf093bdfe f45d8e4c32 df64fda51b">
                                      60–70 inches wide
                                    </div>
                                  </div>
                                </div>
                                <div className="b817090550 a7cf1a6b1d">
                                  <div
                                    id="automation_id_add_bedroom_large_double_bed_input"
                                    data-testid="automation_id_add_bedroom_large_double_bed_input"
                                    className="a7a72174b8"
                                  >
                                    <div className="bfb38641b0">
                                      <button
                                        tabIndex={-1}
                                        disabled={queenbed < 1}
                                        aria-hidden="true"
                                        type="button"
                                        onClick={decrementqueenbed}
                                        className="a83ed08757 c21c56c305 f38b6daa18 d691166b09 ab98298258 deab83296e e91c91fa93"
                                      >
                                        <span className="eedba9e88a">
                                          <span
                                            className="fcd9eec8fb bf9a32efa5"
                                            aria-hidden="true"
                                          >
                                            <svg
                                              xmlns="http://www.w3.org/2000/svg"
                                              viewBox="0 0 24 24"
                                            >
                                              <path d="M20.25 12.75H3.75a.75.75 0 0 1 0-1.5h16.5a.75.75 0 0 1 0 1.5z" />
                                            </svg>
                                          </span>
                                        </span>
                                      </button>
                                      <span
                                        className="d723d73d5f"
                                        aria-hidden="true"
                                      >
                                        {queenbed}
                                      </span>
                                      <button
                                        tabIndex={-1}
                                        aria-hidden="true"
                                        type="button"
                                        onClick={increaseRangequeenbed}
                                        className="a83ed08757 c21c56c305 f38b6daa18 d691166b09 ab98298258 deab83296e bb803d8689 f4d78af12a"
                                      >
                                        <span className="eedba9e88a">
                                          <span
                                            className="fcd9eec8fb bf9a32efa5"
                                            aria-hidden="true"
                                          >
                                            <svg
                                              xmlns="http://www.w3.org/2000/svg"
                                              viewBox="0 0 24 24"
                                            >
                                              <path d="M20.25 11.25h-7.5v-7.5a.75.75 0 0 0-1.5 0v7.5h-7.5a.75.75 0 0 0 0 1.5h7.5v7.5a.75.75 0 0 0 1.5 0v-7.5h7.5a.75.75 0 0 0 0-1.5z" />
                                            </svg>
                                          </span>
                                        </span>
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="aca0ade214 ebac6e22e9 f66f916626 c2931f4182 c27e5d305d db150fece4 spacing--6x addRoom">
                                <div className="roomDesc b817090550 e0ad3ea0c7">
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 96 96"
                                  >
                                    <path d="M89.25 48H6.75C5.1 48 4 49.1 4 50.77v30.46C4 82.9 5.1 84 6.75 84s2.75-1.1 2.75-2.77V70.15h77v11.08c0 1.66 1.1 2.77 2.75 2.77S92 82.9 92 81.23V50.77C92 49.1 90.9 48 89.25 48zm-44-27.7h-27.5v-5.53c0-1.66-1.1-2.77-2.75-2.77s-2.75 1.1-2.75 2.77v27.7h33V20.3zm38.5 2.78v-8.3c0-1.67-1.1-2.78-2.75-2.78s-2.75 1.1-2.75 2.77v5.54h-27.5v22.16h33V23.08z" />
                                  </svg>
                                  <div className="f2cf178bcd">
                                    <div className="a3332d346a e6208ee469 d0caee4251 bed-type--not-highlighted">
                                      King bed(s)
                                    </div>
                                    <div className="abf093bdfe f45d8e4c32 df64fda51b">
                                      71–81 inches wide
                                    </div>
                                  </div>
                                </div>
                                <div className="b817090550 a7cf1a6b1d">
                                  <div
                                    id="automation_id_add_bedroom_extra_large_double_bed_input"
                                    data-testid="automation_id_add_bedroom_extra_large_double_bed_input"
                                    className="a7a72174b8"
                                  >
                                    <div className="bfb38641b0">
                                      <button
                                        tabIndex={-1}
                                        disabled={kingbed < 1}
                                        aria-hidden="true"
                                        type="button"
                                        onClick={decrementkingbed}
                                        className="a83ed08757 c21c56c305 f38b6daa18 d691166b09 ab98298258 deab83296e e91c91fa93"
                                      >
                                        <span className="eedba9e88a">
                                          <span
                                            className="fcd9eec8fb bf9a32efa5"
                                            aria-hidden="true"
                                          >
                                            <svg
                                              xmlns="http://www.w3.org/2000/svg"
                                              viewBox="0 0 24 24"
                                            >
                                              <path d="M20.25 12.75H3.75a.75.75 0 0 1 0-1.5h16.5a.75.75 0 0 1 0 1.5z" />
                                            </svg>
                                          </span>
                                        </span>
                                      </button>
                                      <span
                                        className="d723d73d5f"
                                        aria-hidden="true"
                                      >
                                        {kingbed}
                                      </span>
                                      <button
                                        tabIndex={-1}
                                        aria-hidden="true"
                                        type="button"
                                        onClick={increaseRangekingbed}
                                        className="a83ed08757 c21c56c305 f38b6daa18 d691166b09 ab98298258 deab83296e bb803d8689 f4d78af12a"
                                      >
                                        <span className="eedba9e88a">
                                          <span
                                            className="fcd9eec8fb bf9a32efa5"
                                            aria-hidden="true"
                                          >
                                            <svg
                                              xmlns="http://www.w3.org/2000/svg"
                                              viewBox="0 0 24 24"
                                            >
                                              <path d="M20.25 11.25h-7.5v-7.5a.75.75 0 0 0-1.5 0v7.5h-7.5a.75.75 0 0 0 0 1.5h7.5v7.5a.75.75 0 0 0 1.5 0v-7.5h7.5a.75.75 0 0 0 0-1.5z" />
                                            </svg>
                                          </span>
                                        </span>
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              {beds.map((bed, index) => (
                                <div
                                  key={index}
                                  className="aca0ade214 ebac6e22e9 f66f916626 c2931f4182 c27e5d305d db150fece4 spacing--6x addRoom"
                                >
                                  <div className="roomDesc b817090550 e0ad3ea0c7">
                                    <div className="f2cf178bcd">
                                      <div className="a3332d346a e6208ee469 d0caee4251 bed-type--not-highlighted">
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          viewBox="0 0 96 96"
                                        >
                                          <path d="M89.25 48H6.75C5.1 48 4 49.1 4 50.77v30.46C4 82.9 5.1 84 6.75 84s2.75-1.1 2.75-2.77V70.15h77v11.08c0 1.66 1.1 2.77 2.75 2.77S92 82.9 92 81.23V50.77C92 49.1 90.9 48 89.25 48zm-44-27.7h-27.5v-5.53c0-1.66-1.1-2.77-2.75-2.77s-2.75 1.1-2.75 2.77v27.7h33V20.3zm38.5 2.78v-8.3c0-1.67-1.1-2.78-2.75-2.78s-2.75 1.1-2.75 2.77v5.54h-27.5v22.16h33V23.08z" />
                                        </svg>
                                        <input
                                          type="text"
                                          value={bed.name}
                                          onChange={(e) =>
                                            updateBedName(index, e.target.value)
                                          }
                                        />
                                      </div>
                                      <div className="abf093bdfe f45d8e4c32 df64fda51b">
                                        {bed.width}
                                      </div>
                                    </div>
                                  </div>
                                  <div className="b817090550 a7cf1a6b1d">
                                    <div className="a7a72174b8">
                                      <div className="bfb38641b0">
                                        <button
                                          type="button"
                                          disabled={bed.quantity < 1}
                                          onClick={() =>
                                            updateBedQuantity(
                                              index,
                                              bed.quantity - 1
                                            )
                                          }
                                          className="a83ed08757 c21c56c305 f38b6daa18 d691166b09 ab98298258 deab83296e e91c91fa93"
                                        >
                                          <span className="eedba9e88a">
                                            <span
                                              className="fcd9eec8fb bf9a32efa5"
                                              aria-hidden="true"
                                            >
                                              <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                viewBox="0 0 24 24"
                                              >
                                                <path d="M20.25 12.75H3.75a.75.75 0 0 1 0-1.5h16.5a.75.75 0 0 1 0 1.5z" />
                                              </svg>
                                            </span>
                                          </span>
                                        </button>
                                        <span
                                          className="d723d73d5f"
                                          aria-hidden="true"
                                        >
                                          {bed.quantity}
                                        </span>
                                        <button
                                          type="button"
                                          onClick={() =>
                                            updateBedQuantity(
                                              index,
                                              bed.quantity + 1
                                            )
                                          }
                                          className="a83ed08757 c21c56c305 f38b6daa18 d691166b09 ab98298258 deab83296e bb803d8689 f4d78af12a"
                                        >
                                          <span className="eedba9e88a">
                                            <span
                                              className="fcd9eec8fb bf9a32efa5"
                                              aria-hidden="true"
                                            >
                                              <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                viewBox="0 0 24 24"
                                              >
                                                <path d="M20.25 11.25h-7.5v-7.5a.75.75 0 0 0-1.5 0v7.5h-7.5a.75.75 0 0 0 0 1.5h7.5v7.5a.75.75 0 0 0 1.5 0v-7.5h7.5a.75.75 0 0 0 0-1.5z" />
                                              </svg>
                                            </span>
                                          </span>
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                  <button
                                    style={{
                                      marginTop: "10px",
                                      padding: "5px 5px",
                                      fontSize: "16px",
                                      backgroundColor: "red",
                                      color: "white",
                                      border: "none",
                                      borderRadius: "5px",
                                      cursor: "pointer",
                                      transition: "background-color 0.3s",
                                    }}
                                    type="button"
                                    onClick={() => removeBed(index)}
                                  >
                                    Remove Bed
                                  </button>
                                </div>
                              ))}
                              <button
                                style={{
                                  marginTop: "10px",
                                  padding: "10px 20px",
                                  fontSize: "16px",
                                  backgroundColor: "#70bbe9",
                                  color: "white",
                                  border: "none",
                                  borderRadius: "5px",
                                  cursor: "pointer",
                                  transition: "background-color 0.3s",
                                }}
                                onClick={addBed}
                              >
                                Add Bed
                              </button>
                            </div>
                            <div className="ffb9c3d6a31 wide-container second-container">
                              <label
                                className="spacing--4x bedroom-label"
                                htmlFor="automation_id_bedroom_num_guests_input"
                              >
                                <div className="e1eebb6a1e">
                                  How many guests can stay in this room?
                                </div>
                              </label>
                              <div
                                data-testid="MupRoomFlowLikeContainer-num-guests-input-stepper"
                                className="a7a72174b8"
                              >
                                <input
                                  type="range"
                                  className="ebb9f563b4"
                                  id="automation_id_bedroom_num_guests_input"
                                  min={1}
                                  max={100}
                                  step={1}
                                  aria-valuemin={1}
                                  aria-valuemax={100}
                                  aria-valuenow={2}
                                  value={queststay}
                                  onChange={(e) => setqueststay(e.target.value)}
                                />
                                <div className="bfb38641b0">
                                  <button
                                    tabIndex={-1}
                                    disabled={queststay < 1}
                                    aria-hidden="true"
                                    type="button"
                                    onClick={decrementqueststay}
                                    className="a83ed08757 c21c56c305 f38b6daa18 d691166b09 ab98298258 deab83296e bb803d8689 e91c91fa93"
                                  >
                                    <span className="eedba9e88a">
                                      <span
                                        className="fcd9eec8fb bf9a32efa5"
                                        aria-hidden="true"
                                      >
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          viewBox="0 0 24 24"
                                        >
                                          <path d="M20.25 12.75H3.75a.75.75 0 0 1 0-1.5h16.5a.75.75 0 0 1 0 1.5z" />
                                        </svg>
                                      </span>
                                    </span>
                                  </button>
                                  <span
                                    className="d723d73d5f"
                                    aria-hidden="true"
                                  >
                                    {queststay}
                                  </span>
                                  <button
                                    tabIndex={-1}
                                    aria-hidden="true"
                                    type="button"
                                    onClick={increaseRangequeststay}
                                    className="a83ed08757 c21c56c305 f38b6daa18 d691166b09 ab98298258 deab83296e bb803d8689 f4d78af12a"
                                  >
                                    <span className="eedba9e88a">
                                      <span
                                        className="fcd9eec8fb bf9a32efa5"
                                        aria-hidden="true"
                                      >
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          viewBox="0 0 24 24"
                                        >
                                          <path d="M20.25 11.25h-7.5v-7.5a.75.75 0 0 0-1.5 0v7.5h-7.5a.75.75 0 0 0 0 1.5h7.5v7.5a.75.75 0 0 0 1.5 0v-7.5h7.5a.75.75 0 0 0 0-1.5z" />
                                        </svg>
                                      </span>
                                    </span>
                                  </button>
                                </div>
                              </div>
                            </div>
                            <div className="ffb9c3d6a3 wide-container second-container">
                              <div className="e1eebb6a1e spacing--4x">
                                How big is this room?
                              </div>
                              <div className="bedroom-room-size">
                                <div className="a53cbfa6de ac9267e216 bedroom-room-size__room-measurement a20293ec70">
                                  <label
                                    htmlFor=":r69:"
                                    className="a53cbfa6de e6208ee469 f555271986"
                                  >
                                    <span>
                                      <span className="ccb65902b2">
                                        Room size – optional
                                      </span>
                                    </span>
                                  </label>
                                  <div className="b9b84f4305">
                                    <div className="e000754250">
                                      <input
                                        type="number"
                                        onKeyDown={handleKeyDown}
                                        name="room_size"
                                        className="eb46370fe1"
                                        min={0}
                                        data-testid="bedroom_roomsize_select"
                                        id=":r69:"
                                        value={roomsize}
                                        onChange={(e) =>
                                          setroomsize(e.target.value)
                                        }
                                      />
                                      <div className="e7e5251f68" />
                                    </div>
                                  </div>
                                </div>
                                <div className="a53cbfa6de ac9267e216 bedroom-room-size__measurement-type">
                                  <div className="cabad3c686 aff44befa2">
                                    <select
                                      className="ebf4591c8e"
                                      name="measurement_type"
                                      data-testid="bedroom_roomsize_measurement_type_select"
                                      id=":r6a:"
                                      value={roommeasure}
                                      onChange={(e) =>
                                        setroommeasure(e.target.value)
                                      }
                                    >
                                      <option disabled value="">
                                        Unit
                                      </option>
                                      <option
                                        value="square meters"
                                        data-key={1}
                                      >
                                        square meters
                                      </option>
                                      <option value="square feet" data-key={0}>
                                        square feet
                                      </option>
                                    </select>
                                    <span className="ad2c299542">
                                      <span
                                        className="fcd9eec8fb cab1c196b5 c2cc050fb8 f64eb5d122"
                                        aria-hidden="true"
                                      >
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          viewBox="0 0 24 24"
                                        >
                                          <path d="M19.2681 8.91288C19.2687 9.03226 19.2454 9.15054 19.1996 9.26079C19.1539 9.37105 19.0865 9.47105 19.0016 9.55493L12.9445 15.612C12.8211 15.7367 12.6738 15.8353 12.5115 15.9018C12.3491 15.9684 12.1751 16.0017 11.9996 15.9997C11.6491 16.0075 11.3096 15.877 11.0547 15.6362L4.99764 9.57916C4.8275 9.40881 4.73193 9.17788 4.73193 8.93711C4.73193 8.69634 4.8275 8.46542 4.99764 8.29506C5.08117 8.20935 5.18101 8.14122 5.29128 8.09471C5.40155 8.04819 5.52002 8.02423 5.6397 8.02423C5.75937 8.02423 5.87784 8.04819 5.98811 8.09471C6.09838 8.14122 6.19822 8.20935 6.28175 8.29506L11.9996 13.9887L17.7175 8.27084C17.801 8.18512 17.9009 8.11699 18.0111 8.07048C18.1214 8.02396 18.2399 8 18.3596 8C18.4792 8 18.5977 8.02396 18.708 8.07048C18.8182 8.11699 18.9181 8.18512 19.0016 8.27084C19.0871 8.3543 19.1548 8.45422 19.2006 8.56457C19.2464 8.67492 19.2694 8.79341 19.2681 8.91288Z" />
                                        </svg>
                                      </span>
                                    </span>
                                  </div>
                                </div>
                              </div>
                              <div className="spacing--8x" />
                              <fieldset className="a53cbfa6de ac9267e216 smoking-allowed">
                                <legend className="a53cbfa6de e6208ee469 f555271986">
                                  <span>
                                    <span className="ccb65902b2">
                                      <div
                                        className="e1eebb6a1e spacing--4x"
                                        style={{ marginLeft: "20px" }}
                                      >
                                        Is smoking allowed in this room?
                                      </div>
                                    </span>
                                  </span>
                                </legend>
                                <div className="aca0ade214 aaf30230d9 c2931f4182">
                                  <div className="a53cbfa6de ac9267e216 ad8af0911d">
                                    <label
                                      htmlFor=":r6c:"
                                      className="aca0ade214 aaf30230d9 c2931f4182 d79e71457a f8208e9f83"
                                    >
                                      <input
                                        className="c907c67d20"
                                        type="radio"
                                        value="Yes"
                                        checked={roomsmoking === "Yes"}
                                        onChange={handleOptionChangeRoomsmoking}
                                      />
                                      <span className="aaee4e7cd3 e7a57abb1e">
                                        <div className="a53cbfa6de e6208ee469">
                                          Yes
                                        </div>
                                      </span>
                                    </label>
                                  </div>
                                  <div className="a53cbfa6de ac9267e216 ad8af0911d">
                                    <label
                                      htmlFor=":r6d:"
                                      className="aca0ade214 aaf30230d9 c2931f4182 d79e71457a f8208e9f83"
                                    >
                                      <input
                                        className="c907c67d20"
                                        type="radio"
                                        value="No"
                                        checked={roomsmoking === "No"}
                                        onChange={handleOptionChangeRoomsmoking}
                                      />
                                      <span className="aaee4e7cd3 e7a57abb1e">
                                        <div className="a53cbfa6de e6208ee469">
                                          No
                                        </div>
                                      </span>{" "}
                                    </label>
                                  </div>
                                </div>
                              </fieldset>
                            </div>
                          </div>
                        </>
                      ) : activeStepR == 1 ? (
                        <>
                          <div className="form-container">
                            <div className="f2cf178bcd screen-main-header d991e1216a">
                              <div className="f6431b446c e6208ee469 d0caee4251 screen-main-header__title">
                                Bathroom details
                              </div>
                            </div>
                            <div className="ffb9c3d6a3 wide-container min-height">
                              <fieldset className="a53cbfa6de ac9267e216 bathroom-privacy-question">
                                <legend className="a53cbfa6de e6208ee469 f555271986">
                                  <span>
                                    <span className="ccb65902b2">
                                      <div className="e1eebb6a1e spacing--4x">
                                        Is the bathroom private?
                                      </div>
                                    </span>
                                  </span>
                                </legend>
                                <div className="aca0ade214 aaf30230d9 cd2e7d62b0">
                                  <div className="a53cbfa6de ac9267e216 ad8af0911d">
                                    <label
                                      htmlFor=":r8p:"
                                      className="aca0ade214 aaf30230d9 c2931f4182 d79e71457a f8208e9f83"
                                    >
                                      <input
                                        className="c907c67d20"
                                        type="radio"
                                        value="Yes"
                                        checked={privtebathroom === "Yes"}
                                        onChange={
                                          handleOptionChangePrivatebathroom
                                        }
                                      />

                                      <span className="aaee4e7cd3 e7a57abb1e">
                                        <div className="a53cbfa6de e6208ee469">
                                          Yes
                                        </div>
                                      </span>
                                    </label>
                                  </div>
                                  <div className="a53cbfa6de ac9267e216 ad8af0911d">
                                    <label
                                      htmlFor=":r8q:"
                                      className="aca0ade214 aaf30230d9 c2931f4182 d79e71457a f8208e9f83"
                                    >
                                      <input
                                        className="c907c67d20"
                                        type="radio"
                                        value="No"
                                        checked={privtebathroom === "No"}
                                        onChange={
                                          handleOptionChangePrivatebathroom
                                        }
                                      />
                                      <span className="aaee4e7cd3 e7a57abb1e">
                                        <div className="a53cbfa6de e6208ee469">
                                          No, it's shared
                                        </div>
                                      </span>
                                    </label>
                                  </div>
                                </div>
                              </fieldset>
                              <div className="spacing--8x" />
                              <hr className="b9bfeba2b4 b288f61df6" />
                              <div className="spacing--6x" />
                              <div className="e1eebb6a1e spacing--4x">
                                What bathroom items are available in this room?
                              </div>
                              <div className="spacing--6x" />

                              <div className="aca0ade214 aaf30230d9 cd2e7d62b0">
                                <div className="a53cbfa6de ac9267e216 d8eb520c4e">
                                  {Object.keys(checkboxesBathroomItems).map(
                                    (option) => (
                                      <div key={option}>
                                        <label
                                          htmlFor=":rf:"
                                          className="aca0ade214 aaf30230d9 c2931f4182 d79e71457a bd597ff2d8"
                                        >
                                          <input
                                            type="checkbox"
                                            id={`checkbox${option}`}
                                            className="ef785aa7f4"
                                            checked={
                                              checkboxesBathroomItems[option]
                                            }
                                            onChange={() =>
                                              handleCheckboxChangeBathroom(
                                                option
                                              )
                                            }
                                          />
                                          <div className="a53cbfa6de e6208ee469">
                                            {" "}
                                            {option}
                                          </div>
                                        </label>{" "}
                                      </div>
                                    )
                                  )}
                                </div>
                                <input
                                  type="text"
                                  placeholder="Enter Bathroom items"
                                  value={newCheckboxLabelBathroom}
                                  class="eb46370fe1"
                                  onChange={handleInputChangeBathroom}
                                />
                                <button
                                  onClick={handleAddCheckboxBathroom}
                                  style={{
                                    marginTop: "10px",
                                    padding: "10px 20px",
                                    fontSize: "16px",
                                    backgroundColor: "#70bbe9",
                                    color: "white",
                                    border: "none",
                                    borderRadius: "5px",
                                    cursor: "pointer",
                                    transition: "background-color 0.3s",
                                  }}
                                >
                                  Add Bathroom Items
                                </button>
                              </div>
                            </div>
                          </div>
                        </>
                      ) : activeStepR == 2 ? (
                        <>
                          <div className="form-container">
                            <div className="f2cf178bcd screen-main-header d991e1216a">
                              <div className="f6431b446c e6208ee469 d0caee4251 screen-main-header__title">
                                What can guests use in this room?
                              </div>
                            </div>
                            <div className="ffb9c3d6a3 wide-container min-height">
                              <div className="aca0ade214 ebac6e22e9 f66f916626 c2931f4182 c27e5d305d db150fece4">
                                <div className="b817090550 b736e9e3f4">
                                  <div
                                    data-testid="CheckboxList-title"
                                    className="f2cf178bcd spacing--4x"
                                  >
                                    <div className="e1eebb6a1e e6208ee469 d0caee4251">
                                      General amenities
                                    </div>
                                  </div>
                                  <div className="aca0ade214 aaf30230d9 cd2e7d62b0">
                                    <div className="a53cbfa6de ac9267e216 d8eb520c4e">
                                      {Object.keys(checkboxesGeneral).map(
                                        (option) => (
                                          <div key={option}>
                                            <label
                                              htmlFor=":rf:"
                                              className="aca0ade214 aaf30230d9 c2931f4182 d79e71457a bd597ff2d8"
                                            >
                                              <input
                                                type="checkbox"
                                                id={`checkbox${option}`}
                                                className="ef785aa7f4"
                                                checked={
                                                  checkboxesGeneral[option]
                                                }
                                                onChange={() =>
                                                  handleCheckboxChangeGeneral(
                                                    option
                                                  )
                                                }
                                              />
                                              <div className="a53cbfa6de e6208ee469">
                                                {" "}
                                                {option}
                                              </div>
                                            </label>{" "}
                                          </div>
                                        )
                                      )}
                                    </div>
                                    <input
                                      type="text"
                                      placeholder="Enter General amenities"
                                      value={newCheckboxLabelGeneral}
                                      class="eb46370fe1"
                                      onChange={handleInputChangeGeneral}
                                    />
                                    <button
                                      onClick={handleAddCheckboxGeneral}
                                      style={{
                                        marginTop: "10px",
                                        padding: "10px 20px",
                                        fontSize: "16px",
                                        backgroundColor: "#70bbe9",
                                        color: "white",
                                        border: "none",
                                        borderRadius: "5px",
                                        cursor: "pointer",
                                        transition: "background-color 0.3s",
                                      }}
                                    >
                                      Add General amenities
                                    </button>
                                  </div>

                                  <div className="spacing--8x" />
                                  <hr className="b9bfeba2b4 b288f61df6 spacing--8x" />
                                  <div
                                    data-testid="CheckboxList-title"
                                    className="f2cf178bcd spacing--4x"
                                  >
                                    <div className="e1eebb6a1e e6208ee469 d0caee4251">
                                      Outdoors and views
                                    </div>
                                  </div>
                                  <div className="aca0ade214 aaf30230d9 cd2e7d62b0">
                                    <div className="a53cbfa6de ac9267e216 d8eb520c4e">
                                      {Object.keys(checkboxesOutdoors).map(
                                        (option) => (
                                          <div key={option}>
                                            <label
                                              htmlFor=":rf:"
                                              className="aca0ade214 aaf30230d9 c2931f4182 d79e71457a bd597ff2d8"
                                            >
                                              <input
                                                type="checkbox"
                                                id={`checkbox${option}`}
                                                className="ef785aa7f4"
                                                checked={
                                                  checkboxesOutdoors[option]
                                                }
                                                onChange={() =>
                                                  handleCheckboxChangeOutdoors(
                                                    option
                                                  )
                                                }
                                              />
                                              <div className="a53cbfa6de e6208ee469">
                                                {" "}
                                                {option}
                                              </div>
                                            </label>{" "}
                                          </div>
                                        )
                                      )}
                                    </div>
                                    <input
                                      type="text"
                                      placeholder="Enter Outdoors and Views"
                                      value={newCheckboxLabelOutdoors}
                                      class="eb46370fe1"
                                      onChange={handleInputChangeOutdoors}
                                    />
                                    <button
                                      onClick={handleAddCheckboxOutdoors}
                                      style={{
                                        marginTop: "10px",
                                        padding: "10px 20px",
                                        fontSize: "16px",
                                        backgroundColor: "#70bbe9",
                                        color: "white",
                                        border: "none",
                                        borderRadius: "5px",
                                        cursor: "pointer",
                                        transition: "background-color 0.3s",
                                      }}
                                    >
                                      Add Outdoors and views
                                    </button>
                                  </div>
                                  <div className="spacing--8x" />
                                  <hr className="b9bfeba2b4 b288f61df6 spacing--8x" />
                                  <div
                                    data-testid="CheckboxList-title"
                                    className="f2cf178bcd spacing--4x"
                                  >
                                    <div className="e1eebb6a1e e6208ee469 d0caee4251">
                                      Food and drink
                                    </div>
                                  </div>
                                  <div className="aca0ade214 aaf30230d9 cd2e7d62b0">
                                    <div className="a53cbfa6de ac9267e216 d8eb520c4e">
                                      {Object.keys(checkboxesFood).map(
                                        (option) => (
                                          <div key={option}>
                                            <label
                                              htmlFor=":rf:"
                                              className="aca0ade214 aaf30230d9 c2931f4182 d79e71457a bd597ff2d8"
                                            >
                                              <input
                                                type="checkbox"
                                                id={`checkbox${option}`}
                                                className="ef785aa7f4"
                                                checked={checkboxesFood[option]}
                                                onChange={() =>
                                                  handleCheckboxChangeFood(
                                                    option
                                                  )
                                                }
                                              />
                                              <div className="a53cbfa6de e6208ee469">
                                                {" "}
                                                {option}
                                              </div>
                                            </label>{" "}
                                          </div>
                                        )
                                      )}
                                    </div>
                                    <input
                                      type="text"
                                      placeholder="Enter Food and drink"
                                      value={newCheckboxLabelFood}
                                      class="eb46370fe1"
                                      onChange={handleInputChangeFood}
                                    />
                                    <button
                                      onClick={handleAddCheckboxFood}
                                      style={{
                                        marginTop: "10px",
                                        padding: "10px 20px",
                                        fontSize: "16px",
                                        backgroundColor: "#70bbe9",
                                        color: "white",
                                        border: "none",
                                        borderRadius: "5px",
                                        cursor: "pointer",
                                        transition: "background-color 0.3s",
                                      }}
                                    >
                                      Add Food and drink
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </>
                      ) : activeStepR === 3 ? (
                        <>
                          <div className="form-container">
                            <div className="f2cf178bcd screen-main-header d991e1216a">
                              <div className="f6431b446c e6208ee469 d0caee4251 screen-main-header__title">
                                What does your room look like?
                              </div>
                            </div>

                            <div className="upload-photos_container">
                              <div className="drag-and-drop__highlight">
                                <div
                                  className="ffb9c3d6a33 wide-container min-height ota-container"
                                  style={{ width: "100%" }}
                                >
                                  <div
                                    className="spacing--4x"
                                    data-testid="NumberOfPhotosRequiredMessage-container"
                                  >
                                    <label
                                      htmlFor="photoFileInput"
                                      className="add-photo-sub-info font_body_2"
                                    >
                                      <div className="a3332d346a">
                                        Upload at least 3 photos of this room?.{" "}
                                      </div>{" "}
                                      <div className="a53cbfa6de">
                                        The more you upload, the more likely you
                                        are to get bookings. You can add more
                                        later.{" "}
                                      </div>
                                    </label>
                                  </div>
                                  <div className="take-photo take-photo--with-drag-and-drop">
                                    <div className="drag-and-drop">
                                      <div
                                        style={{
                                          border: "2px dashed #cccccc",
                                          borderRadius: "4px",
                                          padding: "20px",
                                          textAlign: "center",
                                          display: "flex",
                                          flexDirection: "row",
                                          cursor: "pointer",
                                        }}
                                      >
                                        <p>
                                          Click to select one or more images
                                        </p>
                                        <input
                                          type="file"
                                          multiple
                                          accept="image/*"
                                          onChange={(e) =>
                                            handleFileChangeRoom(e)
                                          }
                                        />
                                      </div>
                                    </div>
                                  </div>
                                  <div className="take-photo take-photo--with-drag-and-drop">
                                    <div className="drag-and-drop">
                                      <div
                                        style={{
                                          textAlign: "center",
                                          marginTop: "10px",
                                        }}
                                      >
                                        {uploadedImageroom &&
                                          uploadedImageroom?.map(
                                            (item, index) => (
                                              <div
                                                key={index}
                                                style={{
                                                  display: "inline-block",
                                                  marginRight: "10px",
                                                  marginTop: "10px",
                                                }}
                                              >
                                                <img
                                                  src={URL.createObjectURL(
                                                    item
                                                  )}
                                                  alt="Uploaded"
                                                  style={{ maxWidth: "50px" }}
                                                />

                                                <Tooltip title="delete">
                                                  <MdDelete
                                                    color="red"
                                                    onClick={() =>
                                                      deleteImageRoom(index)
                                                    }
                                                  />
                                                </Tooltip>
                                              </div>
                                            )
                                          )}
                                      </div>
                                    </div>
                                  </div>
                                  <div>
                                    <div className="spacing--4x" />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </>
                      ) : activeStepR === 4 ? (
                        <>
                          <div className="form-container">
                            <div className="f2cf178bcd screen-main-header d991e1216a">
                              <div className="f6431b446c e6208ee469 d0caee4251 screen-main-header__title">
                                What’s the name of this room?
                              </div>
                            </div>
                            <div className="ffb9c3d6a3 wide-container min-height">
                              <div className="form-group">
                                <div className="spacing--8x">
                                  This is the name that guests will see on your
                                  property page. Choose a name that most
                                  accurately describes this room?.
                                </div>
                                <div className="a53cbfa6de ac9267e216">
                                  <label
                                    htmlFor=":ra2:"
                                    className="a53cbfa6de e6208ee469 f555271986"
                                  >
                                    <span>
                                      <span className="ccb65902b2">
                                        Room name
                                      </span>
                                    </span>
                                  </label>
                                  {/* <div className="cabad3c686 aff44befa2">
                            <select
                              className="ebf4591c8e"
                              name="room_name"
                              id=":ra2:"
                              value={RoomName}
                              onChange={(e)=>setRoomName(e.target.value)}
                            >
                              <option value>Select a name for the room </option>
                              
                              <option
                                value="Double Room"
                                data-key="recommended#391"
                              >
                                Double Room
                              </option>
                              <option
                                value="Double Room with Private Bathroom"
                                data-key="recommended#5161"
                              >
                                Double Room with Private Bathroom
                              </option>
                            
                            </select>
                            <span className="ad2c299542">
                              <span
                                className="fcd9eec8fb cab1c196b5 c2cc050fb8 f64eb5d122"
                                aria-hidden="true"
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 24 24"
                                >
                                  <path d="M19.2681 8.91288C19.2687 9.03226 19.2454 9.15054 19.1996 9.26079C19.1539 9.37105 19.0865 9.47105 19.0016 9.55493L12.9445 15.612C12.8211 15.7367 12.6738 15.8353 12.5115 15.9018C12.3491 15.9684 12.1751 16.0017 11.9996 15.9997C11.6491 16.0075 11.3096 15.877 11.0547 15.6362L4.99764 9.57916C4.8275 9.40881 4.73193 9.17788 4.73193 8.93711C4.73193 8.69634 4.8275 8.46542 4.99764 8.29506C5.08117 8.20935 5.18101 8.14122 5.29128 8.09471C5.40155 8.04819 5.52002 8.02423 5.6397 8.02423C5.75937 8.02423 5.87784 8.04819 5.98811 8.09471C6.09838 8.14122 6.19822 8.20935 6.28175 8.29506L11.9996 13.9887L17.7175 8.27084C17.801 8.18512 17.9009 8.11699 18.0111 8.07048C18.1214 8.02396 18.2399 8 18.3596 8C18.4792 8 18.5977 8.02396 18.708 8.07048C18.8182 8.11699 18.9181 8.18512 19.0016 8.27084C19.0871 8.3543 19.1548 8.45422 19.2006 8.56457C19.2464 8.67492 19.2694 8.79341 19.2681 8.91288Z" />
                                </svg>
                              </span>
                            </span>
                          </div> */}
                                  <div className="a53cbfa6de ac9267e216">
                                    <div className="e000754250">
                                      <input
                                        type="text"
                                        value={newOptionRoomname}
                                        onChange={(e) =>
                                          setNewOptionRoomname(e.target.value)
                                        }
                                        placeholder="Type to add or search..."
                                        class="eb46370fe1"
                                        onClick={() =>
                                          setShowOptionsRoomname(
                                            !showOptionsRoomname
                                          )
                                        }
                                      />
                                    </div>
                                    <button
                                      style={{
                                        marginTop: "10px",
                                        padding: "10px 20px",
                                        fontSize: "16px",
                                        backgroundColor: "#70bbe9",
                                        color: "white",
                                        border: "none",
                                        borderRadius: "5px",
                                        cursor: "pointer",
                                        transition: "background-color 0.3s",
                                      }}
                                      onClick={handleAddOption}
                                    >
                                      Add
                                    </button>
                                    {showOptionsRoomname && (
                                      <div className="ebf4591c8e">
                                        {" "}
                                        {optionsRoomname.map((option) => (
                                          <div className="cabad3c686 aff44befa2">
                                            {/* <select
                              className="ebf4591c8e"
                              name="room_type"
                              data-testid="InputRadioWithOptions-inputSelect-room_type"
                              id=":r63:"
                              // value={unit}
                              // onChange={(e) => setunit(e.target.value)}
                              onClick={() => handleOptionClick(option)}
                            > */}
                                            <div
                                              key={option.id}
                                              style={{
                                                padding: "10px",
                                                cursor: "pointer",
                                                color: "black",
                                                backgroundColor:
                                                  selectedOptionsRoomname.some(
                                                    (selectedOption) =>
                                                      selectedOption.id ===
                                                      option.id
                                                  )
                                                    ? "#ddd"
                                                    : "transparent",
                                              }}
                                              onClick={() =>
                                                handleOptionClickRoomname(
                                                  option
                                                )
                                              }
                                            >
                                              <option
                                                value="Bed in Dorm"
                                                data-key={26}
                                              >
                                                {option.label}
                                              </option>
                                            </div>
                                          </div>
                                        ))}
                                      </div>
                                    )}
                                    {selectedOptionsRoomname && (
                                      <div className="selected-option">
                                        Selected Rooms:{" "}
                                        {selectedOptionsRoomname
                                          .map((option) => option.label)
                                          .join(", ")}
                                      </div>
                                    )}
                                  </div>
                                </div>
                              </div>
                              <div className="page-tooltip__container undefined">
                                <div className />
                              </div>
                            </div>
                          </div>
                        </>
                      ) : activeStepR === 5 ? (
                        <>
                          <div className="form-container">
                            <div className="f2cf178bcd screen-main-header d991e1216a">
                              <div className="f6431b446c e6208ee469 d0caee4251 screen-main-header__title">
                                Set the price per night for this room
                              </div>
                            </div>

                            <div className="ffb9c3d6a31 wide-container">
                              <div className="aca0ade214 ebac6e22e9 f66f916626 c2931f4182 c27e5d305d db150fece4">
                                <div className="b817090550 b736e9e3f4">
                                  <div className="e1eebb6a1e spacing--4x">
                                    How much do you want to charge per night?
                                  </div>
                                  <div>
                                    <div className="form-group">
                                      <div className="a53cbfa6de ac9267e216 a20293ec70">
                                        <label
                                          htmlFor="desired_price"
                                          className="a53cbfa6de e6208ee469 f555271986"
                                        >
                                          <span>
                                            <span className="ccb65902b2">
                                              Price guests pay
                                            </span>
                                          </span>
                                        </label>
                                        <div className="b9b84f4305">
                                          <div className="b93ef00bf5">INR</div>
                                          <div className="e000754250">
                                            <input
                                              type="number"
                                              onKeyDown={handleKeyDown}
                                              className="eb46370fe1"
                                              id="desired_price"
                                              value={roomprice}
                                              onChange={(e) =>
                                                setroomprice(e.target.value)
                                              }
                                            />
                                            <div className="e7e5251f68" />
                                          </div>
                                        </div>
                                      </div>
                                      {/* <div className="price-help-text spacing--2x">
                                Including taxes, commission and charges
                              </div> */}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </>
                      ) : activeStepR === 6 ? (
                        <></>
                      ) : (
                        <></>
                      )}

                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          pt: 2,
                        }}
                      >
                        <Button
                          style={{
                            display: activeStepR === 0 ? "none" : "block",
                            background: "green",
                            color: "#fff",
                          }}
                          disabled={activeStepR === 0}
                          onClick={handleBackR}
                          sx={{ mr: 1 }}
                        >
                          Back
                        </Button>
                        <Box sx={{ flex: "1 1 auto" }} />
                        {/* <Button
                          style={{ background: "#ffc107", color: "#000" }}
                          onClick={handleNext}
                          sx={{ mr: 1 }}
                        >
                          Next
                        </Button> */}

                        <button
                          className="pink-btn"
                          style={{
                            background: "green",
                            color: "#fff",
                            padding: 10,
                            marginRight: "30px",
                            display: activeStepR === 6 ? "none" : "block",
                          }}
                          onClick={handleCompleteR}
                        >
                          Next
                        </button>
                        <div className="form-container">
                          <button
                            className="pink-btn"
                            style={{
                              background: "green",
                              alignItems: "self-end",
                              color: "#fff",
                              padding: 10,
                              display: activeStepR === 6 ? "block" : "none",
                            }}
                            onClick={updateAlternative}
                          >
                            Submit
                          </button>
                        </div>
                      </Box>
                    </React.Fragment>
                  )}
                </div>
              </Box>
            </div>
          </Container>
        </Modal.Body>
        {/* <Modal.Footer>
            <Button variant="warning" onClick={handleClose1}>
              Close
            </Button>
            <Button variant="danger" onClick={handleClose1}>
              Save
            </Button>
          </Modal.Footer> */}
      </Modal>
      <Modal
        show={showB}
        size="sm"
        onHide={handleCloseB}
        dialogClassName="custom-modal"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title className="text-danger">Add Badge</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Container fluid>
            <div className="bg-white border-1 p-5">
              <p style={{ color: "black", fontWeight: "600" }}>Upload Badge</p>
              <div
                className="dropzone-container"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  padding: "20px",
                }}
              >
                <div
                  className="dropzone"
                  style={{
                    border: "2px dashed #cccccc",
                    borderRadius: "4px",
                    padding: "20px",
                    textAlign: "center",
                    cursor: "pointer",
                    marginBottom: "20px",
                  }}
                >
                  <input
                    type="file"
                    id="fileInput"
                    style={{ display: "none" }} // Hide the default file input
                    accept="image/png, image/jpg, image/jpeg"
                    onChange={(e) => setUploadedImageBadge(e.target.files[0])}
                  />
                  <label htmlFor="fileInput">
                    {/* Use a label as a clickable area */}
                    <p>Click to select files</p>
                  </label>
                </div>

             
                {uploadedImageBadge ?  <img
                  src={
                     URL.createObjectURL(uploadedImageBadge)
                      
                  }
                  style={{ height: "150px", width: "150px" }}
                /> : ""}
                <Tooltip title="delete">
                  <MdDelete color="red" onClick={() => deleteImageBadge()} />
                </Tooltip>
              </div>
            </div>
            <div className="form-container">
              <button
                className="pink-btn"
                style={{
                  background: "green",
                  alignItems: "self-end",
                  color: "#fff",
                  padding: 10,
                }}
                onClick={uploadBadge}
              >
                Submit
              </button>
            </div>
          </Container>
        </Modal.Body>
        {/* <Modal.Footer>
            <Button variant="warning" onClick={handleClose1}>
              Close
            </Button>
            <Button variant="danger" onClick={handleClose1}>
              Save
            </Button>
          </Modal.Footer> */}
      </Modal>
      <Modal
        show={showD}
        size="sm"
        onHide={handleCloseD}
        dialogClassName="custom-modal"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title className="text-danger">
            Add Discount and Tax
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Container fluid>
            <div className="bg-white border-1 p-3">
              <label>
                Discount Value:
                <input
                  type="text"
                  value={discountValue}
                  onChange={(e) => setDiscountValue(e.target.value)}
                />
              </label>
              <label>
                Type:
                <select
                  value={discountType}
                  onChange={(e) => setDiscountType(e.target.value)}
                >
                  <option value="%">%</option>
                  <option value="INR">INR</option>
                </select>
              </label>
            </div>
            <div className="bg-white border-1 p-3">
              <label>
                Tax:
                <input
                  type="text"
                  value={tax}
                  onChange={(e) => settax(e.target.value)}
                />
              </label>
              <label>
                Type:
                <select
                  value={discountTypetax}
                  onChange={(e) => setDiscountTypetax(e.target.value)}
                >
                  <option value="%">%</option>
                  <option value="INR">INR</option>
                </select>
              </label>
            </div>
            <div className="form-container">
              <button
                className="pink-btn"
                style={{
                  background: "green",
                  alignItems: "self-end",
                  color: "#fff",
                  padding: 10,
                }}
                onClick={updateAlternative}
              >
                Submit
              </button>
            </div>
          </Container>
        </Modal.Body>
        {/* <Modal.Footer>
            <Button variant="warning" onClick={handleClose1}>
              Close
            </Button>
            <Button variant="danger" onClick={handleClose1}>
              Save
            </Button>
          </Modal.Footer> */}
      </Modal>
    </div>
  );
};

export default AlternativeList;
