import React, { useEffect, useState } from "react";
import { Button, Container } from "react-bootstrap";
import Col from "react-bootstrap/Col";
import Nav from "react-bootstrap/Nav";
import Row from "react-bootstrap/Row";
import Tab from "react-bootstrap/Tab";
import { FaAngleRight } from "react-icons/fa";
import Accordion from "react-bootstrap/Accordion";
import Carousel from "react-bootstrap/Carousel";
import Bike from "../../assets/pre-loader-02.svg";
import parse from "html-react-parser";
import axios from "axios";
export const HomeRSA = () => {
  const [Faq, setFaq] = useState([]);
  const getfaq = async () => {
    let res = await axios.get("https://bookingoda.com/api/admin/getallfaq");
    if ((res.status = 200)) {
      //console.log(res);
      setFaq(res.data?.FAQ);
    }
  };

  useEffect(() => {
    getfaq();
  }, []);
  const [test, settest] = useState([]);
  const gettest = async () => {
    let res = await axios.get(
      "https://bookingoda.com/api/admin/getalltestimonial"
    );
    if ((res.status = 200)) {
      //console.log(res);
      settest(res.data?.Testimonial);
    }
  };

  useEffect(() => {
    gettest();
  }, []);
  return (
    <div>
      <div style={{ backgroundColor: "#212121", padding: "10px" }}>
        <Container>
          <h3 className="text-light text-center">Frequently Asked Questions</h3>
        </Container>
      </div>
      <Container fluid>
        <div>
          <Tab.Container id="left-tabs-example" defaultActiveKey="first">
            <Row>
              <Col sm={3} className="cat-first-col">
                <div className="cat_00">
                  <h4>Categories</h4>
                  <p>
                    Pick a category to find out more about Roadside Assistance
                  </p>
                </div>
                <Nav variant="pills" className="flex-column activation-00">
                  {/* <Nav.Item>
                                        <Nav.Link eventKey="first" className='tab-011'> Case registration through App  <FaAngleRight /></Nav.Link>
                                    </Nav.Item> */}
                  <Nav.Item>
                    <Nav.Link eventKey="second" className="tab-011">
                      Terms and Condtions of RSA <FaAngleRight style={{display:'inline-block'}}/>
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="third" className="tab-011">
                      {" "}
                      About RSA <FaAngleRight style={{display:'inline-block'}}/>
                    </Nav.Link>
                  </Nav.Item>
                </Nav>
              </Col>
              <Col sm={9} className="m-auto">
                <Tab.Content>
                  <Tab.Pane eventKey="third">
                    <Accordion>
                      {Faq.filter((item) => item.category === "About").map(
                        (data, i) => (
                          <Accordion.Item
                            eventKey={i}
                            className="mb-3 Accord_00"
                          >
                            <Accordion.Header>
                              <div className="fw-bold">{data.question}</div>
                            </Accordion.Header>
                            <Accordion.Body>
                              {parse(data.answer)}
                            </Accordion.Body>
                          </Accordion.Item>
                        )
                      )}
                    </Accordion>
                  </Tab.Pane>
                  <Tab.Pane eventKey="second">
                    <Accordion>
                      {Faq.filter((item) => item.category === "Terms").map(
                        (data, i) => (
                          <Accordion.Item
                            eventKey={i}
                            className="mb-3 Accord_00"
                          >
                            <Accordion.Header>
                              <div className="fw-bold">{data.question}</div>
                            </Accordion.Header>
                            <Accordion.Body>
                              {parse(data.answer)}
                            </Accordion.Body>
                          </Accordion.Item>
                        )
                      )}
                      {/* <Accordion.Item eventKey="1" className='mb-3 Accord_00'>
                                                <Accordion.Header className='fw-bold'>
                                                    <div className='fw-bold'>
                                                        What is the coverage period and validity after taking this program?
                                                    </div>
                                                </Accordion.Header>
                                                <Accordion.Body>
                                                     Road Side Assistance Program is a 24x7 emergency support provided in the event of any mechanical or electrical breakdown and/or accident of the rider’s motorcycle anywhere on National highways, State highways or motorable roads within mainland India. Support will also be provided at the user’s home and/or office but would be limited to two interventions per agreement year.
                                                </Accordion.Body>
                                            </Accordion.Item>
                                            <Accordion.Item eventKey="2" className='mb-3 Accord_00'>
                                                <Accordion.Header className='fw-bold'>
                                                    <div className='fw-bold'>
                                                        Is there a limit to how many times I can use Roadside Assistance at no charge?
                                                    </div>
                                                </Accordion.Header>
                                                <Accordion.Body>
                                                     Road Side Assistance Program is a 24x7 emergency support provided in the event of any mechanical or electrical breakdown and/or accident of the rider’s motorcycle anywhere on National highways, State highways or motorable roads within mainland India. Support will also be provided at the user’s home and/or office but would be limited to two interventions per agreement year.
                                                </Accordion.Body>
                                            </Accordion.Item> */}
                    </Accordion>
                  </Tab.Pane>
                  <Tab.Pane eventKey="first">
                    <Accordion>
                      <Accordion.Item eventKey="0" className="mb-3 Accord_00">
                        <Accordion.Header>
                          <div className="fw-bold">
                            How do I register my breakdown through the App?
                          </div>
                        </Accordion.Header>
                        <Accordion.Body>
                          Road Side Assistance Program is a 24x7 emergency
                          support provided in the event of any mechanical or
                          electrical breakdown and/or accident of the rider’s
                          motorcycle anywhere on National highways, State
                          highways or motorable roads within mainland India.
                          Support will also be provided at the user’s home
                          and/or office but would be limited to two
                          interventions per agreement year.
                        </Accordion.Body>
                      </Accordion.Item>
                      <Accordion.Item eventKey="1" className="mb-3 Accord_00">
                        <Accordion.Header className="fw-bold">
                          <div className="fw-bold">
                            Does a user need to register his motorcycle in-app
                            for availing RSA via the App?
                          </div>
                        </Accordion.Header>
                        <Accordion.Body>
                          Road Side Assistance Program is a 24x7 emergency
                          support provided in the event of any mechanical or
                          electrical breakdown and/or accident of the rider’s
                          motorcycle anywhere on National highways, State
                          highways or motorable roads within mainland India.
                          Support will also be provided at the user’s home
                          and/or office but would be limited to two
                          interventions per agreement year.
                        </Accordion.Body>
                      </Accordion.Item>
                      <Accordion.Item eventKey="2" className="mb-3 Accord_00">
                        <Accordion.Header className="fw-bold">
                          <div className="fw-bold">
                            What if the breakdown reason is not available in the
                            App?
                          </div>
                        </Accordion.Header>
                        <Accordion.Body>
                          Road Side Assistance Program is a 24x7 emergency
                          support provided in the event of any mechanical or
                          electrical breakdown and/or accident of the rider’s
                          motorcycle anywhere on National highways, State
                          highways or motorable roads within mainland India.
                          Support will also be provided at the user’s home
                          and/or office but would be limited to two
                          interventions per agreement year.
                        </Accordion.Body>
                      </Accordion.Item>
                      <Accordion.Item eventKey="3" className="mb-3 Accord_00">
                        <Accordion.Header className="fw-bold">
                          <div className="fw-bold">
                            How will I come to know the details of the
                            technician in the App?
                          </div>
                        </Accordion.Header>
                        <Accordion.Body>
                          Road Side Assistance Program is a 24x7 emergency
                          support provided in the event of any mechanical or
                          electrical breakdown and/or accident of the rider’s
                          motorcycle anywhere on National highways, State
                          highways or motorable roads within mainland India.
                          Support will also be provided at the user’s home
                          and/or office but would be limited to two
                          interventions per agreement year.
                        </Accordion.Body>
                      </Accordion.Item>
                    </Accordion>
                  </Tab.Pane>
                </Tab.Content>
              </Col>
            </Row>
          </Tab.Container>
        </div>
      </Container>

      {/* Caousal */}
      {/* <Carousel > */}
      {/* <Carousel.Item interval={1000}>
                    <img src="./img/testimonial-1.avif" alt="" />
                </Carousel.Item>
                <Carousel.Item interval={500}>
                    <img src="./img/testimonial-2.png" alt="" />
                </Carousel.Item>
                <Carousel.Item>
                    <img src="./img/testimonial-3.png" alt="" />
                </Carousel.Item> */}

      {/* </Carousel> */}

      <section class="construct-testimonial-area section_100">
        <div class="testimonial-overlay"></div>
        <div class="container">
          <div class="row4">
            <div class="col-md-12">
              <div class="testimonial-slide owl-carousel owl-theme owl-loaded owl-text-select-on">
                <Carousel style={{position:'relative'}}>
                  {test.map((data, i) => {
                    return (
                      <Carousel.Item>
                        <div class="single-testimonial">
                          <div class="testimonial-image">
                            <div class="testimonial-img-left">
                              {/* <img src={Bike} alt="testimonial" /> */}
                            </div>
                            <div class="testimonial-img-right" style={{marginTop:"80px"}}>
                              <h3>{data.name}</h3>
                              <p>{data.occupation}</p>
                            </div>
                          </div>
                          <div class="testimonial-text">
                            <p>{parse(data.message)}</p>
                          </div>
                        </div>
                      </Carousel.Item>
                    );
                  })}
                </Carousel>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};
