import React, { useState } from "react";
import {
  Container,
  Form,
  Row,
  Table,
  DatePicker,
  Modal,
} from "react-bootstrap";
import { MdDelete } from "react-icons/md";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepButton from "@mui/material/StepButton";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { City, Country, State } from "country-state-city";
import axios from "axios";
import CustomInput from "../components/CustomInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import * as XLSX from "xlsx";
import moment from "moment/moment";
import Success from "../../assets/success.png";
import Error from "../../assets/close.png";
import TextArea from "antd/es/input/TextArea";
import { Bathroom } from "@mui/icons-material";
import Switch from "react-switch";
import { useEffect } from "react";
const steps = [
  "Package Details",
  "Details and Includes",
  "Gallery",
  
 
];
const AddVacation = (props) => {
  const admin = JSON.parse(sessionStorage.getItem("admin"));

  const { i_class, onBlr } = props;
  const [activeStep, setActiveStep] = React.useState(0);
  const [completed, setCompleted] = React.useState({});

  const totalSteps = () => {
    return steps.length;
  };

  const completedSteps = () => {
    return Object.keys(completed).length;
  };

  const isLastStep = () => {
    return activeStep === totalSteps() - 1;
  };

  const allStepsCompleted = () => {
    return completedSteps() === totalSteps();
  };

  const handleNext = () => {
    const newActiveStep =
      isLastStep() && !allStepsCompleted()
        ? steps.findIndex((step, i) => !(i in completed))
        : activeStep + 1;
    setActiveStep(newActiveStep);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStep = (step) => () => {
    setActiveStep(step);
  };

  const handleReset = () => {
    setActiveStep(0);
    setCompleted({});
  };
  const [successshow, setsuccessshow] = useState(false);
  const handlesuccessClose = () => setsuccessshow(false);
  const handlesuccess = () => setsuccessshow(true);

  const [errorshow, seterrorshow] = useState(false);
  const handleerrorClose = () => seterrorshow(false);
  const handleerror = () => seterrorshow(true);


  const [Country1, setCountry1] = useState("");
  const [State1, setState1] = useState({ isoCode: "", name: "" });
  const [City1, setCity1] = useState("");
  console.log(State1);
  const CountryList = Country.getAllCountries();
  const StateList = State.getStatesOfCountry(Country1);
  const CityList = City.getCitiesOfState(Country1, State1?.isoCode);

  useEffect(() => {
    // Reset state and city when country changes
    setState1({ isoCode: "", name: "" });
    setCity1("");
  }, [Country1]);

  useEffect(() => {
    // Reset city when state changes
    setCity1("");
  }, [State1]);
  const [StayName, setStayName] = useState("");
  const [StayDescription, setStayDescription] = useState("");
  const [StayCost, setStayCost] = useState();
  const [StayDiscount, setStayDiscount] = useState();
  const [ChildrenCost, setChildrenCost] = useState();
  const [Childrendiscount, setChildrendiscount] = useState();

  const [Measurement, setMeasurement] = useState("");
  const [street, setStreet] = useState("");

  const [Rooms, setRooms] = useState("");
  const [Beds, setBeds] = useState("");
  const [Bathrooms, setBathrooms] = useState("");


  const handleComplete = () => {
    const newCompleted = completed;
    newCompleted[activeStep] = true;
    setCompleted(newCompleted);
    if (activeStep === 0) {
      if (!StayName) {
        return (
          <>
            {alert("Enter stay name")}
            {setActiveStep(0)}
          </>
        );
      } else if (!StayDescription) {
        return (
          <>
            {alert("Enter Stay Description")}
            {setActiveStep(0)}
          </>
        );
      } else if (!StayCost) {
        return (
          <>
            {alert("Enter Adult Stay Cost..!")}
            {setActiveStep(0)}
          </>
        );
      } else if (!ChildrenCost) {
        return (
          <>
            {alert("Enter Children Cost..!")}
            {setActiveStep(0)}
          </>
        );
      } else if (!Country1) {
        return (
          <>
            {alert("Select Country")}
            {setActiveStep(0)}
          </>
        );
      } else if (!State1) {
        return (
          <>
            {alert("Select State")}
            {setActiveStep(0)}
          </>
        );
      } else if (!City1) {
        return (
          <>
            {alert("Select City")}
            {setActiveStep(0)}
          </>
        );
      } else if (!Measurement) {
        return (
          <>
            {alert("Enter stay size..!")}
            {setActiveStep(0)}
          </>
        );
      }
      else if (!Rooms) {
        return (
          <>
            {alert("Enter number of rooms..!")}
            {setActiveStep(0)}
          </>
        );
      }
      else if (!Beds) {
        return (
          <>
            {alert("Enter number of Beds..!")}
            {setActiveStep(0)}
          </>
        );
      }
      else if (!Bathrooms) {
        return (
          <>
            {alert("Enter number of Bathrooms..!")}
            {setActiveStep(0)}
          </>
        );
      }
      handleNext();
    }

    if (activeStep === 1) {
      if (Object.values(checkboxesAmenities).every(value => value === false) === true ) {
        alert("Please select Amenities");
      } else {
        handleNext();
      }
    }
    if (activeStep === 2) {
      if (galleryImages.length === 0) {
        return <>{alert("Select Gallery Images")}</>;
      } else {
        handleNext();
      }
    }

  
   
  };

  //

  // Add Gallery Image
  const [imageURL2, setimageURL2] = useState(null);
  const [Image, setImage] = useState("");
  const [galleryImages, setGalleryImages] = useState([]);
  const onImageChange2 = (event) => {
    if (event.target.files && event.target.files[0]) {
      setimageURL2(URL.createObjectURL(event.target.files[0]));
    }
  };
  ////console.log("galleryImages", galleryImages);
  const AddGalleryImage = () => {
    if (!Image) {
      return alert("Please, Select Image");
    }

    const imageExists = galleryImages.find((img) => img?.imgUrl === Image);

    if (imageExists) {
      return alert("Image already exists in the gallery.");
    }

    const obj = {
      image: Image,
      imgUrl: imageURL2,
    };

    setGalleryImages([...galleryImages, obj]);
    setImage("")
    setimageURL2(null)
    const fileInput = document.getElementById('fileInput');
    if (fileInput) {
      fileInput.value = null;
    }
  };
  const removeItem2 = (val) => {
    const updatedGallery = galleryImages.filter((item, index) => index !== val);
    setGalleryImages(updatedGallery);
  };

  // Add Tour Schedule


  
 

  const TourpackageDetails = async () => {
    let adultofferprice = StayCost - (StayCost * StayDiscount) / 100;
    let childrenofferprice =
      ChildrenCost - (ChildrenCost * Childrendiscount) / 100;
    // let petofferprice = Petcost - (Petcost * Petdiscount) / 100;
    try {
      const config = {
        url: "/admin/addvacation",
        method: "post",
        baseURL: "https://bookingoda.com/api",
        Headers: { "content-type": "multipart/form-data" },
        data: {
          UserId: admin._id,
          stayname: StayName,
          staydescription: StayDescription,
          staycost: StayCost,
          staydiscount: StayDiscount,
          offerprice: adultofferprice,
          street:street,
          country: Country1,
          state: State1?.name,
          city: City1,
          childrencost: ChildrenCost,
          childrendiscount: Childrendiscount,
          childrenoffer: childrenofferprice,
      
          Measurement: Measurement,
          Rooms:Rooms,
          Beds:Beds,
          Bathrooms:Bathrooms,
        Amenities:checkboxesAmenities,
        Smoking:isCheckedSmoking,
        Parties:isCheckedParties,
        Children:isCheckedChildren,
        Pets:isCheckedPets,
       
        
        },
      };
      await axios(config).then(async (res) => {
        if (res.status === 200) {
          for (let i = 0; i < galleryImages.length; i++) {
            const config = {
              url: "/admin/galleryvacationupload",
              method: "put",
              baseURL: "https://bookingoda.com/api",
              headers: { "content-type": "multipart/form-data" },
              data: {
                productId: res.data.success?._id,
                image: galleryImages[i]?.image,
              },
            };
            await axios(config);
          }
          // alert("Package Added Successfully");
          handlesuccess();
          window.location.assign("/admindashboard/vacationlist");
        }
      });
      ////console.log("config", config);
    } catch (error) {
      ////console.log(error);
      handleerror();
    }
  };
  const [checkboxesAmenities, setCheckboxesAmenities] = useState({
    "Air Conditioning": false,
    "Bathrobes": false,
    "Dry cleaning": false,
    "Fitness center": false,
    "Hair dryer":false,
    "High chair":false,
    "Mini bar":false,
    "Restaurant":false,
    "Slippers":false,
    "Telephone":false,
    "Wake-up call":false,
    "Wifi":false,

  });

  const [newCheckboxAmenities, setNewCheckboxAmenities] = useState("");

  const handleCheckboxChangeAmenities = (option) => {
    setCheckboxesAmenities((prevCheckboxes1) => ({
      ...prevCheckboxes1,
      [option]: !prevCheckboxes1[option],
    }));
  };

  const handleInputChangeAmenities = (event) => {
    setNewCheckboxAmenities(event.target.value);
  };

  const handleAddCheckboxAmenities = () => {
    if (newCheckboxAmenities) {
      setCheckboxesAmenities((prevCheckboxes1) => ({
        ...prevCheckboxes1,
        [newCheckboxAmenities]: false,
      }));
      setNewCheckboxAmenities("");
    }
  };
  const [isCheckedSmoking, setIsCheckedSmoking] = useState(false);

  const [isCheckedPets, setIsCheckedPets] = useState(false);
  const [isCheckedParties, setIsCheckedParties] = useState(false);
  const [isCheckedChildren, setIsCheckedChildren] = useState(false);

  const handleToggleSmoking = () => {
    setIsCheckedSmoking(!isCheckedSmoking);
  };
  const handleTogglePets = () => {
    setIsCheckedPets(!isCheckedPets);
  };
  const handleToggleParties = () => {
    setIsCheckedParties(!isCheckedParties);
  };
  const handleToggleChildren = () => {
    setIsCheckedChildren(!isCheckedChildren);
  };
  return (
    <div>
      <Container fluid>
        <div className="d-flex justify-content-between align-items-center pt-4 pb-2">
          <p className="text-success fs-4 fw-semibold">
            Add Vacations Homes Details:
          </p>
        </div>

        <div className="Stepper-info" style={{ padding: "20px" }}>
          <Box sx={{ width: "100%" }}>
            <Stepper nonLinear activeStep={activeStep}>
              {steps.map((label, index) => (
                <Step key={label} completed={completed[index]}>
                  <StepButton
                    color="inherit"
                    style={{ marginBottom: "50px" }}
                    onClick={handleStep(index)}
                  >
                    {label}
                  </StepButton>
                </Step>
              ))}
            </Stepper>
            <div>
              {allStepsCompleted() ? (
                <React.Fragment>
                  <Typography
                    sx={{ mt: 2, mb: 1 }}
                    style={{
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      fontSize: "20px",
                      fontWeight: "500",
                    }}
                  >
                    All steps completed successfully
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignSelf: "center",
                        pt: 2,
                      }}
                    >
                      <Button onClick={handleReset}>Back</Button>
                    </Box>
                  </Typography>
                </React.Fragment>
              ) : (
                <React.Fragment>
                  {activeStep == 0 ? (
                    <>
                      <Typography sx={{ mt: 2, mb: 1, py: 1 }}>
                        <div className="container" style={{ padding: "5px" }}>
                          <div className="row ">
                            <div className="col-md-6">
                              <div className="do-sear mt-2">
                            
                                <CustomInput
                                  type="text"
                                  label="Stay Name"
                                  name="name"
                                  onChng={(e) => setStayName(e.target.value)}
                                  val={StayName}
                                />
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="do-sear mt-2">
                             
                                <CustomInput
                                  type="text"
                                  label="Description"
                                  name="name"
                                  onChng={(e) =>
                                    setStayDescription(e.target.value)
                                  }
                                  val={StayDescription}
                                />
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="do-sear mt-2">
                              
                                <CustomInput
                                  type="number"
                                  label="Adult Price"
                                  max={6}
                                  name="name"
                                  onChng={(e) => setStayCost(e.target.value)}
                                  val={StayCost}
                                />
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="do-sear mt-2">
                              
                                <CustomInput
                                  type="number"
                                  label="Adult Discount"
                                  max={2}
                                  name="name"
                                  onChng={(e) =>
                                    setStayDiscount(e.target.value)
                                  }
                                  val={StayDiscount}
                                />
                              </div>
                            </div>

                            <div className="col-md-6">
                              <div className="do-sear mt-2">
                                
                                <CustomInput
                                  type="number"
                                  label="Children Price"
                                  max={6}
                                  name="name"
                                  onChng={(e) =>
                                    setChildrenCost(e.target.value)
                                  }
                                  val={ChildrenCost}
                                />
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="do-sear mt-2">
                               
                                <CustomInput
                                  type="number"
                                  label="Children Discount"
                                  name="name"
                                  max={2}
                                  onChng={(e) =>
                                    setChildrendiscount(e.target.value)
                                  }
                                  val={Childrendiscount}
                                />
                              </div>
                            </div>
                            {/* <div className="col-md-6">
                              <div className="do-sear mt-2">
                                 <CustomInput
                                  type="text"
                                  label="Pet Price"
                                  name="name"
                                  onChng={(e) => setPetcost(e.target.value)}
                                  val={Petcost}
                                /> 
                              </div>
                            </div> */}
                            <div className="col-md-6">
                              <div className="do-sear mt-2">
                                <CustomInput
                                  type="text"
                                  label="Street address"
                                  name="name"
                                  onChng={(e) => setStreet(e.target.value)}
                                  val={street}
                                />
                              </div>
                            </div>

                            <div className="col-md-6">
                              <div className="do-sear mt-3">
                              <label>Country</label>
                                <select
                                  class="ebf4591c8e country-select-for-e2e-tests"
                                  name="cc1"
                                  id="cc1"
                                  className="vi_0"
                                  style={{
                                    width: "100%",
                                    borderColor: "#E6E6E6",
                                    fontSize: "16px",
                                  }}
                                  onChange={(e) => setCountry1(e.target.value)}
                                >
                                  <option value="" style={{ fontSize: "16px" }}>
                                    Select country/region
                                    <span style={{ color: "red" }}>*</span>
                                  </option>
                                  {CountryList?.map((Country) => (
                                    <option
                                      value={Country?.isoCode}
                                      style={{ fontSize: "16px" }}
                                    >
                                      {Country?.name}
                                    </option>
                                  ))}
                                </select>
                              
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="do-sear mt-3">
                              <label>State</label>
                                <select
                                  class="ebf4591c8e country-select-for-e2e-tests"
                                  name="cc1"
                                  id="cc1"
                                  className="vi_0"
                                  style={{
                                    width: "100%",
                                    borderColor: "#E6E6E6",
                                    fontSize: "16px",
                                  }}
                                  onChange={(e) => {
                                    const selectedState = StateList.find(
                                      (state) =>
                                        state.isoCode === e.target.value
                                    );
                                    setState1(
                                      selectedState
                                        ? {
                                            isoCode: selectedState.isoCode,
                                            name: selectedState.name,
                                          }
                                        : { isoCode: "", name: "" }
                                    );
                                  }}
                                >
                                   <option value="" style={{ fontSize: "16px" }}>
                                    Select state/region{" "}
                                    <span style={{ color: "red" }}>*</span>
                                    </option>
                                  {StateList?.map((state) => (
                                    <option
                                      key={state.isoCode}
                                      value={state.isoCode}
                                    >
                                      {state.name}
                                    </option>
                                  ))}
                                
                                 
                                </select>
                              
                              </div>
                            </div>

                            <div className="col-md-6">
                              <div className="do-sear mt-3">
                              <label>City</label>
                                <select
                                  className="vi_0"
                                  // class="ebf4591c8e country-select-for-e2e-tests"
                                  style={{
                                    width: "100%",
                                    borderColor: "#E6E6E6",
                                    fontSize: "16px",
                                  }}
                                  name="cc1"
                                  id="cc1"
                                  onChange={(e) => setCity1(e.target.value)}
                                >
                                  <option value="" style={{ fontSize: "16px" }}>
                                    Select City{" "}
                                    <span style={{ color: "red" }}>*</span>
                                  </option>
                                  {CityList?.map((city1) => (
                                    <option
                                      value={city1?.name}
                                      style={{ fontSize: "16px" }}
                                    >
                                      {city1?.name}
                                    </option>
                                  ))}
                                </select>
                               
                              </div>
                            </div>
                          
                            <div className="col-md-6">
                              <div className="do-sear mt-2">
                               
                                <CustomInput
                                  type="text"
                                  label="Stay Size(Sq ft)"
                                  name="name"
                                  onChng={(e) =>
                                    setMeasurement(e.target.value)
                                  }
                                  val={Measurement}
                                />
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="do-sear mt-2">
                               
                                <CustomInput
                                  type="number"
                                  label="No of Rooms"
                                  name="name"
                                  onChng={(e) =>
                                    setRooms(e.target.value)
                                  }
                                  val={Rooms}
                                />
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="do-sear mt-2">
                               
                                <CustomInput
                                  type="number"
                                  label="No of Beds"
                                  name="name"
                                  onChng={(e) =>
                                    setBeds(e.target.value)
                                  }
                                  val={Beds}
                                />
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="do-sear mt-2">
                               
                                <CustomInput
                                  type="number"
                                  label="No of Bathrooms"
                                  name="name"
                                  onChng={(e) =>
                                    setBathrooms(e.target.value)
                                  }
                                  val={Bathrooms}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </Typography>
                    </>
                  ) : activeStep == 1 ? (
                    <>
                      <Typography sx={{ mt: 2, mb: 1, py: 1 }}>
                        <div className="container" style={{ padding: "5px" }}>
                             <div
                                    data-testid="CheckboxList-title"
                                    className="f2cf178bcd spacing--4x"
                                  >
                                    <div className="e1eebb6a1e e6208ee469 d0caee4251">
                                    Amenities
                                    </div>
                                  </div>
                        <div className="ffb9c3d6a31 wide-container min-height">
                          <div className="row">
                        
                                  <hr className="b9bfeba2b4 b288f61df6" />
                               
                                  <div className="aca0ade214 aaf30230d9 cd2e7d62b0">
                                    <div className="a53cbfa6de ac9267e216 d8eb520c4e">
                                      {Object.keys(checkboxesAmenities).map(
                                        (option) => (
                                          <div key={option}>
                                            <label
                                              htmlFor=":rf:"
                                              className="aca0ade214 aaf30230d9 c2931f4182 d79e71457a bd597ff2d8"
                                            >
                                              <input
                                                type="checkbox"
                                                id={`checkbox${option}`}
                                                className="ef785aa7f4"
                                                checked={
                                                  checkboxesAmenities[option]
                                                }
                                                onChange={() =>
                                                  handleCheckboxChangeAmenities(
                                                    option
                                                  )
                                                }
                                              />
                                              <div className="a53cbfa6de e6208ee469">
                                                {" "}
                                                {option}
                                              </div>
                                            </label>{" "}
                                          </div>
                                        )
                                      )}
                                    </div>
                                    <input
                                      type="text"
                                      placeholder="Enter Amenities"
                                      value={newCheckboxAmenities}
                                      class="eb46370fe1"
                                      onChange={handleInputChangeAmenities}
                                    />
                                    <button
                                      onClick={handleAddCheckboxAmenities}
                                      style={{
                                        background: "green",
                                        color: "#fff",
                                        padding: 10,
                                      }}
                                    >
                                      Add Amenities
                                    </button>
                                  </div>
                           
                          </div>
                          </div>
                          <div className="form-container">
                            <div className="f2cf178bcd screen-main-header d991e1216a">
                              <div className="f6431b446c e6208ee469 d0caee4251 screen-main-header__title">
                                House rules
                              </div>
                            </div>
                            <div className="ffb9c3d6a31 wide-container min-height">
                              <div className="aca0ade214 ebac6e22e9 f66f916626 c2931f4182 c27e5d305d db150fece4 spacing--6x">
                                <div className="b817090550 d4e36dbd77">
                                  <label htmlFor="automation_checkbox_id_smoking_allowed">
                                    <div className="a53cbfa6de">
                                      Smoking in shared areas allowed
                                    </div>
                                  </label>
                                </div>
                                <Switch
                                  onChange={handleToggleSmoking}
                                  checked={isCheckedSmoking}
                                />
                              </div>
                              <div className="aca0ade214 ebac6e22e9 f66f916626 c2931f4182 c27e5d305d db150fece4 spacing--6x">
                                <div className="b817090550 d4e36dbd77">
                                  <label htmlFor="automation_checkbox_id_pets_allowed">
                                    <div className="a53cbfa6de">
                                      Pets allowed
                                    </div>
                                  </label>
                                </div>
                                <Switch
                                  onChange={handleTogglePets}
                                  checked={isCheckedPets}
                                />
                              </div>
                              <div className="aca0ade214 ebac6e22e9 f66f916626 c2931f4182 c27e5d305d db150fece4 spacing--6x">
                                <div className="b817090550 d4e36dbd77">
                                  <label htmlFor="automation_checkbox_id_pets_allowed">
                                    <div className="a53cbfa6de">
                                      Children allowed
                                    </div>
                                  </label>
                                </div>
                                <Switch
                                  onChange={handleToggleChildren}
                                  checked={isCheckedChildren}
                                />
                              </div>
                              <div className="aca0ade214 ebac6e22e9 f66f916626 c2931f4182 c27e5d305d db150fece4 spacing--6x">
                                <div className="b817090550 d4e36dbd77">
                                  <label htmlFor="automation_checkbox_id_parties_allowed">
                                    <div className="a53cbfa6de">
                                      Parties/events allowed
                                    </div>
                                  </label>
                                </div>
                                <Switch
                                  onChange={handleToggleParties}
                                  checked={isCheckedParties}
                                />
                              </div>
                             
                             
                            </div>
                          </div>
                        </div>
                      </Typography>
                    </>
                  ) : activeStep == 2 ? (
                    <>
                      <Typography sx={{ mt: 2, mb: 1, py: 1 }}>
                        <div className="container" style={{ padding: "5px" }}>
                          <div className="row ">
                            <div className="col-md-6">
                              <div className="do-sear mt-2">
                                <label>
                                  Gallery
                                  <span style={{ color: "red" }}>*</span>
                                </label>
                                <div className=" mt-3">
                                  <input
                                    type="file"
                                    id="fileInput"
                                    className={`form-control ${i_class}`}
                                    onBlur={onBlr}
                                    accept="image/*, video/mp4"
                                    onChange={(e) => {
                                      onImageChange2(e);
                                      setImage(e.target.files[0]);
                                    }}
                                  />
                                </div>
                            
                              </div>

                              <button
                                className="pink-btn mt-4 "
                                style={{
                                  background: "green",
                                  color: "#fff",
                                  padding: 10,
                                }}
                                onClick={AddGalleryImage}
                              >
                                Add
                              </button>
                            </div>

                            <div className="col-md-6">
                              <Table responsive bordered>
                                <thead>
                                  <tr>
                                    <th
                                      style={{
                                        background: "#d81d4a",
                                        color: "#fff",
                                      }}
                                    >
                                      S.No
                                    </th>
                                    <th
                                      style={{
                                        background: "#d81d4a",
                                        color: "#fff",
                                      }}
                                    >
                                      Image
                                    </th>
                                    <th
                                      style={{
                                        background: "#d81d4a",
                                        color: "#fff",
                                      }}
                                    >
                                      Action
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {galleryImages?.map((item, i) => {
                                    return (
                                      <tr>
                                        <td>{i + 1}</td>
                                        <td>
                                          <img
                                            src={item?.imgUrl}
                                            alt=""
                                            style={{
                                              width: "50px",
                                              height: "50px",
                                              objectFit: "cover",
                                            }}
                                          />
                                        </td>
                                        <td>
                                          <div
                                            className="d-flex gap-2 fs-4"
                                            style={{
                                              cursor: "pointer",
                                            }}
                                          >
                                            {/* <BiSolidEdit className="text-primary" /> */}
                                            <MdDelete
                                              onClick={() => removeItem2(i)}
                                              className="text-danger"
                                            />
                                          </div>
                                        </td>
                                      </tr>
                                    );
                                  })}
                                </tbody>
                              </Table>
                            </div>
                          </div>
                        </div>
                      </Typography>
                    </>
                
                  ) : (
                    <></>
                  )}

                  <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
                    <Button
                      style={{
                        background: "green",
                        color: "#fff",
                        display: activeStep === 0 ? "none" : "block",
                      }}
                      // disabled={activeStep === 0}

                      onClick={handleBack}
                      sx={{ mr: 1 }}
                    >
                      Back
                    </Button>
                    <Box sx={{ flex: "1 1 auto" }} />
                    <Button
                      style={{
                        background: "#ffc107",
                        color: "#000",
                        display: activeStep === 4 ? "none" : "block",
                      }}
                      onClick={handleNext}
                      sx={{ mr: 1 }}
                    >
                      Next
                    </Button>
                    {activeStep !== steps.length &&
                      (completed[activeStep] ? (
                        <Typography
                          variant="caption"
                          sx={{ display: "inline-block" }}
                        >
                          Step {activeStep + 1} already completed
                        </Typography>
                      ) : (
                        <button
                          className="pink-btn"
                          style={{
                            background: "green",
                            color: "#fff",
                            padding: 10,
                          }}
                          onClick={() => {
                            completedSteps() === totalSteps() - 1
                              ? TourpackageDetails()
                              : handleComplete();
                          }}
                        >
                          {completedSteps() === totalSteps() - 1
                            ? "Submit"
                            : "Complete Step"}
                        </button>
                      ))}
                  </Box>
                </React.Fragment>
              )}
            </div>
          </Box>
        </div>
      </Container>
      <Modal
        show={successshow}
        onHide={handlesuccess}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton onClick={handlesuccessClose}></Modal.Header>
        <Modal.Body>
          <div className="text-center">
            <img src={Success} style={{ marginTop: "-12px" }} width="100px" />

            <p>Successfully Added</p>
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        show={errorshow}
        onHide={handleerror}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton onClick={handleerrorClose}></Modal.Header>
        <Modal.Body>
          <div className="text-center">
            <img src={Error} style={{ marginTop: "-12px" }} width="100px" />

            <p>Error Occured</p>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};
export default AddVacation;
