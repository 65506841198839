import React, { useEffect, useState } from "react";
import "./property.css";
import Apartment from "../../assets/apartments.png";
import Apartments from "../../assets/buildings.png";

import Homestay from "../../assets/homestay.png";
import Hotel from "../../assets/hotel.png";
import VendorHeader from "./Vendorheader";
import { useNavigate } from "react-router-dom";

const Homeuse = () => {
  const [checkedItems, setCheckedItems] = useState({}); // State to manage checked items
  ////console.log(checkedItems);
  const history = useNavigate();

  // const handleCheckboxChange = (event) => {
  //   const { name, checked } = event.target;
  //   setCheckedItems({ ...checkedItems, [name]: checked });
  // };
  const HandleClick = () => {
    history("/homebreakfast", sessionStorage.setItem("HomeUse", JSON.stringify(checkboxes)))
  };
  useEffect(() => {
    
    const storeduse = sessionStorage.getItem("HomeUse");

 
    if (storeduse) {
      setCheckboxes(JSON.parse(storeduse));
    }

  }, []);
  const [checkboxes, setCheckboxes] = useState({
    Bar: false,
    Sauna: false,
    Garden: false,
    Terrace: false,
    "Hot tub/Jacuzzi": false,
    Heating: false,
    "Free WiFi": false,
    "Air conditioning": false,
    "Swimming pool": false,
   
    
  });

  const [newCheckboxLabel, setNewCheckboxLabel] = useState("");

  const handleCheckboxChange = (option) => {
    setCheckboxes((prevCheckboxes) => ({
      ...prevCheckboxes,
      [option]: !prevCheckboxes[option],
    }));
  };
  ////console.log(checkboxes);
  const handleInputChange = (event) => {
    setNewCheckboxLabel(event.target.value);
  };

  const handleAddCheckbox = () => {
    if (newCheckboxLabel) {
      setCheckboxes((prevCheckboxes) => ({
        ...prevCheckboxes,
        [newCheckboxLabel]: false,
      }));
      setNewCheckboxLabel("");
    }
  };
  
  return (
    <div
      data-capla-component="b-registration-joinapp15min-fe/"
      data-capla-namespace="b-registration-joinapp15min-feaYGUODfA"
    >
      <div id="app-container">
        <div class="error-popup-container"></div>
        <div class="application">
        <VendorHeader />
          <div class="error-popup-container"></div>
          <div className="page-container">
            <div className="screens screens-animation-direction-forward">
              <div
                className="height-100 screen-change-enter-done"
                id="automation_id_screen_container_Facilities"
              >
                <div
                  className="ms-container"
                  data-screen="true"
                  data-save-url="save_facilities"
                >
                  <div className="form-container">
                    <div className="f2cf178bcd screen-main-header d991e1216a">
                      <div className="f6431b446c e6208ee469 d0caee4251 screen-main-header__title">
                        What can guests use at your homestay?
                      </div>
                    </div>
                    <div className="ffb9c3d6a3 wide-container min-height automation_class_facilities_list">
                      <div className="aca0ade214 ebac6e22e9 f66f916626 c2931f4182 c27e5d305d db150fece4">
                        <div className="b817090550 b736e9e3f4">
                       
                                <div className="aca0ade214 aaf30230d9 cd2e7d62b0">
                              <div className="a53cbfa6de ac9267e216 d8eb520c4e">
                                {Object.keys(checkboxes).map((option) => (
                                  <div key={option}>
                                    <label
                                      htmlFor=":rf:"
                                      className="aca0ade214 aaf30230d9 c2931f4182 d79e71457a bd597ff2d8"
                                    >
                                      <input
                                        type="checkbox"
                                        id={`checkbox${option}`}
                                        className="ef785aa7f4"
                                        checked={checkboxes[option]}
                                        onChange={() =>
                                          handleCheckboxChange(option)
                                        }
                                      />
                                      <div className="a53cbfa6de e6208ee469">
                                        {" "}
                                        {option}
                                      </div>
                                    </label>{" "}
                                  </div>
                                ))}
                              </div>
                              <input
                                type="text"
                                placeholder="Enter Guest Use"
                                value={newCheckboxLabel}
                                class="eb46370fe1"
                                onChange={handleInputChange}
                              />
                              <button onClick={handleAddCheckbox} style={{
                                marginTop: "10px",
                                padding: "10px 20px",
                                fontSize: "16px",
                                backgroundColor: "#70bbe9",
                                color: "white",
                                border: "none",
                                borderRadius: "5px",
                                cursor: "pointer",
                                transition: "background-color 0.3s",
                              }}>
                                Add Guest Use
                              </button>
                            </div>
                        </div>
                      </div>
                      <div className="spacing--8x" />
                     
                    </div>
                  </div>
                  <div className="form-cta">
                    <div>
                      <div className="form-buttons-container">
                        <div className="form-buttons-container--secondary" onClick={()=>window.history.back()}>
                          <button
                            aria-label="Back to previous step"
                            data-testid="FormButtonSecondary"
                            type="button"
                            className="a83ed08757 c21c56c305 bf0537ecb5 d691166b09 f671049264 d2529514af af7297d90d"
                          >
                            <span className="eedba9e88a">
                              <span
                                className="fcd9eec8fb c2cc050fb8"
                                aria-hidden="true"
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 24 24"
                                  data-rtl-flip="true"
                                >
                                  <path d="M15.0871 19.2361C14.9677 19.2367 14.8495 19.2134 14.7392 19.1676C14.629 19.1219 14.5289 19.0546 14.4451 18.9696L8.38799 12.9125C8.26332 12.7891 8.16475 12.6418 8.09815 12.4795C8.03156 12.3172 7.99829 12.1431 8.00034 11.9676C7.99246 11.6171 8.12303 11.2776 8.36376 11.0227L14.4208 4.96566C14.5912 4.79552 14.8221 4.69995 15.0629 4.69995C15.3037 4.69995 15.5346 4.79552 15.7049 4.96566C15.7907 5.04918 15.8588 5.14902 15.9053 5.25929C15.9518 5.36957 15.9758 5.48803 15.9758 5.60771C15.9758 5.72739 15.9518 5.84586 15.9053 5.95613C15.8588 6.0664 15.7907 6.16624 15.7049 6.24976L10.0113 11.9676L15.7292 17.6855C15.8149 17.769 15.883 17.8689 15.9295 17.9792C15.976 18.0894 16 18.2079 16 18.3276C16 18.4473 15.976 18.5657 15.9295 18.676C15.883 18.7863 15.8149 18.8861 15.7292 18.9696C15.6457 19.0551 15.5458 19.1228 15.4354 19.1686C15.3251 19.2144 15.2066 19.2374 15.0871 19.2361Z" />
                                </svg>
                              </span>
                            </span>
                          </button>
                        </div>
                        <div className="form-buttons-container--primary" onClick={HandleClick
                          }>
                          <button
                            aria-label="Continue"
                            data-testid="FormButtonPrimary-disabled"
                            type="button"
                            // disabled
                            class="a83ed08757 c21c56c305 a4c1805887 f671049264 d2529514af c082d89982"
                          >
                            <span className="e4adce92df">Continue</span>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              id="automation_id_screen_id_facilities"
              style={{ display: "none" }}
            />
          </div>
          <div
            id="automation_id_flow_Segmentation_Flow"
            style={{ display: "none" }}
          ></div>
          <div></div>
        </div>
      </div>
    </div>
  );
};

export default Homeuse;
