import React, { useState } from "react";
import { Container, Form, Modal, Row, Table } from "react-bootstrap";
import { MdDelete } from "react-icons/md";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepButton from "@mui/material/StepButton";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { City, Country, State } from "country-state-city";
import axios from "axios";
import { CKEditor } from "@ckeditor/ckeditor5-react";

import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import CustomInput from "../../admin/components/CustomInput";
import VendorHeader from "./Vendorheader";
import { FormHelperText } from "@mui/material";
import Success from "../../assets/success.png";
import Error from "../../assets/close.png";
import { useEffect } from "react";
const steps = [
  "Package Details",
  "Everything user wants to know",
  "Gallery Photos",
  "Tour Details",
  "About The Tour",
  "Itinerary",

];
const AddInternational = (props) => {
  const vendor = JSON.parse(sessionStorage.getItem("vendor"));
  const [successshow, setsuccessshow] = useState(false);
  const handlesuccessClose = () => setsuccessshow(false);
  const handlesuccess = () => setsuccessshow(true);
  const handleKeyDown = (e) => {
    // Prevent the input of the minus sign
    if (e.key === '-' || e.key === 'e' || e.key === 'E') {
      e.preventDefault();
    }
  };
  const [errorshow, seterrorshow] = useState(false);
  const handleerrorClose = () => seterrorshow(false);
  const handleerror = () => seterrorshow(true);
  const { i_class, onBlr } = props;
  const [activeStep, setActiveStep] = React.useState(0);
  const [completed, setCompleted] = React.useState({});

  const totalSteps = () => {
    return steps.length;
  };

  const completedSteps = () => {
    return Object.keys(completed).length;
  };

  const isLastStep = () => {
    return activeStep === totalSteps() - 1;
  };

  const allStepsCompleted = () => {
    return completedSteps() === totalSteps();
  };

  const handleNext = () => {
    const newActiveStep =
      isLastStep() && !allStepsCompleted()
        ? steps.findIndex((step, i) => !(i in completed))
        : activeStep + 1;
    setActiveStep(newActiveStep);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStep = (step) => () => {
    setActiveStep(step);
  };

  const handleReset = () => {
    setActiveStep(0);
    setCompleted({});
  };

  // Tour Package Details

  const [Country1, setCountry1] = useState("");
  const [State1, setState1] = useState({ isoCode: "", name: "" });
  const [City1, setCity1] = useState("");
  console.log(State1);
  const CountryList = Country.getAllCountries();
  const StateList = State.getStatesOfCountry(Country1);
  const CityList = City.getCitiesOfState(Country1, State1?.isoCode);

  useEffect(() => {
    // Reset state and city when country changes
    setState1({ isoCode: "", name: "" });
    setCity1("");
  }, [Country1]);

  useEffect(() => {
    // Reset city when state changes
    setCity1("");
  }, [State1]);
  const [DestinationName, setDestinationName] = useState("");
  const [Destinationsubtitle, setDestinationsubtitle] = useState("");
  const [TripCost, setTripCost] = useState();
  const [PlaceDescription, setPlaceDescription] = useState("");
  const [PlaceCulture, setPlaceCulture] = useState("");
  const [PlaceBeaches, setPlaceBeaches] = useState("");
  const [PlaceHotels, setPlaceHotels] = useState("");
  const [PlacePopular, setPlacePopular] = useState("");
  const [PlaceIncluded, setPlaceIncluded] = useState("");
  const [TourType, setTourType] = useState("");
  const [GroupSize, setGroupSize] = useState();
  const [TourGuide, setTourGuide] = useState();
  const [TripDuration, setTripDuration] = useState("");
  //Details And Includes

  const handlePlace = (e, editor) => {
    const data = editor.getData();
    setPlaceDescription(data);
  };
  const handleCulture = (e, editor) => {
    const data = editor.getData();
    setPlaceCulture(data);
  };
  const handleBeaches = (e, editor) => {
    const data = editor.getData();
    setPlaceBeaches(data);
  };
  const handleHotel = (e, editor) => {
    const data = editor.getData();
    setPlaceHotels(data);
  };
  const handlePopular = (e, editor) => {
    const data = editor.getData();
    setPlacePopular(data);
  };
  const handleIncluded = (e, editor) => {
    const data = editor.getData();
    setPlaceIncluded(data);
  };
  const handleComplete = () => {
    const newCompleted = completed;
    newCompleted[activeStep] = true;
    setCompleted(newCompleted);
    if (activeStep === 0) {
      if (!DestinationName) {
        return (
          <>
            {alert("Enter Destination name")}
            {setActiveStep(0)}
          </>
        );
      } else if (!Destinationsubtitle) {
        return (
          <>
            {alert("Enter Destination subtitle")}
            {setActiveStep(0)}
          </>
        );
      } else if (!TripCost) {
        return (
          <>
            {alert("Enter Trip Cost as per duration..!")}
            {setActiveStep(0)}
          </>
        );
      // } else if (!TripDuration) {
      //   return (
      //     <>
      //       {alert("Enter Trip Duration..!")}
      //       {setActiveStep(0)}
      //     </>
      //   );
      } else if (!Country1) {
        return (
          <>
            {alert("Select Country")}
            {setActiveStep(0)}
          </>
        );
      } else if (!State1) {
        return (
          <>
            {alert("Select State")}
            {setActiveStep(0)}
          </>
        );
      } else if (!City1) {
        return (
          <>
            {alert("Select City")}
            {setActiveStep(0)}
          </>
        );
      }

      handleNext();
    }

    if (activeStep === 1) {
      if (!PlaceDescription) {
        return <>{alert("Enter user wants to know about your place")}</>;
        // } else

        //     if (galleryImages.length < 2) {
        //       return alert("Please add at least two images before adding more.");
      } else {
        // sessionStorage.setItem(
        //   "PlaceDescription",
        //   JSON.stringify(PlaceDescription)
        // );
        handleNext();
      }
    }
    if (activeStep === 2) {
      // if (!PlaceCulture) {
      //   return <>{alert("Enter culture at your place")}</>;
      // } else
       if (galleryImagesculture.length < 5) {
        return alert("Please add at least two images before adding more.");
      } else {
        // sessionStorage.setItem("PlaceCulture", JSON.stringify(PlaceCulture));

        handleNext();
      }
    }
    if (activeStep === 3) {
      // if (!PlaceBeaches) {
      //   return <>{alert("Enter beaches at your place")}</>;
      // } else if (galleryImagesbeaches.length < 2) {
      //   return alert("Please add at least two images before adding more.");
    if (!TourType) {
      return (
        <>
          {alert("Enter Tour Type")}
          {setActiveStep(3)}
        </>
      );
    } else if (!TourGuide) {
      return (
        <>
          {alert("Enter number of tour guide")}
          {setActiveStep(3)}
        </>
      );
    } else if (!GroupSize) {
      return (
        <>
          {alert("Select Group Size")}
          {setActiveStep(3)}
        </>
      );
     } else if (!TripDuration) {
        return (
          <>
            {alert("Enter Trip Duration..!")}
            {setActiveStep(3)}
          </>
        );
      } else {
        // sessionStorage.setItem("TourType", JSON.stringify(TourType));
        // sessionStorage.setItem("TourGuide", JSON.stringify(TourGuide));
        // sessionStorage.setItem("GroupSize", JSON.stringify(GroupSize));
        // sessionStorage.setItem("TripDuration", JSON.stringify(TripDuration));

        handleNext();
      }
    }
    if (activeStep === 4) {
      if (tourschedule.length === 0) {
        return <>{alert("Select Schedule")}</>;
      } else {
        // sessionStorage.setItem("PlaceHotels", JSON.stringify(PlaceHotels));

        handleNext();
      }
    }
    if (activeStep === 5) {
      if (Itinerary.length === 0) {
        return <>{alert("Select Itinerary")}</>;
      } else {
        // sessionStorage.setItem("PlacePopular", JSON.stringify(PlacePopular));

        handleNext();
      }
    }
    // if (activeStep === 6) {
    //   if (!PlaceIncluded) {
    //     return <>{alert("Enter whats included at your place")}</>;
    //   } else {
    //     handleNext();
    //   }
    // }
  };

  //

  // Add Gallery Image
  const [imageURL2, setimageURL2] = useState(null);
  const [Image, setImage] = useState("");
  const [galleryImages, setGalleryImages] = useState([]);
  const onImageChange2 = (event) => {
    if (event.target.files && event.target.files[0]) {
      setimageURL2(URL.createObjectURL(event.target.files[0]));
    }
  };
  const AddGalleryImage = () => {
    if (!Image) {
      return alert("Please, Select Image");
    }

    const imageExists = galleryImages.find((img) => img?.imgUrl === Image);

    if (imageExists) {
      return alert("Image already exists in the gallery.");
    }

    const obj = {
      image: Image,
      imgUrl: imageURL2,
    };

    setGalleryImages([...galleryImages, obj]);
    setImage("")
    setimageURL2(null)
    const fileInput = document.getElementById('fileInput');
    if (fileInput) {
      fileInput.value = null;
    }
  };
  const removeItem2 = (val) => {
    const updatedGallery = galleryImages.filter((item, index) => index !== val);
    setGalleryImages(updatedGallery);
  };
  // Culture Images
  const [imageURLculture, setimageURLculture] = useState(null);
  const [Imageculture, setImageculture] = useState("");
  const [galleryImagesculture, setGalleryImagesculture] = useState([]);
  const onImageChangeculture = (event) => {
    if (event.target.files && event.target.files[0]) {
      setimageURLculture(URL.createObjectURL(event.target.files[0]));
    }
  };

  const AddGalleryImageculture = () => {
    if (!Imageculture) {
      return alert("Please, Select Image");
    }

    const imageExists = galleryImagesculture.find(
      (img) => img?.imgUrl === Imageculture
    );

    if (imageExists) {
      return alert("Image already exists in the gallery.");
    }

    const obj = {
      image: Imageculture,
      imgUrl: imageURLculture,
    };

    setGalleryImagesculture([...galleryImagesculture, obj]);
    setImageculture("")
    setimageURLculture(null)
    const fileInput = document.getElementById('fileInput');
    if (fileInput) {
      fileInput.value = null;
    }
  };
  const removeItemculture = (val) => {
    const updatedGallery = galleryImagesculture.filter(
      (item, index) => index !== val
    );
    setGalleryImagesculture(updatedGallery);
  };
  // Beaches Images
  const [imageURLbeaches, setimageURLbeaches] = useState(null);
  const [Imagebeaches, setImagebeaches] = useState("");
  const [galleryImagesbeaches, setGalleryImagesbeaches] = useState([]);
  const onImageChangebeaches = (event) => {
    if (event.target.files && event.target.files[0]) {
      setimageURLbeaches(URL.createObjectURL(event.target.files[0]));
    }
  };

  const AddGalleryImagebeaches = () => {
    if (!Imagebeaches) {
      return alert("Please, Select Image");
    }

    const imageExists = galleryImagesbeaches.find(
      (img) => img?.imgUrl === Imagebeaches
    );

    if (imageExists) {
      return alert("Image already exists in the gallery.");
    }

    const obj = {
      image: Imagebeaches,
      imgUrl: imageURLbeaches,
    };

    setGalleryImagesbeaches([...galleryImagesbeaches, obj]);
  };
  const removeItembeaches = (val) => {
    const updatedGallery = galleryImagesbeaches.filter(
      (item, index) => index !== val
    );
    setGalleryImagesbeaches(updatedGallery);
  };
  // Hotels Images
  const [imageURLhotel, setimageURLhotel] = useState(null);
  const [Imagehotel, setImagehotel] = useState("");
  const [galleryImageshotel, setGalleryImageshotel] = useState([]);
  const onImageChangehotel = (event) => {
    if (event.target.files && event.target.files[0]) {
      setimageURLhotel(URL.createObjectURL(event.target.files[0]));
    }
  };

  const AddGalleryImagehotel = () => {
    if (!Imagehotel) {
      return alert("Please, Select Image");
    }

    const imageExists = galleryImageshotel.find(
      (img) => img?.imgUrl === Imagehotel
    );

    if (imageExists) {
      return alert("Image already exists in the gallery.");
    }

    const obj = {
      image: Imagehotel,
      imgUrl: imageURLhotel,
    };

    setGalleryImageshotel([...galleryImageshotel, obj]);
  };
  const removeItemhotel = (val) => {
    const updatedGallery = galleryImageshotel.filter(
      (item, index) => index !== val
    );
    setGalleryImageshotel(updatedGallery);
  };
  // Popular Images
  const [imageURLpopular, setimageURLpopular] = useState(null);
  const [Imagepopular, setImagepopular] = useState("");
  const [galleryImagespopular, setGalleryImagespopular] = useState([]);
  const onImageChangepopular = (event) => {
    if (event.target.files && event.target.files[0]) {
      setimageURLpopular(URL.createObjectURL(event.target.files[0]));
    }
  };

  const AddGalleryImagepopular = () => {
    if (!Imagepopular) {
      return alert("Please, Select Image");
    }

    const imageExists = galleryImagespopular.find(
      (img) => img?.imgUrl === Imagepopular
    );

    if (imageExists) {
      return alert("Image already exists in the gallery.");
    }

    const obj = {
      image: Imagepopular,
      imgUrl: imageURLpopular,
    };

    setGalleryImagespopular([...galleryImagespopular, obj]);
  };
  const removeItempopular = (val) => {
    const updatedGallery = galleryImagespopular.filter(
      (item, index) => index !== val
    );
    setGalleryImagespopular(updatedGallery);
  };

  // const PlaceDescription1 = JSON.parse(
  //   sessionStorage.getItem("PlaceDescription")
  // );
  // const PlaceCulture1 = JSON.parse(sessionStorage.getItem("PlaceCulture"));
  // const PlaceBeaches1 = JSON.parse(sessionStorage.getItem("PlaceBeaches"));
  // const PlaceHotels1 = JSON.parse(sessionStorage.getItem("PlaceHotels"));
  // const PlacePopular1 = JSON.parse(sessionStorage.getItem("PlacePopular"));
  const [tourschedule, settourschedule] = useState([]);
  const [PlaceName, setPlaceName] = useState("");
  const [Inclusion, setInclusion] = useState("");
  const [Exclusion, setExclusion] = useState("");
  // const [EventDate, setEventDate] = useState("");
  ////console.log();
  const AddTourSchedule = () => {
    if (!PlaceName) {
      return alert("Enter Place Name");
    }
    if (!Inclusion) {
      return alert("Enter Inclusion");
    }
    if (!Exclusion) {
      return alert("Enter Exclusion");
    }
    // if (!EventDate) {
    //   return alert("Enter EventDate");
    // }

    const scheduleExists = tourschedule?.filter(
      (ele) =>
        ele?.placename === PlaceName &&
        ele.inclusion === Inclusion &&
        ele?.exclusion === Exclusion 
    );

    if (!scheduleExists) {
      return alert("Details is  already exists.");
    }

    const obj = {
      placename: PlaceName,
      inclusion: Inclusion,
      exclusion: Exclusion,
     
    };

    settourschedule([...tourschedule, obj]);
    setPlaceName("");
    setInclusion("");
    setExclusion("");

  };
  const removeschedule = (val) => {
    const updatedGallery = tourschedule.filter((item, index) => index !== val);
    settourschedule(updatedGallery);
  };
  const [Itinerary, setItinerary] = useState([]);
  const [Header, setHeader] = useState("");
  const [ItineraryDesc, setItineraryDesc] = useState("");
 
  ////console.log();
  const AddItinerary = () => {
    if (!Header) {
      return alert("Enter Itinerary title");
    }
    if (!ItineraryDesc) {
      return alert("Enter Description");
    }
    

    const ItineraryExists = Itinerary?.filter(
      (ele) =>
        ele?.Header === Header &&
        ele.ItineraryDesc === ItineraryDesc 
    );

    if (!ItineraryExists) {
      return alert("Details is  already exists.");
    }

    const obj = {
      Header: Header,
      ItineraryDesc: ItineraryDesc,
      
    };

    setItinerary([...Itinerary, obj]);
    setHeader("");
    setItineraryDesc("");
   
  };
  const removeItinerary = (val) => {
    const updatedItinerary = Itinerary.filter((item, index) => index !== val);
    setItinerary(updatedItinerary);
  };

  const TourpackageDetails = async () => {
    try {
      const config = {
        url: "/admin/addinternational",
        method: "post",
        baseURL: "https://bookingoda.com/api",
        Headers: { "content-type": "multipart/form-data" },
        data: {
          UserId: vendor._id,
          DestinationName: DestinationName,
          Destinationsubtitle: Destinationsubtitle,
          tripcost: TripCost,
          PlaceDescription: PlaceDescription,

          // PlaceCulture: PlaceCulture1,
          // PlaceBeaches: PlaceBeaches1,
          // PlaceHotels: PlaceHotels1,
          // PlaceIncluded: PlaceIncluded,
          TourType:TourType,
          TourGuide:TourGuide,
          GroupSize:GroupSize,
          // PlacePopular: PlacePopular1,
          country: Country1,
          state: State1?.name,
          city: City1,
          tripduration: TripDuration,
          TourSchedule: tourschedule,
          Itinerary: Itinerary,
          status: "Pending",
        },
      };
      await axios(config).then(async (res) => {
        if (res.status === 200) {
          // for (let i = 0; i < galleryImages.length; i++) {
          //   const config = {
          //     url: "/admin/galleryimageuploadplace",
          //     method: "put",
          //     baseURL: "https://bookingoda.com/api",
          //     headers: { "content-type": "multipart/form-data" },
          //     data: {
          //       productId: res.data.success?._id,
          //       image: galleryImages[i]?.image,
          //     },
          //   };
          //   await axios(config);
          // }
          for (let i = 0; i < galleryImagesculture.length; i++) {
            const config = {
              url: "/admin/galleryimageuploadculture",
              method: "put",
              baseURL: "https://bookingoda.com/api",
              headers: { "content-type": "multipart/form-data" },
              data: {
                productId: res.data.success?._id,
                imageculture: galleryImagesculture[i]?.image,
              },
            };
            await axios(config);
          }
          // for (let i = 0; i < galleryImagesbeaches.length; i++) {
          //   const config = {
          //     url: "/admin/galleryimageuploadbeaches",
          //     method: "put",
          //     baseURL: "https://bookingoda.com/api",
          //     headers: { "content-type": "multipart/form-data" },
          //     data: {
          //       productId: res.data.success?._id,
          //       imagebeaches: galleryImagesbeaches[i]?.image,
          //     },
          //   };
          //   await axios(config);
          // }
          // for (let i = 0; i < galleryImageshotel.length; i++) {
          //   const config = {
          //     url: "/admin/galleryimageuploadhotel",
          //     method: "put",
          //     baseURL: "https://bookingoda.com/api",
          //     headers: { "content-type": "multipart/form-data" },
          //     data: {
          //       productId: res.data.success?._id,
          //       imagehotels: galleryImageshotel[i]?.image,
          //     },
          //   };
          //   await axios(config);
          // }
          // for (let i = 0; i < galleryImagespopular.length; i++) {
          //   const config = {
          //     url: "/admin/galleryimageuploadpopular",
          //     method: "put",
          //     baseURL: "https://bookingoda.com/api",
          //     headers: { "content-type": "multipart/form-data" },
          //     data: {
          //       productId: res.data.success?._id,
          //       imagepopular: galleryImagespopular[i]?.image,
          //     },
          //   };
          //   await axios(config);
          // }
          // window.sessionStorage.removeItem("PlaceDescription1");
          // window.sessionStorage.removeItem("PlaceCulture1");
          // window.sessionStorage.removeItem("PlaceBeaches1");
          // window.sessionStorage.removeItem("PlaceHotels1");
          // window.sessionStorage.removeItem("PlacePopular1");

          handlesuccess();
          // alert("Package Added Successfully");
          window.location.assign("/property");
        }
      });
    } catch (error) {
      handleerror();
      alert(error.response.data.error);
    }
  };

  return (
    <div
      data-capla-component="b-registration-joinapp15min-fe/"
      data-capla-namespace="b-registration-joinapp15min-feaYGUODfA"
    >
      <div id="app-container">
        <div class="error-popup-container"></div>
        <div class="application">
          <VendorHeader />
          <div>
            <Container fluid>
              <div className="d-flex justify-content-between align-items-center pt-4 pb-2">
                <p className="text-success fs-4 fw-semibold">
                  International Hoildays Vacations Details:
                </p>
              </div>

              <div className="Stepper-info" style={{ padding: "20px" }}>
                <Box sx={{ width: "100%" }}>
                  <Stepper nonLinear activeStep={activeStep}>
                    {steps.map((label, index) => (
                      <Step key={label} completed={completed[index]}>
                        <StepButton color="inherit" onClick={handleStep(index)}>
                          {label}
                        </StepButton>
                      </Step>
                    ))}
                  </Stepper>
                  <div>
                    {allStepsCompleted() ? (
                      <React.Fragment>
                        <Typography
                          sx={{ mt: 2, mb: 1 }}
                          style={{
                            position: "absolute",
                            top: "50%",
                            left: "50%",
                            fontSize: "20px",
                            fontWeight: "500",
                          }}
                        >
                          All steps completed successfully
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              alignSelf: "center",
                              pt: 2,
                            }}
                          >
                            <Button onClick={handleReset}>Back</Button>
                          </Box>
                        </Typography>
                      </React.Fragment>
                    ) : (
                      <React.Fragment>
                        {activeStep == 0 ? (
                          <>
                            <Typography sx={{ mt: 2, mb: 1, py: 1 }}>
                              <div
                                className="container"
                                style={{ padding: "5px" }}
                              >
                                <div className="row ">
                                  <div className="col-md-6">
                                    <div className="do-sear mt-2">
                                      <CustomInput
                                        type="text"
                                        label="Destination Name"
                                        name="name"
                                        onChng={(e) =>
                                          setDestinationName(e.target.value)
                                        }
                                        val={DestinationName}
                                      />
                                    </div>
                                  </div>
                                  <div className="col-md-6">
                                    <div className="do-sear mt-2">
                                      {/* <label>
                                  Trip Description
                                  <span style={{ color: "red" }}>*</span>
                                </label> */}
                                      <CustomInput
                                        type="text"
                                        label="Destination Sub-title"
                                        name="name"
                                        onChng={(e) =>
                                          setDestinationsubtitle(e.target.value)
                                        }
                                        val={Destinationsubtitle}
                                      />
                                    </div>
                                  </div>

                                  <div className="col-md-6">
                                    <div className="do-sear mt-3">
                                    <label >Country</label>

                                      <select
                                        class="ebf4591c8e country-select-for-e2e-tests"
                                        name="cc1"
                                        id="cc1"
                                        className="vi_0"
                                        style={{
                                          width: "100%",
                                          borderColor: "#E6E6E6",
                                        }}
                                        onChange={(e) =>
                                          setCountry1(e.target.value)
                                        }
                                      >
                                        <option value="">
                                          Select country/region
                                          <span style={{ color: "red" }}>
                                            *
                                          </span>
                                        </option>
                                        {CountryList?.map((Country) => (
                                          <option value={Country?.isoCode}>
                                            {Country?.name}
                                          </option>
                                        ))}
                                      </select>
                                      {/* // <FormControl fullWidth>
                                //   <InputLabel id="demo-simple-select-helper-label">
                                //     Select country/region{" "}
                                //     <span style={{ color: "red" }}>*</span>
                                //   </InputLabel>
                                //   <Select
                                //     labelId="demo-simple-select-helper-label"
                                //     id="demo-simple-select-helper"
                                //     value={Country1}
                                   
                                //     label="Select country/region"
                                //     onChange={handleChange}
                                //   >
                                 
                                //     {CountryList?.map((item) => (
                                //       <MenuItem value={item?.name}>
                                //         {item?.name}
                                //       </MenuItem>
                                //     ))}
                                //   </Select>
                                  
                                // </FormControl> */}
                                    </div>
                                  </div>
                                  <div className="col-md-6">
                                    <div className="do-sear mt-3">
                                    <label >State</label>

                                      <select
                                        class="ebf4591c8e country-select-for-e2e-tests"
                                        name="cc1"
                                        id="cc1"
                                        className="vi_0"
                                        style={{
                                          width: "100%",
                                          borderColor: "#E6E6E6",
                                        }}
                                        onChange={(e) => {
                                          const selectedState = StateList.find(
                                            (state) =>
                                              state.isoCode === e.target.value
                                          );
                                          setState1(
                                            selectedState
                                              ? {
                                                  isoCode: selectedState.isoCode,
                                                  name: selectedState.name,
                                                }
                                              : { isoCode: "", name: "" }
                                          );
                                        }}
                                      >
                                        <option value="">
                                          Select State 
                                          <span style={{ color: "red" }}>*</span>
                                        </option>
                                        {StateList?.map((state) => (
                                          <option
                                            key={state.isoCode}
                                            value={state.isoCode}
                                          >
                                            {state.name}
                                          </option>
                                        ))}
                                      </select>
                                    </div>
                                  </div>

                                  <div className="col-md-6">
                                    <div className="do-sear mt-3">
                                    <label >City</label>
                                      <select
                                        className="vi_0"
                                        // class="ebf4591c8e country-select-for-e2e-tests"
                                        style={{
                                          width: "100%",
                                          borderColor: "#E6E6E6",
                                        }}
                                        name="cc1"
                                        id="cc1"
                                        onChange={(e) =>
                                          setCity1(e.target.value)
                                        }
                                      >
                                        <option value="">
                                          Select City{" "}
                                          <span style={{ color: "red" }}>
                                            *
                                          </span>
                                        </option>
                                        {CityList?.map((city1) => (
                                          <option value={city1?.name}>
                                            {city1?.name}
                                          </option>
                                        ))}
                                      </select>
                                    </div>
                                  </div>

                                  {/* <div className="col-md-6">
                                    <div className="do-sear mt-2">
                                      <CustomInput
                                        type="text"
                                        label="Duration (eg: 3-4 nights)"
                                        name="name"
                                        onChng={(e) =>
                                          setTripDuration(e.target.value)
                                        }
                                        val={TripDuration}
                                      />
                                    </div>
                                  </div> */}
                                  <div className="col-md-6">
                                    <div className="do-sear mt-2">
                                      <CustomInput
                                        type="number" onKeyDown={handleKeyDown}
                                        label="Price as Per Duartion"
                                        name="name"
                                        onChng={(e) =>
                                          setTripCost(e.target.value)
                                        }
                                        val={TripCost}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </Typography>
                          </>
                        ) : activeStep == 1 ? (
                          <>
                            <Typography sx={{ mt: 2, mb: 1, py: 1 }}>
                              <div
                                className="container"
                                style={{ padding: "5px" }}
                              >
                                <div className="row ">
                                  <div className="col-md-6">
                                    <div className="do-sear mt-2">
                                      <label>
                                        Everything user wants to know
                                        <span style={{ color: "red" }}>*</span>
                                      </label>
                                      <CKEditor
                                        editor={ClassicEditor}
                                        data={PlaceDescription}
                                        onChange={handlePlace}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                              {/* <div className="row ">
                                  <div className="col-md-6">
                                    <div className="do-sear mt-2">
                                      <label>
                                        Attractive Place Photos
                                        <span style={{ color: "red" }}>
                                          *
                                        </span>
                                      </label>
                                      <div className=" mt-3">
                                        <input
                                          type="file"

                                          className={`form-control ${i_class}`}
                                          onBlur={onBlr}
                                          accept="image/*"
                                          onChange={(e) => {
                                            onImageChange2(e);
                                            setImage(e.target.files[0]);
                                          }}
                                        />
                                      </div>

                                    </div>

                                    <button
                                      className="pink-btn mt-4 "
                                      style={{ background: "green", color: "#fff", padding: 10 }}
                                      onClick={AddGalleryImage}
                                    >
                                      Add
                                    </button>
                                  </div>

                                  <div className="col-md-6">
                                    <Table responsive bordered>
                                      <thead>
                                        <tr>
                                          <th
                                            style={{
                                              background: "#d81d4a",
                                              color: "#fff",
                                            }}
                                          >
                                            S.No
                                          </th>
                                          <th
                                            style={{
                                              background: "#d81d4a",
                                              color: "#fff",
                                            }}
                                          >
                                            Image
                                          </th>
                                          <th
                                            style={{
                                              background: "#d81d4a",
                                              color: "#fff",
                                            }}
                                          >
                                            Action
                                          </th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        {galleryImages?.map((item, i) => {
                                          return (
                                            <tr>
                                              <td>{i + 1}</td>
                                              <td>
                                                <img
                                                  src={item?.imgUrl}
                                                  alt=""
                                                  style={{
                                                    width: "50px",
                                                    height: "50px",
                                                    objectFit: "cover",
                                                  }}
                                                />
                                              </td>
                                              <td>
                                                <div
                                                  className="d-flex gap-2 fs-4"
                                                  style={{
                                                    cursor: "pointer",
                                                  }}
                                                >
                                                 
                                                  <MdDelete
                                                    onClick={() =>
                                                      removeItem2(i)
                                                    }
                                                    className="text-danger"
                                                  />
                                                </div>
                                              </td>
                                            </tr>
                                          );
                                        })}
                                      </tbody>
                                    </Table>
                                  </div>
                                </div> */}
                            </Typography>
                          </>
                        ) : activeStep == 2 ? (
                          <>
                            <Typography sx={{ mt: 2, mb: 1, py: 1 }}>
                              <div
                                className="container"
                                style={{ padding: "5px" }}
                              >
                                {/* <div className="row ">
                                    <div className="col-md-6">
                                      <div className="do-sear mt-2">
                                        <label>
                                          Best Cultures at your place
                                          <span style={{ color: "red" }}>*</span>
                                        </label>
                                        <CKEditor editor={ClassicEditor}
                                          data={PlaceCulture}
                                          onChange={handleCulture} />


                                      </div>
                                    </div>


                                  </div> */}
                                <div className="row ">
                                  <div className="col-md-6">
                                    <div className="do-sear mt-2">
                                      <label>
                                        Attractive Photos
                                        <span style={{ color: "red" }}>*</span>
                                      </label>
                                      <div className=" mt-3">
                                        <input
                                          type="file"
                                          id="fileInput"
                                          className={`form-control ${i_class}`}
                                          onBlur={onBlr}
                                          accept="image/*"
                                          onChange={(e) => {
                                            onImageChangeculture(e);
                                            setImageculture(e.target.files[0]);
                                          }}
                                        />
                                      </div>
                                    </div>

                                    <button
                                      className="pink-btn mt-4 "
                                      style={{
                                        background: "green",
                                        color: "#fff",
                                        padding: 10,
                                      }}
                                      onClick={AddGalleryImageculture}
                                    >
                                      Add
                                    </button>
                                  </div>

                                  <div className="col-md-6">
                                    <Table responsive bordered>
                                      <thead>
                                        <tr>
                                          <th
                                            style={{
                                              background: "#d81d4a",
                                              color: "#fff",
                                            }}
                                          >
                                            S.No
                                          </th>
                                          <th
                                            style={{
                                              background: "#d81d4a",
                                              color: "#fff",
                                            }}
                                          >
                                            Image
                                          </th>
                                          <th
                                            style={{
                                              background: "#d81d4a",
                                              color: "#fff",
                                            }}
                                          >
                                            Action
                                          </th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        {galleryImagesculture?.map(
                                          (item, i) => {
                                            return (
                                              <tr>
                                                <td>{i + 1}</td>
                                                <td>
                                                  <img
                                                    src={item?.imgUrl}
                                                    alt=""
                                                    style={{
                                                      width: "50px",
                                                      height: "50px",
                                                      objectFit: "cover",
                                                    }}
                                                  />
                                                </td>
                                                <td>
                                                  <div
                                                    className="d-flex gap-2 fs-4"
                                                    style={{
                                                      cursor: "pointer",
                                                    }}
                                                  >
                                                    {/* <BiSolidEdit className="text-primary" /> */}
                                                    <MdDelete
                                                      onClick={() =>
                                                        removeItemculture(i)
                                                      }
                                                      className="text-danger"
                                                    />
                                                  </div>
                                                </td>
                                              </tr>
                                            );
                                          }
                                        )}
                                      </tbody>
                                    </Table>
                                  </div>
                                </div>
                              </div>
                            </Typography>
                          </>
                        ) : activeStep == 3 ? (
                          <>
                            <Typography sx={{ mt: 2, mb: 1, py: 1 }}>
                              <div
                                className="container"
                                style={{ padding: "5px" }}
                              >
                                <div className="row ">
                                  <div className="col-md-6">
                                    {/* <div className="do-sear mt-2">
                                        <label>
                                          Best Beaches To Visit
                                          <span style={{ color: "red" }}>*</span>
                                        </label>
                                        <CKEditor editor={ClassicEditor}
                                          data={PlaceBeaches}
                                          onChange={handleBeaches} />


                                      </div> */}
                                    <div className="do-sear mt-2">
                                      <CustomInput
                                        type="text"
                                        label="Tour Type"
                                        name="name"
                                        onChng={(e) =>
                                          setTourType(e.target.value)
                                        }
                                        val={TourType}
                                      />
                                    </div>
                                    <div className="do-sear mt-2">
                                      <CustomInput
                                        type="number" onKeyDown={handleKeyDown}
                                        label="Group Size"
                                        name="name"
                                        onChng={(e) =>
                                          setGroupSize(e.target.value)
                                        }
                                        val={GroupSize}
                                      />
                                    </div>
                                  
                                  </div>
                               
                                  <div className="col-md-6">
                                <div className="do-sear mt-2">
                                      <CustomInput
                                        type="number" onKeyDown={handleKeyDown}
                                        label="Number of Tour Guides"
                                        name="name"
                                        onChng={(e) =>
                                          setTourGuide(e.target.value)
                                        }
                                        val={TourGuide}
                                      />
                                    </div>
                                    <div className="do-sear mt-2">
                                      <CustomInput
                                        type="text"
                                        label="Duration (eg: 3N-4D)"
                                        name="name"
                                        onChng={(e) =>
                                          setTripDuration(e.target.value)
                                        }
                                        val={TripDuration}
                                      />
                                    </div>
                                    </div></div>
                                {/* <div className="row ">
                                    <div className="col-md-6">
                                      <div className="do-sear mt-2">
                                        <label>
                                          Attractive Beaches Images
                                          <span style={{ color: "red" }}>
                                            *
                                          </span>
                                        </label>
                                        <div className=" mt-3">
                                          <input
                                            type="file"

                                            className={`form-control ${i_class}`}
                                            onBlur={onBlr}
                                            accept="image/*"
                                            onChange={(e) => {
                                              onImageChangebeaches(e);
                                              setImagebeaches(e.target.files[0]);
                                            }}
                                          />
                                        </div>
                                       
                                      </div>

                                      <button
                                        className="pink-btn mt-4 "
                                        style={{ background: "green", color: "#fff", padding: 10 }}
                                        onClick={AddGalleryImagebeaches}
                                      >
                                        Add
                                      </button>
                                    </div>

                                    <div className="col-md-6">
                                      <Table responsive bordered>
                                        <thead>
                                          <tr>
                                            <th
                                              style={{
                                                background: "#d81d4a",
                                                color: "#fff",
                                              }}
                                            >
                                              S.No
                                            </th>
                                            <th
                                              style={{
                                                background: "#d81d4a",
                                                color: "#fff",
                                              }}
                                            >
                                              Image
                                            </th>
                                            <th
                                              style={{
                                                background: "#d81d4a",
                                                color: "#fff",
                                              }}
                                            >
                                              Action
                                            </th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                          {galleryImagesbeaches?.map((item, i) => {
                                            return (
                                              <tr>
                                                <td>{i + 1}</td>
                                                <td>
                                                  <img
                                                    src={item?.imgUrl}
                                                    alt=""
                                                    style={{
                                                      width: "50px",
                                                      height: "50px",
                                                      objectFit: "cover",
                                                    }}
                                                  />
                                                </td>
                                                <td>
                                                  <div
                                                    className="d-flex gap-2 fs-4"
                                                    style={{
                                                      cursor: "pointer",
                                                    }}
                                                  >
                                                   
                                                    <MdDelete
                                                      onClick={() =>
                                                        removeItembeaches(i)
                                                      }
                                                      className="text-danger"
                                                    />
                                                  </div>
                                                </td>
                                              </tr>
                                            );
                                          })}
                                        </tbody>
                                      </Table>
                                    </div>
                                  </div> */}
                              </div>
                            </Typography>
                          </>
                        ) : activeStep == 4 ? (
                          <>
                            {/* <Typography sx={{ mt: 2, mb: 1, py: 1 }}>
                              <div
                                className="container"
                                style={{ padding: "5px" }}
                              >
                                <div className="row ">
                                  <div className="col-md-6">
                                    <div className="do-sear mt-2">
                                      <label>
                                        Best Hotels To Stay
                                        <span style={{ color: "red" }}>*</span>
                                      </label>
                                      <CKEditor
                                        editor={ClassicEditor}
                                        data={PlaceHotels}
                                        onChange={handleHotel}
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div className="row ">
                                  <div className="col-md-6">
                                    <div className="do-sear mt-2">
                                      <label>
                                        Attractive Hotels Images
                                        <span style={{ color: "red" }}>*</span>
                                      </label>
                                      <div className=" mt-3">
                                        <input
                                          type="file"
                                          className={`form-control ${i_class}`}
                                          onBlur={onBlr}
                                          accept="image/*"
                                          onChange={(e) => {
                                            onImageChangehotel(e);
                                            setImagehotel(e.target.files[0]);
                                          }}
                                        />
                                      </div>
                                     
                                    </div>

                                    <button
                                      className="pink-btn mt-4 "
                                      style={{
                                        background: "green",
                                        color: "#fff",
                                        padding: 10,
                                      }}
                                      onClick={AddGalleryImagehotel}
                                    >
                                      Add
                                    </button>
                                  </div>

                                  <div className="col-md-6">
                                    <Table responsive bordered>
                                      <thead>
                                        <tr>
                                          <th
                                            style={{
                                              background: "#d81d4a",
                                              color: "#fff",
                                            }}
                                          >
                                            S.No
                                          </th>
                                          <th
                                            style={{
                                              background: "#d81d4a",
                                              color: "#fff",
                                            }}
                                          >
                                            Image
                                          </th>
                                          <th
                                            style={{
                                              background: "#d81d4a",
                                              color: "#fff",
                                            }}
                                          >
                                            Action
                                          </th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        {galleryImageshotel?.map((item, i) => {
                                          return (
                                            <tr>
                                              <td>{i + 1}</td>
                                              <td>
                                                <img
                                                  src={item?.imgUrl}
                                                  alt=""
                                                  style={{
                                                    width: "50px",
                                                    height: "50px",
                                                    objectFit: "cover",
                                                  }}
                                                />
                                              </td>
                                              <td>
                                                <div
                                                  className="d-flex gap-2 fs-4"
                                                  style={{
                                                    cursor: "pointer",
                                                  }}
                                                >
                                                
                                                  <MdDelete
                                                    onClick={() =>
                                                      removeItemhotel(i)
                                                    }
                                                    className="text-danger"
                                                  />
                                                </div>
                                              </td>
                                            </tr>
                                          );
                                        })}
                                      </tbody>
                                    </Table>
                                  </div>
                                </div>
                              </div>
                            </Typography> */}
                             <Typography sx={{ mt: 2, mb: 1, py: 1 }}>
                                <div
                                  className="container"
                                  style={{ padding: "5px" }}
                                >
                                  <div className="row ">
                                    <div className="col-md-5">
                                      <div className="do-sear mt-2">
                                        {/* <label>
                                          Place Name
                                          <span style={{ color: "red" }}>
                                            *
                                          </span> */}
                                        {/* </label> */}
                                        {/* <input
                                          type="text"
                                          placeholder="Enter Place Name"
                                          className="vi_0"
                                          onChange={(e) =>
                                            setPlaceName(e.target.value)
                                          }
                                        /> */}
                                        <CustomInput
                                          type="text"
                                          label="Place Name"
                                          name="name"
                                          onChng={(e) =>
                                            setPlaceName(e.target.value)
                                          }
                                          val={PlaceName}
                                        />
                                      </div>
                                      <div className="do-sear mt-2">
                                        {/* <label>
                                          Inclusion
                                          <span style={{ color: "red" }}>
                                            *
                                          </span>
                                        </label>
                                        <input
                                          type="text"
                                          placeholder="Enter Inclusion"
                                          className="vi_0"
                                          onChange={(e) =>
                                            setInclusion(e.target.value)
                                          }
                                        /> */}
                                        <CustomInput
                                          type="text"
                                          label="Inclusion"
                                          name="name"
                                          onChng={(e) =>
                                            setInclusion(e.target.value)
                                          }
                                          val={Inclusion}
                                        />
                                      </div>
                                      <div className="do-sear mt-2">
                                        {/* <label>
                                          Exclusion
                                          <span style={{ color: "red" }}>
                                            *
                                          </span>
                                        </label>
                                        <input
                                          type="text"
                                          placeholder="Enter Exclusion"
                                          className="vi_0"
                                          onChange={(e) =>
                                            setExclusion(e.target.value)
                                          }
                                        /> */}
                                        <CustomInput
                                          type="text"
                                          label="Exclusion"
                                          name="name"
                                          onChng={(e) =>
                                            setExclusion(e.target.value)
                                          }
                                          val={Exclusion}
                                        />
                                      </div>
                                      <div className="do-sear mt-2">
                                        {/* <label>
                                          Event Date
                                          <span style={{ color: "red" }}>
                                            *
                                          </span>
                                        </label>
                                        <input
                                          type="text"
                                          placeholder="Enter Event Date/Time"
                                          className="vi_0"
                                          onChange={(e) =>
                                            setEventDate(e.target.value)
                                          }
                                        /> */}
                                        {/* <CustomInput
                                          type="text"
                                          label="Enter Event Date/Time"
                                          name="name"
                                          onChng={(e) =>
                                            setEventDate(e.target.value)
                                          }
                                          val={EventDate}
                                        /> */}
                                      </div>

                                      <button
                                        onClick={AddTourSchedule}
                                        className="pink-btn mt-4"
                                        style={{
                                          background: "green",
                                          color: "#fff",
                                          padding: 10,
                                        }}
                                      >
                                        Add
                                      </button>
                                    </div>

                                    <div className="col-md-7">
                                      <Table responsive bordered>
                                        <thead>
                                          <tr>
                                            {/* <th
                                              style={{
                                                background: "#d81d4a",
                                                color: "#fff",
                                              }}
                                            >
                                              Itinerary
                                            </th> */}
                                            <th
                                              style={{
                                                background: "#d81d4a",
                                                color: "#fff",
                                              }}
                                            >
                                              Place Name
                                            </th>
                                            <th
                                              style={{
                                                background: "#d81d4a",
                                                color: "#fff",
                                              }}
                                            >
                                              Inclusion
                                            </th>
                                            <th
                                              style={{
                                                background: "#d81d4a",
                                                color: "#fff",
                                              }}
                                            >
                                              Exclusion
                                            </th>
                                            {/* <th
                                              style={{
                                                background: "#d81d4a",
                                                color: "#fff",
                                              }}
                                            >
                                              Event Date
                                            </th> */}
                                            <th
                                              style={{
                                                background: "#d81d4a",
                                                color: "#fff",
                                              }}
                                            >
                                              Action
                                            </th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                          {tourschedule?.map((item, i) => {
                                            return (
                                              <tr>
                                                {/* <td>Day {i + 1}</td> */}
                                                <td>{item?.placename}</td>
                                                <td>{item?.inclusion}</td>
                                                <td>{item?.exclusion}</td>
                                                {/* <td>{item?.eventDate}</td> */}
                                                <td>
                                                  <div
                                                    className="d-flex gap-2 fs-4"
                                                    style={{
                                                      cursor: "pointer",
                                                    }}
                                                  >
                                                    {/* <BiSolidEdit className="text-primary" /> */}
                                                    <MdDelete
                                                      onClick={() =>
                                                        removeschedule(i)
                                                      }
                                                      className="text-danger"
                                                    />
                                                  </div>
                                                </td>
                                              </tr>
                                            );
                                          })}
                                        </tbody>
                                      </Table>
                                    </div>
                                  </div>
                                </div>
                              </Typography>
                          </>
                        ) : activeStep == 5 ? (
                          <>
                            {/* <Typography sx={{ mt: 2, mb: 1, py: 1 }}>
                              <div
                                className="container"
                                style={{ padding: "5px" }}
                              >
                                <div className="row ">
                                  <div className="col-md-6">
                                    <div className="do-sear mt-2">
                                      <label>
                                        Popular at your place
                                        <span style={{ color: "red" }}>*</span>
                                      </label>
                                      <CKEditor
                                        editor={ClassicEditor}
                                        data={PlacePopular}
                                        onChange={handlePopular}
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div className="row ">
                                  <div className="col-md-6">
                                    <div className="do-sear mt-2">
                                      <label>
                                        Attractive Popular Images
                                        <span style={{ color: "red" }}>*</span>
                                      </label>
                                      <div className=" mt-3">
                                        <input
                                          type="file"
                                          className={`form-control ${i_class}`}
                                          onBlur={onBlr}
                                          accept="image/*"
                                          onChange={(e) => {
                                            onImageChangepopular(e);
                                            setImagepopular(e.target.files[0]);
                                          }}
                                        />
                                      </div>
                                    
                                    </div>

                                    <button
                                      className="pink-btn mt-4 "
                                      style={{
                                        background: "green",
                                        color: "#fff",
                                        padding: 10,
                                      }}
                                      onClick={AddGalleryImagepopular}
                                    >
                                      Add
                                    </button>
                                  </div>

                                  <div className="col-md-6">
                                    <Table responsive bordered>
                                      <thead>
                                        <tr>
                                          <th
                                            style={{
                                              background: "#d81d4a",
                                              color: "#fff",
                                            }}
                                          >
                                            S.No
                                          </th>
                                          <th
                                            style={{
                                              background: "#d81d4a",
                                              color: "#fff",
                                            }}
                                          >
                                            Image
                                          </th>
                                          <th
                                            style={{
                                              background: "#d81d4a",
                                              color: "#fff",
                                            }}
                                          >
                                            Action
                                          </th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        {galleryImagespopular?.map(
                                          (item, i) => {
                                            return (
                                              <tr>
                                                <td>{i + 1}</td>
                                                <td>
                                                  <img
                                                    src={item?.imgUrl}
                                                    alt=""
                                                    style={{
                                                      width: "50px",
                                                      height: "50px",
                                                      objectFit: "cover",
                                                    }}
                                                  />
                                                </td>
                                                <td>
                                                  <div
                                                    className="d-flex gap-2 fs-4"
                                                    style={{
                                                      cursor: "pointer",
                                                    }}
                                                  >
                                                  
                                                    <MdDelete
                                                      onClick={() =>
                                                        removeItempopular(i)
                                                      }
                                                      className="text-danger"
                                                    />
                                                  </div>
                                                </td>
                                              </tr>
                                            );
                                          }
                                        )}
                                      </tbody>
                                    </Table>
                                  </div>
                                </div>
                              </div>
                            </Typography> */}
                               <Typography sx={{ mt: 2, mb: 1, py: 1 }}>
                                <div
                                  className="container"
                                  style={{ padding: "5px" }}
                                >
                                  <div className="row ">
                                    <div className="col-md-5">
                                      <div className="do-sear mt-2">
                                       
                                        <CustomInput
                                          type="text"
                                          label="Itinerary Title"
                                          name="name"
                                          onChng={(e) =>
                                            setHeader(e.target.value)
                                          }
                                          val={Header}
                                        />
                                      </div>
                                      <div className="do-sear mt-2">
                                      <label>
                                          Description
                                          <span style={{ color: "red" }}>
                                            *
                                          </span> 
                                 </label>
                                        <textarea
                                          type="text"
                                          label="Inclusion"
                                          name="name"
                                          onChange={(e) =>
                                            setItineraryDesc(e.target.value)
                                          }
                                          value={ItineraryDesc}
                                        />
                                      </div>
                                     

                                      <button
                                        onClick={AddItinerary}
                                        className="pink-btn mt-4"
                                        style={{
                                          background: "green",
                                          color: "#fff",
                                          padding: 10,
                                        }}
                                      >
                                        Add
                                      </button>
                                    </div>

                                    <div className="col-md-7">
                                      <Table responsive bordered>
                                        <thead>
                                          <tr>
                                            <th
                                              style={{
                                                background: "#d81d4a",
                                                color: "#fff",
                                              }}
                                            >
                                              Itinerary
                                            </th>
                                            <th
                                              style={{
                                                background: "#d81d4a",
                                                color: "#fff",
                                              }}
                                            >
                                              Title
                                            </th>
                                            <th
                                              style={{
                                                background: "#d81d4a",
                                                color: "#fff",
                                              }}
                                            >
                                              Description
                                            </th>
                                            
                                           
                                            <th
                                              style={{
                                                background: "#d81d4a",
                                                color: "#fff",
                                              }}
                                            >
                                              Action
                                            </th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                          {Itinerary?.map((item, i) => {
                                            return (
                                              <tr>
                                                <td>Day {i + 1}</td>
                                                <td>{item?.Header}</td>
                                                <td>{item?.ItineraryDesc}</td>
                                               
                                                <td>
                                                  <div
                                                    className="d-flex gap-2 fs-4"
                                                    style={{
                                                      cursor: "pointer",
                                                    }}
                                                  >
                                                    {/* <BiSolidEdit className="text-primary" /> */}
                                                    <MdDelete
                                                      onClick={() =>
                                                        removeItinerary(i)
                                                      }
                                                      className="text-danger"
                                                    />
                                                  </div>
                                                </td>
                                              </tr>
                                            );
                                          })}
                                        </tbody>
                                      </Table>
                                    </div>
                                  </div>
                                </div>
                              </Typography>
                          </>
                        ) : 
                        // activeStep == 6 ? (
                        //   <>
                        //     <Typography sx={{ mt: 2, mb: 1, py: 1 }}>
                        //       <div
                        //         className="container"
                        //         style={{ padding: "5px" }}
                        //       >
                        //         <div className="row ">
                        //           <div className="col-md-6">
                        //             <div className="do-sear mt-2">
                        //               <label>
                        //                 What's Included
                        //                 <span style={{ color: "red" }}>*</span>
                        //               </label>
                        //               <CKEditor
                        //                 editor={ClassicEditor}
                        //                 data={PlaceIncluded}
                        //                 onChange={handleIncluded}
                        //               />
                        //             </div>
                        //           </div>
                        //         </div>
                        //       </div>
                        //     </Typography>
                        //   </>
                        // ) : 
                        (
                          <></>
                        )}

                        <Box
                          sx={{ display: "flex", flexDirection: "row", pt: 2 }}
                        >
                          <Button
                            style={{ background: "green", color: "#fff" }}
                            disabled={activeStep === 0}
                            onClick={handleBack}
                            sx={{ mr: 1 }}
                          >
                            Back
                          </Button>
                          <Box sx={{ flex: "1 1 auto" }} />

                          <button
                            className="pink-btn"
                            style={{
                              background: "green",
                              color: "#fff",
                              padding: 10,
                            }}
                            onClick={() => {
                              completedSteps() === totalSteps() - 1
                                ? TourpackageDetails()
                                : handleComplete();
                            }}
                          >
                            {completedSteps() === totalSteps() - 1
                              ? "Submit"
                              : "Save"}
                          </button>
                        </Box>
                      </React.Fragment>
                    )}
                  </div>
                </Box>
              </div>
            </Container>
          </div>
          <div
            id="automation_id_flow_Segmentation_Flow"
            style={{ display: "none" }}
          ></div>
          <div></div>
        </div>
      </div>
      <img
        alt=""
        height="1"
        width="1"
        style={{ display: "none" }}
        src="https://booking.com/track_join_app_landing?progress_status=2"
      />
      <Modal
        show={successshow}
        onHide={handlesuccess}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton onClick={handlesuccessClose}></Modal.Header>
        <Modal.Body>
          <div className="text-center">
            <img src={Success} style={{ marginTop: "-12px" }} width="100px" />

            <p>Successfully Added</p>
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        show={errorshow}
        onHide={handleerror}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton onClick={handleerrorClose}></Modal.Header>
        <Modal.Body>
          <div className="text-center">
            <img src={Error} style={{ marginTop: "-12px" }} width="100px" />

            <p>Error Occured</p>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};
export default AddInternational;
