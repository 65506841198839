import { CKEditor } from "@ckeditor/ckeditor5-react";
import React, { useEffect, useState } from "react";
import { Container, Form, Modal } from "react-bootstrap";
import { Button, Table ,DatePicker} from "antd";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepButton from "@mui/material/StepButton";
import { City, Country, State } from "country-state-city";
import Switch from "react-switch";
import Table1 from "react-bootstrap/Table";
import Typography from "@mui/material/Typography";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import {  Input, Space } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { BiSolidEdit } from "react-icons/bi";
import { MdDelete } from "react-icons/md";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { BsSearch } from "react-icons/bs";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCancel } from "@fortawesome/free-solid-svg-icons";

import CustomInput from "../components/CustomInput";
import { AiFillDelete } from "react-icons/ai";
import * as XLSX from "xlsx";
import moment from "moment/moment";
const steps = [
  "Package Details",
  "Details and Includes",
  "Gallery",
  

];
function VacationList(props) {
    const [selectedModal, setselectedModal] = useState({});
  const [show, setShow] = useState(false);
  const [show1, setShow1] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleShow1 = (id) => {
    setShow1(true);
    setselectedModal(id);
  };
  const handleClose1 = () => setShow1(false);


  const [show2, setShow2] = useState(false);
  const handleClose2 = () => setShow2(false);
  const handleShow2 = () => setShow2(true);
  const [showI, setShowI] = useState(false);
  const handleCloseI = () => setShowI(false);
  const handleShowI = () => setShowI(true);
  const [show4, setShow4] = useState(false);
  const handleClose4 = () => setShow4(false);
  const handleShow4 = () => setShow4(true);
  const [FullView, setFullView] = useState({});

  const [show3, setShow3] = useState(false);
  const handleClose3 = () => setShow3(false);
  const handleShow3 = (item) => {
    setShow3(true);
    setdata(item);
  };

  // Get Tour Packages

  const [Viewgallery, setViewgallery] = useState({});
  const [Vacationlist, setVacationlist] = useState([]);
  const getVacation = async () => {
    try {
      const res = await axios.get(
        "https://bookingoda.com/api/admin/getvacation"
      );
      if (res.status === 200) {
        setVacationlist(res.data.getvacation);
      }
    } catch (error) {
      // ////console.log(error);
    }
  };
  const block = async (data) => {
    try {
      axios
        .get(`https://bookingoda.com/api/admin/blockvacation/` + data._id)
        .then(function (response) {
          if (response.status === 200) {
            ////console.log(response.data);
            alert("Blocked successfully");
            window.location.reload();
          }
        })
        .catch(function (error) {
          // setdatacondition(true);

          ////console.log(error.message.data);
          alert("Not able to complete");
        });
    } catch (error) {
      console.warn(error);
      alert("Not able to complete");
      // setdatacondition(true);
      return error;
    }
  };

  const unblock = async (data) => {
    try {
      axios
        .get(`https://bookingoda.com/api/admin/unblockvacation/` + data._id)
        .then(function (response) {
          if (response.status === 200) {
            ////console.log(response.data);
            alert("Unblocked successfully");
            window.location.reload();
          }
        })
        .catch(function (error) {
          // setdatacondition(true);

          ////console.log(error.message.data);
          alert("Not able to complete");
        });
    } catch (error) {
      console.warn(error);
      alert("Not able to complete");
      // setdatacondition(true);
      return error;
    }
  };
  // Delete
  const [Data, setdata] = useState("");
  const DeleteStay = async () => {
    try {
      const config = {
        url: "admin/daletevacation/" + Data,
        baseURL: "https://bookingoda.com/api",
        method: "DELETE",
        Headers: { "Content-Type": "application-json" },
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert(res.data.success);
          handleClose3();
          getVacation();
        }
      });
    } catch (error) {
      alert(error.response.data.error);
    }
  };

 
 

  useEffect(() => {
    getVacation();
  }, []);
  const [data1, setData1] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  
  useEffect(() => {
    // Fetch data asynchronously (replace fetchDataCar with your actual data fetching logic)
    getVacation().then((result) => {
      setVacationlist(result);
    });
  }, []);
  useEffect(() => {
  
    setIsCheckedSmoking(selectedModal.Smoking !== "false" ? true : false);
    setIsCheckedPets(selectedModal.Pets !== "false" ? true : false);
    setIsCheckedParties(selectedModal.Parties !== "false" ? true : false);
    setIsCheckedChildren(selectedModal.Children  !== "false" ? true : false);
  }, [selectedModal]);
  useEffect(() => {
    // Ensure datacar is not undefined before transforming it
    if (Vacationlist && Vacationlist.length > 0) {
      const transformedData = transformData(Vacationlist);
      setData1(transformedData);
      setFilteredData(transformedData);
    }
  }, [Vacationlist]);
  const transformData = (Vacationlist) => {
    
    return  Vacationlist.map((item,i) => {
      const PriceDetails = (
        <div>
          <p> Adult Price: + ₹{item.staycost} ,</p>
          <p> Adult Discount:  + ₹{item.staydiscount} %,</p>
          <p> Children Price:  + ₹{item.childrencost}  ,</p>
          <p> Children Discount:  + ₹{item.childrendiscount}  %,</p>
          <p> Pet Price:  + ₹{item.petscost} ,</p>
          <p> Pet Discount:  + ₹{item.petdiscount} %</p>
        </div>
      );
  
        return {
        Sno:i+1,
        UniqueId: "BOVAC" + item._id.slice(22, 24).toUpperCase(),
        StayName: item.stayname,
        // VendorId: item.UserId,
    date:moment(item.createdAt).format("DD/MM/YYYY"),

        staydescription: item.staydescription,
      

        locations:
          item.city +
          "," +
          item.state +
          "," +
          item.country,
        Price: item.staycost,
        action2: (
          <>
            <button
              className="btn"
              style={{ backgroundColor: "#1677ff", color: "white" }}
              onClick={() => {
                handleShow4();
                setFullView(item);
              }}
            >
              Vacation Details
            </button>
          </>
        ),
      
       
        action: (
          <>
            <div className="d-flex gap-4 fs-8" style={{ cursor: "pointer"}}>
            
             
            </div>
            <div style={{display:'flex',flexDirection:'row',justifyContent:'space-around',margin:"10px"}}>
              {/* <BiSolidEdit
              fontSize={24}
                className="text-primary"
                onClick={() => handleShow1(item)}
              />
              <MdDelete
               fontSize={24}
                className="text-danger"
                onClick={() => handleShow3(item?._id)}
              /> */}
                {!item.blockstatus ? (
                <button
                  className="btn btn-outline-success"
                  onClick={() => block(item)}
                >
                  Block
                </button>
              ) : (
                <button
                  className="btn btn-outline-danger"
                  onClick={() => unblock(item)}
                >
                  Un-Block
                </button>
              )}
                <button
                  className="btn btn-outline-success"
                  onClick={() => handleShow1(item)}
                >
                 Edit
                </button>
                <button
                  className="btn btn-outline-danger"
                  onClick={() => handleShow3(item._id)}
                >
                 Delete
                </button>
              </div>
          </>
          ),
        };
      });
    
     
    };


 

  const { i_class,onBlr} = props;
  const [activeStep, setActiveStep] = React.useState(0);
  const [completed, setCompleted] = React.useState({});

  const totalSteps = () => {
    return steps.length;
  };

  const completedSteps = () => {
    return Object.keys(completed).length;
  };

  const isLastStep = () => {
    return activeStep === totalSteps() - 1;
  };

  const allStepsCompleted = () => {
    return completedSteps() === totalSteps();
  };

  const handleNext = () => {
    const newActiveStep =
      isLastStep() && !allStepsCompleted()
        ? steps.findIndex((step, i) => !(i in completed))
        : activeStep + 1;
    setActiveStep(newActiveStep);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStep = (step) => () => {
    setActiveStep(step);
  };

  const handleReset = () => {
    setActiveStep(0);
    setCompleted({});
  };

  // ////console.log("completed", completed);
  // Tour Package Details


  const [street, setStreet] = useState("");
 
  const [Country1, setCountry1] = useState("");
  const [State1, setState1] = useState({ isoCode: "", name: "" });
  const [City1, setCity1] = useState("");
  console.log(State1);
  const CountryList = Country.getAllCountries();
  const StateList = State.getStatesOfCountry(Country1);
  const CityList = City.getCitiesOfState(Country1, State1?.isoCode);

  useEffect(() => {
    // Reset state and city when country changes
    setState1({ isoCode: "", name: "" });
    setCity1("");
  }, [Country1]);

  useEffect(() => {
    // Reset city when state changes
    setCity1("");
  }, [State1]);
  const [StayName, setStayName] = useState("");
  const [StayDescription, setStayDescription] = useState("");
  const [StayCost, setStayCost] = useState();
  const [StayDiscount, setStayDiscount] = useState();
  const [ChildrenCost, setChildrenCost] = useState();
  const [Childrendiscount, setChildrendiscount] = useState();
  const [Petcost, setPetcost] = useState();
  const [Petdiscount, setPetdiscount] = useState();
  const [StayDuration, setStayDuration] = useState("");
  
  const [Measurement, setMeasurement] = useState("");
  const [Rooms, setRooms] = useState("");
  const [Beds, setBeds] = useState("");
  const [Bathrooms, setBathrooms] = useState("");
  //Details And Includes
  const [Sightseeing, setSightseeing] = useState("");
  const [Hotels, setHotels] = useState("");
  const [Transport, setTransport] = useState("");
  

  const handleChange3 = (event) => {
    setSightseeing(event.target.value);
  };
  const handleChange4 = (event) => {
    setHotels(event.target.value);
  };
  const handleChange5 = (event) => {
    setTransport(event.target.value);
  };
  const handleComplete = () => {
    const newCompleted = completed;
    newCompleted[activeStep] = true;
    setCompleted(newCompleted);
    if (activeStep === 0) {
      
      handleNext();
    }

    if (activeStep === 1) {
      
        handleNext();
      }
    
    if (activeStep === 2) {
     
        handleNext();
      }
    

    if (activeStep === 3) {
     handleNext();
      }
      if (activeStep === 4) {
        handleNext();
         }
  }
  

  //

  // Add Gallery Image
  const [imageURL2, setimageURL2] = useState(null);
  const [Image, setImage] = useState("");
  const [galleryImages, setGalleryImages] = useState([]);
  const onImageChange2 = (event) => {
    if (event.target.files && event.target.files[0]) {
      setimageURL2(URL.createObjectURL(event.target.files[0]));
      
    }
  };

  const AddGalleryImage = () => {
    if (!Image) {
      return alert("Please, Select Image");
    }

    const imageExists = galleryImages.find((img) => img?.imgUrl === Image);

    if (imageExists) {
      return alert("Image already exists in the gallery.");
    }

    const obj = {
      image: Image,
      imgUrl: imageURL2,
    };

    setGalleryImages([...galleryImages, obj]);
    setImage("")
    setimageURL2(null)
    const fileInput = document.getElementById('fileInput');
    if (fileInput) {
      fileInput.value = null;
    }
  };
  const removeItem2 = (val) => {
    const updatedGallery = galleryImages.filter((item, index) => index !== val);
    setGalleryImages(updatedGallery);
  };

  // Add Tour Schedule

 

  const [checkboxesAmenities, setCheckboxesAmenities] = useState({
    "Air Conditioning": false,
    "Bathrobes": false,
    "Dry cleaning": false,
    "Fitness center": false,
    "Hair dryer":false,
    "High chair":false,
    "Mini bar":false,
    "Restaurant":false,
    "Slippers":false,
    "Telephone":false,
    "Wake-up call":false,
    "Wifi":false,

  });

  const [newCheckboxAmenities, setNewCheckboxAmenities] = useState("");

  const handleCheckboxChangeAmenities = (option) => {
    setCheckboxesAmenities((prevCheckboxes1) => ({
      ...prevCheckboxes1,
      [option]: !prevCheckboxes1[option],
    }));
  };

  const handleInputChangeAmenities = (event) => {
    setNewCheckboxAmenities(event.target.value);
  };

  const handleAddCheckboxAmenities = () => {
    if (newCheckboxAmenities) {
      setCheckboxesAmenities((prevCheckboxes1) => ({
        ...prevCheckboxes1,
        [newCheckboxAmenities]: false,
      }));
      setNewCheckboxAmenities("");
    }
  };
  const [isCheckedSmoking, setIsCheckedSmoking] = useState(false);

  const [isCheckedPets, setIsCheckedPets] = useState(false);
  const [isCheckedParties, setIsCheckedParties] = useState(false);
  const [isCheckedChildren, setIsCheckedChildren] = useState(false);

  const handleToggleSmoking = () => {
    setIsCheckedSmoking(!isCheckedSmoking);
  };
  const handleTogglePets = () => {
    setIsCheckedPets(!isCheckedPets);
  };
  const handleToggleParties = () => {
    setIsCheckedParties(!isCheckedParties);
  };
  const handleToggleChildren = () => {
    setIsCheckedChildren(!isCheckedChildren);
  };

  
  const TourpackageDetails = async () => {
    let adultofferprice = StayCost - (StayCost * StayDiscount) / 100;
    let childrenofferprice =
      ChildrenCost - (ChildrenCost * Childrendiscount) / 100;

    try {
      const config = {
        url: "/admin/updatevacation",
        method: "put",
        baseURL: "https://bookingoda.com/api",
        Headers: { "content-type": "multipart/form-data" },
        data: {
          // UserId:admin._id,
          techid: selectedModal._id,

          stayname: StayName ? StayName : selectedModal.stayname,
          staydescription: StayDescription ? StayDescription : selectedModal.staydescription,
          staycost: StayCost ? StayCost : selectedModal.staycost,
          staydiscount: StayDiscount ?  StayDiscount : selectedModal.staydiscount,
          offerprice: adultofferprice ? adultofferprice : selectedModal.offerprice,
          country: Country1 ? Country1 : selectedModal.country,
          state: State1?.name ? State1?.name  : selectedModal.state,
          city: City1 ? City1 : selectedModal.city,
          street: street ? street : selectedModal.street,

          childrencost: ChildrenCost ? ChildrenCost :selectedModal.childrencost,
          childrendiscount: Childrendiscount ?  Childrendiscount : selectedModal.childrendiscount,
          childrenoffer: childrenofferprice ? childrenofferprice : selectedModal.childrenoffer,
         
          Measurement: Measurement ? Measurement : selectedModal.Measurement,
          Beds: Beds ? Beds : selectedModal.Beds,
          Rooms: Rooms ? Rooms : selectedModal.Rooms,
          Bathrooms: Bathrooms ?  Bathrooms : selectedModal.Bathrooms,
          Amenities:Object.values(checkboxesAmenities).every((value) => value === false) ===
          false
          ? JSON.stringify(checkboxesAmenities)
          : JSON.stringify(selectedModal.Amenities),
          Smoking:isCheckedSmoking ? isCheckedSmoking : selectedModal.Smoking,
          Parties:isCheckedParties ? isCheckedParties : selectedModal.Parties,
          Children:isCheckedChildren ? isCheckedChildren : selectedModal.Children,
          Pets:isCheckedPets ? isCheckedPets : selectedModal.Pets,
        

        },
      };
      await axios(config).then(async (res) => {
        if (res.status === 200) {
          for (let i = 0; i < galleryImages.length; i++) {
            const config = {
              url: "/admin/galleryvacationupload",
              method: "put",
              baseURL: "https://bookingoda.com/api",
              headers: { "content-type": "multipart/form-data" },
              data: {
                productId: selectedModal?._id,
                image: galleryImages[i]?.image,
              },
            };
            await axios(config);
          }
          alert("Vacation list Updated Successfully");
          window.location.assign("/admindashboard/vacationlist");
        }
      });
      // ////console.log("config", config);
    } catch (error) {
      // ////console.log(error);
    }
  };
 
  const columns = [
    {
      title: "Serial No.",
      dataIndex: "Sno",

      // //fixed: "left",
      width: 100,
    },
    {
      title: "Unique ID",
      dataIndex: "UniqueId",
       
       // //fixed: "left",
       width: 150,
     
    },
    {
      title: "Stay Name",
      dataIndex: "StayName",
      

      width: 200,
      
      
   
    },
 
    // {
    //   title: "Vendor ID",
    //   dataIndex: "UserId",
    //   width: 150,

    //   // //fixed: "left",
    //   // width: 50,
    // },
    {
      title: "Created Date",
      dataIndex: "date",
      width: 150,
     
    },
    {
      title: "Tour Details",
      dataIndex: "action2",
      key: "operation",
      // //fixed: "right",

      // render: () => <Link className="ms-3 fs-8 text-success">Approve</Link>,
      // width: 50,
      width: 150,
    },
   
    // {
    //   title: "Itinerary", 

    //   dataIndex: "action3",
    //   key: "operation",
    //   width: 150,
    //   // //fixed: "right",
  
    //   // render: () => <Link className="ms-3 fs-8 text-success">Approve</Link>,
   
    // },
    {
      title: "Action",
      dataIndex: "action",
      key: "operation",
      width: 300,
      // //fixed: "right",
  
      // render: () => <Link className="ms-3 fs-8 text-success">Approve</Link>,
     
    },
  ];
  const ExportToExcelButton = ({ dataSource, columns, filename }) => {
    const exportToExcel = () => {
      const worksheet = XLSX.utils.json_to_sheet(dataSource);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet 1");
      XLSX.writeFile(workbook, `${filename}.xlsx`);
    };

    return (
      <Button
        type="primary"
        onClick={exportToExcel}
        style={{
          margin: "10px",
          alignSelf: "flex-end",
          backgroundColor: "#1677ff",
          color: "white",
        }}
      >
        Export to Excel
      </Button>
    );
  };

  const [searchTerm, setSearchTerm] = useState("");
  // const [filteredData, setFilteredData] = useState(data1);
  ////console.log(filteredData, "vgvg");
  const handles = (value) => {
    setSearchTerm(value);

    // Filter the data based on the search term
    const filteredDataSource = data1.filter((record) => {
      return Object.values(record).some(
        (val) =>
          val && val.toString().toLowerCase().includes(value.toLowerCase())
      );
    });

    setFilteredData(filteredDataSource);
  };

  const onClearSearch = () => {
    setSearchTerm("");
    setFilteredData(data1);
  };

  const searchInput = (
    <Input
      placeholder="Search"
      value={searchTerm}
      style={{width:"50%"}}
      onChange={(e) => handles(e.target.value)}
      suffix={
        searchTerm && (
          <span
            onClick={onClearSearch}
            style={{ cursor: "pointer", color: "grey" }}
          >
            Clear
          </span>
        )
      }
      prefix={<SearchOutlined />}
    />
  );

  const pageSize = 5;
  //console.log(Country1,"sdsds");
  const [selectedImages, setSelectedImages] = useState([]);

  const toggleImageSelection = (imageId) => {
    setSelectedImages((prevSelected) => {
      if (prevSelected.includes(imageId)) {
        return prevSelected.filter((id) => id !== imageId);
      } else {
        return [...prevSelected, imageId];
      }
    });
  };
  useEffect(() => {
    if (selectedModal && selectedModal.Amenities) {
      setCheckboxesAmenities((prev) => ({
        ...prev,
        ...selectedModal.Amenities,
      }));
    }
  }, [selectedModal]);
  const deleteSelectedImages = async () => {
    try {
      await removeVacation(FullView._id, selectedImages);

      alert("Successfully removed");
      setSelectedImages([]);
    } catch (error) {
      if (error.response) {
        alert(error.response.data.error);
      } else {
        alert("Something went wrong");
      }
    }
  };
  const removeVacation = async (id1, ids) => {
    try {
      await Promise.all(
        ids.map(async (id) => {
          const config = {
            url: `/removevacationPhotos/${id1}/${id}`,
            method: "delete",
            baseURL: "https://bookingoda.com/api/admin",
            headers: { "content-type": "application/json" },
          };

          let res = await axios(config);
          if (res.status !== 200) {
            throw new Error("Failed to delete image");
          }
        })
      );
      alert("Successfully removed");
      setShow1(false);
      setShow4(false);

      getVacation();
    } catch (error) {
      if (error.response) {
        alert(error.response.data.error);
      } else {
        alert("Something went wrong");
      }
    }
  };
  return (
    <>
      <div>
        <Container fluid>
          <div className="d-flex justify-content-between align-items-center pt-4 pb-2">
          <h3 className="mb-4 title">Vacations List</h3>
            {/* <button className='pink-btn' onClick={handleShow}>Add Package</button> */}
          </div>

          <div>
     
        
             <div
              style={{
                display: "flex",
                flexDirection: "row",
                // justifyContent: "space-around",
                margin: 10,
              }}
            >
               {searchInput}
      <ExportToExcelButton
        dataSource={filteredData}
        columns={columns}
        filename="Vacationlist"
      />
      </div>
      <div>
        <Table
          columns={columns} className="custom-table"
          dataSource={filteredData}
          // scroll={{ x: "calc(700px + 50%)", y: 240 }}
          pagination={{
            pageSize: pageSize,
            showSizeChanger: false, // Optional: Hide the "Show X entries" dropdown
          }}
          components={{
            header: {
            
              cell: props => (
                <th {...props} style={{ ...props.style, background:'linear-gradient(#00c9a7,#2F9080,#1D453E)'  ,color:"white"}} />
              ),
            },
          }}
        />
          </div>
          </div>
        </Container>

        {/* Gallery Image Model */}
        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title className="text-danger">Gallery Images</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Table1 responsive bordered>
              <thead>
                <th>Sl.No</th>
                <th>Image</th>
                <th>Action</th>
              </thead>
              <tbody>
                {Viewgallery?.GalleryImage?.map((item, i) => {
                  return (
                    <tr>
                      <td>{i + 1}</td>
                      <td>
                        <img
                          style={{ width: "80px" }}
                          src={`https://bookingoda.com/Packages/${item?.image}`}
                          alt=""
                        />
                      </td>
                      <td>
                        {" "}
                        <div
                          className="d-flex gap-2 fs-4"
                          style={{ cursor: "pointer" }}
                        >
                          <BiSolidEdit className="text-primary" />
                          <MdDelete className="text-danger" />
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table1>
          </Modal.Body>
          {/* <Modal.Footer>
            <Button variant="warning" onClick={handleClose}>
              Close
            </Button>
            <Button variant="danger" onClick={handleClose}>
              Save
            </Button>
          </Modal.Footer> */}
        </Modal>

        {/* Stay Schedule */}
    
        {/* Edit Activity */}
        <Modal show={show1} onHide={handleClose1}>
          <Modal.Header closeButton>
            <Modal.Title className="text-danger">Edit Package</Modal.Title>
          </Modal.Header>
          <Modal.Body>
       
          </Modal.Body>
          <Modal.Footer>
            <Button variant="warning" onClick={handleClose1}>
              Close
            </Button>
            <Button variant="danger" onClick={handleClose1}>
              Save
            </Button>
          </Modal.Footer>
        </Modal>
        <Modal
        show={show1}
        onHide={handleClose1}
        dialogClassName="custom-modal"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title className="text-danger">Edit Vacation List</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <div className="Stepper-info" style={{ padding: "20px" }}>
        <Box sx={{ width: "100%" }}>
            <Stepper nonLinear activeStep={activeStep}>
              {steps.map((label, index) => (
                <Step key={label} completed={completed[index]}>
                  <StepButton
                    color="inherit"
                    style={{ marginBottom: "50px" }}
                    onClick={handleStep(index)}
                  >
                    {label}
                  </StepButton>
                </Step>
              ))}
            </Stepper>
            <div>
              {allStepsCompleted() ? (
                <React.Fragment>
                  <Typography
                    sx={{ mt: 2, mb: 1 }}
                    style={{
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      fontSize: "20px",
                      fontWeight: "500",
                    }}
                  >
                    All steps completed successfully
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignSelf: "center",
                        pt: 2,
                      }}
                    >
                      <Button onClick={handleReset}>Back</Button>
                    </Box>
                  </Typography>
                </React.Fragment>
              ) : (
                <React.Fragment>
                  {activeStep == 0 ? (
                    <>
                      <Typography sx={{ mt: 2, mb: 1, py: 1 }}>
                        <div className="container" style={{ padding: "5px" }}>
                          <div className="row ">
                            <div className="col-md-6">
                              <div className="do-sear mt-2">
                                {/* <label>
                                  Stay Name
                                  <span style={{ color: "red" }}>*</span>
                                </label> */}
                                <CustomInput
                                  type="text"
                                  label="Stay Name"
                                  name="name"
                                  onChng={(e) => setStayName(e.target.value)}
                                  val={StayName ? StayName : selectedModal.stayname}
                                />
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="do-sear mt-2">
                                {/* <label>
                                  Stay Description
                                  <span style={{ color: "red" }}>*</span>
                                </label> */}
                                <CustomInput
                                  type="text"
                                  label="Description"
                                  name="name"
                                  onChng={(e) =>
                                    setStayDescription(e.target.value)
                                  }
                                  val={StayDescription ?  StayDescription : selectedModal.staydescription}
                                />
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="do-sear mt-2">
                                {/* <label>
                                  Adult Price
                                  <span style={{ color: "red" }}>*</span>
                                </label> */}
                                {/* <input
                                  type="text"
                                  placeholder="₹ Price"
                                  className="vi_0"
                                  onChange={(e) => setStayCost(e.target.value)}
                                /> */}
                                <CustomInput
                                  type="number"
                                  label="Adult Price"
                                  max={6}
                                  name="name"
                                  onChng={(e) => setStayCost(e.target.value)}
                                  val={StayCost ?  StayCost : selectedModal.staycost}
                                />
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="do-sear mt-2">
                                {/* <label>
                                  Adult Discount{" "}
                                <span style={{ color: "red" }}>*</span>
                                </label> */}
                                {/* <input
                                  type="text"
                                  placeholder="%Discount"
                                  className="vi_0"
                                  onChange={(e) =>
                                    setStayDiscount(e.target.value)
                                  }
                                /> */}
                                <CustomInput
                                  type="number"
                                  label="Adult Discount"
                                  max={2}
                                  name="name"
                                  onChng={(e) =>
                                    setStayDiscount(e.target.value)
                                  }
                                  val={StayDiscount ? StayDiscount : selectedModal.staydiscount}
                                />
                              </div>
                            </div>

                            <div className="col-md-6">
                              <div className="do-sear mt-2">
                                {/* <label>
                                  Children Price
                                  <span style={{ color: "red" }}>*</span>
                                </label> */}
                                {/* <input
                                  type="text"
                                  placeholder="₹ For Children Price"
                                  className="vi_0"
                                  onChange={(e) =>
                                    setChildrenCost(e.target.value)
                                  }
                                /> */}
                                <CustomInput
                                  type="number"
                                  label="Children Price"
                                  max={6}
                                  name="name"
                                  onChng={(e) =>
                                    setChildrenCost(e.target.value)
                                  }
                                  val={ChildrenCost ? ChildrenCost : selectedModal.childrencost}
                                />
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="do-sear mt-2">
                                {/* <label>
                                  Children Discount{" "}
                                  {/* <span style={{ color: "red" }}>*</span> */}
                                {/* </label> */}
                                {/* <input
                                  type="text"
                                  placeholder="For Children % Discount"
                                  className="vi_0"
                                  onChange={(e) =>
                                    setChildrendiscount(e.target.value)
                                  } 
                                 />  */}
                                <CustomInput
                                  type="number"
                                  label="Children Discount"
                                  max={2}
                                  name="name"
                                  onChng={(e) =>
                                    setChildrendiscount(e.target.value)
                                  }
                                  val={Childrendiscount ? Childrendiscount : selectedModal.childrendiscount}
                                />
                              </div>
                            </div>
                            {/* <div className="col-md-6">
                              <div className="do-sear mt-2">
                                
                                <CustomInput
                                  type="text"
                                  label="Pet Price"
                                  name="name"
                                  onChng={(e) => setPetcost(e.target.value)}
                                  val={Petcost ? Petcost : selectedModal.petscost}
                                />
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="do-sear mt-2">
                              
                                <CustomInput
                                  type="text"
                                  label="Pet Discount"
                                  name="name"
                                  onChng={(e) => setPetdiscount(e.target.value)}
                                  val={Petdiscount}
                                />
                              </div>
                            </div> */}
<div className="col-md-6">
                              <div className="do-sear mt-2">
                                <CustomInput
                                  type="text"
                                  label="Street address"
                                  name="name"
                                  onChng={(e) => setStreet(e.target.value)}
                                  val={street ? street :selectedModal.street}
                                />
                              </div>
                            </div>
                            <div className="col-md-6">
                              <label>Country</label>
                              <div className="do-sear mt-3">
                                <select
                                  class="ebf4591c8e country-select-for-e2e-tests"
                                  name="cc1"
                                  id="cc1"
                                  className="vi_0"
                                  style={{
                                    width: "100%",
                                    borderColor: "#E6E6E6",
                                    fontSize: "16px",
                                  }}
                                  onChange={(e) => setCountry1(e.target.value)}
                                >
                                  <option value="" style={{ fontSize: "16px" }}>
                                  {selectedModal.country}
                                    
                                  </option>
                                  {CountryList?.map((Country) => (
                                    <option
                                      value={Country?.isoCode}
                                      style={{ fontSize: "16px" }}
                                    >
                                      {Country?.name}
                                    </option>
                                  ))}
                                </select>
                                {/* // <FormControl fullWidth>
                                //   <InputLabel id="demo-simple-select-helper-label">
                                //     Select country/region{" "}
                                //     <span style={{ color: "red" }}>*</span>
                                //   </InputLabel>
                                //   <Select
                                //     labelId="demo-simple-select-helper-label"
                                //     id="demo-simple-select-helper"
                                //     value={Country1}
                                   
                                //     label="Select country/region"
                                //     onChange={handleChange}
                                //   >
                                 
                                //     {CountryList?.map((item) => (
                                //       <MenuItem value={item?.name}>
                                //         {item?.name}
                                //       </MenuItem>
                                //     ))}
                                //   </Select>
                                  
                                // </FormControl> */}
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="do-sear mt-3">
                              <label>State</label>
                                <select
                                  class="ebf4591c8e country-select-for-e2e-tests"
                                  name="cc1"
                                  id="cc1"
                                  className="vi_0"
                                  style={{
                                    width: "100%",
                                    borderColor: "#E6E6E6",
                                    fontSize: "16px",
                                  }}
                                  onChange={(e) => {
                                    const selectedState = StateList.find(
                                      (state) =>
                                        state.isoCode === e.target.value
                                    );
                                    setState1(
                                      selectedState
                                        ? {
                                            isoCode: selectedState.isoCode,
                                            name: selectedState.name,
                                          }
                                        : { isoCode: "", name: "" }
                                    );
                                  }}
                                >
                                  <option value="">
                                    {selectedModal.state}
                                    <span style={{ color: "red" }}>*</span>
                                  </option>
                                  {StateList?.map((state) => (
                                    <option
                                      key={state.isoCode}
                                      value={state.isoCode}
                                    >
                                      {state.name}
                                    </option>
                                  ))}
                                </select>
                                {/* <FormControl fullWidth>
                                  <InputLabel id="demo-simple-select-label">
                                    State{" "}
                                    <span style={{ color: "red" }}>*</span>
                                  </InputLabel>
                                  <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={State1}
                                    label="State"
                                    onChange={handleChange1}
                                  >
                                    {StateList?.map((item) => (
                                      <MenuItem value={item?.isoCode}>
                                        {item?.name}
                                      </MenuItem>
                                    ))}
                                  </Select>
                                </FormControl> */}
                              </div>
                            </div>

                            <div className="col-md-6">
                              <div className="do-sear mt-3">
                              <label>City</label>
                                <select
                                  className="vi_0"
                                  // class="ebf4591c8e country-select-for-e2e-tests"
                                  style={{
                                    width: "100%",
                                    borderColor: "#E6E6E6",
                                    fontSize: "16px",
                                  }}
                                  name="cc1"
                                  id="cc1"
                                  onChange={(e) => setCity1(e.target.value)}
                                >
                                  <option value="" style={{ fontSize: "16px" }}>
                                    {selectedModal.city}
                                    
                                  </option>
                                  {CityList?.map((city1) => (
                                    <option
                                      value={city1?.name}
                                      style={{ fontSize: "16px" }}
                                    >
                                      {city1?.name}
                                    </option>
                                  ))}
                                </select>
                                {/* <FormControl fullWidth>
                                  <InputLabel id="demo-simple-select-label">
                                    City <span style={{ color: "red" }}>*</span>
                                  </InputLabel>
                                  <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={City1}
                                    label="City"
                                    onChange={handleChange2}
                                  >
                                    {CityList?.map((item) => (
                                      <MenuItem value={item?.name}>
                                        {item?.name}
                                      </MenuItem>
                                    ))}
                                  </Select>
                                </FormControl> */}
                              </div>
                            </div>
                            {/* <div className="col-md-6">
                              <div className="do-sear mt-2">
                                <label>
                                  Package Start Date
                                  <span style={{ color: "red" }}>*</span>
                                </label>
                                <input
                                  type="date"
                                  className="vi_0"
                                  onChange={(e) => setStartDate(e.target.value)}
                                />
                              </div>
                            </div> */}
                            {/* <div className="col-md-6">
                              <div className="do-sear mt-2">
                                <label>
                                  Package End Date
                                  <span style={{ color: "red" }}>*</span>
                                </label>
                                <input
                                  type="date"
                                  className="vi_0"
                                  onChange={(e) => setEndDate(e.target.value)}
                                />
                              </div>
                            </div> */}
                            <div className="col-md-6">
                              <div className="do-sear mt-2">
                                {/* <label>
                                  Duration
                                  <span style={{ color: "red" }}>*</span>
                                </label>
                                <input
                                  type="text"
                                  placeholder="Days"
                                  className="vi_0"
                                  onChange={(e) =>
                                    setStayDuration(e.target.value)
                                  }
                                /> */}
                                <CustomInput
                                  type="text"
                                  label="Stay Size(Sq ft)"
                                  name="name"
                                  onChng={(e) =>
                                    setMeasurement(e.target.value)
                                  }
                                  val={Measurement ?  Measurement : selectedModal.Measurement}
                                />
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="do-sear mt-2">
                                {/* <label>
                                  Duration
                                  <span style={{ color: "red" }}>*</span>
                                </label>
                                <input
                                  type="text"
                                  placeholder="Days"
                                  className="vi_0"
                                  onChange={(e) =>
                                    setStayDuration(e.target.value)
                                  }
                                /> */}
                                <CustomInput
                                  type="number"
                                  label="No of Beds"
                                  name="name"
                                  onChng={(e) =>
                                    setBeds(e.target.value)
                                  }
                                  val={Beds ?  Beds : selectedModal.Beds}
                                />
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="do-sear mt-2">
                                {/* <label>
                                  Duration
                                  <span style={{ color: "red" }}>*</span>
                                </label>
                                <input
                                  type="text"
                                  placeholder="Days"
                                  className="vi_0"
                                  onChange={(e) =>
                                    setStayDuration(e.target.value)
                                  }
                                /> */}
                                <CustomInput
                                  type="number"
                                  label="No of Rooms"
                                  name="name"
                                  onChng={(e) =>
                                    setRooms(e.target.value)
                                  }
                                  val={Rooms ?  Rooms : selectedModal.Rooms}
                                />
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="do-sear mt-2">
                                {/* <label>
                                  Duration
                                  <span style={{ color: "red" }}>*</span>
                                </label>
                                <input
                                  type="text"
                                  placeholder="Days"
                                  className="vi_0"
                                  onChange={(e) =>
                                    setStayDuration(e.target.value)
                                  }
                                /> */}
                                <CustomInput
                                  type="number"
                                  label="No of Bathrooms"
                                  name="name"
                                  onChng={(e) =>
                                    setBathrooms(e.target.value)
                                  }
                                  val={Bathrooms ?  Bathrooms : selectedModal.Bathrooms}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </Typography>
                    </>
                  ) : 
                    
                      activeStep == 1 ? (
                        <>
                           <Typography sx={{ mt: 2, mb: 1, py: 1 }}>
                        <div className="container" style={{ padding: "5px" }}>
                             <div
                                    data-testid="CheckboxList-title"
                                    className="f2cf178bcd spacing--4x"
                                  >
                                    <div className="e1eebb6a1e e6208ee469 d0caee4251">
                                    Amenities
                                    </div>
                                  </div>
                        <div className="ffb9c3d6a31 wide-container min-height">
                          <div className="row">
                        
                                  <hr className="b9bfeba2b4 b288f61df6" />
                               
                                  <div className="aca0ade214 aaf30230d9 cd2e7d62b0">
                                    <div className="a53cbfa6de ac9267e216 d8eb520c4e">
                                      {Object.keys(checkboxesAmenities).map(
                                        (option) => (
                                          <div key={option}>
                                            <label
                                              htmlFor=":rf:"
                                              className="aca0ade214 aaf30230d9 c2931f4182 d79e71457a bd597ff2d8"
                                            >
                                              <input
                                                type="checkbox"
                                                id={`checkbox${option}`}
                                                className="ef785aa7f4"
                                                checked={
                                                  checkboxesAmenities[option]
                                                }
                                                onChange={() =>
                                                  handleCheckboxChangeAmenities(
                                                    option
                                                  )
                                                }
                                              />
                                              <div className="a53cbfa6de e6208ee469">
                                                {" "}
                                                {option}
                                              </div>
                                            </label>{" "}
                                          </div>
                                        )
                                      )}
                                    </div>
                                    <input
                                      type="text"
                                      placeholder="Enter Amenities"
                                      value={newCheckboxAmenities}
                                      class="eb46370fe1"
                                      onChange={handleInputChangeAmenities}
                                    />
                                    <button
                                      onClick={handleAddCheckboxAmenities}
                                      style={{
                                        background: "green",
                                        color: "#fff",
                                        padding: 10,
                                      }}
                                    >
                                      Add Amenities
                                    </button>
                                  </div>
                           
                          </div>
                          </div>
                          <div className="form-container">
                            <div className="f2cf178bcd screen-main-header d991e1216a">
                              <div className="f6431b446c e6208ee469 d0caee4251 screen-main-header__title">
                                House rules
                              </div>
                            </div>
                            <div className="ffb9c3d6a31 wide-container min-height">
                              <div className="aca0ade214 ebac6e22e9 f66f916626 c2931f4182 c27e5d305d db150fece4 spacing--6x">
                                <div className="b817090550 d4e36dbd77">
                                  <label htmlFor="automation_checkbox_id_smoking_allowed">
                                    <div className="a53cbfa6de">
                                      Smoking in shared areas allowed
                                    </div>
                                  </label>
                                </div>
                                <Switch
                                  onChange={handleToggleSmoking}
                                  checked={isCheckedSmoking}
                                />
                              </div>
                              <div className="aca0ade214 ebac6e22e9 f66f916626 c2931f4182 c27e5d305d db150fece4 spacing--6x">
                                <div className="b817090550 d4e36dbd77">
                                  <label htmlFor="automation_checkbox_id_pets_allowed">
                                    <div className="a53cbfa6de">
                                      Pets allowed
                                    </div>
                                  </label>
                                </div>
                                <Switch
                                  onChange={handleTogglePets}
                                  checked={isCheckedPets}
                                />
                              </div>
                              <div className="aca0ade214 ebac6e22e9 f66f916626 c2931f4182 c27e5d305d db150fece4 spacing--6x">
                                <div className="b817090550 d4e36dbd77">
                                  <label htmlFor="automation_checkbox_id_pets_allowed">
                                    <div className="a53cbfa6de">
                                      Children allowed
                                    </div>
                                  </label>
                                </div>
                                <Switch
                                  onChange={handleToggleChildren}
                                  checked={isCheckedChildren}
                                />
                              </div>
                              <div className="aca0ade214 ebac6e22e9 f66f916626 c2931f4182 c27e5d305d db150fece4 spacing--6x">
                                <div className="b817090550 d4e36dbd77">
                                  <label htmlFor="automation_checkbox_id_parties_allowed">
                                    <div className="a53cbfa6de">
                                      Parties/events allowed
                                    </div>
                                  </label>
                                </div>
                                <Switch
                                  onChange={handleToggleParties}
                                  checked={isCheckedParties}
                                />
                              </div>
                             
                             
                            </div>
                          </div>
                        </div>
                      </Typography>
                        </>
                      ) : 
                          activeStep == 2 ? (
                            <>
                              <Typography sx={{ mt: 2, mb: 1, py: 1 }}>
                                <div
                                  className="container"
                                  style={{ padding: "5px" }}
                                >
                                  <div className="row ">
                                    <div className="col-md-6">
                                      <div className="do-sear mt-2">
                                        <label>
                                          Gallery
                                          <span style={{ color: "red" }}>
                                            *
                                          </span>
                                        </label>
                                        <div className=" mt-3">
                                          <input
                                            type="file"
                                            id="fileInput"
                                            className={`form-control ${i_class}`}
                                            onBlur={onBlr}
                                            accept="image/*, video/mp4"
                                            onChange={(e) => {
                                              onImageChange2(e);
                                              setImage(e.target.files[0]);
                                            }}
                                          />
                                        </div>
                                        {/* <CustomInput
                                          type="file"
                                          label="Gallary"
                                          name="name"
                                          onChng={(e) =>{
                                            onImageChange2(e);
                                            setImage(e.target.value)
                                          }}
                                          val={Image}
                                        /> */}
                                      </div>

                                      <button
                                        className="pink-btn mt-4 "
                                        style={{
                                          background: "green",
                                          color: "#fff",
                                          padding: 10,
                                        }}
                                        onClick={AddGalleryImage}
                                      >
                                        Add
                                      </button>
                                      <div
            style={{
              overflowY: "scroll",
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap",
              margin: "20px",
              // justifyContent: "space-between",
            }}
          >
                                      {selectedModal.GalleryImage?.map((media) => (
              <div
                key={media._id}
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-around",
                }}
                className={`image-item ${
                  selectedImages.includes(media._id) ? "selected" : ""
                }`}
              >
                {media.image.endsWith(".jpg") ||
                media.image.endsWith(".webp") ||
                media.image.endsWith(".jpeg") ||
                media.image.endsWith(".png") ? (
                  <img
                    src={"https://bookingoda.com/image/" + media.image}
                    alt="image"
                   
                    style={{ width: "100px", height: "100px" ,marginRight:"20px",marginBottom:"20px"}}
                    onClick={() =>
                      toggleImageSelection(
                        media._id
                      )
                    }
                  />
                ) : media.image.endsWith(".mp4") ? (
                  <video
                    src={"https://bookingoda.com/image/" + media.image}
                    alt="video"
                   
                    onClick={() =>
                      toggleImageSelection(
                        media._id
                      )
                    }
                    style={{ width: "100px", height: "100px" }}
                    controls
                  />
                ) : null}
                {/* <MdDelete
                  onClick={() => removeHotel(FullView._id, media._id)}
                  style={{ color: "red" }}
                /> */}
              </div>
            ))}
             {selectedImages.length > 0 ? (
                                            <button
                                              onClick={deleteSelectedImages}
                                              style={{
                                                padding: "10px 20px",
                                                backgroundColor: "red",
                                                color: "white",
                                                border: "none",
                                                borderRadius: "5px",
                                                cursor: "pointer",
                                                fontSize: "16px",
                                                fontWeight: "bold",
                                              }}
                                            >
                                              Delete {selectedImages.length}{" "}
                                              Selected Image
                                              {selectedImages.length !== 1
                                                ? "s"
                                                : ""}
                                            </button>
                                          ) : (
                                            ""
                                          )}</div>
                                    </div>
              
                                    <div className="col-md-6">
                                      <Table1 responsive bordered>
                                        <thead>
                                          <tr>
                                            <th
                                              style={{
                                                background: "#d81d4a",
                                                color: "#fff",
                                              }}
                                            >
                                              S.No
                                            </th>
                                            <th
                                              style={{
                                                background: "#d81d4a",
                                                color: "#fff",
                                              }}
                                            >
                                              Image
                                            </th>
                                            <th
                                              style={{
                                                background: "#d81d4a",
                                                color: "#fff",
                                              }}
                                            >
                                              Action
                                            </th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                          {galleryImages?.map((item, i) => {
                                            return (
                                              <tr>
                                                <td>{i + 1}</td>
                                                <td>
                                                  <img
                                                    src={item?.imgUrl}
                                                    alt=""
                                                    style={{
                                                      width: "50px",
                                                      height: "50px",
                                                      objectFit: "cover",
                                                    }}
                                                  />
                                                </td>
                                                <td>
                                                  <div
                                                    className="d-flex fs-4"
                                                    style={{
                                                      cursor: "pointer",
                                                    }}
                                                  >
                                                    {/* <BiSolidEdit className="text-primary" /> */}
                                                    <MdDelete
                                                      onClick={() =>
                                                        removeItem2(i)
                                                      }
                                                      className="text-danger"
                                                    />
                                                  </div>
                                                </td>
                                              </tr>
                                            );
                                          })}
                                        </tbody>
                                      </Table1>
                                    </div>
                                  </div>
                                </div>
                              </Typography>
                            </>
                          ) : 
                             <></>}

                  <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
                    <Button
                      style={{
                        background: "green",
                        color: "#fff",
                        display: activeStep === 0 ? "none" : "block"
                      }}
                      // disabled={activeStep === 0}

                      onClick={handleBack}
                      sx={{ mr: 1 }}
                    >
                      Back
                    </Button>
                    <Box sx={{ flex: "1 1 auto" }} />
                    <Button
                      style={{ background: "#ffc107", color: "#000", display: activeStep === 2 ? "none" : "block",marginRight:"10px" }}
                      onClick={handleNext}
                      sx={{ mr: 1 }}
                    >
                      Next
                    </Button>
                    {/* {activeStep !== steps.length &&
                      (completed[activeStep] ? (
                        <Typography
                          variant="caption"
                          sx={{ display: "inline-block" }}
                        >
                          Step {activeStep + 1} already completed
                        </Typography>
                      ) : (
                        <button
                          className="pink-btn"
                          style={{
                            background: "green",
                            color: "#fff",
                            padding: 10,
                          }}
                          onClick={() => {
                            completedSteps() === totalSteps() - 1
                              ? TourpackageDetails()
                              : handleComplete();
                          }}
                        >
                          {completedSteps() === totalSteps() - 1
                            ? "Submit"
                            : "Complete Step"}
                        </button>
                      ))} */}
                      <button
                          className="pink-btn"
                          style={{
                            background: "green",
                            color: "#fff",
                            padding: 10,
                          }}
                          onClick={TourpackageDetails}
                        >
                          Submit
                        </button>
                  </Box>
                </React.Fragment>
              )}
            </div>
          </Box>
        </div>
        </Modal.Body>
        {/* <Modal.Footer>
            <Button variant="warning" onClick={handleClose1}>
              Close
            </Button>
            <Button variant="danger" onClick={handleClose1}>
              Save
            </Button>
          </Modal.Footer> */}
      </Modal>
      </div>

      <Modal
        show={show3}
        onHide={handleClose3}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <div className="text-center">
           
            <h4 className="fw-bold text-dark mb-2">Are You Sure</h4>
            <p>This event data will be removed permanently</p>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="success" onClick={handleClose3}>
            Cancel
          </Button>
          <Button variant="danger" onClick={() => DeleteStay()}>
            <FontAwesomeIcon icon={faCancel} className=" me-2" />
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={show4} onHide={handleClose4} size="lg">
        <Modal.Header closeButton>
          <Modal.Title className="text-danger">Vacation List</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div
            style={{
              marginTop: "30px",
              marginBottom: "20px",
              fontWeight: "900",
              fontSize: "18px",
              color: "white",
              border: "2px solid #B8BCC7",
              backgroundColor: "#B8BCC7",
              width: "100%",
              boxSizing: "border-box",
              padding: "10px",
            }}
          >
            Photos
          </div>
          <div
            style={{
              overflowY: "scroll",
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap",
              margin: "20px",
              // justifyContent: "space-between",
            }}
          >
                                      {selectedModal.GalleryImage?.map((media) => (
              <div
                key={media._id}
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-around",
                }}
                className={`image-item ${
                  selectedImages.includes(media._id) ? "selected" : ""
                }`}
              >
                {media.image.endsWith(".jpg") ||
                media.image.endsWith(".webp") ||
                media.image.endsWith(".jpeg") ||
                media.image.endsWith(".png") ? (
                  <img
                    src={"https://bookingoda.com/image/" + media.image}
                    alt="image"
                   
                    style={{ width: "100px", height: "100px" ,marginRight:"20px",marginBottom:"20px"}}
                    onClick={() =>
                      toggleImageSelection(
                        media._id
                      )
                    }
                  />
                ) : media.image.endsWith(".mp4") ? (
                  <video
                    src={"https://bookingoda.com/image/" + media.image}
                    alt="video"
                   
                    onClick={() =>
                      toggleImageSelection(
                        media._id
                      )
                    }
                    style={{ width: "100px", height: "100px" }}
                    controls
                  />
                ) : null}
                {/* <MdDelete
                  onClick={() => removeHotel(FullView._id, media._id)}
                  style={{ color: "red" }}
                /> */}
              </div>
            ))}
             {selectedImages.length > 0 ? (
                                            <button
                                              onClick={deleteSelectedImages}
                                              style={{
                                                padding: "10px 20px",
                                                backgroundColor: "red",
                                                color: "white",
                                                border: "none",
                                                borderRadius: "5px",
                                                cursor: "pointer",
                                                fontSize: "16px",
                                                fontWeight: "bold",
                                              }}
                                            >
                                              Delete {selectedImages.length}{" "}
                                              Selected Image
                                              {selectedImages.length !== 1
                                                ? "s"
                                                : ""}
                                            </button>
                                          ) : (
                                            ""
                                          )}</div>
          <div
            style={{
              marginTop: "30px",
              marginBottom: "20px",
              fontWeight: "900",
              fontSize: "18px",
              color: "white",
              border: "2px solid #B8BCC7",
              backgroundColor: "#B8BCC7",
              width: "100%",
              boxSizing: "border-box",
              padding: "10px",
            }}
          >
            Description
          </div>
          <div style={{ fontSize: "18px", marginLeft: "10px" }}>
            {FullView.staydescription}
          </div>
          <div
            style={{
              marginTop: "30px",
              marginBottom: "20px",
              fontWeight: "900",
              fontSize: "18px",
              color: "white",
              border: "2px solid #B8BCC7",
              backgroundColor: "#B8BCC7",
              width: "100%",
              boxSizing: "border-box",
              padding: "10px",
            }}
          >
            Location
          </div>
          <div style={{ fontSize: "18px", marginLeft: "10px" }}>
            {FullView.street +
        "," +FullView.city +
        "," +
        FullView.state +
        "," +
        FullView.country}
          </div>
          <div
            style={{
              marginTop: "30px",
              marginBottom: "20px",
              fontWeight: "900",
              fontSize: "18px",
              color: "white",
              border: "2px solid #B8BCC7",
              backgroundColor: "#B8BCC7",
              width: "100%",
              boxSizing: "border-box",
              padding: "10px",
            }}
          >
           Price
          </div>
          <div style={{ fontSize: "18px", marginLeft: "10px" }}>
          <div>
 <p> Adult Price: + ₹{FullView.staycost} ,</p>
 <p> Adult Discount:  + ₹{FullView.staydiscount} %,</p>
 <p> Children Price:  + ₹{FullView.childrencost}  ,</p>
 <p> Children Discount:  + ₹{FullView.childrendiscount}  %,</p>
 {/* <p> Pet Price:  + ₹{FullView.petscost} ,</p>
 <p> Pet Discount: " + ₹{FullView.petdiscount} %</p> */}
 </div>
 <div
            style={{
              marginTop: "30px",
              marginBottom: "20px",
              fontWeight: "900",
              fontSize: "18px",
              color: "white",
              border: "2px solid #B8BCC7",
              backgroundColor: "#B8BCC7",
              width: "100%",
              boxSizing: "border-box",
              padding: "10px",
            }}
          >
           Vacation Home Details
          </div>
          <div>
 <p> Measurement: {FullView.Measurement} ,</p>
 <p> Rooms:  {FullView.Rooms},</p>
 <p> Beds:  {FullView.Beds}  ,</p>
 <p> Bathrooms:  {FullView.Bathrooms}  ,</p>

 </div>
 <div
            style={{
              marginTop: "30px",
              marginBottom: "20px",
              fontWeight: "900",
              fontSize: "18px",
              color: "white",
              border: "2px solid #B8BCC7",
              backgroundColor: "#B8BCC7",
              width: "100%",
              boxSizing: "border-box",
              padding: "10px",
            }}
          >
          Amenities
          </div>
          <div
          style={{ fontSize: "18px", marginLeft: "10px" }}
          >
           {!FullView?.Amenities ||
              Object.keys(FullView.Amenities).length === 0 ? (
                <div>
                  <span>No Amenities selected</span>
                </div>
              ) : (
                <ul>
                  {Object.entries(FullView.Amenities)
                    .filter(([key, value]) => value === true)
                    .map(([key], index) => (
                      <li key={index}>
                        {index + 1}){key}
                      </li>
                    ))}
                </ul>
              )}
                              </div>
                              <div
              style={{
                marginTop: "30px",
                marginBottom: "20px",
                fontWeight: "900",
                fontSize: "18px",
                color: "white",
                border: "2px solid #B8BCC7",
                backgroundColor: "#B8BCC7",
                width: "100%",
                boxSizing: "border-box",
                padding: "10px",
              }}
            >
              House Rules
            </div>
            <div style={{ fontSize: "18px", marginLeft: "10px" }}>
  {FullView.Smoking && (
    <div>{`Smoking: ${FullView.Smoking !== "false" ? "Allowed" : "Not Allowed" },`}</div>
  )}
  {FullView.Pets && (
    <div>{`Pets: ${FullView.Pets !== "false" ? "Allowed" : "Not Allowed"},`}</div>
  )}
  {FullView.Parties && (
    <div>{`Parties: ${FullView.Parties !== "false" ? "Allowed" : "Not Allowed"},`}</div>
  )}
  {FullView.Children && (
    <div>{`Children: ${FullView.Children !== "false" ? "Allowed" : "Not Allowed"}`}</div>
  )}
  
</div>
          </div>
        </Modal.Body>
        {/* <Modal.Footer>
            <Button variant="warning" onClick={handleClose2}>
              Close
            </Button>
            <Button variant="danger" onClick={handleClose2}>
              Save
            </Button>
          </Modal.Footer> */}
      </Modal>
    </>
  );
}

export default VacationList;
