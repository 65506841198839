import React, { useEffect, useState } from "react";
import "./property.css";
import Apartment from "../../assets/apartments.png";
import Apartments from "../../assets/buildings.png";

import Homestay from "../../assets/homestay.png";
import Hotel from "../../assets/hotel.png";
import VendorHeader from "./Vendorheader";
import { useLocation, useNavigate } from "react-router-dom";
import Switch from "react-switch";
const Propertyhouserules = () => {
  const { state } = useLocation();
  ////console.log(state);
  const history = useNavigate();
  const [isChecked, setIsChecked] = useState(false);


  const [isChecked1, setIsChecked1] = useState(false);
  const [isChecked2, setIsChecked2] = useState(false);
  const [isChecked3, setIsChecked3] = useState(false);

  const handleToggle = () => {
    setIsChecked(!isChecked);
    
  };
  const handleToggle1 = () => {
    setIsChecked1(!isChecked1);
    
  };
  const handleToggle2 = () => {
    setIsChecked2(!isChecked2);
   
  };
  const handleToggle3 = () => {
    setIsChecked3(!isChecked3);
   
  };
  const [selectedTime, setSelectedTime] = useState(""); // State to manage selected check-in time
  const [selectedTime1, setSelectedTime1] = useState(""); // State to manage selected check-in time
  const [selectedTime2, setSelectedTime2] = useState(""); // State to manage selected check-in time
  const [selectedTime3, setSelectedTime3] = useState(""); // State to manage selected check-in time

  const handleTimeChange = (event) => {
    setSelectedTime(event.target.value);
  };
  const handleTimeChange1 = (event) => {
    setSelectedTime1(event.target.value);
  };
  const handleTimeChange2 = (event) => {
    setSelectedTime2(event.target.value);
  };
  const handleTimeChange3 = (event) => {
    setSelectedTime3(event.target.value);
  };
  function generateTimeOptions() {
    const timeOptions = [];
    const periods = ["AM", "PM"];
  
    for (let period of periods) {
      for (let hour = 0; hour < 12; hour++) {
        const displayHour = hour === 0 ? 12 : hour;
        const timeBase = displayHour.toString().padStart(2, '0') + ":";
        
        timeOptions.push(timeBase + "00 " + period);
        timeOptions.push(timeBase + "15 " + period);
        timeOptions.push(timeBase + "30 " + period);
        timeOptions.push(timeBase + "45 " + period);
      }
    }
  
    return timeOptions;
  }
  
  const timeOptions = generateTimeOptions();
  const HandleClick = () => {
    if (selectedTime === "" || selectedTime1 === ""|| selectedTime2 === "" || selectedTime3 === "" ) {
      alert("Please checkin and checkout timings");
   
      }else{
    history("/propertyphoto",sessionStorage.setItem("Smoking", JSON.stringify(isChecked ? "Smoking Allowed" : "")),sessionStorage.setItem("Pets", JSON.stringify(isChecked1 ? "Pets Allowed" : "")),sessionStorage.setItem("Parties", JSON.stringify(isChecked2 ? "Parties/events allowed" : "")), 
    sessionStorage.setItem("Children", JSON.stringify(isChecked3 ? "Children Allowed" : "")),sessionStorage.setItem("CheckInFrom", JSON.stringify(selectedTime)),sessionStorage.setItem("CheckInUntil", JSON.stringify(selectedTime1)),sessionStorage.setItem("CheckOutFrom", JSON.stringify(selectedTime2)),sessionStorage.setItem("CheckOutUntil", JSON.stringify(selectedTime3)));
  }}
  useEffect(() => {
    // Retrieve stored values from sessionStorage
    const storedSmoking = sessionStorage.getItem('Smoking');
    const storedPets = sessionStorage.getItem('Pets');
    const storedParties = sessionStorage.getItem('Parties');
    const storedChildren = sessionStorage.getItem('Children');
    const storedCheckInFrom = sessionStorage.getItem('CheckInFrom');
    const storedCheckInUntil = sessionStorage.getItem('CheckInUntil');
    const storedCheckOutFrom = sessionStorage.getItem('CheckOutFrom');
    const storedCheckOutUntil = sessionStorage.getItem('CheckOutUntil');
  
    // Parse JSON strings if values are present
    const parsedSmoking = storedSmoking ? JSON.parse(storedSmoking) : "";
    const parsedPets = storedPets ? JSON.parse(storedPets) : "";
    const parsedParties = storedParties ? JSON.parse(storedParties) : "";
    const parsedChildren = storedChildren ? JSON.parse(storedChildren) : "";
    const parsedCheckInFrom = storedCheckInFrom ? JSON.parse(storedCheckInFrom) : "";
    const parsedCheckInUntil = storedCheckInUntil ? JSON.parse(storedCheckInUntil) : "";
    const parsedCheckOutFrom = storedCheckOutFrom ? JSON.parse(storedCheckOutFrom) : "";
    const parsedCheckOutUntil = storedCheckOutUntil ? JSON.parse(storedCheckOutUntil) : "";
  
    // Set state variables with retrieved values
    setIsChecked(parsedSmoking === "Smoking Allowed");
    setIsChecked1(parsedPets === "Pets Allowed");
    setIsChecked2(parsedParties === "Parties/events allowed");
    setIsChecked3(parsedChildren === "Children Allowed");
    setSelectedTime(parsedCheckInFrom);
    setSelectedTime1(parsedCheckInUntil);
    setSelectedTime2(parsedCheckOutFrom);
    setSelectedTime3(parsedCheckOutUntil);
  }, []);
  return (
    <div
      data-capla-component="b-registration-joinapp15min-fe/"
      data-capla-namespace="b-registration-joinapp15min-feaYGUODfA"
    >
      <div id="app-container">
        <div class="error-popup-container"></div>
        <div class="application">
          <VendorHeader />
          <div class="error-popup-container"></div>
          <div className="page-container">
            <div className="screens screens-animation-direction-forward">
              <div
                className="height-100 screen-change-enter-done"
                id="automation_id_screen_container_HouseRules"
              >
                <div
                  className="ms-container"
                  data-screen="true"
                  data-save-url="save_house_rules"
                >
                  <div className="form-container">
                    <div className="f2cf178bcd screen-main-header d991e1216a">
                      <div className="f6431b446c e6208ee469 d0caee4251 screen-main-header__title">
                        House rules
                      </div>
                    </div>
                    <div className="ffb9c3d6a31 wide-container min-height">
                      <div className="aca0ade214 ebac6e22e9 f66f916626 c2931f4182 c27e5d305d db150fece4 spacing--6x">
                        <div className="b817090550 d4e36dbd77">
                          <label htmlFor="automation_checkbox_id_smoking_allowed">
                            <div className="a53cbfa6de">Smoking in shared areas allowed</div>
                          </label>
                        </div>
                        <Switch onChange={handleToggle} checked={isChecked} />
                      </div>
                      <div className="aca0ade214 ebac6e22e9 f66f916626 c2931f4182 c27e5d305d db150fece4 spacing--6x">
                        <div className="b817090550 d4e36dbd77">
                          <label htmlFor="automation_checkbox_id_pets_allowed">
                            <div className="a53cbfa6de">Pets allowed</div>
                          </label>
                        </div>
                        <Switch onChange={handleToggle1} checked={isChecked1} />
                      </div>
                      <div className="aca0ade214 ebac6e22e9 f66f916626 c2931f4182 c27e5d305d db150fece4 spacing--6x">
                        <div className="b817090550 d4e36dbd77">
                          <label htmlFor="automation_checkbox_id_pets_allowed">
                            <div className="a53cbfa6de">Children allowed</div>
                          </label>
                        </div>
                        <Switch onChange={handleToggle3} checked={isChecked3} />
                      </div>
                      <div className="aca0ade214 ebac6e22e9 f66f916626 c2931f4182 c27e5d305d db150fece4 spacing--6x">
                        <div className="b817090550 d4e36dbd77">
                          <label htmlFor="automation_checkbox_id_parties_allowed">
                            <div className="a53cbfa6de">
                              Parties/events allowed
                            </div>
                          </label>
                        </div>
                        <Switch onChange={handleToggle2} checked={isChecked2} />
                      </div>
                      <hr className="b9bfeba2b4 b288f61df6 spacing--6x" />
                      <div className="a3332d346a">Check in</div>
                      <div className="aca0ade214 ebac6e22e9 f66f916626 c2931f4182 c27e5d305d db150fece4 spacing--6x">
                        <div className="b817090550 b268b61489">
                          <div className="a53cbfa6de ac9267e216">
                            <label
                              htmlFor=":r32:"
                              className="a53cbfa6de e6208ee469 f555271986"
                            >
                            From
                            </label>
                            <div className="cabad3c686 aff44befa2">
                            <select
                                value={selectedTime}
                                className="ebf4591c8e"
                                onChange={handleTimeChange}
                              >
                                <option value="">Select Time</option>
                                {timeOptions.map((time, index) => (
                                  <option key={index} value={time}>
                                    {time}
                                  </option>
                                ))}
                              </select>
                              <span className="ad2c299542">
                                <span
                                  className="fcd9eec8fb cab1c196b5 c2cc050fb8 f64eb5d122"
                                  aria-hidden="true"
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 24 24"
                                  >
                                    <path d="M19.2681 8.91288C19.2687 9.03226 19.2454 9.15054 19.1996 9.26079C19.1539 9.37105 19.0865 9.47105 19.0016 9.55493L12.9445 15.612C12.8211 15.7367 12.6738 15.8353 12.5115 15.9018C12.3491 15.9684 12.1751 16.0017 11.9996 15.9997C11.6491 16.0075 11.3096 15.877 11.0547 15.6362L4.99764 9.57916C4.8275 9.40881 4.73193 9.17788 4.73193 8.93711C4.73193 8.69634 4.8275 8.46542 4.99764 8.29506C5.08117 8.20935 5.18101 8.14122 5.29128 8.09471C5.40155 8.04819 5.52002 8.02423 5.6397 8.02423C5.75937 8.02423 5.87784 8.04819 5.98811 8.09471C6.09838 8.14122 6.19822 8.20935 6.28175 8.29506L11.9996 13.9887L17.7175 8.27084C17.801 8.18512 17.9009 8.11699 18.0111 8.07048C18.1214 8.02396 18.2399 8 18.3596 8C18.4792 8 18.5977 8.02396 18.708 8.07048C18.8182 8.11699 18.9181 8.18512 19.0016 8.27084C19.0871 8.3543 19.1548 8.45422 19.2006 8.56457C19.2464 8.67492 19.2694 8.79341 19.2681 8.91288Z" />
                                  </svg>
                                </span>
                              </span>
                            </div>
                          </div>
                        </div>
                        <div className="b817090550 b268b61489">
                          <div className="a53cbfa6de ac9267e216">
                            <label
                              htmlFor=":r33:"
                              className="a53cbfa6de e6208ee469 f555271986"
                            >
                              <span>
                                <span className="ccb65902b2">Until</span>
                              </span>
                            </label>
                            <div className="cabad3c686 aff44befa2">
                            <select
                                value={selectedTime1}
                                className="ebf4591c8e"
                                onChange={handleTimeChange1}
                              >
                                <option value="">Select Time</option>
                                {timeOptions.map((time, index) => (
                                  <option key={index} value={time}>
                                    {time}
                                  </option>
                                ))}
                              </select>
                              <span className="ad2c299542">
                                <span
                                  className="fcd9eec8fb cab1c196b5 c2cc050fb8 f64eb5d122"
                                  aria-hidden="true"
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 24 24"
                                  >
                                    <path d="M19.2681 8.91288C19.2687 9.03226 19.2454 9.15054 19.1996 9.26079C19.1539 9.37105 19.0865 9.47105 19.0016 9.55493L12.9445 15.612C12.8211 15.7367 12.6738 15.8353 12.5115 15.9018C12.3491 15.9684 12.1751 16.0017 11.9996 15.9997C11.6491 16.0075 11.3096 15.877 11.0547 15.6362L4.99764 9.57916C4.8275 9.40881 4.73193 9.17788 4.73193 8.93711C4.73193 8.69634 4.8275 8.46542 4.99764 8.29506C5.08117 8.20935 5.18101 8.14122 5.29128 8.09471C5.40155 8.04819 5.52002 8.02423 5.6397 8.02423C5.75937 8.02423 5.87784 8.04819 5.98811 8.09471C6.09838 8.14122 6.19822 8.20935 6.28175 8.29506L11.9996 13.9887L17.7175 8.27084C17.801 8.18512 17.9009 8.11699 18.0111 8.07048C18.1214 8.02396 18.2399 8 18.3596 8C18.4792 8 18.5977 8.02396 18.708 8.07048C18.8182 8.11699 18.9181 8.18512 19.0016 8.27084C19.0871 8.3543 19.1548 8.45422 19.2006 8.56457C19.2464 8.67492 19.2694 8.79341 19.2681 8.91288Z" />
                                  </svg>
                                </span>
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="a3332d346a">Check out</div>
                      <div className="aca0ade214 ebac6e22e9 f66f916626 c2931f4182 c27e5d305d db150fece4 spacing--6x">
                        <div className="b817090550 b268b61489">
                          <div className="a53cbfa6de ac9267e216">
                            <label
                              htmlFor=":r34:"
                              className="a53cbfa6de e6208ee469 f555271986"
                            >
                              <span>
                                <span className="ccb65902b2">From</span>
                              </span>
                            </label>
                            <div className="cabad3c686 aff44befa2">
                            <select
                                value={selectedTime2}
                                className="ebf4591c8e"
                                onChange={handleTimeChange2}
                              >
                                <option value="">Select Time</option>
                                {timeOptions.map((time, index) => (
                                  <option key={index} value={time}>
                                    {time}
                                  </option>
                                ))}
                              </select>
                              <span className="ad2c299542">
                                <span
                                  className="fcd9eec8fb cab1c196b5 c2cc050fb8 f64eb5d122"
                                  aria-hidden="true"
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 24 24"
                                  >
                                    <path d="M19.2681 8.91288C19.2687 9.03226 19.2454 9.15054 19.1996 9.26079C19.1539 9.37105 19.0865 9.47105 19.0016 9.55493L12.9445 15.612C12.8211 15.7367 12.6738 15.8353 12.5115 15.9018C12.3491 15.9684 12.1751 16.0017 11.9996 15.9997C11.6491 16.0075 11.3096 15.877 11.0547 15.6362L4.99764 9.57916C4.8275 9.40881 4.73193 9.17788 4.73193 8.93711C4.73193 8.69634 4.8275 8.46542 4.99764 8.29506C5.08117 8.20935 5.18101 8.14122 5.29128 8.09471C5.40155 8.04819 5.52002 8.02423 5.6397 8.02423C5.75937 8.02423 5.87784 8.04819 5.98811 8.09471C6.09838 8.14122 6.19822 8.20935 6.28175 8.29506L11.9996 13.9887L17.7175 8.27084C17.801 8.18512 17.9009 8.11699 18.0111 8.07048C18.1214 8.02396 18.2399 8 18.3596 8C18.4792 8 18.5977 8.02396 18.708 8.07048C18.8182 8.11699 18.9181 8.18512 19.0016 8.27084C19.0871 8.3543 19.1548 8.45422 19.2006 8.56457C19.2464 8.67492 19.2694 8.79341 19.2681 8.91288Z" />
                                  </svg>
                                </span>
                              </span>
                            </div>
                          </div>
                        </div>
                        <div className="b817090550 b268b61489">
                          <div className="a53cbfa6de ac9267e216">
                            <label
                              htmlFor=":r35:"
                              className="a53cbfa6de e6208ee469 f555271986"
                            >
                              <span>
                                <span className="ccb65902b2">Until</span>
                              </span>
                            </label>
                            <div className="cabad3c686 aff44befa2">
                            <select
                                value={selectedTime3}
                                className="ebf4591c8e"
                                onChange={handleTimeChange3}
                              >
                                <option value="">Select Time</option>
                                {timeOptions.map((time, index) => (
                                  <option key={index} value={time}>
                                    {time}
                                  </option>
                                ))}
                              </select>
                              <span className="ad2c299542">
                                <span
                                  className="fcd9eec8fb cab1c196b5 c2cc050fb8 f64eb5d122"
                                  aria-hidden="true"
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 24 24"
                                  >
                                    <path d="M19.2681 8.91288C19.2687 9.03226 19.2454 9.15054 19.1996 9.26079C19.1539 9.37105 19.0865 9.47105 19.0016 9.55493L12.9445 15.612C12.8211 15.7367 12.6738 15.8353 12.5115 15.9018C12.3491 15.9684 12.1751 16.0017 11.9996 15.9997C11.6491 16.0075 11.3096 15.877 11.0547 15.6362L4.99764 9.57916C4.8275 9.40881 4.73193 9.17788 4.73193 8.93711C4.73193 8.69634 4.8275 8.46542 4.99764 8.29506C5.08117 8.20935 5.18101 8.14122 5.29128 8.09471C5.40155 8.04819 5.52002 8.02423 5.6397 8.02423C5.75937 8.02423 5.87784 8.04819 5.98811 8.09471C6.09838 8.14122 6.19822 8.20935 6.28175 8.29506L11.9996 13.9887L17.7175 8.27084C17.801 8.18512 17.9009 8.11699 18.0111 8.07048C18.1214 8.02396 18.2399 8 18.3596 8C18.4792 8 18.5977 8.02396 18.708 8.07048C18.8182 8.11699 18.9181 8.18512 19.0016 8.27084C19.0871 8.3543 19.1548 8.45422 19.2006 8.56457C19.2464 8.67492 19.2694 8.79341 19.2681 8.91288Z" />
                                  </svg>
                                </span>
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="page-tooltip__wrap">
                        <section
                          aria-labelledby=":r36:"
                          className="c82435a4b8 a178069f51 a6ae3c2b40 a18aeea94d d794b7a0f7 f53e278e95"
                          style={{ padding: "4px" }}
                        >
                          <div className="aca0ade214 ebac6e22e9 c2931f4182 d79e71457a">
                            <span className="d963552ea0">
                              <span
                                className="fcd9eec8fb c2cc050fb8"
                                aria-hidden="true"
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 24 24"
                                >
                                  <path d="M12.75 2.251v-1.5a.75.75 0 0 0-1.5 0v1.5a.75.75 0 0 0 1.5 0zm6.144 3.167l1.061-1.06a.75.75 0 0 0-1.06-1.061l-1.061 1.06a.75.75 0 0 0 1.06 1.061zM21 12.001h1.5a.75.75 0 0 0 0-1.5H21a.75.75 0 0 0 0 1.5zm-3.166 6.144l1.06 1.061a.75.75 0 1 0 1.061-1.06l-1.06-1.061a.75.75 0 1 0-1.061 1.06zM6.166 4.358l-1.06-1.061a.75.75 0 0 0-1.061 1.06l1.06 1.061a.75.75 0 0 0 1.061-1.06zM3 10.5H1.5a.75.75 0 0 0 0 1.5H3a.75.75 0 0 0 0-1.5zm2.106 6.584l-1.061 1.06a.75.75 0 1 0 1.06 1.061l1.061-1.06a.75.75 0 1 0-1.06-1.061zm3.144-.636v2.3a3.75 3.75 0 1 0 7.5 0v-2.3a.75.75 0 0 0-1.5 0v2.3a2.25 2.25 0 0 1-4.5 0v-2.3a.75.75 0 0 0-1.5 0zm9-5.198a5.25 5.25 0 1 1-10.5 0 5.25 5.25 0 0 1 10.5 0zm1.5 0a6.75 6.75 0 1 0-13.5 0 6.75 6.75 0 0 0 13.5 0zm-7.5 10.5v1.5a.75.75 0 0 0 1.5 0v-1.5a.75.75 0 0 0-1.5 0z" />
                                </svg>
                              </span>
                            </span>
                            <div className="aaee4e7cd3 e7a57abb1e">
                              <div className="aca0ade214 aaf30230d9 cd2e7d62b0">
                                <div className="ffd93a9ecb">
                                  <h3
                                    id=":r36:"
                                    className="e1eebb6a1e e0a7dcceb5"
                                  >
                                    What if my house rules change?
                                  </h3>
                                  {/* <div className="abcc616ec7 cc1b961f14 c180176d40 f11eccb5e8">
                                    <button
                                      aria-label="Close banner"
                                      type="button"
                                      className="a83ed08757 c21c56c305 f38b6daa18 d691166b09 ab98298258 deab83296e f4552b6561"
                                    >
                                      <span className="eedba9e88a">
                                        <span
                                          className="fcd9eec8fb bf9a32efa5"
                                          aria-hidden="true"
                                        >
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 24 24"
                                          >
                                            <path d="M13.31 12l6.89-6.89a.93.93 0 1 0-1.31-1.31L12 10.69 5.11 3.8A.93.93 0 0 0 3.8 5.11L10.69 12 3.8 18.89a.93.93 0 0 0 1.31 1.31L12 13.31l6.89 6.89a.93.93 0 1 0 1.31-1.31z" />
                                          </svg>
                                        </span>
                                      </span>
                                    </button>
                                  </div> */}
                                </div>
                                <div className="a53cbfa6de">
                                  You can easily customise these house rules
                                  later and additional house rules can be set on
                                  the Policies page of the extranet after you
                                  complete registration.
                                </div>
                              </div>
                            </div>
                          </div>
                        </section>
                      </div>
                    </div>
                  </div>
                  <div className="form-cta">
                    <div>
                      <div className="form-buttons-container">
                        <div
                          className="form-buttons-container--secondary"
                          onClick={() => window.history.back()}
                        >
                          <button
                            aria-label="Back to previous step"
                            data-testid="FormButtonSecondary"
                            type="button"
                            className="a83ed08757 c21c56c305 bf0537ecb5 d691166b09 f671049264 d2529514af af7297d90d"
                          >
                            <span className="eedba9e88a">
                              <span
                                className="fcd9eec8fb c2cc050fb8"
                                aria-hidden="true"
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 24 24"
                                  data-rtl-flip="true"
                                >
                                  <path d="M15.0871 19.2361C14.9677 19.2367 14.8495 19.2134 14.7392 19.1676C14.629 19.1219 14.5289 19.0546 14.4451 18.9696L8.38799 12.9125C8.26332 12.7891 8.16475 12.6418 8.09815 12.4795C8.03156 12.3172 7.99829 12.1431 8.00034 11.9676C7.99246 11.6171 8.12303 11.2776 8.36376 11.0227L14.4208 4.96566C14.5912 4.79552 14.8221 4.69995 15.0629 4.69995C15.3037 4.69995 15.5346 4.79552 15.7049 4.96566C15.7907 5.04918 15.8588 5.14902 15.9053 5.25929C15.9518 5.36957 15.9758 5.48803 15.9758 5.60771C15.9758 5.72739 15.9518 5.84586 15.9053 5.95613C15.8588 6.0664 15.7907 6.16624 15.7049 6.24976L10.0113 11.9676L15.7292 17.6855C15.8149 17.769 15.883 17.8689 15.9295 17.9792C15.976 18.0894 16 18.2079 16 18.3276C16 18.4473 15.976 18.5657 15.9295 18.676C15.883 18.7863 15.8149 18.8861 15.7292 18.9696C15.6457 19.0551 15.5458 19.1228 15.4354 19.1686C15.3251 19.2144 15.2066 19.2374 15.0871 19.2361Z" />
                                </svg>
                              </span>
                            </span>
                          </button>
                        </div>
                        <div
                          className="form-buttons-container--primary"
                          onClick={HandleClick
                          }
                        >
                          <button
                            aria-label="Continue"
                            data-testid="FormButtonPrimary-enabled"
                            type="button"
                            className="a83ed08757 c21c56c305 a4c1805887 f671049264 d2529514af c082d89982"
                          >
                            <span className="e4adce92df">Continue</span>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              id="automation_id_screen_id_house-rules"
              style={{ display: "none" }}
            />
          </div>

          <div
            id="automation_id_flow_Segmentation_Flow"
            style={{ display: "none" }}
          ></div>
          <div></div>
        </div>

        <img
          alt=""
          height="1"
          width="1"
          style={{ display: "none" }}
          src="https://booking.com/track_join_app_landing?progress_status=2"
        />
      </div>
    </div>
  );
};

export default Propertyhouserules;
