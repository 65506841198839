import React, { useEffect, useState } from "react";
import "./property.css";
import Apartment from "../../assets/apartments.png";
import Apartments from "../../assets/buildings.png";

import Homestay from "../../assets/homestay.png";
import Hotel from "../../assets/hotel.png";
import VendorHeader from "./Vendorheader";
import { useLocation, useNavigate } from "react-router-dom";

const HomeAmenities = () => {
  const { state } = useLocation();
  ////console.log(state);
  const [General, setGeneral] = useState({}); // State to manage checked items
  ////console.log(General);
  // const handleCheckboxChange = (event) => {
  //   const { name, checked } = event.target;
  //   setGeneral({ ...General, [name]: checked });
  // };
  const [Outdoors, setOutdoors] = useState({}); // State to manage checked items
  ////console.log(Outdoors);
  // const handleCheckboxChange1 = (event) => {
  //   const { name, checked } = event.target;
  //   setOutdoors({ ...Outdoors, [name]: checked });
  // };
  const [Food, setFood] = useState({}); // State to manage checked items
  ////console.log(Food);
  // const handleCheckboxChange2 = (event) => {
  //   const { name, checked } = event.target;
  //   setFood({ ...Food, [name]: checked });
  // };
  const history = useNavigate();

  const HandleClick = () => {
    if (Object.values(checkboxes).every(value => value === false) === true ) {
      alert("Please select general Items");
   
  
  }else if (Object.values(checkboxes1).every(value => value === false) === true ) {
    alert("Please select views");
  }else if (Object.values(checkboxes2).every(value => value === false) === true ) {
    alert("Please select kitchen items");
  } else{
    history("/homeroomphoto",{
      state: 
       
        state,
      
    },sessionStorage.setItem("General", JSON.stringify(checkboxes)),sessionStorage.setItem("Outdoors", JSON.stringify(checkboxes1)) ,sessionStorage.setItem("Food", JSON.stringify(checkboxes2)));
  }
  };
  useEffect(() => {
    // Retrieve items from sessionStorage
    const storedGeneral = sessionStorage.getItem("General");
    const storedOutdoors = sessionStorage.getItem("Outdoors");
    const storedFood = sessionStorage.getItem("Food");

    // Check if the items exist in sessionStorage and set state accordingly
    if (storedGeneral) {
      setCheckboxes(JSON.parse(storedGeneral));
    }

    if (storedOutdoors) {
      setCheckboxes1(JSON.parse(storedOutdoors));
    }

    if (storedFood) {
      setCheckboxes2(JSON.parse(storedFood));
    }

    // ... Continue retrieving and setting other items as needed

  }, []); 
  const [checkboxes, setCheckboxes] = useState({
    "Clothes rack": false,
    "Flat screen TV": false,
    "Linens": false,
    "Air conditioning": false,
    "Desk": false,
   "Wake-up service": false,
    "Towels": false,
    "Heating": false,
    "Fan": false,
    "Safe": false,
    "Towels/Sheets (extra fee)": false,
   

  });

  const [newCheckboxLabel, setNewCheckboxLabel] = useState("");

  const handleCheckboxChange = (option) => {
    setCheckboxes((prevCheckboxes) => ({
      ...prevCheckboxes,
      [option]: !prevCheckboxes[option],
    }));
  };
  ////console.log(checkboxes);
  const handleInputChange = (event) => {
    setNewCheckboxLabel(event.target.value);
  };

  const handleAddCheckbox = () => {
    if (newCheckboxLabel) {
      setCheckboxes((prevCheckboxes) => ({
        ...prevCheckboxes,
        [newCheckboxLabel]: false,
      }));
      setNewCheckboxLabel("");
    }
  };
  const [checkboxes1, setCheckboxes1] = useState({
    "Balcony": false,
    "Terrace": false,
    "View": false,
   
  });

  const [newCheckboxLabel1, setNewCheckboxLabel1] = useState("");

  const handleCheckboxChange1 = (option) => {
    setCheckboxes1((prevCheckboxes1) => ({
      ...prevCheckboxes1,
      [option]: !prevCheckboxes1[option],
    }));
  };
  ////console.log(checkboxes1);
  const handleInputChange1 = (event) => {
    setNewCheckboxLabel1(event.target.value);
  };

  const handleAddCheckbox1 = () => {
    if (newCheckboxLabel1) {
      setCheckboxes1((prevCheckboxes1) => ({
        ...prevCheckboxes1,
        [newCheckboxLabel1]: false,
      }));
      setNewCheckboxLabel1("");
    }
  };
  const [checkboxes2, setCheckboxes2] = useState({
    "Electric kettle": false,
    "Tea/Coffee maker": false,
    "Dining area": false,
    "Dining table": false,
    "Microwave": false,
  });

  const [newCheckboxLabel2, setNewCheckboxLabel2] = useState("");

  const handleCheckboxChange2 = (option) => {
    setCheckboxes2((prevCheckboxes2) => ({
      ...prevCheckboxes2,
      [option]: !prevCheckboxes2[option],
    }));
  };
  ////console.log(checkboxes2);
  const handleInputChange2 = (event) => {
    setNewCheckboxLabel2(event.target.value);
  };

  const handleAddCheckbox2 = () => {
    if (newCheckboxLabel2) {
      setCheckboxes2((prevCheckboxes2) => ({
        ...prevCheckboxes2,
        [newCheckboxLabel2]: false,
      }));
      setNewCheckboxLabel2("");
    }
  };
  return (
    <div
      data-capla-component="b-registration-joinapp15min-fe/"
      data-capla-namespace="b-registration-joinapp15min-feaYGUODfA"
    >
      <div id="app-container">
        <div class="error-popup-container"></div>
        <div class="application">
        <VendorHeader />
          <div class="error-popup-container"></div>

          <div className="page-container">
            <div className="screens screens-animation-direction-forward">
              <div
                className="height-100 screen-change-enter-done"
                id="automation_id_screen_container_UnitAmenities"
              >
                <div className="ms-container">
                  <div className="form-container">
                    <div className="f2cf178bcd screen-main-header d991e1216a">
                      <div className="f6431b446c e6208ee469 d0caee4251 screen-main-header__title">
                        What can guests use in this room?
                      </div>
                    </div>
                    <div className="ffb9c3d6a3 wide-container min-height">
                      <div className="aca0ade214 ebac6e22e9 f66f916626 c2931f4182 c27e5d305d db150fece4">
                        <div className="b817090550 b736e9e3f4">
                          <div
                            data-testid="CheckboxList-title"
                            className="f2cf178bcd spacing--4x"
                          >
                            <div className="e1eebb6a1e e6208ee469 d0caee4251">
                              General amenities
                            </div>
                          </div>
                          <div className="aca0ade214 aaf30230d9 cd2e7d62b0">
                              <div className="a53cbfa6de ac9267e216 d8eb520c4e">
                                {Object.keys(checkboxes).map((option) => (
                                  <div key={option}>
                                    <label
                                      htmlFor=":rf:"
                                      className="aca0ade214 aaf30230d9 c2931f4182 d79e71457a bd597ff2d8"
                                    >
                                      <input
                                        type="checkbox"
                                        id={`checkbox${option}`}
                                        className="ef785aa7f4"
                                        checked={checkboxes[option]}
                                        onChange={() =>
                                          handleCheckboxChange(option)
                                        }
                                      />
                                      <div className="a53cbfa6de e6208ee469">
                                        {" "}
                                        {option}
                                      </div>
                                    </label>{" "}
                                  </div>
                                ))}
                              </div>
                              <input
                                type="text"
                                placeholder="Enter General amenities"
                                value={newCheckboxLabel}
                                class="eb46370fe1"
                                onChange={handleInputChange}
                              />
                              <button onClick={handleAddCheckbox} style={{
                                marginTop: "10px",
                                padding: "10px 20px",
                                fontSize: "16px",
                                backgroundColor: "#70bbe9",
                                color: "white",
                                border: "none",
                                borderRadius: "5px",
                                cursor: "pointer",
                                transition: "background-color 0.3s",
                              }}>
                                Add General amenities
                              </button>
                            </div>
                        
                          <div className="spacing--8x" />
                          <hr className="b9bfeba2b4 b288f61df6 spacing--8x" />
                          <div
                            data-testid="CheckboxList-title"
                            className="f2cf178bcd spacing--4x"
                          >
                            <div className="e1eebb6a1e e6208ee469 d0caee4251">
                              Outdoors and views
                            </div>
                          </div>
                          <div className="aca0ade214 aaf30230d9 cd2e7d62b0">
                              <div className="a53cbfa6de ac9267e216 d8eb520c4e">
                                {Object.keys(checkboxes1).map((option) => (
                                  <div key={option}>
                                    <label
                                      htmlFor=":rf:"
                                      className="aca0ade214 aaf30230d9 c2931f4182 d79e71457a bd597ff2d8"
                                    >
                                      <input
                                        type="checkbox"
                                        id={`checkbox${option}`}
                                        className="ef785aa7f4"
                                        checked={checkboxes1[option]}
                                        onChange={() =>
                                          handleCheckboxChange1(option)
                                        }
                                      />
                                      <div className="a53cbfa6de e6208ee469">
                                        {" "}
                                        {option}
                                      </div>
                                    </label>{" "}
                                  </div>
                                ))}
                              </div>
                              <input
                                type="text"
                                placeholder="Enter Outdoors and Views"
                                value={newCheckboxLabel1}
                                class="eb46370fe1"
                                onChange={handleInputChange1}
                              />
                              <button onClick={handleAddCheckbox1} style={{
                                marginTop: "10px",
                                padding: "10px 20px",
                                fontSize: "16px",
                                backgroundColor: "#70bbe9",
                                color: "white",
                                border: "none",
                                borderRadius: "5px",
                                cursor: "pointer",
                                transition: "background-color 0.3s",
                              }}>
                                Add Outdoors and views
                              </button>
                            </div>
                          <div className="spacing--8x" />
                          <hr className="b9bfeba2b4 b288f61df6 spacing--8x" />
                          <div
                            data-testid="CheckboxList-title"
                            className="f2cf178bcd spacing--4x"
                          >
                            <div className="e1eebb6a1e e6208ee469 d0caee4251">
                              Food and drink
                            </div>
                          </div>
                          <div className="aca0ade214 aaf30230d9 cd2e7d62b0">
                              <div className="a53cbfa6de ac9267e216 d8eb520c4e">
                                {Object.keys(checkboxes2).map((option) => (
                                  <div key={option}>
                                    <label
                                      htmlFor=":rf:"
                                      className="aca0ade214 aaf30230d9 c2931f4182 d79e71457a bd597ff2d8"
                                    >
                                      <input
                                        type="checkbox"
                                        id={`checkbox${option}`}
                                        className="ef785aa7f4"
                                        checked={checkboxes2[option]}
                                        onChange={() =>
                                          handleCheckboxChange2(option)
                                        }
                                      />
                                      <div className="a53cbfa6de e6208ee469">
                                        {" "}
                                        {option}
                                      </div>
                                    </label>{" "}
                                  </div>
                                ))}
                              </div>
                              <input
                                type="text"
                                placeholder="Enter Food and drink"
                                value={newCheckboxLabel2}
                                class="eb46370fe1"
                                onChange={handleInputChange2}
                              />
                              <button onClick={handleAddCheckbox2} style={{
                                marginTop: "10px",
                                padding: "10px 20px",
                                fontSize: "16px",
                                backgroundColor: "#70bbe9",
                                color: "white",
                                border: "none",
                                borderRadius: "5px",
                                cursor: "pointer",
                                transition: "background-color 0.3s",
                              }}>
                                Add Food and drink
                              </button>
                            </div>
                        </div>
                      </div>
                   
                    </div>
                  </div>
                  <div className="form-cta">
                    <div>
                      <div className="form-buttons-container">
                           <div className="form-buttons-container--secondary" onClick={()=>window.history.back()}>
                          <button
                            aria-label="Back to previous step"
                            data-testid="FormButtonSecondary"
                            type="button"
                            className="a83ed08757 c21c56c305 bf0537ecb5 d691166b09 f671049264 d2529514af af7297d90d"
                          >
                            <span className="eedba9e88a">
                              <span
                                className="fcd9eec8fb c2cc050fb8"
                                aria-hidden="true"
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 24 24"
                                  data-rtl-flip="true"
                                >
                                  <path d="M15.0871 19.2361C14.9677 19.2367 14.8495 19.2134 14.7392 19.1676C14.629 19.1219 14.5289 19.0546 14.4451 18.9696L8.38799 12.9125C8.26332 12.7891 8.16475 12.6418 8.09815 12.4795C8.03156 12.3172 7.99829 12.1431 8.00034 11.9676C7.99246 11.6171 8.12303 11.2776 8.36376 11.0227L14.4208 4.96566C14.5912 4.79552 14.8221 4.69995 15.0629 4.69995C15.3037 4.69995 15.5346 4.79552 15.7049 4.96566C15.7907 5.04918 15.8588 5.14902 15.9053 5.25929C15.9518 5.36957 15.9758 5.48803 15.9758 5.60771C15.9758 5.72739 15.9518 5.84586 15.9053 5.95613C15.8588 6.0664 15.7907 6.16624 15.7049 6.24976L10.0113 11.9676L15.7292 17.6855C15.8149 17.769 15.883 17.8689 15.9295 17.9792C15.976 18.0894 16 18.2079 16 18.3276C16 18.4473 15.976 18.5657 15.9295 18.676C15.883 18.7863 15.8149 18.8861 15.7292 18.9696C15.6457 19.0551 15.5458 19.1228 15.4354 19.1686C15.3251 19.2144 15.2066 19.2374 15.0871 19.2361Z" />
                                </svg>
                              </span>
                            </span>
                          </button>
                        </div>
                        <div className="form-buttons-container--primary" onClick={HandleClick}>
                          <button
                            aria-label="Continue"
                            data-testid="FormButtonPrimary-disabled"
                            type="button"
                            // disabled
                            className="a83ed08757 c21c56c305 a4c1805887 f671049264 d2529514af c082d89982"

                          >
                            <span className="e4adce92df">Continue</span>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div></div>
        </div>
      </div>
      <img
        alt=""
        height="1"
        width="1"
        style={{ display: "none" }}
        src="https://booking.com/track_join_app_landing?progress_status=2"
      />
    </div>
  );
};

export default HomeAmenities;
