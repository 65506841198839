import React from 'react';
import imageUrl from '../../assets/coming-soon.png';
import Navbar from '../navbar/Navbar';
import Footer from '../footer/Footer';
const HoneyMoonList = () => {
  return ( <>
    
 <Navbar/>


 
 <div class="header-margin"></div>
       
        <section className="layout-pt-md layout-pb-lg">
          <div className="containerh">
            <div className="rowh y-gap-30" style={{display:'flex'}}>
              <div className="col-xl-3 col-lg-4 lg:d-none">
                <aside className="sidebar y-gap-40">
                  <div className="sidebar__item -no-border">
                    <div className="px-20 py-20 bg-light-2 rounded-4">
                      <h5 className="text-18 fw-500 mb-10">Search Tours</h5>
                      <div className="rowhh y-gap-20 pt-20">
                        <div className="col-12">
                          <div className="px-20 py-10 bg-white rounded-4">
                            <div className="searchMenu-loc  js-form-dd js-liverSearch">
                              <div className="d-flex" data-x-dd-click="searchMenu-loc">
                                {/* <i className="icon-location-2 text-20 text-light-1 mt-5" /> */}
                                <div className="ml-10">
                                  <h4 className="text-15 fw-500 ls-2 lh-16">Destinations</h4>
                                  <div className="text-15 text-light-1 ls-2 lh-16">
                                    <input autoComplete="off" type="search" placeholder="London" className="js-search js-dd-focus" />
                                  </div>
                                </div>
                              </div>
                              <div className="searchMenu-loc__field shadow-2 js-popup-window" data-x-dd="searchMenu-loc" data-x-dd-toggle="-is-active">
                                <div className="bg-white px-30 py-30 sm:px-0 sm:py-15 rounded-4">
                                  <div className="y-gap-5 js-results">
                                    <div>
                                      <button className="-link d-block col-12 text-left rounded-4 px-20 py-15 js-search-option">
                                        <div className="d-flex">
                                          <div className="icon-location-2 text-light-1 text-20 pt-4" />
                                          <div className="ml-10">
                                            <div className="text-15 lh-12 fw-500 js-search-option-target">London</div>
                                            <div className="text-14 lh-12 text-light-1 mt-5">Greater London, United Kingdom</div>
                                          </div>
                                        </div>
                                      </button>
                                    </div>
                                    <div>
                                      <button className="-link d-block col-12 text-left rounded-4 px-20 py-15 js-search-option">
                                        <div className="d-flex">
                                          <div className="icon-location-2 text-light-1 text-20 pt-4" />
                                          <div className="ml-10">
                                            <div className="text-15 lh-12 fw-500 js-search-option-target">New York</div>
                                            <div className="text-14 lh-12 text-light-1 mt-5">New York State, United States</div>
                                          </div>
                                        </div>
                                      </button>
                                    </div>
                                    <div>
                                      <button className="-link d-block col-12 text-left rounded-4 px-20 py-15 js-search-option">
                                        <div className="d-flex">
                                          <div className="icon-location-2 text-light-1 text-20 pt-4" />
                                          <div className="ml-10">
                                            <div className="text-15 lh-12 fw-500 js-search-option-target">Paris</div>
                                            <div className="text-14 lh-12 text-light-1 mt-5">France</div>
                                          </div>
                                        </div>
                                      </button>
                                    </div>
                                    <div>
                                      <button className="-link d-block col-12 text-left rounded-4 px-20 py-15 js-search-option">
                                        <div className="d-flex">
                                          <div className="icon-location-2 text-light-1 text-20 pt-4" />
                                          <div className="ml-10">
                                            <div className="text-15 lh-12 fw-500 js-search-option-target">Madrid</div>
                                            <div className="text-14 lh-12 text-light-1 mt-5">Spain</div>
                                          </div>
                                        </div>
                                      </button>
                                    </div>
                                    <div>
                                      <button className="-link d-block col-12 text-left rounded-4 px-20 py-15 js-search-option">
                                        <div className="d-flex">
                                          <div className="icon-location-2 text-light-1 text-20 pt-4" />
                                          <div className="ml-10">
                                            <div className="text-15 lh-12 fw-500 js-search-option-target">Santorini</div>
                                            <div className="text-14 lh-12 text-light-1 mt-5">Greece</div>
                                          </div>
                                        </div>
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-12">
                          <div className="px-20 py-10 bg-white rounded-4">
                            <div className="searchMenu-date  js-form-dd js-calendar js-calendar-el">
                              <div className="d-flex" data-x-dd-click="searchMenu-date">
                                {/* <i className="icon-calendar-2 text-20 text-light-1 mt-5" /> */}
                                <div className="ml-10">
                                  <h4 className="text-15 fw-500 ls-2 lh-16">Check in - Check out</h4>
                                  <div className="capitalize text-15 text-light-1 ls-2 lh-16">
                                    <span className="js-first-date">Wed 2 Mar</span>
                                    -
                                    <span className="js-last-date">Fri 11 Apr</span>
                                  </div>
                                </div>
                              </div>
                              <div className="searchMenu-date__field shadow-2" data-x-dd="searchMenu-date" data-x-dd-toggle="-is-active">
                                <div className="bg-white px-30 py-30 rounded-4">
                                  <div className="elCalendar js-calendar-el-calendar">
                                    <div className="elCalendar__slider js-calendar-slider swiper-initialized swiper-horizontal swiper-pointer-events swiper-autoheight">
                                    
                                    
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-12">
                          <button className="buttonh -dark-1 py-15 px-35 h-60 col-12 rounded-4 bg-blue-1 text-white">
                            {/* <i className="icon-search text-20 mr-10" /> */}
                            Search
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  </div>
                  <div className="sidebar__item -no-border">
                    <h5 className="text-18 fw-500 mb-10">Category Types</h5>
                    <div className="sidebar-checkbox">
                      <div className="rowhh y-gap-10 items-center justify-between">
                        <div className="col-autoh">
                          <div className="d-flex items-center">
                            <div className="form-checkbox ">
                              <input type="checkbox" name="name" />
                              <div className="form-checkbox__mark">
                                <div className="form-checkbox__icon icon-check" />
                              </div>
                            </div>
                            <div className="text-15 ml-10">Tours</div>
                          </div>
                        </div>
                        <div className="col-autoh">
                          <div className="text-15 text-light-1">92</div>
                        </div>
                      </div>
                      <div className="rowhh y-gap-10 items-center justify-between">
                        <div className="col-autoh">
                          <div className="d-flex items-center">
                            <div className="form-checkbox ">
                              <input type="checkbox" name="name" />
                              <div className="form-checkbox__mark">
                                <div className="form-checkbox__icon icon-check" />
                              </div>
                            </div>
                            <div className="text-15 ml-10">Attractions </div>
                          </div>
                        </div>
                        <div className="col-autoh">
                          <div className="text-15 text-light-1">45</div>
                        </div>
                      </div>
                      <div className="rowh y-gap-10 items-center justify-between">
                        <div className="col-autoh">
                          <div className="d-flex items-center">
                            <div className="form-checkbox ">
                              <input type="checkbox" name="name" />
                              <div className="form-checkbox__mark">
                                <div className="form-checkbox__icon icon-check" />
                              </div>
                            </div>
                            <div className="text-15 ml-10">Day Trips</div>
                          </div>
                        </div>
                        <div className="col-autoh">
                          <div className="text-15 text-light-1">21</div>
                        </div>
                      </div>
                      <div className="rowh y-gap-10 items-center justify-between">
                        <div className="col-autoh">
                          <div className="d-flex items-center">
                            <div className="form-checkbox ">
                              <input type="checkbox" name="name" />
                              <div className="form-checkbox__mark">
                                <div className="form-checkbox__icon icon-check" />
                              </div>
                            </div>
                            <div className="text-15 ml-10">Outdoor Activities </div>
                          </div>
                        </div>
                        <div className="col-autoh">
                          <div className="text-15 text-light-1">78</div>
                        </div>
                      </div>
                      <div className="rowh y-gap-10 items-center justify-between">
                        <div className="col-autoh">
                          <div className="d-flex items-center">
                            <div className="form-checkbox ">
                              <input type="checkbox" name="name" />
                              <div className="form-checkbox__mark">
                                <div className="form-checkbox__icon icon-check" />
                              </div>
                            </div>
                            <div className="text-15 ml-10">Concerts &amp; Shows </div>
                          </div>
                        </div>
                        <div className="col-autoh">
                          <div className="text-15 text-light-1">679</div>
                        </div>
                      </div>
                    </div>
                  </div>
                
               
                  <div className="sidebar__item">
                    <h5 className="text-18 fw-500 mb-10">Duration</h5>
                    <div className="sidebar-checkbox">
                      <div className="rowh y-gap-10 items-center justify-between">
                        <div className="col-autoh">
                          <div className="d-flex items-center">
                            <div className="form-checkbox ">
                              <input type="checkbox" name="name" />
                              <div className="form-checkbox__mark">
                                <div className="form-checkbox__icon icon-check" />
                              </div>
                            </div>
                            <div className="text-15 ml-10">Up to 1 hour</div>
                          </div>
                        </div>
                        <div className="col-autoh">
                          <div className="text-15 text-light-1">92</div>
                        </div>
                      </div>
                      <div className="rowh y-gap-10 items-center justify-between">
                        <div className="col-autoh">
                          <div className="d-flex items-center">
                            <div className="form-checkbox ">
                              <input type="checkbox" name="name" />
                              <div className="form-checkbox__mark">
                                <div className="form-checkbox__icon icon-check" />
                              </div>
                            </div>
                            <div className="text-15 ml-10">1 to 4 hours </div>
                          </div>
                        </div>
                        <div className="col-autoh">
                          <div className="text-15 text-light-1">45</div>
                        </div>
                      </div>
                      <div className="rowh y-gap-10 items-center justify-between">
                        <div className="col-autoh">
                          <div className="d-flex items-center">
                            <div className="form-checkbox ">
                              <input type="checkbox" name="name" />
                              <div className="form-checkbox__mark">
                                <div className="form-checkbox__icon icon-check" />
                              </div>
                            </div>
                            <div className="text-15 ml-10">4 hours to 1 day </div>
                          </div>
                        </div>
                        <div className="col-autoh">
                          <div className="text-15 text-light-1">21</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="sidebar__item">
                    <h5 className="text-18 fw-500 mb-10">Languages</h5>
                    <div className="sidebar-checkbox">
                      <div className="rowh y-gap-10 items-center justify-between">
                        <div className="col-autoh">
                          <div className="d-flex items-center">
                            <div className="form-checkbox ">
                              <input type="checkbox" name="name" />
                              <div className="form-checkbox__mark">
                                <div className="form-checkbox__icon icon-check" />
                              </div>
                            </div>
                            <div className="text-15 ml-10">English</div>
                          </div>
                        </div>
                        <div className="col-autoh">
                          <div className="text-15 text-light-1">92</div>
                        </div>
                      </div>
                      <div className="rowh y-gap-10 items-center justify-between">
                        <div className="col-autoh">
                          <div className="d-flex items-center">
                            <div className="form-checkbox ">
                              <input type="checkbox" name="name" />
                              <div className="form-checkbox__mark">
                                <div className="form-checkbox__icon icon-check" />
                              </div>
                            </div>
                            <div className="text-15 ml-10">Spanish</div>
                          </div>
                        </div>
                        <div className="col-autoh">
                          <div className="text-15 text-light-1">45</div>
                        </div>
                      </div>
                      <div className="rowh y-gap-10 items-center justify-between">
                        <div className="col-autoh">
                          <div className="d-flex items-center">
                            <div className="form-checkbox ">
                              <input type="checkbox" name="name" />
                              <div className="form-checkbox__mark">
                                <div className="form-checkbox__icon icon-check" />
                              </div>
                            </div>
                            <div className="text-15 ml-10">French</div>
                          </div>
                        </div>
                        <div className="col-autoh">
                          <div className="text-15 text-light-1">45</div>
                        </div>
                      </div>
                      <div className="rowh y-gap-10 items-center justify-between">
                        <div className="col-autoh">
                          <div className="d-flex items-center">
                            <div className="form-checkbox ">
                              <input type="checkbox" name="name" />
                              <div className="form-checkbox__mark">
                                <div className="form-checkbox__icon icon-check" />
                              </div>
                            </div>
                            <div className="text-15 ml-10">Turkish</div>
                          </div>
                        </div>
                        <div className="col-autoh">
                          <div className="text-15 text-light-1">21</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </aside>
              </div>
              <div className="col-xl-9 col-lg-8">
                <div className="rowh y-gap-10 items-center justify-between">
                  
                  <div className="col-autoh">
                    <div className="rowh x-gap-20 y-gap-20">
                     
                      <div className="col-autoh d-none lg:d-block">
                        <button data-x-click="filterPopup" className="buttonh -blue-1 h-40 px-20 rounded-100 bg-blue-1-05 text-15 text-blue-1">
                          {/* <i className="icon-up-down text-14 mr-10" /> */}
                          Filter
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="filterPopup bg-white" data-x="filterPopup" data-x-toggle="-is-active">
                  <aside className="sidebar -mobile-filter">
                    <div data-x-click="filterPopup" className="-icon-close">
                      {/* <i className="icon-close" /> */}
                    </div>
                    <div className="sidebar__item -no-border">
                      <h5 className="text-18 fw-500 mb-10">Category Types</h5>
                      <div className="sidebar-checkbox">
                        <div className="rowh y-gap-10 items-center justify-between">
                          <div className="col-autoh">
                            <div className="d-flex items-center">
                              <div className="form-checkbox ">
                                <input type="checkbox" name="name" />
                                <div className="form-checkbox__mark">
                                  <div className="form-checkbox__icon icon-check" />
                                </div>
                              </div>
                              <div className="text-15 ml-10">Tours</div>
                            </div>
                          </div>
                          <div className="col-autoh">
                            <div className="text-15 text-light-1">92</div>
                          </div>
                        </div>
                        <div className="rowh y-gap-10 items-center justify-between">
                          <div className="col-autoh">
                            <div className="d-flex items-center">
                              <div className="form-checkbox ">
                                <input type="checkbox" name="name" />
                                <div className="form-checkbox__mark">
                                  <div className="form-checkbox__icon icon-check" />
                                </div>
                              </div>
                              <div className="text-15 ml-10">Attractions </div>
                            </div>
                          </div>
                          <div className="col-autoh">
                            <div className="text-15 text-light-1">45</div>
                          </div>
                        </div>
                        <div className="rowh y-gap-10 items-center justify-between">
                          <div className="col-autoh">
                            <div className="d-flex items-center">
                              <div className="form-checkbox ">
                                <input type="checkbox" name="name" />
                                <div className="form-checkbox__mark">
                                  <div className="form-checkbox__icon icon-check" />
                                </div>
                              </div>
                              <div className="text-15 ml-10">Day Trips</div>
                            </div>
                          </div>
                          <div className="col-autoh">
                            <div className="text-15 text-light-1">21</div>
                          </div>
                        </div>
                        <div className="rowh y-gap-10 items-center justify-between">
                          <div className="col-autoh">
                            <div className="d-flex items-center">
                              <div className="form-checkbox ">
                                <input type="checkbox" name="name" />
                                <div className="form-checkbox__mark">
                                  <div className="form-checkbox__icon icon-check" />
                                </div>
                              </div>
                              <div className="text-15 ml-10">Outdoor Activities </div>
                            </div>
                          </div>
                          <div className="col-autoh">
                            <div className="text-15 text-light-1">78</div>
                          </div>
                        </div>
                        <div className="rowh y-gap-10 items-center justify-between">
                          <div className="col-autoh">
                            <div className="d-flex items-center">
                              <div className="form-checkbox ">
                                <input type="checkbox" name="name" />
                                <div className="form-checkbox__mark">
                                  <div className="form-checkbox__icon icon-check" />
                                </div>
                              </div>
                              <div className="text-15 ml-10">Concerts &amp; Shows </div>
                            </div>
                          </div>
                          <div className="col-autoh">
                            <div className="text-15 text-light-1">679</div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="sidebar__item">
                      <h5 className="text-18 fw-500 mb-10">Other</h5>
                      <div className="sidebar-checkbox">
                        <div className="rowh y-gap-10 items-center justify-between">
                          <div className="col-autoh">
                            <div className="d-flex items-center">
                              <div className="form-checkbox ">
                                <input type="checkbox" name="name" />
                                <div className="form-checkbox__mark">
                                  <div className="form-checkbox__icon icon-check" />
                                </div>
                              </div>
                              <div className="text-15 ml-10">Free cancellation</div>
                            </div>
                          </div>
                          <div className="col-autoh">
                            <div className="text-15 text-light-1">92</div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="sidebar__item pb-30">
                      <h5 className="text-18 fw-500 mb-10">Price</h5>
                      <div className="rowh x-gap-10 y-gap-30">
                        <div className="col-12">
                          <div className="js-price-rangeSlider">
                            <div className="text-14 fw-500" />
                            <div className="d-flex justify-between mb-20">
                              <div className="text-15 text-dark-1">
                                <span className="js-lower">$0</span>
                                -
                                <span className="js-upper">$500</span>
                              </div>
                            </div>
                            <div className="px-5">
                              <div className="js-slider noUi-target noUi-ltr noUi-horizontal noUi-txt-dir-ltr"><div className="noUi-base"><div className="noUi-connects"><div className="noUi-connect" style={{transform: 'translate(0%, 0px) scale(0.25, 1)'}} /></div><div className="noUi-origin" style={{transform: 'translate(-100%, 0px)', zIndex: 5}}><div className="noUi-handle noUi-handle-lower" data-handle={0} tabIndex={0} role="slider" aria-orientation="horizontal" aria-valuemin={0.0} aria-valuemax={500.0} aria-valuenow={0.0} aria-valuetext="$0"><div className="noUi-touch-area" /></div></div><div className="noUi-origin" style={{transform: 'translate(-75%, 0px)', zIndex: 6}}><div className="noUi-handle noUi-handle-upper" data-handle={1} tabIndex={0} role="slider" aria-orientation="horizontal" aria-valuemin={0.0} aria-valuemax={2000.0} aria-valuenow={500.0} aria-valuetext="$500"><div className="noUi-touch-area" /></div></div></div></div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="sidebar__item">
                      <h5 className="text-18 fw-500 mb-10">Duration</h5>
                      <div className="sidebar-checkbox">
                        <div className="rowh y-gap-10 items-center justify-between">
                          <div className="col-autoh">
                            <div className="d-flex items-center">
                              <div className="form-checkbox ">
                                <input type="checkbox" name="name" />
                                <div className="form-checkbox__mark">
                                  <div className="form-checkbox__icon icon-check" />
                                </div>
                              </div>
                              <div className="text-15 ml-10">Up to 1 hour</div>
                            </div>
                          </div>
                          <div className="col-autoh">
                            <div className="text-15 text-light-1">92</div>
                          </div>
                        </div>
                        <div className="rowh y-gap-10 items-center justify-between">
                          <div className="col-autoh">
                            <div className="d-flex items-center">
                              <div className="form-checkbox ">
                                <input type="checkbox" name="name" />
                                <div className="form-checkbox__mark">
                                  <div className="form-checkbox__icon icon-check" />
                                </div>
                              </div>
                              <div className="text-15 ml-10">1 to 4 hours </div>
                            </div>
                          </div>
                          <div className="col-autoh">
                            <div className="text-15 text-light-1">45</div>
                          </div>
                        </div>
                        <div className="rowh y-gap-10 items-center justify-between">
                          <div className="col-autoh">
                            <div className="d-flex items-center">
                              <div className="form-checkbox ">
                                <input type="checkbox" name="name" />
                                <div className="form-checkbox__mark">
                                  <div className="form-checkbox__icon icon-check" />
                                </div>
                              </div>
                              <div className="text-15 ml-10">4 hours to 1 day </div>
                            </div>
                          </div>
                          <div className="col-autoh">
                            <div className="text-15 text-light-1">21</div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="sidebar__item">
                      <h5 className="text-18 fw-500 mb-10">Languages</h5>
                      <div className="sidebar-checkbox">
                        <div className="rowh y-gap-10 items-center justify-between">
                          <div className="col-autoh">
                            <div className="d-flex items-center">
                              <div className="form-checkbox ">
                                <input type="checkbox" name="name" />
                                <div className="form-checkbox__mark">
                                  <div className="form-checkbox__icon icon-check" />
                                </div>
                              </div>
                              <div className="text-15 ml-10">English</div>
                            </div>
                          </div>
                          <div className="col-autoh">
                            <div className="text-15 text-light-1">92</div>
                          </div>
                        </div>
                        <div className="rowh y-gap-10 items-center justify-between">
                          <div className="col-autoh">
                            <div className="d-flex items-center">
                              <div className="form-checkbox ">
                                <input type="checkbox" name="name" />
                                <div className="form-checkbox__mark">
                                  <div className="form-checkbox__icon icon-check" />
                                </div>
                              </div>
                              <div className="text-15 ml-10">Spanish</div>
                            </div>
                          </div>
                          <div className="col-autoh">
                            <div className="text-15 text-light-1">45</div>
                          </div>
                        </div>
                        <div className="rowh y-gap-10 items-center justify-between">
                          <div className="col-autoh">
                            <div className="d-flex items-center">
                              <div className="form-checkbox ">
                                <input type="checkbox" name="name" />
                                <div className="form-checkbox__mark">
                                  <div className="form-checkbox__icon icon-check" />
                                </div>
                              </div>
                              <div className="text-15 ml-10">French</div>
                            </div>
                          </div>
                          <div className="col-autoh">
                            <div className="text-15 text-light-1">45</div>
                          </div>
                        </div>
                        <div className="rowh y-gap-10 items-center justify-between">
                          <div className="col-autoh">
                            <div className="d-flex items-center">
                              <div className="form-checkbox ">
                                <input type="checkbox" name="name" />
                                <div className="form-checkbox__mark">
                                  <div className="form-checkbox__icon icon-check" />
                                </div>
                              </div>
                              <div className="text-15 ml-10">Turkish</div>
                            </div>
                          </div>
                          <div className="col-autoh">
                            <div className="text-15 text-light-1">21</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </aside>
                </div>
                <div className="border-top-light mt-30 mb-30" />
                <div className="rowh y-gap-30">
                  <div className="col-lg-4 col-sm-6">
                    <a href="/Exclusivepackagedetails" className="tourCard -type-1 rounded-4 ">
                      <div className="tourCard__image">
                        <div className="cardImage ratio ratio-1:1">
                          <div className="cardImage__content">
                            <img className="rounded-4 col-12" src="https://creativelayers.net/themes/gotrip-html/img/lists/tour/2/1.png" alt="image" />
                          </div>
                          
                        </div>
                      </div>
                      <div className="tourCard__content mt-10">
                        <div className="d-flex items-center lh-14 mb-5">
                          <div className="text-14 text-light-1">16+ hours</div>
                          <div className="size-3 bg-light-1 rounded-full ml-10 mr-10" />
                          <div className="text-14 text-light-1">Full-day Tours</div>
                        </div>
                        <h4 className="tourCard__title text-dark-1 text-18 lh-16 fw-500">
                          <span>Stonehenge, Windsor Castle and Bath with Pub Lunch in Lacock</span>
                        </h4>
                        <p className="text-light-1 lh-14 text-14 mt-5">Westminster Borough, London</p>
                        <div className="rowh justify-between items-center pt-15">
                          
                          <div className="col-autoh">
                            <div className="text-14 text-light-1">
                              From
                              <span className="text-16 fw-500 text-dark-1">US$72</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </a>
                  </div>
                  <div className="col-lg-4 col-sm-6">
                    <a href="/Exclusivepackagedetails" className="tourCard -type-1 rounded-4 ">
                      <div className="tourCard__image">
                        <div className="cardImage ratio ratio-1:1">
                          <div className="cardImage__content">
                            <div className="cardImage-slider rounded-4 overflow-hidden js-cardImage-slider swiper-initialized swiper-horizontal swiper-pointer-events swiper-backface-hidden">
                              <div className="swiper-wrapper" id="swiper-wrapper-e983736224b69d1a" aria-live="polite" style={{transitionDuration: '0ms', transform: 'translate3d(-255px, 0px, 0px)'}}><div className="swiper-slide swiper-slide-duplicate swiper-slide-prev" data-swiper-slide-index={2} role="group" aria-label="3 / 3" style={{width: '255px'}}>
                                  <img className="col-12" src="https://creativelayers.net/themes/gotrip-html/img/lists/tour/2/1.png" alt="image" />
                                </div>
                                <div className="swiper-slide swiper-slide-active" data-swiper-slide-index={0} role="group" aria-label="1 / 3" style={{width: '255px'}}>
                                  <img className="col-12" src="https://creativelayers.net/themes/gotrip-html/img/lists/tour/2/2.png" alt="image" />
                                </div>
                                <div className="swiper-slide swiper-slide-next" data-swiper-slide-index={1} role="group" aria-label="2 / 3" style={{width: '255px'}}>
                                  <img className="col-12" src="https://creativelayers.net/themes/gotrip-html/img/lists/tour/2/4.png" alt="image" />
                                </div>
                                <div className="swiper-slide swiper-slide-duplicate-prev" data-swiper-slide-index={2} role="group" aria-label="3 / 3" style={{width: '255px'}}>
                                  <img className="col-12" src="https://creativelayers.net/themes/gotrip-html/img/lists/tour/2/1.png" alt="image" />
                                </div>
                                <div className="swiper-slide swiper-slide-duplicate swiper-slide-duplicate-active" data-swiper-slide-index={0} role="group" aria-label="1 / 3" style={{width: '255px'}}>
                                  <img className="col-12" src="https://creativelayers.net/themes/gotrip-html/img/lists/tour/2/2.png" alt="image" />
                                </div></div>
                              <div className="cardImage-slider__pagination js-pagination swiper-pagination-clickable swiper-pagination-bullets swiper-pagination-horizontal"><div className="pagination__item is-active" tabIndex={0} role="buttonh" aria-label="Go to slide 1" aria-current="true" /><div className="pagination__item" tabIndex={0} role="buttonh" aria-label="Go to slide 2" /><div className="pagination__item" tabIndex={0} role="buttonh" aria-label="Go to slide 3" /></div>
                              <div className="cardImage-slider__nav -prev">
                                <button className="buttonh -blue-1 bg-white size-30 rounded-full shadow-2 js-prev" tabIndex={0} aria-label="Previous slide" aria-controls="swiper-wrapper-e983736224b69d1a">
                                  {/* <i className="icon-chevron-left text-10" /> */}
                                </button>
                              </div>
                              <div className="cardImage-slider__nav -next">
                                <button className="buttonh -blue-1 bg-white size-30 rounded-full shadow-2 js-next" tabIndex={0} aria-label="Next slide" aria-controls="swiper-wrapper-e983736224b69d1a">
                                  {/* <i className="icon-chevron-right text-10" /> */}
                                </button>
                              </div>
                              <span className="swiper-notification" aria-live="assertive" aria-atomic="true" /></div>
                          </div>
                          
                        </div>
                      </div>
                      <div className="tourCard__content mt-10">
                        <div className="d-flex items-center lh-14 mb-5">
                          <div className="text-14 text-light-1">9+ hours</div>
                          <div className="size-3 bg-light-1 rounded-full ml-10 mr-10" />
                          <div className="text-14 text-light-1">Attractions &amp; Museums</div>
                        </div>
                        <h4 className="tourCard__title text-dark-1 text-18 lh-16 fw-500">
                          <span>Westminster Walking Tour &amp; Westminster Abbey Entry</span>
                        </h4>
                        <p className="text-light-1 lh-14 text-14 mt-5">Ciutat Vella, Barcelona</p>
                        <div className="rowh justify-between items-center pt-15">
                          
                          <div className="col-autoh">
                            <div className="text-14 text-light-1">
                              From
                              <span className="text-16 fw-500 text-dark-1">US$72</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </a>
                  </div>
                  <div className="col-lg-4 col-sm-6">
                    <a href="/Exclusivepackagedetails" className="tourCard -type-1 rounded-4 ">
                      <div className="tourCard__image">
                        <div className="cardImage ratio ratio-1:1">
                          <div className="cardImage__content">
                            <img className="rounded-4 col-12" src="https://creativelayers.net/themes/gotrip-html/img/lists/tour/2/3.png" alt="image" />
                          </div>
                          
                        </div>
                      </div>
                      <div className="tourCard__content mt-10">
                        <div className="d-flex items-center lh-14 mb-5">
                          <div className="text-14 text-light-1">40–55 minutes</div>
                          <div className="size-3 bg-light-1 rounded-full ml-10 mr-10" />
                          <div className="text-14 text-light-1">Private and Luxury</div>
                        </div>
                        <h4 className="tourCard__title text-dark-1 text-18 lh-16 fw-500">
                          <span>High-Speed Thames River RIB Cruise in London</span>
                        </h4>
                        <p className="text-light-1 lh-14 text-14 mt-5">Manhattan, New York</p>
                        <div className="rowh justify-between items-center pt-15">
                          
                          <div className="col-autoh">
                            <div className="text-14 text-light-1">
                              From
                              <span className="text-16 fw-500 text-dark-1">US$72</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </a>
                  </div>
                  <div className="col-lg-4 col-sm-6">
                    <a href="/Exclusivepackagedetails" className="tourCard -type-1 rounded-4 ">
                      <div className="tourCard__image">
                        <div className="cardImage ratio ratio-1:1">
                          <div className="cardImage__content">
                            <img className="rounded-4 col-12" src="https://creativelayers.net/themes/gotrip-html/img/lists/tour/2/4.png" alt="image" />
                          </div>
                          
                        </div>
                      </div>
                      <div className="tourCard__content mt-10">
                        <div className="d-flex items-center lh-14 mb-5">
                          <div className="text-14 text-light-1">94+ days</div>
                          <div className="size-3 bg-light-1 rounded-full ml-10 mr-10" />
                          <div className="text-14 text-light-1">Bus Tours</div>
                        </div>
                        <h4 className="tourCard__title text-dark-1 text-18 lh-16 fw-500">
                          <span>Edinburgh Darkside Walking Tour: Mysteries, Murder and Legends</span>
                        </h4>
                        <p className="text-light-1 lh-14 text-14 mt-5">Vaticano Prati, Rome</p>
                        <div className="rowh justify-between items-center pt-15">
                          
                          <div className="col-autoh">
                            <div className="text-14 text-light-1">
                              From
                              <span className="text-16 fw-500 text-dark-1">US$72</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </a>
                  </div>
                  <div className="col-lg-4 col-sm-6">
                    <a href="/Exclusivepackagedetails" className="tourCard -type-1 rounded-4 ">
                      <div className="tourCard__image">
                        <div className="cardImage ratio ratio-1:1">
                          <div className="cardImage__content">
                            <img className="rounded-4 col-12" src="https://creativelayers.net/themes/gotrip-html/img/lists/tour/2/5.png" alt="image" />
                          </div>
                          
                        </div>
                      </div>
                      <div className="tourCard__content mt-10">
                        <div className="d-flex items-center lh-14 mb-5">
                          <div className="text-14 text-light-1">94+ days</div>
                          <div className="size-3 bg-light-1 rounded-full ml-10 mr-10" />
                          <div className="text-14 text-light-1">Bus Tours</div>
                        </div>
                        <h4 className="tourCard__title text-dark-1 text-18 lh-16 fw-500">
                          <span>Edinburgh Darkside Walking Tour: Mysteries, Murder and Legends</span>
                        </h4>
                        <p className="text-light-1 lh-14 text-14 mt-5">Vaticano Prati, Rome</p>
                        <div className="rowh justify-between items-center pt-15">
                          
                          <div className="col-autoh">
                            <div className="text-14 text-light-1">
                              From
                              <span className="text-16 fw-500 text-dark-1">US$72</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </a>
                  </div>
                  <div className="col-lg-4 col-sm-6">
                    <a href="/Exclusivepackagedetails" className="tourCard -type-1 rounded-4 ">
                      <div className="tourCard__image">
                        <div className="cardImage ratio ratio-1:1">
                          <div className="cardImage__content">
                            <img className="rounded-4 col-12" src="https://creativelayers.net/themes/gotrip-html/img/lists/tour/2/6.png" alt="image" />
                          </div>
                          
                        </div>
                      </div>
                      <div className="tourCard__content mt-10">
                        <div className="d-flex items-center lh-14 mb-5">
                          <div className="text-14 text-light-1">94+ days</div>
                          <div className="size-3 bg-light-1 rounded-full ml-10 mr-10" />
                          <div className="text-14 text-light-1">Bus Tours</div>
                        </div>
                        <h4 className="tourCard__title text-dark-1 text-18 lh-16 fw-500">
                          <span>Edinburgh Darkside Walking Tour: Mysteries, Murder and Legends</span>
                        </h4>
                        <p className="text-light-1 lh-14 text-14 mt-5">Vaticano Prati, Rome</p>
                        <div className="rowh justify-between items-center pt-15">
                          
                          <div className="col-autoh">
                            <div className="text-14 text-light-1">
                              From
                              <span className="text-16 fw-500 text-dark-1">US$72</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </a>
                  </div>
                  <div className="col-lg-4 col-sm-6">
                    <a href="/Exclusivepackagedetails" className="tourCard -type-1 rounded-4 ">
                      <div className="tourCard__image">
                        <div className="cardImage ratio ratio-1:1">
                          <div className="cardImage__content">
                            <img className="rounded-4 col-12" src="https://creativelayers.net/themes/gotrip-html/img/lists/tour/2/7.png" alt="image" />
                          </div>
                          
                        </div>
                      </div>
                      <div className="tourCard__content mt-10">
                        <div className="d-flex items-center lh-14 mb-5">
                          <div className="text-14 text-light-1">94+ days</div>
                          <div className="size-3 bg-light-1 rounded-full ml-10 mr-10" />
                          <div className="text-14 text-light-1">Bus Tours</div>
                        </div>
                        <h4 className="tourCard__title text-dark-1 text-18 lh-16 fw-500">
                          <span>Edinburgh Darkside Walking Tour: Mysteries, Murder and Legends</span>
                        </h4>
                        <p className="text-light-1 lh-14 text-14 mt-5">Vaticano Prati, Rome</p>
                        <div className="rowh justify-between items-center pt-15">
                          
                          <div className="col-autoh">
                            <div className="text-14 text-light-1">
                              From
                              <span className="text-16 fw-500 text-dark-1">US$72</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </a>
                  </div>
                  <div className="col-lg-4 col-sm-6">
                    <a href="/Exclusivepackagedetails" className="tourCard -type-1 rounded-4 ">
                      <div className="tourCard__image">
                        <div className="cardImage ratio ratio-1:1">
                          <div className="cardImage__content">
                            <img className="rounded-4 col-12" src="https://creativelayers.net/themes/gotrip-html/img/lists/tour/2/8.png" alt="image" />
                          </div>
                          
                        </div>
                      </div>
                      <div className="tourCard__content mt-10">
                        <div className="d-flex items-center lh-14 mb-5">
                          <div className="text-14 text-light-1">94+ days</div>
                          <div className="size-3 bg-light-1 rounded-full ml-10 mr-10" />
                          <div className="text-14 text-light-1">Bus Tours</div>
                        </div>
                        <h4 className="tourCard__title text-dark-1 text-18 lh-16 fw-500">
                          <span>Edinburgh Darkside Walking Tour: Mysteries, Murder and Legends</span>
                        </h4>
                        <p className="text-light-1 lh-14 text-14 mt-5">Vaticano Prati, Rome</p>
                        <div className="rowh justify-between items-center pt-15">
                          
                          <div className="col-autoh">
                            <div className="text-14 text-light-1">
                              From
                              <span className="text-16 fw-500 text-dark-1">US$72</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </a>
                  </div>
                  <div className="col-lg-4 col-sm-6">
                    <a href="/Exclusivepackagedetails" className="tourCard -type-1 rounded-4 ">
                      <div className="tourCard__image">
                        <div className="cardImage ratio ratio-1:1">
                          <div className="cardImage__content">
                            <img className="rounded-4 col-12" src="https://creativelayers.net/themes/gotrip-html/img/lists/tour/2/9.png" alt="image" />
                          </div>
                          
                        </div>
                      </div>
                      <div className="tourCard__content mt-10">
                        <div className="d-flex items-center lh-14 mb-5">
                          <div className="text-14 text-light-1">94+ days</div>
                          <div className="size-3 bg-light-1 rounded-full ml-10 mr-10" />
                          <div className="text-14 text-light-1">Bus Tours</div>
                        </div>
                        <h4 className="tourCard__title text-dark-1 text-18 lh-16 fw-500">
                          <span>Edinburgh Darkside Walking Tour: Mysteries, Murder and Legends</span>
                        </h4>
                        <p className="text-light-1 lh-14 text-14 mt-5">Vaticano Prati, Rome</p>
                        <div className="rowh justify-between items-center pt-15">
                          
                          <div className="col-autoh">
                            <div className="text-14 text-light-1">
                              From
                              <span className="text-16 fw-500 text-dark-1">US$72</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </a>
                  </div>
                </div>
            
              </div>
            </div>
          </div>
        </section>
    
     
      
     
  <Footer/>
    </>
  );
};

export default HoneyMoonList;