import React, { createContext, useEffect, useState } from "react";
import "./property.css";
import Apartment from "../../assets/apartments.png";
import Apartments from "../../assets/buildings.png";

import Homestay from "../../assets/homestay.png";
import Hotel from "../../assets/hotel.png";
import VendorHeader from "./Vendorheader";
import { useLocation, useNavigate } from "react-router-dom";
const RoomContext = createContext();
const Homeroomprice = () => {
  const { state } = useLocation();
  const [Roomdetails, setRoomdetails] = useState([]);
  const handleKeyDown = (e) => {
    // Prevent the input of the minus sign
    if (e.key === '-' || e.key === 'e' || e.key === 'E') {
      e.preventDefault();
    }
  };
  //console.log(state);
  const history = useNavigate();
  const [price, setprice] = useState();
  useEffect(() => {
    const storedRoomdetails = JSON.parse(sessionStorage.getItem('Roomdetails')) || [];
    
    setRoomdetails(storedRoomdetails);
  }, []);

  useEffect(() => {
    //console.log("Roomdetails updated:", Roomdetails);
    sessionStorage.setItem('Roomdetails', JSON.stringify(Roomdetails));
    
  }, [Roomdetails]);

const Roomname = JSON.parse(sessionStorage.getItem("RoomName"))
 const PrivateBathroom = JSON.parse(sessionStorage.getItem("PrivateBathroom"))
 const QuestStay = JSON.parse(sessionStorage.getItem("QuestStay"))
 const TwinBed = JSON.parse(sessionStorage.getItem("TwinBed"))
 const Queenbed = JSON.parse(sessionStorage.getItem("Queenbed"))
 const Kingbed = JSON.parse(sessionStorage.getItem("Kingbed"))
 const Fullbed = JSON.parse(sessionStorage.getItem("Fullbed"))
 const addbeds = JSON.parse(sessionStorage.getItem("addbeds"))
 const BathroomItems = JSON.parse(sessionStorage.getItem("BathroomItems"))

 

 const Roomtype = JSON.parse(sessionStorage.getItem("Roomtype"))
 const RoomUnit = JSON.parse(sessionStorage.getItem("RoomUnit"))
 const Roomsize = JSON.parse(sessionStorage.getItem("Roomsize"))
 const Roommeasure = JSON.parse(sessionStorage.getItem("Roommeasure"))
 const Roomsmoking = JSON.parse(sessionStorage.getItem("Roomsmoking"))
 const General = JSON.parse(sessionStorage.getItem("General"))
 const Outdoors = JSON.parse(sessionStorage.getItem("Outdoors"))
 const Food = JSON.parse(sessionStorage.getItem("Food"))

 const AddRoom = () => {
  const obj = {
    Roomname: Roomname,
    PrivateBathroom: PrivateBathroom,
    RoomStay: QuestStay,
    BathroomItems:BathroomItems,
    Twinbed: TwinBed,
    Queenbed: Queenbed,
    Kingbed: Kingbed,
    Fullbed: Fullbed,
    beds: addbeds,
    RoomPrice: price,
    RoomPhotos: state.uploadedImageroom.map(file => ({
      name: file.name,
      lastModified: file.lastModified,
      size: file.size,
      type:file.type,
      url: URL.createObjectURL(file)
      // ... other properties you want to include
    })),
   
   
    RoomType: Roomtype,
     RoomUnit: RoomUnit,
     RoomSize: Roomsize,
     RoomMeasure: Roommeasure,
     RoomSmoking: Roomsmoking,
  General: General,
  Outdoors: Outdoors,
  Food: Food,
  };

  // Use the setRoomdetails to update the state with the new room details
  setRoomdetails((prevRoomDetails) => [...prevRoomDetails, obj]);

  // Log the updated state to verify
  //console.log("Roomdetails updated:", Roomdetails);
 
};
  const HandleClick = () => {
    if (price === undefined) {
      alert("Please enter price");
    } else {
      // Save the RoomPrice to sessionStorage
      sessionStorage.setItem("RoomPrice", JSON.stringify(price));

      // Call the addRoom function
      AddRoom();

      // Navigate to the next page
      setTimeout(() => {
        // Navigate to the next page
        history("/homeedit", {
          state: state.Photos,
        });
      }, 1000); // Adjust the timeout as needed
      sessionStorage.removeItem("RoomName");
sessionStorage.removeItem("PrivateBathroom");
sessionStorage.removeItem("QuestStay");
sessionStorage.removeItem("TwinBed");
sessionStorage.removeItem("Queenbed");
sessionStorage.removeItem("Kingbed");
sessionStorage.removeItem("Fullbed");
sessionStorage.removeItem("addbeds");
sessionStorage.removeItem("BathroomItems");
sessionStorage.removeItem("Roomtype");
sessionStorage.removeItem("RoomUnit");
sessionStorage.removeItem("Roomsize");
sessionStorage.removeItem("Roommeasure");
sessionStorage.removeItem("Roomsmoking");
sessionStorage.removeItem("General");
sessionStorage.removeItem("Outdoors");
sessionStorage.removeItem("Food");
sessionStorage.removeItem("propertyname");
    }
  }
  //console.log(Roomdetails,"sds");
  return (
    <div
      data-capla-component="b-registration-joinapp15min-fe/"
      data-capla-namespace="b-registration-joinapp15min-feaYGUODfA"
    >
      <div id="app-container">
        <div class="error-popup-container"></div>
        <RoomContext.Provider value={{ Roomdetails, AddRoom }}>
        <div class="application">
        <VendorHeader />
          <div class="error-popup-container"></div>

          <div className="page-container">
            <div className="screens screens-animation-direction-forward">
              <div
                className="height-100 screen-change-enter-done"
                id="automation_id_screen_container_Price"
              >
                <div className="ms-container">
                  <div className="form-container">
                    <div className="f2cf178bcd screen-main-header d991e1216a">
                      <div className="f6431b446c e6208ee469 d0caee4251 screen-main-header__title">
                        Set the price per night for this room
                      </div>
                    </div>
                    <div
                      id="automation_id_price_page_tooltip"
                      className="page-tooltip__wrap"
                    >
                      <section
                        aria-labelledby=":r8:"
                        className="c82435a4b8 a178069f51 a6ae3c2b40 a18aeea94d d794b7a0f7 f53e278e95"
                        style={{ padding: 4 }}
                      >
                        <div className="aca0ade214 ebac6e22e9 c2931f4182 d79e71457a">
                          <span className="d963552ea0">
                            <span
                              className="fcd9eec8fb c2cc050fb8"
                              aria-hidden="true"
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 24 24"
                              >
                                <path d="M12.75 2.251v-1.5a.75.75 0 0 0-1.5 0v1.5a.75.75 0 0 0 1.5 0zm6.144 3.167l1.061-1.06a.75.75 0 0 0-1.06-1.061l-1.061 1.06a.75.75 0 0 0 1.06 1.061zM21 12.001h1.5a.75.75 0 0 0 0-1.5H21a.75.75 0 0 0 0 1.5zm-3.166 6.144l1.06 1.061a.75.75 0 1 0 1.061-1.06l-1.06-1.061a.75.75 0 1 0-1.061 1.06zM6.166 4.358l-1.06-1.061a.75.75 0 0 0-1.061 1.06l1.06 1.061a.75.75 0 0 0 1.061-1.06zM3 10.5H1.5a.75.75 0 0 0 0 1.5H3a.75.75 0 0 0 0-1.5zm2.106 6.584l-1.061 1.06a.75.75 0 1 0 1.06 1.061l1.061-1.06a.75.75 0 1 0-1.06-1.061zm3.144-.636v2.3a3.75 3.75 0 1 0 7.5 0v-2.3a.75.75 0 0 0-1.5 0v2.3a2.25 2.25 0 0 1-4.5 0v-2.3a.75.75 0 0 0-1.5 0zm9-5.198a5.25 5.25 0 1 1-10.5 0 5.25 5.25 0 0 1 10.5 0zm1.5 0a6.75 6.75 0 1 0-13.5 0 6.75 6.75 0 0 0 13.5 0zm-7.5 10.5v1.5a.75.75 0 0 0 1.5 0v-1.5a.75.75 0 0 0-1.5 0z" />
                              </svg>
                            </span>
                          </span>
                          <div className="aaee4e7cd3 e7a57abb1e">
                            <div className="aca0ade214 aaf30230d9 cd2e7d62b0">
                              <div className="ffd93a9ecb">
                                <h3 id=":r8:" className="e1eebb6a1e e0a7dcceb5">
                                  What if I'm not sure about my price?
                                </h3>
                                {/* <div className="abcc616ec7 cc1b961f14 c180176d40 f11eccb5e8">
                                  <button
                                    aria-label="Close banner"
                                    type="button"
                                    className="a83ed08757 c21c56c305 f38b6daa18 d691166b09 ab98298258 deab83296e f4552b6561"
                                  >
                                    <span className="eedba9e88a">
                                      <span
                                        className="fcd9eec8fb bf9a32efa5"
                                        aria-hidden="true"
                                      >
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          viewBox="0 0 24 24"
                                        >
                                          <path d="M13.31 12l6.89-6.89a.93.93 0 1 0-1.31-1.31L12 10.69 5.11 3.8A.93.93 0 0 0 3.8 5.11L10.69 12 3.8 18.89a.93.93 0 0 0 1.31 1.31L12 13.31l6.89 6.89a.93.93 0 1 0 1.31-1.31z" />
                                        </svg>
                                      </span>
                                    </span>
                                  </button>
                                </div> */}
                              </div>
                              <div className="a53cbfa6de">
                                Don't worry, you can always change it later. You
                                can even set weekend, midweek and seasonal
                                prices, giving you more control over what you
                                earn.
                              </div>
                            </div>
                          </div>
                        </div>
                      </section>
                    </div>
                    {/* <div className="ffb9c3d6a31 wide-container spacing--6x">
                      <div className="aca0ade214 ebac6e22e9 f66f916626 c2931f4182 c27e5d305d db150fece4">
                        <div className="b817090550 b736e9e3f4">
                         
                          <div className="a53cbfa6de spacing--4x">
                           
                            Properties that received bookings over the last year and share features such as location, facilities, and amenities similar to yours have usually had pricing that falls between Rs. 1157.97 and 2901.13, with a median price of Rs. 1788.45 
                         
                          </div>
                        
                          <hr className="b9bfeba2b4 b288f61df6 spacing--4x" />
                          Did this help you decide on a price?
                          <button
                            type="button"
                            className="a83ed08757 f88a5204c2 price-advice--feedback__link fde1badfe6 d8ea675e30"
                          >
                            <span
                              className="fcd9eec8fb bf9a32efa5"
                              aria-hidden="true"
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 24 24"
                              >
                                <path d="M5.239 22.5c.461.008.92.076 1.363.202l3.634 1.038c.602.172 1.226.26 1.853.26h4.885a6.75 6.75 0 0 0 6.716-6.075l.303-5.131a5.252 5.252 0 0 0-3.967-5.365l-1.477-.324A.745.745 0 0 1 18 6.382V3a3 3 0 1 0-6 0v1.554a6.75 6.75 0 0 1-6.75 6.75.75.75 0 0 0-.75.75v9.697c0 .41.329.744.739.75zm.022-1.499l-.011.75H6v-9.697l-.75.75a8.25 8.25 0 0 0 8.25-8.25V3a1.5 1.5 0 0 1 3 0v3.382a2.25 2.25 0 0 0 1.709 2.184l1.475.323a3.745 3.745 0 0 1 2.811 3.823l-.3 5.094c-.267 2.652-2.525 4.694-5.221 4.694h-4.885a5.25 5.25 0 0 1-1.44-.202L7.013 21.26A6.747 6.747 0 0 0 5.261 21zM.75 10.5h4.5l-.75-.75v13.5l.75-.75H.75l.75.75V9.75l-.75.75zm0-1.5a.75.75 0 0 0-.75.75v13.5c0 .414.336.75.75.75h4.5a.75.75 0 0 0 .75-.75V9.75A.75.75 0 0 0 5.25 9H.75z" />
                              </svg>
                            </span>
                            <span />
                          </button>
                          <button
                            type="button"
                            className="a83ed08757 f88a5204c2 price-advice--feedback__link fde1badfe6 d8ea675e30"
                          >
                            <span
                              className="fcd9eec8fb bf9a32efa5"
                              aria-hidden="true"
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 24 24"
                              >
                                <path d="M18.76 1.498a5.205 5.205 0 0 1-1.362-.2L13.764.26A6.75 6.75 0 0 0 11.911 0H7.026A6.75 6.75 0 0 0 .31 6.075l-.303 5.131a5.252 5.252 0 0 0 3.967 5.366l1.477.323a.745.745 0 0 1 .549.723V21a3 3 0 1 0 6 0v-1.554a6.75 6.75 0 0 1 6.75-6.75.75.75 0 0 0 .75-.75V2.248a.75.75 0 0 0-.74-.75zm-.02 1.5l.01-.75H18v9.698l.75-.75a8.25 8.25 0 0 0-8.25 8.25V21a1.5 1.5 0 0 1-3 0v-3.383a2.25 2.25 0 0 0-1.708-2.183l-1.476-.323a3.745 3.745 0 0 1-2.811-3.823l.3-5.094C2.072 3.542 4.33 1.5 7.026 1.5h4.885c.487 0 .972.068 1.44.202l3.635 1.038c.57.163 1.16.25 1.754.258zm.01-1.498h4.5L22.5.75v13.5l.75-.75h-4.5l.75.75V.75l-.75.75zm0-1.5a.75.75 0 0 0-.75.75v13.5c0 .414.336.75.75.75h4.5a.75.75 0 0 0 .75-.75V.75a.75.75 0 0 0-.75-.75h-4.5z" />
                              </svg>
                            </span>
                            <span />
                          </button>
                        </div>
                      </div>
                    </div> */}
                    <div className="ffb9c3d6a31 wide-container">
                      <div className="aca0ade214 ebac6e22e9 f66f916626 c2931f4182 c27e5d305d db150fece4">
                        <div className="b817090550 b736e9e3f4">
                          <div className="e1eebb6a1e spacing--4x">
                            How much do you want to charge per night?
                          </div>
                          <div>
                            <div className="form-group">
                              <div className="a53cbfa6de ac9267e216 a20293ec70">
                                <label
                                  htmlFor="desired_price"
                                  className="a53cbfa6de e6208ee469 f555271986"
                                >
                                  <span>
                                    <span className="ccb65902b2">
                                      Price guests pay
                                    </span>
                                  </span>
                                </label>
                                <div className="b9b84f4305">
                                  <div className="b93ef00bf5">INR</div>
                                  <div className="e000754250">
                                    <input
                                    type="number" onKeyDown={handleKeyDown}
                                      className="eb46370fe1"
                                      id="desired_price"
                                      value={price}
                                     onChange={(e)=>setprice(e.target.value)}
                                    />
                                    <div className="e7e5251f68" />
                                  </div>
                                </div>
                              </div>
                              {/* <div className="price-help-text spacing--2x">
                                Including taxes, commission and charges
                              </div> */}
                            </div>
                          </div>
                         
                        </div>
                      </div>
                    </div>
                  
                  </div>
                  <div className="form-cta">
                    <div>
                      <div className="form-buttons-container">
                        <div className="form-buttons-container--secondary" onClick={()=>window.history.back()}>
                          <button
                            aria-label="Back to previous step"
                            data-testid="FormButtonSecondary"
                            type="button"
                            className="a83ed08757 c21c56c305 bf0537ecb5 d691166b09 f671049264 d2529514af af7297d90d"
                          >
                            <span className="eedba9e88a">
                              <span
                                className="fcd9eec8fb c2cc050fb8"
                                aria-hidden="true"
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 24 24"
                                  data-rtl-flip="true"
                                >
                                  <path d="M15.0871 19.2361C14.9677 19.2367 14.8495 19.2134 14.7392 19.1676C14.629 19.1219 14.5289 19.0546 14.4451 18.9696L8.38799 12.9125C8.26332 12.7891 8.16475 12.6418 8.09815 12.4795C8.03156 12.3172 7.99829 12.1431 8.00034 11.9676C7.99246 11.6171 8.12303 11.2776 8.36376 11.0227L14.4208 4.96566C14.5912 4.79552 14.8221 4.69995 15.0629 4.69995C15.3037 4.69995 15.5346 4.79552 15.7049 4.96566C15.7907 5.04918 15.8588 5.14902 15.9053 5.25929C15.9518 5.36957 15.9758 5.48803 15.9758 5.60771C15.9758 5.72739 15.9518 5.84586 15.9053 5.95613C15.8588 6.0664 15.7907 6.16624 15.7049 6.24976L10.0113 11.9676L15.7292 17.6855C15.8149 17.769 15.883 17.8689 15.9295 17.9792C15.976 18.0894 16 18.2079 16 18.3276C16 18.4473 15.976 18.5657 15.9295 18.676C15.883 18.7863 15.8149 18.8861 15.7292 18.9696C15.6457 19.0551 15.5458 19.1228 15.4354 19.1686C15.3251 19.2144 15.2066 19.2374 15.0871 19.2361Z" />
                                </svg>
                              </span>
                            </span>
                          </button>
                        </div>
                        <div className="form-buttons-container--primary" onClick={HandleClick}>
                          <button
                            aria-label="Continue"
                            data-testid="FormButtonPrimary-enabled"
                            type="button"
                            className="a83ed08757 c21c56c305 a4c1805887 f671049264 d2529514af c082d89982"
                          >
                            <span className="e4adce92df">Continue</span>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <img
              alt=""
              height="1"
              width="1"
              style={{ display: "none" }}
              src="https://booking.com/track_join_app_landing?progress_status=2"
            />
          </div>
        </div>
        </RoomContext.Provider>
      </div>
    </div>
  );
};

export default Homeroomprice;
