// import "./about.css";
import React from "react";
import "./community.scoped.css";
import Profile_Dashboard from "./Profile_Dashboard";

import { MdDescription } from "react-icons/md";
import { ImMusic } from "react-icons/im";
import { GiHeartInside } from "react-icons/gi";
import { SiBandsintown } from "react-icons/si";
import { MdLocalMovies } from "react-icons/md";
import { MdOutlineLocalMovies } from "react-icons/md";
import { IoIosBook } from "react-icons/io";
import { CgGames } from "react-icons/cg";
import { RiMovieFill } from "react-icons/ri";
import { BiCameraMovie } from "react-icons/bi";
import { MdFolderSpecial } from "react-icons/md";
import { MdWork } from "react-icons/md";
import { SiTalend } from "react-icons/si";
import { AiFillSkin } from "react-icons/ai";
import { SiNamecheap } from "react-icons/si";
import { MdPlace } from "react-icons/md";
import { CgWebsite } from "react-icons/cg";
import { FaHandshake } from "react-icons/fa";
import { GiThreeFriends } from "react-icons/gi";
import { BiRepost } from "react-icons/bi";
import { GoComment } from "react-icons/go";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebook, faGooglePlus, faInstagram, faTwitter, faVk } from "@fortawesome/free-brands-svg-icons";

function About() {
  const user = JSON.parse(sessionStorage.getItem("user"));
  // const [showadd, setshowadd] = useState(false)
  const [userdetails, setuserdetails] = useState({});
  const [userPost, setuserPost] = useState([]);
  const [following, setFollowing] = useState([]);

  useEffect(() => {
    getuser();
    getPost();
    getAllFollowing();
  }, []);

  const getuser = async () => {
    let res = await axios.get(
      "https://bookingoda.com/api/user/getmyprofile/" + user._id
    );
    if (res.status === 200) {
      //console.log("getuser===>", res.data);
      setuserdetails(res.data.user);
    }
  };

  const getPost = async () => {
    let res = await axios.get(
      "https://bookingoda.com/api/user/getPostByUser/" + user._id
    );
    if (res.status === 200) {
      //console.log("getPost===>", res.data);
      setuserPost(res.data.data);
    }
  };

  const getAllFollowing = async () => {
    let res = await axios.get(
      `https://bookingoda.com/api/user/getFririndlistByuserId/${user._id}`
    );
    if (res.status === 200) {
      //console.log("getAllFollowing==>", res.data);
      setFollowing(res.data.success);
    }
  };
console.log(userdetails);
  return (
    <div className="communityhomestyle">
   
      <div className="gap2 gray-bg">
        <div
          className="container fluid mobile-correct-0"
          style={{ paddingTop: "30px" }}
        >
          <div className="row">
            <div className="col-lg-12">
              <div className="row merged20" id="page-contents">
                {/* profile Dashboard start ========================================= */}
                <div className="user-profile">
                  <Profile_Dashboard />
                </div>
                <div>
                  <Link to="/community_add_profile">
                    <button
                      style={{
                        backgroundColor: "#fa6342",
                        color: "white",
                        border: 0,
                        borderRadius: "6px",
                        boxShadow: "0px 1px 2px 1px rgb(76 76 76 / 18%)",
                        marginBottom: "10px",
                      }}
                      // onClick={() =>
                      //   window.location.assign("/community_add_profile")
                      // }
                    >
                      <span className="f-title1" style={{color:'white',padding:"10px"}}>Add</span>
                    </button>
                  </Link>
                </div>
                {/* profile Dashboard end =================================================== */}
                {/* middle page start =================================================== */}
                <div className="col-lg-4 col-md-4">
                  <aside className="sidebar">
                    <div className="central-meta stick-widget">
                      <span className="create-post">Statistics</span>
                      <div className="personal-head">
                        <span className="f-title1">
                       
                            <GiThreeFriends color="#ff7d26" size={20}/>
                          {" "}
                          Friends : {following.length} 
                        </span> <br />
                        <span className="f-title1">
                        
                            <BiRepost color="#ff7d26" size={20}/>
                          {" "}
                          Posts : {userPost?.length}
                        </span>
                        {/* <span className="f-title1">
                       
                            <GoComment color="#ff7d26" size={20}/>
                          {" "}
                          Comments : 0
                        </span> */}
                      </div>
                    </div>
                    <div className="central-meta stick-widget">
                      <span className="create-post">Personal Info</span>
                      <div className="personal-head">
                        <span className="f-title1">
                          <i className="fas fa-user"></i> About Me:
                        </span>
                        <p  style={{fontWeight:"bolder"}}>{userdetails?.aboutMe}</p>

                        <span className="f-title1">
                          <i className="fas fa-user">
                            <SiNamecheap style={{ marginTop: "5px" }} />
                          </i>{" "}
                          Nick name:
                        </span>
                        <p  style={{fontWeight:"bolder"}}>{userdetails?.nickName}</p>
                        <span className="f-title1">
                          <i className="fas fa-birthday-cake"></i> Birthday:
                        </span>
                        <p  style={{fontWeight:"bolder"}}>{userdetails?.DOB}</p>
                        <span className="f-title1">
                          <i className="fas fa-phone"></i> Phone Number:
                        </span>
                        {/* phone number um email im epdi kudukurathu ipuduye katu pothum */}
                        <p  style={{fontWeight:"bolder"}}>{userdetails?.mobile}</p>
                        <span className="f-title1">
                          <i className="fas fa-medkit"></i> Blood group:
                        </span>
                        <p  style={{fontWeight:"bolder"}}>{userdetails?.bloodGroup}</p>
                        <span className="f-title1">
                          <i className="fas fa-male"></i> Gender:
                        </span>
                        <p  style={{fontWeight:"bolder"}}>{userdetails?.gender}</p>
                        <span className="f-title1">
                          <i className="fas fa-globe"></i> Relationship Status:
                        </span>
                        <p  style={{fontWeight:"bolder"}}>{userdetails?.relationshipStatus}</p>
                        <span className="f-title1">
                          <i className="fas fa-globe1">
                            <MdPlace />
                          </i>{" "}
                          Birthplace:
                        </span>
                        <p  style={{fontWeight:"bolder"}}>{userdetails?.DOBPlace}</p>
                        <span className="f-title1">
                          <i className="fas fa-globe"></i> Country:
                        </span>
                        <p  style={{fontWeight:"bolder"}}>{userdetails?.country}</p>
                        <span className="f-title1">
                          <i className="fas fa-briefcase"></i> Occupation:
                        </span>
                        <p  style={{fontWeight:"bolder"}}>{userdetails?.occupation}</p>
                        <span className="f-title1">
                          <i className="fas fa-handshake-o1">
                            <FaHandshake />
                          </i>{" "}
                          Joined:
                        </span>
                        <p  style={{fontWeight:"bolder"}}>{userdetails?.joined}</p>

                        <span className="f-title1">
                          <i className="fas fa-envelope"></i> Email :
                        </span>
                        <p  style={{fontWeight:"bolder"}}>
                          
                            {userdetails?.email}
                        
                        </p>

                        <span className="f-title1">
                          <i className="fas fa-envelope1">
                            <CgWebsite />
                          </i>{" "}
                          Website :
                        </span>
                        <p  style={{fontWeight:"bolder"}}>{userdetails?.webSite}</p>
                      </div>
                    </div>


                    <div className="central-meta stick-widget">
                      <span className="create-post"> Social Networks</span>
                      <div className="personal-head">
                        <ul className="sociaz-media">
                          <li>
                            <a className="twitter" href={userdetails.SocialNetworks?.facebook} target="_blank" title="">
                            <FontAwesomeIcon icon={faFacebook}
                            style={{
                              borderRadius: "100%",
                             
                              display: "inline-block",
                              fontSize: "15px",
                              fontWeight: "500",
                              height: "30px",
                              lineHeight: "30px",
                              textAlign: "center",
                              transition: "all 0.2s linear 0s",
                              width: "30px",
                            }}
                          />{" "}
                            </a>
                          </li>
                          <li>
                            <a className="twitter" target="_blank" href={userdetails.SocialNetworks?.twitter}  title="">
                            <FontAwesomeIcon icon={faTwitter} 
                            style={{
                              borderRadius: "100%",
                              // color: "#fa6342",
                              display: "inline-block",
                              fontSize: "15px",
                              fontWeight: "500",
                              height: "30px",
                              lineHeight: "30px",
                              textAlign: "center",
                              transition: "all 0.2s linear 0s",
                              width: "30px",
                            }}
                          />{" "}
                            </a>
                          </li>
                          <li>
                            <a className="twitter" href={userdetails.SocialNetworks?.googlePlus} target="_blank"  title="">
                            <FontAwesomeIcon icon={faGooglePlus} 
                            style={{
                              borderRadius: "100%",
                              // color: "#fa6342",
                              display: "inline-block",
                              fontSize: "15px",
                              fontWeight: "500",
                              height: "30px",
                              lineHeight: "30px",
                              textAlign: "center",
                              transition: "all 0.2s linear 0s",
                              width: "30px",
                            }}
                          />{" "}
                            </a>
                          </li>
                          <li>
                            <a className="twitter" href={userdetails.SocialNetworks?.vk} target="_blank"  title="">
                            <FontAwesomeIcon icon={faVk} 
                            style={{
                              borderRadius: "100%",
                              // color: "#fa6342",
                              display: "inline-block",
                              fontSize: "15px",
                              fontWeight: "500",
                              height: "30px",
                              lineHeight: "30px",
                              textAlign: "center",
                              transition: "all 0.2s linear 0s",
                              width: "30px",
                            }}
                         / >{" "}
                            </a>
                          </li>
                          <li>
                            <a className="twitter" target="_blank" href={userdetails.SocialNetworks?.instagram}  title="">
                            <FontAwesomeIcon icon={faInstagram}
                            style={{
                              borderRadius: "100%",
                              // color: "#fa6342",
                              display: "inline-block",
                              fontSize: "15px",
                              fontWeight: "500",
                              height: "30px",
                              lineHeight: "30px",
                              textAlign: "center",
                              transition: "all 0.2s linear 0s",
                              width: "30px",
                            }}
                         / >{" "}
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </aside>
                </div>
                <div className="col-lg-8 col-md-8">
                  <div className="central-meta">
                    <span className="create-post">
                      General Info
                         {/* <a href="#" title="">
                        See All
                      </a> */} 
                    </span>

                    <div className="gen-metabox1">
                      <span className="f-title1">
                        <i class="fa-duotone fa-file-audi2o">
                          <MdDescription color="#ff7d26" size={20}/>
                        </i>{" "}
                        Description
                      </span>
                      <p  style={{fontWeight:"bolder"}}>{userdetails?.Description}</p>
                    </div>
                    <div className="row">
                      <div className="col-lg-6">
                        <div className="gen-metabox1">
                          <span className="f-title1">
                            <i className="fas fa-puzzle-piece" style={{color:"#ff7d26" ,size:'24px'}}></i> Hobbies
                          </span>
                          <p  style={{fontWeight:"bolder"}}>{userdetails?.hobbies}</p>
                        </div>

                        <div className="gen-metabox11">
                          <span className="f-title1">
                            <i class="fa-solid fa-headphones" style={{color:"#ff7d26" ,size:'24px'}}></i> Favourite
                            Songs
                          </span>
                          <p  style={{fontWeight:"bolder"}}>{userdetails?.FavouriteSongs}</p>
                        </div>

                        <div className="gen-metabox1">
                          <span className="f-title1">
                            {/* <i class="fa-solid fa-headphone1s"> */}
                              <ImMusic style={{color:"#ff7d26" ,size:'24px'}}/>
                            {/* </i> */}
                            {" "}
                            Latest Songs
                          </span>
                          <p  style={{fontWeight:"bolder"}}>{userdetails?.LatestSongs}</p>
                        </div>

                        <div className="gen-metabox1">
                          <span className="f-title1">
                            {/* <i class="fa-solid fa-headphone1s"> */}
                              <GiHeartInside style={{color:"#ff7d26" ,size:'24px'}}/>
                            {/* </i> */}
                            {" "}
                            Favourite Bands / Artists
                          </span>
                          <p  style={{fontWeight:"bolder"}}>{userdetails?.FavouriteBands}</p>
                        </div>

                        <div className="gen-metabox1">
                          <span className="f-title1">
                            {/* <i class="fa-solid fa-closed-captioning1"> */}
                              <SiBandsintown style={{color:"#ff7d26" ,size:'24px'}}/>
                            {/* </i> */}
                            {" "}
                            Latest Bands
                          </span>
                          <p  style={{fontWeight:"bolder"}}>{userdetails?.LatestBands}</p>
                        </div>

                        <div className="gen-metabox1">
                          <span className="f-title1">
                            {/* <i class="fa-regular fa-camera-movie1"> */}
                              <MdLocalMovies style={{color:"#ff7d26" ,size:'24px'}}/>
                            {/* </i> */}
                            Favourite Movies
                          </span>
                          <p  style={{fontWeight:"bolder"}}>{userdetails?.favouriteMovies}</p>
                        </div>

                        <div className="gen-metabox1">
                          <span className="f-title1">
                            {/* <i className="fas fa-puzzle-piece1"> */}
                              <MdOutlineLocalMovies style={{color:"#ff7d26" ,size:'24px'}}/>
                            {/* </i> */}
                            Latest Movies
                          </span>
                          <p  style={{fontWeight:"bolder"}}>{userdetails?.latestMovies}</p>
                        </div>

                        <div className="gen-metabox1">
                          <span className="f-title1">
                            {/* <i className="fas fa-puzzle-piece12"> */}
                              <IoIosBook style={{color:"#ff7d26" ,size:'24px',paddingTop:'1px'}} />
                            {/* </i> */}
                            Favourite Books
                          </span>
                          <p  style={{fontWeight:"bolder"}}>{userdetails?.favouriteBooks}</p>
                        </div>

                        <div className="gen-metabox1">
                          <span className="f-title1">
                            {/* <i className="fas fa-puzzle-piece12"> */}
                              <CgGames style={{color:"#ff7d26" ,size:'24px'}}/>
                            {/* </i> */}
                            Favourite Games
                          </span>
                          <p  style={{fontWeight:"bolder"}}>{userdetails?.favouriteGames}</p>
                        </div>

                        <div className="gen-metabox1">
                          <span className="f-title1">
                            {/* <i className="fas fa-puzzle-piece12"> */}
                             
                              <CgGames style={{color:"#ff7d26" ,size:'24px'}}/>
                            {/* </i> */}
                            Latest Game
                          </span>
                          <p  style={{fontWeight:"bolder"}}>{userdetails?.latestGame}</p>
                        </div>

                        <div className="gen-metabox1">
                          <span className="f-title1">
                            {/* <i className="fas fa-puzzle-piece12"> */}
                              <RiMovieFill style={{color:"#ff7d26" ,size:'24px'}}/>
                            {/* </i> */}
                            Favourite Series
                          </span>
                          <p  style={{fontWeight:"bolder"}}>{userdetails?.favouriteSeries}</p>
                        </div>

                        <div className="gen-metabox1">
                          <span className="f-title1">
                            {/* <i className="fas fa-puzzle-piece12"> */}
                              <BiCameraMovie style={{color:"#ff7d26" ,size:'24px'}}/>
                            {/* </i> */}
                            Latest Series
                          </span>
                          <p  style={{fontWeight:"bolder"}}>{userdetails?.latestSeries}</p>
                        </div>

                        <div className="gen-metabox1">
                          <span className="f-title1">
                            <i className="fas fa-plus" style={{color:"#ff7d26" ,size:'24px'}}></i> Others Interests
                          </span>
                          <p  style={{fontWeight:"bolder"}}>{userdetails?.OthersInterests}</p>
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="gen-metabox1">
                          <span className="f-title1">
                            <i className="fas fa-mortar-board" style={{color:"#ff7d26" ,size:'24px'}}></i> Education
                          </span>
                          <p  style={{fontWeight:"bolder"}}>
                            Insitution&nbsp;:&nbsp;
                          
                              {userdetails?.education?.Institue}
                           
                          </p>

                          <p  style={{fontWeight:"bolder"}}>
                            Course&nbsp;:&nbsp;
                          
                              {userdetails?.education?.Course}
                            
                          </p>

                          <p  style={{fontWeight:"bolder"}}>
                            Location&nbsp;:&nbsp;
                          
                              {userdetails?.education?.Location}
                            
                          </p>

                          <p  style={{fontWeight:"bolder"}}>
                            Period&nbsp;:&nbsp;
                          
                              {userdetails?.education?.Period}
                            
                          </p>
                        </div>

                        <div className="gen-metabox1">
                          <span className="f-title1">
                            {/* <i className="fas fa-certificate1" > */}
                              <MdFolderSpecial style={{color:"#ff7d26" ,size:'24px'}}/>
                            {/* </i> */}
                            {" "}
                            Specialization
                          </span>
                          <p  style={{fontWeight:"bolder"}}>{userdetails?.Specialization}</p>
                        </div>

                        <div className="gen-metabox1">
                          <span className="f-title1">
                            {/* <i className="fas fa-certificate1"> */}
                              <MdWork style={{color:"#ff7d26" ,size:'24px'}}/>
                            {/* </i> */}
                            {" "}
                            Employment
                          </span>
                          <p  style={{fontWeight:"bolder"}}>
                            Company&nbsp;:&nbsp;
                          
                              {userdetails?.Employment?.Company}
                           
                          </p>

                          <p  style={{fontWeight:"bolder"}}>
                            Designation&nbsp;:&nbsp;
                          
                              {userdetails?.Employment?.Designation}
                         
                          </p>

                          <p  style={{fontWeight:"bolder"}}>
                            Location&nbsp;:&nbsp;
                          
                              {userdetails?.Employment?.Location}
                           
                          </p>

                          <p  style={{fontWeight:"bolder"}}>
                            Period&nbsp;:&nbsp;
                          
                              {userdetails?.Employment?.Period}
                            
                          </p>
                        </div>

                        <div className="gen-metabox1">
                          <span className="f-title1">
                            {/* <i className="fas fa-certificate1"> */}
                              <MdWork style={{color:"#ff7d26" ,size:'24px'}}/>
                            {/* </i> */}
                            {" "}
                            Work and experience
                          </span>
                          <p  style={{fontWeight:"bolder"}}>{userdetails?.workAndExperience}</p>
                        </div>

                        <div className="gen-metabox1">
                          <span className="f-title1">
                            {/* <i className="fas fa-certificate1"> */}
                              <SiTalend style={{color:"#ff7d26" ,size:'24px'}}/>
                            {/* </i> */}
                            {" "}
                            Talent
                          </span>

                          <p  style={{fontWeight:"bolder"}}>
                            Insitution&nbsp;:&nbsp;
                          
                              {userdetails?.talent?.Institue}
                            
                          </p>

                          <p  style={{fontWeight:"bolder"}}>
                            Course&nbsp;:&nbsp;
                          
                              {userdetails?.talent?.Course}
                            
                          </p>

                          <p  style={{fontWeight:"bolder"}}>
                            Location&nbsp;:&nbsp;
                          
                              {userdetails?.talent?.Location}{" "}
                           
                          </p>

                          <p  style={{fontWeight:"bolder"}}>
                            Period&nbsp;:&nbsp;
                          
                              {userdetails?.talent?.Period}
                          
                          </p>
                        </div>

                        <div className="gen-metabox1">
                          <span className="f-title1">
                            {/* <i className="fas fa-certificate1" > */}
                              <AiFillSkin style={{color:"#ff7d26" ,size:'24px'}}/>
                            {/* </i> */}
                            {" "}
                            Skill Set
                          </span>
                          <p  style={{fontWeight:"bolder"}}>{userdetails?.SkillSet}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  
                 
                
                </div>
               
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default About;
