import axios from "axios";
import React, { useEffect, useState } from "react";
import { Button, Container, Form, Modal } from "react-bootstrap";
import {
  FaAngleRight,
  FaBriefcaseMedical,
  FaCarCrash,
  FaHotel,
  FaMusic,
} from "react-icons/fa";
import { MdOutlineDesignServices, MdReportProblem } from "react-icons/md";
import Carousel from "react-multi-carousel";

export const ServiceRSA = () => {

  const [slider, setslider] = useState([]);
  const [categorydata, setcategorydata] = useState([]);
  //Accident
  const [show4, setShow4] = useState(false);
  const [Modals, setModals] = useState({});
  const [subcategory, setsubcategory] = useState([]);
  const handleClose4 = () => setShow4(false);
  const handleShow4 = (item) => {
    setShow4(true);
    setModals(item);
  };


 

  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };
  const getcategory = async () => {
    let res = await axios.get(
      "https://bookingoda.com/api/admin/getRSACategory"
    );
    if ((res.status = 200)) {
      ////console.log(res);
      setcategorydata(res.data?.RSAcategory);
    }
  };
  const getsubcategory = async () => {
    let res = await axios.get(
      "https://bookingoda.com/api/admin/getRSASubcategory"
    );
    if ((res.status = 200)) {
      ////console.log(res);
      setsubcategory(res.data?.RSASubcategory);
    }
  };
  
  useEffect(() => {
    // getcountry();
    getcategory();
    getsubcategory();

  }, []);
  const getslider = async () => {
    let res = await axios.get("https://bookingoda.com/api/admin/getallRSASlider");
    if ((res.status = 200)) {
      ////console.log(res);
      setslider(res.data?.RSASlider);
    }
  };
  useEffect(() => {
    // getcountry();
    getslider();
  }, []);
  return (
    <div>
      <Carousel responsive={responsive} autoPlay={true}>
      {slider.map((data)=> (<><div class="item">
          <div class="box">
            <img
              src={`https://bookingoda.com/image/${data.RSASlider}`}
              alt="service-img"
              style={{
                width: "100%",
                height: "300px",
                imageRendering: "pixelated",
              }}
            />
          </div>
        </div>
        {/* <div class="item">
          <div class="box">
            <img
             src={`https://bookingoda.com/image/${data[1].RSASlider}`}
              alt="service-img"
              style={{
                width: "100%",
                height: "300px",
                imageRendering: "pixelated",
              }}
            />
          </div>
        </div>
        <div class="item">
          <div class="box">
            <img
              src={`https://bookingoda.com/image/${data[2].RSASlider}`}
              alt="service-img"
              style={{
                width: "100%",
                height: "300px",
                imageRendering: "pixelated",
              }}
            />
          </div>
        </div> */}
        </>)) }   
      </Carousel>

      <div style={{ backgroundColor: "#212121", padding: "10px" }}>
        <p className="text-light text-center fw-bold fs-5 letter-space">
          Service
        </p>
      </div>
      <div
        style={{
          backgroundImage: 'url("./img/login-banner.webp")',
          backgroundPosition: "center",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          height: "200px",
          width: "100%",
          padding: "25px",
        }}
      >
        <Container>
        

          <div className="row justify-content-between mb-5">
            {categorydata.map((data) => (
              <div
                className="col-lg-2"
                style={{
                  border: "1px solid #fff",
                  display: "flex",
                  flexDirection: "column",
                  height: "100px",
                  justifyContent: "center",
                  alignItems: "center",
                }}
                onClick={() => handleShow4(data)}
              >
              
                <img
                  src={`https://bookingoda.com/image/${data.categoryimage}`}
                  alt="service-img"
                  style={{
                    width: "40px",
                    height: "40px",
                    imageRendering: "pixelated",
                  }}
                />
                <p className="text-light">{data.RSAcategoryName}</p>
              </div>
            ))}
        
          </div>
          {/* <div className="d-flex justify-content-center">
            <button className="renew-btn ">Submit</button>
          </div> */}
        </Container>
      </div>

     

      {/* Accident  */}
      <Modal show={show4} onHide={handleClose4}>
        <Modal.Header
          closeButton
          style={{
            backgroundColor: "#698541",
            color: "white",
            display: "flex",
            alignItems: "center",
          }}
        >
          <Modal.Title>{Modals.RSAcategoryName}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* <div className=" text-center" style={{color:"#698541"}}>
            <h4>Accident</h4>
            <hr/>
          </div> */}
          <Form>
            {["checkbox"].map((type) => (
              <div key={`inline-${type}`} className="mb-3">
               {subcategory.filter((data)=>data.categoryName === Modals.RSAcategoryName).map((item) =>(<><Form.Check
                  inline
                  label={item.RSASubcategoryName}
                
                  name="group1"
                  type={type}
                  id={`inline-${type}-1`}
                /><br></br> </>))}
               
              </div>
            ))}
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary">Send Query</Button>
        </Modal.Footer>
      </Modal>

    </div>
  );
};
