import React from "react";
import "./property.css";



const GetStarted = () => {
 
  ////console.log(state);
  return (
    <>
    <div class="partner-header">
    <header class=" aCWKa87CwyJQfbPP_yg6">
      <nav class="yC5_EodFadTqIIY8_YDx">
        <div class="NrR_ZlI8bZYI3w06JKKb">
          <div class="H2AEzwF6W02wmXtVrp9U">
          <div class="mobile-join-logo join_logo">
      <span style={{ color: "white",fontSize:30,marginTop:"40px"}}>Booking-Oda.com</span>
      </div>
          </div>
        </div>
      </nav>
    </header>
  </div>
    <div className="f054b6e97f" id="automation_id_hero_container">
      <div className="ffb9c3d6a3 dbd3b41eae">
        <div className="aca0ade214 b23c3f480a c2931f4182 c27e5d305d db150fece4">
          <div className="b817090550 b736e9e3f4">
            <div className="c277eab6bf">
              <div className="c0d0e448a8" style={{paddingRight:"70px"}}>
                <h1 className="be54f7e491">
                  <div className="ab64ff081b">
                    <div className="f42f308029 bcb3b5354a">
                      List your{" "}
                      <div className="f560139817">
                        <div className="b9bac14a73">
                          <p className="b7cbbbdb07">apartment</p>
                          <p className="b7cbbbdb07">hotel</p>
                          <p className="b7cbbbdb07">holiday home</p>
                          <p className="b7cbbbdb07">guest house</p>
                          <p className="b7cbbbdb07">bed and breakfast</p>
                        </div>
                      </div>{" "}
                      on Booking-Oda.com
                    </div>
                    <div className="a1a525e0a9 d62ced7487">
                      List <div>Property</div> on Booking-Oda.com
                    </div>
                  </div>
                </h1>
                <h3 className="b14e7782c1">
                  Whether hosting is your side passion or full-time job,
                  register your holiday rental on Booking-Oda.com to reach
                  travellers worldwide
                </h3>
              </div>
              <div className="f33f32dcf3 ab307327fc">
                <div className="ef6753444c">
                  <div className="eb11e57202">
                    <div className="a9cf2ea607 fa1e8d74e8">
                      <div className="f2cf178bcd bui-spacer--medium d9dea29fc0 df11f444aa">
                        <div className="af8fbdf136 e6208ee469 d0caee4251">
                          Create new listing
                        </div>
                      </div>
                      <div className="bui-spacer--large" />
                      <ul className="c807d72881 bui-spacer--large e10711a42e">
                        <li className="a8b57ad3ff d50c412d31 c7a5a1307a">
                          <span
                            className="fcd9eec8fb ea94fb98d4 d24fc26e73 a229b4a525"
                            aria-hidden="true"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 128 128"
                            >
                              <path d="M56.33 100a4 4 0 0 1-2.82-1.16L20.68 66.12a4 4 0 1 1 5.64-5.65l29.57 29.46 45.42-60.33a4 4 0 1 1 6.38 4.8l-48.17 64a4 4 0 0 1-2.91 1.6z" />
                            </svg>
                          </span>
                          <div className="ff372597a4">
                            <div>
                             It's free to create a listing
                            </div>
                          </div>
                        </li>
                        <li className="a8b57ad3ff d50c412d31 c7a5a1307a">
                          <span
                            className="fcd9eec8fb ea94fb98d4 d24fc26e73 a229b4a525"
                            aria-hidden="true"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 128 128"
                            >
                              <path d="M56.33 100a4 4 0 0 1-2.82-1.16L20.68 66.12a4 4 0 1 1 5.64-5.65l29.57 29.46 45.42-60.33a4 4 0 1 1 6.38 4.8l-48.17 64a4 4 0 0 1-2.91 1.6z" />
                            </svg>
                          </span>
                          <div className="ff372597a4">
                            <div>
                              24/7 support by phone or email
                            </div>
                          </div>
                        </li>
                        <li className="a8b57ad3ff d50c412d31 c7a5a1307a">
                          <span
                            className="fcd9eec8fb ea94fb98d4 d24fc26e73 a229b4a525"
                            aria-hidden="true"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 128 128"
                            >
                              <path d="M56.33 100a4 4 0 0 1-2.82-1.16L20.68 66.12a4 4 0 1 1 5.64-5.65l29.57 29.46 45.42-60.33a4 4 0 1 1 6.38 4.8l-48.17 64a4 4 0 0 1-2.91 1.6z" />
                            </svg>
                          </span>
                          <div className="ff372597a4">
                            <div>
                              Set your own house rukes for guests
                            </div>
                          </div>
                        </li>
                        <li className="a8b57ad3ff d50c412d31 c7a5a1307a">
                          <span
                            className="fcd9eec8fb ea94fb98d4 d24fc26e73 a229b4a525"
                            aria-hidden="true"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 128 128"
                            >
                              <path d="M56.33 100a4 4 0 0 1-2.82-1.16L20.68 66.12a4 4 0 1 1 5.64-5.65l29.57 29.46 45.42-60.33a4 4 0 1 1 6.38 4.8l-48.17 64a4 4 0 0 1-2.91 1.6z" />
                            </svg>
                          </span>
                          <div className="ff372597a4">
                            <div>Sync your calender with other sites you list on</div>
                          </div>
                        </li>
                      </ul>
                      <hr className="b9bfeba2b4 b288f61df6 bui-spacer--large b135aa957f" />
                      <div className="f2cf178bcd bui-spacer--medium d9dea29fc0 df11f444aa">
                        <div className="af8fbdf136 e6208ee469 d0caee4251">
                          Create a partnet account to get started:
                        </div>
                      </div>
                      <a
                        href="/createaccount"
                        className="a83ed08757 c21c56c305 a4c1805887 f671049264 d2529514af c082d89982 c5a47e9549 js-signup-button automation_get_started_button"
                      >
                        <span className="e4adce92df">
                          <div className="aca0ade214 aaf30230d9 c2931f4182 e7d9f93f4d">
                            <div className="a53cbfa6de">Get started now</div>
                            <svg
                              width={19}
                              height={9}
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M15 0l-1.0025635 1L16.75 4H0v1h16.75l-2.7525635 3L15 9.01696777 19 4.5z"
                                fill="#FFF"
                                fillRule="nonzero"
                              />
                            </svg>
                          </div>
                        </span>
                      </a>
                      <div className="cb5c18b3f6" />
                      <div>
                        <hr className="b9bfeba2b4 b288f61df6 bui-spacer--large b135aa957f" />
                        <div className="f2cf178bcd a80c7ce9a0">
                          <div className="e1eebb6a1e e6208ee469 d0caee4251">
                            Already started a registration?
                          </div>
                        </div>
                        <a
                          href="/createaccount"
                          className="a83ed08757 c21c56c305 f38b6daa18 ab98298258 deab83296e bb803d8689 cbfa22e9ce a9df6f852d"
                        >
                          <span className="e4adce92df">
                            Continue your registration
                          </span>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    </>
  );
};

export default GetStarted;
