import { FaClock, FaLongArrowAltRight } from "react-icons/fa";
import "./vacation.css";

import { useEffect, useState } from "react";
import { GrMapLocation } from "react-icons/gr";
import Navbar from "../navbar/Navbar";
import Footer from "../footer/Footer";
import { Carousel } from "react-bootstrap";
import axios from "axios";
import modern from "../../assets/shield.png";
import parse from "html-react-parser";
import { Link, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBuilding,
  faCity,
  faHomeLg,
  faMagnifyingGlass,
  faMapMarkerAlt,
  faSearch,
  faStoreAlt,
} from "@fortawesome/free-solid-svg-icons";
import { GiDoorWatcher } from "react-icons/gi";
import { PiBathtubFill } from "react-icons/pi";
import { IoIosBed } from "react-icons/io";

export const Vacationhome = () => {
  const [places, setPlaces] = useState([]);

  const getvacation = async () => {
    let res = await axios.get(
      "https://bookingoda.com/api/admin/getvacation"
    );
    if (res.status === 200) {
      //////console.log(res.data);
      setPlaces(
        res.data.getvacation.filter((item) => item.blockstatus !== true)
      );
    }
  };
  useEffect(() => {
    getvacation();
  }, []);
  //   const parsedDescription = parse(data?.PlaceDescription)?.toString();
  // const truncatedDescription = parsedDescription.slice(0, 300);
  const sanitizeHTML = (html) => {
    const doc = new DOMParser().parseFromString(html, "text/html");
    return doc.body.textContent || "";
  };
  const [city, setcity] = useState("");
  const handleDestination = (e) => {
    setcity(e.target.value);
  };
  const history = useNavigate();
  const search = () => {
    history("/vacationlist", sessionStorage.setItem("city", JSON.stringify(city)))
   
  }
  return (
    <>
      <Navbar />

      <div  className="site-content text-break" tabIndex={-1}>
        <div
          data-elementor-type="wp-page"
          data-elementor-id={1179}
          className="elementor elementor-1179"
        >
          <div
            className="elementor-section elementor-top-section elementor-element elementor-element-7cad85f elementor-section-full_width hero-block hero-v6 bg-img-hero-bottom gradient-overlay-half-bg-charcoal-gray text-center z-index-2 elementor-section-height-default elementor-section-height-default"
            data-id="7cad85f"
            data-element_type="section"
            data-settings='{"background_background":"classic"}'
          >
            <div className="elementor-containerv elementor-column-gap-default containerv space-2  ">
              <div
                className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-0c99245"
                data-id="0c99245"
                data-element_type="column"
              >
                <div className="p-0 elementor-widget-wrap elementor-element-populated">
                  <div
                    className="elementor-section elementor-inner-section elementor-element elementor-element-e5b8170 elementor-section-full_width justify-content-md-center py-xl-10 pt-8 elementor-section-height-default elementor-section-height-default"
                    data-id="e5b8170"
                    data-element_type="section"
                  >
                    <div className="elementor-containerv elementor-column-gap-default pb-lg-11 mb-7">
                      <div
                        className="elementor-column elementor-col-100 elementor-inner-column elementor-element elementor-element-8c408d8"
                        data-id="8c408d8"
                        data-element_type="column"
                      >
                        <div className="p-0 elementor-widget-wrap elementor-element-populated">
                          <div
                            className="elementor-element elementor-element-d65c561 mb-0 elementor-widget elementor-widget-highlighted-heading"
                            data-id="d65c561"
                            data-element_type="widget"
                            data-widget_type="highlighted-heading.default"
                          >
                            <div className="elementor-widget-containerv">
                              <h1 className="mytravel-elementor-highlighted-heading__title font-size-60 font-size-xs-30 text-white font-weight-bold">
                                Find your next vacation homes
                              </h1>{" "}
                            </div>
                          </div>
                          <div
                            className="elementor-element elementor-element-4a30f96 mb-0 elementor-widget elementor-widget-highlighted-heading"
                            data-id="4a30f96"
                            data-element_type="widget"
                            data-widget_type="highlighted-heading.default"
                          >
                            <div className="elementor-widget-containerv">
                              <p className="mytravel-elementor-highlighted-heading__title font-size-20 font-weight-normal text-white">
                                Book experiences you’ll want to tell the world
                                about
                              </p>{" "}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                 
                    <div className="section-search"> 
          <div className="containercars">	  
            <div className="search-box-bannerv">
              {/* <form action={search}> */}
                <ul style={{display:"flex",flexDirection:"row"}}>
               
                <li className="column-group-main">
                    <div className="input-block">
                      <label>City</label>												
                      <div className="group-imgv">
                      <select
                    id="cities"
                    name="cities"
                    value={city}
                    onChange={handleDestination}
                  >
                    <option value="">Where are you going?</option>
                    {places.map((data) => (
                      <option value={data._id}>{data.city}</option>
                    ))}
                  </select>
                        {/* <input type="text" className="form-control" placeholder="Enter City" /> */}
                        <span><i className="feather-map-pin" /></span>
                      </div>
                    </div>
                  </li>
               
                  <li className="column-group-last">
                    <div className="input-block">
                      <div className="search-btn">
                        <button className="btn search-button" onClick={search} type="submit"> <FontAwesomeIcon icon={faMagnifyingGlass} /> {" "}Search</button>
                      </div>
                    </div>
                  </li>
                </ul>
              {/* </form>	 */}
            </div>
          </div>	
        </div>	
                </div>
              </div>
            </div>
          </div>
          <div
            className="elementor-section elementor-top-section elementor-element elementor-element-8f88c51 elementor-section-full_width category-block category-v1 bg-gray-2 elementor-section-height-default elementor-section-height-default"
            data-id="8f88c51"
            data-element_type="section"
          >
            <div className="elementor-containerv elementor-column-gap-default containerv space-2">
              <div
                className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-866e1d3"
                data-id="866e1d3"
                data-element_type="column"
              >
                <div className="p-0 elementor-widget-wrap elementor-element-populated">
                  <div
                    className="elementor-element elementor-element-54bdaed w-md-80 w-lg-50 text-center mx-md-auto mb-5 mt-lg-10 elementor-widget elementor-widget-highlighted-heading"
                    data-id="54bdaed"
                    data-element_type="widget"
                    data-widget_type="highlighted-heading.default"
                  >
                    <div className="elementor-widget-containerv">
                      <h2 className="mytravel-elementor-highlighted-heading__title section-title text-black font-size-30 font-weight-bold mb-0">
                        Find a Home Type
                      </h2>{" "}
                    </div>
                  </div>
                  <div
                    className="elementor-section elementor-inner-section elementor-element elementor-element-2c68282 elementor-section-full_width elementor-section-height-default elementor-section-height-default"
                    data-id="2c68282"
                    data-element_type="section"
                  >
                    <div className="elementor-containerv elementor-column-gap-default row mb-2 row-margin">
                      <div
                        className="elementor-column elementor-col-25 elementor-inner-column elementor-element elementor-element-5bfd6a3 col-md-6 col-xl-3"
                        data-id="5bfd6a3"
                        data-element_type="column"
                      >
                        <div className="p-0 rounded-xs mb-5 mb-xl-0 elementor-widget-wrap elementor-element-populated">
                          <div
                            className="elementor-element elementor-element-e92a033 elementor-position-top elementor-mobile-position-top elementor-widget elementor-widget-icon-box"
                            data-id="e92a033"
                            data-element_type="widget"
                            data-widget_type="icon-box.myt-icon-box"
                          >
                            <div className="elementor-widget-containerv">
                              <a className="d-block">
                                <div
                                  className="bg-img-hero gradient-overlay-half-bg-white transition-3d-hover shadow-hover-2 max-height-200 "
                                  style={{
                                    backgroundImage:
                                      "url(https://mytravel.madrasthemes.com/wp-content/uploads/2022/02/img1307200.jpeg)",
                                  }}
                                >
                                  <div className="elementor-icon-box-wrapper text-center py-5">
                                    <i className="elementor-icon-box-icon myt-icon-box-icon font-size-50 text-red-lighter-1 flaticon flaticon-house-1" />
                                    <FontAwesomeIcon
                                      icon={faHomeLg}
                                      className="elementor-icon-box-icon myt-icon-box-icon font-size-50 text-red-lighter-1 flaticon flaticon-house-1"
                                    />
                                    <h6 className="myt-icon-box-title elementor-icon-box-title font-size-17 font-weight-bold text-gray-3 mt-2 ">
                                      Modern Villa
                                    </h6>
                                    {/* <span className="myt-icon-box-desc elementor-icon-box-description font-size-14 font-weight-normal text-gray-1 ">
                                      07 Listings
                                    </span> */}
                                  </div>
                                </div>
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className="elementor-column elementor-col-25 elementor-inner-column elementor-element elementor-element-9017961 col-md-6 col-xl-3"
                        data-id={9017961}
                        data-element_type="column"
                      >
                        <div className="p-0 rounded-xs mb-5 mb-xl-0 elementor-widget-wrap elementor-element-populated">
                          <div
                            className="elementor-element elementor-element-6d19d0d elementor-position-top elementor-mobile-position-top elementor-widget elementor-widget-icon-box"
                            data-id="6d19d0d"
                            data-element_type="widget"
                            data-widget_type="icon-box.myt-icon-box"
                          >
                            <div className="elementor-widget-containerv">
                              <a className="d-block">
                                <div
                                  className="bg-img-hero gradient-overlay-half-bg-white transition-3d-hover shadow-hover-2 max-height-200 "
                                  style={{
                                    backgroundImage:
                                      "url(https://mytravel.madrasthemes.com/wp-content/uploads/2022/02/img1307200.jpeg)",
                                  }}
                                >
                                  <div className="elementor-icon-box-wrapper text-center py-5">
                                    <i className="elementor-icon-box-icon myt-icon-box-icon font-size-50 text-red-lighter-1 flaticon flaticon-house" />
                                    <FontAwesomeIcon
                                      icon={faStoreAlt}
                                      className="elementor-icon-box-icon myt-icon-box-icon font-size-50 text-red-lighter-1 flaticon flaticon-house"
                                    />
                                    <h6 className="myt-icon-box-title elementor-icon-box-title font-size-17 font-weight-bold text-gray-3 mt-2 ">
                                      Family House
                                    </h6>
                                    {/* <span className="myt-icon-box-desc elementor-icon-box-description font-size-14 font-weight-normal text-gray-1 ">
                                      58 Listings
                                    </span> */}
                                  </div>
                                </div>
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className="elementor-column elementor-col-25 elementor-inner-column elementor-element elementor-element-228c189 col-md-6 col-xl-3"
                        data-id="228c189"
                        data-element_type="column"
                      >
                        <div className="p-0 rounded-xs mb-5 mb-xl-0 elementor-widget-wrap elementor-element-populated">
                          <div
                            className="elementor-element elementor-element-f92b01b elementor-position-top elementor-mobile-position-top elementor-widget elementor-widget-icon-box"
                            data-id="f92b01b"
                            data-element_type="widget"
                            data-widget_type="icon-box.myt-icon-box"
                          >
                            <div className="elementor-widget-containerv">
                              <a className="d-block">
                                <div
                                  className="bg-img-hero gradient-overlay-half-bg-white transition-3d-hover shadow-hover-2 max-height-200 "
                                  style={{
                                    backgroundImage:
                                      "url(https://mytravel.madrasthemes.com/wp-content/uploads/2022/02/img1307200.jpeg)",
                                  }}
                                >
                                  <div className="elementor-icon-box-wrapper text-center py-5">
                                    <i className="elementor-icon-box-icon myt-icon-box-icon font-size-50 text-red-lighter-1 flaticon flaticon-modern-house" />

                                    <FontAwesomeIcon
                                      icon={faCity}
                                      className="elementor-icon-box-icon myt-icon-box-icon font-size-50 text-red-lighter-1 flaticon flaticon-modern-house"
                                    />
                                    <h6 className="myt-icon-box-title elementor-icon-box-title font-size-17 font-weight-bold text-gray-3 mt-2 ">
                                      Town House
                                    </h6>
                                    {/* <span className="myt-icon-box-desc elementor-icon-box-description font-size-14 font-weight-normal text-gray-1 ">
                                      07 Listings
                                    </span> */}
                                  </div>
                                </div>
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className="elementor-column elementor-col-25 elementor-inner-column elementor-element elementor-element-2d0abb2 col-md-6 col-xl-3"
                        data-id="2d0abb2"
                        data-element_type="column"
                      >
                        <div className="p-0 rounded-xs mb-5 mb-xl-0 elementor-widget-wrap elementor-element-populated">
                          <div
                            className="elementor-element elementor-element-f3d5d33 elementor-position-top elementor-mobile-position-top elementor-widget elementor-widget-icon-box"
                            data-id="f3d5d33"
                            data-element_type="widget"
                            data-widget_type="icon-box.myt-icon-box"
                          >
                            <div className="elementor-widget-containerv">
                              <a className="d-block">
                                <div
                                  className="bg-img-hero gradient-overlay-half-bg-white transition-3d-hover shadow-hover-2 max-height-200 "
                                  style={{
                                    backgroundImage:
                                      "url(https://mytravel.madrasthemes.com/wp-content/uploads/2022/02/img1307200.jpeg)",
                                  }}
                                >
                                  <div className="elementor-icon-box-wrapper text-center py-5">
                                    <i className="elementor-icon-box-icon myt-icon-box-icon font-size-50 text-red-lighter-1 flaticon flaticon-apartment" />
                                    <FontAwesomeIcon
                                      icon={faBuilding}
                                      className="elementor-icon-box-icon myt-icon-box-icon font-size-50 text-red-lighter-1 flaticon flaticon-apartment"
                                    />
                                    <h6 className="myt-icon-box-title elementor-icon-box-title font-size-17 font-weight-bold text-gray-3 mt-2 ">
                                      Apartment
                                    </h6>
                                    {/* <span className="myt-icon-box-desc elementor-icon-box-description font-size-14 font-weight-normal text-gray-1 ">
                                      07 Listings
                                    </span> */}
                                  </div>
                                </div>
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="elementor-section elementor-top-section elementor-element elementor-element-2b2ef7a elementor-section-full_width product-cardv-carousel-block product-cardv-carousel-v3 elementor-section-height-default elementor-section-height-default"
            data-id="2b2ef7a"
            data-element_type="section"
          >
            <div className="elementor-containerv elementor-column-gap-default containerv space-1">
              <div
                className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-eb85b3c"
                data-id="eb85b3c"
                data-element_type="column"
              >
                <div className="p-0 elementor-widget-wrap elementor-element-populated">
                  <div
                    className="elementor-element elementor-element-dc7a83d mb-5 pb-1 w-md-80 w-lg-50 text-center mx-md-auto mt-3 elementor-widget elementor-widget-highlighted-heading"
                    data-id="dc7a83d"
                    data-element_type="widget"
                    data-widget_type="highlighted-heading.default"
                  >
                    <div className="elementor-widget-containerv">
                      <h2 className="mytravel-elementor-highlighted-heading__title section-title text-black font-size-30 font-weight-bold mb-0">
                        Trending Homes
                      </h2>{" "}
                    </div>
                  </div>
                  <div
                    className="elementor-element elementor-element-0b240f7 mt-lg-n1 mb-4 elementor-widget elementor-widget-myt-product-carousel"
                    data-id="0b240f7"
                    data-element_type="widget"
                    data-settings='{"autoplay":"no","center_slides":"no","infinite":"no","fade":"no"}'
                    data-widget_type="myt-product-carousel.rental"
                  >
                    <div className="elementor-widget-containerv">
                      <div className="myt-carousel rental-slide-wrap">
                        <div
                          className="js-slick-carousel u-slick u-slick--equal-height u-slick--gutters-3 mytravel-65d8783e2b581 slick-initialized slick-slider slick-dotted"
                          data-slides-show={4}
                          data-ride="slick"
                          data-responsive='[{"breakpoint":1025,"settings":{"slidesToShow":3}},{"breakpoint":992,"settings":{"slidesToShow":2}},{"breakpoint":768,"settings":{"slidesToShow":1}}]'
                          data-center-mode={0}
                          data-autoplay
                          data-autoplay-speed={3000}
                          data-pagi-classes="text-center u-slick__pagination mt-4 d-lg-none"
                          data-infinite
                          data-fade
                          data-rows={1}
                          data-arrows-classes="elementor-slick-arrow d-none d-lg-inline-block u-slick__arrow-classic u-slick__arrow-centered--y rounded-circle  u-slick__arrow-classic--v1 shadow-5"
                          data-arrow-left-classes="fas fa-chevron-left u-slick__arrow-classic-inner u-slick__arrow-classic-inner--left"
                          data-arrow-right-classes="fas fa-chevron-right u-slick__arrow-classic-inner u-slick__arrow-classic-inner--right"
                        >
                         
                          <div className="slick-list draggable">
                            <div
                              className="slick-track"
                              style={{
                                opacity: 1,
                                width: "1500px",
                                transform: "translate3d(0px, 0px, 0px)",
                              }}
                            >
                             {places.slice(0,4).map((data)=>( 
              <div
                                className="js-slide slick-slide slick-current slick-active"
                                data-slick-index={0}
                                aria-hidden="false"
                                tabIndex={0}
                                role="tabpanel"
                                id="slick-slide00"
                                aria-describedby="slick-slide-control00"
                                style={{ maxWidth: "300px", height: "auto" }}
                              >
                                <div className="add-to-wishlist-after_add_to_cart product type-product post-218 status-publish first instock product_cat-coupes product_cat-rental has-post-thumbnail featured shipping-taxable purchasable product-type-simple mb-3 mb-md-4 pb-1">
                                  <div className="cardv transition-3d-hover shadow-hover-2 h-100">
                                    <div className="cardv-header position-relative p-0">
                                    <Link to={`/Vacationdetails/${data._id}`} >
                                        <img
                                          fetchpriority="high"
                                          decoding="async"
                                          width={300}
                                          height={225}
                                          src={`https://bookingoda.com/image/${data.GalleryImage[0]?.image}`}
                                          className="min-height-230 bg-img-hero cardv-img-top"
                                          alt=""
                                        />{" "}
                                      </Link>

                                      <div className="price-wrapper position-absolute bottom-0 left-0 right-0">
                                        <div className="px-3 pb-2">
                                          <a
                                           
                                            className="stretched-link cardv-title text-dark"
                                            tabIndex={0}
                                          >
                                            <h2 className="woocommerce-loop-product__title font-size-17 text-white font-weight-bold">
                                              {data.stayname}
                                            </h2>
                                          </a>{" "}
                                          <span className="price">
                                            <span className="woocommerce-Price-amount font-weight-bold font-size-19 mb-2 d-inline-block text-white amount">
                                              <bdi>
                                                <span className="woocommerce-Price-currencySymbol">
                                                  INR
                                                </span>
                                                {data.staycost}
                                              </bdi>
                                            </span>
                                            <span className="wceb-price-format">
                                              {" "}
                                              / night
                                            </span>{" "}
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                 
                                    <div
                                      className="cardv-body
					border-bottom"
                                    >
                                     <Link to={`/Vacationdetails/${data._id}`} >
                                        <div className="font-size-14 text-gray-1">
                                       
                                          <FontAwesomeIcon
                                            icon={faMapMarkerAlt}
                                            style={{color:"#69a3cf"}}
                                            className="icon flaticon-pin-1 mr-2 font-size-24"
                                          />
                                          {data?.street}{data.city},{data.state},{data.country}{" "}
                                        </div>
                                      </Link>
                                    </div>
                                    <div className="">
                                      <div className="row">
                                        <div className="col-6">
                                          {" "}
                                          <div className="media text-gray-1 align-items-center">
                                            <small className="mr-2">
                                              
                                              <i className="fas fa-chalkboard font-size-24 small"  style={{color:"#69a3cf"}}/>
                                            </small>
                                            <div className="media-body font-size-1">
                                              {data.Measurement} sq ft
                                            </div>
                                          </div>
                                        </div>
                                        <div className="col-6">
                                          {" "}
                                          <div className="media mb-2 text-gray-1 align-items-center">
                                            <small className="mr-2">
                                             <GiDoorWatcher className="fas fa-chalkboard font-size-24 small"  style={{color:"#69a3cf"}}/>
                                            </small>
                                            <div className="media-body font-size-1">
                                              {data.Rooms} rooms
                                            </div>
                                          </div>
                                        </div>
                                        <div className="col-6">
                                          {" "}
                                          <div className="media mb-2 text-gray-1 align-items-center">
                                            <small className="mr-2">
                                              <PiBathtubFill className="fas fa-chalkboard font-size-24 small"  style={{color:"#69a3cf"}}/>
                                            </small>
                                            <div className="media-body font-size-1">
                                              {data.Bathrooms} bathrooms
                                            </div>
                                          </div>
                                        </div>
                                        <div className="col-6">
                                          {" "}
                                          <div className="media mb-2 text-gray-1 align-items-center">
                                            <small className="mr-2">
                                              <IoIosBed  className="flaticon-bed-1 font-size-24 small"  style={{color:"#69a3cf"}}/>
                                            </small>
                                            <div className="media-body font-size-1">
                                              {data.Beds} beds
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>))}
                            
                            </div>
                          </div>
                         
                    
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};
