import React, { useEffect, useState } from "react";
import "./property.css";
import Apartment from "../../assets/apartments.png";
import Apartments from "../../assets/buildings.png";

import Homestay from "../../assets/homestay.png";
import Hotel from "../../assets/hotel.png";
import VendorHeader from "./Vendorheader";
import { useLocation, useNavigate } from "react-router-dom";

const Alternativesetup = () => {
  const { state } = useLocation();
  ////console.log(state);
  const [RoombedCount, setRoomBedCount] = useState(1);
  const [Roombeds, setRoomBeds] = useState([]);
  const handleKeyDown = (e) => {
    // Prevent the input of the minus sign
    if (e.key === '-' || e.key === 'e' || e.key === 'E') {
      e.preventDefault();
    }
  };
  const addRoomBed = () => {
    setRoomBedCount(RoombedCount + 1);
    setRoomBeds([...Roombeds, { name: `RoomBed ${RoombedCount + 1}`, quantity: 1 }]);
  };

  const removeRoomBed = (index) => {
    const updatedRoomBeds = Roombeds.filter((_, i) => i !== index);
    setRoomBeds(updatedRoomBeds);
  };
  ////console.log(Roombeds, "sds");
  const updateRoomBedQuantity = (index, newQuantity) => {
    const updatedRoomBeds = [...Roombeds];
    updatedRoomBeds[index].quantity = newQuantity;
    setRoomBeds(updatedRoomBeds);
  };

  const updateRoomBedName = (index, newName) => {
    const updatedRoomBeds = [...Roombeds];
    updatedRoomBeds[index].name = newName;
    setRoomBeds(updatedRoomBeds);
  };
  const [LRoombedCount, setLRoomBedCount] = useState(1);
  const [LRoombeds, setLRoomBeds] = useState([]);

  const addLRoomBed = () => {
    setLRoomBedCount(LRoombedCount + 1);
    setLRoomBeds([...LRoombeds, { name: `LRoomBed ${LRoombedCount + 1}`, quantity: 1 }]);
  };

  const removeLRoomBed = (index) => {
    const updatedLRoomBeds = LRoombeds.filter((_, i) => i !== index);
    setLRoomBeds(updatedLRoomBeds);
  };
  ////console.log(LRoombeds, "sds");
  const updateLRoomBedQuantity = (index, newQuantity) => {
    const updatedLRoomBeds = [...LRoombeds];
    updatedLRoomBeds[index].quantity = newQuantity;
    setLRoomBeds(updatedLRoomBeds);
  };

  const updateLRoomBedName = (index, newName) => {
    const updatedLRoomBeds = [...LRoombeds];
    updatedLRoomBeds[index].name = newName;
    setLRoomBeds(updatedLRoomBeds);
  };
  const history = useNavigate();
  const [Singlebed, setSinglebed] = useState(0);
  const [Open, setOpen] = useState(false);
  const [Open1, setOpen1] = useState(false);

  const [Doublebed, setDoublebed] = useState(0);
  const [Largebed, setLargebed] = useState(0);

  const [XLargebed, setXLargebed] = useState(0);
  const [Sofabed, setSofabed] = useState(0);
  const [Stay, setStay] = useState(0);
  const [Bathroom, setBathroom] = useState(0);
  const [ApartmentSize, setApartmentSize] = useState(0);
  const [ApartmentType, setApartmentType] = useState("");

  const increaseRange = () => {
    setSinglebed(Singlebed + 1);
  };
  const decrement = () => {
    setSinglebed(Singlebed - 1);
  };
  const increaseRange1 = () => {
    setDoublebed(Doublebed + 1);
  };
  const decrement1 = () => {
    setDoublebed(Doublebed - 1);
  };
  const increaseRange2 = () => {
    setLargebed(Largebed + 1);
  };
  const decrement2 = () => {
    setLargebed(Largebed - 1);
  };
  const increaseRange3 = () => {
    setXLargebed(XLargebed + 1);
  };
  const decrement3 = () => {
    setXLargebed(XLargebed - 1);
  };
  const increaseRange4 = () => {
    setSofabed(Sofabed + 1);
  };
  const decrement4 = () => {
    setSofabed(Sofabed - 1);
  };

  const increaseRange5 = () => {
    setStay(Stay + 1);
  };
  const decrement5 = () => {
    setStay(Stay - 1);
  };
  const increaseRange6 = () => {
    setBathroom(Bathroom + 1);
  };
  const decrement6 = () => {
    setBathroom(Bathroom - 1);
  };
  const HandleClick1 = () => {
    setOpen(!Open);
  };
  const HandleClick2 = () => {
    setOpen1(!Open1);
  };
  const HandleClick = () => {
    if (Stay === 0) {
      alert("Please enter number of guest can stay at your property");
    } else if(Bathroom === 0){
      alert("Please enter number of bathrooms at your property");
 

    

    }else{
    history("/alternativeuse",sessionStorage.setItem("Singlebed", JSON.stringify(Singlebed)),sessionStorage.setItem("Doublebed", JSON.stringify(Doublebed)),sessionStorage.setItem("Largebed", JSON.stringify(Largebed)),sessionStorage.setItem("XLargebed", JSON.stringify(XLargebed)),sessionStorage.setItem("Sofabed", JSON.stringify(Sofabed)),sessionStorage.setItem("Stay", JSON.stringify(Stay)),sessionStorage.setItem("Bathroom", JSON.stringify(Bathroom)), sessionStorage.setItem("AlternativeSize", JSON.stringify(ApartmentSize)),sessionStorage.setItem("AlternativeMeasurement", JSON.stringify(ApartmentType)),sessionStorage.setItem("Roombeds", JSON.stringify(Roombeds)),sessionStorage.setItem("LRoombeds", JSON.stringify(LRoombeds)));
  }}
  useEffect(() => {
    // Retrieve stored values from sessionStorage
    const storedSinglebed = sessionStorage.getItem("Singlebed");
    const storedDoublebed = sessionStorage.getItem("Doublebed");
    const storedLargebed = sessionStorage.getItem("Largebed");
    const storedXLargebed = sessionStorage.getItem("XLargebed");
    const storedSofabed = sessionStorage.getItem("Sofabed");
    const storedStay = sessionStorage.getItem("Stay");
    const storedBathroom = sessionStorage.getItem("Bathroom");
    const storedApartmentSize = sessionStorage.getItem("AlternativeSize");
    const storedApartmentMeasurement = sessionStorage.getItem(
      "AlternativeMeasurement"
    );
    const storedRoombeds = sessionStorage.getItem("Roombeds");
    const storedLRoombeds = sessionStorage.getItem("LRoombeds");

    // Parse JSON strings if values are present
    const parsedSinglebed = storedSinglebed
      ? JSON.parse(storedSinglebed)
      : Singlebed;
    const parsedDoublebed = storedDoublebed
      ? JSON.parse(storedDoublebed)
      : Doublebed;
    const parsedLargebed = storedLargebed
      ? JSON.parse(storedLargebed)
      : Largebed;
    const parsedXLargebed = storedXLargebed
      ? JSON.parse(storedXLargebed)
      : XLargebed;
    const parsedSofabed = storedSofabed ? JSON.parse(storedSofabed) : Sofabed;
    const parsedStay = storedStay ? JSON.parse(storedStay) : Stay;
    const parsedBathroom = storedBathroom
      ? JSON.parse(storedBathroom)
      : Bathroom;
    const parsedApartmentSize = storedApartmentSize
      ? JSON.parse(storedApartmentSize)
      : ApartmentSize;
    const parsedApartmentMeasurement = storedApartmentMeasurement
      ? JSON.parse(storedApartmentMeasurement)
      : ApartmentType;
    const parsedRoombeds = storedRoombeds
      ? JSON.parse(storedRoombeds)
      : Roombeds;
    const parsedLRoombeds = storedLRoombeds
      ? JSON.parse(storedLRoombeds)
      : LRoombeds;

    setSinglebed(parsedSinglebed);
    setDoublebed(parsedDoublebed);
    setLargebed(parsedLargebed);
    setXLargebed(parsedXLargebed);
    setSofabed(parsedSofabed);
    setStay(parsedStay);
    setBathroom(parsedBathroom);
    setApartmentSize(parsedApartmentSize);
    setApartmentType(parsedApartmentMeasurement);
    setRoomBeds(parsedRoombeds);
    setLRoomBeds(parsedLRoombeds);
  }, []);
  return (
    <div
    data-capla-component="b-registration-joinapp15min-fe/"
    data-capla-namespace="b-registration-joinapp15min-feaYGUODfA"
  >
    <div id="app-container">
      <div class="error-popup-container"></div>
      <div class="application">
        <VendorHeader />
        <div class="error-popup-container"></div>
        <div className="page-container">
          <div className="screens screens-animation-direction-forward">
            <div
              className="height-100 screen-change-enter-done"
              id="automation_id_screen_container_Bedroom"
            >
              <div className="height-100 screens-animation-direction-backward">
                <div className="ms-container" id="automation_id_bedroom_page">
                  <div className="form-container">
                    <div className="f2cf178bcd screen-main-header d991e1216a">
                      <div className="f6431b446c e6208ee469 d0caee4251 screen-main-header__title">
                        Property details
                      </div>
                    </div>
                    <div
                      data-testid="SupMupEpContainer"
                      className="ffb9c3d6a3 wide-container"
                    >
                      <div className="aca0ade214 ebac6e22e9 f66f916626 c2931f4182 c27e5d305d db150fece4">
                        <div className="b817090550 b736e9e3f4">
                          <div className="bcdcb105b3">
                            Where can people sleep?
                          </div>
                          <div className="room-rows-container">
                            <div className="aca0ade214 ebac6e22e9 f66f916626 c2931f4182 c27e5d305d db150fece4 count-input add-room room-row">
                              <div className="b817090550 d4e36dbd77">
                                <button
                                  type="button"
                                  id="automation_id_bedroom_edit_1"
                                  data-testid="bedroomitem_edit_0"
                                  className="edit-room bedroom-setup--list"
                                  onClick={HandleClick1}
                                >
                                  <span className="room-row-text">
                                    Bedroom
                                  </span>
                                  <small>
                                    {Singlebed === 0
                                      ? ""
                                      : `${Singlebed} Single bed`}{" "}
                                    {Doublebed === 0
                                      ? ""
                                      : `${Doublebed} Double bed`}{" "}
                                    {Largebed === 0
                                      ? ""
                                      : `${Largebed} Large bed`}{" "}
                                    {XLargebed === 0
                                      ? ""
                                      : `${XLargebed} Xtra-Large bed`}{"  "}
                                      {Roombeds.length < 0 ? "" : Roombeds.map((data, index) => (
                            <>
                             {data.quantity} {data.name}
                             </>
                          ))}
                                  </small>
                                </button>
                              </div>
                              {Open ? (
                                <div className="ffb9c3d6a3">
                                  <div className="aca0ade214 ebac6e22e9 f66f916626 c2931f4182 c27e5d305d db150fece4 spacing--6x">
                                    <div className="b817090550 b736e9e3f4">
                                      <div className="bcdcb105b3">
                                        Which beds are available in this room?
                                      </div>
                                    </div>
                                  </div>
                                  <div className="aca0ade214 ebac6e22e9 f66f916626 c2931f4182 c27e5d305d db150fece4 spacing--6x addRoom">
                                    <div className="roomDesc b817090550 e0ad3ea0c7">
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 96 96"
                                      >
                                        <path d="M78.25 48h-60.5C16.1 48 15 49.1 15 50.77v30.46C15 82.9 16.1 84 17.75 84s2.75-1.1 2.75-2.77V70.15h55v11.08c0 1.66 1.1 2.77 2.75 2.77S81 82.9 81 81.23V50.77C81 49.1 79.9 48 78.25 48zm-22-27.7h-27.5v-5.53c0-1.66-1.1-2.77-2.75-2.77s-2.75 1.1-2.75 2.77v27.7h33V20.3z" />
                                        <path d="M72.75 23.08v-8.3c0-1.67-1.1-2.78-2.75-2.78s-2.75 1.1-2.75 2.77v5.54h-27.5v22.16h33V23.08z" />
                                      </svg>
                                      <div className="f2cf178bcd">
                                        <div className="a3332d346a e6208ee469 d0caee4251">
                                          Single bed
                                        </div>
                                        <div className="abf093bdfe f45d8e4c32 df64fda51b">
                                          90 - 130 cm wide
                                        </div>
                                      </div>
                                    </div>
                                    <div className="b817090550 a7cf1a6b1d">
                                      <div
                                        id="automation_id_add_bedroom_single_bed_input"
                                        data-testid="automation_id_add_bedroom_single_bed_input"
                                        className="a7a72174b8"
                                      >
                                        <div className="bfb38641b0">
                                          <button
                                            tabIndex={-1}
                                  disabled={Singlebed < 1}

                                            aria-hidden="true"
                                            type="button"
                                            className="a83ed08757 c21c56c305 f38b6daa18 d691166b09 ab98298258 deab83296e bb803d8689 e91c91fa93"
                                            onClick={decrement}
                                          >
                                            <span className="eedba9e88a">
                                              <span
                                                className="fcd9eec8fb bf9a32efa5"
                                                aria-hidden="true"
                                              >
                                                <svg
                                                  xmlns="http://www.w3.org/2000/svg"
                                                  viewBox="0 0 24 24"
                                                >
                                                  <path d="M20.25 12.75H3.75a.75.75 0 0 1 0-1.5h16.5a.75.75 0 0 1 0 1.5z" />
                                                </svg>
                                              </span>
                                            </span>
                                          </button>
                                          <span
                                            className="d723d73d5f"
                                            aria-hidden="true"
                                          >
                                            {Singlebed}
                                          </span>
                                          <button
                                            tabIndex={-1}
                                            aria-hidden="true"
                                            type="button"
                                            className="a83ed08757 c21c56c305 f38b6daa18 d691166b09 ab98298258 deab83296e bb803d8689 f4d78af12a"
                                            onClick={increaseRange}
                                          >
                                            <span className="eedba9e88a">
                                              <span
                                                className="fcd9eec8fb bf9a32efa5"
                                                aria-hidden="true"
                                              >
                                                <svg
                                                  xmlns="http://www.w3.org/2000/svg"
                                                  viewBox="0 0 24 24"
                                                >
                                                  <path d="M20.25 11.25h-7.5v-7.5a.75.75 0 0 0-1.5 0v7.5h-7.5a.75.75 0 0 0 0 1.5h7.5v7.5a.75.75 0 0 0 1.5 0v-7.5h7.5a.75.75 0 0 0 0-1.5z" />
                                                </svg>
                                              </span>
                                            </span>
                                          </button>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="aca0ade214 ebac6e22e9 f66f916626 c2931f4182 c27e5d305d db150fece4 spacing--6x addRoom">
                                    <div className="roomDesc b817090550 e0ad3ea0c7">
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 96 96"
                                      >
                                        <path d="M89.25 48H6.75C5.1 48 4 49.1 4 50.77v30.46C4 82.9 5.1 84 6.75 84s2.75-1.1 2.75-2.77V70.15h77v11.08c0 1.66 1.1 2.77 2.75 2.77S92 82.9 92 81.23V50.77C92 49.1 90.9 48 89.25 48zm-44-27.7h-27.5v-5.53c0-1.66-1.1-2.77-2.75-2.77s-2.75 1.1-2.75 2.77v27.7h33V20.3zm38.5 2.78v-8.3c0-1.67-1.1-2.78-2.75-2.78s-2.75 1.1-2.75 2.77v5.54h-27.5v22.16h33V23.08z" />
                                      </svg>
                                      <div className="f2cf178bcd">
                                        <div className="a3332d346a e6208ee469 d0caee4251">
                                          Double bed
                                        </div>
                                        <div className="abf093bdfe f45d8e4c32 df64fda51b">
                                          131 - 150 cm wide
                                        </div>
                                      </div>
                                    </div>
                                    <div className="b817090550 a7cf1a6b1d">
                                      <div
                                        id="automation_id_add_bedroom_double_bed_input"
                                        data-testid="automation_id_add_bedroom_double_bed_input"
                                        className="a7a72174b8"
                                      >
                                        <div className="bfb38641b0">
                                          <button
                                            tabIndex={-1}
                                  disabled={Doublebed < 1}

                                            aria-hidden="true"
                                            type="button"
                                            className="a83ed08757 c21c56c305 f38b6daa18 d691166b09 ab98298258 deab83296e bb803d8689 e91c91fa93"
                                            onClick={decrement1}
                                          >
                                            <span className="eedba9e88a">
                                              <span
                                                className="fcd9eec8fb bf9a32efa5"
                                                aria-hidden="true"
                                              >
                                                <svg
                                                  xmlns="http://www.w3.org/2000/svg"
                                                  viewBox="0 0 24 24"
                                                >
                                                  <path d="M20.25 12.75H3.75a.75.75 0 0 1 0-1.5h16.5a.75.75 0 0 1 0 1.5z" />
                                                </svg>
                                              </span>
                                            </span>
                                          </button>
                                          <span
                                            className="d723d73d5f"
                                            aria-hidden="true"
                                          >
                                            {Doublebed}
                                          </span>
                                          <button
                                            tabIndex={-1}
                                            aria-hidden="true"
                                            type="button"
                                            className="a83ed08757 c21c56c305 f38b6daa18 d691166b09 ab98298258 deab83296e bb803d8689 f4d78af12a"
                                            onClick={increaseRange1}
                                          >
                                            <span className="eedba9e88a">
                                              <span
                                                className="fcd9eec8fb bf9a32efa5"
                                                aria-hidden="true"
                                              >
                                                <svg
                                                  xmlns="http://www.w3.org/2000/svg"
                                                  viewBox="0 0 24 24"
                                                >
                                                  <path d="M20.25 11.25h-7.5v-7.5a.75.75 0 0 0-1.5 0v7.5h-7.5a.75.75 0 0 0 0 1.5h7.5v7.5a.75.75 0 0 0 1.5 0v-7.5h7.5a.75.75 0 0 0 0-1.5z" />
                                                </svg>
                                              </span>
                                            </span>
                                          </button>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="aca0ade214 ebac6e22e9 f66f916626 c2931f4182 c27e5d305d db150fece4 spacing--6x addRoom">
                                    <div className="roomDesc b817090550 e0ad3ea0c7">
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 96 96"
                                      >
                                        <path d="M89.25 48H6.75C5.1 48 4 49.1 4 50.77v30.46C4 82.9 5.1 84 6.75 84s2.75-1.1 2.75-2.77V70.15h77v11.08c0 1.66 1.1 2.77 2.75 2.77S92 82.9 92 81.23V50.77C92 49.1 90.9 48 89.25 48zm-44-27.7h-27.5v-5.53c0-1.66-1.1-2.77-2.75-2.77s-2.75 1.1-2.75 2.77v27.7h33V20.3zm38.5 2.78v-8.3c0-1.67-1.1-2.78-2.75-2.78s-2.75 1.1-2.75 2.77v5.54h-27.5v22.16h33V23.08z" />
                                      </svg>
                                      <div className="f2cf178bcd">
                                        <div className="a3332d346a e6208ee469 d0caee4251">
                                          Large bed (King size)
                                        </div>
                                        <div className="abf093bdfe f45d8e4c32 df64fda51b">
                                          151 - 180 cm wide
                                        </div>
                                      </div>
                                    </div>
                                    <div className="b817090550 a7cf1a6b1d">
                                      <div
                                        id="automation_id_add_bedroom_large_double_bed_input"
                                        data-testid="automation_id_add_bedroom_large_double_bed_input"
                                        className="a7a72174b8"
                                      >
                                        <div className="bfb38641b0">
                                          <button
                                            tabIndex={-1}
                                  disabled={Largebed < 1}

                                            aria-hidden="true"
                                            type="button"
                                            className="a83ed08757 c21c56c305 f38b6daa18 d691166b09 ab98298258 deab83296e bb803d8689 e91c91fa93"
                                            onClick={decrement2}
                                          >
                                            <span className="eedba9e88a">
                                              <span
                                                className="fcd9eec8fb bf9a32efa5"
                                                aria-hidden="true"
                                              >
                                                <svg
                                                  xmlns="http://www.w3.org/2000/svg"
                                                  viewBox="0 0 24 24"
                                                >
                                                  <path d="M20.25 12.75H3.75a.75.75 0 0 1 0-1.5h16.5a.75.75 0 0 1 0 1.5z" />
                                                </svg>
                                              </span>
                                            </span>
                                          </button>
                                          <span
                                            className="d723d73d5f"
                                            aria-hidden="true"
                                          >
                                            {Largebed}
                                          </span>
                                          <button
                                            tabIndex={-1}
                                            aria-hidden="true"
                                            type="button"
                                            className="a83ed08757 c21c56c305 f38b6daa18 d691166b09 ab98298258 deab83296e bb803d8689 f4d78af12a"
                                            onClick={increaseRange2}
                                          >
                                            <span className="eedba9e88a">
                                              <span
                                                className="fcd9eec8fb bf9a32efa5"
                                                aria-hidden="true"
                                              >
                                                <svg
                                                  xmlns="http://www.w3.org/2000/svg"
                                                  viewBox="0 0 24 24"
                                                >
                                                  <path d="M20.25 11.25h-7.5v-7.5a.75.75 0 0 0-1.5 0v7.5h-7.5a.75.75 0 0 0 0 1.5h7.5v7.5a.75.75 0 0 0 1.5 0v-7.5h7.5a.75.75 0 0 0 0-1.5z" />
                                                </svg>
                                              </span>
                                            </span>
                                          </button>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="aca0ade214 ebac6e22e9 f66f916626 c2931f4182 c27e5d305d db150fece4 spacing--6x addRoom">
                                    <div className="roomDesc b817090550 e0ad3ea0c7">
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 96 96"
                                      >
                                        <path d="M89.25 48H6.75C5.1 48 4 49.1 4 50.77v30.46C4 82.9 5.1 84 6.75 84s2.75-1.1 2.75-2.77V70.15h77v11.08c0 1.66 1.1 2.77 2.75 2.77S92 82.9 92 81.23V50.77C92 49.1 90.9 48 89.25 48zm-44-27.7h-27.5v-5.53c0-1.66-1.1-2.77-2.75-2.77s-2.75 1.1-2.75 2.77v27.7h33V20.3zm38.5 2.78v-8.3c0-1.67-1.1-2.78-2.75-2.78s-2.75 1.1-2.75 2.77v5.54h-27.5v22.16h33V23.08z" />
                                      </svg>
                                      <div className="f2cf178bcd">
                                        <div className="a3332d346a e6208ee469 d0caee4251">
                                          Extra-large double bed (Super-king
                                          size)
                                        </div>
                                        <div className="abf093bdfe f45d8e4c32 df64fda51b">
                                          181 - 210 cm wide
                                        </div>
                                      </div>
                                    </div>
                                    <div className="b817090550 a7cf1a6b1d">
                                      <div
                                        id="automation_id_add_bedroom_extra_large_double_bed_input"
                                        data-testid="automation_id_add_bedroom_extra_large_double_bed_input"
                                        className="a7a72174b8"
                                      >
                                        <div className="bfb38641b0">
                                          <button
                                            tabIndex={-1}
                                  disabled={XLargebed < 1}

                                            aria-hidden="true"
                                            type="button"
                                            className="a83ed08757 c21c56c305 f38b6daa18 d691166b09 ab98298258 deab83296e bb803d8689 e91c91fa93"
                                            onClick={decrement3}
                                          >
                                            <span className="eedba9e88a">
                                              <span
                                                className="fcd9eec8fb bf9a32efa5"
                                                aria-hidden="true"
                                              >
                                                <svg
                                                  xmlns="http://www.w3.org/2000/svg"
                                                  viewBox="0 0 24 24"
                                                >
                                                  <path d="M20.25 12.75H3.75a.75.75 0 0 1 0-1.5h16.5a.75.75 0 0 1 0 1.5z" />
                                                </svg>
                                              </span>
                                            </span>
                                          </button>
                                          <span
                                            className="d723d73d5f"
                                            aria-hidden="true"
                                          >
                                            {XLargebed}
                                          </span>
                                          <button
                                            tabIndex={-1}
                                            aria-hidden="true"
                                            type="button"
                                            className="a83ed08757 c21c56c305 f38b6daa18 d691166b09 ab98298258 deab83296e bb803d8689 f4d78af12a"
                                            onClick={increaseRange3}
                                          >
                                            <span className="eedba9e88a">
                                              <span
                                                className="fcd9eec8fb bf9a32efa5"
                                                aria-hidden="true"
                                              >
                                                <svg
                                                  xmlns="http://www.w3.org/2000/svg"
                                                  viewBox="0 0 24 24"
                                                >
                                                  <path d="M20.25 11.25h-7.5v-7.5a.75.75 0 0 0-1.5 0v7.5h-7.5a.75.75 0 0 0 0 1.5h7.5v7.5a.75.75 0 0 0 1.5 0v-7.5h7.5a.75.75 0 0 0 0-1.5z" />
                                                </svg>
                                              </span>
                                            </span>
                                          </button>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  {Roombeds.map((bed, index) => (
                        <div key={index} className="aca0ade214 ebac6e22e9 f66f916626 c2931f4182 c27e5d305d db150fece4 spacing--6x addRoom">
                          <div className="roomDesc b817090550 e0ad3ea0c7">
                            
                            <div className="f2cf178bcd">
                              <div className="a3332d346a e6208ee469 d0caee4251 bed-type--not-highlighted">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 96 96"
                                >
                                  <path d="M89.25 48H6.75C5.1 48 4 49.1 4 50.77v30.46C4 82.9 5.1 84 6.75 84s2.75-1.1 2.75-2.77V70.15h77v11.08c0 1.66 1.1 2.77 2.75 2.77S92 82.9 92 81.23V50.77C92 49.1 90.9 48 89.25 48zm-44-27.7h-27.5v-5.53c0-1.66-1.1-2.77-2.75-2.77s-2.75 1.1-2.75 2.77v27.7h33V20.3zm38.5 2.78v-8.3c0-1.67-1.1-2.78-2.75-2.78s-2.75 1.1-2.75 2.77v5.54h-27.5v22.16h33V23.08z" />
                                </svg>
                                <input
                                  type="text"
                                  value={bed.name}
                                  onChange={(e) => updateRoomBedName(index, e.target.value)}
                                />
                              </div>
                              <div className="abf093bdfe f45d8e4c32 df64fda51b">
                                {bed.width}
                              </div>
                            </div>
                          </div>
                          <div className="b817090550 a7cf1a6b1d">
                            <div className="a7a72174b8">
                              <div className="bfb38641b0">
                                <button
                                  type="button"
                                  disabled={bed.quantity < 1}
                                  onClick={() => updateRoomBedQuantity(index, bed.quantity - 1)}
                                  className="a83ed08757 c21c56c305 f38b6daa18 d691166b09 ab98298258 deab83296e e91c91fa93"
                                >
                                  <span className="eedba9e88a">
                                  <span
                                    className="fcd9eec8fb bf9a32efa5"
                                    aria-hidden="true"
                                  >
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      viewBox="0 0 24 24"
                                    >
                                      <path d="M20.25 12.75H3.75a.75.75 0 0 1 0-1.5h16.5a.75.75 0 0 1 0 1.5z" />
                                    </svg>
                                  </span>
                                </span>
                                </button>
                                <span className="d723d73d5f" aria-hidden="true">
                                  {bed.quantity}
                                </span>
                                <button
                                  type="button"
                                  onClick={() => updateRoomBedQuantity(index, bed.quantity + 1)}
                                  className="a83ed08757 c21c56c305 f38b6daa18 d691166b09 ab98298258 deab83296e bb803d8689 f4d78af12a"
                                >
                                   <span className="eedba9e88a">
                                  <span
                                    className="fcd9eec8fb bf9a32efa5"
                                    aria-hidden="true"
                                  >
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      viewBox="0 0 24 24"
                                    >
                                      <path d="M20.25 11.25h-7.5v-7.5a.75.75 0 0 0-1.5 0v7.5h-7.5a.75.75 0 0 0 0 1.5h7.5v7.5a.75.75 0 0 0 1.5 0v-7.5h7.5a.75.75 0 0 0 0-1.5z" />
                                    </svg>
                                  </span>
                                </span>
                                </button>
                              </div>
                            </div>
                          </div>
                          <button style={{
                            marginTop: "10px",
                            padding: "5px 5px",
                            fontSize: "16px",
                            backgroundColor: "red",
                            color: "white",
                            border: "none",
                            borderRadius: "5px",
                            cursor: "pointer",
                            transition: "background-color 0.3s",
                          }} type="button" onClick={() => removeRoomBed(index)}>Remove Room Bed</button>
                        </div>
                      ))}
                      <button style={{
                        marginTop: "10px",
                        padding: "10px 20px",
                        fontSize: "16px",
                        backgroundColor: "#70bbe9",
                        color: "white",
                        border: "none",
                        borderRadius: "5px",
                        cursor: "pointer",
                        transition: "background-color 0.3s",
                      }} onClick={addRoomBed}>Add Bed</button>
                                </div>
                              ) : (
                                ""
                              )}
                            </div>
                            <div className="aca0ade214 ebac6e22e9 f66f916626 c2931f4182 c27e5d305d db150fece4 count-input add-room room-row">
                              <div className="b817090550 d4e36dbd77">
                                <button
                                  type="button"
                                  id="automation_id_bedroom_edit_2"
                                  data-testid="bedroomitem_edit_1"
                                  className="edit-room bedroom-setup--list"
                                  onClick={HandleClick2}
                                >
                                  <span className="room-row-text">
                                    Living room
                                  </span>
                                  <small className="room-row-beds-count">
                                    {Sofabed === 0
                                      ? "Living room"
                                      : `${Sofabed} Sofa bed`}{"  "}
                                      {LRoombeds.length < 0 ? "" : LRoombeds.map((data, index) => (
                           <>
                            {data.quantity} {data.name}
                            </>
                         ))}
                                  </small>
                                </button>
                              </div>
                            </div>
                            {Open1 ? (
                              <div className="ffb9c3d6a3">
                                <div className="aca0ade214 ebac6e22e9 f66f916626 c2931f4182 c27e5d305d db150fece4 spacing--6x">
                                  <div className="b817090550 b736e9e3f4">
                                    <div className="bcdcb105b3">
                                      Living Room
                                    </div>
                                  </div>
                                </div>
                                <div className="aca0ade214 ebac6e22e9 f66f916626 c2931f4182 c27e5d305d db150fece4 addRoom">
                                  <div className="roomDesc b817090550 e0ad3ea0c7">
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      viewBox="0 0 96 96"
                                    >
                                      <path d="M78.25 45.75h-5.5c-1.65 0-2.75 1.1-2.75 2.75v8.25H26V48.5c0-1.65-1.1-2.75-2.75-2.75h-5.5c-1.65 0-2.75 1.1-2.75 2.75v19.25c0 1.65 1.1 2.75 2.75 2.75h2.75v2.75c0 1.65 1.1 2.75 2.75 2.75S26 74.9 26 73.25V70.5h44v2.75C70 74.9 71.1 76 72.75 76s2.75-1.1 2.75-2.75V70.5h2.75c1.65 0 2.75-1.1 2.75-2.75V48.5c0-1.65-1.1-2.75-2.75-2.75z" />
                                      <path d="M45.25 51.25V21h-22c-1.65 0-2.75 1.1-2.75 2.75v16.5h8.25c1.65 0 2.75 1.1 2.75 2.75v8.25h13.75zm30.25-27.5c0-1.65-1.1-2.75-2.75-2.75h-22v30.25H64.5V43c0-1.65 1.1-2.75 2.75-2.75h8.25v-16.5z" />
                                    </svg>
                                    Sofa bed
                                  </div>
                                  <div className="b817090550 a7cf1a6b1d">
                                    <div
                                      id="automation_id_add_bedroom_sofa_bed_input"
                                      className="a7a72174b8 input-stepper"
                                    >
                                      <div className="bfb38641b0">
                                        <button
                                          tabIndex={-1}
                                  disabled={Sofabed < 1}

                                          aria-hidden="true"
                                          type="button"
                                          className="a83ed08757 c21c56c305 f38b6daa18 d691166b09 ab98298258 deab83296e bb803d8689 e91c91fa93"
                                          onClick={decrement4}
                                        >
                                          <span className="eedba9e88a">
                                            <span
                                              className="fcd9eec8fb bf9a32efa5"
                                              aria-hidden="true"
                                            >
                                              <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                viewBox="0 0 24 24"
                                              >
                                                <path d="M20.25 12.75H3.75a.75.75 0 0 1 0-1.5h16.5a.75.75 0 0 1 0 1.5z" />
                                              </svg>
                                            </span>
                                          </span>
                                        </button>
                                        <span
                                          className="d723d73d5f"
                                          aria-hidden="true"
                                        >
                                          {Sofabed}
                                        </span>
                                        <button
                                          tabIndex={-1}
                                          aria-hidden="true"
                                          type="button"
                                          className="a83ed08757 c21c56c305 f38b6daa18 d691166b09 ab98298258 deab83296e bb803d8689 f4d78af12a"
                                          onClick={increaseRange4}
                                        >
                                          <span className="eedba9e88a">
                                            <span
                                              className="fcd9eec8fb bf9a32efa5"
                                              aria-hidden="true"
                                            >
                                              <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                viewBox="0 0 24 24"
                                              >
                                                <path d="M20.25 11.25h-7.5v-7.5a.75.75 0 0 0-1.5 0v7.5h-7.5a.75.75 0 0 0 0 1.5h7.5v7.5a.75.75 0 0 0 1.5 0v-7.5h7.5a.75.75 0 0 0 0-1.5z" />
                                              </svg>
                                            </span>
                                          </span>
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                {LRoombeds.map((bed, index) => (
                        <div key={index} className="aca0ade214 ebac6e22e9 f66f916626 c2931f4182 c27e5d305d db150fece4 spacing--6x addRoom">
                          <div className="roomDesc b817090550 e0ad3ea0c7">
                            
                            <div className="f2cf178bcd">
                              <div className="a3332d346a e6208ee469 d0caee4251 bed-type--not-highlighted">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 96 96"
                                >
                                  <path d="M89.25 48H6.75C5.1 48 4 49.1 4 50.77v30.46C4 82.9 5.1 84 6.75 84s2.75-1.1 2.75-2.77V70.15h77v11.08c0 1.66 1.1 2.77 2.75 2.77S92 82.9 92 81.23V50.77C92 49.1 90.9 48 89.25 48zm-44-27.7h-27.5v-5.53c0-1.66-1.1-2.77-2.75-2.77s-2.75 1.1-2.75 2.77v27.7h33V20.3zm38.5 2.78v-8.3c0-1.67-1.1-2.78-2.75-2.78s-2.75 1.1-2.75 2.77v5.54h-27.5v22.16h33V23.08z" />
                                </svg>
                                <input
                                  type="text"
                                  value={bed.name}
                                  onChange={(e) => updateLRoomBedName(index, e.target.value)}
                                />
                              </div>
                              <div className="abf093bdfe f45d8e4c32 df64fda51b">
                                {bed.width}
                              </div>
                            </div>
                          </div>
                          <div className="b817090550 a7cf1a6b1d">
                            <div className="a7a72174b8">
                              <div className="bfb38641b0">
                                <button
                                  type="button"
                                  disabled={bed.quantity < 1}
                                  onClick={() => updateLRoomBedQuantity(index, bed.quantity - 1)}
                                  className="a83ed08757 c21c56c305 f38b6daa18 d691166b09 ab98298258 deab83296e e91c91fa93"
                                >
                                  <span className="eedba9e88a">
                                  <span
                                    className="fcd9eec8fb bf9a32efa5"
                                    aria-hidden="true"
                                  >
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      viewBox="0 0 24 24"
                                    >
                                      <path d="M20.25 12.75H3.75a.75.75 0 0 1 0-1.5h16.5a.75.75 0 0 1 0 1.5z" />
                                    </svg>
                                  </span>
                                </span>
                                </button>
                                <span className="d723d73d5f" aria-hidden="true">
                                  {bed.quantity}
                                </span>
                                <button
                                  type="button"
                                  onClick={() => updateLRoomBedQuantity(index, bed.quantity + 1)}
                                  className="a83ed08757 c21c56c305 f38b6daa18 d691166b09 ab98298258 deab83296e bb803d8689 f4d78af12a"
                                >
                                   <span className="eedba9e88a">
                                  <span
                                    className="fcd9eec8fb bf9a32efa5"
                                    aria-hidden="true"
                                  >
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      viewBox="0 0 24 24"
                                    >
                                      <path d="M20.25 11.25h-7.5v-7.5a.75.75 0 0 0-1.5 0v7.5h-7.5a.75.75 0 0 0 0 1.5h7.5v7.5a.75.75 0 0 0 1.5 0v-7.5h7.5a.75.75 0 0 0 0-1.5z" />
                                    </svg>
                                  </span>
                                </span>
                                </button>
                              </div>
                            </div>
                          </div>
                          <button style={{
                            marginTop: "10px",
                            padding: "5px 5px",
                            fontSize: "16px",
                            backgroundColor: "red",
                            color: "white",
                            border: "none",
                            borderRadius: "5px",
                            cursor: "pointer",
                            transition: "background-color 0.3s",
                          }} type="button" onClick={() => removeLRoomBed(index)}>Remove Living Room Bed</button>
                        </div>
                      ))}
                      <button style={{
                        marginTop: "10px",
                        padding: "10px 20px",
                        fontSize: "16px",
                        backgroundColor: "#70bbe9",
                        color: "white",
                        border: "none",
                        borderRadius: "5px",
                        cursor: "pointer",
                        transition: "background-color 0.3s",
                      }} onClick={addLRoomBed}>Add Living Room Bed</button>
                              </div>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div
                      data-testid="SupMupEpContainer"
                      className="ffb9c3d6a3 wide-container"
                    >
                      <div className="form-group b817090550 b736e9e3f4">
                        <label
                          className="spacing--2x bedroom-label"
                          htmlFor="automation_id_bedroom_num_guests_input"
                        >
                          <div className="bcdcb105b3">
                            How many guests can stay?
                          </div>
                        </label>
                        <div
                          data-testid="automation_id_bedroom_num_guests_input_stepper"
                          className="a7a72174b8 input-stepper"
                        >
                          <div className="bfb38641b0">
                            <button
                              tabIndex={-1}
                              disabled={Stay < 1}

                              aria-hidden="true"
                              type="button"
                              className="a83ed08757 c21c56c305 f38b6daa18 d691166b09 ab98298258 deab83296e bb803d8689 e91c91fa93"
                              onClick={decrement5}
                            >
                              <span className="eedba9e88a">
                                <span
                                  className="fcd9eec8fb bf9a32efa5"
                                  aria-hidden="true"
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 24 24"
                                  >
                                    <path d="M20.25 12.75H3.75a.75.75 0 0 1 0-1.5h16.5a.75.75 0 0 1 0 1.5z" />
                                  </svg>
                                </span>
                              </span>
                            </button>
                            <span className="d723d73d5f" aria-hidden="true">
                              {Stay}
                            </span>
                            <button
                              tabIndex={-1}
                              aria-hidden="true"
                              type="button"
                              className="a83ed08757 c21c56c305 f38b6daa18 d691166b09 ab98298258 deab83296e bb803d8689 f4d78af12a"
                           onClick={increaseRange5}
                           >
                              <span className="eedba9e88a">
                                <span
                                  className="fcd9eec8fb bf9a32efa5"
                                  aria-hidden="true"
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 24 24"
                                  >
                                    <path d="M20.25 11.25h-7.5v-7.5a.75.75 0 0 0-1.5 0v7.5h-7.5a.75.75 0 0 0 0 1.5h7.5v7.5a.75.75 0 0 0 1.5 0v-7.5h7.5a.75.75 0 0 0 0-1.5z" />
                                  </svg>
                                </span>
                              </span>
                            </button>
                          </div>
                        </div>
                   
                      <div className="form-group b817090550 b736e9e3f4">
                        <label
                          className="spacing--2x bedroom-label"
                          htmlFor="automation_id_bedroom_num_guests_bathrooms_input"
                        >
                          <div className="bcdcb105b3">
                            How many bathrooms are there?
                          </div>
                        </label>
                        <div
                          data-testid="automation_id_bedroom_num_bathrooms_input_stepper"
                          className="a7a72174b8 input-stepper"
                        >
                          <div className="bfb38641b0">
                            <button
                              tabIndex={-1}
                              disabled={Bathroom < 1}

                              aria-hidden="true"
                              type="button"
                              className="a83ed08757 c21c56c305 f38b6daa18 d691166b09 ab98298258 deab83296e bb803d8689 e91c91fa93"
                              onClick={decrement6}

                            >
                              <span className="eedba9e88a">
                                <span
                                  className="fcd9eec8fb bf9a32efa5"
                                  aria-hidden="true"
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 24 24"
                                  >
                                    <path d="M20.25 12.75H3.75a.75.75 0 0 1 0-1.5h16.5a.75.75 0 0 1 0 1.5z" />
                                  </svg>
                                </span>
                              </span>
                            </button>
                            <span className="d723d73d5f" aria-hidden="true">
                             {Bathroom}
                            </span>
                            <button
                              tabIndex={-1}
                              aria-hidden="true"
                              type="button"
                              className="a83ed08757 c21c56c305 f38b6daa18 d691166b09 ab98298258 deab83296e bb803d8689 f4d78af12a"
                              onClick={increaseRange6}

                            >
                              <span className="eedba9e88a">
                                <span
                                  className="fcd9eec8fb bf9a32efa5"
                                  aria-hidden="true"
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 24 24"
                                  >
                                    <path d="M20.25 11.25h-7.5v-7.5a.75.75 0 0 0-1.5 0v7.5h-7.5a.75.75 0 0 0 0 1.5h7.5v7.5a.75.75 0 0 0 1.5 0v-7.5h7.5a.75.75 0 0 0 0-1.5z" />
                                  </svg>
                                </span>
                              </span>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
</div>
                    <div className="ffb9c3d6a3 wide-container second-container">
                      <div className="bcdcb105b3 spacing--4x">
                        How big is this Alternative Property?
                      </div>
                      <div className="bedroom-room-size">
                        <div className="a53cbfa6de ac9267e216 bedroom-room-size__room-measurement a20293ec70">
                          <label
                            htmlFor=":r1g:"
                            className="a53cbfa6de e6208ee469 f555271986"
                          >
                            <span>
                              <span className="ccb65902b2">
                                Alternative Property size - optional
                              </span>
                            </span>
                          </label>
                          <div className="b9b84f4305">
                            <div className="e000754250">
                              <input
                                type="number" onKeyDown={handleKeyDown}
                                name="room_size"
                                className="eb46370fe1"
                                value={ApartmentSize}
                               onChange={(e)=>setApartmentSize(e.target.value)}
                              />
                              <div className="e7e5251f68" />
                            </div>
                          </div>
                        </div>
                        <div className="a53cbfa6de ac9267e216 bedroom-room-size__measurement-type">
                          <div className="cabad3c686 aff44befa2">
                            <select
                              className="ebf4591c8e"
                              name="measurement_type"
                              value={ApartmentType}
                            onChange={(e)=>setApartmentType(e.target.value)}
                            >
                                <option disabled value="">
                                  Unit
                                </option>
                              <option value="square metres">
                                square metres
                              </option>
                              <option value="square feet">
                                square feet
                              </option>
                            </select>
                            <span className="ad2c299542">
                              <span
                                className="fcd9eec8fb cab1c196b5 c2cc050fb8 f64eb5d122"
                                aria-hidden="true"
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 24 24"
                                >
                                  <path d="M19.2681 8.91288C19.2687 9.03226 19.2454 9.15054 19.1996 9.26079C19.1539 9.37105 19.0865 9.47105 19.0016 9.55493L12.9445 15.612C12.8211 15.7367 12.6738 15.8353 12.5115 15.9018C12.3491 15.9684 12.1751 16.0017 11.9996 15.9997C11.6491 16.0075 11.3096 15.877 11.0547 15.6362L4.99764 9.57916C4.8275 9.40881 4.73193 9.17788 4.73193 8.93711C4.73193 8.69634 4.8275 8.46542 4.99764 8.29506C5.08117 8.20935 5.18101 8.14122 5.29128 8.09471C5.40155 8.04819 5.52002 8.02423 5.6397 8.02423C5.75937 8.02423 5.87784 8.04819 5.98811 8.09471C6.09838 8.14122 6.19822 8.20935 6.28175 8.29506L11.9996 13.9887L17.7175 8.27084C17.801 8.18512 17.9009 8.11699 18.0111 8.07048C18.1214 8.02396 18.2399 8 18.3596 8C18.4792 8 18.5977 8.02396 18.708 8.07048C18.8182 8.11699 18.9181 8.18512 19.0016 8.27084C19.0871 8.3543 19.1548 8.45422 19.2006 8.56457C19.2464 8.67492 19.2694 8.79341 19.2681 8.91288Z" />
                                </svg>
                              </span>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="form-cta">
                    <div>
                      <div className="form-buttons-container">
                        <div
                          className="form-buttons-container--secondary"
                          onClick={() => window.history.back()}
                        >
                          <button
                            aria-label="Back to previous step"
                            data-testid="FormButtonSecondary"
                            type="button"
                            className="a83ed08757 c21c56c305 bf0537ecb5 d691166b09 f671049264 d2529514af af7297d90d"
                          >
                            <span className="eedba9e88a">
                              <span
                                className="fcd9eec8fb c2cc050fb8"
                                aria-hidden="true"
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 24 24"
                                  data-rtl-flip="true"
                                >
                                  <path d="M15.0871 19.2361C14.9677 19.2367 14.8495 19.2134 14.7392 19.1676C14.629 19.1219 14.5289 19.0546 14.4451 18.9696L8.38799 12.9125C8.26332 12.7891 8.16475 12.6418 8.09815 12.4795C8.03156 12.3172 7.99829 12.1431 8.00034 11.9676C7.99246 11.6171 8.12303 11.2776 8.36376 11.0227L14.4208 4.96566C14.5912 4.79552 14.8221 4.69995 15.0629 4.69995C15.3037 4.69995 15.5346 4.79552 15.7049 4.96566C15.7907 5.04918 15.8588 5.14902 15.9053 5.25929C15.9518 5.36957 15.9758 5.48803 15.9758 5.60771C15.9758 5.72739 15.9518 5.84586 15.9053 5.95613C15.8588 6.0664 15.7907 6.16624 15.7049 6.24976L10.0113 11.9676L15.7292 17.6855C15.8149 17.769 15.883 17.8689 15.9295 17.9792C15.976 18.0894 16 18.2079 16 18.3276C16 18.4473 15.976 18.5657 15.9295 18.676C15.883 18.7863 15.8149 18.8861 15.7292 18.9696C15.6457 19.0551 15.5458 19.1228 15.4354 19.1686C15.3251 19.2144 15.2066 19.2374 15.0871 19.2361Z" />
                                </svg>
                              </span>
                            </span>
                          </button>
                        </div>
                        <div
                          className="form-buttons-container--primary"
                          // onClick={() =>
                          //   window.location.assign("/propertyservice")
                          // }
                          onClick={HandleClick}
                        >
                          <button
                            aria-label="Continue"
                            data-testid="FormButtonPrimary-enabled"
                            type="button"
                            className="a83ed08757 c21c56c305 a4c1805887 f671049264 d2529514af c082d89982"
                          >
                            <span className="e4adce92df">Continue</span>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            id="automation_id_screen_id_bedroom"
            style={{ display: "none" }}
          />
        </div>

        <div
          id="automation_id_flow_Segmentation_Flow"
          style={{ display: "none" }}
        ></div>
        <div></div>
      </div>
    </div>
    <img
      alt=""
      height="1"
      width="1"
      style={{ display: "none" }}
      src="https://booking.com/track_join_app_landing?progress_status=2"
    />
  </div>
  );
};

export default Alternativesetup;
