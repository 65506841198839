import { faAnglesRight } from "@fortawesome/free-solid-svg-icons";
import "./interhome.css";
import { debounce } from "lodash";
import { useEffect, useRef, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { TiInfoLarge } from "react-icons/ti";
import { BsFillFileEarmarkSpreadsheetFill } from "react-icons/bs";
import { FaImages, FaPlay } from "react-icons/fa";
import { GrMapLocation } from "react-icons/gr";
import Navbar from "../navbar/Navbar";
import Footer from "../footer/Footer";
import { Link, useLocation, useParams } from "react-router-dom";

import Icon1 from "../../assets/pd1.svg";
import Icon2 from "../../assets/pd2.svg";
import Icon3 from "../../assets/pd3.svg";
import Icon4 from "../../assets/pd4.svg";
import parse from "html-react-parser";
import axios from "axios";
import { MdDelete, MdKeyboardDoubleArrowRight } from "react-icons/md";
import Weather from "../../pages/hotel/Weather";
import { Map, GoogleApiWrapper, Marker } from "google-maps-react";
import { Tooltip } from "antd";
const API_KEY = "46a9246bebba16d42b36aac3fc3ba8af";
const HomeInterDetails = (props) => {
  const handleKeyDown = (e) => {
    // Prevent the input of the minus sign
    if (e.key === "-" || e.key === "e" || e.key === "E") {
      e.preventDefault();
    }
  };
  const user = JSON.parse(sessionStorage.getItem("user"));
  const [Checkindate, setCheckindate] = useState();
  const [name, setname] = useState("");
  const [email, setemail] = useState("");
  const [mobile, setmobile] = useState("");
  const [people, setpeople] = useState("");
  const [street, setstreet] = useState("");
  const [payment, setpayment] = useState("");

  const [country, setcountry] = useState("");
  const [state, setstate] = useState("");

  const [city, setcity] = useState("");
  const [pincode, setpincode] = useState("");
  const [address, setaddress] = useState("");

  // const address = [street, city, state, country, pincode];
  const [Checkoutdate, setCheckoutdate] = useState();
  const [info, setinfo] = useState(true);
  const [plan, setplan] = useState(false);
  const [gallary, setgallary] = useState(false);
  const [places, setPlaces] = useState([]);
  const clickinfo = () => {
    setinfo(true);
    setplan(false);
    setgallary(false);
  };
  const clickplan = () => {
    setplan(true);
    setinfo(false);

    setgallary(false);
  };
  const clickgallary = () => {
    setgallary(true);
    setplan(false);
    setinfo(false);
  };
  const params = useParams();
  const place = places.filter((items) => items?._id === params.id)[0];

  const getinternational = async () => {
    let res = await axios.get(
      "https://bookingoda.com/api/admin/getinternational"
    );
    if (res.status === 200) {
      //////console.log(res.data);
      setPlaces(
        res.data.getpackages.filter((item) => item.blockstatus !== true)
      );
    }
  };
  useEffect(() => {
    getinternational();
  }, [place]);
  //console.log(place?.city);
  const [uploadedImage, setUploadedImage] = useState([]);
  const fileInputRef = useRef(null);

  const handleFileChange = (event) => {
    const files = Array.from(event.target.files);
    setUploadedImage([...uploadedImage, ...files]);
    // Reset the file input value to allow re-uploading the same image
    fileInputRef.current.value = null;
  };

  const deleteImage = (index) => {
    const updatedImages = [...uploadedImage];
    updatedImages.splice(index, 1);
    setUploadedImage(updatedImages.length === 0 ? [] : updatedImages);
    if (fileInputRef.current) {
      fileInputRef.current.value = null;
    }
  };
  const mobilePattern = /^[0-9]{10}$/;

  const PackageBooking = async () => {
    const emailRegex = /^[^\s@]+@gmail\.com$/; // Email should end with @gmail.com
    const nameRegex = /^[a-zA-Z\s]{3,}$/; // At least 3 characters, letters and spaces only
    const mobileRegex = /^\d{10}$/; // Exactly 10 digits

    if (!user) {
      alert("Please login");
      window.location.assign("/login");
    } else if (!Checkindate) {
      return alert("Please select Check In Date");
    } else if (!people) {
      return alert("Please enter number of people");
      // } else if (!Checkoutdate) {
      //   return alert("Please select Check Out Date");
    } else if (
      !name ||
      !mobile ||
      !email ||
      // !pincode ||
      // !city ||
      // !state ||
      // !street ||
      !address
    ) {
      alert("Please fill all the fields");
    } else if (!payment) {
      alert("Please select payment method");
    } else if (!emailRegex.test(email)) {
      alert("Please enter a valid Email address");
    } else if (!nameRegex.test(name)) {
      alert("Name must be at least 3 characters long");
    } else if (!mobilePattern.test(mobile)) {
      return alert("Please enter a valid mobile number");
    } else if (uploadedImage.length === 0) {
      alert("No documents selected");
    } else {
      try {
        const config = {
          url: "admin/bookinternationalpackage",
          method: "post",
          baseURL: "https://bookingoda.com/api/",
          headers: { "Content-Type": "application/json" },
          data: {
            userId: user?._id,

            name: name ? name : user.name,
            email: email ? email : user.email,
            mobile: mobile ? mobile : user.mobile,
            noofpeople: people,
            address: address,
            payment: payment,
            arrivaldate: Checkindate,
            // departuredate: Checkoutdate,
            packageId: place?._id,
          },
        };
        await axios(config).then((res) => {
          if (res.status === 200) {
            uploadFiles(res.data.data?._id);

            // alert(res.data.success);
            // window.location.assign("/HomeInternational");
          }
        });
      } catch (error) {
        ////console.log(error);
        alert(error.response.data.error);
      }
    }
  };
  const formdata2 = new FormData();
  const uploadFiles = async (id) => {
   
      try {
        for (let i = 0; i < uploadedImage.length; i++) {
          formdata2.append("id", id);
          formdata2.append("Doc", uploadedImage[i]);
          await fetch("https://bookingoda.com/api/admin/addDocInternational/", {
            method: "post",
            body: formdata2,
          });
          alert("Booked Succesfully");

          window.location.assign("/HomeInternational");
        }
      } catch (error) {}
    
  };
  const [weatherData, setWeatherData] = useState(null);
  const [loaded, setLoaded] = useState(true);

  async function fetchWeatherData(cityName) {
    setLoaded(false);
    const API = `https://api.openweathermap.org/data/2.5/weather?q=${cityName}&units=metric&appid=${API_KEY}`;

    try {
      const response = await fetch(API);
      if (response.status === 200) {
        const data = await response.json();
        setWeatherData(data);
      } else {
        setWeatherData(null);
      }
      setLoaded(true);
    } catch (error) {
      console.error("Error fetching weather data:", error);
      setLoaded(true);
    }
  }
  const fetchData = async () => {
    try {
      const city = place?.city !== "" ? place?.city : "Bengaluru";
      await fetchWeatherData(city);
      // Other code that depends on the fetched data
    } catch (error) {
      console.error("Error fetching weather data:", error);
    }
  };
  useEffect(() => {
    fetchData();
  }, []); // The empty dependency array ensures that this effect runs only once on mount
  const debouncedFetchData = debounce(fetchData, 300);

  useEffect(() => {
    debouncedFetchData();
  }, [place?.city]);

  const lat = 13.07876;
  const log = 77.53324;

  if (!loaded) {
    return (
      <div className="container">
        <div>Loading...</div>
      </div>
    );
  } else if (weatherData === null) {
    return (
      <div className="container">
        {/* Render any additional UI or messages for when weather data is not available */}
      </div>
    );
  }
  return (
    <>
      <Navbar />
      <div>
        <div className="breadcrumb breadcrumb-style-one">
          <div className="container">
            <div className="col-lg-12 text-center">
              <h2 className="breadcrumb-title">Package Details</h2>
              <ul className="d-flex justify-content-center breadcrumb-items">
                <li className="breadcrumb-item">
                  <a href="/HomeInternational">Home</a>
                </li>
                <FontAwesomeIcon
                  className="icons"
                  icon={faAnglesRight}
                  style={{ margin: "0px" }}
                />
                <li className="breadcrumb-item active">Package details</li>
              </ul>
            </div>
          </div>
        </div>

        <div className="package-details-wrapper pt-76">
          <div className="container">
            <div className="row ">
              <div className="col-lg-8">
                <div className="tour-package-details">
                  <div className="pd-header">
                    <div className=" pd-top row row-cols-lg-4 row-cols-md-2 row-cols-2 gy-4">
                      <div className="col">
                        <div className="pd-single-infos">
                          <div className="infos-icon">
                            <img src={Icon1} alt="" />
                          </div>
                          <div className="infos">
                            <h6>Duration</h6>
                            <span>{place?.tripduration}</span>
                          </div>
                        </div>
                      </div>
                      <div className="col">
                        <div className="pd-single-infos">
                          <div className="infos-icon">
                            <img src={Icon2} alt="" />
                          </div>
                          <div className="infos">
                            <h6>Tour Type</h6>
                            <span>{place?.TourType}</span>
                          </div>
                        </div>
                      </div>
                      <div className="col">
                        <div className="pd-single-infos">
                          <div className="infos-icon">
                            <img src={Icon3} alt="" />
                          </div>
                          <div className="infos">
                            <h6>Group Size</h6>
                            <span>{place?.GroupSize} People</span>
                          </div>
                        </div>
                      </div>
                      <div className="col">
                        <div className="pd-single-infos">
                          <div className="infos-icon">
                            <img src={Icon4} alt="" />
                          </div>
                          <div className="infos">
                            <h6>Tour Guide</h6>
                            <span>{place?.TourGuide} People</span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="pd-thumb">
                      <img
                        src={`https://bookingoda.com/image/${place?.GalleryImageCulture[0]?.imageculture}`}
                        alt=""
                      />
                    </div>
                    <div className="header-bottom">
                      <div className="pd-lavel d-flex justify-content-between align-items-center flex-wrap gap-2">
                        <h5 className="location">
                          <GrMapLocation /> {place?.city},{place?.state},
                          {place?.country}
                        </h5>
                      </div>
                      <h2 className="pd-title" style={{ marginTop: "20px" }}>
                        {place?.DestinationName}
                      </h2>
                    </div>
                  </div>
                  <div className="package-details-tabs">
                    <ul
                      className="nav nav-pills tab-switchers gap-xxl-4 gap-3"
                      id="pills-tab"
                      role="tablist"
                    >
                      <li className="nav-item" role="presentation">
                        <button
                          className="nav-link active"
                          id="pills-package1"
                          data-bs-toggle="pill"
                          data-bs-target="#pill-body1"
                          type="button"
                          role="tab"
                          aria-controls="pill-body1"
                          aria-selected="true"
                          onClick={clickinfo}
                        >
                          <TiInfoLarge /> Information
                        </button>
                      </li>
                      <li className="nav-item" role="presentation">
                        <button
                          className="nav-link"
                          id="pills-package2"
                          data-bs-toggle="pill"
                          data-bs-target="#pill-body2"
                          type="button"
                          role="tab"
                          aria-controls="pill-body2"
                          aria-selected="false"
                          onClick={clickplan}
                        >
                          {" "}
                          <BsFillFileEarmarkSpreadsheetFill /> Travel Plan
                        </button>
                      </li>
                      <li className="nav-item" role="presentation">
                        <button
                          className="nav-link"
                          id="pills-package3"
                          data-bs-toggle="pill"
                          data-bs-target="#pill-body3"
                          type="button"
                          role="tab"
                          aria-controls="pill-body3"
                          aria-selected="false"
                          onClick={clickgallary}
                        >
                          <FaImages /> Tour Gallery
                        </button>
                      </li>
                    </ul>
                    <div className="" id="pills-tabContent">
                      {!info ? (
                        ""
                      ) : (
                        <div
                          className="tab-pane fade package-plan-tab  mt-3 active show"
                          id="pill-body1"
                          role="tabpanel"
                          aria-labelledby="pills-package1"
                        >
                          <h3 className="d-subtitle">Package Details</h3>
                          <p>{parse(place?.PlaceDescription)}</p>
                          <div className="p-infos-featured-img row position-relative g-4 row-cols-1 row-cols-sm-2">
                            <div className="col">
                              <div className="featured-img">
                                <img
                                  src={`https://bookingoda.com/image/${place?.GalleryImageCulture[1]?.imageculture}`}
                                  alt=""
                                />
                              </div>
                            </div>
                            <div className="col">
                              <div className="featured-img">
                                <img
                                  src={`https://bookingoda.com/image/${place?.GalleryImageCulture[2]?.imageculture}`}
                                  alt=""
                                />
                              </div>
                            </div>
                            <div className="featured-video position-absolute ">
                              <img
                                src={`https://bookingoda.com/image/${place?.GalleryImageCulture[3]?.imageculture}`}
                                style={{ width: "200px", height: "150px" }}
                                alt=""
                              />
                            </div>
                          </div>
                          <table className="table package-infos-table mb-0" style={{marginTop:"40px"}}>
                            {place?.TourSchedule.map((item) => (
                              <tbody>
                                <tr>
                                  <th>Destination</th>
                                  <td>{item.placename}</td>
                                </tr>

                                <tr>
                                  <th>Included</th>
                                  <td>
                                    <ul className="included-list">
                                      <li>{item.inclusion}</li>
                                    </ul>
                                  </td>
                                </tr>
                                <tr>
                                  <th>Excluded</th>
                                  <td>
                                    <ul className="excluded-list">
                                      <li>{item.exclusion}</li>
                                    </ul>
                                  </td>
                                </tr>
                                <tr>
                                  {/* <td colSpan={2} className="tour-transport-col"> <div className="tour-transport  d-flex align-items-center justify-content-center"><img src="https://demo.egenslab.com/html/tourxpro/demo/assets/images/icons/bus.svg" alt="" /> <span>Travel With Bus</span></div> </td> */}
                                </tr>
                              </tbody>
                            ))}
                          </table>
                        </div>
                      )}
                      {!plan ? (
                        ""
                      ) : (
                        <div
                          className="tab-pane fade package-plan-tab tab-body mt-3 active show"
                          id="pill-body2"
                          role="tabpanel"
                          aria-labelledby="pills-package2"
                        >
                          <h3 className="d-subtitle">Details</h3>

                          <div
                            className="accordion plans-accordion"
                            id="planAccordion"
                          >
                            {place?.Itinerary.map((data, i) => (
                              <div className="accordion-item plans-accordion-single">
                                <div
                                  className="accordion-header"
                                  id="planHeadingOne"
                                >
                                  <div
                                    className="accordion-button"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#planCollapse1"
                                    aria-expanded="true"
                                    role="navigation"
                                  >
                                    <div className="paln-index-circle">
                                      <h4 style={{ color: "white" }}>
                                        {i + 1}
                                      </h4>
                                    </div>
                                    <div className="plan-title">
                                      <h5>
                                        DAY {i + 1} : {data.Header}
                                      </h5>
                                    </div>
                                  </div>
                                </div>
                                <div
                                  id="planCollapse1"
                                  className="accordion-collapse collapse show"
                                  aria-labelledby="planHeadingOne"
                                  data-bs-parent="#planAccordion"
                                >
                                  <div className="accordion-body plan-infos">
                                    <p>{data.ItineraryDesc}</p>
                                  </div>
                                </div>
                              </div>
                            ))}
                          </div>
                        </div>
                      )}
                      {!gallary ? (
                        ""
                      ) : (
                        <div
                          className="tab-pane fade package-gallary-tab mt-3 active show"
                          id="pill-body3"
                          role="tabpanel"
                          aria-labelledby="pills-package3"
                        >
                          <div className="row g-4">
                            {place?.GalleryImageCulture.map((data) => (
                              <div className="col-6">
                                <div className="package-gallary-item">
                                  <a
                                    href={`https://bookingoda.com/image/${data.imageculture}`}
                                    data-fancybox="gallery"
                                    data-caption="Caption Here"
                                  >
                                    <img
                                      src={`https://bookingoda.com/image/${data.imageculture}`}
                                      alt=""
                                    />
                                  </a>
                                </div>
                              </div>
                            ))}
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-lg-4">
                {weatherData && (
                  <Weather
                    weatherData={weatherData}
                    fetchWeatherData={fetchWeatherData}
                  />
                )}
                <div className="package-sidebar">
                  <aside className="package-widget-style-2 widget-form mt-30">
                    <div className="widget-title text-center d-flex justify-content-between">
                      <h4>Book This Tour</h4>
                      <h3 className="widget-lavel">
                        Rs.{place?.tripcost} <span>Per Person</span>
                      </h3>
                    </div>
                    <div className="widget-body">
                      <div className="booking-form-wrapper">
                        <div className="custom-input-group">
                          <input
                            type="text"
                            class="black-placeholder"
                            placeholder="Your Full Name"
                            id="name"
                            style={{ width: "100%" }}
                            onChange={(e) => setname(e.target.value)}
                          />
                        </div>
                        <div className="custom-input-group">
                          <input
                            type="email"
                            class="black-placeholder"
                            placeholder="Your Email"
                            id="email"
                            style={{ width: "100%" }}
                            onChange={(e) => setemail(e.target.value)}
                          />
                        </div>
                        <div className="custom-input-group">
                          <input
                            type="tel"
                            placeholder="Phone"
                            class="black-placeholder"
                            maxLength={10}
                            id="phone"
                            style={{ width: "100%" }}
                            onChange={(e) => setmobile(e.target.value)}
                          />
                        </div>

                        <div className="custom-input-group">
                          <i className="bi bi-chevron-down" />
                          <input
                            type="number"
                            onKeyDown={handleKeyDown}
                            placeholder="No of people"
                            class="black-placeholder"
                            id="people"
                            style={{ width: "100%" }}
                            onChange={(e) => setpeople(e.target.value)}
                          />
                        </div>

                        <div className="custom-input-group">
                          <i className="bi bi-calendar3" />
                          <input
                            placeholder="Select your date"
                            type="date"
                            name="checkIn"
                            id="datepicker2"
                            onChange={(e) => setCheckindate(e.target.value)}
                            className="calendar hasDatepicker"
                            style={{ width: "100%" }}
                            min={new Date().toISOString().split("T")[0]}
                          />
                        </div>

                        <div
                          className="custom-input-group"
                          style={{ marginBottom: "20px" }}
                        >
                          <h6 style={{ marginLeft: "20px" }}>
                            <strong>Full address</strong>
                          </h6>
                          <textarea
                            cols={2}
                            rows={7}
                            class="black-placeholder"
                            id="name"
                            placeholder="Full address"
                            onChange={(e) => setaddress(e.target.value)}
                          />
                        </div>
                        <div className="upload-photos_container">
                          <div className="drag-and-drop__highlight">
                            <div className="wide-container min-height ota-container">
                              <div
                                className="spacing--4x"
                                data-testid="NumberOfPhotosRequiredMessage-container"
                              >
                                <label
                                  htmlFor="photoFileInput"
                                  className="add-photo-sub-info font_body_2"
                                >
                                  <div
                                    className="a3332d346a"
                                    style={{ margin: "10px", display: "block" }}
                                  >
                                    Upload documents for verfication (Pan
                                    card/Aadhar Card/Passport/Any other relevant
                                    government document).
                                  </div>{" "}
                                </label>
                              </div>
                              <div className="take-photo take-photo--with-drag-and-drop">
                                <div className="drag-and-drop">
                                  <div
                                    style={{
                                      border: "2px dashed #cccccc",
                                      borderRadius: "4px",
                                      padding: "20px",
                                      textAlign: "center",
                                      display: "flex",
                                      flexDirection: "row",
                                      cursor: "pointer",
                                    }}
                                  >
                                    <input
                                      type="file"
                                      multiple
                                      onChange={handleFileChange}
                                      ref={fileInputRef}
                                    />
                                    <p>Click to select one or more images</p>
                                  </div>
                                </div>
                              </div>
                              <div className="take-photo take-photo--with-drag-and-drop">
                                <div className="drag-and-drop">
                                  <div
                                    style={{
                                      textAlign: "center",
                                      marginTop: "10px",
                                    }}
                                  >
                                    {uploadedImage &&
                                      uploadedImage.map((item, index) => (
                                        <div
                                          key={index}
                                          style={{
                                            display: "inline-block",
                                            marginRight: "10px",
                                            marginTop: "10px",
                                          }}
                                        >
                                          <img
                                            src={URL.createObjectURL(item)}
                                            alt="Uploaded"
                                            style={{ maxWidth: "50px" }}
                                          />

                                          <Tooltip title="delete">
                                            <MdDelete
                                              color="red"
                                              onClick={() => deleteImage(index)}
                                            />
                                          </Tooltip>
                                        </div>
                                      ))}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="package-widget widget-tour-categoris mt-30">
                          <h3 style={{ marginLeft: "20px" }}>Payment Method</h3>
                          <div id="payment" className="widget-body" style={{}}>
                            <ul>
                              <li className="category-check">
                                <input
                                  type="checkbox"
                                  className="form-check-input"
                                  value="Online payment"
                                  onChange={(e) => setpayment(e.target.value)}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="payment_method_cheque"
                                >
                                  <MdKeyboardDoubleArrowRight />
                                  Online payment
                                </label>
                              </li>
                              <li className="category-check">
                                <input
                                  type="checkbox"
                                  className="form-check-input"
                                  value="Pay Cash"
                                  onChange={(e) => setpayment(e.target.value)}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="payment_method_cheque"
                                >
                                  <MdKeyboardDoubleArrowRight />
                                  Pay Cash
                                </label>
                              </li>
                            </ul>
                          </div>
                        </div>
                        <div className="custom-input-group">
                          <div className="submite-btn">
                            <button type="submit" onClick={PackageBooking}>
                              Book Now
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </aside>
                  <aside className="package-widget-style-2 widget-recent-package-entries mt-30">
                    <div className="widget-title text-center">
                      <h4>New Package</h4>
                    </div>
                    <div className="widget-body">
                      <ul>
                        {places.map((data) => (
                          <li className="package-sm">
                            <div className="thumb">
                              <Link
                                to={`/HomeInternationalDetails/${data._id}`}
                              >
                                <img
                                  src={`https://bookingoda.com/image/${data.GalleryImageCulture[0]?.imageculture}`}
                                  alt=""
                                />
                              </Link>
                            </div>
                            <div className="infos">
                              <h6>
                                <Link
                                  to={`/HomeInternationalDetails/${data._id}`}
                                >
                                  {data.DestinationName}
                                </Link>
                              </h6>
                              <div className="price">
                                <span>From</span>
                                <h6>
                                  Rs.{data.tripcost.toFixed(2)}{" "}
                                  <span>Per Person</span>
                                </h6>
                              </div>
                            </div>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </aside>
                </div>
                <div className="list-single-main-item fl-wrap" id="sec4">
                  <div className="list-single-main-item-title fl-wrap">
                    <h3>Location</h3>
                  </div>
                  <div
                    id="map"
                    style={{ position: "relative", overflow: "hidden" }}
                  >
                    <div
                      style={{
                        height: "100%",
                        width: "100%",
                        position: "absolute",
                        top: 0,
                        left: 0,
                        backgroundColor: "rgb(229, 227, 223)",
                      }}
                    >
                      <Map
                        google={props.google}
                        zoom={14}
                        style={{ width: "100%", height: "400px" }}
                        initialCenter={{ lat: lat, lng: log }}
                      >
                        <Marker position={{ lat: lat, lng: log }} />
                      </Map>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};
export default GoogleApiWrapper({
  apiKey: "AIzaSyCom4BCfTVsw3o22AYg69Y7EovmLfV_Alw",
})(HomeInterDetails);
