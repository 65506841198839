import React from "react";
import Profile_Dashboard from "./Profile_Dashboard";

import axios from "axios";
import { useEffect } from "react";
import { useState } from "react";
import moment from "moment";
import io from "socket.io-client";
import pics from "../../assets/user.png";
import { useRef } from "react";

function Com_Message() {

  const socket = io("https://bookingoda.com", {
    transports: ["polling"],
  });
  const user = JSON.parse(sessionStorage.getItem("user"));

  const [isLoading, setIsLoading] = useState(true);
  const [friends, setFriends] = useState([]);
  const [isConnected, setIsConnected] = useState(socket.connected);
  useEffect(() => {
    getFriends();

    socket.on("connect", () => {
      setIsConnected(true);
    });

    socket.on("disconnect", () => {
      setIsConnected(false);
    });

    socket.on("receive message client", (message) => {
      setData((prevChats) => [...prevChats, message]);
    });

    return () => {
      socket.off("connect");
      socket.off("disconnect");
      socket.off("receive message client");
    };
  }, []);
  const getFriends = () => {
    axios
      .get("https://bookingoda.com/api/user/getFririndlistByuserId/" + user._id)
      .then(function (response) {
        setFriends(response.data.success);
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  const [item, setitem] = useState({});

  const [data, setData] = useState([]);
  useEffect(() => {
    if (Object.keys(item).length > 0) {
      setIsLoading(true);
      setData([]); // Clear the data state
      getChat();
    }
  }, [item]);

  const getChat = async () => {
    let res = await axios.get(
      `https://bookingoda.com/api/user/getchat/${item?.senderId?._id}/${item?.receiverId?._id}`
    );
    if ((res.status = 200)) {
      //console.log(res);
      setData([...res.data.chat]);
      setIsLoading(false);
    }
  };
  const [inputText, setInputText] = useState("");

  const addChat = async () => {
    try {
      const config = {
        url: "/addchat",
        method: "post",
        baseURL: "https://bookingoda.com/api/user",
        data: {
          text: inputText,
          createdAt: new Date(),
          user: {
            _id: user?._id,
            name: user.name,
            avthar: user?.profileImage,
          },
          receiver: {
            _id:
              item?.senderId?._id == user._id
                ? item?.receiverId?._id
                : item?.senderId?._id,
            name:
              item?.senderId?._id == user._id
                ? item?.receiverId.name
                : item?.senderId.name,
            avthar:
              item?.senderId?._id == user._id
                ? item?.receiverId?.profileImage
                : item?.senderId?.profileImage,
          },
          type: "text",
        },
      };
      await axios(config).then(function (res) {
        if (res.status === 200) {
          getChat();
          setInputText("");
        }
      });
    } catch (error) {
      console.log(error);
      alert("Unable to send");
    }
  };

  const handleClick = (data) => {
    setitem(data);
  };
  const scrollRef = useRef();

  useEffect(() => {
    // Scroll to the bottom when messages change
    scrollRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, [data]);
  const MessageItem = ({ message, user }) => {
    return (
      <div
        style={{
          display: "flex",
          backgroundColor: message.user?._id === user?._id ? "#005c4b" : "#202c33",
          justifyContent: message.user?._id === user?._id ? "flex-end" : "flex-start",
          padding: "15px",
          borderTopRightRadius: message.user?._id === user?._id ? 0 : 20,
          marginBottom: "10px",
          borderBottomRightRadius: 20,
          borderTopLeftRadius: message.user?._id === user?._id ? 20 : 0,
          borderBottomLeftRadius: 20,
        }}
      >
        <span
          style={{
            color: "white",
            fontSize: "16px",
            fontWeight: "500",
            marginLeft: "10px",
          }}
        >
          {message.text}
        </span>
  
        <span
          style={{
            color: "white",
            fontSize: "12px",
            marginTop: "5px",
            alignSelf: "flex-end",
            marginLeft: "10px",
          }}
        >
          {moment(message.createdAt).format("DD/MM/YYYY hh:mm a")}
        </span>
      </div>
    );
  };
  return (
    <div className="communityhomestyle">
      <div className="gap2 gray-bg">
        <div
          className="container fluid mobile-correct-0"
          style={{ paddingTop: "30px" }}
        >
          <div className="row">
            <div className="col-lg-12">
              <div className="row merged20" id="page-contents">
                <div className="user-profile">
                  <Profile_Dashboard />
                </div>

                <div className="col-lg-12 col-md-12">
                  <aside className="sidebar">
                    <div class="dashboard-list-box fl-wrap">
                      <div
                        class="dashboard-header color-bg fl-wrap d-flex"
                        style={{ justifyContent: "space-between" }}
                      >
                        <h3>Indox</h3>
                      </div>
                      <div
                        class="chat-wrapper fl-wrap"
                        style={{ backgroundColor: "white" }}
                      >
                        <div class="row">
                          <div class="col-sm-8">
                            <div>
                              <div>
                                <div class="dashboard-message-avatar">
                                  {item?.senderId?._id == user._id ? (
                                    item?.receiverId?.profileImage ? (
                                      <img
                                        src={`https://bookingoda.com/admin/${item?.receiverId?.profileImage}`}
                                        alt="Avatar"
                                      />
                                    ) : (
                                      <img src={pics} alt="Avatar" />
                                    )
                                  ) : item?.senderId?.profileImage ? (
                                    <img
                                      src={`https://bookingoda.com/admin/${item?.senderId?.profileImage}`}
                                      alt="Avatar"
                                    />
                                  ) : (
                                    <img src={pics} alt="Avatar" />
                                  )}

                                  <span>
                                    {item?.senderId?._id == user._id
                                      ? item?.receiverId?.name
                                      : item?.senderId?.name}
                                  </span>
                                </div>
                              </div>
                              <div className="chat-container" style={{ height: '400px', // Set a fixed height for the chat window
        overflowY: 'auto', 
        
       
        padding: '10px', }}>
                                {isLoading ? (
                                  <div>Loading...</div>
                                ) : data?.length > 0 ? (
                                  data.map((message) => (
                                    <div
                                      className="messages-container"
                                      key={data.length}
                                      style={{
                                        display: "flex",
                                        justifyContent:
                                          message.user?._id === user?._id
                                            ? "flex-end"
                                            : "flex-start",
                                      }}
                                    >
                                      <MessageItem
                                        key={message?._id}
                                        message={message}
                                        user={user}
                                      />
                                    </div>
                                  ))
                                ) : (
                                  <div>No messages to display</div>
                                )}
                                   <div ref={scrollRef} />
                                <div
                                  className="message-input-container"
                                  style={{}}
                                >
                                  <input
                                    className="message-input"
                                    value={inputText}
                                    onChange={(e) =>
                                      setInputText(e.target.value)
                                    }
                                    placeholder="Type your message..."
                                  />
                                  <button
                                    className="send-button"
                                    onClick={() => addChat(inputText)}
                                  >
                                    Send
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div class="col-sm-4">
                            {friends.map((data) => (
                              <div
                                class="chat-contacts fl-wrap"
                                style={{ border: "1px solid #eeeeee" }}
                                onClick={() => handleClick(data)}
                              >
                                <a class="chat-contacts-item">
                                  <div class="dashboard-message-avatar">
                                    {data?.senderId?._id == user?._id ? (
                                      data?.receiverId?.profileImage ? (
                                        <img
                                          src={`https://bookingoda.com/admin/${data?.receiverId?.profileImage}`}
                                          alt="Avatar"
                                        />
                                      ) : (
                                        <img src={pics} alt="Avatar" />
                                      )
                                    ) : data?.senderId?.profileImage ? (
                                      <img
                                        src={`https://bookingoda.com/admin/${data?.senderId?.profileImage}`}
                                        alt="Avatar"
                                      />
                                    ) : (
                                      <img src={pics} alt="Avatar" />
                                    )}
                                    {/* <div class="message-counter text-center">
                                      2
                                    </div> */}
                                  </div>
                                  <div class="chat-contacts-item-text">
                                    <h4>
                                      {data?.senderId?._id == user?._id
                                        ? data?.receiverId?.name
                                        : data?.senderId?.name}
                                    </h4>

                                    <p>
                                      {moment(data.updatedAt).format(
                                        "DD/MM/YYYY hh:mm a"
                                      )}
                                    </p>
                                  </div>
                                </a>
                              </div>
                            ))}
                          </div>
                        </div>
                      </div>
                    </div>
                  </aside>
                </div>

                {/* middle page end =================================================== */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Com_Message;
