import React, { useState } from "react";
import "./property.css";
import Apartment from "../../assets/apartments.png";
import Apartments from "../../assets/buildings.png";

import Hotel from "../../assets/hotel.png";
import VendorHeader from "./Vendorheader";
import { useLocation } from "react-router-dom";
import axios from "axios";
import Success from "../../assets/success.png";
import Error from "../../assets/close.png";
import { Container, Form, Modal, Row, Table } from "react-bootstrap";
const HotelCompleteregistration = () => {
  const [successshow, setsuccessshow] = useState(false);
  const handlesuccessClose = () => setsuccessshow(false);
  const handlesuccess = () => setsuccessshow(true);

  const [errorshow, seterrorshow] = useState(false);
  const handleerrorClose = () => seterrorshow(false);
  const handleerror = () => seterrorshow(true);
  const { state } = useLocation();
  ////console.log(state);
  const Roombeds = JSON?.parse(sessionStorage.getItem("Roombeds"));
  const vendor = JSON?.parse(sessionStorage.getItem("vendor"));

  const LRoombeds = JSON?.parse(sessionStorage.getItem("LRoombeds"));
  const Pets = JSON?.parse(sessionStorage.getItem("Pets"));
  const CreditCharges = JSON?.parse(sessionStorage.getItem("CreditCharges"));
  const General = JSON?.parse(sessionStorage.getItem("General"));
  const Food = JSON?.parse(sessionStorage.getItem("Food"));
  const HotelUse = JSON?.parse(sessionStorage.getItem("HotelUse"));
  const address = JSON?.parse(sessionStorage.getItem("address"));

  const Hotelname = JSON?.parse(sessionStorage.getItem("Hotelname"));
  const BreakfastTypes = JSON?.parse(sessionStorage.getItem("BreakfastTypes"));
  const Fullbed = JSON?.parse(sessionStorage.getItem("Fullbed"));
  const RoomName = JSON?.parse(sessionStorage.getItem("RoomName"));
  const BathroomItems = JSON?.parse(sessionStorage.getItem("BathroomItems"));
  const Roomtype = JSON?.parse(sessionStorage.getItem("Roomtype"));
  const Roomsmoking = JSON?.parse(sessionStorage.getItem("Roomsmoking"));
  const Language = JSON?.parse(sessionStorage.getItem("Language"));
  const SameAddress = JSON?.parse(sessionStorage.getItem("SameAddress"));
  const QuestStay = JSON?.parse(sessionStorage.getItem("QuestStay"));
  const CheckInFrom = JSON?.parse(sessionStorage.getItem("CheckInFrom"));
  const Roomsize = JSON?.parse(sessionStorage.getItem("Roomsize"));
  const BreakfastIncluded = JSON?.parse(
    sessionStorage.getItem("BreakfastIncluded")
  );
  const PrivateBathroom = JSON?.parse(
    sessionStorage.getItem("PrivateBathroom")
  );
  const Smoking = JSON?.parse(sessionStorage.getItem("Smoking"));
  const Breakfast = JSON?.parse(sessionStorage.getItem("Breakfast"));
  const CheckOutUntil = JSON?.parse(sessionStorage.getItem("CheckOutUntil"));
  const HotelType = JSON?.parse(sessionStorage.getItem("HotelType"));
  const Children = JSON?.parse(sessionStorage.getItem("Children"));
  const TwinBed = JSON?.parse(sessionStorage.getItem("TwinBed"));
  const BreakfastPrice = JSON?.parse(sessionStorage.getItem("BreakfastPrice"));
  const invoicename = JSON?.parse(sessionStorage.getItem("invoicename"));
  const Price = JSON?.parse(sessionStorage.getItem("Price"));
  const Queenbed = JSON?.parse(sessionStorage.getItem("Queenbed"));
  const NumberManyHotelstayType = JSON?.parse(
    sessionStorage.getItem("NumberManyHotelType")
  );
  const CheckInUntil = JSON?.parse(sessionStorage.getItem("CheckInUntil"));
  const Kingbed = JSON?.parse(sessionStorage.getItem("Kingbed"));
  const beds = JSON?.parse(sessionStorage.getItem("addbeds"));

  const RoomUnit = JSON?.parse(sessionStorage.getItem("RoomUnit"));
  const Parties = JSON?.parse(sessionStorage.getItem("Parties"));
  const CheckOutFrom = JSON?.parse(sessionStorage.getItem("CheckOutFrom"));
  const Outdoors = JSON?.parse(sessionStorage.getItem("Outdoors"));
  const Roommeasure = JSON?.parse(sessionStorage.getItem("Roommeasure"));
  const Locationservices = JSON?.parse(
    sessionStorage.getItem("Location services")
  );
  const AccidentalBooking = JSON?.parse(
    sessionStorage.getItem("AccidentalBooking")
  );
  const Refunddays = JSON?.parse(sessionStorage.getItem("Refunddays"));
  const Discount = JSON?.parse(sessionStorage.getItem("Discount"));

  const Weekdayrate = JSON?.parse(sessionStorage.getItem("Weekdayrate"));
  const Weekendrate = JSON?.parse(sessionStorage.getItem("Weekendrate"));
  const differentaddress = JSON?.parse(
    sessionStorage.getItem("differentaddress")
  );
  const RefundRate = JSON?.parse(sessionStorage.getItem("RefundRate"));
  const Singlebed = JSON?.parse(sessionStorage.getItem("Singlebed"));
  const XLargebed = JSON?.parse(sessionStorage.getItem("XLargebed"));
  const Largebed = JSON?.parse(sessionStorage.getItem("Largebed"));
  const HotelMeasurement = JSON?.parse(
    sessionStorage.getItem("HotelMeasurement")
  );
  const Doublebed = JSON?.parse(sessionStorage.getItem("Doublebed"));
  const Sofabed = JSON?.parse(sessionStorage.getItem("Sofabed"));
  const HotelSize = JSON?.parse(sessionStorage.getItem("HotelSize"));
  const Bathroom = JSON?.parse(sessionStorage.getItem("Bathroom"));
  const Stay = JSON?.parse(sessionStorage.getItem("Stay"));
  const RoomDetails = JSON?.parse(sessionStorage.getItem("Roomdetails"));
  //console.log(RoomDetails, "Room");
  const formdata = new FormData();

  const PhotosFile = state.uploadedImage;
  const PhotosFile1 = state.uploadedVideos;
  const photoFileInput = PhotosFile.concat(PhotosFile1);

  const [check1, setcheck1] = useState(false);
  const [check2, setcheck2] = useState(false);
  const uploadFiles = async (id) => {
    try {
      for (let i = 0; i < photoFileInput.length; i++) {
        formdata.append("id", id);
        formdata.append("Photos", photoFileInput[i]);
        await fetch("https://bookingoda.com/api/admin/addhotelPhotos/", {
          method: "post",
          body: formdata,
        });
      }
      ////console.log(formdata,"ggg");
      window.location.assign("/property");
    } catch (error) {
      ////console.log(error);
    }
  };
  // const Register = async () => {
  //   if (check1 === false || check2 === false) {
  //     alert("Please Select Agree the terms");
  //   } else {

  //     try {
  //       const config = {
  //         url: "/Addhotel",
  //         method: "post",
  //         baseURL: "https://bookingoda.com/api/admin",
  //         headers: { "content-type": "application/json" },
  //         data:
  //          {UserId: vendor._id,
  //         HotelType: HotelType,

  //         NumberManyHotelType: NumberManyHotelstayType,

  //         PropertyName: Hotelname,
  //         Singlebed:Singlebed,
  //         Doublebed:Doublebed,
  //         Largebed:Largebed,
  //         XLargebed:XLargebed,
  //         Sofabed:Sofabed,
  //         QuestStayHotel:Stay,
  //         HotelStaySize:HotelSize,
  //         BathroomsHotelStay:Bathroom,

  //         HotelStayMeasure:HotelMeasurement,
  //         Locationservices: Locationservices,
  //         differentaddress: differentaddress,
  //         AccidentalBooking: AccidentalBooking,
  //         Weekendrate: Weekendrate,
  //         Cancellationdays: Refunddays,
  //         Discount: Discount,
  //         DiscountRate: RefundRate,
  //         Weekdayrate: Weekdayrate,
  //         address: address,
  //      HotelUse:HotelUse,
  //         Breakfast:Breakfast,
  //         BreakfastIncluded:BreakfastIncluded,
  //         BreakfastOffers:BreakfastTypes,
  //         BreakfastPrice:BreakfastPrice,

  //         Language:Language,
  //         Smoking: Smoking,
  //         Pets: Pets,
  //         Children:Children,
  //         Parties: Parties,
  //         CheckinFrom:CheckInFrom,
  //         CheckinUntil: CheckInUntil,
  //         CheckOutFrom: CheckOutFrom,
  //         CheckOutUntil: CheckOutUntil,
  //         // Photos: state.Property.Property.Property.RoomDetails.Room.Room.Rate.Price.Photos.Photo,
  //         Price:Price,

  //         RoomUnit:RoomUnit ,
  //         RoomType:Roomtype ,
  //         Twinbed:TwinBed,
  //         Fullbed:Fullbed,
  //         Queenbed:Queenbed,
  //         Kingbed:Kingbed,
  //         beds:beds,
  //         Roombeds:Roombeds,
  //         LRoombeds:LRoombeds,
  //         RoomStay:QuestStay,
  //         RoomSize:Roomsize,
  //         RoomMeasure:Roommeasure ,
  //         RoomSmoking:Roomsmoking ,
  //         PrivateBathroom:PrivateBathroom ,
  //         BathroomItems:BathroomItems ,
  //         General: General ,
  //         Outdoors: Outdoors ,
  //         Food: Food ,
  //         Roomname:RoomName,
  //         CreditCharges:CreditCharges,
  //         InvoiceName: invoicename,
  //         Sameaddress: SameAddress,
  //         status:"Pending"
  //         },
  //       };
  //       await axios(config).then((res) => {
  //         if (res.status === 200) {
  //           uploadFiles(res.data.hotels?._id)
  //           window.sessionStorage.removeItem("HotelType");
  //           window.sessionStorage.removeItem("SameAddress");
  //           window.sessionStorage.removeItem("invoicename");
  //           window.sessionStorage.removeItem("CreditCharges");
  //           window.sessionStorage.removeItem("RoomName");
  //           window.sessionStorage.removeItem("Food");
  //           window.sessionStorage.removeItem("Outdoors");
  //           window.sessionStorage.removeItem("General");
  //           window.sessionStorage.removeItem("BathroomItems");
  //           window.sessionStorage.removeItem("PrivateBathroom");
  //           window.sessionStorage.removeItem("Roomsmoking");
  //           window.sessionStorage.removeItem("Roommeasure");
  //           window.sessionStorage.removeItem("Roomsize");
  //           window.sessionStorage.removeItem("QuestStay");
  //           window.sessionStorage.removeItem("Kingbed");
  //           window.sessionStorage.removeItem("beds");
  //           window.sessionStorage.removeItem("Roombeds");
  //           window.sessionStorage.removeItem("LRoombeds");
  //           window.sessionStorage.removeItem("Queenbed");
  //           window.sessionStorage.removeItem("Fullbed");
  //           window.sessionStorage.removeItem("TwinBed");
  //           window.sessionStorage.removeItem("Roomtype");
  //           window.sessionStorage.removeItem("RoomUnit");
  //           window.sessionStorage.removeItem("Weekdayrate");
  //           window.sessionStorage.removeItem("RefundRate");
  //           window.sessionStorage.removeItem("Discount");
  //           window.sessionStorage.removeItem("AccidentalBooking");
  //           window.sessionStorage.removeItem("Refunddays");
  //           window.sessionStorage.removeItem("Price");
  //           window.sessionStorage.removeItem("CheckOutUntil");
  //           window.sessionStorage.removeItem("CheckOutFrom");
  //           window.sessionStorage.removeItem("CheckInUntil");
  //           window.sessionStorage.removeItem("CheckInFrom");
  //           window.sessionStorage.removeItem("HotelUse");
  //           window.sessionStorage.removeItem("BreakfastTypes");
  //           window.sessionStorage.removeItem("Parties");
  //           window.sessionStorage.removeItem("Pets");
  //           window.sessionStorage.removeItem("Smoking");
  //           window.sessionStorage.removeItem("Language");
  //           window.sessionStorage.removeItem("Parking");
  //           window.sessionStorage.removeItem("Breakfast");
  //           window.sessionStorage.removeItem("Bathroom");
  //           window.sessionStorage.removeItem("HotelMeasurement");
  //           window.sessionStorage.removeItem("HotelSize");
  //           window.sessionStorage.removeItem("Locationservices");
  //           window.sessionStorage.removeItem("BreakfastIncluded");
  //           window.sessionStorage.removeItem("BreakfastPrice");
  //           window.sessionStorage.removeItem("Stay");
  //           window.sessionStorage.removeItem("Sofabed");
  //           window.sessionStorage.removeItem("XLargebed");
  //           window.sessionStorage.removeItem("Largebed");
  //           window.sessionStorage.removeItem("Doublebed");
  //           window.sessionStorage.removeItem("Singlebed");
  //           window.sessionStorage.removeItem("differentaddress");
  //           window.sessionStorage.removeItem("Location services");
  //           window.sessionStorage.removeItem("address");
  //           window.sessionStorage.removeItem("Hotelname");
  //           window.sessionStorage.removeItem("Weekendrate");
  //           window.sessionStorage.removeItem("NumberManyHotelstayType");
  //           window.sessionStorage.removeItem("Stay1");

  //           //  alert("Successfully Registered");
  //           handlesuccess();

  //         }
  //       });
  //     } catch (error) {
  //       //////console.log(error);
  //       alert(error.response.data.error);
  //       handleerror();
  //     }
  //   }
  // };
  const Register = async () => {
    if (check1 === false || check2 === false) {
      alert("Please Select Agree the terms");
    } else {
      try {
        const formData1 = new FormData();

        formData1.append("UserId", vendor._id);
        formData1.append("HotelType", HotelType);

        formData1.append("NumberManyHotelType", NumberManyHotelstayType);
        formData1.append("Weekendrate", Weekendrate);
        formData1.append("PropertyName", Hotelname);
        formData1.append("address", address);
        formData1.append("Locationservices", JSON.stringify(Locationservices));
        formData1.append("differentaddress", differentaddress);
        formData1.append("Singlebed", Singlebed);
        formData1.append("Doublebed", Doublebed);
        formData1.append("Largebed", Largebed);
        formData1.append("XLargebed", XLargebed);
        formData1.append("Sofabed", Sofabed);
        formData1.append("QuestStayHotel", Stay);
        formData1.append("HotelStaySize", HotelSize);
        formData1.append("HotelStayMeasure", HotelMeasurement);
        formData1.append("BathroomsHotelStay", Bathroom);
        formData1.append("Breakfast", Breakfast);
        formData1.append("BreakfastIncluded", BreakfastIncluded);
        formData1.append("BreakfastOffers", JSON.stringify(BreakfastTypes));
        formData1.append("BreakfastPrice", BreakfastPrice);

        formData1.append("Language", JSON.stringify(Language));
        formData1.append("Smoking", Smoking);
        formData1.append("Pets", Pets);
        formData1.append("Children", Children);
        formData1.append("Parties", Parties);
        formData1.append("HotelUse", JSON.stringify(HotelUse));
        formData1.append("CheckinFrom", CheckInFrom);
        formData1.append("CheckinUntil", CheckInUntil);
        formData1.append("CheckOutFrom", CheckOutFrom);
        formData1.append("CheckOutUntil", CheckOutUntil);
        formData1.append("Price", Price);
        formData1.append("Cancellationdays", Refunddays);
        formData1.append("AccidentalBooking", AccidentalBooking);
        formData1.append("Discount", JSON.stringify(Discount));
        formData1.append("DiscountRate", RefundRate);
        formData1.append("Weekdayrate", Weekdayrate);
        formData1.append("Roombeds", JSON.stringify(Roombeds));
        formData1.append("LRoombeds", JSON.stringify(LRoombeds));
        formData1.append("CreditCharges", CreditCharges);
        formData1.append("InvoiceName", invoicename);
        formData1.append("Sameaddress", SameAddress);
        formData1.append("status", "Pending");

        formData1.append("RoomDetails", JSON.stringify(RoomDetails));
        RoomDetails.forEach((room, i) => {
          room.RoomPhotos.forEach((photo, j) => {
            formData1.append(`RoomDetails[${i}].RoomPhotos[${j}]`, photo);
          });
        });
        const config = {
          url: "/Addhotel",
          method: "post",
          baseURL: "https://bookingoda.com/api/admin",
          headers: { "content-type": "multipart/form-data" },
          data: formData1,
        };

        const response = await axios(config);

        if (response.status === 200) {
          uploadFiles(response.data.hotels?._id);

          window.sessionStorage.removeItem("HotelType");
          window.sessionStorage.removeItem("SameAddress");
          window.sessionStorage.removeItem("invoicename");
          window.sessionStorage.removeItem("CreditCharges");
          window.sessionStorage.removeItem("RoomName");
          window.sessionStorage.removeItem("Food");
          window.sessionStorage.removeItem("Outdoors");
          window.sessionStorage.removeItem("General");
          window.sessionStorage.removeItem("BathroomItems");
          window.sessionStorage.removeItem("PrivateBathroom");
          window.sessionStorage.removeItem("Roomsmoking");
          window.sessionStorage.removeItem("Roommeasure");
          window.sessionStorage.removeItem("Roomsize");
          window.sessionStorage.removeItem("QuestStay");
          window.sessionStorage.removeItem("Kingbed");
          window.sessionStorage.removeItem("beds");
          window.sessionStorage.removeItem("Roombeds");
          window.sessionStorage.removeItem("BreakfastIncluded");
          window.sessionStorage.removeItem("BreakfastPrice");
          window.sessionStorage.removeItem("BreakfastTypes");
          window.sessionStorage.removeItem("LRoombeds");
          window.sessionStorage.removeItem("Queenbed");
          window.sessionStorage.removeItem("Fullbed");
          window.sessionStorage.removeItem("TwinBed");
          window.sessionStorage.removeItem("Roomtype");
          window.sessionStorage.removeItem("RoomUnit");
          window.sessionStorage.removeItem("Roomdetails");
          window.sessionStorage.removeItem("NumberManyHotelType");
          window.sessionStorage.removeItem("Weekdayrate");
          window.sessionStorage.removeItem("RefundRate");
          window.sessionStorage.removeItem("Discount");
          window.sessionStorage.removeItem("AccidentalBooking");
          window.sessionStorage.removeItem("Refunddays");
          window.sessionStorage.removeItem("Price");
          window.sessionStorage.removeItem("CheckOutUntil");
          window.sessionStorage.removeItem("CheckOutFrom");
          window.sessionStorage.removeItem("CheckInUntil");
          window.sessionStorage.removeItem("CheckInFrom");
          window.sessionStorage.removeItem("Parties");
          window.sessionStorage.removeItem("Pets");
          window.sessionStorage.removeItem("Smoking");
          window.sessionStorage.removeItem("Language");
          window.sessionStorage.removeItem("Breakfast");
          window.sessionStorage.removeItem("BreakFast");
          window.sessionStorage.removeItem("Bathroom");
          window.sessionStorage.removeItem("HotelMeasurement");
          window.sessionStorage.removeItem("HotelSize");
          window.sessionStorage.removeItem("Location services");
          window.sessionStorage.removeItem("Children");
          window.sessionStorage.removeItem("RoomPrice");
          window.sessionStorage.removeItem("addbeds");
          window.sessionStorage.removeItem("HotelUse");

          window.sessionStorage.removeItem("Stay");
          window.sessionStorage.removeItem("Sofabed");
          window.sessionStorage.removeItem("XLargebed");
          window.sessionStorage.removeItem("Largebed");
          window.sessionStorage.removeItem("Doublebed");
          window.sessionStorage.removeItem("Singlebed");
          window.sessionStorage.removeItem("differentaddress");
          window.sessionStorage.removeItem("Location services");
          window.sessionStorage.removeItem("address");
          window.sessionStorage.removeItem("propertyname");
          window.sessionStorage.removeItem("Weekendrate");
          window.sessionStorage.removeItem("NumberManyHotelType");
          // window.sessionStorage.removeItem("ManyHotelType");
          window.sessionStorage.removeItem("Stay1");
          window.sessionStorage.removeItem("Hotelname");
          //  alert("Successfully Registered");
          handlesuccess();
        }
      } catch (error) {
        // Handle error
        console.error(error);
        alert(error.response.data.error);
        handleerror();
      }
    }
  };
  return (
    <div
      data-capla-component="b-registration-joinapp15min-fe/"
      data-capla-namespace="b-registration-joinapp15min-feaYGUODfA"
    >
      <div id="app-container">
        <div class="error-popup-container"></div>
        <div class="application">
          <VendorHeader />
          <div class="error-popup-container"></div>
          <div className="page-container">
            <div className="screens screens-animation-direction-forward">
              <div
                className="height-100 screen-change-enter-done"
                id="automation_id_screen_container_Agreement"
              >
                <div className="ms-container agreement">
                  <div className>
                    <div className="ffb9c3d6a3 ab0db388cf a8e45fbcef spacing--6x" />
                    <div className="ffb9c3d6a3 wide-container">
                      <div className="aca0ade214 ebac6e22e9 f66f916626 c2931f4182 c27e5d305d db150fece4">
                        <div className="b817090550 b736e9e3f4">
                          <div className="f2cf178bcd agreement-title d991e1216a">
                            <div className="f6431b446c e6208ee469 d0caee4251 screen-main-header__title">
                              That’s it! You’ve done everything you need to
                              before your first guest stays.
                            </div>
                          </div>
                          <p className="agreement-sub-title">
                            After you finish your registration you’ll be able
                            to:
                          </p>
                          <ul className="agreement-next-list agreement-next-list--with-av-message">
                            <li>
                              <div className="agreement-next-item-icon">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width={64}
                                  height={64}
                                >
                                  <g fill="none" fillRule="evenodd">
                                    <path d="M0 0h64v64H0z" />
                                    <path
                                      fill="#707070"
                                      d="M43.43 8.02L20.55 8A4.47 4.47 0 0 0 16 12.36v39.28c0 2.4 2.04 4.36 4.55 4.36h22.88A4.49 4.49 0 0 0 48 51.64V12.36c0-2.4-2.06-4.34-4.57-4.34z"
                                    />
                                    <path
                                      fill="#BDBDBD"
                                      d="M45 50.26H19V13h26z"
                                    />
                                    <g>
                                      <path d="M20 19h24v23.96H20z" />
                                      <path
                                        fill="#F7F7F7"
                                        d="M39 23h-1v-2h-2v2h-8v-2h-2v2h-1c-1.11 0-1.99.9-1.99 1.99L23 38.97a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2V24.99a2 2 0 0 0-2-2z"
                                      />
                                      <path
                                        fill="#707070"
                                        d="M29.11 28.04h-2v2h2zM29.11 32.11h-2v2h2zM33.11 28.04h-2v2h2zM33.11 32.11h-2v2h2zM37.11 28.04h-2v2h2zM37.11 32.11h-2v2h2z"
                                      />
                                    </g>
                                  </g>
                                </svg>
                              </div>
                              <div className="agreement-next-item-text">
                                Manage your property from your dashboard
                              </div>
                            </li>
                            <li>
                              <div className="agreement-next-item-icon">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width={64}
                                  height={64}
                                >
                                  <g fill="none" fillRule="evenodd">
                                    <path d="M0 0h64v64H0z" />
                                    <g transform="translate(10 12)">
                                      <rect
                                        width={40}
                                        height="25.37"
                                        x="2.93"
                                        y="3.9"
                                        fill="#BDBDBD"
                                        rx={5}
                                      />
                                      <rect
                                        width="26.34"
                                        height="4.88"
                                        x="16.59"
                                        y="33.17"
                                        fill="#707070"
                                        rx="2.44"
                                      />
                                      <rect
                                        width="11.71"
                                        height="4.88"
                                        x="2.93"
                                        y="33.17"
                                        fill="#707070"
                                        rx="2.44"
                                      />
                                    </g>
                                    <g fill="#FFF" fillRule="nonzero">
                                      <path d="M33.42 20.78c-6.75 0-9.76 7.32-9.76 7.32s3.2 7.31 9.76 7.31c6.88 0 9.75-7.31 9.75-7.31s-2.87-7.32-9.75-7.32zm-.03 12.12a4.74 4.74 0 0 1-4.72-4.76 4.74 4.74 0 0 1 4.72-4.76c2.61 0 4.72 2.13 4.72 4.76a4.74 4.74 0 0 1-4.72 4.76z" />
                                      <path d="M33.42 25.66c-.15 0-.29.01-.43.04.57.14.96.65.96 1.24a1.28 1.28 0 1 1-2.53-.24 2.44 2.44 0 1 0 2-1.04z" />
                                    </g>
                                  </g>
                                </svg>
                              </div>
                              <div className="agreement-next-item-text">
                                Get bookings and make money from guests browsing
                                our site
                              </div>
                            </li>
                            <li>
                              <div className="agreement-next-item-icon">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width={64}
                                  height={64}
                                >
                                  <g fill="none" fillRule="evenodd">
                                    <path d="M0 0h64v64H0z" />
                                    <path
                                      fill="#BDBDBD"
                                      fillRule="nonzero"
                                      d="M36.29 12.04h-1.73V8.7H31.1v3.34H17.26V8.7h-3.47v3.34h-1.73a3.39 3.39 0 0 0-3.44 3.34l-.02 23.4a3.4 3.4 0 0 0 3.46 3.35H36.3c1.9 0 3.46-1.5 3.46-3.35v-23.4a3.41 3.41 0 0 0-3.46-3.34z"
                                    />
                                    <path
                                      fill="#FFF"
                                      d="M23.44 28.62l-1.86-5.37-1.86 5.37h3.72zm5.18 5.73H26c-.38 0-.67-.06-.88-.18-.21-.12-.37-.35-.49-.68l-.73-2.1v-.05h-4.64l-.73 2.15c-.13.33-.3.56-.5.68s-.5.18-.87.18h-2.62l.78-2.03.1-.24.45-1.21 3.4-8.96.58-1.65c.11-.32.28-.54.49-.66.2-.12.5-.18.87-.18h.98c.36 0 .63.07.82.2.19.14.34.35.45.64l5.16 14.1zM30 32.51a2.05 2.05 0 0 1 1.23-1.87 2.04 2.04 0 0 1 2.86 1.87c0 .58-.2 1.07-.6 1.46-.39.4-.88.6-1.45.6a1.96 1.96 0 0 1-1.44-.6 2.05 2.05 0 0 1-.6-1.46z"
                                    />
                                    <g>
                                      <path
                                        fill="#707070"
                                        fillRule="nonzero"
                                        d="M51.35 25.21h-1.73v-3.34h-3.46v3.34H32.3v-3.34h-3.46v3.34h-1.73a3.39 3.39 0 0 0-3.44 3.35l-.02 23.4a3.4 3.4 0 0 0 3.46 3.34h24.23c1.9 0 3.46-1.5 3.46-3.34v-23.4a3.41 3.41 0 0 0-3.46-3.35z"
                                      />
                                      <path
                                        fill="#FFF"
                                        d="M45.18 45.73c0-.5.17-.92.53-1.27.35-.35.77-.52 1.26-.52.5 0 .91.17 1.27.52.35.35.52.77.52 1.27s-.17.92-.52 1.27c-.36.35-.78.53-1.27.53s-.91-.18-1.26-.53a1.73 1.73 0 0 1-.53-1.27zm-3.2-5.98c.55.3.98.74 1.3 1.32.3.58.46 1.22.46 1.93 0 .67-.13 1.27-.37 1.8-.25.54-.6 1-1.04 1.37-.44.38-.99.67-1.62.87-.64.2-1.35.31-2.14.31h-5.16V34.03c0-.42.15-.78.44-1.08.3-.3.66-.46 1.08-.48h3.58c.71 0 1.35.1 1.92.27a4.3 4.3 0 0 1 1.46.78c.4.34.72.75.93 1.22.22.48.33 1 .33 1.6 0 .76-.15 1.37-.44 1.83-.3.45-.56.78-.78.98l-.4.33.46.27zm-5.7-1.27h2.25c.64 0 1.08-.2 1.34-.6.25-.39.38-.8.38-1.22 0-.55-.16-.98-.47-1.29-.31-.3-.75-.46-1.3-.46h-1.27c-.33.02-.56.12-.71.29-.15.17-.22.43-.22.77v2.51zm2.5 6.36c.62 0 1.1-.18 1.46-.54.36-.36.54-.86.55-1.5 0-.62-.2-1.11-.55-1.47a1.98 1.98 0 0 0-1.46-.54h-1.7c-.28.04-.48.15-.6.31-.14.17-.2.41-.2.74v3h2.5z"
                                      />
                                    </g>
                                  </g>
                                </svg>
                              </div>
                              <div className="agreement-next-item-text">
                                Stay on top of bookings from all the sites you
                                use by syncing your calendar
                              </div>
                            </li>
                          </ul>
                          <div className="spacing--4x">
                            <div
                              id="automation_id_termsAccepted_label"
                              className="a53cbfa6de ac9267e216 d8eb520c4e"
                            >
                              <input
                                id=":r1k:"
                                className="f0b6754c38"
                                type="checkbox"
                                name="termsAccepted"
                                onChange={() => setcheck1(!check1)}
                              />
                              <label
                                htmlFor=":r1k:"
                                className="aca0ade214 aaf30230d9 c2931f4182 d79e71457a bd597ff2d8"
                              >
                                <span className="ff5328eb35" />
                                <span className="ef785aa7f4">
                                  <span
                                    className="fcd9eec8fb b27b51da7f bf9a32efa5"
                                    aria-hidden="true"
                                  >
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      viewBox="0 0 128 128"
                                    >
                                      <path d="M56.33 102a6 6 0 0 1-4.24-1.75L19.27 67.54A6.014 6.014 0 1 1 27.74 59l27.94 27.88 44-58.49a6 6 0 1 1 9.58 7.22l-48.17 64a5.998 5.998 0 0 1-4.34 2.39z" />
                                    </svg>
                                  </span>
                                </span>
                                <span className="aaee4e7cd3 e7a57abb1e">
                                  <div className="a53cbfa6de e6208ee469">
                                    I certify that this is a legitimate
                                    accommodation business with all necessary
                                    licenses and permits, which can be shown
                                    upon first request. Booking-Oda.com B.V.
                                    reserves the right to verify and investigate
                                    any details provided in this registration.
                                  </div>
                                </span>
                              </label>
                            </div>
                          </div>
                          <div className>
                            <div
                              id="automation_id_agreementAccepted_label"
                              className="a53cbfa6de ac9267e216 d8eb520c4e"
                            >
                              <input
                                id=":r1l:"
                                className="f0b6754c38"
                                type="checkbox"
                                name="agreementAccepted"
                                onChange={() => setcheck2(!check2)}
                              />
                              <label
                                htmlFor=":r1l:"
                                className="aca0ade214 aaf30230d9 c2931f4182 d79e71457a bd597ff2d8"
                              >
                                <span className="ff5328eb35" />
                                <span className="ef785aa7f4">
                                  <span
                                    className="fcd9eec8fb b27b51da7f bf9a32efa5"
                                    aria-hidden="true"
                                  >
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      viewBox="0 0 128 128"
                                    >
                                      <path d="M56.33 102a6 6 0 0 1-4.24-1.75L19.27 67.54A6.014 6.014 0 1 1 27.74 59l27.94 27.88 44-58.49a6 6 0 1 1 9.58 7.22l-48.17 64a5.998 5.998 0 0 1-4.34 2.39z" />
                                    </svg>
                                  </span>
                                </span>
                                <span className="aaee4e7cd3 e7a57abb1e">
                                  <div className="a53cbfa6de e6208ee469">
                                    <span>
                                      I have read, accepted, and agreed to the{" "}
                                      <a
                                        id="show_ast_link"
                                        name="gdt-link"
                                        target="_blank"
                                        href=""
                                      >
                                        General Delivery Terms
                                      </a>
                                      .
                                    </span>
                                  </div>
                                </span>
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="form-cta">
                    <div className="form-buttons-container" onClick={Register}>
                      <div className="form-buttons-container--primary agreement-primary-btn">
                        <button
                          data-testid="FormButtonPrimary-enabled"
                          type="button"
                          className="a83ed08757 c21c56c305 a4c1805887 f671049264 d2529514af c082d89982"
                        >
                          <span className="e4adce92df">
                            Complete registration
                          </span>
                        </button>
                      </div>
                    </div>
                    <div>
                      {/* <button
                        type="button"
                        onClick={() => window.location.assign("/property")}
                        className="a83ed08757 c21c56c305 f38b6daa18 ab98298258 d2529514af bb803d8689 btn-not-ready automation_class_agreement_not_ready"
                      >
                        <span className="e4adce92df">
                          Complete registration and open later
                        </span>
                      </button> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            id="automation_id_flow_Segmentation_Flow"
            style={{ display: "none" }}
          ></div>
          <div></div>
        </div>
      </div>
      <img
        alt=""
        height="1"
        width="1"
        style={{ display: "none" }}
        src="https://booking.com/track_join_app_landing?progress_status=2"
      />
      <Modal
        show={successshow}
        onHide={handlesuccess}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton onClick={handlesuccessClose}></Modal.Header>
        <Modal.Body>
          <div className="text-center">
            <img src={Success} style={{ marginTop: "-12px" }} width="100px" />

            <p>
              Successfully added. It will take 2 to 3 days to be available for
              the user.
            </p>
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        show={errorshow}
        onHide={handleerror}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton onClick={handleerrorClose}></Modal.Header>
        <Modal.Body>
          <div className="text-center">
            <img src={Error} style={{ marginTop: "-12px" }} width="100px" />

            <p>Error Occured</p>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default HotelCompleteregistration;
