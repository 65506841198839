import { FaClock, FaLongArrowAltRight } from "react-icons/fa";
import "./interhome.css";

import { useEffect, useState } from "react";
import { GrMapLocation } from "react-icons/gr";
import Navbar from "../navbar/Navbar";
import Footer from "../footer/Footer";
import { Carousel } from "react-bootstrap";
import axios from "axios";

import parse from "html-react-parser";
import { Link } from "react-router-dom";


export const HomeInter = () => {
  const [places, setPlaces] = useState([]);
 
 
  const getinternational = async () => {
    let res = await axios.get(
      "https://bookingoda.com/api/admin/getinternational"
    );
    if (res.status === 200) {
      //////console.log(res.data);
      setPlaces(res.data.getpackages.filter((item)=>
          item.blockstatus !== true
      ));
    
    }
  };
  useEffect(() => {
    getinternational();
  }, []);
//   const parsedDescription = parse(data?.PlaceDescription)?.toString();
// const truncatedDescription = parsedDescription.slice(0, 300);
const sanitizeHTML = (html) => {
  const doc = new DOMParser().parseFromString(html, 'text/html');
  return doc.body.textContent || '';
};

  return (
    <>
<Navbar/>
      <div>
  
     
      <Carousel style={{position:'relative'}}>
        {places?.slice(0,4).map((data) => (<Carousel.Item>
         <div className="swiper-slide single-hero-slide swiper-slide-prev" data-swiper-slide-index={0} role="group" aria-label="1 / 3" style={{width: '1263px'}}>
        <div className="container">
          <div className="row align-items-center" style={{marginTop:"20px"}}>
            <div className="col-lg-6">
              <div className="hero-content">
                <h5 className="featured-location"> <GrMapLocation /> {"    "}{data.city},{data.state},{data.country}
                </h5>
                <h2 className="hero-title">Most
                  <span>Attractive</span> Travel Places
                </h2>
                <h3 className="featured-price">Rs.{data.tripcost.toFixed(2)} /<span>Person</span></h3>
                <p dangerouslySetInnerHTML={{ __html: sanitizeHTML(data?.PlaceDescription)?.slice(0, 300) + '...' }} />
                <div className="hero-btns">
                  <Link to={`/HomeInternationalDetails/${data._id}`}>  <a className="button-fill-round">Book Now</a></Link>
                  <Link to={`/HomeInternationalDetails/${data._id}`}> <a  className="button-fill-round">View Details</a></Link>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="hero-image-group">
                <img src={`https://bookingoda.com/image/${data.GalleryImageCulture[0]?.imageculture}`} alt="" className="img-fluid" style={{width:"600px",height:"330px"}} />
              </div>
            </div>
          </div>
        </div>
      </div></Carousel.Item>))}
    
      </Carousel>
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-8">
            <div className="section-head-alpha">
              <h2>Popular Tour Package</h2>
              <p>Travelling is better with friends. Let us take you places! We guarantee unique travel packages and exclusive services.</p>
            </div>
          </div>
          <div className="col-lg-4">
            <div className="package-btnint text-lg-end">
              <a href="/HomeInternationalList" className="button-fill-primary">View All Tour</a>
            </div>
          </div>
        </div>
        
        <div className="row g-4">
        {places?.slice(0,6).map((data) => (  
        <div className="col-lg-4 col-md-6">
            <div className="package-card-alpha">
              <div className="package-thumb">
                <a ><img src={`https://bookingoda.com/image/${data.GalleryImageCulture[0]?.imageculture}`} alt="" style={{height:"250px"}}/></a>
                <p className="card-lavel">
                  <FaClock /> <span>{data.tripduration}</span>
                </p>
              </div>
              <div className="package-card-body">
                <h3 className="p-card-title"><a>{data.Destinationsubtitle.slice(0,100)}{"..."}</a></h3>
                <div className="p-card-bottom">
                  <div className="book-btnint">
                    <Link to={`/HomeInternationalDetails/${data._id}`}>  Book Now <FaLongArrowAltRight /></Link>
                  </div>
                  <div className="p-card-info">
                    <span>From</span>
                    <h6>Rs.{data.tripcost} <span>Per Person</span></h6>
                  </div>
                </div>
              </div>
            </div>
          </div>))}
         
      </div> 
      </div>
      </div>
      <Footer/>
    </>
  );
};
