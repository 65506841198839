import React, { useEffect, useState } from "react";
import "./property.css";
import Apartment from "../../assets/apartments.png";
import Apartments from "../../assets/buildings.png";

import Homestay from "../../assets/homestay.png";
import Hotel from "../../assets/hotel.png";
import VendorHeader from "./Vendorheader";
import { useLocation, useNavigate } from "react-router-dom";

const HomeRoomname = () => {
  const { state } = useLocation();

  ////console.log(state,"gfgf");
  const [RoomName, setRoomName] = useState(""); // State to manage checked items
  const history = useNavigate();

  const HandleClick = () => {
    if (selectedOptions.length < 1) {
      alert("Please select room name");
   
  
 
  } else{
    history("/homeroomprice",{
      state: 
       
        state,
      
    },sessionStorage.setItem("RoomName", JSON.stringify(selectedOptions)));}
  };
  useEffect(() => {
    const storedRoomName = sessionStorage.getItem('RoomName');
    if (storedRoomName) {
      setSelectedOptions(JSON.parse(storedRoomName));
    }
  }, []);
  const [options, setOptions] = useState([
    {
      id: 1,
      label: "Double Room",
      value: "Double Room",
    },
    {
      id: 2,
      label: "Double Room with Private Bathroom",
      value: "Double Room with Private Bathroom",
    },
   
  
    // Add more objects as needed
  ]);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [showOptions, setShowOptions] = useState(false);
  const [newOption, setNewOption] = useState("");

  // const handleOptionClick = (option) => {
  //   const isSelected = selectedOptions.some(
  //     (selectedOption) => selectedOption.id === option.id
  //   );
  //   if (isSelected) {
  //     setSelectedOptions(
  //       selectedOptions.filter(
  //         (selectedOption) => selectedOption.id !== option.id
  //       )
  //     );
  //   } else {
  //     setSelectedOptions([...selectedOptions, option]);
  //   }
  // };

  // const handleAddOption = () => {
  //   if (newOption.trim() !== "") {
  //     const optionId = options.length + 1;
  //     const newOptionObj = {
  //       id: optionId,
  //       label: newOption,
  //       value: newOption.toLowerCase().replace(/\s/g, ""),
  //       extraData: "Additional Info for " + newOption,
  //     };
  //     setOptions([...options, newOptionObj]);
  //     setNewOption("");
  //   }
  // };
  const handleOptionClick = (option) => {
    // Clear all selected options
    setSelectedOptions([]);
  
    // Add the clicked option
    setSelectedOptions([option]);
    setShowOptions(false)
  };
  
  const handleAddOption = () => {
    if (newOption.trim() !== "") {
      // Clear all selected options
      setSelectedOptions([]);
  
      const optionId = options.length + 1;
      const newOptionObj = {
        id: optionId,
        label: newOption,
        value: newOption.toLowerCase().replace(/\s/g, ""),
        extraData: "Additional Info for " + newOption,
      };
      setOptions([...options, newOptionObj]);
      setNewOption("");
    }
  };
  return (
    <div
      data-capla-component="b-registration-joinapp15min-fe/"
      data-capla-namespace="b-registration-joinapp15min-feaYGUODfA"
    >
      <div id="app-container">
        <div class="error-popup-container"></div>
        <div class="application">
        <VendorHeader />
          <div class="error-popup-container"></div>
          <div className="page-container">
            <div className="screens screens-animation-direction-forward">
              <div
                className="height-100 screen-change-enter-done"
                id="automation_id_screen_container_UnitName"
              >
                <div className="ms-container">
                  <div className="form-container">
                    <div className="f2cf178bcd screen-main-header d991e1216a">
                      <div className="f6431b446c e6208ee469 d0caee4251 screen-main-header__title">
                        What’s the name of this room?
                      </div>
                    </div>
                    <div className="ffb9c3d6a3 wide-container min-height">
                      <div className="form-group">
                        <div className="spacing--8x">
                          This is the name that guests will see on your property
                          page. Choose a name that most accurately describes
                          this room.
                        </div>
                        <div className="a53cbfa6de ac9267e216">
                          <label
                            htmlFor=":ra2:"
                            className="a53cbfa6de e6208ee469 f555271986"
                          >
                            <span>
                              <span className="ccb65902b2">Room name</span>
                            </span>
                          </label>
                          <div className="a53cbfa6de ac9267e216">
                        <div className="e000754250">
                              <input
                                type="text"
                                value={newOption}
                                onChange={(e) => setNewOption(e.target.value)}
                                placeholder="Type to add or search..."
                                class="eb46370fe1"
                                onClick={() => setShowOptions(!showOptions)}
                              />
                            </div>
                            <button
                              style={{
                                marginTop: "10px",
                                padding: "10px 20px",
                                fontSize: "16px",
                                backgroundColor: "#70bbe9",
                                color: "white",
                                border: "none",
                                borderRadius: "5px",
                                cursor: "pointer",
                                transition: "background-color 0.3s",
                              }}
                              onClick={handleAddOption}
                            >
                              Add
                            </button>
                            {showOptions && (
                              <div
                              className="ebf4591c8e"
                              >   {options.map((option) => (     
                               <div className="cabad3c686 aff44befa2">
                            {/* <select
                              className="ebf4591c8e"
                              name="room_type"
                              data-testid="InputRadioWithOptions-inputSelect-room_type"
                              id=":r63:"
                              // value={unit}
                              // onChange={(e) => setunit(e.target.value)}
                              onClick={() => handleOptionClick(option)}
                            > */}
                            <div
                                    
                                    key={option.id}
                                    style={{
                                      padding: "10px",
                                      cursor: "pointer",
                                      color: "black",
                                      backgroundColor: selectedOptions.some(
                                        (selectedOption) =>
                                          selectedOption.id === option.id
                                      )
                                        ? "#ddd"
                                        : "transparent",
                                    }}
                                    onClick={() => handleOptionClick(option)}
                                  >  
                              <option value="Bed in Dorm" data-key={26}>
                              {option.label}
                              </option>
                              </div> 
                         
                          </div>))}</div>)}
                          {selectedOptions && (
                              <div className="selected-option">
                                Selected Rooms:{" "}
                                {selectedOptions
                                  .map((option) => option.label)
                                  .join(", ")}
                              </div>
                            )}
                        </div>
                        </div>
                      </div>
                      <div className="page-tooltip__container undefined">
                        <div className />
                      </div>
                    </div>
                  </div>
                  <div className="form-cta">
                    <div>
                      <div className="form-buttons-container">
                           <div className="form-buttons-container--secondary" onClick={()=>window.history.back()}>
                          <button
                            aria-label="Back to previous step"
                            data-testid="FormButtonSecondary"
                            type="button"
                            className="a83ed08757 c21c56c305 bf0537ecb5 d691166b09 f671049264 d2529514af af7297d90d"
                          >
                            <span className="eedba9e88a">
                              <span
                                className="fcd9eec8fb c2cc050fb8"
                                aria-hidden="true"
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 24 24"
                                  data-rtl-flip="true"
                                >
                                  <path d="M15.0871 19.2361C14.9677 19.2367 14.8495 19.2134 14.7392 19.1676C14.629 19.1219 14.5289 19.0546 14.4451 18.9696L8.38799 12.9125C8.26332 12.7891 8.16475 12.6418 8.09815 12.4795C8.03156 12.3172 7.99829 12.1431 8.00034 11.9676C7.99246 11.6171 8.12303 11.2776 8.36376 11.0227L14.4208 4.96566C14.5912 4.79552 14.8221 4.69995 15.0629 4.69995C15.3037 4.69995 15.5346 4.79552 15.7049 4.96566C15.7907 5.04918 15.8588 5.14902 15.9053 5.25929C15.9518 5.36957 15.9758 5.48803 15.9758 5.60771C15.9758 5.72739 15.9518 5.84586 15.9053 5.95613C15.8588 6.0664 15.7907 6.16624 15.7049 6.24976L10.0113 11.9676L15.7292 17.6855C15.8149 17.769 15.883 17.8689 15.9295 17.9792C15.976 18.0894 16 18.2079 16 18.3276C16 18.4473 15.976 18.5657 15.9295 18.676C15.883 18.7863 15.8149 18.8861 15.7292 18.9696C15.6457 19.0551 15.5458 19.1228 15.4354 19.1686C15.3251 19.2144 15.2066 19.2374 15.0871 19.2361Z" />
                                </svg>
                              </span>
                            </span>
                          </button>
                        </div>
                        <div className="form-buttons-container--primary" onClick={HandleClick}>
                          <button
                            aria-label="Continue"
                            data-testid="FormButtonPrimary-enabled"
                            type="button"
                            className="a83ed08757 c21c56c305 a4c1805887 f671049264 d2529514af c082d89982"
                          >
                            <span className="e4adce92df">Continue</span>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            id="automation_id_flow_Segmentation_Flow"
            style={{ display: "none" }}
          ></div>
          <div></div>
        </div>
      </div>
      <img
        alt=""
        height="1"
        width="1"
        style={{ display: "none" }}
        src="https://booking.com/track_join_app_landing?progress_status=2"
      />
    </div>
  );
};

export default HomeRoomname;
