import React from "react";
import { useState } from "react";
import "./profile.css";
import Logo from "../../assets/booking.jpeg";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IoMdLogOut } from "react-icons/io";
import { MdLibraryAdd } from "react-icons/md";
import { IoListCircleSharp } from "react-icons/io5";
import { FaBuildingUser } from "react-icons/fa6";
import {
  Button,
  Container,
  FloatingLabel,
  Form,
  Modal,
  Table,
} from "react-bootstrap";
import {
  faCalendarDays,
  faCar,
  faPerson,
  faPlane,
  faTaxi,
  faHouse,
  faHotel,
  faEarthAmericas,
  faGroupArrowsRotate,
} from "@fortawesome/free-solid-svg-icons";
import { FaAngleRight, FaUser, FaHotel, FaUmbrellaBeach } from "react-icons/fa";
import { BsFillTaxiFrontFill } from "react-icons/bs";

import { AiOutlineRight } from "react-icons/ai";

import {
  BiEdit,
  BiLogOutCircle,
  BiSolidHomeSmile,
  BiUserPin,
} from "react-icons/bi";
import HikingIcon from "@mui/icons-material/Hiking";
import axios from "axios";
import { useEffect } from "react";
import AddHomeWorkIcon from "@mui/icons-material/AddHomeWork";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import { useNavigate } from "react-router-dom";
import Navbar from "../navbar/Navbar";
import moment from "moment";
import { IoPeopleCircle } from "react-icons/io5";

export const Profile = () => {
  const navigate = useNavigate();
  const [data, setdata] = useState([]);
  const [data1, setdata1] = useState([]);
  const [data2, setdata2] = useState([]);
  const [data3, setdata3] = useState([]);
  const handleKeyDown = (e) => {
    // Prevent the input of the minus sign
    if (e.key === "-" || e.key === "e" || e.key === "E") {
      e.preventDefault();
    }
  };
  const getapartment = async () => {
    let res = await axios.get(
      "https://bookingoda.com/api/admin/getallApartment"
    );
    if (res.status === 200) {
      //////console.log(res.data);
      setdata(res.data.Apartment.filter((item) => item.status === "Approved"));
    }
  };
  const getalternative = async () => {
    let res = await axios.get(
      "https://bookingoda.com/api/admin/getallalternative"
    );
    if (res.status === 200) {
      //////console.log(res.data);
      setdata2(
        res.data.alternative.filter((item) => item.status === "Approved")
      );
    }
  };
  const gethotel = async () => {
    let res = await axios.get("https://bookingoda.com/api/admin/getallhotel");
    if (res.status === 200) {
      //////console.log(res.data);
      setdata1(res.data.hotel.filter((item) => item.status === "Approved"));
    }
  };
  const gethomestay = async () => {
    let res = await axios.get("https://bookingoda.com/api/admin/getallhome");
    if (res.status === 200) {
      //////console.log(res.data);
      setdata3(res.data.home.filter((item) => item.status === "Approved"));
    }
  };

  useEffect(() => {
    getapartment();
    gethotel();
    getalternative();
    gethomestay();
  }, []);
  const data4 = [...data, ...data1, ...data2, ...data3];
  const [Taxi, setTaxi] = useState(false);
  const [Hotel, setHotel] = useState(true);
  const [Event, setEvent] = useState(false);
  const [Profile, setProfile] = useState(false);
  const [Tour, setTour] = useState(false);
  const [Vac, setVac] = useState(false);

  const [acc, setAcc] = useState(false);
  const [acc1, setAcc1] = useState(true);
  const [acc2, setAcc2] = useState("");
  const [acc3, setAcc3] = useState("");
  const [acc4, setAcc4] = useState("");
  const [acc5, setAcc5] = useState("");
  const [show, setShow] = useState();
  const [show1, setShow1] = useState();
  const [show2, setShow2] = useState();
  const [show4, setShow4] = useState();

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleClose1 = () => setShow1(false);
  const [ModalData, setModalData] = useState({});
  const [ModalData1, setModalData1] = useState({});
  const [matchedItem1, setmatchedItem1] = useState({});

  const handleShow1 = () => setShow1(true);

  const handleClose2 = () => setShow2(false);
  const handleShow2 = () => setShow2(true);
  const handleClose4 = () => setShow4(false);
  const handleShow4 = () => setShow4(true);
  const startDate = new Date(ModalData1?.arrivaldate);
  const endDate = new Date(ModalData1?.departuredate);
  const timeDifference = endDate - startDate;

  // Convert the difference to days
  const daysDifference = timeDifference / (1000 * 60 * 60 * 24);
  const [View, setView] = useState({});
  ////console.log("View", View);

  const Customer = JSON.parse(sessionStorage.getItem("user"));
  const Customerlogout = () => {
    sessionStorage.removeItem("user");
    window.location.assign("/bookingoda");
  };

  const [updateprofile, setupdateprofile] = useState(true);

  const [Email, setEmail] = useState("");
  const [Password, setPassword] = useState("");
  const [Cpassword, setCpassword] = useState("");
  const [Fname, setFname] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showPassword1, setShowPassword1] = useState(false);
  const [Mobile, setMobile] = useState("");
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  const togglePasswordVisibility1 = () => {
    setShowPassword1(!showPassword1);
  };

  // Update Profile
  var validRegex =
    /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
  var mobilevalid = /^[6-9][0-9]{9}$/;
  var f_Name = /^[a-zA-Z][a-zA-Z]/;

  const updatedUser = async () => {
    if (Password !== Cpassword) {
      return alert("Password and Confirm Password does not match");
    }
    try {
      const config = {
        url: "/user/editUser",
        baseURL: "https://bookingoda.com/api",
        method: "put",
        headers: { "Content-Type": "application/json" },
        data: {
          userId: Customer?._id,
          email: Email ? Email : Customer?.email,
          password: Password,
          name: Fname ? Fname : Customer?.name,
          cpassword: Cpassword,
          mobile: Mobile ? Mobile : Customer?.mobile,
        },
      };
      await axios(config).then((res) => {
        if (res.status == 200) {
          sessionStorage.setItem("user", JSON.stringify(res.data.success));
          alert("Successfully Updated");
          sessionStorage.removeItem("user");

          window.location.assign("Login");
        }
      });
    } catch (error) {}
  };

  // Prfoile update

  const formdata = new FormData();

  // Get Tour Packages
  const [GetTourpackages, setGetTourpackages] = useState([]);
  const gettourpackagesbyid = async () => {
    try {
      let res = await axios.get(
        "https://bookingoda.com/api/admin/getbookedtourpackages"
      );
      if (res.status === 200) {
        setGetTourpackages(
          res.data.bookedtourpackages.filter(
            (data) => data.userId?._id === Customer._id
          )
        );
      }
    } catch (error) {
      ////console.log(error);
      setGetTourpackages([]);
    }
  };
  const [Getvacationpackages, setGetvacationpackages] = useState([]);
  const getvacationpackagesbyid = async () => {
    try {
      let res = await axios.get(
        "https://bookingoda.com/api/admin/getbookvacationpackages"
      );
      if (res.status === 200) {
        setGetvacationpackages(
          res.data.bookedvacationpackages.filter(
            (data) => data.userId?._id === Customer._id
          )
        );
      }
    } catch (error) {
      ////console.log(error);
      setGetvacationpackages([]);
    }
  };
  const [car, setcar] = useState([]);
  const getcar = async () => {
    try {
      let res = await axios.get(
        "https://bookingoda.com/api/admin/getbookedcars"
      );
      if (res.status === 200) {
        setcar(
          res.data.bookedcars.filter(
            (data) => data.userId?._id === Customer._id
          )
        );
      }
    } catch (error) {
      ////console.log(error);
    }
  };
  const [international, setinternational] = useState([]);
  const getinternational = async () => {
    try {
      let res = await axios.get(
        "https://bookingoda.com/api/admin/getbookinternationalpackages"
      );
      if (res.status === 200) {
        setinternational(
          res.data.bookedtourpackages.filter(
            (data) => data.userId?._id === Customer._id
          )
        );
      }
    } catch (error) {
      ////console.log(error);
    }
  };
  const [stay, setstay] = useState([]);
  const getstay = async () => {
    try {
      let res = await axios.get(
        "https://bookingoda.com/api/admin/getbookstays"
      );
      if (res.status === 200) {
        setstay(
          res.data.bookedstays.filter(
            (data) => data.userId?._id === Customer._id
          )
        );
      }
    } catch (error) {
      ////console.log(error);
    }
  };
  const PrintInvoice = (Item) => {
    sessionStorage.setItem("InvoiceItem", JSON.stringify(Item));

    window.location.assign("/stayinvoice");
  };
  const PrintInvoice1 = (Item) => {
    sessionStorage.setItem("InvoiceItem", JSON.stringify(Item));

    window.location.assign("/internationalinvoice");
  };
  const PrintInvoice2 = (Item) => {
    sessionStorage.setItem("InvoiceItem", JSON.stringify(Item));

    window.location.assign("/pilgriminvoice");
  };

  useEffect(() => {
    gettourpackagesbyid();
    getvacationpackagesbyid();
    getcar();
    getinternational();
    getstay();
  }, []);
  ////console.log("GetTourpackages", GetTourpackages);
  const user = JSON.parse(sessionStorage.getItem("user"));

  return (
    <div>
      <Navbar />

      <Container fluid style={{ marginTop: "140px" }}>
        <div className="row mt-3 justify-content-between">
          <div className="col-lg-3">
            <p className="profile-name">Dashboard</p>

            <div className=" d-flex flex-column  mb-5 mt-5">
              <Button
                className="booking-btn d-flex align-items-center justify-content-evenly"
                onClick={() => {
                  setTaxi(false);
                  setHotel(true);
                  setEvent(false);
                  setProfile(false);
                  setVac(false);

                  setAcc(false);
                  setAcc1(true);
                  setAcc2(false);
                  setAcc3(false);
                  setAcc4(false);
                  setAcc5(false);
                }}
                style={{
                  backgroundColor: acc1 ? "green" : "#0f7cba",
                  color: "white",
                  width: "300px",
                  border: acc1 ? "3px solid green" : "",
                }}
              >
                <FaHotel
                  style={{
                    marginLeft: "0px",
                    color: "white",
                    fontSize: "20px",
                  }}
                />{" "}
                Stays Booking{" "}
                <AiOutlineRight
                  style={{
                    marginRight: "0px",
                    color: "white",
                    fontSize: "20px",
                  }}
                />
              </Button>

              <Button
                className="booking-btn d-flex align-items-center justify-content-evenly"
                onClick={() => {
                  setTaxi(false);
                  setHotel(false);
                  setEvent(true);
                  setProfile(false);
                  setVac(false);

                  setAcc(false);
                  setAcc1(false);
                  setAcc2(true);
                  setAcc3(false);
                  setAcc4(false);
                  setAcc5(false);
                }}
                style={{
                  backgroundColor: acc2 ? "green" : "#0f7cba",
                  color: "white",
                  width: "300px",

                  border: acc2 ? "3px solid green" : "",
                }}
              >
                <HikingIcon
                  style={{
                    marginLeft: "0px",
                    color: "white",
                    fontSize: "20px",
                  }}
                />{" "}
                International Holiday{" "}
                <AiOutlineRight
                  style={{
                    marginRight: "0px",
                    color: "white",
                    fontSize: "20px",
                  }}
                />
              </Button>
              <Button
                className="booking-btn d-flex align-items-center justify-content-between "
                onClick={() => {
                  setTaxi(true);
                  setHotel(false);
                  setEvent(false);
                  setProfile(false);
                  setVac(false);

                  setAcc(true);
                  setAcc1(false);
                  setAcc2(false);
                  setAcc3(false);
                  setAcc4(false);
                  setAcc5(false);
                }}
                style={{
                  border: acc ? "3px solid green" : "",
                  backgroundColor: acc ? "green" : "#0f7cba",
                  width: "300px",

                  color: "white",
                }}
              >
                <BsFillTaxiFrontFill
                  style={{
                    marginLeft: "0px",
                    color: "white",
                    fontSize: "20px",
                  }}
                />
                {"   "} Car rental{" "}
                <AiOutlineRight
                  style={{
                    marginRight: "0px",
                    color: "white",
                    fontSize: "20px",
                  }}
                />
              </Button>
              <Button
                className="booking-btn d-flex align-items-center justify-content-evenly"
                onClick={() => {
                  setTaxi(false);
                  setHotel(false);
                  setEvent(false);
                  setProfile(false);
                  setTour(true);
                  setVac(false);

                  setAcc(false);
                  setAcc1(false);
                  setAcc2(false);
                  setAcc3(false);
                  setAcc4(true);
                  setAcc5(false);
                }}
                style={{
                  backgroundColor: acc4 ? "green" : "#0f7cba",
                  color: "white",
                  width: "300px",

                  border: acc4 ? "3px solid green" : "",
                }}
              >
                <AddHomeWorkIcon
                  style={{
                    marginLeft: "0px",
                    color: "white",
                    fontSize: "20px",
                  }}
                />{" "}
                Pilgrim Vacation{" "}
                <AiOutlineRight
                  style={{
                    marginRight: "0px",
                    color: "white",
                    fontSize: "20px",
                  }}
                />
              </Button>
              <Button
                className="booking-btn d-flex align-items-center justify-content-evenly"
                onClick={() => {
                  setTaxi(false);
                  setHotel(false);
                  setEvent(false);
                  setProfile(false);
                  setTour(false);
                  setVac(true);

                  setAcc(false);
                  setAcc1(false);
                  setAcc2(false);
                  setAcc3(false);
                  setAcc4(false);
                  setAcc5(true);
                }}
                style={{
                  backgroundColor: acc5 ? "green" : "#0f7cba",
                  color: "white",
                  width: "300px",

                  border: acc4 ? "3px solid green" : "",
                }}
              >
                <BiSolidHomeSmile
                  style={{
                    marginLeft: "0px",
                    color: "white",
                    fontSize: "20px",
                  }}
                />{" "}
                Vacation Homes{" "}
                <AiOutlineRight
                  style={{
                    marginRight: "0px",
                    color: "white",
                    fontSize: "20px",
                  }}
                />
              </Button>
              <Button
                className="booking-btn d-flex align-items-center justify-content-evenly"
                onClick={() => {
                  setTaxi(false);
                  setHotel(false);
                  setEvent(false);
                  setProfile(true);
                  setTour(false);
                  setVac(false);

                  setAcc(false);
                  setAcc1(false);
                  setAcc2(false);
                  setAcc3(true);
                  setAcc4(false);
                  setAcc5(false);
                }}
                style={{
                  backgroundColor: acc3 ? "green" : "#0f7cba",
                  color: "white",
                  width: "300px",

                  border: acc3 ? "3px solid green" : "",
                }}
              >
                <BiUserPin
                  style={{
                    marginLeft: "0px",
                    color: "white",
                    fontSize: "20px",
                  }}
                />{" "}
                Profile{" "}
                <AiOutlineRight
                  style={{
                    marginRight: "0px",
                    color: "white",
                    fontSize: "20px",
                  }}
                />
              </Button>

              <Button
                onClick={() => Customerlogout()}
                className="booking-btn d-flex align-items-center justify-content-evenly"
                style={{
                  backgroundColor: "#0f7cba",
                  color: "white",
                  width: "300px",
                }}
              >
                <BiLogOutCircle
                  className="text-danger"
                  style={{ marginLeft: "0px", fontSize: "20px" }}
                />{" "}
                Log Out{" "}
                <AiOutlineRight
                  style={{
                    marginRight: "0px",
                    color: "white",
                    fontSize: "20px",
                  }}
                />
              </Button>
            </div>
          </div>

          <div className="col-lg-9">
            {Taxi ? (
              <>
                <div className="wad_0">
                  <h4>Car rental Booking</h4>
                  <Table responsive>
                    <thead>
                      <tr>
                        <th className="text-secondary">No</th>
                        <th className="text-secondary">Car Name</th>

                        <th className="text-secondary">Journey From</th>
                        <th className="text-secondary">Journey To</th>
                        <th className="text-secondary">Pick Up Date</th>
                        <th className="text-secondary">Drop Off Date</th>
                        <th className="text-secondary">Pick Up Time</th>
                        <th className="text-secondary">Drop Off Time</th>
                        <th className="text-secondary">Address</th>

                        <th className="text-secondary">Driver</th>

                        <th className="text-secondary">Price</th>
                      </tr>
                    </thead>

                    <tbody>
                      {car?.map((item, i) => {
                        return (
                          <tr>
                            <td>BOBC{item?._id.slice(22, 24).toUpperCase()}</td>
                            <td>{item.CarId?.CarName}</td>

                            <td>{item.pickUpLocation}</td>
                            <td>{item.dropOffLocation}</td>
                            <td>{moment(item.pickUp).format("DD/MM/YYYY")}</td>
                            <td>{moment(item.dropOff).format("DD/MM/YYYY")}</td>
                            <td>
                              {moment(item.pickTime, "HH:mm").format("hh:mm A")}
                            </td>
                            <td>
                              {moment(item.dropTime, "HH:mm").format("hh:mm A")}
                            </td>
                            <td>
                              {item.address},{item.city}-{item.zipcode}
                            </td>

                            <td>
                              {item.driver === "false"
                                ? "Self Drive"
                                : "Driver Required"}
                            </td>
                            <td>₹{item.CarId?.Price}/per km</td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>
                </div>
              </>
            ) : (
              <>
                {Hotel ? (
                  <>
                    <div className="wad_0">
                      <h4>Stays Booking</h4>
                      <Table responsive>
                        <thead>
                          <tr>
                            <th className="text-secondary">No</th>
                            <th className="text-secondary">Hotel</th>
                            {/* <th className="text-secondary">Duration</th> */}
                            <th className="text-secondary">Check in Date</th>
                            <th className="text-secondary">Check out Date</th>
                            
                            <th className="text-secondary">Price</th>
                            <th className="text-secondary">Address</th>

                            <th className="text-secondary">Payment</th>
                            <th className="text-secondary">More</th>
                          </tr>
                        </thead>

                        <tbody>
                          {stay?.map((item, i) => {
                            const matchedItem = data4.find(
                              (e) => e._id === item?.packageId[0]
                            );
                            const startDate = new Date(item?.arrivaldate);
                            const endDate = new Date(item?.departuredate);
                            const timeDifference = endDate - startDate;

                            // Convert the difference to days
                            const daysDifference =
                              timeDifference / (1000 * 60 * 60 * 24);
                            ////console.log(daysDifference, "sadfsad");
                            ////console.log(matchedItem, "sadsa");
                            return (
                              <tr>
                                <td>
                                  BOBS{item._id.slice(22, 24).toUpperCase()}
                                </td>
                                <td>{matchedItem?.PropertyName}</td>
                                {/* <td>6days/5nights</td> */}
                                <td>{item.arrivaldate}</td>
                                <td>{item.departuredate}</td>
                                <td>₹{item.amount}</td>
                                <td>{item?.address?.join(", ")}</td>

                                <td>
                                  {item.payment}
                                  {/* <div className="pay-done">{item.payment}</div> */}
                                </td>
                                <td>
                                  <div
                                    className="pay-more"
                                    onClick={() => {
                                      handleShow();
                                      setModalData1(item);
                                      setmatchedItem1(matchedItem);
                                    }}
                                  >
                                    View More
                                  </div>
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </Table>
                    </div>
                  </>
                ) : (
                  <>
                    {Event ? (
                      <>
                        <div className="wad_0">
                          <h4>International Holiday Booking</h4>
                          <Table responsive>
                            <thead>
                              <tr>
                                <th className="text-secondary">No</th>
                                <th className="text-secondary">Activity</th>
                                <th className="text-secondary">Arrival Date</th>
                                <th className="text-secondary">
                                  Departure Date
                                </th>
                                <th className="text-secondary">Address</th>
                                <th className="text-secondary">Price</th>
                                <th className="text-secondary">Payment</th>
                                <th className="text-secondary">More</th>
                              </tr>
                            </thead>

                            <tbody>
                              {international?.map((item, i) => {
                                return (
                                  <tr>
                                    <td>
                                      BOI{item._id.slice(22, 24).toUpperCase()}
                                    </td>
                                    <td>{item.packageId?.DestinationName}</td>
                                    <td>
                                      {moment(item.arrivaldate).format(
                                        "DD/MM/YYYY"
                                      )}
                                    </td>
                                    <td>
                                      {moment(item.departuredate).format(
                                        "DD/MM/YYYY"
                                      )}
                                    </td>
                                    <th style={{ width: "200px" }}>
                                      {item.address?.join(", ")}
                                    </th>
                                    <td>₹{item.packageId?.tripcost}</td>
                                    <td>
                                      {item.payment}
                                      {/* <div className="pay-done">Done</div> */}
                                    </td>
                                    <td>
                                      <div
                                        className="pay-more"
                                        onClick={() => {
                                          handleShow1();
                                          setModalData(item);
                                        }}
                                      >
                                        View More
                                      </div>
                                    </td>
                                  </tr>
                                );
                              })}
                            </tbody>
                          </Table>
                        </div>
                      </>
                    ) : (
                      <>
                        {Profile ? (
                          <>
                            <div className="wad_0">
                              {updateprofile == true ? (
                                <>
                                  <h4>My Profile</h4>
                                  <Table responsive>
                                    <tbody>
                                      <tr>
                                        <td className="fw-bold">Name</td>
                                        <td>:</td>
                                        <td>{Customer?.name}</td>
                                      </tr>
                                      <tr>
                                        <td className="fw-bold">Email</td>
                                        <td>:</td>
                                        <td>{Customer?.email}</td>
                                      </tr>
                                      <tr>
                                        <td className="fw-bold">Phone</td>
                                        <td>:</td>
                                        <td>{Customer?.mobile}</td>
                                      </tr>
                                    </tbody>
                                  </Table>
                                  <div>
                                    <button
                                      onClick={() => {
                                        setupdateprofile(false);
                                      }}
                                      className="pink-btn"
                                    >
                                      Edit Profile
                                    </button>
                                  </div>
                                </>
                              ) : (
                                <>
                                  <h4>Update Profile</h4>
                                  <div>
                                    <div className="row">
                                      <div className="mb-2 col-lg-6">
                                        <label className="fw-bold mb-1">
                                          Enter{" "}
                                          <span className="text-danger">
                                            Your Name
                                          </span>{" "}
                                          :
                                        </label>

                                        <Form.Control
                                          type="text"
                                          placeholder="Name"
                                          onChange={(e) =>
                                            setFname(e.target.value)
                                          }
                                        />
                                      </div>
                                    </div>

                                    <div className="row">
                                      <div className="mb-2 col-lg-6">
                                        <label className="fw-bold mb-1">
                                          Enter{" "}
                                          <span className="text-danger">
                                            Password
                                          </span>{" "}
                                          :
                                        </label>
                                        <div class="input-container">
                                          <Form.Control
                                            type={
                                              showPassword ? "text" : "password"
                                            }
                                            placeholder="Password"
                                            onChange={(e) =>
                                              setPassword(e.target.value)
                                            }
                                          />
                                          <i
                                            class={
                                              showPassword
                                                ? "fas fa-eye-slash"
                                                : "fas fa-eye"
                                            }
                                            style={{ marginRight: "10px" }}
                                            onClick={togglePasswordVisibility}
                                          ></i>
                                        </div>
                                      </div>

                                      <div className="mb-2 col-lg-6">
                                        <label className="fw-bold mb-1">
                                          Confirm{" "}
                                          <span className="text-danger">
                                            Password
                                          </span>{" "}
                                          :
                                        </label>
                                        <div class="input-container">
                                          <Form.Control
                                            type={
                                              showPassword1
                                                ? "text"
                                                : "password"
                                            }
                                            placeholder="Password"
                                            onChange={(e) =>
                                              setCpassword(e.target.value)
                                            }
                                          />
                                          <i
                                            class={
                                              showPassword1
                                                ? "fas fa-eye-slash"
                                                : "fas fa-eye"
                                            }
                                            style={{ marginRight: "10px" }}
                                            onClick={togglePasswordVisibility1}
                                          ></i>
                                        </div>
                                      </div>
                                    </div>

                                    <div className="row">
                                      <div className="mb-2 col-lg-6">
                                        <label className="fw-bold mb-1">
                                          Enter Your
                                          <span className="text-danger">
                                            Mobile Number
                                          </span>{" "}
                                          :
                                        </label>

                                        <Form.Control
                                          type="number"
                                          onKeyDown={handleKeyDown}
                                          placeholder="Number"
                                          onChange={(e) =>
                                            setMobile(e.target.value)
                                          }
                                        />
                                      </div>

                                      <div className="mb-2 col-lg-6">
                                        <label className="fw-bold mb-1">
                                          Enter{" "}
                                          <span className="text-danger">
                                            Email
                                          </span>{" "}
                                          :
                                        </label>
                                        <FloatingLabel
                                          controlId="floatingPassword"
                                          label={Customer?.Email}
                                        >
                                          <Form.Control
                                            type="email"
                                            placeholder="Email"
                                            onChange={(e) =>
                                              setEmail(e.target.value)
                                            }
                                          />
                                        </FloatingLabel>
                                      </div>
                                    </div>

                                    <div className="d-flex gap-2 mb-5">
                                      <Button
                                        variant="danger"
                                        onClick={updatedUser}
                                      >
                                        Save
                                      </Button>
                                      <Button
                                        variant="warning"
                                        onClick={() => {
                                          setupdateprofile(true);
                                        }}
                                      >
                                        Cancel
                                      </Button>
                                    </div>
                                  </div>
                                </>
                              )}
                            </div>
                          </>
                        ) : (
                          <>
                            {Tour ? (
                              <>
                                <div className="wad_0">
                                  <h4>Pilgrim Vacation Booking</h4>
                                  <Table responsive>
                                    <thead>
                                      <tr>
                                        <th className="text-secondary">No</th>
                                        <th className="text-secondary">
                                          Package
                                        </th>
                                        <th className="text-secondary">
                                          Duration
                                        </th>
                                        <th className="text-secondary">
                                          Mobile
                                        </th>
                                        <th className="text-secondary">
                                          Start Date
                                        </th>
                                        <th className="text-secondary">
                                          Address
                                        </th>
                                        <th className="text-secondary">
                                          Price
                                        </th>
                                        <th className="text-secondary">
                                          Payment
                                        </th>
                                        <th className="text-secondary">More</th>
                                      </tr>
                                    </thead>

                                    <tbody>
                                      {GetTourpackages?.map((item, i) => {
                                        return (
                                          <tr>
                                            <td>
                                              BOBP
                                              {item._id
                                                .slice(22, 24)
                                                .toUpperCase()}
                                            </td>
                                            <td>{item?.packageId?.tripname}</td>
                                            <td>
                                              {item?.packageId?.tripduration}{" "}
                                              
                                            </td>
                                            <td>{item?.mobile}</td>
                                            <td>{item?.checkindate}</td>
                                            <td>{item?.address?.join(", ")}</td>
                                            <td>
                                              ₹
                                              {item?.adultprice +
                                                item?.childrenprice +
                                                item?.petsprice}
                                            </td>
                                            <td>
                                              {/* <div className="pay-done">
                                                Done
                                              </div> */}
                                              {item.payment}
                                            </td>
                                            <td>
                                              <div
                                                className="pay-more"
                                                onClick={() => {
                                                  handleShow2();
                                                  setView(item);
                                                }}
                                              >
                                                View More
                                              </div>
                                            </td>
                                          </tr>
                                        );
                                      })}
                                    </tbody>
                                  </Table>
                                </div>
                              </>
                            ) : (
                              <>
                                {Vac ? (
                                  <>
                                    <div className="wad_0">
                                      <h4>Vacation Booking</h4>
                                      <Table responsive>
                                        <thead>
                                          <tr>
                                            <th className="text-secondary">
                                              No
                                            </th>
                                            <th className="text-secondary">
                                              Package
                                            </th>
                                            <th className="text-secondary">
                                              Mobile
                                            </th>
                                            <th className="text-secondary">
                                              Start Date
                                            </th>
                                            <th className="text-secondary">
                                              End Date
                                            </th>
                                            <th className="text-secondary">
                                              Address
                                            </th>
                                            <th className="text-secondary">
                                              Price
                                            </th>
                                            <th className="text-secondary">
                                              Payment
                                            </th>
                                            <th className="text-secondary">
                                              More
                                            </th>
                                          </tr>
                                        </thead>

                                        <tbody>
                                          {Getvacationpackages?.map(
                                            (item, i) => {
                                              return (
                                                <tr>
                                                  <td>
                                                    BOBV
                                                    {item._id
                                                      .slice(22, 24)
                                                      .toUpperCase()}
                                                  </td>
                                                  <td>
                                                    {item?.packageId?.stayname}
                                                  </td>
                                                  <td>
                                                    {item?.mobile}
                                                  </td>
                                                  <td>{item?.checkindate}</td>
                                                  <td>{item?.checkoutdate}</td>
                                                  <td>
                                                    {item?.address?.join(", ")}
                                                  </td>
                                                  <td>
                                                    ₹
                                                    {item?.adultprice +
                                                      item?.childrenprice +
                                                      item?.petsprice}
                                                  </td>
                                                  <td>
                                                    {/* <div className="pay-done">
                                                  Done
                                                </div> */}
                                                    {item.payment}
                                                  </td>
                                                  <td>
                                                    <div
                                                      className="pay-more"
                                                      onClick={() => {
                                                        handleShow4();
                                                        setView(item);
                                                      }}
                                                    >
                                                      View More
                                                    </div>
                                                  </td>
                                                </tr>
                                              );
                                            }
                                          )}
                                        </tbody>
                                      </Table>
                                    </div>
                                  </>
                                ) : (
                                  <></>
                                )}
                              </>
                            )}
                          </>
                        )}
                      </>
                    )}
                  </>
                )}
              </>
            )}
          </div>
        </div>
      </Container>

      {/* Hotel Modal */}

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Hotel Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Table>
            <tbody>
              <tr>
                <td className="text-danger">Hotel Name : </td>
                <td>{matchedItem1?.PropertyName}</td>
              </tr>
              {/* <tr>
                <td className="text-danger">Duration : </td>
                <td>6 days / 5 Nights</td>
              </tr> */}
              <tr>
                <td className="text-danger">
                  Actual Price(1 Night & 1 Room) :{" "}
                </td>
                <td>₹{parseInt(ModalData1?.amount/ModalData1?.noofrooms)}</td>
              </tr>
              <tr>
                <td className="text-danger">Check In Date : </td>
                <td>{ModalData1?.arrivaldate}</td>
              </tr>
              <tr>
                <td className="text-danger">Check Out Date : </td>
                <td>{ModalData1?.departuredate}</td>
              </tr>
              <tr>
                <td className="text-danger">Total Guest : </td>
                <td>
                  {/* {ModalData1?.noofpeople &&
                    Object?.entries(ModalData1?.noofpeople).map(
                      ([key, value]) => (
                        <div key={key}>
                          <span>{key}:</span> {value}
                        </div>
                      )
                    )} */}
                  Adult: {JSON.stringify(ModalData1?.noofpeople?.adult)},
                  Children: {JSON.stringify(ModalData1?.noofpeople?.children)},
                  Rooms: {ModalData1.noofrooms}
                </td>
              </tr>
              <tr>
                <td className="text-danger">Payment : </td>
                <td>{ModalData1.payment}</td>
              </tr>
              <tr>
                <td className="text-danger">
                  <Button
                    onClick={() => {
                      PrintInvoice(ModalData1);
                    }}
                  >
                    Invoice
                  </Button>{" "}
                </td>
              </tr>
            </tbody>
          </Table>
        </Modal.Body>
      </Modal>

      {/* Activity Modal */}

      <Modal show={show1} onHide={handleClose1}>
        <Modal.Header closeButton>
          <Modal.Title>International Booking Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Table>
            <tbody>
              <tr>
                <td className="text-danger">Activity Name : </td>
                <td>{ModalData.packageId?.DestinationName}</td>
              </tr>
              <tr>
                <td className="text-danger">Duration : </td>
                <td>{ModalData.packageId?.tripduration}</td>
              </tr>
              <tr>
                <td className="text-danger">Price : </td>
                <td>₹{ModalData.packageId?.tripcost}</td>
              </tr>
              <tr>
                <td className="text-danger">Start Date : </td>
                <td>{moment(ModalData.arrivaldate).format(
                                        "DD/MM/YYYY"
                                      )}</td>
              </tr>
              <tr>
                <td className="text-danger">End Date : </td>
                <td> {moment(ModalData.departuredate).format(
                                        "DD/MM/YYYY"
                                      )}</td>
              </tr>
              <tr>
                <td className="text-danger">Total Guest : </td>
                <td>{ModalData.noofpeople}</td>
              </tr>
              <tr>
                <td className="text-danger">Payment : </td>
                <td>
                  {ModalData.payment}
                  {/* <div className="pay-done">Paid</div> */}
                </td>
              </tr>
              <tr>
                <td className="text-danger">
                  <Button
                    onClick={() => {
                      PrintInvoice1(ModalData);
                    }}
                  >
                    Invoice
                  </Button>{" "}
                </td>
              </tr>
            </tbody>
          </Table>
        </Modal.Body>
      </Modal>

      {/* Tour Package Modal */}

      <Modal show={show2} onHide={handleClose2}>
        <Modal.Header closeButton>
          <Modal.Title>Pilgrim Vacations Details</Modal.Title>
        </Modal.Header>
        <Modal.Body id="pdf">
          <Table>
            <tbody>
              <tr>
                <td className="text-danger">Package Name : </td>
                <td>{View?.packageId?.tripname}</td>
              </tr>
              <tr>
                <td className="text-danger">Duration : </td>
                <td>{View?.packageId?.tripduration}</td>
              </tr>
              <tr>
                <td className="text-danger">Price : </td>
                <td>
                  ₹{View?.adultprice + View?.childrenprice + View?.petsprice}
                </td>
              </tr>
              <tr>
                <td className="text-danger">Start Date : </td>
                <td>{View?.checkindate}</td>
              </tr>
              {/* <tr>
                                <td className='text-danger'>End Date : </td>
                                <td>10/21/2023</td>
                            </tr> */}
              <tr>
                <td className="text-danger">Total Guest : </td>
                <td>
                  {View?.adultquantity +
                    View?.childrenquantity +
                    View?.petsquantity}
                  (Adults : {View?.adultquantity}, Children :
                  {View?.childrenquantity})
                </td>
              </tr>
              <tr>
                <td className="text-danger">Places Covered : </td>
                <td>
                  {View?.packageId?.TourSchedule?.map((item, index) => {
                    return (
                      <React.Fragment key={index}>
                        {item?.placename}
                        {index !== View.packageId.TourSchedule.length - 1 &&
                          ", "}
                      </React.Fragment>
                    );
                  })}
                </td>
              </tr>
              <tr>
                <td className="text-danger">Payment : </td>
                <td>{View.payment}</td>
                {/* <td>
                  <div className="pay-done">Paid</div>
                </td> */}
              </tr>
              <tr>
                <td className="text-danger">
                  <Button
                    onClick={() => {
                      PrintInvoice2(View);
                    }}
                  >
                    Invoice
                  </Button>{" "}
                </td>
              </tr>
            </tbody>
          </Table>
        </Modal.Body>
      </Modal>
      <Modal show={show4} onHide={handleClose2}>
        <Modal.Header closeButton>
          <Modal.Title>Vacations Details</Modal.Title>
        </Modal.Header>
        <Modal.Body id="pdf">
          <Table>
            <tbody>
              <tr>
                <td className="text-danger">Package Name : </td>
                <td>{View?.packageId?.stayname}</td>
              </tr>

              <tr>
                <td className="text-danger">Price : </td>
                <td>
                  ₹{View?.adultprice + View?.childrenprice + View?.petsprice}
                </td>
              </tr>
              <tr>
                <td className="text-danger">Start Date : </td>
                <td>{View?.checkindate}</td>
              </tr>
              <tr>
                                <td className='text-danger'>End Date : </td>
                                <td>{View?.checkoutdate}</td>
                            </tr>
              <tr>
                <td className="text-danger">Total Guest : </td>
                <td>
                  {View?.adultquantity +
                    View?.childrenquantity +
                    View?.petsquantity}
                  (Adults : {View?.adultquantity}, Children :
                  {View?.childrenquantity})
                </td>
              </tr>

              <tr>
                <td className="text-danger">Payment : </td>
                <td>{View.payment}</td>
                {/* <td>
                  <div className="pay-done">Paid</div>
                </td> */}
              </tr>
              <tr>
                <td className="text-danger">
                  <Button
                    onClick={() => {
                      PrintInvoice2(View);
                    }}
                  >
                    Invoice
                  </Button>{" "}
                </td>
              </tr>
            </tbody>
          </Table>
        </Modal.Body>
      </Modal>
    </div>
  );
};
