
import "./homearc.css";
import { Carousel } from 'react-bootstrap';
import Logo from "../../assets/arc.jpeg"
const Abt = () => {
  return (
    <div >
    
    
       
       
      
       <div class="construct-site-preloader" id="preloader" style={{opacity: "1", display: "none"}}>
          <div class="sk-cube-grid">
             <div class="sk-cube sk-cube1"></div>
             <div class="sk-cube sk-cube2"></div>
             <div class="sk-cube sk-cube3"></div>
             <div class="sk-cube sk-cube4"></div>
             <div class="sk-cube sk-cube5"></div>
             <div class="sk-cube sk-cube6"></div>
             <div class="sk-cube sk-cube7"></div>
             <div class="sk-cube sk-cube8"></div>
             <div class="sk-cube sk-cube9"></div>
          </div>
       </div>
      
       <header class="construct-header-area">
          <div class="header-top-area">
             <div class="header-top-overlay"></div>
             <div class="container">
                <div class="row4">
                   <div class="col-md-6 col-sm-12">
                      <div class="header-top-left">
                         <p>Have any question? +968 547856 254</p>
                      </div>
                   </div>
                   <div class="col-md-6 col-sm-12">
                      <div class="header-top-right">
                         <ul>
                            <li><a href="#"><i class="fab fa-facebook" style={{color:"white"}}></i></a></li>
                            <li><a href="#"><i class="fab fa-twitter" style={{color:"white"}}></i></a></li>
                            <li><a href="#"><i class="fab fa-linkedin" style={{color:"white"}}></i></a></li>
                            <li><a href="#"><i class="fab fa-skype" style={{color:"white"}}></i></a></li>
                            <li><a href="#"><i class="fab fa-pinterest" style={{color:"white"}}></i></a></li>
                            <li><a href="#"><i class="fa fa-rss" aria-hidden="true" style={{color:"white"}}></i></a></li>
                         </ul>
                      </div>
                   </div>
                </div>
             </div>
          </div>
          <div class="header-logo-area">
             <div class="container">
                <div class="row4">
                   <div class="col-md-3">
                      <div class="construct-logo">
                         <a href="#">
                         <img src={Logo} alt="site logo"  style={{height:"130px"}}/>
                         </a>
                      </div>
                   </div>
                   <div class="col-md-9">
                      <div class="logoright-section">
                         <div class="single-logo-right">
                            <div class="logo-right-icon">
                               <i class="fa fa-phone"></i>
                            </div>
                            <div class="logo-right-text">
                               <h4>call us at</h4>
                               <p>All Days 9:00 to 5:00</p>
                            </div>
                         </div>
                         <div class="single-logo-right">
                            <div class="logo-right-icon">
                            <i class="fa fa-envelope" aria-hidden="true"></i>
                            </div>
                            <div class="logo-right-text">
                               <h4>Mail us</h4>
                               <p>support@construct.com</p>
                            </div>
                         </div>
                      </div>
                   </div>
                </div>
             </div>
          </div>
          <div class="mainmenu-area">
             <div class="container">
                <div class="row4">
                   <div class="col-md-10">
                      <div class="mainmenu">
                         <ul id="construct_navigation">
                            <li ><a href="/architecture">home</a></li>
                            <li class="current-page-item"><a href="/aboutus">about</a></li>
                            <li>
                               <a href="/project">project</a>
                               {/* <ul class="sub_menu">
                                  <li><a href="#">all projects</a></li>
                                  <li><a href="#">single project</a></li>
                               </ul> */}
                            </li>
                            <li>
                               <a href="/service">services</a>
                               <ul>
                                  {/* <li><a href="#">all services</a></li>
                                  <li><a href="#">Construction</a></li>
                                  <li><a href="#">Smart houses</a></li>
                                  <li><a href="#"> Roof Repairing </a></li>
                                  <li><a href="#">Renovations</a></li>
                                  <li><a href="#">Interior</a></li>
                                  <li><a href="3">architecture</a></li> */}
                               </ul>
                            </li>
                            <li>
                               <a href="#">pages</a>
                               <ul>
                                  {/* <li><a href="#">404 pages</a></li>
                                  <li><a href="#">cart page</a></li>
                                  <li><a href="#">checkout page</a></li> */}
                               </ul>
                            </li>
                            <li>
                               <a href="#">shop</a>
                               <ul>
                                  {/* <li><a href="#">shop</a></li>
                                  <li><a href="#">single shop</a></li> */}
                               </ul>
                            </li>
                            <li>
                               <a href="#">blog</a>
                               <ul>
                                  {/* <li><a href="#">blog</a></li>
                                  <li><a href="#">single blog</a></li> */}
                               </ul>
                            </li>
                            <li><a href="#">contact</a></li>
                         </ul>
                      </div>
                    
                      <div class="construct-responsive-menu"><div class="slicknav_menu"><a href="#" aria-haspopup="true" role="button" tabindex="0" class="slicknav_btn slicknav_collapsed" style={{outline: "none"}}><span class="slicknav_menutxt">MENU</span><span class="slicknav_icon"><span class="slicknav_icon-bar"></span><span class="slicknav_icon-bar"></span><span class="slicknav_icon-bar"></span></span></a><ul class="slicknav_nav slicknav_hidden" aria-hidden="true" role="menu" style={{display: "none"}}>
                            <li class="current-page-item"><a href="/architecture" role="menuitem" tabindex="-1">home</a></li>
                            <li><a href="/aboutus" role="menuitem" tabindex="-1">about</a></li>
                            <li class="slicknav_collapsed slicknav_parent"><a href="#" role="menuitem" aria-haspopup="true" tabindex="-1" class="slicknav_item slicknav_row1" style={{outline: "none"}}>
                               <a href="/project" tabindex="-1">project</a>
                               <span class="slicknav_arrow1">►</span></a><ul class="sub_menu slicknav_hidden" role="menu" aria-hidden="true" style={{display: "none"}}>
                                  {/* <li><a href="#" role="menuitem" tabindex="-1">all projects</a></li>
                                  <li><a href="#" role="menuitem" tabindex="-1">single project</a></li> */}
                               </ul>
                            </li>
                            <li class="slicknav_collapsed slicknav_parent"><a href="#" role="menuitem" aria-haspopup="true" tabindex="-1" class="slicknav_item slicknav_row1" style={{outline: "none"}}>
                               <a href="/service" tabindex="-1">services</a>
                               <span class="slicknav_arrow1">►</span></a><ul role="menu" class="slicknav_hidden" aria-hidden="true" style={{display: "none"}}>
                                  {/* <li><a href="#" role="menuitem" tabindex="-1">all services</a></li>
                                  <li><a href="#" role="menuitem" tabindex="-1">Construction</a></li>
                                  <li><a href="#" role="menuitem" tabindex="-1">Smart houses</a></li>
                                  <li><a href="#" role="menuitem" tabindex="-1"> Roof Repairing </a></li>
                                  <li><a href="#" role="menuitem" tabindex="-1">Renovations</a></li>
                                  <li><a href="#" role="menuitem" tabindex="-1">Interior</a></li>
                                  <li><a href="#" role="menuitem" tabindex="-1">architecture</a></li> */}
                               </ul>
                            </li>
                            <li class="slicknav_collapsed slicknav_parent"><a href="#" role="menuitem" aria-haspopup="true" tabindex="-1" class="slicknav_item slicknav_row1" style={{outline: "none"}}>
                               <a href="#" tabindex="-1">pages</a>
                               <span class="slicknav_arrow1">►</span></a><ul role="menu" class="slicknav_hidden" aria-hidden="true" style={{display: "none"}}>
                                  {/* <li><a href="#" role="menuitem" tabindex="-1">404 pages</a></li>
                                  <li><a href="#" role="menuitem" tabindex="-1">cart page</a></li>
                                  <li><a href="#" role="menuitem" tabindex="-1">checkout page</a></li> */}
                               </ul>
                            </li>
                            <li class="slicknav_collapsed slicknav_parent"><a href="#" role="menuitem" aria-haspopup="true" tabindex="-1" class="slicknav_item slicknav_row1" style={{outline: "none"}}>
                               <a href="#" tabindex="-1">shop</a>
                               <span class="slicknav_arrow1">►</span></a><ul role="menu" class="slicknav_hidden" aria-hidden="true" style={{display: "none"}}>
                                  {/* <li><a href="#" role="menuitem" tabindex="-1">shop</a></li>
                                  <li><a href="#" role="menuitem" tabindex="-1">single shop</a></li> */}
                               </ul>
                            </li>
                            <li class="slicknav_collapsed slicknav_parent"><a href="#" role="menuitem" aria-haspopup="true" tabindex="-1" class="slicknav_item slicknav_row1" style={{outline: "none"}}>
                               <a href="#" tabindex="-1">blog</a>
                               <span class="slicknav_arrow1">►</span></a><ul role="menu" class="slicknav_hidden" aria-hidden="true" style={{display: "none"}}>
                                  {/* <li><a href="#" role="menuitem" tabindex="-1">blog</a></li>
                                  <li><a href="#" role="menuitem" tabindex="-1">single blog</a></li> */}
                               </ul>
                            </li>
                            <li><a href="#" role="menuitem" tabindex="-1">contact</a></li>
                         </ul></div></div>
                    
                   </div>
                   <div class="col-md-2">
                      <div class="search_icon" data-toggle="modal" data-target="#myModal">
                         <i class="fa fa-search"></i>
                      </div>
                   </div>
                </div>
             </div>
          </div>
       </header>
    
 

    <section class="construct-breadcromb-area section_100">
       <div class="container">
          <div class="row4">
             <div class="col-md-12">
                <div class="breadcromb">
                   <h2>About Us</h2>
                   <ul>
                      <li><a href="/architecture">home</a></li>
                      <li>/</li>
                      <li> <a href="/aboutus">About Us</a></li>
                   </ul>
                </div>
             </div>
          </div>
       </div>
    </section>
  
    <section class="construct-about-page-area section_100">
       <div class="container">
          <div class="row4">
             <div class="col-md-12">
                <div class="section-heading">
                   <h4>about us</h4>
                   <h2>Allow us to introduce ourselves</h2>
                   <p>Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for 'lorem ipsum' will uncover many web sites.</p>
                </div>
             </div>
          </div>
          <div class="row4">
             <div class="col-md-8 col-sm-8">
                <div class="about-page-left">
                   <img src="http://themescare.com/demos/construct-v-1-2/assets/img/abt-page-1.jpg" alt="about image" />
                </div>
             </div>
             <div class="col-md-4 col-sm-4">
                <div class="about-page-right">
                   <div class="single-abt-img">
                      <img src="http://themescare.com/demos/construct-v-1-2/assets/img/abt-2.jpeg" alt="about image" />
                   </div>
                   <div class="single-abt-img">
                      <img src="http://themescare.com/demos/construct-v-1-2/assets/img/abt-1.jpeg" alt="about image" />
                   </div>
                </div>
             </div>
          </div>
          <div class="row4">
             <div class="col-md-12">
                <div class="about-desc">
                   <h3>More Than 1000 construction Planners</h3>
                   <p>dolor sit amet, consectetur adipiscing elit. Praesent congue justo scelerisque mattis iaculis. Maecenas vestibulum faucibus enim scelerisque egestas. Praesent facilisis, tortor vel vehicula imperdiet, felis massa ultrices metus, sed consectetur massa ex vitae sem. Integer eu sodales augue. Suspendisse eget placerat lorem. Phasellus ac hendrerit leo. Morbi quis iaculis eros. Sed tincidunt augue ante, sit amet vehicula odio aliquam quis. Donec at malesuada nisl. Pellentesque eros lorem, aliquet id hendrerit id, hendrerit ac odio. In dui mauris, auctor vel vestibulum vitae, tincidunt id mi.Curabitur dictum, sapien eu mattis pret</p>
                   <p>Consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat</p>
                </div>
             </div>
          </div>
       </div>
    </section>
   
    <section class="construct-about-bottom-area">
       <div class="container">
          <div class="row4">
             <div class="col-md-8">
                <div class="about-bottom-left section_100">
                   <div class="about-bottom-one">
                      <h3>about us and our priorities</h3>
                      <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
                   </div>
                   <div class="about-bottom-right clearfix row4">
                      <div class="col-md-6 col-sm-6">
                         <div class="about_list">
                            <div class="single-about-list">
                               <div class="about_icon">
                                  <i class="fa fa-building"></i>
                               </div>
                               <div class="about_text">
                                  <h4>Construction</h4>
                                  <p>build homes</p>
                               </div>
                            </div>
                            <div class="single-about-list">
                               <div class="about_icon">
                                  <i class="fa fa-users"></i>
                               </div>
                               <div class="about_text">
                                  <h4>Awesome Stuff</h4>
                                  <p>1200+ workers</p>
                               </div>
                            </div>
                            <div class="single-about-list">
                               <div class="about_icon">
                                  <i class="fa fa-cogs"></i>
                               </div>
                               <div class="about_text">
                                  <h4>Good Planning</h4>
                                  <p>architecture</p>
                               </div>
                            </div>
                         </div>
                      </div>
                      <div class="col-md-6 col-sm-6">
                         <div class="about_list">
                            <div class="single-about-list">
                               <div class="about_icon">
                                  <i class="fa fa-building"></i>
                               </div>
                               <div class="about_text">
                                  <h4>Construction</h4>
                                  <p>build homes</p>
                               </div>
                            </div>
                            <div class="single-about-list">
                               <div class="about_icon">
                                  <i class="fa fa-users"></i>
                               </div>
                               <div class="about_text">
                                  <h4>Awesome Stuff</h4>
                                  <p>1200+ workers</p>
                               </div>
                            </div>
                            <div class="single-about-list">
                               <div class="about_icon">
                                  <i class="fa fa-cogs"></i>
                               </div>
                               <div class="about_text">
                                  <h4>Good Planning</h4>
                                  <p>architecture</p>
                               </div>
                            </div>
                         </div>
                      </div>
                   </div>
                </div>
             </div>
          </div>
       </div>
    </section>
   
    <section class="construct-about-section-three section_t_100 section_b_70">
       <div class="container">
          <div class="row4">
             <div class="col-md-4">
                <div class="single-abt-page-promo">
                   <div class="abt-page-icon">
                      <i class="fa fa-globe"></i>
                   </div>
                   <div class="abt-page-content">
                      <h3>The Best in Asia</h3>
                      <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna</p>
                   </div>
                </div>
             </div>
             <div class="col-md-4">
                <div class="single-abt-page-promo">
                   <div class="abt-page-icon">
                      <i class="fa fa-umbrella"></i>
                   </div>
                   <div class="abt-page-content">
                      <h3>Best Support</h3>
                      <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna</p>
                   </div>
                </div>
             </div>
             <div class="col-md-4">
                <div class="single-abt-page-promo">
                   <div class="abt-page-icon">
                      <i class="fa fa-users"></i>
                   </div>
                   <div class="abt-page-content">
                      <h3>Professional Agents</h3>
                      <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna</p>
                   </div>
                </div>
             </div>
          </div>
       </div>
    </section>
 
   
    <div class="modal fade" id="myModal" tabindex="-1" role="dialog">
       <div class="container">
          <div class="row4">
             <div class="col-md-12">
                <div class="modal-dialog" role="document">
                   <div class="modal-content">
                      <div class="modal-body">
                         <div class="search_box_container">
                            <form action="#">
                               <input type="text" placeholder="Search Here.." />
                               <button type="submit">
                               <i class="fa fa-search"></i>
                               </button>
                            </form>
                         </div>
                      </div>
                   </div>
                </div>
             </div>
          </div>
       </div>
    </div>
  
  

<div class="btntoTop"></div>
       <footer class="construct-footer-area">
          <div class="construct-footer-top-area section_50">
             <div class="container">
                <div class="row4">
                   <div class="col-md-3">
                      <div class="single-footer ">
                         <a href="#">
                         <img src="http://themescare.com/demos/construct-v-1-2/assets/img/footer-logo.png" alt="footer logo" />
                         </a>
                         <p>Ipsum dolor sit amet consect adipsicing sed elit tempor incididunt ueta nostrud dolore magna aliqua ut enim minim .</p>
                         <div class="footer-social-icon">
                            <h3>Follow Us</h3>
                            <ul class="footer-social">
                               <li>
                                  <a href="#" class="fb">
                                  <i class="fab fa-facebook"></i>
                                  </a>
                               </li>
                               <li>
                                  <a href="#" class="twitter">
                                  <i class="fab fa-twitter"></i>
                                  </a>
                               </li>
                               <li>
                                  <a href="#" class="gp">
                                  <i class="fab fa-google-plus"></i>
                                  </a>
                               </li>
                               <li>
                                  <a href="#" class="linkedin">
                                  <i class="fab fa-linkedin"></i>
                                  </a>
                               </li>
                            </ul>
                         </div>
                      </div>
                   </div>
                   <div class="col-md-3">
                      <div class="single-footer ">
                         <h3>quick links</h3>
                         <ul class="footer-widget">
                            <li>
                               <a href="#">
                               <i class="fa fa-angle-right"></i>
                               about us
                               </a>
                            </li>
                            <li>
                               <a href="#">
                               <i class="fa fa-angle-right"></i>
                               what we offers
                               </a>
                            </li>
                            <li>
                               <a href="#">
                               <i class="fa fa-angle-right"></i>
                               testimonial
                               </a>
                            </li>
                            <li>
                               <a href="#">
                               <i class="fa fa-angle-right"></i>
                               our project
                               </a>
                            </li>
                            <li>
                               <a href="#">
                               <i class="fa fa-angle-right"></i>
                               terms &amp; conditions
                               </a>
                            </li>
                         </ul>
                      </div>
                   </div>
                   <div class="col-md-3">
                      <div class="single-footer ">
                         <h3>head office</h3>
                         <p>3638 Karnataka, Karnataka Banglore, India </p>
                         <p class="contact-details">
                            <i class="fa fa-phone"></i>
                            987 - 5678 - 9012
                         </p>
                         <p class="contact-details">
                            <i class="fa fa-envelope"></i>
                            info@example.com
                         </p>
                         <p class="contact-details">
                            <i class="fa fa-hourglass-2"></i>
                            8:00 a.m - 17:00 a.m
                         </p>
                      </div>
                   </div>
                   <div class="col-md-3">
                      <div class="single-footer ">
                         <h3>Instagram</h3>
                         <div class="single-footer-insta">
                            <div class="insta-post">
                               <a href="#">
                               <img src="http://themescare.com/demos/construct-v-1-2/assets/img/insta-3.jpg" alt="instagram 1" /> 
                               </a>
                               <div class="overlay-insta"></div>
                            </div>
                            <div class="insta-post">
                               <a href="#">
                               <img src="http://themescare.com/demos/construct-v-1-2/assets/img/insta-6.jpg" alt="instagram 1" />
                               </a>
                               <div class="overlay-insta"></div>
                            </div>
                            <div class="insta-post">
                               <a href="#">
                               <img src="http://themescare.com/demos/construct-v-1-2/assets/img/insta-1.jpg" alt="instagram 1" />
                               </a>
                               <div class="overlay-insta"></div>
                            </div>
                            <div class="insta-post">
                               <a href="#">
                               <img src="http://themescare.com/demos/construct-v-1-2/assets/img/insta-3.jpg" alt="instagram 1" />
                               </a>
                               <div class="overlay-insta"></div>
                            </div>
                            <div class="insta-post">
                               <a href="#">
                               <img src="http://themescare.com/demos/construct-v-1-2/assets/img/insta-4.jpg" alt="instagram 1" />
                               </a>
                               <div class="overlay-insta"></div>
                            </div>
                            <div class="insta-post">
                               <a href="#">
                               <img src="http://themescare.com/demos/construct-v-1-2/assets/img/insta-6.jpg" alt="instagram 1" />
                               </a>
                               <div class="overlay-insta"></div>
                            </div>
                         </div>
                      </div>
                   </div>
                </div>
             </div>
          </div>
          <div class="construct-footer-bottom-area section_15">
             <div class="container">
                <div class="row4">
                   <div class="col-md-6">
                      <div class="copyright-left">
                         <p>Copyrights © 2023 Construct. All Rights Reserved. </p>
                      </div>
                   </div>
                   <div class="col-md-6">
                      {/* <div class="copyright-right">
                         <p>Built with <i class="fa fa-heart"></i> from<span>Themescare</span></p>
                      </div> */}
                   </div>
                </div>
             </div>
          </div>
       </footer>
      
       <div class="modal fade" id="myModal" tabindex="-1" role="dialog">
          <div class="container">
             <div class="row4">
                <div class="col-md-12">
                   <div class="modal-dialog" role="document">
                      <div class="modal-content">
                         <div class="modal-body">
                            <div class="search_box_container">
                               <form action="#">
                                  <input type="text" placeholder="Search Here.." />
                                  <button type="submit">
                                  <i class="fa fa-search"></i>
                                  </button>
                               </form>
                            </div>
                         </div>
                      </div>
                   </div>
                </div>
             </div>
          </div>
       </div>
    
      </div>
   
  );
};

export default Abt;
