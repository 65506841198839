// src/Post.js

import axios from "axios";
import moment from "moment";
import React, { useState, useEffect } from "react";
import pics from "../../assets/user.png"
import { useParams } from "react-router-dom";
const Shared = () => {
    const params = useParams();
   console.log(params.id);
    const [posts, setPosts] = useState([]);
    const [comments, setComments] = useState([]);
    const [showComments, setShowComments] = useState("");
    const [showMoreComments, setShowMoreComments] = useState("");
    const [getUser, setgetUser] = useState([]);
    const formdata = new FormData();
    const user = JSON.parse(sessionStorage.getItem("user"));
   
  const [post, setPost] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  useEffect(() => {
    getPost();
    getComments();
    getAllUser();
  
  }, []);

  const getPost = async () => {
    let res = await axios.get("https://bookingoda.com/api/user/getPost");
    if (res.status === 200) {
      //console.log("getPost==>", res.data);
      setPosts(res.data.data.filter((data) => data._id === params.id));
    }
  };

  const getComments = async () => {
    //ithu get comments ithu vena
    let res = await axios.get("https://bookingoda.com/api/user/getComments");
    if (res.status === 200) {
      //console.log("getComments==>", res.data);
      setComments(res.data.GetData);
    }
  };

  const getAllUser = async () => {
    let res = await axios.get("https://bookingoda.com/api/user/getalluser");
    if (res.status === 200) {
      //console.log("getAllUser==>", res.data);
      setgetUser(res.data.userData);
    }
  };



  
  const Addlike = async (e, id) => {
    if(!user){
        alert("Please login")
    } else {
    e.preventDefault();
    try {
      const config = {
        url: "/user/addlike",
        method: "post",
        baseURL: "https://bookingoda.com/api",
        // hearder: { "content-type": "multipart/form-data" },
        data: {
          postId: id,
          userId: user?._id,
        },
      };
      await axios(config).then(function (res) {
        if (res.status === 200) {
          //console.log(res.data);
          // alert("comments added");
          getPost();
          // window.location.reload(); ipo check pannu
        }
      });
    } catch (error) {
      //console.log(error.res);
      alert("Something went wrong!!! try again");
    }
}
  };

  const unlike = async (e, id) => {
    if(!user){
        alert("Please login")
    } else {
    e.preventDefault();

    try {
      const config = {
        url: "/user/unlike",
        method: "post",
        baseURL: "https://bookingoda.com/api",
        // hearder: { "content-type": "multipart/form-data" },
        data: {
          postId: id,
          userId: user?._id,
        },
      };
      await axios(config).then(function (res) {
        if (res.status === 200) {
          //console.log(res.data);
          // alert("comments added");
          getPost();
          // window.location.reload(); ipo check pannu
        }
      });
    } catch (error) {
      //console.log(error.res);
      alert("Something went wrong!!! try again");
    }
}
  };
  return (
  
      
    <div class="loadMore">
    {posts?.map((de) => (
      <div class="central-meta item">
        <div class="user-post">
          <div class="friend-info">
            <figure>
             {de?.user[0]?.profileImage ? <img
                src={`https://bookingoda.com/admin/${de?.user[0]?.profileImage}`}
                alt=""
              /> : <img
                src={pics}
                alt=""
              />}
            </figure>
            <div class="friend-name">
            
              <ins>
                <a href="#" title="">
                  {de.user[0]?.name}
                </a>{" "}
                Post Album
              </ins>
              <span>
                <i class="fas fa-globe"></i> published:{" "}
                {moment(de?.createdAt).format(
                  "MMMM Do YYYY, h:mm:ss a"
                )}
              </span>
            </div>
            <div class="post-meta">
              <p>{de.text}</p>
              <figure>
                <div class="img-bunch">
                  <div class="row">
                    {de.image ? (
                      <div class="col-lg-6 col-md-6 col-sm-6">
                        <figure>
                          <a
                            href={`https://bookingoda.com/admin/${de?.image}`}
                            title=""
                            data-toggle="modal"
                            data-target="#img-comt"
                            target={"_blank"}
                          >
                            <img
                              src={`https://bookingoda.com/admin/${de?.image}`}
                              alt=""
                            />
                          </a>
                        </figure>
                      </div>
                    ) : (
                      ""
                    )}

                    {de.video ? (
                      <div class="col-lg-6 col-md-6 col-sm-6">
                        <figure>
                          <a
                            href={`https://bookingoda.com/admin/${de?.video}`}
                            title=""
                            target="_blank"
                          >
                            <video width="100%" controls>
                              <source
                                src={`https://bookingoda.com/admin/${de?.video}`}
                                type="video/mp4"
                              />
                              Your browser does not support
                              the video tag.
                            </video>
                          </a>
                        </figure>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </figure>
              <div class="we-video-info">
                <ul>
                  <li>
                    {de?.love?.find(
                      (item) => item.userId === user?._id
                    )?.userId === user?._id ? (
                      <span
                        class="likes heart"
                        title="Like/Dislike"
                        onClick={(e) => unlike(e, de._id)}
                      >
                        <i
                          class="fa-solid fa-heart"
                          style={{ color: "red" }}
                        ></i>
                        {/* ❤ */}
                        <ins>{de?.love?.length}</ins>
                      </span>
                    ) : (
                      <span
                        class="likes heart"
                        title="Like/Dislike"
                        onClick={(e) => Addlike(e, de._id)}
                      >
                        <i class="fa-solid fa-heart"></i>
                        {/* ❤ */}
                        <ins>{de?.love?.length}</ins>
                      </span>
                    )}
                  </li>
                  <li>
                    <span class="comment" title="Comments">
                      <i
                        class="fas fa-commenting"
                        onClick={() =>
                          setShowComments(de._id)
                        }
                      ></i>
                      <ins>{de?.comment?.length} </ins>
                    </span>
                  </li>

                 
                </ul>
              </div>
            </div>
            {showComments === de._id ? (
              <div
                class="coment-area"
                style={{ display: "block" }}
              >
                <ul class="we-comet">
                  {showMoreComments === de._id ? (
                    de?.commentusers?.map((cm, i) => (
                      <li>
                        <div class="comet-avatar">
                          <img
                            src={`https://bookingoda.com/admin/${de?.commentusers[i]?.profileImage}`}
                            alt=""
                          />
                        </div>
                        <div class="we-comment">
                          <h5>
                            <a href="#" title="">
                              {" "}
                              {
                                de?.commentusers[i]
                                  ?.username
                              }
                            </a>
                          </h5>
                          <p>
                            {" "}
                            {
                              de?.comment[i]?.commentsText
                            }{" "}
                          </p>
                          <img
                            src={`https://bookingoda.com/admin/${de?.comment[i]?.comments_Image}`}
                            alt=""
                            style={{
                              width: "25%",
                              display: "flex",
                            }}
                          />
                          <div class="inline-itms">
                            <span>
                              {moment(
                                de?.comment[i]
                                  ?.comments_date
                              ).format(
                                "MMMM Do YYYY, h:mm:ss a"
                              )}
                            </span>
                          </div>
                        </div>
                      </li>
                    ))
                  ) : (
                    <li>
                      <div class="comet-avatar">
                        <img
                          src={`https://bookingoda.com/admin/${de?.commentusers[0]?.profileImage}`}
                          alt=""
                        />
                      </div>
                      <div class="we-comment">
                        <h5>
                          <a href="#" title="">
                            {" "}
                            {de?.commentusers[0]?.username}
                          </a>
                        </h5>
                        <p>
                          {" "}
                          {
                            de?.comment[0]?.commentsText
                          }{" "}
                        </p>
                        <img
                          src={`https://bookingoda.com/admin/${de?.comment[0]?.comments_Image}`}
                          alt=""
                          style={{
                            width: "25%",
                            display: "flex",
                          }}
                        />
                        <div class="inline-itms">
                          <span>
                            {moment(
                              de?.comment[0]?.comments_date
                            ).format(
                              "MMMM Do YYYY, h:mm:ss a"
                            )}
                          </span>
                        </div>
                      </div>
                    </li>
                  )}

                  <li>
                    <a
                      onClick={() =>
                        setShowMoreComments(de._id)
                      }
                      title=""
                      class="showmore underline"
                    >
                      more comments+
                    </a>
                  </li>
                  <li class="post-comment">
                    <div class="comet-avatar">
                      <img
                        src={`https://bookingoda.com/admin/${user?.profileImage}`}
                        alt=""
                      />
                    </div>
                
                  </li>
                </ul>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    ))}
  </div>
     
 
  );
};

export default Shared;
