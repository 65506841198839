import React from 'react'
import { Container } from 'react-bootstrap';
import Carousel from 'react-bootstrap/Carousel';
import { FaAngleRight } from 'react-icons/fa';

export const Shop = () => {
    return (
        <div>
            <Carousel>
                <Carousel.Item interval={3000}>
                    <img src="./img/shop-1.jpg" alt="" style={{ width: '100%', height: '400px' }} />
                </Carousel.Item>
                <Carousel.Item interval={3000}>
                    <img src="./img/shop-2.jpg" alt="" style={{ width: '100%', height: '400px' }} />
                </Carousel.Item>
                <Carousel.Item interval={3000}>
                    <img src="./img/shop-3.avif" alt="" style={{ width: '100%', height: '400px' }} />
                </Carousel.Item>
                <Carousel.Item interval={3000}>
                    <img src="./img/shop-4.avif" alt="" style={{ width: '100%', height: '400px' }} />
                </Carousel.Item>
            </Carousel>

            <div className='shop_00'>
                <Container>
                    <div className="row justify-content-between text-center">
                        <div className="col-lg-4">
                            <img src="./img/shop-logo-1.png" alt="" />
                            <p className='text-light'>Warranty - *3 years manufacturer warranty for complete peace of mind and *3 years warranty on All New Classic 350 and Meteor 350 Genuine Accessories.</p>
                        </div>
                        <div className="col-lg-4">
                            <img src="./img/shop-logo-2.png" alt="" />
                            <p className='text-light'>Genuine Accessories - branding as a mark of quality assurance.</p>
                        </div>
                        <div className="col-lg-4">
                            <img src="./img/shop-logo-3.png" alt="" />
                            <p className='text-light'>Seamless Integration - Engineered in-house to ensure perfect fit every time.</p>
                        </div>
                    </div>
                </Container>
            </div>

            <div style={{ backgroundColor: '#212121', padding: '10px', }}>
                <p className='text-light text-center fw-bold fs-5 letter-space'>Choose By Motorcycle</p>
            </div>

            <div style={{background:'#000', padding:'10px'}}>
                <div className="row">
                    <div className="col-lg-4 bike">
                        <img src="./img/bike-7.jpg" alt="" />
                        <div>
                            <p className='text-light fw-bold text-center letter-space mb-2'>Meteor 350</p>
                            <div className="d-flex gap-2 justify-content-center mb-2">
                                <button className='renew-btn'>Explore<FaAngleRight /></button>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 bike">
                        <img src="./img/bike-8.avif" alt="" />
                        <div>
                            <p className='text-light fw-bold text-center letter-space mb-2'>Interceptor</p>
                            <div className="d-flex gap-2 justify-content-center mb-2">
                                <button className='renew-btn'>Explore<FaAngleRight /></button>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 bike">
                        <img src="./img/bike-9.jpg" alt="" />
                        <div>
                            <p className='text-light fw-bold text-center letter-space mb-2'>Continetal GT</p>
                            <div className="d-flex gap-2 justify-content-center mb-2">
                                <button className='renew-btn'>Explore<FaAngleRight /></button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
