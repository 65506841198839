import React, { useEffect, useState } from "react";
import "./hotel.css";
import Navbar from "../../components/navbar/Navbar";
import Header from "../../components/header/Header";
import { BsHeart, BsFillShareFill, BsFillPersonFill } from "react-icons/bs";
import { CgScreen } from "react-icons/cg";
import { ImLocation } from "react-icons/im";
import { PiFlowerLotusFill, PiTelevisionFill } from "react-icons/pi";
import { Map, GoogleApiWrapper, Marker } from "google-maps-react";
import StarRatings from "react-star-ratings";
import UserP from "../../assets/user.png";
import {
  FaCircleInfo,
  FaHouseTsunami,
  FaMapLocationDot,
  FaPersonWalkingDashedLineArrowRight,
} from "react-icons/fa6";

import { TbBedFilled } from "react-icons/tb";
import { IoBed } from "react-icons/io5";
import { LiaLanguageSolid } from "react-icons/lia";
import Logo from "../../assets/booking.jpeg";
import {
  faBed,
  faCalendarDays,
  faCar,
  faPerson,
  faPlane,
  faTaxi,
  faHouse,
  faHotel,
  faEarthAmericas,
} from "@fortawesome/free-solid-svg-icons";
import {
  MdOutlineAirportShuttle,
  MdSmokeFree,
  MdRoomService,
  MdCoffeeMaker,
  MdLocalBar,
  MdRoofing,
} from "react-icons/md";
import {
  Accordion,
  Box,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
} from "@chakra-ui/react";
import { BiSpa } from "react-icons/bi";
import { FaAccessibleIcon, FaBath, FaParking } from "react-icons/fa";
import { TiTick, TiTickOutline } from "react-icons/ti";
import { IoIosRestaurant, IoIosBed } from "react-icons/io";
import {
  GiCoffeeCup,
  GiForkKnifeSpoon,
  GiModernCity,
  GiOpenedFoodCan,
  GiPathDistance,
  GiSofa,
} from "react-icons/gi";
import Footer from "../../components/footer/Footer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCircleArrowLeft,
  faCircleArrowRight,
  faCircleXmark,
  faLocationDot,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { format } from "date-fns";
import { DateRange } from "react-date-range";
import { Button } from "antd";
import { Modal } from "react-bootstrap";
import moment from "moment";
import Weather from "./Weather";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
const API_KEY = "46a9246bebba16d42b36aac3fc3ba8af";
const Hotel = (props) => {
  const [latitude, setLatitude] = useState();
  const [longitude, setLongitude] = useState();
  const user = JSON.parse(sessionStorage.getItem("user"));
  const lat = 13.07876;
  const log = 77.53324;
  //console.log(typeof lat, log);
  const apiKey = "AIzaSyCom4BCfTVsw3o22AYg69Y7EovmLfV_Alw";
  useEffect(() => {
    getCoordinates();
  }, []);

  const [destination, setDestination] = useState("");
  const [openDate, setOpenDate] = useState(false);

  const [openOptions, setOpenOptions] = useState(false);
  const [options, setOptions] = useState({
    adult: 1,
    children: 0,
    room: 1,
  });

  const [modalOpen, setModalOpen] = useState(false);
  const closeModal = () => setModalOpen(false);
  // const openModal = () => setModalOpen(true);

  const [photomodal, setPhotoModal] = useState(false);
  const closephotoModal = () => setPhotoModal(false);
  const openphotoModal = () => setPhotoModal(true);
  const [photomodal1, setPhotoModal1] = useState(false);
  const closephotoModal1 = () => setPhotoModal1(false);
  const [id, setid] = useState();
  const openModal = (id) => {
    setid(id);
    setModalOpen(true);
  };

  const openphotoModal1 = (id) => {
    setid(id);
    setPhotoModal1(true);
  };
  const [Calendar, setCalendar] = useState(false);
  const closeCalendar = () => setCalendar(false);
  const [Calendarid, setCalendarid] = useState();
  const openCalendar = (id) => {
    setCalendarid(id);
    setCalendar(true);
  };
  const handleOption = (name, operation) => {
    setOptions((prev) => {
      return {
        ...prev,
        [name]: operation === "i" ? options[name] + 1 : options[name] - 1,
      };
    });
  };
  const params = useParams();
  //console.log("item", params, params.id);
  const location = useLocation();
  //console.log(location.state.options, "location");
  const [data, setdata] = useState([]);
  const [data1, setdata1] = useState([]);
  const [data2, setdata2] = useState([]);
  const [data3, setdata3] = useState([]);
  const [Pricerate, setPricerate] = useState(0);
  const [Staffrate, setStaffrate] = useState(0);
  const [Cleanlinessrate, setCleanlinessrate] = useState(0);
  const [Facilitiesrate, setFacilitiesrate] = useState(0);
  const [Comfortrate, setComfortrate] = useState(0);
  const [Locationrate, setLocationrate] = useState(0);
  const [name, setname] = useState("");
  const [email, setemail] = useState("");
  const [comment, setcomment] = useState("");

  const [title, settitle] = useState("");

  const getapartment = async () => {
    let res = await axios.get(
      "https://bookingoda.com/api/admin/getallApartment"
    );
    if (res.status === 200) {
      ////console.log(res.data);
      setdata(
        res.data.Apartment.filter(
          (item) =>
            item.status === "Approved" &&
            item.Deletestatus !== "Deleted By Admin" &&
            item.blockstatus !== true
        )
      );
    }
  };
  const getalternative = async () => {
    let res = await axios.get(
      "https://bookingoda.com/api/admin/getallalternative"
    );
    if (res.status === 200) {
      ////console.log(res.data);
      setdata2(
        res.data.alternative.filter(
          (item) =>
            item.status === "Approved" &&
            item.Deletestatus !== "Deleted By Admin" &&
            item.blockstatus !== true
        )
      );
    }
  };
  const handleDateChange = (selectedDates) => {
    setDate([selectedDates.selection]);
  };
  const gethotel = async () => {
    let res = await axios.get("https://bookingoda.com/api/admin/getallhotel");
    if (res.status === 200) {
      ////console.log(res.data);
      setdata1(
        res.data.hotel.filter(
          (item) =>
            item.status === "Approved" &&
            item.Deletestatus !== "Deleted By Admin" &&
            item.blockstatus !== true
        )
      );
    }
  };
  const gethomestay = async () => {
    let res = await axios.get("https://bookingoda.com/api/admin/getallhome");
    if (res.status === 200) {
      ////console.log(res.data);
      setdata3(
        res.data.home.filter(
          (item) =>
            item.status === "Approved" &&
            item.Deletestatus !== "Deleted By Admin" &&
            item.blockstatus !== true
        )
      );
    }
  };

  useEffect(() => {
    getapartment();
    gethotel();
    getalternative();
    gethomestay();
  }, []);
  const list = [...data, ...data1, ...data2, ...data3];
  const list1 = list.filter((items) => items?._id === params.id);
  //console.log(list1);
  const [slideNumber, setSlideNumber] = useState(0);
  const [open, setOpen] = useState(false);
  const [slideNumber1, setSlideNumber1] = useState(0);
  const [open1, setOpen1] = useState(false);
  const [date, setDate] = useState(
    location.state?.date
      ? location.state?.date
      : [
          {
            startDate: new Date(),
            endDate: new Date(),
            key: "selection",
          },
        ]
  );
  const handleOpen = (i) => {
    setPhotoModal(false);
    setSlideNumber(i);
    setOpen(true);
  };
  const handleOpen1 = (i) => {
    setPhotoModal1(false);
    setModalOpen(false);
    setSlideNumber1(i);
    setOpen1(true);
  };
  const startDate = date[0].startDate.setHours(0, 0, 0, 0);
  const endDate = date[0].endDate.setHours(0, 0, 0, 0);
  const timeDifference = endDate - startDate;
  const navigate = useNavigate();

  // Convert the difference to days
  const daysDifference = timeDifference / (1000 * 60 * 60 * 24);
  //console.log(typeof location.state?.options.room, "sadfsad");

  const renderMedia = (media, index) => {
    return (
      <div className="hotelImgWrapper" key={index}>
        {media.file?.endsWith(".jpg") ||
        media.file?.endsWith(".webp") ||
        media.file?.endsWith(".jpeg") ||
        media.file?.endsWith(".png") ? (
          <img
            src={"https://bookingoda.com/image/" + media.file}
            alt="image"
            // style={{ width: "80px", height: "80px" }}
            onClick={() => handleOpen(index)}
            className="hotelImg"
          />
        ) : media.file.endsWith(".mp4") ? (
          <video
            src={"https://bookingoda.com/image/" + media.file}
            alt="video"
            className="hotelImg"
            controls
          />
        ) : null}
      </div>
    );
  };
  const renderMedia1 = (media, index) => {
    return (
      <div className="hotelImgWrapper" key={index}>
        <img
          src={"https://bookingoda.com/image/" + media.name}
          alt="image"
          // style={{ width: "80px", height: "80px" }}
          onClick={() => handleOpen1(index)}
          className="hotelImg"
        />
      </div>
    );
  };
  const handleMove = (direction) => {
    let newSlideNumber;

    if (direction === "l") {
      newSlideNumber = slideNumber === 0 ? 5 : slideNumber - 1;
    } else {
      newSlideNumber = slideNumber === 5 ? 0 : slideNumber + 1;
    }

    setSlideNumber(newSlideNumber);
  };
  const handleMove1 = (direction) => {
    let newSlideNumber1;

    if (direction === "l") {
      newSlideNumber1 = slideNumber1 === 0 ? 5 : slideNumber1 - 1;
    } else {
      newSlideNumber1 = slideNumber1 === 5 ? 0 : slideNumber1 + 1;
    }

    setSlideNumber1(newSlideNumber1);
  };

  const getCoordinates = () => {
    const apiUrl = `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(
      list1[0]?.address?.map((item) => item)
    )}&key=${apiKey}`;

    fetch(apiUrl)
      .then((response) => response.json())
      .then((data) => {
        if (data.results.length > 0) {
          const location = data.results[0].geometry.location;
          setLatitude(location.lat);
          setLongitude(location.lng);
        } else {
          //console.log("No results found for the given address");
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };
  const [order, setorder] = useState([]);
  const [review, setreview] = useState([]);

  const getstays = async () => {
    let res = await axios.get("https://bookingoda.com/api/admin/getbookstays");
    if (res.status === 200) {
      ////console.log(res.data);
      setorder(res.data.bookedstays);
      //console.log(res.data.bookedstays,"dasfasd");
    }
  };
  const getreviews = async () => {
    let res = await axios.get("https://bookingoda.com/api/user/getallReview");
    if (res.status === 200) {
      ////console.log(res.data);
      setreview(res.data.success);
      //console.log(res.data.bookedstays,"dasfasd");
    }
  };
  const booked = order.some(
    (item) =>
      item.userId?._id === user?._id && item.packageId[0] === list1[0]?._id
  );
  const selectedCategories = [
    "Pricerating",
    "Staffrating",
    "Cleanlinessrating",
    "Comfortrating",
    "Facilitiesrating",
    "Locationrating",
  ];
  const averageRatings = {};

  // Check if there are ratings
  const filteredReviews = Array.isArray(review)
    ? review.filter(
        (data) =>
          data.HotelId &&
          Array.isArray(data.HotelId) &&
          data.HotelId[0] === list1[0]?._id
      )
    : [];
  if (filteredReviews?.length > 0) {
    selectedCategories.forEach((category) => {
      // Calculate the sum of ratings for the category
      const sum = filteredReviews.reduce(
        (acc, rating) => acc + rating[category],
        0
      );

      // Calculate the average
      averageRatings[category] = sum / filteredReviews.length;
    });
  }
  const overallAverage =
    selectedCategories.length > 0
      ? selectedCategories.reduce(
          (acc, category) => acc + averageRatings[category],
          0
        ) / selectedCategories.length
      : 0;

  // Determine the status based on the overall average
  let status;
  if (overallAverage >= 1 && overallAverage < 2) {
    status = "Review score";
  } else if (overallAverage >= 2 && overallAverage < 3) {
    status = "Okay";
  } else if (overallAverage >= 3 && overallAverage < 4) {
    status = "Average";
  } else if (overallAverage >= 4 && overallAverage <= 5) {
    status = "Good";
  } else {
    status = "Unrated";
  }
  console.log(booked, "booked");
  useEffect(() => {
    getstays();
    getreviews();
  }, []);

  const postReview = async () => {
    if (!user) {
      alert("Please login");
      window.location.assign("/login");
    } else if (!booked) {
      alert("Book the stay first!");
    } else if (
      !Pricerate ||
      !Staffrate ||
      !Comfortrate ||
      !Cleanlinessrate ||
      !Locationrate ||
      !Facilitiesrate ||
      !name ||
      !email ||
      !title ||
      !comment
    ) {
      alert("Please fill all the  fields!");
    } else {
      try {
        const config = {
          url: "/createReview",
          method: "post",
          baseURL: "https://bookingoda.com/api/user",
          headers: { "content-type": "application/json" },
          data: {
            HotelId: list1[0]?._id,
            userId: user?._id,
            Pricerating: Pricerate,
            Staffrating: Staffrate,
            Cleanlinessrating: Cleanlinessrate,
            Comfortrating: Comfortrate,
            Facilitiesrating: Facilitiesrate,
            Locationrating: Locationrate,
            Average:
              (Pricerate +
                Staffrate +
                Cleanlinessrate +
                Comfortrate +
                Facilitiesrate +
                Locationrate) /
              6,
            comment: comment,
            name: name,
            email: email,
            title: title,
          },
        };
        let res = await axios(config);
        if (res.status === 200) {
          alert("Reviews added successfully");
          getreviews();
          setname("");
          setemail("");
          settitle("");
          setcomment("");
          setLocationrate(0);
          setFacilitiesrate(0);
          setCleanlinessrate(0);
          setComfortrate(0);
          setStaffrate(0);
          setPricerate(0);
        } else {
          alert("Something Wrong");
        }
      } catch (error) {
        //console.log(error);
      }
    }
  };
  const [weatherData, setWeatherData] = useState(null);
  const [loaded, setLoaded] = useState(true);
  const RemainingRoomsCalendar = ({ remainingRoomsData }) => {
  
    const events = remainingRoomsData[Calendarid].remaining.flatMap(item => {
    
      return ({
        title: `Available Rooms: ${item.remainingRooms}`,
        start: item?.remainingRoomsDates?.checkInDate,
        end: item?.remainingRoomsDates?.checkOutDate,
        backgroundColor: item.remainingRooms === 0 ? 'red' : 'green'
      });
    });
    const eventClassNames = ({ event }) => {
      const today = new Date().setHours(0, 0, 0, 0);
    const eventStart = event.start.setHours(0, 0, 0, 0);
    if (eventStart < today) {
      return ['past-date'];
    } else if (event.backgroundColor === 'red') {
      return ['red-cell']; // Add red-cell class to cells with red background
    } else if (event.backgroundColor === 'green') {
      return ['green-cell']; // Add green-cell class to cells with green background
    }
    return [];
    };
    const eventContent = ({ event }) => (
      <div>
        <span className="event-title">{event.title}</span>
      </div>
    );
  
    return (
      <div className="calendar1" style={{width:"70%",marginLeft:'auto',marginRight:'auto',marginTop:"50px"}}>
        <h2>Available Rooms</h2>
        <FullCalendar
          plugins={[dayGridPlugin]}
          initialView="dayGridMonth"
          events={events}
          eventClassNames={eventClassNames}
          eventContent={eventContent}
        />
      </div>
    );
  };
  async function fetchWeatherData(cityName) {
    setLoaded(false);
    const API = `https://api.openweathermap.org/data/2.5/weather?q=${cityName}&units=metric&appid=${API_KEY}`;

    try {
      const response = await fetch(API);
      if (response.status === 200) {
        const data = await response.json();
        setWeatherData(data);
      } else {
        setWeatherData(null);
      }
      setLoaded(true);
    } catch (error) {
      console.error("Error fetching weather data:", error);
      setLoaded(true);
    }
  }

  const [last, setLast] = useState("");

  useEffect(() => {
    if (Array.isArray(list1) && list1.length > 0) {
      const addressArray = list1[0]?.address;

      if (
        addressArray &&
        Array.isArray(addressArray) &&
        addressArray.length > 0
      ) {
        const parts = addressArray[0]?.split(",");

        if (parts && Array.isArray(parts) && parts.length >= 4) {
          // Extract the city name from the last element of the array
          const lastElement = parts[parts.length - 4].trim();

          // Check if the new lastElement is different from the current last state
          if (lastElement !== last) {
            setLast(lastElement);
            console.log("City:", lastElement);

            // Fetch weather data only when lastElement changes
            fetchWeatherData(lastElement);
          }
        } else {
          console.log(
            "Invalid address format or insufficient elements in parts."
          );
        }
      } else {
        console.log("No or invalid address data available in addressArray.");
      }
    } else {
      console.log("No or invalid data available in list1.");
    }
  }, [list1, last]); 
  const [nearby, setnearby] = useState([]);
  const getnearby = async () => {
    let res = await axios.get("https://bookingoda.com/api/admin/getnearby");
    if ((res.status = 200)) {
      //console.log(res);
      setnearby(res.data?.nearby);
    }
  };

  useEffect(() => {
    // getcountry();
    getnearby();
  }, []);
  const gal = list1[0]?.Photos.map((data) => ({
    url: `https://bookingoda.com/image/${data.file}`,
  }));
  const [activeImageNum, setCurrent] = useState(0);
  const length = gal?.length;
  const nextSlide = () => {
    setCurrent(activeImageNum === length - 1 ? 0 : activeImageNum + 1);
  };
  const prevSlide = () => {
    setCurrent(activeImageNum === 0 ? length - 1 : activeImageNum - 1);
  };
  if (!Array.isArray(gal) || gal?.length <= 0) {
    return null;
  }
  const originalPrice = parseInt(list1[0]?.Price);
  const discountString = list1[0]?.Offerprice || "";
  let discountedPrice;

  console.log(`Discount String: ${discountString}`);

  if (discountString.endsWith("%")) {
    const discountPercentage = parseFloat(
      discountString.replace(/[^0-9.]/g, "")
    );
    discountedPrice =
      originalPrice - (originalPrice * discountPercentage) / 100;
  } else {
    const discountValue = parseFloat(discountString.replace(/[^0-9.]/g, ""));
    discountedPrice = originalPrice - discountValue;
  }

  const taxInfo = list1[0]?.tax || "";
  let taxedPrice;

  if (taxInfo.endsWith("%")) {
    // If the tax is in percentage
    const taxPercentage = parseFloat(taxInfo.replace(/[^0-9.]/g, ""));
    taxedPrice = (originalPrice * taxPercentage) / 100;
  } else if (taxInfo.endsWith("INR")) {
    // If the tax is in INR
    const taxInr = parseFloat(taxInfo.replace(/[^0-9.]/g, ""));
    taxedPrice = taxInr;
  } else {
    // Handle the case where the tax format is not recognized
    console.warn(`Unrecognized tax format for item with ID ${list1[0]?._id}`);
  }
  if (!loaded) {
    return (
      <div className="container">
        <div>Loading...</div>
      </div>
    );
  } else {
    return (
      <div>
        <Navbar />
        {/* <Header type="list" /> */}
        <div class="header12 listMode">
          <div class="headerContainer12 container12">
            <div class="headerCont12">
              <div>
                <h2 class="headerTitle12 text-center" style={{marginTop:"50px"}}>
                  Fresh, quite and peaceful
                </h2>
              </div>
            </div>
          </div>
        </div>
        <div></div>

        <div className="hotelContainer">
          {open && (
            <div className="slider">
              <FontAwesomeIcon
                icon={faCircleXmark}
                style={{ width: "20px", color: "white" }}
                className="close"
                onClick={() => setOpen(false)}
              />
              <FontAwesomeIcon
                icon={faCircleArrowLeft}
                className="arrow"
                onClick={() => handleMove("l")}
              />
              <div className="sliderWrapper">
                <img
                  src={`https://bookingoda.com/image/${list1[0]?.Photos[slideNumber]?.file}`}
                  alt=""
                  style={{ width: "500px", height: "500px" }}
                  className="sliderImg"
                />
              </div>
              <FontAwesomeIcon
                icon={faCircleArrowRight}
                className="arrow"
                onClick={() => handleMove("r")}
              />
            </div>
          )}
          {open1 && (
            <div className="slider">
              <FontAwesomeIcon
                icon={faCircleXmark}
                style={{ width: "20px", color: "white" }}
                className="close"
                onClick={() => setOpen1(false)}
              />
              <FontAwesomeIcon
                icon={faCircleArrowLeft}
                className="arrow"
                onClick={() => handleMove1("l")}
              />
              <div className="sliderWrapper">
                <img
                  src={`https://bookingoda.com/image/${list1[0]?.RoomDetails[id].RoomPhotos[slideNumber1]?.name}`}
                  alt=""
                  style={{ width: "500px", height: "500px" }}
                  className="sliderImg"
                />
              </div>
              <FontAwesomeIcon
                icon={faCircleArrowRight}
                className="arrow"
                onClick={() => handleMove1("r")}
              />
            </div>
          )}
          <div className="hotelWrapper">
            <h1 className="hotelTitle" style={{ color: "#0B58B4" }}>
              {list1[0]?.PropertyName}
            </h1>
            <div
              className="hotelAddress"
              style={{ marginTop: "10px", marginBottom: "20px" }}
            >
              <h3>
                <FontAwesomeIcon icon={faLocationDot} />
                {list1[0]?.address?.map((item) => item).join(", ")}
              </h3>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                marginRight: "0px",
              }}
            >
              {/* {list1[0]?.Photos.slice(0, 4).map((media, index) => (
              // <div class="col-lg-3 col-md-3 col-sm-6 col-xs-6">
              //   <div class="item-box">
              //     <a
              //       class="strip"
              //       // href={`https://bookingoda.com/image/${media.file}`}
              //       title=""
              //       data-strip-group="mygroup"
              //       data-strip-group-options="loop: false"
              //     >
              //       <img
              //         src={`https://bookingoda.com/image/${media.file}`}
              //         alt=""
              //       />
              //     </a>
              //     <div class="over-photo">
              //       <a
              //         onClick={() => handleOpen(index)}
              //         className="gal-link popup-image"
              //       >
              //         <i
              //           className="fas fa-search"
              //           style={{ marginLeft: "15px", color: "white" }}
              //         />
              //       </a>
              //     </div>
              //   </div>
              // </div> */}
              <div className="image-sliderdesc">
                <div class="leftdesc">
                  <ArrowBackIosIcon onClick={prevSlide} />
                </div>
                <div class="rightdesc">
                  <ArrowForwardIosIcon onClick={nextSlide} />
                </div>
                {gal.map((currentSlide, ind) => {
                  return (
                    <div
                      className={
                        ind === activeImageNum
                          ? "currentSlide active"
                          : "currentSlide"
                      }
                      key={ind}
                    >
                      {ind === activeImageNum && (
                        <img src={currentSlide.url} className="imagedesc" />
                      )}
                    </div>
                  );
                })}
              </div>

              {/* ))} */}
              <div
                class="col-lg-3 col-md-3 col-sm-6 col-xs-6"
                style={{ marginLeft: "0px" }}
              >
                <div class="item-box">
                  <a
                    class="strip"
                    href={`https://bookingoda.com/image/${list1[0]?.Photos[4]?.file}`}
                    title=""
                    data-strip-group="mygroup"
                    data-strip-group-options="loop: false"
                  >
                    <img
                      src={`https://bookingoda.com/image/${list1[0]?.Photos[4]?.file}`}
                      alt=""
                    />
                  </a>
                  <div class="over-photo">
                    <a
                      // onClick={() => handleOpen(index)}
                      className="gal-link popup-image"
                    >
                      <i
                        className="fas fa-search"
                        style={{ marginLeft: "15px", color: "white" }}
                      />
                    </a>
                  </div>
                </div>
                <div class="item-box">
                  <a
                    class="strip"
                    // href={`https://bookingoda.com/image/${list1[0]?.Photos[5]?.file}`}
                    title=""
                    data-strip-group="mygroup"
                    data-strip-group-options="loop: false"
                  >
                    <img
                      src={`https://bookingoda.com/image/${list1[0]?.Photos[5]?.file}`}
                      alt=""
                    />
                  </a>
                  <div class="over-photo">
                    <div
                      className="more-photos-button dynamic-gal"
                      onClick={openphotoModal}
                    >
                      View more <faChevronRight />
                    </div>
                  </div>
                </div>
              </div>
              {weatherData && (
                <Weather
                  weatherData={weatherData}
                  fetchWeatherData={fetchWeatherData}
                />
              )}
            </div>

            <span className="hotelDistance" style={{ marginTop: "20px" }}>
              Excellent location – 500m from center
            </span>

            {/* {list1[0]?.Photos.map((media, index) => renderMedia(media, index))} */}
            <div className="hotelDetails">
              <div className="hotelDetailsTexts">
                <h1 className="hotelTitle">Stay in the heart of City</h1>
                <p className="hotelDesc">
                  Located at {list1[0]?.address?.map((item) => item).join(", ")}
                  , {list1[0]?.PropertyName} has accommodations with and feature
                  a fully equipped furniture . Popular points of interest near
                  the apartment include Location services{" "}
                  {list1[0]?.Locationservices?.map((item) => item.label).join(
                    ", "
                  )}
                  ,{list1[0]?.address?.map((item) => item).join(", ")}.
                </p>
                <div className="HotelSeeAvialability_Description_Most_popular_category">
                  <div>
                    <h2
                      size="sm"
                      style={{ marginTop: "20px", marginBottom: "20px" }}
                    >
                      Most popular facilities
                    </h2>
                  </div>
                </div>
              </div>
              <div className="hotelDetailsPrice">
                <h1 style={{ color: "#0B58B4" }}>
                  Perfect for a {daysDifference ? daysDifference : 1}-night
                  stay!
                </h1>
                <span
                  style={{ borderBottom: "1px solid grey", padding: "5px" }}
                >
                  Located in the{" "}
                  {list1[0]?.address?.map((item) => item).join(", ")},this
                  property has an excellent location score of 9.8!
                </span>
                <div style={{ textAlign: "right" }}>
                  <span
                    style={{
                      color: "GrayText",
                      fontSize: "16px",
                      textDecoration: "line-through",
                    }}
                  >
                    ₹ {parseInt(list1[0]?.Price)}
                  </span>

                  <h3 style={{ color: "#0B58B4" }}>
                    <b style={{ color: "#0B58B4" }}>
                      ₹
                      {discountedPrice ? discountedPrice :  parseInt(list1[0]?.Price)*
                        parseInt(location.state?.options.room) *
                        (daysDifference ? daysDifference : 1)}
                    </b>
                    {/* ({daysDifference ? daysDifference : 1} Night) */}
                  </h3>
                  <span style={{ fontSize: "16px", color: "GrayText" }}>
                    Per Night
                  </span>
                  <span className="siTaxOp">
                    +{taxedPrice} taxes &amp; fees
                  </span>
                </div>
              </div>
            </div>
            <div class="e50d7535fa">
              <div class="f1e6195c8b">
                <div class="bd948ef1e2">
                  <div class="f2cf178bcd aaa19a85b8">
                    <div class="e1eebb6a1e e6208ee469 d0caee4251">
                      <div class="d1ca9115fe" style={{ color: "green" }}>
                        <span
                          data-testid="facility-group-icon"
                          class="fcd9eec8fb de55c5a312 c2cc050fb8"
                          aria-hidden="true"
                        >
                          <FaMapLocationDot color="green" />
                        </span>
                        Location Services
                      </div>
                    </div>
                  </div>
                  {list1[0]?.Locationservices?.map((key) => (
                    <ul class="c807d72881 da08adf9d2 e10711a42e">
                      <li class="a8b57ad3ff d50c412d31 fb9a5438f9 c7a5a1307a">
                        <div>
                          <div class="c1f85371f5">
                            <span
                              data-testid="facility-icon"
                              class="fcd9eec8fb f8e1212c4d dc2d7e0744 bf9a32efa5 f64eb5d122"
                              aria-hidden="true"
                            >
                              <TiTick color="green" />
                            </span>
                            <div class="dc6c58be0b">
                              <span class="fe975f7f02">
                                <div class="a53cbfa6de e6208ee469">
                                  <span class="a5a5a75131">{key.label}</span>
                                </div>
                              </span>
                            </div>
                          </div>
                        </div>
                      </li>
                    </ul>
                  ))}
                </div>
              </div>

              <div class="f1e6195c8b">
                <div class="bd948ef1e2">
                  <div class="f2cf178bcd aaa19a85b8">
                    <div class="e1eebb6a1e e6208ee469 d0caee4251">
                      <div class="d1ca9115fe" style={{ color: "green" }}>
                        <span
                          data-testid="facility-group-icon"
                          class="fcd9eec8fb de55c5a312 c2cc050fb8"
                          aria-hidden="true"
                        >
                          <GiSofa color="green" />
                        </span>
                        Living Area
                      </div>
                    </div>
                  </div>
                  <ul class="c807d72881 da08adf9d2 e10711a42e">
                    <li class="a8b57ad3ff d50c412d31 fb9a5438f9 c7a5a1307a">
                      <div>
                        <div class="c1f85371f5">
                          <span
                            data-testid="facility-icon"
                            class="fcd9eec8fb f8e1212c4d dc2d7e0744 bf9a32efa5 f64eb5d122"
                            aria-hidden="true"
                          >
                            <TiTick color="green" />
                          </span>
                          <div class="dc6c58be0b">
                            <span class="fe975f7f02">
                              <div class="a53cbfa6de e6208ee469">
                                <span class="a5a5a75131">Sofa bed</span>{" "}
                              </div>
                            </span>
                          </div>
                        </div>
                      </div>
                    </li>
                    {list1[0]?.LRoombeds.map((data) => (
                      <li class="a8b57ad3ff d50c412d31 fb9a5438f9 c7a5a1307a">
                        <div>
                          <div class="c1f85371f5">
                            <span
                              data-testid="facility-icon"
                              class="fcd9eec8fb f8e1212c4d dc2d7e0744 bf9a32efa5 f64eb5d122"
                              aria-hidden="true"
                            >
                              <TiTick color="green" />
                            </span>
                            <div class="dc6c58be0b">
                              <span class="fe975f7f02">
                                <div class="a53cbfa6de e6208ee469">
                                  <span class="a5a5a75131">{data.name}</span>{" "}
                                </div>
                              </span>
                            </div>
                          </div>
                        </div>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>

              <div class="f1e6195c8b">
                <div class="bd948ef1e2">
                  <div class="f2cf178bcd aaa19a85b8">
                    <div class="e1eebb6a1e e6208ee469 d0caee4251">
                      <div class="d1ca9115fe" style={{ color: "green" }}>
                        <span
                          data-testid="facility-group-icon"
                          class="fcd9eec8fb de55c5a312 c2cc050fb8"
                          aria-hidden="true"
                        >
                          <FaAccessibleIcon color="green" />
                        </span>
                        Accessibility
                      </div>
                    </div>
                  </div>
                  {list1 &&
                    list1[0] &&
                    list1[0]?.AlternativeUse &&
                    Object.entries(list1[0]?.AlternativeUse)
                      ?.filter(([key, value]) => value === true)
                      ?.map(([key]) => (
                        <ul class="c807d72881 da08adf9d2 e10711a42e">
                          <li class="a8b57ad3ff d50c412d31 fb9a5438f9 c7a5a1307a">
                            <div>
                              <div class="c1f85371f5">
                                <span
                                  data-testid="facility-icon"
                                  class="fcd9eec8fb f8e1212c4d dc2d7e0744 bf9a32efa5 f64eb5d122"
                                  aria-hidden="true"
                                >
                                  <TiTick color="green" />
                                </span>
                                <div class="dc6c58be0b">
                                  <span class="fe975f7f02">
                                    <div class="a53cbfa6de e6208ee469">
                                      <span class="a5a5a75131">{key}</span>{" "}
                                    </div>
                                  </span>
                                </div>
                              </div>
                            </div>
                          </li>
                        </ul>
                      ))}
                  {list1 &&
                    list1[0] &&
                    list1[0]?.HotelUse &&
                    Object.entries(list1[0]?.HotelUse)
                      ?.filter(([key, value]) => value === true)
                      ?.map(([key]) => (
                        <ul class="c807d72881 da08adf9d2 e10711a42e">
                          <li class="a8b57ad3ff d50c412d31 fb9a5438f9 c7a5a1307a">
                            <div>
                              <div class="c1f85371f5">
                                <span
                                  data-testid="facility-icon"
                                  class="fcd9eec8fb f8e1212c4d dc2d7e0744 bf9a32efa5 f64eb5d122"
                                  aria-hidden="true"
                                >
                                  <TiTick color="green" />
                                </span>
                                <div class="dc6c58be0b">
                                  <span class="fe975f7f02">
                                    <div class="a53cbfa6de e6208ee469">
                                      <span class="a5a5a75131">{key}</span>{" "}
                                    </div>
                                  </span>
                                </div>
                              </div>
                            </div>
                          </li>
                        </ul>
                      ))}
                  {list1 &&
                    list1[0] &&
                    list1[0]?.HomeUse &&
                    Object.entries(list1[0]?.HomeUse)
                      ?.filter(([key, value]) => value === true)
                      ?.map(([key]) => (
                        <ul class="c807d72881 da08adf9d2 e10711a42e">
                          <li class="a8b57ad3ff d50c412d31 fb9a5438f9 c7a5a1307a">
                            <div>
                              <div class="c1f85371f5">
                                <span
                                  data-testid="facility-icon"
                                  class="fcd9eec8fb f8e1212c4d dc2d7e0744 bf9a32efa5 f64eb5d122"
                                  aria-hidden="true"
                                >
                                  <TiTick color="green" />
                                </span>
                                <div class="dc6c58be0b">
                                  <span class="fe975f7f02">
                                    <div class="a53cbfa6de e6208ee469">
                                      <span class="a5a5a75131">{key}</span>{" "}
                                    </div>
                                  </span>
                                </div>
                              </div>
                            </div>
                          </li>
                        </ul>
                      ))}
                </div>
              </div>
              <div class="f1e6195c8b">
                <div class="bd948ef1e2">
                  <div class="f2cf178bcd aaa19a85b8">
                    <div class="e1eebb6a1e e6208ee469 d0caee4251">
                      <div class="d1ca9115fe" style={{ color: "green" }}>
                        <span
                          data-testid="facility-group-icon"
                          class="fcd9eec8fb de55c5a312 c2cc050fb8"
                          aria-hidden="true"
                        >
                          <LiaLanguageSolid color="green" />
                        </span>
                        Languages Spoken
                      </div>
                    </div>
                  </div>
                  {list1 &&
                    list1[0] &&
                    list1[0]?.Language &&
                    Object.entries(list1[0]?.Language)
                      ?.filter(([key, value]) => value === true)
                      ?.map(([key]) => (
                        <ul class="c807d72881 da08adf9d2 e10711a42e">
                          <li class="a8b57ad3ff d50c412d31 fb9a5438f9 c7a5a1307a">
                            <div>
                              <div class="c1f85371f5">
                                <span
                                  data-testid="facility-icon"
                                  class="fcd9eec8fb f8e1212c4d dc2d7e0744 bf9a32efa5 f64eb5d122"
                                  aria-hidden="true"
                                >
                                  <TiTick color="green" />
                                </span>
                                <div class="dc6c58be0b">
                                  <span class="fe975f7f02">
                                    <div class="a53cbfa6de e6208ee469">
                                      <span class="a5a5a75131">{key}</span>{" "}
                                    </div>
                                  </span>
                                </div>
                              </div>
                            </div>
                          </li>
                        </ul>
                      ))}
                </div>
              </div>
              <div class="f1e6195c8b">
                <div class="bd948ef1e2">
                  <div class="f2cf178bcd aaa19a85b8">
                    <div class="e1eebb6a1e e6208ee469 d0caee4251">
                      <div class="d1ca9115fe" style={{ color: "green" }}>
                        <span
                          data-testid="facility-group-icon"
                          class="fcd9eec8fb de55c5a312 c2cc050fb8"
                          aria-hidden="true"
                        >
                          <FaPersonWalkingDashedLineArrowRight color="green" />
                        </span>
                        Nearby Services
                      </div>
                    </div>
                  </div>
                  {nearby.map((key) => (
                    <ul class="c807d72881 da08adf9d2 e10711a42e">
                      <li class="a8b57ad3ff d50c412d31 fb9a5438f9 c7a5a1307a">
                        <div>
                          <div class="c1f85371f5">
                            <span
                              data-testid="facility-icon"
                              class="fcd9eec8fb f8e1212c4d dc2d7e0744 bf9a32efa5 f64eb5d122"
                              aria-hidden="true"
                            >
                              <TiTick color="green" />
                            </span>
                            <div class="dc6c58be0b">
                              <span class="fe975f7f02">
                                <div class="a53cbfa6de e6208ee469">
                                  <span class="a5a5a75131">
                                    {key.nearbyName}
                                  </span>{" "}
                                </div>
                              </span>
                            </div>
                          </div>
                        </div>
                      </li>
                    </ul>
                  ))}
                </div>
              </div>
            </div>
          </div>
          {/* <MailList /> */}
          <div className="list-single-main-item fl-wrap" id="sec4">
            <div className="list-single-main-item-title fl-wrap">
              <h3>Location</h3>
            </div>
            <div id="map" style={{ position: "relative", overflow: "hidden" }}>
              <div
                style={{
                  height: "100%",
                  width: "100%",
                  position: "absolute",
                  top: 0,
                  left: 0,
                  backgroundColor: "rgb(229, 227, 223)",
                }}
              >
                <Map
                  google={props.google}
                  zoom={14}
                  style={{ width: "100%", height: "400px" }}
                  initialCenter={{ lat: lat, lng: log }}
                >
                  <Marker position={{ lat: lat, lng: log }} />
                </Map>
              </div>
            </div>
          </div>
          <div className="list-single-main-item fl-wrap" id="sec4">
            <div className="list-single-main-item-title fl-wrap">
              <h3>House Rules</h3>
            </div>
            <table className="table package-info-table mb-0">
              <tbody>
                <tr>
                  <th>Check-IN</th>
                  <td>
                    {list1[0]?.CheckinFrom}-{list1[0]?.CheckinUntil}
                  </td>
                </tr>
                <tr>
                  <th>Check-OUT</th>
                  <td>
                    {" "}
                    {list1[0]?.CheckOutFrom}-{list1[0]?.CheckOutUntil}
                  </td>
                </tr>
                <tr>
                  <th>Pets</th>
                  <td>
                    {list1[0]?.Pets !== ""
                      ? "Pets Allowed"
                      : "Pets Not Allowed"}
                  </td>
                </tr>
                <tr>
                  <th>Children</th>
                  <td>
                    {list1[0]?.Children !== ""
                      ? "Children Allowed"
                      : "Children Not Allowed"}
                  </td>
                </tr>
                <tr>
                  <th>Parties</th>
                  <td>
                    {list1[0]?.Parties !== ""
                      ? "Parties Allowed"
                      : "Parties  Not Allowed"}
                  </td>
                </tr>
                <tr>
                  <th>Cancellation Policy</th>
                  <td>
                    <p>
                      Cancel their booking for free before:{" "}
                      {list1[0]?.Cancellationdays}
                    </p>
                    <p>
                      Protection against accidental bookings:{" "}
                      {list1[0]?.AccidentalBooking !== "true" ? "No" : "Yes"}
                    </p>
                  </td>
                </tr>
                {/* <tr>
            <th>Rate Plan</th>
            <td>
              <p>Non-refundable rate plan: {list1[0]?.DiscountRate}%</p>
                <p>Week Day rate plan: {list1[0]?.Weekdayrate}%</p>
                <p>Weekend rate plan: {list1[0]?.Weekendrate}%</p>
            </td>
          </tr> */}
                <tr>
                  <th>Accepted payment methods</th>
                  <td>
                    <p
                      aria-label="Accepted payment methods and conditions"
                      className="payment_methods_overall"
                      rel={300}
                    >
                      <img
                        src="https://cf.bstatic.com/static/img/transparent/8e09e5757781bf4c0f42228d45f422e5e800ae64.gif"
                        className="payment_methods_readability ppd_payment_methods_readability creditcard maestro"
                        alt="Maestro"
                        title="Maestro"
                      />
                      <img
                        src="https://cf.bstatic.com/static/img/transparent/8e09e5757781bf4c0f42228d45f422e5e800ae64.gif"
                        className="payment_methods_readability ppd_payment_methods_readability creditcard euromastercard"
                        alt="Mastercard"
                        title="Mastercard"
                      />
                      <img
                        src="https://cf.bstatic.com/static/img/transparent/8e09e5757781bf4c0f42228d45f422e5e800ae64.gif"
                        className="payment_methods_readability ppd_payment_methods_readability creditcard visa"
                        alt="Visa"
                        title="Visa"
                      />
                      <img
                        src="https://cf.bstatic.com/static/img/transparent/8e09e5757781bf4c0f42228d45f422e5e800ae64.gif"
                        className="payment_methods_readability ppd_payment_methods_readability creditcard americanexpress"
                        alt="American Express"
                        title="American Express"
                      />
                      <span
                        className="bui-badge bui-badge--constructive hp_bp_payment_method__cash-badge"
                        data-bui-component="Badge"
                      >
                        <span className="bui-badge__text">Cash</span>
                      </span>
                    </p>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          
          <div className="list-single-main-item fl-wrap" id="sec4">
            <div className="list-single-main-item-title fl-wrap">
              <h3>Available Rooms</h3>
            </div>

            <div className="rooms-container fl-wrap">
              {list1[0]?.RoomDetails?.map((data, i) => {
                return (
                  <div className="rooms-item fl-wrap">
                    <div className="rooms-media">
                      <img
                        src={`https://bookingoda.com/image/${data.RoomPhotos[0].name}`}
                        alt=""
                      />
                      <button
                        className="dynamic-gal more-photos-button"
                        onClick={() => openphotoModal1(i)}
                        data-dynamicpath="[{'src': 'images/gal/slider/1.jpg'}, {'src': 'images/gal/slider/2.jpg'},{'src': 'images/gal/slider/3.jpg'}]"
                      >
                        {" "}
                        View Gallery{" "}
                        <span>{data.RoomPhotos.length} photos</span>{" "}
                        <FontAwesomeIcon
                          icon={faChevronRight}
                          style={{ alignSelf: "flex-end" }}
                        />
                      </button>
                    </div>
                    <div className="rooms-details">
                      <div className="rooms-details-header fl-wrap">
                        <span className="rooms-price">
                          ₹{data.RoomPrice} <strong> / night</strong>
                        </span>
                        <h3>{data.Roomname[0].value}</h3>
                        <h5>
                          Max Guests: <span>{data.RoomStay} persons</span>
                        </h5>
                        <h5>
                          Available Rooms: <span>{data.RoomType} Rooms</span>
                        </h5>
                      </div>
                      <p>
                        {data.Roomname[0].value} has accommodations with{" "}
                        {data &&
                          data &&
                          data?.General &&
                          Object.entries(data?.General)
                            ?.filter(([key, value]) => value === true)
                            ?.map(([key]) => key)
                            ?.join(", ")}{" "}
                        The Room units come with{" "}
                        {data?.RoomUnit?.map(
                          (item) => item.value + " beds"
                        ).join(", ")}{" "}
                        and feature a fully equipped furniture{" "}
                        {data &&
                          data &&
                          data?.Food &&
                          Object.entries(data?.Food)
                            ?.filter(([key, value]) => value === true)
                            ?.map(([key]) => key)
                            ?.join(", ")}{" "}
                        , and{" "}
                        {data.PrivateBathroom === "Yes"
                          ? "a Private"
                          : "no shared"}{" "}
                        bathroom with{" "}
                        {data &&
                          data &&
                          data?.BathroomItems &&
                          Object.entries(data?.BathroomItems)
                            ?.filter(([key, value]) => value === true)
                            ?.map(([key]) => key)
                            ?.join(", ")}{" "}
                      </p>
                      <div className="facilities-list fl-wrap">
                        <Button
                          className="btn3 color-bg ajax-link"
                          onClick={() => openCalendar(i)}
                          style={{background:"green"}}
                        >
                          Check Availability
                          <i className="fas fa-caret-right" />
                        </Button>
                      </div>
                      <div className="facilities-list fl-wrap">
                        <Button
                          className="btn3 color-bg ajax-link"
                          onClick={() => openModal(i)}
                          style={{background:"green"}}
                        >
                          Details
                          <i className="fas fa-caret-right" />
                        </Button>
                      </div>
                    </div>
                  </div>
                );
              })}

              {/* <div className="rooms-item fl-wrap">
              <div className="rooms-media">
                <img src="http://easybook.kwst.net/images/gal/9.jpg" alt="" />
                <div
                  className="dynamic-gal more-photos-button"
                  data-dynamicpath="[{'src': 'images/gal/slider/4.jpg'}, {'src': 'images/gal/slider/5.jpg'}, {'src': 'images/gal/slider/2.jpg'} ]"
                >
                  View Gallery <span>3 photos</span>{" "}
                  <FontAwesomeIcon
                    icon={faChevronRight}
                    style={{ alignSelf: "flex-end" }}
                  />
                </div>
              </div>
              <div className="rooms-details">
                <div className="rooms-details-header fl-wrap">
                  <span className="rooms-price">
                    ₹122 <strong> / night</strong>
                  </span>
                  <h3>Superior Double Room</h3>
                  <h5>
                    Max Guests: <span>4 persons</span>
                  </h5>
                </div>
                <p>
                  Morbi varius, nulla sit amet rutrum elementum, est elit
                  finibus tellus, ut tristique elit risus at metus. Lorem ipsum
                  dolor sit amet, consectetur adipiscing elit.
                </p>
                <div className="facilities-list fl-wrap">
                  <Button
                    className="btn3 color-bg ajax-link"
                    onClick={openModal}
                  >
                    Details
                    <i className="fas fa-caret-right" />
                  </Button>
                </div>
              </div>
            </div> */}
            </div>
          </div>
          <div className="mt-reviews-list-info-criteria">
            <div className="reviews-criteria">
              <div
                className="mt-item-reviews-average-wrapper"
                itemProp="aggregateRating"
                itemScope
                itemType="http://schema.org/AggregateRating"
              >
                <div
                  className="star4 mt-item-reviews-average-rating"
                  itemProp="ratingValue"
                >
                  {/* 4.0 */}
                  {/* <ul>
        {selectedCategories.map((category) => (
          <li key={category}>
            {category}: {averageRatings[category]}
          </li>
        ))}
      </ul> */}
                  {overallAverage 
                    ? overallAverage.toFixed(1)
                    : 0}
                </div>{" "}
                <div className="mt-item-reviews-verbal-description">
                  <span className="rating-Superb mt-item-reviews-rating-description">
                    {status}
                  </span>
                  <div className="mt-item-reviews-ratings-count">
                    <div className="mt-reviews-list-info edgtf-reviews-count">
                      <p className="mt-reviews-summary">
                        <span
                          className="mt-reviews-number"
                          itemProp="ratingCount"
                        >
                          {Array.isArray(review)
                            ? review.filter(
                                (data) =>
                                  data?.HotelId &&
                                  Array.isArray(data.HotelId) &&
                                  data.HotelId[0] === list1[0]?._id
                              ).length
                            : 0}
                        </span>
                        <span className="mt-reviews-label">&nbsp;review</span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="mt-rating-percentage-wrapper">
                <div className="label_text_percentange">
                  <span className="label_text">Value for money</span>
                  <span className="label_percentage">
                    &nbsp;{" "}
                    {(Array.isArray(review) && review.length > 0
                      ? ((review.filter(
                          (data) =>
                            data?.HotelId &&
                            Array.isArray(data.HotelId) &&
                            data.HotelId[0] === list1[0]?._id
                        ).length > 0
                          ? review
                              .filter(
                                (data) =>
                                  data?.HotelId &&
                                  Array.isArray(data.HotelId) &&
                                  data.HotelId[0] === list1[0]?._id
                              )
                              .reduce(
                                (acc, rating) => acc + rating?.Pricerating || 0,
                                0
                              ) /
                            review.filter(
                              (data) =>
                                data?.HotelId &&
                                Array.isArray(data.HotelId) &&
                                data.HotelId[0] === list1[0]?._id
                            ).length
                          : 0) /
                          5) *
                        100
                      : 0).toFixed(2)}
                    %
                  </span>
                </div>
                <div className="progress">
                  <div
                    style={{
                      width: `${
                        Array.isArray(review) && review.length > 0
                          ? ((review.filter(
                              (data) =>
                                data?.HotelId &&
                                Array.isArray(data.HotelId) &&
                                data.HotelId[0] === list1[0]?._id
                            ).length > 0
                              ? review
                                  .filter(
                                    (data) =>
                                      data?.HotelId &&
                                      Array.isArray(data.HotelId) &&
                                      data.HotelId[0] === list1[0]?._id
                                  )
                                  .reduce(
                                    (acc, rating) =>
                                      acc + rating?.Pricerating || 0,
                                    0
                                  ) /
                                review.filter(
                                  (data) =>
                                    data?.HotelId &&
                                    Array.isArray(data.HotelId) &&
                                    data.HotelId[0] === list1[0]?._id
                                ).length
                              : 0) /
                              5) *
                            100
                          : 0
                      }%`,
                      height: "20px",
                      backgroundColor: "green",
                      borderRadius: "5px",
                    }}
                  />
                </div>

                <div className="label_text_percentange">
                  <span className="label_text">Staff</span>
                  <span className="label_percentage">
                    &nbsp;{" "}
                    {(Array.isArray(review) && review.length > 0
                      ? ((review.filter(
                          (data) =>
                            data?.HotelId &&
                            Array.isArray(data.HotelId) &&
                            data.HotelId[0] === list1[0]?._id
                        ).length > 0
                          ? review
                              .filter(
                                (data) =>
                                  data?.HotelId &&
                                  Array.isArray(data.HotelId) &&
                                  data.HotelId[0] === list1[0]?._id
                              )
                              .reduce(
                                (acc, rating) => acc + rating?.Staffrating || 0,
                                0
                              ) /
                            review.filter(
                              (data) =>
                                data?.HotelId &&
                                Array.isArray(data.HotelId) &&
                                data.HotelId[0] === list1[0]?._id
                            ).length
                          : 0) /
                          5) *
                        100
                      : 0).toFixed(2)}
                    %
                  </span>
                </div>
                <div className="progress">
                  <div
                    style={{
                      width: `${
                        Array.isArray(review) && review.length > 0
                          ? ((review.filter(
                              (data) =>
                                data?.HotelId &&
                                Array.isArray(data.HotelId) &&
                                data.HotelId[0] === list1[0]?._id
                            ).length > 0
                              ? review
                                  .filter(
                                    (data) =>
                                      data?.HotelId &&
                                      Array.isArray(data.HotelId) &&
                                      data.HotelId[0] === list1[0]?._id
                                  )
                                  .reduce(
                                    (acc, rating) =>
                                      acc + rating?.Staffrating || 0,
                                    0
                                  ) /
                                review.filter(
                                  (data) =>
                                    data?.HotelId &&
                                    Array.isArray(data.HotelId) &&
                                    data.HotelId[0] === list1[0]?._id
                                ).length
                              : 0) /
                              5) *
                            100
                          : 0
                      }%`,
                      height: "20px",
                      backgroundColor: "green",
                      borderRadius: "5px",
                    }}
                  />
                </div>
                <div className="label_text_percentange">
                  <span className="label_text">Cleanliness</span>
                  <span className="label_percentage">
                    {" "}
                    &nbsp;{" "}
                    {(Array.isArray(review) && review.length > 0
                      ? ((review.filter(
                          (data) =>
                            data?.HotelId &&
                            Array.isArray(data.HotelId) &&
                            data.HotelId[0] === list1[0]?._id
                        ).length > 0
                          ? review
                              .filter(
                                (data) =>
                                  data?.HotelId &&
                                  Array.isArray(data.HotelId) &&
                                  data.HotelId[0] === list1[0]?._id
                              )
                              .reduce(
                                (acc, rating) =>
                                  acc + rating?.Cleanlinessrating || 0,
                                0
                              ) /
                            review.filter(
                              (data) =>
                                data?.HotelId &&
                                Array.isArray(data.HotelId) &&
                                data.HotelId[0] === list1[0]?._id
                            ).length
                          : 0) /
                          5) *
                        100
                      : 0).toFixed(2)}
                    %
                  </span>
                </div>
                <div className="progress">
                  <div
                    style={{
                      width: `${
                        Array.isArray(review) && review.length > 0
                          ? ((review.filter(
                              (data) =>
                                data?.HotelId &&
                                Array.isArray(data.HotelId) &&
                                data.HotelId[0] === list1[0]?._id
                            ).length > 0
                              ? review
                                  .filter(
                                    (data) =>
                                      data?.HotelId &&
                                      Array.isArray(data.HotelId) &&
                                      data.HotelId[0] === list1[0]?._id
                                  )
                                  .reduce(
                                    (acc, rating) =>
                                      acc + (rating?.Cleanlinessrating || 0),
                                    0
                                  ) /
                                review.filter(
                                  (data) =>
                                    data?.HotelId &&
                                    Array.isArray(data.HotelId) &&
                                    data.HotelId[0] === list1[0]?._id
                                ).length
                              : 0) /
                              5) *
                            100
                          : 0
                      }%`,
                      height: "20px",
                      backgroundColor: "green",
                      borderRadius: "5px",
                    }}
                  />
                </div>
                <div className="label_text_percentange">
                  <span className="label_text">Facilities</span>
                  <span className="label_percentage">
                    {" "}
                    &nbsp;{" "}
                    {(Array.isArray(review) && review.length > 0
                      ? ((review.filter(
                          (data) =>
                            data?.HotelId &&
                            Array.isArray(data.HotelId) &&
                            data.HotelId[0] === list1[0]?._id
                        ).length > 0
                          ? review
                              .filter(
                                (data) =>
                                  data?.HotelId &&
                                  Array.isArray(data.HotelId) &&
                                  data.HotelId[0] === list1[0]?._id
                              )
                              .reduce(
                                (acc, rating) =>
                                  acc + (rating?.Facilitiesrating || 0),
                                0
                              ) /
                            review.filter(
                              (data) =>
                                data?.HotelId &&
                                Array.isArray(data.HotelId) &&
                                data.HotelId[0] === list1[0]?._id
                            ).length
                          : 0) /
                          5) *
                        100
                      : 0).toFixed(2)}
                    %
                  </span>
                </div>
                <div className="progress">
                  <div
                    style={{
                      width: `${
                        Array.isArray(review) && review.length > 0
                          ? ((review.filter(
                              (data) =>
                                data?.HotelId &&
                                Array.isArray(data.HotelId) &&
                                data.HotelId[0] === list1[0]?._id
                            ).length > 0
                              ? review
                                  .filter(
                                    (data) =>
                                      data?.HotelId &&
                                      Array.isArray(data.HotelId) &&
                                      data.HotelId[0] === list1[0]?._id
                                  )
                                  .reduce(
                                    (acc, rating) =>
                                      acc + (rating?.Facilitiesrating || 0),
                                    0
                                  ) /
                                Math.max(
                                  review.filter(
                                    (data) =>
                                      data?.HotelId &&
                                      Array.isArray(data.HotelId) &&
                                      data.HotelId[0] === list1[0]?._id
                                  ).length,
                                  1
                                ) // Ensure the denominator is at least 1 to avoid division by zero
                              : 0) /
                              5) *
                            100
                          : 0
                      }%`,
                      height: "20px",
                      backgroundColor: "green",
                      borderRadius: "5px",
                    }}
                  />
                </div>
                <div className="label_text_percentange">
                  <span className="label_text">Comfort</span>
                  <span className="label_percentage">
                    {" "}
                    &nbsp;{" "}
                    {(Array.isArray(review) && review.length > 0
                      ? ((review.filter(
                          (data) =>
                            data?.HotelId &&
                            Array.isArray(data.HotelId) &&
                            data.HotelId[0] === list1[0]?._id
                        ).length > 0
                          ? review
                              .filter(
                                (data) =>
                                  data?.HotelId &&
                                  Array.isArray(data.HotelId) &&
                                  data.HotelId[0] === list1[0]?._id
                              )
                              .reduce(
                                (acc, rating) =>
                                  acc + (rating?.Comfortrating || 0),
                                0
                              ) /
                            Math.max(
                              review.filter(
                                (data) =>
                                  data?.HotelId &&
                                  Array.isArray(data.HotelId) &&
                                  data.HotelId[0] === list1[0]?._id
                              ).length,
                              1
                            ) // Ensure the denominator is at least 1 to avoid division by zero
                          : 0) /
                          5) *
                        100
                      : 0).toFixed(2)}
                    %
                  </span>
                </div>
                <div className="progress">
                  <div
                    style={{
                      width: `${
                        Array.isArray(review) && review.length > 0
                          ? ((review.filter(
                              (data) =>
                                data?.HotelId &&
                                Array.isArray(data.HotelId) &&
                                data.HotelId[0] === list1[0]?._id
                            ).length > 0
                              ? review
                                  .filter(
                                    (data) =>
                                      data?.HotelId &&
                                      Array.isArray(data.HotelId) &&
                                      data.HotelId[0] === list1[0]?._id
                                  )
                                  .reduce(
                                    (acc, rating) =>
                                      acc + (rating?.Comfortrating || 0),
                                    0
                                  ) /
                                Math.max(
                                  review.filter(
                                    (data) =>
                                      data?.HotelId &&
                                      Array.isArray(data.HotelId) &&
                                      data.HotelId[0] === list1[0]?._id
                                  ).length,
                                  1
                                ) // Ensure the denominator is at least 1 to avoid division by zero
                              : 0) /
                              5) *
                            100
                          : 0
                      }%`,
                      height: "20px",
                      backgroundColor: "green",
                      borderRadius: "5px",
                    }}
                  />
                </div>
                <div className="label_text_percentange">
                  <span className="label_text">Location</span>
                  <span className="label_percentage">
                    {" "}
                    &nbsp;{" "}
                    {
                    (Array.isArray(review) && review.length > 0
                      ? ((review.filter(
                          (data) =>
                            data?.HotelId &&
                            Array.isArray(data.HotelId) &&
                            data.HotelId[0] === list1[0]?._id
                        ).length > 0
                          ? review
                              .filter(
                                (data) =>
                                  data?.HotelId &&
                                  Array.isArray(data.HotelId) &&
                                  data.HotelId[0] === list1[0]?._id
                              )
                              .reduce(
                                (acc, rating) =>
                                  acc + (rating?.Locationrating || 0),
                                0
                              ) /
                            Math.max(
                              review.filter(
                                (data) =>
                                  data?.HotelId &&
                                  Array.isArray(data.HotelId) &&
                                  data.HotelId[0] === list1[0]?._id
                              ).length,
                              1
                            ) // Ensure the denominator is at least 1 to avoid division by zero
                          : 0) /
                          5) *
                        100
                      : 0).toFixed(2)}
                    %
                  </span>
                </div>
                <div className="progress">
                  <div
                    style={{
                      width: `${
                        Array.isArray(review) && review.length > 0
                          ? ((review.filter(
                              (data) =>
                                data?.HotelId &&
                                Array.isArray(data.HotelId) &&
                                data.HotelId[0] === list1[0]?._id
                            ).length > 0
                              ? review
                                  .filter(
                                    (data) =>
                                      data?.HotelId &&
                                      Array.isArray(data.HotelId) &&
                                      data.HotelId[0] === list1[0]?._id
                                  )
                                  .reduce(
                                    (acc, rating) =>
                                      acc + (rating?.Locationrating || 0),
                                    0
                                  ) /
                                Math.max(
                                  review.filter(
                                    (data) =>
                                      data?.HotelId &&
                                      Array.isArray(data.HotelId) &&
                                      data.HotelId[0] === list1[0]?._id
                                  ).length,
                                  1
                                ) // Ensure the denominator is at least 1 to avoid division by zero
                              : 0) /
                              5) *
                            100
                          : 0
                      }%`,
                      height: "20px",
                      backgroundColor: "green",
                      borderRadius: "5px",
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="reviews-section" style={{ width: "85%" }}>
            <h3 id="review-title">
              {Array.isArray(review) &&
                review.filter(
                  (data) => data?.HotelId && data.HotelId[0] === list1[0]?._id
                ).length}{" "}
              reviews
            </h3>
            {Array.isArray(review)
              ? review
                  ?.filter((e) => e.HotelId[0] === list1[0]?._id)
                  .map((data) => (
                    <div id="mt-review-id-65345" className="mt-review-content">
                      <div className="mt-review-image">
                        <div className="avatar-picture">
                          <img
                            alt=""
                            src={UserP}
                            className="avatar avatar-140"
                            height={140}
                            width={140}
                            decoding="async"
                          />
                        </div>
                      </div>
                      <div
                        className="mt-review-text"
                        itemProp="review"
                        itemScope
                        itemType="http://schema.org/Review"
                      >
                        <div className="review-author-name">
                          <span
                            itemProp="author"
                            itemScope
                            itemType="http://schema.org/Person"
                          >
                            <h4 itemProp="name">{data.tilte}</h4>
                          </span>
                        </div>
                        <div id="mt-rating-stars-parent">
                          <div className="mt-rating-stars">
                            <label>Value for money</label>{" "}
                            <StarRatings
                              rating={data.Pricerating}
                              starRatedColor="gold"
                              numberOfStars={5}
                              name="rating"
                              isSelectable={false}
                              starDimension="20px"
                            />
                          </div>
                          <div className="mt-rating-stars">
                            <label>Staff</label>{" "}
                            <StarRatings
                              rating={data.Staffrating}
                              starRatedColor="gold"
                              numberOfStars={5}
                              name="rating"
                              isSelectable={false}
                              starDimension="20px"
                            />
                          </div>
                          <div className="mt-rating-stars">
                            <label>Cleanliness</label>
                            <StarRatings
                              rating={data.Cleanlinessrating}
                              starRatedColor="gold"
                              numberOfStars={5}
                              name="rating"
                              isSelectable={false}
                              starDimension="20px"
                            />
                          </div>
                          <div className="mt-rating-stars">
                            <label>Facilities</label>{" "}
                            <StarRatings
                              rating={data.Facilitiesrating}
                              starRatedColor="gold"
                              numberOfStars={5}
                              name="rating"
                              isSelectable={false}
                              starDimension="20px"
                            />
                          </div>
                          <div className="mt-rating-stars">
                            <label>Comfort</label>
                            <StarRatings
                              rating={data.Comfortrating}
                              starRatedColor="gold"
                              numberOfStars={5}
                              name="rating"
                              isSelectable={false}
                              starDimension="20px"
                            />
                          </div>
                          <div className="mt-rating-stars">
                            <label>Location</label>{" "}
                            <StarRatings
                              rating={data.Locationrating}
                              starRatedColor="gold"
                              numberOfStars={5}
                              name="rating"
                              isSelectable={false}
                              starDimension="20px"
                            />
                          </div>{" "}
                        </div>
                        <div className="review-content-paragraph">
                          <p itemProp="reviewBody">
                            <img
                              draggable="false"
                              role="img"
                              className="emoji"
                              alt="🗓️"
                              src="https://s.w.org/images/core/emoji/14.0.0/svg/1f5d3.svg"
                            />{" "}
                            {data.comment}
                          </p>
                        </div>
                        <div className="review-date">
                          <span
                            itemProp="datePublished"
                            content="October 31, 2023"
                          >
                            {moment(data.createdAt).format("MMMM DD, YYYY")}
                          </span>
                        </div>
                      </div>
                    </div>
                  ))
              : null}
          </div>

          <div id="comment_form" style={{ width: "85%" }}>
            <div className="comment-form mt-110">
              <h4>Leave Your Review</h4>

              <div id="mt-rating-stars-parent" style={{ marginTop: "2%" }}>
                <div className="mt-rating-stars" id="mt-rating-stars-0">
                  <h5 style={{ marginBottom: "2%" }}>Value for money</h5>
                  <StarRatings
                    rating={Pricerate} // Initial rating
                    starRatedColor="gold"
                    changeRating={(newRating) => setPricerate(newRating)}
                    numberOfStars={5}
                    starDimension="30px"
                    name="rating"
                  />
                </div>
                <div className="mt-rating-stars" id="mt-rating-stars-1">
                  <h5 style={{ marginBottom: "2%" }}>Staff</h5>
                  <StarRatings
                    rating={Staffrate} // Initial rating
                    starRatedColor="gold"
                    changeRating={(newRating) => setStaffrate(newRating)}
                    numberOfStars={5}
                    starDimension="30px"
                    name="rating"
                  />
                </div>
                <div className="mt-rating-stars" id="mt-rating-stars-2">
                  <h5 style={{ marginBottom: "2%" }}>Cleanliness</h5>
                  <StarRatings
                    rating={Cleanlinessrate} // Initial rating
                    starRatedColor="gold"
                    changeRating={(newRating) => setCleanlinessrate(newRating)}
                    numberOfStars={5}
                    starDimension="30px"
                    name="rating"
                  />
                </div>
                <div className="mt-rating-stars" id="mt-rating-stars-2">
                  <h5 style={{ marginBottom: "2%" }}>Facilities</h5>
                  <StarRatings
                    rating={Facilitiesrate} // Initial rating
                    starRatedColor="gold"
                    changeRating={(newRating) => setFacilitiesrate(newRating)}
                    numberOfStars={5}
                    starDimension="30px"
                    name="rating"
                  />
                </div>
                <div className="mt-rating-stars" id="mt-rating-stars-3">
                  <h5 style={{ marginBottom: "2%" }}>Comfort</h5>
                  <StarRatings
                    rating={Comfortrate} // Initial rating
                    starRatedColor="gold"
                    changeRating={(newRating) => setComfortrate(newRating)}
                    numberOfStars={5}
                    starDimension="30px"
                    name="rating"
                  />
                </div>
                <div className="mt-rating-stars" id="mt-rating-stars-3">
                  <h5 style={{ marginBottom: "2%" }}>Location</h5>
                  <StarRatings
                    rating={Locationrate} // Initial rating
                    starRatedColor="gold"
                    changeRating={(newRating) => setLocationrate(newRating)}
                    numberOfStars={5}
                    starDimension="30px"
                    name="rating"
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-lg-6">
                  <div className="custom-input-group">
                    <input
                      type="text"
                      placeholder="Your Full Name"
                      onChange={(e) => setname(e.target.value)}
                      value={name}
                      id="name1"
                      style={{ width: "100%" }}
                    />
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="custom-input-group">
                    <input
                      type="text"
                      placeholder="Your Email"
                      onChange={(e) => setemail(e.target.value)}
                      value={email}
                      id="email1"
                      style={{ width: "100%" }}
                    />
                  </div>
                </div>
              </div>
              <div className="custom-input-group">
                <input
                  type="text"
                  placeholder="Title of your review"
                  onChange={(e) => settitle(e.target.value)}
                  value={title}
                  id="type"
                  style={{ width: "100%" }}
                />
              </div>
              <div className="custom-input-group">
                <textarea
                  cols={20}
                  rows={7}
                  onChange={(e) => setcomment(e.target.value)}
                  value={comment}
                  placeholder="Write Message"
                  defaultValue={""}
                />
              </div>

              <div className="custom-input-group">
                <div className="submite-btn">
                  <button
                    type="submit"
                    className="button-listing"
                    name="add-review"
                    onClick={postReview}
                  >
                    Add Review
                  </button>
                </div>
              </div>
            </div>
          </div>
          <Modal
            show={modalOpen}
            onHide={closeModal}
            backdrop="static"
            keyboard={false}
            size="lg"
          >
            <Modal.Body>
              <div id="ajax-modal" className="fl-wrap">
                {/*ajax-modal-wrap */}
                <div className="ajax-modal-wrap fl-wrap" style={{ opacity: 1 }}>
                  <Button className="ajax-modal-close" onClick={closeModal}>
                    <i
                      className="fa-solid fa-circle-xmark"
                      style={{ alignSelf: "center" }}
                    />
                  </Button>
                  {/*ajax-modal-item*/}
                  <div className="ajax-modal-item fl-wrap">
                    <div className="ajax-modal-media fl-wrap">
                      <img
                        src={`https://bookingoda.com/image/${list1[0]?.RoomDetails[id]?.RoomPhotos[0]?.name}`}
                        alt=""
                      />
                      <div className="ajax-modal-title">
                        <div className="section-title-separator">
                          <span />
                        </div>
                        {}
                      </div>
                      <button
                        className="ajax-modal-photos-btn dynamic-gal"
                        onClick={() => openphotoModal1(id)}
                        data-dynamicpath="[{'src': 'images/gal/slider/1.jpg'}, {'src': 'images/gal/slider/4.jpg'},{'src': 'images/gal/slider/5.jpg'}]"
                      >
                        Photos (
                        <span>
                          {list1[0]?.RoomDetails[id]?.RoomPhotos?.length}
                        </span>
                        )
                      </button>
                    </div>
                    <div className="ajax-modal-list fl-wrap">
                      <ul>
                        <li>
                          <i className="fas fa-user-alt" />
                          <h5>
                            <span>{list1[0]?.RoomDetails[id]?.RoomStay}</span>{" "}
                            Persons
                          </h5>
                        </li>
                        <li>
                          <i className="fas fa-chalkboard" />
                          <h5>
                            <span>
                              {list1[0]?.RoomDetails[id]?.RoomSize}{" "}
                              {list1[0]?.RoomDetails[id]?.RoomMeasure}
                            </span>{" "}
                            Ft²
                          </h5>
                        </li>
                        <li>
                          <i className="fas fa-bath" />
                          <h5>
                            <span>
                              {list1[0]?.RoomDetails[id]?.PrivateBathroom ===
                              "Yes"
                                ? "Private bathroom"
                                : "Shared bathroom"}
                            </span>{" "}
                            Bathroom
                          </h5>
                        </li>
                        <li>
                          <i className="fas fa-hand-holding-usd" />
                          <h5>
                            <span>
                              Rs.{list1[0]?.RoomDetails[id]?.RoomPrice}
                            </span>{" "}
                            / Per Night
                          </h5>
                        </li>
                      </ul>
                    </div>
                    <div className="ajax-modal-details fl-wrap">
                      {/*ajax-modal-details-box*/}
                      <div className="ajax-modal-details-box">
                        <h3>Details</h3>
                        <p>
                          {list1[0]?.RoomDetails[id]?.Roomname[0]?.value} has
                          accommodations with{" "}
                          {list1[0]?.RoomDetails[id] &&
                            list1[0]?.RoomDetails[id] &&
                            list1[0]?.RoomDetails[id]?.General &&
                            Object.entries(list1[0]?.RoomDetails[id]?.General)
                              ?.filter(([key, value]) => value === true)
                              ?.map(([key]) => key)
                              ?.join(", ")}{" "}
                          The Room units come with{" "}
                          {list1[0]?.RoomDetails[id]?.RoomUnit?.map(
                            (item) => item.value + " beds"
                          ).join(", ")}{" "}
                          and feature a fully equipped furniture{" "}
                          {list1[0]?.RoomDetails[id] &&
                            list1[0]?.RoomDetails[id] &&
                            list1[0]?.RoomDetails[id]?.Food &&
                            Object.entries(list1[0]?.RoomDetails[id]?.Food)
                              ?.filter(([key, value]) => value === true)
                              ?.map(([key]) => key)
                              ?.join(", ")}{" "}
                          , and{" "}
                          {list1[0]?.RoomDetails[id]?.PrivateBathroom === "Yes"
                            ? "a Private"
                            : "no shared"}{" "}
                          bathroom with{" "}
                          {list1[0]?.RoomDetails[id] &&
                            list1[0]?.RoomDetails[id] &&
                            list1[0]?.RoomDetails[id]?.BathroomItems &&
                            Object.entries(
                              list1[0]?.RoomDetails[id]?.BathroomItems
                            )
                              ?.filter(([key, value]) => value === true)
                              ?.map(([key]) => key)
                              ?.join(", ")}{" "}
                        </p>
                      </div>
                      {/*ajax-modal-details-box end*/}
                      {/*ajax-modal-details-box*/}
                      <div className="ajax-modal-details-box">
                        {/* <h3>Room Amenities</h3> */}
                        <div className="listing-features fl-wrap">
                          {/* <ul>
                          <li>
                            <i className="fas fa-wifi" /> Free WiFi
                          </li>
                          <li>
                            <i className="fas fa-glass-martini-alt" /> Mini Bar
                          </li>
                          <li>
                            <i className="fas fa-snowflake" />
                            Air Conditioner
                          </li>
                          <li>
                            <i className="fas fa-tv" />
                            <span>Tv Inside</span>
                          </li>
                          <li>
                            <i className="fas fa-concierge-bell" /> Breakfast
                          </li>
                          <li>
                            <i className="fas fa-paw" /> Pet Friendly
                          </li>
                        </ul> */}

                          <h3
                            style={{ marginBottom: "-20px", marginTop: "5px" }}
                          >
                            Genereal Amenities :
                          </h3>
                          {list1[0]?.RoomDetails[id] &&
                            list1[0]?.RoomDetails[id] &&
                            list1[0]?.RoomDetails[id]?.General &&
                            Object.entries(list1[0]?.RoomDetails[id]?.General)
                              ?.filter(([key, value]) => value === true)
                              ?.map(([key]) => key)
                              ?.join(", ")}
                          <h3
                            style={{ marginBottom: "-20px", marginTop: "5px" }}
                          >
                            {" "}
                            Outdoors:{" "}
                          </h3>
                          {list1[0]?.RoomDetails[id] &&
                            list1[0]?.RoomDetails[id] &&
                            list1[0]?.RoomDetails[id]?.Outdoors &&
                            Object.entries(list1[0]?.RoomDetails[id]?.Outdoors)
                              ?.filter(([key, value]) => value === true)
                              ?.map(([key]) => key)
                              ?.join(", ")}
                          <h3
                            style={{ marginBottom: "-20px", marginTop: "5px" }}
                          >
                            {" "}
                            Kitchen Accessibility :
                          </h3>
                          {list1[0]?.RoomDetails[id] &&
                            list1[0]?.RoomDetails[id] &&
                            list1[0]?.RoomDetails[id]?.Food &&
                            Object.entries(list1[0]?.RoomDetails[id]?.Food)
                              ?.filter(([key, value]) => value === true)
                              ?.map(([key]) => key)
                              ?.join(", ")}
                        </div>
                      </div>
                      {/*ajax-modal-details-box end*/}
                      {/* <a
                      href="booking-single.html"
                      className="btn3 float-btn color2-bg"
                    >
                      Book Now
                      <i className="fas fa-caret-right" />
                    </a> */}
                      <Link
                        to="/cart"
                        state={{
                          item: list1[0],
                          RoomDetails:list1[0]?.RoomDetails[id],
                          date: location.state?.date,
                          Room: location.state?.options,
                          amount:
                            parseInt(list1[0]?.RoomDetails[id]?.RoomPrice) *
                            parseInt(location.state?.options.room) *
                            (daysDifference ? daysDifference : 1),
                        }}
                      >
                        <a
                          // href="booking-single.html"
                          className="btn3 float-btn color2-bg"
                          style={{ color: "white",backgroundColor:"green" }}
                        >
                          Book Now
                          <i className="fas fa-caret-right" />
                        </a>
                      </Link>
                    </div>
                  </div>
                  {/*ajax-modal-item*/}
                </div>
                {/*ajax-modal-wrap end */}
              </div>
            </Modal.Body>
          </Modal>
          <Modal
            show={photomodal}
            onHide={closephotoModal}
            backdrop="static"
            keyboard={false}
            size="lg"
          >
            <Modal.Body>
              <Button className="ajax-modal-close" onClick={closephotoModal}>
                <i
                  className="fa-solid fa-circle-xmark"
                  style={{ alignSelf: "center" }}
                />
              </Button>
              <div className="hotelImages">
                {list1[0]?.Photos?.map((media, index) =>
                  renderMedia(media, index)
                )}
              </div>
            </Modal.Body>
          </Modal>
          <Modal
            show={photomodal1}
            onHide={closephotoModal1}
            backdrop="static"
            keyboard={false}
            size="lg"
          >
            <Modal.Body>
              <Button className="ajax-modal-close" onClick={closephotoModal1}>
                <i
                  className="fa-solid fa-circle-xmark"
                  style={{ alignSelf: "center" }}
                />
              </Button>
              <div className="hotelImages">
                {list1[0]?.RoomDetails[id]?.RoomPhotos?.map((media, index) =>
                  renderMedia1(media, index)
                )}
              </div>
            </Modal.Body>
          </Modal>
          <Modal
            show={Calendar}
            onHide={closeCalendar}
            backdrop="static"
            keyboard={false}
            size="lg"
          >
            <Modal.Body>
              <Button className="ajax-modal-close" onClick={closeCalendar}>
                <i
                  className="fa-solid fa-circle-xmark"
                  style={{ alignSelf: "center" }}
                />
              </Button>
              <RemainingRoomsCalendar remainingRoomsData={list1[0]?.RoomDetails} />
            </Modal.Body>
          </Modal>
          <Footer />
        </div>
      </div>
    );
  }
};

export default GoogleApiWrapper({
  apiKey: "AIzaSyCom4BCfTVsw3o22AYg69Y7EovmLfV_Alw",
})(Hotel);
