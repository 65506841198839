import React, { useState, useEffect } from "react";
import "./community.scoped.css";
import { BiCommentDetail } from "react-icons/bi";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";

import axios from "axios";
import moment from "moment";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { debounce } from "lodash";
import { Button, Container, Form, Modal } from "react-bootstrap";
import Weather1 from "../../pages/hotel/Weather1";
import { faCancel, faClipboardCheck, faComputerMouse, faFile, faRightFromBracket, faUser, faUsers } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Navbar from "../navbar/Navbar";
import pics from "../../assets/user.png"
const API_KEY = "46a9246bebba16d42b36aac3fc3ba8af";
function Com_Home() {
  const [text, setText] = useState('');
  const [images, setImages] = useState(null);
  const [videos, setVideos] = useState();
  const catresponsive = {
    0: {
      items: 1,
    },
    600: {
      items: 1,
    },
    1000: {
      items: 1,
    },
  };
  const [posts, setPosts] = useState([]);
  const [comments, setComments] = useState([]);
  const [showComments, setShowComments] = useState("");
  const [showMoreComments, setShowMoreComments] = useState("");
  const [getUser, setgetUser] = useState([]);
  const formdata = new FormData();
  const user = JSON.parse(sessionStorage.getItem("user"));
  const [textcontent, setTextContent] = useState("");
  const [selectedpost, setselectedpost] = useState({});
  const [music, setMusic] = useState();
  const [image, setImage] = useState();
  const [video, setVideo] = useState();
  const [photo, setPhoto] = useState();
  const [uploadShorts, setUploadshorts] = useState();
  const [typecomments, settypecomments] = useState("");
  const [comments_image, setComments_image] = useState();
  // require("./community.css");
  const [userdetails, setuserdetails] = useState({});
  // //console.log("uploadShorts =>", uploadShorts);
  const [offers, setoffers] = useState([]);
  const getoffers = async () => {
    let res = await axios.get("https://bookingoda.com/api/admin/getalloffers");
    if ((res.status = 200)) {
      //console.log(res);
      setoffers(res.data?.offers);
    }
  };
  useEffect(() => {
 
    getoffers();
  }, []);
  const [storiescreated, setstoriescreated] = useState([]);

  useEffect(() => {
    getstoriescreated();
  }, []);
  const [show3, setShow3] = useState(false);
  const handleClose3 = () => setShow3(false);
  const handleShow3 = (item) => {
    setShow3(true);
   
  };
  const getstoriescreated = async () => {
    let res = await axios.get(
      'https://bookingoda.com/api/user/getallcommunityStories',
    );
    if (res.status === 200) {
      // console.log(res.data);
      setstoriescreated(res.data.communityStories);
    }
  };
  const state = {
    responsive: {
      0: {
        items: 2,
      },
      450: {
        items: 2,
      },
      600: {
        items: 3,
      },
      1000: {
        items: 4,
      },
    },
  };

  const CreatePost = async (e) => {
    e.preventDefault();
    formdata.append("userId", user?._id);
    formdata.append("text", textcontent);
    formdata.append("image", image);
    formdata.append("photo", photo);
    formdata.append("video", video);
    formdata.append("music", music);
    try {
      const config = {
        url: "/user/addPost",
        method: "post",
        baseURL: "https://bookingoda.com/api",
        hearder: { "content-type": "multipart/form-data" },
        data: formdata,
      };
      await axios(config).then(function (res) {
        if (res.status === 200) {
          //console.log(res.data);
          // alert("success");
          window.location.reload();
        }
      });
    } catch (error) {
      //console.log(error.res);
      alert("Something went wrong");
    }
  };
  const handleSubmit = async () => {
   


    const formData = new FormData();
    formData.append('text', text);
    formData.append('userId', user?._id);

    if (images) {
      formData.append('image',  images ); // Attach image file
    }
    if (videos) {
      formData.append('image', videos); // Attach image file
    }
    try {
      const config = {
        url: '/user/addcommunityStories',
        method: 'post',
        baseURL: 'https://bookingoda.com/api',
        headers: {'Content-Type': 'multipart/form-data'}, // Correct header name
        data: formData,
      };
  
      const response = await axios(config);
      if (response.status === 200) {
       
        alert('Story added');
        setText('');
       setShow3(false)
      
      }
    } catch (error) {
      console.log(error);
      alert('Something went wrong!!! Try again');
    }
  };
  const AddComments = async (e) => {
    e.preventDefault();
    formdata.append("postId", selectedpost._id);
    formdata.append("userId", user?._id);
    formdata.append("commentsText", typecomments);
    formdata.append("comments_Image", comments_image);
    try {
      const config = {
        url: "/user/addComments",
        method: "post",
        baseURL: "https://bookingoda.com/api",
        hearder: { "content-type": "multipart/form-data" },
        data: formdata,
      };
      await axios(config).then(function (res) {
        if (res.status === 200) {
          //console.log(res.data);
          // alert("comments added");
          settypecomments("");
          setComments_image();
          getPost();
          // window.location.reload(); ipo check pannu
        }
      });
    } catch (error) {
      //console.log(error.res);
      alert("Something went wrong!!! try again");
    }
  };
  const onShare = async (id) => {
    try {
      if (navigator.share) {
        await navigator.share({
          url: `https://mukkatis.com/shared/${id}`,
        });
        console.log('URL shared successfully');
      } else {
        // Web Share API not supported, handle fallback
        console.log('Web Share API is not supported in this browser');
        // You can provide alternative sharing methods here, e.g., copy to clipboard
      }
    } catch (error) {
      console.error('Error sharing URL:', error);
    }
  };
  const Addlike = async (e, id) => {
    e.preventDefault();
    try {
      const config = {
        url: "/user/addlike",
        method: "post",
        baseURL: "https://bookingoda.com/api",
        // hearder: { "content-type": "multipart/form-data" },
        data: {
          postId: id,
          userId: user?._id,
        },
      };
      await axios(config).then(function (res) {
        if (res.status === 200) {
          //console.log(res.data);
          // alert("comments added");
          getPost();
          // window.location.reload(); ipo check pannu
        }
      });
    } catch (error) {
      //console.log(error.res);
      alert("Something went wrong!!! try again");
    }
  };

  const unlike = async (e, id) => {
    e.preventDefault();

    try {
      const config = {
        url: "/user/unlike",
        method: "post",
        baseURL: "https://bookingoda.com/api",
        // hearder: { "content-type": "multipart/form-data" },
        data: {
          postId: id,
          userId: user?._id,
        },
      };
      await axios(config).then(function (res) {
        if (res.status === 200) {
          //console.log(res.data);
          // alert("comments added");
          getPost();
          // window.location.reload(); ipo check pannu
        }
      });
    } catch (error) {
      //console.log(error.res);
      alert("Something went wrong!!! try again");
    }
  };

  useEffect(() => {
    getPost();
    getComments();
    getAllUser();
    getuser();
  }, []);

  const getPost = async () => {
    let res = await axios.get("https://bookingoda.com/api/user/getPost");
    if (res.status === 200) {
      //console.log("getPost==>", res.data);
      setPosts(res.data.data);
    }
  };
  console.log(posts);
  const getComments = async () => {
    //ithu get comments ithu vena
    let res = await axios.get("https://bookingoda.com/api/user/getComments");
    if (res.status === 200) {
      //console.log("getComments==>", res.data);
      setComments(res.data.GetData);
    }
  };

  const getAllUser = async () => {
    let res = await axios.get("https://bookingoda.com/api/user/getalluser");
    if (res.status === 200) {
      //console.log("getAllUser==>", res.data);
      setgetUser(res.data.userData);
    }
  };

  const getuser = async () => {
    let res = await axios.get(
      "https://bookingoda.com/api/user/getmyprofile/" + user?._id
    );
    if (res.status === 200) {
      //console.log("getuser==>", res.data);
      setuserdetails(res.data.user);
    }
  };
  const dobString = user?.DOB ? user?.DOB : "20/11/1999";
  const parts = dobString?.split("/");
  const dob = new Date(parts[2], parts[1] - 1, parts[0]); // Month is zero-based

  const now = new Date();
  const age = now.getFullYear() - dob?.getFullYear();
  const [weatherData, setWeatherData] = useState(null);
  const [loaded, setLoaded] = useState(true);

  async function fetchWeatherData(cityName) {
    setLoaded(false);
    const API = `https://api.openweathermap.org/data/2.5/weather?q=${cityName}&units=metric&appid=${API_KEY}`;

    try {
      const response = await fetch(API);
      if (response.status === 200) {
        const data = await response.json();
        setWeatherData(data);
      } else {
        setWeatherData(null);
      }
      setLoaded(true);
    } catch (error) {
      console.error("Error fetching weather data:", error);
      setLoaded(true);
    }
  }

  const fetchData = async () => {
    try {
      const city = user?.city !== undefined ? user?.city : "Bengaluru";
      await fetchWeatherData(city);
      // Other code that depends on the fetched data
    } catch (error) {
      console.error("Error fetching weather data:", error);
    }
  };
  console.log(user?.city);
  useEffect(() => {
    fetchData();
  }, []); // The empty dependency array ensures that this effect runs only once on mount
  const debouncedFetchData = debounce(fetchData, 300);

  useEffect(() => {
    debouncedFetchData();
  }, [user?.city]); // The empty dependency array ensures that this effect runs only once on mount

  if (!loaded) {
    return (
      <div className="container">
        <div>Loading...</div>
      </div>
    );
  } else if (weatherData === null) {
    return (
      <div className="container" >
        {/* Render any additional UI or messages for when weather data is not available */}
      </div>
    );
  }
  const containerStyle = {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    padding: "20px",
  };

  const offerStyle = {
    marginBottom: "20px",
    borderRadius: "10px",

    boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
    width: "600px",
    height: "200px",
  };

  const imageStyle = {
    width: "300px",
    height: "auto",
  };

  const headingStyle = {
    fontSize: "24px",
    margin: "10px 0",
  };

  const paragraphStyle = {
    fontSize: "16px",
    lineHeight: "1.5",
  };
  return (<><Navbar/>
    <div className="communityhomestyle" style={{marginTop:"70px"}}>
      <div class="theme-layout">
        <div class="postoverlay"></div>
       
        <section style={{ paddingTop: "50px" }}>
          <div class="gap2 gray-bg">
            <div class="container">
              <div class="row">
                <div class="col-lg-12">
                  <div class="row merged20" id="page-contents">
                    <div class="col-lg-3">
                      <aside class="sidebar static left">
                        <div class="widget">
                       
                          {weatherData && (
                            <Weather1
                              weatherData={weatherData}
                              fetchWeatherData={fetchWeatherData}
                            />
                          )}
                          {/* <!-- Weather Widget --> */}
                        </div>
                        {/* <!-- weather widget--> */}
                        {/* <div class="widget whitish low-opacity">
                          <div
                            style={{
                              backgroundImage: "url(Images/resources/dob2.png)",
                            }}
                            class="bg-image"
                          ></div>
                          <div class="dob-head">
                            <img src="Images/resources/sug-page-5.jpg" alt="" />
                            <span>22nd Birthday</span>
                            <div class="dob">
                              <i>19</i>
                              <span>September</span>
                            </div>
                          </div>
                          <div class="dob-meta">
                            <figure>
                              <img src="img/dob-cake.gif" alt="" />
                            </figure>
                            <h6>
                              <a href="#" title="">
                                Lucy Carbel
                              </a>{" "}
                              valentine's birthday
                            </h6>
                            <p>
                              leave a message with your best wishes on his
                              profile.
                            </p>
                          </div>
                        </div> */}

                      

                        {/* <!-- recent activites --> */}
                        <div class="widget stick-widget">
                          <h4 class="widget-title">Shortcuts</h4>
                          <ul class="naves">
                            <li>
                            <FontAwesomeIcon style={{color:"#ff7d26",marginRight:"10px"}} icon={faClipboardCheck} />
                              <a href="#" title="">
                                News feed
                              </a>
                            </li>
                            <li>
                            <FontAwesomeIcon style={{color:"#ff7d26",marginRight:"10px"}} icon={faComputerMouse} />
                         
                              <a href="/community-message" title="">
                                Inbox
                              </a>
                            </li>
                            <li>
                            <FontAwesomeIcon style={{color:"#ff7d26",marginRight:"10px"}} icon={faUser} />
                              <a href="/community-about-us" title="">
                                Profile
                              </a>
                            </li>
                            <li>
                            <FontAwesomeIcon style={{color:"#ff7d26",marginRight:"10px"}} icon={faFile} />
                              <a href="/community-mypages" title="">
                                My Feeds
                              </a>
                            </li>
                            <li>
                            <FontAwesomeIcon style={{color:"#ff7d26",marginRight:"10px"}} icon={faUsers} />
                              <a href="/community-friends" title="">
                                friends
                              </a>
                            </li>
                            <li>
                            <FontAwesomeIcon style={{color:"#ff7d26",marginRight:"10px"}} icon={faUsers} />
                              <a href="/community-reqs" title="">
                                Friend Requests
                              </a>
                            </li>

                            <li>
                            <FontAwesomeIcon style={{color:"#ff7d26",marginRight:"10px"}} icon={faRightFromBracket} />
                              <a  href="/bookingoda" title="">
                                Logout
                              </a>
                            </li>
                          </ul>
                        </div>
                        {/* <!-- Shortcuts --> */}
                      </aside>
                    </div>
                    {/* <!-- Main --> */}
                    <div class="col-lg-6">
                      <div class="central-meta postbox">
                        <span class="create-post">Create post</span>
                        <div class="new-postbox">
                           <figure>
                           {userdetails?.profileImage ?   <img
                              src={`https://bookingoda.com/admin/${userdetails?.profileImage}`}
                              alt=""
                              style={{ width: "25%" }}
                            /> :  <img
                            src={pics}
                            alt=""
                            style={{ width: "15%" }}
                          />}
                          </figure>
                          <div class="newpst-input">
                            <form method="post">
                              <textarea
                                rows="2"
                                placeholder="Share some what you are thinking?"
                                type="text"
                                onChange={(e) => setTextContent(e.target.value)}
                              ></textarea>
                            </form>
                          </div>
                          <div class="attachments">
                            {image != undefined ? (
                              <img
                                src={URL.createObjectURL(image)}
                                style={{ height: "150px", width: "50%" }}
                              />
                            ) : null}

                            {video != undefined ? (
                              <video width="200" height="200" controls>
                                <source
                                  src={URL.createObjectURL(video)}
                                  type="video/mp4"
                                />
                              </video>
                            ) : null}
                            <ul>
                              <li>
                                <i class="fas fa-image"></i>
                                <label class="fileContainer">
                                  <input
                                    type="file"
                                    multiple
                                    onChange={(e) =>
                                      setImage(e.target.files[0])
                                    }
                                  />
                                </label>
                              </li>
                              <li>
                                <i class="fas fa-video-camera"></i>
                                <label class="fileContainer">
                                  <input
                                    type="file"
                                    onChange={(e) =>
                                      setVideo(e.target.files[0])
                                    }
                                  />
                                </label>
                              </li>
                              <li>
                                <i class="fas fa-camera"></i>
                                <label class="fileContainer">
                                  <input
                                    type="file"
                                    onChange={(e) =>
                                      setPhoto(e.target.files[0])
                                    }
                                  />
                                </label>
                              </li>
                            </ul>

                            <button class="post-btn" onClick={CreatePost}>
                              Post
                            </button>
                          </div>
                        </div>
                      </div>

                      <div class="central-meta">
                        <span class="create-post">
                          Recent Stories{" "}
                          {/* <a href="#" title="">
                            See All
                          </a> */}
                        </span>
                        <div class="story-postbox">
                          <div className="story-container">
                          
                            <div class="col-lg-3 col-md-3 col-sm-3">
                              <div class="story-box">
                                <figure>
                                {user.profileImage ? (
                                      <img
                                        
                                        src={`https://bookingoda.com/admin/${user.profileImage}`}
                                        
                                      />
                                    ): (
                                  <img src="img/story.jpg" alt="" />)}
                                  <span>Add Your Story</span>
                                </figure>
                                <div
                                  class="story-thumb"
                                  data-toggle="tooltip"
                                  title="Add Your Story"
                                >
                                  <i class="fas fa-plus" onClick={handleShow3}></i>
                                  <label class="fileContainer">
                                    <input
                                      type="file"
                                      style={{
                                        left: "5%",
                                        cursor: "pointer",
                                      }}
                                      onChange={(e) =>
                                        setUploadshorts(e.target.value)
                                      }
                                    />
                                  </label>
                                </div>
                              </div>
                              
                            </div>
                            <div >
                              <div className="story-wrapper">
                                {storiescreated.map((item) => (
                                  <div key={item.id} className="story-card">
                                    {item.image?.endsWith(".jpg") ||
                                    item.image?.endsWith(".jpeg") ||
                                    item.image?.endsWith(".png") ? (
                                      <a href={`https://bookingoda.com/admin/${item?.image}`} target="_blank">
                                      <img
                                        className="story-image"
                                        src={`https://bookingoda.com/admin/${item?.image}`}
                                        alt="Story"
                                      />
                                      </a>
                                    ) : item.image?.endsWith(".mp4") ? (
                                      <div className="video-playerstory">
                                         <a href={`https://bookingoda.com/admin/${item?.image}`} target="_blank">
                                        <video className="story-image" controls>
                                          <source
                                            src={`https://bookingoda.com/admin/${item?.image}`}
                                            type="video/mp4"
                                          />
                                          Your browser does not support the
                                          video tag.
                                        </video></a>
                                      </div>
                                    ) : null}
                                    {item.userId?.profileImage && (
                                      <img
                                        className="profile-imagestory"
                                        src={`https://bookingoda.com/admin/${item.userId.profileImage}`}
                                        alt="Profile"
                                      />
                                    )}
                                  </div>
                                ))}
                              </div>
                            </div>
                          </div>
                          
                        </div>
                       
                      </div>
                     
                      <div class="adb" style={{ display: "inline-block" }}>
                        <div class="adv-baner">
                         
                          <div style={containerStyle} className="offer-card-group">
          <OwlCarousel
          
            loop
            margin={10}
            nav={true}
            responsive={catresponsive}
            autoplayTimeout={5000}
            autoplay={true}
            autoHeightClass="owl-height"
          >
            {" "}
            {offers.map((offer, index) => (
              <div
                key={index}
                style={{
                  ...offerStyle,
                  background: `url('https://bookingoda.com/image/${offer.offers}')`,
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                }}
              >
                <a href={offer.link} title="">
                  <div
                    style={{
                      padding: "20px",
                      backgroundColor: "rgba(0, 0, 0, 0.5)",
                    }}
                  >
                    <h1 style={{ ...headingStyle, color: "#fff" }}>
                      {offer.Heading}
                    </h1>
                    <h1 style={{ ...paragraphStyle, color: "#fff" }}>
                      {offer.Description}
                    </h1>
                  </div>
                </a>
              </div>
            ))}
          </OwlCarousel>
        </div>
                        </div>
                      </div>
                      <div class="loadMore">
                        {posts?.map((de) => (
                          <div class="central-meta item">
                            <div class="user-post">
                              <div class="friend-info">
                                <figure>
                                 {de?.user[0]?.profileImage ? <img
                                    src={`https://bookingoda.com/admin/${de?.user[0]?.profileImage}`}
                                    alt=""
                                  /> : <img
                                    src={pics}
                                    alt=""
                                  />}
                                </figure>
                                <div class="friend-name">
                                
                                  <ins>
                                    <a href="#" title="">
                                      {de.user[0]?.name}
                                    </a>{" "}
                                    Post Album
                                  </ins>
                                  <span>
                                    <i class="fas fa-globe"></i> published:{" "}
                                    {moment(de?.createdAt).format(
                                      "MMMM Do YYYY, h:mm:ss a"
                                    )}
                                  </span>
                                </div>
                                <div class="post-meta">
                                  <p>{de.text}</p>
                                  <figure>
                                    <div class="img-bunch">
                                      <div class="row">
                                        {de.image ? (
                                          <div class="col-lg-6 col-md-6 col-sm-6">
                                            <figure>
                                              <a
                                                href={`https://bookingoda.com/admin/${de?.image}`}
                                                title=""
                                                data-toggle="modal"
                                                data-target="#img-comt"
                                                target={"_blank"}
                                              >
                                                <img
                                                  src={`https://bookingoda.com/admin/${de?.image}`}
                                                  alt=""
                                                />
                                              </a>
                                            </figure>
                                          </div>
                                        ) : (
                                          ""
                                        )}

                                        {de.video ? (
                                          <div class="col-lg-6 col-md-6 col-sm-6">
                                            <figure>
                                              <a
                                                href={`https://bookingoda.com/admin/${de?.video}`}
                                                title=""
                                                target="_blank"
                                              >
                                                <video width="100%" controls>
                                                  <source
                                                    src={`https://bookingoda.com/admin/${de?.video}`}
                                                    type="video/mp4"
                                                  />
                                                  Your browser does not support
                                                  the video tag.
                                                </video>
                                              </a>
                                            </figure>
                                          </div>
                                        ) : (
                                          ""
                                        )}
                                      </div>
                                    </div>
                                  </figure>
                                  <div class="we-video-info">
                                    <ul>
                                      <li>
                                        {de?.love?.find(
                                          (item) => item.userId === user?._id
                                        )?.userId === user?._id ? (
                                          <span
                                            class="likes heart"
                                            title="Like/Dislike"
                                            onClick={(e) => unlike(e, de._id)}
                                          >
                                            <i
                                              class="fa-solid fa-heart"
                                              style={{ color: "red" }}
                                            ></i>
                                            {/* ❤ */}
                                            <ins>{de?.love?.length}</ins>
                                          </span>
                                        ) : (
                                          <span
                                            class="likes heart"
                                            title="Like/Dislike"
                                            onClick={(e) => Addlike(e, de._id)}
                                          >
                                            <i class="fa-solid fa-heart"></i>
                                            {/* ❤ */}
                                            <ins>{de?.love?.length}</ins>
                                          </span>
                                        )}
                                      </li>
                                      <li>
                                        <span class="comment" title="Comments">
                                          <i
                                            class="fas fa-commenting"
                                            onClick={() =>
                                              setShowComments(de._id)
                                            }
                                          ></i>
                                          <ins>{de?.comment?.length} </ins>
                                        </span>
                                      </li>

                                      <li>
                                        <span>
                                          <div
                                            class="share-pst"
                                        
                                            title="Share"
                                            onClick={() => onShare(de._id)}
                                          >
                                            <i class="fas fa-share-alt"></i>
                                          </div>
                                        </span>
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                                {showComments === de._id ? (
                                  <div
                                    class="coment-area"
                                    style={{ display: "block" }}
                                  >
                                    <ul class="we-comet">
                                      {showMoreComments === de._id ? (
                                        de?.commentusers?.map((cm, i) => (
                                          <li>
                                            <div class="comet-avatar">
                                              <img
                                                src={`https://bookingoda.com/admin/${de?.commentusers[i]?.profileImage}`}
                                                alt=""
                                              />
                                            </div>
                                            <div class="we-comment">
                                              <h5>
                                                <a href="#" title="">
                                                  {" "}
                                                  {
                                                    de?.commentusers[i]
                                                      ?.username
                                                  }
                                                </a>
                                              </h5>
                                              <p>
                                                {" "}
                                                {
                                                  de?.comment[i]?.commentsText
                                                }{" "}
                                              </p>
                                              <img
                                                src={`https://bookingoda.com/admin/${de?.comment[i]?.comments_Image}`}
                                                alt=""
                                                style={{
                                                  width: "25%",
                                                  display: "flex",
                                                }}
                                              />
                                              <div class="inline-itms">
                                                <span>
                                                  {moment(
                                                    de?.comment[i]
                                                      ?.comments_date
                                                  ).format(
                                                    "MMMM Do YYYY, h:mm:ss a"
                                                  )}
                                                </span>
                                              </div>
                                            </div>
                                          </li>
                                        ))
                                      ) : (
                                        <li>
                                          <div class="comet-avatar">
                                            <img
                                              src={`https://bookingoda.com/admin/${de?.commentusers[0]?.profileImage}`}
                                              alt=""
                                            />
                                          </div>
                                          <div class="we-comment">
                                            <h5>
                                              <a href="#" title="">
                                                {" "}
                                                {de?.commentusers[0]?.username}
                                              </a>
                                            </h5>
                                            <p>
                                              {" "}
                                              {
                                                de?.comment[0]?.commentsText
                                              }{" "}
                                            </p>
                                            <img
                                              src={`https://bookingoda.com/admin/${de?.comment[0]?.comments_Image}`}
                                              alt=""
                                              style={{
                                                width: "25%",
                                                display: "flex",
                                              }}
                                            />
                                            <div class="inline-itms">
                                              <span>
                                                {moment(
                                                  de?.comment[0]?.comments_date
                                                ).format(
                                                  "MMMM Do YYYY, h:mm:ss a"
                                                )}
                                              </span>
                                            </div>
                                          </div>
                                        </li>
                                      )}

                                      <li>
                                        <a
                                          onClick={() =>
                                            setShowMoreComments(de._id)
                                          }
                                          title=""
                                          class="showmore underline"
                                        >
                                          more comments+
                                        </a>
                                      </li>
                                      <li class="post-comment">
                                        <div class="comet-avatar">
                                          <img
                                            src={`https://bookingoda.com/admin/${user?.profileImage}`}
                                            alt=""
                                          />
                                        </div>
                                        <div
                                          class="post-comt-box"
                                          onClick={() => setselectedpost(de)}
                                        >
                                          <form method="post">
                                            <textarea
                                              placeholder="Post your comment"
                                              value={typecomments}
                                              onChange={(e) =>
                                                settypecomments(e.target.value)
                                              }
                                            ></textarea>

                                            <button
                                              onClick={(e) => AddComments(e)}
                                            >
                                              Post
                                            </button>
                                          </form>
                                        </div>
                                      </li>
                                    </ul>
                                  </div>
                                ) : (
                                  ""
                                )}
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                    {/* <!-- centerl meta --> */}
                    <div class="col-lg-3">
                      <aside class="sidebar static right">
                        <div class="widget">
                          <h4 class="widget-title">Profile intro</h4>
                          <ul class="short-profile">
                            <li>
                              <span>about</span>
                              <p>
                                Hi, {user?.aboutMe}, i am {age} years old and
                                worked as a {user?.occupation}
                              </p>
                            </li>
                            <li>
                              <span>fav tv show</span>
                              <p>
                                {user?.favouriteSeries} ,{user?.favouriteMovies}
                              </p>
                            </li>
                            <li>
                              <span>favourit music</span>
                              <p>{user?.FavouriteSongs}</p>
                            </li>
                          </ul>
                        </div>
                      </aside>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <div class="bottombar">
          <div class="container">
            <div class="row">
              <div class="col-md-12">
                <span class="copyright">
                  © Booking-Oda 2023. All rights reserved.
                </span>
                <i>
                  <img src="Images/credit-cards.png" alt="" />
                </i>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="side-panel">
        <h4 class="panel-title">General Setting</h4>
        <form method="post">
          <div class="setting-row">
            <span>use night mode</span>
            <input type="checkbox" id="nightmode1" />
            <label
              for="nightmode1"
              data-on-label="ON"
              data-off-label="OFF"
            ></label>
          </div>
          <div class="setting-row">
            <span>Notifications</span>
            <input type="checkbox" id="switch22" />
            <label
              for="switch22"
              data-on-label="ON"
              data-off-label="OFF"
            ></label>
          </div>
          <div class="setting-row">
            <span>Notification sound</span>
            <input type="checkbox" id="switch33" />
            <label
              for="switch33"
              data-on-label="ON"
              data-off-label="OFF"
            ></label>
          </div>
          <div class="setting-row">
            <span>My profile</span>
            <input type="checkbox" id="switch44" />
            <label
              for="switch44"
              data-on-label="ON"
              data-off-label="OFF"
            ></label>
          </div>
          <div class="setting-row">
            <span>Show profile</span>
            <input type="checkbox" id="switch55" />
            <label
              for="switch55"
              data-on-label="ON"
              data-off-label="OFF"
            ></label>
          </div>
        </form>
        <h4 class="panel-title">Account Setting</h4>
        <form method="post">
          <div class="setting-row">
            <span>Sub users</span>
            <input type="checkbox" id="switch66" />
            <label
              for="switch66"
              data-on-label="ON"
              data-off-label="OFF"
            ></label>
          </div>
          <div class="setting-row">
            <span>personal account</span>
            <input type="checkbox" id="switch77" />
            <label
              for="switch77"
              data-on-label="ON"
              data-off-label="OFF"
            ></label>
          </div>
          <div class="setting-row">
            <span>Business account</span>
            <input type="checkbox" id="switch88" />
            <label
              for="switch88"
              data-on-label="ON"
              data-off-label="OFF"
            ></label>
          </div>
          <div class="setting-row">
            <span>Show me online</span>
            <input type="checkbox" id="switch99" />
            <label
              for="switch99"
              data-on-label="ON"
              data-off-label="OFF"
            ></label>
          </div>
          <div class="setting-row">
            <span>Delete history</span>
            <input type="checkbox" id="switch101" />
            <label
              for="switch101"
              data-on-label="ON"
              data-off-label="OFF"
            ></label>
          </div>
          <div class="setting-row">
            <span>Expose author name</span>
            <input type="checkbox" id="switch111" />
            <label
              for="switch111"
              data-on-label="ON"
              data-off-label="OFF"
            ></label>
          </div>
        </form>
      </div>
      {/* <!-- side panel --> */}

      <div class="popup-wraper2">
        <div class="popup post-sharing">
          <span class="popup-closed">
            <i class="ti-close"></i>
          </span>
          <div class="popup-meta">
            <div class="popup-head">
              <select
                data-placeholder="Share to friends..."
                multiple
                class="chosen-select multi"
              >
                <option>Share in your feed</option>
                <option>Share in friend feed</option>
                <option>Share in a page</option>
                <option>Share in a group</option>
                <option>Share in message</option>
              </select>
              <div class="post-status">
                <span>
                  <i class="fas fa-globe"></i>
                </span>
                <ul>
                  <li>
                    <a href="#" title="">
                      <i class="fas fa-globe"></i> Post Globaly
                    </a>
                  </li>
                  <li>
                    <a href="#" title="">
                      <i class="fas fa-user"></i> Post Private
                    </a>
                  </li>
                  <li>
                    <a href="#" title="">
                      <i class="fas fa-user-plus"></i> Post Friends
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div class="postbox">
              <div class="post-comt-box">
                <form method="post">
                  <input
                    type="text"
                    placeholder="Search Friends, Pages, Groups, etc...."
                  />
                  <textarea placeholder="Say something about this..."></textarea>
                  <div class="add-smiles">
                    <span title="add icon" class="em em-expressionless"></span>
                    <div class="smiles-bunch">
                      <i class="em em---1"></i>
                      <i class="em em-smiley"></i>
                      <i class="em em-anguished"></i>
                      <i class="em em-laughing"></i>
                      <i class="em em-angry"></i>
                      <i class="em em-astonished"></i>
                      <i class="em em-blush"></i>
                      <i class="em em-disappointed"></i>
                      <i class="em em-worried"></i>
                      <i class="em em-kissing_heart"></i>
                      <i class="em em-rage"></i>
                      <i class="em em-stuck_out_tongue"></i>
                    </div>
                  </div>

                  <button type="submit"></button>
                </form>
              </div>
              <figure>
                <img src="Images/resources/share-post.jpg" alt="" />
              </figure>
              <div class="friend-info">
                <figure>
                  <img alt="" src="Images/resources/admin.jpg" />
                </figure>
                <div class="friend-name">
                  <ins>
                    <a title="" href="time-line.html">
                      Jack Carter
                    </a>{" "}
                    share{" "}
                    <a title="" href="#">
                      link
                    </a>
                  </ins>
                  <span>
                    Yesterday with @Jack Piller and @Emily Stone at the concert
                    of # Rock'n'Rolla in Ontario.
                  </span>
                </div>
              </div>
              <div class="share-to-other">
                <span>Share to other socials</span>
                <ul>
                  <li>
                    <a class="facebook-color" href="#" title="">
                      <i class="fas fa-facebook-square"></i>
                    </a>
                  </li>
                  <li>
                    <a class="twitter-color" href="#" title="">
                      <i class="fas fa-twitter-square"></i>
                    </a>
                  </li>
                  <li>
                    <a class="dribble-color" href="#" title="">
                      <i class="fas fa-dribbble"></i>
                    </a>
                  </li>
                  <li>
                    <a class="instagram-color" href="#" title="">
                      <i class="fas fa-instagram"></i>
                    </a>
                  </li>
                  <li>
                    <a class="pinterest-color" href="#" title="">
                      <i class="fas fa-pinterest-square"></i>
                    </a>
                  </li>
                </ul>
              </div>
              <div class="copy-email">
                <span>Copy & Email</span>
                <ul>
                  <li>
                    <a href="#" title="Copy Post Link">
                      <i class="fas fa-link"></i>
                    </a>
                  </li>
                  <li>
                    <a href="#" title="Email this Post">
                      <i class="fas fa-envelope"></i>
                    </a>
                  </li>
                </ul>
              </div>
              <div class="we-video-info">
                <ul>
                  <li>
                    <span
                      title=""
                      data-toggle="tooltip"
                      class="views"
                      data-original-title="views"
                    >
                      <i class="fas fa-eye"></i>
                      <ins>1.2k</ins>
                    </span>
                  </li>
                  <li>
                    <span
                      title=""
                      data-toggle="tooltip"
                      class="views"
                      data-original-title="views"
                    >
                      <i class="fas fa-share-alt"></i>
                      <ins>20k</ins>
                    </span>
                  </li>
                </ul>
                <button class="main-btn color" data-ripple="">
                  Submit
                </button>
                <button class="main-btn cancel" data-ripple="">
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- share popup --> */}

      <div class="popup-wraper3">
        <div class="popup">
          <span class="popup-closed">
            <i class="ti-close"></i>
          </span>
          <div class="popup-meta">
            <div class="popup-head">
              <h5>Report Post</h5>
            </div>
            <div class="Rpt-meta">
              <span>We're sorry something's wrong. How can we help?</span>
              <form method="post" class="c-form">
                <div class="form-radio">
                  <div class="radio">
                    <label>
                      <input type="radio" name="radio" checked="checked" />
                      <i class="check-box"></i>It's spam or abuse
                    </label>
                  </div>
                  <div class="radio">
                    <label>
                      <input type="radio" name="radio" />
                      <i class="check-box" />
                      It breaks r/technology's rules
                    </label>
                  </div>
                  <div class="radio">
                    <label>
                      <input type="radio" name="radio" />
                      <i class="check-box" />
                      Not Related
                    </label>
                  </div>
                  <div class="radio">
                    <label>
                      <input type="radio" name="radio" />
                      <i class="check-box" />
                      Other issues
                    </label>
                  </div>
                </div>
                <div>
                  <label>Write about Report</label>
                  <textarea
                    placeholder="write someting about Post"
                    rows="2"
                  ></textarea>
                </div>
                <div>
                  <button data-ripple="" type="submit" class="main-btn">
                    Submit
                  </button>
                  <a href="#" data-ripple="" class="main-btn3 cancel">
                    Close
                  </a>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- report popup --> */}

      <div class="popup-wraper1">
        <div class="popup direct-mesg">
          <span class="popup-closed">
            <i class="ti-close"></i>
          </span>
          <div class="popup-meta">
            <div class="popup-head">
              <h5>Send Message</h5>
            </div>
            <div class="send-message">
              <form method="post" class="c-form">
                <input type="text" placeholder="Sophia" />
                <textarea placeholder="Write Message"></textarea>
                <button type="submit" class="main-btn">
                  Send
                </button>
              </form>
              <div class="add-smiles">
                <div class="uploadimage">
                  <i class="fas fa-image"></i>
                  <label class="fileContainer">
                    <input type="file" />
                  </label>
                </div>
                <span title="add icon" class="em em-expressionless"></span>
                <div class="smiles-bunch">
                  <i class="em em---1"></i>
                  <i class="em em-smiley"></i>
                  <i class="em em-anguished"></i>
                  <i class="em em-laughing"></i>
                  <i class="em em-angry"></i>
                  <i class="em em-astonished"></i>
                  <i class="em em-blush"></i>
                  <i class="em em-disappointed"></i>
                  <i class="em em-worried"></i>
                  <i class="em em-kissing_heart"></i>
                  <i class="em em-rage"></i>
                  <i class="em em-stuck_out_tongue"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- send message popup --> */}

      <div class="modal fade" id="img-comt">
        <div class="modal-dialog">
          <div class="modal-content">
            {/* <!-- Modal Header --> */}
            <div class="modal-header">
              <button type="button" class="close" data-dismiss="modal">
                ×
              </button>
            </div>

            {/* <!-- Modal body --> */}
            <div class="modal-body">
              <div class="row">
                <div class="col-lg-8">
                  <div class="pop-image">
                    <div class="pop-item">
                      <figure>
                        <img src="Images/resources/blog-detail.jpg" alt="" />
                      </figure>
                    </div>
                  </div>
                </div>
                <div class="col-lg-4">
                  <div class="user">
                    <figure>
                      <img src="Images/resources/user1.jpg" alt="" />
                    </figure>
                    <div class="user-information">
                      <h4>
                        <a href="#" title="">
                          Danile Walker
                        </a>
                      </h4>
                      <span>2 hours ago</span>
                    </div>
                    <a href="#" title="Follow" data-ripple="">
                      Follow
                    </a>
                  </div>
                  <div class="we-video-info">
                    <ul>
                      <li>
                        <div title="Like/Dislike" class="likes heart">
                          ❤ <span>2K</span>
                        </div>
                      </li>
                      <li>
                        <span title="Comments" class="comment">
                          <i class="fas fa-commenting"></i>
                          <ins>52</ins>
                        </span>
                      </li>

                      <li>
                        <span>
                          <a title="Share" href="#" class="">
                            <i class="fas fa-share-alt"></i>
                          </a>
                          <ins>20</ins>
                        </span>
                      </li>
                    </ul>
                    <div class="users-thumb-list">
                      <a
                        href="#"
                        title=""
                        data-toggle="tooltip"
                        data-original-title="Anderw"
                      >
                        <img src="Images/resources/userlist-1.jpg" alt="" />
                      </a>
                      <a
                        href="#"
                        title=""
                        data-toggle="tooltip"
                        data-original-title="frank"
                      >
                        <img src="Images/resources/userlist-2.jpg" alt="" />
                      </a>
                      <a
                        href="#"
                        title=""
                        data-toggle="tooltip"
                        data-original-title="Sara"
                      >
                        <img src="Images/resources/userlist-3.jpg" alt="" />
                      </a>
                      <a
                        href="#"
                        title=""
                        data-toggle="tooltip"
                        data-original-title="Amy"
                      >
                        <img src="Images/resources/userlist-4.jpg" alt="" />
                      </a>
                      <span>
                        <strong>You</strong>, <b>Sarah</b> and{" "}
                        <a title="" href="#">
                          24+ more
                        </a>{" "}
                        liked
                      </span>
                    </div>
                  </div>
                  <div style={{ display: "block" }} class="coment-area">
                    <ul class="we-comet">
                      <li>
                        <div class="comet-avatar">
                          <img alt="" src="Images/resources/nearly3.jpg" />
                        </div>
                        <div class="we-comment">
                          <h5>
                            <a title="" href="time-line.html">
                              Jason borne
                            </a>
                          </h5>
                          <p>
                            we are working for the dance and sing songs. this
                            video is very awesome for the youngster. please vote
                            this video and like our channel
                          </p>
                          <div class="inline-itms">
                            <span>1 year ago</span>
                            <a title="Reply" href="#" class="we-reply">
                              <i class="fas fa-reply"></i>
                            </a>
                            <a title="" href="#">
                              <i class="fas fa-heart"></i>
                              <span>20</span>
                            </a>
                          </div>
                        </div>
                      </li>
                      <li>
                        <div class="comet-avatar">
                          <img alt="" src="Images/resources/comet-4.jpg" />
                        </div>
                        <div class="we-comment">
                          <h5>
                            <a title="" href="time-line.html">
                              Sophia
                            </a>
                          </h5>
                          <p>
                            we are working for the dance and sing songs. this
                            video is very awesome for the youngster.
                            <i class="em em-smiley"></i>
                          </p>
                          <div class="inline-itms">
                            <span>1 year ago</span>
                            <a title="Reply" href="#" class="we-reply">
                              <i class="fas fa-reply"></i>
                            </a>
                            <a title="" href="#">
                              <i class="fas fa-heart"></i>
                              <span>20</span>
                            </a>
                          </div>
                        </div>
                      </li>
                      <li>
                        <div class="comet-avatar">
                          <img alt="" src="Images/resources/comet-4.jpg" />
                        </div>
                        <div class="we-comment">
                          <h5>
                            <a title="" href="time-line.html">
                              Sophia
                            </a>
                          </h5>
                          <p>
                            we are working for the dance and sing songs. this
                            video is very awesome for the youngster.
                            <i class="em em-smiley"></i>
                          </p>
                          <div class="inline-itms">
                            <span>1 year ago</span>
                            <a title="Reply" href="#" class="we-reply">
                              <i class="fas fa-reply"></i>
                            </a>
                            <a title="" href="#">
                              <i class="fas fa-heart"></i>
                              <span>20</span>
                            </a>
                          </div>
                        </div>
                      </li>
                      <li>
                        <a class="showmore underline" title="" href="#">
                          more comments+
                        </a>
                      </li>
                      <li class="post-comment">
                        <div class="comet-avatar">
                          <img alt="" src="Images/resources/nearly1.jpg" />
                        </div>
                        <div class="post-comt-box">
                          <form method="post">
                            <textarea placeholder="Post your comment"></textarea>
                            <div class="add-smiles">
                              <div class="uploadimage">
                                <i class="fas fa-image"></i>
                                <label class="fileContainer">
                                  <input type="file" />
                                </label>
                              </div>
                              <span
                                title="add icon"
                                class="em em-expressionless"
                              ></span>
                              <div class="smiles-bunch">
                                <i class="em em---1"></i>
                                <i class="em em-smiley"></i>
                                <i class="em em-anguished"></i>
                                <i class="em em-laughing"></i>
                                <i class="em em-angry"></i>
                                <i class="em em-astonished"></i>
                                <i class="em em-blush"></i>
                                <i class="em em-disappointed"></i>
                                <i class="em em-worried"></i>
                                <i class="em em-kissing_heart"></i>
                                <i class="em em-rage"></i>
                                <i class="em em-stuck_out_tongue"></i>
                              </div>
                            </div>

                            <button type="submit"></button>
                          </form>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>    <Modal
        show={show3}
        onHide={handleClose3}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
       
                      <div class="central-meta postbox">
                        <span class="create-post">Create Story</span>
                        <div class="new-postbox">
                          <figure>
                            <img
                              src={`https://bookingoda.com/admin/${userdetails?.profileImage}`}
                              alt=""
                              style={{ width: "25%" }}
                            />
                          </figure>
                          <div class="newpst-input">
                            <form method="post">
                              <textarea
                                rows="2"
                                placeholder="Share your story?"
                                type="text"
                                onChange={(e) => setText(e.target.value)}
                              ></textarea>
                            </form>
                          </div>
                          <div class="attachments">
                            {images != undefined ? (
                              <img
                                src={URL.createObjectURL(images)}
                                style={{ height: "150px", width: "50%" }}
                              />
                            ) : null}

                            {videos != undefined ? (
                              <video width="200" height="200" controls>
                                <source
                                  src={URL.createObjectURL(videos)}
                                  type="video/mp4"
                                />
                              </video>
                            ) : null}
                            <ul>
                              <li>
                                <i class="fas fa-image"></i>
                                <label class="fileContainer">
                                  <input
                                    type="file"
                                    multiple
                                    onChange={(e) =>
                                      setImages(e.target.files[0])
                                    }
                                  />
                                </label>
                              </li>
                              <li>
                                <i class="fas fa-video-camera"></i>
                                <label class="fileContainer">
                                  <input
                                    type="file"
                                    onChange={(e) =>
                                      setVideos(e.target.files[0])
                                    }
                                  />
                                </label>
                              </li>
                           
                            </ul>

                          
                          </div>
                        </div>
                      </div>

                     
                  
        </Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={handleClose3}>
            Cancel
          </Button>
          <Button variant="success" onClick={() => handleSubmit()}>
         
            Submit
          </Button>
        </Modal.Footer>
      </Modal></>
  );
}

export default Com_Home;

