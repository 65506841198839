import "./attraction.css";

import Footer from "../../components/footer/Footer";
import {
  faBed,
  faCalendarDays,
  faCar,
  faPerson,
  faUsersRays,
  faTaxi,
  faHouse,
  faHotel,
  faEarthAmericas,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { DateRange } from "react-date-range";

import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file
import { format } from "date-fns";
import { useLocation, useNavigate } from "react-router-dom";
import Logo from "../../assets/booking.jpeg";

import { Autocomplete, Box, Slider } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import { AiOutlineRight } from "react-icons/ai";
import { Link } from "react-router-dom";
import TextField from "@mui/material/TextField";
import Pagination from "@mui/material/Pagination";
import axios from "axios";
import Navbar from "../navbar/Navbar";

const Attraction = ({ type }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const itemsPerPage = 4; // Number of cards per page
  const [currentPage, setCurrentPage] = useState(1);
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const [destination, setdestination] = useState(location?.state?.destination ? location?.state?.destination : "");
  // Get the current page's items
  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };
  const Destination = [
    { label: "Chennai" },
    { label: "New York" },
    { label: "Dubai" },
    { label: "London" },
    { label: "Hubli" },
    { label: "America" },
  ];

  const Package = [
    { label: "1hrs 10km" },
    { label: "2hrs 20km" },
    { label: "3hrs 30km" },
    { label: "4hrs 40km" },
    { label: "5hrs 50km" },
    { label: "6hrs 60km" },
  ];

  const [Tourpackages, setTourpackages] = useState([]);
  const getTourPackages = async () => {
    try {
      const res = await axios.get(
        "https://bookingoda.com/api/admin/gettrippackages"
      );
      if (res.status === 200) {
        setTourpackages(
          res.data.getpackages.filter((item) => item.blockstatus !== true)
        );
      }
    } catch (error) {
      
    }
  };
  useEffect(() => {
    getTourPackages();
  }, []);
  
  const currentItems = Tourpackages.slice(startIndex, endIndex);
  
  return (
    <div className="home">
      <Navbar />
    

    
      <section>
        <div className="rows inner_banner inner_banner_3">
          <div className="containerpil">
            <div className="spe-title tit-inn-pg">
              <h1>
                Pilgrim <span>Vacations</span>{" "}
              </h1>
              <div className="title-line">
                <div className="tl-1" />
                <div className="tl-2" />
                <div className="tl-3" />
              </div>
              <p>Explore the best packages listed below</p>
            </div>
          </div>
        </div>
      </section>
      <Container>
        <div className="row justify-content-between">
          <div className="col-lg-12">
          <div class="containerpil inn-page-con-bg tb-space pad-bot-redu-5" id="inner-page-title">
            {destination !== ""
                  ? 
                  Tourpackages
                      .filter((item) => item.state === destination).map((item) => ( 
                          
              <div className="rows p2_2">
                <div className="col-md-6 col-sm-6 col-xs-12 p2_1">
                  <div className="band">
                  <div className='trip-offer'>{item?.tripdiscount}%off</div>
                  </div>
                  <div className="to-ho-hotel-con pack-new-box">
                    <div className="to-ho-hotel-con-1">
                      <img
                        src={`https://bookingoda.com/image/${item?.GalleryImage[0]?.image}`}
                        alt=""
                      />
                      <div className="hom-pack-deta">
                        <h2>{item?.tripname}</h2>
                        <h4>
                          <span>
                            ₹ {item?.offerprice} /{" "}
                            <span style={{ color: "black", fontSize: "18px" }}>
                              <s>₹ {item?.tripcost}</s>
                            </span>{" "}
                            <br />
                          </span>{" "}
                          {item?.tripduration}
                        </h4>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 col-sm-6 col-xs-12 p2">
                  <h3>{item?.tripname}</h3>
                  <p>{item?.tripdescription.slice(0, 200)}...</p>

                  <div className="featur">
                    <h4>Package Locations</h4>
                    <ul>
                      <li>
                        {item?.city},{item?.state},{item?.country}
                      </li>
                    </ul>
                  </div>
                  <div className="p2_book">
                    <Link
                      to={`/trippackagedetails/${item._id}`}
                      // state={{ item: item }}
                      className="text-decoration-none"
                    >
                      <button
                        className="header-search1"
                        style={{ width: "auto", height: "45px" }}
                      >
                        View Package
                      </button>
                    </Link>
                  </div>
                </div>
              </div>
            ))  :
              currentItems.map((item) => ( 
                
    <div className="rows p2_2">
      <div className="col-md-6 col-sm-6 col-xs-12 p2_1">
        <div className="band">
        <div className='trip-offer'>{item?.tripdiscount}%off</div>
        </div>
        <div className="to-ho-hotel-con pack-new-box">
          <div className="to-ho-hotel-con-1">
            <img
              src={`https://bookingoda.com/image/${item?.GalleryImage[0]?.image}`}
              alt=""
            />
            <div className="hom-pack-deta">
              <h2>{item?.tripname}</h2>
              <h4>
                <span>
                  ₹ {item?.offerprice} /{" "}
                  <span style={{ color: "black", fontSize: "18px" }}>
                    <s>₹ {item?.tripcost}</s>
                  </span>{" "}
                  <br />
                </span>{" "}
                {item?.tripduration}
              </h4>
            </div>
          </div>
        </div>
      </div>
      <div className="col-md-6 col-sm-6 col-xs-12 p2">
        <h3>{item?.tripname}</h3>
        <p>{item?.tripdescription.slice(0, 200)}...</p>

        <div className="featur">
          <h4>Package Locations</h4>
          <ul>
            <li>
              {item?.city},{item?.state},{item?.country}
            </li>
          </ul>
        </div>
        <div className="p2_book">
          <Link
            to={`/trippackagedetails/${item._id}`}
            // state={{ item: item }}
            className="text-decoration-none"
          >
            <button
              className="header-search1"
              style={{ width: "auto", height: "45px" }}
            >
              View Package
            </button>
          </Link>
        </div>
      </div>
    </div>
  ))}
            </div>
            <div className="blog-pagination">
                <nav>
                  <ul className="pagination page-item justify-content-center">
                    {Array.from({
                      length: Math.ceil(Tourpackages.length / itemsPerPage),
                    }).map((_, index) => (
                      <li
                        key={index}
                        className={`previtem ${
                          currentPage === index + 1 ? "active" : ""
                        }`}
                      >
                        <button
                          className="page-link"
                          onClick={() => handlePageChange(index + 1)}
                        >
                          <i className="fas fa-regular fa-arrow-right me-2" />{" "}
                          {index + 1}
                        </button>
                      </li>
                    ))}
                  </ul>
                </nav>
              </div>
          </div>
        </div>
      </Container>
     
        <Footer />
    
    </div>
  );
};

export default Attraction;
