import React, { useState } from "react";
import "./createaccount.css";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { BsEyeFill, BsFillEyeSlashFill } from "react-icons/bs";
import Success from '../../assets/success.png'
import Error from '../../assets/close.png'
import { Container, Form, Modal, Row, Table } from "react-bootstrap";
import { initializeApp } from "firebase/app";
import { getMessaging, getToken } from "firebase/messaging";

const firebaseConfig = {
  apiKey: "AIzaSyCKxU1uQtQhgOkBddkbe1NKsFSXJ_v4ico",
  authDomain: "mukkatis.firebaseapp.com",
  projectId: "mukkatis",
  storageBucket: "mukkatis.appspot.com",
  messagingSenderId: "255102460015",
  appId: "1:255102460015:web:68af0bb8f73057720ead9a",
  measurementId: "G-EQVQ4N72VJ"
};

const Signin = () => {
  const [deviceToken,setdeviceToken] =useState("");
  const app = initializeApp(firebaseConfig);
  const messaging = getMessaging(app);
function requestPermission() {
  console.log("Requesting permission...");
  Notification.requestPermission().then((permission) => {
    if (permission === "granted") {
      console.log("Notification permission granted.");
      getToken(messaging, {
        vapidKey:
          "BGuemogN_43tJcTzXHA03EpfZkZgS0nWo0vGao2tyI302BT4Cx2KcM5DAeYfAho2sEWE8cNV82qupr1_2zi6PGQ",
      }).then((currentToken) => {
        if (currentToken) {
          console.log("currentToken111: ", currentToken);
          setdeviceToken(currentToken)
        } else {
          console.log("Can not get token");
        }
      });
    } else {
      console.log("Do not have permission!");
    }
  });
}
requestPermission();
  const [successshow, setsuccessshow] = useState(false);
  const handlesuccessClose = () => setsuccessshow(false);
  const handlesuccess = () => 
    setsuccessshow(true);
   
    const [errorshow, seterrorshow] = useState(false);
    const handleerrorClose = () => seterrorshow(false);
    const handleerror = () => 
      seterrorshow(true);
  const [email, setEmail] = useState("");
  const [password, setpassword] = useState("");
  const [eye,seteye]=useState(true)
  let history = useNavigate();

  const onSubmit = async (e) => {
    e.preventDefault();
    try {
      const config = {
        url: "/vendorlogin",
        method: "post",
        baseURL: "https://bookingoda.com/api/admin",
        headers: { "content-type": "application/json" },
        data: { email: email, password: password ,deviceTokens:deviceToken,},
      };
      let res = await axios(config);
      if (res.status === 200) {
        if (res.data.success.blockstatus === true) {
          alert("You're blocked");
        } else {
        if (res.data) {
        sessionStorage.setItem("vendor", JSON.stringify(res.data.success));
        history("/property");
        // alert("Successfully Logged In");
        handlesuccess();
        // window.location.reload(true);
      } else {
        alert("Something Wrong");
      }
    }}} catch (error) {
      if (error.response) {
        // alert(error.response.data.error);
        alert("Invalid Email or Password")
      }
    }
  };
  return (
    <div id="root" class="bui-u-text-left bui_font_body">
      <div>
        <div class="">
          <div class="">
            <div class="partner-header">
              <header class=" aCWKa87CwyJQfbPP_yg6">
                <nav class="yC5_EodFadTqIIY8_YDx">
                  <div class="NrR_ZlI8bZYI3w06JKKb">
                    <div>
                    <div class="mobile-join-logo join_logo">
                <span style={{ color: "white",fontSize:30,marginTop:"40px"}}>Booking-Oda.com</span>
                </div>
                    </div>
                  </div>
                 
                </nav>
              </header>
            </div>
            <div className="app">
              <div className="access-container bui_font_body">
                <div className="access-panel-container">
                  <div className="access-panel nw-access-panel">
                    <div className="transition-container">
                      <div
                        className="animate-height"
                        style={{ height: "auto", overflow: "visible" }}
                      >
                        <div className="sliding-panel">
                          <div className="transition bui-panel-body">
                            <div className>
                              <div
                                tabIndex={-1}
                                className="page-header"
                                aria-labelledby="55f3c2ab-6578-48fd-9f51-10c6f4a82c01"
                                aria-describedby="fde5d809-fdeb-4aef-9659-c270ec6e6206"
                              >
                                <h1
                                  id="55f3c2ab-6578-48fd-9f51-10c6f4a82c01"
                                  className="bui_font_display_one bui_font_heading--bold bui-spacer--medium nw-step-header"
                                >
                                  Sign in to manage your property
                                </h1>
                                <div className="bui-spacer--largest" />
                              </div>
                            </div>
                            <form className="nw-signin">
                              <div className="input-wrapper-email bui-spacer--large nw-login-name">
                                <div className="sOewt4Y1yBOPAIUGcJwH wkTNdQjAfRVbKvFBiR1T">
                                  <label
                                    htmlFor="loginname"
                                    className="sOewt4Y1yBOPAIUGcJwH hAFaqujHGDhKOcPl6uUa _nwGprfLZfZgMFjsmap7"
                                  >
                                    <span>
                                      <span className="L08id9Rh757AqBBZobKh">
                                        Email
                                      </span>
                                    </span>
                                  </label>
                                  <div className="cTkJymCseykCmdCpB4JG">
                                    <div className="KBX_kI7wQ0ziDRM0x5Ig">
                                      <input
                                        type="text"
                                        name="loginname"
                                        className="ZMH9h0HCYH9GGNxHnXGJ"
                                        placeholder="Enter email here"
                                        onChange={(e) =>
                                          setEmail(e.target.value)
                                        }
                                        value={email}
                                       
                                      />
                                      <div className="KlWiFnWD2KyUjaUF1WAE" />
                                    </div>
                                  </div>
                                  <form class="nw-register" novalidate="">
                                     
                                     <div class="password-input-container bui-spacer--large">
                                       <div class="password-input-container bui-spacer--large">
                                         <div
                                           aria-live="polite"
                                           class="sOewt4Y1yBOPAIUGcJwH wkTNdQjAfRVbKvFBiR1T nw-password"
                                         >
                                           <label
                                             for="new_password"
                                             class="sOewt4Y1yBOPAIUGcJwH hAFaqujHGDhKOcPl6uUa _nwGprfLZfZgMFjsmap7"
                                           >
                                             <span>
                                               <span class="L08id9Rh757AqBBZobKh">
                                                 Password
                                               </span>
                                             </span>
                                           </label>
                                           {!eye ? (
                                    <div class="cTkJymCseykCmdCpB4JG">
                                      <div class="KBX_kI7wQ0ziDRM0x5Ig">
                                        <input
                                          type="text"
                                          name="new_password"
                                          class="ZMH9h0HCYH9GGNxHnXGJ"
                                          placeholder="Enter a password"
                                          id="new_password"
                                          onChange={(e) =>
                                            setpassword(e.target.value)
                                          }
                                          value={password}
                                        />
                                        <div class="KlWiFnWD2KyUjaUF1WAE"></div>
                                        <div class="ypuClFu1I0a0wmwCMKWV">
                                          <div class="ods6EhCCFo1GhPrxs58m reVLDpXAXDa8VaCE9Fpe">
                                            <BsEyeFill
                                              onClick={() => seteye(true)}
                                              fontSize={30}
                                              style={{ margin: 5 }}
                                            />
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  ) : (
                                    <div class="cTkJymCseykCmdCpB4JG">
                                      <div class="KBX_kI7wQ0ziDRM0x5Ig">
                                        <input
                                          type="password"
                                          name="new_password"
                                          class="ZMH9h0HCYH9GGNxHnXGJ"
                                          placeholder="Enter a password"
                                          id="new_password"
                                          onChange={(e) =>
                                            setpassword(e.target.value)
                                          }
                                          value={password}
                                        />
                                        <div class="KlWiFnWD2KyUjaUF1WAE"></div>
                                        <div class="ypuClFu1I0a0wmwCMKWV">
                                          <div class="ods6EhCCFo1GhPrxs58m reVLDpXAXDa8VaCE9Fpe">
                                            <BsFillEyeSlashFill
                                              onClick={() => seteye(false)}
                                              fontSize={30}
                                              style={{ margin: 5 }}
                                            />
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  )}
                                         </div>
                                       </div>
                                     </div>
                                    
                                   
                                   </form>
                                </div>
                              </div>
                          
                              <div  onClick={onSubmit}>
                                <button
                                  type="submit"
                                  class="Iiab0gVMeWOd4XcyJGA3 wPxWIS_rJCpwAWksE0s3 Ut3prtt_wDsi7NM_83Jc TuDOVH9WFSdot9jLyXlw EJWUAldA4O1mP0SSFXPm "
                                  style={{backgroundColor: "#70bbe9",borderColor:"#70bbe9",color:"white"}}  
                                  >
                                  <span class="yfCvx60qsR50VNBG15jF">Sign-In</span>  

                                
                                </button>
                              </div>
                              {/* <div className="u-text-center link-recovery-options">
                               
                                  <div
                                   
                                    className="Iiab0gVMeWOd4XcyJGA3 wPxWIS_rJCpwAWksE0s3 bIq8dzpVP4b9elGUVPWA fZddHa1seh1KCXQ0f65u EJWUAldA4O1mP0SSFXPm RM3d8T7hALYxY0QkqDIe"
                                    data-dv-event-id={0}
                                  >
                                    <span className="yfCvx60qsR50VNBG15jF">
                                      Having trouble signing in?
                                    </span>
                                  </div>
                               
                              </div> */}
                             
                              <a
                                className="nw-link-register"
                                data-ga-action="click"
                                data-ga-label="Create account"
                                href="/createaccount"
                                data-dv-event-id={2}
                              >
                                <button
                                  type="button"
                                  className="Iiab0gVMeWOd4XcyJGA3 wPxWIS_rJCpwAWksE0s3 UUMoIgvHWjWBfPzTW9Lg TuDOVH9WFSdot9jLyXlw EJWUAldA4O1mP0SSFXPm bvJ1qjCOOZTWBD88F3yW"
                                >
                                  <span className="yfCvx60qsR50VNBG15jF">
                                    Create your partner account
                                  </span>
                                </button>
                              </a>
                            </form>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="account-access__footer">
                <div className="u-text-center bui_font_caption portal_footer">
                  <div className="account_footer_terms footer-block">
                    By signing in or creating an account, you agree with our{" "}
                    <a
                     
                      className="bui_color_action nw-terms"
                      target="_blank"
                      rel="noopener noreferrer"
                      data-ga-action="click"
                      data-ga-label="Terms partner"
                    >
                      Terms &amp; conditions
                    </a>{" "}
                    and{" "}
                    <a
                     
                      className="bui_color_action nw-privacy"
                      target="_blank"
                      rel="noopener noreferrer"
                      data-ga-action="click"
                      data-ga-label="Privacy partner"
                    >
                      Privacy statement
                    </a>
                  </div>
                  <div className="access-footer-bottom bui_font_caption footer-block">
                    <div>
                      All rights reserved. <br />
                      Copyright (2006 - 2023) - Booking-Oda.com™{" "}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        show={successshow}
        onHide={handlesuccess}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton onClick={handlesuccessClose}></Modal.Header>
        <Modal.Body>
          <div className="text-center">
           <img src={Success}
              style={{ marginTop: "-12px" }}
              width="100px"/>
           
            <p>Successfully Added</p>
          </div>
        </Modal.Body>
     
      </Modal>
      <Modal
        show={errorshow}
        onHide={handleerror}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton onClick={handleerrorClose}></Modal.Header>
        <Modal.Body>
          <div className="text-center">
           <img src={Error}
              style={{ marginTop: "-12px" }}
              width="100px"/>
           
            <p>Error Occured</p>
          </div>
        </Modal.Body>
     
      </Modal>
    </div>
  );
};

export default Signin;
