import Header from "../../components/header/Header";
import Navbar from "../../components/navbar/Navbar";
import Featured from "../../components/featured/Featured";
import "./home1.css";
import PropertyList from "../../components/propertyList/PropertyList";
import FeaturedProperties from "../../components/featuredProperties/FeaturedProperties";
import MailList from "../../components/mailList/MailList";
import Footer from "../../components/footer/Footer";
import AboutImg from "../../assets/house.jpg";
import Logo from "../../assets/logo2.jpg"
import Booking from "../../assets/booking.jpeg"
import Mukkatis from "../../assets/arc.jpeg"
import Marketing from "../../assets/market.jpeg"

import { MdKeyboardArrowDown } from 'react-icons/md';
const Home1 = () => {
  return (
    // <div >
    //       <img className="logo" src={Logo} alt="" style={{height:"80px",width:"250px"}}/>
    //   <section className="home1" id="home">
    
    //     <div className="content">
    //       <h3>
    //     BookingOda
    //       </h3>
    //       <p>
    //       A lifetime of discounts? It's Genius.
    //       </p>
    //       <a href="/bookingoda" className="btn">
    //         Book Now
    //       </a>
    //     </div>
    //   </section>
    //   <section className="about" id="about">
    //     <h1 className="heading">
    //       <span>Architecture and construction</span> 
    //     </h1>

    //     <div className="row">
    //       <div className="image">
    //         <img src={AboutImg} alt="" />
    //       </div>

    //       <div className="content">
    //         <h3>Architecture and construction  provide a great way for architects to showcase their work to potential clients.</h3>
    //         <p>
    //           Lorem ipsum dolor sit amet consectetur adipisicing elit.
    //           Voluptatibus qui ea ullam, enim tempora ipsum fuga alias quae
    //           ratione a officiis id temporibus autem? Quod nemo facilis
    //           cupiditate. Ex, vel?
    //         </p>
    //         <p>
    //           Lorem ipsum dolor sit amet consectetur, adipisicing elit. Odit
    //           amet enim quod veritatis, nihil voluptas culpa! Neque consectetur
    //           obcaecati sapiente?
    //         </p>
    //         <a href="/architecture" className="btn">
    //           Show More
    //         </a>
    //       </div>
    //     </div>
    //   </section>
      
    // </div>
    <div class="line-wrapper">
			{/* <header id="line-header" class="">
				<div class="line-wrap">
					<div >
						<a href="#" class="line-logo-simple">
							<img alt="logo" src={Logo} style={{width:"200px"}}/>
						</a>
					</div>
				</div>
			</header> */}
		

			<div id="line-page-body">
				<div class="line-container">
					<div class="line-page-content">
						<div id="line-home" class="line-full line-center line-parallax bg1" data-type="background" data-speed="4" >
							{/* <div class="line-hello line-middle">
								<h1 data-scrollreveal="enter top over 0.5s after 0.3s" data-sr-init="true" data-sr-complete="true">Please 
									<span>Choose</span>
								</h1>
								<h2 data-scrollreveal="enter bottom over 0.5s after 0.3s" data-sr-init="true" data-sr-complete="true">Your Business</h2>
							</div> */}
							<div class="line-scroll-btn">
								<a href="#detials" class="line-reveal animated bounce">
									<i class="fas fa-chevron-down"></i>
                  {/* <MdKeyboardArrowDown size={34} color="white" style={{marginTop:"10px"}}/> */}
								</a>
							</div>
						</div>
					

						<div id="detials" class="line-full line-section line-cover line-center bg2">
							<div class="line-middle">
								
								<div class="line-wrap">
								<div class="line-hello line-middle">
								<h1 data-scrollreveal="enter top over 0.5s after 0.3s" data-sr-init="true" data-sr-complete="true"><span style={{color: "#009eca",fontSize:"50px",marginBottom:"20px"}}>Please</span> 
									<span style={{color: "#e74c3c",fontSize:"50px",marginBottom:"20px"}}>Choose</span>
								</h1>
								<h2 data-scrollreveal="enter bottom over 0.5s after 0.3s" data-sr-init="true" data-sr-complete="true"><span style={{color: "#fdf024",fontSize:"50px",marginBottom:"20px"}}>Your Business</span></h2>
							</div>
							</div>
							<div className="line-wrap1">
									<div class="one-third" data-scrollreveal="enter bottom over 0.5s after 0.3s" data-sr-init="true" data-sr-complete="true">
										<a href="/bookingoda" >
											<img class="line-thumbnail" src={Booking} alt="img" />
											<p></p>
											<h4>Booking-Oda.com</h4>
										</a>
									</div>
									<div class="one-third" data-scrollreveal="enter bottom over 0.5s after 0.5s" data-sr-init="true" data-sr-complete="true">
										<a href="/architecture">
											<img class="line-thumbnail" src={Mukkatis} alt="img" />
											<p></p>
											<h4> MAC ( Mukkatis Architecture & Constructions)</h4>
										</a>
									</div>
									<div class="one-third last" data-scrollreveal="enter bottom over 0.5s after 0.7s" data-sr-init="true" data-sr-complete="true">
										<a href="/marketing">
											<img class="line-thumbnail" src="" alt="img" />
											<p></p>
											<h4>Kavnit (Digital Marketing & App Development)</h4>
										</a>
									</div>
								</div>
							</div>
						</div>
					

		</div>
</div></div></div>
  );
};

export default Home1;
