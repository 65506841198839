import React from "react";
import "./property.css";
import Apartment from "../../assets/apartments.png";
import Apartments from "../../assets/buildings.png";
import location from "../../assets/location.png";

import mutilocation from "../../assets/mutilocation.png";
import Hotel from "../../assets/hotel.png";
import VendorHeader from "./Vendorheader";

const MultiApartmentType = () => {
  
  return (
    <div id="app-container">
      <div class="error-popup-container"></div>
      <div class="application">
      <VendorHeader />
        <div class="error-popup-container"></div>
        <div class="page-container">
          <div class="screens screens-animation-direction-backward">
            <div
              class="height-100 screen-change-enter-done"
              id="automation_id_screen_container_Owner"
            >
              <div class="ms-container">
                <div class="form-container">
                  <div class="f2cf178bcd screen-main-header d991e1216a">
                    <div class="f6431b446c e6208ee469 d0caee4251 screen-main-header__title">
                      How many apartments are you listing?
                    </div>
                  </div>
                  <div class="ffb9c3d6a3 wide-container spacing--4x">
                    <div class="selectable-box-group">
                      <div
                        class="selectable-box selectable-box--wide"
                        id="automation_id_choose_owner_type_single"
                        role="checkbox"
                        aria-checked="false"
                        tabindex="0"
                        data-testid="automation_id_choose_owner_type_single"
                        onClick={()=>window.location.assign("/apartment1")}
                      >
                        <div
                          class="c82435a4b8 a6ae3c2b40 a18aeea94d selectable-box-card"
                          style={{ padding: "30px" }}
                        >
                          <div class="aca0ade214 ebac6e22e9 f66f916626 c2931f4182 c27e5d305d db150fece4">
                            <div class="b817090550 b1e9f8a61d">
                              <div
                                class="b795ec59f5"
                                style={{ padding: "10%" }}
                              >
                                <div class="aea002392a">
                                  <div class="f8e5e86e33">
                                    <picture
                                      data-testid="SelectableBox-image"
                                      class="e5a3812a75 selectable-box-card__img"
                                      style={{ width: "50px", height: "50px" ,marginLeft:"-20px",marginTop:"-20px"}}
                                    >
                                      <img
                                        class="e3fa9175ee d354f8f44f ba6d792fd4 b1a5e281e7"
                                        src={Apartment}
                                        alt=""
                                        role="presentation"
                                        loading="lazy"
                                      />
                                    </picture>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="b817090550 d4e36dbd77">
                              <div class="selectable-box-card__text-container selectable-box-card-text--center">
                                <div
                                  data-testid="SelectableBox-title"
                                  class="f2cf178bcd selectable-box-card__text"
                                >
                                  <div class="a53cbfa6de e6208ee469 d0caee4251">
                                    One apartment
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        class="selectable-box selectable-box--wide selectable-box--selected"
                        id="automation_id_choose_owner_type_more_than_one"
                        role="checkbox"
                        aria-checked="true"
                        tabindex="0"
                        data-testid="automation_id_choose_owner_type_more_than_one"
                      >
                        <div
                          class="c82435a4b8 selectable-box-card"
                          style={{ padding: "30px" }}
                        >
                          <div class="aca0ade214 ebac6e22e9 f66f916626 c2931f4182 c27e5d305d db150fece4">
                            <div class="b817090550 b1e9f8a61d">
                              <div
                                class="b795ec59f5"
                                style={{ paddingTop: "10%" }}
                              >
                                <div class="aea002392a">
                                  <div class="f8e5e86e33">
                                    <picture
                                      data-testid="SelectableBox-image"
                                      class="e5a3812a75 selectable-box-card__img"
                                      style={{ width: "50px", height: "50px",marginTop:"-20px" }}
                                    >
                                      <img
                                        class="e3fa9175ee d354f8f44f ba6d792fd4 b1a5e281e7"
                                        src={Apartments}
                                        alt=""
                                        role="presentation"
                                        loading="lazy"
                                      />
                                    </picture>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="b817090550 d4e36dbd77">
                              <div class="selectable-box-card__text-container selectable-box-card-text--center">
                                <div
                                  data-testid="SelectableBox-title"
                                  class="f2cf178bcd selectable-box-card__text"
                                >
                                  <div class="a53cbfa6de e6208ee469 d0caee4251">
                                    Multiple apartments
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <p class="spacing--8x">
                      Are these properties in the same address or building?
                    </p>
                    <div class="selectable-box-group">
                      <div
                        class="selectable-box selectable-box--wide selectable-box--selected"
                        id="automation_id_choose_address_type_single"
                        role="checkbox"
                        aria-checked="true"
                        tabindex="0"
                        data-testid="automation_id_choose_address_type_single"
                      >
                        <div
                          class="c82435a4b8 selectable-box-card"
                          style={{ padding: "30px" }}
                        >
                          <div class="aca0ade214 ebac6e22e9 f66f916626 c2931f4182 c27e5d305d db150fece4">
                            <div class="b817090550 b1e9f8a61d">
                              <div
                                class="b795ec59f5"
                                style={{ paddingTop: "10%" }}
                              >
                                <div class="aea002392a">
                                  <div class="f8e5e86e33">
                                    <picture
                                      data-testid="SelectableBox-image"
                                      class="e5a3812a75 selectable-box-card__img"
                                      style={{ width: "50px", height: "50px",marginTop:"-20px" }}
                                    >
                                      <img
                                        class="e3fa9175ee d354f8f44f ba6d792fd4 b1a5e281e7"
                                        src={location}
                                        alt=""
                                        role="presentation"
                                        loading="lazy"
                                      />
                                    </picture>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="b817090550 d4e36dbd77">
                              <div class="selectable-box-card__text-container selectable-box-card-text--center">
                                <div
                                  data-testid="SelectableBox-title"
                                  class="f2cf178bcd selectable-box-card__text"
                                >
                                  <div class="a53cbfa6de e6208ee469 d0caee4251">
                                    Yes, these apartments are at the same
                                    address or building
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        class="selectable-box selectable-box--wide"
                        id="automation_id_choose_address_type_multiple"
                        role="checkbox"
                        aria-checked="false"
                        tabindex="0"
                        data-testid="automation_id_choose_address_type_multiple"
                      >
                        <div
                          class="c82435a4b8 a6ae3c2b40 a18aeea94d selectable-box-card"
                          style={{ padding: "30px" }}
                        >
                          <div class="aca0ade214 ebac6e22e9 f66f916626 c2931f4182 c27e5d305d db150fece4">
                            <div class="b817090550 b1e9f8a61d">
                              <div
                                class="b795ec59f5"
                                // style={{ paddingTop: "10%" }}
                              >
                                <div class="aea002392a">
                                  <div class="f8e5e86e33">
                                    <picture
                                      data-testid="SelectableBox-image"
                                      class="e5a3812a75 selectable-box-card__img"
                                      style={{ width: "50px", height: "50px",marginTop:"-20px" }}
                                    >
                                      <img
                                        class="e3fa9175ee d354f8f44f ba6d792fd4 b1a5e281e7"
                                        src={mutilocation}
                                        alt=""
                                        role="presentation"
                                        loading="lazy"
                                      />
                                    </picture>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="b817090550 d4e36dbd77">
                              <div class="selectable-box-card__text-container selectable-box-card-text--center">
                                <div
                                  data-testid="SelectableBox-title"
                                  class="f2cf178bcd selectable-box-card__text"
                                >
                                  <div class="a53cbfa6de e6208ee469 d0caee4251">
                                    No, these apartments are at different
                                    addresses or locations
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <label
                      class="property-number-label spacing--4x"
                      for="automation_id_number_of_properties"
                    >
                      <div class="bcdcb105b3">Number of properties</div>
                    </label>
                    <div class="property-number-input">
                      <div
                        data-testid="automation_id_number_of_properties"
                        class="a53cbfa6de ac9267e216 a20293ec70"
                      >
                        <div class="b9b84f4305">
                          <div class="e000754250">
                            <input
                              type="number" 
                              name="numberOfProperties"
                              class="eb46370fe1"
                              id="automation_id_number_of_properties"
                             
                            />
                            <div class="e7e5251f68"></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="form-cta">
                  <div>
                    <div class="form-buttons-container">
                      <div class="form-buttons-container--secondary" onClick={()=>window.history.back()
}  >
                        <button
                          aria-label="Back to previous step"
                          data-testid="FormButtonSecondary"
                          type="button"
                          class="a83ed08757 c21c56c305 bf0537ecb5 d691166b09 f671049264 d2529514af af7297d90d"
                        >
                          <span class="eedba9e88a">
                            <span
                              class="fcd9eec8fb c2cc050fb8"
                              aria-hidden="true"
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 24 24"
                                data-rtl-flip="true"
                              >
                                <path d="M15.0871 19.2361C14.9677 19.2367 14.8495 19.2134 14.7392 19.1676C14.629 19.1219 14.5289 19.0546 14.4451 18.9696L8.38799 12.9125C8.26332 12.7891 8.16475 12.6418 8.09815 12.4795C8.03156 12.3172 7.99829 12.1431 8.00034 11.9676C7.99246 11.6171 8.12303 11.2776 8.36376 11.0227L14.4208 4.96566C14.5912 4.79552 14.8221 4.69995 15.0629 4.69995C15.3037 4.69995 15.5346 4.79552 15.7049 4.96566C15.7907 5.04918 15.8588 5.14902 15.9053 5.25929C15.9518 5.36957 15.9758 5.48803 15.9758 5.60771C15.9758 5.72739 15.9518 5.84586 15.9053 5.95613C15.8588 6.0664 15.7907 6.16624 15.7049 6.24976L10.0113 11.9676L15.7292 17.6855C15.8149 17.769 15.883 17.8689 15.9295 17.9792C15.976 18.0894 16 18.2079 16 18.3276C16 18.4473 15.976 18.5657 15.9295 18.676C15.883 18.7863 15.8149 18.8861 15.7292 18.9696C15.6457 19.0551 15.5458 19.1228 15.4354 19.1686C15.3251 19.2144 15.2066 19.2374 15.0871 19.2361Z"></path>
                              </svg>
                            </span>
                          </span>
                        </button>
                      </div>
                      <div class="form-buttons-container--primary" onClick={()=>window.location.assign("/multiapartmenttype1")}>
                        <button
                          aria-label="Continue"
                          data-testid="FormButtonPrimary-enabled"
                          type="button"
                          class="a83ed08757 c21c56c305 a4c1805887 f671049264 d2529514af c082d89982"
                        >
                          <span class="e4adce92df">Continue</span>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            id="automation_id_screen_id_owner"
            style={{ display: "none" }}
          ></div>
        </div>
        <div
          id="automation_id_flow_Segmentation_Flow"
          style={{ display: "none" }}
        ></div>
        <div></div>
      </div>
    </div>
  );
};

export default MultiApartmentType;
