import React, { useState } from "react";
import { MenuFoldOutlined, MenuUnfoldOutlined } from "@ant-design/icons";
import {
  AiOutlineDashboard,
  AiOutlineShoppingCart,
  AiOutlineUser,
  AiOutlineBgColors,
} from "react-icons/ai";
import { RiCouponLine } from "react-icons/ri";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link } from "react-router-dom";
import { Outlet } from "react-router-dom";
import { ImBlog } from "react-icons/im";
import { IoIosNotifications } from "react-icons/io";
import { FaClipboardList, FaBloggerB, FaHotel, FaPlaceOfWorship } from "react-icons/fa";
import { SiBrandfolder } from "react-icons/si";
import { BiCategoryAlt } from "react-icons/bi";
import { Layout, Menu, theme } from "antd";
import { useNavigate } from "react-router-dom";
import Logo from "../../assets/logo2.jpg"
import Admin from "../../assets/admin.png"
import { MdAddToPhotos, MdApartment, MdBedroomParent } from "react-icons/md";
import { HiOutlineHomeModern } from "react-icons/hi2";
import { GiBarracksTent } from "react-icons/gi";
import { FaPowerOff } from "react-icons/fa";

import { IoCarSport } from "react-icons/io5";
import { BsGlobeCentralSouthAsia } from "react-icons/bs";


const { Header, Sider, Content } = Layout;
const MainLayoutVendor = () => {
  const vendor = JSON.parse(sessionStorage.getItem("vendor"));
  if (!vendor) {
    alert("Please Login");
    window.location.assign("/signin");
  }
  const [collapsed, setCollapsed] = useState(false);
  const [Expanded, setExpanded] = useState(false);

  const {
    token: { colorBgContainer },
  } = theme.useToken();
  const navigate = useNavigate();

  const logOut = () => {
    window.location.assign("/signin");
    window.sessionStorage.removeItem("vendor");
    alert("Successfully logout");
   
  };
  return (
    <Layout /* onContextMenu={(e) => e.preventDefault()} */ hasSider>
      <Sider trigger={null} collapsible collapsed={collapsed}
       style={{
        overflow: 'auto',
        height: '100vh',
      position:'fixed',
    
        left: 0,
        top: 0,
        bottom: 0,
      }}
       >
        <div className="logo">
          <h2 className="text-white fs-5 text-center py-3 mb-0">
            <span className="sm-logo">BO.com</span>
            {/* <span className="lg-logo">Mukkatis</span> */}
            <span className="lg-logo"   style={{marginTop:'-12px',fontSize:20,width:"130px",color:'#1677ff'}}>Booking-Oda.com</span>
            {/* <img
                    src={Logo}
                   style={{marginTop:'-12px'}}
                    width="130px"
                    className="lg-logo"
                    // style={{ height: "50px", borderRadius: "8px" }}
                  /> */}
          </h2>
        </div>
        <Menu
          theme="dark"
          mode="inline"
          defaultSelectedKeys={[""]}
          onClick={({ key }) => {
            if (key == "signout") {
            } else {
              navigate(key);
            }
          }}
          items={[
            {
              key: "",
              icon: <AiOutlineDashboard className="fs-4" />,
              label: "Dashboard",
            },
          
            {
              key: "apartmentlistvendor",
              icon: <MdApartment className="fs-4" />,
              label: "Apartments",
            },
            {
              key: "homelistvendor",
              icon: <HiOutlineHomeModern className="fs-4" />,
              label: "Homestay",
            },
            {
              key: "hotellistvendor",
              icon: <FaHotel className="fs-4" />,
              label: "Hotels/Resorts",
            },
            {
              key: "alternativelistvendor",
              icon: <GiBarracksTent className="fs-4" />,
              label: "Alternative Property",
            },
            
            {
              key: "bookstaysvendor",
              icon: <MdBedroomParent  className="fs-4" />,
              label: "Booked Stays",
            },
            {
              key: "tourlistvendor",
              icon: <FaPlaceOfWorship className="fs-4" />,
              label: "Pilgrim Vacations List",
            },
            {
              key: "pilgrimordervendor",
              icon: <FaPlaceOfWorship className="fs-4" />,
              label: "Pilgrim Vacations Orders",
            },
            {
              key: "internationalvendor",
              icon: <BsGlobeCentralSouthAsia className="fs-4" />,
              label: "International Packages",
            },
            {
              key: "bookinternationalvendor",
              icon: <BsGlobeCentralSouthAsia className="fs-4" />,
              label: "International Customer Booking Packages",
            },
            
          ]}
        />
      </Sider>
      <Layout className="site-layout" style={{
          marginLeft: 250,
        }}>
        <Header
          className="d-flex justify-content-between ps-1 pe-5"
          style={{
            padding: 0,
            background: colorBgContainer,
          }}
        >
          {React.createElement(
            collapsed ? MenuUnfoldOutlined : MenuFoldOutlined,
            {
              className: "trigger",
              onClick: () => setCollapsed(!collapsed),
            }
          )}
          <div className="d-flex gap-4 align-items-center">
           

            <div className="d-flex gap-3 align-items-center dropdown">
              <button className="pink-btn" onClick={()=>window.location.assign("/property")}>
            <MdAddToPhotos /> List your property</button>
              <div>
                <img
                  width={32}
                  height={32}
                  src={Admin}
                  alt=""
                />
              </div>
              <div
                role="button"
                id="dropdownMenuLink"
                data-bs-toggle="dropdown"
                aria-expanded="false"
               
              >
                <h5 className="mb-0">{vendor.name}</h5>
                <p className="mb-0">{vendor.email}</p>
              </div>
           
            
                <li>
                  
                    <FaPowerOff onClick={logOut}  style={{ height: "auto", lineHeight: "20px",color:'red',cursor:'pointer' }}/>
                 
                </li>
              
            </div>
          </div>
        </Header>
        <Content
          style={{
            margin: "24px 16px",
            padding: 24,
            // minHeight: 280,
            height:"100%",
            background: colorBgContainer,
          }}
        >
          <ToastContainer
            position="top-right"
            autoClose={250}
            hideProgressBar={false}
            newestOnTop={true}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            theme="light"
          />
          <Outlet />
        </Content>
      </Layout>
    </Layout>
  );
};
export default MainLayoutVendor;
