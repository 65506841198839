import React, { useCallback, useState } from "react";
import "./property.css";
import Apartment from "../../assets/apartments.png";
import Apartments from "../../assets/buildings.png";

import Homestay from "../../assets/homestay.png";
import Hotel from "../../assets/hotel.png";
import VendorHeader from "./Vendorheader";
import { useLocation, useNavigate } from "react-router-dom";
import { useDropzone } from "react-dropzone";

import axios from "axios";
import { PlusOutlined } from "@ant-design/icons";
import { Button, Modal, Tooltip, Upload } from "antd";
import { DeleteOutlined } from "@material-ui/icons";
import { MdDelete } from "react-icons/md";
const getBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
const Homephoto = () => {
  const { state } = useLocation();
  ////console.log(state);
  const history = useNavigate();
  const [uploadedImage, setUploadedImage] = useState([]);

  // const onDrop = (acceptedFiles) => {
  //   const imageFile = acceptedFiles[0];
  //   uploadedImage.push(imageFile);
  //   // const reader = new FileReader();

  //   // reader.onload = () => {
  //   //   uploadedImage.push(reader.result);
  //   // };

  //   // reader.readAsDataURL(imageFile);
  // };
  const handleFileChange = (event) => {
    const files = event.target.files;
    const newSelectedImages = [...uploadedImage, ...files];
    setUploadedImage(newSelectedImages);
  };
  // const { getRootProps, getInputProps } = useDropzone({ onDrop });
  // const deleteImage = (index) => {
  //   const updatedImages = uploadedImage.filter((_, i) => i !== index);
  //   setUploadedImage(updatedImages);
  // };
  const deleteImage = (index) => {
    const updatedImages = [...uploadedImage];
    updatedImages.splice(index, 1);
    setUploadedImage(updatedImages);
  };
  const [uploadedVideos, setUploadedVideos] = useState([]);

  const handleVideoChange = (event) => {
    const files = event.target.files;
    const newSelectedVideos = [...uploadedVideos, ...files];
    setUploadedVideos(newSelectedVideos);
  };
  const handleDeleteVideo = (index) => {
    const updatedVideos = [...uploadedVideos];
    updatedVideos.splice(index, 1);
    setUploadedVideos(updatedVideos);
  };
  ////console.log(uploadedImage);

  const HandleClick = () => {
    if (uploadedImage.length < 5) {
      alert("Please add atleast 5 photos");
   
      }else{
    history("/homeprice", {
      state: {
        uploadedImage: uploadedImage,
        uploadedVideos: uploadedVideos,
      },
    });
  }}
  return (
    <div
      data-capla-component="b-registration-joinapp15min-fe/"
      data-capla-namespace="b-registration-joinapp15min-feaYGUODfA"
    >
      <div id="app-container">
        <div class="error-popup-container"></div>
        <div class="application">
          <VendorHeader />
          <div class="error-popup-container"></div>

          <div className="page-container">
            <div className="screens screens-animation-direction-forward">
              <div
                className="height-100"
                id="automation_id_screen_container_Photos"
              >
                <div className="height-100 screens-animation-direction-backward">
                  <div className="ms-container">
                    <div className="form-container">
                      <div className="f2cf178bcd screen-main-header d991e1216a">
                        <div className="f6431b446c e6208ee469 d0caee4251 screen-main-header__title">
                          What does your place look like?
                        </div>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                        }}
                      >
                        <div className="upload-photos_container">
                          <div className="drag-and-drop__highlight">
                            <div className="ffb9c3d6a33 wide-container min-height ota-container">
                              <div
                                className="spacing--4x"
                                data-testid="NumberOfPhotosRequiredMessage-container"
                              >
                                <label
                                  htmlFor="photoFileInput"
                                  className="add-photo-sub-info font_body_2"
                                >
                                  <div className="a3332d346a">
                                    Upload at least 5 photos of your property.{" "}
                                  </div>{" "}
                                  <div className="a53cbfa6de">
                                    The more you upload, the more likely you are
                                    to get bookings. You can add more later.{" "}
                                  </div>
                                </label>
                              </div>
                              <div className="take-photo take-photo--with-drag-and-drop">
                                <div className="drag-and-drop">
                                  <div
                                    style={{
                                      border: "2px dashed #cccccc",
                                      borderRadius: "4px",
                                      padding: "20px",
                                      textAlign: "center",
                                      display: "flex",
                                      flexDirection: "row",
                                      cursor: "pointer",
                                    }}
                                  >
                                    <input
                                      type="file"
                                      multiple
                                      accept="image/*"
                                      onChange={(e) => handleFileChange(e)}
                                    />
                                    <p>Click to select one or more images</p>
                                  </div>
                                </div>
                              </div>
                              <div className="take-photo take-photo--with-drag-and-drop">
                                <div className="drag-and-drop">
                                  <div
                                    style={{
                                      textAlign: "center",
                                      marginTop: "10px",
                                    }}
                                  >
                                    {uploadedImage &&
                                      uploadedImage.map((item, index) => (
                                        <div
                                          key={index}
                                          style={{
                                            display: "inline-block",
                                            marginRight: "10px",
                                            marginTop: "10px",
                                          }}
                                        >
                                          <img
                                            src={URL.createObjectURL(item)}
                                            alt="Uploaded"
                                            style={{ maxWidth: "50px" }}
                                          />

                                          <Tooltip title="delete">
                                            <MdDelete
                                              color="red"
                                              onClick={() => deleteImage(index)}
                                            />
                                          </Tooltip>
                                        </div>
                                      ))}
                                  </div>
                                </div>
                              </div>
                              <div>
                                <div className="spacing--4x" />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="upload-photos_container">
                          <div className="drag-and-drop__highlight">
                            <div className="ffb9c3d6a33 wide-container min-height ota-container">
                              <div
                                className="spacing--4x"
                                data-testid="NumberOfPhotosRequiredMessage-container"
                              >
                                <label
                                  htmlFor="photoFileInput"
                                  className="add-photo-sub-info font_body_2"
                                >
                                  <div className="a3332d346a">
                                    Upload videos of your property.{" "}
                                  </div>{" "}
                                </label>
                              </div>
                              <div className="take-photo take-photo--with-drag-and-drop">
                                <div className="drag-and-drop">
                                  <div
                                    style={{
                                      border: "2px dashed #cccccc",
                                      borderRadius: "4px",
                                      padding: "20px",
                                      textAlign: "center",
                                      display: "flex",
                                      flexDirection: "row",
                                      cursor: "pointer",
                                    }}
                                  >
                                    <input
                                      type="file"
                                      accept="video/*"
                                      multiple
                                      onChange={handleVideoChange}
                                    />
                                    <p>Click to select video</p>
                                  </div>
                                </div>
                              </div>
                              <div className="take-photo take-photo--with-drag-and-drop">
                                <div className="drag-and-drop">
                                  <div
                                    style={{
                                      textAlign: "center",
                                      marginTop: "10px",
                                    }}
                                  >
                                    {uploadedVideos.map((video, index) => (
                                      <div key={index}>
                                        <p>Video {index + 1}</p>
                                        <video
                                          width="200"
                                          height="200"
                                          controls
                                        >
                                          <source
                                            src={URL.createObjectURL(video)}
                                            type="video/mp4"
                                          />
                                          Your browser does not support the
                                          video tag.
                                        </video>

                                        <Tooltip title="delete">
                                          <MdDelete
                                            color="red"
                                            onClick={() =>
                                              handleDeleteVideo(index)
                                            }
                                          />
                                        </Tooltip>
                                      </div>
                                    ))}
                                  </div>
                                </div>
                              </div>
                              <div>
                                <div className="spacing--4x" />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="form-cta">
                      <div>
                        <div className="form-buttons-container">
                          <div
                            className="form-buttons-container--secondary"
                            onClick={() => window.history.back()}
                          >
                            <button
                              aria-label="Back to previous step"
                              data-testid="FormButtonSecondary"
                              type="button"
                              className="a83ed08757 c21c56c305 bf0537ecb5 d691166b09 f671049264 d2529514af af7297d90d"
                            >
                              <span className="eedba9e88a">
                                <span
                                  className="fcd9eec8fb c2cc050fb8"
                                  aria-hidden="true"
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 24 24"
                                    data-rtl-flip="true"
                                  >
                                    <path d="M15.0871 19.2361C14.9677 19.2367 14.8495 19.2134 14.7392 19.1676C14.629 19.1219 14.5289 19.0546 14.4451 18.9696L8.38799 12.9125C8.26332 12.7891 8.16475 12.6418 8.09815 12.4795C8.03156 12.3172 7.99829 12.1431 8.00034 11.9676C7.99246 11.6171 8.12303 11.2776 8.36376 11.0227L14.4208 4.96566C14.5912 4.79552 14.8221 4.69995 15.0629 4.69995C15.3037 4.69995 15.5346 4.79552 15.7049 4.96566C15.7907 5.04918 15.8588 5.14902 15.9053 5.25929C15.9518 5.36957 15.9758 5.48803 15.9758 5.60771C15.9758 5.72739 15.9518 5.84586 15.9053 5.95613C15.8588 6.0664 15.7907 6.16624 15.7049 6.24976L10.0113 11.9676L15.7292 17.6855C15.8149 17.769 15.883 17.8689 15.9295 17.9792C15.976 18.0894 16 18.2079 16 18.3276C16 18.4473 15.976 18.5657 15.9295 18.676C15.883 18.7863 15.8149 18.8861 15.7292 18.9696C15.6457 19.0551 15.5458 19.1228 15.4354 19.1686C15.3251 19.2144 15.2066 19.2374 15.0871 19.2361Z" />
                                  </svg>
                                </span>
                              </span>
                            </button>
                          </div>
                          <div
                            className="form-buttons-container--primary"
                            onClick={HandleClick}
                          >
                            <button
                              aria-label="Continue"
                              data-testid="FormButtonPrimary-disabled"
                              type="button"
                              // disabled
                              className="a83ed08757 c21c56c305 a4c1805887 f671049264 d2529514af c082d89982"
                            >
                              <span className="e4adce92df">Continue</span>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            id="automation_id_flow_Segmentation_Flow"
            style={{ display: "none" }}
          ></div>
          <div></div>
        </div>
      </div>
      <img
        alt=""
        height="1"
        width="1"
        style={{ display: "none" }}
        src="https://booking.com/track_join_app_landing?progress_status=2"
      />
    </div>
  );
};

export default Homephoto;
