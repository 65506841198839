import React, { useState } from "react";
import "./createaccount.css";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect } from "react";

const ContactDetails = () => {
  const [name, setname] = useState("");
  const [Lname, setLname] = useState("");
  const [mobile, setmobile] = useState("");
  const [code, setcode] = useState("+91");
  const [isValid, setIsValid] = useState(true);
  const history = useNavigate();

  const validatePhone = (inputPhone) => {
    // You can use a regular expression for basic phone number validation
    const phoneRegex = /^[0-9]{10}$/; // Assumes a 10-digit phone number
    const isValidPhone = phoneRegex.test(inputPhone);
    setIsValid(isValidPhone);
    return isValidPhone;
  };

  const Handlepress = () => {
    if (!name) {
      alert("Please enter your first name");
    } else if (!Lname) {
      alert("Please enter your last name");
    } else if (!mobile) {
      alert("Please enter a phone number and continue.");
    } else if (!validatePhone(mobile)) {
      alert("Please enter a valid 10-digit phone number.");
    } else {
      history(
        `/createpassword`,
        sessionStorage.setItem("name", JSON.stringify(name)),
        sessionStorage.setItem("Lname", JSON.stringify(Lname)),
        sessionStorage.setItem("mobile", JSON.stringify(mobile)),
        sessionStorage.setItem("code", JSON.stringify(code))
      );
    }
  };
  // const onSubmit = async (e) => {
  //   e.preventDefault();

  //   if (password !== cpassword) {
  //     alert("Passwords don't match");
  //   } else {
  //     try {
  //       const config = {
  //         url: "/vendorsignup",
  //         method: "post",
  //         baseURL: "https://bookingoda.com/api/admin",
  //         headers: { "content-type": "application/json" },
  //         data: {
  //           email: email,
  //           name: name,
  //           Lname: Lname,
  //           mobile: mobile,
  //           password: password,
  //           Cpassword: cpassword,
  //         },
  //       };
  //       let res = await axios(config);
  //       if (res.status === 200) {
  //         history("/signin");
  //         // sessionStorage.setItem("user", JSON.stringify(res.data.user));
  //         alert("successfully created");
  //         // history.push("/");
  //       } else {
  //         alert("Something Wrong");
  //       }
  //     } catch (error) {
  //       if (error.response) {
  //         alert(error.response.data.error);
  //       }
  //     }
  //   }
  // };
  useEffect(() => {

    const storedname = sessionStorage.getItem("name");
    const storedLname = sessionStorage.getItem("Lname");
    const storedmobile = sessionStorage.getItem("mobile");
    const storedcode = sessionStorage.getItem("code");

  
    if (storedname) {
      setname(JSON.parse(storedname));
    }
    if (storedLname) {
      setLname(JSON.parse(storedLname));
    }
    if (storedmobile) {
      setmobile(JSON.parse(storedmobile));
    }
    if (storedcode) {
      setcode(JSON.parse(storedcode));
    }
  
  }, []);
  return (
    <div>
      <div class="">
        <div class="">
          <div class="partner-header">
            <header class=" aCWKa87CwyJQfbPP_yg6">
              <nav class="yC5_EodFadTqIIY8_YDx">
                <div class="NrR_ZlI8bZYI3w06JKKb">
                  <div class="H2AEzwF6W02wmXtVrp9U">
                    <div class="mobile-join-logo join_logo">
                      <span
                        style={{
                          color: "white",
                          fontSize: 30,
                          marginTop: "40px",
                        }}
                      >
                        Booking-Oda.com
                      </span>
                    </div>
                  </div>
                </div>
              </nav>
            </header>
          </div>
          <div class="app">
            <div class="access-container bui_font_body">
              <div class="access-panel-container">
                <div class="access-panel nw-access-panel">
                  <div class="transition-container">
                    <div
                      class="animate-height"
                      style={{
                        height: "auto",
                        overflow: "visible",
                      }}
                    >
                      <div class="sliding-panel">
                        <div class="transition bui-panel-body slide-enter-done">
                          <div class="">
                            <div
                              tabindex="-1"
                              class="page-header"
                              aria-labelledby="7318b361-a261-4922-8cae-491449c4bb8f"
                              aria-describedby="0c667ef5-57dd-4116-b57e-ef4018c565f6"
                            >
                              <h1
                                id="7318b361-a261-4922-8cae-491449c4bb8f"
                                class="bui_font_display_one bui_font_heading--bold bui-spacer--medium nw-step-header"
                              >
                                Contact details
                              </h1>
                              <div class="bui-spacer--largest">
                                <div>
                                  <p
                                    class="nw-step-description"
                                    id="0c667ef5-57dd-4116-b57e-ef4018c565f6"
                                  >
                                    Your full name and phone number are needed
                                    to ensure the security of your
                                    Booking-Oda.com account.
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                          <form
                            class="nw-register-contact-details"
                            novalidate=""
                          >
                            <div class="bui-spacer--large nw-first-name">
                              <div class="sOewt4Y1yBOPAIUGcJwH wkTNdQjAfRVbKvFBiR1T">
                                <label
                                  for="__bui-9"
                                  class="sOewt4Y1yBOPAIUGcJwH hAFaqujHGDhKOcPl6uUa _nwGprfLZfZgMFjsmap7"
                                >
                                  <span>
                                    <span class="L08id9Rh757AqBBZobKh">
                                      First name
                                    </span>
                                  </span>
                                </label>
                                <div class="cTkJymCseykCmdCpB4JG">
                                  <div class="KBX_kI7wQ0ziDRM0x5Ig">
                                    <input
                                      name="firstName"
                                      class="ZMH9h0HCYH9GGNxHnXGJ"
                                      autocomplete="given-name"
                                      autocapitalize="off"
                                      autocorrect="off"
                                      spellcheck="false"
                                      tabindex="1"
                                      data-focus="true"
                                      id="__bui-9"
                                      onChange={(e) => setname(e.target.value)}
                                      value={name}
                                    />
                                    <div class="KlWiFnWD2KyUjaUF1WAE"></div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="bui-spacer--large nw-last-name">
                              <div class="sOewt4Y1yBOPAIUGcJwH wkTNdQjAfRVbKvFBiR1T">
                                <label
                                  for="__bui-10"
                                  class="sOewt4Y1yBOPAIUGcJwH hAFaqujHGDhKOcPl6uUa _nwGprfLZfZgMFjsmap7"
                                >
                                  <span>
                                    <span class="L08id9Rh757AqBBZobKh">
                                      Last name
                                    </span>
                                  </span>
                                </label>
                                <div class="cTkJymCseykCmdCpB4JG">
                                  <div class="KBX_kI7wQ0ziDRM0x5Ig">
                                    <input
                                      name="lastName"
                                      class="ZMH9h0HCYH9GGNxHnXGJ"
                                      autocomplete="family-name"
                                      autocapitalize="off"
                                      autocorrect="off"
                                      spellcheck="false"
                                      tabindex="2"
                                      id="__bui-10"
                                      onChange={(e) => setLname(e.target.value)}
                                      value={Lname}
                                    />
                                    <div class="KlWiFnWD2KyUjaUF1WAE"></div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="bui-spacer--larger">
                              <div class="input-wrapper-phone">
                                <label
                                  for="__bui-10"
                                  class="sOewt4Y1yBOPAIUGcJwH hAFaqujHGDhKOcPl6uUa _nwGprfLZfZgMFjsmap7"
                                >
                                  <span>
                                    <span class="L08id9Rh757AqBBZobKh">
                                      Phone number
                                    </span>
                                  </span>
                                </label>
                                <div class="nw-phone">
                                  <fieldset className="sOewt4Y1yBOPAIUGcJwH wkTNdQjAfRVbKvFBiR1T">
                                    <div className="Vw55_aACpYkyD1luIfis kBoHgdoepdx0bTMKriS3 OYyazDJlDgcUwQs4sbIx">
                                      <div className>
                                        <div className="sOewt4Y1yBOPAIUGcJwH wkTNdQjAfRVbKvFBiR1T">
                                          <div className="L70T2NfE5tHEchGQK6y_ zo4a0cj8_KvmFcVxoyYB PiPD9EVjp4TIih9GujDz">
                                            <span
                                              className="isKpyeWLSwFVXzgPs2ab r7o2yg2XszQo9UrVzwdD rdiANYXlY3MjBkMBDUoC mPoSe58Aj9gxS7_aR1e0"
                                              aria-hidden="true"
                                            >
                                              <div className="y9lzhmQNQryIehDperWr OtBvQgnIPoy52mmbs0kM RvdFdQQchyTYAGlpG3Tw PGLDTB6lHjckhYeyuWLQ pxFsfbf7KI1xpvKUhFtr At0LaPkfLxbGfy2IE7Ml dKILGxd5Asc3LkSvhT3o">
                                                <picture className="J_IAnmEIkAKgTtpx0RUf VIfNmfuVY5GY3XMcdMN_">
                                                  <img
                                                    className="p_uTm456vzU6aitkvXH3 u8k01bt43C19CmGpggYv I62bvTJSMGuMjcHNdNDq"
                                                    src="https://t-cf.bstatic.com/design-assets/assets/v3.30.2/images-flags/In.png"
                                                    srcSet="https://t-cf.bstatic.com/design-assets/assets/v3.30.2/images-flags/In@2x.png 2x"
                                                    alt=""
                                                    role="presentation"
                                                    loading="lazy"
                                                  />
                                                </picture>
                                              </div>
                                            </span>
                                            <select
                                              className="WzTc2zyfGtrvWPWVo8ek"
                                              name="countryCode"
                                              autoComplete="tel"
                                              autoCapitalize="off"
                                              autoCorrect="off"
                                              spellCheck="false"
                                              type="tel"
                                              data-focus="true"
                                              data-ga-action="start typing"
                                              data-ga-label="phone"
                                              tabIndex={3}
                                              onChange={(e) =>
                                                setcode(e.target.value)
                                              }
                                              id="__bui-19"
                                            >
                                              <option value="+91">
                                                India (+91)
                                              </option>
                                              <option value="+93">
                                                Afghanistan (+93)
                                              </option>
                                              <option value="+355">
                                                Albania (+355)
                                              </option>
                                              <option value="+213">
                                                Algeria (+213)
                                              </option>
                                              <option value="+1">
                                                American Samoa (+1)
                                              </option>
                                              <option value="+376">
                                                Andorra (+376)
                                              </option>
                                              <option value="+244">
                                                Angola (+244)
                                              </option>
                                              <option value="+1">
                                                Anguilla (+1)
                                              </option>
                                              <option value="+1">
                                                Antigua &amp; Barbuda (+1)
                                              </option>
                                              <option value="+54">
                                                Argentina (+54)
                                              </option>
                                              <option value="+374">
                                                Armenia (+374)
                                              </option>
                                              <option value="+297">
                                                Aruba (+297)
                                              </option>
                                              <option value="+61">
                                                Australia (+61)
                                              </option>
                                              <option value="+43">
                                                Austria (+43)
                                              </option>
                                              <option value="+994">
                                                Azerbaijan (+994)
                                              </option>
                                              <option value="+1">
                                                Bahamas (+1)
                                              </option>
                                              <option value="+973">
                                                Bahrain (+973)
                                              </option>
                                              <option value="+880">
                                                Bangladesh (+880)
                                              </option>
                                              <option value="+1">
                                                Barbados (+1)
                                              </option>
                                              <option value="+375">
                                                Belarus (+375)
                                              </option>
                                              <option value="+32">
                                                Belgium (+32)
                                              </option>
                                              <option value="+501">
                                                Belize (+501)
                                              </option>
                                              <option value="+229">
                                                Benin (+229)
                                              </option>
                                              <option value="+1">
                                                Bermuda (+1)
                                              </option>
                                              <option value="+975">
                                                Bhutan (+975)
                                              </option>
                                              <option value="+591">
                                                Bolivia (+591)
                                              </option>
                                              <option value="+599">
                                                Bonaire St Eustatius and Saba
                                                (+599)
                                              </option>
                                              <option value="+387">
                                                Bosnia and Herzegovina (+387)
                                              </option>
                                              <option value="+267">
                                                Botswana (+267)
                                              </option>
                                              <option value="+55">
                                                Brazil (+55)
                                              </option>
                                              <option value="+246">
                                                British Indian Ocean Territory
                                                (+246)
                                              </option>
                                              <option value="+673">
                                                Brunei Darussalam (+673)
                                              </option>
                                              <option value="+359">
                                                Bulgaria (+359)
                                              </option>
                                              <option value="+226">
                                                Burkina Faso (+226)
                                              </option>
                                              <option value="+257">
                                                Burundi (+257)
                                              </option>
                                              <option value="+855">
                                                Cambodia (+855)
                                              </option>
                                              <option value="+237">
                                                Cameroon (+237)
                                              </option>
                                              <option value="+1">
                                                Canada (+1)
                                              </option>
                                              <option value="+238">
                                                Cape Verde (+238)
                                              </option>
                                              <option value="+1">
                                                Cayman Islands (+1)
                                              </option>
                                              <option value="+236">
                                                Central Africa Republic (+236)
                                              </option>
                                              <option value="+235">
                                                Chad (+235)
                                              </option>
                                              <option value="+56">
                                                Chile (+56)
                                              </option>
                                              <option value="+86">
                                                China (+86)
                                              </option>
                                              <option value="+61">
                                                Christmas Island (+61)
                                              </option>
                                              <option value="+61">
                                                Cocos (K) I. (+61)
                                              </option>
                                              <option value="+57">
                                                Colombia (+57)
                                              </option>
                                              <option value="+269">
                                                Comoros (+269)
                                              </option>
                                              <option value="+242">
                                                Congo (+242)
                                              </option>
                                              <option value="+682">
                                                Cook Islands (+682)
                                              </option>
                                              <option value="+506">
                                                Costa Rica (+506)
                                              </option>
                                              <option value="+225">
                                                Côte d'Ivoire (+225)
                                              </option>
                                              <option value="+385">
                                                Croatia (+385)
                                              </option>
                                              <option value="+53">
                                                Cuba (+53)
                                              </option>
                                              <option value="+599">
                                                Curaçao (+599)
                                              </option>
                                              <option value="+357">
                                                Cyprus (+357)
                                              </option>
                                              <option value="+420">
                                                Czech Republic (+420)
                                              </option>
                                              <option value="+243">
                                                Democratic Republic of Congo
                                                (+243)
                                              </option>
                                              <option value="+45">
                                                Denmark (+45)
                                              </option>
                                              <option value="+253">
                                                Djibouti (+253)
                                              </option>
                                              <option value="+1">
                                                Dominica (+1)
                                              </option>
                                              <option value="+1">
                                                Dominican Republic (+1)
                                              </option>
                                              <option value="+670">
                                                East Timor (+670)
                                              </option>
                                              <option value="+593">
                                                Ecuador (+593)
                                              </option>
                                              <option value="+20">
                                                Egypt (+20)
                                              </option>
                                              <option value="+212">
                                                EH (+212)
                                              </option>
                                              <option value="+503">
                                                El Salvador (+503)
                                              </option>
                                              <option value="+240">
                                                Equatorial Guinea (+240)
                                              </option>
                                              <option value="+291">
                                                Eritrea (+291)
                                              </option>
                                              <option value="+372">
                                                Estonia (+372)
                                              </option>
                                              <option value="+268">
                                                Eswatini (+268)
                                              </option>
                                              <option value="+251">
                                                Ethiopia (+251)
                                              </option>
                                              <option value="+500">
                                                Falkland Islands (Malvinas)
                                                (+500)
                                              </option>
                                              <option value="+298">
                                                Faroe Islands (+298)
                                              </option>
                                              <option value="+679">
                                                Fiji (+679)
                                              </option>
                                              <option value="+358">
                                                Finland (+358)
                                              </option>
                                              <option value="+33">
                                                France (+33)
                                              </option>
                                              <option value="+594">
                                                French Guiana (+594)
                                              </option>
                                              <option value="+689">
                                                French Polynesia (+689)
                                              </option>
                                              <option value="+241">
                                                Gabon (+241)
                                              </option>
                                              <option value="+220">
                                                Gambia (+220)
                                              </option>
                                              <option value="+995">
                                                Georgia (+995)
                                              </option>
                                              <option value="+49">
                                                Germany (+49)
                                              </option>
                                              <option value="+233">
                                                Ghana (+233)
                                              </option>
                                              <option value="+350">
                                                Gibraltar (+350)
                                              </option>
                                              <option value="+30">
                                                Greece (+30)
                                              </option>
                                              <option value="+299">
                                                Greenland (+299)
                                              </option>
                                              <option value="+1">
                                                Grenada (+1)
                                              </option>
                                              <option value="+590">
                                                Guadeloupe (+590)
                                              </option>
                                              <option value="+1">
                                                Guam (+1)
                                              </option>
                                              <option value="+502">
                                                Guatemala (+502)
                                              </option>
                                              <option value="+44">
                                                Guernsey (+44)
                                              </option>
                                              <option value="+224">
                                                Guinea (+224)
                                              </option>
                                              <option value="+245">
                                                Guinea-Bissau (+245)
                                              </option>
                                              <option value="+592">
                                                Guyana (+592)
                                              </option>
                                              <option value="+509">
                                                Haiti (+509)
                                              </option>
                                              <option value="+504">
                                                Honduras (+504)
                                              </option>
                                              <option value="+852">
                                                Hong Kong (+852)
                                              </option>
                                              <option value="+36">
                                                Hungary (+36)
                                              </option>
                                              <option value="+354">
                                                Iceland (+354)
                                              </option>
                                              <option value="+91">
                                                India (+91)
                                              </option>
                                              <option value="+62">
                                                Indonesia (+62)
                                              </option>
                                              <option value="+98">
                                                Iran (+98)
                                              </option>
                                              <option value="+964">
                                                Iraq (+964)
                                              </option>
                                              <option value="+353">
                                                Ireland (+353)
                                              </option>
                                              <option value="+44">
                                                Isle of Man (+44)
                                              </option>
                                              <option value="+972">
                                                Israel (+972)
                                              </option>
                                              <option value="+39">
                                                Italy (+39)
                                              </option>
                                              <option value="+1">
                                                Jamaica (+1)
                                              </option>
                                              <option value="+81">
                                                Japan (+81)
                                              </option>
                                              <option value="+44">
                                                Jersey (+44)
                                              </option>
                                              <option value="+962">
                                                Jordan (+962)
                                              </option>
                                              <option value="+7">
                                                Kazakhstan (+7)
                                              </option>
                                              <option value="+254">
                                                Kenya (+254)
                                              </option>
                                              <option value="+686">
                                                Kiribati (+686)
                                              </option>
                                              <option value="+383">
                                                Kosovo (+383)
                                              </option>
                                              <option value="+965">
                                                Kuwait (+965)
                                              </option>
                                              <option value="+996">
                                                Kyrgyzstan (+996)
                                              </option>
                                              <option value="+856">
                                                Laos (+856)
                                              </option>
                                              <option value="+371">
                                                Latvia (+371)
                                              </option>
                                              <option value="+961">
                                                Lebanon (+961)
                                              </option>
                                              <option value="+266">
                                                Lesotho (+266)
                                              </option>
                                              <option value="+231">
                                                Liberia (+231)
                                              </option>
                                              <option value="+218">
                                                Libya (+218)
                                              </option>
                                              <option value="+423">
                                                Liechtenstein (+423)
                                              </option>
                                              <option value="+370">
                                                Lithuania (+370)
                                              </option>
                                              <option value="+352">
                                                Luxembourg (+352)
                                              </option>
                                              <option value="+853">
                                                Macao (+853)
                                              </option>
                                              <option value="+261">
                                                Madagascar (+261)
                                              </option>
                                              <option value="+265">
                                                Malawi (+265)
                                              </option>
                                              <option value="+60">
                                                Malaysia (+60)
                                              </option>
                                              <option value="+960">
                                                Maldives (+960)
                                              </option>
                                              <option value="+223">
                                                Mali (+223)
                                              </option>
                                              <option value="+356">
                                                Malta (+356)
                                              </option>
                                              <option value="+692">
                                                Marshall Islands (+692)
                                              </option>
                                              <option value="+596">
                                                Martinique (+596)
                                              </option>
                                              <option value="+222">
                                                Mauritania (+222)
                                              </option>
                                              <option value="+230">
                                                Mauritius (+230)
                                              </option>
                                              <option value="+262">
                                                Mayotte (+262)
                                              </option>
                                              <option value="+52">
                                                Mexico (+52)
                                              </option>
                                              <option value="+691">
                                                Micronesia (+691)
                                              </option>
                                              <option value="+373">
                                                Moldova (+373)
                                              </option>
                                              <option value="+377">
                                                Monaco (+377)
                                              </option>
                                              <option value="+976">
                                                Mongolia (+976)
                                              </option>
                                              <option value="+382">
                                                Montenegro (+382)
                                              </option>
                                              <option value="+1">
                                                Montserrat (+1)
                                              </option>
                                              <option value="+212">
                                                Morocco (+212)
                                              </option>
                                              <option value="+258">
                                                Mozambique (+258)
                                              </option>
                                              <option value="+95">
                                                Myanmar (+95)
                                              </option>
                                              <option value="+264">
                                                Namibia (+264)
                                              </option>
                                              <option value="+674">
                                                Nauru (+674)
                                              </option>
                                              <option value="+977">
                                                Nepal (+977)
                                              </option>
                                              <option value="+31">
                                                Netherlands (+31)
                                              </option>
                                              <option value="+687">
                                                New Caledonia (+687)
                                              </option>
                                              <option value="+64">
                                                New Zealand (+64)
                                              </option>
                                              <option value="+505">
                                                Nicaragua (+505)
                                              </option>
                                              <option value="+227">
                                                Niger (+227)
                                              </option>
                                              <option value="+234">
                                                Nigeria (+234)
                                              </option>
                                              <option value="+683">
                                                Niue (+683)
                                              </option>
                                              <option value="+672">
                                                Norfolk Island (+672)
                                              </option>
                                              <option value="+850">
                                                North Korea (+850)
                                              </option>
                                              <option value="+389">
                                                North Macedonia (+389)
                                              </option>
                                              <option value="+1">
                                                Northern Mariana Islands (+1)
                                              </option>
                                              <option value="+47">
                                                Norway (+47)
                                              </option>
                                              <option value="+968">
                                                Oman (+968)
                                              </option>
                                              <option value="+92">
                                                Pakistan (+92)
                                              </option>
                                              <option value="+680">
                                                Palau (+680)
                                              </option>
                                              <option value="+970">
                                                Palestinian Territory (+970)
                                              </option>
                                              <option value="+507">
                                                Panama (+507)
                                              </option>
                                              <option value="+675">
                                                Papua New Guinea (+675)
                                              </option>
                                              <option value="+595">
                                                Paraguay (+595)
                                              </option>
                                              <option value="+51">
                                                Peru (+51)
                                              </option>
                                              <option value="+63">
                                                Philippines (+63)
                                              </option>
                                              <option value="+48">
                                                Poland (+48)
                                              </option>
                                              <option value="+351">
                                                Portugal (+351)
                                              </option>
                                              <option value="+1">
                                                Puerto Rico (+1)
                                              </option>
                                              <option value="+974">
                                                Qatar (+974)
                                              </option>
                                              <option value="+262">
                                                Reunion (+262)
                                              </option>
                                              <option value="+40">
                                                Romania (+40)
                                              </option>
                                              <option value="+7">
                                                Russia (+7)
                                              </option>
                                              <option value="+250">
                                                Rwanda (+250)
                                              </option>
                                              <option value="+590">
                                                Saint Barthelemy (+590)
                                              </option>
                                              <option value="+1">
                                                Saint Kitts and Nevis (+1)
                                              </option>
                                              <option value="+1">
                                                Saint Lucia (+1)
                                              </option>
                                              <option value="+590">
                                                Saint Martin (+590)
                                              </option>
                                              <option value="+1">
                                                Saint Vincent &amp; Grenadines
                                                (+1)
                                              </option>
                                              <option value="+685">
                                                Samoa (+685)
                                              </option>
                                              <option value="+378">
                                                San Marino (+378)
                                              </option>
                                              <option value="+239">
                                                São Tomé and Príncipe (+239)
                                              </option>
                                              <option value="+966">
                                                Saudi Arabia (+966)
                                              </option>
                                              <option value="+221">
                                                Senegal (+221)
                                              </option>
                                              <option value="+381">
                                                Serbia (+381)
                                              </option>
                                              <option value="+248">
                                                Seychelles (+248)
                                              </option>
                                              <option value="+232">
                                                Sierra Leone (+232)
                                              </option>
                                              <option value="+65">
                                                Singapore (+65)
                                              </option>
                                              <option value="+1">
                                                Sint Maarten (+1)
                                              </option>
                                              <option value="+421">
                                                Slovakia (+421)
                                              </option>
                                              <option value="+386">
                                                Slovenia (+386)
                                              </option>
                                              <option value="+677">
                                                Solomon Islands (+677)
                                              </option>
                                              <option value="+252">
                                                Somalia (+252)
                                              </option>
                                              <option value="+27">
                                                South Africa (+27)
                                              </option>
                                              <option value="+82">
                                                South Korea (+82)
                                              </option>
                                              <option value="+211">
                                                South Sudan (+211)
                                              </option>
                                              <option value="+34">
                                                Spain (+34)
                                              </option>
                                              <option value="+94">
                                                Sri Lanka (+94)
                                              </option>
                                              <option value="+290">
                                                St Helena (+290)
                                              </option>
                                              <option value="+508">
                                                St Pierre and Miquelon (+508)
                                              </option>
                                              <option value="+249">
                                                Sudan (+249)
                                              </option>
                                              <option value="+597">
                                                Suriname (+597)
                                              </option>
                                              <option value="+47">
                                                Svalbard &amp; Jan Mayen (+47)
                                              </option>
                                              <option value="+46">
                                                Sweden (+46)
                                              </option>
                                              <option value="+41">
                                                Switzerland (+41)
                                              </option>
                                              <option value="+963">
                                                Syria (+963)
                                              </option>
                                              <option value="+886">
                                                Taiwan (+886)
                                              </option>
                                              <option value="+992">
                                                Tajikistan (+992)
                                              </option>
                                              <option value="+255">
                                                Tanzania (+255)
                                              </option>
                                              <option value="+66">
                                                Thailand (+66)
                                              </option>
                                              <option value="+228">
                                                Togo (+228)
                                              </option>
                                              <option value="+690">
                                                Tokelau (+690)
                                              </option>
                                              <option value="+676">
                                                Tonga (+676)
                                              </option>
                                              <option value="+1">
                                                Trinidad and Tobago (+1)
                                              </option>
                                              <option value="+216">
                                                Tunisia (+216)
                                              </option>
                                              <option value="+90">
                                                Turkey (+90)
                                              </option>
                                              <option value="+993">
                                                Turkmenistan (+993)
                                              </option>
                                              <option value="+1">
                                                Turks &amp; Caicos Islands (+1)
                                              </option>
                                              <option value="+688">
                                                Tuvalu (+688)
                                              </option>
                                              <option value="+256">
                                                Uganda (+256)
                                              </option>
                                              <option value="+1">
                                                UK Virgin Islands (+1)
                                              </option>
                                              <option value="+380">
                                                Ukraine (+380)
                                              </option>
                                              <option value="+971">
                                                United Arab Emirates (+971)
                                              </option>
                                              <option value="+44">
                                                United Kingdom (+44)
                                              </option>
                                              <option value="+1">
                                                United States (+1)
                                              </option>
                                              <option value="+598">
                                                Uruguay (+598)
                                              </option>
                                              <option value="+1">
                                                US Virgin Islands (+1)
                                              </option>
                                              <option value="+998">
                                                Uzbekistan (+998)
                                              </option>
                                              <option value="+678">
                                                Vanuatu (+678)
                                              </option>
                                              <option value="+39">
                                                Vatican City (+39)
                                              </option>
                                              <option value="+58">
                                                Venezuela (+58)
                                              </option>
                                              <option value="+84">
                                                Vietnam (+84)
                                              </option>
                                              <option value="+681">
                                                Wallis and Futuna (+681)
                                              </option>
                                              <option value="+967">
                                                Yemen (+967)
                                              </option>
                                              <option value="+260">
                                                Zambia (+260)
                                              </option>
                                              <option value="+263">
                                                Zimbabwe (+263)
                                              </option>
                                            </select>
                                            <span className="T6ByzjjtSb6EVjdNG8vK">
                                              <span
                                                className="isKpyeWLSwFVXzgPs2ab iuKCvJUhmWisXozvRudK OFAVWUCDp1d7GKyp5TSX mPoSe58Aj9gxS7_aR1e0"
                                                aria-hidden="true"
                                              >
                                                <svg
                                                  xmlns="http://www.w3.org/2000/svg"
                                                  viewBox="0 0 24 24"
                                                >
                                                  <path d="M18 9.45c0 .2-.078.39-.22.53l-5 5a1.08 1.08 0 0 1-.78.32 1.1 1.1 0 0 1-.78-.32l-5-5a.75.75 0 0 1 0-1.06.74.74 0 0 1 1.06 0L12 13.64l4.72-4.72a.74.74 0 0 1 1.06 0 .73.73 0 0 1 .22.53zm-5.72 4.47zm-.57 0z" />
                                                </svg>
                                              </span>
                                            </span>
                                          </div>
                                        </div>
                                      </div>
                                      <div dir="ltr" className>
                                        <div className="sOewt4Y1yBOPAIUGcJwH wkTNdQjAfRVbKvFBiR1T">
                                          <div className="cTkJymCseykCmdCpB4JG">
                                            <div className="AXxnJbx43_09Y0XUu0ke">
                                              {code}
                                            </div>
                                            <div className="KBX_kI7wQ0ziDRM0x5Ig">
                                              {" "}
                                              <input
                                                type="tel"
                                                name="number"
                                                class="ZMH9h0HCYH9GGNxHnXGJ"
                                                aria-label="Phone number"
                                                maxLength={10}
                                                onKeyPress={(event) => {
                                                  if (
                                                    !/[0-9]/.test(event.key)
                                                  ) {
                                                    event.preventDefault();
                                                  }
                                                }}
                                                onChange={(e) =>
                                                  setmobile(e.target.value)
                                                }
                                                value={mobile}
                                              />
                                              <div className="KlWiFnWD2KyUjaUF1WAE" />
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div
                                      id="phone-note"
                                      role="alert"
                                      className="sOewt4Y1yBOPAIUGcJwH H6lzDEPPhc6rnad4mB7d CgEr4LoA7GBJSRxe_hwL"
                                    >
                                      We'll text a two-factor authentication
                                      code to this number when you sign in.
                                    </div>
                                  </fieldset>
                                </div>
                              </div>
                            </div>
                            {/* <div class="bui-spacer--larger">
                                          <div class="input-wrapper-phone">
                                            <div class="nw-phone">
                                              <fieldset class="sOewt4Y1yBOPAIUGcJwH wkTNdQjAfRVbKvFBiR1T">
                                                <legend class="sOewt4Y1yBOPAIUGcJwH hAFaqujHGDhKOcPl6uUa _nwGprfLZfZgMFjsmap7">
                                                  <span>
                                                    <span class="L08id9Rh757AqBBZobKh" style={{marginLeft:"20px"}}>
                                                      Phone number
                                                    </span>
                                                  </span>
                                                </legend>

                                                <div class="Vw55_aACpYkyD1luIfis kBoHgdoepdx0bTMKriS3 OYyazDJlDgcUwQs4sbIx">
                                                  <div dir="ltr" class="">
                                                    <div class="sOewt4Y1yBOPAIUGcJwH wkTNdQjAfRVbKvFBiR1T">
                                                      <div class="cTkJymCseykCmdCpB4JG">
                                                        <div class="AXxnJbx43_09Y0XUu0ke">
                                                          +91
                                                        </div>
                                                        <div class="KBX_kI7wQ0ziDRM0x5Ig">
                                                          <input
                                                            type="tel"
                                                            name="number"
                                                            class="ZMH9h0HCYH9GGNxHnXGJ"
                                                            aria-label="Phone number"
                                                          maxLength={10}
                                                          onKeyPress={(event) => {
                                                            if (!/[0-9]/.test(event.key)) {
                                                              event.preventDefault();
                                                            }
                                                          }}
                                                            onChange={(e) =>
                                                              setmobile(
                                                                e.target.value
                                                              )
                                                            }
                                                            value={mobile}
                                                          />
                                                          <div class="KlWiFnWD2KyUjaUF1WAE"></div>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                               
                                              </fieldset>
                                            </div>
                                          </div>
                                        </div> */}
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="account-access__footer">
              <div class="u-text-center bui_font_caption portal_footer">
                <div
                  data-dv-event-id="8"
                  type="submit"
                  onClick={Handlepress}
                  class="Iiab0gVMeWOd4XcyJGA3 wPxWIS_rJCpwAWksE0s3 Ut3prtt_wDsi7NM_83Jc TuDOVH9WFSdot9jLyXlw EJWUAldA4O1mP0SSFXPm "
                  style={{
                    backgroundColor: "#70bbe9",
                    borderColor: "#70bbe9",
                    color: "white",
                  }}
                >
                  <span class="yfCvx60qsR50VNBG15jF">Continue</span>
                </div>

                <div className="account-access__footer">
                  <div className="u-text-center bui_font_caption portal_footer">
                    <div className="account_footer_terms footer-block">
                      By signing in or creating an account, you agree with our{" "}
                      <a
                        className="bui_color_action nw-terms"
                        target="_blank"
                        rel="noopener noreferrer"
                        data-ga-action="click"
                        data-ga-label="Terms partner"
                      >
                        Terms &amp; conditions
                      </a>{" "}
                      and{" "}
                      <a
                        className="bui_color_action nw-privacy"
                        target="_blank"
                        rel="noopener noreferrer"
                        data-ga-action="click"
                        data-ga-label="Privacy partner"
                      >
                        Privacy statement
                      </a>
                    </div>
                    <div className="access-footer-bottom bui_font_caption footer-block">
                      <div>
                        All rights reserved. <br />
                        Copyright (2006 - 2023) - Booking-Oda.com™{" "}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactDetails;
