import React, { useEffect, useState } from "react";
import { Table } from "antd";
import { BiEdit } from "react-icons/bi";
import { AiFillDelete } from "react-icons/ai";
import { FcApproval } from "react-icons/fc";
import Switch from "react-switch";
import { City, Country, State } from "country-state-city";
import { Input, Space } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { MdDelete } from "react-icons/md";
import { BiSolidEdit } from "react-icons/bi";
import { Container, Form, Modal } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCancel } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import axios from "axios";
import CustomInput from "../components/CustomInput";
import Apartment from "../../assets/apartments.png";
import Apartments from "../../assets/buildings.png";
import mutilocation from "../../assets/mutilocation.png";
import location from "../../assets/location.png";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepButton from "@mui/material/StepButton";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { Tooltip } from "antd";
import { DeleteOutlined } from "@material-ui/icons";
import * as XLSX from "xlsx";
import moment from "moment";

const PriorityList = () => {
  const [home, sethome] = useState([]);
  const [Filter, setFilter] = useState([]);

  const gethome = async () => {
    let res = await axios.get("https://bookingoda.com/api/admin/getallhome");
    if (res.status === 200) {
      //////console.log(res.data);
      sethome(
        res.data.home.filter((e) => e.Deletestatus !== "Deleted By Admin")
      );
    }
  };
  const [hotel, sethotel] = useState([]);

  const gethotel = async () => {
    let res = await axios.get("https://bookingoda.com/api/admin/getallhotel");
    if (res.status === 200) {
      //////console.log(res.data);
      sethotel(
        res.data.hotel.filter((e) => e.Deletestatus !== "Deleted By Admin")
      );
    }
  };

  const [apartment, setapartment] = useState([]);

  const getapartment = async () => {
    let res = await axios.get(
      "https://bookingoda.com/api/admin/getallApartment"
    );
    if (res.status === 200) {
      //////console.log(res.data);
      setapartment(
        res.data.Apartment.filter((e) => e.Deletestatus !== "Deleted By Admin")
      );
    }
  };
  const [alternative, setalternative] = useState([]);

  const getalternative = async () => {
    let res = await axios.get(
      "https://bookingoda.com/api/admin/getallalternative"
    );
    if (res.status === 200) {
      //////console.log(res.data);
      setalternative(
        res.data.alternative.filter(
          (e) => e.Deletestatus !== "Deleted By Admin"
        )
      );
    }
  };

  useEffect(() => {
    gethotel();
    getapartment();
    getalternative();
    gethome();
  }, []);
  const [data1, setData1] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const staysofall = [...apartment, ...home, ...hotel, ...alternative];

  const priority = staysofall.filter((data) => data.prioritystatus === true);

  useEffect(() => {
    if (priority && priority.length > 0 && Filter.length === 0) {
      const transformedData = transformData(priority);
      setData1(transformedData);
      setFilteredData(transformedData);
    }
    
  }, [priority]);
 
  const transformData = (datas) => {
    return datas.map((data, i) => {
      return {
        Sno: i + 1,

        UniqueId: "BOSTAY" + data._id.slice(22, 24).toUpperCase(),
        PropertyName: data.PropertyName,
        VendorId: "BOVEN" + data.UserId.slice(22, 24).toUpperCase(),

        date: moment(data.updatedAt).format("DD/MM/YYYY"),
      };
    });
  };

  const columns = [
    {
      title: "Serial No.",
      dataIndex: "Sno",

      // //fixed: "left",
      width: 100,
    },
    {
      title: "Unique ID",
      dataIndex: "UniqueId",

      //fixed: "left",
      width: 150,
    },
    {
      title: "Vendor ID",
      dataIndex: "VendorId",

      //fixed: "left",
      width: 150,
    },
    {
      title: "Property Name",
      dataIndex: "PropertyName",

      // //fixed: "left",
      width: 150,
    },
    {
      title: "Created Date",
      dataIndex: "date",
      width: 150,
    },
  ];
  const ExportToExcelButton = ({ dataSource, columns, filename }) => {
    const exportToExcel = () => {
      const worksheet = XLSX.utils.json_to_sheet(dataSource);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet 1");
      XLSX.writeFile(workbook, `${filename}.xlsx`);
    };

    return (
      <Button
        type="primary"
        onClick={exportToExcel}
        style={{
          margin: "10px",
          alignSelf: "flex-end",
          backgroundColor: "#1677ff",
          color: "white",
        }}
      >
        Export to Excel
      </Button>
    );
  };

  const [searchTerm, setSearchTerm] = useState("");
  // const [filteredData, setFilteredData] = useState(data1);
  ////console.log(filteredData, "vgvg");
  const handles = (value) => {
    setSearchTerm(value);

    const filteredDataSource = data1.filter((record) => {
      return Object.values(record).some(
        (val) =>
          val &&
          val
            .toString()
            .trim()
            .toLowerCase()
            .includes(value.trim().toLowerCase())
      );
    });
    console.log(filteredDataSource);
    setFilter(filteredDataSource);
    setFilteredData(filteredDataSource);
  };

  const onClearSearch = () => {
    setSearchTerm("");
    setFilteredData(data1);
  };

  const searchInput = (
    <Input
      placeholder="Search"
      value={searchTerm}
      style={{ width: "50%" }}
      onChange={(e) => handles(e.target.value)}
      suffix={
        searchTerm && (
          <span
            onClick={onClearSearch}
            style={{ cursor: "pointer", color: "grey" }}
          >
            Clear
          </span>
        )
      }
      prefix={<SearchOutlined />}
    />
  );

  const pageSize = 5;

  return (
    <div>
      <h3 className="mb-4 title">Priority List</h3>

      <div
        style={{
          display: "flex",
          flexDirection: "row",
          // justifyContent: "space-around",
          margin: 10,
        }}
      >
        {searchInput}
        <ExportToExcelButton
          dataSource={filteredData}
          columns={columns}
          filename="prioritylist"
        />
      </div>
      <div>
        <Table
          key={filteredData.length}
          className="custom-table"
          columns={columns}
          dataSource={filteredData}
          scroll={{ x: "calc(700px + 50%)", y: 240 }}
          pagination={{
            pageSize: pageSize,
            showSizeChanger: false, // Optional: Hide the "Show X entries" dropdown
          }}
          components={{
            header: {
              cell: (props) => (
                <th
                  {...props}
                  style={{
                    ...props.style,
                    background: "linear-gradient(#6D4AC7,#493283,#2B1F49)",
                    color: "white",
                  }}
                />
              ),
            },
          }}
        />
      </div>
    </div>
  );
};

export default PriorityList;
