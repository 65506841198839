
import "./homearc.css";
import { Carousel } from 'react-bootstrap';
import Logo from "../../assets/arc.jpeg"
const HomeArc = () => {
  return (
    <div >
    
    
       
       
      
       <div class="construct-site-preloader" id="preloader" style={{opacity: "1", display: "none"}}>
          <div class="sk-cube-grid">
             <div class="sk-cube sk-cube1"></div>
             <div class="sk-cube sk-cube2"></div>
             <div class="sk-cube sk-cube3"></div>
             <div class="sk-cube sk-cube4"></div>
             <div class="sk-cube sk-cube5"></div>
             <div class="sk-cube sk-cube6"></div>
             <div class="sk-cube sk-cube7"></div>
             <div class="sk-cube sk-cube8"></div>
             <div class="sk-cube sk-cube9"></div>
          </div>
       </div>
      
       <header class="construct-header-area">
          <div class="header-top-area">
             <div class="header-top-overlay"></div>
             <div class="container">
                <div class="row4">
                   <div class="col-md-6 col-sm-12">
                      <div class="header-top-left">
                         <p>Have any question? +968 547856 254</p>
                      </div>
                   </div>
                   <div class="col-md-6 col-sm-12">
                      <div class="header-top-right">
                         <ul>
                            <li><a href="#"><i class="fab fa-facebook" style={{color:"white"}}></i></a></li>
                            <li><a href="#"><i class="fab fa-twitter" style={{color:"white"}}></i></a></li>
                            <li><a href="#"><i class="fab fa-linkedin" style={{color:"white"}}></i></a></li>
                            <li><a href="#"><i class="fab fa-skype" style={{color:"white"}}></i></a></li>
                            <li><a href="#"><i class="fab fa-pinterest" style={{color:"white"}}></i></a></li>
                            <li><a href="#"><i class="fa fa-rss" aria-hidden="true" style={{color:"white"}}></i></a></li>
                         </ul>
                      </div>
                   </div>
                </div>
             </div>
          </div>
          <div class="header-logo-area">
             <div class="container">
                <div class="row4">
                   <div class="col-md-3">
                      <div class="construct-logo">
                         <a href="#">
                         <img src={Logo} alt="site logo"  style={{height:"130px"}}/>
                         </a>
                      </div>
                   </div>
                   <div class="col-md-9">
                      <div class="logoright-section">
                         <div class="single-logo-right">
                            <div class="logo-right-icon">
                               <i class="fa fa-phone"></i>
                            </div>
                            <div class="logo-right-text">
                               <h4>call us at</h4>
                               <p>All Days 9:00 to 5:00</p>
                            </div>
                         </div>
                         <div class="single-logo-right">
                            <div class="logo-right-icon">
                            <i class="fa fa-envelope" aria-hidden="true"></i>
                            </div>
                            <div class="logo-right-text">
                               <h4>Mail us</h4>
                               <p>support@construct.com</p>
                            </div>
                         </div>
                      </div>
                   </div>
                </div>
             </div>
          </div>
          <div class="mainmenu-area">
             <div class="container">
                <div class="row4">
                   <div class="col-md-10">
                      <div class="mainmenu">
                         <ul id="construct_navigation">
                            <li class="current-page-item"><a href="/architecture">home</a></li>
                            <li><a href="/aboutus">about</a></li>
                            <li>
                               <a href="/project">project</a>
                               {/* <ul class="sub_menu">
                                  <li><a href="#">all projects</a></li>
                                  <li><a href="#">single project</a></li>
                               </ul> */}
                            </li>
                            <li>
                               <a href="/service">services</a>
                               {/* <ul>
                                  <li><a href="#">all services</a></li>
                                  <li><a href="#">Construction</a></li>
                                  <li><a href="#">Smart houses</a></li>
                                  <li><a href="#"> Roof Repairing </a></li>
                                  <li><a href="#">Renovations</a></li>
                                  <li><a href="#">Interior</a></li>
                                  <li><a href="3">architecture</a></li>
                               </ul> */}
                            </li>
                            <li>
                               <a href="#">pages</a>
                               {/* <ul>
                                  <li><a href="#">404 pages</a></li>
                                  <li><a href="#">cart page</a></li>
                                  <li><a href="#">checkout page</a></li>
                               </ul> */}
                            </li>
                            <li>
                               <a href="#">shop</a>
                               {/* <ul>
                                  <li><a href="#">shop</a></li>
                                  <li><a href="#">single shop</a></li>
                               </ul> */}
                            </li>
                            <li>
                               <a href="#">blog</a>
                               {/* <ul>
                                  <li><a href="#">blog</a></li>
                                  <li><a href="#">single blog</a></li>
                               </ul> */}
                            </li>
                            <li><a href="#">contact</a></li>
                         </ul>
                      </div>
                    
                      <div class="construct-responsive-menu"><div class="slicknav_menu"><a href="#" aria-haspopup="true" role="button" tabindex="0" class="slicknav_btn slicknav_collapsed" style={{outline: "none"}}><span class="slicknav_menutxt">MENU</span><span class="slicknav_icon"><span class="slicknav_icon-bar"></span><span class="slicknav_icon-bar"></span><span class="slicknav_icon-bar"></span></span></a><ul class="slicknav_nav slicknav_hidden" aria-hidden="true" role="menu" style={{display: "none"}}>
                            <li class="current-page-item"><a href="#" role="menuitem" tabindex="-1">home</a></li>
                            <li><a href="/aboutus" role="menuitem" tabindex="-1">about</a></li>
                            <li class="slicknav_collapsed slicknav_parent"><a href="#" role="menuitem" aria-haspopup="true" tabindex="-1" class="slicknav_item slicknav_row1" style={{outline: "none"}}>
                               <a href="/project" tabindex="-1">project</a>
                               <span class="slicknav_arrow1">►</span></a><ul class="sub_menu slicknav_hidden" role="menu" aria-hidden="true" style={{display: "none"}}>
                                  {/* <li><a href="#" role="menuitem" tabindex="-1">all projects</a></li>
                                  <li><a href="#" role="menuitem" tabindex="-1">single project</a></li> */}
                               </ul>
                            </li>
                            <li class="slicknav_collapsed slicknav_parent"><a href="#" role="menuitem" aria-haspopup="true" tabindex="-1" class="slicknav_item slicknav_row1" style={{outline: "none"}}>
                               <a href="/service" tabindex="-1">services</a>
                               <span class="slicknav_arrow1">►</span></a><ul role="menu" class="slicknav_hidden" aria-hidden="true" style={{display: "none"}}>
                                  {/* <li><a href="#" role="menuitem" tabindex="-1">all services</a></li>
                                  <li><a href="#" role="menuitem" tabindex="-1">Construction</a></li>
                                  <li><a href="#" role="menuitem" tabindex="-1">Smart houses</a></li>
                                  <li><a href="#" role="menuitem" tabindex="-1"> Roof Repairing </a></li>
                                  <li><a href="#" role="menuitem" tabindex="-1">Renovations</a></li>
                                  <li><a href="#" role="menuitem" tabindex="-1">Interior</a></li>
                                  <li><a href="#" role="menuitem" tabindex="-1">architecture</a></li> */}
                               </ul>
                            </li>
                            <li class="slicknav_collapsed slicknav_parent"><a href="#" role="menuitem" aria-haspopup="true" tabindex="-1" class="slicknav_item slicknav_row1" style={{outline: "none"}}>
                               <a href="#" tabindex="-1">pages</a>
                               <span class="slicknav_arrow1">►</span></a><ul role="menu" class="slicknav_hidden" aria-hidden="true" style={{display: "none"}}>
                                  {/* <li><a href="#" role="menuitem" tabindex="-1">404 pages</a></li>
                                  <li><a href="#" role="menuitem" tabindex="-1">cart page</a></li>
                                  <li><a href="#" role="menuitem" tabindex="-1">checkout page</a></li> */}
                               </ul>
                            </li>
                            <li class="slicknav_collapsed slicknav_parent"><a href="#" role="menuitem" aria-haspopup="true" tabindex="-1" class="slicknav_item slicknav_row1" style={{outline: "none"}}>
                               <a href="#" tabindex="-1">shop</a>
                               <span class="slicknav_arrow1">►</span></a><ul role="menu" class="slicknav_hidden" aria-hidden="true" style={{display: "none"}}>
                                  {/* <li><a href="#" role="menuitem" tabindex="-1">shop</a></li>
                                  <li><a href="#" role="menuitem" tabindex="-1">single shop</a></li> */}
                               </ul>
                            </li>
                            <li class="slicknav_collapsed slicknav_parent"><a href="#" role="menuitem" aria-haspopup="true" tabindex="-1" class="slicknav_item slicknav_row1" style={{outline: "none"}}>
                               <a href="#" tabindex="-1">blog</a>
                               <span class="slicknav_arrow1">►</span></a>
                               {/* <ul role="menu" class="slicknav_hidden" aria-hidden="true" style={{display: "none"}}>
                                  <li><a href="#" role="menuitem" tabindex="-1">blog</a></li>
                                  <li><a href="#" role="menuitem" tabindex="-1">single blog</a></li>
                               </ul> */}
                            </li>
                            <li><a href="#" role="menuitem" tabindex="-1">contact</a></li>
                         </ul></div></div>
                    
                   </div>
                   <div class="col-md-2">
                      <div class="search_icon" data-toggle="modal" data-target="#myModal">
                         <i class="fa fa-search"></i>
                      </div>
                   </div>
                </div>
             </div>
          </div>
       </header>
        <section class="construct-slider-area">
          <div class="construct-slide owl-carousel owl-theme owl-loaded owl-text-select-on">      
         <Carousel>
      <Carousel.Item> 
        
            <div class="construct-main-slide slide-item-1">
                <div class="construct-main-caption">
                   <div class="construct-caption-cell">
                      <div class="container">
                         <div class="row4">
                            <div class="col-md-7 ml-auto">
                               <h2 style={{opacity: "1"}} class="animated fadeInUp">build your dream with <span>passion</span></h2>
                               <p style={{opacity: "1"}} class="animated fadeInUp">We help construction innovate and grow1.</p>
                               <a href="#" class="construct-btn animated fadeInDown" style={{opacity: "1"}}>start a project</a>
                            </div>
                         </div>
                      </div>
                   </div>
                </div>
             </div>
             </Carousel.Item>
            
      <Carousel.Item>
      <div class="construct-main-slide slide-item-2">
                <div class="construct-main-caption">
                   <div class="construct-caption-cell">
                      <div class="container">
                         <div class="row4">
                            <div class="col-md-7 ml-auto">
                               <h2 style={{opacity: "1"}} class="animated fadeInUp">construction service <span>providors</span> </h2>
                               <p style={{opacity: "1"}} class="animated fadeInUp">We help construction innovate and grow1. </p>
                               <a href="#" class="construct-btn animated fadeInDown" style={{opacity: "1"}}>start a project</a>
                            </div>
                         </div>
                      </div>
                   </div>
                </div>
             </div></Carousel.Item> 
      <Carousel.Item>
        <div class="construct-main-slide slide-item-1">
                <div class="construct-main-caption">
                   <div class="construct-caption-cell">
                      <div class="container">
                         <div class="row4">
                            <div class="col-md-7 ml-auto">
                               <h2 style={{opacity: "1"}} class="animated fadeInUp">build your dream with <span>passion</span></h2>
                               <p style={{opacity: "1"}} class="animated fadeInUp">We help construction innovate and grow1.</p>
                               <a href="#" class="construct-btn animated fadeInDown" style={{opacity: "1"}}>start a project</a>
                            </div>
                         </div>
                      </div>
                   </div>
                </div>
             </div></Carousel.Item> 
      <Carousel.Item>
        <div class="construct-main-slide slide-item-2">
                <div class="construct-main-caption">
                   <div class="construct-caption-cell">
                      <div class="container">
                         <div class="row4">
                            <div class="col-md-7 ml-auto">
                               <h2 style={{opacity: "1"}} class="animated fadeInUp">construction service <span>providors</span> </h2>
                               <p style={{opacity: "1"}} class="animated fadeInUp">We help construction innovate and grow1. </p>
                               <a href="#" class="construct-btn animated fadeInDown" style={{opacity: "1"}}>start a project</a>
                            </div>
                         </div>
                      </div>
                   </div>
                </div>
             </div></Carousel.Item>
            
      <Carousel.Item>   
         <div class="construct-main-slide slide-item-1">
                <div class="construct-main-caption">
                   <div class="construct-caption-cell">
                      <div class="container">
                         <div class="row4">
                            <div class="col-md-7 ml-auto">
                               <h2 style={{opacity: "1"}} class="animated fadeInUp">build your dream with <span>passion</span></h2>
                               <p style={{opacity: "1"}} class="animated fadeInUp">We help construction innovate and grow1.</p>
                               <a href="#" class="construct-btn animated fadeInDown" style={{opacity: "1"}}>start a project</a>
                            </div>
                         </div>
                      </div>
                   </div>
                </div>
             </div></Carousel.Item>
            
      <Carousel.Item>  <div class="construct-main-slide slide-item-2">
                <div class="construct-main-caption">
                   <div class="construct-caption-cell">
                      <div class="container">
                         <div class="row4">
                            <div class="col-md-7 ml-auto">
                               <h2 style={{opacity: "1"}} class="animated fadeInUp">construction service <span>providors</span> </h2>
                               <p style={{opacity: "1"}} class="animated fadeInUp">We help construction innovate and grow1. </p>
                               <a href="#" class="construct-btn animated fadeInDown" style={{opacity: "1"}}>start a project</a>
                            </div>
                         </div>
                      </div>
                   </div>
                </div>
             </div></Carousel.Item></Carousel></div>
       </section> 
      
       <section class="construct-service-area section_100">
          <div class="container">
             <div class="row4">
                <div class="col-md-12">
                   <div class="section-heading">
                      <h4>Our Services</h4>
                      <h2>We Build Everything</h2>
                      <p>Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for 'lorem ipsum' will uncover many web sites.</p>
                   </div>
                </div>
             </div>
             <div class="row4">
                <div class="col-md-4">
                   <div class="single-service">
                      <div class="service-image">
                         <a href="#">
                         <img src="http://themescare.com/demos/construct-v-1-2/assets/img/service-1.jpg" alt="service" />
                         </a>
                      </div>
                      <div class="service-text">
                         <h3><a href="#">Construction</a></h3>
                         <p>Lorem ipsum dolor sit amet, cons ectetur elit. Ves tibulum nec odios Suspe ndisse cursus mal suada faci lisis. Lorem ipsum dolor.</p>
                      </div>
                   </div>
                </div>
                <div class="col-md-4">
                   <div class="single-service">
                      <div class="service-image">
                         <a href="#">
                         <img src="	http://themescare.com/demos/construct-v-1-2/assets/img/service-2.jpg" alt="service" />
                         </a>
                      </div>
                      <div class="service-text">
                         <h3><a href="#">Architectural</a></h3>
                         <p>Lorem ipsum dolor sit amet, cons ectetur elit. Ves tibulum nec odios Suspe ndisse cursus mal suada faci lisis. Lorem ipsum dolor.</p>
                      </div>
                   </div>
                </div>
                <div class="col-md-4">
                   <div class="single-service">
                      <div class="service-image">
                         <a href="#">
                         <img src="	http://themescare.com/demos/construct-v-1-2/assets/img/service-3.jpg" alt="service" />
                         </a>
                      </div>
                      <div class="service-text">
                         <h3><a href="#">renovation</a></h3>
                         <p>Lorem ipsum dolor sit amet, cons ectetur elit. Ves tibulum nec odios Suspe ndisse cursus mal suada faci lisis. Lorem ipsum dolor.</p>
                      </div>
                   </div>
                </div>
             </div>
          </div>
       </section>
   
       <section class="construct-about-bottom-area">
          <div class="container">
             <div class="row4">
                <div class="col-md-8">
                   <div class="about-bottom-left section_100">
                      <div class="about-bottom-one">
                         <h3>about us and our priorities</h3>
                         <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
                      </div>
                      <div class="about-bottom-right clearfix row4">
                         <div class="col-md-6 col-sm-6">
                            <div class="about_list">
                               <div class="single-about-list">
                                  <div class="about_icon">
                                     <i class="fa fa-building"></i>
                                  </div>
                                  <div class="about_text">
                                     <h4>Construction</h4>
                                     <p>build homes</p>
                                  </div>
                               </div>
                               <div class="single-about-list">
                                  <div class="about_icon">
                                     <i class="fa fa-users"></i>
                                  </div>
                                  <div class="about_text">
                                     <h4>Awesome Stuff</h4>
                                     <p>1200+ workers</p>
                                  </div>
                               </div>
                               <div class="single-about-list">
                                  <div class="about_icon">
                                     <i class="fa fa-cogs"></i>
                                  </div>
                                  <div class="about_text">
                                     <h4>Good Planning</h4>
                                     <p>architecture</p>
                                  </div>
                               </div>
                            </div>
                         </div>
                         <div class="col-md-6 col-sm-6">
                            <div class="about_list">
                               <div class="single-about-list">
                                  <div class="about_icon">
                                     <i class="fa fa-building"></i>
                                  </div>
                                  <div class="about_text">
                                     <h4>Construction</h4>
                                     <p>build homes</p>
                                  </div>
                               </div>
                               <div class="single-about-list">
                                  <div class="about_icon">
                                     <i class="fa fa-users"></i>
                                  </div>
                                  <div class="about_text">
                                     <h4>Awesome Stuff</h4>
                                     <p>1200+ workers</p>
                                  </div>
                               </div>
                               <div class="single-about-list">
                                  <div class="about_icon">
                                     <i class="fa fa-cogs"></i>
                                  </div>
                                  <div class="about_text">
                                     <h4>Good Planning</h4>
                                     <p>architecture</p>
                                  </div>
                               </div>
                            </div>
                         </div>
                      </div>
                   </div>
                </div>
             </div>
          </div>
       </section>
     
       <section class="construct-project-page-area section_100">
          <div class="container">
             <div class="row4">
                <div class="col-md-12">
                   <div class="section-heading">
                      <h4>our Best Works</h4>
                      <h2>our recent project</h2>
                      <p>Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for 'lorem ipsum' will uncover many web sites.</p>
                   </div>
                </div>
             </div>
             <div class="row4">
                <div class="col-md-12">
                   <div class="construct-isotope-project">
                      <div class="projectFilter project-btn">
                         <a href="#" data-filter="*" class="current">show all</a>
                         <a href="#" data-filter=".construction">construction</a>
                         <a href="#" data-filter=".renovation">renovation</a>
                         <a href="#" data-filter=".plumbing">plumbing</a>
                         <a href="#" data-filter=".flooring">flooring</a>
                      </div>
                      <div class="row4">
                         <div class="col-md-12">
                            <div class="clearfix projectContainer projectContainer3column" style={{position: "relative", height: "526.604px"}}>
                               <div class="element-item  construction" style={{position: "absolute", left: "0px", top: "0px"}}>
                                  <div class="project-single-item img-contain-isotope">
                                     <a href="#">
                                     <img src="	http://themescare.com/demos/construct-v-1-2/assets/img/work-1.jpeg" class="img-responsive" alt="Image" />
                                     </a>
                                     <div class="project-img-overlay">
                                        <h4>project title</h4>
                                        <p>Duis aute irure dolor in reprehenderi</p>
                                     </div>
                                  </div>
                               </div>
                               <div class="element-item renovation plumbing" style={{position: "absolute", left: "379px", top: "0px"}}>
                                  <div class="project-single-item img-contain-isotope">
                                     <a href="#">
                                     <img src="	http://themescare.com/demos/construct-v-1-2/assets/img/work-2.jpeg" class="img-responsive" alt="Image" />
                                     </a>
                                     <div class="project-img-overlay">
                                        <h4>project title</h4>
                                        <p>Duis aute irure dolor in reprehenderi</p>
                                     </div>
                                  </div>
                               </div>
                               <div class="element-item flooring" style={{position: "absolute", left: "759px", top: "0px"}}>
                                  <div class="project-single-item img-contain-isotope">
                                     <a href="#">
                                     <img src="	http://themescare.com/demos/construct-v-1-2/assets/img/work-3.jpeg" class="img-responsive" alt="Image" />
                                     </a>
                                     <div class="project-img-overlay">
                                        <h4>project title</h4>
                                        <p>Duis aute irure dolor in reprehenderi</p>
                                     </div>
                                  </div>
                               </div>
                               <div class="element-item plumbing " style={{position: "absolute", left: "0px", top: "263px"}}>
                                  <div class="project-single-item img-contain-isotope">
                                     <a href="#">
                                     <img src="	http://themescare.com/demos/construct-v-1-2/assets/img/work-4.jpeg" class="img-responsive" alt="Image" />
                                     </a>
                                     <div class="project-img-overlay">
                                        <h4>project title</h4>
                                        <p>Duis aute irure dolor in reprehenderi</p>
                                     </div>
                                  </div>
                               </div>
                               <div class="element-item construction plumbing" style={{position: "absolute", left: "379px", top: "263px"}}>
                                  <div class="project-single-item img-contain-isotope">
                                     <a href="#">
                                     <img src="	http://themescare.com/demos/construct-v-1-2/assets/img/work-5.jpeg" class="img-responsive" alt="Image" />
                                     </a>
                                     <div class="project-img-overlay">
                                        <h4>project title</h4>
                                        <p>Duis aute irure dolor in reprehenderi</p>
                                     </div>
                                  </div>
                               </div>
                               <div class="element-item flooring" style={{position: "absolute", left: "759px", top: "263px"}}>
                                  <div class="project-single-item img-contain-isotope">
                                     <a href="#">
                                     <img src="	http://themescare.com/demos/construct-v-1-2/assets/img/work-3.jpeg" class="img-responsive" alt="Image" />
                                     </a>
                                     <div class="project-img-overlay">
                                        <h4>project title</h4>
                                        <p>Duis aute irure dolor in reprehenderi</p>
                                     </div>
                                  </div>
                               </div>
                            </div>
                         </div>
                      </div>
                   </div>
                </div>
             </div>
          </div>
       </section>
     
       <header class="construct-hire-area">
          <div class="hire-bg"></div>
          <div class="container">
             <div class="row4">
                <div class="col-md-8">
                   <div class="construct-hire-left">
                      <div class="hire-overlay"></div>
                      <h3>Lets make your dream house with our professionals</h3>
                      <div class="hire-icon">
                         <i class="fa fa-home"></i>
                      </div>
                   </div>
                </div>
                <div class="col-md-4">
                   <div class="construct-hire-right">
                      <a href="#" class="construct-btn">start a project</a>
                   </div>
                </div>
             </div>
          </div>
     </header>
       <section class="construct-quote-area section_100">
          <div class="container">
             <div class="row4">
                <div class="col-md-6">
                   <div class="quote-right">
                      <div class="section-heading">
                         <h4>contact us soon</h4>
                         <h2>get a free quote</h2>
                         <p>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusa nt ium dolorem que laudantium totam rem aperiam and web page editors now use Lorem Ipsum as their default model text, and a search for 'lorem ipsum' will uncover many web sites.</p>
                      </div>
                      <div class="quote-star">
                         <ul>
                            <li><i class="fa fa-star"></i></li>
                            <li class="big-star"><i class="fa fa-star"></i></li>
                            <li><i class="fa fa-star"></i></li>
                         </ul>
                      </div>
                   </div>
                </div>
                <div class="col-md-6">
                   <div class="quote-left">
                      <form>
                         <div class="row4">
                            <div class="col-md-6">
                               <p>
                                  <label for="name">Your Name</label>
                                  <input type="text" id="name" name="name" placeholder="Your Name..." />
                               </p>
                            </div>
                            <div class="col-md-6">
                               <p>
                                  <label for="email">Email Address</label>
                                  <input type="email" id="email" name="email" placeholder="Your Email Address..." />
                               </p>
                            </div>
                         </div>
                         <div class="row4">
                            <div class="col-md-12">
                               <p>
                                  <label for="msg">How can we help you</label>
                                  <textarea id="msg" name="msg" placeholder="Write Your Message Here..."></textarea>
                               </p>
                            </div>
                         </div>
                         <div class="row4">
                            <div class="col-md-12">
                               <p>
                                  <button type="submit">request quote</button>
                               </p>
                            </div>
                         </div>
                      </form>
                   </div>
                </div>
             </div>
          </div>
       </section>
     
       <section class="construct-testimonial-area section_100">
          <div class="testimonial-overlay"></div>
          <div class="container">
             <div class="row4">
                <div class="col-md-12">
                   <div class="testimonial-slide owl-carousel owl-theme owl-loaded owl-text-select-on">
                      
                      
                      
                 
                   <Carousel>
      <Carousel.Item>
         <div class="single-testimonial">
                         <div class="testimonial-image">
                            <div class="testimonial-img-left">
                               <img src="	http://themescare.com/demos/construct-v-1-2/assets/img/woman.png" alt="testimonial" />
                            </div>
                            <div class="testimonial-img-right">
                               <h3>Jessica Espinoza</h3>
                               <p>Chief Director</p>
                            </div>
                         </div>
                         <div class="testimonial-text">
                            <p>Donec nec justo eget felis facilisis fermentum. Aliquam porttitor mauris sit amet orci.
                               Aenean dignissim pellentesque felis. Morbi in sem quis dui placerat ornare. Pellentesque odio nisi, euismod in, pharetra a,
                               ultricies in, diam. Sed arcu. Cras consequat.
                            </p>
                         </div>
                      </div></Carousel.Item><Carousel.Item>
                      <div class="single-testimonial">
                         <div class="testimonial-image">
                            <div class="testimonial-img-left">
                               <img src="	http://themescare.com/demos/construct-v-1-2/assets/img/woman.png" alt="testimonial" />
                            </div>
                            <div class="testimonial-img-right">
                               <h3>Jessica Espinoza</h3>
                               <p>Chief Director</p>
                            </div>
                         </div>
                         <div class="testimonial-text">
                            <p>Donec nec justo eget felis facilisis fermentum. Aliquam porttitor mauris sit amet orci.
                               Aenean dignissim pellentesque felis. Morbi in sem quis dui placerat ornare. Pellentesque odio nisi, euismod in, pharetra a,
                               ultricies in, diam. Sed arcu. Cras consequat.
                            </p>
                         </div>
                      </div></Carousel.Item>
                      <Carousel.Item>
                       <div class="single-testimonial">
                         <div class="testimonial-image">
                            <div class="testimonial-img-left">
                               <img src="	http://themescare.com/demos/construct-v-1-2/assets/img/woman.png" alt="testimonial" />
                            </div>
                            <div class="testimonial-img-right">
                               <h3>Jessica Espinoza</h3>
                               <p>Chief Director</p>
                            </div>
                         </div>
                         <div class="testimonial-text">
                            <p>Donec nec justo eget felis facilisis fermentum. Aliquam porttitor mauris sit amet orci.
                               Aenean dignissim pellentesque felis. Morbi in sem quis dui placerat ornare. Pellentesque odio nisi, euismod in, pharetra a,
                               ultricies in, diam. Sed arcu. Cras consequat.
                            </p>
                         </div>
                      </div> </Carousel.Item><Carousel.Item><div class="single-testimonial">
                         <div class="testimonial-image">
                            <div class="testimonial-img-left">
                               <img src="	http://themescare.com/demos/construct-v-1-2/assets/img/woman.png" alt="testimonial" />
                            </div>
                            <div class="testimonial-img-right">
                               <h3>Jessica Espinoza</h3>
                               <p>Chief Director</p>
                            </div>
                         </div></div></Carousel.Item>
                         <Carousel.Item> 
                            <div class="testimonial-text">
                            <p>Donec nec justo eget felis facilisis fermentum. Aliquam porttitor mauris sit amet orci.
                               Aenean dignissim pellentesque felis. Morbi in sem quis dui placerat ornare. Pellentesque odio nisi, euismod in, pharetra a,
                               ultricies in, diam. Sed arcu. Cras consequat.
                            </p>
                         </div>
                      </Carousel.Item><Carousel.Item>
                        <div class="single-testimonial">
                         <div class="testimonial-image">
                            <div class="testimonial-img-left">
                               <img src="	http://themescare.com/demos/construct-v-1-2/assets/img/woman.png" alt="testimonial" />
                            </div>
                            <div class="testimonial-img-right">
                               <h3>Jessica Espinoza</h3>
                               <p>Chief Director</p>
                            </div>
                         </div>
                         <div class="testimonial-text">
                            <p>Donec nec justo eget felis facilisis fermentum. Aliquam porttitor mauris sit amet orci.
                               Aenean dignissim pellentesque felis. Morbi in sem quis dui placerat ornare. Pellentesque odio nisi, euismod in, pharetra a,
                               ultricies in, diam. Sed arcu. Cras consequat.
                            </p>
                         </div>
                      </div></Carousel.Item>
                      <Carousel.Item>    
                          <div class="single-testimonial">
                         <div class="testimonial-image">
                            <div class="testimonial-img-left">
                               <img src="	http://themescare.com/demos/construct-v-1-2/assets/img/woman.png" alt="testimonial" />
                            </div>
                            <div class="testimonial-img-right">
                               <h3>Jessica Espinoza</h3>
                               <p>Chief Director</p>
                            </div>
                         </div>
                         <div class="testimonial-text">
                            <p>Donec nec justo eget felis facilisis fermentum. Aliquam porttitor mauris sit amet orci.
                               Aenean dignissim pellentesque felis. Morbi in sem quis dui placerat ornare. Pellentesque odio nisi, euismod in, pharetra a,
                               ultricies in, diam. Sed arcu. Cras consequat.
                            </p>
                         </div>
                      </div></Carousel.Item>
                      <Carousel.Item><div class="single-testimonial">
                         <div class="testimonial-image">
                            <div class="testimonial-img-left">
                               <img src="	http://themescare.com/demos/construct-v-1-2/assets/img/woman.png" alt="testimonial" />
                            </div>
                            <div class="testimonial-img-right">
                               <h3>Jessica Espinoza</h3>
                               <p>Chief Director</p>
                            </div>
                         </div> 
                         <div class="testimonial-text">
                            <p>Donec nec justo eget felis facilisis fermentum. Aliquam porttitor mauris sit amet orci.
                               Aenean dignissim pellentesque felis. Morbi in sem quis dui placerat ornare. Pellentesque odio nisi, euismod in, pharetra a,
                               ultricies in, diam. Sed arcu. Cras consequat.
                            </p>
                         </div>
                      </div></Carousel.Item></Carousel></div>
                </div>
             </div>
          </div>
       </section>
    
       <section class="construct-latest-blog-area section_t_100 section_b_70">
          <div class="container">
             <div class="row4">
                <div class="col-md-12">
                   <div class="section-heading">
                      <h4>latest news</h4>
                      <h2>our recent blog</h2>
                      <p>Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for 'lorem ipsum' will uncover many web sites.</p>
                   </div>
                </div>
             </div>
             <div class="row4">
                <div class="col-md-4">
                   <div class="single-blog">
                      <div class="blog-img">
                         <a href="#"><img src="	http://themescare.com/demos/construct-v-1-2/assets/img/blog-1.jpeg" alt="blog-image" /></a>
                      </div>
                      <div class="blog-heading">
                         <h3><a href="#">Construction conference 2017</a></h3>
                      </div>
                      <div class="blog-comnt">
                         <p><i class="fa fa-user"></i> Admin</p>
                         <p><i class="fa fa-calendar"></i> 21.11.2017</p>
                         <p><i class="fa fa-commenting-o"></i> 12 comments</p>
                      </div>
                      <div class="blog-details">
                         <p>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusa nt ium dolorem que laudantium totam rem aperiam</p>
                      </div>
                   </div>
                </div>
                <div class="col-md-4">
                   <div class="single-blog">
                      <div class="blog-img">
                         <a href="#"><img src="	http://themescare.com/demos/construct-v-1-2/assets/img/blog-3.jpeg" alt="blog-image" /></a>
                      </div>
                      <div class="blog-heading">
                         <h3><a href="#">From Ground Up Building</a></h3>
                      </div>
                      <div class="blog-comnt">
                         <p><i class="fa fa-user"></i> Admin</p>
                         <p><i class="fa fa-calendar"></i> 21.11.2017</p>
                         <p><i class="fa fa-commenting-o"></i> 12 comments</p>
                      </div>
                      <div class="blog-details">
                         <p>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusa nt ium dolorem que laudantium totam rem aperiam</p>
                      </div>
                   </div>
                </div>
                <div class="col-md-4">
                   <div class="single-blog">
                      <div class="blog-img">
                         <a href="#"><img src="	http://themescare.com/demos/construct-v-1-2/assets/img/blog-2.jpeg" alt="blog-image" /></a>
                      </div>
                      <div class="blog-heading">
                         <h3><a href="#">Construction conference 2017</a></h3>
                      </div>
                      <div class="blog-comnt">
                         <p><i class="fa fa-user"></i> Admin</p>
                         <p><i class="fa fa-calendar"></i> 21.11.2017</p>
                         <p><i class="fa fa-commenting-o"></i> 12 comments</p>
                      </div>
                      <div class="blog-details">
                         <p>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusa nt ium dolorem que laudantium totam rem aperiam</p>
                      </div>
                   </div>
                </div>
             </div>
          </div>
       </section>

       {/* <section class="construct-partners-area section_50">
          <div class="container">
             <div class="row4">
                <div class="col-md-12">
                   <div class="partners-slider owl-carousel owl-theme owl-loaded">
                      
                      
                      
                      
                      
                   <div class="owl-stage-outer"><div class="owl-stage" style={{transform: "translate3d(-2088px, 0px, 0px)", transition: "all 1.2s ease 0s", width: "3480px"}}><div class="owl-item cloned" style={{width: "212px", marginRight: "20px"}}><div class="single-partners">
                         <a href="#">
                         <img src="	http://themescare.com/demos/construct-v-1-2/assets/img/brand-1.png" alt="partners" />
                         </a>
                      </div></div><div class="owl-item cloned" style={{width: "212px", marginRight: "20px"}}><div class="single-partners">
                         <a href="#">
                         <img src="	http://themescare.com/demos/construct-v-1-2/assets/img/brand-2.png" alt="partners" />
                         </a>
                      </div></div><div class="owl-item cloned" style={{width: "212px", marginRight: "20px"}}><div class="single-partners">
                         <a href="#">
                         <img src="	http://themescare.com/demos/construct-v-1-2/assets/img/brand-3.png" alt="partners" />
                         </a>
                      </div></div><div class="owl-item cloned" style={{width: "212px", marginRight: "20px"}}><div class="single-partners">
                         <a href="#">
                         <img src="	http://themescare.com/demos/construct-v-1-2/assets/img/brand-4.png" alt="partners" />
                         </a>
                      </div></div><div class="owl-item cloned" style={{width: "212px", marginRight: "20px"}}><div class="single-partners">
                         <a href="#">
                         <img src="	http://themescare.com/demos/construct-v-1-2/assets/img/brand-5.png" alt="partners" />
                         </a>
                      </div></div><div class="owl-item" style={{width: "212px", marginRight: "20px"}}><div class="single-partners">
                         <a href="#">
                         <img src="	http://themescare.com/demos/construct-v-1-2/assets/img/brand-1.png" alt="partners" />
                         </a>
                      </div></div><div class="owl-item" style={{width: "212px", marginRight: "20px"}}><div class="single-partners">
                         <a href="#">
                         <img src="	http://themescare.com/demos/construct-v-1-2/assets/img/brand-2.png" alt="partners" />
                         </a>
                      </div></div><div class="owl-item" style={{width: "212px", marginRight: "20px"}}><div class="single-partners">
                         <a href="#">
                         <img src="	http://themescare.com/demos/construct-v-1-2/assets/img/brand-3.png" alt="partners" />
                         </a>
                      </div></div><div class="owl-item" style={{width: "212px", marginRight: "20px"}}><div class="single-partners">
                         <a href="#">
                         <img src="	http://themescare.com/demos/construct-v-1-2/assets/img/brand-4.png" alt="partners" />
                         </a>
                      </div></div><div class="owl-item active" style={{width: "212px", marginRight: "20px"}}><div class="single-partners">
                         <a href="#">
                         <img src="	http://themescare.com/demos/construct-v-1-2/assets/img/brand-5.png" alt="partners" />
                         </a>
                      </div></div><div class="owl-item cloned active" style={{width: "212px", marginRight: "20px"}}><div class="single-partners">
                         <a href="#">
                         <img src="	http://themescare.com/demos/construct-v-1-2/assets/img/brand-1.png" alt="partners" />
                         </a>
                      </div></div><div class="owl-item cloned active" style={{width: "212px", marginRight: "20px"}}><div class="single-partners">
                         <a href="#">
                         <img src="	http://themescare.com/demos/construct-v-1-2/assets/img/brand-2.png" alt="partners" />
                         </a>
                      </div></div><div class="owl-item cloned active" style={{width: "212px", marginRight: "20px"}}><div class="single-partners">
                         <a href="#">
                         <img src="	http://themescare.com/demos/construct-v-1-2/assets/img/brand-3.png" alt="partners" />
                         </a>
                      </div></div><div class="owl-item cloned active" style={{width: "212px", marginRight: "20px"}}><div class="single-partners">
                         <a href="#">
                         <img src="	http://themescare.com/demos/construct-v-1-2/assets/img/brand-4.png" alt="partners" />
                         </a>
                      </div></div><div class="owl-item cloned" style={{width: "212px", marginRight: "20px"}}><div class="single-partners">
                         <a href="#">
                         <img src="	http://themescare.com/demos/construct-v-1-2/assets/img/brand-5.png" alt="partners" />
                         </a>
                      </div></div></div></div><div class="owl-controls"><div class="owl-nav"><div class="owl-prev" style={{display: "none"}}>prev</div><div class="owl-next" style={{display: "none"}}>next</div></div><div class="owl-dots" style={{display: "none"}}></div></div></div>
                </div>
             </div>
          </div>
       </section> */}
   
       <footer class="construct-footer-area">
          <div class="construct-footer-top-area section_50">
             <div class="container">
                <div class="row4">
                   <div class="col-md-3">
                      <div class="single-footer ">
                         <a href="#">
                         <img src="	http://themescare.com/demos/construct-v-1-2/assets/img/footer-logo.png" alt="footer logo" />
                         </a>
                         <p>Ipsum dolor sit amet consect adipsicing sed elit tempor incididunt ueta nostrud dolore magna aliqua ut enim minim .</p>
                         <div class="footer-social-icon">
                            <h3>Follow Us</h3>
                            <ul class="footer-social">
                               <li>
                                  <a href="#" class="fb">
                                  <i class="fab fa-facebook"></i>
                                  </a>
                               </li>
                               <li>
                                  <a href="#" class="twitter">
                                  <i class="fab fa-twitter"></i>
                                  </a>
                               </li>
                               <li>
                                  <a href="#" class="gp">
                                  <i class="fab fa-google-plus"></i>
                                  </a>
                               </li>
                               <li>
                                  <a href="#" class="linkedin">
                                  <i class="fab fa-linkedin"></i>
                                  </a>
                               </li>
                            </ul>
                         </div>
                      </div>
                   </div>
                   <div class="col-md-3">
                      <div class="single-footer ">
                         <h3>quick links</h3>
                         <ul class="footer-widget">
                            <li>
                               <a href="/aboutus">
                               <i class="fa fa-angle-right"></i>
                               about us
                               </a>
                            </li>
                            <li>
                               <a href="#">
                               <i class="fa fa-angle-right"></i>
                               what we offers
                               </a>
                            </li>
                            <li>
                               <a href="#">
                               <i class="fa fa-angle-right"></i>
                               testimonial
                               </a>
                            </li>
                            <li>
                               <a href="#">
                               <i class="fa fa-angle-right"></i>
                               our project
                               </a>
                            </li>
                            <li>
                               <a href="#">
                               <i class="fa fa-angle-right"></i>
                               terms &amp; conditions
                               </a>
                            </li>
                         </ul>
                      </div>
                   </div>
                   <div class="col-md-3">
                      <div class="single-footer ">
                         <h3>head office</h3>
                         <p>3638 Karnataka, Karnataka Banglore, India </p>
                         <p class="contact-details">
                            <i class="fa fa-phone"></i>
                            987 - 5678 - 9012
                         </p>
                         <p class="contact-details">
                            <i class="fa fa-envelope"></i>
                            info@example.com
                         </p>
                         <p class="contact-details">
                            <i class="fa fa-hourglass-2"></i>
                            8:00 a.m - 17:00 a.m
                         </p>
                      </div>
                   </div>
                   <div class="col-md-3">
                      <div class="single-footer ">
                         <h3>Instagram</h3>
                         <div class="single-footer-insta">
                            <div class="insta-post">
                               <a href="#">
                               <img src="	http://themescare.com/demos/construct-v-1-2/assets/img/insta-3.jpg" alt="instagram 1" /> 
                               </a>
                               <div class="overlay-insta"></div>
                            </div>
                            <div class="insta-post">
                               <a href="#">
                               <img src="	http://themescare.com/demos/construct-v-1-2/assets/img/insta-6.jpg" alt="instagram 1" />
                               </a>
                               <div class="overlay-insta"></div>
                            </div>
                            <div class="insta-post">
                               <a href="#">
                               <img src="	http://themescare.com/demos/construct-v-1-2/assets/img/insta-1.jpg" alt="instagram 1" />
                               </a>
                               <div class="overlay-insta"></div>
                            </div>
                            <div class="insta-post">
                               <a href="#">
                               <img src="	http://themescare.com/demos/construct-v-1-2/assets/img/insta-3.jpg" alt="instagram 1" />
                               </a>
                               <div class="overlay-insta"></div>
                            </div>
                            <div class="insta-post">
                               <a href="#">
                               <img src="	http://themescare.com/demos/construct-v-1-2/assets/img/insta-4.jpg" alt="instagram 1" />
                               </a>
                               <div class="overlay-insta"></div>
                            </div>
                            <div class="insta-post">
                               <a href="#">
                               <img src="	http://themescare.com/demos/construct-v-1-2/assets/img/insta-6.jpg" alt="instagram 1" />
                               </a>
                               <div class="overlay-insta"></div>
                            </div>
                         </div>
                      </div>
                   </div>
                </div>
             </div>
          </div>
          <div class="construct-footer-bottom-area section_15">
             <div class="container">
                <div class="row4">
                   <div class="col-md-6">
                      <div class="copyright-left">
                         <p>Copyrights © 2023 Construct. All Rights Reserved. </p>
                      </div>
                   </div>
                   <div class="col-md-6">
                      {/* <div class="copyright-right">
                         <p>Built with <i class="fa fa-heart"></i> from<span>Themescare</span></p>
                      </div> */}
                   </div>
                </div>
             </div>
          </div>
       </footer>
      
       <div class="modal fade" id="myModal" tabindex="-1" role="dialog">
          <div class="container">
             <div class="row4">
                <div class="col-md-12">
                   <div class="modal-dialog" role="document">
                      <div class="modal-content">
                         <div class="modal-body">
                            <div class="search_box_container">
                               <form action="#">
                                  <input type="text" placeholder="Search Here.." />
                                  <button type="submit">
                                  <i class="fa fa-search"></i>
                                  </button>
                               </form>
                            </div>
                         </div>
                      </div>
                   </div>
                </div>
             </div>
          </div>
       </div>
    
      </div>
   
  );
};

export default HomeArc;
