import { React, useEffect, useState } from "react";
import "./cart.css";
import Navbar from "../navbar/Navbar";
import { Link, useLocation } from "react-router-dom";
import moment from "moment";
import { format } from "date-fns";
import { DateRange } from "react-date-range";
const CartStay = () => {
  const location = useLocation();
  const { item } = location.state;
  console.log(location);
  const [rooms, setrooms] = useState(location.state.Room.room);
  const increaseRangerooms = () => {
    setrooms(rooms + 1);
  };
  const decrementrooms = () => {
    setrooms(rooms - 1);
  };
  const [adult, setadult] = useState(location.state.Room.adult);
  const increaseRangeadult = () => {
    setadult(adult + 1);
  };
  const decrementadult = () => {
    setadult(adult - 1);
  };
  const [children, setChildren] = useState(location.state.Room.children);
  const [childrenAges, setChildrenAges] = useState(
    Array(location.state.Room.children).fill(0)
  );
  const handleAgeChange = (index, age) => {
    if (age >= 0 && age < 18) {
      const newAges = [...childrenAges];
      newAges[index] = age;
      setChildrenAges(newAges);
    }
  };
  const increaseRangeChildren = () => {
    setChildren(children + 1);
    setChildrenAges([...childrenAges, null]);
  };

  const decrementChildren = () => {
    if (children > 0) {
      setChildren(children - 1);
      setChildrenAges(childrenAges.slice(0, -1));
    }
  };
  const [date, setDate] = useState(
    location.state.date
      ? location.state.date
      : [
          {
            startDate: new Date(),
            endDate: new Date(),
            key: "selection",
          },
        ]
  );
  const startDate = date[0].startDate.setHours(0, 0, 0, 0);
  const endDate = date[0].endDate.setHours(0, 0, 0, 0);
  const timeDifference = endDate - startDate;
   const daysDifference = timeDifference / (1000 * 60 * 60 * 24);
  const [openDate, setOpenDate] = useState(false);
  const handleDateChange = (selectedDates) => {
    setDate([selectedDates.selection]);
  };
  console.log(item, "itrm");
  return (
    <>
      <Navbar />
      <div class="header12 listMode">
        <div class="headerContainer12 container12">
          <div class="headerCont12">
            <div>
              <h2
                class="headerTitle12 text-center"
                style={{ marginTop: "50px" }}
              >
                Fresh, quite and peaceful
              </h2>
            </div>
          </div>
        </div>
      </div>
      <div className="containercart">
        <div id="primary" className="primary content-area">
          <article className="post-1831 page type-page status-publish hentry">
            <div className="entry-content">
              <div
                className="woocommerce"
                style={{
                  display: "flex",
                  flexdirection: "row",
                  margin: "20px",
                  justifyContent: "space-around",
                  alignItems: "center",
                }}
              >
                <form
                  className="woocommerce-cart-form"
                  action="https://cozystay.loftocean.com/countryside-lodge/cart/"
                  method="post"
                  style={{ margin: "20px" }}
                >
                  <table
                    className="shop_table shop_table_responsive cart woocommerce-cart-form__contents"
                    cellSpacing={0}
                    style={{ border: "1px solid black",padding:"10px" }}
                  >
                    <thead style={{ border: "1px solid black",padding:"10px" }}>
                      <tr style={{ border: "1px solid black",padding:"10px" }}>
                        <th
                          className="product-thumbnail"
                          style={{ border: "1px solid black",padding:"10px" }}
                        >
                          <span className="screen-reader-text">
                            Thumbnail image
                          </span>
                        </th>
                        <th
                          className="product-name"
                          style={{ border: "1px solid black",padding:"10px" ,fontWeight:"bolder"}}
                        >
                          Stay
                        </th>
                        {/* <th className="product-price">Price</th> */}
                        <th
                          className="product-quantity"
                          style={{ border: "1px solid black",padding:"10px",fontWeight:"bolder" }}
                        >
                          Options
                        </th>
                        <th
                          className="product-subtotal"
                          style={{ border: "1px solid black",padding:"10px",fontWeight:"bolder" }}
                        >
                          Price
                        </th>
                      </tr>
                    </thead>
                    <tbody style={{ border: "1px solid black",padding:"10px" }}>
                      <tr
                        className="woocommerce-cart-form__cart-item cart_item"
                        style={{ border: "1px solid black",padding:"10px" }}
                      >
                        <td
                          className="product-thumbnail"
                          style={{ border: "1px solid black",padding:"10px" }}
                        >
                          <Link
                            to={`/hotels/${item._id}`}
                            state={{
                              date: location.state.date,
                              options: location.state.Room,
                            }}
                          >
                            <img
                              decoding="async"
                              width={150}
                              height={150}
                              src={`https://bookingoda.com/image/${item.Photos[0].file}`}
                              className="attachment-thumbnail size-thumbnail wp-post-image"
                              alt=""
                              sizes="(max-width: 150px) 100vw, 150px"
                            />
                          </Link>{" "}
                        </td>
                        <td
                          className="product-name"
                          data-title="Product"
                          style={{ border: "1px solid black",padding:"10px" }}
                        >
                          <Link
                            to={`/hotels/${item._id}`}
                            state={{
                              date: location.state.date,
                              options: location.state.Room,
                            }}
                          >
                            {item.PropertyName}
                          </Link>

                          <div className="cs-room-order-wrapper">
                            <div className="cs-room-order-date">
                              <strong>Room Name: </strong>{" "}
                              {location.state.RoomDetails.Roomname[0]?.label}
                            </div>
                            {/* <div className="cs-room-order-date">
                              <strong>Date: </strong>
                              {moment(location.state.date[0].startDate).format(
                                "DD/MM/YYYY"
                              )}{" "}
                              -{" "}
                              {moment(location.state.date[0].endDate).format(
                                "DD/MM/YYYY"
                              )}{" "}
                            </div> */}
                            <div className="cs-room-order-details">
                              <strong>Details: </strong>
                              Adults: {
                                location.state.Room.adult
                              } ,Children: {location.state.Room.children}
                            </div>
                            <div className="lsItem">
                              <strong>Dates: </strong>
                              <span
                                onClick={() => setOpenDate(!openDate)}
                              >{`${format(startDate, "MM/dd/yyyy")} to ${format(
                                endDate,
                                "MM/dd/yyyy"
                              )}`}</span>
                              {openDate && (
                                <DateRange
                                  onChange={handleDateChange}
                                  minDate={new Date()}
                                  ranges={date}
                                  style={{ width: "290px" }}
                                />
                              )}
                            </div>
                          </div>
                        </td>
                        {/* <td className="product-price" data-title="Price">
                          <span className="woocommerce-Price-amount amount">
                            <bdi>
                              <span className="woocommerce-Price-currencySymbol">
                                Rs.
                              </span>
                              {location.state.amount}.00
                            </bdi>
                          </span>{" "}
                        </td> */}
                        <td
                          className="product-quantity"
                          data-title="Quantity"
                          style={{ border: "1px solid black",padding:"10px" }}
                        >
                          <div className="quantity hidden">
                            <input
                              type="hidden"
                              id="quantity_658bf3b950d45"
                              className="qty"
                              name="cart[16b181772732166294441b4d4fcb7579][qty]"
                              defaultValue={1}
                            />
                          </div>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "space-between",
                            }}
                          >
                            <div
                              className="quantity"
                              style={{ margin: "20px" }}
                            >
                              {/* {location.state.Room.room} */}
                              <strong>Adult</strong>
                              <div
                                id="automation_id_add_bedroom_sofa_bed_input"
                                className="a7a72174b8 input-stepper"
                                style={{ marginLeft: "-70px" }}
                              >
                                <div className="bfb38641b0">
                                  <button
                                    tabIndex={-1}
                                    disabled={adult < 1}
                                    aria-hidden="true"
                                    type="button"
                                    className="a83ed08757 c21c56c305 f38b6daa18 d691166b09 ab98298258 deab83296e bb803d8689 e91c91fa93"
                                    onClick={decrementadult}
                                  >
                                    <span className="eedba9e88a">
                                      <span
                                        className="fcd9eec8fb bf9a32efa5"
                                        aria-hidden="true"
                                      >
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          viewBox="0 0 24 24"
                                        >
                                          <path d="M20.25 12.75H3.75a.75.75 0 0 1 0-1.5h16.5a.75.75 0 0 1 0 1.5z" />
                                        </svg>
                                      </span>
                                    </span>
                                  </button>
                                  <span
                                    className="d723d73d5f"
                                    aria-hidden="true"
                                  >
                                    {adult}
                                  </span>
                                  <button
                                    tabIndex={-1}
                                    aria-hidden="true"
                                    type="button"
                                    className="a83ed08757 c21c56c305 f38b6daa18 d691166b09 ab98298258 deab83296e bb803d8689 f4d78af12a"
                                    onClick={increaseRangeadult}
                                  >
                                    <span className="eedba9e88a">
                                      <span
                                        className="fcd9eec8fb bf9a32efa5"
                                        aria-hidden="true"
                                      >
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          viewBox="0 0 24 24"
                                        >
                                          <path d="M20.25 11.25h-7.5v-7.5a.75.75 0 0 0-1.5 0v7.5h-7.5a.75.75 0 0 0 0 1.5h7.5v7.5a.75.75 0 0 0 1.5 0v-7.5h7.5a.75.75 0 0 0 0-1.5z" />
                                        </svg>
                                      </span>
                                    </span>
                                  </button>
                                </div>
                              </div>
                            </div>{" "}
                            <div
                              className="quantity"
                              style={{ margin: "20px" }}
                            >
                              {/* {location.state.Room.room} */}
                              <strong>Rooms</strong>
                              <div
                                id="automation_id_add_bedroom_sofa_bed_input"
                                className="a7a72174b8 input-stepper"
                                style={{ marginLeft: "-70px" }}
                              >
                                <div className="bfb38641b0">
                                  <button
                                    tabIndex={-1}
                                    disabled={rooms < 1}
                                    aria-hidden="true"
                                    type="button"
                                    className="a83ed08757 c21c56c305 f38b6daa18 d691166b09 ab98298258 deab83296e bb803d8689 e91c91fa93"
                                    onClick={decrementrooms}
                                  >
                                    <span className="eedba9e88a">
                                      <span
                                        className="fcd9eec8fb bf9a32efa5"
                                        aria-hidden="true"
                                      >
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          viewBox="0 0 24 24"
                                        >
                                          <path d="M20.25 12.75H3.75a.75.75 0 0 1 0-1.5h16.5a.75.75 0 0 1 0 1.5z" />
                                        </svg>
                                      </span>
                                    </span>
                                  </button>
                                  <span
                                    className="d723d73d5f"
                                    aria-hidden="true"
                                  >
                                    {rooms}
                                  </span>
                                  <button
                                    tabIndex={-1}
                                    aria-hidden="true"
                                    type="button"
                                    className="a83ed08757 c21c56c305 f38b6daa18 d691166b09 ab98298258 deab83296e bb803d8689 f4d78af12a"
                                    onClick={increaseRangerooms}
                                  >
                                    <span className="eedba9e88a">
                                      <span
                                        className="fcd9eec8fb bf9a32efa5"
                                        aria-hidden="true"
                                      >
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          viewBox="0 0 24 24"
                                        >
                                          <path d="M20.25 11.25h-7.5v-7.5a.75.75 0 0 0-1.5 0v7.5h-7.5a.75.75 0 0 0 0 1.5h7.5v7.5a.75.75 0 0 0 1.5 0v-7.5h7.5a.75.75 0 0 0 0-1.5z" />
                                        </svg>
                                      </span>
                                    </span>
                                  </button>
                                </div>
                              </div>
                            </div>
                            <div
                              className="quantity"
                              style={{ margin: "20px" }}
                            >
                              <strong>Children</strong>
                              <div
                                id="automation_id_add_bedroom_sofa_bed_input"
                                className="a7a72174b8 input-stepper"
                                style={{ marginLeft: "-70px" }}
                              >
                                <div className="bfb38641b0">
                                  <button
                                    tabIndex={-1}
                                    disabled={children < 1}
                                    aria-hidden="true"
                                    type="button"
                                    className="a83ed08757 c21c56c305 f38b6daa18 d691166b09 ab98298258 deab83296e bb803d8689 e91c91fa93"
                                    onClick={decrementChildren}
                                  >
                                    <span className="eedba9e88a">
                                      <span
                                        className="fcd9eec8fb bf9a32efa5"
                                        aria-hidden="true"
                                      >
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          viewBox="0 0 24 24"
                                        >
                                          <path d="M20.25 12.75H3.75a.75.75 0 0 1 0-1.5h16.5a.75.75 0 0 1 0 1.5z" />
                                        </svg>
                                      </span>
                                    </span>
                                  </button>
                                  <span
                                    className="d723d73d5f"
                                    aria-hidden="true"
                                  >
                                    {children}
                                  </span>
                                  <button
                                    tabIndex={-1}
                                    aria-hidden="true"
                                    type="button"
                                    className="a83ed08757 c21c56c305 f38b6daa18 d691166b09 ab98298258 deab83296e bb803d8689 f4d78af12a"
                                    onClick={increaseRangeChildren}
                                  >
                                    <span className="eedba9e88a">
                                      <span
                                        className="fcd9eec8fb bf9a32efa5"
                                        aria-hidden="true"
                                      >
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          viewBox="0 0 24 24"
                                        >
                                          <path d="M20.25 11.25h-7.5v-7.5a.75.75 0 0 0-1.5 0v7.5h-7.5a.75.75 0 0 0 0 1.5h7.5v7.5a.75.75 0 0 0 1.5 0v-7.5h7.5a.75.75 0 0 0 0-1.5z" />
                                        </svg>
                                      </span>
                                    </span>
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                          {Array.from({ length: children }).map((_, index) => (
                            <div
                              key={index}
                              style={{
                                marginTop: "10px",
                              }}
                            >
                              <label>Child {index + 1} Age:</label>
                              <input
                                type="number"
                                onKeyDown={(event) => {
                                  const allowedKeys = [
                                    "Backspace",
                                    "ArrowLeft",
                                    "ArrowRight",
                                    "Delete",
                                    "Tab",
                                  ];
                                  if (
                                    !/[0-9]/.test(event.key) &&
                                    !allowedKeys.includes(event.key)
                                  ) {
                                    event.preventDefault();
                                  }
                                }}
                                value={
                                  childrenAges[index] != null
                                    ? childrenAges[index]
                                    : ""
                                }
                                onChange={(e) => {
                                  const value = e.target.value;
                                  if (
                                    value === "" ||
                                    (parseInt(value) >= 0 &&
                                      parseInt(value) <= 17)
                                  ) {
                                    handleAgeChange(
                                      index,
                                      value === "" ? null : parseInt(value)
                                    );
                                  }
                                }}
                                min="0"
                                max="17"
                              />
                            </div>
                          ))}
                        </td>
                        <td
                          className="product-subtotal"
                          data-title="Subtotal"
                          style={{ border: "1px solid black",padding:"10px" }}
                        >
                          <span className="woocommerce-Price-amount amount">
                            <bdi>
                              <span className="woocommerce-Price-currencySymbol">
                                Rs.
                              </span>
                              {location.state.amount}
                            </bdi>
                          </span>{" "}
                        </td>
                      </tr>
                      {/* <tr>
                          <td colSpan={6} className="actions">
                            <div className="coupon">
                              <label htmlFor="coupon_code" className="screen-reader-text">Coupon:</label> <input type="text" name="coupon_code" className="input-text" id="coupon_code" defaultValue placeholder="Coupon code" /> <button type="submit" className="button" name="apply_coupon" value="Apply coupon">Apply coupon</button>
                            </div>
                            <button type="submit" className="button" name="update_cart" value="Update cart" disabled>Update cart</button>
                            <input type="hidden" id="woocommerce-cart-nonce" name="woocommerce-cart-nonce" defaultValue="c4ba7a4c00" /><input type="hidden" name="_wp_http_referer" defaultValue="/countryside-lodge/cart/" />				</td>
                        </tr> */}
                    </tbody>
                  </table>
                </form>
                <div className="cart-collaterals">
                  <div className="cart_totals ">
                    <h2>Cart totals</h2>
                    <table
                      cellSpacing={0}
                      className="shop_table shop_table_responsive"
                      style={{ border: "1px solid black",padding:"10px" }}
                    >
                      <tbody style={{ border: "1px solid black",padding:"10px" }}>
                        {/* <tr className="cart-subtotal">
                          <th>Subtotal</th>
                          <td data-title="Subtotal">
                            <span className="woocommerce-Price-amount amount">
                              <bdi>
                                <span className="woocommerce-Price-currencySymbol">
                                  Rs.
                                </span>
                                299.00
                              </bdi>
                            </span>
                          </td>
                        </tr> */}
                        <tr
                          className="order-total"
                          style={{ border: "1px solid black",padding:"10px" }}
                        >
                          <th style={{ border: "1px solid black",padding:"10px",fontWeight:"bolder" }}>Total</th>
                          <td
                            data-title="Total"
                            style={{ border: "1px solid black",padding:"10px" }}
                          >
                            <strong>
                              <span className="woocommerce-Price-amount amount">
                                <bdi>
                                  <span className="woocommerce-Price-currencySymbol">
                                    Rs.
                                  </span>
                                  {location.state.amount * rooms * daysDifference}
                                </bdi>
                              </span>
                            </strong>{" "}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <div className="wc-proceed-to-checkout">
                      <Link
                        to="/checkout"
                        state={{ items: location.state, noofrooms: rooms }}
                        className="checkout-button button alt wc-forward"
                        style={{ backgroundColor: "green" }}
                      >
                        Proceed to checkout
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* .post-entry */}
          </article>{" "}
        </div>
      </div>
    </>
  );
};

export default CartStay;
