// import "./about.css";
import React from "react";
import "./community.scoped.css";
import Profile_Dashboard from "./Profile_Dashboard";
import moment from "moment";
import pics from "../../assets/user.png"

import { useEffect, useState } from "react";
import axios from "axios";

function Mypages() {
  const user = JSON.parse(sessionStorage.getItem("user"));
  // const [showadd, setshowadd] = useState(false)
  const [userdetails, setuserdetails] = useState({});
  const [userPost, setuserPost] = useState([]);
  const [following, setFollowing] = useState([]);
  const [typecomments, settypecomments] = useState("");
  const [showComments, setShowComments] = useState("");
  const [showMoreComments, setShowMoreComments] = useState("");
  const [selectedpost, setselectedpost] = useState({});
  const [comments_image, setComments_image] = useState();
  const formdata = new FormData();
  useEffect(() => {
    getuser();
    getPost();
    getAllFollowing();
  }, []);

  const getuser = async () => {
    let res = await axios.get(
      "https://bookingoda.com/api/user/getmyprofile/" + user._id
    );
    if (res.status === 200) {
      //console.log("getuser===>", res.data);
      setuserdetails(res.data.user);
    }
  };

  const getPost = async () => {
    let res = await axios.get(
      "https://bookingoda.com/api/user/getPost"
    );
    if (res.status === 200) {
      //console.log("getPost===>", res.data);
      setuserPost(res.data.data);
    }
  };

  const getAllFollowing = async () => {
    let res = await axios.get(
      `https://bookingoda.com/api/user/getFririndlistByuserId/${user._id}`
    );
    if (res.status === 200) {
      //console.log("getAllFollowing==>", res.data);
      setFollowing(res.data.success);
    }
  };
console.log(userdetails);
const AddComments = async (e) => {
    e.preventDefault();
    formdata.append("postId", selectedpost._id);
    formdata.append("userId", user?._id);
    formdata.append("commentsText", typecomments);
    formdata.append("comments_Image", comments_image);
    try {
      const config = {
        url: "/user/addComments",
        method: "post",
        baseURL: "https://bookingoda.com/api",
        hearder: { "content-type": "multipart/form-data" },
        data: formdata,
      };
      await axios(config).then(function (res) {
        if (res.status === 200) {
          //console.log(res.data);
          // alert("comments added");
          settypecomments("");
          setComments_image();
          getPost();
          // window.location.reload(); ipo check pannu
        }
      });
    } catch (error) {
      //console.log(error.res);
      alert("Something went wrong!!! try again");
    }
  };
  const onShare = async (id) => {
    try {
      if (navigator.share) {
        await navigator.share({
          url: `https://mukkatis.com/shared/${id}`,
        });
        console.log('URL shared successfully');
      } else {
        // Web Share API not supported, handle fallback
        console.log('Web Share API is not supported in this browser');
        // You can provide alternative sharing methods here, e.g., copy to clipboard
      }
    } catch (error) {
      console.error('Error sharing URL:', error);
    }
  };
  const Addlike = async (e, id) => {
    e.preventDefault();
    try {
      const config = {
        url: "/user/addlike",
        method: "post",
        baseURL: "https://bookingoda.com/api",
        // hearder: { "content-type": "multipart/form-data" },
        data: {
          postId: id,
          userId: user?._id,
        },
      };
      await axios(config).then(function (res) {
        if (res.status === 200) {
          //console.log(res.data);
          // alert("comments added");
          getPost();
          // window.location.reload(); ipo check pannu
        }
      });
    } catch (error) {
      //console.log(error.res);
      alert("Something went wrong!!! try again");
    }
  };

  const unlike = async (e, id) => {
    e.preventDefault();

    try {
      const config = {
        url: "/user/unlike",
        method: "post",
        baseURL: "https://bookingoda.com/api",
        // hearder: { "content-type": "multipart/form-data" },
        data: {
          postId: id,
          userId: user?._id,
        },
      };
      await axios(config).then(function (res) {
        if (res.status === 200) {
          //console.log(res.data);
          // alert("comments added");
          getPost();
          // window.location.reload(); ipo check pannu
        }
      });
    } catch (error) {
      //console.log(error.res);
      alert("Something went wrong!!! try again");
    }
  };
  return (
    <div className="communityhomestyle">
   
      <div className="gap2 gray-bg">
        <div
          className="container fluid mobile-correct-0"
          style={{ paddingTop: "30px" }}
        >
          <div className="row">
            <div className="col-lg-12">
              <div className="row merged20" id="page-contents">
                {/* profile Dashboard start ========================================= */}
                <div className="user-profile">
                  <Profile_Dashboard />
                </div>
               
            
             
                <div className="col-lg-12 col-md-8">
                  <div className="central-meta">
                    <span className="create-post">
                      My Feeds
                         {/* <a href="#" title="">
                        See All
                      </a> */} 
                    </span>

                
                  </div>
                  <div class="loadMore">
                        {userPost.filter(item =>item.userId === user._id)?.map((de) => (
                          <div class="central-meta item">
                            <div class="user-post">
                              <div class="friend-info">
                                <figure>
                                 {de?.user[0]?.profileImage ? <img
                                    src={`https://bookingoda.com/admin/${de?.user[0]?.profileImage}`}
                                    alt=""
                                  /> : <img
                                    src={pics}
                                    alt=""
                                  />}
                                </figure>
                                <div class="friend-name">
                                
                                  <ins>
                                    <a href="#" title="">
                                      {de.user[0]?.name}
                                    </a>{" "}
                                    Post Album
                                  </ins>
                                  <span>
                                    <i class="fas fa-globe"></i> published:{" "}
                                    {moment(de?.createdAt).format(
                                      "MMMM Do YYYY, h:mm:ss a"
                                    )}
                                  </span>
                                </div>
                                <div class="post-meta">
                                  <p>{de.text}</p>
                                  <figure>
                                    <div class="img-bunch">
                                      <div class="row">
                                        {de.image ? (
                                          <div class="col-lg-6 col-md-6 col-sm-6">
                                            <figure>
                                              <a
                                                href={`https://bookingoda.com/admin/${de?.image}`}
                                                title=""
                                                data-toggle="modal"
                                                data-target="#img-comt"
                                                target={"_blank"}
                                              >
                                                <img
                                                  src={`https://bookingoda.com/admin/${de?.image}`}
                                                  alt=""
                                                />
                                              </a>
                                            </figure>
                                          </div>
                                        ) : (
                                          ""
                                        )}

                                        {de.video ? (
                                          <div class="col-lg-6 col-md-6 col-sm-6">
                                            <figure>
                                              <a
                                                href={`https://bookingoda.com/admin/${de?.video}`}
                                                title=""
                                                target="_blank"
                                              >
                                                <video width="100%" controls>
                                                  <source
                                                    src={`https://bookingoda.com/admin/${de?.video}`}
                                                    type="video/mp4"
                                                  />
                                                  Your browser does not support
                                                  the video tag.
                                                </video>
                                              </a>
                                            </figure>
                                          </div>
                                        ) : (
                                          ""
                                        )}
                                      </div>
                                    </div>
                                  </figure>
                                  <div class="we-video-info">
                                    <ul>
                                      <li>
                                        {de?.love?.find(
                                          (item) => item.userId === user?._id
                                        )?.userId === user?._id ? (
                                          <span
                                            class="likes heart"
                                            title="Like/Dislike"
                                            onClick={(e) => unlike(e, de._id)}
                                          >
                                            <i
                                              class="fa-solid fa-heart"
                                              style={{ color: "red" }}
                                            ></i>
                                            {/* ❤ */}
                                            <ins>{de?.love?.length}</ins>
                                          </span>
                                        ) : (
                                          <span
                                            class="likes heart"
                                            title="Like/Dislike"
                                            onClick={(e) => Addlike(e, de._id)}
                                          >
                                            <i class="fa-solid fa-heart"></i>
                                            {/* ❤ */}
                                            <ins>{de?.love?.length}</ins>
                                          </span>
                                        )}
                                      </li>
                                      <li>
                                        <span class="comment" title="Comments">
                                          <i
                                            class="fas fa-commenting"
                                            onClick={() =>
                                              setShowComments(de._id)
                                            }
                                          ></i>
                                          <ins>{de?.comment?.length} </ins>
                                        </span>
                                      </li>

                                      <li>
                                        <span>
                                          <div
                                            class="share-pst"
                                        
                                            title="Share"
                                            onClick={() => onShare(de._id)}
                                          >
                                            <i class="fas fa-share-alt"></i>
                                          </div>
                                        </span>
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                                {showComments === de._id ? (
                                  <div
                                    class="coment-area"
                                    style={{ display: "block" }}
                                  >
                                    <ul class="we-comet">
                                      {showMoreComments === de._id ? (
                                        de?.commentusers?.map((cm, i) => (
                                          <li>
                                            <div class="comet-avatar">
                                              <img
                                                src={`https://bookingoda.com/admin/${de?.commentusers[i]?.profileImage}`}
                                                alt=""
                                              />
                                            </div>
                                            <div class="we-comment">
                                              <h5>
                                                <a href="#" title="">
                                                  {" "}
                                                  {
                                                    de?.commentusers[i]
                                                      ?.username
                                                  }
                                                </a>
                                              </h5>
                                              <p>
                                                {" "}
                                                {
                                                  de?.comment[i]?.commentsText
                                                }{" "}
                                              </p>
                                              <img
                                                src={`https://bookingoda.com/admin/${de?.comment[i]?.comments_Image}`}
                                                alt=""
                                                style={{
                                                  width: "25%",
                                                  display: "flex",
                                                }}
                                              />
                                              <div class="inline-itms">
                                                <span>
                                                  {moment(
                                                    de?.comment[i]
                                                      ?.comments_date
                                                  ).format(
                                                    "MMMM Do YYYY, h:mm:ss a"
                                                  )}
                                                </span>
                                              </div>
                                            </div>
                                          </li>
                                        ))
                                      ) : (
                                        <li>
                                          <div class="comet-avatar">
                                            <img
                                              src={`https://bookingoda.com/admin/${de?.commentusers[0]?.profileImage}`}
                                              alt=""
                                            />
                                          </div>
                                          <div class="we-comment">
                                            <h5>
                                              <a href="#" title="">
                                                {" "}
                                                {de?.commentusers[0]?.username}
                                              </a>
                                            </h5>
                                            <p>
                                              {" "}
                                              {
                                                de?.comment[0]?.commentsText
                                              }{" "}
                                            </p>
                                            <img
                                              src={`https://bookingoda.com/admin/${de?.comment[0]?.comments_Image}`}
                                              alt=""
                                              style={{
                                                width: "25%",
                                                display: "flex",
                                              }}
                                            />
                                            <div class="inline-itms">
                                              <span>
                                                {moment(
                                                  de?.comment[0]?.comments_date
                                                ).format(
                                                  "MMMM Do YYYY, h:mm:ss a"
                                                )}
                                              </span>
                                            </div>
                                          </div>
                                        </li>
                                      )}

                                      <li>
                                        <a
                                          onClick={() =>
                                            setShowMoreComments(de._id)
                                          }
                                          title=""
                                          class="showmore underline"
                                        >
                                          more comments+
                                        </a>
                                      </li>
                                      <li class="post-comment">
                                        <div class="comet-avatar">
                                          <img
                                            src={`https://bookingoda.com/admin/${user?.profileImage}`}
                                            alt=""
                                          />
                                        </div>
                                        <div
                                          class="post-comt-box"
                                          onClick={() => setselectedpost(de)}
                                        >
                                          <form method="post">
                                            <textarea
                                              placeholder="Post your comment"
                                              value={typecomments}
                                              onChange={(e) =>
                                                settypecomments(e.target.value)
                                              }
                                            ></textarea>

                                            <button
                                              onClick={(e) => AddComments(e)}
                                            >
                                              Post
                                            </button>
                                          </form>
                                        </div>
                                      </li>
                                    </ul>
                                  </div>
                                ) : (
                                  ""
                                )}
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                 
                
                </div>
               
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Mypages;
