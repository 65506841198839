import Footer from "../footer/Footer";
import React, { useEffect, useState } from "react";
import { FaArrowRight, FaArrowUp, FaLongArrowAltUp } from "react-icons/fa";
import { Carousel, Image, Modal } from "react-bootstrap";
import { SlCalender } from "react-icons/sl";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { GrMap } from "react-icons/gr";
import "./homecar.css";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Navbar from "../navbar/Navbar";
import axios from "axios";
import { Link, useLocation } from "react-router-dom";
import Icon1 from "../../assets/specification-icon-1.svg";
import Icon2 from "../../assets/specification-icon-2.svg";
import Icon3 from "../../assets/specification-icon-3.svg";
import Icon4 from "../../assets/specification-icon-4.svg";
import Icon5 from "../../assets/specification-icon-5.svg";
import Icon6 from "../../assets/specification-icon-7.svg";
import Icon7 from "../../assets/specification-icon-9.svg";
import Icon8 from "../../assets/specification-icon-12.svg";
import Car1 from "../../assets/car-parts-01.svg";
import Car2 from "../../assets/car-parts-02.svg";
import Car3 from "../../assets/car-parts-03.svg";
import Car4 from "../../assets/car-parts-04.svg";
import Car5 from "../../assets/car-parts-05.svg";
import Car6 from "../../assets/car-parts-06.svg";
import IndianCities from "indian-cities-json";
import { MdDelete } from "react-icons/md";
import { Tooltip } from "antd";
import { useRef } from "react";

function Carrentaldetails() {
  const location = useLocation();
  const user = JSON.parse(sessionStorage.getItem("user"));

  const pickuplocation = JSON.parse(sessionStorage.getItem("pickuplocation"));
  const handleKeyDown = (e) => {
    // Prevent the input of the minus sign
    if (e.key === "-" || e.key === "e" || e.key === "E") {
      e.preventDefault();
    }
  };
  const pickupdate = JSON.parse(sessionStorage.getItem("pickupdate"));
  const dropuptime = JSON.parse(sessionStorage.getItem("dropuptime"));
  const dropupdate = JSON.parse(sessionStorage.getItem("dropupdate"));
  const pickuptime = JSON.parse(sessionStorage.getItem("pickuptime"));
  const { car } = location.state;
  const [viewImage, setViewImage] = useState(car?.Photos[0]?.file || "");

  const responsive1 = {
    0: { items: 2 },
    600: { items: 3 },
    1000: { items: 6 },
  };
  const [carType, setCarType] = useState("");
  const [pickUp, setPickUp] = useState(pickuplocation);

  const [dropOff, setDropOff] = useState("");
  const [pickTime, setPickTime] = useState(pickupdate);
  const [dropTime, setDropTime] = useState(dropupdate);
  const [pickTime1, setPickTime1] = useState(pickuptime);
  const [dropTime1, setDropTime1] = useState(dropuptime);
  const [carImg, setCarImg] = useState("");

  // modal infos
  ////console.log(pickTime1,"sadsa");
  const [name, setName] = useState("");

  const [phone, setPhone] = useState("");
  const [age, setAge] = useState("");
  const [email, setEmail] = useState("");
  const [address, setAddress] = useState("");
  const [city, setCity] = useState("");
  const [zipcode, setZipCode] = useState("");

  const handleCityChange1 = (event) => {
    setDropOff(event.target.value);
  };
  const handleCityChange = (event) => {
    setPickUp(event.target.value);
  };
  const handleName = (e) => {
    setName(e.target.value);
  };

  const handlePhone = (e) => {
    setPhone(e.target.value);
  };

  const handleAge = (e) => {
    setAge(e.target.value);
  };

  const handleEmail = (e) => {
    setEmail(e.target.value);
  };

  const handleAddress = (e) => {
    setAddress(e.target.value);
  };

  const handleCity = (e) => {
    setCity(e.target.value);
  };

  const handleZip = (e) => {
    setZipCode(e.target.value);
  };
  const [isChecked, setChecked] = useState(false);

  // Function to handle checkbox change
  const handleCheckboxChange = () => {
    setChecked(!isChecked);
  };

  const handlePick = (e) => {
    setPickUp(e.target.value);
  };

  const handleDrop = (e) => {
    setDropOff(e.target.value);
  };

  const handlePickTime = (e) => {
    setPickTime(e.target.value);
  };

  const handleDropTime = (e) => {
    setDropTime(e.target.value);
  };
  const handlePickTime1 = (e) => {
    setPickTime1(e.target.value);
  };

  const handleDropTime1 = (e) => {
    setDropTime1(e.target.value);
  };
  const [uploadedImage, setUploadedImage] = useState([]);
  const fileInputRef = useRef(null);

  const handleFileChange = (event) => {
    const files = Array.from(event.target.files);
    setUploadedImage([...uploadedImage, ...files]);
    // Reset the file input value to allow re-uploading the same image
    fileInputRef.current.value = null;
  };

  const deleteImage = (index) => {
    const updatedImages = [...uploadedImage];
    updatedImages.splice(index, 1);
    setUploadedImage(updatedImages.length === 0 ? [] : updatedImages);
    if (fileInputRef.current) {
      fileInputRef.current.value = null;
    }
  };
  const mobilePattern = /^[0-9]{10}$/;
  const PackageBooking = async () => {
    if (!user) {
      alert("Please login");
      window.location.assign("/login");
    } else if (
      !pickUp ||
      !dropOff ||
      !pickTime ||
      !dropTime ||
      !pickTime1 ||
      !dropTime1 ||
      !age ||
      !address ||
      !city ||
      !zipcode ||
      !phone
    ) {
      alert("please fill all the feilds");
    } else if (age < 18 || age > 60) {
      alert("Age should be between 18 and 60");
    } else if (!mobilePattern.test(phone)) {
      return alert("Please enter a valid mobile number");
    } else if (uploadedImage.length === 0) {
      alert("No documents selected");
    } else {
      try {
        const config = {
          url: "admin/bookcar",
          method: "post",
          baseURL: "https://bookingoda.com/api/",
          headers: { "Content-Type": "application/json" },
          data: {
            userId: user?._id,

            name: name ? name : user.name,
            email: email ? email : user.email,
            mobile: phone ? phone : user.mobile,
            pickUpLocation: pickUp,
            dropOffLocation: dropOff,
            pickUp: pickTime,
            dropOff: dropTime,
            pickTime: pickTime1,
            dropTime: dropTime1,
            age: age,
            address: address,
            city: city,
            zipcode: zipcode,
            driver: isChecked,
            CarId: car._id,
          },
        };
        await axios(config).then((res) => {
          if (res.status === 200) {
            alert(res.data.success);
            uploadFiles(res.data.data?._id);
            // window.sessionStorage.removeItem("pickuplocation");
            // window.sessionStorage.removeItem("pickupdate");
            // window.sessionStorage.removeItem("dropuptime");
            // window.sessionStorage.removeItem("dropupdate");
            // window.sessionStorage.removeItem("pickuptime");
            // window.location.assign("/carrentals");
          }
        });
      } catch (error) {
        ////console.log(error);
        alert(error.response.data.error);
      }
    }
  };
  const formdata2 = new FormData();
  const uploadFiles = async (id) => {
    try {
      for (let i = 0; i < uploadedImage.length; i++) {
        formdata2.append("id", id);
        formdata2.append("Doc", uploadedImage[i]);
        await fetch("https://bookingoda.com/api/admin/addDocCar/", {
          method: "post",
          body: formdata2,
        });
        alert("Booked Succesfully");
        window.sessionStorage.removeItem("pickuplocation");
        window.sessionStorage.removeItem("pickupdate");
        window.sessionStorage.removeItem("dropuptime");
        window.sessionStorage.removeItem("dropupdate");
        window.sessionStorage.removeItem("pickuptime");
        window.location.assign("/carrentals");
      }
    } catch (error) {}
  };
  const responsive = {
    loop: true,
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 5,
      slidesToSlide: 1,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
      slidesToSlide: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1,
    },
  };
  const options = {
    loop: true,
    margin: 10,
    responsive: {
      0: {
        items: 1,
      },
      600: {
        items: 2,
      },
      1000: {
        items: 3,
      },
    },
  };
  const [show, setshow] = useState(false);
  const handleshowClose = () => setshow(false);
  const handleshow = () => setshow(true);
  const [cars, setcar] = useState([]);
  const getcar = async () => {
    let res = await axios.get("https://bookingoda.com/api/admin/getallCar");
    if (res.status === 200) {
      ////console.log(res.data.Car);
      setcar(res.data.Car.filter((item) => item.blockstatus !== true));
    }
  };
  useEffect(() => {
    getcar();
  }, [cars]);
  return (
    <>
      <Navbar />
      <div className="main-wrapper" style={{ transform: "none" }}>
        {/* Breadscrumb Section */}
        <div className="breadcrumb-barcar">
          <div className="container">
            <div className="row align-items-center text-center">
              <div className="col-md-12 col-12">
                <h2 className="breadcrumb-title">{car.CarName}</h2>
                <nav aria-label="breadcrumbcar" className="page-breadcrumbcar">
                  <ol className="breadcrumbcar">
                    <li className="breadcrumb-itemcar">
                      <a href="/carrentals">Home</a>
                    </li>
                    <li className="breadcrumb-itemcar">
                      <a href="/carlist">Listings</a>
                    </li>
                    <li
                      className="breadcrumb-itemcar active"
                      aria-current="page"
                    >
                      {car.CarName}
                    </li>
                  </ol>
                </nav>
              </div>
            </div>
          </div>
        </div>

        {/* /Detail Page Head*/}
        <section
          className="section product-details"
          style={{ transform: "none" }}
          id="test"
        >
          <div className="container" style={{ transform: "none" }}>
            <div className="row" style={{ transform: "none" }}>
              <div className="col-lg-8">
                <div className="detail-product">
                  <div className="slider detail-bigimg slick-initialized slick-slider">
                    <div className="slick-list draggable">
                      <div
                        className="slick-track"
                        style={{ opacity: 1, width: "3580px" }}
                      >
                        <div
                          className="product-img slick-slide slick-current slick-active"
                          data-slick-index={0}
                          aria-hidden="false"
                          tabIndex={0}
                          style={{
                            width: "696px",
                            position: "relative",
                            left: "0px",
                            top: "0px",
                            zIndex: 999,
                            opacity: 1,
                          }}
                        >
                          <Image
                            src={`https://bookingoda.com/image/${viewImage}`}
                            alt="Main View"
                            style={{
                              width: "100%",
                              height: "550px",
                              objectFit: "cover",
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div
                    className="thumbnail-container"
                    style={{
                      display: "flex",
                      flexWrap: "wrap",
                      justifyContent: "center",
                      marginTop: "20px",
                    }}
                  >
                    {car.Photos?.map((item, index) => (
                      <div
                        key={index}
                        onClick={() => setViewImage(item.file)}
                        style={{ margin: "5px", cursor: "pointer" }}
                      >
                        <Image
                          src={`https://bookingoda.com/image/${item.file}`}
                          alt={`Thumbnail ${index + 1}`}
                          style={{
                            width: "100px",
                            height: "100px",
                            objectFit: "cover",
                            border:
                              viewImage === item.file
                                ? "2px solid blue"
                                : "none",
                          }}
                        />
                      </div>
                    ))}
                  </div>
                </div>

                {/*Listing Features Section*/}
                <div className="review-sec specification-card ">
                  <div className="review-header">
                    <h4>Specifications</h4>
                  </div>
                  <div className="card-body">
                    <div className="lisiting-featues">
                      <div className="row">
                        <div className="featureslist d-flex align-items-center col-xl-3 col-md-4 col-sm-6">
                          <div className="feature-img">
                            <img src={Icon1} alt="Icon" />
                          </div>
                          <div className="featues-info">
                            <span>Car </span>
                            <h6> {car.CarName}</h6>
                          </div>
                        </div>
                        <div className="featureslist d-flex align-items-center col-xl-3 col-md-4 col-sm-6">
                          <div className="feature-img">
                            <img src={Icon2} alt="Icon" />
                          </div>
                          <div className="featues-info">
                            <span>Model </span>
                            <h6> {car.CarModel}</h6>
                          </div>
                        </div>
                        <div className="featureslist d-flex align-items-center col-xl-3 col-md-4 col-sm-6">
                          <div className="feature-img">
                            <img src={Icon3} alt="Icon" />
                          </div>
                          <div className="featues-info">
                            <span>Transmission </span>
                            <h6> {car.TransmissionType}</h6>
                          </div>
                        </div>
                        <div className="featureslist d-flex align-items-center col-xl-3 col-md-4 col-sm-6">
                          <div className="feature-img">
                            <img src={Icon4} alt="Icon" />
                          </div>
                          <div className="featues-info">
                            <span>Fuel Type </span>
                            <h6>{car.FuelType}</h6>
                          </div>
                        </div>
                        <div className="featureslist d-flex align-items-center col-xl-3 col-md-4 col-sm-6">
                          <div className="feature-img">
                            <img src={Icon5} alt="Icon" />
                          </div>
                          <div className="featues-info">
                            <span>Mileage </span>
                            <h6>{car.Mileage}</h6>
                          </div>
                        </div>

                        <div className="featureslist d-flex align-items-center col-xl-3 col-md-4 col-sm-6">
                          <div className="feature-img">
                            <img src={Icon6} alt="Icon" />
                          </div>
                          <div className="featues-info">
                            <span>Year</span>
                            <h6>{car.YearofManufacture}</h6>
                          </div>
                        </div>

                        <div className="featureslist d-flex align-items-center col-xl-3 col-md-4 col-sm-6">
                          <div className="feature-img">
                            <img src={Icon7} alt="Icon" />
                          </div>
                          <div className="featues-info">
                            <span>Seats </span>
                            <h6> {car.Seats}</h6>
                          </div>
                        </div>

                        <div className="featureslist d-flex align-items-center col-xl-3 col-md-4 col-sm-6">
                          <div className="feature-img">
                            <img src={Icon8} alt="Icon" />
                          </div>
                          <div className="featues-info">
                            <span>Engine</span>
                            <h6>{car.Enginetype.slice(0, 7) + "..."}</h6>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {car.InsuranceDetails !== "" &&
                car.RegistrationDetails !== "" ? (
                  <div className="review-sec extra-service mb-0">
                    {/* <div className="review-header">
                      <h4>Description of Listing</h4>
                    </div> */}
                    <div className="description-list">
                      {car.InsuranceDetails ? <h4>Insurance Details</h4> : ""}
                      <p>{car.InsuranceDetails}</p>

                      {/* {car.RegistrationDetails ?    <h4>Registration Details</h4> : ""}
        <p>{car.RegistrationDetails}</p> */}
                    </div>
                  </div>
                ) : (
                  ""
                )}
              </div>
              <div
                className="col-lg-4 theiaStickySidebar"
                style={{
                  position: "relative",
                  overflow: "visible",
                  boxSizing: "border-box",
                  minHeight: "1px",
                }}
              >
                <div
                  className="theiaStickySidebar"
                  style={{
                    paddingTop: "0px",
                    paddingBottom: "1px",
                    position: "static",
                    transform: "none",
                    top: "0px",
                    left: "833.656px",
                  }}
                >
                  <div className="review-sec mt-0">
                    <div className="review-header">
                      <h4>Book Car</h4>
                    </div>
                    <div className>
                      <form className>
                        <ul>
                          <li className="column-group-main">
                            <div className="input-block">
                              <label>Pickup Location</label>
                              <div className="group-img">
                                {/* <input
                                type="text"
                                className="form-control"
                                placeholder="Pickup Location"
                                value={pickUp}
                                  onChange={handlePick}
                              /> */}
                                <select
                                  id="cities"
                                  name="cities"
                                  value={pickUp}
                                  onChange={handlePick}
                                >
                                  <option value="">
                                    Select pick up location
                                  </option>
                                  {IndianCities.cities?.map((data) => (
                                    <option value={data.name}>
                                      {data.name}
                                    </option>
                                  ))}
                                </select>
                              </div>
                            </div>
                          </li>
                          <li className="column-group-main">
                            <div className="input-block">
                              <label>Dropoff Location</label>
                              <div className="group-img">
                                {/* <input
                                type="text"
                                className="form-control"
                                placeholder="Drop off location"
                                value={dropOff}
                                  onChange={handleDrop}
                              /> */}
                                <select
                                  id="cities"
                                  name="cities"
                                  value={dropOff}
                                  onChange={handleDrop}
                                >
                                  <option value="">
                                    Select drop off location
                                  </option>
                                  {IndianCities.cities?.map((data) => (
                                    <option value={data.name}>
                                      {data.name}
                                    </option>
                                  ))}
                                </select>
                              </div>
                            </div>
                          </li>
                          <li className="column-group-main">
                            <div className="input-block m-0">
                              <label>Pickup Date</label>
                            </div>
                            <div className="input-block-wrapp sidebar-form">
                              <div className="input-block me-2">
                                <div className="group-img">
                                  <input
                                    type="date"
                                    className="form-control datetimepicker"
                                    placeholder="04/11/2023"
                                    value={pickTime}
                                    onChange={handlePickTime}
                                    min={new Date().toISOString().split("T")[0]}
                                  />
                                </div>
                              </div>
                              <div className="input-block">
                                <div className="group-img">
                                  <input
                                    type="time"
                                    className="form-control timepicker"
                                    placeholder="11:00 AM"
                                    value={pickTime1}
                                    onChange={handlePickTime1}
                                  />
                                </div>
                              </div>
                            </div>
                          </li>
                          <li className="column-group-main">
                            <div className="input-block m-0">
                              <label>Return Date</label>
                            </div>
                            <div className="input-block-wrapp sidebar-form">
                              <div className="input-block me-2">
                                <div className="group-img">
                                  <input
                                    type="date"
                                    className="form-control datetimepicker"
                                    placeholder="04/11/2023"
                                    value={dropTime}
                                    onChange={handleDropTime}
                                    min={new Date().toISOString().split("T")[0]}
                                  />
                                </div>
                              </div>
                              <div className="input-block">
                                <div className="group-img">
                                  <input
                                    type="time"
                                    className="form-control timepicker"
                                    placeholder="11:00 AM"
                                    value={dropTime1}
                                    onChange={handleDropTime1}
                                  />
                                </div>
                              </div>
                            </div>
                          </li>
                          <li className="column-group-main">
                            <div className="input-block">
                              <label>Name</label>
                              <div className="group-img">
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Name"
                                  value={name}
                                  onChange={handleName}
                                />
                              </div>
                            </div>
                          </li>
                          <li className="column-group-main">
                            <div className="input-block">
                              <label>Mobile Number</label>
                              <div className="group-img">
                                <input
                                  type="tel"
                                  onKeyDown={handleKeyDown}
                                  maxLength={10}
                                  className="form-control"
                                  placeholder="Mobile Number"
                                  value={phone}
                                  onChange={handlePhone}
                                />
                              </div>
                            </div>
                          </li>
                          <li className="column-group-main">
                            <div className="input-block">
                              <label>Age</label>
                              <div className="group-img">
                                <input
                                  type="number"
                                  onKeyDown={handleKeyDown}
                                  max={2}
                                  className="form-control"
                                  placeholder="Age"
                                  value={age}
                                  onChange={handleAge}
                                />
                              </div>
                            </div>
                          </li>
                          <li className="column-group-main">
                            <div className="input-block">
                              <label>Email</label>
                              <div className="group-img">
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Email"
                                  value={email}
                                  onChange={handleEmail}
                                />
                              </div>
                            </div>
                          </li>
                          <li className="column-group-main">
                            <div className="input-block">
                              <label>Address</label>
                              <div className="group-img">
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Address"
                                  value={address}
                                  onChange={handleAddress}
                                />
                              </div>
                            </div>
                          </li>
                          <li className="column-group-main">
                            <div className="input-block">
                              <label>City</label>
                              <div className="group-img">
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="City"
                                  value={city}
                                  onChange={handleCity}
                                />
                              </div>
                            </div>
                          </li>
                          <li className="column-group-main">
                            <div className="input-block">
                              <label>Pin code</label>
                              <div className="group-img">
                                <input
                                  type="number"
                                  onKeyDown={handleKeyDown}
                                  max={6}
                                  className="form-control"
                                  placeholder="Pin code"
                                  value={zipcode}
                                  onChange={handleZip}
                                />
                              </div>
                            </div>
                          </li>
                          <div className="upload-photos_container">
                            <div className="drag-and-drop__highlight">
                              <div className="wide-container min-height ota-container">
                                <div
                                  className="spacing--4x"
                                  data-testid="NumberOfPhotosRequiredMessage-container"
                                >
                                  <label
                                    htmlFor="photoFileInput"
                                    className="add-photo-sub-info font_body_2"
                                  >
                                    <div
                                      className="a3332d346a"
                                      style={{
                                        margin: "10px",
                                        display: "block",
                                      }}
                                    >
                                      Upload documents for verfication (Pan
                                      card/Aadhar Card/Any other relevant
                                      government document).
                                    </div>{" "}
                                  </label>
                                </div>
                                <div className="take-photo take-photo--with-drag-and-drop">
                                  <div className="drag-and-drop">
                                    <div
                                      style={{
                                        border: "2px dashed #cccccc",
                                        borderRadius: "4px",
                                        padding: "20px",
                                        textAlign: "center",
                                        display: "flex",
                                        flexDirection: "row",
                                        cursor: "pointer",
                                      }}
                                    >
                                      <input
                                        type="file"
                                        multiple
                                        onChange={handleFileChange}
                                        ref={fileInputRef}
                                      />
                                      <p>Click to select one or more images</p>
                                    </div>
                                  </div>
                                </div>
                                <div className="take-photo take-photo--with-drag-and-drop">
                                  <div className="drag-and-drop">
                                    <div
                                      style={{
                                        textAlign: "center",
                                        marginTop: "10px",
                                      }}
                                    >
                                      {uploadedImage &&
                                        uploadedImage.map((item, index) => (
                                          <div
                                            key={index}
                                            style={{
                                              display: "inline-block",
                                              marginRight: "10px",
                                              marginTop: "10px",
                                            }}
                                          >
                                            <img
                                              src={URL.createObjectURL(item)}
                                              alt="Uploaded"
                                              style={{ maxWidth: "50px" }}
                                            />

                                            <Tooltip title="delete">
                                              <MdDelete
                                                color="red"
                                                onClick={() =>
                                                  deleteImage(index)
                                                }
                                              />
                                            </Tooltip>
                                          </div>
                                        ))}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <li>
                            <div className="input-block">
                              <label>
                                <input
                                  type="checkbox"
                                  checked={isChecked}
                                  onChange={handleCheckboxChange}
                                />
                                {"   "} Is Driver Required
                              </label>
                            </div>
                          </li>
                          <li className="column-group-last">
                            <div className="input-block mb-0">
                              <div className="search-btn">
                                <button
                                  className="btn btn-primary check-available w-100"
                                  type="button"
                                  onClick={() => handleshow()}
                                >
                                  {" "}
                                  Book Car
                                </button>
                              </div>
                            </div>
                          </li>
                        </ul>
                      </form>
                    </div>
                  </div>
                  <div
                    className="resize-sensor"
                    style={{
                      position: "absolute",
                      inset: "0px",
                      overflow: "hidden",
                      zIndex: -1,
                      visibility: "hidden",
                    }}
                  >
                    <div
                      className="resize-sensor-expand"
                      style={{
                        position: "absolute",
                        left: 0,
                        top: 0,
                        right: 0,
                        bottom: 0,
                        overflow: "hidden",
                        zIndex: -1,
                        visibility: "hidden",
                      }}
                    >
                      <div
                        style={{
                          position: "absolute",
                          left: "0px",
                          top: "0px",
                          transition: "all 0s ease 0s",
                          width: "390px",
                          height: "3536px",
                        }}
                      />
                    </div>
                    <div
                      className="resize-sensor-shrink"
                      style={{
                        position: "absolute",
                        left: 0,
                        top: 0,
                        right: 0,
                        bottom: 0,
                        overflow: "hidden",
                        zIndex: -1,
                        visibility: "hidden",
                      }}
                    >
                      <div
                        style={{
                          position: "absolute",
                          left: 0,
                          top: 0,
                          transition: "0s",
                          width: "200%",
                          height: "200%",
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div className="details-car-grid">
                  <div className="details-slider-heading">
                    <h3>You May be Interested in</h3>
                  </div>
                  <div
                    className="car-details-slider owl-carousel owl-loaded owl-drag"
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    {/* <OwlCarousel className="owl-theme" {...options}> */}
                    {cars.slice(0, 3).map((item) => {
                      return (
                        <div className="cardcar">
                          <div className="listing-itemcar">
                            <div className="listing-img">
                              <Link
                                to="/Carrentaldetails"
                                state={{ car: item }}
                              >
                                <img
                                  src={`https://bookingoda.com/image/${item?.Photos[0]?.file}`}
                                  className="img-fluid"
                                  alt="Toyota"
                                  style={{ width: "380px", height: "250px" }}
                                />
                              </Link>
                              <div className="fav-item">
                                <span className="featured-text">
                                  {item.CarModel}
                                </span>
                              </div>
                            </div>
                            <div className="listing-content">
                              <div className="listing-features">
                                <h3 className="listing-title">
                                  <Link
                                    to="/Carrentaldetails"
                                    state={{ car: item }}
                                  >
                                    {item.CarName}
                                  </Link>
                                </h3>
                              </div>
                              <div className="listing-details-group">
                                <ul>
                                  <li>
                                    <span>
                                      <img src={Car1} alt="Auto" />
                                    </span>
                                    <p>{item.TransmissionType}</p>
                                  </li>
                                  <li>
                                    <span>
                                      <img src={Car2} alt="10 KM" />
                                    </span>
                                    <p>{item.Mileage} KM/ltr</p>
                                  </li>
                                  <li>
                                    <span>
                                      <img src={Car3} alt="Petrol" />
                                    </span>
                                    <p>{item.FuelType}</p>
                                  </li>
                                </ul>
                                <ul>
                                  <li>
                                    <span>
                                      <img src={Car4} alt="Power" />
                                    </span>
                                    <p>{item.Enginetype}</p>
                                  </li>
                                  <li>
                                    <span>
                                      <img src={Car5} alt={2018} />
                                    </span>
                                    <p>{item.YearofManufacture}</p>
                                  </li>
                                  <li>
                                    <span>
                                      <img src={Car6} alt="Persons" />
                                    </span>
                                    <p>{item.Seats} Seats</p>
                                  </li>
                                </ul>
                              </div>
                              <div className="listing-location-details">
                                {/* <div className="listing-price">
                          <span>
                            <GrMapLocation />
                          </span>
                          Germany
                        </div> */}
                                <div
                                  className="listing-price"
                                  style={{ alignSelf: "center" }}
                                >
                                  <h6>
                                    &#8377; {item.Price} <span>/ Per km</span>
                                  </h6>
                                </div>
                              </div>
                              <div className="listing-button">
                                <Link
                                  to="/Carrentaldetails"
                                  state={{ car: item }}
                                >
                                  {" "}
                                  <a className="btn btn-order">
                                    <span>
                                      {" "}
                                      <span>
                                        <SlCalender className="me-2" />
                                      </span>
                                    </span>
                                    Rent Now
                                  </a>
                                </Link>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })}

                    {/* </OwlCarousel>  */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* Modal */}
        <Modal
          show={show}
          onHide={handleshowClose}
          backdrop="static"
          keyboard={false}
          size="lg"
        >
          <Modal.Header>
            Booking Details{" "}
            <button
              type="button"
              className="close"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={handleshowClose}
            >
              <span className="align-center" aria-hidden="true">
                ×
              </span>
            </button>
          </Modal.Header>
          <Modal.Body>
            <div className="rowcar">
              <div className="col-lg-12 col-md-12">
                <div className="row booking-info">
                  <div className="col-md-4 pickup-address">
                    <h5>Pickup</h5>
                    <p>{pickUp}</p>
                    <span>
                      Date &amp; time : {pickTime}/{pickTime1}
                    </span>
                  </div>
                  <div className="col-md-4 drop-address">
                    <h5>Drop Off</h5>
                    <p>{dropOff}</p>
                    <span>
                      Date &amp; time : {dropTime}/{dropTime1}
                    </span>
                  </div>
                  <div className="col-md-4 booking-amount">
                    <h5>Booking Amount</h5>
                    <h6>
                      <span>
                        &#8377; {car.Price} <span>/ Per km </span>
                      </span>
                    </h6>
                  </div>
                </div>
              </div>

              {/* <div className="col-md-12">
                  <div className="booking-info pay-amount">
                    <h6>Deposit Option</h6>
                    <div className="radio radio-btn">
                      <label>
                        <input type="radio" name="radio" /> Pay Deposit
                      </label>
                    </div>
                    <div className="radio">
                      <label>
                        <input type="radio" name="radio" /> Full Amount
                      </label>
                    </div>
                  </div>
                </div> */}
              <div className="col-md-6" />
              <div className="col-md-6">
                <div className="booking-info service-tax">
                  <ul>
                    <li>
                      Booking Price <span>&#8377; {car.Price} </span>/ Per km
                    </li>
                    {/* <li>
                        Extra Service <span>$10</span>
                      </li>
                      <li>
                        Tax <span>$5</span>
                      </li> */}
                  </ul>
                </div>
                <div className="grand-total">
                  <h5>Grand Total</h5>
                  <span>
                    &#8377; {car.Price} <span>/ Per km</span>
                  </span>
                </div>
                <div className="search-btn">
                  <button
                    className="btn btn-primary check-available w-100"
                    type="button"
                    onClick={PackageBooking}
                  >
                    {" "}
                    Book Car
                  </button>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </div>
      <Footer />
    </>
  );
}

export default Carrentaldetails;
