import React, { useEffect, useState } from "react";
import "./createaccount.css";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { BsEyeFill, BsFillEyeSlashFill } from "react-icons/bs";

const CreateAccount = () => {
  const [email, setEmail] = useState("");
  // const [name, setname] = useState("");
  // const [Lname, setLname] = useState("");
  // const [mobile, setmobile] = useState("");
  // const [password, setpassword] = useState("");
  // const [cpassword, setcpassword] = useState("");
  // const [eye,seteye]=useState(false)
  // const [eye1,seteye1]=useState(false)
  const validateEmail = (inputEmail) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const isValidEmail = emailRegex.test(inputEmail);
    setIsValid(isValidEmail);
    return isValidEmail; // Return the validation result
  };
  let history = useNavigate();
  const [isValid, setIsValid] = useState(true);


  const Handlepress = () => {
    if (!email) {
      alert("Please enter email and Continue");
    } else if (!validateEmail(email)) {
      alert("Please enter a valid email");
    } else {
      sessionStorage.setItem("email", JSON.stringify(email));
      history('/contactdetails');
    }
  };
  useEffect(() => {
    // Retrieve items from sessionStorage
    const storedEmail = sessionStorage.getItem("email");
    

    // Check if the items exist in sessionStorage and set state accordingly
    if (storedEmail) {
      setEmail(JSON.parse(storedEmail));
    }

    // ... Continue retrieving and setting other items as needed

  }, []);
  return (
    <div id="root" class="bui-u-text-left bui_font_body">
      <div>
        <div class="">
          <div class="">
            <div class="partner-header">
              <header class=" aCWKa87CwyJQfbPP_yg6">
                <nav class="yC5_EodFadTqIIY8_YDx">
                  <div class="NrR_ZlI8bZYI3w06JKKb">
                    <div class="H2AEzwF6W02wmXtVrp9U">
                    <div class="mobile-join-logo join_logo">
                <span style={{ color: "white",fontSize:30,marginTop:"40px"}}>Booking-Oda.com</span>
                </div>
                    </div>
                  </div>
                </nav>
              </header>
            </div>
            <div class="app">
              <div class="access-container bui_font_body">
                <div class="access-panel-container">
                  <div class="access-panel nw-access-panel">
                    <div class="transition-container">
                      <div
                        class="animate-height"
                        style={{ height: "auto", overflow: "visible" }}
                      >
                        <div class="sliding-panel">
                          <div class="transition bui-panel-body slide-enter-done">
                            <div class="">
                              <div
                                tabindex="-1"
                                class="page-header"
                                aria-labelledby="8ebd8b67-dcac-423e-8481-d173da499783"
                                aria-describedby="78ccc58c-3222-4279-bd83-0bcb9ebb5f13"
                              >
                                <h1
                                  id="8ebd8b67-dcac-423e-8481-d173da499783"
                                  class="bui_font_display_one bui_font_heading--bold bui-spacer--medium nw-step-header"
                                >
                                  Create your partner account
                                </h1>
                                <div class="bui-spacer--largest">
                                  <div>
                                    <p
                                      class="nw-step-description"
                                      id="78ccc58c-3222-4279-bd83-0bcb9ebb5f13"
                                    >
                                      Create an account to list and manage your
                                      property.
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <form class="nw-register" novalidate="">
                              <div class="input-wrapper-email bui-spacer--large nw-login-name">
                                <div class="sOewt4Y1yBOPAIUGcJwH wkTNdQjAfRVbKvFBiR1T">
                                  <label
                                    for="login_name_register"
                                    class="sOewt4Y1yBOPAIUGcJwH hAFaqujHGDhKOcPl6uUa _nwGprfLZfZgMFjsmap7"
                                  >
                                    <span>
                                      <span class="L08id9Rh757AqBBZobKh">
                                        Email address
                                      </span>
                                    </span>
                                  </label>
                                  <div class="cTkJymCseykCmdCpB4JG">
                                    <div class="KBX_kI7wQ0ziDRM0x5Ig">
                                      <input
                                        type="email"
                                        name="login_name_register"
                                        class="ZMH9h0HCYH9GGNxHnXGJ"
                                        placeholder="Enter your email here"
                                        onChange={(e) =>
                                          setEmail(e.target.value)
                                        }
                                        value={email}
                                        id="login_name_register"
                                      />
                                      <div class="KlWiFnWD2KyUjaUF1WAE"></div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                             
                              <div class="ext_partner_links u-text-center bui-spacer" style={{marginTop:"-20px"}}><hr />Questions about your property or the extranet? Check out <a  target="_blank" rel="noopener noreferrer" data-ga-action="click" data-ga-label="Partner help centre">Partner Help</a> or ask another question on the <a  target="_blank" rel="noopener noreferrer" data-ga-action="click" data-ga-label="Partner forum">Partner Community</a>.</div>
                           
                            <div
                                data-dv-event-id="8"
                                type="submit"
                                onClick={Handlepress}
                                class="Iiab0gVMeWOd4XcyJGA3 wPxWIS_rJCpwAWksE0s3 Ut3prtt_wDsi7NM_83Jc TuDOVH9WFSdot9jLyXlw EJWUAldA4O1mP0SSFXPm "
                                style={{
                                  backgroundColor: "#70bbe9",
                                  borderColor: "#70bbe9",
                                  color: "white",
                                }}
                              >
                                <span class="yfCvx60qsR50VNBG15jF">
                                  Continue
                                </span>
                              </div>
                           
                              <div
                                class="u-text-center bui-spacer--top"
                                onClick={() =>
                                  window.location.assign("/signin")
                                }
                              >
                                <a
                                  class="nw-link-signin"
                                  data-ga-action="click"
                                  data-ga-label="Sign in"
                                  data-dv-event-id="9"
                                >
                                  <button
                                    type="button"
                                    class="Iiab0gVMeWOd4XcyJGA3 wPxWIS_rJCpwAWksE0s3 UUMoIgvHWjWBfPzTW9Lg TuDOVH9WFSdot9jLyXlw EJWUAldA4O1mP0SSFXPm bvJ1qjCOOZTWBD88F3yW"
                                    style={{
                                      backgroundColor: "white",
                                      borderColor: "#70bbe9",
                                      color: "#70bbe9",
                                    }}
                                  >
                                    <span class="yfCvx60qsR50VNBG15jF">
                                      Sign in
                                    </span>
                                  </button>
                                </a>
                              </div>
                              <div className="account-access__footer">
                <div className="u-text-center bui_font_caption portal_footer">
                  <div className="account_footer_terms footer-block">
                    By signing in or creating an account, you agree with our{" "}
                    <a
                     
                      className="bui_color_action nw-terms"
                      target="_blank"
                      rel="noopener noreferrer"
                      data-ga-action="click"
                      data-ga-label="Terms partner"
                    >
                      Terms &amp; conditions
                    </a>{" "}
                    and{" "}
                    <a
                     
                      className="bui_color_action nw-privacy"
                      target="_blank"
                      rel="noopener noreferrer"
                      data-ga-action="click"
                      data-ga-label="Privacy partner"
                    >
                      Privacy statement
                    </a>
                  </div>
                  <div className="access-footer-bottom bui_font_caption footer-block">
                    <div>
                      All rights reserved. <br />
                      Copyright (2006 - 2023) - Booking-Oda.com™{" "}
                    </div>
                  </div>
                </div>
              </div>
                            </form>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* <div class="account-access__footer">
                <div class="u-text-center bui_font_caption portal_footer">
                  <div class="account_footer_terms footer-block">
                    By signing in or creating an account, you agree with our{" "}
                    <a
                      href="https://www.booking.com/general.en-gb.html?tmpl=docs/terms_of_use"
                      class="bui_color_action nw-terms"
                      target="_blank"
                      rel="noopener noreferrer"
                      data-ga-action="click"
                      data-ga-label="Terms partner"
                    >
                      Terms &amp; conditions
                    </a>{" "}
                    and{" "}
                    <a
                      href="https://admin.booking.com/hotel/hoteladmin/privacy.html?lang=en-gb"
                      class="bui_color_action nw-privacy"
                      target="_blank"
                      rel="noopener noreferrer"
                      data-ga-action="click"
                      data-ga-label="Privacy partner"
                    >
                      Privacy statement
                    </a>
                  </div>
                  <div class="access-footer-bottom bui_font_caption footer-block">
                    <div>
                      All rights reserved. <br />
                      Copyright (2006 - 2023) - Booking-Oda.com™{" "}
                    </div>
                  </div>
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateAccount;
