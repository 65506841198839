import { React, useEffect, useState } from "react";
import CustomInput from "../components/CustomInput";
import ReactQuill from "react-quill";
import { useNavigate } from "react-router-dom";
import "react-quill/dist/quill.snow.css";
import { toast } from "react-toastify";
import * as yup from "yup";
import { useFormik } from "formik";

import {  Table } from "antd";
import Dropzone, { useDropzone } from "react-dropzone";

import Register from "../../pages/register/Register";
import axios from "axios";
import { Modal } from "react-bootstrap";
import Success from "../../assets/success.png";
import Error from "../../assets/close.png";
import { MdDelete } from "react-icons/md";
import { Select } from "@material-ui/core";
let schema = yup.object().shape({});

const AddNearBy = () => {
  const [nearby, setnearby] = useState([]);
 
  const [nearbyName, setnearbyName] = useState("");

  
  const [nearbyadd, setnearbyadd] = useState(true);
  const [nearbylist, setnearbylist] = useState(false);
  const handlenearby = () => {
    setnearbyadd(true);
    setnearbylist(false);
  };
  const handlenearbylist = () => {
    setnearbyadd(false);
    setnearbylist(true);
  };

  const formik = useFormik({
    initialValues: {
      images: "",
    },
    validationSchema: schema,
    onSubmit: (values) => {
      ////console.log(values);
    },
  });
  const formdata = new FormData();

  const Register1 = async (e) => {
    e.preventDefault();
    if (!nearbyName || !nearbyName) {
      alert("Please select all the feilds");
    } else {
      try {
        const config = {
          url: "/addnearby",
          method: "post",
          baseURL: "https://bookingoda.com/api/admin",

          headers: { "content-type": "application/json" },
          data: {
           
            nearbyName:nearbyName,
   
          },
          // data: {banner:uploadedImages},
        };
        await axios(config).then(function (response) {
          if (response.status === 200) {
            ////console.log("success");
            handlesuccess();
            getnearby();
            setnearbyName("");
            setnearbyName("");
          }
        });
      } catch (error) {
        console.error(error);
        handleerror();
      }
    }
  };
 
  const remove = async (datas) => {
    try {
      const config = {
        url: `/deletenearby/${datas._id}`,
        method: "delete",
        baseURL: "https://bookingoda.com/api/admin",
        headers: { "Content-Type": "application/json" },
      };

      let res = await axios(config);
      if (res.status === 200) {
        alert("Successfully removed");
        getnearby();
        
      } else {
        alert("Something Wrong");
      }
    } catch (error) {
      if (error.response) {
        alert("Something went wrong");
      }
    }
  };
  const [successshow, setsuccessshow] = useState(false);
  const handlesuccessClose = () => setsuccessshow(false);
  const handlesuccess = () => setsuccessshow(true);

  const [errorshow, seterrorshow] = useState(false);
  const handleerrorClose = () => seterrorshow(false);
  const handleerror = () => seterrorshow(true);
  const getnearby = async () => {
    let res = await axios.get(
      "https://bookingoda.com/api/admin/getnearby"
    );
    if ((res.status = 200)) {
      ////console.log(res);
      setnearby(res.data?.nearby);
    }
  };

  
  useEffect(() => {
    // getcountry();
    getnearby();
 
  }, []);
  const columns = [
    {
      title: "Serial No.",
      dataIndex: "Sno",

      // //fixed: "left",
      // width: 50,
    },
    {
      title: "Unique ID",
      dataIndex: "UniqueId",

      // //fixed: "left",
    },
    {
      title: "Nearby Service Name",
      dataIndex: "nearby",
      width: 550,
    },
   

    {
      title: "Action",
      dataIndex: "action1",
      key: "operation",
      //fixed: "right",

      // render: () => <Link className="ms-3 fs-8 text-success">Approve</Link>,
    },
  ];
  const data1 = [];
  for (let i = 0; i < nearby?.length; i++) {
    data1.push({
      Sno: i + 1,
      UniqueId: "BONB" + nearby[i]._id.slice(22, 24).toUpperCase(),

      nearby:nearby[i].nearbyName,
     


      action1: (
        <>
          <div className="d-flex gap-2 fs-4" style={{ cursor: "pointer" }}>
            <MdDelete
              className="text-danger"
              onClick={() => remove(nearby[i])}
            />
          </div>
        </>
      ),
    });
  }
  return (
    <div>
      <h3 className="mb-4 title">Add Nearby Service</h3>
      <button
        className="btn btn-success border-0 rounded-3 my-5 mr-5"
        type="submit"
        onClick={handlenearby}
      >
        Add Nearby Service
      </button>
      <button
        className="btn btn-success border-0 rounded-3 my-5"
        type="submit"
        onClick={handlenearbylist}
      >
        Nearby Service List
      </button>
      {nearbyadd ? (
        <div>
          <form
            onSubmit={formik.handleSubmit}
            className="d-flex gap-3 flex-column"
          >
            <div className="bg-white border-1 p-5">
         
         
                <CustomInput
                type="text"
                label="Nearby Service Name"
                name="Nearby Service"
                onChng={(e) => setnearbyName(e.target.value)}
                val={nearbyName}
              />
            </div>
          
            <button
              className="btn btn-success border-0 rounded-3 my-5"
              type="submit"
              onClick={Register1}
            >
              Add Nearby Service 
            </button>
          </form>
        </div>
      ) : (
        ""
      )}
      {nearbylist ? (
        <div>
          <Table
            columns={columns}
            className="custom-table"

            dataSource={data1}
            // scroll={{ x: "calc(700px + 50%)", y: 240 }}
            pagination={{
              pageSize: 5,
              showSizeChanger: false, // Optional: Hide the "Show X entries" dropdown
            }}
            components={{
              header: {
              
                cell: props => (
                  <th {...props} style={{ ...props.style, background:'linear-gradient(#8b4246,#642F31,#401E20)'  ,color:"white"}} />
                ),
              },
            }}
          />
        </div>
      ) : (
        ""
      )}
      <Modal
        show={successshow}
        onHide={handlesuccess}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton onClick={handlesuccessClose}></Modal.Header>
        <Modal.Body>
          <div className="text-center">
            <img src={Success} style={{ marginTop: "-12px" }} width="100px" />

            <p>Successfully Added</p>
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        show={errorshow}
        onHide={handleerror}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton onClick={handleerrorClose}></Modal.Header>
        <Modal.Body>
          <div className="text-center">
            <img src={Error} style={{ marginTop: "-12px" }} width="100px" />

            <p>Error Occured</p>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default AddNearBy;
