import React, { useEffect, useState } from "react";
import { Button, Card, Carousel, Container, Form } from "react-bootstrap";
import { FaAngleRight, FaDirections } from "react-icons/fa";
import Modal from "react-bootstrap/Modal";
import { PiPhoneCallFill } from "react-icons/pi";
import axios from "axios";
export const HeaderRSA = () => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  //GetDirections
  const [show1, setShow1] = useState(false);
  const handleClose1 = () => setShow1(false);
  const handleShow1 = () => setShow1(true);

  //Next
  const [show2, setShow2] = useState(false);
  const handleClose2 = () => setShow2(false);
  const handleShow2 = () => setShow2(true);

  //Allow
  const [show3, setShow3] = useState(false);
  const handleClose3 = () => setShow3(false);
  const handleShow3 = () => setShow3(true);

  //CurrentLocation
  const [show4, setShow4] = useState(false);
  const handleClose4 = () => setShow4(false);
  const handleShow4 = () => setShow4(true);
  const [banner, setbanner] = useState([]);
  const getbanner = async () => {
    let res = await axios.get("https://bookingoda.com/api/admin/getallrsabanner");
    if ((res.status = 200)) {
      ////console.log(res);
      setbanner(res.data?.banner);
    }
  };
  useEffect(() => {
    
    getbanner();
  }, []);
  return (
    <div>
      <div className="">
      <Carousel style={{position:'relative'}}>
      
      {banner.map((data) => {
  return (
    <Carousel.Item>
      <img
        src={"https://bookingoda.com/image/" + data.banner}
        alt=""
        style={{ width: "100%", height: "400px" }}
      />
    </Carousel.Item>
  )
})} </Carousel>
      </div>
{/* 
      <div style={{ textAlign: "center" }}>
        <button
          className="renew-btn fw-semibold"
          style={{ padding: "4px 9px", height: "50px" }}
          onClick={handleShow1}
        >
         Road Side Assisstance<FaAngleRight />
        </button>
      
      </div> */}
      <br />

      <div className="container  mb-5">
        <div className="row  ">
          <div className="col-md-12 text-center">
            <h2>How It Works</h2>
          </div>
        </div>
        <div className="row d-flex justify-content-center gap-4 mt-3">
          <div className="col-md-2">
            <Card style={{ width: "100%" }}>
              <Card.Img
                variant="top"
                src="/img/service.jpg"
                style={{ width: "100%" }}
              />
              <Card.Body>
                <Card.Title>Book Service</Card.Title>
                <Card.Text>Search and book a service on our site.</Card.Text>
                {/* <Button variant="primary">Go somewhere</Button> */}
              </Card.Body>
            </Card>
          </div>
          <div className="col-2 md-2">
            <Card style={{ width: "100%" }}>
              <Card.Img
                variant="top"
                src="/img/rsa.jpg"
                style={{ width: "100%", height: "180px" }}
              />
              <Card.Body>
                <Card.Title>Get RSA Free</Card.Title>
                <Card.Text>Search and book a service on our site.</Card.Text>
                {/* <Button variant="primary">Go somewhere</Button> */}
              </Card.Body>
            </Card>
          </div>
          <div className="col-md-2">
            <Card style={{ width: "100%" }}>
              <Card.Img
                variant="top"
                src="/img/direction.jpg"
                style={{ width: "100%" }}
              />
              <Card.Body>
                <Card.Title>Direction</Card.Title>
                <Card.Text>
                  Search and book a service on our site.
                  {/* Search and book a service on our site. */}
                </Card.Text>
                {/* <Button variant="primary">Go somewhere</Button> */}
              </Card.Body>
            </Card>
          </div>
          <div className="col-md-2">
            <Card style={{ width: "100%" }}>
              <Card.Img
                variant="top"
                src="/img/explore.jpg"
                style={{ width: "100%", height: "180px" }}
              />
              <Card.Body>
                <Card.Title>Explore Service</Card.Title>
                <Card.Text>Search and book a service on our site.</Card.Text>
                {/* <Button variant="primary">Go somewhere</Button> */}
              </Card.Body>
            </Card>
          </div>
          <div className="col-md-2">
            <Card style={{ width: "100%" }}>
              <Card.Img
                variant="top"
                src="/img/assisstance.jpg"
                style={{ width: "100%", height: "180px" }}
              />
              <Card.Body>
                <Card.Title>Ask For Assisstance</Card.Title>
                <Card.Text>Search and book</Card.Text>
                {/* <Button variant="primary">Go somewhere</Button> */}
              </Card.Body>
            </Card>
          </div>
        </div>
      </div>

      <Container fluid>
        {/* <p className="text-center header-text">
          Whether it's a flat tyre, a breakdown or even if you run out of fuel,
          our experts will come to your location and get your motorcycle back on
          the road or ensure it reaches the nearest Authorised Service Centre.
        </p>
        <img
          src="./img/rsa-strip-banner.jpg"
          alt=""
          style={{ width: "-webkit-fill-available" }}
        /> */}
        {/* <p className="more-info mb-3">For more information,</p> */}
        <div className="d-flex justify-content-center mb-3 gap-3">
          <h2>OUR SERVICES - Get Free Roadside Assisstance</h2>
        </div>

        <div className="d-flex justify-content-center gap-3 mb-5">
          <button
            className=""
            style={{
              // backgroundColor: "#698541",
              backgroundColor:"#da291c",
              border: "1px solid #da291c",
              borderRadius: "10px",
              color: "white",
              padding: "10px",
            }}
            onClick={handleShow}
          >
            Ask For Assistance<FaAngleRight style={{display:'inline-block'}}/>
          </button>
          <a href="\location">
            <button
              className=""
              style={{
                // backgroundColor: "#0B5ED7",
                backgroundColor:"#da291c",

                border: "1px solid #da291c",
                borderRadius: "10px",
                color: "white",
                padding: "10px",
              }}
            >
              Click here
            </button>
          </a>
          {/* <button className="renew-btn fw-semibold">
            Call Toll-Free No <FaAngleRight />
          </button> */}
        </div>
      </Container>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Assistance</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3886.4279757072804!2d77.52563127412199!3d13.072040712670146!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae22d6f48a713d%3A0xdec0b3fe51f1f35e!2sRestolax%20Advance%20Health%20Care%20Equipments!5e0!3m2!1sen!2sin!4v1704716611798!5m2!1sen!2sin"
            width="100%"
            height="250"
            allowfullscreen=""
            loading="lazy"
            referrerpolicy="no-referrer-when-downgrade"
          ></iframe>

          <label htmlFor="" className="fw-bold  mt-3">
            Please Select :
          </label>
          <Form.Select
            aria-label="Default select example"
            className="service-input"
          >
            <option>Select</option>
            <option value="1">Are you on road</option>
            <option value="2">Are you on highway</option>
          </Form.Select>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <a href="/Service">
            <Button variant="primary">Next</Button>
          </a>
        </Modal.Footer>
      </Modal>

      {/* GetDirection  */}
      <Modal show={show1} onHide={handleClose1}>
        <Modal.Header closeButton>
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3" controlId="formGroupEmail">
              <Form.Label>Enter Vehicle Type</Form.Label>
              <Form.Select aria-label="Default select example">
                <option>Open this select menu</option>
                <option value="1">Car</option>
                <option value="2">Two-Wheeler</option>
                <option value="3">Bus</option>
              </Form.Select>
            </Form.Group>
            <Form.Group className="mb-3" controlId="formGroupEmail">
              <Form.Label>Vehicle Number</Form.Label>
              <Form.Control type="text" placeholder="Enter Vehicle Number" />
            </Form.Group>

            <Form>
              {["checkbox"].map((type) => (
                <div key={`inline-${type}`} className="mb-3">
                  <Form.Check
                    inline
                    label="I agree to the terms and conditions."
                    name="group1"
                    type={type}
                    id={`inline-${type}-1`}
                  />
                </div>
              ))}
            </Form>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant=""
            style={{ backgroundColor: "#DA291C", color: "white" }}
            onClick={handleShow2}
          >
            Next
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Next  */}
      <Modal show={show2} onHide={handleClose2}>
        <Modal.Header closeButton>
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h6>Are you on road?</h6>
          <Form>
            {["checkbox"].map((type) => (
              <div key={`inline-${type}`} className="mb-3">
                <Form.Check
                  inline
                  label="Yes"
                  name="group1"
                  type={type}
                  id={`inline-${type}-1`}
                />
                <Form.Check
                  inline
                  label="No"
                  name="group1"
                  type={type}
                  id={`inline-${type}-2`}
                />
              </div>
            ))}
          </Form>

          <h6>Are you on Highway?</h6>
          <Form>
            {["checkbox"].map((type) => (
              <div key={`inline-${type}`} className="mb-3">
                <Form.Check
                  inline
                  label="Yes"
                  name="group1"
                  type={type}
                  id={`inline-${type}-1`}
                />
                <Form.Check
                  inline
                  label="No"
                  name="group1"
                  type={type}
                  id={`inline-${type}-2`}
                />
              </div>
            ))}
          </Form>

          <h6>In which direction are you riding?</h6>
          <div
            className="pre-0"
            style={{ display: "flex", alignItems: "center" }}
          >
            {/* <label>Search Address</label> */}
            <input
              style={{ width: "100%" }}
              class="form-controls"
              required=""
              placeholder="Enter Location"
              name="name"
              type="text"
            ></input>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant=""
            style={{ backgroundColor: "#DA291C", color: "white" }}
            onClick={handleShow3}
          >
            Next
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Allow  */}
      <Modal show={show3} onHide={handleClose3}>
        <Modal.Header closeButton>
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h6>Allow your current location</h6>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose3}>
            No
          </Button>
          <a href="\location">
            <Button
              variant=""
              style={{ backgroundColor: "#DA291C", color: "white" }}
              onClick={handleShow4}
            >
              Yes
            </Button>
          </a>
        </Modal.Footer>
      </Modal>

      {/* CurrentLocation  */}
      {/* <Modal show={show4} onHide={handleClose4}>
        <Modal.Header closeButton>
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body>

          <div className="container">
            <div className="row">
              <div className="col-md-6">

              <Form.Group className="mb-3" controlId="formGroupEmail">
              <Form.Label>Current Location: </Form.Label>
              <Form.Control type="text" placeholder="" />
            </Form.Group>

            <Form.Group className="mb-3" controlId="formGroupEmail">
              <Form.Label>Destination: </Form.Label>
              <Form.Control type="text" placeholder="" />
            </Form.Group>

            <button className="renew-btn fw-semibold" onClick={handleShow}>
           Directions
          </button>
             
              </div>

              <div
                className="col-md-6"
                style={{ paddingLeft: "unset", paddingRight: "unset" }}
              >
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d31090.264129437837!2d77.53480077779398!3d13.081241386031335!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae229f7a8debe1%3A0x4d80f1d259c7e7ac!2sVidyaranyapura%2C%20Bengaluru%2C%20Karnataka!5e0!3m2!1sen!2sin!4v1702035015935!5m2!1sen!2sin"
                  style={{
                    width: "100%",
                    // marginTop: "2rem",
                    height: "450px",
                  }}
                  allowfullscreen=""
                  loading="lazy"
                  referrerpolicy="no-referrer-when-downgrade"
                ></iframe>
              </div>

             


            </div>
          </div>
       
        </Modal.Body>
        <Modal.Footer>
            <Button variant="primary" onClick={handleShow4}>
              Yes
            </Button>         
        </Modal.Footer>
      </Modal> */}
    </div>
  );
};
