import React from "react";
import "./property.css";
import Apartment from "../../assets/apartments.png";
import Apartments from "../../assets/buildings.png";

import Homestay from "../../assets/homestay.png";
import Hotel from "../../assets/hotel.png";
import VendorHeader from "./Vendorheader";

const Propertylist = () => {
  return (
    <div
      data-capla-component="b-registration-joinapp15min-fe/"
      data-capla-namespace="b-registration-joinapp15min-feaYGUODfA"
    >
      <div id="app-container">
        <div class="error-popup-container"></div>
        <div class="application">
        <VendorHeader />
          <div class="error-popup-container"></div>

          <div className="page-container">
            <div className="screens screens-animation-direction-forward">
              <div
                className="height-100 screen-change-enter-done"
                id="automation_id_screen_container_Property"
              >
                <div className="ms-container ms-container--full-width">
                  <div className="form-container">
                    <div className="f2cf178bcd screen-main-header d991e1216a">
                      <div className="f6431b446c e6208ee469 d0caee4251 screen-main-header__title">
                        From the list below, which property category is most
                        similar to your place?
                      </div>
                    </div>
                    <div className="ffb9c3d6a3 wide-container spacing--4x">
                      <div
                        data-testid="PropertyTypeCardGroup-container"
                        className="ffb9c3d6a3 property-type-card-group"
                      >
                        <div className="aca0ade214 ebac6e22e9 c2931f4182 c27e5d305d db150fece4">
                        
                            
                          <div className="property-type-card-grid b817090550  dc18e1e302 d768eb1b65">
                            <div
                              className="property-type-card"
                              id="automation_id_property_type_216"
                              role="checkbox"
                              aria-checked="false"
                              tabIndex={0}
                              data-testid="PropertyTypeCard-container"
                            >
                              <div
                                className="c82435a4b8 a178069f51 a6ae3c2b40 a18aeea94d d794b7a0f7 f53e278e95 card-property-type"
                                style={{ padding: 4 }}
                              >
                                <div
                                  className="c82435a4b8"
                                  style={{ padding: 0 }}
                                >
                                  <div
                                    data-testid="PropertyTypeCard-name"
                                    className="f2cf178bcd spacing--4x"
                                  >
                                    <div className="e1eebb6a1e e6208ee469 d0caee4251">
                                      Guest house
                                    </div>
                                  </div>
                                  <div
                                    data-testid="PropertyTypeCard-description"
                                    className="a53cbfa6de"
                                  >
                                    Private home with separate living facilities
                                    for host and guest
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="property-type-card-grid b817090550  dc18e1e302 d768eb1b65">
                            <div
                              className="property-type-card"
                              id="automation_id_property_type_208"
                              role="checkbox"
                              aria-checked="false"
                              tabIndex={0}
                              data-testid="PropertyTypeCard-container"
                            >
                              <div
                                className="c82435a4b8 a178069f51 a6ae3c2b40 a18aeea94d d794b7a0f7 f53e278e95 card-property-type"
                                style={{ padding: 4 }}
                              >
                                <div
                                  className="c82435a4b8"
                                  style={{ padding: 0 }}
                                >
                                  <div
                                    data-testid="PropertyTypeCard-name"
                                    className="f2cf178bcd spacing--4x"
                                  >
                                    <div className="e1eebb6a1e e6208ee469 d0caee4251">
                                      Private house
                                    </div>
                                  </div>
                                  <div
                                    data-testid="PropertyTypeCard-description"
                                    className="a53cbfa6de"
                                  >
                                    Private home offering overnight stays and
                                    breakfast
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="property-type-card-grid b817090550  dc18e1e302 d768eb1b65">
                            <div
                              className="property-type-card"
                              id="automation_id_property_type_222"
                              role="checkbox"
                              aria-checked="false"
                              tabIndex={0}
                              data-testid="PropertyTypeCard-container"
                            >
                              <div
                                className="c82435a4b8 a178069f51 a6ae3c2b40 a18aeea94d d794b7a0f7 f53e278e95 card-property-type"
                                style={{ padding: 4 }}
                              >
                                <div
                                  className="c82435a4b8"
                                  style={{ padding: 0 }}
                                >
                                  <div
                                    data-testid="PropertyTypeCard-name"
                                    className="f2cf178bcd spacing--4x"
                                  >
                                    <div className="e1eebb6a1e e6208ee469 d0caee4251">
                                      Homestay
                                    </div>
                                  </div>
                                  <div
                                    data-testid="PropertyTypeCard-description"
                                    className="a53cbfa6de"
                                  >
                                    A shared home where the guest has a private
                                    room and the host lives and is on site. Some
                                    facilities are shared between hosts and
                                    guests.
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="property-type-card-grid b817090550  dc18e1e302 d768eb1b65">
                            <div
                              className="property-type-card"
                              id="automation_id_property_type_203"
                              role="checkbox"
                              aria-checked="false"
                              tabIndex={0}
                              data-testid="PropertyTypeCard-container"
                            >
                              <div
                                className="c82435a4b8 a178069f51 a6ae3c2b40 a18aeea94d d794b7a0f7 f53e278e95 card-property-type"
                                style={{ padding: 4 }}
                              >
                                <div
                                  className="c82435a4b8"
                                  style={{ padding: 0 }}
                                >
                                  <div
                                    data-testid="PropertyTypeCard-name"
                                    className="f2cf178bcd spacing--4x"
                                  >
                                    <div className="e1eebb6a1e e6208ee469 d0caee4251">
                                      Hotels
                                    </div>
                                  </div>
                                  <div
                                    data-testid="PropertyTypeCard-description"
                                    className="a53cbfa6de"
                                  >
                                   Independent room with facilities
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="property-type-card-grid b817090550  dc18e1e302 d768eb1b65">
                            <div
                              className="property-type-card"
                              id="automation_id_property_type_219"
                              role="checkbox"
                              aria-checked="false"
                              tabIndex={0}
                              data-testid="PropertyTypeCard-container"
                            >
                              <div
                                className="c82435a4b8 a178069f51 a6ae3c2b40 a18aeea94d d794b7a0f7 f53e278e95 card-property-type"
                                style={{ padding: 4 }}
                              >
                                <div
                                  className="c82435a4b8"
                                  style={{ padding: 0 }}
                                >
                                  <div
                                    data-testid="PropertyTypeCard-name"
                                    className="f2cf178bcd spacing--4x"
                                  >
                                    <div className="e1eebb6a1e e6208ee469 d0caee4251">
                                      Farm house stay
                                    </div>
                                  </div>
                                  <div
                                    data-testid="PropertyTypeCard-description"
                                    className="a53cbfa6de"
                                  >
                                   Private farm with simple accommodation
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        
                       
                         
                         
                        </div>
                      </div>
                    </div>
                   
                  </div>
                  <div className="form-cta">
                    <div>
                      <div className="form-buttons-container">
                           <div className="form-buttons-container--secondary" onClick={()=>window.history.back()}>
                          <button
                            aria-label="Back to previous step"
                            data-testid="FormButtonSecondary"
                            type="button"
                            className="a83ed08757 c21c56c305 bf0537ecb5 d691166b09 f671049264 d2529514af af7297d90d"
                          >
                            <span className="eedba9e88a">
                              <span
                                className="fcd9eec8fb c2cc050fb8"
                                aria-hidden="true"
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 24 24"
                                  data-rtl-flip="true"
                                >
                                  <path d="M15.0871 19.2361C14.9677 19.2367 14.8495 19.2134 14.7392 19.1676C14.629 19.1219 14.5289 19.0546 14.4451 18.9696L8.38799 12.9125C8.26332 12.7891 8.16475 12.6418 8.09815 12.4795C8.03156 12.3172 7.99829 12.1431 8.00034 11.9676C7.99246 11.6171 8.12303 11.2776 8.36376 11.0227L14.4208 4.96566C14.5912 4.79552 14.8221 4.69995 15.0629 4.69995C15.3037 4.69995 15.5346 4.79552 15.7049 4.96566C15.7907 5.04918 15.8588 5.14902 15.9053 5.25929C15.9518 5.36957 15.9758 5.48803 15.9758 5.60771C15.9758 5.72739 15.9518 5.84586 15.9053 5.95613C15.8588 6.0664 15.7907 6.16624 15.7049 6.24976L10.0113 11.9676L15.7292 17.6855C15.8149 17.769 15.883 17.8689 15.9295 17.9792C15.976 18.0894 16 18.2079 16 18.3276C16 18.4473 15.976 18.5657 15.9295 18.676C15.883 18.7863 15.8149 18.8861 15.7292 18.9696C15.6457 19.0551 15.5458 19.1228 15.4354 19.1686C15.3251 19.2144 15.2066 19.2374 15.0871 19.2361Z" />
                                </svg>
                              </span>
                            </span>
                          </button>
                        </div>
                        <div className="form-buttons-container--primary" onClick={()=>window.location.assign('/privatetype')}>
                          <button
                            aria-label="Continue"
                            data-testid="FormButtonPrimary-enabled"
                            type="button"
                            // disabled
                            className="a83ed08757 c21c56c305 a4c1805887 f671049264 d2529514af c082d89982"
                          >
                            <span className="e4adce92df">Continue</span>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div
            id="automation_id_flow_Segmentation_Flow"
            style={{ display: "none" }}
          ></div>
          <div></div>
        </div>
      </div>
      <img
        alt=""
        height="1"
        width="1"
        style={{ display: "none" }}
        src="https://booking.com/track_join_app_landing?progress_status=2"
      />
    </div>
  );
};

export default Propertylist;
