import React from "react";
import "./property.css";
import Apartment from "../../assets/apartments.png";
import Apartments from "../../assets/buildings.png";

import Homestay from "../../assets/homestay.png";
import Hotel from "../../assets/hotel.png";
import VendorHeader from "./Vendorheader";

const Cancellation = () => {
  return (
    <div
      data-capla-component="b-registration-joinapp15min-fe/"
      data-capla-namespace="b-registration-joinapp15min-feaYGUODfA"
    >
      <div id="app-container">
        <div class="error-popup-container"></div>
        <div class="application">
        <VendorHeader />
          <div class="error-popup-container"></div>

          <div className="page-container">
            <div className="screens screens-animation-direction-forward">
              <div
                className="height-100"
                id="automation_id_screen_container_Policies"
              >
                <div className="ms-container">
                  <div className="form-container">
                    <div className="f2cf178bcd screen-main-header d991e1216a">
                      <div className="f6431b446c e6208ee469 d0caee4251 screen-main-header__title">
                        Cancellation policies
                      </div>
                    </div>
                    <div className="ffb9c3d6a31 wide-container min-height">
                      <div className="aca0ade214 ebac6e22e9 f66f916626 c2931f4182 c27e5d305d db150fece4">
                        <div className="b817090550 b736e9e3f4">
                          <div className="form-group">
                            <p
                              className="policies__label"
                              data-testid="FreeCancellation-body"
                            >
                              How many days before their arrival can your guests{" "}
                              <b>cancel their booking for free</b>?
                            </p>
                            <div className="f3942475b4 b7efa77ac6 bd4784f2d6 d6b5cc954e">
                              <div
                                data-testid="FreeCancellation-SegmentedControl"
                                className="spacing--6x policies__free-cancellation-segmented-control--desktop"
                              >
                                <span className="cdadcf1b05">
                                  <input
                                    id=":r24:-1"
                                    className="b7cdcf5f57"
                                    type="radio"
                                    name="days"
                                    defaultValue={1}
                                    defaultChecked
                                  />
                                  <label
                                    htmlFor=":r24:-1"
                                    className="a53cbfa6de a454023a93"
                                  >
                                    {/* <span className="abf093bdfe c147fc6dd1 policies__free-cancellation-recommended d18b4a6026">
                                      <span className="b30f8eb2d6">
                                        Recommended
                                      </span>
                                    </span> */}
                                    1 day
                                  </label>
                                  <input
                                    id=":r24:-5"
                                    className="b7cdcf5f57"
                                    type="radio"
                                    name="days"
                                    defaultValue={5}
                                  />
                                  <label
                                    htmlFor=":r24:-5"
                                    className="a53cbfa6de a454023a93"
                                  >
                                    5 days
                                  </label>
                                  <input
                                    id=":r24:-14"
                                    className="b7cdcf5f57"
                                    type="radio"
                                    name="days"
                                    defaultValue={14}
                                  />
                                  <label
                                    htmlFor=":r24:-14"
                                    className="a53cbfa6de a454023a93"
                                  >
                                    14 days
                                  </label>
                                  <input
                                    id=":r24:-30"
                                    className="b7cdcf5f57"
                                    type="radio"
                                    name="days"
                                    defaultValue={30}
                                  />
                                  <label
                                    htmlFor=":r24:-30"
                                    className="a53cbfa6de a454023a93"
                                  >
                                    30 days
                                  </label>
                                </span>
                              </div>
                            </div>
                            <div className="a4cbc7b9f5 a312d3fe67 b5156fd448 b88ad0398a">
                              <div
                                data-testid="FreeCancellation-select"
                                className="a53cbfa6de ac9267e216 spacing--6x"
                              >
                                <div className="cabad3c686 aff44befa2">
                                  <select
                                    className="ebf4591c8e"
                                    name="days"
                                    id=":r25:"
                                  >
                                    <option value={1} data-key={1}>
                                      Recommended1 day
                                    </option>
                                    <option value={5} data-key={5}>
                                      5 days
                                    </option>
                                    <option value={14} data-key={14}>
                                      14 days
                                    </option>
                                    <option value={30} data-key={30}>
                                      30 days
                                    </option>
                                  </select>
                                  <span className="ad2c299542">
                                    <span
                                      className="fcd9eec8fb cab1c196b5 c2cc050fb8 f64eb5d122"
                                      aria-hidden="true"
                                    >
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 24 24"
                                      >
                                        <path d="M19.2681 8.91288C19.2687 9.03226 19.2454 9.15054 19.1996 9.26079C19.1539 9.37105 19.0865 9.47105 19.0016 9.55493L12.9445 15.612C12.8211 15.7367 12.6738 15.8353 12.5115 15.9018C12.3491 15.9684 12.1751 16.0017 11.9996 15.9997C11.6491 16.0075 11.3096 15.877 11.0547 15.6362L4.99764 9.57916C4.8275 9.40881 4.73193 9.17788 4.73193 8.93711C4.73193 8.69634 4.8275 8.46542 4.99764 8.29506C5.08117 8.20935 5.18101 8.14122 5.29128 8.09471C5.40155 8.04819 5.52002 8.02423 5.6397 8.02423C5.75937 8.02423 5.87784 8.04819 5.98811 8.09471C6.09838 8.14122 6.19822 8.20935 6.28175 8.29506L11.9996 13.9887L17.7175 8.27084C17.801 8.18512 17.9009 8.11699 18.0111 8.07048C18.1214 8.02396 18.2399 8 18.3596 8C18.4792 8 18.5977 8.02396 18.708 8.07048C18.8182 8.11699 18.9181 8.18512 19.0016 8.27084C19.0871 8.3543 19.1548 8.45422 19.2006 8.56457C19.2464 8.67492 19.2694 8.79341 19.2681 8.91288Z" />
                                      </svg>
                                    </span>
                                  </span>
                                </div>
                              </div>
                            </div>
                            <div className="aca0ade214 aaf30230d9 c2931f4182 d79e71457a">
                              <span
                                className="fcd9eec8fb bf9a32efa5 b3ef400590"
                                aria-hidden="true"
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 24 24"
                                >
                                  <path d="M14.25 15.75h-.75a.75.75 0 0 1-.75-.75v-3.75a1.5 1.5 0 0 0-1.5-1.5h-.75a.75.75 0 0 0 0 1.5h.75V15a2.25 2.25 0 0 0 2.25 2.25h.75a.75.75 0 0 0 0-1.5zM11.625 6a1.125 1.125 0 1 0 0 2.25 1.125 1.125 0 0 0 0-2.25.75.75 0 0 0 0 1.5.375.375 0 1 1 0-.75.375.375 0 0 1 0 .75.75.75 0 0 0 0-1.5zM22.5 12c0 5.799-4.701 10.5-10.5 10.5S1.5 17.799 1.5 12 6.201 1.5 12 1.5 22.5 6.201 22.5 12zm1.5 0c0-6.627-5.373-12-12-12S0 5.373 0 12s5.373 12 12 12 12-5.373 12-12z" />
                                </svg>
                              </span>
                              <div className="aaee4e7cd3 e7a57abb1e">
                                Guests love flexibility – free cancellation
                                rates are generally the most booked rates on our
                                site. Get your first booking sooner by allowing
                                guests to cancel up to five days before
                                check-in.
                              </div>
                            </div>
                            <div className="policies__spacing" />
                            <div>
                              <label
                                className="policies__label spacing--4x"
                                data-testid="GracePeriod-title"
                                htmlFor="automation_id_switch_grace_period"
                              >
                                <div className="a3332d346a">
                                  Protection against accidental bookings
                                </div>
                              </label>
                              <div data-testid="GracePeriod-InputSwitch">
                                <div className="a53cbfa6de d87fd4472a">
                                  <input
                                    type="checkbox"
                                    name="switch"
                                    id="automation_id_switch_grace_period"
                                    className="f43c0d9153"
                                    aria-checked="true"
                                    role="switch"
                                    defaultChecked
                                  />
                                  <label
                                    htmlFor="automation_id_switch_grace_period"
                                    className="dbe832aef0"
                                    aria-live="polite"
                                  >
                                    <span className="ade77cee67" />
                                    <span className="c640a7fa88">On</span>
                                    <span className="f9bbc6d198" />
                                  </label>
                                </div>
                              </div>
                              <div
                                className="grace-period__caption"
                                data-testid="GracePeriod-body"
                              >
                                <div className="abf093bdfe f45d8e4c32">
                                  To avoid you having to spend time handling
                                  accidental bookings, we automatically waive
                                  cancellation fees for guests that cancel
                                  within the first 24 hours of making a booking.
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="page-tooltip__wrap spacing--4x">
                            <section
                              aria-labelledby=":r26:"
                              className="c82435a4b8 a178069f51 a6ae3c2b40 a18aeea94d d794b7a0f7 f53e278e95"
                              style={{ padding: 4 }}
                            >
                              <div className="aca0ade214 ebac6e22e9 c2931f4182 d79e71457a">
                                <span className="d963552ea0">
                                  <span
                                    className="fcd9eec8fb c2cc050fb8"
                                    aria-hidden="true"
                                  >
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      viewBox="0 0 24 24"
                                    >
                                      <path d="M12.75 2.251v-1.5a.75.75 0 0 0-1.5 0v1.5a.75.75 0 0 0 1.5 0zm6.144 3.167l1.061-1.06a.75.75 0 0 0-1.06-1.061l-1.061 1.06a.75.75 0 0 0 1.06 1.061zM21 12.001h1.5a.75.75 0 0 0 0-1.5H21a.75.75 0 0 0 0 1.5zm-3.166 6.144l1.06 1.061a.75.75 0 1 0 1.061-1.06l-1.06-1.061a.75.75 0 1 0-1.061 1.06zM6.166 4.358l-1.06-1.061a.75.75 0 0 0-1.061 1.06l1.06 1.061a.75.75 0 0 0 1.061-1.06zM3 10.5H1.5a.75.75 0 0 0 0 1.5H3a.75.75 0 0 0 0-1.5zm2.106 6.584l-1.061 1.06a.75.75 0 1 0 1.06 1.061l1.061-1.06a.75.75 0 1 0-1.06-1.061zm3.144-.636v2.3a3.75 3.75 0 1 0 7.5 0v-2.3a.75.75 0 0 0-1.5 0v2.3a2.25 2.25 0 0 1-4.5 0v-2.3a.75.75 0 0 0-1.5 0zm9-5.198a5.25 5.25 0 1 1-10.5 0 5.25 5.25 0 0 1 10.5 0zm1.5 0a6.75 6.75 0 1 0-13.5 0 6.75 6.75 0 0 0 13.5 0zm-7.5 10.5v1.5a.75.75 0 0 0 1.5 0v-1.5a.75.75 0 0 0-1.5 0z" />
                                    </svg>
                                  </span>
                                </span>
                                <div className="aaee4e7cd3 e7a57abb1e">
                                  <div className="aca0ade214 aaf30230d9 cd2e7d62b0">
                                    <div className="ffd93a9ecb">
                                      <h3
                                        id=":r26:"
                                        className="e1eebb6a1e e0a7dcceb5"
                                      >
                                        What policy should I choose?
                                      </h3>
                                      {/* <div className="abcc616ec7 cc1b961f14 c180176d40 f11eccb5e8">
                                        <button
                                          aria-label="Close banner"
                                          type="button"
                                          className="a83ed08757 c21c56c305 f38b6daa18 d691166b09 ab98298258 deab83296e f4552b6561"
                                        >
                                          <span className="eedba9e88a">
                                            <span
                                              className="fcd9eec8fb bf9a32efa5"
                                              aria-hidden="true"
                                            >
                                              <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                viewBox="0 0 24 24"
                                              >
                                                <path d="M13.31 12l6.89-6.89a.93.93 0 1 0-1.31-1.31L12 10.69 5.11 3.8A.93.93 0 0 0 3.8 5.11L10.69 12 3.8 18.89a.93.93 0 0 0 1.31 1.31L12 13.31l6.89 6.89a.93.93 0 1 0 1.31-1.31z" />
                                              </svg>
                                            </span>
                                          </span>
                                        </button>
                                      </div> */}
                                    </div>
                                    <div className="a53cbfa6de">
                                      Any policy you select now can be easily
                                      updated later.
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </section>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="form-cta">
                    <div>
                      <div className="form-buttons-container">
                        <div className="form-buttons-container--secondary" onClick={()=>window.history.back()}>
                          <button
                            aria-label="Back to previous step"
                            data-testid="FormButtonSecondary"
                            type="button"
                            className="a83ed08757 c21c56c305 bf0537ecb5 d691166b09 f671049264 d2529514af af7297d90d"
                          >
                            <span className="eedba9e88a">
                              <span
                                className="fcd9eec8fb c2cc050fb8"
                                aria-hidden="true"
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 24 24"
                                  data-rtl-flip="true"
                                >
                                  <path d="M15.0871 19.2361C14.9677 19.2367 14.8495 19.2134 14.7392 19.1676C14.629 19.1219 14.5289 19.0546 14.4451 18.9696L8.38799 12.9125C8.26332 12.7891 8.16475 12.6418 8.09815 12.4795C8.03156 12.3172 7.99829 12.1431 8.00034 11.9676C7.99246 11.6171 8.12303 11.2776 8.36376 11.0227L14.4208 4.96566C14.5912 4.79552 14.8221 4.69995 15.0629 4.69995C15.3037 4.69995 15.5346 4.79552 15.7049 4.96566C15.7907 5.04918 15.8588 5.14902 15.9053 5.25929C15.9518 5.36957 15.9758 5.48803 15.9758 5.60771C15.9758 5.72739 15.9518 5.84586 15.9053 5.95613C15.8588 6.0664 15.7907 6.16624 15.7049 6.24976L10.0113 11.9676L15.7292 17.6855C15.8149 17.769 15.883 17.8689 15.9295 17.9792C15.976 18.0894 16 18.2079 16 18.3276C16 18.4473 15.976 18.5657 15.9295 18.676C15.883 18.7863 15.8149 18.8861 15.7292 18.9696C15.6457 19.0551 15.5458 19.1228 15.4354 19.1686C15.3251 19.2144 15.2066 19.2374 15.0871 19.2361Z" />
                                </svg>
                              </span>
                            </span>
                          </button>
                        </div>
                        <div className="form-buttons-container--primary" onClick={()=>window.location.assign("/propertyrate")}>
                          <button
                            aria-label="Continue"
                            data-testid="FormButtonPrimary-enabled"
                            type="button"
                            className="a83ed08757 c21c56c305 a4c1805887 f671049264 d2529514af c082d89982"
                          >
                            <span className="e4adce92df">Continue</span>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Cancellation;
