
import React, { useState } from "react";
import "./property.css";
import Apartment from "../../assets/apartments.png";
import Apartments from "../../assets/buildings.png";
import { Map, GoogleApiWrapper, Marker } from "google-maps-react";
import Homestay from "../../assets/homestay.png";
import Hotel from "../../assets/hotel.png";
import VendorHeader from "./Vendorheader";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect } from "react";

const MapHotel = (props) => {
  const address1 = JSON.parse(sessionStorage.getItem("address"));

  const [address, setAddress] = useState(
    address1[0] +
      " " +
      address1[1]  +
      " " +
      address1[2]  +
      " " +
      address1[3]  +
      " " +
      address1[4] 
  );

  const [latitude, setLatitude] = useState();
  const [longitude, setLongitude] = useState();

  const lat = 13.078760;
  const log = 77.533240;
  ////console.log(typeof lat, log);
  const apiKey = "AIzaSyCom4BCfTVsw3o22AYg69Y7EovmLfV_Alw";
  useEffect(() => {
    getCoordinates();
  }, []);
  const history = useNavigate();
  const HandleClick = () => {
    history("/hotelname");
  };
  const getCoordinates = () => {
    const apiUrl = `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(
      address
    )}&key=${apiKey}`;

    fetch(apiUrl)
      .then((response) => response.json())
      .then((data) => {
        if (data.results.length > 0) {
          const location = data.results[0].geometry.location;
          setLatitude(location.lat);
          setLongitude(location.lng);
        } else {
          ////console.log("No results found for the given address");
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  return (
    <div
      data-capla-component="b-registration-joinapp15min-fe/"
      data-capla-namespace="b-registration-joinapp15min-feaYGUODfA"
    >
      <div id="app-container">
        <div class="error-popup-container"></div>
        <div class="application">
          <VendorHeader />
          <div class="error-popup-container"></div>
          <>
            {/* Hello world */}
            <div className="page-container">
              <div className="screens screens-animation-direction-forward">
                <div
                  className="height-100"
                  id="automation_id_screen_container_Map"
                >
                  <div className="ms-container">
                    <div className="form-container form-container--map">
                      <div className="f2cf178bcd screen-main-header screen-main-header-with-error-summary d991e1216a">
                        <div className="f6431b446c e6208ee469 d0caee4251 screen-main-header__title">
                          Pin the location of your property
                        </div>
                      </div>
                      <div className="map-mobile-title">
                        <div className="a53cbfa6de">
                          This is the location we'll show to guests on our site.
                          Drag the map so the pin matches the exact location of
                          your place.
                        </div>
                      </div>
                      <div className="wide-container map-container">
                        <div className="a53cbfa6de map-title">
                          This is the location we'll show to guests on our site.
                          Drag the map so the pin matches the exact location of
                          your place.{" "}
                        </div>
                        <div className="map-with-pin-container">
                          <div
                            id="map"
                            style={{ position: "relative", overflow: "hidden" }}
                          >
                            <div
                              style={{
                                height: "100%",
                                width: "100%",
                                position: "absolute",
                                top: 0,
                                left: 0,
                                backgroundColor: "rgb(229, 227, 223)",
                              }}
                            >
                              <Map
                                google={props.google}
                                zoom={14}
                                initialCenter={{ lat: lat, lng: log }} // Default center for the map
                              >
                                <Marker position={{ lat: lat, lng: log }} />
                              </Map>
                            </div>
                          </div>
                          <span
                            className="fcd9eec8fb centerMarker bf9a32efa5"
                            aria-hidden="true"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 24 24"
                            >
                              <path d="M12 0a8.009 8.009 0 0 0-8 8c0 3.51 5 12.025 7.148 15.524A1 1 0 0 0 12 24a.99.99 0 0 0 .852-.477C15 20.026 20 11.514 20 8a8.009 8.009 0 0 0-8-8zm0 11.5A3.5 3.5 0 1 1 15.5 8a3.5 3.5 0 0 1-3.5 3.5z" />
                            </svg>
                          </span>
                        </div>
                      </div>
                      <input type="hidden" name="entrance_latitude" />
                      <input type="hidden" name="entrance_longitude" />
                    </div>
                    <div className="form-cta form-cta--fixed">
                      <div>
                        <div className="form-buttons-container">
                          <div
                            className="form-buttons-container--secondary"
                            onClick={() => window.history.back()}
                          >
                            <button
                              aria-label="Back to previous step"
                              data-testid="FormButtonSecondary"
                              type="button"
                              className="a83ed08757 c21c56c305 bf0537ecb5 d691166b09 f671049264 d2529514af af7297d90d"
                            >
                              <span className="eedba9e88a">
                                <span
                                  className="fcd9eec8fb c2cc050fb8"
                                  aria-hidden="true"
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 24 24"
                                    data-rtl-flip="true"
                                  >
                                    <path d="M15.0871 19.2361C14.9677 19.2367 14.8495 19.2134 14.7392 19.1676C14.629 19.1219 14.5289 19.0546 14.4451 18.9696L8.38799 12.9125C8.26332 12.7891 8.16475 12.6418 8.09815 12.4795C8.03156 12.3172 7.99829 12.1431 8.00034 11.9676C7.99246 11.6171 8.12303 11.2776 8.36376 11.0227L14.4208 4.96566C14.5912 4.79552 14.8221 4.69995 15.0629 4.69995C15.3037 4.69995 15.5346 4.79552 15.7049 4.96566C15.7907 5.04918 15.8588 5.14902 15.9053 5.25929C15.9518 5.36957 15.9758 5.48803 15.9758 5.60771C15.9758 5.72739 15.9518 5.84586 15.9053 5.95613C15.8588 6.0664 15.7907 6.16624 15.7049 6.24976L10.0113 11.9676L15.7292 17.6855C15.8149 17.769 15.883 17.8689 15.9295 17.9792C15.976 18.0894 16 18.2079 16 18.3276C16 18.4473 15.976 18.5657 15.9295 18.676C15.883 18.7863 15.8149 18.8861 15.7292 18.9696C15.6457 19.0551 15.5458 19.1228 15.4354 19.1686C15.3251 19.2144 15.2066 19.2374 15.0871 19.2361Z" />
                                  </svg>
                                </span>
                              </span>
                            </button>
                          </div>
                          <div
                            className="form-buttons-container--primary"
                            onClick={HandleClick}
                          >
                            <button
                              aria-label="Continue"
                              data-testid="FormButtonPrimary-enabled"
                              type="button"
                              className="a83ed08757 c21c56c305 a4c1805887 f671049264 d2529514af c082d89982"
                            >
                              <span className="e4adce92df">Continue</span>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>

          <div
            id="automation_id_flow_Segmentation_Flow"
            style={{ display: "none" }}
          ></div>
          <div></div>
        </div>
      </div>
      <img
        alt=""
        height="1"
        width="1"
        style={{ display: "none" }}
        src="https://booking.com/track_join_app_landing?progress_status=2"
      />
    </div>
  );
};

export default GoogleApiWrapper({
  apiKey: "AIzaSyCom4BCfTVsw3o22AYg69Y7EovmLfV_Alw",
})(MapHotel);
