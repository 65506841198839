import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./pages/home/Home";
import List from "./pages/list/List";
import Hotel from "./pages/hotel/Hotel";
import Login from "./pages/login/Login";
import "bootstrap/dist/css/bootstrap.min.css";
import Register from "./pages/register/Register";
import Home1 from "./pages/home/home1";
import HomeArc from "./components/Aritechture/Homearc";
import Abt from "./components/Aritechture/abt";
import Project from "./components/Aritechture/project";
import Service from "./components/Aritechture/service";
import Homedig from "./components/Digital/homedigital";
import { SlCalender } from "react-icons/sl";

import "@fortawesome/fontawesome-svg-core/styles.css";
import CreateAccount from "./components/mailList/createaccount";
import ContactDetails from "./components/mailList/contactdetails";
import CreatePassword from "./components/mailList/createpwd";
import Property from "./components/mailList/property";
import ApartmentType from "./components/mailList/apartmenttype";
import MultiApartmentType from "./components/mailList/multipleapartment";
import MultiApartmentType1 from "./components/mailList/multiapartment1";
import Apartment1 from "./components/mailList/apartment1";
import SourceLink from "./components/mailList/sourcelink";
import Propertyname from "./components/mailList/propertyname";
import Propertylocation from "./components/mailList/propertylocation";
import Map1 from "./components/mailList/map";
import Propertysetup from "./components/mailList/propertysetup";
import Propertyservice from "./components/mailList/propertyservice";
import Propertylanguage from "./components/mailList/propertylanguage";
import Propertyhouserules from "./components/mailList/propertyhouserules";
import Propertyphoto from "./components/mailList/propertyphoto";
import Propertyprice from "./components/mailList/propertyprice";
import Propertyrate from "./components/mailList/propertyrate";
import Cancellation from "./components/mailList/cancellationpolicy";
import Refundable from "./components/mailList/refundable";
import PriceGroup from "./components/mailList/pricegroup";
import Weeklyrate from "./components/mailList/weeklyrate";
import HomeType from "./components/mailList/hometype";
import HomeType1 from "./components/mailList/hometype1";
import MultiHome from "./components/mailList/multiHome";
import Homestaylocation from "./components/mailList/homestaylocation";
import MapHome from "./components/mailList/maphome";
import Homename from "./components/mailList/homwname";
import Homeuse from "./components/mailList/homwuse";
import HomeBreakfast from "./components/mailList/homebreakfast";
import Homelanguage from "./components/mailList/homelanguage";
import Homehouserules from "./components/mailList/homehouserules";
import HotelType from "./components/mailList/hoteltype";
import MultiHotel from "./components/mailList/multiHotel";
import Signin from "./components/mailList/signin";
import Entireproperty from "./components/mailList/entireproperty";
import Propertylist from "./components/mailList/propertylist";
import Entirepropertytype from "./components/mailList/entirepropertytype";
import Entiretype1 from "./components/mailList/entiretype1";
import Propertyedit from "./components/mailList/propertyedit";
import Addroom from "./components/mailList/addroom";
import Bathroom from "./components/mailList/bathroom";
import Amenities from "./components/mailList/amenities";
import Roomname from "./components/mailList/roomname";
import Price from "./components/mailList/price";
import Availablity from "./components/mailList/availablity";
import Payment from "./components/mailList/payment";
import Invoice from "./components/mailList/invoice";

import Completeregistration from "./components/mailList/completereg";

import Hotels1 from "./components/mailList/hotels1";
import Hoteltypess from "./components/mailList/hoteltypess";
import Onehotel from "./components/mailList/onehotel";
import Alternative1 from "./components/mailList/alternative1";
import MultiAlternativeType1 from "./components/mailList/multialternative";
import Forgotpassword from "./admin/pages/Forgotpassword";
import Resetpassword from "./admin/pages/Resetpassword";
import MainLayout from "./admin/components/MainLayout";

import AdminLogin from "./admin/pages/Login";

import "./App.css";
import Customers from "./admin/pages/Customers";
import Dashboard from "./admin/pages/Dashboard";
import Attraction from "./components/Attraction/attraction";

import VendorHeader from "./components/mailList/Vendorheader";
import MyProperty from "./components/mailList/myProperty";
import Bedroom from "./components/mailList/bedroom";
import ApartmentList from "./admin/pages/ApartmentList";
import Homephoto from "./components/mailList/Homephoto";
import Homeprice from "./components/mailList/homeprice";
import HomeAddroom from "./components/mailList/homeaddroom";
import HomeBathroom from "./components/mailList/Homebathroom";
import HomeAmenities from "./components/mailList/Homeamenities";
import HomeRoomname from "./components/mailList/homeroomname";
import Homeedit from "./components/mailList/homeedit";
import HomePayment from "./components/mailList/homepayment";
import HomeInvoice from "./components/mailList/homeinvoice";
import HomeCompleteregistration from "./components/mailList/homecompletereg";
import HomeList from "./admin/pages/home";
import HotelList from "./admin/pages/hotellist";
import AlternativeList from "./admin/pages/alternativelist";
import Hotellocation from "./components/mailList/hotellocation";
import Maphotel from "./components/mailList/maphotel";
import Hotelname from "./components/mailList/hotelname";
import HotelUse from "./components/mailList/hoteluse";
import HotelBreakfast from "./components/mailList/hotelbreakfast";
import Hotelhouserules from "./components/mailList/hotelhouserules";
import Hotellanguage from "./components/mailList/hotellanguage";
import Hotelphoto from "./components/mailList/Hotelphoto";
import Hotelprice from "./components/mailList/hotelprice";
import HotelAddroom from "./components/mailList/hoteladdroom";
import HotelBathroom from "./components/mailList/Hotelbathroom";
import HotelAmenities from "./components/mailList/Hotelamenities";
import HotelRoomname from "./components/mailList/hotelroomname";
import HotelCompleteregistration from "./components/mailList/hotelcompletereg";
import Hoteledit from "./components/mailList/hoteledit";
import HotelInvoice from "./components/mailList/hotelinvoice";
import HotelPayment from "./components/mailList/hotelpayment";
import Alternativelocation from "./components/mailList/alternativelocation";
import Alternativename from "./components/mailList/alternativename";
import Mapalternative from "./components/mailList/mapalternative";
import AlternativeUse from "./components/mailList/alternativeuse";
import AlternativeBreakfast from "./components/mailList/alternativebreakfast";
import Alternativelanguage from "./components/mailList/alternativelanguage";
import Alternativehouserules from "./components/mailList/alternativehouserules";
import Alternativephoto from "./components/mailList/Alternativephoto";
import Alternativeprice from "./components/mailList/alternativeprice";
import AlternativeAddroom from "./components/mailList/alternativeaddroom";
import AlternativeBathroom from "./components/mailList/Alternativebathroom";
import AlternativeAmenities from "./components/mailList/Alternativeamenities";
import Alternativeedit from "./components/mailList/alternativeedit";
import AlternativeRoomname from "./components/mailList/alternativeroomname";
import AlternativePayment from "./components/mailList/alternativepayment";
import AlternativeInvoice from "./components/mailList/alternativeinvoice";
import AlternativeCompleteregistration from "./components/mailList/alternativecompletereg";
import AddCars from "./admin/pages/addcars";
import CarList from "./admin/pages/Carlist";
import AddTourPackage from "./admin/pages/addtourpackages";
import TourList from "./admin/pages/TourList";
import Homerate from "./components/mailList/homerate";
import Homesetup from "./components/mailList/homesetup";
import Hotelsetup from "./components/mailList/hotelsetup";
import Alternativesetup from "./components/mailList/alternativesetup";
import Alternativerate from "./components/mailList/alternativerate";
import Hotelrate from "./components/mailList/hotelerate";
import AddPilgrim from "./components/mailList/addpilgrim";
import MainLayoutVendor from "./vendor/components/MainLayoutVendor";
import AlternativeListVendor from "./vendor/pages/alternativelistVendor";
import DashboardVendor from "./vendor/pages/DashboardVendor";
import ApartmentListVendor from "./vendor/pages/ApartmentListVendor";
import HomeListVendor from "./vendor/pages/homeVendor";
import HotelListVendor from "./vendor/pages/hotellistVendor";
import TourListVendor from "./vendor/pages/TourListVendor";
// import { HomeInternational } from "./components/International/pages/Home";
// import { Book } from "./components/International/pages/Book";
// import { Contact } from "./components/International/pages/Contact";
// import { Locations } from "./components/International/pages/Locations";
// import { Dubai } from "./components/International/pages/locations/Dubai/Dubai";

import AddInternational from "./components/mailList/addInternational";
import InternationalPackages from "./admin/pages/Internationalpackages";
import InternationalPackagesVendor from "./vendor/pages/Internationalpackagesvendor";
import Vendors from "./admin/pages/Vendors";
import BookInternationalPackages from "./admin/pages/BookedInternational";
import BookInternationalPackagesVendor from "./vendor/pages/BookedInternationalvendor";
import { BookFormStay } from "./pages/home/BookFormStay";
import { BookStay } from "./pages/home/Bookstay";
import Bookstay from "./admin/pages/BookedStays";
import BookstayVendor from "./vendor/pages/BookedStaysVendor";
import TripPackageDetails from "./components/Attraction/TripPackageDetails";
// import HomeCar from "./components/CarRental/Pages/Home";
// import Models from "./components/CarRental/Pages/Models";
import PilgrimOrder from "./admin/pages/Pilgrimlocationorderlist";
import PilgrimOrderVendor from "./vendor/pages/Pilgrimlocationorderlistvendor";
// import CarDetails1 from "./components/CarRental/components/CarDetails";
import Bookedcar from "./admin/pages/bookedcars";
import { Profile } from "./components/profile/profile";
import AddBannerStay from "./admin/pages/addbannerstay";
import CartStay from "./components/Stayscart/cartstays";
import Checkoutstay from "./components/Stayscart/checkoutstay";
import NewInvoice from "./components/Stayscart/Invoice";
import InternationalInvoice from "./components/Stayscart/Invoiceinternational";
import PilgrimInvoice from "./components/Stayscart/Invoicepilgrim";
import HomeCar1 from "./components/Cars/homecar";
import Carrentaldetails from "./components/Cars/carrentaldetails";
import Listofcars from "./components/Cars/Listcars";
import { HomeInter } from "./components/inter/interhome";
import HomeInterDetails from "./components/inter/interdetails";
import { HomeInterList } from "./components/inter/interlist";
import GetStarted from "./components/mailList/Getstarted";
import Propertyroomphoto from "./components/mailList/propertyroomphoto";
import Propertyroomprice from "./components/mailList/propertyroomprice";
import Homeroomphoto from "./components/mailList/homeroomphoto";
import Homeroomprice from "./components/mailList/homeroomprice";
import Hotelroomphoto from "./components/mailList/hotelroomphoto";
import Hotelroomprice from "./components/mailList/hotelroomprice";
import Alternativeroomphoto from "./components/mailList/alternativeroomphoto";
import Alternativeroomprice from "./components/mailList/alternativeroomprice";
import { Error } from "./components/Error";
import Navigation from "./components/RSA/Navigation";
import Food from "./components/RSA/Food";
import { FooterRSA } from "./components/RSA/Footer";
import { Contact } from "./components/RSA/Contact";
import { Shop } from "./components/RSA/Shop";
import { ServiceRSA } from "./components/RSA/Service";
import { MotorCycle } from "./components/RSA/MotorCycle";
import { HeaderRSA } from "./components/RSA/Headers";
import { HomeRSA } from "./components/RSA/Home";
import Location from "./components/RSA/Location";
import Services from "./components/RSA/Services";
import "react-multi-carousel/lib/styles.css";
import AddBannerRSA from "./admin/pages/addbannerrsa";
import AddCategory from "./admin/pages/addcategory";
import AddSubCategory from "./admin/pages/addsubcategory";
import AddSliderRSA from "./admin/pages/addsliderrsa";
import AddFAQ from "./admin/pages/addFAQ";
import AddTestimonial from "./admin/pages/addTestimonial";
import Com_Home from "./components/Community/Com_Home";
import About from "./components/Community/About";
import Add_profile from "./components/Community/Add_profile";
import Friend from "./components/Community/Friend";
import Com_Message from "./components/Community/Com_Message";

import AddAdvertise from "./admin/pages/addAdvertise";
import AddNearBy from "./admin/pages/addnearby";
import UnbookedList from "./admin/pages/unbookedlist";
import AddAdvertiseList from "./admin/pages/addAdvertiseList";
import SortedList from "./components/sort";
import { Vacationhome } from "./components/VacationHomes/Vacationhome";
import Vacationdetails from "./components/VacationHomes/vacationdetails";
import Vacationlist from "./components/VacationHomes/vacationlist";
import AddVacation from "./admin/pages/addvacation";
import VacationList from "./admin/pages/VacationList";
import VacationOrder from "./admin/pages/Bookingvacation";
import AddOffers from "./admin/pages/addOffers";

import HoneymoonHome from "./components/Honeymoon/honeymoonhome";
import HoneyMoonList from "./components/Honeymoon/honeymoonlist";
import HoneyMoonDetails from "./components/Honeymoon/honeymoondetails";
import PriorityList from "./admin/pages/PriorityList";
import Offers from "./components/StayOffers";
import Offers1 from "./components/PilOffers";
import Offers2 from "./components/VacOffers";
import "./firebase";
import { getMessaging, onMessage } from "firebase/messaging";
import React, { useEffect } from "react";
import { initializeApp } from "firebase/app";
import FriendReq from "./components/Community/Friendreq";
import Shared from "./components/Community/SharedView";
import Mypages from "./components/Community/Myfeed";
function App() {
  const firebaseConfig = {
    apiKey: "AIzaSyCKxU1uQtQhgOkBddkbe1NKsFSXJ_v4ico",
    authDomain: "mukkatis.firebaseapp.com",
    projectId: "mukkatis",
    storageBucket: "mukkatis.appspot.com",
    messagingSenderId: "255102460015",
    appId: "1:255102460015:web:68af0bb8f73057720ead9a",
    measurementId: "G-EQVQ4N72VJ",
  };
  const app = initializeApp(firebaseConfig);
  const messaging = getMessaging(app);
  useEffect(() => {
    // Handle incoming messages
    onMessage(messaging, (payload) => {
      console.log("Message received:", payload);

      // Extract notification data
      const { title, body } = payload.notification;

      // Display notification
      new Notification(title, { body });
    });
  }, []);
  return (
    <div className="App">
      <Router>
        <Routes>
          {/* RSA */}
          <Route
            path="/rsa"
            element={
              <>
                <Navigation />
                <HeaderRSA /> <HomeRSA />
                <FooterRSA />
              </>
            }
          />
          <Route
            path="/MotorCycle"
            element={
              <>
                <Navigation />
                <MotorCycle />
                <FooterRSA />
              </>
            }
          />
          <Route
            path="/Service"
            element={
              <>
                <Navigation />
                <ServiceRSA />
                <FooterRSA />
              </>
            }
          />
          <Route
            path="/Shop"
            element={
              <>
                <Navigation />
                <Shop />
                <FooterRSA />
              </>
            }
          />
          <Route
            path="/Contact"
            element={
              <>
                <Navigation />
                <Contact />
                <FooterRSA />
              </>
            }
          />
          <Route
            path="/Location"
            element={
              <>
                <Navigation />
                <Location />
                <FooterRSA />
              </>
            }
          />
          <Route
            path="/food"
            element={
              <>
                <Navigation />
                <Food />
                <FooterRSA />
              </>
            }
          />
          <Route
            path="/nearby"
            element={
              <>
                <Navigation />
                <Services />
                <FooterRSA />
              </>
            }
          />

          {/* admin */}
          <Route path="/admin" element={<AdminLogin />} />
          <Route path="/reset-password" element={<Resetpassword />} />
          <Route path="/forgot-password" element={<Forgotpassword />} />
          <Route path="/admindashboard" element={<MainLayout />}>
            <Route index element={<Dashboard />} />
            <Route path="customers" element={<Customers />} />
            <Route path="vendors" element={<Vendors />} />
            <Route path="staysbanner" element={<AddBannerStay />} />
            <Route path="advertise" element={<AddAdvertise />} />
            <Route path="offers" element={<AddOffers />} />

            <Route path="advertiseList" element={<AddAdvertiseList />} />
            <Route path="rsabanner" element={<AddBannerRSA />} />
            <Route path="rsacategory" element={<AddCategory />} />
            <Route path="rsasubcategory" element={<AddSubCategory />} />
            <Route path="nearby" element={<AddNearBy />} />
            <Route path="rsaslider" element={<AddSliderRSA />} />
            <Route path="rsafaq" element={<AddFAQ />} />
            <Route path="rsatestimonial" element={<AddTestimonial />} />

            <Route path="apartmentlist" element={<ApartmentList />} />
            <Route path="PriorityList" element={<PriorityList />} />

            <Route path="homelist" element={<HomeList />} />
            <Route path="hotellist" element={<HotelList />} />
            <Route path="alternativelist" element={<AlternativeList />} />
            <Route path="addcars" element={<AddCars />} />
            <Route path="carlist" element={<CarList />} />
            <Route path="addtourpackage" element={<AddTourPackage />} />
            <Route path="international" element={<InternationalPackages />} />
            <Route path="bookcar" element={<Bookedcar />} />

            <Route
              path="bookinternational"
              element={<BookInternationalPackages />}
            />
            <Route path="bookstays" element={<Bookstay />} />
            <Route path="UnbookedList" element={<UnbookedList />} />
            <Route path="pilgrimorder" element={<PilgrimOrder />} />

            <Route path="tourlist" element={<TourList />} />
            <Route path="addvacation" element={<AddVacation />} />
            <Route path="vacationlist" element={<VacationList />} />
            <Route path="vacationorder" element={<VacationOrder />} />
          </Route>
          {/* Vendor Dashboard */}
          <Route path="/vendordashboard" element={<MainLayoutVendor />}>
            <Route index element={<DashboardVendor />} />

            <Route
              path="apartmentlistvendor"
              element={<ApartmentListVendor />}
            />
            <Route path="homelistvendor" element={<HomeListVendor />} />
            <Route path="hotellistvendor" element={<HotelListVendor />} />
            <Route
              path="alternativelistvendor"
              element={<AlternativeListVendor />}
            />
            <Route
              path="internationalvendor"
              element={<InternationalPackagesVendor />}
            />

            <Route
              path="bookinternationalvendor"
              element={<BookInternationalPackagesVendor />}
            />
            <Route path="bookstaysvendor" element={<BookstayVendor />} />
            <Route path="pilgrimordervendor" element={<PilgrimOrderVendor />} />

            <Route path="tourlistvendor" element={<TourListVendor />} />
          </Route>
          {/* Customer */}
          <Route path="/" element={<Home1 />} />

          <Route path="/architecture" element={<HomeArc />} />
          <Route path="/project" element={<Project />} />
          <Route path="/service" element={<Service />} />
          <Route path="/marketing" element={<Homedig />} />
          <Route path="/aboutus" element={<Abt />} />

          {/* <Route path="/carrental" element={<HomeCar />} /> */}
          <Route path="/carrentals" element={<HomeCar1 />} />
          <Route path="/Carrentaldetails" element={<Carrentaldetails />} />
          <Route path="/carlist" element={<Listofcars />} />

          {/* <Route path="/cardetails1" element={<CarDetails1 />} /> */}

          {/* <Route path="models" element={<Models />} /> */}
          <Route path="/Attraction" element={<Attraction />} />
          <Route
            path="/trippackagedetails/:id"
            element={<TripPackageDetails />}
          />
          <Route path="/profile" element={<Profile />} />

          <Route path="/ExclusivepackageHome" element={<HoneymoonHome />} />
          <Route path="/Exclusivepackagelist" element={<HoneyMoonList />} />
          <Route
            path="/Exclusivepackagedetails"
            element={<HoneyMoonDetails />}
          />

          <Route path="/createaccount" element={<CreateAccount />} />
          {/* <Route path="/" element={<CreateAccount />} /> */}
          <Route path="/Vendorheader" element={<VendorHeader />} />
          <Route path="/myproperty" element={<MyProperty />} />

          <Route path="/contactdetails" element={<ContactDetails />} />
          <Route path="/createpassword" element={<CreatePassword />} />
          {/* //Apartment */}
          <Route path="/property" element={<Property />} />
          <Route path="/propertyname" element={<Propertyname />} />
          <Route path="/propertylocation" element={<Propertylocation />} />
          <Route path="/Bedroom" element={<Bedroom />} />
          <Route path="/pilgrimvacation" element={<AddPilgrim />} />
          <Route path="/internationalvacation" element={<AddInternational />} />

          <Route path="/apartmenttype" element={<ApartmentType />} />
          <Route path="/multiapartmenttype" element={<MultiApartmentType />} />
          <Route
            path="/multiapartmenttype1"
            element={<MultiApartmentType1 />}
          />
          <Route path="/apartment1" element={<Apartment1 />} />
          <Route path="/registerlink" element={<SourceLink />} />
          <Route path="/propertysetup" element={<Propertysetup />} />
          <Route path="/propertyservice" element={<Propertyservice />} />
          <Route path="/propertylanguage" element={<Propertylanguage />} />
          <Route path="/propertyhouserules" element={<Propertyhouserules />} />
          <Route path="/propertyphoto" element={<Propertyphoto />} />
          <Route path="/propertyroomphoto" element={<Propertyroomphoto />} />

          <Route path="/propertyprice" element={<Propertyprice />} />
          <Route path="/propertyroomprice" element={<Propertyroomprice />} />

          <Route path="/propertyrate" element={<Propertyrate />} />
          <Route path="/cancellationpolicy" element={<Cancellation />} />
          <Route path="/nonrefundable" element={<Refundable />} />
          <Route path="/pricepergroup" element={<PriceGroup />} />
          <Route path="/weeklyrate" element={<Weeklyrate />} />
          <Route path="/map" element={<Map1 />} />

          {/* HomeStay */}
          <Route path="/hometype" element={<HomeType />} />
          <Route path="/Homestays" element={<Hotels1 />} />

          <Route path="/multihometype" element={<HomeType1 />} />

          <Route path="/multihome" element={<MultiHome />} />
          <Route path="/homestaylocation" element={<Homestaylocation />} />
          <Route path="/maphome" element={<MapHome />} />
          <Route path="/homename" element={<Homename />} />
          <Route path="/homeuse" element={<Homeuse />} />
          <Route path="/homesetup" element={<Homesetup />} />

          <Route path="/homebreakfast" element={<HomeBreakfast />} />
          <Route path="/homelanguage" element={<Homelanguage />} />
          <Route path="/homehouserules" element={<Homehouserules />} />
          <Route path="/homerate" element={<Homerate />} />

          <Route path="/Homephoto" element={<Homephoto />} />
          <Route path="/homeroomphoto" element={<Homeroomphoto />} />

          <Route path="/homeroomprice" element={<Homeroomprice />} />
          <Route path="/homeprice" element={<Homeprice />} />
          <Route path="/homeaddroom" element={<HomeAddroom />} />
          <Route path="/homebathroomdetails" element={<HomeBathroom />} />
          <Route path="/homeamenities" element={<HomeAmenities />} />
          <Route path="/homeroomname" element={<HomeRoomname />} />
          <Route path="/homeedit" element={<Homeedit />} />
          <Route path="/homepayment" element={<HomePayment />} />
          <Route path="/homeinvoice" element={<HomeInvoice />} />
          <Route
            path="/homecompleteregistration"
            element={<HomeCompleteregistration />}
          />

          {/* Hotel */}
          <Route path="/hoteltype" element={<HotelType />} />
          <Route path="/hoteltypess" element={<Hoteltypess />} />
          <Route path="/Onehotel" element={<Onehotel />} />

          <Route path="/multihotel" element={<MultiHotel />} />
          <Route path="/hotellocation" element={<Hotellocation />} />
          <Route path="/maphotel" element={<Maphotel />} />
          <Route path="/hotelname" element={<Hotelname />} />
          <Route path="/hoteluse" element={<HotelUse />} />
          <Route path="/hotelsetup" element={<Hotelsetup />} />

          <Route path="/hotelbreakfast" element={<HotelBreakfast />} />
          <Route path="/hotellanguage" element={<Hotellanguage />} />
          <Route path="/hotelhouserules" element={<Hotelhouserules />} />
          <Route path="/hotelrate" element={<Hotelrate />} />

          <Route path="/Hotelphoto" element={<Hotelphoto />} />
          <Route path="/hotelprice" element={<Hotelprice />} />
          <Route path="/hotelroomphoto" element={<Hotelroomphoto />} />
          <Route path="/sort" element={<SortedList />} />

          <Route path="/hotelroomprice" element={<Hotelroomprice />} />
          <Route path="/hoteladdroom" element={<HotelAddroom />} />
          <Route path="/hotelbathroomdetails" element={<HotelBathroom />} />
          <Route path="/hotelamenities" element={<HotelAmenities />} />
          <Route path="/hotelroomname" element={<HotelRoomname />} />
          <Route path="/hoteledit" element={<Hoteledit />} />
          <Route path="/hotelpayment" element={<HotelPayment />} />
          <Route path="/hotelinvoice" element={<HotelInvoice />} />
          <Route
            path="/hotelcompleteregistration"
            element={<HotelCompleteregistration />}
          />
          <Route path="/Bookingoda" element={<Home />} />
          <Route path="/BookStay" element={<BookStay />} />

          <Route path="/hotels" element={<List />} />
          <Route path="/Offers" element={<Offers />} />
          <Route path="/PilgrimOffers" element={<Offers1 />} />
          <Route path="/VacationOffers" element={<Offers2 />} />
          <Route path="/cart" element={<CartStay />} />
          <Route path="/checkout" element={<Checkoutstay />} />
          <Route path="/stayinvoice" element={<NewInvoice />} />
          <Route
            path="/internationalinvoice"
            element={<InternationalInvoice />}
          />
          <Route path="/pilgriminvoice" element={<PilgrimInvoice />} />

          <Route path="/hotels/:id" element={<Hotel />} />
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route path="/signin" element={<Signin />} />

          {/* Alternative */}
          <Route path="/alternative" element={<Entireproperty />} />
          <Route path="/alternative1" element={<Alternative1 />} />
          <Route
            path="/singlealternative"
            element={<MultiAlternativeType1 />}
          />
          <Route
            path="/alternativelocation"
            element={<Alternativelocation />}
          />
          <Route path="/mapalternative" element={<Mapalternative />} />
          <Route path="/alternativename" element={<Alternativename />} />
          <Route path="/alternativeuse" element={<AlternativeUse />} />
          <Route path="/alternativesetup" element={<Alternativesetup />} />
          <Route
            path="/alternativebreakfast"
            element={<AlternativeBreakfast />}
          />
          <Route
            path="/alternativelanguage"
            element={<Alternativelanguage />}
          />
          <Route
            path="/alternativehouserules"
            element={<Alternativehouserules />}
          />
          <Route path="/alternativephoto" element={<Alternativephoto />} />
          <Route path="/alternativerate" element={<Alternativerate />} />
          <Route
            path="/alternativeroomphoto"
            element={<Alternativeroomphoto />}
          />

          <Route
            path="/alternativeroomprice"
            element={<Alternativeroomprice />}
          />
          <Route path="/alternativeprice" element={<Alternativeprice />} />
          <Route path="/alternativeaddroom" element={<AlternativeAddroom />} />
          <Route
            path="/alternativebathroomdetails"
            element={<AlternativeBathroom />}
          />
          <Route
            path="/alternativeamenities"
            element={<AlternativeAmenities />}
          />
          <Route
            path="/alternativeroomname"
            element={<AlternativeRoomname />}
          />
          <Route path="/alternativeedit" element={<Alternativeedit />} />
          <Route path="/alternativepayment" element={<AlternativePayment />} />
          <Route path="/alternativeinvoice" element={<AlternativeInvoice />} />
          <Route
            path="/alternativecompleteregistration"
            element={<AlternativeCompleteregistration />}
          />

          <Route path="/propertylist" element={<Propertylist />} />

          <Route path="/privatetype" element={<Entirepropertytype />} />
          <Route path="/privatemutipletype" element={<Entiretype1 />} />
          <Route path="/propertyedit" element={<Propertyedit />} />
          <Route path="/addroom" element={<Addroom />} />
          <Route path="/bathroomdetails" element={<Bathroom />} />
          <Route path="/amenities" element={<Amenities />} />
          <Route path="/roomname" element={<Roomname />} />
          <Route path="/price" element={<Price />} />
          <Route path="/availablity" element={<Availablity />} />
          <Route path="/payment" element={<Payment />} />
          <Route path="/invoice" element={<Invoice />} />
          <Route path="/CreateProperty" element={<GetStarted />} />
          <Route
            path="/completeregistration"
            element={<Completeregistration />}
          />

          {/* International */}

          <Route path="/HomeInternational" element={<HomeInter />} />
          <Route
            path="/HomeInternationalDetails/:id"
            element={<HomeInterDetails />}
          />
          <Route path="/HomeInternationalList" element={<HomeInterList />} />
          <Route path="/Vacationhome" element={<Vacationhome />} />
          <Route path="/Vacationdetails/:id" element={<Vacationdetails />} />
          <Route path="/Vacationlist" element={<Vacationlist />} />
          {/* 
          <Route path="/book" element={<Book />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/locations" element={<Locations />} />
          <Route path="/locations/:id" element={<Dubai />} /> */}
          {/* Community */}

          <Route
            path="/community-home"
            element={
              <>
                {/* <PageLoader> */}

                <Com_Home />

                {/* </PageLoader> */}
              </>
            }
          />
           <Route
            path="/shared/:id"
            element={
              <>
                {/* <PageLoader> */}

                <Shared />

                {/* </PageLoader> */}
              </>
            }
          />
          <Route
            path="/community-about-us"
            element={
              <>
                {/* <PageLoader> */}

                {/* <Layout children={<About />} /> */}
                <About />

                {/* </PageLoader> */}
              </>
            }
          />
              <Route
            path="/community-mypages"
            element={
              <>
                {/* <PageLoader> */}

                {/* <Layout children={<About />} /> */}
                <Mypages />

                {/* </PageLoader> */}
              </>
            }
          />
          <Route
            path="/community_add_profile"
            element={
              <>
                {/* <PageLoader> */}

                {/* <Layout children={<About />} /> */}
                <Add_profile />
                {/* </PageLoader> */}
              </>
            }
          />
          <Route
            path="/community-friends"
            element={
              <>
                {/* <PageLoader> */}

                <Friend />

                {/* </PageLoader> */}
              </>
            }
          />
          <Route
            path="/community-reqs"
            element={
              <>
                {/* <PageLoader> */}

                <FriendReq />

                {/* </PageLoader> */}
              </>
            }
          />

          <Route path="/community-message" element={<Com_Message />} />

          <Route path="*" element={<Error />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
