import React, { useState } from "react";
import {
  Container,
  Form,
  Row,
  Table,
  DatePicker,
  Modal,
} from "react-bootstrap";
import { MdDelete } from "react-icons/md";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepButton from "@mui/material/StepButton";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { City, Country, State } from "country-state-city";
import axios from "axios";
import CustomInput from "../components/CustomInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import * as XLSX from "xlsx";
import moment from "moment/moment";
import Success from "../../assets/success.png";
import Error from "../../assets/close.png";
import TextArea from "antd/es/input/TextArea";
import { useEffect } from "react";

const steps = [
  "Package Details",
  // "Details and Includes",
  "Gallery Image",
  "About The Tour ",
  "Itinerary ",
];
const AddTourPackage = (props) => {
  const admin = JSON.parse(sessionStorage.getItem("admin"));

  const { i_class, onBlr } = props;
  const [activeStep, setActiveStep] = React.useState(0);
  const [completed, setCompleted] = React.useState({});

  const totalSteps = () => {
    return steps.length;
  };

  const completedSteps = () => {
    return Object.keys(completed).length;
  };

  const isLastStep = () => {
    return activeStep === totalSteps() - 1;
  };

  const allStepsCompleted = () => {
    return completedSteps() === totalSteps();
  };

  const handleNext = () => {
    const newActiveStep =
      isLastStep() && !allStepsCompleted()
        ? steps.findIndex((step, i) => !(i in completed))
        : activeStep + 1;
    setActiveStep(newActiveStep);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStep = (step) => () => {
    setActiveStep(step);
  };

  const handleReset = () => {
    setActiveStep(0);
    setCompleted({});
  };
  const [successshow, setsuccessshow] = useState(false);
  const handlesuccessClose = () => setsuccessshow(false);
  const handlesuccess = () => setsuccessshow(true);

  const [errorshow, seterrorshow] = useState(false);
  const handleerrorClose = () => seterrorshow(false);
  const handleerror = () => seterrorshow(true);
  ////console.log("completed", completed);
  // Tour Package Details

  const [Country1, setCountry1] = useState("");
  const [State1, setState1] = useState({ isoCode: "", name: "" });
  const [City1, setCity1] = useState("");
  console.log(State1);
  const CountryList = Country.getAllCountries();
  const StateList = State.getStatesOfCountry(Country1);
  const CityList = City.getCitiesOfState(Country1, State1?.isoCode);

  useEffect(() => {
    // Reset state and city when country changes
    setState1({ isoCode: "", name: "" });
    setCity1("");
  }, [Country1]);

  useEffect(() => {
    // Reset city when state changes
    setCity1("");
  }, [State1]);
  const [TripName, setTripName] = useState("");
  const [TripDescription, setTripDescription] = useState("");
  const [TripCost, setTripCost] = useState();
  const [TripDiscount, setTripDiscount] = useState();
  const [ChildrenCost, setChildrenCost] = useState();
  const [Childrendiscount, setChildrendiscount] = useState();
  // const [Petcost, setPetcost] = useState();
  // const [Petdiscount, setPetdiscount] = useState();
  const [TripDuration, setTripDuration] = useState("");
  //Details And Includes
  const [Sightseeing, setSightseeing] = useState("");
  const [Hotels, setHotels] = useState("");
  const [Transport, setTransport] = useState("");
  ////console.log(Sightseeing);
  const handleChange = (event) => {
    setCountry1(event.target.value);
  };
  const handleChange1 = (event) => {
    setState1(event.target.value);
  };
  const handleChange2 = (event) => {
    setCity1(event.target.value);
  };
  const handleChange3 = (event) => {
    setSightseeing(event.target.value);
  };
  const handleChange4 = (event) => {
    setHotels(event.target.value);
  };
  const handleChange5 = (event) => {
    setTransport(event.target.value);
  };
  const handleComplete = () => {
    const newCompleted = completed;
    newCompleted[activeStep] = true;
    setCompleted(newCompleted);
    if (activeStep === 0) {
      if (!TripName) {
        return (
          <>
            {alert("Enter trip name")}
            {setActiveStep(0)}
          </>
        );
      } else if (!TripDescription) {
        return (
          <>
            {alert("Enter Trip Description")}
            {setActiveStep(0)}
          </>
        );
      } else if (!TripCost) {
        return (
          <>
            {alert("Enter Adult Trip Cost..!")}
            {setActiveStep(0)}
          </>
        );
      } else if (!ChildrenCost) {
        return (
          <>
            {alert("Enter Children Cost..!")}
            {setActiveStep(0)}
          </>
        );
      } else if (!Country1) {
        return (
          <>
            {alert("Select Country")}
            {setActiveStep(0)}
          </>
        );
      } else if (!State1) {
        return (
          <>
            {alert("Select State")}
            {setActiveStep(0)}
          </>
        );
      } else if (!City1) {
        return (
          <>
            {alert("Select City")}
            {setActiveStep(0)}
          </>
        );
      } else if (!TripDuration) {
        return (
          <>
            {alert("Enter trip Deration..!")}
            {setActiveStep(0)}
          </>
        );
      }
      handleNext();
    }

    // if (activeStep === 1) {
    //   if (!Sightseeing) {
    //     return <>{alert("Select Sight Seeing")}</>;
    //   } else if (!Hotels) {
    //     return <>{alert("Select Hotels")}</>;
    //   } else if (!Transport) {
    //     return <>{alert("Select Transport")}</>;
    //   } else {
    //     handleNext();
    //   }
    // }
    if (activeStep === 1) {
      if (galleryImages.length === 0) {
        return <>{alert("Select Gallery Images")}</>;
      } else {
        handleNext();
      }
    }

    if (activeStep === 2) {
      if (tourschedule.length === 0) {
        return <>{alert("Select Placess")}</>;
      } else {
        handleNext();
      }
    }
    if (activeStep === 3) {
      if (Itinerary.length === 0) {
        return <>{alert("Select Itinerary")}</>;
      } else {
        handleNext();
      }
    }
  };

  //

  // Add Gallery Image
  const [imageURL2, setimageURL2] = useState(null);
  const [Image, setImage] = useState("");
  const [galleryImages, setGalleryImages] = useState([]);
  const onImageChange2 = (event) => {
    if (event.target.files && event.target.files[0]) {
      setimageURL2(URL.createObjectURL(event.target.files[0]));
    }
  };
  ////console.log("galleryImages", galleryImages);
  const AddGalleryImage = () => {
    if (!Image) {
      return alert("Please, Select Image");
    }

    const imageExists = galleryImages.find((img) => img?.imgUrl === Image);

    if (imageExists) {
      return alert("Image already exists in the gallery.");
    }

    const obj = {
      image: Image,
      imgUrl: imageURL2,
    };

    setGalleryImages([...galleryImages, obj]);
    setImage("")
    setimageURL2(null)
    const fileInput = document.getElementById('fileInput');
    if (fileInput) {
      fileInput.value = null;
    }
  };
  const removeItem2 = (val) => {
    const updatedGallery = galleryImages.filter((item, index) => index !== val);
    setGalleryImages(updatedGallery);
  };

  // Add Tour Schedule
  const [tourschedule, settourschedule] = useState([]);
  const [PlaceName, setPlaceName] = useState("");
  const [Inclusion, setInclusion] = useState("");
  const [Exclusion, setExclusion] = useState("");
  const [EventDate, setEventDate] = useState("");
  ////console.log();
  const AddTourSchedule = () => {
    if (!PlaceName) {
      return alert("Enter Place Name");
    }
    if (!Inclusion) {
      return alert("Enter Inclusion");
    }
    if (!Exclusion) {
      return alert("Enter Exclusion");
    }
    if (!EventDate) {
      return alert("Enter EventDate");
    }

    const scheduleExists = tourschedule?.filter(
      (ele) =>
        ele?.placename === PlaceName &&
        ele.inclusion === Inclusion &&
        ele?.exclusion === Exclusion &&
        ele?.eventDate === EventDate
    );

    if (!scheduleExists) {
      return alert("Details is already exists.");
    }

    const obj = {
      placename: PlaceName,
      inclusion: Inclusion,
      exclusion: Exclusion,
      eventDate: EventDate,
    };

    settourschedule([...tourschedule, obj]);
    setPlaceName("");
    setInclusion("");
    setExclusion("");
    setEventDate("");
  };
  const removeschedule = (val) => {
    const updatedGallery = tourschedule.filter((item, index) => index !== val);
    settourschedule(updatedGallery);
  };
  const [Itinerary, setItinerary] = useState([]);
  const [Header, setHeader] = useState("");
  const [ItineraryDesc, setItineraryDesc] = useState("");

  ////console.log();
  const AddItinerary = () => {
    if (!Header) {
      return alert("Enter Itinerary title");
    }
    if (!ItineraryDesc) {
      return alert("Enter Description");
    }

    const ItineraryExists = Itinerary?.filter(
      (ele) => ele?.Header === Header && ele.ItineraryDesc === ItineraryDesc
    );

    if (!ItineraryExists) {
      return alert("Details is  already exists.");
    }

    const obj = {
      Header: Header,
      ItineraryDesc: ItineraryDesc,
    };

    setItinerary([...Itinerary, obj]);
    setHeader("");
    setItineraryDesc("");
  };
  const removeItinerary = (val) => {
    const updatedItinerary = Itinerary.filter((item, index) => index !== val);
    setItinerary(updatedItinerary);
  };

  const TourpackageDetails = async () => {
    let adultofferprice = TripCost - (TripCost * TripDiscount) / 100;
    let childrenofferprice =
      ChildrenCost - (ChildrenCost * Childrendiscount) / 100;
    // let petofferprice = Petcost - (Petcost * Petdiscount) / 100;
    try {
      const config = {
        url: "/admin/addtrippackages",
        method: "post",
        baseURL: "https://bookingoda.com/api",
        Headers: { "content-type": "multipart/form-data" },
        data: {
          UserId: admin._id,
          tripname: TripName,
          tripdescription: TripDescription,
          tripcost: TripCost,
          tripdiscount: TripDiscount,
          offerprice: adultofferprice,
          country: Country1,
          state: State1?.name,
          city: City1,
          childrencost: ChildrenCost,
          childrendiscount: Childrendiscount,
          childrenoffer: childrenofferprice,
          // petscost: Petcost,
          // petdiscount: Petdiscount,
          // petoffer: petofferprice,
          tripduration: TripDuration,
          signtseeing: Sightseeing,
          hotels: Hotels,
          transport: Transport,
          // GalleryImage: galleryImages,
          TourSchedule: tourschedule,
          Itinerary: Itinerary,
        },
      };
      await axios(config).then(async (res) => {
        if (res.status === 200) {
          for (let i = 0; i < galleryImages.length; i++) {
            const config = {
              url: "/admin/galleryimageupload",
              method: "put",
              baseURL: "https://bookingoda.com/api",
              headers: { "content-type": "multipart/form-data" },
              data: {
                productId: res.data.success?._id,
                image: galleryImages[i]?.image,
              },
            };
            await axios(config);
          }
          // alert("Package Added Successfully");
          handlesuccess();
          window.location.assign("/admindashboard/tourlist");
        }
      });
      ////console.log("config", config);
    } catch (error) {
      ////console.log(error);
      handleerror();
    }
  };

  return (
    <div>
      <Container fluid>
        <div className="d-flex justify-content-between align-items-center pt-4 pb-2">
          <p className="text-success fs-4 fw-semibold">
            Pilgrim Vacations Details:
          </p>
        </div>

        <div className="Stepper-info" style={{ padding: "20px" }}>
          <Box sx={{ width: "100%" }}>
            <Stepper nonLinear activeStep={activeStep}>
              {steps.map((label, index) => (
                <Step key={label} completed={completed[index]}>
                  <StepButton
                    color="inherit"
                    style={{ marginBottom: "50px" }}
                    onClick={handleStep(index)}
                  >
                    {label}
                  </StepButton>
                </Step>
              ))}
            </Stepper>
            <div>
              {allStepsCompleted() ? (
                <React.Fragment>
                  <Typography
                    sx={{ mt: 2, mb: 1 }}
                    style={{
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      fontSize: "20px",
                      fontWeight: "500",
                    }}
                  >
                    All steps completed successfully
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignSelf: "center",
                        pt: 2,
                      }}
                    >
                      <Button onClick={handleReset}>Back</Button>
                    </Box>
                  </Typography>
                </React.Fragment>
              ) : (
                <React.Fragment>
                  {activeStep == 0 ? (
                    <>
                      <Typography sx={{ mt: 2, mb: 1, py: 1 }}>
                        <div className="container" style={{ padding: "5px" }}>
                          <div className="row ">
                            <div className="col-md-6">
                              <div className="do-sear mt-2">
                                {/* <label>
                                  Trip Name
                                  <span style={{ color: "red" }}>*</span>
                                </label> */}
                                <CustomInput
                                  type="text"
                                  label="Trip Name"
                                  name="name"
                                  onChng={(e) => setTripName(e.target.value)}
                                  val={TripName}
                                />
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="do-sear mt-2">
                                {/* <label>
                                  Trip Description
                                  <span style={{ color: "red" }}>*</span>
                                </label> */}
                                <CustomInput
                                  type="text"
                                  label="Description"
                                  name="name"
                                  onChng={(e) =>
                                    setTripDescription(e.target.value)
                                  }
                                  val={TripDescription}
                                />
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="do-sear mt-2">
                                {/* <label>
                                  Adult Price
                                  <span style={{ color: "red" }}>*</span>
                                </label> */}
                                {/* <input
                                  type="text"
                                  placeholder="₹ Price"
                                  className="vi_0"
                                  onChange={(e) => setTripCost(e.target.value)}
                                /> */}
                                <CustomInput
                                  type="number"
                                  label="Adult Price"
                                  max={6}
                                  name="name"
                                  onChng={(e) => setTripCost(e.target.value)}
                                  val={TripCost}
                                />
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="do-sear mt-2">
                                {/* <label>
                                  Adult Discount{" "}
                                <span style={{ color: "red" }}>*</span>
                                </label> */}
                                {/* <input
                                  type="text"
                                  placeholder="%Discount"
                                  className="vi_0"
                                  onChange={(e) =>
                                    setTripDiscount(e.target.value)
                                  }
                                /> */}
                                <CustomInput
                                  type="number"
                                  label="Adult Discount"
                                  max={2}
                                  name="name"
                                  onChng={(e) =>
                                    setTripDiscount(e.target.value)
                                  }
                                  val={TripDiscount}
                                />
                              </div>
                            </div>

                            <div className="col-md-6">
                              <div className="do-sear mt-2">
                                {/* <label>
                                  Children Price
                                  <span style={{ color: "red" }}>*</span>
                                </label> */}
                                {/* <input
                                  type="text"
                                  placeholder="₹ For Children Price"
                                  className="vi_0"
                                  onChange={(e) =>
                                    setChildrenCost(e.target.value)
                                  }
                                /> */}
                                <CustomInput
                                  type="number"
                                  label="Children Price"
                                  max={6}
                                  name="name"
                                  onChng={(e) =>
                                    setChildrenCost(e.target.value)
                                  }
                                  val={ChildrenCost}
                                />
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="do-sear mt-2">
                                {/* <label>
                                  Children Discount{" "}
                                  {/* <span style={{ color: "red" }}>*</span> */}
                                {/* </label> */}
                                {/* <input
                                  type="text"
                                  placeholder="For Children % Discount"
                                  className="vi_0"
                                  onChange={(e) =>
                                    setChildrendiscount(e.target.value)
                                  } 
                                 />  */}
                                <CustomInput
                                  type="number"
                                  label="Children Discount"
                                  name="name"
                                  max={2}
                                  onChng={(e) =>
                                    setChildrendiscount(e.target.value)
                                  }
                                  val={Childrendiscount}
                                />
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="do-sear mt-2">
                                {/* <CustomInput
                                  type="text"
                                  label="Pet Price"
                                  name="name"
                                  onChng={(e) => setPetcost(e.target.value)}
                                  val={Petcost}
                                /> */}
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="do-sear mt-2">
                                {/* <CustomInput
                                  type="text"
                                  label="Pet Discount"
                                  name="name"
                                  onChng={(e) => setPetdiscount(e.target.value)}
                                  val={Petdiscount}
                                /> */}
                              </div>
                            </div>

                            <div className="col-md-6">
                              <div className="do-sear mt-3">
                              <label>Country</label>
                                <select
                                  class="ebf4591c8e country-select-for-e2e-tests"
                                  name="cc1"
                                  id="cc1"
                                  className="vi_0"
                                  style={{
                                    width: "100%",
                                    borderColor: "#E6E6E6",
                                    fontSize: "16px",
                                  }}
                                  onChange={(e) => setCountry1(e.target.value)}
                                >
                                  <option value="" style={{ fontSize: "16px" }}>
                                    Select country/region
                                    <span style={{ color: "red" }}>*</span>
                                  </option>
                                  {CountryList?.map((Country) => (
                                    <option
                                      value={Country?.isoCode}
                                      style={{ fontSize: "16px" }}
                                    >
                                      {Country?.name}
                                    </option>
                                  ))}
                                </select>
                                {/* // <FormControl fullWidth>
                                //   <InputLabel id="demo-simple-select-helper-label">
                                //     Select country/region{" "}
                                //     <span style={{ color: "red" }}>*</span>
                                //   </InputLabel>
                                //   <Select
                                //     labelId="demo-simple-select-helper-label"
                                //     id="demo-simple-select-helper"
                                //     value={Country1}
                                   
                                //     label="Select country/region"
                                //     onChange={handleChange}
                                //   >
                                 
                                //     {CountryList?.map((item) => (
                                //       <MenuItem value={item?.name}>
                                //         {item?.name}
                                //       </MenuItem>
                                //     ))}
                                //   </Select>
                                  
                                // </FormControl> */}
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="do-sear mt-3">
                              <label>State</label>
                                <select
                                  class="ebf4591c8e country-select-for-e2e-tests"
                                  name="cc1"
                                  id="cc1"
                                  className="vi_0"
                                  style={{
                                    width: "100%",
                                    borderColor: "#E6E6E6",
                                    fontSize: "16px",
                                  }}
                                  onChange={(e) => {
                                    const selectedState = StateList.find(
                                      (state) =>
                                        state.isoCode === e.target.value
                                    );
                                    setState1(
                                      selectedState
                                        ? {
                                            isoCode: selectedState.isoCode,
                                            name: selectedState.name,
                                          }
                                        : { isoCode: "", name: "" }
                                    );
                                  }}
                                >
                                   <option value="" style={{ fontSize: "16px" }}>
                                    Select state/region{" "}
                                    <span style={{ color: "red" }}>*</span>
                                    </option>
                                  {StateList?.map((state) => (
                                    <option
                                      key={state.isoCode}
                                      value={state.isoCode}
                                    >
                                      {state.name}
                                    </option>
                                  ))}
                                
                                 
                                </select>
                                {/* <FormControl fullWidth>
                                  <InputLabel id="demo-simple-select-label">
                                    State{" "}
                                    <span style={{ color: "red" }}>*</span>
                                  </InputLabel>
                                  <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={State1}
                                    label="State"
                                    onChange={handleChange1}
                                  >
                                    {StateList?.map((item) => (
                                      <MenuItem value={item?.isoCode}>
                                        {item?.name}
                                      </MenuItem>
                                    ))}
                                  </Select>
                                </FormControl> */}
                              </div>
                            </div>

                            <div className="col-md-6">
                              <div className="do-sear mt-3">
                              <label>City</label>
                                <select
                                  className="vi_0"
                                  // class="ebf4591c8e country-select-for-e2e-tests"
                                  style={{
                                    width: "100%",
                                    borderColor: "#E6E6E6",
                                    fontSize: "16px",
                                  }}
                                  name="cc1"
                                  id="cc1"
                                  onChange={(e) => setCity1(e.target.value)}
                                >
                                  <option value="" style={{ fontSize: "16px" }}>
                                    Select City{" "}
                                    <span style={{ color: "red" }}>*</span>
                                  </option>
                                  {CityList?.map((city1) => (
                                    <option
                                      value={city1?.name}
                                      style={{ fontSize: "16px" }}
                                    >
                                      {city1?.name}
                                    </option>
                                  ))}
                                </select>
                                {/* <FormControl fullWidth>
                                  <InputLabel id="demo-simple-select-label">
                                    City <span style={{ color: "red" }}>*</span>
                                  </InputLabel>
                                  <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={City1}
                                    label="City"
                                    onChange={handleChange2}
                                  >
                                    {CityList?.map((item) => (
                                      <MenuItem value={item?.name}>
                                        {item?.name}
                                      </MenuItem>
                                    ))}
                                  </Select>
                                </FormControl> */}
                              </div>
                            </div>
                            {/* <div className="col-md-6">
                              <div className="do-sear mt-2">
                                <label>
                                  Package Start Date
                                  <span style={{ color: "red" }}>*</span>
                                </label>
                                <input
                                  type="date"
                                  className="vi_0"
                                  onChange={(e) => setStartDate(e.target.value)}
                                />
                              </div>
                            </div> */}
                            {/* <div className="col-md-6">
                              <div className="do-sear mt-2">
                                <label>
                                  Package End Date
                                  <span style={{ color: "red" }}>*</span>
                                </label>
                                <input
                                  type="date"
                                  className="vi_0"
                                  onChange={(e) => setEndDate(e.target.value)}
                                />
                              </div>
                            </div> */}
                            <div className="col-md-6">
                              <div className="do-sear mt-2">
                                {/* <label>
                                  Duration
                                  <span style={{ color: "red" }}>*</span>
                                </label>
                                <input
                                  type="text"
                                  placeholder="Days"
                                  className="vi_0"
                                  onChange={(e) =>
                                    setTripDuration(e.target.value)
                                  }
                                /> */}
                                <CustomInput
                                  type="text"
                                  label="Duration(eg: 3N/3D)"
                                  name="name"
                                  onChng={(e) =>
                                    setTripDuration(e.target.value)
                                  }
                                  val={TripDuration}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </Typography>
                    </>
                  ) : activeStep == 1 ? (
                  //   <>
                  //     <Typography sx={{ mt: 2, mb: 1, py: 1 }}>
                  //       <div className="container" style={{ padding: "5px" }}>
                  //         <div className="row ">
                  //           <div className="col-md-6">
                  //             <div className="do-sear mt-2">
                  //               {/* <label>
                  //                     Sight Seeing{" "}
                  //                     <span style={{ color: "red" }}>*</span>
                  //                   </label> */}
                  //               {/* <input
                  //                     type="text"
                  //                     placeholder="Sight"
                  //                     className="vi_0"
                  //                   /> */}
                  //               {/* <select
                  //                     className="vi_0"
                  //                     onChange={(e) =>
                  //                       setSightseeing(e.target.value)
                  //                     }
                  //                   >
                  //                     <option>Select</option>
                  //                     <option value="Yes">Yes</option>
                  //                     <option value="No">No</option>
                  //                   </select> */}
                  //               {/* <FormControl fullWidth>
                  //                     <InputLabel id="demo-simple-select-label">
                  //                       Sight Seeing{" "}
                  //                       <span style={{ color: "red" }}>*</span>
                  //                     </InputLabel>
                  //                     <Select
                  //                       labelId="demo-simple-select-label"
                  //                       id="demo-simple-select"
                  //                       value={Sightseeing}
                  //                       label="Sight Seeing"
                  //                       onChange={handleChange3}
                  //                     >
                  //                       <MenuItem value="Yes">Yes</MenuItem>
                  //                       <MenuItem value="No">No</MenuItem>
                  //                     </Select>
                  //                   </FormControl> */}
                  //               <select
                  //                 className="vi_0"
                  //                 // class="ebf4591c8e country-select-for-e2e-tests"
                  //                 style={{
                  //                   width: "100%",
                  //                   borderColor: "#E6E6E6",
                  //                   fontSize: "16px",
                  //                 }}
                  //                 name="cc1"
                  //                 id="cc1"
                  //                 value={Sightseeing}
                  //                 label="Sight Seeing"
                  //                 onChange={handleChange3}
                  //               >
                  //                 <option value="" style={{ fontSize: "16px" }}>
                  //                   Sight Seeing{" "}
                  //                   <span style={{ color: "red" }}>*</span>
                  //                 </option>

                  //                 <option
                  //                   value="Yes"
                  //                   style={{ fontSize: "16px" }}
                  //                 >
                  //                   Yes
                  //                 </option>
                  //                 <option
                  //                   value="No"
                  //                   style={{ fontSize: "16px" }}
                  //                 >
                  //                   No
                  //                 </option>
                  //               </select>
                  //             </div>
                  //           </div>

                  //           <div className="col-md-6">
                  //             <div className="do-sear mt-2">
                  //               {/* <label>
                  //                     Hotel
                  //                     <span style={{ color: "red" }}>*</span>
                  //                   </label> */}
                  //               {/* <input
                  //                     type="text"
                  //                     placeholder="Hotel"
                  //                     className="vi_0"
                  //                   /> */}
                  //               {/* <select
                  //                     className="vi_0"
                  //                     onChange={(e) =>
                  //                       setHotels(e.target.value)
                  //                     }
                  //                   >
                  //                     <option>Select</option>
                  //                     <option value="Yes">Yes</option>
                  //                     <option value="No">No</option>
                  //                   </select> */}
                  //               {/* <FormControl fullWidth>
                  //                     <InputLabel id="demo-simple-select-label">
                  //                       Hotel{" "}
                  //                       <span style={{ color: "red" }}>*</span>
                  //                     </InputLabel>
                  //                     <Select
                  //                       labelId="demo-simple-select-label"
                  //                       id="demo-simple-select"
                  //                       value={Hotels}
                  //                       label="Hotel"
                  //                       onChange={handleChange4}
                  //                     >
                  //                       <MenuItem value="Yes">Yes</MenuItem>
                  //                       <MenuItem value="No">No</MenuItem>
                  //                     </Select>
                  //                   </FormControl> */}
                  //               <select
                  //                 className="vi_0"
                  //                 // class="ebf4591c8e country-select-for-e2e-tests"
                  //                 style={{
                  //                   width: "100%",
                  //                   borderColor: "#E6E6E6",
                  //                   fontSize: "16px",
                  //                 }}
                  //                 name="cc1"
                  //                 id="cc1"
                  //                 value={Hotels}
                  //                 label="Hotel"
                  //                 onChange={handleChange4}
                  //               >
                  //                 <option value="" style={{ fontSize: "16px" }}>
                  //                   Hotel{" "}
                  //                   <span style={{ color: "red" }}>*</span>
                  //                 </option>

                  //                 <option
                  //                   value="Yes"
                  //                   style={{ fontSize: "16px" }}
                  //                 >
                  //                   Yes
                  //                 </option>
                  //                 <option
                  //                   value="No"
                  //                   style={{ fontSize: "16px" }}
                  //                 >
                  //                   No
                  //                 </option>
                  //               </select>
                  //             </div>
                  //           </div>
                  //           <div className="col-md-6">
                  //             <div className="do-sear mt-2">
                  //               {/* <label>
                  //                     Transpotrs
                  //                     <span style={{ color: "red" }}>*</span>
                  //                   </label>
                  //                   <select
                  //                     className="vi_0"
                  //                     onChange={(e) =>
                  //                       setTransport(e.target.value)
                  //                     }
                  //                   >
                  //                     <option>Select</option>
                  //                     <option value="Yes">Yes</option>
                  //                     <option value="No">No</option>
                  //                   </select> */}
                  //               {/* <FormControl fullWidth>
                  //                     <InputLabel id="demo-simple-select-label">
                  //                       Transpotrs{" "}
                  //                       <span style={{ color: "red" }}>*</span>
                  //                     </InputLabel>
                  //                     <Select
                  //                       labelId="demo-simple-select-label"
                  //                       id="demo-simple-select"
                  //                       value={Transport}
                  //                       label="Transpotrs"
                  //                       onChange={handleChange5}
                  //                     >
                  //                       <MenuItem value="Yes">Yes</MenuItem>
                  //                       <MenuItem value="No">No</MenuItem>
                  //                     </Select>
                  //                   </FormControl> */}
                  //               <select
                  //                 className="vi_0"
                  //                 // class="ebf4591c8e country-select-for-e2e-tests"
                  //                 style={{
                  //                   width: "100%",
                  //                   borderColor: "#E6E6E6",
                  //                   fontSize: "16px",
                  //                 }}
                  //                 name="cc1"
                  //                 id="cc1"
                  //                 value={Transport}
                  //                 label="Transpotrs"
                  //                 onChange={handleChange5}
                  //               >
                  //                 <option value="" style={{ fontSize: "16px" }}>
                  //                   Transports{" "}
                  //                   <span style={{ color: "red" }}>*</span>
                  //                 </option>

                  //                 <option
                  //                   value="Yes"
                  //                   style={{ fontSize: "16px" }}
                  //                 >
                  //                   Yes
                  //                 </option>
                  //                 <option
                  //                   value="No"
                  //                   style={{ fontSize: "16px" }}
                  //                 >
                  //                   No
                  //                 </option>
                  //               </select>
                  //             </div>
                  //           </div>
                  //           {/* <div className="col-md-6">
                  //                 <div className="do-sear mt-2">
                  //                   <label>
                  //                     Duration
                  //                     <span style={{ color: "red" }}>*</span>
                  //                   </label>
                  //                   <input
                  //                     type="text"
                  //                     placeholder="Enter Duration"
                  //                     className="vi_0"
                  //                   />
                  //                 </div>
                  //               </div> */}
                  //           {/* <div className="col-md-6">
                  //                 <div className="do-sear mt-2">
                  //                   <label>
                  //                     Location
                  //                     <span style={{ color: "red" }}>*</span>
                  //                   </label>
                  //                   <input
                  //                     type="text"
                  //                     placeholder="Enter Location"
                  //                     className="vi_0"
                  //                   />
                  //                 </div>
                  //               </div> */}
                  //         </div>
                  //       </div>
                  //     </Typography>
                  //   </>
                  // ) : activeStep == 2 ? (
                    <>
                      <Typography sx={{ mt: 2, mb: 1, py: 1 }}>
                        <div className="container" style={{ padding: "5px" }}>
                          <div className="row ">
                            <div className="col-md-6">
                              <div className="do-sear mt-2">
                                <label>
                                  Gallery
                                  <span style={{ color: "red" }}>*</span>
                                </label>
                                <div className=" mt-3">
                                  <input
                                    type="file"
                                    id="fileInput"
                                    className={`form-control ${i_class}`}
                                    onBlur={onBlr}
                                    accept="image/*"
                                    onChange={(e) => {
                                      onImageChange2(e);
                                      setImage(e.target.files[0]);
                                    }}
                                  />
                                </div>
                                {/* <CustomInput
                                          type="file"
                                          label="Gallary"
                                          name="name"
                                          onChng={(e) =>{
                                            onImageChange2(e);
                                            setImage(e.target.value)
                                          }}
                                          val={Image}
                                        /> */}
                              </div>

                              <button
                                className="pink-btn mt-4 "
                                style={{
                                  background: "green",
                                  color: "#fff",
                                  padding: 10,
                                }}
                                onClick={AddGalleryImage}
                              >
                                Add
                              </button>
                            </div>

                            <div className="col-md-6">
                              <Table responsive bordered>
                                <thead>
                                  <tr>
                                    <th
                                      style={{
                                        background: "#d81d4a",
                                        color: "#fff",
                                      }}
                                    >
                                      S.No
                                    </th>
                                    <th
                                      style={{
                                        background: "#d81d4a",
                                        color: "#fff",
                                      }}
                                    >
                                      Image
                                    </th>
                                    <th
                                      style={{
                                        background: "#d81d4a",
                                        color: "#fff",
                                      }}
                                    >
                                      Action
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {galleryImages?.map((item, i) => {
                                    return (
                                      <tr>
                                        <td>{i + 1}</td>
                                        <td>
                                          <img
                                            src={item?.imgUrl}
                                            alt=""
                                            style={{
                                              width: "50px",
                                              height: "50px",
                                              objectFit: "cover",
                                            }}
                                          />
                                        </td>
                                        <td>
                                          <div
                                            className="d-flex gap-2 fs-4"
                                            style={{
                                              cursor: "pointer",
                                            }}
                                          >
                                            {/* <BiSolidEdit className="text-primary" /> */}
                                            <MdDelete
                                              onClick={() => removeItem2(i)}
                                              className="text-danger"
                                            />
                                          </div>
                                        </td>
                                      </tr>
                                    );
                                  })}
                                </tbody>
                              </Table>
                            </div>
                          </div>
                        </div>
                      </Typography>
                    </>
                  ) : activeStep == 2 ? (
                    <>
                      <Typography sx={{ mt: 2, mb: 1, py: 1 }}>
                        <div className="container" style={{ padding: "5px" }}>
                          <div className="row ">
                            <div className="col-md-5">
                              <div className="do-sear mt-2">
                                {/* <label>
                                          Place Name
                                          <span style={{ color: "red" }}>
                                            *
                                          </span> */}
                                {/* </label> */}
                                {/* <input
                                          type="text"
                                          placeholder="Enter Place Name"
                                          className="vi_0"
                                          onChange={(e) =>
                                            setPlaceName(e.target.value)
                                          }
                                        /> */}
                                <CustomInput
                                  type="text"
                                  label="Place Name"
                                  name="name"
                                  onChng={(e) => setPlaceName(e.target.value)}
                                  val={PlaceName}
                                />
                              </div>
                              <div className="do-sear mt-2">
                                {/* <label>
                                          Inclusion
                                          <span style={{ color: "red" }}>
                                            *
                                          </span>
                                        </label>
                                        <input
                                          type="text"
                                          placeholder="Enter Inclusion"
                                          className="vi_0"
                                          onChange={(e) =>
                                            setInclusion(e.target.value)
                                          }
                                        /> */}
                                <CustomInput
                                  type="text"
                                  label="Inclusion"
                                  name="name"
                                  onChng={(e) => setInclusion(e.target.value)}
                                  val={Inclusion}
                                />
                              </div>
                              <div className="do-sear mt-2">
                                {/* <label>
                                          Exclusion
                                          <span style={{ color: "red" }}>
                                            *
                                          </span>
                                        </label>
                                        <input
                                          type="text"
                                          placeholder="Enter Exclusion"
                                          className="vi_0"
                                          onChange={(e) =>
                                            setExclusion(e.target.value)
                                          }
                                        /> */}
                                <CustomInput
                                  type="text"
                                  label="Exclusion"
                                  name="name"
                                  onChng={(e) => setExclusion(e.target.value)}
                                  val={Exclusion}
                                />
                              </div>
                              <div className="do-sear mt-2">
                                {/* <label>
                                          Event Date
                                          <span style={{ color: "red" }}>
                                            *
                                          </span>
                                        </label>
                                        <input
                                          type="text"
                                          placeholder="Enter Event Date/Time"
                                          className="vi_0"
                                          onChange={(e) =>
                                            setEventDate(e.target.value)
                                          }
                                        /> */}
                                <CustomInput
                                  type="text"
                                  label="Enter Event Date/Time"
                                  name="name"
                                  onChng={(e) => setEventDate(e.target.value)}
                                  val={EventDate}
                                />
                              </div>

                              <button
                                onClick={AddTourSchedule}
                                className="pink-btn mt-4"
                                style={{
                                  background: "green",
                                  color: "#fff",
                                  padding: 10,
                                }}
                              >
                                Add
                              </button>
                            </div>

                            <div className="col-md-7">
                              <Table responsive bordered>
                                <thead>
                                  <tr>
                                    {/* <th
                                              style={{
                                                background: "#d81d4a",
                                                color: "#fff",
                                              }}
                                            >
                                              Itinerary
                                            </th> */}
                                    <th
                                      style={{
                                        background: "#d81d4a",
                                        color: "#fff",
                                      }}
                                    >
                                      Place Name
                                    </th>
                                    <th
                                      style={{
                                        background: "#d81d4a",
                                        color: "#fff",
                                      }}
                                    >
                                      Inclusion
                                    </th>
                                    <th
                                      style={{
                                        background: "#d81d4a",
                                        color: "#fff",
                                      }}
                                    >
                                      Exclusion
                                    </th>
                                    <th
                                      style={{
                                        background: "#d81d4a",
                                        color: "#fff",
                                      }}
                                    >
                                      Event Date
                                    </th>
                                    <th
                                      style={{
                                        background: "#d81d4a",
                                        color: "#fff",
                                      }}
                                    >
                                      Action
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {tourschedule?.map((item, i) => {
                                    return (
                                      <tr>
                                        {/* <td>Day {i + 1}</td> */}
                                        <td>{item?.placename}</td>
                                        <td>{item?.inclusion}</td>
                                        <td>{item?.exclusion}</td>
                                        <td>{item?.eventDate}</td>
                                        <td>
                                          <div
                                            className="d-flex gap-2 fs-4"
                                            style={{
                                              cursor: "pointer",
                                            }}
                                          >
                                            {/* <BiSolidEdit className="text-primary" /> */}
                                            <MdDelete
                                              onClick={() => removeschedule(i)}
                                              className="text-danger"
                                            />
                                          </div>
                                        </td>
                                      </tr>
                                    );
                                  })}
                                </tbody>
                              </Table>
                            </div>
                          </div>
                        </div>
                      </Typography>
                    </>
                  ) : activeStep == 3 ? (
                    <>
                      <Typography sx={{ mt: 2, mb: 1, py: 1 }}>
                        <div className="container" style={{ padding: "5px" }}>
                          <div className="row ">
                            <div className="col-md-5">
                              <div className="do-sear mt-2">
                                {/* <label>
                                          Place Name
                                          <span style={{ color: "red" }}>
                                            *
                                          </span> */}
                                {/* </label> */}
                                {/* <input
                                          type="text"
                                          placeholder="Enter Place Name"
                                          className="vi_0"
                                          onChange={(e) =>
                                            setPlaceName(e.target.value)
                                          }
                                        /> */}
                                <CustomInput
                                  type="text"
                                  label="Itinerary Title"
                                  name="name"
                                  onChng={(e) => setHeader(e.target.value)}
                                  val={Header}
                                />
                              </div>
                               
                              <div className="do-sear mt-2">
                              <label>
                                          Description
                                          <span style={{ color: "red" }}>
                                            *
                                          </span> 
                                 </label>
                                <textarea
                                  type="text"
                                  label="Inclusion"
                                  name="name"
                                  onChange={(e) =>
                                    setItineraryDesc(e.target.value)
                                  }
                                  value={ItineraryDesc}
                                />
                              </div>

                              <button
                                onClick={AddItinerary}
                                className="pink-btn mt-4"
                                style={{
                                  background: "green",
                                  color: "#fff",
                                  padding: 10,
                                }}
                              >
                                Add
                              </button>
                            </div>

                            <div className="col-md-7">
                              <Table responsive bordered>
                                <thead>
                                  <tr>
                                    <th
                                      style={{
                                        background: "#d81d4a",
                                        color: "#fff",
                                      }}
                                    >
                                      Itinerary
                                    </th>
                                    <th
                                      style={{
                                        background: "#d81d4a",
                                        color: "#fff",
                                      }}
                                    >
                                      Title
                                    </th>
                                    <th
                                      style={{
                                        background: "#d81d4a",
                                        color: "#fff",
                                      }}
                                    >
                                      Description
                                    </th>

                                    <th
                                      style={{
                                        background: "#d81d4a",
                                        color: "#fff",
                                      }}
                                    >
                                      Action
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {Itinerary?.map((item, i) => {
                                    return (
                                      <tr>
                                        <td>Day {i + 1}</td>
                                        <td>{item?.Header}</td>
                                        <td>{item?.ItineraryDesc}</td>

                                        <td>
                                          <div
                                            className="d-flex gap-2 fs-4"
                                            style={{
                                              cursor: "pointer",
                                            }}
                                          >
                                            {/* <BiSolidEdit className="text-primary" /> */}
                                            <MdDelete
                                              onClick={() => removeItinerary(i)}
                                              className="text-danger"
                                            />
                                          </div>
                                        </td>
                                      </tr>
                                    );
                                  })}
                                </tbody>
                              </Table>
                            </div>
                          </div>
                        </div>
                      </Typography>
                    </>
                  ) : (
                    <></>
                  )}

                  <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
                    <Button
                      style={{
                        background: "green",
                        color: "#fff",
                        display: activeStep === 0 ? "none" : "block",
                      }}
                      // disabled={activeStep === 0}

                      onClick={handleBack}
                      sx={{ mr: 1 }}
                    >
                      Back
                    </Button>
                    <Box sx={{ flex: "1 1 auto" }} />
                    {/* <Button
                      style={{
                        background: "#ffc107",
                        color: "#000",
                        display: activeStep === 3 ? "none" : "block",
                      }}
                      onClick={handleNext}
                      sx={{ mr: 1 }}
                    >
                      Next
                    </Button> */}
                    {activeStep !== steps.length &&
                      (completed[activeStep] ? (
                        <Typography
                          variant="caption"
                          sx={{ display: "inline-block" }}
                        >
                          Step {activeStep + 1} already completed
                        </Typography>
                      ) : (
                        <button
                          className="pink-btn"
                          style={{
                            background: "green",
                            color: "#fff",
                            padding: 10,
                          }}
                          onClick={() => {
                            completedSteps() === totalSteps() - 1
                              ? TourpackageDetails()
                              : handleComplete();
                          }}
                        >
                          {completedSteps() === totalSteps() - 1
                            ? "Submit"
                            : "Complete Step"}
                        </button>
                      ))}
                  </Box>
                </React.Fragment>
              )}
            </div>
          </Box>
        </div>
      </Container>
      <Modal
        show={successshow}
        onHide={handlesuccess}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton onClick={handlesuccessClose}></Modal.Header>
        <Modal.Body>
          <div className="text-center">
            <img src={Success} style={{ marginTop: "-12px" }} width="100px" />

            <p>Successfully Added</p>
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        show={errorshow}
        onHide={handleerror}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton onClick={handleerrorClose}></Modal.Header>
        <Modal.Body>
          <div className="text-center">
            <img src={Error} style={{ marginTop: "-12px" }} width="100px" />

            <p>Error Occured</p>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};
export default AddTourPackage;
