import { React, useEffect, useState } from "react";
import CustomInput from "../components/CustomInput";
import ReactQuill from "react-quill";
import { useNavigate } from "react-router-dom";
import "react-quill/dist/quill.snow.css";
import { toast } from "react-toastify";
import * as yup from "yup";
import { useFormik } from "formik";

import { Select } from "antd";
import Dropzone, { useDropzone } from "react-dropzone";

import Register from "../../pages/register/Register";
import axios from "axios";
import { Modal } from "react-bootstrap";
import Success from '../../assets/success.png'
import Error from '../../assets/close.png'
let schema = yup.object().shape({
  name: yup.string().required("Car Name is Required"),
  model: yup.string().required("Car Model is Required"),
  manufacture: yup.string().required("Year of Manufacture is Required"),
  License: yup.string().required("License Plate Number is Required"),
  // Mileage: yup.string().required("Mileage is Required"),
  FuelType: yup.string().required("Fuel Type is Required"),
  Price: yup.string().required("Price is Required"),

  TransmissionType: yup.string().required("Transmission Type is Required"),
  Color: yup.string().required("Colour is Required"),
  Seats: yup.string().required("Number of seats is Required"),
  EngineType: yup.string().required("Engine Type is Required"),
  // InsuranceDetails: yup.string().required("Insurance Details is Required"),
  RegistrationDetails: yup
    .string()
    .required("Registration Details is Required"),
  DriverDetails: yup.string().required("Driver Details is Required"),
});

const AddCars = () => {
  const [color, setColor] = useState([]);
  const [images, setImages] = useState([]);

  const [uploadedImages, setUploadedImages] = useState([]);

  const onDrop = (acceptedFiles) => {
    // Update the state with the new uploaded files
    setUploadedImages([...uploadedImages, ...acceptedFiles]);
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: "image/*", // You can specify the accepted file types here
  });

  const formik = useFormik({
    initialValues: {
      title: "",
      description: "",
      price: "",
      brand: "",
      category: "",
      tags: "",
      color: "",
      quantity: "",
      images: "",
    },
    validationSchema: schema,
    onSubmit: (values) => {
      ////console.log(values);
    },
  });
  const formdata = new FormData();

  const uploadFiles = async (id) => {
    try {
      for (let i = 0; i < uploadedImages.length; i++) {
        formdata.append("id", id);
        formdata.append("Photos", uploadedImages[i]);
        await fetch("https://bookingoda.com/api/admin/addcarPhotos/", {
          method: "post",
          body: formdata,
        });
      }

      window.location.reload("");
    } catch (error) {
      ////console.log(error);
    }
  };
  const [successshow, setsuccessshow] = useState(false);
  const handlesuccessClose = () => setsuccessshow(false);
  const handlesuccess = () => 
    setsuccessshow(true);
   
    const [errorshow, seterrorshow] = useState(false);
    const handleerrorClose = () => seterrorshow(false);
    const handleerror = () => 
      seterrorshow(true);
  const Register1 = async () => {
    const fieldsToCheck = [
      { value: formik.values.name, fieldName: 'Car Name' },
      { value: formik.values.model, fieldName: 'Car Model' },
      { value: formik.values.License, fieldName: 'Car License' },
      { value: formik.values.FuelType, fieldName: 'Car FuelType' },
      { value: formik.values.Price, fieldName: 'Price' },
      { value: formik.values.TransmissionType, fieldName: 'Car TransmissionType' },
      { value: formik.values.Seats, fieldName: 'Car Seats' },
      { value: formik.values.EngineType, fieldName: 'Car EngineType' },
      { value: formik.values.DriverDetails, fieldName: 'DriverDetails' }
    ];
  
    const emptyFields = fieldsToCheck.filter(field => !field.value);
  
    if (emptyFields.length > 0) {
      const fieldNames = emptyFields.map(field => field.fieldName);
      alert(`Please fill the following fields: ${fieldNames.join(', ')}`);
    } else if (uploadedImages.length < 1) {
      alert("Please add at least one image");
    } else {
    try {
      const config = {
        url: "/Addcar",
        method: "post",
        baseURL: "https://bookingoda.com/api/admin",
        headers: { "content-type": "application/json" },
        data: {
          CarName: formik.values.name,
          CarModel: formik.values.model,
          YearofManufacture: formik.values.manufacture,
          License: formik.values.License,
          Mileage: formik.values.Mileage,
          FuelType: formik.values.FuelType,
          Price: formik.values.Price,

          TransmissionType: formik.values.TransmissionType,
          Color: formik.values.Color,
          Seats: formik.values.Seats,

          Enginetype: formik.values.EngineType,
          InsuranceDetails: formik.values.InsuranceDetails,
          // RegistrationDetails: formik.values.RegistrationDetails,
          DriverDetails: formik.values.DriverDetails,

          status: "Pending",
        },
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          uploadFiles(res.data.cars?._id);
          handlesuccess();
        }
      });
    } catch (error) {
      //////console.log(error);
      alert(error.response.data.error);
      handleerror();
    }
  }
  };

  return (
    <div>
      <h3 className="mb-4 title">Add Car</h3>
      <div>
        <form
          onSubmit={formik.handleSubmit}
          className="d-flex gap-3 flex-column"
        >
          <CustomInput
            type="text"
            label="Enter Car Name"
            name="name"
            onChng={formik.handleChange("name")}
            onBlr={formik.handleBlur("name")}
            val={formik.values.name}
          />
          <div className="error">
            {formik.touched.name && formik.errors.name}
          </div>

          <CustomInput
            type="text"
            label="Enter Car Model"
            name="model"
            onChng={formik.handleChange("model")}
            onBlr={formik.handleBlur("model")}
            val={formik.values.model}
          />
          <div className="error">
            {formik.touched.model && formik.errors.model}
          </div>

          <CustomInput
            type="number"
            label="Enter Year of Manufacture"
            name="manufacture"
            onChng={formik.handleChange("manufacture")}
            onBlr={formik.handleBlur("manufacture")}
            val={formik.values.manufacture}
          />
          <div className="error">
            {formik.touched.manufacture && formik.errors.manufacture}
          </div>
          <CustomInput
            type="text"
            label="Registered Plate Number"
            name="License"
            onChng={formik.handleChange("License")}
            onBlr={formik.handleBlur("License")}
            val={formik.values.License}
          />
          <div className="error">
            {formik.touched.License && formik.errors.License}
          </div>
          <CustomInput
            type="text"
            label="Mileage"
            name="Mileage"
            onChng={formik.handleChange("Mileage")}
            onBlr={formik.handleBlur("Mileage")}
            val={formik.values.Mileage}
          />
          {/* <div className="error">
            {formik.touched.Mileage && formik.errors.Mileage}
          </div> */}
          <CustomInput
            type="text"
            label="Fuel Type"
            name="FuelType"
            onChng={formik.handleChange("FuelType")}
            onBlr={formik.handleBlur("FuelType")}
            val={formik.values.FuelType}
          />
          <div className="error">
            {formik.touched.FuelType && formik.errors.FuelType}
          </div>
          <CustomInput
            type="text"
            label="Transmission Type"
            name="TransmissionType"
            onChng={formik.handleChange("TransmissionType")}
            onBlr={formik.handleBlur("TransmissionType")}
            val={formik.values.TransmissionType}
          />
          <div className="error">
            {formik.touched.TransmissionType && formik.errors.TransmissionType}
          </div>
          <CustomInput
            type="text"
            label="Color"
            name="Color"
            onChng={formik.handleChange("Color")}
            onBlr={formik.handleBlur("Color")}
            val={formik.values.Color}
          />
          <div className="error">
            {formik.touched.Color && formik.errors.Color}
          </div>
          <CustomInput
            type="number"
            label="Number of Seats"
            name="Seats"
            onChng={formik.handleChange("Seats")}
            onBlr={formik.handleBlur("Seats")}
            val={formik.values.Seats}
          />
          <div className="error">
            {formik.touched.Seats && formik.errors.Seats}
          </div>
          <CustomInput
            type="number"
            label="Price/ Per KM"
            name="Price"
            onChng={formik.handleChange("Price")}
            onBlr={formik.handleBlur("Price")}
            val={formik.values.Price}
          />
          <div className="error">
            {formik.touched.Price && formik.errors.Price}
          </div>
          <CustomInput
            type="text"
            label="Engine Type"
            name="EngineType"
            onChng={formik.handleChange("EngineType")}
            onBlr={formik.handleBlur("EngineType")}
            val={formik.values.EngineType}
          />
          <div className="error">
            {formik.touched.EngineType && formik.errors.EngineType}
          </div>
          <CustomInput
            type="text"
            label="Insurance Details"
            name="InsuranceDetails"
            onChng={formik.handleChange("InsuranceDetails")}
            onBlr={formik.handleBlur("InsuranceDetails")}
            val={formik.values.InsuranceDetails}
          />
          {/* <div className="error">
            {formik.touched.InsuranceDetails && formik.errors.InsuranceDetails}
          </div> */}
          {/* <CustomInput
            type="text"
            label="Registration Details"
            name="RegistrationDetails"
            onChng={formik.handleChange("RegistrationDetails")}
            onBlr={formik.handleBlur("RegistrationDetails")}
            val={formik.values.RegistrationDetails}
          />

          <div className="error">
            {formik.touched.RegistrationDetails &&
              formik.errors.RegistrationDetails}
          </div> */}
          <div className="error">
            {formik.touched.EngineType && formik.errors.EngineType}
          </div>
          <CustomInput
            type="text"
            label="Driver Details"
            name="DriverDetails"
            onChng={formik.handleChange("DriverDetails")}
            onBlr={formik.handleBlur("DriverDetails")}
            val={formik.values.DriverDetails}
          />
          <div className="error">
            {formik.touched.DriverDetails && formik.errors.DriverDetails}
          </div>
          <p style={{color:'black',fontWeight:'600'}}>Upload Car Photos</p>
          <div className="bg-white border-1 p-5 text-center">
            <div
              className="dropzone-container"
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                padding: "20px",
              }}
            >
              
              <div
                className="dropzone"
                style={{
                  border: "2px dashed #cccccc",
                  borderRadius: "4px",
                  padding: "20px",
                  textAlign: "center",
                  cursor: "pointer",
                  marginBottom: "20px",
                }}
                {...getRootProps()}
              >
                <input {...getInputProps()} />
                <p>Drag & drop files here, or click to select files</p>
              </div>
              {uploadedImages.length > 0 && (
                <div className="uploaded-images">
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      flexWrap: "wrap",
                    }}
                  >
                    {uploadedImages.map((file, index) => (
                      <img
                        key={index}
                        src={URL.createObjectURL(file)}
                        alt={`Uploaded ${index}`}
                        className="uploaded-image"
                        style={{
                          width: "130px",
                          height: "130px",
                          marginRight: "20px",
                        }}
                      />
                    ))}
                  </div>
                </div>
              )}
            </div>
          </div>

          <button
            className="btn btn-success border-0 rounded-3 my-5"
            type="submit"
            onClick={Register1}
          >
            Add Car
          </button>
        </form>
      </div>
      <Modal
        show={successshow}
        onHide={handlesuccess}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton onClick={handlesuccessClose}></Modal.Header>
        <Modal.Body>
          <div className="text-center">
           <img src={Success}
              style={{ marginTop: "-12px" }}
              width="100px"/>
           
            <p>Successfully Added</p>
          </div>
        </Modal.Body>
     
      </Modal>
      <Modal
        show={errorshow}
        onHide={handleerror}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton onClick={handleerrorClose}></Modal.Header>
        <Modal.Body>
          <div className="text-center">
           <img src={Error}
              style={{ marginTop: "-12px" }}
              width="100px"/>
           
            <p>Error Occured</p>
          </div>
        </Modal.Body>
     
      </Modal>
    </div>
  );
};

export default AddCars;
