import React, { useState } from "react";
import { FaCoffee, FaDirections } from "react-icons/fa";
import { IoIosRestaurant } from "react-icons/io";
import { BiSolidDrink } from "react-icons/bi";

const Food = () => {
  const [Restaurant, setRestaurant] = useState(true);
  const [Bars, setBars] = useState(false);
  const [CoffeeShop, setCoffeeShop] = useState(false);

  return (
    <div>
      <div className="container">
        <div className="row mt-4">
          <div className="col-md-12">
            <button
              className=" btn"
              style={{ backgroundColor: "#DA291C", color: "White" }}
              onClick={() => {
                setRestaurant(true);
                setBars(false);
                setCoffeeShop(false);
              }}
            >
              <IoIosRestaurant />
              &nbsp;Restaurants
            </button>{" "}
            &nbsp;
            <button
              className=" btn"
              style={{ backgroundColor: "#DA291C", color: "White" }}
              onClick={() => {
                setRestaurant(false);
                setBars(true);
                setCoffeeShop(false);
              }}
            >
              <BiSolidDrink />
              &nbsp;Bars
            </button>{" "}
            &nbsp;
            <button
              className=" btn"
              style={{ backgroundColor: "#DA291C", color: "White" }}
              onClick={() => {
                setRestaurant(false);
                setBars(false);
                setCoffeeShop(true);
              }}
            >
              <FaCoffee />
              &nbsp;Coffee Shops
            </button>{" "}
            &nbsp;
          </div>
        </div>
      </div>
      <br />
      <br />

      <div className="container">
        <div className="row">
          <div className="col-md-12">
            {Restaurant ? (
              <>
                <div className="row">
                  <div className="col-md-6">
                    <div
                      className="row"
                      style={{
                        border: "1px solid #DDDDDD",
                        borderRadius: "10px",
                        padding: "20px",
                      }}
                    >
                      <div className="col-md-8">
                        <h5>White Restaurant</h5>
                        <p>
                          Family Restaurant <br />
                          4th cross Rd, Tirumala Delights, Tirumala Nagar Rd
                        </p>
                        <p>Open-Closes at 11pm</p>
                        <br />
                        <p>Dinein-Takeaway-Delivery</p>
                      </div>
                      <div className="col-md-4">
                        <img
                          src="/img/white.jpg"
                          alt=""
                          style={{
                            width: "auto",
                            height: "150px",
                            borderRadius: "15px",
                          }}
                        />
                        <div
                          className="direction text-center"
                          style={{ padding: "10px",marginLeft:"-2rem", color:"#DA291C" }}
                        >
                          <p>
                            <FaDirections />
                          </p>
                          <h6>Directions</h6>
                        </div>
                      </div>

                      <div className="col-md-12">
                        <hr />
                      </div>

                      <div className="col-md-8">
                        <h5>White Restaurant</h5>
                        <p>
                          Family Restaurant <br />
                          4th cross Rd, Tirumala Delights, Tirumala Nagar Rd
                        </p>
                        <p>Open-Closes at 11pm</p>
                        <br />
                        <p>Dinein-Takeaway-Delivery</p>
                      </div>
                      <div className="col-md-4">
                        <img
                          src="/img/white.jpg"
                          alt=""
                          style={{
                            width: "auto",
                            height: "150px",
                            borderRadius: "15px",
                          }}
                        />
                        <div
                          className="direction text-center"
                          style={{ padding: "10px",marginLeft:"-2rem", color:"#DA291C" }}
                        >
                          <p>
                            <FaDirections />
                          </p>
                          <h6>Directions</h6>
                        </div>
                      </div>

                      <div className="col-md-12">
                        <hr />
                      </div>

                      <div className="col-md-8">
                        <h5>White Restaurant</h5>
                        <p>
                          Family Restaurant <br />
                          4th cross Rd, Tirumala Delights, Tirumala Nagar Rd
                        </p>
                        <p>Open-Closes at 11pm</p>
                        <br />
                        <p>Dinein-Takeaway-Delivery</p>
                      </div>
                      <div className="col-md-4">
                        <img
                          src="/img/white.jpg"
                          alt=""
                          style={{
                            width: "auto",
                            height: "150px",
                            borderRadius: "15px",
                          }}
                        />
                        <div
                          className="direction text-center"
                          style={{ padding: "10px",marginLeft:"-2rem", color:"#DA291C"}}
                        >
                          <p>
                            <FaDirections />
                          </p>
                          <h6>Directions</h6>
                        </div>
                      </div>

                      <div className="col-md-12">
                        <hr />
                      </div>

                      <div className="col-md-8">
                        <h5>White Restaurant</h5>
                        <p>
                          Family Restaurant <br />
                          4th cross Rd, Tirumala Delights, Tirumala Nagar Rd
                        </p>
                        <p>Open-Closes at 11pm</p>
                        <br />
                        <p>Dinein-Takeaway-Delivery</p>
                      </div>
                      <div className="col-md-4">
                        <img
                          src="/img/white.jpg"
                          alt=""
                          style={{
                            width: "auto",
                            height: "150px",
                            borderRadius: "15px",
                          }}
                        />
                        <div
                          className="direction text-center"
                          style={{ padding: "10px",marginLeft:"-2rem", color:"#DA291C" }}
                        >
                          <p>
                            <FaDirections />
                          </p>
                          <h6>Directions</h6>
                        </div>
                      </div>
                      <div className="col-md-12">
                        <hr />
                      </div>
                    </div>
                  </div>
                  <div
                    className="col-md-6"
                    style={{ paddingLeft: "unset", paddingRight: "unset" }}
                  >
                    <iframe
                      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d31090.264129437837!2d77.53480077779398!3d13.081241386031335!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae229f7a8debe1%3A0x4d80f1d259c7e7ac!2sVidyaranyapura%2C%20Bengaluru%2C%20Karnataka!5e0!3m2!1sen!2sin!4v1702035015935!5m2!1sen!2sin"
                      style={{
                        width: "100%",
                        // marginTop: "2rem",
                        height: "450px",
                      }}
                      allowfullscreen=""
                      loading="lazy"
                      referrerpolicy="no-referrer-when-downgrade"
                    ></iframe>
                  </div>
                </div>
              </>
            ) : (
              <>
                {Bars ? (
                  <>
                    <div className="row">
                      <div className="col-md-6">
                        <div
                          className="row"
                          style={{
                            border: "1px solid #DDDDDD",
                            borderRadius: "10px",
                            padding: "20px",
                          }}
                        >
                          <div className="col-md-8">
                            <h5>Nevermind Bar & Social</h5>
                            <p>
                              Bar <br />
                              4th cross Rd, Tirumala Delights, Tirumala Nagar Rd
                            </p>
                            <p>Open-Closes at 11pm</p>
                            <br />
                            <p>Dinein-Takeaway-Delivery</p>
                          </div>
                          <div className="col-md-4">
                            <img
                              src="/img/white.jpg"
                              alt=""
                              style={{
                                width: "auto",
                                height: "150px",
                                borderRadius: "15px",
                              }}
                            />
                            <div
                              className="direction text-center"
                              style={{ padding: "10px",marginLeft:"-2rem",color:"#DA291C" }}
                            >
                              <p>
                                <FaDirections />
                              </p>
                              <h6>Directions</h6>
                            </div>
                          </div>

                          <div className="col-md-12">
                            <hr />
                          </div>

                          <div className="col-md-8">
                            <h5>Nevermind Bar & Social</h5>
                            <p>
                              Bar <br />
                              4th cross Rd, Tirumala Delights, Tirumala Nagar Rd
                            </p>
                            <p>Open-Closes at 11pm</p>
                            <br />
                            <p>Dinein-Takeaway-Delivery</p>
                          </div>
                          <div className="col-md-4">
                            <img
                              src="/img/white.jpg"
                              alt=""
                              style={{
                                width: "auto",
                                height: "150px",
                                borderRadius: "15px",
                              }}
                            />
                            <div
                              className="direction text-center"
                              style={{ padding: "10px",marginLeft:"-2rem", color:"#DA291C" }}
                            >
                              <p>
                                <FaDirections />
                              </p>
                              <h6>Directions</h6>
                            </div>
                          </div>

                          <div className="col-md-12">
                            <hr />
                          </div>

                          <div className="col-md-8">
                            <h5>Nevermind Bar & Social</h5>
                            <p>
                              Bar <br />
                              4th cross Rd, Tirumala Delights, Tirumala Nagar Rd
                            </p>
                            <p>Open-Closes at 11pm</p>
                            <br />
                            <p>Dinein-Takeaway-Delivery</p>
                          </div>
                          <div className="col-md-4">
                            <img
                              src="/img/white.jpg"
                              alt=""
                              style={{
                                width: "auto",
                                height: "150px",
                                borderRadius: "15px",
                              }}
                            />
                            <div
                              className="direction text-center"
                              style={{ padding: "10px",marginLeft:"-2rem", color:"#DA291C" }}
                            >
                              <p>
                                <FaDirections />
                              </p>
                              <h6>Directions</h6>
                            </div>
                          </div>

                          <div className="col-md-12">
                            <hr />
                          </div>

                          <div className="col-md-8">
                            <h5>Nevermind Bar & Social</h5>
                            <p>
                              Bar <br />
                              4th cross Rd, Tirumala Delights, Tirumala Nagar Rd
                            </p>
                            <p>Open-Closes at 11pm</p>
                            <br />
                            <p>Dinein-Takeaway-Delivery</p>
                          </div>
                          <div className="col-md-4">
                            <img
                              src="/img/white.jpg"
                              alt=""
                              style={{
                                width: "auto",
                                height: "150px",
                                borderRadius: "15px",
                              }}
                            />
                            <div
                              className="direction text-center"
                              style={{ padding: "10px",marginLeft:"-2rem", color:"#DA291C" }}
                            >
                              <p>
                                <FaDirections />
                              </p>
                              <h6>Directions</h6>
                            </div>
                          </div>
                          <div className="col-md-12">
                        <hr />
                      </div>
                        </div>
                      </div>
                      <div
                        className="col-md-6"
                        style={{ paddingLeft: "unset", paddingRight: "unset" }}
                      >
                        <iframe
                          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d31090.264129437837!2d77.53480077779398!3d13.081241386031335!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae229f7a8debe1%3A0x4d80f1d259c7e7ac!2sVidyaranyapura%2C%20Bengaluru%2C%20Karnataka!5e0!3m2!1sen!2sin!4v1702035015935!5m2!1sen!2sin"
                          style={{
                            width: "100%",
                            // marginTop: "2rem",
                            height: "450px",
                          }}
                          allowfullscreen=""
                          loading="lazy"
                          referrerpolicy="no-referrer-when-downgrade"
                        ></iframe>
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    {CoffeeShop ? (
                      <>
                        <div className="row">
                          <div className="col-md-6">
                            <div
                              className="row"
                              style={{
                                border: "1px solid #DDDDDD",
                                borderRadius: "10px",
                                padding: "20px",
                              }}
                            >
                              <div className="col-md-8">
                                <h5>Alchemy Coffee Roasters</h5>
                                <p>
                                  Coffee Shop <br />
                                  4th cross Rd, Tirumala Delights, Tirumala
                                  Nagar Rd
                                </p>
                                <p>Open-Closes at 11pm</p>
                                <br />
                                <p>Dinein-Takeaway-Delivery</p>
                              </div>
                              <div className="col-md-4">
                                <img
                                  src="/img/white.jpg"
                                  alt=""
                                  style={{
                                    width: "auto",
                                    height: "150px",
                                    borderRadius: "15px",
                                  }}
                                />
                                <div
                                  className="direction text-center"
                                  style={{ padding: "10px",marginLeft:"-2rem", color:"#DA291C" }}
                                >
                                  <p>
                                    <FaDirections />
                                  </p>
                                  <h6>Directions</h6>
                                </div>
                              </div>

                              <div className="col-md-12">
                                <hr />
                              </div>

                              <div className="col-md-8">
                                <h5>Alchemy Coffee Roasters</h5>
                                <p>
                                  Coffee Shop <br />
                                  4th cross Rd, Tirumala Delights, Tirumala
                                  Nagar Rd
                                </p>
                                <p>Open-Closes at 11pm</p>
                                <br />
                                <p>Dinein-Takeaway-Delivery</p>
                              </div>
                              <div className="col-md-4">
                                <img
                                  src="/img/white.jpg"
                                  alt=""
                                  style={{
                                    width: "auto",
                                    height: "150px",
                                    borderRadius: "15px",
                                  }}
                                />
                                <div
                                  className="direction text-center"
                                  style={{ padding: "10px",marginLeft:"-2rem", color:"#DA291C" }}
                                >
                                  <p>
                                    <FaDirections />
                                  </p>
                                  <h6>Directions</h6>
                                </div>
                              </div>

                              <div className="col-md-12">
                                <hr />
                              </div>

                              <div className="col-md-8">
                                <h5>Alchemy Coffee Roasters</h5>
                                <p>
                                  Coffee Shop <br />
                                  4th cross Rd, Tirumala Delights, Tirumala
                                  Nagar Rd
                                </p>
                                <p>Open-Closes at 11pm</p>
                                <br />
                                <p>Dinein-Takeaway-Delivery</p>
                              </div>
                              <div className="col-md-4">
                                <img
                                  src="/img/white.jpg"
                                  alt=""
                                  style={{
                                    width: "auto",
                                    height: "150px",
                                    borderRadius: "15px",
                                  }}
                                />
                                <div
                                  className="direction text-center"
                                  style={{ padding: "10px",marginLeft:"-2rem", color:"#DA291C" }}
                                >
                                  <p>
                                    <FaDirections />
                                  </p>
                                  <h6>Directions</h6>
                                </div>
                              </div>

                              <div className="col-md-12">
                                <hr />
                              </div>

                              <div className="col-md-8">
                                <h5>Alchemy Coffee Roasters</h5>
                                <p>
                                  Coffee Shop <br />
                                  4th cross Rd, Tirumala Delights, Tirumala
                                  Nagar Rd
                                </p>
                                <p>Open-Closes at 11pm</p>
                                <br />
                                <p>Dinein-Takeaway-Delivery</p>
                              </div>
                              <div className="col-md-4">
                                <img
                                  src="/img/white.jpg"
                                  alt=""
                                  style={{
                                    width: "auto",
                                    height: "150px",
                                    borderRadius: "15px",
                                  }}
                                />
                                <div
                                  className="direction text-center"
                                  style={{ padding: "10px",marginLeft:"-2rem", color:"#DA291C" }}
                                >
                                  <p>
                                    <FaDirections />
                                  </p>
                                  <h6>Directions</h6>
                                </div>
                                
                              </div>
                              <div className="col-md-12">
                        <hr />
                      </div>
                              
                            </div>
                          </div>
                          <div
                            className="col-md-6"
                            style={{
                              paddingLeft: "unset",
                              paddingRight: "unset",
                            }}
                          >
                            <iframe
                              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d31090.264129437837!2d77.53480077779398!3d13.081241386031335!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae229f7a8debe1%3A0x4d80f1d259c7e7ac!2sVidyaranyapura%2C%20Bengaluru%2C%20Karnataka!5e0!3m2!1sen!2sin!4v1702035015935!5m2!1sen!2sin"
                              style={{
                                width: "100%",
                                // marginTop: "2rem",
                                height: "450px",
                              }}
                              allowfullscreen=""
                              loading="lazy"
                              referrerpolicy="no-referrer-when-downgrade"
                            ></iframe>
                          </div>
                        </div>
                      </>
                    ) : (
                      <></>
                    )}
                  </>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Food;
