import { useState } from "react";
import "./login.css";
import axios from "axios";
import Success from "../../assets/success.png";
import Error from "../../assets/close.png";
import { Container, Form, Modal, Row, Table } from "react-bootstrap";
const Login = () => {
  const [successshow, setsuccessshow] = useState(false);
  const handlesuccessClose = () => setsuccessshow(false);
  const handlesuccess = () => setsuccessshow(true);

  const [errorshow, seterrorshow] = useState(false);
  const handleerrorClose = () => seterrorshow(false);
  const handleerror = () => seterrorshow(true);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(true);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  const handleClick = async (e) => {
    e.preventDefault();

    try {
      const config = {
        url: "/user/loginUser",
        method: "post",
        baseURL: "https://bookingoda.com/api",
        hearder: { "content-type": "application/json" },

        data: {
          email: email,
          password: password,
        },
      };
      await axios(config).then(function (res) {
        if (res.status === 200) {
          if (res.data.success.blockstatus === true) {
            alert("You're blocked");
          } else {
            if (res.data) {
              // alert("login success");
              handlesuccess();
              sessionStorage.setItem("user", JSON.stringify(res.data.success));
              window.location.assign("/bookingoda");
            } else {
              handleerror();
            }
          }
        }
      });
    } catch (error) {
      alert(error.response.data.error);
      ////console.log(error.response.data.error);
    }
  };

  return (
    //     <div class="container"  style={{marginTop:"50px"}}>
    //       <div class="wrapper">
    //         <div class="title"><span>Login Form</span></div>
    //         <form action="#">
    //           <div class="row">
    //             <i class="fas fa-user"></i>
    //             <input type="text" placeholder="Email" onChange={(e)=>setEmail(e.target.value)}
    //             value={email} required />
    //           </div>
    //           <div class="row">
    //   <div class="input-container">
    //     <input
    //       type={showPassword ? 'text' : 'password'}
    //       placeholder="Password"
    //       onChange={(e) => setPassword(e.target.value)}
    //       value={password}
    //       required
    //     />
    //     <i class={showPassword ? "fas fa-eye-slash" : "fas fa-eye"} onClick={togglePasswordVisibility}></i>
    //   </div>
    //   <i class="fas fa-lock"></i>
    // </div>

    //           {/* <div class="pass"><a href="#">Forgot password?</a></div> */}
    //           <div class="row button">
    //             <input type="submit" value="Login" onClick={handleClick} />
    //           </div>
    //           <div class="signup-link">Not a member? <a href="/register">Signup now</a></div>
    //         </form>
    //       </div>
    //       <Modal
    //         show={successshow}
    //         onHide={handlesuccess}
    //         backdrop="static"
    //         keyboard={false}
    //       >
    //         <Modal.Header closeButton onClick={handlesuccessClose}></Modal.Header>
    //         <Modal.Body>
    //           <div className="text-center">
    //            <img src={Success}
    //               style={{ marginTop: "-12px" }}
    //               width="100px"/>

    //             <p>Login Successfully</p>
    //           </div>
    //         </Modal.Body>

    //       </Modal>
    //       <Modal
    //         show={errorshow}
    //         onHide={handleerror}
    //         backdrop="static"
    //         keyboard={false}
    //       >
    //         <Modal.Header closeButton onClick={handleerrorClose}></Modal.Header>
    //         <Modal.Body>
    //           <div className="text-center">
    //            <img src={Error}
    //               style={{ marginTop: "-12px" }}
    //               width="100px"/>

    //             <p>Error Occured</p>
    //           </div>
    //         </Modal.Body>

    //       </Modal>
    //     </div>
    // <section class="vh-100 login-mdb">
    //   <div class="Toastify"></div>
    //   <div class="container py-5 vh-100">
    //     <div class="row d-flex justify-content-center align-items-center h-100">
    //       <div class="col col-xl-10">
    //         <div class="card card-mbd">
    //           <div class="row g-0">
    //             <div class="col-md-6 col-lg-6 d-none d-md-block">
    //               <img
    //                 src="https://images.pexels.com/photos/70441/pexels-photo-70441.jpeg?auto=compress&amp;cs=tinysrgb&amp;w=1260&amp;h=750&amp;dpr=2"
    //                 alt="login form"
    //                 class="img-fluid img-mdb"
    //               />
    //             </div>
    //             <div class="col-md-6 col-lg-6 d-flex align-items-center">
    //               <div class="card-body p-4 p-lg-5 text-black">
    //                 <form>
    //                   <div class="d-flex align-items-center mb-3 pb-1">
    //                     <span class="h1 fw-bold mb-0">Booking-Oda.com</span>
    //                   </div>
    //                   <h5 class="fw-normal headersign mb-3 pb-3">
    //                     Sign into your account
    //                   </h5>
    //                   <div class="mb-3">
    //                     <label for="username" class="form-label">
    //                       Email
    //                     </label>
    //                     <input
    //                       type="text"
    //                       class="form-control form-control-lg"
    //                       id="username"
    //                       placeholder="Email" onChange={(e)=>setEmail(e.target.value)}
    //                                   value={email} required

    //                     />
    //                   </div>
    //                   <div class="mb-3">
    //                     <label for="passowrd" class="form-label">
    //                       Password
    //                     </label>
    //                     <div class="input-container" >
    //                     <input
    //                       type={!showPassword ? 'text' : 'password'}
    //                       class="form-control form-control-lg"
    //                       id="password"
    //                       onChange={(e) => setPassword(e.target.value)}
    //                             value={password}
    //                             required
    //                     />
    //                     <i class={showPassword ? "fas fa-eye-slash" : "fas fa-eye"} onClick={togglePasswordVisibility} style={{marginRight:"10px"}}></i>
    //                     </div>
    //                   </div>
    //                   <div class="pt-1 mb-4">
    //                     <button
    //                       class="btn btn-dark btn-lg btn-block"
    //                       type="button"
    //                       onClick={handleClick}
    //                     >
    //                       Login
    //                     </button>
    //                   </div>
    //                   <br />
    //                   <a href="#!" class="small text-muted">
    //                     Terms of use.
    //                   </a>
    //                   <a href="#!" class="small text-muted">
    //                    {" "} Privacy policy
    //                   </a>
    //                 </form>
    //               </div>
    //             </div>
    //           </div>
    //         </div>
    //       </div>
    //     </div>
    //   </div>
    //   <Modal
    //         show={successshow}
    //         onHide={handlesuccess}
    //         backdrop="static"
    //         keyboard={false}
    //       >
    //         <Modal.Header closeButton onClick={handlesuccessClose}></Modal.Header>
    //         <Modal.Body>
    //           <div className="text-center">
    //            <img src={Success}
    //               style={{ marginTop: "-12px" }}
    //               width="100px"/>

    //             <p>Login Successfully</p>
    //           </div>
    //         </Modal.Body>

    //       </Modal>
    //       <Modal
    //         show={errorshow}
    //         onHide={handleerror}
    //         backdrop="static"
    //         keyboard={false}
    //       >
    //         <Modal.Header closeButton onClick={handleerrorClose}></Modal.Header>
    //         <Modal.Body>
    //           <div className="text-center">
    //            <img src={Error}
    //               style={{ marginTop: "-12px" }}
    //               width="100px"/>

    //             <p>Error Occured</p>
    //           </div>
    //         </Modal.Body>

    //       </Modal>
    // </section>
    <>
      <div class="partner-header">
        <header class=" aCWKa87CwyJQfbPP_yg6">
          <nav class="yC5_EodFadTqIIY8_YDx">
            <div class="NrR_ZlI8bZYI3w06JKKb">
              <div class="H2AEzwF6W02wmXtVrp9U">
                <div class="mobile-join-logo join_logo">
                  <span
                    style={{ color: "white", fontSize: 30, marginTop: "40px" }}
                  >
                    Booking-Oda.com
                  </span>
                </div>
              </div>
            </div>
          </nav>
        </header>
      </div>
      <div className="f054b6e97f" id="automation_id_hero_container">
        <div className="ffb9c3d6a3 dbd3b41eae">
          <div className="aca0ade214 b23c3f480a c2931f4182 c27e5d305d db150fece4">
            <div className="b817090550 b736e9e3f4">
              <div className="c277eab6bf">
                <div className="c0d0e448a8" style={{ paddingRight: "70px" }}>
                  <h1 className="be54f7e491">
                    <div className="ab64ff081b">
                      <div className="a1a525e0a9 d62ced7487">
                        Login <div>to</div> Booking-Oda.com
                      </div>
                    </div>
                  </h1>
                  <h3 className="b14e7782c1">
                    Hospitality is about trying to support multiple functions in
                    one space
                  </h3>
                </div>
                <div className="f33f32dcf3 ab307327fc">
                  <div className="ef6753444c">
                    <div className="eb11e57202">
                      <div className="a9cf2ea607 fa1e8d74e8">
                        <div>
                          <div>
                            <form>
                              <div class="d-flex align-items-center mb-3 pb-1">
                                <span class="h1 fw-bold mb-0">
                                  Booking-Oda.com
                                </span>
                              </div>
                              <h5 class="fw-normal headersign mb-3 pb-3">
                                Sign into your account
                              </h5>
                              <div class="mb-3">
                                <label for="username" class="form-label" style={{fontSize:"14px",fontWeight:"bold"}}>
                                  Email
                                </label>
                                <input
                                  type="text"
                                  class="form-control form-control-lg"
                                  id="username"
                                  placeholder="Email"
                                  onChange={(e) => setEmail(e.target.value)}
                                  value={email}
                                  required
                                />
                              </div>
                              <div class="mb-3">
                                <label for="passowrd" class="form-label" style={{fontSize:"14px",fontWeight:"bold"}}>
                                  Password
                                </label>
                                <div class="input-container">
                                  <input
                                    type={!showPassword ? "text" : "password"}
                                    class="form-control form-control-lg"
                                    id="password"
                                    placeholder="Password"
                                    onChange={(e) =>
                                      setPassword(e.target.value)
                                    }
                                    value={password}
                                    required
                                  />
                                  <i
                                    class={
                                      showPassword
                                        ? "fas fa-eye-slash"
                                        : "fas fa-eye"
                                    }
                                    onClick={togglePasswordVisibility}
                                    style={{ marginRight: "10px" }}
                                  ></i>
                                </div>
                              </div>
                              <div class="pt-1 mb-4">
                                <button
                                  class="btn btn-dark btn-lg btn-block"
                                  type="button"
                                  onClick={handleClick}
                                >
                                  Login
                                </button>
                              </div>
                              <br />
                              <a href="#!" class="small text-muted">
                                Terms of use.
                              </a>
                              <a href="#!" class="small text-muted">
                                {" "}
                                Privacy policy
                              </a>
                            </form>
                            <a
                              href="/register"
                              class="large"
                              style={{
                                color: "#297cbb",
                                fontWeight: "900",
                                marginLeft: "auto",
                                marginRight: "auto",
                              }}
                            >
                              Don't have an account? Register here!
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        show={successshow}
        onHide={handlesuccess}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton onClick={handlesuccessClose}></Modal.Header>
        <Modal.Body>
          <div className="text-center">
            <img src={Success} style={{ marginTop: "-12px" }} width="100px" />

            <p>Login Successfully</p>
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        show={errorshow}
        onHide={handleerror}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton onClick={handleerrorClose}></Modal.Header>
        <Modal.Body>
          <div className="text-center">
            <img src={Error} style={{ marginTop: "-12px" }} width="100px" />

            <p>Error Occured</p>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Login;
