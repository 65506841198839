import { display } from "@mui/system";
import axios from "axios";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import "./community.scoped.css";
import Profile_Dashboard from "./Profile_Dashboard";

import pics from "../../assets/user.png";
function Friend() {
  const user = JSON.parse(sessionStorage.getItem("user"));
  const [userdetails, setuserdetails] = useState({});
  const [followers, setfollowers] = useState([]);

  useEffect(() => {
    getuser();
  }, []);

  const getuser = async () => {
    let res = await axios.get(
      "https://bookingoda.com/api/user/getmyprofile/" + user._id
    );
    if (res.status === 200) {
      //console.log("getuser===>", res.data);
      setuserdetails(res.data.user);
    }
  };

  const [friends, setFriends] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");

  const [friendss, setFriendss] = useState([]);
  const [friendss1, setFriendss1] = useState([]);

  useEffect(() => {
    getFriends();
  }, []);

  useEffect(() => {
    if (Object.keys(user).length > 0) {
      getFriendss();
      getFriendss1();
    }
  }, [user]);

  const getFriends = () => {
    axios
      .get("https://bookingoda.com/api/user/getAllUser")
      .then((response) => {
        setFriends(response.data.success);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getFriendss = () => {
    axios
      .get(
        `https://bookingoda.com/api/user/getFririndlistByuserIdPending/${user._id}`
      )
      .then((response) => {
        setFriendss(response.data.success);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getFriendss1 = () => {
    axios
      .get(`https://bookingoda.com/api/user/getFririndlistByuserId/${user._id}`)
      .then((response) => {
        setFriendss1(response.data.success);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const addFriendReq = async (id) => {
    try {
      const config = {
        url: "/addfriendreq",
        method: "post",
        baseURL: "https://bookingoda.com/api/user",
        data: {
          senderId: user?._id,
          receiverId: id,
          status: "Pending",
        },
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert("Friend request sent");
          postFrndReqDetails(id);
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  const postFrndReqDetails = async (id) => {
    try {
      const config = {
        url: "/postreqdetails",
        method: "post",
        baseURL: "https://bookingoda.com/api/user",
        data: {
          senderId: user._id,
          receiverId: id,
        },
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          // setData(res.data.request);
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  const handleSearch = (query) => {
    setSearchQuery(query);
  };

  const filteredFriends = friends.filter((friend) =>
    friend.name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <>
      <div className="communityhomestyle">
   
        <div>
          <div className="gap2 gray-bg">
            <div
              className="container fluid mobile-correct-0"
              style={{ paddingTop: "25px" }}
            >
              <div className="row">
                <div className="col-lg-12">
                  <div className="row merged20" id="page-contents">
                    {/* profile Dashboard start ========================================= */}
                    <div className="user-profile">
                      <Profile_Dashboard />
                    </div>
                    {/* <!-- user profile banner  --> */}
                    <div class="col-lg-12">
                      <div class="central-meta">
                        <div class="title-block">
                          <div class="row">
                            <div class="col-lg-6">
                              <div class="row merged20">
                                <div class="col-lg-7 col-lg-7 col-sm-7">
                                  <form method="post">
                                    <input
                                      type="text"
                                      placeholder="Search Friend"
                                      value={searchQuery}
                                      onChange={(e) =>
                                        handleSearch(e.target.value)
                                      }
                                    />
                                    <button
                                      type="submit"
                                      style={{ border: "none" }}
                                    >
                                      <i class="fas fa-search"></i>
                                    </button>
                                  </form>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/*  */}
                      <div class="central-meta">
                        <div class="title-block">
                          <div className="friends-list">
                            <h2>Friends</h2>
                            <div>
                              {filteredFriends.map((friend) => (
                                <div key={friend._id}>
                                  <div>
                                    {friend?.profileImage ? (
                                      <img
                                        src={`https://bookingoda.com/admin/${friend.profileImage}`}
                                        alt={friend.name}
                                        style={{
                                          height: "50px",
                                          width: "50px",
                                          borderRadius: "50%",
                                        }}
                                      />
                                    ) : (
                                      <img
                                        src={pics}
                                        alt="Avatar"
                                        style={{
                                          height: "50px",
                                          width: "50px",
                                          borderRadius: "50%",
                                        }}
                                      />
                                    )}
                                    <span className="friend-name">
                                      {"    "}
                                      {friend.name}
                                    </span>
                                  </div>
                                  {friend._id === user._id ? null : (
                                    <>
                                      {friendss1.some(
                                        (item) =>
                                          item.receiverId._id === friend._id &&
                                          item.status === "Accepted"
                                      ) ? (
                                        <div>
                                          <span className="friend-statusaccepted">
                                            Friends
                                          </span>
                                        </div>
                                      ) : friendss.some(
                                          (item) =>
                                            item.receiverId._id ===
                                              friend._id &&
                                            item.status === "Pending"
                                        ) ? (
                                        <div>
                                          <span className="friend-statuspending">Request Sent</span>
                                        </div>
                                      ) : (
                                        <button
                                          onClick={() =>
                                            addFriendReq(friend._id)
                                          }
                                          className="friend-action-btn"
                                        >
                                          Add Friend
                                        </button>
                                      )}
                                    </>
                                  )}
                                  <hr />
                                </div>
                              ))}
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* <!-- title block --> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Friend;
