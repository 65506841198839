import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { store } from "./admin/app/store";
import { Provider } from "react-redux";
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import Com_Message from "./components/Community/Com_Message";
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(

  <Provider store={store}>
    <App />
    </Provider>
);
serviceWorkerRegistration.register()